import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import {
  CloseIcon,
  GearIconSVG,
  SummaryEmptySVG,
} from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Toastr from "../../../common/components/Toast";
import FormStepper from "../../../common/components/FormStepper";
import SidebarTitle from "../../../common/components/SidebarTitle";
import Finalize from "../../../common/components/Finalize";
import FirstStepContent from "./FirstStep";
import SecondStepContent from "./SecondStep";
import QuestionnaireStep from "./Questionnaire";
import CommunicationStep from "./CommunicationStep";
import CallAttemptProcedureComponent from "./CallAttemptProcedure";
import Notes from "./Notes";
import { useDispatch, useSelector } from "react-redux";
import FollowupAppointmentStepComponent from "./FollowupAppointmentStep";
import Cookies from "universal-cookie";
import {
  setProcedureData,
  saveProcedureQuestion,
  getSingleProcedure,
  get_patient_notes,
} from "../../../store/actions";
import { Icon } from "antd";

const cookies = new Cookies();
const initialCondition = [
  { step: "demographics", status: true },
  { step: "questions", status: true },
  { step: "procedure", status: true },
  { step: "communication", status: false },
  { step: "attempt", status: false },
  { step: "notes", status: false },
  { step: "finalize", status: false },
];

const formSteps = [
  {
    title: "Demographics",
    isConfirmed: true,
    content: FirstStepContent,
  },
  {
    title: "Questionnaire",
    isConfirmed: true,
    content: QuestionnaireStep,
  },
  {
    title: "Procedure Scheduling",
    isConfirmed: true,
    content: SecondStepContent,
  },
  {
    title: "Communication",
    isConfirmed: true,
    content: CommunicationStep,
  },
  {
    title: "Call Attempts",
    isConfirmed: true,
    content: CallAttemptProcedureComponent,
  },
  {
    title: "Notes",
    isConfirmed: true,
    content: Notes,
  },
  {
    title: "Finalize",
    isConfirmed: true,
    content: Finalize,
  },
];

const HospitalSideBar = React.forwardRef(
  ({ isShow, handleCloseSidebar, handleNext, selectedType }, ref) => {
    const resource = useSelector((state) => state.procedure.resource);
    const isConfirmed = useSelector((state) => state.procedure.isConfirmed);
    const followupSlots = useSelector((state) => state.procedure.followupSlots);
    const existingFollowUp = useSelector(
      (state) => state.procedure.existing_followup
    );
    const patientsNote = useSelector((state) => state.newPatient.patientsNote);
    const call_attempts = useSelector((state) => state.procedure.call_attempts);
    const checkApointment = useSelector(
      (state) => state.common.checkApointment
    );
    const [check, setCheck] = useState(false);
    const procedure = useSelector((state) => state.procedure.procedure);
    const [conditions, setConditions] = useState(initialCondition);
    const [step, setStep] = useState(0);
    const [title, setTitle] = useState("");
    const [noteCancelValue, setNoteCancelValue] = useState("");
    const [questions, setQuestions] = useState({
      taking_metformin: -1,
      taking_eliquis: -1,
      taking_coumadin: -1,
      taking_plavix: -1,
      receive_coronary_stent: -1,
    });
    const [showNote, setShowNote] = useState(false);
    const [timer, setTimer] = useState(0);
    const procedure_id = cookies.get("procedure_id");
    const dispatch = useDispatch();

    const actionShowNote = (value) => {
      setShowNote(value);
    };

    const isToastr = useMemo(() => {
      if (isConfirmed) {
        setTimer(0);
        setTimeout(() => {
          setTimer(600);
        }, 2000);
        return true;
      }
      return false;
    }, [isConfirmed]);

    useEffect(() => {
      if (resource && resource.patient) {
        const newTitle =
          `${resource.patient.first_name || ""} ${
            resource.patient.last_name || ""
          } ` +
          `(DOB ${
            resource.patient.date_of_birth
              ? moment(resource.patient.date_of_birth).format("MM/DD/YYYY")
              : ""
          })` +
          `(OPS ID: ` +
          resource.patient.id +
          `)`;
        setTitle(newTitle);
        setStep(0);
        setShowNote(false);
        setNoteCancelValue("");
        dispatch(get_patient_notes(resource.patient.id));
      }
    }, [resource]);

    const handleSubmit = () => {
      if (step === 0) {
        setStep(1);
        handleNext(1);
      } else {
        // Todo: handleSubmit
        // console.log('save');
      }
    };
    const handleStep = (step) => {
      setStep(step);
      handleNext(step);
    };
    console.log(conditions, "conditions");
    useEffect(() => {
      if (resource) {
        const newConditions = [...conditions];
        if (procedure && procedure?.patient) {
          if (
            procedure?.patient?.taking_metformin == null ||
            procedure?.patient?.taking_metformin == -1 ||
            procedure?.patient?.taking_eliquis == null ||
            procedure?.patient?.taking_eliquis == -1 ||
            procedure?.patient?.taking_coumadin == null ||
            procedure?.patient?.taking_coumadin == -1 ||
            procedure?.patient?.taking_plavix == null ||
            procedure?.patient?.taking_plavix == -1 ||
            procedure?.patient?.receive_coronary_stent == null ||
            procedure?.patient?.receive_coronary_stent == -1
          ) {
            newConditions[1].status = false;
          } else {
            newConditions[1].status = true;
          }
          if (checkApointment) {
            newConditions[2].status = true;
          } else {
            if (
              procedure?.patient?.is_completed_scheduling == null ||
              procedure?.patient?.is_completed_scheduling == 0
            ) {
              newConditions[2].status = false;
            } else {
              newConditions[2].status = true;
            }
          }
        }
        if (check) {
          newConditions[3].status = true;
        } else {
          newConditions[3].status = false;
        }
        if (call_attempts && call_attempts.length > 0) {
          newConditions[4].status = true;
        } else {
          newConditions[4].status = false;
        }
        if (patientsNote && patientsNote.length > 0) {
          newConditions[5].status = true;
        } else {
          newConditions[5].status = false;
        }
        setConditions([...newConditions]);
      }
    }, [
      resource,
      step,
      procedure,
      checkApointment,
      call_attempts,
      patientsNote,
      check,
    ]);

    const checkQuestion = (quest) => {
      setQuestions(quest);
    };

    const onCloseStep = async (step) => {
      if (step == 1) {
        await dispatch(setProcedureData("loadingSchedule", true));
        await dispatch(saveProcedureQuestion(resource.id, questions));
        await dispatch(getSingleProcedure(resource.id));
      }
    };

    const setNoteCancelValueCheck = (value) => {
      setNoteCancelValue(value);
    };

    const checkAction = (value) => {
      setCheck(value);
    };

    if (!isShow) {
      return null;
    }

    if (!resource) {
      return (
        <div ref={ref} className={`resourceContainer ${isShow ? "show" : ""}`}>
          <div
            className="emptyContentIcon"
            style={{
              width: "100%",
              height: "calc(100% - 160px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SummaryEmptySVG />
          </div>
        </div>
      );
    }

    return (
      <div ref={ref} className={`resourceContainer ${isShow ? "show" : ""}`}>
        {isToastr && timer < 600 && (
          <div style={{ position: "fixed", top: "60px", right: "2px" }}>
            <Toastr
              type="Procedure"
              status="success"
              msg="Confirmed Successfully!"
            />
          </div>
        )}
        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>

          <SidebarTitle
            icon={
              <Icon
                type="notification"
                theme="twoTone"
                twoToneColor="#0f62fe"
              />
            }
            title={
              <span className="text-uppercase">
                {resource.procedure_type && resource.procedure_type.value
                  ? resource.procedure_type.value
                  : `Procedure Scheduling ${selectedType}`}
              </span>
            }
            subTitle=""
          />

          <FormStepper
            showNote={showNote}
            setNoteCancelValue={setNoteCancelValueCheck}
            noteCancelValue={noteCancelValue}
            checkAction={checkAction}
            type="Procedure"
            actionShowNote={actionShowNote}
            steps={formSteps}
            currentStep={step}
            checkQuestion={checkQuestion}
            onCloseStep={onCloseStep}
            conditions={conditions}
            handleStep={handleStep}
            handleContinue={handleSubmit}
            customResource={resource}
          />
        </ScrollWrapper>
      </div>
    );
  }
);

export default HospitalSideBar;
