import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";
import * as API from "../../services/api.services";

/*
// already defined on patientSchedule
export const getPatientSchedules = createActionThunk(
  AT.GET_PATIENT_SCHEDULES,
  ({date, location, provider_id, patient_id}) => API.getPatientSchedules({date, location, provider_id, patient_id})
);

// already defined on patientSchedule
export const searchPatientSchedules = (searchValue) => (
  (dispatch) => dispatch({
    type: AT.SEARCH_PATIENT_SCHEDULES,
    payload: {
      searchValue
    }
  })
)
*/

export const choosePatientOnSchedules = createActionThunk(
  AT.CHOOSE_PATIENT_ON_SCHEDULES,
  (id) => API.getPatient({ id })
);

export const saveUploadedPatientDocument = createActionThunk(
  AT.SAVE_UPLOADED_PATIENT_DOCUMENT,
  ({ patient_id, category, provider_id, filename }) =>
    API.saveUploadedPatientDocument({
      patient_id,
      category,
      provider_id,
      filename,
    })
);

export const reset_select_document = createActionThunk(
  AT.RESET_SELECT_DOCUMENT,
  () => {}
);

export const searchPatientDocuments = createActionThunk(
  AT.SEARCH_PATIENT_DOCUMENTS,
  ({ patient_id, date, category, provider_id }) =>
    API.searchPatientDocuments({ patient_id, date, category, provider_id })
);

export const openDocument = (document) => (dispatch) =>
  dispatch({
    type: AT.OPEN_PATIENT_DOCUMENT,
    payload: {
      document,
    },
  });
