/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

import DetailSidebar from "./DetailSidebar";
import { useWindowSize } from "../../../../common/utils";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ImageEditor from "../../../../common/components/ImageEditor";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import {
  getCurrentUserInfo,
  getSupportModules,
  getAllSupportModules,
  updateUserMedia,
  setAuthorRead
} from "../../../../store/actions";
import { CheckSVG, RedRequiredCircleSVG } from "../../../../common/components/icons/SVGIcon";
import { convertToLongNumber } from '../../../../common/utils';
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";
import moment from "moment";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { updateAuthorReadStatus } from "../../../../services/api.services";

const AllPersonalTickets = ({ title, filter }) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const history = useHistory();
  const location = useLocation();
  const authId = cookies.get("cvai-current-user");
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const supportModules = useSelector((state) => state.supportModule);
  const userMediaFiles = useSelector((state) => state.userMedia);
  const [emptyRows, setEmptyRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [isSidebar, setIsSidebar] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [activeIndex, setRow] = useState(-1);

  useEffect(() => {
    if (!!currentUserInfo.id) {
      const { role_id } = currentUserInfo;
      if (role_id === 3) {
        dispatch(getAllSupportModules());
      } else {
        dispatch(getSupportModules(currentUserInfo.id));
      }
    }
  }, [currentUserInfo.id]);

  useEffect(() => {
    if (!supportModules.loading && !!supportModules.supports) {
      let tmpSupportModules = supportModules.supports;

      if (filter === 'open') {
        tmpSupportModules = tmpSupportModules.filter(row => row.status === 0);
      } else if (filter === 'closed') {
        tmpSupportModules = tmpSupportModules.filter(row => row.status === 1);
      }

      let rowCount = 0;
      let tempEmptyRows = [];
    
      if (rowCount > tmpSupportModules.length) {
        tempEmptyRows = new Array(rowCount - tmpSupportModules.length).fill({});
      }
      setEmptyRows(tempEmptyRows);
      setTableRows(tmpSupportModules);
    }
  }, [supportModules.loading]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isCaptured = searchParams.get("is_captured");

    if (isCaptured && !userMediaFiles.loading && userMediaFiles.media.length > 0) {
      setIsPreview(true);
      setPreviewData(userMediaFiles.media[userMediaFiles.media.length - 1]);
    }
  }, [userMediaFiles, location]);

  useEffect(() => {
    handleSidebar();

    if (authId) {
      dispatch(getCurrentUserInfo(authId));
    }
  }, []);

  const handleSidebar = () => setIsSidebar(true);
  const handleCloseSidebar = () => setIsSidebar(false);
  const toggleSidebar = () => setIsSidebar(!isSidebar);

  const handleRow = async (index) => {
    setRow(index)
    await dispatch(setAuthorRead(tableRows[index].id));
    await dispatch(getSupportModules(currentUserInfo.id));
  }

  const handleMediaFileDoubleClicked = (value) => {
    setIsPreview(true);
    setPreviewData(value);
  };

  // Image Editor handler
  const handleSaveImage = (value) => {
    const payload = {
      type: "image",
      user_id: value.originImage.user_id,
      data: value.updatedImage,
    };
    dispatch(updateUserMedia(value.originImage.id, payload));
    history.push("/support/details");
  };
  const handleCloseEditor = () => {
    setIsPreview(false);
    history.push("/support/details");
  };
  const handleCreatedModule = () => {
    setRow(tableRows.length);
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        {isPreview ? (
          <div className="fullContainer pt-2">
            {previewData.type === "image" && (
              <ImageEditor
                onSaveImage={handleSaveImage}
                onCloseEditor={handleCloseEditor}
                imageData={previewData}
              />
            )}
            {/* {previewData.type === "video" && (
              <div className="resourceInfo pt-2">
                <div className="resourceInfo" style={{position: 'relative'}}>
                  <video width="100%" src={previewData.url} type="video/mp4" loop autoPlay controls={true} />
                  <AiOutlineClose
                    style={{
                      position: "absolute", 
                      top: "-15px", 
                      right: "-15px", 
                      border: "1px soid lightgray", 
                      backgroundColor: "white",
                      borderRadius: "20px",
                      width: "30px",
                      height: "30px",
                      boxShadow: "0 0 20px black",
                      padding: "5px",
                      cursor: "pointer",
                      color: "black",
                      zIndex: 999
                    }}
                    onClick={handleCloseEditor}
                  />
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <div className="fullContainer pt-2">
            <div className="tableSection">
              <div className="tableContainer">
                <DataTable
                  title={'support_tickets'}
                  labels={[
                    'Date', 
                    'ID',
                    'Subject'
                  ]}
                  widths={[
                    '30%',
                    '20%',
                    '50%'
                  ]}
                  columns={[
                    { sortable: false, key: 'date' },
                    { sortable: false, key: 'id' },
                    { sortable: false, key: 'subject' }
                  ]}
                  disableDelete={true}
                  handleClickRow={(row, index) => handleRow(index)}
                  defaultActive={-1}
                  rowData={(tableRows || []).map((row) => {
                    return {
                      date: (
                        <div 
                          className="iconField td with-icon"
                          style={{
                            display: "flex",
                            paddingLeft: "0.25rem",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {row.author_unread == 1 ? (<Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28"/>) : 
                            row.status === 0 ? <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/> : 
                            row.status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
                          }
                          
                          <p style={{paddingLeft: '0.75rem'}}>{moment(row.updated_at).format('YYYY-MM-DD [at] h:ma')}</p>
                        </div>
                      ),
                      id: (<p>{convertToLongNumber(row.id)}</p>),
                      subject: (<p>{row.subject}</p>)
                    }
                  })}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <DetailSidebar
        ref={sidebarRef}
        currentUserInfo={currentUserInfo}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
        resource={activeIndex !== -1 ? tableRows[activeIndex] : undefined}
        mediaFileDoubleClicked={handleMediaFileDoubleClicked}
        onCreatedModule={handleCreatedModule}
      />

      <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
    </div>
  );
};

export default AllPersonalTickets;
