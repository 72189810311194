import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import DashboardSidebar from "./DashboardSidebar";
import Loading from "../../../../common/components/Loading";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import { getCurrentUserInfo } from "../../../../store/actions";
import { StaticResource, DashboardRows } from "../../constant";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const cookies = new Cookies();

const Dashboard = ({ loading }) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [activeIndex, setRow] = useState(-1);
  const tableRows = DashboardRows ? DashboardRows : [];

  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  const authId = cookies.get("cvai-current-user");

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleRow = (row, index) => () => {
    setRow(index);
  };

  const fetchCurrentUser = () => {
    if (authId) {
      dispatch(getCurrentUserInfo(authId));
    }
  };

  const loadMore = () => {
    // TODO: API integration with pagination
    // console.log('load more');
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_dashboard"
                labels={["Pay Period", "Regular Hours", "OT Hours", "Witholdings"]}
                widths={["31%", "23%", "23%", "23%"]}
                columns={[
                  { sortable: false, key: "period" },
                  { sortable: false, key: "regular_hours" },
                  { sortable: false, key: "ot_hours" },
                  { sortable: false, key: "witholdings" },
                ]}
                sorts={["", "", "", ""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row.id,
                  period: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {row.icon && row.icon}
                      {!row.icon && <span style={{ minWidth: "26px" }}></span>}
                      <p style={{ paddingLeft: "0.75rem" }}>
                        {"10/01/2019 - 10/09/2019"}
                      </p>
                    </div>
                  ),
                  regular_hours: "40.0 hrs",
                  ot_hours: "10.0 hrs",
                  witholdings: "$120.12",
                }))}
                fetchList={fetchCurrentUser}
                loadMore={loadMore}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {currentUserInfo && currentUserInfo.name && (
        <DashboardSidebar
          ref={sidebarRef}
          currentUserInfo={currentUserInfo}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}

      {currentUserInfo && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Dashboard;
