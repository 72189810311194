import React, { useEffect } from "react";
import { Icon } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { TrashSVG } from "../../../common/components/icons/SVGIcon";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import {
  setProcedureDetails,
  getUltrasounds,
  addProcedureDetails,
  updateDetailProcedureData,
  getProcedureType,
  editProcedureType,
  deleteProcedureType,
} from "../../../store/actions";

let timer = null;
const VariableCapture = ({ resource }) => {
  // TODO: captures integration
  const variable = useSelector((state) => state.procedureDetail.variable);
  const loading_procedure_type = useSelector(
    (state) => state.procedureDetail.loading_procedure_type
  );
  const procedure_type = useSelector(
    (state) => state.procedureDetail.procedure_type
  );
  const detailProcedureData = useSelector(
    (state) => state.common.detailProcedureData
  );
  const dispatch = useDispatch();

  const updateVariable = async () => {
    const checkArray = (procedure_type || []).map((r, index) => {
      return {
        variable_name: r?.variable_name,
        id: r?.id,
        quest: r?.question,
        answer: r?.answers,
        condition_serialize:
          detailProcedureData &&
          Object.keys(detailProcedureData).length > 0 &&
          detailProcedureData?.cpt_rules,
        cpt_code: r?.cpt_codes,
        cpt_code_id:
          detailProcedureData &&
          Object.keys(detailProcedureData).length > 0 &&
          detailProcedureData?.cpt_rules &&
          detailProcedureData?.cpt_rules[index] &&
          detailProcedureData?.cpt_rules[index].id,
        short_desc:
          detailProcedureData &&
          Object.keys(detailProcedureData).length > 0 &&
          detailProcedureData?.cpt_rules[index] &&
          `${detailProcedureData?.cpt_rules[index].code} ${detailProcedureData?.cpt_rules[index].short_desc}`,
      };
    });
    await dispatch(setProcedureDetails("variable", [...checkArray]));
  };

  useEffect(() => {
    updateVariable();
  }, [procedure_type, detailProcedureData]);

  const updateProviders = async (type, index, value, indexAnswer, idOnRow) => {
    if (type === "quest") {
      variable[index][type] = value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          editProcedureType(idOnRow.id, {
            variable_name: idOnRow.variable_name,
            question: value,
            procedure_type_id: detailProcedureData.id,
            answers: variable[index]["answer"],
          })
        );
      }, 1000);
    } else {
      variable[index]["answer"][indexAnswer][type] = value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          editProcedureType(idOnRow.id, {
            variable_name: idOnRow.variable_name,
            question: idOnRow.quest,
            procedure_type_id: detailProcedureData.id,
            answers: variable[index]["answer"],
          })
        );
      }, 1000);
    }
    await dispatch(setProcedureDetails("variable", [...variable]));
  };
  const removeProvider = async (type, index, indexAnswer, idOnRow) => {
    if (type === "quest") {
      variable.splice(index, 1);
      await dispatch(deleteProcedureType(idOnRow));
    } else {
      variable[index]["answer"].splice(indexAnswer, 1);
      await dispatch(
        editProcedureType(idOnRow.id, {
          variable_name: idOnRow.variable_name,
          question: idOnRow.quest,
          procedure_type_id: detailProcedureData.id,
          answers: variable[index]["answer"],
        })
      );
    }
    await dispatch(getProcedureType(detailProcedureData.id));
    // await dispatch(setProcedureDetails("variable", [...variable]));
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ScrollWrapper css="no-padding">
        <div style={{ paddingTop: "12px" }}>
          {(variable || []).map((r, index) => (
            <React.Fragment key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: "8px",
                }}
              >
                <div style={{ width: "calc(100% - 32px)", marginRight: "8px" }}>
                  <EditableFormInput
                    label="Specifications"
                    value={r.quest}
                    fullWidth
                    handleChange={(value) =>
                      updateProviders("quest", index, value, null, r)
                    }
                  />
                </div>
                <div
                  style={{
                    width: "24px",
                    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => removeProvider("quest", null, index, r.id)}
                >
                  <Icon
                    type="delete"
                    
                    style={{ fontSize: "16px" }}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 10 }} className="resourceInfo d-flex">
                <b style={{ minWidth: "37%" }}>Variable Name: </b>
                {r.variable_name}
              </div>
              {r.answer.map((item, indexAnswer) => (
                <div
                  key={indexAnswer}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ width: "calc(40% - 8px)", marginRight: "8px" }}>
                    <EditableFormInput
                      handleChange={(value) =>
                        updateProviders("answer", index, value, indexAnswer, r)
                      }
                      value={item.answer}
                      label={`Answer ${indexAnswer + 1}`}
                      fullWidth
                    />
                  </div>
                  <div
                    style={{ width: "calc(60% - 32px)", marginRight: "8px" }}
                  >
                    <EditableFormInput
                      handleChange={(value) =>
                        updateProviders(
                          "text_template",
                          index,
                          value,
                          indexAnswer,
                          r
                        )
                      }
                      label="Sentence"
                      value={item.text_template}
                      fullWidth
                    />
                  </div>
                  <div
                    style={{
                      width: "24px",
                      cursor: "pointer",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() =>
                      removeProvider("answer", index, indexAnswer, r)
                    }
                  >
                    <Icon
                      type="delete"
                      
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default VariableCapture;
