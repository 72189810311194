import { combineReducers } from "redux";
import { sessionReducer as session } from "redux-react-session";

import common from "./common";
import newPatient from "./newPatient";
import patientPending from "./patientPending";
import patientCallback from "./patientCallback";
import clinicEvents from "./clinicEvents";
import reminders from "./reminders";
import procedure from "./procedures";
import prior from "./prior";
import patientRecords from "./patientRecords";
import dashboard from "./dashboard";
import patientSchedule from "./patientSchedule";
import scanDriverLicense from "./scanDriverLicense";
import scanInsuranceCard from "./scanInsuranceCard";
import ultrasound from "./ultrasound";
import clinicProvider from "./clinicProvider";
import adminReferralCondition from "./adminReferralCondition";
import inventory from "./inventory";
import clinicProviderHPI from "./clinicProviderHPI";
import patientExamination from "./patientExamination";
import clinicProviderReview from "./clinicProviderReview";
import clinicProviderPatientSnapshot from "./clinicProviderPatientSnapshot";
import clinicProviderPlan from "./clinicProviderPlan";
import procedureConsent from "./procedureConsent";
import adminUser from "./adminUser";
import hipaa from "./hipaa";
import patientSnapshot from "./patientSnapshot";
import dicom from "./dicom";
import procedureReview from "./procedureReview";
import adminSetting from "./adminSetting";
import setting from "./setting";
import clinicProviderOffice from "./clinicProviderOffice";
import procedureDetail from "./procedureDetail";
import userMedia from "./userMedia";
import supportModule from "./supportModule";
import adminMeaningful from "./adminMeaningful";
import appointmentBoard from "./appointmentBoard";
import sidebar from "./sidebar";


const rootReducer = combineReducers({
  common,
  session,
  newPatient,
  patientPending,
  patientCallback,
  clinicEvents,
  reminders,
  procedure,
  prior,
  patientRecords,
  dashboard,
  patientSchedule,
  scanDriverLicense,
  scanInsuranceCard,
  ultrasound,
  clinicProvider,
  adminReferralCondition,
  inventory,
  procedureConsent,
  clinicProviderHPI,
  patientExamination,
  clinicProviderReview,
  clinicProviderPatientSnapshot,
  clinicProviderPlan,
  adminUser,
  hipaa,
  patientSnapshot,
  dicom,
  procedureReview,
  adminSetting,
  setting,
  clinicProviderOffice,
  procedureDetail,
  userMedia,
  supportModule,
  adminMeaningful,
  appointmentBoard,
  sidebar,
});

export default rootReducer;
