import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { DatePicker, Select, Tabs } from "antd";

import { BASE_API, BASE_SERVER, DICOM_WEB } from "../../constants/constants";
import DataTable from "../../components/DataTable/WhiteHeaderTable";
import { get_image_list, set_id_image } from "../../../store/actions";

const { Option } = Select;
const cookies = new Cookies();
const { TabPane } = Tabs;

const dateFormat = "MM/DD/YYYY";
const { RangePicker } = DatePicker;
class ImageList extends Component {
  state = {
    isSidebar: false,
    image: "",
    imageList: [],
    oldImageList: [],
    imageUnique: [],
    imageLoader: 0,
    examData: "Please load an exam first",
    imageId: 0,
    examTop: 50,
    dateFilter: undefined,
    link: "",
    provider: undefined,
    type: undefined,
    patient_id: cookies.get("current_patient_id"),
    sort: {
      date: "",
      type: "",
    },
  };

  componentDidMount() {
    if (this.state.imageLoader === 0) {
      this.fetchImageList(true);
      this.setState({
        imageLoader: 1,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.image_list) {
      this.setState({
        imageList: nextProps.image_list,
        oldImageList: nextProps.image_list,
      });

      /**
       * Set the top margins
       */
      this.setState({
        examTop: 50 + nextProps.image_list.length * 42,
      });

      /**
       * Now create a unique list
       */
      let imageUnique = [];
      nextProps.image_list.map((item) => {
        if (!imageUnique.includes(item.exam_name)) {
          imageUnique.push(item.exam_name);
        }
      });
      this.setState({
        imageUnique: imageUnique,
      });
    }
  }

  loadExamAndViewer = (id, link, index) => {
    this.setState({
      imageId: id,
      link: link,
    });
    this.props.set_id_image(index);
  };

  handleSort = (sort) => {
    this.setState({ sort: { date: sort[0], type: sort[1] } });
  };

  /**
   * saveMessage
   */
  saveMessage = (e) => {
    e.preventDefault();
    fetch(BASE_API + "patients/exam", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        patient_id: 1,
        exam: this.state.examData,
        image_id: this.state.imageId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.loadExamAndViewer(this.state.imageId, this.state.link);
        alert("Exam Data saved!");
      })
      .catch((error) => {
        // console.log(error)
      });
    this.setState({
      examData: "Loaded",
    });
  };

  changeFilter = (date) => {
    this.setState({
      dateFilter: date ? date : undefined,
    });
    const param = {
      date: date && moment(date).format("MM/DD/YYYY"),
      exam_name: this.state.type,
      provider_id: this.state.provider,
    };
    this.fetchImageList(false, param);
  };

  handleChangeType = (data) => {
    this.setState({
      type: data,
    });
    const param = {
      date:
        this.state.dateFilter &&
        moment(this.state.dateFilter).format("MM/DD/YYYY"),
      exam_name: data,
      provider_id: this.state.provider,
    };
    this.fetchImageList(false, param);
  };

  handleChangeProvider = (data) => {
    this.setState({
      provider: data,
    });
    const param = {
      date:
        this.state.dateFilter &&
        moment(this.state.dateFilter).format("MM/DD/YYYY"),
      exam_name: this.state.type,
      provider_id: data,
    };
    this.fetchImageList(false, param);
  };

  refreshList = () => {
    this.setState({
      imageLoader: 0,
    });
  };
  /**
   * messageOnChange
   * @param data
   */
  messageOnChange = (data) => {
    this.setState({
      examData: data,
    });
  };

  fetchImageList = async (first, param) => {
    if (first) {
      await this.props.get_image_list(this.state.patient_id, null);
    } else {
      await this.props.get_image_list(this.state.patient_id, param);
    }
  };

  render() {
    const lengthOfEmpty = Math.max(
      Math.floor((window.innerHeight - 390) / 40),
      0
    );
    const emptyRowLength =
      this.state.imageList && lengthOfEmpty > this.state.imageList.length
        ? lengthOfEmpty - this.state.imageList.length
        : 0;

    return (
      <div className="resourceInfo d-flex" style={{ width: '100%', height: "calc(100vh - 350px)", minHeight: "25vh" }}>
        <DataTable
          title="patient_chart_imagelist"
          isNormal={true}
          disableDelete={true}
          labels={["Date", "Type"]}
          widths={["35%", "65%"]}
          showIcon={false}
          columns={[
            { sortable: true, key: "date" },
            { sortable: true, key: "type" },
          ]}
          handleSort={(sortOpt) => this.handleSort(sortOpt)}
          handleClickRow={(row, index) =>
            this.loadExamAndViewer(row.data.id, row.data.image_location, index)
          }
          rowData={(this.state.imageList || []).map((item, index) => ({
            key: `image-list-${item.id}-${index}`,
            data: item,
            date: item.date && moment(item.date).format("MM/DD/YYYY"),
            type: item.exam_name,
          }))}
          emptyRows={new Array(emptyRowLength).fill(0)}
          sorts={[this.state.sort.date, this.state.sort.type]}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  image_list: state.common.image_list,
  providers: state.common.providers,
});

const mapDispatchToProps = {
  get_image_list,
  set_id_image,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageList);
