import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Icon } from "antd";
import SubSideNav from "../../common/components/SubSideNav";
import MainContainer from "./Communication";
import "./style.scss";

export default class Communication extends Component {
  sideNavLinks = [
    {
      link: "/communication/faxing",
      label: "Faxing Module",
    },
    {
      link: "/communication/scanning",
      label: "Scanning Module",
    },
    {
      link: "/communication/patient-reminders",
      label: "Patient Reminders",
    },
  ];

  state = {
    isSideNav: true,
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Communication</title>
        <meta name="title" content="FAACT Vascular | Communication" />
      </Helmet>
    );
  }

  openSideNav = () => {
    this.setState({ isSideNav: true });
  };

  closeSideNav = () => {
    this.setState({ isSideNav: false });
  };

  getTitle = () => (
    <div className="sub-title">
      <Icon type="printer" />
      Communication
    </div>
  );

  render() {
    const { location } = this.props;
    const { isSideNav } = this.state;
    return (
      <div className="clinicWorkflow">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
        />
        <MainContainer location={location} isSideNav={isSideNav} />
      </div>
    );
  }
}
