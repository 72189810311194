import React, { useState, useEffect } from "react";
import {
  CloseIcon,
  SummaryEmptySVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import YesNo from "../../../../common/components/YesNo";
import {
  updateDetailProcedureData,
  addProcedure,
  getDetailProcedureData,
  setGlobalLoading,
} from "../../../../store/actions/common.js";
import { useDispatch, useSelector } from "react-redux";
import { validateSteps, validateField } from "../../../../services/validations";
import ToastMessage from "../../../../common/components/Toast";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import EditableFormInput from "../../../../common/components/EditableText/LoginFormInput";
import {
  getUltrasounds,
  deleteProcedureData,
} from "../../../../store/actions/common";
import { getProcedureData } from "../../../../store/actions";
const OldProcedureConditionSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
}, ref) => {
  const Providers = useSelector((state) => state.common.providers);
  const Locations = useSelector((state) => state.common.locations);
  const Ultrasounds = useSelector((state) => state.common.ultrasounds);
  const detailProcedureData = useSelector(
    (state) => state.common.detailProcedureData
  );
  const updatingProcedureData = useSelector(
    (state) => state.common.updatingProcedureData
  );
  const isUpdatedProcedureData = useSelector(
    (state) => state.common.isUpdatedProcedureData
  );
  const addProcedureData = useSelector(
    (state) => state.common.addProcedureData
  );
  const isAddProcedureData = useSelector(
    (state) => state.common.isAddProcedureData
  );
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [loading, setLoading] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [numberWeek, setNumberWeek] = useState("");
  // Providers
  const [inputProvider, setInputProvider] = useState("");
  const [listResultProvider, setListResultProvider] = useState([]);
  const [listSlectedProvider, setListSlectedProvider] = useState([]);
  const [isLoadingProvider, setIsLoadingProvider] = useState(false);
  const [isResultProvider, setIsResultProvider] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [isDeleteToastr, setIsDeleteToastr] = useState(false);

  //Ultrasounds
  const [inputUltrasound, setInputUltrasound] = useState("");
  const [listResultUltrasound, setListResultUltrasound] = useState([]);
  const [listSelectedUltrasound, setListSelectedUltrasound] = useState([]);
  const [isLoadingUltrasound, setIsLoadingUltrasound] = useState(false);
  const [isResultUltrasound, setIsResultUltrasound] = useState(false);
  const [isFirstCase, setIsFirstCase] = useState(0);
  const [range, setRange] = useState("");
  const [timeInterval, setTimeInterval] = useState("");
  const [titleProvider, setTitleProvider] = useState("");

  // Locations
  const [inputLocation, setInputLocation] = useState("");
  const [listResultLocation, setListResultLocation] = useState([]);
  const [listSlectedLocation, setListSlectedLocation] = useState([]);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [isResultLocation, setIsResultLocation] = useState(false);

  // add new procedure
  const [isAddForm, setOpenAddForm] = useState(false);

  const dispatch = useDispatch();

  const handleSearchProvider = (search) => {
    setInputProvider(search);
    setIsLoadingProvider(true);
    setIsResultProvider(true);
    const fillter =
      Providers &&
      Providers.filter(
        (provider) =>
          provider.value.toLowerCase().includes(search.toLowerCase()) &&
          search.length > 0
      );
    if (search.length === 0) {
      setIsLoadingProvider(false);
      setIsResultProvider(false);
    } else {
      setTimeout(() => {
        setListResultProvider(fillter);
        setIsLoadingProvider(false);
        setIsResultProvider(true);
      }, 600);
    }
  };

  const selectItemProvider = (item) => {
    if (item.length > 0) {
      listSlectedProvider.push(item);
      setInputProvider("");
      setIsResultProvider(false);
    }
  };

  const removeRowProvider = (index) => {
    setIsLoadingProvider(true);
    const tasks = listSlectedProvider;
    const idDex = tasks.findIndex((r) => r === index);
    tasks.splice(idDex, 1);
    setListSlectedProvider(tasks);
    setTimeout(() => {
      setIsLoadingProvider(false);
    }, 0);
  };

  const handleSearchLocation = (search) => {
    setInputLocation(search);
    setIsLoadingLocation(true);
    setIsResultLocation(true);
    const fillter =
      Locations &&
      Locations.filter(
        (location) =>
          location.value.toLowerCase().includes(search.toLowerCase()) &&
          search.length > 0
      );
    if (search.length === 0) {
      setIsLoadingLocation(false);
      setIsResultLocation(false);
    } else {
      setTimeout(() => {
        setListResultLocation(fillter);
        setIsLoadingLocation(false);
        setIsResultLocation(true);
      }, 600);
    }
  };

  const selectItemLocation = (item) => {
    if (item.length > 0) {
      listSlectedLocation.push(item);
      setInputLocation("");
      setIsResultLocation(false);
    }
  };

  const removeRowLocation = (index) => {
    setIsLoadingLocation(true);
    const tasks = listSlectedLocation;
    const idDex = tasks.findIndex((r) => r === index);
    tasks.splice(idDex, 1);
    setListSlectedLocation(tasks);
    setTimeout(() => {
      setIsLoadingLocation(false);
    }, 0);
  };

  const handleSearchUltrasound = (search) => {
    setInputUltrasound(search);
    setIsLoadingUltrasound(true);
    setIsResultUltrasound(true);
    const fillter =
      Ultrasounds &&
      Ultrasounds.filter(
        (ultrasound) =>
          ultrasound.name.toLowerCase().includes(search.toLowerCase()) &&
          search.length > 0
      );
    if (search.length === 0) {
      setIsLoadingUltrasound(false);
      setIsResultUltrasound(false);
    } else {
      setTimeout(() => {
        setListResultUltrasound(fillter);
        setIsLoadingUltrasound(false);
        setIsResultUltrasound(true);
      }, 600);
    }
  };

  const selectItemUltrasound = (item) => {
    if (item.length > 0) {
      listSelectedUltrasound.push(item);
      setInputUltrasound("");
      setIsResultUltrasound(false);
    }
  };

  const removeRowUltrasound = (index) => {
    setIsLoadingUltrasound(true);
    const tasks = listSelectedUltrasound;
    const idDex = tasks.findIndex((r) => r === index);
    tasks.splice(idDex, 1);
    setListSelectedUltrasound(tasks);
    setTimeout(() => {
      setIsLoadingUltrasound(false);
    }, 0);
  };

  const data = {
    providers: listSlectedProvider,
    locations: listSlectedLocation,
    ultrasounds: listSelectedUltrasound,
    is_first_case: isFirstCase,
    value: titleProvider,
    range_between_procedure: range,
    time_interval: timeInterval,
  };
  const mapData = [{ value: "value", type: "default" }];

  const saveAll = async () => {
    if (!validateSteps(data, mapData)) {
      setEnableError(true);
      return;
    }
    const DataProviders = [];
    for (const key in listSlectedProvider) {
      const checked = Providers.find(
        (r) => listSlectedProvider && r.value === listSlectedProvider[key]
      );
      DataProviders.push(checked && checked.id);
    }
    const DataLocations = [];
    for (const key in listSlectedLocation) {
      const checked = Locations.find(
        (r) => listSlectedLocation && r.value === listSlectedLocation[key]
      );
      DataLocations.push(checked && checked.id);
    }
    const DataUltrasounds = [];
    for (const key in listSelectedUltrasound) {
      const checked = Ultrasounds.find(
        (r) => listSelectedUltrasound && r.name === listSelectedUltrasound[key]
      );
      DataUltrasounds.push(checked && checked.id);
    }

    await dispatch(
      updateDetailProcedureData(detailProcedureData.id, {
        providers: DataProviders,
        locations: DataLocations,
        ultrasounds: DataUltrasounds,
        is_first_case: isFirstCase,
        value: titleProvider,
        sort: detailProcedureData.sort,
        side: detailProcedureData.side,
        range_between_procedure: range,
        time_interval: timeInterval,
        number_of_week_followup: numberWeek,
      })
    );
    await dispatch(getProcedureData());
    await dispatch(getDetailProcedureData(detailProcedureData.id));
  };

  const deleteProcedure = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    const deleteSuccess = await dispatch(
      deleteProcedureData(detailProcedureData.id)
    );
    const loadList = await dispatch(getProcedureData());
    if (deleteSuccess && loadList) {
      setLoading(false);
      setIsDeleteToastr(true);
      setTimeout(() => {
        setIsDeleteToastr(false);
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(updatingProcedureData);
    if (isUpdatedProcedureData) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  }, [updatingProcedureData, isUpdatedProcedureData]);

  useEffect(() => {
    setLoading(addProcedureData);
    if (isAddProcedureData) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  }, [addProcedureData, isAddProcedureData]);

  useEffect(() => {
    if (detailProcedureData) {
      setReset(true);
      setTitleProvider(detailProcedureData.value);
      setIsFirstCase(detailProcedureData.is_first_case);
      setRange(detailProcedureData.range_between_procedure);
      setTimeInterval(detailProcedureData.time_interval);
      setNumberWeek(detailProcedureData.number_of_week_followup);
      setListSlectedProvider(
        (detailProcedureData.providers || []).map((r) => r.value)
      );
      setListSlectedLocation(
        (detailProcedureData.locations || []).map((r) => r.value)
      );
      setListSelectedUltrasound(
        (detailProcedureData.ultrasound_types || []).map((r) => r.name)
      );
      setOpenAddForm(false);
    }
  }, [detailProcedureData]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  useEffect(() => {
    if (
      isShow &&
      detailProcedureData &&
      Object.keys(detailProcedureData).length
    ) {
      dispatch(setGlobalLoading(loading))
    }
  }, [loading, isShow, detailProcedureData]);

  if (
    !isShow ||
    !detailProcedureData ||
    !Object.keys(detailProcedureData).length
  ) {
    return null;
  }

  const openAddForm = () => {
    setTitleProvider(undefined);
    setIsFirstCase(0);
    setRange(undefined);
    setTimeInterval("");
    setNumberWeek("");
    setListSlectedProvider([]);
    setListSlectedLocation([]);
    setListSelectedUltrasound([]);
    setOpenAddForm(true);
    setEnableError(false);
  };

  const cancelAddForm = () => {
    setOpenAddForm(false);
    setEnableError(false);
    if (detailProcedureData) {
      setReset(true);
      setTitleProvider(detailProcedureData.value);
      setIsFirstCase(detailProcedureData.is_first_case);
      setRange(detailProcedureData.range_between_procedure);
      setTimeInterval(detailProcedureData.time_interval);
      setNumberWeek(detailProcedureData.number_of_week_followup);
      setListSlectedProvider(
        (detailProcedureData.providers || []).map((r) => r.value)
      );
      setListSlectedLocation(
        (detailProcedureData.locations || []).map((r) => r.value)
      );
      setListSelectedUltrasound(
        (detailProcedureData.ultrasound_types || []).map((r) => r.name)
      );
    }
  };

  const addNewProcedure = async () => {
    if (!validateSteps(data, mapData)) {
      setEnableError(true);
      return;
    }
    const DataProviders = [];
    for (const key in listSlectedProvider) {
      const checked = Providers.find(
        (r) => listSlectedProvider && r.value === listSlectedProvider[key]
      );
      DataProviders.push(checked && checked.id);
    }
    const DataLocations = [];
    for (const key in listSlectedLocation) {
      const checked = Locations.find(
        (r) => listSlectedLocation && r.value === listSlectedLocation[key]
      );
      DataLocations.push(checked && checked.id);
    }
    const DataUltrasounds = [];
    for (const key in listSelectedUltrasound) {
      const checked = Ultrasounds.find(
        (r) => listSelectedUltrasound && r.name === listSelectedUltrasound[key]
      );
      DataUltrasounds.push(checked && checked.id);
    }
    await dispatch(
      addProcedure({
        providers: DataProviders,
        locations: DataLocations,
        ultrasounds: DataUltrasounds,
        is_first_case: isFirstCase,
        value: titleProvider,
        sort: detailProcedureData.sort,
        side: detailProcedureData.side,
        range_between_procedure: range,
        time_interval: timeInterval,
      })
    );
    await dispatch(getProcedureData());
    await dispatch(getDetailProcedureData(detailProcedureData.id));
    setOpenAddForm(false);
  };

  return (
    <>
      <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
        {isToastr && (
          <ToastMessage
            type="Procedures"
            status="success"
            msg="Saved Successfully!"
          />
        )}
        {isDeleteToastr && (
          <ToastMessage
            type="Procedures"
            status="success"
            msg="Deleted Successfully!"
          />
        )}
        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          <SidebarTitle
            icon={<MaleSVG />}
            title={
              <span className="text-uppercase">
                {isAddForm ? "Add New Procedure" : detailProcedureData.value}
              </span>
            }
            subTitle=""
          />
          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
          >
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                reset={reset}
                label={"Name"}
                value={titleProvider}
                handleChange={(value) => {
                  setTitleProvider(value);
                }}
                required={true}
                enableErrorText={enableError}
                isError={validateField("text", titleProvider)}
                helperText={"Name is Required!"}
              />
            </div>
          </div>

          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
          >
            <div className="resourceInfo d-flex">
              <b>Procedure Providers</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <AutoCompleteComponent
                title="procedure-providers"
                placeholder="Search Providers"
                keyLabel={"value"}
                disableSearchIcon={false}
                loading={isLoadingProvider}
                isResult={isResultProvider}
                handleSearch={handleSearchProvider}
                query={inputProvider}
                selectItem={selectItemProvider}
                removeItem={removeRowProvider}
                rows={listSlectedProvider}
                options={listResultProvider}
              />
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "1rem" }}>
              <b>Procedure Locations</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <AutoCompleteComponent
                title="procedure-locations"
                placeholder="Search Procedure Locations"
                keyLabel={"value"}
                disableSearchIcon={false}
                loading={isLoadingLocation}
                isResult={isResultLocation}
                handleSearch={handleSearchLocation}
                query={inputLocation}
                selectItem={selectItemLocation}
                removeItem={removeRowLocation}
                rows={listSlectedLocation}
                options={listResultLocation}
              />
            </div>

            <div className="resourceInfo d-flex" style={{ marginTop: "1rem" }}>
              <b>Follow-Up Ultrasounds</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <AutoCompleteComponent
                title="follow-up-ultrasounds"
                placeholder="Search Ultrasounds"
                keyLabel={"name"}
                disableSearchIcon={false}
                loading={isLoadingUltrasound}
                isResult={isResultUltrasound}
                handleSearch={handleSearchUltrasound}
                query={inputUltrasound}
                selectItem={selectItemUltrasound}
                removeItem={removeRowUltrasound}
                rows={listSelectedUltrasound}
                options={listResultUltrasound}
              />
            </div>

            <div
              className="resourceInfo between d-flex"
              style={{ marginTop: "1rem" }}
            >
              <div style={{ maxWidth: "60%", whiteSpace: "unset" }}>
                Must be first operative case of the day
              </div>
              <YesNo
                option={isFirstCase}
                setOption={() => {
                  setIsFirstCase(!!isFirstCase ? 0 : 1);
                }}
              />
            </div>

            <div
              className="resourceInfo between d-flex required-field"
              style={{ marginTop: "1rem" }}
            >
              <EditableFormInput
                reset={reset}
                label={"Operation Duration"}
                value={timeInterval}
                handleChange={(value) => {
                  setTimeInterval(value);
                }}
                unit={"minutes"}
              />
            </div>

            <div
              className="resourceInfo between d-flex required-field"
              style={{ marginTop: "1rem" }}
            >
              <EditableMaterialText
                reset={reset}
                label={"Recovery time until another procedure is scheduled"}
                value={range}
                handleChange={(value) => {
                  setRange(value);
                }}
              />
            </div>
            <div
              className="resourceInfo between d-flex required-field"
              style={{ marginTop: "1rem" }}
            >
              <EditableMaterialText
                reset={reset}
                label={"Number of weeks for Follow-up"}
                value={numberWeek}
                handleChange={(value) => {
                  setNumberWeek(value);
                }}
              />
            </div>
          </div>
          {!isAddForm && (
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                flexWrap: "wrap",
                paddingBottom: '2rem'
              }}
            >
              <button
                className={"common-btn outlined-btn"}
                style={{ marginTop: "0.5rem" }}
                onClick={openAddForm}
              >
                New Procedure
              </button>
              <button
                style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}
                onClick={saveAll}
              >
                Save Record
              </button>
              <div
                style={{
                  justifyContent: "flex-end",
                  marginTop: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              >
                {!confirmDelete && (
                  <button
                    className="common-btn outlined-btn"
                    onClick={deleteProcedure}
                  >
                    Delete Procedure
                  </button>
                )}
                {confirmDelete && (
                  <>
                    <button
                      className="common-btn cancel-btn"
                      onClick={() => setConfirmDelete(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="common-btn delete-btn"
                      style={{
                        marginLeft: "0.5rem",
                        marginRight: 0,
                        backgroundColor: "#CA373B",
                      }}
                      onClick={deleteProcedure}
                    >
                      Confirm Delete ?
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {isAddForm && (
            <div className="actionContent" style={{ paddingBottom: '2rem' }}>
              <button
                className="cancel-btn"
                style={{ marginRight: "0.5rem" }}
                onClick={cancelAddForm}
              >
                cancel
              </button>
              <button onClick={addNewProcedure}>Save</button>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </>
  );
});

export default OldProcedureConditionSidebar;