import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const clinicProviderPatientSnapshotLoadInit = createActionThunk(
  AT.CLINIC_PATIENT_SNAPSHOT_LOAD_INIT,
  (patient_id) => API.clinicProviderPatientSnapshotLoadInit(patient_id)
);

// angio load init
export const angioPatientSnapshotLoadInit = createActionThunk(
  AT.ANGIO_PATIENT_SNAPSHOT_LOAD_INIT,
  (patient_id) => API.angioPatientSnapshotLoadInit(patient_id)
);

// angio load intra
export const load_intra_operative = createActionThunk(
  AT.LOAD_INTRA_OPERATIVE,
  (id) => API.load_intra_operative(id)
);

export const save_intra_operative = createActionThunk(
  AT.SAVE_INTRA_OPERATIVE,
  (id, params) => API.save_intra_operative(id, params)
);

//
export const clinicProviderPatientSnapshotSaveMedicalHistory = createActionThunk(
  AT.CLINIC_PATIENT_SNAPSHOT_SAVE_MEDICAL_HISTORY,
  (patient_id, data) =>
    API.clinicProviderPatientSnapshotSaveMedicalHistory(patient_id, data)
);

export const loadUltrasoundHistoryForCondition = createActionThunk(
    AT.LOAD_ULTRASOUND_HISTORY_FOR_CONDITION,
    (patient_id, condition_value) =>
        API.loadUltrasoundHistoryForCondition(patient_id, condition_value)
);