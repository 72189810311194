import React, { useState, useEffect, useMemo, useRef } from "react";
import { Input, DatePicker, Select, TimePicker } from "antd";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import { ExpandSVG, RedRequiredCircleSVG } from "../icons/SVGIcon";
import "./style.scss";

const { Option } = Select;
const { Password } = Input;
const numberMask = "999";

const getLocaleString = (str) => {
  if (!str) {
    return "0.00";
  }
  const num = parseFloat(str).toFixed(2);
  const decimals = num.split(".")[1];
  if (!decimals || decimals === "00") {
    return Math.round(parseFloat(str)).toLocaleString() + ".00";
  }
  if (decimals.charAt(1) === "0") {
    return (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + "0";
  }
  return (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
};
let timer = null;
const EditableInputWithDiv = ({
  label,
  value,
  handleChange,
  subLabel,
  isError,
  required,
  enableErrorText,
  helperText,
  fullWidth,
  noEditable,
  prefix,
  unit,
  reset,
  disabled,
}) => {
  const ref = useRef(null);
  const [error, setError] = useState(false);
  const [enableHelper, setEnableHelper] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [openTime, setOpenTime] = useState(false);
  const [topValue, setTopValue] = useState("");
  const [bottomValue, setBottomValue] = useState("");

  useEffect(() => {
    if (!isError) {
      setError(false);
      setEnableHelper(false);
    }
    if (enableErrorText && required && isError) {
      setEnableHelper(true);
      setError(true);
    }
    if (value && value !== inputValue) {
      const values = value.split("/");
      setTopValue(values[0]);
      setBottomValue(values[1]);
      setInputValue(value);
    }
  }, [value, isError, enableErrorText]);

  const enableBlur = () => {
    setFocus(false);
    onChange();
  };

  const onChange = () => {
    // let input_value = "";
    // if (!!topValue || !!bottomValue) {
    //   input_value = topValue + "/" + bottomValue;
    //   setInputValue(input_value);
    // }
    // if (topValue === "0" || bottomValue === "0") {
    //   input_value = topValue + "/" + bottomValue;
    //   setInputValue(input_value);
    // }
    // setInputValue(input_value);
    if (handleChange) {
      handleChange(inputValue);
    }
  };

  useEffect(() => {
    let input_value = "";
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (!!topValue || !!bottomValue) {
        input_value = topValue + "/" + bottomValue;
        setInputValue(input_value);
      }
      if (topValue === "0" || bottomValue === "0") {
        input_value = topValue + "/" + bottomValue;
        setInputValue(input_value);
      }
      if (handleChange) {
        handleChange(input_value);
      }
    }, 500);
  }, [topValue, bottomValue]);

  const lableContent = useMemo(() => {
    if (required) {
      return <span>{label}</span>;
    }
    return label;
  }, [required, label]);

  const errorText = useMemo(() => {
    if (enableHelper && helperText) {
      return (
        <span>
          <RedRequiredCircleSVG /> {helperText || ""}
        </span>
      );
    }
    return "";
  }, [enableHelper]);

  return (
    <div className={`editableText material ${fullWidth ? "fullWidth" : ""} `}>
      {noEditable && (
        <TextField
          variant="outlined"
          label={lableContent}
          value={value || ""}
          style={{ pointerEvents: 'none' }}
        />
      )}

      {!noEditable && (
        <>
          <div
            className={`MuiFormControl-root outlined-root MuiTextField-root ${errorText ? "error" : ""
              } ${focus ? "Mui-focused Mui-focused" : ""} `}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          >
            {(focus || !!inputValue) && (
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
                {lableContent}
              </label>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                position: "relative",
                paddingRight: "14px",
                backgroundColor: `${disabled ? "#c6c6c6" : ""}`
              }}
            >
              <InputMask
                mask={numberMask}
                value={topValue || ""}
                onChange={(e) => setTopValue(e.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={enableBlur}
                maskPlaceholder={null}
              >
                <TextField
                  className="no-border no-right-padding"
                  style={{ flex: 0, minWidth: "56px" }}
                  placeholder={!focus && !inputValue && subLabel}
                />
              </InputMask>
              <div
                className="no-border"
                style={{
                  flex: 0,
                  textAlign: "center",
                  minWidth: "16px",
                }}
              >
                {"/"}
              </div>
              <InputMask
                mask={numberMask}
                value={bottomValue || ""}
                onChange={(e) => setBottomValue(e.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={enableBlur}
                maskPlaceholder={null}
              >
                <TextField
                  className="no-border no-left-padding"
                  style={{ flex: 1 }}
                />
              </InputMask>
              <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                <p
                  className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary"
                  style={{ fontSize: "0.875rem" }}
                >
                  {unit}
                </p>
              </div>
            </div>
          </div>
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default EditableInputWithDiv;
