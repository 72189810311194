import React, { useState, useEffect, useRef } from "react";
import { Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import EducationSideBar from "./sidebar";
import { StaticResource } from "../PatientChart/constant";
import { BASE_API } from "../../constants/constants";
import { LeftCharvenSVG } from "../../components/icons/SVGIcon";
import Cookies from "universal-cookie";
import { get_filter_patient_document, setSidebarState } from "../../../store/actions";
import VideoImageViewer from "../PatientChart/VideoImageViewer";
import SidebarExtension from "../../components/SidebarExtension";

const cookies = new Cookies();
const { TabPane } = Tabs;
let imageTags = [];

const Education = (props) => {
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const selectedDocument = useSelector(
    (state) => state.clinicProvider.selectedDocument
  );

  const dispatch = useDispatch();
  const current_patient_id = cookies.get("current_patient_id");
  const [isSidebar, setIsSidebar] = useState(true);
  const [image, setImage] = useState("");

  useEffect(() => {
    let params = { patient_id: current_patient_id };
    params = {
      ...params,
      category: "",
      provider_id: "",
      created_at: "",
    };
    dispatch(get_filter_patient_document(params));

    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    } else if (patientResource) {
      setIsSidebar(true);
    }
  }, []);

  /**
   * getImage
   * @desc Retrieves the Image and displays it in the image tab
   * @param imageId
   */
  const getImage = (imageId) => {
    fetch(BASE_API + "imagetags/" + imageId, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        imageTags = response;
        setImage(
          "http://dashboard.vascularhealthclinics.org/get-image/1/" + imageId
        );
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const refreshImageList = () => {
    // this.refs.parent_charts_sidebar_ref.refreshImageList();
  };

  useEffect(() => {
  },[])

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };
  const sidebarRef = useRef();
  return (
    <div
      style={{
        display: !props.display
          ? "flex"
          : props.display === "flex"
          ? "flex"
          : "none",
      }}
      className="clinicWorkflow__main-section"
    >
      <div className="eventsContainer has-sub-header">
        <Tabs className="patientChart" type="card">
          <TabPane tab="Images" key="image-viewer-tab">
            <VideoImageViewer />
          </TabPane>
        </Tabs>
      </div>
      <EducationSideBar
        sidebarIcon={props.sidebarIcon}
        ref={sidebarRef}
        resource={StaticResource}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />

      <SidebarExtension startingWidth="calc(28.3% - 20px + 0.3rem)" sidebarToggle={toggleSidebar} sidebarReference={sidebarRef} sidebarState={isSidebar}/>
    </div>
  );
};

export default Education;
