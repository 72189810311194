import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import TopBar from "../../common/components/TopBar";
import CardBlock from "../../common/components/Card";
import ScrollWrapper from "../../common/components/ScrollBar";
import PatientSearch from "./components/PatientSearch";
import PatientDemographics from "./components/PatientDemographics";
import AppointmentManager from './components/AppointmentManager';
import OrganizationMessages from "./components/OrganizationWideMessage";
import QuickRegister from "./components/QuickRegister";

import {
  getPCPList,
  getReferralConditions,
  getReferredProviders,
  getProviders,
  getProcedureData,
} from "../../store/actions";
import "./style.scss";

const cookies = new Cookies();

class Dashboard extends Component {
  state = {
    isSidebar: false,
    isAuth: cookies.get("cvai-auth-token") ? true : false,
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular - Dashboard</title>
        <meta name="title" content="FAACT Vascular | Dashboard" />
      </Helmet>
    );
  }

  render() {
    const { isAuth } = this.state;
    if (!isAuth) {
      this.props.history.push("/login");
      return null;
    }

    return (
      <div className="homePage">
        {this.renderMetadata()}
        <TopBar title="Dashboard" />
        <div className="main">
          <ScrollWrapper css="no-padding x-hidden">
            <div
              className="mainContainer"
              style={{
                marginLeft: "-10px",
                paddingRight: "calc(5% - 20px)",
                paddingTop: "0",
                paddingBottom: "0",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <CardBlock
                title="Search Patients"
                varient="half"
                other="dashboard"
              >
                <PatientSearch />
              </CardBlock>
              <CardBlock
                title="Patient Demographics"
                varient="quarter"
                other="dashboard"
              >
                <PatientDemographics />
              </CardBlock>
              <CardBlock
                title="Organization Wide Messaging"
                varient="quarter"
                other="dashboard"
              >
                <OrganizationMessages />
              </CardBlock>
              <CardBlock
                title="Quick Register"
                varient="half"
                other="dashboard"
                contentPadding={'0.25rem'}
              >
                <QuickRegister />
              </CardBlock>

              <AppointmentManager />
            </div>
          </ScrollWrapper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.common,
});

const mapDispatchToProps = {
  getReferralConditions,
  getReferredProviders,
  getPCPList,
  getProviders,
  getProcedureData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
