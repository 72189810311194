import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";
import { Tooltip } from "antd";
import TopBar from "../../../common/components/SubTopBar";
import ClinicWorkflowSidebar from "../../../common/views/QuickRegisterSidebar";
import DayCalendar from "./DayCalendar";
import { LeftCharvenSVG } from "../../../common/components/icons/SVGIcon";

import {
  getLocations,
  getProviders,
  getPatientSchedules,
  getReferralConditions,
  chooseSelectedPatientResourceByRandom,
  chooseSelectedPatientResource,
  searchPatientSchedules,
  selectPatient,
  patient_insurances_id_insurances,
  get_patient_notes,
  getClinicNursingLocations
} from "../../../store/actions";
import SidebarExtension from "../../../common/components/SidebarExtension";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";

let timer = null;
const resources = [
  {
    id: "clinic",
    title: "Clinic",
  },
  {
    id: "us",
    title: "Ultrasound",
  },
];

const cookies = new Cookies();

class CheckIn extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  state = {
    isSidebar: false,
    events: [],
    resources: [],
    searchValue: "",
    selectedCategories: [],
    schedulesState: [],
    isSelectedPatient: false,
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = () => {
    this.props.getClinicNursingLocations();
    this.props.getProviders();
    this.loadInitialPaymentJs();
    this.props.getReferralConditions();
    this.handleEvents(this.state.filters);
  };

  loadInitialPaymentJs = () => {
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => { };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 1,
      only_procedures: 0,
      only_us: 1,
      only_angio: 0
    });
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: searchPatientName });
    } else {
      this.setState({ schedulesState: this.props.schedules });
    }
  };

  onSelectResource = (patient_id) => {
    cookies.set("current_patient_id", patient_id);
    this.props.chooseSelectedPatientResource(patient_id);
    this.props.selectPatient(patient_id);
    this.props.patient_insurances_id_insurances(patient_id);
    this.props.get_patient_notes(patient_id);
    this.handleSidebar();
  };

  onSelectAppointment = (appointment_id) => {
    cookies.set("current_appointment_id", appointment_id);
  };

  refreshCalendar = () => {
    const { date, location, provider_id } = this.state;
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 1,
      only_procedures: 0,
      only_us: 1,
      only_angio: 0
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.schedules) {
      this.setState({ schedulesState: nextProps.schedules });
    }
    const isSelectedPatientProps = nextProps.selectedPatientResource || {};
    if (Object.keys(isSelectedPatientProps).length > 0) {
      this.setState({
        isSelectedPatient: true,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  renderSideComponent = () => {
    const { selectedPatientResource, resourceCheck } = this.props;
    if (
      !resourceCheck ||
      !Object.keys(resourceCheck).length
    ) {
      return null;
    }

    const fullName = (
      resourceCheck.first_name +
      " " +
      resourceCheck.last_name
    ).toUpperCase();
    const age = resourceCheck.date_of_birth
      ? "(" +
      moment().diff(resourceCheck.date_of_birth, "years") +
      // " yo " +
      // (!!resourceCheck.gender
      //   ? resourceCheck.gender.toUpperCase()
      //   : "MALE") +
      ")"
      : "";
    const allergies = [];
    if (resourceCheck.allergies) {
      resourceCheck.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
            allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </>
          );
        allergies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >

        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "24px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allergies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck.no_drug_allergies ? 'NKDA' : 'Pending Data'}
              </span>
            </div>
          )}
          {allergies.length >= 1 && <br />}
          {allergies.length >= 1 && allergies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allergies.map((allergy) => allergy)}
            </div>
          )}
          {allergies.length >= 3 && (
            <Tooltip
              placement="bottomRight"
              title={resourceCheck.allergies
                .map((allergy) => allergy.allergy_name)
                .join(", ")}
            >
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allergies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>
      
        {resourceCheck.gender && resourceCheck.gender.toUpperCase() == 'MALE' ? 
          <MaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/> :
          <FemaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/>
        }
      </div>
    );
  };

  render() {
    const { isSidebar, isSelectedPatient } = this.state;
    const { selectedPatientResource } = this.props;
    const isResource =
      !selectedPatientResource || !Object.keys(selectedPatientResource).length;
    return (
      <div className="clinicWorkflow__main-page">
        <TopBar
          title="Check-In Module"
          isBarcode={isSelectedPatient}
          sideComponent={this.renderSideComponent()}
        />

        <ClinicWorkflowSidebar
          ref={this.sidebarRef}
          canCheckIn={true}
          resource={selectedPatientResource}
          isShow={isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          paymentForm={window.SqPaymentForm}
          refreshCalendar={this.refreshCalendar}
          referralConditions={this.props.referralConditions || []}
          isNormal={true}
        />
        {!isResource && (
          <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={this.sidebarRef} sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>
        )}
        <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
          <div className="clinicWorkflow__main-section">
            <div className="eventsContainer">
              <div className="calendarContainer">
                <DayCalendar
                  locations={this.props.locations || []}
                  providers={this.props.providers || []}
                  events={this.state.schedulesState || []}
                  slotProviders={this.props.scheduleProviders || []}
                  resources={resources}
                  loading={this.props.loadingSchedules || this.props.loadingResource}
                  fetchEvents={this.handleEvents}
                  onSearch={this.onSearch}
                  valueSearch={this.state.searchValue}
                  onSelectResource={this.onSelectResource}
                  calendarType="check-in"
                  onSelectAppointment={this.onSelectAppointment}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  locations: state.common.clinicNursingLocations,
  providers: state.common.providers,
  schedules: state.patientSchedule.schedules,
  scheduleProviders: state.patientSchedule.providers,
  loadingSchedules: state.patientSchedule.loadingSchedules,
  referralConditions: state.common.referralConditions,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  loadingResource: state.patientSchedule.loadingResource,
  resourceCheck: state.patientSnapshot.resource,
});

const mapDispatchToProps = {
  getClinicNursingLocations,
  getProviders,
  getPatientSchedules,
  chooseSelectedPatientResourceByRandom,
  chooseSelectedPatientResource,
  getReferralConditions,
  selectPatient,
  get_patient_notes,
  searchPatientSchedules,
  patient_insurances_id_insurances,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
