import React, { useState, useEffect, useRef } from "react";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import DetailsSidebar from "./DetailsSidebar";
import { useWindowSize } from "../../../../common/utils";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import { getLocations, getDetailLocations } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../common/components/Loading";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import DataTable from "../../../../common/components/DataTable/DataTable";

const Details = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const locations = useSelector((state) => state.common.locations);
  const detailLocations = useSelector((state) => state.common.detailLocations);
  const dispatch = useDispatch();
  const [activeIndex, setRow] = useState(-1);
  const [locationId, setLocationId] = useState(0);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const tableRows = locations ? locations : [];
  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = async (row, index)=> {
    setRow(index);
    if (locationId !== tableRows[index].id) {
      setLocationId(tableRows[index].id);
      await dispatch(getDetailLocations(tableRows[index].id));
      handleSidebar();
    }
  };

  const fetchData = async () => {
    await dispatch(getLocations());
    // await dispatch(getDetailLocations(1));
  };

  useEffect(() => {
    fetchData();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="location_details"
                labels={[
                  'Name',
                  'Angiography',
                  'Laboratory',
                  'Hyperbaric 02',
                  'Ultrasound',
                  'Wound Care'
                ]}
                widths={['20%','16%','16%','16%','16%','16%']}
                columns={[
                  { sortable: false, key: 'name' },
                  { sortable: false, key: 'angio' },
                  { sortable: false, key: 'lab' },
                  { sortable: false, key: 'hyperbaric' },
                  { sortable: false, key: 'ultrasound' },
                  { sortable: false, key: 'wound' },
                ]}
                rowData={tableRows.map((row, index) => {
                  return {
                    name: row.value,
                    angio: row.angiography === -1
                            ? ""
                            : row.angiography
                            ? "Yes"
                            : "No",
                    lab: row.laboratory === -1
                            ? ""
                            : row.laboratory
                            ? "Yes"
                            : "No",
                    hyperbaric: row.hyperbaric === -1
                                  ? ""
                                  : row.hyperbaric
                                  ? "Yes"
                                  : "No",
                    ultrasound: row.ultrasound === -1
                                  ? ""
                                  : row.ultrasound
                                  ? "Yes"
                                  : "No",
                    wound: row.wound_care === -1
                            ? ""
                            : row.wound_care
                            ? "Yes"
                            : "No"
                  }
                })}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {detailLocations && activeIndex >= 0 && (
        <DetailsSidebar
          ref={sidebarRef}
          resource={
            activeIndex >= 0 && detailLocations ? detailLocations : undefined
          }
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
      {detailLocations && activeIndex >= 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Details;
