import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { StaticResource, DashboardRows } from "../../constant";
import Loading from "../../../../common/components/Loading";
import InvoicesSidebar from "./InvoicesSidebar";
import InvoicesFilter from "./InvoicesFilter";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import {
  getReceivingOrders,
  setInventoryData,
} from "../../../../store/actions";
import moment from "moment";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import DataTable from "../../../../common/components/DataTable/DataTable";

const filterDataByOption = (data, option) => {
  const { amount, company, startDate, endDate } = option;
  let filteredData = data;
  if (!amount && (!company || !company.length) && !startDate && !endDate) {
    return filteredData;
  }
  if (endDate) {
    filteredData = filteredData.filter(
      (d) => d.updated_at && moment(endDate).diff(moment(d.updated_at)) >= 0
    );
  }
  if (startDate) {
    filteredData = filteredData.filter(
      (d) => d.updated_at && moment(d.updated_at).diff(moment(startDate)) >= 0
    );
  }
  if (amount) {
    filteredData = filteredData.filter(
      (d) => d.purchase_number * d.cost >= +amount
    );
  }
  if (company && company.length) {
    filteredData = filteredData.filter(
      (d) => d.brand && company.includes(d.brand)
    );
  }
  return filteredData;
};

const Invoices = ({ data, title, loading }) => {
  const receivings = useSelector((state) => state.inventory.receivings);
  const receivingOrder = useSelector((state) => state.inventory.receivingOrder);
  const filterOption = useSelector((state) => state.inventory.orderFilter);

  const dispatch = useDispatch();

  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const tableRows = useMemo(() => {
    if (receivings && receivings.length) {
      return filterDataByOption(receivings, filterOption);
    }
    return [];
  }, [receivings, filterOption]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData("receivingOrder", rowData[index]));
    handleSidebar();
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const onScroll = (values) => {
    if (
      values.scrollTop > values.scrollHeight - values.clientHeight - 80 &&
      offset < tableRows.length
    ) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <InvoicesFilter />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="return_credit"
                loadMore={onScroll}
                defaultActive={-1}
                hideHeader={true}
                disableDelete={true}
                labels={['Name', 'Date', 'Amount']}
                widths={['56%', '22%', '22%']}
                columns={[
                  { sortable: false, key: 'name' },
                  { sortable: false, key: 'date' },
                  { sortable: false, key: 'amount' },
                ]}
                rowData={rowData.map((row, index) => {
                  return { 
                    name: (
                      <div 
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ paddingLeft: "0.75rem" }} >{row.name || ''}</p>
                      </div>
                    ),
                    date: row.date
                    ? moment(row.date).format("MM/DD/YYYY")
                    : row.updated_at
                    ? moment(row.updated_at).format("MM/DD/YYYY")
                    : "",
                    amount: "$" + Math.round(row.purchase_number * row.cost * 100) / 100,
                  }
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {receivingOrder && activeIndex >= 0 && (
        <InvoicesSidebar
          ref={sidebarRef}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
      {receivingOrder && activeIndex >= 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Invoices;
