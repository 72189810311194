import React, { useState, useEffect, useCallback, forwardRef } from "react";
import moment from "moment";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import LoginFormInput from "../../../../common/components/EditableText/LoginFormInput";
import {
  updatePCM,
  getPCMDetail,
  setGlobalLoading, exportPCMOverview,
} from "../../../../store/actions";
import ToastMessage from "../../../../common/components/Toast";
import CptCodeDataTable from "../../../../common/components/CptCodeDataTable";
import BillingNote from "../../../../common/components/BillingNote";
import ButtonDropdown from "../../../../common/components/EditableText/Dropdown";

const OverviewSidebar = React.forwardRef(
  (
    {
      resource,
      isShow,
      handleCloseSidebar,
      detailPCM,
      updateState,
      moveState,
      updateLocal,
      iconOverviewStatus,
    },
    ref
  ) => {
    const [reset, setReset] = useState(true);
    const [enableError, setEnableError] = useState(false);
    const [direct, setDirect] = useState("");
    const [payment, setPayment] = useState("");
    const [cm, setCm] = useState("");
    const [loading, setLoading] = useState(false);
    const [isToastr, setIsToastr] = useState(false);
    const [first, setFirst] = useState(false);
    const [secon, setSecon] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setFirst(false);
    }, [detailPCM.id]);

    useEffect(() => {
      if (detailPCM && detailPCM.direct_cost) {
        setDirect(detailPCM.direct_cost);
        updateState(detailPCM, {
          direct: detailPCM.direct_cost,
        });
      }
      if (detailPCM && detailPCM.payment) {
        setPayment(detailPCM.payment);
        updateState(detailPCM, {
          payment: detailPCM.payment,
        });
        setReset(true);
      }
      if (detailPCM && detailPCM.contribution_margin) {
        setCm(detailPCM.contribution_margin);
        updateState(detailPCM, {
          contribution_margin: detailPCM.contribution_margin,
        });
        setReset(true);
      }
    }, [detailPCM]);

    useEffect(() => {
      if (reset) {
        setReset(false);
        setEnableError(false);
      }
    }, [reset]);

    // const saveAll = async () => {
    //   setSecon(true);
    //   if (secon) {
    //     setLoading(true);
    //     const saveSuccess = await dispatch(
    //       updatePCM(detailPCM.id, {
    //         // direct_cost: direct,
    //     // total_payment: payment,
    //     // type_endo_nonendo: "nonendo",
    //         // icon_status: "correction"
    //       })
    //     );
    //     const getDetailSuccess = await dispatch(getPCMDetail(detailPCM.id));
    //     //const getListSuccess = await dispatch(getPCM(1, "endo"));
    //     if (saveSuccess && getDetailSuccess) {
    //       setLoading(false);
    //       setIsToastr(true);
    //       setTimeout(() => {
    //         setIsToastr(false);
    //       }, 2000);
    //     } else {
    //       setLoading(false);
    //     }
    //   }
    // };

    const changePayment = useCallback(
      debounce((value) => setPayment(value), 400),
      []
    );
    const changeDirect = useCallback(
      debounce((value) => setDirect(value), 400),
      []
    );

    const sendExport = async () => {
      if (resource) {
        const result = await dispatch(
            exportPCMOverview(iconOverviewStatus)
        );
        window.open(result.url, "_self");
      }
    };

    const descFormat = (desc) => {
      let cutDesc = desc.slice(1);
      let firstChar = desc[0];

      return firstChar.toUpperCase() + cutDesc.toLowerCase();
    }

    // useEffect(() => {
    //   setFirst(true);
    //   if (first) {
    //     saveAll();
    //   }
    // }, [direct, payment]);

    const saveType = async (type) => {
      setLoading(true);

      const params = {
        icon_overview_status: type,
        // direct_cost: direct,
        // total_payment: payment,
        // type_endo_nonendo: "nonendo",
      };
      const saveSuccess = await dispatch(updatePCM(detailPCM.id, params));
      if (saveSuccess) {
        if (updateLocal) {
          updateLocal({ id: detailPCM.id, status: type });
        }
        detailPCM.icon_overview_status = type;
        setLoading(false);
        setIsToastr(true);
        // moveState(detailPCM.id, params);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      } else {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (isShow && detailPCM && Object.keys(detailPCM).length) {
        dispatch(setGlobalLoading(loading));
      }
    }, [loading, isShow, detailPCM]);

    if (!isShow || !detailPCM || !Object.keys(detailPCM).length) {
      return null;
    }
  
    return (
      <>
        <div
          ref={ref}
          className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
        >
          {isToastr && (
            <ToastMessage
              type="Endovascular"
              status="success"
              msg="Saved Successfully!"
            />
          )}
          <ScrollWrapper>
            <div className="headerContainer">
              <button onClick={handleCloseSidebar}>
                <CloseIcon />
              </button>
            </div>
            <SidebarTitle
              icon={<MaleSVG />}
              title={<span className="text-uppercase">PATIENT DASHBOARD</span>}
              subTitle=""
            />

            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
              }}
            >
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Provider"}
                  value={detailPCM.provider_name}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Date of Service"}
                  value={
                    detailPCM.date_of_procedure &&
                    moment(detailPCM.date_of_procedure).format("MM/DD/YYYY")
                  }
                  type={"date"}
                  noEditable={true}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Date of Billing"}
                  value={
                    detailPCM.date_of_billing &&
                    moment(detailPCM.date_of_billing).format("MM/DD/YYYY")
                  }
                  type={"date"}
                  noEditable={true}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                    label={"Primary Insurance"}
                    value={
                      detailPCM.primary_insurance
                    }
                    type={"text"}
                    noEditable={true}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                    label={"Primary Insurance Number"}
                    value={
                      detailPCM.primary_insurance_number
                    }
                    type={"text"}
                    noEditable={true}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                    label={"Secondary Insurance"}
                    value={
                      detailPCM.secondary_insurance
                    }
                    type={"text"}
                    noEditable={true}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                    label={"Secondary Insurance Number"}
                    value={
                      detailPCM.secondary_insurance_number
                    }
                    type={"text"}
                    noEditable={true}
                />
              </div>
              {/* <div className="resourceInfo d-flex">
                <EditableMaterialText
                  reset={reset}
                  label={"Payment"}
                  value={payment}
                  handleChange={changePayment}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditableMaterialText
                  reset={reset}
                  label={"Direct Costs"}
                  handleChange={changeDirect}
                  value={direct}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditableMaterialText
                  reset={reset}
                  label={"Contribution Margin"}
                  value={detailPCM.contribution_amount}
                />
              </div> */}
              <div
                className="resourceInfo d-flex"
                style={{ marginTop: "0.5rem" }}
              >
                <CptCodeDataTable title="CPT" data={detailPCM.cpt_code.map((data) => {
                  return {
                    code: data.code,
                    short_desc: descFormat(data.short_desc)
                  }
                })} />
              </div>
              <hr />
            </div>
            
            {detailPCM ? (
                <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                }}
              >

                <BillingNote detailPCM={detailPCM} saveType={saveType}></BillingNote>
              </div>
            ) : null}

            <div
              className="actionContent"
              style={{ marginTop: "2rem", paddingRight: "0" }}
            >
              <ButtonDropdown
                options={[
                  {
                    display: 'Correction Team',
                    value: 'correction'
                  },
                  {
                    display: 'Research Team',
                    value: 'research'
                  },
                  {
                    display: 'Payment Posting',
                    value: 'payment_posting'
                  },
                  {
                    display: 'Adjust Off',
                    value: 'adjust_off'
                  },
                ]}
                onSelect={(val) => {
                  saveType(val);
                }}
                label="Assignment"
              />
              <button
                  className={"button-fix"}
                  onClick={() => {
                    sendExport()
                  }}
                  style={{ marginRight: "10px" }}
              >
                Export To Excel
              </button>
            </div>
          </ScrollWrapper>
        </div>
      </>
    );
  }
);

export default OverviewSidebar;
