import React from "react";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { Icon } from "antd";

const EvaluationsSidebar = React.forwardRef(({
  resource,
  isShow,
  evaluationsTagertDetail,
  handleCloseSidebar,
  currentUserInfo,
}, ref) => {
  if (!isShow || !currentUserInfo || !evaluationsTagertDetail) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper css="x-hidden">
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={<Icon type="bank" theme="twoTone" twoToneColor="#9F1853"/>}
          title={(
            <span className="text-uppercase">
            {
              currentUserInfo.name +
              (currentUserInfo.position && "(" + currentUserInfo.position + ")")
            }
            </span>
          )}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
        >
          <div className="resourceInfo d-flex" style={{ marginBottom: "1rem" }}>
            <div>
              Employee evaluations are a means to monitor progress and methods
              of evaluations within the organization. Employee evaluations are
              utilized for promotion and assessment of raises or bonuses.
              Improvements in employment metrics are a key determinent of raises
              and bonuses and will be directly utilized on a calculation to
              determine end of year increases. Raises and bonuses are not
              guaranteed. Performance improvement and evaluations are reset at
              the beginning of the year with respect to raises and bonuses.
            </div>
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Summary"
              value={evaluationsTagertDetail.summary}
              type="area"
              minRows={8}
              fullWidth
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Facts"
              value={evaluationsTagertDetail.facts}
              type="area"
              minRows={8}
              fullWidth
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Corrective Actions"
              value={evaluationsTagertDetail.corrective_actions}
              type="area"
              minRows={8}
              fullWidth
            />
          </div>
        </div>

        <div
          className="actionContent"
          style={{ marginTop: "2rem", paddingRight: "1rem", paddingBottom: '2rem' }}
        >
          <button>Save</button>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default EvaluationsSidebar;
