import React, { useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import moment from "moment";
import DeleteIconButton from "../../../common/components/icons/DeleteIconButton";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientSearchHistory,
  getGlobalMessages,
  addGlobalMessage,
  deleteGlobalMessage,
  set_default_toast,
} from "../../../store/actions";
import ToastMessage from "../../../common/components/Toast";

const { TextArea } = Input;

const OrganizationMessages = () => {
  const messages = useSelector((state) => state.dashboard.messages);
  const addedGlobalMessage = useSelector(
    (state) => state.dashboard.addedGlobalMessage
  );
  const toastWideMess = useSelector((state) => state.dashboard.toastWideMess);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const [status, setStatus] = useState(true);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [messageToast, setMessageToast] = useState(null);
  const [isToastr, setIsToastr] = useState(false);
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    dispatch(getGlobalMessages());
  }, []);

  useEffect(() => {
    if (toastWideMess) {
      setIsToastr(true);
      setTimeout(() => {
        dispatch(set_default_toast());
        setIsToastr(false);
      }, 2000);
    }
  }, [toastWideMess]);

  const tableRows = useMemo(() => {
    if (messages) {
      return messages;
    }
    return [];
  }, [messages]);

  const addNewMessage = async () => {
    await dispatch(addGlobalMessage(message));
    await dispatch(getGlobalMessages());
    setMessage("");
    setMessageToast("Add Message Successfully!");
  };

  const deleteMesage = async (id) => {
    if (selected === -1) {
      setSelected(id);
      return;
    }
    await dispatch(deleteGlobalMessage(id));
    setSelected(-1);
    setMessageToast("Delete Message Successfully!");
  };

  const setGlobalMessage = (e) => {
    setMessage(e.target.value);
  };

  const showToast = () => {
    return (
      <ToastMessage
        type="Organization message"
        status={status ? "success" : "failed"}
        msg={messageToast ? messageToast : "Saved Successfully!"}
      />
    );
  };

  return (
    <div className="QuickLinks" style={{ width: "100%", height: "100%" }}>
      {isToastr && !errorRest && showToast()}

      <div className="fullContainer" style={{ padding: 0 }}>
        <div className="messageContainer">
          <div className="messagesSection">
            <ScrollWrapper css="no-padding x-hidden">
              {(tableRows || []).map((row, index) => (
                <div
                  className="messageInfo"
                  key={`resultTable-${index}`}
                  style={{
                    position: "relative",
                    paddingRight: "24px",
                    paddingBottom: "16px",
                    marginBottom: "14px",
                    borderBottom: "1px solid #dfe3e6",
                  }}
                >
                  {currentUserInfo.is_admin &&
                  currentUserInfo.is_admin === 1 ? (
                    <DeleteIconButton
                      selected={selected === row.id}
                      handleClick={() => deleteMesage(row.id)}
                      icon={'can'}
                      extraStyle={{
                        position: "absolute",
                        right: 0,
                        top: "1px",
                      }}
                    />
                  ) : null}
                  <b>
                    {row.user && row.user.name} (
                    {moment(row.created_at).format("MM/D/YYYY hh:mm A")})
                  </b>
                  <p>{row.message}</p>
                </div>
              ))}
            </ScrollWrapper>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "45%",
            minHeight: "156px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
          className="messageTextArea"
        >
          <TextArea
            placeholder={"Enter Organization Wide Messaging"}
            autoSize={{ minRows: 3 }}
            style={{
              marginTop: "1rem",
              flex: 1,
              backgroundColor: "#f4f7fb",
            }}
            onChange={setGlobalMessage}
            value={message}
          />
          <div
            className="fullContainer__actions"
            style={{ paddingTop: "1rem" }}
          >
            <button className="common-btn blue-btn" onClick={addNewMessage}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationMessages;
