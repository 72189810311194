import React, { useState, useEffect, useRef } from "react";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import CardWhiteBlock from "../../../../common/components/CardWhite";
import SlackEditor from "../../../../common/components/SlackEditor";
import { VideoFile, ImageFile } from "../../../../common/components/MediaFiles";
import { Icon, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUserMedia,
  createSupportModule,
  removeUserMedia,
  updateSupportModule,
  updateSupportModuleHistory,
  deleteSupportModule,
  closeSupportModule,
  setActiveSupportTicket,
} from "../../../../store/actions";
import { EditorState, convertToRaw } from "draft-js";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { convertToLongNumber, useWindowSize } from "../../../../common/utils";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import PusherConnector from "../../../../common/components/PusherScan/pusherConnector";

const getListStyle = (isDraggingOver) => ({
  border: isDraggingOver ? "2px dotted #0f62fe" : "",
  width: "100%",
  filter: isDraggingOver ? "blur(10px)" : "",
});

const DetailSidebarComponent = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  currentUserInfo,
  mediaFileDoubleClicked,
  onCreatedModule,
},ref) => {
  const dispatch = new useDispatch();
  const userMedia = useSelector((state) => state.userMedia);
  const [isNew, setIsNew] = useState(true);
  const [subject, setSubject] = useState("");
  const [comment, setComment] = useState();
  const [history, setHistory] = useState(null);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [status, setStatus] = useState(0);
  const [isDev, setIsDev] = useState(0);
  const [user, setUser] = useState(null);
  const [resetEditor, setResetEditor] = useState(false);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [loadingMediaFiles, setLoadingMediaFiles] = useState(true);
  const [pusherChannel, setPusherChannel] = useState(false);
  const [pusherConnector, setPusherConnnector] = useState();

  const newMessage = async callback => {
    const data = await callback;
    console.dir(data);
    if (data.is_dev != 1 && data.support_id == resource.id) {
      setHistory([
        ...history,
        data
      ]);
    }
  }
  
  useEffect(() => {
    const pusher = new PusherConnector();
    var channelName = 'support-messages';
    const channel = pusher.createChannel(channelName);
    setPusherConnnector(pusher);  
    setPusherChannel(channel);
  }, [])
  
  useEffect(() => {
    if (pusherChannel && pusherChannel.bind && !!resource && pusherConnector) {
      var eventName = 'new-message';
      pusherConnector.unbindEvent(pusherChannel, eventName);
      pusherConnector.bindEvent(pusherChannel, eventName, newMessage);
    }
  }, [resource, history, pusherChannel, pusherConnector])

  useEffect(() => {
    if (!!currentUserInfo.id) {
      const { role_id } = currentUserInfo;
      setIsDev(currentUserInfo.is_support == true ? 1 : 0);
      dispatch(getAllUserMedia(currentUserInfo.id));
    }
  }, [currentUserInfo.id]);

  useEffect(() => {
    if (!!resource) {
      setSubject(resource.subject);
      setComment();
      setHistory(resource.history);
      setStatus(resource.status);
      dispatch(setActiveSupportTicket(resource.id));
      setIsNew(false);
      setResetEditor(true);
      setUser(resource.user);
    }

    return () => {
      dispatch(setActiveSupportTicket(null));
    }
  }, [resource]);

  useEffect(() => {
    setLoadingMediaFiles(userMedia.loading);
    if (!userMedia.loading) {
      setMediaFiles(userMedia.media);
    }
  }, [userMedia]);

  if (!isShow) {
    return null;
  }

  const handleSubmit = async () => {
    if (
      currentUserInfo.id &&
      subject &&
      (comment || attachedFiles.length > 0)
    ) {
      const payload = {
        user_id: currentUserInfo.id,
        status: status, // 0 => in progress
        subject: subject,
        is_dev: isDev,
        comment: comment,
        attached_files: JSON.stringify(attachedFiles),
      };
      if (!!selectedHistory) {
        await dispatch(
          updateSupportModuleHistory(selectedHistory, { comment })
        );
      } else {
        if (isNew) {
          if (!comment) {
            payload.comment = JSON.stringify(
              convertToRaw(EditorState.createEmpty().getCurrentContent())
            );
          }
          onCreatedModule();
          await dispatch(createSupportModule(payload));
        } else {
          payload.type = "update";
          if (!comment) {
            payload.comment = JSON.stringify(
              convertToRaw(EditorState.createEmpty().getCurrentContent())
            );
          }
          await dispatch(updateSupportModule(resource.id, payload));
        }
      }
      setSelectedHistory(null);
      setAttachedFiles([]);
    } else {
      console.log("empty string");
    }
  };

  const handleEditorChange = (editorState) => {
    let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    setComment(data);
    setResetEditor(false);
    setUser(currentUserInfo);
  };

  const handleDragEnd = (result) => {
    const { destination } = result;
    if (!destination) return;
    if (result.destination.droppableId === "droppable_input") {
      const item = JSON.parse(result.draggableId);
      const tempFiles = attachedFiles.filter((e) => e.id !== item.id);
      setAttachedFiles([...tempFiles, item]);
    }
  };

  const handleRemoveAttachedFile = (value) => {
    const temp = attachedFiles.filter((e) => e.id !== value);
    setAttachedFiles(temp);
  };

  const handleSelecedHistory = (id) => setSelectedHistory(id);

  const handleCloseSupportModule = () => {
    const payload = {
      status: 1,
      type: "close",
    };

    dispatch(closeSupportModule(resource.id, payload));
  };

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <div className="resourceInfo d-flex" style={{ padding: "0 24px" }}>
          <SidebarTitle
            icon={<Icon type="bug" />}
            title={
              <span className="text-uppercase">
                {!isNew ? `${subject} (ID: ` + convertToLongNumber(resource.id) + ')': "New Ticket"}
              </span>
            }
            subTitle=""
          />
        </div>
        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
          >
            <div
              className="resourceInfo d-flex"
              style={{ marginTop: 0, marginBottom: "0.5rem" }}
            >
              <Droppable droppableId="droppable_input">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {snapshot.isDraggingOver && <h1>Test</h1>}

                    <SlackEditor
                      inputBoxTitle="Reply"
                      onEditorChange={handleEditorChange}
                      historyData={history}
                      userInfo={user}
                      onSelectedHistory={handleSelecedHistory}
                      onSubmit={handleSubmit}
                      isReset={resetEditor}
                      mediaFiles={attachedFiles}
                      onRemoveBtnClicked={handleRemoveAttachedFile}
                      fileDoubleClicked={mediaFileDoubleClicked}
                    />
                  </div>
                )}
              </Droppable>
            </div>
          </div>

          <div
            className="actionContent"
            style={{
              margin: 0,
              display: "flex",
            }}
          >
            <button
              className="common-btn delete-btn"
              onClick={handleCloseSupportModule}
              style={{
                marginRight: '-3px'
              }}
            >
              Close Support Case
            </button>
          </div>
        </ScrollWrapper>

      </DragDropContext>
    </div>
  );
});

export default DetailSidebarComponent;
