import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Select, DatePicker } from "antd";
import { useDebounce } from "use-lodash-debounce";
import moment from "moment";
import {
  ExpandSVG,
  SearchSVG,
} from "../../../../common/components/icons/SVGIcon";
import {
  getInventoryProducts,
  getInventoryLocations,
  getNarcoticInventories,
  setInventoryData,
} from "../../../../store/actions";

const { Search } = Input;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const NarcoticFilter = ({ locations }) => {
  const productNumbers = useSelector(
    (state) => state.inventory.inventoryProducts
  );
  const filterOption = useSelector((state) => state.inventory.inventoryFilter);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const debounce = useDebounce(search, 400);

  useEffect(() => {
    dispatch(getInventoryProducts());
    dispatch(getNarcoticInventories(""));
    dispatch(
      setInventoryData("inventoryFilter", {
        location: undefined,
        quantity: undefined,
        startDate: undefined,
        endDate: undefined,
      })
    );
    dispatch(setInventoryData("inventory", null));
  }, []);

  useEffect(() => {
    dispatch(getNarcoticInventories(debounce));
  }, [debounce]);

  const changeSearch = (value) => {
    setSearch(value);
  };

  const changeFilter = (type) => (value) => {
    const newFilter = { ...filterOption, [type]: value };
    dispatch(setInventoryData("inventoryFilter", newFilter));
  };

  const changeFilterDate = (type) => (date, dateString) => {
    const newFilter = { ...filterOption, [type]: dateString };
    dispatch(setInventoryData("inventoryFilter", newFilter));
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer" style={{ width: "44%" }}>
        <div className="searchField">
          <Search
            placeholder="Search"
            onChange={(e) => changeSearch(e.target.value)}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer" style={{ width: "56%" }}>
        <div className="statusFilter" style={{ flex: 1 }}>
          <DatePicker
            dateFormat={dateFormat}
            placeholder="Start Date"
            suffixIcon={<ExpandSVG />}
            style={{
              width: "100%",
            }}
            onChange={changeFilterDate("startDate")}
          />
        </div>
        <div className="statusFilter" style={{ flex: 1 }}>
          <DatePicker
            dateFormat={dateFormat}
            placeholder="End Date"
            suffixIcon={<ExpandSVG />}
            style={{
              width: "100%",
            }}
            onChange={changeFilterDate("endDate")}
          />
        </div>
        <div
          className="statusFilter"
          style={{
            width: "120px",
            maxWidth: "120px",
            minWidth: "120px",
          }}
        >
          <Select
            placeholder="Quantity..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.quantity}
            onChange={changeFilter("quantity")}
          >
            <Option value="1">+1</Option>
            <Option value="5">+5</Option>
            <Option value="10">+10</Option>
            <Option value="20">+20</Option>
          </Select>
        </div>
        <div
          className="statusFilter"
          style={{
            width: "120px",
            maxWidth: "120px",
            minWidth: "120px",
          }}
        >
          <Select
            placeholder="Location..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.location}
            onChange={changeFilter("location")}
          >
            {(locations || []).map((loc, index) => (
              <Option key={`location-${index}`} value={loc.value}>
                {loc.value}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default NarcoticFilter;
