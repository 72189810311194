import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

import ClinicWorkflowSidebar from "../ClinicWorkflowSidebar";
import DayCalendarCheck from "../DayCalendarCheck";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";

import {
  getVhcLocations,
  getProviders,
  getPatientSchedules,
  chooseSelectedPatientResourceByRandom,
  choosePatientOnSchedules,
  searchPatientSchedules,
  angioPatientSnapshotLoadInit,
  load_intra_operative,
  load_procedurals,
  detail_angio_type,
  set_data_template_review,
  get_patient_notes,
  selectPatient,
  get_cpt_code_review,
} from "../../../../store/actions";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";

const resources = [
  {
    id: "angio",
    title: "OBL Procedure",
  },
];

const cookies = new Cookies();

class CheckIn extends Component {
  state = {
    isSidebar: false,
    disable: true,
    events: [],
    resources: [],
    selectedCategories: [],
    searchValue: "",
    schedulesState: [],
    isSelectedPatient: false,
    block: true,
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = () => {
    this.props.getVhcLocations();
    this.props.getProviders();
    this.loadInitialPaymentJs();
    this.handleEvents(this.state.filters);
  };

  loadInitialPaymentJs = () => {
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    if (!this.state.block) {
      this.props.getPatientSchedules({
        date,
        location,
        provider_id,
        patient_id: "",
        only_clinics: 0,
        only_procedures: 1,
        only_us: 0,
        only_angio: 1,
      });
    }
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: [...searchPatientName] });
    } else {
      this.setState({ schedulesState: [...(this.props.schedules || [])] });
    }
  };

  onSelectResource = async (patient_id) => {
    const { goNextTab } = this.props;
    cookies.set("current_patient_id", patient_id);
    await this.props.get_patient_notes(patient_id);
    await this.props.selectPatient(patient_id);
    await this.props.choosePatientOnSchedules(patient_id);
    goNextTab();
  };

  onSelectAppointment = async (appointment_id) => {
    await cookies.set("current_appointment_id", appointment_id);
    let data = [];
    const checkid = await this.props.load_intra_operative(appointment_id);
    await this.props.get_cpt_code_review(appointment_id);
    await this.props.set_data_template_review([]);
    if (checkid && checkid.angio_procedures) {
      checkid.angio_procedures.map(async (r) => {
        const value = await this.props.detail_angio_type(
          r.angio_procedure_type_id
        );
        data = [...data, value];
        await this.props.set_data_template_review([...data]);
      });
    }
    await this.props.angioPatientSnapshotLoadInit(appointment_id);

    this.props.load_procedurals(appointment_id);
  };

  refreshCalendar = () => {
    const { date, location, provider_id } = this.state;
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 0,
      only_procedures: 1,
      only_us: 0,
      only_angio: 1,
    });
  };

  componentWillReceiveProps(nextProps) {
    const selectedPatientResourceProps =
      nextProps.selectedPatientResource || {};
    if (nextProps.schedules) {
      this.setState({ schedulesState: [...(nextProps.schedules || [])] });
    }
    if (Object.keys(selectedPatientResourceProps).length > 0) {
      this.setState({ isSelectedPatient: true });
    } else {
      this.setState({ isSelectedPatient: false });
    }
    if (nextProps.activeReview) {
      this.setState({
        block: true,
      });
      if (nextProps.goNextTabReview) {
        nextProps.goNextTabReview();
      }
    } else {
      this.setState({
        block: false,
      });
    }
  }

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar, disable, isSelectedPatient } = this.state;
    const { selectedPatientResource } = this.props;

    return (
      <div
        className={`clinicWorkflow__main-section ${
          isSidebar ? "openSidebar" : ""
        }`}
      >
        <div className="eventsContainer has-sub-header">
          <div className="calendarContainer">
            <DayCalendarCheck
              locations={this.props.locations || []}
              providers={this.props.providers || []}
              events={this.state.schedulesState || []}
              slotProviders={this.props.scheduleProviders || []}
              resources={resources}
              loading={
                this.props.loadingSchedules || this.props.loadingResource
              }
              fetchEvents={this.handleEvents}
              onSearch={this.onSearch}
              valueSearch={this.state.searchValue}
              onSelectResource={this.onSelectResource}
              calendarType="check-in"
              onSelectAppointment={this.onSelectAppointment}
            />
          </div>
        </div>
        {!disable && (
          <ClinicWorkflowSidebar
            ref={this.sidebarRef}
            sidebarIcon={<Icon type="copy" theme="twoTone" twoToneColor="#198038"/>}
            resource={selectedPatientResource}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
            paymentForm={window.SqPaymentForm}
            refreshCalendar={this.refreshCalendar}
          />
        )}
        {!disable && !isSidebar && (
          <SidebarExtension
            startingWidth="calc(28.3% - 20px + 0.3rem)"
            sidebarReference={this.sidebarRef}
            sidebarToggle={this.toggleSidebar}
            sidebarState={isSidebar}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.common.vhcLocations,
  providers: state.common.providers,
  schedules: state.patientSchedule.schedules,
  scheduleProviders: state.patientSchedule.providers,
  detail_angio: state.procedureDetail.detail_angio_type,
  loadingSchedules: state.patientSchedule.loadingSchedules,
  loadingResource: state.patientSchedule.loadingResource,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  activeReview: state.common.activeReview,
});

const mapDispatchToProps = {
  getVhcLocations,
  getProviders,
  getPatientSchedules,
  angioPatientSnapshotLoadInit,
  chooseSelectedPatientResourceByRandom,
  choosePatientOnSchedules,
  searchPatientSchedules,
  load_intra_operative,
  get_patient_notes,
  selectPatient,
  load_procedurals,
  detail_angio_type,
  set_data_template_review,
  get_cpt_code_review,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
