import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input, Select } from 'antd';
import EditableText from "../../../../common/components/EditableText/EditableFormInput";

const InputPlans = (props) => {
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    setPlans(props.plans);
    setSelectedIndex(-1);
  }, [props.plans]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const conditionRef = useSelector(state=>state.adminReferralCondition.selectedReferralCondition);
  if (!conditionRef) {
    return (
      <div>
        <b>Plan Suggestions</b><br/>
        <span>Select condition first.</span>
      </div>
    );
  }
  const condition = conditionRef.value;

  const onAddPlan = (plan_) => {
    let plans_ = [...plans];
    plan_['icd_code'] = plan_['icdCode'];
    plan_['plan_name'] = plan_['planName'];
    plan_['condition'] = condition;
    plans_.push(plan_);
    setPlans(plans_);
    setSelectedIndex(-1);
    props.onChangePlans(plans_);
  }
  const onNewPlan = () => {
    setSelectedIndex(-1);
  }
  const onUpdatePlan = (selectedIndex_, plan_) => {
    let plans_ = [...plans];
    plan_['icd_code'] = plan_['icdCode'];
    plan_['plan_name'] = plan_['planName'];
    plan_['condition'] = condition;
    plans_[selectedIndex_] = plan_;
    setPlans(plans_);
    props.onChangePlans(plans_);
  }
  const onDeletePlan = (selectedIndex_) => {
    let plans_ = [...plans];
    plans_.splice(selectedIndex_, 1);
    setPlans(plans_);
    setSelectedIndex(-1);
    props.onChangePlans(plans_);
  }

  return (
    <>
      <div>
        <b>Plan Suggestions</b>
      </div>      
      <EditBox 
        selectedIndex={selectedIndex}
        plans={plans}
        onAddPlan={onAddPlan}
        onNewPlan={onNewPlan}
        onUpdatePlan={onUpdatePlan}
        onDeletePlan={onDeletePlan}
      />
      <PlanList 
        plans={plans}
        onSelectPlan={setSelectedIndex}
        selectedIndex={selectedIndex}
      />
    </>
  )
}

const PlanList = (props) => {  
  return (
    <div 
      style={{
        width: '100%', height: '200px',
        overflowY: 'auto',
        border: '1px solid #dfe3e6',
        marginTop: '1.5rem'
      }}
    >
      {props.plans.map((plan, index) => (
        <div         
          onClick={() => props.onSelectPlan(index)}
          style={{
            cursor: 'pointer',
            borderBottom: '1px solid #dfe3e6',
            padding: '5px',
            ...index===props.selectedIndex && { backgroundColor: '#fde876' }
          }}
          key={`plan-${index}`} 
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <strong>{plan.plan_name}</strong>
            <span style={{marginRight: '10px'}}>{plan.icd_code}</span>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            {plan.matching.map((matchingCond, index2) => (
              <div key={`matching-${index}-${index2}`} style={{marginBottom: '1px'}}>
                <span
                  style={{
                    background: 'rgb(222, 7, 28)', color: 'white',
                    paddingLeft: '2px', paddingRight: '2px',
                    borderRadius: '3px 0 0 3px'
                  }}
                >
                  {matchingCond.scope}
                </span>
                <span
                  style={{
                    paddingLeft: '2px', paddingRight: '2px',
                    backgroundColor: '#f4f7fb'
                  }}
                >
                  {matchingCond.field}
                </span>
                <span
                  style={{
                    background: '#005ef6', color: 'white',
                    paddingLeft: '5px', paddingRight: '5px'
                  }}
                >
                  {matchingCond.operator}
                </span>
                <span
                  style={{
                    paddingLeft: '2px', paddingRight: '2px',
                    borderRadius: '0 3px 3px 0',
                    backgroundColor: '#f4f7fb',
                  }}
                >
                  {matchingCond.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const EditBox = (props) => {
  const [formType, setFormType] = useState('new');
  const [planName, setPlanName] = useState('');
  const [icdCode, setIcdCode] = useState('');
  const [matching, setMatching] = useState([{scope: '', operator: '', field: '', value: ''}]);
  useEffect(() => {
    if (props.selectedIndex>=0) {
      setFormType('update');
      setPlanName(props.plans[props.selectedIndex].plan_name);
      setIcdCode(props.plans[props.selectedIndex].icd_code);
      setMatching(props.plans[props.selectedIndex].matching);
    } else {
      setFormType('new');
      setPlanName('');
      setIcdCode('');
      setMatching([{scope: '', operator: '', field: '', value: ''}]);
    }
  }, [props.plans, props.selectedIndex]);
  const onChangeMatchingCond = (index, valueName, value) => {
    let matching_ = [...matching];
    matching_[index][valueName] = value;
    setMatching(matching_);
  }
  const onClickInsert = () => {
    let matching_ = [...matching];
    matching_.push({scope: '', operator: '', field: '', value: ''});
    setMatching(matching_);
  }
  const onClickRemove = (index) => {
    let matching_ = [...matching];
    matching_.splice(index, 1);
    setMatching(matching_);
  }
  const validateForm = () => {
    if (planName==='') return false;
    if (icdCode==='') return false;
    for (let matchingCond of matching) {
      if (matchingCond['scope']==='') return false;
      if (matchingCond['operator']==='') return false;
      if (matchingCond['field']==='') return false;
      if (matchingCond['value']==='') return false;
    }
    return true;
  }
  const onClickAdd = () => {
    if (!validateForm()) return;
    props.onAddPlan(
      { planName, icdCode, matching }
    );
  }
  const onClickDelete = () => {
    props.onDeletePlan(props.selectedIndex);
  }
  const onClickUpdate = () => {
    if (!validateForm()) return;
    props.onUpdatePlan(props.selectedIndex,
      { planName, icdCode, matching }
    );
  }
  const onClickNew = () => {
    props.onNewPlan();
  }

  return (
    <div style={{width: '100%', marginTop: '20px'}}>
      <div style={{display: 'flex', paddingRight: '33px'}}>
        <EditableText
          value={planName}
          handleChange={setPlanName}
          label='Plan Name'
          fullWidth={true}
        />
      </div>
      <div style={{ marginTop: '0.5rem' }}>
        {matching.map((matchingCond, index) => (
          <React.Fragment key={`editbox-matching-${index}`}>
            <div style={{display: 'flex', marginTop: '0.5rem', marginBottom: '0.5rem'}}>
              <div style={{ flex: 1, paddingRight: '0.5rem' }}>
                <EditableText
                  label={'Scope'}
                  value={matchingCond.scope}
                  type={'check'}
                  handleChange={value => onChangeMatchingCond(index, 'scope', value)}
                  options={[
                    {value: ' ', option: ''},
                    {value: 'Medication', option: 'medication'},
                    {value: 'Ultrasound Test', option: 'ultrasound'}
                  ]}
                  optionKey={'option'}
                  valueKey={'value'}
                />
              </div>
              <div style={{ flex: 1, paddingRight: '0.5rem' }}>
                <EditableText
                  value={matchingCond.field} 
                  handleChange={val =>onChangeMatchingCond(index, 'field', val)}
                  label='Matching Field'
                />
              </div>
              <div style={{ minWidth: '25px', flex: 0 }} />
              {index > 0 && <div style={{ minWidth: '25', marginLeft: '0.5rem', flex: 0 }} />}
            </div>
            <div style={{display: 'flex', marginTop: '0.5rem', marginBottom: '0.5rem'}}>
              <div style={{ flex: 1, paddingRight: '0.5rem' }}>
                <EditableText
                  label={'Operator'}
                  value={matchingCond.operator}
                  type={'check'}
                  handleChange={value => onChangeMatchingCond(index, 'operator', value)}
                  options={[
                    {value: ' ', option: ''},
                    {value: '=', option: '='},
                    {value: '>', option: '>'},
                    {value: '<', option: '<'},
                    {value: 'Contains', option: 'contains'},
                    {value: 'Not contains', option: 'not contains'}
                  ]}
                  optionKey={'option'}
                  valueKey={'value'}
                />
              </div>
              <div style={{ flex: 1, paddingRight: '0.5rem' }}>
                <EditableText
                  value={matchingCond.value} 
                  handleChange={val =>onChangeMatchingCond(index, 'value', val)}
                  label='Matching Value'
                />
              </div>

              <button
                style={{ flex: 0 }}
                className="small-blue-btn medium"
                onClick={onClickInsert}
              >
                +
              </button>
              {index > 0 &&
                <button
                  style={{ flex: 0, marginLeft: '0.5rem' }}
                  className="small-blue-btn medium"
                  onClick={() => onClickRemove(index)}
                >
                  -
                </button>
              }
            </div>
          </React.Fragment>
        ))}        
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '1rem',
        marginBottom: '0.5rem'
      }}>
        {formType==='new' &&
          <button 
            className="common-btn"
            onClick={onClickAdd}
          >
            Add Suggestion
          </button>
        }  
        {formType==='update' &&
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
          marginTop: '1rem',
        }}>
          <div style={{ marginTop: '0.5rem' }}>
            <button 
              className="common-btn outlined-btn"
              onClick={onClickUpdate}
            >
              Update
            </button>
          </div>
          <div style={{ marginTop: '0.5rem', marginLeft: '0.5rem' }}>
            <button 
              className="common-btn outlined-btn"
              onClick={onClickNew}
            >
              New
            </button> 
          </div>
          <div style={{ marginTop: '0.5rem', marginLeft: '0.5rem' }}>
            <button 
              className="common-btn outlined-btn"
              onClick={onClickDelete}
            >
              Delete
            </button>
          </div>
        </div>
        }        
      </div>
    </div>
  )
}

export default InputPlans;
