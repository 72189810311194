import React, { useState, useEffect } from "react";
import { Input, Collapse, Icon } from "antd";
import {
  SearchSVG,
  CloseIconSVG,
  TrashSVG,
} from "../../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useDebounce } from "use-lodash-debounce";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getReferralConditions,
  detail_angio_type,
  get_angio_type_variable,
  save_intra_operative,
  set_data_template_review,
  load_intra_operative,
  HPIGetConditions,
} from "../../../../store/actions";
import Loading from "../../../../common/components/Loading";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let timer = null;
const ProcedureSearch = ({ setResource }) => {
  const [data, setData] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [searchInput, setSearch] = useState("");
  const dispatch = useDispatch();
  const loading_intra = useSelector(
    (state) => state.patientExamination.loading_intra
  );
  const id_patient = cookies.get("current_patient_id");
  const id_apoinment = cookies.get("current_appointment_id");

  const intra = useSelector((state) => state.patientExamination.intra);
  const data_template_review = useSelector(
    (state) => state.patientSchedule.data_template_review
  );
  const loading = useSelector(
    (state) => state.procedureDetail.loading_angio_type
  );
  const is_locked = useSelector((state) => state.common.is_locked);
  const referralConditions = useSelector(
    (state) => state.common.referralConditions
  );
  const conditions = useSelector((state) => state.clinicProviderHPI.conditions);
  // const angio_list = useSelector((state) => state.procedureDetail.angio_list);

  useEffect(() => {
    setData(intra);
  }, [intra]);

  useEffect(() => {
    // dispatch(get_angio_type());
    dispatch(load_intra_operative(id_apoinment));
  }, []);

  const handleSearch = (search) => {
    setSearch(search);
    // const array_filter_search = angio_list.filter((r) =>
    //   r.name.toLowerCase().includes(search.toLowerCase())
    // );
    const array_filter_search = referralConditions.filter(
      (r) =>
        r["value"] && r["value"].toLowerCase().includes(search.toLowerCase())
    );
    setFilteredOptions([...array_filter_search]);
  };
  const onSelectOption = async (option) => {
    // setSearch(option["name"]);
    setSearch(option["value"]);
    let params = {};
    const newCondition = await dispatch(HPIGetConditions(option.id));
    if (newCondition && newCondition.allConditions) {
      const fill = newCondition.allConditions.find(
        (r) => r.condition == option["value"]
      );
      if (fill && option) {
        params = {
          ...option,
          ...fill,
        };
        setShowOptions(false);
        setSelectedOption(params);
      } else {
        setShowOptions(false);
        setSelectedOption(undefined);
      }
    }
    // const dataAnser = await dispatch(get_angio_type_variable(option.id));

    // if (dataAnser && option) {
    //   params = {
    //     ...option,
    //     questions: (dataAnser || []).map((r) => {
    //       return {
    //         comments: "",
    //         question: r.question,
    //         choices: (r.answers || []).map((a, ai) => {
    //           return { option: a.answer, text_template: a.text_template };
    //         }),
    //         checked_choice: "",
    //         variable_name: r.variable_name,
    //       };
    //     }),
    //     angio_procedure_type_id: option.id,
    //     text_template: option.text_template,
    //   };
    //   setShowOptions(false);
    //   setSelectedOption(params);
    // } else {
    //   setShowOptions(false);
    //   setSelectedOption(undefined);
    // }
  };

  const onAddCondition = async () => {
    if (!selectedOption) return;
    let data_ = [...data];
    let temp_obj = {
      condition_id: selectedOption.id,
      condition_name: selectedOption.value,
      questions: selectedOption.questions,
    };
    console.log(selectedOption);
    data_.push(temp_obj);
    setSearch("");
    setSelectedOption(undefined);
    setData(data_);
  };

  const onRemoveCondition = (conditionIndex) => {
    if (conditionIndex == 0) {
      let data_ = [...data];
      data_template_review.splice(conditionIndex, 1);
      dispatch(set_data_template_review([...data_template_review]));
      data_.splice(conditionIndex, 1);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(
          save_intra_operative(id_apoinment, {
            patient_id: id_patient,
            conditions: [...data_],
          })
        );
        await dispatch(load_intra_operative(id_apoinment));
      }, 500);
      setData(data_);
    } else {
      let data_ = [...data];
      data_.splice(conditionIndex, 1);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(
          save_intra_operative(id_apoinment, {
            patient_id: id_patient,
            conditions: [...data_],
          })
        );
        await dispatch(load_intra_operative(id_apoinment));
      }, 500);
      data_template_review.splice(conditionIndex, 1);
      dispatch(set_data_template_review([...data_template_review]));
      setData(data_);
    }
  };
  const onChangeComments = (conditionIndex, questionIndex, comments) => {
    let data_ = [...data];
    data_[conditionIndex]["questions"][questionIndex]["comments"] = comments;
    setData(data_);
  };

  const onSelectChoice = (conditionIndex, questionIndex, checked_choice) => {
    let data_ = [...data];
    if (
      data_[conditionIndex]["questions"][questionIndex]["checked_choice"] !=
      checked_choice
    ) {
      data_[conditionIndex]["questions"][questionIndex]["checked_choice"] =
        checked_choice;
    } else if (
      data_[conditionIndex]["questions"][questionIndex]["checked_choice"] ==
      checked_choice
    ) {
      data_[conditionIndex]["questions"][questionIndex]["checked_choice"] = "";
    }
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(
        save_intra_operative(id_apoinment, {
          patient_id: id_patient,
          conditions: [...data_],
        })
      );
      await dispatch(load_intra_operative(id_apoinment));
    }, 500);
    setData(data_);
  };

  const checkAction = async () => {
    if (selectedOption && selectedOption.id) {
      const value = await dispatch(detail_angio_type(selectedOption.id));
      await dispatch(
        set_data_template_review([...data_template_review, value])
      );
    }
  };

  const PanelToolbar = ({ onRemoveCondition, row }) => (
    <div
      className="atuoAccordion__result-item-close_btn"
      onClick={(e) => {
        e.stopPropagation();
        onRemoveCondition(row);
      }}
      style={{ position: "absolute", right: "16px", top: "15px" }}
    >
      <Icon type="delete"/>
    </div>
  );

  return (
    <div className="atuoAccordion">
      <div className="atuoAccordion__filter">
        <div className="atuoAccordion__filter-search white">
          <SearchInput
            searchInput={searchInput}
            setSearch={handleSearch}
            setShowOptions={setShowOptions}
            disabled={is_locked}
          />
        </div>
        <div
          style={{
            height: '39px',
          }}
          className={`atuoComplete__filter-action ${
            is_locked === true || is_locked === 1 ? "disabled" : ""
          }`}
        >
          <button
            className="atuoAccordion__filter-add"
            style={{
              backgroundColor: is_locked === true ? "#c6c6c6" : "#005ef6",
            }}
            onClick={() => {
              onAddCondition();
              checkAction();
            }}
            disabled={is_locked}
          >
            <span>Add </span>
            <span>+</span>
          </button>
        </div>
        {showOptions && searchInput && (
          <div className="atuoAccordion__filter-result">
            <div className="atuoAccordion__filter-result-list">
              <ScrollWrapper css="no-padding">
                {!!filteredOptions.length &&
                  filteredOptions.map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`filter-option-${index}-${row.name}`}
                      onClick={() => onSelectOption(row)}
                    >
                      <p>{row["value"]}</p>
                      {/* <p>{row["name"]}</p> */}
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>
      <div className="atuoAccordion__result">
        <div className="atuoAccordion__result-list">
          {/* onChange={(key) => onClickConditionPanel(key)} */}
          <Collapse accordion>
            {data.map((row, index) => (
              <Collapse.Panel
                className="atuoAccordion__result-item"
                key={`collapse-${index}`}
                // header={row.name || row.angio_procedure_type_name}
                header={row.value || row.condition_name}
                extra={
                  <PanelToolbar
                    onRemoveCondition={() => onRemoveCondition(index)}
                    row={row}
                  />
                }
              >
                <div className="content">
                  <div className="hpiList">
                    {(row.questions || []).map((question, index2) => (
                      <HPIConditionItem
                        key={`questionList-${index}-${index2}`}
                        data={question}
                        onChangeComments={(comments) =>
                          onChangeComments(index, index2, comments)
                        }
                        onSelectChoice={(checked_choice) =>
                          onSelectChoice(index, index2, checked_choice)
                        }
                        isLocked={is_locked}
                      />
                    ))}
                  </div>
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

const SearchInput = (props) => {
  const [searchInput, setSearch] = useState("");
  const debouncedSearch = useDebounce(searchInput, 400);
  useEffect(() => {
    setSearch(props.searchInput);
  }, [props.searchInput]);

  useEffect(() => {
    props.setSearch(searchInput);
  }, [debouncedSearch]);

  const handleSearch = (search) => {
    setSearch(search);
  };

  return (
    <Input.Search
      value={searchInput}
      disabled={props.disabled}
      placeholder="Search Condition"
      onChange={(e) => handleSearch(e.target.value)}
      style={{ width: "100%" }}
      suffix={<SearchSVG />}
      onFocus={() => props.setShowOptions(true)}
      onBlur={() =>
        setTimeout(() => {
          props.setShowOptions(false);
        }, 250)
      }
    />
  );
};

const HPIConditionItem = ({
  data,
  onChangeComments,
  onSelectChoice,
  isLocked,
}) => {
  const [isOpenedBox, setOpenBox] = useState(false);
  const [question, setQuestion] = useState("");
  const [checkedChoice, setCheckedChoice] = useState(null);
  const [change, setChange] = useState(null);
  const [comments, setComments] = useState("");
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    let question = "";
    let choices = [];
    let comments = "";
    let checked_choice = "";
    question = data?.question;
    choices = data?.choices;
    comments = data?.comments;
    checked_choice = data?.checked_choice;
    // if (data) {
    //   (data.answers || []).map((r) => {
    //     if (r.option == data.checked_choice) {
    //       comments = r.text_template;
    //     } else if (r.option == "" || data.checked_choice == "") {
    //       comments = "";
    //     }
    //   });
    // }
    onChangeComments(comments);
    setQuestion(question);
    setChoices(choices);
    setComments(comments);
    setCheckedChoice(checked_choice);
  }, [data, checkedChoice, change]);

  return (
    <div className={`HPIItem`}>
      <div className="HPIItem__label">{question}</div>
      <div
        className={`HPIItem__icon ${isOpenedBox ? "open" : ""}`}
        onClick={() => setOpenBox(!isOpenedBox)}
      >
        <Icon type="edit" />
      </div>
      <div className={`HPIItem__options`}>
        {choices &&
          choices.map((choice, index) => (
            <button
              key={`question-choice-${index}`}
              className={`HPIItem__option ${
                isLocked === 0 || (isLocked === false && "not-locked")
              } ${choice.option == checkedChoice ? "active" : "non-active"}`}
              onClick={() => {
                setChange(Math.random());
                setCheckedChoice(choice.option);
                onSelectChoice(choice.option);
              }}
              disabled={isLocked}
            >
              {choice.option}
            </button>
          ))}
      </div>

      <div
        className="HPIItem__box"
        style={{
          display: isOpenedBox ? "inline-block" : "none",
        }}
        onBlur={() => {
          onChangeComments(comments);
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "5px",
            top: "5px",
            zIndex: 10,
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
          onClick={() => setOpenBox(!isOpenedBox)}
        >
          <CloseIconSVG />
        </div>
        <Input.TextArea
          rows={4}
          value={comments}
          onChange={(e) => {
            setComments(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default ProcedureSearch;
