import { createReducer } from "redux-create-reducer";
import moment from "moment";
import AT from "../actionTypes";

const initialState = {
  conditions: [],
  allConditions: [],
  descList: [],
  saving: false,
};

export default createReducer(initialState, {
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      conditions: [],
      allConditions: [],
      descList: [],
      saving: false,
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.OFFICE_GET_CONDITIONS}_SUCCEEDED`](state, action) {
    return {
      ...state,
      conditions: action?.payload?.conditions
        ? action?.payload?.conditions
        : [],
      allConditions: action?.payload?.allConditions
        ? action?.payload?.allConditions
        : [],
      descList: action?.payload?.descList ? action?.payload?.descList : [],
    };
  },
  [`${AT.OFFICE_SET_CONDITIONS}`](state, action) {
    return {
      ...state,
      conditions: action?.payload?.conditions
        ? action?.payload?.conditions
        : [],
    };
  },
  [`${AT.OFFICE_SAVE_CONDITION}_STARTED`](state, action) {
    const { message } = action.payload;
    return {
      ...state,
      saving: true,
    };
  },
  [`${AT.OFFICE_SAVE_CONDITION}_SUCCEEDED`](state, action) {
    const { message } = action.payload;
    return {
      ...state,
      saving: false,
    };
  },
});
