
import { Icon } from "antd";
import { xprod } from "ramda";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarState } from "../../../store/actions/sidebar";
import { LeftCharvenSVG } from "../icons/SVGIcon";
import "./style.scss";

const SidebarExtension = ({
  sidebarToggle,
  sidebarReference,
  sidebarState,
  defaultOpenState = false,
  startingWidth = "calc(28.3% - 20px)",
  topOffset = "calc(50%)",
  ...restProps
}) => {
  const dispatch = useDispatch();
  const [xPos, setXPos] = useState(0);
  const [width, setWidth] = useState(0);
  const [initialWidth, setInitialWidth] = useState();
  const [openerPosition, setOpenerPosition] = useState(startingWidth);
  const [isOpen, setIsOpen] = useState(defaultOpenState);
  const openerRef = useRef();
  let [isResizing, setIsResizing] = useState();
  let clickTime;
  let xPosition = xPos;
  let sidebarWidth = width;

  useEffect(() => {
    dispatch(setSidebarState(isOpen));

    return function cleanup() {
      dispatch(setSidebarState(false));
    }
  },[])

  const toggleSidebar = () => {
    dispatch(setSidebarState(!isOpen));
    sidebarToggle();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(setSidebarState(isOpen));
  }, [isOpen])

  useEffect(() => {
    if (!sidebarReference) return;
    if (!sidebarReference.current) return;
    setIsOpen(sidebarState);
    setInitialWidth(sidebarReference.current.offsetWidth);
    setWidth(sidebarReference.current.offsetWidth);
    setOpenerPosition((initialWidth-20) + "px");
  }, [sidebarState])

  useEffect(() => {
    setIsOpen(sidebarState);
  }, [])


  const startResize = (e) => { // OnMouseDown
    clickTime = new Date();
    if (!sidebarReference) return;
    if (!sidebarReference.current) return;
    setXPos(e.clientX);
    setWidth(sidebarReference.current.offsetWidth);
    sidebarWidth = sidebarReference.current.offsetWidth;
    xPosition=e.clientX;
    document.documentElement.addEventListener('mousemove', handleResize);
    document.documentElement.addEventListener('mouseup', endResize);
    document.documentElement.classList.add('noSelect');
  }

  const endResize = (e) => { // OnMouseUp
    document.documentElement.removeEventListener('mousemove', handleResize);
    document.documentElement.removeEventListener('mouseup', endResize);
    document.documentElement.classList.remove('noSelect');
  }

  const handleResize = (e) => { // OnMouseMove
    const calculation = sidebarWidth - e.clientX + xPosition;
    setIsResizing(true);
    if (!sidebarReference.current) return;
    sidebarReference.current.style.width = calculation + 'px';
    if (sidebarReference.current.offsetWidth !== calculation) {
      setOpenerPosition(sidebarReference.current.offsetWidth - 20);
      return;
    }
    if (calculation < initialWidth - 20) {
      setOpenerPosition(initialWidth-20);
      return;
    }; 
    setOpenerPosition(calculation - 20);
  };

  const handleClick = (e) => { // OnClick
    e.preventDefault();
    e.stopPropagation();
    if (new Date() - clickTime < 1000 || !isResizing) {
      toggleSidebar();
    }
    setIsResizing(false);
  }

  if ((!isOpen && !sidebarState)) {
    return (
      <>
        <div
          className="resizeHandle"
          style={{
            top: topOffset,
            right: "0Px",
          }}
          onClick={toggleSidebar}
        >
          <LeftCharvenSVG />
        </div>
      </>
    );
  }

  return (
    <>
      <div
        ref={openerRef}
        className="resizeHandle active"
        style={{
          top: topOffset,
          right: openerPosition
        }}
        onMouseDown={startResize}
        onClick={handleClick}
      >
        <LeftCharvenSVG />
      </div>

      {/* <div style={{
        position: "absolute",
        top: 118,
        right: 15,
        zIndex: 9999,
        cursor: 'pointer',
        fontWeight: 'bold'
      }}>
        <Icon style={{fontSize: '25px'}} onClick={toggleSidebar} type="close-square" theme="twoTone" />
      </div> */}
    </>
  );
};

export default SidebarExtension;
