import { createReducer } from "redux-create-reducer";
import AT from "../actionTypes";
const initialState = {
  loading: false,
  media: []
}

const UPLOAD_USER_MEDIA_STARTED = `${AT.UPLOAD_USER_MEDIA}_STARTED`;
const UPLOAD_USER_MEDIA_SUCCEEDED = `${AT.UPLOAD_USER_MEDIA}_SUCCEEDED`;

const GET_ALL_USER_MEDIA_STARTED = `${AT.GET_ALL_USER_MEDIA}_STARTED`;
const GET_ALL_USER_MEDIA_SUCCEEDED = `${AT.GET_ALL_USER_MEDIA}_SUCCEEDED`;

const REMOVE_USER_MEDIA_STARTED = `${AT.REMOVE_USER_MEDIA}_STARTED`;
const REMOVE_USER_MEDIA_SUCCEEDED = `${AT.REMOVE_USER_MEDIA}_SUCCEEDED`;

const UPDATE_USER_MEDIA_STARTED = `${AT.UPDATE_USER_MEDIA}_STARTED`;
const UPDATE_USER_MEDIA_SUCCEEDED = `${AT.UPDATE_USER_MEDIA}_SUCCEEDED`;

export default createReducer(initialState, {
  [UPLOAD_USER_MEDIA_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [UPLOAD_USER_MEDIA_SUCCEEDED](state, action) {
    return {
      ...state,
      loading: false,
      media: [
        ...state.media,
        action.payload
      ]
    };
  },
  [GET_ALL_USER_MEDIA_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [GET_ALL_USER_MEDIA_SUCCEEDED](state, action) {
    return {
      ...state,
      loading: false,
      media: action.payload
    };
  },
  [REMOVE_USER_MEDIA_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [REMOVE_USER_MEDIA_SUCCEEDED](state, action) {
    const id = action.payload.id.split("}")[0];
    const data = state.media.filter(e => e.id !== parseInt(id));
    return {
      ...state,
      loading: false,
      media: [...data]
    };
  },
  [UPDATE_USER_MEDIA_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [UPDATE_USER_MEDIA_SUCCEEDED](state, action) {
    let tmp = [];
    const data = action.payload.data;
    for(let obj of state.media) {
      if (obj.id === data[0].id) {
        tmp.push(data[0]);
      } else {
        tmp.push(obj);
      }
    }
    return {
      ...state,
      loading: false,
      media: [...tmp]
    };
  }
});
