import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ProviderReportSidebar from "./ProviderReportSidebar";
import ProviderReportFilter from "./ProviderReportFilter";
import {
  LeftCharvenSVG,
  CheckSVG,
  YellowAlertSVG,
  RedRequiredCircleSVG,
  RedCloseSVG,
} from "../../../../common/components/icons/SVGIcon";
import DataTable from "../../../../common/components/DataTable/DataTable";
import {
  // getPCM,
  getPCMProvider,
  getPCMForUser,
  getPCMFilter,
  setGlobalLoading,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";

let timer;
const ProviderReport = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);

  const pcm = useSelector((state) => state.common.pcm);
  const ScheduleRow = useSelector((state) => state.common.pcmProvider);
  const pcmUser = useSelector((state) => state.common.pcmUser);
  const pcmFilter = useSelector((state) => state.common.pcmFilter);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [search, setSearch] = useState("");
  const [offLoad, setOffLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [forFilter, setForFilter] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [titleName, setTitleName] = useState("");
  const [userProvider, setUserProvider] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [sortOption, setSortOption] = useState({});

  const sortList = async () => {
    const sortColumn = [];
    if (!!sortOption.patient_name) {
      sortColumn.push({
        name: "patient_name",
        direction: sortOption.patient_name,
      });
    }
    if (!!sortOption.dos) {
      sortColumn.push({ name: "dos", direction: sortOption.dos });
    }
    if (!!sortOption.total_payment) {
      sortColumn.push({
        name: "total_payment",
        direction: sortOption.total_payment,
      });
    }
    if (!!sortOption.contribution_margin) {
      sortColumn.push({
        name: "contribution_margin",
        direction: sortOption.contribution_margin,
      });
    }

    setTableLoads([]);
    setLoading(true);
    setPage(1);
    if (!forFilter) {
      // await dispatch(getPCM(1, "", { column: sortColumn }));
      await dispatch(
        getPCMFilter({
          page: 1,
          type: "",
          column: sortColumn,
        })
      );
    } else {
      await dispatch(
        getPCMFilter({
          user_id: userProvider,
          startDate: startDateFilter,
          endDate: endDateFilter,
          page: 1,
          search: search,
          column: sortColumn,
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    sortList();
  }, [sortOption]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
  };

  const handleRow = (row, index) => () => {
    if (activeIndex !== index) {
      setRow(index);
      setSelectedId(tableLoad && tableLoad[index]?.user_id);
    }
  };

  const fetchDetail = async () => {
    if (activeIndex >= 0) {
      setLoading(true);
      setTitleName(tableLoad && tableLoad[activeIndex]?.provider_name);
      const detailSuccess = await dispatch(
        getPCMForUser(
          tableLoad && tableLoad[activeIndex]?.user_id,
          startDateFilter ? startDateFilter : null,
          endDateFilter ? endDateFilter : null
        )
      );
      if (detailSuccess) {
        setLoading(false);
      }
      handleSidebar();
    }
  };

  useEffect(() => {
    fetchDetail();
  }, [activeIndex, tableLoad]);

  const fetchList = async () => {
    // const listPCM = await dispatch(getPCM(1));
    const listPCM = await dispatch(
      getPCMFilter({
        page: 1,
      })
    );
    const listProvider = await dispatch(getPCMProvider());
    if (listPCM && listProvider) {
      setLoading(false);
    }
  };

  const valueSearch = (value) => {
    clearTimeout(timer);
    setSearch(value);
    setLoading(true);
    timer = setTimeout(() => {
      setTableLoads([]);
      setForFilter(true);
      setPage(1);
      setLoading(false);
      dispatch(
        getPCMFilter({
          user_id: userProvider,
          startDate: startDateFilter,
          endDate: endDateFilter,
          page: 1,
          search: value,
        })
      );
    }, 1000);
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    const sortColumn = [];
    if (!!sortOption.patient_name) {
      sortColumn.push({
        name: "patient_name",
        direction: sortOption.patient_name,
      });
    }
    if (!!sortOption.dos) {
      sortColumn.push({ name: "dos", direction: sortOption.dos });
    }
    if (!!sortOption.total_payment) {
      sortColumn.push({
        name: "total_payment",
        direction: sortOption.total_payment,
      });
    }
    if (!!sortOption.contribution_margin) {
      sortColumn.push({
        name: "contribution_margin",
        direction: sortOption.contribution_margin,
      });
    }

    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad && search.length <= 0 && !forFilter) {
        setLoading(true);
        // const listPCM = await dispatch(
        //   getPCM(page + 1, "", { column: sortColumn })
        // );
        const listPCM = await dispatch(
          getPCMFilter({
            column: sortColumn,
            page: page + 1,
            type: "",
          })
        );
        if (listPCM) {
          if (
            (pcm && pcm.endovascular && pcm.endovascular.length > 0) ||
            (pcm && pcm.nonendovasculars && pcm.nonendovasculars.length > 0)
          ) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      } else if (offLoad && search.length <= 0 && forFilter) {
        setLoading(true);
        const listPCMFilter = await dispatch(
          getPCMFilter({
            user_id: userProvider,
            startDate: startDateFilter,
            endDate: endDateFilter,
            column: sortColumn,
            page: 1,
            search: search,
          })
        );
        if (listPCMFilter) {
          if (
            (pcmFilter &&
              pcmFilter.endovascular &&
              pcmFilter.endovascular.length > 0) ||
            (pcmFilter &&
              pcmFilter.nonendovasculars &&
              pcmFilter.nonendovasculars.length > 0)
          ) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  const changeDate = (value, type) => {
    const date = moment(value).format("MM/DD/YYYY");
    if (type === "end") {
      if (value && value !== "Invalid date") {
        setEndDateFilter(date);
      } else {
        setEndDateFilter(undefined);
      }
    } else {
      if (value && value !== "Invalid date") {
        setStartDateFilter(date);
      } else {
        setStartDateFilter(undefined);
      }
    }
  };

  const changeUserProvider = (value) => {
    setUserProvider(value);
  };

  useEffect(() => {
    if (pcm && pcm.endovascular && pcm.nonendovasculars) {
      // if (tableLoad && tableLoad.length === 0) {
      // dispatch(
      //   getPCMForUser(
      //     pcm.endovascular[0] && pcm.endovascular[0].user_id,
      //     startDateFilter ? startDateFilter : null,
      //     endDateFilter ? endDateFilter : null
      //   )
      // );
      // }
      if (page > 1) {
        setTableLoads([
          ...tableLoad,
          ...pcm.endovascular,
          ...pcm.nonendovasculars,
        ]);
      } else {
        setTableLoads([...pcm.endovascular, ...pcm.nonendovasculars]);
      }
    }
  }, [pcm]);

  const callApi = async () => {
    setLoading(true);
    setForFilter(true);
    setPage(1);
    setTableLoads([]);
    const detailSuccess = await dispatch(
      getPCMFilter({
        user_id: userProvider,
        startDate: startDateFilter,
        endDate: endDateFilter,
        page: 1,
        search: search,
      })
    );
    if (detailSuccess) {
      setLoading(false);
    }
  };

  useEffect(() => {
    callApi();
  }, [userProvider, startDateFilter, endDateFilter]);

  useEffect(() => {
    if (pcmFilter && pcmFilter.endovascular && pcmFilter.nonendovasculars) {
      if (tableLoad && tableLoad.length === 0) {
        // dispatch(
        //   getPCMForUser(
        //     pcm.endovascular[0] && pcm.endovascular[0].user_id
        //       ? pcm.endovascular[0].user_id
        //       : pcm.nonendovasculars[0] && pcm.nonendovasculars[0].user_id,
        //     startDateFilter ? startDateFilter : null,
        //     endDateFilter ? endDateFilter : null
        //   )
        // );
      }
      setTableLoads([
        ...tableLoad,
        ...pcmFilter.endovascular,
        ...pcmFilter.nonendovasculars,
      ]);
    }
  }, [pcmFilter]);

  useEffect(() => {
    if (tableLoad) {
      setTitleName(tableLoad[0] && tableLoad[0].provider_name);
    }
  }, [tableLoad]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);
  const sidebarRef = useRef();
  console.dir(pcmUser);
  console.dir(activeIndex);
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer">
        <div className="fullContainer pt-2">
          <ProviderReportFilter
            startDate={startDateFilter}
            endDate={endDateFilter}
            changeUserProvider={changeUserProvider}
            changeDate={changeDate}
            providers={ScheduleRow || []}
            valueSearch={(value) => valueSearch(value)}
          />
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                handleClickRow={(row, index) => {
                  if (activeIndex !== index) {
                    setRow(index);
                    setSelectedId(tableLoad && tableLoad[index]?.user_id);
                  }
                }}
                title="pcm_non_endovascular"
                tableWidth={1200}
                labels={["Patient Name", "DOB", "DOS", "Provider", "Payment", "CM"]}
                widths={["20%", "16%", "16%", "16%", "16%", "16%"]}
                columns={[
                  { sortable: true, key: "patient_name" },
                  { sortable: false, key: "patient_dob" },
                  { sortable: true, key: "dos" },
                  { sortable: false, key: "provider" },
                  { sortable: true, key: "total_payment" },
                  { sortable: true, key: "contribution_margin" },
                ]}
                sorts={[
                  sortOption.patient_name,
                  "",
                  sortOption.dos,
                  "",
                  sortOption.total_payment,
                  sortOption.contribution_margin,
                ]}
                rowData={(tableLoad || []).map((row, index) => ({
                  id: row.id,
                  patient_name: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {row.icon_status === "green" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="clock-circle" theme="twoTone" twoToneColor="#24A148"/>
                          <span
                            className="tooltip tooltip-without-border"
                            role="tooltip"
                          >
                            {">70%"}
                          </span>
                        </div>
                      )}
                      {row.icon_status === "yellow" && <YellowAlertSVG />}
                      {row.icon_status === "red" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
                          <span
                            className="tooltip tooltip-without-border"
                            role="tooltip"
                          >
                            {"<70% and > 365 days"}
                          </span>
                        </div>
                      )}
                      {row.icon_status === "red_x" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="thunderbolt" theme="twoTone" twoToneColor="#6929C4"/>
                          <span
                            className="tooltip tooltip-without-border"
                            role="tooltip"
                          >
                            {"<70% and < 365 days"}
                          </span>
                        </div>
                      )}
                      {!row.icon_status && (
                        <span style={{ minWidth: "26px" }}></span>
                      )}
                      <p style={{ paddingLeft: "0.75rem" }}>{row.patient_name}</p>
                    </div>
                  ),
                  patient_dob:
                    row.patient_dob && moment(row.patient_dob).format("MM/DD/YYYY"),
                  dos:
                    row.date_of_procedure &&
                    moment(row.date_of_procedure).format("MM/DD/YYYY"),
                  proceduralist: row.provider_name,
                  total_payment: row.payment,
                  contribution_margin: row.contribution_amount,
                  provider: row.provider_name
                }))}
                handleSort={(value, item) => {
                  handleSortOption(value, item);
                }}
                fetchList={fetchList}
                loadMore={loadMore}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>

      {pcmUser && activeIndex >= 0 && (
        <ProviderReportSidebar
          ref={sidebarRef}
          endDate={endDateFilter}
          startDate={startDateFilter}
          titleName={titleName}
          resource={activeIndex >= 0 && pcmUser ? pcmUser : undefined}
          userId={selectedId}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}

      {pcmUser && activeIndex >= 0 && (
        <SidebarExtension
          startingWidth="calc(28.3% - 20px + 0.3rem)"
          sidebarReference={sidebarRef}
          sidebarToggle={toggleSidebar}
          sidebarState={isSidebar}
        />
      )}
    </div>
  );
};

export default ProviderReport;
