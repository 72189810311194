/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function DisplaySignature ({signatureImage}) {
  return (
    <React.Fragment>
      <div>
        <b>Signature:</b>
      </div>
      <div className="resourceInfo" style={{ textAlign: "left" }}>
        <img
          src={signatureImage}
          style={{
            width: "300px",
            height: "200px",
            margin: "0 auto",
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default DisplaySignature;