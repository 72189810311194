import { createActionThunk } from 'redux-thunk-actions';
import AT from '../actionTypes';
import * as API from '../../services/api.services';

export const procedureConsentLoadInit = createActionThunk(
  AT.PROCEDURE_CONSENT_LOAD_INIT,
  (patient_id) => API.procedureConsentLoadInit(patient_id)
);

export const procedureConsentOpenCreate = (data) => (
  (dispatch) => dispatch({
    type: AT.PROCEDURE_CONSENT_OPEN_CREATE,
    payload: data
  })
)

export const procedureConsentSaveDocument = createActionThunk(
  AT.PROCEDURE_CONSENT_SAVE_DOCUMENT,
  (data) => API.procedureConsentSaveDocument(data)
)

export const procedureConsentClosedCreate = (data) => (
  (dispatch) => dispatch({
    type: AT.PROCEDURE_CONSENT_CLOSED_CREATE
  })
)

export const procedureConsentSelectDocument = (selectedDocumentData) => (
  (dispatch) => dispatch({
    type: AT.PROCEDURE_CONSENT_SELECT_DOCUMENT,
    payload: { selectedDocumentData }
  })
)

export const procedureConsentSetSearchData = (searchData) => (
  (dispatch) => dispatch({
    type: AT.PROCEDURE_CONSENT_SET_SEARCH_DATA,
    payload: { searchData }
  })
)

export const procedureConsentSearch = createActionThunk(
  AT.PROCEDURE_CONSENT_SEARCH,
  (patient_id, searchData) => API.procedureConsentSearch(patient_id, searchData)
)

export const procedureConsentGetSignatureImage = createActionThunk(
  AT.PROCEDURE_CONSENT_GET_SIGNATURE_IMAGE,
  (provider_id) => API.procedureConsentGetSignatureImage(provider_id)
)

export const procedureConsentSetSignatureImage = createActionThunk(
  AT.PROCEDURE_CONSENT_SET_SIGNATURE_IMAGE,
  (provider_id, signature_image) => API.procedureConsentSetSignatureImage(provider_id, signature_image)
)

export const procedureConsentDelete = createActionThunk(
  AT.PROCEDURE_CONSENT_DELETE,
  (consent_id) => API.procedureConsentDelete(consent_id)
)

export const procedureConsentSendFax = createActionThunk(
  AT.PROCEDURE_CONSENT_SEND_FAX,
  (consent_id, data) => API.procedureConsentSendFax(consent_id, data)
)

export const procedureConsentSendEmail = createActionThunk(
  AT.PROCEDURE_CONSENT_SEND_EMAIL,
  (consent_id, data) => API.procedureConsentSendEmail(consent_id, data)
)
