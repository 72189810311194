import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import Axios from "axios";
import SplashPannel from "./components/SplashPannel";
import SocialPannel from "./components/SocialPannel";
import SplashPannelBottom from "./components/SplashPannelBottom";
import LoginForm from "./components/LoginForm";
import { AUTH_API } from "../../common/constants/constants";
import ToastMessage from "../../common/components/Toast";

import {
  getLocations,
  getProviders,
  getCurrentUserInfo,
  getCurrentUser,
} from "../../store/actions";
import * as API from "../../services/api.services";

import "./style.scss";
import Loading from "../../common/components/Loading";
const cookies = new Cookies();
const LoginPage = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [isAuth, setIsAuth] = useState(!!cookies.get("cvai-auth-token"));

  useEffect(() => {
    if (isAuth) {
      props.history.push("/");
    }
  }, [isAuth]);

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular</title>
        <meta name="title" content="FAACT Vascular" />
      </Helmet>
    );
  };

  const handleSubmit = (username, password) => {
    setLoading(true);
    const grant_type = "password";
    const client_id = process.env.REACT_APP_client_id;
    const client_secret = process.env.REACT_APP_client_secret;
    Axios.post(
      AUTH_API,
      {
        grant_type,
        client_id,
        client_secret,
        username,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then(async (result) => {
        if (result.data) {
          cookies.set("cvai-auth-token", result.data.access_token);
          cookies.set("cvai-token-type", result.data.token_type);
          cookies.set("cvai-token-expires", result.data.expires_in);
          dispatch(getCurrentUser());
          const checkLoginInfo = await API.getCurrentUserInfo();
          dispatch(getCurrentUserInfo(checkLoginInfo && checkLoginInfo.id));
          const checkInfo = await API.getDetailUsers(
            checkLoginInfo && checkLoginInfo.id
          );

          if (checkLoginInfo && checkInfo && checkLoginInfo.status === 1) {
            setIsAuth(true);
            setLoading(false);
            dispatch(getLocations());
            dispatch(getProviders());
            if (checkInfo.provider) {
              cookies.set(
                "cvai-current-user-provider-name",
                checkInfo?.provider?.value || ""
              );
            }
          } else {
            cookies.remove("cvai-auth-token");
            cookies.remove("cvai-token-type");
            cookies.remove("cvai-token-expires");
            // alert(`You Can't Login because account not active`);
            setIsAuth(false);
            setIsToastr(true);
            setLoading(false);
            setTimeout(() => {
              setIsToastr(false);
            }, 2000);
          }
        }
      })
      .catch((err) => {
        setLoginError(true);
        setLoading(false);
      });
  };

  if (isAuth) {
    return null;
  }

  return (
    <div className="loginPage">
      {isToastr && (
        <ToastMessage
          type="Login"
          status="failed"
          msg="You Can't Login because account not active!"
        />
      )}
      {loading && <Loading />}
      {renderMetadata()}
      <div className="loginMainContainer">
        <div className="splashContainer">
          <LoginForm handleSubmit={handleSubmit} loginError={loginError} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
