import React from 'react';

export const SortingArrowIcon = ({ sort, handleSortASC, handleSortDESC }) => {
  const handleSorting = () => {
    if (sort === 'asc') {
      handleSortDESC();
    } else {
      handleSortASC();
    }
  };

  if (!sort) {
    return (
      <svg
        fill="currentColor"
        width="16"
        height="16"
        viewBox="0 0 32 32"
        style={{
          cursor: 'pointer',
        }}
        onClick={handleSortASC}
      >
        <path d="M27.6 20.6L24 24.2V4h-2v20.2l-3.6-3.6L17 22l6 6 6-6zM9 4l-6 6 1.4 1.4L8 7.8V28h2V7.8l3.6 3.6L15 10z" />
      </svg>
    );
  }

  return (
    <svg
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      style={{
        cursor: 'pointer',
        transform: sort === 'asc' ? 'rotate(0)' : 'rotate(180deg)'
      }}
      onClick={handleSorting}
    >
        <path d="M3.7 6.7L7.5 2.9 7.5 15 8.5 15 8.5 2.9 12.3 6.7 13 6 8 1 3 6z" />
    </svg>
  );
};

const SortingIcon = ({ sort, handleSortASC, handleSortDESC }) => {

  return (
    <svg viewBox="0 0 50 50" style={{ width: '38px', height: '38px', marginLeft: 0 }} x="0px" y="0px">
      <style type="text/css">
        {`.st0{fill:#7D7D7D;}
        .st1{clip-path:url(#SVGID_2_);}
        .st2{clip-path:url(#SVGID_4_);}`}
      </style>
      <g>
        {sort === 'asc' && (
          <polygon 
            className="st0"
            points="33.55,17.61 25.02,9.08 25,9.1 24.98,9.08 16.45,17.61 19.29,20.44 25,14.73 30.71,20.44 	"
            style={{
              cursor: 'pointer',
              stroke: '#0f62fe',
              fill: '#0f62fe',
            }}
            onClick={handleSortASC}
          />
        )}
        {sort === 'desc' && (
          <polygon
            className="st0"
            points="16.45,32.39 24.98,40.92 25,40.9 25.02,40.92 33.55,32.39 30.71,29.56 25,35.27 19.29,29.56 	"
            style={{
              cursor: 'pointer',
              stroke: '#0f62fe',
              fill: '#0f62fe',
            }}
            onClick={handleSortDESC}
          />
        )}
        {sort !== 'asc' && (
          <g style={{ cursor: 'pointer' }} onClick={handleSortASC}>
            <g>
              <g id="Up_arrow_small" transform="translate(36)">
                <path id="Path_36" class="st0" d="M-11,8.87l8.31,8.31l-2.48,2.48L-11,13.82l-5.83,5.83l-2.48-2.48L-11,8.87z"/>
              </g>
            </g>
          </g>
        )}
        {sort !== 'desc' && (
          <g style={{ cursor: 'pointer' }} onClick={handleSortDESC}>
            <g>
              <g id="Up_arrow_small_1_" transform="translate(36)">
                <path id="Path_36_1_" class="st0" d="M-10.99,41.13l-8.32-8.3l2.47-2.48l5.84,5.83l5.83-5.84l2.48,2.47L-10.99,41.13z"/>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  );
};

export default SortingIcon;
