import React, { useEffect, useState } from "react";
import AppRouters from "./routers";
import ToastrMsg from "./common/components/Toast";
import "./App.scss";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  getPCPList,
  getReferralConditions,
  getReferredProviders,
  getProviders,
  getLocations,
  getProcedureData,
  getCurrentUserInfo,
  setGlobalErrorMessage,
  insurance_types,
  search_insurance_companies,
  payer_id_types, setGlobalToastr,
} from "./store/actions";
import "material-design-icons/iconfont/material-icons.css";
import "draft-js-mention-plugin/lib/plugin.css";
import PusherConnector from "./common/components/PusherScan/pusherConnector";

const cookies = new Cookies();

function App() {
  const error = useSelector((state) => state.common.globalErrorMessage);
  const globalMessage = useSelector((state) => state.common.globalMessage);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const activeSupportTicket = useSelector((state) => state.common.activeSupportTicket);
  const [pusherChannel, setPusherChannel] = useState(false);
  const [pusherConnector, setPusherConnnector] = useState();
  const dispatch = useDispatch();
  const userToken = cookies.get("cvai-auth-token");
  const authId = cookies.get("cvai-current-user");
  cookies.set("patient_document_id", null);
  const fetchApiCheck = async () => {
    await dispatch(getCurrentUserInfo(authId));
  };

  const fetchData = async () => {
    await dispatch(getProviders());
    await dispatch(insurance_types());
    await dispatch(getLocations());
    await dispatch(search_insurance_companies(""));
    await dispatch(getReferralConditions());
    await dispatch(getReferredProviders());
    await dispatch(getProcedureData());
    await dispatch(getPCPList(null, null));
    await dispatch(payer_id_types());
  };

  useEffect(() => {
    const pusher = new PusherConnector();
    var channelName = 'support-messages';
    const channel = pusher.createChannel(channelName);
    setPusherConnnector(pusher);  
    setPusherChannel(channel);
  }, [])
  
  useEffect(() => {
    if (pusherChannel && pusherChannel.bind && pusherConnector) {
      var eventName = 'new-ticket';
      
      pusherConnector.unbindEvent(pusherChannel, eventName);
      pusherConnector.bindEvent(pusherChannel, eventName, async (callback) => {
        const data = await callback;
        if (currentUserInfo.is_support) {
          dispatch(setGlobalToastr({
            header: "New Support Ticket",
            message: "Subject: " + data.subject,
            type: "warning",
          }))
          console.log('new support ticket');
        }
      });

    }
  }, [pusherChannel, pusherConnector, currentUserInfo])

  useEffect(() => {
    if (pusherChannel && pusherChannel.bind && pusherConnector) {
      var eventName = 'new-message';
      
      pusherConnector.unbindEvent(pusherChannel, eventName);
      pusherConnector.bindEvent(pusherChannel, eventName, async (callback) => {
        const data = await callback;
        if (currentUserInfo.id == data.user_id && activeSupportTicket != data.support_id) {
          dispatch(setGlobalToastr({
            header: "New Support Message",
            message: "Subject: " + data.subject,
            type: "warning",
          }))
        }
      });
      
    }
  }, [pusherChannel, pusherConnector, currentUserInfo, activeSupportTicket])


  useEffect(() => {
    if (
      userToken &&
      authId &&
      currentUserInfo &&
      Object.keys(currentUserInfo).length <= 0
    ) {
      fetchApiCheck();
    } else if (!userToken || !authId) {
      cookies.remove("cvai-auth-token");
      cookies.remove("cvai-token-type");
      cookies.remove("cvai-token-expires");
    }
  }, []);

  useEffect(() => {
    if (currentUserInfo && Object.keys(currentUserInfo).length <= 0) {
      fetchData();
    }
  }, [currentUserInfo]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(setGlobalErrorMessage(null));
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    if (globalMessage) {
      setTimeout(() => {
        dispatch(setGlobalToastr(null));
      }, 3000);
    }
  }, [globalMessage]);

  return (
    <div className="App" id="app">
      <AppRouters />
      {error && error.message && (
        <ToastrMsg
          type={error.status ? error.status.toUpperCase() : "failed"}
          msg={error.message}
          status={"failed"}
        />
      )}
      {globalMessage && globalMessage.message && (
          <ToastrMsg
              type={globalMessage.header ? globalMessage.header : "Success"}
              msg={globalMessage.message}
              status={globalMessage.type ? globalMessage.type : "success"}
          />
      )}
    </div>
  );
}

export default App;
