import React, { useState, useEffect, useCallback } from "react";
import { debounce as lodashDebounce } from "lodash";
import { useDebounce } from "use-lodash-debounce";
import { useSelector, useDispatch } from "react-redux";
import ScrollWrapper from "../../../common/components/ScrollBar";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import ToastMessage from "../../../common/components/Toast";
import {
  search_insurance_companies,
  detail_insurance_companies,
  save_insurance_companies,
  edit_insurance_companies,
  getPrimaryProviderDetails,
} from "../../../store/actions";
import Loading from "../../../common/components/Loading";
import { Select } from "antd";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import EditableInput from "../../../common/components/EditableText/EditableFormInput";

let timer = null;
const { Option } = Select;

const AddCarrier = () => {
  const dispatch = useDispatch();
  const search_insurances = useSelector(
    (state) => state.newPatient.search_insurance_companies
  );
  const detail_insurance = useSelector(
    (state) => state.newPatient.detail_insurance_companies
  );
  const payer_id_types = useSelector(
    (state) => state.newPatient.payer_id_types
  );
  const [isToastr, setIsToastr] = useState(false);
  const [search, setSearch] = useState("");
  const [state, setState] = useState({});
  const [listSearch, setListSearch] = useState([]);

  const debounce = useDebounce(search, 400);
  useEffect(() => {
    fetchData(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const fetchData = (query) => {
    if (!query) {
      setListSearch([]);
      return;
    }
    dispatch(search_insurance_companies(query));
  };

  useEffect(() => {
    dispatch(search_insurance_companies(""));
  }, []);

  useEffect(() => {
    setListSearch(search_insurances);
  }, [search_insurances]);

  useEffect(() => {
    if (detail_insurance && detail_insurance.id) {
      setState({
        ...detail_insurance,
      });
    }
  }, [detail_insurance]);

  const addSearch = async (value) => {
    if (value && value.id) {
      await dispatch(detail_insurance_companies(value.id));
    }
  };

  const handleProvider = (value, type) => {
    const params = {
      ...state,
      [type]: value,
    };
    setState(params);
    if (params && params.id) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const saveSuccess = await dispatch(
          edit_insurance_companies(params.id, params)
        );
        if (saveSuccess) {
          setIsToastr(true);
          setTimeout(() => {
            setIsToastr(false);
          }, 1000);
        }
      }, 500);
    }
  };

  const handleBtnSaveClicked = async () => {
    const saveSucess = await dispatch(save_insurance_companies(state));
    if (saveSucess) {
      setIsToastr(true);
      setTimeout(() => {
        setState({});
        setIsToastr(false);
      }, 1000);
    }
  };

  const styleLeftElement = {
    width: "50%",
    paddingRight: "1rem",
    borderRight: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  const styleRightElement = {
    width: "50%",
    paddingLeft: "1rem",
    borderLeft: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  return (
    <div
      className="documentOcrSetting"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "100%",
      }}
    >
      <AutoCompleteSearch
        options={listSearch}
        handlePopulate={(value) => {
          addSearch(value);
        }}
        handleSearch={(value) => {
          setSearch(value);
        }}
        title="Healthcare-provider-location"
        placeholder="Search Insurance Companies"
        actionLabel="Search"
        keyLabel="name"
      />
      <div style={{ flex: 1, paddingTop: "1rem", width: "100%" }}>
        {isToastr && (
          <ToastMessage
            type="Add Carrier"
            status="success"
            msg="Save Carrier Successfully!"
          />
        )}
        <ScrollWrapper css="no-padding x-hidden">
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: "5px",
            }}
          >
            <div
              style={{
                width: "50%",
                paddingRight: "1rem",
                borderRight: "solid 1px #f0f3f6",
              }}
            >
              <EditableFormInput
                label="Carrier Name"
                value={state.name}
                handleChange={(value) => {
                  handleProvider(value, "name");
                }}
                fullWidth
              />
            </div>
            <div
              style={{
                width: "50%",
                paddingLeft: "1rem",
                borderLeft: "solid 1px #f0f3f6",
              }}
            >
              <EditableFormInput
                label="Address"
                value={state.address}
                handleChange={(value) => {
                  handleProvider(value, "address");
                }}
                fullWidth
              />
            </div>
          </div>
          {/* <div style={{ marginTop: '0.5rem' }} /> */}
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Phone Number"
                value={state.phone}
                handleChange={(value) => {
                  handleProvider(value, "phone");
                }}
                mask={"phone"}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Fax Number"
                value={state.fax}
                handleChange={(value) => {
                  handleProvider(value, "fax");
                }}
                mask={"phone"}
                fullWidth
              />
            </div>
          </div>
          {/* <div style={{ marginTop: '0.5rem' }} /> */}
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Suite"
                value={state.suite}
                handleChange={(value) => {
                  handleProvider(value, "suite");
                }}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="State"
                value={state.state}
                handleChange={(value) => {
                  handleProvider(value, "state");
                }}
                fullWidth
              />
            </div>
          </div>
          {/* <div style={{ marginTop: '0.5rem' }} /> */}
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="City"
                value={state.city}
                handleChange={(value) => {
                  handleProvider(value, "city");
                }}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Zip Code"
                value={state.zip}
                handleChange={(value) => {
                  handleProvider(value, "zip");
                }}
                fullWidth
              />
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            {/* <Select
              mode="default"
              placeholder={"Claim Payer ID"}
              suffixIcon={<ExpandSVG />}
              allowClear={false}
              value={state.claim_payer_id || undefined}
              onChange={(value) => handleProvider(value, "claim_payer_id")}
              style={{ width: "100%", border: "1px solid #ccc" }}
              showSearch
            >
              {(payer_id_types || []).map((item, index) => (
                <Option key={item.payer_id} value={item.payer_id}>
                  {item.name}
                </Option>
              ))}
            </Select> */}
            <AutoCompleteSearch
                options={payer_id_types}
                handlePopulate={(value) => {
                  handleProvider(value.payer_id, "claim_payer_id");
                }}
                title="Healthcare-provider-location"
                placeholder="Search Claim Payer ID"
                actionLabel="Search"
                keyLabel="name"
                handleCurrentItem={(value) => {
                  handleProvider(value.payer_id, "claim_payer_id")
                }}
            />
          </div>

          <div
            style={{
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
              float: "right",
              marginLeft: "10px",
              marginRight: "0px",
            }}
          >
            <button onClick={handleBtnSaveClicked} className={"common-btn"}>
              Create New Carrier
            </button>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default AddCarrier;
