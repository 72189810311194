import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../common/components/Card";
import ScrollWrapper from "../../common/components/ScrollBar";

import {
  addProcedureDetails,
  setProcedureDetails,
  saveConditionVariable,
  getConditionVariable,
  editConditionVariable,
  update_icd_rule,
  update_plan_condition,
} from "../../store/actions";

import ConditionSearch from "./conditionDetails/ConditionSearch";
import ProviderBlock from "./conditionDetails/ConditionProviders";
import PlanSuggestionBlock from "./conditionDetails/PlanSuggestion";
import VariableCaptureBlock from "./conditionDetails/VariableCapture";
import DocumentationBlock from "./conditionDetails/TemplateDocumentation";
import UltrasoundBlock from "./conditionDetails/FollowUpUltrasounds";
import ICDCodeLogicBlock from "./conditionDetails/ICDCodeLogic";
import "./style.scss";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ConditionDetail = (props) => {
  const [activeCard, setActiveCard] = useState('');
  const [resource, setResource] = useState(null);
  const [isDxRanges, setIsDxRanges] = useState(false);
  const conditionVariable = useSelector(
    (state) => state.procedureDetail.conditionVariable
  );
  const dispatch = useDispatch();
  const selectedReferralCondition = useSelector(
    (state) => state.adminReferralCondition.selectedReferralCondition
  );
  const condition_type = useSelector(
    (state) => state.procedureDetail.condition_type
  );

  const id_procedure_type_icd = cookies.get("id_procedure_type_icd");

  const icdCodes = useSelector((state) => state.procedureDetail.icdCodes);
  const plans = useSelector((state) => state.procedureDetail.plans);

  const toggleDxRanges = () => {
    setIsDxRanges(!isDxRanges);
  }

  const handleAddNewData = async (type, data) => {
    if (
      selectedReferralCondition &&
      Object.keys(selectedReferralCondition).length > 0
    ) {
      await dispatch(addProcedureDetails(type, data));
      await dispatch(
        saveConditionVariable({
          question: "",
          condition_id: selectedReferralCondition.id,
          answers: [],
          cpt_code: [],
        })
      );
      await dispatch(getConditionVariable(selectedReferralCondition.id));
    }
  };
  const update = async () => {
    if (
      selectedReferralCondition &&
      Object.keys(selectedReferralCondition).length > 0
    ) {
      const index = conditionVariable && conditionVariable.length;
      if (index > 0) {
        conditionVariable[`${index - 1}`]["answer"] = [
          ...conditionVariable[`${index - 1}`]["answer"],
          {
            answer: "",
            text_template: "",
          },
        ];
        await dispatch(
          setProcedureDetails("conditionVariable", [...conditionVariable])
        );
        // call api save answer
        await dispatch(
          editConditionVariable(
            condition_type && condition_type[condition_type.length - 1]["id"],
            {
              variable_name:
                condition_type &&
                condition_type[condition_type.length - 1]["variable_name"],
              question:
                conditionVariable &&
                conditionVariable.length > 0 &&
                conditionVariable[conditionVariable.length - 1]["quest"],
              condition_id: selectedReferralCondition.id,
              answers:
                conditionVariable &&
                conditionVariable.length > 0 &&
                conditionVariable[conditionVariable.length - 1]["answer"],
            }
          )
        );
      }
      await dispatch(getConditionVariable(selectedReferralCondition.id));
    }
  };

  const updateICD = async () => {
    const arrayADD = icdCodes.map((r) => {
      return {
        disease_id: r?.disease_id,
        condition_id: selectedReferralCondition.id,
        disease: r?.disease,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    let param = {
      disease_id: 1,
      condition_id: selectedReferralCondition.id,
      disease: { code: "", short_desc: "", icd_code_name: "" },
      condition_serialize: [
        {
          variable_id: undefined,
          answer: undefined,
          variable_name: undefined,
          comparison: ">=",
          operator: "Select",
        },
      ],
    };
    await dispatch(
      setProcedureDetails(
        "icdCodes",
        arrayADD && Object.keys(arrayADD).length > 0
          ? [...arrayADD, param]
          : [param]
      )
    );
    await dispatch(
      update_icd_rule(id_procedure_type_icd, {
        rules:
          arrayADD && Object.keys(arrayADD).length > 0
            ? [...arrayADD, param]
            : [param],
      })
    );
  };

  const updatePlan = async () => {
    const arrayADD = plans.map((r) => {
      return {
        ...r,
        procedure_type_id: r?.procedure_type_id,
        condition_id: selectedReferralCondition.id,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    let param = {
      side: undefined,
      bundle: undefined,
      procedure_type_id: 1,
      condition_id: selectedReferralCondition.id,
      condition_serialize: [
        {
          variable_id: undefined,
          answer: undefined,
          variable_name: undefined,
          comparison: ">=",
          operator: "Select",
        },
      ],
    };
    await dispatch(
      setProcedureDetails(
        "plans",
        arrayADD && Object.keys(arrayADD).length > 0
          ? [...arrayADD, param]
          : [param]
      )
    );
    await dispatch(
      update_plan_condition(id_procedure_type_icd, {
        rules:
          arrayADD && Object.keys(arrayADD).length > 0
            ? [...arrayADD, param]
            : [param],
      })
    );
  };

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>Procedure Detail | FAACT Vascular</title>
        <meta name="title" content="Procedure Detail | FAACT Vascular" />
      </Helmet>
    );
  };

  return (
    <div
      className="clinicWorkflow__main-section"
      style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}
    >
      <div className="fullContainer has-no-sidebar">
        <ScrollWrapper css="no-padding x-hidden">
          <div
            className="mainContainer"
            style={{
              marginLeft: "-10px",
              paddingTop: "0",
              paddingBottom: "0",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "stretch",
            }}
          >
            <CardBlock
              title="Search Condition"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <ConditionSearch setResource={setResource} />
            </CardBlock>

            <CardBlock
              title="Condition Providers"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <ProviderBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="Follow-Up Ultrasounds"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <UltrasoundBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="Template Documentation"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <DocumentationBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="Variable Capture"
              onClick={() => {setActiveCard('variable_capture')}}
              varient={(activeCard == '' || activeCard == 'variable_capture') ? "half" : "quarter"}
              other=""
              contentPadding="5px"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      selectedReferralCondition &&
                      Object.keys(selectedReferralCondition).length <= 0
                    }
                    className="text-btn"
                    onClick={() => {
                      handleAddNewData("conditionVariable", {
                        quest: "",
                        answer: [],
                      });
                    }}
                  >
                    Add Specification +
                  </button>
                  <button
                    disabled={
                      selectedReferralCondition &&
                      Object.keys(selectedReferralCondition).length <= 0
                    }
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={update}
                  >
                    Add Answer +
                  </button>
                </div>
              }
            >
              <VariableCaptureBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="ICD Code Logic"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      selectedReferralCondition &&
                      Object.keys(selectedReferralCondition).length <= 0
                    }
                    className="text-btn"
                    onClick={() => {
                      resource && toggleDxRanges();
                    }}
                  >
                    Dx Ranges {isDxRanges ? "-" : "+"}
                  </button>
                  <button
                    disabled={
                      selectedReferralCondition &&
                      Object.keys(selectedReferralCondition).length <= 0
                    }
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={updateICD}
                  >
                    Add +
                  </button>
                </div>
              }
              onClick={() => {setActiveCard('icd_code_logic')}}
              varient={activeCard == 'icd_code_logic' ? "half" : "quarter"}
              other=""
              contentPadding="1rem"
            >
              <ICDCodeLogicBlock resource={resource} isDxRanges={isDxRanges} />
            </CardBlock>
            
            <CardBlock
              title="Plan Suggestion"
              onClick={() => {setActiveCard('plan_suggestion')}}
              varient={activeCard == 'plan_suggestion' ? "half" : "quarter"}
              other=""
              contentPadding="1rem"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      selectedReferralCondition &&
                      Object.keys(selectedReferralCondition).length <= 0
                    }
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={updatePlan}
                  >
                    Plan +
                  </button>
                </div>
              }
            >
              <PlanSuggestionBlock resource={resource} />
            </CardBlock>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default ConditionDetail;
