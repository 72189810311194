/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState} from "react";
import TopBar from "../../../common/components/SubTopBar";
import AllPersonalTickets from "./DetailsComponent/PersonalTickets";
import { TabHeaders } from "../constant";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { useSelector } from "react-redux";

const PersonalTickets = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen)
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Personal Support Tickets" subStatus={""} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <ScrollWrapper css="no-padding tab-header-scroll">
              <div style={{ display: "flex" }}>
                {TabHeaders.map((tab, index) => (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? 'active' : ''}`}
                    style={{ width: tab.width }}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
        {activeTab === 0 && 
          <AllPersonalTickets 
            title="support-detail"
          />
        }
        {activeTab === 1 && 
          <AllPersonalTickets 
            title="support-detail"
            filter="open"
          />
        }
        {activeTab === 2 && 
          <AllPersonalTickets 
            title="support-detail"
            filter="closed"
          />
        }
      </div>
    </div>
  );
}

export default PersonalTickets;
