import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, DatePicker } from 'antd';
import moment from 'moment';

import { ExpandSVG, SearchSVG } from '../../../common/components/icons/SVGIcon';
import { getCallback, setStepData, setGlobalLoading } from '../../../store/actions';

const { Search } = Input;
const dateFormat = 'MM/DD/YYYY';

const CallbackFilters = ({handleSearch, searchValue}) => {
  const loadingEvents = useSelector(
    (state) => state.clinicEvents.loadingEvents
  );
  const loadingCallback = useSelector(
    (state) => state.patientCallback.loadingCallback
  );
  const loadingPending = useSelector((state) => state.patientPending.loadingPending);

  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGlobalLoading(
      loadingEvents ||
      loadingCallback ||
      loadingPending
    ))
  }, [
    loadingEvents,
    loadingCallback,
    loadingPending
  ]);

  const onChangeDate = (date, dateString) => {
    dispatch(setStepData('loadingCallback', true));
    dispatch(getCallback({referral_date: dateString}));
  };

  const changeSearch = (value) => {
    setSearch(value);
    handleSearch(value);
  }

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
            placeholder="Search"
            onChange={e => changeSearch(e.target.value)}
            style={{ width: '100%' }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default CallbackFilters;
