import React, { useState, useEffect, useRef } from "react";
import { Icon, Input, Select } from "antd";
import ScrollWrapper from "../ScrollBar";
import Loading from "../Loading";
import { SearchSVG, ExpandSVG, CheckSVG } from "../icons/SVGIcon";
import DeleteIconButton from "../icons/DeleteIconButton";
import { useOutsideDetect } from "../../utils";
import "./style.scss";
import ExtendDataTable from "../SubDataTable/ExtendDataTable";

const { Option } = Select;
const { Search } = Input;
const selectArray = [
  { value: "ASAP", id: "asap" },
  { value: "Within 2 weeks", id: "2_week" },
  { value: "3 months", id: "3_month" },
  { value: "6 months", id: "6_month" },
  { value: "1 year", id: "1_year" },
];

const AutoCompleteDataTable = ({
  rows,
  options,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keyLabel,
  keyValue,
  keyType,
  selectItem,
  disableOptions,
  onclickRow,
  isSelectRow,
  handleValueSelect,
  onChangeSelect,
  valueSelected,
  minRows,
  onScroll,
  isLocked,
  tableTitleOptions,
  onClickTableRow,
  tableData,
  tableOptions,
  tableWidths
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [valueSelectedGlobal, setValueSelectedGlobal] = useState({});
  const [selectRow, setSelectRow] = useState(false);
  const [valueHandleSelect, setValueHandleSelect] = useState([]);
  const [toDelete, setToDelete] = useState(-1);

  const [openResult, setOpenResult] = useState(false);
  const data = rows ? rows : [];
  const optionData = options ? options : [];
  const minRowCount = minRows ? minRows : 4;
  let emptyRows = [];

  if (data.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - data.length).fill({ label: "" });
  }

  useEffect(() => {
    setValueSelectedGlobal(valueSelected);
  }, [valueSelected]);

  useEffect(() => {
    setValueHandleSelect(handleValueSelect);
  }, [handleValueSelect]);

  useEffect(() => {
    setSelectRow(isSelectRow);
  }, [isSelectRow]);

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setSelected(row[keyLabel]);
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keyLabel]);
    if (title === "previous-medical-cpt") {
      setSearch(`${row[keyLabel]} ${row[keyType]}`);
    }
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (selectItem) {
      selectItem(selected, selectedValue, selectedType);
    } else {
      if (addItem) {
        addItem();
      }
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target.value);
    if (handleSearch) {
      handleSearch(e.target.value);
    }
  };

  const clickHandle = (row, index) => {
    if (onclickRow) {
      onclickRow(row, index);
    }
  };

  const selectHandle = (value, index) => {
    setValueSelectedGlobal({
      ...valueSelectedGlobal,
      [index]: value,
    });
    onChangeSelect(value, index);
  };

  const getTableTitle = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {tableTitleOptions.map((row, index) => {
          if (index == 0) {
            return <div style={{
              width: "25%",
              textAlign: "left",
              color: "rgba(0, 0, 0, 0.65)",
              fontWeight: 600,
              display: "flex",
              height: "100%",
              borderTop: "1px solid #dfe3e6",
              alignItems: "center",
              fontSize: "14px",
              minWidth: tableWidths[index]
            }}>{row}</div>
          }

          return <div style={{
            width: "25%",
            textAlign: "left",
            paddingLeft: "11px",
            color: "rgba(0, 0, 0, 0.65)",
            fontWeight: 600,
            borderLeft: "1px solid #dfe3e6",
            borderTop: "1px solid #dfe3e6",
            display: "flex",
            height: "100%",
            alignItems: "center",
            fontSize: "14px",
            minWidth: tableWidths[index]
          }}>{row}</div>
        })}
      </div>
    );
  };

  return (
    <div className="atuoComplete">
      <div className="atuoComplete__filter">
        <div
          ref={ref}
          className={`atuoComplete__filter-search origin ${
            disableSearchIcon ? "disable-icon" : ""
          }`}
        >
          <Search
            value={search}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{ width: "100%", height: "42.5px" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        <div
          className={`atuoComplete__filter-action ${
            isLocked === true || isLocked === 1 ? "disabled" : ""
          }`}
        >
          <button
            className="atuoComplete__filter-add"
            onClick={addSelectedItem}
            disabled={isLocked}
          >
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
        {!disableOptions && openResult && !!search && (
          <div ref={ref1} className="atuoComplete__filter-result">
            <div className="atuoComplete__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper
                onScrollFrame={
                  onScroll
                    ? (value) => {
                        onScroll(value);
                      }
                    : null
                }
                css="no-padding"
              >
                {!!optionData.length &&
                  optionData.map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`empty-${title}-${index}`}
                      onClick={() => handleSelectItem(row)}
                    >
                      {title === "previous-medical-cpt" ? (
                        <p style={{ paddingLeft: "28px" }}>
                          {row[keyLabel]} {row[keyType]}
                        </p>
                      ) : (
                        <p style={{ paddingLeft: "28px" }}>{row[keyLabel]}</p>
                      )}
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>

      <div className="atuoComplete__result" style={{paddingTop: '1rem'}}>
          <ExtendDataTable
            title={getTableTitle()}
            onClickRow={onClickTableRow}
            data={tableData}
            options={tableOptions}
            widths={tableWidths}
            minRows={9}
          />
      </div>
    </div>
  );
};

export default AutoCompleteDataTable;
