import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";

import {
  LeftCharvenSVG,
} from "../../components/icons/SVGIcon";
import Loading from "../../components/Loading";
import DataTable from "../../components/DataTable/DataTable";
import { getClinicWorkflowAppointments, setSidebarState } from "../../../store/actions";
import Sidebar from "./sidebar";
import SidebarExtension from "../../components/SidebarExtension";
import { Icon } from "antd";

const cookies = new Cookies();

const Medications = ({ data, title, display }) => {
  const [activeIndex, setRow] = useState(-1);
  const [isSidebar, setIsSidebar] = useState(false);
  const dispatch = useDispatch();
  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (row, index) => {
    setRow(index);
    handleSidebar();
  };

  const appointments = useSelector(
    (state) => state.procedure.patientProcedures
  );

  const patient_id = cookies.get("current_patient_id");

  const fetchAPI = () => {
    dispatch(
      getClinicWorkflowAppointments({ patient_id: patient_id, paging: 1 })
    );
  };

  const [tableLoad, setTableLoads] = useState([]);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad) {
        setLoading(true);
        const listUpdate = await dispatch(
          getClinicWorkflowAppointments({
            patient_id: patient_id,
            paging: page + 1,
          })
        );
        if (listUpdate) {
          if (appointments && appointments.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (appointments.length > 0) {
      if (page > 1) {
        setTableLoads([...tableLoad, ...appointments]);
        setLoading(false);
      } else {
        setTableLoads([...appointments]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [appointments]);
  const sidebarRef = useRef();
  return (
    <div
      className="clinicWorkflow__main-section"
    >
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          {loading && <Loading />}
          <DataTable
            title="appointment_table"
            tableWidth={1060}
            labels={["Date and Time", "Appointment Type (CPT Code)", "ICD Code", "Location", "Bill Date"]}
            widths={["20%", "22%", "18%", "20%", "20%"]}
            columns={[
              { sortable: false, key: "date_time" },
              { sortable: false, key: "cpt_code" },
              { sortable: false, key: "icd_code" },
              { sortable: false, key: "location" },
              { sortable: false, key: "bill_date" },
            ]}
            sorts={["","","","",""]}
            rowData={(tableLoad || []).map((row, index) => ({
              id: row.id,
              date_time: row.appointment_date &&
                moment(row.appointment_date).format("MM/DD/YYYY hh:mm A"),
              cpt_code: row.procedure_type && row.procedure_type.value,
              icd_code: row.icd_codes,
              location: row.location && row.location.value,
              bill_date: row.charge_date &&
                moment(row.charge_date).format("MM/DD/YYYY hh:mm A"),
            }))}
            disableIcon={true}
            fetchList={fetchAPI}
            loadMore={loadMore}
            handleClickRow={handleRow}
            defaultActive={-1}
            disableDelete={true}
          />
        </div>
      </div>

      <Sidebar
        sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
        ref={sidebarRef}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />

      <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>

    </div>
  );
};

export default Medications;
