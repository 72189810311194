import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import TopBar from "../../common/components/SubTopBar";
import Endovascular from "./components/PCMComponents/Endovascular";
import Overview from "./components/PCMComponents/Overview";
import NoneEndovascular from "./components/PCMComponents/NoneEndovascular";
import ProviderReport from "./components/PCMComponents/ProviderReport";
import Summary from "./components/PCMComponents/Summary";
// import { PCMHeaders, Children } from "./constant";
import { setCommonData } from "../../store/actions";
import {
  CheckSVG,
  RedRequiredCircleSVG,
  RedCloseSVG,
  GearIconSVG,
  CreditReceivedSVG,
  GreenGearIconSVG
} from "../../common/components/icons/SVGIcon";
import { FemaleSVG, MaleSVG } from "../../common/components/icons/FaxSVG";
import { Icon } from "antd";

const Billing = ({location}) => {
  const pcmDetail = useSelector((state) => state.common.pcmDetail);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  const pcm = useSelector((state) => state.common.pcmFilter);
  const pcm_overview = useSelector((state) => state.common.pcm_overview);

  const Children = {
    summary: Summary,
    overview: Overview,
    nonEndovascular: NoneEndovascular,
    providerReport: ProviderReport,
    endovasculars: Endovascular,
  };

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    dispatch(setCommonData("pcmDetail", null));
  }, [activeTab]);

  const renderSideComponent = () => {
    if (!pcmDetail || !Object.keys(pcmDetail).length) {
      return null;
    }
    if (activeTab === 2) {
      return null;
    }

    const fullName = pcmDetail.patient_name.toUpperCase();
    const age = pcmDetail.patient_dob
      ? "(" +
        moment().diff(pcmDetail.patient_dob, "years") +
        // " yo" +
        // (!!pcmDetail.gender ? pcmDetail.gender.toUpperCase() : "MALE") +
        ")"
      : "";

    return (
      <>
        <div
          style={{
            display: 'flex',
            textAlign: "left",
            paddingTop: "0px",
            paddingRight: "12px",
            paddingLeft: "6px",
          }}
        >
          <div
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "26px",
              whiteSpace: "nowrap",
            }}
          >
            {fullName} {age}
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                fontSize: "0.625rem",
                color: '#da1e28',
                fontWeight: 600,
              }}
            >
              {pcmDetail.no_drug_allergies ? 'NKDA' : 'Pending Data'}
            </div>
          </div>
          {pcmDetail.gender && pcmDetail.gender.toUpperCase() == 'MALE' ? 
            <MaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/> :
            <FemaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/>
          }
        </div>
      </>
    );
  };

  const getActivePath = (path) => {
    const paths = path.split('/');
    if (paths.length) {
      return paths[paths.length - 1];
    }
    return '';
  }

  const activePath = getActivePath(location.pathname);
  const ActiveContent = Children[activePath];

  const renderSubStatus = () => {
    let greenCount = 0;
    let correctionCount = 0;
    let researchCount = 0;
    let paymentPostingCount = 0;
    let contactedCount = 0;
    let patientBalanceCount = 0;
    let adjustOffCount = 0;

    switch (activePath) {
      case 'overview': // Overview Tab
          greenCount = pcm_overview.count_green;
          correctionCount = pcm_overview.count_correction;
          researchCount = pcm_overview.count_research;
          paymentPostingCount = pcm_overview.count_payment_posting;
          contactedCount = pcm_overview.count_contacted;
          patientBalanceCount = pcm_overview.count_patient_balance;
          adjustOffCount = pcm_overview.count_adjust_off;
        break;
      default:
          greenCount = pcm.count_green;
          correctionCount = pcm.count_red;
          adjustOffCount = pcm.count_redx;
        break;
    }
    if (activePath == 'overview')
    {
      return (

          // Green Checkmark, Red Exclamation, Blue Sproket, Green Sprocket, Dollar, Blue Man, Red Circle Line
          <div className="statusHealth">
            <div className="has-tooltip">
              <Icon type="clock-circle" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#24A148"/>
              <span
                className="tooltip tooltip-without-border"
                role="tooltip"
                style={{zIndex: 5}}
                >
                {"Less Than 30 Days"}
              </span>
            </div>
            {greenCount}
            
            <span style={{ minWidth: '10px', display: 'inline-block' }} />
            <div className="has-tooltip">
              <Icon type="warning" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#FF832B"/>
              <span
                className="tooltip tooltip-without-border"
                role="tooltip"
                style={{zIndex: 5}}
                >
                {"Correction Team"}
              </span>
            </div>
            {correctionCount}

            <span style={{ minWidth: '10px', display: 'inline-block' }} />
            <div className="has-tooltip">
              <Icon type="phone" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#005ef6"/>
              <span
                className="tooltip tooltip-without-border"
                role="tooltip"
                style={{zIndex: 5}}
                >
                {"Research Team - Action Required"}
              </span>
            </div>
            {researchCount}

            <span style={{ minWidth: '10px', display: 'inline-block' }} />
            <div className="has-tooltip">
              <Icon type="phone" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#DA1E28"/>
              <span
                className="tooltip tooltip-without-border"
                role="tooltip"
                style={{zIndex: 5}}
                >
                {"Research Team - Call Logged"}
              </span>
            </div>
            {contactedCount}

            <span style={{ minWidth: '10px', display: 'inline-block' }} />
            <div className="has-tooltip">
              <Icon type="dollar" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#F1C21B"/>
              <span
                className="tooltip tooltip-without-border"
                role="tooltip"
                style={{zIndex: 5}}
                >
                {"Payment Pending"}
              </span>
            </div>
            {paymentPostingCount}

            <span style={{ minWidth: '10px', display: 'inline-block' }} />
            <div className="has-tooltip">
              <Icon type="account-book" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#ff7eb6"/>
              <span
                className="tooltip tooltip-without-border"
                role="tooltip"
                style={{zIndex: 5}}
                >
                {"Collection"}
              </span>
            </div>
            {patientBalanceCount}

            <span style={{ minWidth: '10px', display: 'inline-block' }} />
            <div className="has-tooltip">
              <Icon type="thunderbolt" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#6929C4"/>
              <span
                className="tooltip tooltip-without-border"
                role="tooltip"
                style={{zIndex: 5}}
                >
                {"Adjust Off"}
              </span>
            </div>
            {adjustOffCount}

            <span style={{ minWidth: '10px', display: 'inline-block' }} />
            <div className="has-tooltip">
              <Icon type="file-excel" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#DA1E28"/>
              <span
                className="tooltip tooltip-without-border"
                role="tooltip"
                style={{zIndex: 5}}
                >
                {"Billing Error"}
              </span>
            </div>
            {0}
          </div>

      );
    } else {
      return (

          // Green Checkmark, Red Exclamation, Blue Sproket, Green Sprocket, Dollar, Blue Man, Red Circle Line
          <div className="statusHealth">
            <Icon type="clock-circle" theme="twoTone" twoToneColor="#24A148"/>
            {greenCount}
            <span style={{ minWidth: '10px', display: 'inline-block' }} />
            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
            {correctionCount}
            <span style={{ minWidth: '10px', display: 'inline-block' }} />
          </div>

      );
    }

  };

  const renderTitle = () => {
    if (activePath == 'overview')
    {
      return 'Overview';
    } else if (activePath == 'endovasculars')
    {
      return 'Endovascular';
    } else if (activePath == 'nonEndovascular')
    {
      return 'Non-Endovascular';
    } else {
      return 'Provider Report';
    }
  };

  return (
    <div className={`clinicWorkflow__main ${isSidebar ? 'openSidebar' : ''}`}>
      <TopBar
        title={renderTitle()}
        subStatus={renderSubStatus()}
        sideComponent={renderSideComponent()}
        isBarcode={false}
      />
      <div className="clinicWorkflow__main-container">
        <ActiveContent />
      </div>
    </div>
  );
};

export default Billing;
