import React, { useState } from "react";
import { Input, Select, DatePicker } from "antd";
import moment from "moment";
import {
  ExpandSVG,
  SearchSVG,
} from "../../../../common/components/icons/SVGIcon";

const { Search } = Input;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const ProviderReportFilter = ({
  valueSearch,
  providers,
  changeDate,
  startDate,
  endDate,
  changeUserProvider,
}) => {
  const [search, setSearch] = useState("");
  const changeSearch = (value) => {
    setSearch(value);
    valueSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
            placeholder="Search"
            onChange={(e) => changeSearch(e.target.value)}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <Select
            placeholder="Filter by Provider"
            suffixIcon={<ExpandSVG />}
            onChange={changeUserProvider}
            allowClear={true}
          >
            {providers.map((items) => {
              return (
                <Option key={items.id} value={items.id}>
                  {items.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <DatePicker
            format={dateFormat}
            value={
              startDate && startDate !== "Invalid date"
                ? moment(startDate, "MM/DD/YYYY")
                : undefined
            }
            onChange={(value) => {
              if (value && value !== "Invalid date") {
                changeDate(value, "start");
              } else {
                changeDate(undefined, "start");
              }
            }}
            placeholder="Filter by Start Date"
            suffixIcon={<ExpandSVG />}
          />
        </div>
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <DatePicker
            format={dateFormat}
            value={
              endDate && endDate !== "Invalid date"
                ? moment(endDate, "MM/DD/YYYY")
                : undefined
            }
            onChange={(value) => {
              if (value && value !== "Invalid date") {
                changeDate(value, "end");
              } else {
                changeDate(undefined, "end");
              }
            }}
            placeholder="Filter by End Date"
            suffixIcon={<ExpandSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default ProviderReportFilter;
