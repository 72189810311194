import React, { useState, useEffect, useRef } from "react";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import VacationRequestSidebar from "./VacationRequestSidebar";
import { useWindowSize } from "../../../../common/utils";
import { StaticResource, DashboardRows } from "../../constant";
import {
  CheckSVG,
  RedCloseSVG,
  LeftCharvenSVG,
} from "../../../../common/components/icons/SVGIcon";
import {
  timeoff_requests_all,
  timeoff_detail_requests,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";

const VacationRequest = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [tableRows, setTableRows] = useState([]);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const dispatch = useDispatch();

  const timeRequests = useSelector(
    (state) => state.common.timeoff_requests_all
  );

  useEffect(() => {
    setTableRows(timeRequests);
  }, [timeRequests]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, id) => {
    setRow(index);
    handleSidebar();
  };

  const fetchList = () => {
    dispatch(timeoff_requests_all());
  };

  const timeRemaining = (id) => {
    var a = 0;
    if (timeRequests.length > 0) {
      for (const item in timeRequests) {
        if (timeRequests[item].user_id === id) {
          a += Number(timeRequests[item].amount_of_hours);
        }
      }
    }
    return a;
  };

  useEffect(() => {
    fetchList();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="vacation_request"
                labels={[
                  "Employee Name",
                  'Start Date / Time',
                  'End Date / Time',
                  'Reason'
                ]}
                widths={['calc(31% - 2rem)', '23%', '23%', '23%']}
                columns={[
                  { sortable: false, key: 'name' }, 
                  { sortable: false, key: 'start' }, 
                  { sortable: false, key: 'end' }, 
                  { sortable: false, key: 'reason' }, 
                ]}
                disableDelete={true}
                handleClickRow={(row, index) => handleRow(index, row)}
                defaultActive={-1}
                rowData={tableRows.map((row, index) => {
                  return {
                    name: (
                      <div className="td with-icon">
                        {!row.is_approved ? (
                          <div className="iconField" style={{ height: "24px" }}>
                            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
                          </div>
                        ) : null}
                        {row.is_approved ? (
                          <div className="iconField" style={{ height: "24px" }}>
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
                          </div>
                        ) : null}
                        <p>{row.user && row.user.name}</p>
                      </div>
                    ),
                    start: row.start_time,
                    end: row.end_time,
                    reason: row.reason
                  }
                })}
              />
            </div>
          </div>
        </div>
      </div>

      {timeRequests && timeRequests.length > 0 && activeIndex >= 0 ? (
        <VacationRequestSidebar
          ref={sidebarRef}
          timeDetail={timeRequests[activeIndex]}
          timeRemaining={timeRemaining(
            timeRequests[activeIndex] && timeRequests[activeIndex].user_id
          )}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      ) : null}
      {timeRequests && timeRequests.length > 0 && activeIndex >= 0 && (
        
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default VacationRequest;
