import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDetailUltrasoundProps,
  save_ultrasound_rule_result,
} from "../../../../store/actions";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const ClinicalIndication = ({ resource, ...restProps }) => {
  const [indications, setIndications] = useState([]);
  const [indication, setIndication] = useState();
  const apointment_ultrasound_id = cookies.get("apointment_ultrasound_id");
  // const ultrasounds = useSelector((state) => state.common.ultrasounds);
  const dispatch = useDispatch();
  const detailUltrasounds = useSelector(
    (state) => state.common.detailUltrasounds
  );
  const detail_ultrasound_props = useSelector(
    (state) => state.common.detail_ultrasound_props
  );
  const is_locked = useSelector(
      (state) => state.clinicProviderReview.is_locked
  );

  useEffect(() => {
    setIndication(detail_ultrasound_props?.disease_id);
  }, [detail_ultrasound_props]);

  useEffect(() => {
    if (detailUltrasounds && detailUltrasounds.codes) {
      setIndications(detailUltrasounds.codes);
    }
  }, [detailUltrasounds]);

  const onchangeValue = async (value) => {
    await dispatch(
      setDetailUltrasoundProps("detail_ultrasound_props", {
        ...detail_ultrasound_props,
        disease_id: value,
      })
    );
    await dispatch(
      save_ultrasound_rule_result(apointment_ultrasound_id, {
        left_result: detail_ultrasound_props?.result_l,
        right_result: detail_ultrasound_props?.result_r,
        left_conclusion: detail_ultrasound_props?.conclusion_l,
        right_conclusion: detail_ultrasound_props?.conclusion_r,
        disease_id: value,
      })
    );
    setIndication(value);
  };

  return (
    <div className="resourceContent pr-7px">
      <div className="resourceInfo">
        <EditableFormInput
          label={"Clinical Indication"}
          value={indication}
          type={"check"}
          options={indications || []}
          optionKey={"id"}
          valueKey={"icd_code_name"}
          // required={true}
          // enableErrorText={true}
          // isError={!indication}
          // helperText={"Clinical Indication is required"}
          handleChange={(value) => {
            onchangeValue(value);
          }}
          disabled={is_locked}
        />
      </div>
    </div>
  );
};

export default ClinicalIndication;
