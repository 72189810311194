import { createReducer } from "redux-create-reducer";
import AT from "../actionTypes";
const initialState = {
  loading: false,
  supports: []
}

const GET_ALL_SUPPORT_MODULES_STARTED = `${AT.GET_ALL_SUPPORT_MODULES}_STARTED`;
const GET_ALL_SUPPORT_MODULES_SUCCEEDED = `${AT.GET_ALL_SUPPORT_MODULES}_SUCCEEDED`;

const GET_SUPPORT_MODULES_STARTED = `${AT.GET_SUPPORT_MODULES}_STARTED`;
const GET_SUPPORT_MODULES_SUCCEEDED = `${AT.GET_SUPPORT_MODULES}_SUCCEEDED`;


const CREATE_NEW_SUPPORT_MODULE_STARTED = `${AT.CREATE_NEW_SUPPORT_MODULE}_STARTED`;
const CREATE_NEW_SUPPORT_MODULE_SUCCEEDED = `${AT.CREATE_NEW_SUPPORT_MODULE}_SUCCEEDED`;

const UPDATE_SUPPORT_MODULE_STARTED = `${AT.UPDATE_SUPPORT_MODULE}_STARTED`;
const UPDATE_SUPPORT_MODULE_SUCCEEDED = `${AT.UPDATE_SUPPORT_MODULE}_SUCCEEDED`;

const DELETE_SUPPORT_MODULE_STARTED = `${AT.DELETE_SUPPORT_MODULE}_STARTED`;
const DELETE_SUPPORT_MODULE_SUCCEEDED = `${AT.DELETE_SUPPORT_MODULE}_SUCCEEDED`;

const CLOSE_SUPPORT_MODULE_STARTED = `${AT.CLOSE_SUPPORT_MODULE}_STARTED`;
const CLOSE_SUPPORT_MODULE_SUCCEEDED = `${AT.CLOSE_SUPPORT_MODULE}_SUCCEEDED`;

const UPDATE_SUPPORT_MODULE_HISTORY_STARTED = `${AT.UPDATE_SUPPORT_MODULE_HISTORY}_STARTED`;
const UPDATE_SUPPORT_MODULE_HISTORY_SUCCEEDED = `${AT.UPDATE_SUPPORT_MODULE_HISTORY}_SUCCEEDED`;

export default createReducer(initialState, {
  [GET_ALL_SUPPORT_MODULES_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [GET_ALL_SUPPORT_MODULES_SUCCEEDED](state, action) {
    if (action.payload) {
      return {
        ...state,
        loading: false,
        supports: action.payload.supports
      };
    }
    return {
      ...state,
      loading: false,
    };
  },
  [GET_SUPPORT_MODULES_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [GET_SUPPORT_MODULES_SUCCEEDED](state, action) {
    if (action.payload) {
      return {
        ...state,
        loading: false,
        supports: action.payload.support
      };
    }
    return {
      ...state,
      loading: false,
    };
  },
  [CREATE_NEW_SUPPORT_MODULE_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [CREATE_NEW_SUPPORT_MODULE_SUCCEEDED](state, action) {
    if (action.payload) {
      const data = action.payload.data;
      return {
        ...state,
        loading: false,
        supports: [
          ...state.supports,
          ...data
        ]
      };
    }
    return {
      ...state,
      loading: false,
    };
  },
  [UPDATE_SUPPORT_MODULE_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [UPDATE_SUPPORT_MODULE_SUCCEEDED](state, action) {
    let tmp = [];
    const data = action.payload.data;
    for(let obj of state.supports) {
      if (obj.id === data[0].id) {
        tmp.push(data[0]);
      } else {
        tmp.push(obj);
      }
    }
    return {
      ...state,
      loading: false,
      supports: [...tmp]
    };
  },
  [DELETE_SUPPORT_MODULE_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [DELETE_SUPPORT_MODULE_SUCCEEDED](state, action) {
    const tmpData = state.supports.filter(e => e.id !== action.payload.data[0].id);
    return {
      ...state,
      loading: false,
      supports: [
        ...tmpData
      ]
    };
  },
  [CLOSE_SUPPORT_MODULE_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [CLOSE_SUPPORT_MODULE_SUCCEEDED](state, action) {
    state.supports.forEach(element => {
      if (element.id === action.payload.data[0].id) {
        element.status = action.payload.data[0].status;
      }
    });
    return {
      ...state,
      loading: false,
      supports: [
        ...state.supports
      ]
    };
  },
  [UPDATE_SUPPORT_MODULE_HISTORY_STARTED](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [UPDATE_SUPPORT_MODULE_HISTORY_SUCCEEDED](state, action) {
    state.supports.forEach(element => {
      if (element.id === action.payload.data[0].support_id) {
        for(let obj of element.history) {
          if (obj.id === action.payload.data[0].id) {
            obj.comment = action.payload.data[0].comment;
          }
        }
      }
    });
    return {
      ...state,
      loading: false,
      supports: [
        ...state.supports
      ]
    };
  }
});
