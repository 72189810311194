import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ScrollWrapper from "../../../../../common/components/ScrollBar";
import CommonDocumentUpload from "../../../../../common/components/DocumentUpload/CommonDocumentUpload";
import { adminSettingSaveOCRInfo } from "../../../../../store/actions";

const DocumentTemplates = ({resource}) => {
  const dispatch = useDispatch();
  const [newPatientDocument, setNewPatientDocument] = useState("");
  const [inPatientDocument, setInPatientDocument] = useState("");
  const [outPatientDocument, setOutPatientDocument] = useState("");

  useEffect(() => {
    if (!resource.loading && !!resource.new_patient_document) {
      setNewPatientDocument("File Uploaded!");
    }
    if (!resource.loading && !!resource.in_patient_document) {
      setInPatientDocument("File Uploaded!");
    }
    if (!resource.loading && !!resource.out_patient_document) {
      setOutPatientDocument("File Uploaded!");
    }
  }, [resource]);
  return (
    <div
      className="documentTemplates"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <ScrollWrapper css="no-padding x-hidden">
        <div style={{ marginTop: '0.5rem' }}>
          <CommonDocumentUpload title="New Patient Documents" data={resource && resource.new_patient_document} />
          {/* <div style={{display: "block", width: "100%", marginTop: "5px"}}>{newPatientDocument}</div> */}
        </div>
        <div style={{ marginTop: '10px' }}>
          <CommonDocumentUpload title="Hospital Documents" data={resource && resource.in_patient_document} />
          {/* <div style={{display: "block", width: "100%", marginTop: "5px"}}>{inPatientDocument}</div> */}
        </div>
        <div style={{ marginTop: '10px' }}>
          <CommonDocumentUpload title="Out-Patient Documents" data={resource && resource.out_patient_document} />
          {/* <div style={{display: "block", width: "100%", marginTop: "5px"}}>{outPatientDocument}</div> */}
        </div>
      </ScrollWrapper>
    </div>
  );
}

export default DocumentTemplates;
