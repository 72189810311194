import React from "react";

const TableOutput = (props) => {
  const {
    row,
    column,
    caption,
    cell,
    detailUltrasounds,
    ultrasound_finding,
    ultrasound_value,
  } = props;
  const rows = [];
  const theadRow = [];
  const theadCol = [];

  const rowsL = [];
  let arrayRow = [];
  let arrayLabel = [];

  const [coordinate, setCoordinate] = React.useState([]);

  for (let i = 0; i < column; i += 1) {
    theadCol.push(<th key={i}>{cell[0][i]}</th>);
  }
  theadRow.push(
    <thead key="thead">
      <tr>{theadCol}</tr>
    </thead>
  );

  if (row > 1) {
    for (let i = 1; i < row; i += 1) {
      const cols = [];
      for (let j = 0; j < column; j += 1) {
        cols.push(
          <td style={{ width: 100 }} key={`${i} ${j}`}>
            {cell && cell[i] && cell[i][j]}
          </td>
        );
      }
      rows.push(<tr key={i}>{cols}</tr>);
    }
  }

  arrayRow = ultrasound_value.filter((r) => r.finding_name.includes("Left"));
  arrayRow = arrayRow.map((r) => {
    return {
      label: r.finding_name.replace("Left ", ""),
      [`${detailUltrasounds.headers[0]}`]: r[`${detailUltrasounds.headers[0]}`],
      [`${detailUltrasounds.headers[1]}`]: r[`${detailUltrasounds.headers[1]}`],
      [`${detailUltrasounds.headers[2]}`]: r[`${detailUltrasounds.headers[2]}`],
      [`${detailUltrasounds.headers[3]}`]: r[`${detailUltrasounds.headers[3]}`],
      [`${detailUltrasounds.headers[4]}`]: r[`${detailUltrasounds.headers[4]}`],
      [`${detailUltrasounds.headers[5]}`]: r[`${detailUltrasounds.headers[5]}`],
      [`${detailUltrasounds.headers[6]}`]: r[`${detailUltrasounds.headers[6]}`],
      [`${detailUltrasounds.headers[7]}`]: r[`${detailUltrasounds.headers[7]}`],
    };
  });

  if (cell[0].some((r) => r == "PSV" || r == "EDV")) {
    for (let i = 0; i < arrayRow.length; i += 1) {
      arrayLabel = {
        ...arrayLabel,
        [0]: cell[0],
        [i + 1]: [
          arrayRow[i]["label"],
          arrayRow[i] &&
          arrayRow[i][`${detailUltrasounds.headers[0]}`] &&
          arrayRow[i][`${detailUltrasounds.headers[0]}`].length > 0 &&
          arrayRow[i][`${detailUltrasounds.headers[0]}`].includes("/")
            ? arrayRow[i][`${detailUltrasounds.headers[0]}`].split("/")[0]
            : "",
          arrayRow[i] &&
          arrayRow[i][`${detailUltrasounds.headers[0]}`] &&
          arrayRow[i][`${detailUltrasounds.headers[0]}`].length > 0 &&
          arrayRow[i][`${detailUltrasounds.headers[0]}`].includes("/")
            ? arrayRow[i][`${detailUltrasounds.headers[0]}`].split("/")[1]
            : "",
          arrayRow[i][`${detailUltrasounds.headers[1]}`],
          arrayRow[i][`${detailUltrasounds.headers[2]}`],
          arrayRow[i][`${detailUltrasounds.headers[3]}`],
          arrayRow[i][`${detailUltrasounds.headers[4]}`],
          arrayRow[i][`${detailUltrasounds.headers[5]}`],
          arrayRow[i][`${detailUltrasounds.headers[6]}`],
          arrayRow[i][`${detailUltrasounds.headers[7]}`],
        ],
      };
    }
  } else {
    for (let i = 0; i < arrayRow.length; i += 1) {
      arrayLabel = {
        ...arrayLabel,
        [0]: cell[0],
        [i + 1]: [
          arrayRow[i]["label"],
          arrayRow[i][`${detailUltrasounds.headers[0]}`],
          arrayRow[i][`${detailUltrasounds.headers[1]}`],
          arrayRow[i][`${detailUltrasounds.headers[2]}`],
          arrayRow[i][`${detailUltrasounds.headers[3]}`],
          arrayRow[i][`${detailUltrasounds.headers[4]}`],
          arrayRow[i][`${detailUltrasounds.headers[5]}`],
          arrayRow[i][`${detailUltrasounds.headers[6]}`],
          arrayRow[i][`${detailUltrasounds.headers[7]}`],
        ],
      };
    }
  }

  if (arrayRow.length + 1 > 1) {
    for (let i = 1; i < arrayRow.length + 1; i += 1) {
      const cols = [];
      for (let j = 0; j < column; j += 1) {
        cols.push(
          <td style={{ width: 100 }} key={`${i} ${j}`}>
            {arrayLabel && arrayLabel[i] && arrayLabel[i][j]}
          </td>
        );
      }
      rowsL.push(<tr key={i}>{cols}</tr>);
    }
  }

  return (
    <div>
      <table className="hover-table">
        <caption style={{ fontWeight: 500, color: "black" }}>Right</caption>
        {theadRow}
        <tbody>{rows}</tbody>
      </table>
      <table className="hover-table">
        <caption style={{ fontWeight: 500, color: "black" }}>Left</caption>
        {theadRow}
        <tbody>{rowsL}</tbody>
      </table>
    </div>
  );
};

const TableBlock = (props) => {
  const {
    contentState,
    block,
    blockProps,
    detailUltrasounds,
    ultrasound_finding,
    ultrasound_value,
  } = props;
  const entity = contentState.getEntity(block.getEntityAt(0));
  const shape = entity.getData();

  return (
    <TableOutput
      detailUltrasounds={detailUltrasounds}
      ultrasound_finding={ultrasound_finding}
      ultrasound_value={ultrasound_value}
      row={shape.row}
      column={shape.column}
      caption={shape.caption}
      cell={shape.cell}
      block={block}
      blockProps={blockProps}
    />
  );
};

export default TableBlock;
