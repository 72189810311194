import React from "react";
import AutoCompleteComponent from "../../../common/components/AutoComplete";
import { connect } from "react-redux";
import {
  save_patient_notes_billing,
  get_patient_notes_billing,
  delete_patient_notes_billing,
  setPriorData,
  setGlobalLoading,
} from "../../../store/actions";
import Cookies from "universal-cookie";
import { Checkbox } from "antd";
const cookies = new Cookies();
class NotesComponent extends React.Component {
  state = {
    rows: [],
    rowID: [],
    searchInput: "",
    logCorrespondence: false,
    current_patient_id: cookies.get("cvai-current-user"),
  };

  async componentDidMount() {
    const { detailPCM } = this.props;
    if (detailPCM) {
      this.props.setGlobalLoading(true);
      await this.props.get_patient_notes_billing(detailPCM.id);
      this.props.setGlobalLoading(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.detailPCM.id != this.props.detailPCM.id) {
      this.props.get_patient_notes_billing(nextProps.detailPCM.id);
    }
    let { rows, rowID } = this.state;
    this.setState({ ...this.state });
    if (nextProps.patientsNoteBilling.length) {
      if (nextProps.patientsNoteBilling.length != rows.length) {
        rows = [];
        rowID = [];
        for (let notes of nextProps.patientsNoteBilling) {
          rows.push({ note: `${notes?.note}`, name: `${notes?.user?.name}` });
          rowID.push(`${notes?.id}`);
        }
        this.setState({
          ...this.state,
          rows: [...rows],
          rowID: [...rowID],
        });
      }
    } else {
      this.setState({
        rows: [],
        rowID: [],
      });
    }
  }

  render() {
    let { rows } = this.state;
    return (
      <div className="resourceContent">
        {this.props.detailPCM.icon_overview_status === 'research' && (
          <div className="resourceInfo d-flex">
            <Checkbox checked={this.state.logCorrespondence} onClick={(val) => this.setLogCorrespondence(val.target.checked)}>Log Correspondence</Checkbox>
          </div>
        )}
        <div className="resourceInfo d-flex">
            <AutoCompleteComponent
              title="previous-medical"
              rows={rows}
              twoColum={true}
              hasCheckbox={true}
              removeItem={this.removeRow}
              keyLabel="note"
              keyType="name"
              addItem={this.addRow}
              handleSearch={this.handleSearch}
              placeholder={"Add Information Note"}
              disableSearchIcon={true}
              disableOptions={true}
              selectItem={this.addNote}
              minRows={this.props.minRows || 8}
            />
        </div>
      </div>
    );
  }

  setLogCorrespondence = (val) => {
    console.dir(val);
    this.setState({...this.state, logCorrespondence: val});
  }
  handleSearch = (search) => {
    this.setInput(search);
  };
  addRow = () => {
    const { rows, searchInput } = this.state;
    const newRows = [...rows, { label: searchInput }];
    this.setRows(newRows);
  };
  removeRow = async (item, rowIndex) => {
    const { detailPCM } = this.props;
    if (!this.state.rowID[rowIndex]) {
      this.setState({ rows: [], rowID: [], searchInput: "" });
      this.props.setGlobalLoading(false);
    } else {
      await this.props.delete_patient_notes_billing(this.state.rowID[rowIndex]);
      await this.props.get_patient_notes_billing(detailPCM.id);
    }
  };
  setRows = (rows) => {
    this.setState({
      ...this.state,
      rows,
    });
  };
  setInput = (searchInput) => {
    this.setState({
      ...this.state,
      searchInput,
    });
  };
  addNote = async () => {
    const { searchInput } = this.state;
    const { detailPCM } = this.props;
    if (detailPCM) {
      const formData = new FormData();
      formData.append("note", searchInput);
      formData.append("appointment_id", detailPCM.id);
      formData.append("user_id", this.state.current_patient_id);
      formData.append("log_correspondence", this.state.logCorrespondence);
      await this.props.save_patient_notes_billing(formData);
      await this.props.get_patient_notes_billing(detailPCM.id);
      await this.props.setPriorData("loadingResource", false);
      if(this.state.logCorrespondence) {
        this.setState({
          ...this.state,
          logCorrespondence: false
        })
        this.props.saveType('contacted');
      }
    }
  };
}

const mapStateToProps = (state) => ({
  loadingNotes: state.newPatient.loadingNotes,
  patientsNoteBilling: state.newPatient.patientsNoteBilling,
});

const mapDispatchToProps = {
  save_patient_notes_billing,
  get_patient_notes_billing,
  delete_patient_notes_billing,
  setPriorData,
  setGlobalLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesComponent);
