import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Icon } from 'antd';
import SubSideNav from '../../common/components/SubSideNav';
import MainContainer from './Billing';

export default class Billing extends Component {
  sideNavLinks = [
    {
      link: '/billing/summary',
      label: 'Summary'
    },
    {
      link: '/billing/overview',
      label: 'Overview'
    },
    {
      link: '/billing/endovasculars',
      label: 'Endovascular'
    },
    {
      link: '/billing/nonEndovascular',
      label: 'Non-Endovascular'
    },
    {
      link: '/billing/providerReport',
      label: 'Provider Report'
    },
];

  state = {
    isSideNav: true
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Billing</title>
        <meta name="title" content="FAACT Vascular | Billing" />
      </Helmet>
    )
  }

  openSideNav = () => {
    this.setState({ isSideNav: true });
  };

  closeSideNav = () => {
    this.setState({ isSideNav: false });
  };

  getTitle = () => (
    <div className="sub-title">
      <Icon type="dollar" />
      Billing
    </div>
  );

  render() {
    const { location } = this.props;
    const { isSideNav } = this.state;
    return (
      <div className="clinicWorkflow">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
        />
        <MainContainer location={location} isSideNav={isSideNav} />
      </div>
    )
  }
}
