import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import ScrollWrapper from "../../../common/components/ScrollBar";
import ProcedureDetail from "../../procedureDetail";
import ConditionDetail from "../../procedureDetail/ConditionDetail";
import AngioDetail from "../../procedureDetail/angioDetail";
import ClinicProcedure from "../../procedureDetail/clinicProcedure";
import MeaningfulUseDetail from "../../procedureDetail/meaningfulUse";
import UltrasoundDetail from "../../procedureDetail/ultrasoundDetail";

import { ProcedureDetailsHeaders } from "../constant";
import { refrest_state_tab } from "../../../store/actions";

const ProcedureDetails = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen)

  const changeTab = (index) => {
    dispatch(refrest_state_tab());
    setActiveTab(index);
  };

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Clinical Logic" subStatus={""} isBarcode={false} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <ScrollWrapper css="no-padding tab-header-scroll">
              <div style={{ display: "flex" }}>
                {ProcedureDetailsHeaders.map((tab, index) => (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${
                      activeTab === index ? "active" : ""
                    }`}
                    style={{ width: tab.width }}
                    onClick={() => changeTab(index)}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
        {activeTab === 0 && <ProcedureDetail />}
        {activeTab === 1 && <ConditionDetail />}
        {activeTab === 2 && <AngioDetail />}
        {activeTab === 3 && <ClinicProcedure />}
        {activeTab === 4 && <MeaningfulUseDetail />}
        {activeTab === 5 && <UltrasoundDetail />}
      </div>
    </div>
  );
};

export default ProcedureDetails;
