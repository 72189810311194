import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Upload, Select, Collapse } from "antd";
import * as _ from "lodash";

import ScrollWrapper from "../../../common/components/ScrollBar";
import ToastMessage from "../../../common/components/Toast";
import {getPending, save_quick_register, setGlobalLoading, setStepData} from "../../../store/actions";
import { validateField } from "../../../services/validations";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";

const { TextArea } = Input;
const { Option } = Select;

const { Panel } = Collapse;

const QuickRegister = () => {
  const conditions = useSelector((state) => state.common.referralConditions);
  const pcpList = useSelector((state) => state.common.pcpList);
  const providers = useSelector((state) => state.common.providers);

  const isRegistered = useSelector((state) => state.dashboard.isRegistered);
  const messageSaveQuick = useSelector(
    (state) => state.dashboard.messageSaveQuick
  );
  const procedureData = useSelector((state) => state.common.procedureData);
  const referralConditions = useSelector(
    (state) => state.adminReferralCondition.referralConditions
  );

  const dispatch = useDispatch();

  const [patient, setPatient] = useState({});
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [activePannel, setActivePannel] = useState('');

  // const keyPressHandler = (e) => {
  //   console.log('key value', e.key)
  // }

  // useEffect(() => {
  //   document.addEventListener('keydown', keyPressHandler);
  //   return () => {
  //     document.removeEventListener('keydown', keyPressHandler);
  //   };
  // }, []);

  // const isToastr = useMemo(() => {
  //   if (isRegistered) {
  //     setTimer(0);
  //     setPatient({});
  //     setImage(null);
  //     setTimeout(() => {
  //       setTimer(600);
  //     }, 2000);
  //     return true;
  //   }
  //   return false;
  // }, [isRegistered]);

  // useEffect(() => {
  //   if (isRegistered) {

  //   }
  // }, [isRegistered]);

  const uploadProps = useMemo(() => {
    return {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setImage(file);
        return false;
      },
    };
  }, []);

  const handleInput = (type) => (value) => {
    const updates = { ...patient, [type]: value };
    setPatient(updates);
    setError(false);
  };

  const handleChangeOptions = (type) => (get) => {
    let value = "";
    if (type === "notes") {
      value = get.target.value;
    } else {
      value = get;
    }
    const updates = { ...patient, [type]: value || "" };
    setPatient(updates);
    setError(false);
  };

  const onRegister = async () => {
    if (
      validateField("text", patient.first_name) ||
      validateField("text", patient.last_name) ||
      validateField("date", patient.date_of_birth) ||
      validateField("phone", patient.phone) ||
      validateField("text", patient.zipcode) ||
      validateField("text", patient.referral_condition) ||
      validateField("text", patient["referral_provider"]) ||
      validateField("text", patient.gender) ||
      validateField("date", patient["referral_date"])
    ) {
      setError(true);
      setActivePannel('register');
    } else {
      setError(false);
      const formData = new FormData();
      formData.append("first_name", patient.first_name);
      formData.append("last_name", patient.last_name);
      formData.append("date_of_birth", patient.date_of_birth);
      formData.append("phone", patient.phone);
      formData.append("zipcode", patient.zipcode);
      formData.append("referral_condition", patient.referral_condition);
      formData.append("referral_provider", patient.referral_provider);
      formData.append("referral_date", patient.referral_date);
      formData.append("notes", patient.notes);
      formData.append("gender", patient.gender);
      formData.append("profile_image", image);
      formData.append("ssn", patient.ssn);
      formData.append("address", patient.address);
      formData.append("apt_number", patient.apt_number);
      formData.append("appointment_type_id", patient.appointment_type_id);
      formData.append("provider_id", patient.provider_id);
      formData.append("urgency", patient.urgency);
      await dispatch(setGlobalLoading(true));
      const saveAll = await dispatch(save_quick_register(formData));
      if (saveAll) {
        setPatient({});
        setImage(null);
        setIsToastr(true);
        dispatch(setGlobalLoading(false));
        dispatch(getPending({}));
        setTimeout(() => {
          // setTimer(600);
          setIsToastr(false);
        }, 500);
      }
    }
  };

  return (
    <>
      <div
        className="QuickRegister"
        style={{
          width: "100%",
          height: "100%",
          marginTop: "0",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {isToastr ? (
          <ToastMessage
            type="Quick Register"
            status="success"
            msg="Registered new patient Successfully!"
          />
        ) : null}
        <div
          className="fullContainer"
          style={{ width: "100%", padding: 0, marginTop: "0px", flex: 1 }}
        >
          <ScrollWrapper css={"no-padding"}>
            <div className="registerContainer">
              <div className="registerSection">
                <div className="quickPlan">
                  <Collapse
                    accordion
                    expandIcon={null}
                    activeKey={activePannel}
                    style={{ paddingRight: 0, paddingLeft: 0 }}
                    onChange={(active) => setActivePannel(active)}
                  >
                    <Panel header="Register" key="register">
                      <div
                        className="formContainer"
                        style={{ paddingTop: "5px" }}
                      >
                        <div className="leftContent">
                          <div className="formControl">
                            <EditableFormInput
                              label={"First Name"}
                              value={patient.first_name}
                              handleChange={handleInput("first_name")}
                              required={true}
                              fullWidth={true}
                              isError={validateField(
                                "text",
                                patient.first_name
                              )}
                              enableErrorText={error}
                              helperText={"First Name is Required!"}
                              tabIndex={1}
                            />
                          </div>
                          <div className="formControl">
                            <EditableFormInput
                              label={"DOB"}
                              value={patient.date_of_birth}
                              handleChange={handleInput("date_of_birth")}
                              required={true}
                              fullWidth={true}
                              mask={"date"}
                              isError={validateField(
                                "date",
                                patient.date_of_birth
                              )}
                              enableErrorText={error}
                              helperText={"Birthday should be MM/DD/YYYY!"}
                              tabIndex={3}
                            />
                          </div>

                          <div className="formControl">
                            <EditableFormInput
                              label={"Phone No"}
                              value={patient.phone}
                              handleChange={handleInput("phone")}
                              required={true}
                              fullWidth={true}
                              mask={"phone"}
                              isError={validateField("phone", patient.phone)}
                              enableErrorText={error}
                              helperText={"Phone Number is Required"}
                              tabIndex={5}
                            />
                          </div>
                          <div className="formControl">
                            <EditableFormInput
                              label={"Zip Code"}
                              value={patient.zipcode}
                              handleChange={handleInput("zipcode")}
                              required={true}
                              fullWidth={true}
                              isError={validateField("text", patient.zipcode)}
                              enableErrorText={error}
                              helperText={"Zip Code is Required!"}
                              tabIndex={7}
                            />
                          </div>
                          <div className="formControl">
                            <EditableFormInput
                              label={"Gender"}
                              handleChange={handleChangeOptions("gender")}
                              value={patient.gender}
                              type={"check"}
                              optionKey={"value"}
                              valueKey={"name"}
                              options={[
                                { value: "male", name: "Male" },
                                { value: "female", name: "Female" },
                              ]}
                              fullWidth={true}
                              required={true}
                              isError={validateField("text", patient.gender)}
                              enableErrorText={error}
                              helperText={"Gender is Required!"}
                              tabIndex={9}
                            />
                          </div>
                        </div>
                        <div
                          className="rightContent"
                          style={{ paddingRight: 0 }}
                        >
                          <div className="formControl">
                            <EditableFormInput
                              label={"Last Name"}
                              value={patient.last_name}
                              handleChange={handleInput("last_name")}
                              required={true}
                              fullWidth={true}
                              tabIndex={2}
                              isError={validateField("text", patient.last_name)}
                              enableErrorText={error}
                              helperText={"Last Name is Required!"}
                            />
                          </div>
                          <div className="formControl">
                            <EditableFormInput
                              label={"Referred for"}
                              value={patient.referral_condition || undefined}
                              handleChange={handleChangeOptions(
                                "referral_condition"
                              )}
                              required={true}
                              fullWidth={true}
                              tabIndex={4}
                              type={"check"}
                              isError={validateField(
                                "text",
                                patient.referral_condition
                              )}
                              optionKey={"value"}
                              valueKey={"value"}
                              options={conditions || []}
                              enableErrorText={error}
                              helperText={"Diagnosis is required"}
                            />
                          </div>
                          <div className="formControl">
                            <EditableFormInput
                              label={"By"}
                              value={patient.referral_provider || undefined}
                              handleChange={handleChangeOptions(
                                "referral_provider"
                              )}
                              required={true}
                              fullWidth={true}
                              tabIndex={6}
                              type={"check"}
                              isError={validateField(
                                "text",
                                patient.referral_provider
                              )}
                              optionKey={"id"}
                              valueKey={"full_name"}
                              options={(pcpList && pcpList["active_pcp"]) || []}
                              enableErrorText={error}
                              helperText={"Referred By is required"}
                            />
                          </div>
                          <div className="formControl">
                            <EditableFormInput
                              label={"On"}
                              value={patient["referral_date"] || undefined}
                              handleChange={handleChangeOptions(
                                "referral_date"
                              )}
                              required={true}
                              fullWidth={true}
                              tabIndex={8}
                              type={"date"}
                              isError={validateField(
                                "date",
                                patient.referral_date
                              )}
                              enableErrorText={error}
                              helperText={"On should be MM/DD/YYYY!"}
                            />
                          </div>
                        </div>
                      </div>
                    </Panel>
                    <Panel
                      header="Clinical Plan"
                      key="quick_plan"
                      style={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                      <div
                        className="formContainer"
                        style={{ paddingTop: "5px" }}
                      >
                        <div className="leftContent">
                          <div className="formControl">
                            <EditableFormInput
                              label={"Plan"}
                              value={patient.appointment_type_id || undefined}
                              handleChange={handleChangeOptions(
                                "appointment_type_id"
                              )}
                              fullWidth={true}
                              type={"check"}
                              optionKey={"id"}
                              valueKey={"value"}
                              tabIndex={1}
                              options={procedureData || []}
                            />
                          </div>
                          <div className="formControl">
                            <EditableFormInput
                              label={"Provider"}
                              value={patient.provider_id || undefined}
                              handleChange={handleChangeOptions("provider_id")}
                              fullWidth={true}
                              type={"check"}
                              tabIndex={3}
                              optionKey={"id"}
                              valueKey={"value"}
                              options={providers || []}
                            />
                          </div>
                        </div>
                        <div
                          className="rightContent"
                          style={{ paddingRight: 0 }}
                        >
                          <div className="formControl">
                            <EditableFormInput
                              label={"Urgency"}
                              value={patient.urgency || undefined}
                              handleChange={handleChangeOptions("urgency")}
                              fullWidth={true}
                              type={"check"}
                              tabIndex={2}
                              optionKey={"value"}
                              valueKey={"label"}
                              options={[
                                {
                                  value: "Next Available",
                                  label: "Next Available",
                                },
                                { value: "Urgent", label: "Urgent" },
                                { value: "Emergent", label: "Emergent" },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </Panel>
                    <Panel header="Notes" key="note">
                      <div className="rightContent" style={{ paddingRight: 0 }}>
                        <div className="formControl">
                          <TextArea
                            placeholder={"Notes"}
                            autoSize={{ minRows: 3 }}
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#f4f7fb",
                            }}
                            onChange={handleChangeOptions("notes")}
                            value={patient.notes || undefined}
                          />
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>

                <div className="detailContent">
                  <div className="detailInfo">
                    Upload Patient Demographic or other information easily
                    through your smartphone. All information contained within
                    the images are part of the patients chart and may be
                    retreivable at any date.
                    {messageSaveQuick && (
                      <div className="formControl">
                        <div className="messageSave">
                          {messageSaveQuick.message}
                        </div>
                      </div>
                    )}
                  </div>
                  <Upload {...uploadProps} className="text-right">
                    <button className="common-btn outlined-btn">
                      Upload Image
                    </button>
                  </Upload>
                </div>
              </div>
            </div>
          </ScrollWrapper>
        </div>

        <div
          className="fullContainer__actions"
          style={{
            width: "100%",
            paddingTop: "1.5rem",
            paddingRight: "0px",
            minHeight: '64px',
            flex: 0,
          }}
        >
          <button className="common-btn blue-btn" onClick={onRegister}>
            Create Record
          </button>
        </div>
      </div>
    </>
  );
};

export default QuickRegister;
