import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from "draft-js";

import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import { defaultSuggestionsFilter } from "draft-js-mention-plugin";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { update_text_clinic_procedure_type } from "../../../store/actions";
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from "draft-js-buttons";

import ScrollWrapper from "../../../common/components/ScrollBar";

const mentionPlugin = createMentionPlugin({
  mentionTrigger: "[",
});

const toolbarPlugin = createToolbarPlugin();

let timer = null;
const TemplateDocumentation = ({ resource }) => {
  const [editorState, setEditor] = useState(EditorState.createEmpty());
  const [answerCheck, setAnswerCheck] = useState([]);
  const [variableCheck, setVariableCheck] = useState([]);
  const [SearchVariable, setSearchVariable] = useState([]);
  const [content, setContent] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [focusEditor, setFocusEditor] = useState(false);
  const [selectVariable, setSelectVariable] = useState("");
  const dispatch = useDispatch();
  const clinicVariable = useSelector(
    (state) => state.procedureDetail.clinicVariable
  );
  const detail_clinic_type = useSelector(
    (state) => state.procedureDetail.detail_clinic_type
  );

  const onChange = (editorState) => {
    // if (resource === null || resource === undefined) {
    //   return;
    // }
    setEditor(editorState);
    let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const newContentState = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setContent(newContentState);
    if (
      detail_clinic_type &&
      Object.keys(detail_clinic_type).length > 0 &&
      focusEditor
    ) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          update_text_clinic_procedure_type(detail_clinic_type?.id, {
            text_template: data,
          })
        );
      }, 500);
    }
  };

  useEffect(() => {
    if (clinicVariable) {
      const variableMap = (clinicVariable || []).map((r) => {
        return {
          link: r.id,
          name: `[${r.variable_name}]`,
          check: r.variable_name,
        };
      });
      setVariableCheck([...variableMap]);
    }
  }, [clinicVariable]);

  useEffect(() => {
    if (detail_clinic_type && Object.keys(detail_clinic_type).length > 0) {
      if (
        detail_clinic_type.text_template &&
        detail_clinic_type.text_template.length > 0
      ) {
        try {
          const contentState = convertFromRaw(
            JSON.parse(detail_clinic_type.text_template)
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        } catch (error) {
          setEditor(EditorState.createEmpty());
        }
      } else {
        setEditor(EditorState.createEmpty());
      }
    }
  }, [detail_clinic_type]);

  useEffect(() => {
    if (selectVariable && clinicVariable) {
      const answerCheck = (clinicVariable || []).find(
        (r) => r.variable_name == selectVariable
      );
      if (answerCheck) {
        const allAnswerCheck = (answerCheck.answer || []).map((item, index) => {
          return { link: `${item.id} - ${index}`, name: `[${item.answer}]` };
        });
        setAnswerCheck([...allAnswerCheck]);
      }
    }
  }, [selectVariable]);

  const onSearchChange = ({ value }) => {
    setSearchVariable(
      defaultSuggestionsFilter(value, isChange ? answerCheck : variableCheck)
    );
  };

  const onAddMention = (value) => {
    setSelectVariable(value?.check);
  };

  const { MentionSuggestions } = mentionPlugin;
  const { Toolbar } = toolbarPlugin;
  let plugins = [mentionPlugin, toolbarPlugin];

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div className="custom-toolbar-editor">
        <Toolbar>
          {(externalProps) => (
            <div className="editor-custom-toolbar">
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
            </div>
          )}
        </Toolbar>
      </div>
      <div
        style={{
          border: "1px solid #f2f2f2",
          padding: "10px",
          height: "calc(100% - 40px)",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <ScrollWrapper css="no-padding x-hidden">
          <Editor
            onFocus={() => {
              setFocusEditor(true);
            }}
            onBlur={() => {
              setFocusEditor(false);
            }}
            editorState={editorState}
            onChange={onChange}
            plugins={plugins}
            placeholder="Procedure Details"
          />
        </ScrollWrapper>
      </div>
      <div className="mention-suggestion">
        <MentionSuggestions
          onSearchChange={onSearchChange}
          suggestions={isChange ? answerCheck : variableCheck}
          onAddMention={onAddMention}
        />
      </div>
    </div>
  );
};

export default TemplateDocumentation;
