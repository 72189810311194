import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from '@react-pdf/renderer'
import logoImage from './vhc_logo.png'
import {
  procedureConsentSaveDocument,
  procedureConsentClosedCreate,
} from '../../../../store/actions'
import SignatureCanvas from 'react-signature-canvas'
import Loading from '../../../../common/components/Loading'

const ConsentPreCreateDocument = ({ closeCreatePage }) => {
  const dispatch = useDispatch()
  const [documentBlob, setDocumentBlob] = useState(null)
  const newDocumentData = useSelector(
    (state) => state.procedureConsent.newDocumentData
  )
  const procedure_data = newDocumentData.procedure_data
  const procedure = procedure_data.description
  const patient_data = newDocumentData.patient_data
  const provider_data = newDocumentData.provider_data
  const [signImage, setSignImage] = useState(null)
  const saving = useSelector((state) => state.procedureConsent.saving)

  useEffect(() => {
    if (saving === 'done') {
      closeCreatePage()
      dispatch(procedureConsentClosedCreate())
    }
  }, [saving])

  const saveDocument = () => {
    let data = new FormData()
    let newDocumentData_ = { ...newDocumentData }
    newDocumentData_.is_signed = true
    data.append('newDocumentData', JSON.stringify(newDocumentData_))
    data.append('documentBlob', documentBlob)
    dispatch(procedureConsentSaveDocument(data))
  }
  const onSign = (signImage_) => {
    setSignImage(signImage_)
  }

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 10000,
        background: 'white',
        left: 0, top: 0, 
        right: 0, bottom: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: 'calc(100% - 50px)',
        }}
      >
        <PDFViewer style={{ width: '100%' }}>
          <DocumentContent
            procedure={procedure}
            patient_data={patient_data}
            provider_data={provider_data}
            signImage={signImage}
          />
        </PDFViewer>
        <PDFDownloadLink
          document={
            <DocumentContent
              procedure={procedure}
              patient_data={patient_data}
              provider_data={provider_data}
              signImage={signImage}
            />
          }
          fileName="somename.pdf"
        >
          {({ blob, url, loading, error }) => {
            if (!loading && !error) {
              setDocumentBlob(blob)
            }
            return <></>
          }}
        </PDFDownloadLink>
      </div>
      <ButtonSection
        closeCreatePage={closeCreatePage}
        saveDocument={saveDocument}
        onSign={onSign}
      />
      {saving === 'now' && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0.8,
            backgroundColor: '#ccc',
          }}
        >
          {/* <Loading /> */}
        </div>
      )}
    </div>
  )
}

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 30,
    paddingVertical: 10,
  },
  section: {
    marginTop: 2,
    marginBottom: 2,
    display: 'block',
  },
  title: {
    fontSize: '18px',
  },
  p: {
    fontSize: '12px',
    lineHeight: '3em',
    display: 'block',
  },
  nameSection: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  doctorLabel: {
    fontSize: '14px',
    marginRight: 20,
  },
  doctorName: {
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  patientLabel: {
    fontSize: '14px',
    marginRight: 20,
  },
  patientName: {
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  signImage: {
    width: 80,
    height: 32,
  },
})

// Create Document Component
const DocumentContent = ({
  procedure,
  patient_data,
  provider_data,
  signImage,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            source={logoImage}
            style={{ width: '220px', height: '42px' }}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.title}>Procedural Consent Form</Text>
          <Text style={styles.p}>RISKS OF THE PROCEDURE</Text>
          <Text style={styles.p}>
            The doctor believes the benefits to you from having this procedure
            exceed the risks involved. The risks and complications with this
            procedure can include but are not limited to the following.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>{procedure}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>Common risks and complications include:</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>
            1. Minor pain, bruising and/or infection from the IV cannula. This
            may require treatment with antibiotics.
          </Text>
          <Text style={styles.p}>
            2. Pain or discomfort at the puncture site. This may require
            medication.
          </Text>
          <Text style={styles.p}>
            3. Bleeding or bruising could occur. This is usually stopped by
            applying pressure and/or ice to the puncture site. This is more
            common if you take Aspirin, Warfarin, Clopidogrel (Plavix) or
            Dipyridamole (Persantin).
          </Text>
          <Text style={styles.p}>
            4. Nerve damage, is usually temporary. Permanent nerve damage is
            rare.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>
            Less common risks and complications include:
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>
            1. Infection, requiring antibiotics and further treatment.
          </Text>
          <Text style={styles.p}>
            2. Damage to surrounding structures such as blood vessels, organs
            and muscles, requiring further treatment.
          </Text>
          <Text style={styles.p}>
            3. A blood clot or excessive bleeding from the puncture site. This
            may require other corrective surgery.
          </Text>
          <Text style={styles.p}>
            4. An allergy to injected drugs, requiring further treatment.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>Rare risks and complications include:</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>
            1. Stroke or stroke like complications may occur due to the catheter
            causing damage to the artery blocking the blood flow. This can cause
            weakness in the face, arms and legs; it could be temporary or
            permanent.
          </Text>
          <Text style={styles.p}>
            2. Skin burns or damage from exposure to x-rays.
          </Text>
          <Text style={styles.p}>
            3. Seizures and/or cardiac arrest due to local anaesthetic toxicity.
          </Text>
          <Text style={styles.p}>
            4. Death as a result of this procedure is very rare. RISKS OF
            IODINATED CONTRAST FOR PATIENTS WITH RENAL IMPAIRMENT
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>
            Specific Risks of Iodinated Contrast to patient's identified as
            having Renal Impairment.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>
            1. Giving the Contrast to people with weakened kidneys (renal
            impairment), can cause further kidney damage, which may in turn
            cause the kidneys to stop working properly (acute renal failure).
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>PATIENT CONSENT</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>
            I acknowledge that the doctor/doctor delegate has explained the
            proposed procedure. I understand;
          </Text>
          <Text style={styles.p}>
            1. The risks and complications, including the risks that are
            specific to me.
          </Text>
          <Text style={styles.p}>
            2. The sedation/anaesthetic required for this procedure. I
            understand the risks, including the risks that are specific to me.
          </Text>
          <Text style={styles.p}>
            3. That no guarantee has been made that the procedure will improve
            my condition even though it has been carried out with due
            professional care.
          </Text>
          <Text style={styles.p}>
            4. If immediate life-threatening events happen during the procedure,
            they will be treated based on my discussions with the doctor/doctor
            delegate or my Acute Resuscitation Plan.
          </Text>
          <Text style={styles.p}>
            5. I was able to ask questions and raise concerns with the
            doctor/doctor delegate about the proposed procedure and its risks.
            My questions and concerns have been discussed and answered to my
            satisfaction.
          </Text>
          <Text style={styles.p}>
            6. I understand I have the right to change my mind at any time
            including after I have signed this form but, preferably following a
            discussion with my doctor/doctor delegate.{' '}
          </Text>
          <Text style={styles.p}>
            7. I understand that image/s or video footage may be recorded as
            part of and during my procedure and that these image/s or video/s
            will assist the doctor to provide appropriate treatment.
          </Text>
          <Text style={styles.p}>
            8. I understand that Vascular Health Clinics may release my relevant
            de-identified information
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.p}>
            On the basis of the above statements, I request to have the
            procedure
          </Text>
        </View>
        <View style={styles.nameSection}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.doctorLabel}>Doctor:</Text>
            <Text style={styles.doctorName}>{provider_data.name}</Text>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            <Text style={styles.patientLabel}>Patient:</Text>
            <Text style={styles.patientName}>
              {patient_data.first_name} {patient_data.last_name}
            </Text>
            {signImage && <Image source={signImage} style={styles.signImage} />}
          </View>
        </View>
      </Page>
    </Document>
  )
}

const ButtonSection = ({ saveDocument, closeCreatePage, onSign }) => {
  const [isSigned, setIsSigned] = useState(false)
  const [isOpenedSign, setIsOpenedSign] = useState(false)
  const [canvasRef, setCanvasRef] = useState(null)

  const onApplySign = () => {
    let signImage = canvasRef.toDataURL()
    onSign(signImage)
    setIsSigned(true)
    setIsOpenedSign(false)
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        background: 'white',
        padding: '10px',
      }}
    >
      {isOpenedSign && !isSigned && (
        <>
          <div style={{ border: '1px solid #ccc', width: 700 }}>
            <SignatureCanvas
              penColor="#232323"
              canvasProps={{ width: 700, height: 500, className: 'sigCanvas' }}
              ref={(ref) => setCanvasRef(ref)}
              minWidth={5}
              maxWidth={10}
            />
          </div>
          <button onClick={onApplySign}>Apply</button>
          <button
            onClick={() => setIsOpenedSign(false)}
            style={{ marginLeft: '20px' }}
          >
            Back
          </button>
        </>
      )}
      {isSigned && (
        <button onClick={saveDocument} style={{}}>
          Save
        </button>
      )}
      {!isOpenedSign && !isSigned && (
        <button onClick={() => setIsOpenedSign(true)}>
          Sign this document
        </button>
      )}
      {!isOpenedSign && (
        <button style={{ float: 'right' }} onClick={closeCreatePage}>
          Back
        </button>
      )}
    </div>
  )
}

export default ConsentPreCreateDocument
