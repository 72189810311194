import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { DatePicker, Icon } from "antd";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import {
  exportPCM,
  exportPCMUser,
  getPCMForUser,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const ProviderContributionSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  userDetail,
}, ref) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const pcmUser = useSelector((state) => state.common.pcmUser);

  const export_pcm = useSelector((state) => state.common.export_pcm_user);

  const sendExport = async () => {
    if (resource) {
      const result = await dispatch(
        exportPCMUser(currentUserInfo.id, startDate, endDate)
      );
      window.open(result.url, "_self");
    }
  };

  useEffect(() => {
    if (endDate && startDate) {
      dispatch(getPCMForUser(currentUserInfo.id, startDate, endDate));
    }
  }, [endDate, startDate]);

  if (!isShow || !userDetail) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper css="x-hidden">
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={<Icon type="bank" theme="twoTone" twoToneColor="#9F1853"/>}
          title={<span className="text-uppercase">{userDetail.name}</span>}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px", paddingBottom: '2rem' }}
        >
          <div className="resourceInfo d-flex" style={{ marginBottom: "1rem" }}>
            <div>
              Vacation Requests greater than 8 hours must be made 7 days prior
              to start for approval. Requests for vacation are not guaranteed
              and must be approved. Please allow 2-3 business days for approval.
              If you require covering staff please ensure you have worked out
              all covering issues prior to requesting time off. Vacation days
              will be approved based on first-come first serve and fairness
              regarding equity of
            </div>
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Start DOS"
              type="date"
              value={startDate}
              handleChange={setStartDate}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="End DOS"
              type="date"
              value={endDate}
              handleChange={setEndDate}
            />
          </div>

          <div className="resourceInfo d-flex" style={{ margin: "1rem 0" }}>
            <div>
              For the dates selected the following information is determined.
            </div>
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Guaranteed Salary"
              value={pcmUser && pcmUser.salary}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Endovascular Contribution Margin"
              value={pcmUser && pcmUser.total_endo}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Non-Endovascular Contribution Margin"
              value={pcmUser && pcmUser.total_nonendo}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Net Accrual"
              value={pcmUser && pcmUser.net}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Accounts Receivables"
              value={pcmUser && pcmUser.account_receivables}
            />
          </div>
          <div
            className="resourceInfo d-flex"
            style={{ marginBottom: "1rem", marginTop: "0.5rem" }}
          >
            <div>
              The amounts listed may be subject to change with charge backs from
              insurance companies and/or increased remittance from appeals to
              insurance. Amounts received for work listed within the dates
              selected would be reflected in the totals for the dates listed.
            </div>
          </div>
          <hr />
          <div
            className="actionContent"
            style={{
              marginTop: "2rem",
              paddingRight: "1rem",
              flexWrap: "wrap",
            }}
          >
            <button
              onClick={sendExport}
              className="common-btn"
              style={{ marginTop: "0.5rem" }}
            >
              Export to Excel
            </button>
          </div>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default ProviderContributionSidebar;
