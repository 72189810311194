import React, { Component, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TopBar from '../../../common/components/SubTopBar';
import InventorySearch from './inventoryModules/InventorySearch';
import Supplies from './inventoryModules/Supplies';
import NarcoticInventory from './inventoryModules/NarcoticInventory';
import PurchaseList from './inventoryModules/PurchaseList';
import Receiving from './inventoryModules/Receiving';
import ReturnCredit from './inventoryModules/ReturnCredit';
import Invoices from './inventoryModules/Invoices';
import TransactionHistory from './inventoryModules/HistoryTable';
import { TabHeaders } from '../constant';
import ScrollWrapper from "../../../common/components/ScrollBar";
import { getLocations } from '../../../store/actions';

const InventoryModule = () => {
  const locations = useSelector(state => state.common.locations);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!locations || !locations.length) {
      dispatch(getLocations());
    }
  }, [locations]);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen)
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Inventory Module" subStatus={''} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: '29px' }}>
            <ScrollWrapper css="no-padding tab-header-scroll">
              <div style={{ display: 'flex' }}>
                {TabHeaders.map((tab, index) => (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? 'active' : ''}`}
                    style={{ width: tab.width }}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
        {activeTab === 0 && <InventorySearch title="inventory-search" locations={locations} />}
        {activeTab === 1 && <Supplies title="angiographic-supplies" locations={locations} />}
        {activeTab === 2 && <NarcoticInventory title="narcotic-inventory" locations={locations} />}
        {activeTab === 3 && <PurchaseList title="inventory-purchase-list" locations={locations} />}
        {activeTab === 4 && <Receiving title="inventory-receiving" locations={locations} />}
        {activeTab === 5 && <ReturnCredit title="inventory-return-credit" locations={locations} />}
        {activeTab === 6 && <Invoices title="inventory-Invoices" locations={locations} />}
        {activeTab === 7 && <TransactionHistory title="inventory-transaction-history" locations={locations} />}
      </div>
    </div>
  )
}

export default InventoryModule;
