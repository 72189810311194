import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import ToggleButton from '../ToggleButton';
import SideNav from '../SideNav';
import HeaderMenu from '../HeaderMenu';
import HeaderIconMenu from '../HeaderIconMenu';
import './style.scss';

export default function Header() {
  const location = useLocation();
  const [openSideMenu, setSideMenu] = useState(false);
  const isLogin = location.pathname === '/login';
  const isMonitor = (location.pathname.includes('/mon/'))

  const handleClick = () => {
    if (openSideMenu) {
      setSideMenu(!openSideMenu);
    }
  };


  return (
    <header className={`headerNav ${isLogin ? 'hide' : ''}`}>
      <div className='leftContainer headerWrapper'>
        <div className='brandContainer'>
          <div className='toggleMenu' style={{ marginRight: 0 }}>
            <ToggleButton handleClick={() => {setSideMenu(true)}} openSideBar={openSideMenu} />
          </div>
          <div className='logoContainer' style={{ paddingLeft: (isMonitor ? '10px' : '')}}>
            <NavLink to='/'>
              <img src={'/images/faact_logo_v2.svg'} alt="faact" style={{ height: '33px', marginLeft: '-10px' }} />
            </NavLink>
          </div>
        </div>

        <HeaderMenu shouldDisplay={!isMonitor} />

        <SideNav handleClick={handleClick} active={openSideMenu} />
      </div>
      <div className='rightContainer headerWrapper' style={{ display: (isMonitor ? 'none' : '')}}>
        <HeaderIconMenu/>
      </div>
    </header>
  );
}
