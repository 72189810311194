import { createActionThunk } from 'redux-thunk-actions';
import AT from '../actionTypes';
import * as API from '../../services/api.services';

export const HPIGetConditions = createActionThunk(
  AT.HPI_GET_CONDITIONS,
  (procedure_id) => API.HPIGetConditions(procedure_id)
);

export const HPISetConditions = (conditions) => (
  (dispatch) => dispatch({
    type: AT.HPI_SET_CONDITIONS,
    payload: {
      conditions
    }
  })
)

export const HPISaveCondition = createActionThunk(
  AT.HPI_SAVE_CONDITION,
  (patient_id, procedure_id, data) => API.HPISaveCondition(patient_id, procedure_id, data)
);

export const loadUltrasoundHistoryForConditionHPI = createActionThunk(
  AT.LOAD_CONDITION_HPI_UNTRASOUND,
  (patient_id, condition_value) => API.loadUltrasoundHistoryForCondition(patient_id, condition_value)
);