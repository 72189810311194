import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CloseIcon } from '../../../../common/components/icons/SVGIcon'
import ScrollWrapper from '../../../../common/components/ScrollBar'
import SidebarTitle from '../../../../common/components/SidebarTitle'
import { MaleSVG } from '../../../../common/components/icons/FaxSVG'
import ConsentDocumentList from './ConsentDocumentList'
import ConsentCreateButtonSection from './ConsentCreateButtonSection'
import ConsentFaxingModule from './ConsentFaxingModule'
import moment from 'moment'

const ConsenSideBar = React.forwardRef(({ sidebarIcon, isShow, handleCloseSidebar, openCreatePage },ref) => {
  const patientResource = useSelector(
    (state) => state.patientSchedule.selectedPatientResource
  );
  const pcpList = useSelector((state) => state.common.pcpList);

  const fullName = (
    patientResource.first_name +
    " " +
    patientResource.last_name
  ).toUpperCase();
  const age = patientResource.date_of_birth
    ? "(" + moment().diff(patientResource.date_of_birth, "years") + " yo)"
    : "";

  const existingProvider = (pcpList.active_pcp ? pcpList.active_pcp : pcpList)
    .find(rp => patientResource && rp.id === patientResource.referral_provider);

  const existingPCP = (pcpList.active_pcp ? pcpList.active_pcp : pcpList)
    .find(cp => patientResource && cp.id === patientResource.primary_care_provider_id);

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? 'show' : ''}`}>
      <div className="headerContainer">
        <button onClick={handleCloseSidebar}>
          <CloseIcon />
        </button>
      </div>
      <ScrollWrapper>
        <SidebarTitle
          icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
          title={<span className="text-uppercase">Consent Databse</span>}
          subTitle=""
        />
        <div
          className="resourceContent"
          style={{
            flex: 'unset',
            height: '200px',
            display: 'block',
            paddingLeft: '24px'
          }}
        >
          <div className="resourceInfo">
            <ConsentDocumentList />
          </div>
          <ConsentCreateButtonSection openCreatePage={openCreatePage} />
          <ConsentFaxingModule existingProvider={existingProvider} existingPCP={existingPCP} />
        </div>
      </ScrollWrapper>
    </div>
  )
});

export default ConsenSideBar
