import React, { useState, useEffect, useMemo, useRef } from "react";
import { Input, DatePicker, Select, TimePicker, Checkbox } from "antd";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import AutoComplete from "@material-ui/lab/Autocomplete";
import MultiSelect from "react-multi-select-component";
import moment from "moment";
import { ExpandSVG, RedRequiredCircleSVG } from "../icons/SVGIcon";
import CustomItem from "./CustomItem";
import "./style.scss";

const { Option } = Select;
const { Password } = Input;
const dateFormat = "MM/DD/YYYY";
const timeFormat = "h:mm A";
const phoneMask = "(999) 999-9999";
const ssnMask = "999-99-9999";
const dateMask = "99/99/9999";
const specialMask = "999 / 999";

const getLocaleString = (str) => {
  if (!str) {
    return "0.00";
  }
  const num = parseFloat(str).toFixed(2);
  const decimals = num.split(".")[1];
  if (!decimals || decimals === "00") {
    return Math.round(parseFloat(str)).toLocaleString() + ".00";
  }
  if (decimals.charAt(1) === "0") {
    return (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + "0";
  }
  return (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
};

const EditableFormInput = ({
  label,
  value,
  handleChange,
  type,
  isError,
  required,
  options,
  optionKey,
  valueKey,
  enableErrorText,
  helperText,
  mask,
  fullWidth,
  noEditable,
  prefix,
  unit,
  minRows,
  autoFocus,
  tabIndex,
  multiple,
  reset,
  onCustomBlur,
  customStyle,
  onChangeSearch,
  autoClearSearchValue,
  notFoundContent,
  hideIconSelect,
  disabled,
  disableSearch = false,
  disableMultipleCheckAll,
}) => {
  const [error, setError] = useState(false);
  const [enableHelper, setEnableHelper] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [openTime, setOpenTime] = useState(false);
  const [remove, setRemove] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef && selectRef.current && selectRef.current.rcSelect.inputRef) {
      selectRef.current.rcSelect.inputRef.tabIndex = tabIndex;
    }
  }, [selectRef])

  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === 'Tab' && selectRef && tabIndex >= 0) {
        setFocus(document.activeElement.tabIndex === tabIndex)
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [])

  useEffect(() => {
    if (!isError) {
      setError(false);
      setEnableHelper(false);
    }
    if (enableErrorText && required && isError) {
      setEnableHelper(true);
      setError(true);
    }
    if (value && prefix !== "$") {
      setInputValue(value);
    }

    if (value && prefix === "$") {
      const str = value.replace(/,/g, "");
      if (focus) {
        setInputValue(str);
      } else {
        setInputValue(getLocaleString(str));
      }
    }
  }, [value, isError, enableErrorText]);

  const enableBlur = () => {
    // if (isError) {
    //   setError(true);
    // }
  };

  const changeHandler = str => handleChange && handleChange(str);

  const checkBlurState = (e) => {
    if (prefix === "$") {
      const str = inputValue.replace(/,/g, "");
      setInputValue(getLocaleString(str));
      changeHandler(str);
    }
    setFocus(false);
  };

  const onFocusState = (e) => {
    if (prefix === "$") {
      const str = inputValue.replace(/,/g, "");
      setInputValue(str);
    }
    setFocus(true);
  };

  const onChange = (e) => {
    const targetValue = e.target.value;
    const str = targetValue.replace(/[^0-9.'"]/g, "");
    if (unit === "℉" && str.length < 6) {
      if (str.length === 3 && !str.includes(".")) {
        const checkPush = str.split("").map((r, index) => {
          if (index === 2) {
            return `.${r}`;
          }
          return r;
        });
        setInputValue(checkPush.join(""));
        changeHandler(checkPush.join(""));
      } else {
        setInputValue(str);
        changeHandler(str);
      }
    } else if (unit === "mnHg" && str.length < 4) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "Kg" && str.length < 6) {
      if (str.length === 4 && !str.includes(".")) {
        const checkPush = str.split("").map((r, index) => {
          if (index === 2) {
            return `.${r}`;
          }
          return r;
        });
        setInputValue(checkPush.join(""));
        changeHandler(checkPush.join(""));
      } else {
        setInputValue(str);
        changeHandler(str);
      }
    } else if (unit === "BPM" && str.length < 4) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "RR" && str.length < 3) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "%" && str.length < 4) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "/ 10" && str.length < 3) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "Inches" && str.length < 3) {

      setInputValue(str);
      changeHandler(str);
    } else if (
      unit !== "℉" &&
      unit !== "mnHg" &&
      unit !== "Kg" &&
      unit !== "BPM" &&
      unit !== "RR" &&
      unit !== "/ 10" &&
      unit !== "Inches" &&
      unit !== "%"
    ) {
      setInputValue(targetValue);

      if (prefix === "$") {
        changeHandler(targetValue.replace(/,/g, ""));
      } else {
        changeHandler(targetValue);
      }
    }
  };

  const onChangeSelect = (opt, value) => {
    if (opt) {
      changeHandler(value[optionKey] || opt);
      setFocus(false)
    }
  };

  const onChangeMultiSelect = (value) => {
    changeHandler(value);
  };

  const onSearch = (opt, value) => {
    if (onChangeSearch && opt) {
      onChangeSearch(value || opt);
    }
  };

  const onChangeDate = (date, dateString) => {
    changeHandler(dateString);
  };

  const onChangeTime = (time, timeString) => {
    changeHandler(timeString);
  };

  const lableContent = useMemo(() => {
    if (required) {
      return <span>{label}</span>;
    }
    return label;
  }, [required, label]);

  const errorText = useMemo(() => {
    if (enableHelper && helperText) {
      return (
        <span>
          <RedRequiredCircleSVG /> {helperText || ""}
        </span>
      );
    }
    return "";
  }, [enableHelper]);

  const maskValue = useMemo(() => {
    if (mask === "phone") {
      return phoneMask;
    }
    if (mask === "ssn") {
      return ssnMask;
    }
    if (mask === "date") {
      return dateMask;
    }
    if (mask === "special") {
      return specialMask;
    }
    return "";
  }, [mask]);

  const getValue = () => {
    const optionValue = options.find(
      (condition) =>
        condition[optionKey] === value || condition[valueKey] === value
    );
    if (optionValue) {
      return optionValue[valueKey];
    }
    return value;
  };

  return (
    
    <div className={`editableText material ${fullWidth ? "fullWidth" : ""} ${disabled ? "disabled" : ""}`}>
      {noEditable && !mask && (
        <TextField
          error={error}
          helperText={errorText}
          variant="outlined"
          label={lableContent}
          value={value || ""}
          InputProps={{ inputProps: { tabIndex } }}
          style={{ pointerEvents: 'none' }}
        />
      )}
      {noEditable && mask && (
        <InputMask
          mask={mask === "phone" ? phoneMask : ssnMask}
          value={value || ""}
          onBlur={enableBlur}
          maskPlaceholder={null}
        >
          <TextField
            variant="outlined"
            label={lableContent}
            value={value || ""}
            InputProps={{ inputProps: { tabIndex } }}
            style={{ pointerEvents: 'none' }}
          />
        </InputMask>
      )}
      {!noEditable && prefix && !unit && !type && (
        <FormControl
          fullWidth
          variant="outlined"
          className={`${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""
            }`}
        >
          <InputLabel
            htmlFor={`outlined-adornment-prefix-${lableContent}`}
            className={`${errorText ? "error" : ""}`}
          >
            {lableContent}
          </InputLabel>
          <OutlinedInput
            id={`outlined-adornment-prefix-${lableContent}`}
            value={inputValue || ""}
            onChange={onChange}
            error={errorText}
            onFocus={onFocusState}
            autoFocus={!!autoFocus}
            onBlur={checkBlurState}
            inputProps={{ tabIndex }}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            startAdornment={
              <InputAdornment position="start">{prefix}</InputAdornment>
            }
          />
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </FormControl>
      )}
      {!noEditable && unit && !prefix && !type && (
        <FormControl
          fullWidth
          variant="outlined"
          className={`${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""
            }`}
        >
          <InputLabel
            htmlFor={`outlined-adornment-unit-${lableContent}`}
            className={`${errorText ? "error" : ""}`}
          >
            {lableContent}
          </InputLabel>
          <OutlinedInput
            id={`outlined-adornment-unit-${lableContent}`}
            value={value || ""}
            onChange={onChange}
            error={errorText}
            onFocus={() => setFocus(true)}
            autoFocus={!!autoFocus}
            inputProps={{ tabIndex }}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            endAdornment={
              <InputAdornment position="end">{unit}</InputAdornment>
            }
          />
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </FormControl>
      )}
      {!noEditable && prefix && unit && !type && (
        <FormControl
          fullWidth
          variant="outlined"
          className={`${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""
            }`}
        >
          <InputLabel
            htmlFor={`outlined-adornment-both-${lableContent}`}
            className={`${errorText ? "error" : ""}`}
          >
            {lableContent}
          </InputLabel>
          <OutlinedInput
            id={`outlined-adornment-both-${lableContent}`}
            value={inputValue || ""}
            onChange={onChange}
            error={errorText}
            autoFocus={!!autoFocus}
            onFocus={onFocusState}
            onBlur={checkBlurState}
            inputProps={{ tabIndex }}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            startAdornment={
              <InputAdornment position="start">{prefix}</InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">{unit}</InputAdornment>
            }
          />
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </FormControl>
      )}
      {!noEditable && !mask && !prefix && !unit && !type && (
        <TextField
          variant="outlined"
          label={lableContent}
          value={value || ""}
          onChange={onChange}
          error={error}
          onBlur={!!onCustomBlur ? onCustomBlur : enableBlur}
          helperText={errorText}
          autoComplete={"off"}
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          autoFocus={!!autoFocus}
          InputProps={{ inputProps: { tabIndex } }}
        />
      )}
      {!noEditable && mask && !type && (
        <InputMask
          mask={maskValue}
          value={value || ""}
          onChange={onChange}
          onBlur={!!onCustomBlur ? onCustomBlur : enableBlur}
          maskPlaceholder={null}
        >
          <TextField
            variant="outlined"
            label={lableContent}
            error={error}
            helperText={errorText}
            autoFocus={!!autoFocus}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            InputProps={{ inputProps: { tabIndex } }}
          />
        </InputMask>
      )}
      {!noEditable && type && type === "area" && (
        <TextField
          variant="outlined"
          label={lableContent}
          value={value || ""}
          multiline={true}
          rows={minRows || 25}
          rowsMax={30}
          onChange={onChange}
          error={error}
          onBlur={enableBlur}
          helperText={errorText}
          autoComplete={"off"}
          autoFocus={!!autoFocus}
          style={{ ...customStyle, pointerEvents: disabled ? 'none' : 'auto' }}
          InputProps={{ inputProps: { tabIndex } }}
        />
      )}
      {!noEditable && !mask && type && type === "password" && (
        <div
          className={`MuiFormControl-root MuiTextField-root password-input ${errorText ? "error" : ""
            } ${focus ? "Mui-focused Mui-focused" : ""}`}
        >
          {(focus || !!inputValue) && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <Password
            value={value || ""}
            onChange={onChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete={"new-password"}
            placeholder={focus ? "" : "Password"}
            tabIndex={tabIndex}
          />
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </div>
      )}
      {!noEditable && type && type === "date" && (
        <div
          className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? "error" : ""
            } ${focus ? "Mui-focused Mui-focused" : ""}`}
        >
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <DatePicker
            value={!!value && moment(value).isValid() ? moment(value) : null}
            format={dateFormat}
            placeholder={label}
            onChange={onChangeDate}
            suffixIcon={<ExpandSVG />}
            tabIndex={tabIndex}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </div>
      )}
      {!noEditable && type && type === "time" && (
        <div
          className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? "error" : ""
            } ${focus ? "Mui-focused Mui-focused" : ""}`}
        >
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <TimePicker
            style={{ width: "100%", pointerEvents: disabled ? 'none' : 'auto' }}
            format={timeFormat}
            use12Hours
            value={
              !!value && moment(value, timeFormat).isValid()
                ? moment(value, timeFormat)
                : null
            }
            placeholder={label}
            tabIndex={tabIndex}
            suffixIcon={<ExpandSVG />}
            onChange={onChangeTime}
            onOpenChange={(open) => {
              setFocus(open);
              setOpenTime(open);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              if (!openTime) {
                setFocus(false);
              }
            }}
          />
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </div>
      )}
      {!noEditable && type && type === "check" && !multiple && (
        <div
          className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? "error" : ""
            }`}
        >
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <Select
            showSearch={true}
            ref={selectRef}
            optionFilterProp="children"
            placeholder={label}
            suffixIcon={<ExpandSVG onClick={() => setFocus(!focus)} />}
            value={options && (value || undefined)}
            onChange={onChangeSelect}
            showArrow={hideIconSelect ? false : true}
            onSearch={onSearch}
            autoClearSearchValue={autoClearSearchValue ? true : false}
            notFoundContent={notFoundContent ? notFoundContent : null}
            allowClear={false}
            tabIndex={tabIndex}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            open={focus}
            onFocus={(e) => {
              setFocus(true);
              selectRef.current.rcSelect.inputRef.focus();
            }}
            onBlur={() => {
              setFocus(false);
            }}
            filterOption={(input, opt) =>
              input &&
              opt.props.children &&
              opt.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {(options || []).map((condition, index) => (
              <Option
                key={`condition-${condition.id}-${index}`}
                value={condition[optionKey || "id"]}
              >
                {condition[valueKey] || condition.value}
              </Option>
            ))}
          </Select>
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </div>
      )}
      {!noEditable && type && type === "check" && multiple && (
        <div
          className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? "error" : ""
            }`}
        >
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <MultiSelect
            disableSearch={disableSearch}
            hasSelectAll={disableMultipleCheckAll ? false : true}
            overrideStrings={{ selectSomeItems: label }}
            options={(options || []).map((opt) => ({
              label: opt[valueKey],
              value: opt[optionKey],
              suffix: opt['suffix'],
            }))}
            value={value ? value : []}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            onChange={onChangeMultiSelect}
            ItemRenderer={CustomItem}
          />
          {!!errorText && (
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}
            >
              {errorText}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default EditableFormInput;
