import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, TimePicker, Select } from "antd";
import moment from "moment";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import SubDataTable from "../../../common/components/SubDataTable";
import {
  addCallAttempts,
  deleteCallAttemp,
  getCallback,
  getPending,
  setGlobalLoading,
  getProcedureCallback,
  getProcedurePending,
  selectPatient,
} from "../../../store/actions";
import EditableMaterialText from "../../../common/components/EditableText/MaterialInput";
import { validateField } from "../../../services/validations";
import DeleteIconButton from "../../../common/components/icons/DeleteIconButton";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
const timeFormat = "HH:mm";

const callResult = [
  {
    id: "call_no_answer",
    value: "Call No Answer",
  },
  {
    id: "left_message",
    value: "Left Message",
  },
];

const CallAttemptComponent = ({ handleContinue, ...restProps }) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const attempt = useSelector((state) => state.newPatient.attempt);

  const dispatch = useDispatch();

  const [attempts, setAttemps] = useState([]);
  const [option, setOption] = useState({
    call_result: "",
    contact_date: "",
    contact_time: "",
  });

  useEffect(() => {
    if (attempt && attempt.call_attempts) {
      const dataTableRows = (attempt.call_attempts || []).map((ca) => {
        let item = "";
        if (!!ca.left_message) {
          item = "Left Message";
        } else if (!!ca.call_no_answer) {
          item = "Call No Answer";
        }
        return {
          item,
          id: ca?.id,
          amount: moment(ca.message_datetime).format("MM/DD/YYYY HH:mm"),
        };
      });
      setAttemps(dataTableRows);
    }
  }, [attempt]);

  const handleChange = (value) => {
    const newOption = { ...option, call_result: value };
    setOption(newOption);
  };

  const handleChangeDate = (dateString) => {
    const newOption = { ...option, contact_date: dateString };
    setOption(newOption);
  };

  const handleChangeTime = (timeString) => {
    const newOption = { ...option, contact_time: timeString };
    setOption(newOption);
  };

  const selectToday = () => {
    const contact_date = moment(new Date()).format("MM/DD/YYYY");
    const contact_time = moment(new Date()).format("HH:mm");
    const newOption = { ...option, contact_date, contact_time };
    setOption(newOption);
  };

  const handleAttempts = async () => {
    if (!option.call_result) {
      alert("Contact result is required!");
      return;
    }

    if (!option.contact_date || !option.contact_time) {
      alert("Contact Date and Contact Time are required!");
      return;
    }

    const { customResource, type } = restProps;
    const patientId =
      customResource && customResource.patient
        ? customResource.patient.id
        : resource.id;

    const checkUpdate = await dispatch(
      addCallAttempts({
        patient_id: parseInt(patientId),
        call_result: option.call_result,
        contact_date: option.contact_date,
        contact_time: option.contact_time,
        procedure_id: customResource?.id ? customResource?.id : null,
      })
    );
    if (checkUpdate && type !== "Procedure") {
      await dispatch(getCallback({}));
      await dispatch(getPending({}));
    } else {
      await dispatch(getProcedureCallback({}));
      await dispatch(getProcedurePending({}));
    }
  };

  const [activeDelete, setActiveDelete] = useState();
  const handleDeleteRow = async (index, row) => {
    const { customResource, type } = restProps;
    if (activeDelete != index) {
      setActiveDelete(index);
    }
    if (activeDelete == index) {
      await dispatch(setGlobalLoading(true));
      if (type !== "Procedure") {
        await dispatch(deleteCallAttemp(row?.id));

        await dispatch(getCallback({}));
        await dispatch(getPending({}));
      } else {
        await dispatch(
          deleteCallAttemp(row?.id, {
            procedure_id: customResource?.id,
          })
        );

        await dispatch(getProcedureCallback({}));
        await dispatch(getProcedurePending({}));
      }
      const patientId =
        customResource && customResource.patient
          ? customResource.patient.id
          : resource.id;
      await dispatch(selectPatient(patientId));
      await dispatch(setGlobalLoading(false));
      setActiveDelete(-1);
    }
  };

  return (
    <div className="resourceContent pr-7px">
      <div className="resourceInfo between">
        <EditableMaterialText
          label={"Call Result"}
          value={option.call_result || undefined}
          handleChange={handleChange}
          type={"check"}
          optionKey={"id"}
          valueKey={"value"}
          options={callResult}
        />
      </div>
      <div className="resourceInfo between">
        <EditableMaterialText
          label={"Contact Date"}
          value={option.contact_date}
          type="date"
          handleChange={handleChangeDate}
        />
      </div>
      <div className="resourceInfo between">
        <EditableMaterialText
          label={"Contact Time"}
          value={option.contact_time}
          type="time"
          handleChange={handleChangeTime}
        />
      </div>

      <div
        className="actionContent"
        style={{ marginTop: "1.5rem", flexWrap: "wrap" }}
      >
        <button
          className="common-btn outlined-btn"
          style={{ marginTop: "0.5rem", paddingRight: "12px" }}
          onClick={selectToday}
        >
          Select Now
        </button>
        <button
          className="common-btn"
          style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
          onClick={handleAttempts}
        >
          Save Data
        </button>
      </div>
      <div className="resourceInfo d-flex" style={{ marginTop: "1.5rem" }}>
        <SubDataTable
          title={
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textAlign: "left",
                }}
              >
                Call Attempt Log
              </div>
            </div>
          }
          data={attempts.map((row, index) => {
            return {
              item: row.item,
              amount: (
                <div style={{ display: "flex" }}>
                  {row.amount}{" "}
                  <DeleteIconButton
                    handleClick={() => handleDeleteRow(index, row)}
                    selected={activeDelete === index}
                    icon={"can"}
                    extraStyle={{
                      marginLeft: "5px",
                    }}
                  />
                </div>
              ),
            };
          })}
        />
      </div>
    </div>
  );
};

export default CallAttemptComponent;
