import Cookies from "universal-cookie";
import * as _ from "lodash";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

const cookies = new Cookies();

export const logout = () => {
  const history = new useHistory();
  cookies.remove("cvai-auth-token");
  cookies.remove("cvai-token-type");
  cookies.remove("cvai-token-expires");
  history.push("/login");
};

export const useOutsideDetect = (ref, ref1, active, handleClick) => {
  const handleClickOutside = (event) => {
    if (
      active &&
      ref.current &&
      !ref.current.contains(event.target) &&
      ref1.current &&
      !ref1.current.contains(event.target)
    ) {
      handleClick(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export const useWindowSize = (ref) => {
  const getSize = () => {
    return {
      width: ref.current ? ref.current.clientWidth : undefined,
      height: ref.current ? ref.current.clientHeight : undefined,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!ref) {
      return false;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const getNewDate = (momentDate) => {
  const dateValue = momentDate
    .format("YYYY,MM,DD,HH,mm")
    .split(",")
    .map((value) => parseInt(value));
  return new Date(
    dateValue[0],
    dateValue[1] - 1,
    dateValue[2],
    dateValue[3],
    dateValue[4]
  );
};

const sortByDate = (a, b) => {
  const aDate = moment(a.start)
  const bDate = moment(b.start);
  return -bDate.diff(aDate, 'days');
}

export const snakeToTitle = (str) => {
  let sentence = str.toLowerCase().split("-");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  
  return sentence.join(" ");
}

export const getOpeningAppointments = (
  type,
  date,
  eventData = [],
  activeData = [],
  closedDates = [],
  openingTime,
  closingTime,
  activeID
) => {
  let events = [];
  let opening = openingTime || "7:00 AM";
  let closing = closingTime || "5:00 PM";

  const mins = moment(closing, "h:mm a").diff(moment(opening, "h:mm a"), "minutes");
  const countOfSlots = Math.round(mins / 15);
  const startHour = moment(opening, "h:mm a").get('hour');
  const startMin = moment(opening, "h:mm a").get('minute');

  switch (type) {
    case "month":
      const openingDates = new Array(35).fill(0);
      const groupedEvents = _.groupBy(eventData, (event) =>
        moment(event.start).format("MM/DD/YYYY")
      );
      const eventDates = Object.keys(groupedEvents);
      const startDate = moment(date).startOf("month").format("MM/DD/YYYY");
      events = openingDates.map((opening, index) => {
        const comparedDate = moment(startDate).add(index, "days");
        if (comparedDate.weekday() === 0 || comparedDate.weekday() === 6) {
          return {
            id: index,
            title: "",
            procedure_name: "",
            status: "",
            referral_condition: "",
            start: getNewDate(moment(comparedDate)),
            end: getNewDate(moment(comparedDate.add(1, "hours"))),
            className: "white",
          };
        }
        if (
          closedDates &&
          closedDates.length &&
          closedDates.includes(comparedDate.format("MM/DD/YYYY"))
        ) {
          return {
            id: index,
            title: "",
            procedure_name: "",
            status: "",
            referral_condition: "",
            start: getNewDate(moment(comparedDate)),
            end: getNewDate(moment(comparedDate.add(1, "hours"))),
            className: "white",
          };
        }
        if (
          activeData &&
          activeData.length &&
          activeData.includes(comparedDate.format("MM/DD/YYYY"))
        ) {
          return {
            id: index,
            title: "",
            procedure_name: "",
            status: "",
            referral_condition: "",
            start: getNewDate(moment(comparedDate)),
            end: getNewDate(moment(comparedDate.add(1, "hours"))),
            className: "grey",
          };
        }

        if (
          eventDates.includes(comparedDate.format("MM/DD/YYYY")) &&
          groupedEvents[comparedDate.format("MM/DD/YYYY")].length > 39
        ) {
          return {
            id: index,
            title: "",
            procedure_name: "",
            status: "",
            referral_condition: "",
            start: getNewDate(moment(comparedDate)),
            end: getNewDate(moment(comparedDate.add(1, "hours"))),
            className: "pink",
          };
        }

        return {
          id: index,
          title: "",
          procedure_name: "",
          status: "",
          referral_condition: "",
          start: getNewDate(moment(comparedDate)),
          end: getNewDate(moment(comparedDate.add(1, "hours"))),
          className: "pink",
        };
      });
      return events;
    case "week":
      const weekDay = moment(date).startOf("week").weekday();
      const openingDays = new Array(7 - weekDay).fill(0);
      const openingSlots = new Array(countOfSlots).fill(0);
      const timeSlotes = [];
      events = [];
      const groupByEvents = _.groupBy(eventData, (event) => `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`);

      if (eventData && eventData.length > 0) {
        for (let group in groupByEvents) {
          const sortedData = groupByEvents[group].sort(sortByDate);
          const subEvents = [];
          for (let groupIdx in sortedData) {
            const subEvent = sortedData[groupIdx];
  
            const selectedDay = moment(subEvent.start);
            let idx = 0;
  
            const diff = moment(subEvent.end).diff(selectedDay, "minutes");
            const count = Math.round(diff / 15);
  
            subEvents.push({
              id: subEvent.id,
              title: subEvent.title,
              patient_id: subEvent?.patient_id,
              procedure_name: subEvent.procedure_name,
              referral_condition: subEvent.referral_condition,
              status: subEvent.status,
              start: getNewDate(moment(subEvent.start)),
              end: getNewDate(moment(subEvent.end)),
              className: `${subEvent.status ? subEvent.status : "purple"} ${groupIdx > 0 && moment(subEvent.start).diff(moment(sortedData[groupIdx - 1].start), 'days') === 0
                  ? 'more-event-status' : ''
                } ${activeID === subEvent.id ? 'rbc-selected' : ''}`,
            })
  
            while (idx < count) {
              const startDate = moment(selectedDay).add(idx * 15, "minutes")
  
              if (!timeSlotes.includes(startDate.format("YYYY/MM/DD/HH/mm"))) {
                timeSlotes.push(startDate.format("YYYY/MM/DD/HH/mm"));
              }
              idx = idx + 1;
            }
          }
          events.push.apply(events, subEvents);
        }
      }

      for (let i in openingDays) {
        const compareDay = moment(date)
          .startOf("week")
          .add(i, "days")
          .set({ hour: startHour, minute: startMin });
        for (let j in openingSlots) {
          const compareSlot = moment(compareDay).add(j * 15, "minutes");
          if (
            !timeSlotes.includes(compareSlot.format("YYYY/MM/DD/HH/mm")) &&
            compareDay.weekday() !== 0 &&
            compareDay.weekday() !== 6
          ) {
            if (
              closedDates &&
              closedDates.length &&
              closedDates.includes(compareDay.format("MM/DD/YYYY"))
            ) {
              events.push({
                id: i.toString() + j.toString(),
                title: "",
                procedure_name: "",
                referral_condition: "",
                start: getNewDate(moment(compareSlot)),
                end: getNewDate(moment(compareSlot.add(15, "minutes"))),
                className: "white",
              });
            } else if (
              activeData &&
              activeData.length &&
              activeData.includes(compareDay.format("MM/DD/YYYY"))
            ) {
              events.push({
                id: i.toString() + j.toString(),
                title: "",
                procedure_name: "",
                referral_condition: "",
                start: getNewDate(moment(compareSlot)),
                end: getNewDate(moment(compareSlot.add(15, "minutes"))),
                className: "grey",
              });
            } else {
              events.push({
                id: i.toString() + j.toString(),
                title: "",
                procedure_name: "",
                referral_condition: "",
                start: getNewDate(moment(compareSlot)),
                end: getNewDate(moment(compareSlot.add(15, "minutes"))),
                className: "pink",
              });
            }
          }
        }
      }

      return events;
    default:
      if (moment(date).weekday() === 0 && moment(date).weekday() === 6) {
        return [];
      }
      const selectedDate = moment(date).format("MM-DD-YYYY");
      const filteredEvents = _.filter(
        eventData,
        (event) => moment(event.start).format("MM-DD-YYYY") === selectedDate
      );
      events = [];
      const dategroupByEvents = _.groupBy(filteredEvents, (event) => `${getNewDate(moment(event.start))} - ${getNewDate(moment(event.end))}`);

      const slotes = [];

      if (filteredEvents && filteredEvents.length > 0) {
        for (let group in dategroupByEvents) {
          const subEvents = [];
          for (let groupIdx in dategroupByEvents[group]) {
            const subEvent = dategroupByEvents[group][groupIdx];
            const selectedDay = moment(subEvent.start);
            let idx = 0;
  
            const diff = moment(subEvent.end).diff(selectedDay, "minutes");
            const count = Math.round(diff / 15);
  
            subEvents.push({
              id: subEvent.id,
              title: subEvent.title,
              patient_id: subEvent?.patient_id,
              procedure_name: subEvent.procedure_name,
              status: subEvent.status, 
              referral_condition: subEvent.referral_condition,
              start: getNewDate(moment(subEvent.start)),
              end: getNewDate(moment(subEvent.end)),
              className: `${subEvent.status ? subEvent.status : "purple"} ${groupIdx > 0 ? 'more-event-status' : ''} ${activeID === subEvent.id ? 'rbc-selected' : ''}`,
            })
  
            while (idx < count) {
              const startDate = moment(selectedDay).add(idx * 15, "minutes")
  
              if (!slotes.includes(startDate.format("YYYY/MM/DD/HH/mm"))) {
                slotes.push(startDate.format("YYYY/MM/DD/HH/mm"));
              }
              idx = idx + 1;
            }
          }
          events.push.apply(events, subEvents);
        }
      }

      const slots = new Array(40).fill(0);
      const selectedDay = moment(date).set({ hour: startHour, minute: startMin });
      for (let i in slots) {
        const selectedSlot = moment(selectedDay).add(i * 15, "minutes");
        if (!slotes.includes(selectedSlot.format("YYYY/MM/DD/HH/mm"))) {
          if (
            closedDates &&
            closedDates.length &&
            closedDates.includes(selectedDay.format("MM/DD/YYYY"))
          ) {
            events.push({
              id: i.toString(),
              title: "",
              procedure_name: "",
              status: "",
              referral_condition: "",
              start: getNewDate(moment(selectedSlot)),
              end: getNewDate(moment(selectedSlot.add(15, "minutes"))),
              className: "white",
            });
          } else if (
            activeData &&
            activeData.length &&
            activeData.includes(selectedDay.format("MM/DD/YYYY"))
          ) {
            events.push({
              id: i.toString(),
              title: "",
              status: "",
              procedure_name: "",
              referral_condition: "",
              start: getNewDate(moment(selectedSlot)),
              end: getNewDate(moment(selectedSlot.add(15, "minutes"))),
              className: "grey",
            });
          }  else {
            events.push({
              id: i.toString(),
              title: "",
              procedure_name: "",
              status: "",
              referral_condition: "",
              start: getNewDate(moment(selectedSlot)),
              end: getNewDate(moment(selectedSlot.add(15, "minutes"))),
              className: "pink",
            });
          }
        }
      }
      return events;
  }
};
export const eventStyleGetter = (event, start, end, isSelected) => {
  // const Day = moment(start).weekday();
  // let color = "#137DD1";
  // if (Day === 2) {
  //   color = "#137DD1";
  // } else if (Day === 3) {
  //   color = "#54D76D";
  // } else if (Day === 4) {
  //   color = "#FE29BC";
  // } else if (Day === 5) {
  //   color = "#";
  // } else if (Day === 6) {
  //   color = "#FA3E5F";
  // } else if (Day === 7) {
  //   color = "#FC3D39";
  // } else if (Day === 8) {
  //   color = "#FA3E5F";
  // }

  return {
    className: event.className,
    // style: {
    //   backgroundColor: color
    // }
  };
};

export const generateRandomString = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const useHover = () => {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}

export const fetchAsBlob = url => fetch(url)
  .then(response => response.blob());

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});

export const getValueFromObjectArray = (source, value, targetKey, targetValue) => {
  if (!source || !source.length) {
    return '';
  }
  const target = source.find(src => src[targetKey] === value);
  if (!target) {
    return '';
  }
  return target[targetValue];
}

export const convertToLongNumber = (num) => {
  const str = "" + num;
  const pad = "0000000";
  return pad.substring(0, pad.length - str.length) + str;
}

export const getCapitalizeString = (str) => {
  if (!str) {
    return "";
  }
  if (str.length < 2) {
    return str.charAt(0).toUpperCase();
  }
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
}
