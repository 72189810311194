/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";
import {
  addNewPatient as addPatient,
  editDemographicsOfPatient,
} from "../../../services/api.services";
import {
  rescheduleProcedure,
  setProcedureData,
  confirmProcedure,
  setPatientSnapshotData,
  setGlobalLoading,
  getProcedurePending, getFollowUp, getExistingFollowUp
} from "../../../store/actions";
import { validateSteps, validateField } from "../../../services/validations";
import {
  newSnapshotOption,
  stateOptions,
} from "../../../common/constants/initialData";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import LoginFormInput from "../../../common/components/EditableText/LoginFormInput";

const { demographics } = newSnapshotOption;

const Demographics = ({ handleContinue, ...restProps }) => {
  const resource = useSelector((state) => state.procedure.resource);
  const scanToken = useSelector((state) => state.scanDriverLicense.scanToken);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const [toastMessage, setToastMessage] = useState(null);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const tabActive = useSelector(
    (state) => state.clinicProviderReview.tabActive
  );
  const dispatch = useDispatch();
  const [patient, setPatient] = useState({});
  const [enableError, setEnableError] = useState(false);
  const [qrData, setQRData] = useState(); // panamacheng
  const [clickedScanID, setClickedScanID] = useState(false); // panamacheng
  const loading = useSelector((state) => state.procedure.loadingResource);
  const [isReschedule, setIsReschedule] = useState(false);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (resource) {
      setPatient(resource.patient);
      setIsReschedule(false);
      dispatch(setProcedureData("loadingSchedule", true));
      dispatch(rescheduleProcedure(resource.patient_id, resource.id, {}));
      const newOption = { prev_date: moment(resource.appointment_date).format("MM/DD/YYYY") };
      dispatch(getFollowUp(resource.patient_id, resource.id, newOption, false, "true"));
      dispatch(getExistingFollowUp(resource.id));
    }
  }, [resource]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingGlobal));
  }, [loading, loadingGlobal]);

  const onConfirm = async () => {
    if (resource) {
      await dispatch(setProcedureData("loadingResource", true));
      await dispatch(confirmProcedure(resource.id));
      await dispatch(getProcedurePending({}));
    }
  };

  const onChangePatient = (type) => (value) => {
    const newPatient = {
      ...patient,
      [type]: value,
    };
    setPatient(newPatient);
    setEnableError(false);
    if (!restProps.isAddForm) {
      reset && changeHandler(newPatient);
    }
  };

  const onChangeAddress = (type) => (value) => {
    let address = "";
    if (value) {
      const splitedAddress = value.split(" ").map((addr) => {
        if (!addr || !addr.length) {
          return "";
        }
        if (addr.length < 2) {
          return addr.charAt(0).toUpperCase();
        }
        return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
      });
      address = splitedAddress.join(" ");
    }

    const newPatient = {
      ...patient,
      [type]: address,
    };
    setPatient(newPatient);
    setEnableError(false);
    if (!restProps.isAddForm) {
      reset && changeHandler(newPatient);
    }
  };

  const updatePatient = async (patient) => {
    setLoadingGlobal(true);
    const updatedPatient = { ...patient };
    updatedPatient["date_of_birth"] =
      patient["date_of_birth"] &&
      moment(patient["date_of_birth"]).format("MM/DD/YYYY");
    updatedPatient["referral_date"] =
      patient.referral_date &&
      moment(patient.referral_date).format("MM/DD/YYYY");
    updatedPatient["primary_care_provider_id"] =
      patient["primary_care_provider_id"] || patient.pcp;
    if (!validateSteps(updatedPatient, demographics)) {
      setEnableError(true);
      return;
    }
    try {
      await editDemographicsOfPatient(updatedPatient);
      await dispatch(setPatientSnapshotData("resource", patient));
      setIsToastr(true);
      setLoadingGlobal(false);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    } catch (error) {
      setLoadingGlobal(false);
    }
  };

  const changeHandler = useCallback(
    debounce((patient) => updatePatient(patient), 400),
    []
  );

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
      <React.Fragment>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"First Name"}
            value={patient.first_name}
            handleChange={onChangePatient("first_name")}
            required={true}
            isError={validateField("text", patient.first_name)}
            enableErrorText={enableError}
            helperText={"First Name is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Last Name"}
            value={patient.last_name}
            handleChange={onChangePatient("last_name")}
            required={true}
            isError={validateField("text", patient.last_name)}
            enableErrorText={enableError}
            helperText={"Last Name is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"DOB"}
            value={
              patient.date_of_birth
                ? moment(patient.date_of_birth).format("MM/DD/YYYY")
                : undefined
            }
            handleChange={onChangePatient("date_of_birth")}
            required={true}
            mask={"date"}
            isError={validateField("date", patient.date_of_birth)}
            enableErrorText={enableError}
            helperText={"Birthday should be MM/DD/YYYY!"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Phone No"}
            value={patient.phone_number}
            handleChange={onChangePatient("phone_number")}
            required={true}
            isError={validateField("phone_number", patient.phone_number)}
            enableErrorText={enableError}
            helperText={"Phone Number should be (XXX) XXX-XXXX format"}
            mask={"phone"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Phone No"}
            value={patient.secondary_phone}
            handleChange={onChangePatient("secondary_phone")}
            mask={"phone"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"E-Mail"}
            value={patient.email}
            handleChange={onChangePatient("email")}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"SSN"}
            value={patient.ssn}
            handleChange={onChangePatient("ssn")}
            mask={"ssn"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Address"}
            value={patient.address}
            handleChange={onChangeAddress("address")}
            required={true}
            isError={validateField("text", patient.address)}
            enableErrorText={enableError}
            helperText={"Address is required"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Apt"}
            value={patient.apt_number}
            handleChange={onChangePatient("apt_number")}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"State"}
            value={patient.state || undefined}
            handleChange={onChangePatient("state")}
            type={"check"}
            valueKey={"value"}
            optionKey={"id"}
            options={stateOptions || []}
            required={true}
            isError={validateField("text", patient.state)}
            enableErrorText={enableError}
            helperText={"State is required"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"City"}
            value={patient.city}
            handleChange={onChangePatient("city")}
            required={true}
            isError={validateField("text", patient.city)}
            enableErrorText={enableError}
            helperText={"City is required"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Zip Code"}
            value={patient.zipcode}
            handleChange={onChangePatient("zipcode")}
            required={true}
            isError={validateField("text", patient.zipcode)}
            enableErrorText={enableError}
            helperText={"Zip Code is required"}
          />
        </div>
        <div className="resourceInfo d-flex" style={{ pointerEvents: "none" }}>
          <LoginFormInput
            label={"Procedure"}
            value={
              resource &&
              resource.procedure_type &&
              resource.procedure_type.value
            }
            disabled={
              resource &&
              resource.procedure_type &&
              resource.procedure_type.value
                ? false
                : true
            }
            noEditable
          />
        </div>
        <div className="resourceInfo d-flex" style={{ pointerEvents: "none" }}>
          <LoginFormInput
            label={"Location"}
            value={resource && resource.location && resource.location.value}
            disabled={
              resource && resource.location && resource.location.value
                ? false
                : true
            }
            noEditable
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Date of Procedure"}
            value={
              resource.appointment_date
                ? moment(resource.appointment_date).format("MM/DD/YYYY")
                : undefined
            }
            disabled
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Time of Procedure"}
            value={
              resource.appointment_date
                ? moment(resource.appointment_date).format("HH:mm")
                : undefined
            }
            disabled
          />
        </div>

        {/*<div
          className="actionContent"
          style={{ flexWrap: "wrap", marginTop: "1.5rem" }}
        >
          {resource.appointment_date && (
            <button
              className="common-btn outlined-btn"
              style={{ marginTop: "0.5rem", paddingRight: "12px" }}
              onClick={onConfirm}
            >
              Confirm
            </button>
          )}
        </div>*/}
      </React.Fragment>
    </div>
  );
};

export default Demographics;
