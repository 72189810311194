import { createReducer } from 'redux-create-reducer'
import AT from '../actionTypes'

const initialState = {
  documents: []
}

export default createReducer(initialState, {
  [`${AT.HIPAA_GET_LIST}_SUCCEEDED`] (state, action) {
    const { documents } = action.payload
    return {
      ...state,
      documents
    }
  },
})
