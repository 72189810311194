import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Icon } from 'antd';
import SubSideNav from '../../common/components/SubSideNav';
import MainContainer from './EmployeeWorkflow';

export default class EmployeeWorkflow extends Component {
  sideNavLinks = [{
    link: '/employee-workflow/employee',
    label: 'Employee Module'
  },
  {
    link: '/employee-workflow/deficiency',
    label: 'Deficiency Module'
  }
];

  state = {
    isSideNav: true
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Employee Workflow</title>
        <meta name="title" content="FAACT Vascular | Employee Workflow" />
      </Helmet>
    )
  }

  openSideNav = () => {
    this.setState({ isSideNav: true });
  }

  closeSideNav = () => {
    this.setState({ isSideNav: false });
  }

  getTitle = () => (
    <div className="sub-title">
      <Icon type="solution" />
      Employee Workflow
    </div>
  );

  render() {
    const { location } = this.props;
    const { isSideNav } = this.state;
    return (
      <div className="clinicWorkflow">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
        />
        <MainContainer location={location} isSideNav={isSideNav} />
      </div>
    )
  }
}
