import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Empty, Select } from "antd";

import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import {
  setProcedureDetails,
  addProcedureDetails,
  getDiseases,
  update_plan_condition,
  getselectedReferralCondition,
  getProcedureType,
  getProcedureData,
  search_plan_suggestion,
} from "../../../store/actions";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import Loading from "../../../common/components/Loading";
import Cookies from "universal-cookie";
import ScrollWrapper from "../../../common/components/ScrollBar";

const cookies = new Cookies();
let timer = null;
const PlanSuggestion = ({ resource }) => {
  const plans = useSelector((state) => state.procedureDetail.plans);
  const [searchResult, setSearchResult] = useState([]);
  const [searchAnswer, setSearchAnswer] = useState([]);
  const [selectResult, setSelectResult] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [focusState, setFocusState] = useState(false);
  const [IDRow, setIDRow] = useState(null);
  const [search, setSearch] = useState("");
  const [activeDelete, setActiveDelete] = useState([]);
  const [removableParent, setRemovableParent] = useState(-1);
  const condition_id_cookie = cookies.get("id_procedure_type_icd");
  const procedureData = useSelector((state) => state.common.procedureData);
  const all_plans = useSelector((state) => state.procedure.search_plan_all);
  const conditionVariable = useSelector(
    (state) => state.procedureDetail.conditionVariable
  );
  const dispatch = useDispatch();
  const selectedReferralCondition = useSelector(
    (state) => state.adminReferralCondition.selectedReferralCondition
  );
  const handleChange = async (type, value, index, indexPlan) => {
    if (plans && plans[indexPlan]) {
      if (type === "side") {
        plans[indexPlan]["side"] = value;
      }
      if (type === "entity_id") {
        const sptValue = value.length > 0 ? value.split(" - ") : [];
        plans[indexPlan]["entity_id"] = sptValue[0];
        plans[indexPlan]["bundle"] = sptValue[1];
        await dispatch(setProcedureDetails("plans", [...plans]));
      } else {
        plans[indexPlan]["condition_serialize"][index][type] =
          value === "THAN" && type === "operator" ? "THAN" : value;
        await dispatch(setProcedureDetails("plans", [...plans]));
      }
    }
  };

  const handleChangeOperator = async (value, index, indexPlan) => {
    const nextIndex = index + 1;
    if (
      plans[indexPlan] &&
      plans[indexPlan]["condition_serialize"] &&
      plans[indexPlan]["condition_serialize"].length > 0
    ) {
      const plan = plans[indexPlan]["condition_serialize"][nextIndex];
      let paramNU = {
        variable_id:
          plan && typeof plan["variable_id"] != "undefined"
            ? plan["variable_id"]
            : "",
        variable_name:
          plan && typeof plan["variable_name"] != "undefined"
            ? plan["variable_name"]
            : "",
        answer:
          plan && typeof plan["answer"] != "undefined" ? plan["answer"] : "",
        comparison:
          plan && typeof plan["comparison"] != "undefined"
            ? plan["comparison"]
            : ">=",
        operator:
          plan && typeof plan["operator"] != "undefined"
            ? plan["operator"]
            : "",
      };
      let paramUN = {
        variable_id: undefined,
        variable_name: undefined,
        answer: undefined,
        comparison: undefined,
        operator: undefined,
      };
      if (plans && plans[indexPlan]) {
        if (value !== "THAN") {
          plans[indexPlan]["condition_serialize"][index + 1] = { ...paramNU };
          await dispatch(setProcedureDetails("plans", [...plans]));
        } else {
          const abc = plans[indexPlan];
          abc["condition_serialize"].map((item, indexMap) => {
            if (indexMap > index) {
              abc["condition_serialize"][indexMap] = null;
            }
          });
          const check = abc["condition_serialize"].filter((item) => {
            if (item != null) {
              return item;
            }
          });
          plans[indexPlan]["condition_serialize"] = [...check, paramUN];
          await dispatch(setProcedureDetails("plans", [...plans]));
        }
      }
    }
  };

  const procedure_type = useSelector(
    (state) => state.procedureDetail.procedure_type
  );

  useEffect(() => {
    if (
      selectedReferralCondition &&
      Object.keys(selectedReferralCondition).length > 0
    ) {
      dispatch(
        setProcedureDetails(
          "plans",
          selectedReferralCondition?.plan_rules
            ? selectedReferralCondition?.plan_rules
            : []
        )
      );
    }
  }, [selectedReferralCondition]);

  useEffect(() => {
    const variableMap = (conditionVariable || []).map((r) => {
      return { id: r.id, value: r.variable_name };
    });
    let answerCheck = [];
    const variableAswer = (conditionVariable || []).map((r) => {
      if (r.answer) {
        (r.answer || []).map((item) => {
          answerCheck = [...answerCheck, item];
        });
      }
    });
    setSearchAnswer([...answerCheck]);
    setSearchResult([...variableMap]);
  }, [conditionVariable]);

  useEffect(() => {
    getProcedureData();
  }, []);

  useEffect(() => {
    if (!localLoading) {
      setSelectResult([...all_plans]);
    }
  }, [all_plans]);

  useEffect(() => {
    dispatch(search_plan_suggestion());
  }, []);

  const handleClickRow = (value) => {
    // setIDRow(value.conditionVariable);
  };

  const removeProvider = async (index, indexPlan) => {
    const checkPlanRow = plans[indexPlan];
    if (plans && index == 0) {
      delete plans[indexPlan];
      const arrayJoin = [...plans].filter((r) => {
        if (r) {
          return r;
        }
      });
      await dispatch(setProcedureDetails("plans", [...arrayJoin]));
      await dispatch(
        update_plan_condition(condition_id_cookie, { rules: [...arrayJoin] })
      );
    }
    if (
      checkPlanRow &&
      checkPlanRow["condition_serialize"] &&
      checkPlanRow["condition_serialize"].length > 1
    ) {
      if (checkPlanRow["condition_serialize"][index].operator === 'THAN'
      || checkPlanRow["condition_serialize"][index].operator === '') {
        checkPlanRow["condition_serialize"].splice(index, 2);
      } else {
        checkPlanRow["condition_serialize"].splice(index, 1);
      }

      const check = checkPlanRow["condition_serialize"].filter((item) => {
        if (item != null) {
          return item;
        }
      });
      if (check && check.length > 0) {
        plans[indexPlan]["condition_serialize"] = [...check];
      } else {
        plans.splice(indexPlan, 1);
      }

      await dispatch(setProcedureDetails("plans", [...plans]));
    } else {
      if (plans) {
        delete plans[indexPlan];
        const arrayJoin = [...plans].filter((r) => {
          if (r) {
            return r;
          }
        });
        await dispatch(setProcedureDetails("plans", [...arrayJoin]));
        await dispatch(
          update_plan_condition(condition_id_cookie, { rules: [...arrayJoin] })
        );
      }
    }
  };

  const update = async () => {
    if (plans && plans[IDRow] && plans[IDRow]["condition_serialize"]) {
      const arrayADD = plans.map((r) => {
        return {
          entity_id: r?.entity_id,
          bundle: r?.bundle,
          side: r?.side,
          condition_id: condition_id_cookie,
          condition_serialize: r?.condition_serialize
            ? [...r?.condition_serialize]
            : [{}],
        };
      });
      await dispatch(
        update_plan_condition(condition_id_cookie, { rules: arrayADD })
      );
    }
  };

  useEffect(() => {
    update();
  }, [plans]);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <ScrollWrapper css="no-padding x-hidden">
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {plans.map((itemPlan, indexPlan) => {
          return (
            <div
              key={indexPlan}
              onClick={() => {
                setIDRow(indexPlan);
              }}
              style={{
                width: "100%",
                marginBottom:
                  itemPlan?.condition_serialize &&
                  itemPlan?.condition_serialize.length > 2
                    ? 6
                    : 0,
                height:
                  itemPlan?.condition_serialize &&
                  itemPlan?.condition_serialize.length > 1
                    ? itemPlan?.condition_serialize.length * 42
                    : 46,
              }}
            >
              <DataTable
                title="plan_suggestion"
                sizeHeightFix={
                  itemPlan?.condition_serialize &&
                  itemPlan?.condition_serialize.length > 1
                    ? itemPlan?.condition_serialize.length * 42
                    : 46
                }
                isFixHeight={true}
                handleClickRow={handleClickRow}
                hideHeader={true}
                hideHeaderHeight="0px"
                allActive={true}
                handleDelete={(value, index) =>
                  removeProvider(index, indexPlan)
                }
                parentIndex={indexPlan}
                handleActiveDelete={(val) => {
                  const activeDeletes = [...activeDelete];
                  activeDeletes[indexPlan] = val;
                  if (removableParent !== indexPlan) {
                    activeDeletes[removableParent] = -1;
                    setRemovableParent(indexPlan);
                  } else {
                    if (val === -1) setRemovableParent(-1);
                  }
                  setActiveDelete(activeDeletes);
                }}
                activeParent={activeDelete[indexPlan]}
                labels={["Variable", "Option", "Value", "Operator"]}
                widths={["35%", "15%", "20%", "30%"]}
                columns={[
                  { sortable: false, key: "variable" },
                  { sortable: false, key: "option" },
                  { sortable: false, key: "value" },
                  { sortable: false, key: "operator" },
                ]}
                rowData={(itemPlan?.condition_serialize || []).map(
                  (item, index) => {
                    let arrayAnswer = [];
                    const answerCheck = (conditionVariable || []).find((r) => {
                      return r.variable_name == item?.variable_name?.value;
                    });
                    if (answerCheck) {
                      const allAnswerCheck = (answerCheck.answer || []).map(
                        (item, index) => {
                          return { id: item.id, answer: item.answer };
                        }
                      );
                      arrayAnswer = [...allAnswerCheck];
                    }
                    return {
                      key: index,
                      disableDelete:
                        typeof item?.operator === "undefined" && true,
                      fullsize: typeof item?.operator === "undefined" && true,
                      isSide:
                        typeof item?.operator === "undefined" ? true : false,
                      componentSide: (
                        <Select
                          placeholder="Side"
                          suffixIcon={<ExpandSVG />}
                          value={itemPlan?.side}
                          onChange={(value) => {
                            handleChange("side", value, index, indexPlan);
                          }}
                          style={{ width: "100%", borderBottom: 0 }}
                        >
                          {["Not applicable", "Left", "Right", "Bilateral"].map(
                            (r, index) => {
                              return (
                                <Select.Option
                                  key={r}
                                  value={r}
                                >
                                  {r}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      ),
                      variable:
                        typeof item?.operator === "undefined" ? (
                          <Select
                            key={index}
                            placeholder="Plan"
                            value={`${
                              itemPlan?.entity_id
                                ? `${itemPlan?.entity_id} -`
                                : ""
                            } ${itemPlan?.bundle ? itemPlan?.bundle : ""}`}
                            suffixIcon={<ExpandSVG />}
                            onChange={(value) => {
                              handleChange(
                                "entity_id",
                                value,
                                index,
                                indexPlan
                              );
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {(selectResult || []).map((r, index) => {
                              return (
                                <Select.Option
                                  key={`${r?.id} - ${r?.type}`}
                                  value={`${r?.id} - ${r?.type}`}
                                >
                                  {r.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        ) : (
                          <Select
                            placeholder={"Variable"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.variable_id}
                            onChange={(value) => {
                              handleChange(
                                "variable_id",
                                value,
                                index,
                                indexPlan
                              );
                              handleChange(
                                "variable_name",
                                searchResult.find((r) => r.id == value),
                                index,
                                indexPlan
                              );
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {(searchResult || []).map((opt, index) => (
                              <Select.Option
                                key={`conditionVariable-${index}-${opt.id}`}
                                value={opt.id}
                              >
                                {opt.value}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                      option:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <Select
                            placeholder={"Opt"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            showArrow={false}
                            value={item?.comparison}
                            onChange={(value) => {
                              handleChange(
                                "comparison",
                                value,
                                index,
                                indexPlan
                              );
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {["<=", "=", ">="].map((opt) => (
                              <Select.Option key={`option--${opt}`} value={opt}>
                                {opt}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                      value:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <Select
                            placeholder={"Answer"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.answer}
                            onChange={(value) => {
                              handleChange("answer", value, index, indexPlan);
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {(arrayAnswer || []).map(
                              (opt) =>
                                opt.answer && (
                                  <Select.Option
                                    key={`answer-${opt.answer}`}
                                    value={opt.answer}
                                  >
                                    {opt.answer}
                                  </Select.Option>
                                )
                            )}
                          </Select>
                        ),
                      operator:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <Select
                            placeholder={"Operator"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.operator || undefined}
                            onChange={(value) => {
                              handleChange("operator", value, index, indexPlan);
                              handleChangeOperator(value, index, indexPlan);
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {["OR", "AND", "THAN"].map((opt) => (
                              <Select.Option
                                key={`operator-${opt}`}
                                value={opt}
                              >
                                {opt}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                    };
                  }
                )}
              />
            </div>
          );
        })}
      </div>
    </ScrollWrapper>
  );
};

export default PlanSuggestion;
