import React, { Component } from 'react';
import TopBar from '../../../common/components/SubTopBar';
import ProcedureCondition from './Procedure/ProcedureCondition';
import { ProcedureDetailsHeaders } from '../constant';
import ConditionDetail from "./Procedure/ConditionDetail";
import OldProcedureCondition from "./Procedure/OldProcedureCondition";
import OldConditionDetail from "./Procedure/OldConditionDetail";
class OldProcedureDetails extends Component {
    state = {
        activeTab: 0
    };

    render() {
        const { activeTab } = this.state;

        return (
            <div className="clinicWorkflow__main-page">
                <TopBar title="Procedure Details" subStatus={''} isBarcode={false} />
                <div className="clinicWorkflow__main-container">
                    <div className="clinicWorkflow__main-subTitle tabContainer">
                        <div className="tabHeaders">
                            {ProcedureDetailsHeaders.map((tab, index) => (
                                <div
                                    key={`tabHeaders-${tab.label}-${index}`}
                                    className={`tabHeaders-item ${activeTab === index ? 'active' : ''}`}
                                    style={{ width: tab.width }}
                                    onClick={() => this.setState({ activeTab: index })}
                                >
                                    {tab.label}
                                </div>
                            ))}
                        </div>
                    </div>
                    {activeTab === 0 && <OldProcedureCondition />}
                    {activeTab === 1 && <OldConditionDetail />}
                </div>
            </div>
        )
    }
}

export default OldProcedureDetails;