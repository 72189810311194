import React, { useState, useEffect, useMemo, useRef } from "react";
import { Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";

import TopBar from "../../../common/components/SubTopBar";
import PDFView from "../../../common/../common/components/PDFView";
import FaxingSidebar from "./FaxingSidebar";
import { StaticResource } from "../constant";
import {
  LeftCharvenSVG,
  SummaryEmptySVG,
} from "../../../common/components/icons/SVGIcon";
import {
  setGlobalLoading
} from "../../../store/actions";
import SidebarExtension from "../../../common/components/SidebarExtension";

const { TabPane } = Tabs;

export const FaxingModule = (props) => {
  const detail_fax_logs = useSelector(
    (state) => state.patientRecords.detail_fax_logs
  );
  const loadingDetailLogs = useSelector(
    (state) => state.patientRecords.loadingDetailLogs
  );
  const loadingFaxLogs = useSelector(
    (state) => state.patientRecords.loadingFaxLogs
  );

  const [isSidebar, setIsSidebar] = useState(true);
  const [activeTab, setActiveTab] = useState("incoming-fax");
  const [changedTab, setChangedTab] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  useEffect(() => {
    if (loadingDetailLogs) {
      setChangedTab(false);
    }
  }, [loadingDetailLogs]);

  useEffect(() => {
    dispatch(setGlobalLoading(loadingDetailLogs || loadingFaxLogs))
  }, [loadingDetailLogs, loadingFaxLogs]);

  const enablePDFViewer = useMemo(() => {
    if (loadingDetailLogs || (!loadingDetailLogs && changedTab)) {
      return false;
    }
    if (detail_fax_logs && detail_fax_logs.media_url) {
      return true;
    }
    return false;
  }, [loadingDetailLogs, changedTab, detail_fax_logs]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleTab = (activeKey) => {
    setActiveTab(activeKey);
    setChangedTab(true);
  };
  const sidebarRef = useRef();
  
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Faxing Module" subStatus="" />
      <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <Tabs className="patientChart" type="card" onChange={handleTab}>
              <TabPane tab="Incoming Fax" key="incoming-fax">
                <PDFView url={enablePDFViewer && detail_fax_logs.media_url} />
              </TabPane>
              <TabPane tab="Outgoing Fax" key="outgoing-fax">
                <PDFView url={enablePDFViewer && detail_fax_logs.media_url} />
              </TabPane>
            </Tabs>
          </div>
          <FaxingSidebar
            ref={sidebarRef}
            resource={StaticResource}
            activeTab={activeTab}
            isShow={isSidebar}
            handleCloseSidebar={handleCloseSidebar}
          />
          <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
        </div>
      </div>
    </div>
  );
};

export default FaxingModule;
