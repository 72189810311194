import { Icon } from 'antd';
import React, { useMemo } from 'react';
import { CheckSVG, RedCloseSVG } from '../icons/SVGIcon';
import './style.scss';

const ToastMessage = ({ type, msg, status, width, isStatic }) => {
  const varient = useMemo(() => {
    if (status === 'success') {
      return 'blue-box';
    } else if (status === 'failed') {
      return 'red-box';
    }

    return 'default';
  }, [status]);

  return (
    <div className={`ToastMessage ${varient} ${isStatic ? 'static' : ''}`} style={width ? { width } : {}}>
      <div className="ToastMessage__container">
        {status !== 'failed' && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>}
        {status === 'failed' && <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28"/>}
        <div className="ToastMessage__infoSection">
          <div className="ToastMessage__infoSection-title">
            {type}
          </div>
          <div className="ToastMessage__infoSection-msg">
            {msg}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastMessage;
