import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../../common/components/Card";
import ScrollWrapper from "../../../common/components/ScrollBar";

import {
  addProcedureDetails,
  setProcedureDetails,
  save_angio_type_variable,
  edit_angio_type_variable,
  get_angio_type_variable,
  angio_condition_type_icd,
  angio_condition_type_cpt,
} from "../../../store/actions";

import AngioSearch from "./AngioSearch";
import ProviderBlock from "./ProcedureProviders";
import SupportLocationBlock from "./LocationsAndSupport";
import VariableCaptureBlock from "./VariableCapture";
import DocumentationBlock from "./TemplateDocumentation";
import UltrasoundBlock from "./FollowUpUltrasounds";
import CPTCodeLogicBlock from "./cptCodeLogic";
import IndicationCode from "./IndicationCode";
import "../style.scss";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const AngioDetail = (props) => {
  const [activeCard, setActiveCard] = useState('');
  const [resource, setResource] = useState(null);
  const angioVariable = useSelector(
    (state) => state.procedureDetail.angioVariable
  );
  const cptCodesAngio = useSelector(
    (state) => state.procedureDetail.cptCodesAngio
  );
  const icdCodesAngio = useSelector(
    (state) => state.procedureDetail.icdCodesAngio
  );
  const dispatch = useDispatch();
  const detail_angio_type = useSelector(
    (state) => state.procedureDetail.detail_angio_type
  );
  const angio_type_variable_list = useSelector(
    (state) => state.procedureDetail.angio_type_variable_list
  );
  // const id_angio_type = cookies.get("id_angio_type");

  const handleAddNewData = async (type, data) => {
    if (detail_angio_type && Object.keys(detail_angio_type).length > 0) {
      await dispatch(addProcedureDetails(type, data));
      await dispatch(
        save_angio_type_variable({
          question: "",
          angio_procedure_type_id: detail_angio_type.id,
          answers: [],
        })
      );
      await dispatch(get_angio_type_variable(detail_angio_type.id));
    }
  };

  const updateICD = async () => {
    const arrayADD = icdCodesAngio.map((r) => {
      return {
        disease_id: r?.disease_id,
        angio_procedure_type_id: detail_angio_type.id,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                icd_code: { icd_code: "", icd_name: "" },
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    let param = {
      disease_id: 1,
      angio_procedure_type_id: detail_angio_type.id,
      condition_serialize: [
        {
          icd_code: { icd_code: "", icd_name: "" },
          variable_id: undefined,
          variable_name: undefined,
          answer: undefined,
          comparison: ">=",
          operator: "Select",
        },
      ],
    };
    await dispatch(
      setProcedureDetails(
        "icdCodesAngio",
        arrayADD && Object.keys(arrayADD).length > 0
          ? [...arrayADD, param]
          : [param]
      )
    );
    await dispatch(
      angio_condition_type_icd(detail_angio_type.id, {
        rules:
          arrayADD && Object.keys(arrayADD).length > 0
            ? [...arrayADD, param]
            : [param],
      })
    );
  };

  const updateCPT = async () => {
    const arrayADD = cptCodesAngio.map((r) => {
      return {
        cpt_code_id: r?.cpt_code_id,
        angio_procedure_type_id: detail_angio_type.id,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                cpt_code: { code: "", short_desc: "" },
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    let param = {
      cpt_code_id: 1,
      angio_procedure_type_id: detail_angio_type.id,
      condition_serialize: [
        {
          cpt_code: { code: "", short_desc: "" },
          variable_id: undefined,
          variable_name: undefined,
          answer: undefined,
          comparison: ">=",
          operator: "Select",
        },
      ],
    };
    await dispatch(
      setProcedureDetails(
        "cptCodesAngio",
        arrayADD && Object.keys(arrayADD).length > 0
          ? [...arrayADD, param]
          : [param]
      )
    );
    await dispatch(
      angio_condition_type_cpt(detail_angio_type.id, {
        rules:
          arrayADD && Object.keys(arrayADD).length > 0
            ? [...arrayADD, param]
            : [param],
      })
    );
  };

  const update = async () => {
    if (detail_angio_type && Object.keys(detail_angio_type).length > 0) {
      const index = angioVariable && angioVariable.length;
      if (index > 0) {
        angioVariable[`${index - 1}`]["answer"] = [
          ...angioVariable[`${index - 1}`]["answer"],
          {
            answer: "",
            text_template: "",
          },
        ];
        await dispatch(
          setProcedureDetails("angioVariable", [...angioVariable])
        );
        // call api save answer
        await dispatch(
          edit_angio_type_variable(
            angio_type_variable_list &&
              angio_type_variable_list[angio_type_variable_list.length - 1][
                "id"
              ],
            {
              variable_name:
                angio_type_variable_list &&
                angio_type_variable_list[angio_type_variable_list.length - 1][
                  "variable_name"
                ],
              question:
                angioVariable &&
                angioVariable.length > 0 &&
                angioVariable[angioVariable.length - 1]["quest"],
              angio_procedure_type_id: detail_angio_type.id,
              answers:
                angioVariable &&
                angioVariable.length > 0 &&
                angioVariable[angioVariable.length - 1]["answer"],
            }
          )
        );
      }
      await dispatch(get_angio_type_variable(detail_angio_type.id));
    }
  };

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>Procedure Detail | FAACT Vascular</title>
        <meta name="title" content="Procedure Detail | FAACT Vascular" />
      </Helmet>
    );
  };

  return (
    <div
      className="clinicWorkflow__main-section"
      style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}
    >
      <div className="fullContainer has-no-sidebar">
        <ScrollWrapper css="no-padding x-hidden">
          <div
            className="mainContainer"
            style={{
              marginLeft: "-10px",
              paddingTop: "0",
              paddingBottom: "0",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "stretch",
            }}
          >
            <CardBlock
              title="Search OBL Procedures"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <AngioSearch setResource={setResource} />
            </CardBlock>

            <CardBlock
              title="OBL Procedure Providers"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <ProviderBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="Follow-Up Ultrasounds"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <UltrasoundBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="Template Documentation"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <DocumentationBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="Variable Capture"
              onClick={() => {setActiveCard('variable_capture')}}
              varient={(activeCard == '' || activeCard == 'variable_capture') ? "half" : "quarter"}
              other=""
              contentPadding="1rem"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      detail_angio_type &&
                      Object.keys(detail_angio_type).length <= 0
                    }
                    className="text-btn"
                    onClick={() => {
                      handleAddNewData("angioVariable", {
                        quest: "",
                        answer: [],
                      });
                    }}
                  >
                    Add Specification +
                  </button>
                  <button
                    disabled={
                      detail_angio_type &&
                      Object.keys(detail_angio_type).length <= 0
                    }
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={update}
                  >
                    Add Answer +
                  </button>
                </div>
              }
            >
              <VariableCaptureBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="Indication Codes"
              onClick={() => {setActiveCard('indication')}}
              varient={(activeCard == 'indication') ? "half" : "quarter"}
              other=""
              contentPadding="1rem"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      detail_angio_type &&
                      Object.keys(detail_angio_type).length <= 0
                    }
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={updateICD}
                  >
                    Add +
                  </button>
                </div>
              }
            >
              <IndicationCode resource={resource} />
            </CardBlock>
            <CardBlock
              title="CPT Code Logic"
              onClick={() => {setActiveCard('cpt_code_logic')}}
              varient={(activeCard == 'cpt_code_logic') ? "half" : "quarter"}
              other=""
              contentPadding="1rem"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      detail_angio_type &&
                      Object.keys(detail_angio_type).length <= 0
                    }
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={updateCPT}
                  >
                    Add +
                  </button>
                </div>
              }
            >
              <CPTCodeLogicBlock resource={resource} />
            </CardBlock>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default AngioDetail;
