import React from 'react';
import { useSelector } from 'react-redux';
import ExtendDataTable from "../../../../common/components/SubDataTable/ExtendDataTable";

const titleStyle = {
  textAlign: 'left',
  paddingLeft: '11px',
  color: 'rgba(0, 0, 0, 0.65)',
  fontWeight: 600,
  borderLeft: '1px solid #dfe3e6',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  fontSize: '14px'
}

const ReviewIcdCode = () => {
  const icdCodes = useSelector(state=>state.clinicProviderReview.icd_codes);
  const getTitle = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <div className='td' style={{ ...titleStyle, width: '30%', borderLeft: 0 }}>
            ICD Code
        </div>
        <div className='td' style={{ ...titleStyle, width: '70%' }}>
            Diagnosis
        </div>
      </div>
    );
  };

  return (
    <div className="review-icd-code">
      <ExtendDataTable
        title={getTitle()}
        data={icdCodes}
        options={['icd_code', 'icd_name']}
        widths={['30%', '70%']}
        minRows={7}
      />
    </div>
  )
}

export default ReviewIcdCode;
