import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";

export const getCommunicationRecords = createActionThunk(
  AT.GET_COMMUNICATION_RECORDS,
  ({ patient_id, result, method, paging }) =>
    API.getCommunicationRecords({ patient_id, result, method, paging })
);

export const getFaxLogs = createActionThunk(
  AT.GET_FAX_LOGS,
  ({
    patient_id,
    user_id,
    pcp_id,
    direction,
    dateSort,
    patientSort,
    typeSort,
  }) =>
    API.getFaxLogs({
      patient_id,
      user_id,
      pcp_id,
      direction,
      dateSort,
      patientSort,
      typeSort,
    })
);
export const getScanLogs = createActionThunk(
  AT.GET_SCAN_LOGS,
  ({
    patient_id,
    user_id,
    pcp_id,
    direction,
    dateSort,
    patientSort,
    typeSort,
  }) =>
    API.getScanLogs({
      patient_id,
      user_id,
      pcp_id,
      direction,
      dateSort,
      patientSort,
      typeSort,
    })
);

export const filterFaxLogs = createActionThunk(
  AT.FILTER_FAX_LOG,
  ({ patient_id, user_id, pcp_id, direction }) =>
    API.filterFaxLogs({ patient_id, user_id, pcp_id, direction })
);

export const sendFax = createActionThunk(AT.SEND_FAX, (id, params) =>
  API.sendFax(id, params)
);
export const emailFax = createActionThunk(AT.EMAIL_FAX, (id, params) =>
  API.emailFax(id, params)
);
export const printFax = createActionThunk(AT.PRINT_FAX, (id) =>
  API.printFax(id)
);
export const sendFaxPatientDocument = createActionThunk(
  AT.SEND_FAX_PATIENT_DOCUMENT,
  (id, params) => API.sendFaxPatientDocument(id, params)
);
export const emailPatientDocument = createActionThunk(
  AT.EMAIL_PATIENT_DOCUMENT,
  (id, params) => API.emailPatientDocument(id, params)
);
export const emailPatientDocumentBulk = createActionThunk(
  AT.EMAIL_PATIENT_DOCUMENT_BULK,
  (params) => API.emailPatientDocumentBulk(params)
);
export const printPatientDocumentBulk = createActionThunk(
    AT.PRINT_PATIENT_DOCUMENT_BULK,
    (params) => API.printPatientDocumentBulk(params)
);
export const faxPatientDocumentBulk = createActionThunk(
  AT.FAX_PATIENT_DOCUMENT_BULK,
  (params) => API.faxPatientDocumentBulk(params)
);
export const printPatientDocument = createActionThunk(
  AT.PRINT_PATIENT_DOCUMENT,
  (id) => API.printPatientDocument(id)
);

export const set_default_toast = createActionThunk(
  AT.SET_DEFAULT_TOAST,
  () => {}
);

export const detailFaxLog = createActionThunk(AT.DETAIL_FAX_LOG, (id) =>
  API.detailFaxLog(id)
);
export const clearFax = createActionThunk(AT.CLEAR_DEATAIL_FAX_LOG, () => {});

export const sendScan = createActionThunk(AT.SEND_SCAN, (id, params) =>
  API.sendScan(id, params)
);
export const emailScan = createActionThunk(AT.EMAIL_SCAN, (id, params) =>
  API.emailScan(id, params)
);
export const printScan = createActionThunk(AT.PRINT_SCAN, (id) =>
  API.printScan(id)
);
export const detailScanLog = createActionThunk(AT.DETAIL_SCAN_LOG, (id) =>
  API.detailScanLog(id)
);
export const clearScan = createActionThunk(AT.CLEAR_DEATAIL_SCAN_LOG, () => {});
export const saveFaxToDocument = createActionThunk(
  AT.SAVE_FAX_TO_DOCUMENT,
  (id, params) => API.saveFaxToDocument(id, params)
);
export const setPatientRecordData = createActionThunk(
  AT.SET_PATIENT_RECORD_DATA,
  (type, value) => ({ [type]: value })
);

export const reset_fax_scan_document_log = createActionThunk(
  AT.RESET_FAX_SCAN_DOCUMENT_LOG,
  () => {}
);

export const setPatientForRecords = createActionThunk(
  AT.SET_PATIENT_FOR_RECORDS,
  (id) => API.getPatient({ id })
);

export const getInitialPatientData = createActionThunk(
  AT.GET_INITIAL_PATIENT_DATA,
  () => API.getInitialPatientData()
);
//
export const getPatientHospital = createActionThunk(
  AT.GET_PATIENT_HOSPITAL,
  (id, page) => API.getPatientHospital(id, page)
);

export const save_in_patient_charting = createActionThunk(
  AT.SAVE_IN_PATIENT_CHARTING,
  (params) => API.save_in_patient_charting(params)
);

export const update_in_patient_charting = createActionThunk(
  AT.UPDATE_IN_PATIENT_CHARTING,
  (id, params) => API.update_in_patient_charting(id, params)
);

export const setParamsChart = createActionThunk(
  AT.SET_PARAMS_CHART,
  (type, newData) => ({ [type]: newData })
);

export const removeParamsChart = createActionThunk(
  AT.REMOVE_PARAMS_CHART,
  () => {}
);

export const reset_charting_pdf = createActionThunk(
  AT.RESET_CHARTING_PDF,
  () => {}
);

export const reset_appointment_pdf = createActionThunk(
  AT.RESET_APPOINTMENT_PDF,
  () => {}
);

export const charting_generate_pdf = createActionThunk(
  AT.CHARTING_GENERATE_PDF,
  (id) => API.charting_generate_pdf(id)
);

export const apply_cpt_rules = createActionThunk(AT.APPLY_CPT_RULES, (param) =>
  API.apply_cpt_rules(param)
);

export const reset_cpt_rules = createActionThunk(AT.RESET_CPT_RULES, () => {});

export const patient_document_delete_multiple = createActionThunk(
  AT.PATIENT_DOCUMENT_DELETE_MULTIPLE,
  (params) => API.patient_document_delete_multiple(params)
);
