import React, { useState } from "react";
import { DatePicker, Select } from "antd";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import { useSelector } from "react-redux";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const EvaluationsFilter = (props) => {
  const users = useSelector((state) => state.common.users);
  const userActive =
    users && users["active_users"] && users["active_users"].length > 0
      ? users["active_users"]
      : [];
  return (
    <div className="filterContainer">
      <div className="statusFilterContainer">
        <div
          className="statusFilter"
          style={{ minWidth: "180px", marginLeft: "0" }}
        >
          <DatePicker
            format={dateFormat}
            onChange={(value) => {
              props.changeDate(value);
            }}
            placeholder="Filter by Reporting Period..."
            suffixIcon={<ExpandSVG />}
          />
        </div>
        <div
          className="statusFilter"
          style={{ minWidth: "260px", marginLeft: "10px" }}
        >
          <Select
            placeholder="Filter by Employee Name..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={(value) => {
              props.changeEmployee(value);
            }}
          >
            {(userActive || []).map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default EvaluationsFilter;
