import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, DatePicker } from 'antd';

import { ExpandSVG, SearchSVG } from '../../../common/components/icons/SVGIcon';
import { getProcedureCallback, setProcedureData, setGlobalLoading } from '../../../store/actions';

const { Search } = Input;
const dateFormat = 'MM/DD/YYYY';

const CallbackFilters = ({handleSearch}) => {
  const loadingCallback = useSelector((state) => state.procedure.loadingCallback);
  const loadingPending = useSelector((state) => state.procedure.loadingPending);
  const loadingEvents = useSelector((state) => state.procedure.loadingEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGlobalLoading(
      loadingCallback ||
      loadingPending ||
      loadingEvents
    ));
  }, [
    loadingCallback,
    loadingPending,
    loadingEvents
  ])

  const onChangeDate = (date, dateString) => {
    dispatch(setProcedureData('loadingCallback', true));
    dispatch(getProcedureCallback({procedure_date: dateString}));
  };

  const changeSearch = (value) => {
    handleSearch(value);
  };

  return (
    <div className='filterContainer'>
      <div className='searchFieldContainer'>
        <div className='searchField'>
          <Search
            placeholder="Search"
            onChange={e => changeSearch(e.target.value)}
            style={{ width: '100%' }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default CallbackFilters;
