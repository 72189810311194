import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Header from "../common/components/Header";
import Dashboard from "../views/home";
import HospitalProcedurePage from "../views/hospitalProcedure";
import NewPatientBinderPage from "../views/newPatientBinder";
import PostClinicPage from "../views/postClinic";
import PriorAuthorizationPage from "../views/priorAuthorization";
import ClinicReminders from "../views/clinicReminders";
import ClinicWorkFlow from "../views/clinicWorkflow";
import Communication from "../views/communication";
import PatientRecords from "../views/patientRecords";
import EmployeeWorkflow from "../views/employeeWorkflow";
import InventoryWorkflow from "../views/inventoryWorkflow";
import ProcedureWorkflow from "../views/procedureWorkflow";
import Administrator from "../views/administrator";
import Billing from "../views/billing";
import LoginPage from "../views/login";
import QuickRegister from "../views/quickRegister";
import Support from "../views/supportPage";
import AboutPage from "../views/about";
import LocationSchedulePage from "../views/locationSchedule";
import { useSelector } from "react-redux";

const AppRouters = () => {
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  return (
    <BrowserRouter>
      <StickyContainer>
        <Header/>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/login" exact component={LoginPage} />
          <Route path="/mon/:key" exact component={LocationSchedulePage} />

          <Route
            path="/hospital-procedure"
            exact
            component={HospitalProcedurePage}
          />
          <Route
            path="/new-patient-binder"
            exact
            component={NewPatientBinderPage}
          />
          <Route path="/post-clinic-binder" exact component={PostClinicPage} />
          <Route
            path="/prior-authorizations"
            exact
            component={PriorAuthorizationPage}
          />
          <Route
            path="/subsidiary-workflow/:nav"
            exact
            component={QuickRegister}
          />
          <Route path="/clinic-reminders" exact component={ClinicReminders} />
          <Route
            path="/clinic-workflow/:nav"
            exact
            component={ClinicWorkFlow}
          />
          <Route
            path="/patient-records/:nav"
            exact
            component={PatientRecords}
          />
          <Route path="/communication/:nav" exact component={Communication} />
          <Route
            path="/employee-workflow/:nav"
            exact
            component={EmployeeWorkflow}
          />
          <Route
            path="/inventory-workflow/:nav"
            exact
            component={InventoryWorkflow}
          />
          <Route
            path="/obl-workflow/:nav"
            exact
            component={ProcedureWorkflow}
          />
          <Route path="/administrator/:nav" exact component={Administrator} />
          <Route path="/billing/:nav" exact component={Billing} />
          <Route path="/support" exact component={Support} />
          <Route path="/support/:nav" exact component={Support} />
          <Route path="/about" exact component={AboutPage} />
          <Route render={() => <Redirect to={"/"} />} />
        </Switch>
      </StickyContainer>
    </BrowserRouter>
  );
};

export default AppRouters;
