import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import * as dates from 'date-arithmetic';
import { Select } from 'antd';
import CustomWeek from '../../../common/components/CustomWeek';
import { ExpandSVG } from '../../../common/components/icons/SVGIcon';

const { Option } = Select;

const localizer = momentLocalizer(moment);
const start = dates.startOf(new Date(), 'day');
const min = dates.add(start, 6, 'hours');
const max = dates.add(min, 14, 'hours');

const events = [
  {
    id: 0,
    title: 'Hospital',
    start: new Date(),
    end: new Date()
  }
]

export class CustomToolbar extends React.PureComponent {
  state = {
    view: 'month'
  }

  navigate = action => () => {
    const { onView } = this.props;
    this.setState({ view: action });
    onView(action);
  }

  render() {
    const { label } = this.props;
    const { view } = this.state;

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button">Prev</button>
        </span>
        <span className="rbc-toolbar-filter">
          <div className='rbc-dropdown-Filter'>
            <Select defaultValue={"dec"} placeholder="Filter by Date" suffixIcon={<ExpandSVG />} allowClear={true}>
              <Option value="dec">December 2020</Option>
              <Option value="nov">November 2020</Option>
              <Option value="oct">October 2020</Option>
              <Option value="sep">September 2020</Option>
              <Option value="aug">August 2020</Option>
              <Option value="jul">July 2020</Option>
              <Option value="jun">June 2020</Option>
              <Option value="may">May 2020</Option>
            </Select>
          </div>
          <div className='rbc-dropdown-Filter'>
            <Select placeholder="Filter by Location, Provider" suffixIcon={<ExpandSVG />} allowClear={true}>
              <Option value="loc1">Location 1</Option>
              <Option value="loc2">Location 2</Option>
              <Option value="pvi1">Provider 1</Option>
              <Option value="pvi2">Provider 2</Option>
            </Select>
          </div>
        </span>
        <span className="rbc-btn-group">
          <button
            className={`${view === 'month' && 'rbc-active'}`}
            type="button"
            onClick={this.navigate('month')}
          >
            Monthly
          </button>
          <button
            className={`${view === 'week' && 'rbc-active'}`}
            type="button"
            onClick={this.navigate('week')}
          >Weekly</button>
          <button
            className={`${view === 'day' && 'rbc-active'}`}
            type="button"
            onClick={this.navigate('day')}
          >Daily</button>
        </span>
      </div>
    )
  }
}

const ClinicalCalendar = () => {
  return (
    <Calendar
      localizer={localizer}
      events={events}
      timeslots={1}
      step={15}
      min={min}
      max={max}
      components={{
        toolbar: CustomToolbar
      }}
    />
  );
};

export default ClinicalCalendar;
