import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Empty, Select, Input } from "antd";

import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import {
  search_cpt_code,
  ultrasound_update_result_rule,
  getDetailUltrasounds,
} from "../../../store/actions";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import Loading from "../../../common/components/Loading";
import ScrollWrapper from "../../../common/components/ScrollBar";

let timer = null;
const ResultsLogic = ({ resource }) => {
  const detail_untrasound_list_type = useSelector(
    (state) => state.procedureDetail.detail_untrasound_list_type
  );
  const loading_ultrasound_type = useSelector(
    (state) => state.procedureDetail.loading_ultrasound_type
  );
  const selectData = useSelector((state) => state.common.searchCPT);
  const loadingSearchCPT = useSelector(
    (state) => state.common.loadingSearchCPT
  );
  const [page, setPage] = useState(1);
  const [selectResult, setSelectResult] = useState([]);
  const [selectSearch, setSelectSearch] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [focusState, setFocusState] = useState(false);
  const ultrasound_type_list = useSelector(
    (state) => state.procedureDetail.ultrasound_type
  );
  const dispatch = useDispatch();
  const [data_test, set_data_test] = useState([]);

  const [arrayHeader, setArrayHeader] = useState([]);
  const detailUltrasounds = useSelector(
    (state) => state.common.detailUltrasounds
  );
  const [activeDelete, setActiveDelete] = useState([]);
  const [removableParent, setRemovableParent] = useState(-1);

  useEffect(() => {
    if (detailUltrasounds.headers) {
      if (detailUltrasounds.headers.some((r) => r == "PSV/EDV")) {
        const arrayFilter = detailUltrasounds.headers.filter(
          (r) => r !== "PSV/EDV"
        );
        setArrayHeader(["PSV", "EDV", ...arrayFilter]);
      } else {
        setArrayHeader(detailUltrasounds.headers);
      }
    }
  }, [detailUltrasounds]);

  useEffect(() => {
    if (detail_untrasound_list_type) {
      set_data_test(detail_untrasound_list_type.result_rules || []);
    }
  }, [detail_untrasound_list_type]);

  const handleChange = async (type, value, index, indexCPT) => {
    if (data_test && data_test[indexCPT] && !loading_ultrasound_type) {
      if (type === "text_template") {
        data_test[indexCPT]["text_template"] = value;
        set_data_test([...data_test]);
      }
      if (type === "ultrasound_finding_id") {
        data_test[indexCPT]["ultrasound_finding_id"] = value;
        data_test[indexCPT]["condition_serialize"][index][
          "ultrasound_finding_id"
        ] = value;
        set_data_test([...data_test]);
      } else {
        data_test[indexCPT]["condition_serialize"][index][type] =
          value === "THAN" && type === "operator" ? "THAN" : value;
        set_data_test([...data_test]);
      }
      const params = {
        ultrasound_type_id: detail_untrasound_list_type.id,
        rules: [...data_test],
      };
      clearTimeout(timer);
      timer = setTimeout(async () => {
        updateApi(detail_untrasound_list_type.id, params);
      }, 500);
    }
  };

  const handleChangeOperator = async (value, index, indexCPT) => {
    const nextIndex = index + 1;
    if (data_test && data_test[indexCPT] && !loading_ultrasound_type) {
      data_test[indexCPT]["condition_serialize"][index]["operator"] =
        value === "THAN" ? "THAN" : value;
    }
    if (
      data_test[indexCPT] &&
      data_test[indexCPT]["condition_serialize"] &&
      data_test[indexCPT]["condition_serialize"].length > 0
    ) {
      const plan = data_test[indexCPT]["condition_serialize"][nextIndex];
      let paramNU = {
        ultrasound_finding_id:
          plan && typeof plan["ultrasound_finding_id"] != "undefined"
            ? plan["ultrasound_finding_id"]
            : "",
        text_template:
          plan && typeof plan["text_template"] != "undefined"
            ? plan["text_template"]
            : "",
        answer:
          plan && typeof plan["answer"] != "undefined" ? plan["answer"] : "",
        comparison:
          plan && typeof plan["comparison"] != "undefined"
            ? plan["comparison"]
            : ">=",
        operator:
          plan && typeof plan["operator"] != "undefined"
            ? plan["operator"]
            : "",
      };
      let paramUN = {
        ultrasound_finding_id: undefined,
        text_template: "",
        answer: undefined,
        comparison: undefined,
        operator: undefined,
      };
      if (data_test && data_test[indexCPT] && !loading_ultrasound_type) {
        if (value !== "THAN") {
          data_test[indexCPT]["condition_serialize"][index + 1] = {
            ...paramNU,
          };
          const params = {
            ultrasound_type_id: detail_untrasound_list_type.id,
            rules: [...data_test],
          };
          updateApi(detail_untrasound_list_type.id, params);
        } else {
          const abc = data_test[indexCPT];
          abc["condition_serialize"].map((item, indexMap) => {
            if (indexMap > index) {
              abc["condition_serialize"][indexMap] = null;
            }
          });
          const check = abc["condition_serialize"].filter((item) => {
            if (item != null) {
              return item;
            }
          });
          data_test[indexCPT]["condition_serialize"] = [...check, paramUN];
          const params = {
            ultrasound_type_id: detail_untrasound_list_type.id,
            rules: [...data_test],
          };
          updateApi(detail_untrasound_list_type.id, params);
        }
      }
    }
  };

  useEffect(() => {
    clearTimeout(timer);
    setLocalLoading(true);
    setSelectResult([]);
    setPage(1);
    timer = setTimeout(() => {
      dispatch(search_cpt_code(selectSearch, page));
      setLocalLoading(false);
    }, 1000);
  }, [selectSearch]);

  useEffect(() => {
    if (!localLoading) {
      setSelectResult(selectData);
    }
  }, [selectData]);

  const handleClickRow = (value) => {
    // setIDRow(value.variable);
  };

  const updateApi = async (id, params) => {
    await dispatch(ultrasound_update_result_rule(id, params));
    await dispatch(getDetailUltrasounds(id));
  };

  const removeProvider = async (index, indexCPT) => {
    const checkCPTRow = data_test[indexCPT];

    if (
      checkCPTRow &&
      checkCPTRow["condition_serialize"] &&
      checkCPTRow["condition_serialize"].length > 1 &&
      !loading_ultrasound_type
    ) {

      if (checkCPTRow["condition_serialize"][index].operator === 'THAN'
      || checkCPTRow["condition_serialize"][index].operator === '') {
        checkCPTRow["condition_serialize"].splice(index, 2);
      } else {
        checkCPTRow["condition_serialize"].splice(index, 1);
      }

      const check = checkCPTRow["condition_serialize"].filter((item) => {
        if (item != null) {
          return item;
        }
      });
      if (check && check.length > 0) {
        data_test[indexCPT]["condition_serialize"] = [...check];
      } else {
        data_test.splice(indexCPT, 1);
      }

      const params = {
        ultrasound_type_id: detail_untrasound_list_type.id,
        rules: [...data_test],
      };
      updateApi(detail_untrasound_list_type.id, params);
    } else {
      if (!loading_ultrasound_type) {
        data_test[indexCPT] = null;
        const filterCheck = data_test.filter((r) => r != null);
        const params = {
          ultrasound_type_id: detail_untrasound_list_type.id,
          rules: [...filterCheck],
        };
        updateApi(detail_untrasound_list_type.id, params);
      }
    }
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <ScrollWrapper css="no-padding x-hidden">
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {data_test.map((itemCPT, indexCPT) => {
          return (
            <div
              key={indexCPT}
              style={{
                width: "100%",
                marginBottom:
                  itemCPT?.condition_serialize &&
                  itemCPT?.condition_serialize.length > 2
                    ? 6
                    : 0,
                height:
                  itemCPT?.condition_serialize &&
                  itemCPT?.condition_serialize.length > 1
                    ? itemCPT?.condition_serialize.length * 42
                    : 46,
              }}
            >
              <DataTable
                title="cpt_code"
                handleClickRow={handleClickRow}
                hideHeader={true}
                sizeHeightFix={
                  itemCPT?.condition_serialize &&
                  itemCPT?.condition_serialize.length > 1
                    ? itemCPT?.condition_serialize.length * 42
                    : 46
                }
                isFixHeight={true}
                hideHeaderHeight="0px"
                allActive={true}
                handleDelete={(value, index) => removeProvider(index, indexCPT)}
                parentIndex={indexCPT}
                handleActiveDelete={(val) => {
                  const activeDeletes = [...activeDelete];
                  activeDeletes[indexCPT] = val;
                  if (removableParent !== indexCPT) {
                    activeDeletes[removableParent] = -1;
                    setRemovableParent(indexCPT);
                  } else {
                    if (val === -1) setRemovableParent(-1);
                  }
                  setActiveDelete(activeDeletes);
                }}
                activeParent={activeDelete[indexCPT]}
                labels={[
                  "Ultrasound",
                  "Header",
                  "Option",
                  "Answer",
                  "Operator",
                ]}
                widths={["20%", "15%", "15%", "20%", "30%"]}
                columns={[
                  { sortable: false, key: "ultrasound" },
                  { sortable: false, key: "header" },
                  { sortable: false, key: "option" },
                  { sortable: false, key: "answer" },
                  { sortable: false, key: "operator" },
                ]}
                rowData={(itemCPT?.condition_serialize || []).map(
                  (item, index) => {
                    return {
                      key: index,
                      disableDelete:
                        typeof item?.operator === "undefined" && true,
                      fullsize: typeof item?.operator === "undefined" && true,
                      ultrasound:
                        typeof item?.operator === "undefined" ? (
                          <Input
                            style={{
                              width: "100%",
                              borderBottom: 0,
                            }}
                            onChange={(e) => {
                              handleChange(
                                "text_template",
                                e.target.value,
                                index,
                                indexCPT
                              );
                            }}
                            value={item?.text_template}
                          />
                        ) : (
                          <Select
                            placeholder={"Ultrasound"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.ultrasound_finding_id}
                            onChange={(value) => {
                              handleChange(
                                "ultrasound_finding_id",
                                value,
                                index,
                                indexCPT
                              );
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {(ultrasound_type_list || []).map((opt, index) => (
                              <Select.Option
                                key={`ultrasound-${index}-${opt.id}`}
                                value={opt.id}
                              >
                                {opt.finding_name}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                      option:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <Select
                            placeholder={"Opt"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            showArrow={false}
                            value={item?.comparison}
                            onChange={(value) => {
                              handleChange(
                                "comparison",
                                value,
                                index,
                                indexCPT
                              );
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {["<=", "=", ">="].map((opt) => (
                              <Select.Option key={`option--${opt}`} value={opt}>
                                {opt}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                      header:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <Select
                            placeholder={"Header"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.header}
                            onChange={(value) => {
                              handleChange("header", value, index, indexCPT);
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {arrayHeader.map((opt) => (
                              <Select.Option key={`option--${opt}`} value={opt}>
                                {opt}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                      answer:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : item?.header == "Stenosis" ||
                          item?.header == "Plaque" ||
                          item?.header == "Wave" ||
                          item?.header == "Direction of Flow" ? (
                          <Select
                            placeholder={"Header"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.answer}
                            onChange={(value) => {
                              handleChange("answer", value, index, indexCPT);
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {(item?.header == "Stenosis"
                              ? ["None", "Moderate", "Severe", "Not Identified"]
                              : item?.header == "Plaque"
                              ? [
                                  "Homogeneous",
                                  "Heterogenous",
                                  "Not Identified",
                                  "None",
                                ]
                              : item?.header == "Wave"
                              ? ["Normal", "Abnormal", "Not Identified"]
                              : item?.header == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : []
                            ).map((opt) => (
                              <Select.Option key={`option--${opt}`} value={opt}>
                                {opt}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            style={{
                              width: "100%",
                              borderBottom: 0,
                              borderRadius: "none",
                            }}
                            onChange={(e) => {
                              handleChange(
                                "answer",
                                e.target.value,
                                index,
                                indexCPT
                              );
                            }}
                            value={item?.answer}
                          />
                        ),
                      operator:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <Select
                            placeholder={"Operator"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.operator || undefined}
                            onChange={(value) => {
                              handleChangeOperator(value, index, indexCPT);
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {["OR", "AND", "THAN"].map((opt) => (
                              <Select.Option
                                key={`operator-${opt}`}
                                value={opt}
                              >
                                {opt}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                    };
                  }
                )}
              />
            </div>
          );
        })}
      </div>
    </ScrollWrapper>
  );
};

export default ResultsLogic;
