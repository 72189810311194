import React, { useState, useEffect, useMemo, useRef } from 'react';
import {Input, DatePicker, Select, TimePicker} from 'antd';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import {ExpandSVG, RedRequiredCircleSVG} from '../icons/SVGIcon';
import './style.scss';

const { Option } = Select;
const { Password } = Input;
const dateFormat = 'MM/DD/YYYY';
const timeFormat = 'h:mm A';
const phoneMask = '(999) 999-9999';
const ssnMask = '999-99-9999';
const dateMask = '99/99/9999';

const getLocaleString = (str) => {
  if (!str) {
    return '0.00';
  }
  const num = parseFloat(str).toFixed(2);
  const decimals = num.split('.')[1];
  if (!decimals || decimals === '00') {
    return Math.round(parseFloat(str)).toLocaleString() + '.00';
  }
  if (decimals.charAt(1) === '0') {
    return (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + '0';
  }
  return (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
}

const LoginFormInput = ({
  label,
  value,
  handleChange,
  type,
  isError,
  required,
  options,
  optionKey,
  valueKey,
  enableErrorText,
  helperText,
  mask,
  fullWidth,
  noEditable,
  prefix,
  unit,
  minRows,
  autoFocus,
  disabled,
  reset,
  onKeyDown
}) => {
  const ref = useRef(null);
  const [error, setError] = useState(false);
  const [enableHelper, setEnableHelper] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [openTime, setOpenTime] = useState(false);

  useEffect(() => {
    if (!isError) {
      setError(false);
      setEnableHelper(false);
    }
    if (enableErrorText && required && isError) {
      setEnableHelper(true);
      setError(true);
    }
    if (value) {
      if (prefix === '$') {
        setInputValue(getLocaleString(value));
      } else {
        setInputValue(value);
      }
    }
  }, [value, isError, enableErrorText]);

  useEffect(() => {
    if (ref && autoFocus) {
      ref.current.focus();
    }
  }, [ref, autoFocus]);

  const enableBlur = () => {
    // if (isError) {
    //   setError(true);
    // }
  };

  const checkBlurState = (e) => {
    if (prefix === '$') {
      const str = inputValue.replace(/,/g, '');
      setInputValue(getLocaleString(str))
      if (handleChange) {
        handleChange(str);
      }
    }
  }

  const onChange = (e) => {
    const targetValue = e.target.value;
    setInputValue(targetValue);
    if (handleChange) {
      handleChange(targetValue);
    }
  };

  const onChangeSelect = opt => {
    if (handleChange) {
      handleChange(opt);
    }
  };

  const onChangeDate = (date, dateString) => {
    if (handleChange) {
      handleChange(dateString);
    }
  };

  const onChangeTime = (time, timeString) => {
    if (handleChange) {
      handleChange(timeString);
    }
  };

  const lableContent = useMemo(() => {
    if (required) {
      return <span>{label}</span>
    }
    return label
  }, [required, label]);

  const errorText = useMemo(() => {
    if (enableHelper && helperText) {
      return <span><RedRequiredCircleSVG /> {helperText || ''}</span>
    }
    return '';
  }, [enableHelper]);

  const maskValue = useMemo(() => {
    if (mask === 'phone') {
      return phoneMask;
    }
    if (mask === 'ssn') {
      return ssnMask;
    }
    if (mask === 'date') {
      return dateMask;
    }
    return '';
  }, [mask]);

  return (
    <div className={`editableText material ${fullWidth ? 'fullWidth' : ''}`}>
      {noEditable && !mask && (
        <TextField
          variant="outlined"
          label={lableContent}
          value={value || ''}
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
        />
      )}
      {noEditable && mask && (
        <InputMask
          mask={mask === 'phone' ? phoneMask : ssnMask}
          value={value || ''}
          onBlur={enableBlur}
          maskPlaceholder={null}
        >
          <TextField
            variant="outlined"
            label={lableContent}
            value={value || ''}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          />
        </InputMask>
      )}
      {!noEditable && prefix && !unit && !type && (
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor={`outlined-adornment-prefix-${lableContent}`}>{lableContent}</InputLabel>
          <OutlinedInput
            id={`outlined-adornment-prefix-${lableContent}`}
            value={inputValue || ''}
            onChange={onChange}
            autoFocus={!!autoFocus}
            onBlur={checkBlurState}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            startAdornment={<InputAdornment position="start">{prefix}</InputAdornment>}
          />
        </FormControl>
      )}
      {!noEditable && unit && !prefix && !type && (
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor={`outlined-adornment-unit-${lableContent}`}>{lableContent}</InputLabel>
          <OutlinedInput
            id={`outlined-adornment-unit-${lableContent}`}
            value={value || ''}
            onChange={onChange}
            autoFocus={!!autoFocus}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
          />
        </FormControl>
      )}
      {!noEditable && prefix && unit && !type && (
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor={`outlined-adornment-both-${lableContent}`}>{lableContent}</InputLabel>
          <OutlinedInput
            id={`outlined-adornment-both-${lableContent}`}
            value={inputValue || ''}
            onChange={onChange}
            onBlur={checkBlurState}
            autoFocus={!!autoFocus}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            startAdornment={<InputAdornment position="start">{prefix}</InputAdornment>}
            endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
          />
        </FormControl>
      )}
      {!noEditable && !mask && !prefix && !unit && !type && (
        <TextField
          ref={ref}
          variant="outlined"
          label={lableContent}
          value={value || ''}
          onChange={onChange}
          error={error}
          onBlur={enableBlur}
          helperText={errorText}
          autoFocus={!!autoFocus}
          autoComplete={autoFocus && "username"}
          name={autoFocus && 'username'}
        />
      )}
      {!noEditable && mask && !type && (
        <InputMask
          mask={maskValue}
          value={value || ''}
          onChange={onChange}
          onBlur={enableBlur}
          maskPlaceholder={null}
        >
          <TextField
            variant="outlined"
            label={lableContent}
            error={error}
            helperText={errorText}
            autoFocus={!!autoFocus}
          />
        </InputMask>
      )}
      {!noEditable && (type && type === 'area') && (
        <TextField
          variant="outlined"
          label={lableContent}
          value={value || ''}
          multiline={true}
          rows={minRows || 25}
          rowsMax={30}
          onChange={onChange}
          error={error}
          onBlur={enableBlur}
          helperText={errorText}
          autoComplete={'off'}
          autoFocus={!!autoFocus}
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
        />
      )}
      {!noEditable && !mask && (type && type === 'password') && (
        <div className={`MuiFormControl-root MuiTextField-root password-input ${errorText ? 'error' : ''} ${focus ? 'Mui-focused Mui-focused' : ''}`}>
          {(focus || !!inputValue) && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <Password
            value={value || ''}
            onChange={onChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            placeholder={focus ? '' : 'Password'}
            onKeyDown={onKeyDown}
          />
          {!!errorText && (
            <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>
              {errorText}
            </p>
          )}
        </div>
      )}
      {!noEditable && (type && type === 'date') && (
        <div className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? 'error' : ''} ${focus ? 'Mui-focused Mui-focused' : ''}`}>
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <DatePicker
            value={!!value && moment(value).isValid() ? moment(value) : null}
            format={dateFormat}
            placeholder={label}
            onChange={onChangeDate}
            suffixIcon={<ExpandSVG />}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          />
          {!!errorText && (
            <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>
              {errorText}
            </p>
          )}
        </div>
      )}
      {!noEditable && (type && type === 'time') && (
        <div className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? 'error' : ''} ${focus ? 'Mui-focused Mui-focused' : ''}`}>
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <TimePicker
            format={timeFormat}
            use12Hours
            value={!!value && moment(value, timeFormat).isValid() ? moment(value, timeFormat) : null}
            placeholder={label}
            suffixIcon={<ExpandSVG />}
            onChange={onChangeTime}
            style={{ pointerEvents: disabled ? 'none' : 'auto', width: '100%' }}
            onOpenChange={(open) => {
              setFocus(open);
              setOpenTime(open);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              if(!openTime) {
                setFocus(false);
              }
            }}
          />
          {!!errorText && (
            <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>
              {errorText}
            </p>
          )}
        </div>
      )}
      {!noEditable && (type && type === 'check') && (
        <div className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? 'error' : ''}`}>
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <Select
            showSearch={true}
            optionFilterProp="children"
            placeholder={label}
            suffixIcon={<ExpandSVG />}
            value={value || undefined}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            onChange={onChangeSelect}
            allowClear={true}
            filterOption={(input, opt) =>
              input && opt.props.children && opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {(options || []).map((condition, index) => (
              <Option key={`condition-${condition.id}-${index}`} value={condition[optionKey || 'id']}>{condition[valueKey] || condition.value}</Option>
            ))}
          </Select>
          {!!errorText && (
            <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>
              {errorText}
            </p>
          )}
        </div>
      )}
    </div>
  )
};

export default LoginFormInput;
