import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";
import { Tooltip } from "antd";
import TopBar from "../../../common/components/SubTopBar";
import ClinicSchedule from "./ultrasoundViews/ClinicSchedule";
import UltrasoundFindings from "./ultrasoundViews/UltrasoundFindings";
import Review from "./ultrasoundViews/Review";
import {
  getLocations,
  getProviders,
  getReferralConditions,
  getUltrasounds,
  setActiveReviewChange,
} from "../../../store/actions";
import { UltrasoundTabHeaders } from "../constant";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";

const cookies = new Cookies();

class UltrasoundModule extends Component {
  state = {
    activeTab: 0,
    isSelectedPatient: false,
  };

  componentDidMount = () => {
    this.props.getLocations();
    this.props.getProviders();
    this.props.getReferralConditions();
    this.props.getUltrasounds();
  };

  componentWillReceiveProps(nextProps) {
    const isSelectedPatientProps = nextProps.selectedPatientResource || {};
    if (Object.keys(isSelectedPatientProps).length > 0) {
      this.setState({
        isSelectedPatient: true,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  renderSideComponent = () => {
    const { selectedPatientResource, resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (
      resourceCheck.first_name +
      " " +
      resourceCheck.last_name
    ).toUpperCase();
    const age = resourceCheck.date_of_birth
      ? "(" +
        moment().diff(resourceCheck.date_of_birth, "years") +
        // " yo " +
        // (!!resourceCheck.gender
        //   ? resourceCheck.gender.toUpperCase()
        //   : "MALE") +
        ")"
      : "";
    const allerrgies = [];
    if (resourceCheck.allergies) {
      resourceCheck.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
          allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >
        
        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "24px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && <br />}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip
              placement="bottomRight"
              title={resourceCheck.allergies
                .map((allergy) => allergy.allergy_name)
                .join(", ")}
            >
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>
      
        {selectedPatientResource.gender &&
        selectedPatientResource.gender.toUpperCase() == "MALE" ? (
          <MaleSVG
            style={{ marginLeft: "8px", height: "unset", width: "26px" }}
          />
        ) : (
          <FemaleSVG
            style={{ marginLeft: "8px", height: "unset", width: "26px" }}
          />
        )}
      </div>
    );
  };

  goNextTabReview = () => {
    this.setState({
      activeTab: 2,
    });
    this.props.setActiveReviewChange(false);
  };

  conditionClickProps = (value) => {
    this.setState({
      stateCondition: value,
    });
  };

  changeTabIndex = async (index) => {
    this.setState({ activeTab: index });
  };

  render() {
    const { activeTab, isSelectedPatient } = this.state;
    const { selectedPatientResource } = this.props;

    return (
      <div className="clinicWorkflow__main-page">
        <TopBar
          title="Ultrasound Module"
          isBarcode={isSelectedPatient}
          sideComponent={this.renderSideComponent()}
          subStatus={""}
        />
        <div
          className={`clinicWorkflow__main-container ${
            this.props.isSidebar ? "openSidebar" : ""
          }`}
        >
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <ScrollWrapper css="no-padding tab-header-scroll">
                <div style={{ display: "flex" }}>
                  {UltrasoundTabHeaders.map((tab, index) =>
                    this.state.isSelectedPatient ? (
                      <div
                        key={`tabHeaders-${tab.label}-${index}`}
                        className={`tabHeaders-item ${
                          activeTab === index ? "active" : ""
                        }`}
                        style={{ width: tab.width }}
                        onClick={() => this.changeTabIndex(index)}
                      >
                        {tab.label}
                      </div>
                    ) : (
                      <div
                        key={`tabHeaders-${tab.label}-${index}`}
                        className={`tabHeaders-item ${
                          activeTab === index ? "active" : ""
                        } ${index > 0 && "disabled"}`}
                        style={{ width: tab.width }}
                      >
                        {tab.label}
                      </div>
                    )
                  )}
                </div>
              </ScrollWrapper>
            </div>
          </div>
          {activeTab >= 0 && (
            <ClinicSchedule
              display={activeTab === 0}
              goNextTab={this.goNextTab_}
              goNextTabReview={this.goNextTabReview}
            />
          )}
          {selectedPatientResource && activeTab === 1 && (
            <UltrasoundFindings patientResource={selectedPatientResource} />
          )}
          {selectedPatientResource && activeTab === 2 && (
            <Review patientResource={selectedPatientResource} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  resourceCheck: state.patientSnapshot.resource,
  isSidebar: state.sidebar.isSidebarOpen,
});
const mapDispatchToProps = {
  getLocations,
  getProviders,
  getReferralConditions,
  getUltrasounds,
  setActiveReviewChange,
};
export default connect(mapStateToProps, mapDispatchToProps)(UltrasoundModule);
