import React, { useEffect, useRef, useState } from "react";
import TopBar from "../../../common/components/SubTopBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllSupportModules, setAuthorRead, setSupportRead } from "../../../store/actions";
import moment from "moment";
import { convertToLongNumber } from "../../../common/utils";
import ImageEditor from "../../../common/components/ImageEditor";
import { Icon } from "antd";
import SidebarExtension from "../../../common/components/SidebarExtension";
import DataTable from "../../../common/components/DataTable/DataTable";
import DetailSidebarComponent from "./AllTickets/DetailSidebar";
import { useHistory } from "react-router";
import PusherConnector from "../../../common/components/PusherScan/pusherConnector";

const AllTickets = () => {
  const supportTickets = useSelector((state) => state.supportModule.supports);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const [activeIndex, setRow] = useState(-1);
  const [isSidebar, setIsSidebar] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [previewData, setPreviewData] = useState();
  const sidebarRef = useRef();
  const [pusherChannel, setPusherChannel] = useState(false);
  const [pusherConnector, setPusherConnnector] = useState();
  const dispatch = useDispatch();



  useEffect(() => {
    const pusher = new PusherConnector();
    var channelName = 'support-messages';
    const channel = pusher.createChannel(channelName);
    setPusherConnnector(pusher);  
    setPusherChannel(channel);
  }, [])
  
  useEffect(() => {
    if (pusherChannel && pusherChannel.bind && pusherConnector) {
      var eventName = 'new-ticket';
      pusherConnector.unbindEvent(pusherChannel, eventName);
      pusherConnector.bindEvent(pusherChannel, eventName, async (callback) => {
        const data = await callback;
        dispatch(getAllSupportModules());
      });
    }
  }, [pusherChannel, pusherConnector])


  const handleCloseSidebar = () => setIsSidebar(false);
  const toggleSidebar = () => setIsSidebar(!isSidebar);

  const handleRow = async (index) => {
    setRow(index)
    await dispatch(setSupportRead(supportTickets[index].id));
    await dispatch(getAllSupportModules());
  }

  const handleMediaFileDoubleClicked = (value) => {
    setIsPreview(true);
    setPreviewData(value);
  };

  const handleCloseEditor = () => {
    setIsPreview(false);
  };

  useEffect(() => {
    dispatch(getAllSupportModules());
  }, []);

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="All Support Tickets" subStatus={""} />
      <div
        className={`clinicWorkflow__main-container ${
          isSidebar ? "openSidebar" : ""
        }`}
      >
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            {isPreview ? (
              <div className="fullContainer pt-2">
                {previewData.type === "image" && (
                  <ImageEditor
                    disableEditing={true}
                    onCloseEditor={handleCloseEditor}
                    onSaveImage={() => {}}
                    imageData={previewData}
                  />
                )}
              </div>
            ) : (
            <div className="fullContainer pt-2">
              <div className="tableSection">
                <div className="tableContainer">
                  <DataTable
                    title={"support_tickets"}
                    labels={["Date", "ID", "Subject"]}
                    widths={["30%", "20%", "50%"]}
                    columns={[
                      { sortable: false, key: "date" },
                      { sortable: false, key: "id" },
                      { sortable: false, key: "subject" },
                    ]}
                    disableDelete={true}
                    handleClickRow={(row, index) => {
                      handleRow(index);
                      setIsSidebar(true);
                    }}
                    defaultActive={-1}
                    rowData={(supportTickets || []).map((row) => {
                      return {
                        date: (
                          <div
                            className="iconField td with-icon"
                            style={{
                              display: "flex",
                              paddingLeft: "0.25rem",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {row.support_unread == 1 ? (<Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28"/>) : 
                              row.status === 0 ? <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/> : 
                              row.status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
                            }

                            <p style={{ paddingLeft: "0.75rem" }}>
                              {moment(row.updated_at).format(
                                "YYYY-MM-DD [at] h:ma"
                              )}
                            </p>
                          </div>
                        ),
                        id: convertToLongNumber(row.id),
                        subject: row.subject,
                      };
                    })}
                  />
                </div>
              </div>
            </div>
            )}
          </div>

          {supportTickets && supportTickets[activeIndex] && (
            <>
              <DetailSidebarComponent
                ref={sidebarRef}
                currentUserInfo={currentUserInfo}
                isShow={isSidebar}
                handleCloseSidebar={handleCloseSidebar}
                resource={
                  activeIndex !== -1 ? supportTickets[activeIndex] : undefined
                }
                mediaFileDoubleClicked={handleMediaFileDoubleClicked}
                // onCreatedModule={handleCreatedModule}
              />

              <SidebarExtension
                startingWidth="calc(28.3% - 20px + 0.3rem)"
                sidebarReference={sidebarRef}
                sidebarToggle={toggleSidebar}
                sidebarState={isSidebar}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllTickets;
