import React, { useEffect, useState } from "react";
import {
  CloseIcon,
  SummaryEmptySVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import EditableText from "../../../../common/components/EditableText";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import LoginFormInput from "../../../../common/components/EditableText/LoginFormInput";
import { useDispatch, useSelector } from "react-redux";
import { exportPCM } from "../../../../store/actions";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";

const ProviderReportSidebar = React.forwardRef(({
  resource,
  userId,
  startDate,
  endDate,
  isShow,
  handleCloseSidebar,
  titleName,
}, ref) => {
  const dispatch = useDispatch();
  const export_pcm = useSelector((state) => state.common.export_pcm);
  const [dataDetail, setDataDetail] = useState({});

  const sendExport = async () => {
    if (resource) {
      const result = await dispatch(exportPCM(userId, startDate, endDate));
      window.open(result.url, "_self");
    }
  };

  /*useEffect(() => {
    if (export_pcm && Object.keys(export_pcm).length > 0) {
      window.open(export_pcm.url, '_blank');
    }
  }, [export_pcm]);*/

  useEffect(() => {
    if (resource) {
      setDataDetail(resource);
    }
  }, [resource]);

  if (!isShow || !resource || !Object.keys(resource).length) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={<MaleSVG />}
          title={
            <span className="text-uppercase">
              {startDate && endDate
                ? `${titleName} (${startDate} - ${endDate})`
                : titleName}
            </span>
          }
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
        >
          <div className="resourceInfo d-flex">
            <LoginFormInput
              label={"Total Payment"}
              value={dataDetail.total_pay}
            />
          </div>
          <div className="resourceInfo d-flex">
            <LoginFormInput
              label={"Contribution Margin"}
              value={dataDetail.total_cm}
            />
          </div>
          <div className="resourceInfo d-flex">
            <LoginFormInput
              label={"Practice Margin"}
              value={dataDetail.practice_margin}
            />
          </div>
          <div className="resourceInfo d-flex">
            <LoginFormInput
              label={"% CM"}
              value={dataDetail.percent_cm}
              unit={"%"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <LoginFormInput
              label={"% PM"}
              value={dataDetail.practice_margin_percent}
              unit={"%"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <LoginFormInput label={"Total Paid Out"} value={""} prefix={"$"} />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
                label="Accounts Receivables"
                value={dataDetail.account_receivables}
            />
          </div>
          <hr />
          <div
            className="actionContent"
            style={{
              marginTop: "2rem",
              paddingRight: "1rem",
              flexWrap: "wrap",
            }}
          >
            <button
              onClick={sendExport}
              className="common-btn"
              style={{ marginTop: "0.5rem" }}
            >
              Export to Excel
            </button>
          </div>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default ProviderReportSidebar;
