import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { YellowAlertSVG, CheckSVG } from '../../common/components/icons/SVGIcon';
import TopBar from '../../common/components/TopBar';
import HospitalSideBar from './components/HospitalSidebar';
import CallbackFilter from './components/CallbackFilter';
import PendingFilter from './components/PendingFilter';
import CallbackTable from './components/CallBackTable';
import PendingTable from './components/PendingTable';
import ClinicalCalendar from './components/ClinicalCalendar';
import './style.scss';
import { Icon } from 'antd';

const resultData = [
  {name: 'James Fisher', type: 'Clinic/US Appointment', date: '4/9/2019', result: 'Call No Answer', icon: <YellowAlertSVG />},
  {name: 'Tony Stark', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 1', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 2', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 3', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 4', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 5', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 6', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 7', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 8', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 9', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 10', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />},
  {name: 'Tester 11', type: 'Clinic/US Appointment', date: '6/2/2019', result: 'Confirmed', icon: <YellowAlertSVG />}
];

const resourceData = {
  firstname: 'James',
  lastname: 'Fisher',
  dob: '06/09/1973',
  primaryPhone: '(989) 897-8797',
  secondaryPhone: '(989) 897-8797',
  email: 'haqqani@hotmail.com',
  ssn: '890-09-8977',
  streetAddress: '1109 W St Andrews Rd',
  apt: '',
  suite: '',
  bldg: '',
  state: 'Michigan',
  city: 'Midland',
  zipCode: '48642',
  clinicDate: '07/04/2019',
  result: '',
  order: 'Left Carotid Endarterectomy',
  timeframe: 'Next Available',
  icon: <YellowAlertSVG />
}

export default class PostClinicPage extends Component {
  state = {
    isSidebar: false,
    step: 0
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  }

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Post-Clinic</title>
        <meta name='title' content='FAACT Vascular | Post-Clinic' />
      </Helmet>
    )
  }

  getStatus = (status, status1) => {
    return (
      <div className='statusHealth'>
        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
        {status}{' '}
        <YellowAlertSVG />
        {status1}
      </div>
    );
  }

  handleResource = (step) => {
    this.setState({ step });
  }

  render() {
    return (
      <div className='postClinicPage mainPage'>
        {this.renderMetadata()}
        <TopBar title="Post-Clinic Processing" subStatus={this.getStatus(128, 2)} />
        <HospitalSideBar
          resource={resourceData}
          isShow={this.state.isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
        />
        <div className='main multi-main'>
          <div className='mainContainer'>
            <div className='mainTableContainer'>
              <div className='leftContainer'>
                <div className='sectionTitle'>
                  Post-Clinic Patient Pending
                </div>
                <PendingFilter />
                <div className='tableSection'>
                  <PendingTable data={resultData} title='result' handleSidebar={this.handleSidebar} />
                </div>
              </div>
              <div className='rightContainer'>
                <div className='sectionTitle'>
                  Post-Clinic Patient Call Back
                </div>
                <CallbackFilter />
                <div className='tableSection'>
                  <CallbackTable data={resultData} title='result' handleSidebar={this.handleSidebar} />
                </div>
              </div>
            </div>

            <div className='calendarContainer'>
              <div className='sectionTitle'>
                Clinical Calendar
              </div>
              <ClinicalCalendar />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
