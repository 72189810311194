import { createActionThunk } from 'redux-thunk-actions';

import AT from '../actionTypes';

import * as API from '../../services/api.services';

// Get Inventories
export const getInventorySearch = createActionThunk(
  AT.GET_INVENTORY_SEARCH,
  (query) => API.getInventories(query)
);

// Set Inventory data
export const setInventoryData = createActionThunk(
  AT.SET_INVENTORY_DATA,
  (type, data) => ({ [type]: data })
);

// Get Inventory Product Numbers
export const getInventoryProducts = createActionThunk(
  AT.GET_INVENTORY_PRODUCTS,
  () => API.getInventoryProducts()
);

// Get Inventory Locations
export const getInventoryLocations = createActionThunk(
  AT.GET_INVENTORY_LOCATIONS,
  () => API.getInventoryLocations()
);

// Get Inventory Specific Locations
export const getInventorySpecificLocations = createActionThunk(
  AT.GET_INVENTORY_SPECIFIC_LOCATIONS,
  () => API.getInventorySpecificLocations()
);

// Get Angiographic Supplies
export const getAngiographicSupplies = createActionThunk(
  AT.GET_ANGIOGRAPHIC_SUPPLIES,
  (query) => API.getAgniographicSupplies(query)
);

// Get Narcotic Inventories
export const getNarcoticInventories = createActionThunk(
  AT.GET_NARCOTIC_INVENTORIES,
  (query) => API.getNarcoticInventories(query)
);

// Add New Inventory
export const addNewInventory = createActionThunk(
  AT.ADD_NEW_INVENTORY,
  (params) => API.addNewInventory(params)
);

// Update Inventory Item
export const updateInventoryItem = createActionThunk(
  AT.UPDATE_INVENTORY_ITEM,
  (id, params) => API.updateInventoryItem(id, params)
);

// Get Inventory Cost History
export const getInventoryCostHistory = createActionThunk(
  AT.GET_INVENTORY_COST_HISTORY,
  (id, type) => API.getInventoryCostHistory(id, type)
);

// Add Inventory Cost History
export const addInventoryCostHistory = createActionThunk(
  AT.ADD_INVENTORY_COST_HISTORY,
  (id, params) => API.addInventoryCostHistory(id, params)
);

// Add Inventory to Purchase List
export const addToPurchaseList = createActionThunk(
  AT.ADD_TO_PURCHASE_LIST,
  (id, params) => API.addToPurchaseList(id, params)
);

// Update Angiographic Supply
export const updateAngioSupply = createActionThunk(
  AT.UPDATE_ANGIOGRAPHIC_SUPPLY,
  (id, params) => API.updateAngiographicSupply(id, params)
);

// Update Narcotic Inventory
export const updateNarcoticInventory = createActionThunk(
  AT.UPDATE_NARCOTIC_INVENTORY,
  (id, params) => API.updateNarcoticInventory(id, params)
);

// Get Purchase List
export const getPurchaseList = createActionThunk(
  AT.GET_PURCHASE_LIST,
  () => API.getPurchaseList()
);

// Order purchase item
export const orderPurchaseItem = createActionThunk(
  AT.ORDER_PURCHASE_ITEM,
  (id, params) => API.orderPurchaseItem(id, params)
);

// Get Receiving Orders
export const getReceivingOrders = createActionThunk(
  AT.GET_RECEIVING_ORDERS,
  () => API.getReceivingOrders()
);

// Update Receiving order
export const updateReceivingItem = createActionThunk(
  AT.UPDATE_RECEIVING_ORDER,
  (id, params) => API.updateReceivingOrder(id, params)
);

// Item Received Receiving order
export const receivedReceivingItem = createActionThunk(
  AT.INVENTORY_ORDER_ITEM_RECEIVED,
  (id, params) => API.itemReceivedOrder(id, params)
);

// Credit Received Receiving order
export const creditReceivingItem = createActionThunk(
  AT.INVENTORY_ORDER_CREDIT_RECEIVED,
  (id, params) => API.creditReceivedOrder(id, params)
);

// Shipped Receiving order
export const shippedReceivingItem = createActionThunk(
  AT.INVENTORY_ORDER_SHIPPED,
  (id, params) => API.shippedReceivingOrder(id, params)
);

// Get Return Credit orders
export const getReturnCreditOrders = createActionThunk(
  AT.GET_RETURN_CREDIT_ORDERS,
  () => API.getReturnCreditOrders()
);

// Delete Inventory Item
export const deleteInventoryItem = createActionThunk(
  AT.DELETE_INVENTORY_ITEM,
  (id, params) => API.deleteInventoryItem(id, params)
);

// Update Inventory Notes
export const updateInventoryNotes = createActionThunk(
  AT.UPDATE_INVENTORY_NOTES,
  (id, params) => API.updateInventoryNotes(id, params)
);

// Delete Purchase Item
export const deletePurchaseItem = createActionThunk(
  AT.DELETE_PURCHASE_ITEM,
  (id) => API.deletePurchase(id)
);

// Get Inventory Users
export const getInventoryUsers = createActionThunk(
  AT.GET_INVENTORY_USERS,
  () => API.getInventoryUsers()
);

// Get All Receiving Orders
export const getAllReceivingOrders = createActionThunk(
  AT.GET_ALL_ORDERS,
  () => API.getAllReceivingOrders()
);

// Delete Receiving Order
export const deleteReceivingOrder = createActionThunk(
  AT.DELETE_RECEIVING_ORDER,
  (id) => API.deleteReceivingOrder(id)
);
