import React from 'react';

export const IncomingFaxSVG = () => (
  <svg viewBox="0 0 650.3 627.04" width={14} height={14}>
    <defs>
      <style>{`.a-incoming{fill:#00b4a0;}`}</style>
    </defs>
    <title>EMR Icons v4</title>
    <path className="a-incoming"
          d="M540.3,215.82H516.67V.08h-40V215.82h-303V.08h-40V215.82H110a110.12,110.12,0,0,0-110,110V511.75a30,30,0,0,0,30,30H133.63V597a30,30,0,0,0,30,30h323a30,30,0,0,0,30-30V541.75H620.3a30,30,0,0,0,30-30V325.82A110.12,110.12,0,0,0,540.3,215.82ZM476.67,587h-303V439.5h303ZM610.3,501.75H516.67V439.5h34.18v-40H100.13v40h33.5v62.25H40V325.82a70.08,70.08,0,0,1,70-70H540.3a70.08,70.08,0,0,1,70,70Z"/>
    <polygon className="a-incoming"
             points="415.46 86.11 387.18 57.82 345.15 99.85 345.15 0.08 305.15 0.08 305.15 99.85 263.12 57.82 234.83 86.11 325.15 176.42 415.46 86.11"/>
  </svg>
);

export const OutgoingFaxSVG = () => (
  <svg viewBox="0 0 644.04 627.04" width={14} height={14}>
    <defs>
      <style>{`.a-outgoing{fill:#00b4a0;}`}</style>
    </defs>
    <title>EMR Icons v4</title>
    <path className="a-outgoing"
          d="M534,219.7h-20.5V.17h-40V215.9h-303V.17h-40V219.7H110A110.13,110.13,0,0,0,0,329.7v183a30,30,0,0,0,30,30H132.26V597a30,30,0,0,0,30,30H481.78a30,30,0,0,0,30-30V542.71H614a30,30,0,0,0,30-30v-183A110.12,110.12,0,0,0,534,219.7ZM471.78,587H172.26V441.5H471.78ZM604,502.71H511.78V441.5h33.63v-40H99.31v40h32.95v61.21H40v-173a70.08,70.08,0,0,1,70-70H534a70.08,70.08,0,0,1,70,70Z"/>
    <polygon className="a-outgoing"
             points="302.02 76.57 302.02 176.34 342.02 176.34 342.02 76.57 384.05 118.6 412.34 90.31 322.02 0 231.71 90.31 259.99 118.6 302.02 76.57"/>
  </svg>
);

export const LockIconSVG = () => (
  <svg viewBox="0 0 421.96 591.72" width={14} height={14}>
    <defs>
      <style>{`.a-lock-icon{fill:#5aaafa;}`}</style>
    </defs>
    <title>EMR Icons v4</title>
    <path className="a-lock-icon"
          d="M392,254.64H339.44V128.46a128.46,128.46,0,0,0-256.92,0V254.64H30a30,30,0,0,0-30,30V561.72a30,30,0,0,0,30,30H392a30,30,0,0,0,30-30V284.64A30,30,0,0,0,392,254.64ZM122.52,128.46a88.46,88.46,0,0,1,176.92,0V254.64H122.52ZM382,551.72H40V294.64H382Z"/>
  </svg>
);

export const FemaleSVG = ({style}) => (
  <svg viewBox="0 0 494.78 635.69" width={13} style={style} height={14}>
    <defs>
      <style>{`.a-female{fill:#ff71d4;}`}</style>
    </defs>
    <title>EMR Icons v4</title>
    <path className="a-female"
          d="M422.32,399.55A247.39,247.39,0,0,0,0,574.48v31.21a30,30,0,0,0,30,30H464.78a30,30,0,0,0,30-30V574.48A245.74,245.74,0,0,0,422.32,399.55Zm32.46,196.14H40V574.48c0-114.36,93-207.39,207.39-207.39s207.39,93,207.39,207.39Z"/>
    <path className="a-female"
          d="M247.39,286.49c79,0,143.24-64.26,143.24-143.25S326.38,0,247.39,0,104.15,64.26,104.15,143.24,168.41,286.49,247.39,286.49Zm99.79-169.76a295.6,295.6,0,0,1-77.57-27.94,298.82,298.82,0,0,1-59.93-41.66,103.26,103.26,0,0,1,137.5,69.6ZM175.09,69.62A339,339,0,0,0,251,124.18a336.21,336.21,0,0,0,98.63,33.76A103.24,103.24,0,1,1,175.09,69.62Z"/>
  </svg>
);

export const MaleSVG = ({style}) => (
  <svg viewBox="0 0 494.78 635.69" width={13} style={style} height={14}>
    <defs>
      <style>{`.a-male{fill:#7cc7ff;}`}</style>
    </defs>
    <title>EMR Icons v4</title>
    <path className="a-male"
          d="M422.32,399.55A247.39,247.39,0,0,0,0,574.48v31.21a30,30,0,0,0,30,30H464.78a30,30,0,0,0,30-30V574.48A245.74,245.74,0,0,0,422.32,399.55Zm32.46,196.14H40V574.48c0-114.36,93-207.39,207.39-207.39s207.39,93,207.39,207.39Z"/>
    <path className="a-male"
          d="M247.39,286.49c79,0,143.24-64.26,143.24-143.25S326.38,0,247.39,0,104.15,64.26,104.15,143.24,168.41,286.49,247.39,286.49Zm0-246.49a103.24,103.24,0,0,1,87.1,47.87H160.29A103.25,103.25,0,0,1,247.39,40ZM145.3,127.87H349.49a103.25,103.25,0,1,1-205.34,15.37A102.61,102.61,0,0,1,145.3,127.87Z"/>
  </svg>
);

export const LampSVG = () => (
  <svg data-name="Layer 1" viewBox="0 0 64 63.8">
    <defs>
      <style>{`.cls-1{fill:#9950D7;}`}</style>
    </defs>
    <title>STUDIO-pictogram</title>
    <path className="cls-1"
          d="M19.12,24.8l15.22,9a1.76,1.76,0,0,0,1,.2H35.9a1.79,1.79,0,0,0,1.17-.78s7.22-12.49,7.22-12.68A10.51,10.51,0,0,0,40,6.27,10.64,10.64,0,0,0,31.61,5.1a10.36,10.36,0,0,0-6,4.68L18.34,22.46h0A1.79,1.79,0,0,0,19.12,24.8ZM19.9,23l7.22-12.68a8.45,8.45,0,0,1,5.07-3.9,9.82,9.82,0,0,1,7,1c4.29,2.54,6,8,3.71,12.1,0,0.2-7.22,12.68-7.41,12.68h-0.2l-15.22-9C19.9,23.24,19.71,23,19.9,23Z"
          transform="translate(0 -0.22)"/>
    <path className="cls-1"
          d="M63.22,62.46H0.78A0.78,0.78,0,0,0,.78,64H63.22A0.84,0.84,0,0,0,64,63.24,0.71,0.71,0,0,0,63.22,62.46Z"
          transform="translate(0 -0.22)"/>
    <path className="cls-1"
          d="M10.15,57.78H25A0.84,0.84,0,0,0,25.76,57V42.37A0.84,0.84,0,0,0,25,41.59H23.61V35.15a0.69,0.69,0,0,0-.78-0.78H19.51a0.84,0.84,0,0,0-.78.78v6.44H16.59l0.2-7.41c0-.2,0-0.39-0.2-0.39l-1.76-3.12A1,1,0,0,0,14,30.27h0a1,1,0,0,0-.78.39l-1.76,2.73c0,0.2-.2.2-0.2,0.39v7.61H10.15a0.84,0.84,0,0,0-.78.78V56.8A0.89,0.89,0,0,0,10.15,57.78Zm10.15-22H22v5.66H20.29V35.73Zm-7.22-1.56,1-1.56,1,1.56-0.2,7.22H13.07V34.17Zm-2,8.78H24.2V56H11.12V43Z"
          transform="translate(0 -0.22)"/>
    <path className="cls-1"
          d="M46.83,8L58.15,29.49a3.13,3.13,0,0,0-1,2.15A4.09,4.09,0,0,0,58,33.78L45.27,56.22H36.49a0.78,0.78,0,1,0,0,1.56H57a0.78,0.78,0,0,0,0-1.56H47.22L59.51,34.56h0.78a2.93,2.93,0,1,0,0-5.85H59.71L48.2,7.24A0.94,0.94,0,0,0,47,6.85,0.74,0.74,0,0,0,46.83,8ZM60.29,30.27a1.37,1.37,0,1,1-1.37,1.37A1.33,1.33,0,0,1,60.29,30.27Z"
          transform="translate(0 -0.22)"/>
    <path className="cls-1"
          d="M43.71,6.07a3,3,0,0,0,2.93-2.93,2.93,2.93,0,1,0-5.85,0A3,3,0,0,0,43.71,6.07Zm0-4.29a1.33,1.33,0,0,1,1.37,1.37,1.37,1.37,0,0,1-2.73,0A1.47,1.47,0,0,1,43.71,1.78Z"
          transform="translate(0 -0.22)"/>
  </svg>
);

export const AcceptedSVG = () => (
  <svg viewBox="0 0 581.62 519.09" width={14}>
    <defs>
      <style>{`.a-accepted{fill:#41d6c3;}`}</style>
    </defs>
    <title>EMR Icons</title>
    <path
      className="a-accepted"
      d="M471.26,201.07a220.85,220.85,0,0,1,7.83,58.48A219.55,219.55,0,0,1,104.3,414.79a219.55,219.55,0,0,1,302-318.5L434.65,68A259.54,259.54,0,0,0,76,443.07,259.68,259.68,0,0,0,503,169.3Z"
    />
    <polygon
      className="a-accepted"
      points="553.33 5.86 509.31 49.88 462.03 97.16 433.56 125.64 259.55 299.64 141.74 181.83 113.45 210.12 259.55 356.21 455.48 160.28 485 130.76 537.59 78.17 581.62 34.14 553.33 5.86"
    />
  </svg>
);

export const BarcodeSVG = () => (
  <svg viewBox="0 0 520 696.34">
    <defs>
      <style>{`.a-barcode{fill:#7cc7ff;}`}</style>
    </defs>
    <title>EMR Icons v4</title>
    <rect className="a-barcode" x="80" y="576.34" width="40" height="40"/>
    <path className="a-barcode" d="M120,496.34H0v200H200v-200H120Zm40,80v80H40v-120H160Z"/>
    <rect className="a-barcode" x="80" y="256.34" width="40" height="40"/>
    <path className="a-barcode" d="M80,376.34H200v-200H0v200H80Zm-40-80v-80H160v120H40Z"/>
    <polygon className="a-barcode"
             points="322.03 118.6 350.31 90.31 260 0 169.69 90.31 197.97 118.6 240 76.57 240 176.34 240 176.34 240 216.34 240 256.34 240 296.34 240 336.34 240 376.34 240 416.34 200 416.34 160 416.34 120 416.34 80 416.34 40 416.34 0 416.34 0 456.34 40 456.34 80 456.34 120 456.34 160 456.34 200 456.34 240 456.34 280 456.34 280 416.34 280 376.34 280 336.34 280 296.34 280 256.34 280 216.34 280 176.34 280 176.34 280 76.57 322.03 118.6"/>
    <polygon className="a-barcode"
             points="360 576.34 360 536.34 360 496.34 320 496.34 280 496.34 240 496.34 240 536.34 280 536.34 320 536.34 320 576.34 280 576.34 240 576.34 240 616.34 240 656.34 240 696.34 280 696.34 280 656.34 280 616.34 320 616.34 360 616.34 360 576.34"/>
    <polygon className="a-barcode"
             points="440 576.34 400 576.34 400 616.34 440 616.34 480 616.34 480 656.34 440 656.34 400 656.34 360 656.34 360 696.34 400 696.34 440 696.34 480 696.34 520 696.34 520 656.34 520 616.34 520 576.34 480 576.34 440 576.34"/>
    <rect className="a-barcode" x="400" y="256.34" width="40" height="40"/>
    <path className="a-barcode" d="M480,176.34H320v200H520v-200Zm0,80v80H360v-120H480Z"/>
    <polygon className="a-barcode"
             points="480 456.34 480 496.34 440 496.34 440 456.34 440 416.34 400 416.34 360 416.34 320 416.34 320 456.34 360 456.34 400 456.34 400 496.34 400 536.34 440 536.34 480 536.34 520 536.34 520 496.34 520 456.34 520 416.34 480 416.34 480 456.34"/>
  </svg>
);
