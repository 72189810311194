import React, {useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import ScrollWrapper from "../../../../../common/components/ScrollBar";
import EditableFormInput from "../../../../../common/components/EditableText/EditableFormInput";
import { adminSettingSaveOrgDetail } from "../../../../../store/actions";

function OrganizationDetails({resource}) {
  const dispatch = useDispatch();
  const [organizationName, setOrganizationName] = useState("")
  const [address1, setAddress1] = useState("")
  // const [address2, setAddress2] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [fax, setFax] = useState("")
  const [phone, setPhone] = useState("")

  useEffect(() => {
    if (!resource.loading) {
      setOrganizationName(resource.org_name);
      setAddress1(resource.org_address1);
      // setAddress2(resource.org_address2);
      setState(resource.org_state);
      setCity(resource.org_city);
      setZipCode(resource.org_zipcode);
      setFax(resource.org_fax_number);
      setPhone(resource.org_phone_number);
    }
  }, [resource]);

  const handlInputChange = ((key, val) => {
    const payload = {
      type: "org",
      key: key,
      value: val
    };
    dispatch(adminSettingSaveOrgDetail(payload));
  });

  return (
    <div
      className="orgianization__details"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <ScrollWrapper css="no-padding x-hidden">
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="Name of Organization"
          value={organizationName}
          onCustomBlur={()=>handlInputChange('org_name', organizationName)}
          handleChange={(val) => setOrganizationName(val)}
        />
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="Address"
          value={address1}
          onCustomBlur={()=>handlInputChange('org_address1', address1)}
          handleChange={(val) => setAddress1(val)}
        />
        {/* <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="Address"
          value={address2}
          onCustomBlur={()=>handlInputChange('org_address2', address2)}
          handleChange={(val) => setAddress2(val)}
        /> */}
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="State"
          value={state}
          onCustomBlur={()=>handlInputChange('org_state', state)}
          handleChange={(val) => setState(val)}
        />
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="City"
          value={city}
          onCustomBlur={()=>handlInputChange('org_city', city)}
          handleChange={(val) => setCity(val)}
        />
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="Zip code"
          value={zipCode}
          onCustomBlur={()=>handlInputChange('org_zipcode', zipCode)}
          handleChange={(val) => setZipCode(val)}
        />
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="Phone Number"
          value={phone}
          mask={'phone'}
          onCustomBlur={()=>handlInputChange('org_phone_number', phone)}
          handleChange={(val) => setPhone(val)}
        />
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="Fax Number"
          value={fax}
          mask={'phone'}
          onCustomBlur={()=>handlInputChange('org_fax_number', fax)}
          handleChange={(val) => setFax(val)}
        />
      </ScrollWrapper>
    </div>
  )
}

export default OrganizationDetails;
