import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  CloseIcon,
  SummaryEmptySVG,
} from "../../../common/components/icons/SVGIcon";
import FormStepper from "../../../common/components/FormStepper";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Toastr from "../../../common/components/Toast";
import FirstStepContent from "./FirstStep";
import SecondStepContent from "./SecondStep";
import ThirdStepContent from "./ThirdStep";
import ForthStepContent from "./ForthStep";
import FinalStepContent from "./FinalStep";
import MedicalHistory from "./MedicalHistory";
import AddNewPatient from "./AddNewPatient";
import CallAttempt from "./CallAttempt";
import Notes from "../../../common/components/Notes";
import Finalize from "../../../common/components/Finalize";

import { getProviderSchedule } from "../../../services/api.services";
import {
  getAppointments,
  filterAppointments,
  setStepData,
  actionAddAndSelect,
  update_patient_ops,
  saveCheckQuestion,
  patient_insurances_id_insurances,
  set_communication_compelete,
  getCallback,
  selectPatient,
  get_patient_notes,
} from "../../../store/actions";
import { putRequest } from "../../../services/api.services";
import { validateSteps } from "../../../services/validations";
import { newPatientOptions } from "../../../common/constants/initialData";
import { MaleSVG } from "../../../common/components/icons/FaxSVG";
import SidebarTitle from "../../../common/components/SidebarTitle";
import Cookies from "universal-cookie";
import { Icon } from "antd";

const cookies = new Cookies();
const initialState = {
  previous_medical_history: [],
  previous_surgical_history: [],
  medications: [],
  allergies: [],
  flu_vaccination: -1,
  pneumococcal: -1,
  aortic_disease: -1,
  amputation: -1,
  heart_disease: -1,
  smoking_ppd: 0,
  previous_smoking_ppd: 0,
  alcoholic_drink_pw: 0,
};

const initialData = {
  demographics: {},
  insurance: {},
  medical: {},
  questions: {},
  schedule: {},
  communication: {},
  attempt: {},
};

const initialCondition = [
  { step: "demographics", status: true },
  { step: "insurance", status: true },
  { step: "medical", status: true },
  { step: "questions", status: true },
  { step: "schedule", status: true },
  { step: "communication", status: true },
  { step: "attempt", status: true },
  { step: "notes", status: true },
  { step: "finalize", status: false },
];

const formSteps = [
  {
    title: "Demographics",
    isConfirmed: true,
    content: FirstStepContent,
  },
  {
    title: "Insurance Details",
    isConfirmed: true,
    content: SecondStepContent,
  },
  {
    title: "Medical History",
    isConfirmed: false,
    content: MedicalHistory,
  },
  {
    title: "Questionnaire",
    isConfirmed: true,
    content: ThirdStepContent,
  },
  {
    title: "Appointment Schedule",
    isConfirmed: true,
    content: ForthStepContent,
  },
  {
    title: "Communication",
    isConfirmed: true,
    content: FinalStepContent,
  },
  {
    title: "Call Attempts",
    isConfirmed: true,
    content: CallAttempt,
  },
  {
    title: "Notes",
    isConfirmed: true,
    content: Notes,
  },
  {
    title: "Finalize",
    isConfirmed: true,
    content: Finalize,
  },
];

const NewPatientSideBar = React.forwardRef(
  ({ isShow, handleCloseSidebar, selected, stepProps }, ref) => {
    const resource = useSelector((state) => state.newPatient.resource);
    const dropdownOption = useSelector(
      (state) => state.newPatient.dropdownOption
    );
    const scheduleOptions = useSelector(
      (state) => state.newPatient.scheduleOptions
    );
    const [hideScope, setHideScope] = useState(false);
    const scheduleLoading = useSelector(
      (state) => state.newPatient.scheduleLoading
    );
    const locations = useSelector((state) => state.common.locations);
    const providers = useSelector((state) => state.common.only_providers);
    const filteredLocations = useSelector(
      (state) => state.common.filteredLocations
    );
    const patient_id = cookies.get("current_patient_id");
    const compelete_step_communication = useSelector(
      (state) => state.common.compelete_step_communication
    );
    const checkApointment = useSelector(
      (state) => state.common.checkApointment
    );

    const filteredProviders = useSelector(
      (state) => state.common.filteredProviders
    );
    const referralConditions = useSelector(
      (state) => state.common.referralConditions
    );
    const referredProviders = useSelector(
      (state) => state.common.referredProviders
    );

    const save_check_question = useSelector(
      (state) => state.newPatient.save_check_question
    );
    const pcpList = useSelector((state) => state.common.pcpList);
    const stepData = useSelector((state) => ({
      demographics: state.newPatient.demographics,
      insurance: state.newPatient.insurance,
      medical: {
        ...state.newPatient.medical,
        previous_medical_history: resource?.icd_codes || [],
      },
      questions: state.newPatient.questions,
      schedule: state.newPatient.schedule,
      communication: state.newPatient.communication,
      attempt: state.newPatient.attempt,
    }));
    const checkschedule = useSelector((state) => state.newPatient.schedules);
    const isAdded = useSelector((state) => state.patientPending.isAdded);
    const isVerified = useSelector((state) => state.newPatient.isVerified);
    const patientsNote = useSelector((state) => state.newPatient.patientsNote);
    const attempt = useSelector((state) => state.newPatient.attempt);
    const [hideTitle, setHideTitle] = useState(false);
    const hasInsurance = useSelector((state) => state.newPatient.hasInsurance);
    const enterInsuranceCheck = useSelector(
      (state) => state.newPatient.enterInsuranceCheck
    );
    const action_add_and_select = useSelector(
      (state) => state.common.action_add_and_select
    );
    const questions = useSelector((state) => state.newPatient.questions);
    const patient_all_insurances_id = useSelector(
      (state) => state.newPatient.patient_all_insurances_id
    );
    const dispatch = useDispatch();

    const [step, setStep] = useState(0);
    const [title, setTitle] = useState("");
    const [valueCheckAddForm, setValueCheckAddForm] = useState(false);
    const [conditions, setConditions] = useState(initialCondition);
    const [timer, setTimer] = useState(0);
    const [isAddForm, setAddForm] = useState(false);
    const [noteCancelValue, setNoteCancelValue] = useState("");
    const [showNote, setShowNote] = useState(false);
    const [check, setCheck] = useState(false);
    const [width, setWidth] = useState(300);
    const [startingWidth, setStartingWidth] = useState(300);
    const [xPos, setXPos] = useState(0);
    const startResize = (e) => {
      setXPos(e.clientX);
      setStartingWidth(width);
      document.documentElement.addEventListener("mousemove", handleResize);
      document.documentElement.addEventListener("mouseup", endResize);
      document.documentElement.classList.add("noSelect");
    };
    const endResize = (e) => {
      document.documentElement.removeEventListener("mousemove", handleResize);
      document.documentElement.removeEventListener("mouseup", endResize);
      document.documentElement.classList.remove("noSelect");
    };
    const handleResize = (e) => {
      setWidth(startingWidth - e.clientX + xPos);
    };

    useEffect(() => {
      if (stepProps == -1) {
        setStep(0);
      } else {
        setStep(stepProps);
      }
    }, [stepProps]);

    const isToastr = useMemo(() => {
      if (isAdded) {
        setTimer(0);
        setTimeout(() => {
          setTimer(600);
        }, 2000);
        return true;
      }
      return false;
    }, [isAdded]);

    useEffect(() => {
      if (resource) {
        let a = moment();
        let b = resource.date_of_birth
          ? moment(resource.date_of_birth)
          : undefined;
        let ops_patient_id = resource.id ? resource.id : "";
        const fullName = `${resource.first_name || ""} ${
          resource.last_name || ""
        } `;
        const newTitle =
          fullName.toUpperCase() +
          `(` +
          a.diff(b, "years") +
          ` yo MALE)` +
          ` (OPS ID: ${ops_patient_id})`;
        setTitle(newTitle);

        if (a.diff(b, "years") >= 62) {
          setHideScope(false);
        } else {
          if (
            Number(moment().format("MM")) < 10 &&
            Number(moment().format("MM")) > 2
          ) {
            setHideScope(true);
          } else {
            setHideScope(false);
          }
        }
        setShowNote(false);
        setNoteCancelValue("");
        dispatch(get_patient_notes(resource?.id));
      }
    }, [resource]);

    useEffect(() => {
      if (resource) {
        const newConditions = [...conditions];
        for (let i = 0; i < 7; i++) {
          const validate = validateSteps(
            stepData[conditions[i].step],
            newPatientOptions[conditions[i].step]
          );
          newConditions[i].status = validate;
        }
        if (valueCheckAddForm) {
          newConditions[0].status = false;
        }
        if (stepData["medical"]) {
          if (
            stepData["medical"]["alcoholic_drink_pw"] != "" &&
            ((stepData["medical"]["allergies"] &&
              stepData["medical"]["allergies"].length > 0) ||
              stepData["medical"]["no_drug_allergies"] == true) &&
            stepData["medical"]["amputation"] != -1 &&
            (hideScope
              ? stepData["medical"]["pneumococcal"] != null
              : stepData["medical"]["pneumococcal"] != -1) &&
            stepData["medical"]["aortic_disease"] != -1 &&
            stepData["medical"]["heart_disease"] != -1 &&
            ((stepData["medical"]["medications"] &&
              stepData["medical"]["medications"].length > 0) ||
              stepData["medical"]["no_medication"] == true) &&
            stepData["medical"]["previous_medical_history"] &&
            stepData["medical"]["previous_medical_history"].length > 0 &&
            stepData["medical"]["previous_smoking_ppd"] != "" &&
            stepData["medical"]["previous_surgical_history"] &&
            stepData["medical"]["previous_surgical_history"].length > 0 &&
            stepData["medical"]["smoking_ppd"] != "" &&
            stepData["medical"]["years_of_smoking"] != ""
          ) {
            newConditions[2].status = true;
          } else {
            newConditions[2].status = false;
          }
        }
        console.log(patient_all_insurances_id, "patient_all_insurances_id1");

        if (
          patient_all_insurances_id &&
          patient_all_insurances_id.some(
            (variable) =>
              variable.is_verified &&
              variable.is_verified === 1 &&
              variable.is_eligible &&
              variable.is_eligible === 1
          )
        ) {
          newConditions[1].status = true;
        } else {
          newConditions[1].status = false;
        }
        if (enterInsuranceCheck) {
          newConditions[1].status = true;
        }

        if (checkApointment) {
          newConditions[4].status = true;
        } else {
          if (
            resource?.is_completed_scheduling &&
            resource?.is_completed_scheduling == 1
          ) {
            newConditions[4].status = true;
          } else {
            newConditions[4].status = false;
          }
        }
        if (check) {
          newConditions[5].status = true;
        } else {
          newConditions[5].status = false;
        }
        if (
          attempt &&
          attempt?.call_attempts &&
          attempt?.call_attempts.length > 0
        ) {
          newConditions[6].status = true;
        } else {
          newConditions[6].status = false;
        }
        if (patientsNote && patientsNote.length > 0) {
          newConditions[7].status = true;
        } else {
          newConditions[7].status = false;
        }
        dispatch(saveCheckQuestion(newConditions[3].status));
        setConditions(newConditions);
      }
    }, [
      resource,
      step,
      hideScope,
      enterInsuranceCheck,
      patient_all_insurances_id,
      checkApointment,
      valueCheckAddForm,
      attempt,
      patientsNote,
      check,
    ]);

    const handleSubmit = () => {
      if (step < 8) {
        console.log("handle submit", step);
        const validate = conditions[step];
        setStep(step + 1);
        // dispatch(setStepData('step', step + 1));
      } else {
        // Todo: handleSubmit
        console.log("save");
      }
    };

    const onGetPending = async () => {
      // await dispatch(getPending({}));
      await dispatch(set_communication_compelete(false));
      // await dispatch(getCallback({}));
    };

    const handlePrev = () => {
      setStep(step - 1);
    };

    // scheduling
    const handleScheduleOption = (idxOfSchedule, newOption) => {
      const newScheduleOption = [...scheduleOptions];
      newScheduleOption[idxOfSchedule] = newOption;
      dispatch(setStepData("scheduleOptions", newScheduleOption));
    };

    const handleProviderSchedule = async (
      location_id,
      provider_id,
      idxOfSchedule
    ) => {
      if (location_id && provider_id) {
        return;
      }
      const newOption = [...dropdownOption];
      if (!location_id && !provider_id) {
        newOption[idxOfSchedule] = { type: "", list: [] };
        dispatch(setStepData("dropdownOption", newOption));
        return;
      }
      try {
        const result = await getProviderSchedule(
          resource.id,
          location_id,
          provider_id
        );
        let type = "";
        if (!location_id) {
          type = "location";
        } else if (!provider_id) {
          type = "provider";
        }
        newOption[idxOfSchedule] = { type, list: result.data };
        dispatch(setStepData("dropdownOption", newOption));
      } catch (error) {
        dispatch(setStepData("dropdownOption", dropdownOption));
      }
    };

    // test initial scheduling
    const initialSchedule = async () => {
      dispatch(setStepData("initialLoading", true));
    };

    const handleSchedule = (
      location_id,
      provider_id,
      period,
      prev,
      idxOfSchedule,
      action = "",
      custom,
      load_next
    ) => {
      const loadingOfSchedules = [...scheduleLoading];
      if (action === "row" && idxOfSchedule >= loadingOfSchedules.length - 1) {
        return;
      }
      if (action === "row") {
        loadingOfSchedules[idxOfSchedule + 1] = true;
      } else {
        loadingOfSchedules[idxOfSchedule] = true;
      }
      dispatch(setStepData("scheduleLoading", loadingOfSchedules));
      dispatch(
        filterAppointments(
          resource.id,
          location_id,
          provider_id,
          period,
          prev,
          idxOfSchedule,
          action,
          custom,
          load_next
        )
      );
    };

    const checkIsInsurances = async (value) => {
      if (value) {
        await dispatch(patient_insurances_id_insurances(value));
      }
    };

    const setStateAddForm = (value) => {
      setValueCheckAddForm(value);
    };

    const handleCloseStep = async (step) => {
      if (step == 3) {
        await putRequest(resource?.id, questions, "questionnaire");
        await dispatch(getAppointments(resource.id));
      }
      if (step == 0) {
        dispatch(update_patient_ops(resource?.id));
      }
    };

    useEffect(() => {
      if (action_add_and_select) {
        setTimeout(() => {
          dispatch(actionAddAndSelect());
        }, 100);
      }
    }, [action_add_and_select]);

    const actionShowNote = (value) => {
      setShowNote(value);
    };

    const setNoteCancelValueCheck = (value) => {
      setNoteCancelValue(value);
    };

    if (!isShow) {
      return null;
    }
    const checkIsAdding = (value) => {
      setHideTitle(value);
    };
    if (typeof resource === "object" && selected === -1) {
      return (
        <div
          data-testid="NewPatientAddSidebar"
          ref={ref}
          className={`resourceContainer ${isShow ? "show" : ""}`}
        >
          <ScrollWrapper css="x-hidden">
            <div className="headerContainer">
              <button onClick={handleCloseSidebar}>
                <CloseIcon />
              </button>
            </div>
            {!isAddForm && (
              <div
                className="emptyContentIcon"
                style={{
                  width: "100%",
                  height: "calc(100% - 160px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SummaryEmptySVG />
              </div>
            )}
            {!isAddForm && (
              <div
                className="actionContent"
                style={{ marginTop: "2rem", paddingRight: "1rem" }}
              >
                <button
                  data-testid="AddNewPatientButton"
                  onClick={() => setAddForm(true)}
                >
                  Add New Patient
                </button>
              </div>
            )}
            {isAddForm && (
              <SidebarTitle
                icon={
                  <Icon type="mail" theme="twoTone" twoToneColor="#0f62fe" />
                }
                title={"ADD NEW PATIENT"}
                subTitle=""
              />
            )}
            {isAddForm && (
              <AddNewPatient
                checkIsInsurances={(value) => {
                  checkIsInsurances(value);
                }}
                handleClose={() => setAddForm(false)}
                referralConditions={referralConditions}
                pcpList={
                  pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList
                }
                providers={providers}
              />
            )}
          </ScrollWrapper>
        </div>
      );
    }

    const checkAction = (value) => {
      setCheck(value);
    };

    return (
      <div
        data-testid="NewPatientSidebar"
        ref={ref}
        className={`resourceContainer ${isShow ? "show" : ""}`}
      >
        {isToastr && timer < 600 && (
          <div
            style={{ position: "fixed", top: "60px", right: "2px", zIndex: 10 }}
          >
            <Toastr
              type="New Patient"
              status="success"
              msg="Added New Patient Successfully!"
            />
          </div>
        )}
        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>

          <SidebarTitle
            icon={<Icon type="mail" theme="twoTone" twoToneColor="#0f62fe" />}
            title={isAddForm ? "ADD NEW PATIENT" : "PATIENT DASHBOARD"}
            subTitle=""
          />

          <FormStepper
            compelete_step_communication={compelete_step_communication}
            checkIsAdding={(value) => {
              checkIsAdding(value);
            }}
            showNote={showNote}
            setNoteCancelValue={setNoteCancelValueCheck}
            noteCancelValue={noteCancelValue}
            actionShowNote={actionShowNote}
            checkIsInsurances={(value) => {
              checkIsInsurances(value);
            }}
            type="newPatient"
            onGetPending={onGetPending}
            setStateAddForm={setStateAddForm}
            steps={formSteps}
            currentStep={step}
            handleStep={setStep}
            onCloseStep={handleCloseStep}
            handleContinue={handleSubmit}
            locations={locations}
            providers={providers}
            filteredLocations={filteredLocations}
            filteredProviders={filteredProviders}
            conditions={conditions}
            referredProviders={referredProviders}
            referralConditions={referralConditions}
            pcpList={
              pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList
            }
            handleAppointmentSchedule={handleSchedule}
            initialAppointmentSchedule={initialSchedule}
            handleProviderSchedule={handleProviderSchedule}
            handleScheduleOption={handleScheduleOption}
          />

          <div style={{ width: "100%", paddingBottom: "2rem" }} />
        </ScrollWrapper>
      </div>
    );
  }
);

export default NewPatientSideBar;
