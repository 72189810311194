import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Tooltip } from 'antd';
import { YellowAlertSVG, LeftCharvenSVG, GearIconSVG } from '../../common/components/icons/SVGIcon';
import SubTopBar from '../../common/components/SubTopBar';
import ScrollWrapper from '../../common/components/ScrollBar';
import HospitalSideBar from './components/HospitalSidebar';
import CallbackFilter from './components/CallbackFilter';
import PendingFilter from './components/PendingFilter';
import CallbackTable from './components/CallBackTable';
import PendingTable from './components/PendingTable';
import ProcedureCalendar from './ProcedureCalendar';
import SnapshotSideBar from "../../common/views/PatientSnapshot/SnapSidebar";
import {
  selectPatient,
  setPatientSnapshotData,
  changeTab,
  setGlobalLoading
} from "../../store/actions";
import './style.scss';
import SidebarExtension from '../../common/components/SidebarExtension';
import { FemaleSVG, MaleSVG } from '../../common/components/icons/FaxSVG';
import CardBlock from '../../common/components/Card';

class HospitalProcedure extends Component {
  state = {
    isSidebar: false,
    step: 0,
    selectedType: 'pending',
    search: {
      callback: '',
      pending: ''
    },
    active: false,
    activeID: null,
    show: false,
    settup: -1,
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchSelect = async (type) => {
    this.setState({ selectedType: type });
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true, active: false, activeID: null, show: true });
  }

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  }

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  }

  handleSearch = type => value => {
    this.setState({ search: { ...this.state.search, [type]: value } });
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Hospital Procedure</title>
        <meta name='title' content='FAACT Vascular | Hospital Procedure' />
      </Helmet>
    )
  }

  getStatus = (status, status1) => {
    return (
      <div className='statusHealth'>
        <Icon type="notification" theme="twoTone" twoToneColor="#0f62fe"/>
        {status}
        <span style={{ minWidth: '10px', display: 'inline-block' }} />
        <Icon type="phone" theme="twoTone" twoToneColor="#F1C21B"/>
        {status1}
      </div>
    );
  }

  onHandleClick = async (event) => {
    // check
    this.setState({
      settup: 0,
    }, () => {
      setTimeout(() => {
        this.setState({
          settup: -1,
        })
      }, 0);
    })
    this.props.setGlobalLoading(true);
    await this.props.changeTab({
      tabActive: null,
      accordion: null,
      step: 0,
    });
    const result = await this.props.selectPatient(event.patient_id);
    await this.props.setPatientSnapshotData("resource", { ...result });
    this.setState({
      isSidebar: true,
      active: true,
      activeID: event.id,
      show: true
    })
    setTimeout(() => {
      this.props.setGlobalLoading(false);
    }, 800);
  }

  handleResource = (step) => {
    this.setState({ step });
  }

  renderSideComponent = () => {
    const { resource } = this.props;
    if (!resource || !Object.keys(resource).length) {
      return null;
    }

    const fullName = resource.patient && resource.patient.name.toUpperCase();
    const age = resource.patient && resource.patient.date_of_birth
      ? "(" +
      moment().diff(resource.patient.date_of_birth, "years") +
      // " yo " +
      // (!!resource.patient.gender ? resource.patient.gender.toUpperCase() : "MALE") +
      ")"
      : "";

    const allerrgies = [];
    if (resource.patient.allergies) {
      resource.patient.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
            allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resource.patient.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resource.patient.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >

        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "26px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resource.no_drug_allergies ? 'NKDA' : 'Pending Data'}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && <br />}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={resource.patient.allergies.map(allergy => allergy.allergy_name).join(', ')}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>
      
        {resource.gender && resource.gender.toUpperCase() == 'MALE' ? 
          <MaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/> :
          <FemaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/>
        }
      </div>
    );
  }

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  } 

  render() {
    const { pending, callback, resource } = this.props;
    const {
      selectedType,
      isSidebar,
      search,
      active,
      show
    } = this.state;

    const pendingLengh = pending ? pending.length : 0;
    const callbackLength = callback ? callback.length : 0;
    const isResource = !resource || !Object.keys(resource).length;

    return (
      <div className='hospitalContent mainPage'>
        {this.renderMetadata()}
        <SubTopBar
          title="Procedure Processing"
          noLeftSide={true}
          subStatus={this.getStatus(pendingLengh, callbackLength)}
          sideComponent={this.renderSideComponent()}
          isBarcode={!isResource}
        />
        {
          active ?
            <SnapshotSideBar
              ref={this.sidebarRef}
              patientResource={this.props.patientResource}
              isShow={isSidebar}
              settup={this.state.settup}
              isScan={true}
              handleCloseSidebar={this.handleCloseSidebar}
            /> :
            <HospitalSideBar
              ref={this.sidebarRef}
              isShow={isSidebar}
              handleCloseSidebar={this.handleCloseSidebar}
              handleNext={this.handleResource}
              selectedType={selectedType}
            />
        }

        <SidebarExtension startingWidth='calc(28.3% - 20px)' sidebarReference={this.sidebarRef} sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>



        <div className='main multi-main'>
          <ScrollWrapper css="no-padding x-hidden">
            <div 
              className={`mainContainer ${isResource || !isSidebar ? 'no-sidebar' : ''}`}
              style={{
                marginLeft: "-10px",
                marginRight: "-10px",
                paddingTop: "0",
                paddingBottom: "0",
                display: "flex",
                width: '96%',
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <div className="mainTableContainer">
                <CardBlock
                  title="Procedure Pending"
                  varient="half"
                  other="dashboard"
                >
                  <PendingFilter
                    handleSearch={this.handleSearch('pending')}
                  />
                  <div className='tableSection'>
                    <PendingTable
                      title='result'
                      search={search.pending}
                      selectedType={selectedType}
                      handleSidebar={this.handleSidebar}
                      handleSelect={this.fetchSelect} />
                  </div>
                </CardBlock>
              
                <CardBlock
                  title="Procedure Call Back"
                  varient="half"
                  other="dashboard"
                >
                  <CallbackFilter
                    handleSearch={this.handleSearch('callback')}
                  />
                  <div className='tableSection'>
                    <CallbackTable
                      title='result'
                      search={search.callback}
                      selectedType={selectedType}
                      handleSidebar={this.handleSidebar}
                      handleSelect={this.fetchSelect}
                    />
                  </div>
                </CardBlock>
              </div>
            
              <ProcedureCalendar activeID={this.state.activeID} onHandleClick={this.onHandleClick} />
            </div>
          </ScrollWrapper>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  pending: state.procedure.pending,
  callback: state.procedure.callback,
  resource: state.procedure.resource,
  patientResource: state.clinicProvider.patientResource,
});

const mapDispatchToProps = {
  selectPatient,
  setPatientSnapshotData,
  changeTab,
  setGlobalLoading
};


export default connect(mapStateToProps, mapDispatchToProps)(HospitalProcedure);