import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "antd";
import moment from "moment";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ToastMessage from "../../../../common/components/Toast";
import HPISnapList from "./HPISnapList";
import _ from "lodash";

const OfficeProcedureSidebar = React.forwardRef(({
  isShow,
  sidebarIcon,
  handleCloseSidebar,
  conditions,
  clickedCondition,
}, ref) => {
  const [filteredDescList, setFilteredDescList] = useState([]);
  const descList = useSelector((state) => state.clinicProviderOffice.descList);
  useEffect(() => {
    let filteredDescList_ = [];
    for (let row of conditions) {
      let { condition } = row;
      let sublist = descList[condition];
      if (sublist) {
        filteredDescList_.push({ condition, sublist });
      } else {
        filteredDescList_.push({ condition, sublist: [] });
      }
    }
    setFilteredDescList(filteredDescList_);
  }, [conditions]);
  const [clickedConditionOnMain, setClickedConditionOnMain] = useState("");
  useEffect(() => {
    setClickedConditionOnMain(clickedCondition);
  }, [clickedCondition]);

  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );

  if (!isShow) {
    return null;
  }

  const fullName = (
    patientResource.first_name +
    " " +
    patientResource.last_name
  ).toUpperCase();
  const age = patientResource.date_of_birth
    ? "(" + moment().diff(patientResource.date_of_birth, "years") + " yo)"
    : "";

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
          title={<span className="text-uppercase">PROCEDURE Details</span>}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ paddingLeft: "12px", paddingRight: "8px" }}
        >
          <Collapse accordion activeKey={"collapse-" + clickedConditionOnMain}>
            {filteredDescList.map((data, index) => (
              <Collapse.Panel
                header={data.condition}
                key={`collapse-${_.snakeCase(data.condition)}`}
              >
                <HPISnapList list={data.sublist} />
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default OfficeProcedureSidebar;
