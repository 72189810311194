import React, { useState } from "react";
import { Icon } from "antd";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
// import FileUploads from "../../../../common/components/FileUpload/FileUploads";

const KnowledgeBaseSidebar = React.forwardRef(({
  resource,
  isShow,
  evaluationsTagertDetail,
  handleCloseSidebar,
  currentUserInfo,
}, ref) => {
  const [subject, setSubject] = useState('');
  const [summary, setSummary] = useState('');
  const [comment, setComment] = useState("");
  const [user, setUser] = useState('');
  const [resolved, setResolved] = useState(false);
  const [inProcess, setInProcess] = useState(false);

  if (!isShow) {
    return null;
  }

  return (
    <div className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper css="x-hidden">
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <div className="resourceInfo d-flex" style={{ padding: "0 8px" }}>
          <SidebarTitle
            icon={<MaleSVG />}
            title={(
              <span className="text-uppercase">
                ID
              </span>
            )}
            subTitle=""
          />
        </div>

        <div
          className="resourceContent"
          style={{ flex: "unset", paddingLeft: "8px", paddingRight: "0.5rem" }}
        >
          <div className="resourceInfo d-flex" style={{ marginBottom: "0px" }}>
            <EditableFormInput
              label="Subject"
              value={subject}
              handleChange={setSubject}
              fullWidth
            />
          </div>
          
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label="Programmer Comments"
              value={comment}
              type="area"
              minRows={12}
              handleChange={setComment}
              customStyle={{
                background: '#fff'
              }}
              fullWidth
            />
          </div>
          <div className="resourceInfo d-flex" style={{ marginTop: '0px', marginBottom: '0.5rem' }}>
          </div>

          {/* <div className="resourceInfo d-flex" style={{ marginTop: '0.5rem', marginBottom: '0.5rem', justifyContent: 'space-between' }}>
            <Icon type="camera" fontSize="20" style={{ cursor: 'pointer' }} />
            <button className="common-btn">Submit</button>
          </div> */}
        </div>

        <div
          className="actionContent"
          style={{ marginTop: "2rem", paddingRight: "1rem", paddingBottom: '2rem' }}
        >
          <button className="common-btn outlined-btn">New Support Case</button>
          <button className="common-btn" style={{ marginLeft: "0.5rem" }}>Save Record</button>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default KnowledgeBaseSidebar;
