import React from 'react';

export const CartoidDisease = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs>
      <style>{`.a-cartoid,.b-cartoid,.c-cartoid{fill:none;stroke-width:40px;}.a-cartoid,.b-cartoid{stroke:#ff71d4;}.a-cartoid,.c-cartoid{stroke - miterlimit:10;}.b-cartoid{stroke - linecap:round;stroke-linejoin:round;}.c-cartoid{stroke:#025cf7;}`}</style>
    </defs>
    <title>EMR Icons Expanded v3</title>
    <path className="a-cartoid"
          d="M222.69,97.38C217,177.51,213.78,290,244.09,417.31c42.78,179.61,104.55,295.41,106.1,421.44.42,33.77-.21,46.52-7.38,75.72"/>
    <path className="a-cartoid"
          d="M137.55,171.21c-1.39,69.89,3,154.14,24.9,246.1C205.22,596.92,267,712.72,268.55,838.75c.24,19.55.13,32-1.39,44.78"/>
    <path className="a-cartoid" d="M608,915.61C659.06,813.67,700.26,697,721.55,591c48.69-242.36-55-344.12-44.08-525"/>
    <path className="a-cartoid" d="M709,873.33C750.68,782.17,784,682.57,802.35,591,846.58,370.85,765.11,266.71,758,113.93"/>
    <path className="a-cartoid"
          d="M225,306.68c5.82,46.89,70.59,28.75,96,64.38,23.84,33.42,9.73,61.58,22,91.35C355.59,493,379.77,496.19,389,527.25c8.9,30.14-7.63,50.88-.19,88.09,5.77,28.83,31.42,44.84,26.8,83.35-4.42,36.76-71.54,72.89-65.51,133.39"/>
    <path className="a-cartoid"
          d="M713.93,624.69c5.39-65.35-68.45-54.25-84.7-102.37-10.64-31.49,18.39-58.06,18.39-96,0-37.4-25.69-57.36-19.84-94.05,7.56-47.44,79.72-52.3,71.3-86.19"/>
    <path className="b-cartoid"
          d="M557,30.1s-34.36,96.18-46.64,222.32c-11.63,119.5-20,296.13-20,296.13-86.87-262.48-86.87-525-86.87-525"/>
    <circle className="c-cartoid" cx="477.28" cy="477.29" r="457.28"/>
  </svg>
);

export const AorticAneurysm = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs>
      <style>{`.a-aortic,.b-aortic,.c-aortic{fill:none;stroke-width:40px;}.a-aortic,.b-aortic{stroke:#ff5050;}.a-aortic{stroke - linecap:round;stroke-linejoin:round;}.b-aortic,.c-aortic{stroke - miterlimit:10;}.c-aortic{stroke:#025cf7;}`}</style>
    </defs>
    <title>EMR Icons Expanded v3</title>
    <path className="a-aortic"
          d="M655.74,898.43,534.38,688.22a26.62,26.62,0,0,1-3.58-13.34h0a26.76,26.76,0,0,1,10.6-21.29C585.19,620.25,615,553.81,615,477.28c0-69.62-24.71-130.9-62.15-166.5a71.85,71.85,0,0,1-22.09-52.15V23.1"/>
    <path className="b-aortic" d="M404.85,928.86l56.55-98a18.35,18.35,0,0,1,31.77,0l56.55,98"/>
    <path className="a-aortic"
          d="M423.77,23.1V258.63a71.85,71.85,0,0,1-22.09,52.15c-37.44,35.6-62.15,96.88-62.15,166.5,0,76.53,29.85,143,73.64,176.31a26.76,26.76,0,0,1,10.6,21.29h0a26.62,26.62,0,0,1-3.58,13.34L298.83,898.43"/>
    <path className="a-aortic" d="M456.45,359.9A204.43,204.43,0,0,0,452.55,589"/>
    <circle className="c-aortic" cx="477.28" cy="477.28" r="457.28"/>
  </svg>
);

export const AccessManagement = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs>
      <style>{`.a-management,.b-management{fill:none;stroke-miterlimit:10;stroke-width:40px;}.a-management{stroke:#025cf7;}.b-management{stroke:#9855d4;}`}</style>
    </defs>
    <title>EMR Icons Expanded v3</title>
    <circle className="a-management" cx="477.28" cy="477.28" r="457.28"/>
    <line className="b-management" x1="430.71" y1="20" x2="430.71" y2="934.57"/>
    <line className="b-management" x1="523.86" y1="20" x2="523.86" y2="934.57"/>
    <path className="b-management"
          d="M430.71,430.71H313.44c8.82-12.8,20.76-30.57,20.76-57.75,0-45.63-46.32-82.72-90.43-82.72-72.3,0-127.19,76.55-127.19,191.38,0,103.25,62,187.81,142.42,182.47,66.32-4.4,83.15-34.76,83.15-63.54,0-31.65-29.44-50.67-34.33-72.46-.33-1.48-.6-2.88-.81-4.23h123.7Z"/>
    <path className="b-management"
          d="M523.86,430.71H641.13c-8.82-12.8-20.76-30.57-20.76-57.75,0-45.63,46.32-82.72,90.43-82.72,72.3,0,127.19,76.55,127.19,191.38,0,103.25-62,187.81-142.42,182.47-66.32-4.4-83.15-34.76-83.15-63.54,0-31.65,29.44-50.67,34.33-72.46.33-1.48.6-2.88.81-4.23H523.86Z"/>
  </svg>
);

export const MesentericIschemia = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs>
      <style>{`.a-ischemia,.b-ischemia{fill:none;stroke-linecap:round;stroke-width:40px;}.a-ischemia{stroke:#ff7832;stroke-miterlimit:10;}.b-ischemia{stroke:#025cf7;stroke-linejoin:round;}`}</style>
    </defs>
    <title>EMR Icons Expanded v3</title>
    <path className="a-ischemia" d="M516.61,21.67S494.05,78,506.4,119.53c11.44,38.51,66,55.35,66,55.35"/>
    <path className="a-ischemia"
          d="M587.89,241.85S551,150.5,592.08,135.53c57.2-20.84,160.65,50,183.34,134.51,21,78.08-64.83,80.65-64.83,80.65"/>
    <path className="a-ischemia"
          d="M430.87,27.93S415.24,59,420.82,103.53c5.61,44.78,41.49,74.28,14.35,95.63-38.42,30.22-122.44-53.59-176.3-42.7-75,15.16-55.66,126.75-8.38,136.46,51.63,10.61,68.66-32.65,103-31S390,293.6,420.75,297.77c34.3,4.64,52.82-34.43,90.61-29.12,42,5.9,58.51,72.84,58.51,72.84"/>
    <path className="a-ischemia" d="M556.63,371.62C567,335.62,599.8,294,632.54,294c55.11.05,145.4,119.47,54.14,148.77"/>
    <path className="a-ischemia"
          d="M215.73,267.81s-29.1-1.06-66.54,34.6c-41,39.07-43.49,121.24,14.23,147.91,45.31,20.93,171.07-22.81,176.25-68.15"/>
    <path className="a-ischemia" d="M323,347.18c10.51,33,32.18,84.16,148.91,66.3"/>
    <path className="a-ischemia"
          d="M484.63,350.69s-22.88,33.77-7.35,82.05c11.25,34.95,49.1,43.82,56.75,75.46,8.09,33.49,4.21,95.07,29.86,138.58,31.25,53,77.26,45.77,77.26,45.77"/>
    <path className="a-ischemia"
          d="M686.68,442.78s64.46-31.21,110.51-4.41c59.18,34.43,46.67,133.06-4.46,163.14-53.64,31.54-170.24-10.18-170.24-10.18S719,616.64,719,677.48c0,74.25-44.56,111-100.93,111-60.84,0-117.21-59.12-117.21-59.12"/>
    <path className="a-ischemia" d="M706.05,533.23s-29.85-36.59-83-36.59c-46.89,0-84,39-80.82,75.76"/>
    <path className="a-ischemia" d="M163.42,450.32s-35.16,141,31,174.83c69.32,35.44,128.23-74.93,207.81-50.37"/>
    <path className="a-ischemia"
          d="M239.61,556.64s46-68.74,116.65-79.36c77.95-11.71,143.06,77.51,114.79,133.78-20.46,40.75-48.93,45.21-83.72,49.49"/>
    <path className="a-ischemia" d="M318.31,591.33s-53.3,156.2,56,161.58"/>
    <path className="a-ischemia"
          d="M185.38,829.29s35.5-17.09,107.81,0c55.9,13.21,89.48,14.43,128.37-.93,42.42-16.74,74.05-49.11,79.26-99,5.87-56.16-46.05-94.57-46.05-94.57"/>
    <path className="a-ischemia" d="M634.41,388s-42.1,33.61-29.82,110.64"/>
    <path className="a-ischemia" d="M269.19,294.53s-9.49,74.87-75.35,73.87"/>
    <path className="a-ischemia" d="M125.46,769.4s46.34-31.92,114.15-33.59c55.86-1.38,137.77,41.38,183.91-2.52"/>
    <circle className="b-ischemia" cx="477.28" cy="477.28" r="457.28"/>
  </svg>
);

export const DeepVenous = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs>
      <style>{`.a-venous,.b-venous,.c-venous{fill:none;stroke-miterlimit:10;stroke-width:40px;}.a-venous,.b-venous{stroke:#7cc7ff;}.b-venous{stroke - linecap:round;}.c-venous{stroke:#025cf7;}`}</style>
    </defs>
    <title>EMR Icons Expanded v3</title>
    <path className="a-venous" d="M330.1,422.58s5.66,296.3-37.54,483.72"/>
    <path className="b-venous"
          d="M357.8,35.77c-2,11.21-4.25,22.43-6.74,33.59-32.49,145.33-88.69,339.48,8.81,373.83,52.63,18.54,81.93-46.08,81.93-46.08"/>
    <path className="b-venous" d="M322.42,685.17c-4.27,51.18,27.27,86.61,62.47,87.18,43.54.71,56.91-43,56.91-43"/>
    <path className="a-venous" d="M624.47,422.58S618.81,718.88,662,906.3"/>
    <path className="b-venous"
          d="M596.77,35.78c2,11.2,4.25,22.42,6.74,33.58C636,214.69,692.2,408.84,594.7,443.19c-52.63,18.54-81.93-46.08-81.93-46.08"/>
    <path className="b-venous" d="M632.15,685.17c4.27,51.18-27.27,86.61-62.47,87.18-43.54.71-56.91-43-56.91-43"/>
    <path className="b-venous"
          d="M734.18,854.28c-28.74-164.37-28-381.65-27.6-424a109.06,109.06,0,0,0,13.59-25.22c8.86-22.92,12.5-51.66,11.12-87.86-2.42-63.44-20.37-140.92-36.21-209.28-2.9-12.52-5.67-24.45-8.32-36.17"/>
    <path className="b-venous"
          d="M220.39,854.28c28.74-164.37,28-381.65,27.6-424a109.06,109.06,0,0,1-13.59-25.22c-8.86-22.92-12.5-51.66-11.12-87.86,2.42-63.44,20.37-140.92,36.21-209.28,2.9-12.52,5.67-24.45,8.32-36.17"/>
    <circle className="c-venous" cx="477.28" cy="477.29" r="457.28"/>
  </svg>
);

export const PeripheralVascular = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs>
      <style>{`.a-peripheral,.b-peripheral{fill:none;stroke-miterlimit:10;stroke-width:40px;}.a-peripheral{stroke:#fdd600;}.b-peripheral{stroke:#025cf7;}`}</style>
    </defs>
    <title>EMR Icons Expanded v3</title>
    <path className="a-peripheral"
          d="M314.73,184.66c-8.29,39.56,59.34,84.89,66.06,131.29,5.89,40.6-19.23,60.14-10.1,98.65,8.12,34.24,40,43.9,45.79,90.83,4.92,39.78-17.45,54.66-16,107.85,1.27,46.34,29.9,58.12,26.82,113.32-3.91,69.92-81.65,139.51-58.26,195.08"/>
    <path className="a-peripheral"
          d="M686.32,825.79c-14.41-40.69-95.5-27.31-121.46-68.5-26.2-41.56,4.22-67.39-.84-110.29-5.71-48.5-52.45-74.65-56.29-119.66-5.21-61.11,24.23-83.28,30.76-136.18,5.58-45.21-25-87.83,12.81-134.36C598.21,199.16,675.24,184.66,703.89,80"/>
    <path className="a-peripheral" d="M703.9,874.55c-45.1-114.81-71.25-251.08-71.25-397.27S658.79,194.82,703.89,80"/>
    <path className="a-peripheral" d="M369,32.89l-.39.84c-57.14,123.66-91,277.2-91,443.55,0,166.74,34,320.6,91.37,444.4"/>
    <path className="a-peripheral" d="M769.15,830.51c-35.23-105-55.25-225.32-55.25-353.23,0-127.53,19.9-247.52,54.93-352.3"/>
    <path className="a-peripheral" d="M272.79,68.16c-48,117.23-76,257.89-76,409.12s28,291.89,76,409.12"/>
    <circle className="b-peripheral" cx="477.28" cy="477.28" r="457.28"/>
  </svg>
);

export const VenousInsufficiency = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs>
      <style>{`.a-insufficiency,.b-insufficiency,.c-insufficiency{fill:none;stroke-miterlimit:10;stroke-width:40px;}.a-insufficiency,.b-insufficiency{stroke:#5aaafa;}.a-insufficiency{stroke - linecap:round;}.c-insufficiency{stroke:#025cf7;}`}</style>
    </defs>
    <title>EMR Icons Expanded v3</title>
    <path className="a-insufficiency" d="M323.27,327s-101.51,36.07-181.12-41.33"/>
    <path className="a-insufficiency" d="M243.71,338.15S217,410.45,109.58,382.23"/>
    <path className="a-insufficiency" d="M415.24,529.61s-149.8-96.88-299.6,48.92"/>
    <path className="a-insufficiency" d="M204.65,517.32S264.83,591.86,187,673.45"/>
    <path className="b-insufficiency"
          d="M888,673.45c-26.65-85.8-61.92-161.53-105.34-226-49.22-73.13-108.89-132-177.36-175.12-27.07-17-76.94-36.31-117.89-50.49a105.72,105.72,0,0,1-71.06-96.37,931.79,931.79,0,0,1,2.05-101.67"/>
    <path className="b-insufficiency"
          d="M656.51,898.1c13.67-83.24,11.13-151.24-7.67-211.93-19.55-63.14-53.93-110.67-90.32-161-24.4-33.74-49.64-68.63-74-112.57a465.79,465.79,0,0,1-34.95-81.55,403.22,403.22,0,0,1,97.62,46.54C600.32,412.24,647,459.71,686,518.71c49.91,75.6,87.37,170.53,111.34,282.15"/>
    <path className="b-insufficiency"
          d="M323.27,908C368,790.47,421,709.91,458.54,662.22c9.69-12.32,18.85-23.09,27.22-32.41,21.45,30.93,38.33,59.17,48.45,91.86,15,48.47,14.58,107.39-1.36,185.41l-4.39,22.12"/>
    <path className="b-insufficiency"
          d="M296.24,57.25c-3.22,93.36,3.54,269.33,83.33,413.47,11.85,21.42,23.85,40.89,35.67,58.89-51,52.44-133.36,155.19-199.23,323"/>
    <circle className="c-insufficiency" cx="477.28" cy="477.28" r="457.28"/>
  </svg>
);

export const WoundDisease = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs>
      <style>{`.a-wound,.b-wound{fill:none;stroke-miterlimit:10;stroke-width:40px;}.a-wound{stroke:#41d6c3;stroke-linecap:round;}.b-wound{stroke:#025cf7;}`}</style>
    </defs>
    <title>EMR Icons Expanded v3</title>
    <path className="a-wound"
          d="M206.13,362.26,426.31,240.8l32.59-18A154.2,154.2,0,0,1,628,480.65l-24.06,14.94L374.36,617.24c-74.95,46.56-177,13-192.56-66.68-14-71.74,46-137.82,118.12-131.75,118.89,10,76.5,173.88-10.51,147.68-43.36-13-29.86-64.5-8.1-75.81"/>
    <path className="a-wound"
          d="M860.56,714.15h-574c-102.23,0-185.11-82.87-185.11-185.1S184.3,343.94,286.53,343.94c112.17,0,163.84,79.76,161.4,163.68-2.24,76.79-73.57,109.62-73.57,109.62"/>
    <line className="a-wound" x1="492.47" y1="554.65" x2="926.64" y2="554.65"/>
    <circle className="b-wound" cx="477.28" cy="477.28" r="457.28"/>
  </svg>
);
