import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import Cookies from "universal-cookie";

import {
  CloseIcon,
  SummaryEmptySVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import { validateField } from "../../../../services/validations";
import LoginFormInput from "../../../../common/components/EditableText/LoginFormInput";
import {
  incident_reports_update,
  incident_reports_all,
  incident_reports_post,
  setGlobalLoading
} from "../../../../store/actions";
import ToastMessage from "../../../../common/components/Toast";

const VariancesSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  dataDetail,
  users,
  locations,
},ref) => {
  const [variance, setVariance] = useState({});
  const [isAddForm, setIsAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [state, setState] = useState({});

  const dispatch = useDispatch();
  const cookies = new Cookies();
  const authId = cookies.get("cvai-current-user");

  const handleChange = (type) => (value) => {
    const updatedVariance = { ...variance, [type]: value };
    setVariance(updatedVariance);
  };

  const fetchState = () => {
    if (Object.keys(dataDetail).length > 0) {
      setState({
        id: dataDetail.id,
        summary: dataDetail.summary ? dataDetail.summary : "",
        facts: dataDetail.facts ? dataDetail.facts : "",
        corrective: dataDetail.corrective_actions ? dataDetail.corrective_actions : ""
      });
    }
  };

  const fetchList = () => {
    dispatch(incident_reports_all());
  };

  const saveAll = async (value) => {
    setLoading(true);
    const checkSave = await dispatch(
      incident_reports_update(dataDetail.id, {
        user_id: dataDetail.user_id,
        location_id: dataDetail.location_id,
        summary: value.summary,
        facts: value.facts,
        corrective_actions: value.corrective,
      })
    );
    if (checkSave) {
      fetchList();
      setLoading(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const changeHandler = useCallback(debounce((value) => saveAll(value), 400), []);

  const handleDetailChange = (type, value) => {
    const newState = {
      ...state,
      [type]: value
    };
    setState(newState);
    isReset && changeHandler(newState);
  }

  const openAddForm = () => {
    setIsAddForm(true);
  };

  const closeAddForm = () => {
    setIsAddForm(false);
    setVariance({});
    setEnableError(false);
  };

  const addAll = async () => {
    const addSuccess = await dispatch(
      incident_reports_post({
        user_id: authId,
        location_id: variance.facility,
        summary: variance.summary,
        targeted_user_id: variance.employee_name,
        variance_type: variance.variance_type,
        points: variance.points,
      })
    );
    if (addSuccess) {
      fetchList();
      setLoading(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const addNewVariance = () => {
    if (
      validateField("text", variance.employee_name) ||
      validateField("text", variance.variance_type) ||
      validateField("date", variance.incident_date) ||
      validateField("time", variance.incident_time) ||
      validateField("text", variance.facility) ||
      validateField("text", variance.summary) ||
      validateField("text", variance.points)
    ) {
      setEnableError(true);
      return;
    }
    // Todo: API integration for add new variance
    setIsAddForm(false);
    addAll();
    setVariance({});
    setEnableError(false);
  };

  useEffect(() => {
    if (dataDetail && dataDetail.id !== state.id) {
      fetchState();
      setIsReset(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
  }, [dataDetail]);

  useEffect(() => {
    if (isShow && dataDetail) {
      dispatch(setGlobalLoading(loading));
    }
  }, [loading, isShow, dataDetail])

  if (!isShow || !dataDetail) {
    return null;
  }

  const renderTitle = () => {
    return (
      <span className="text-uppercase">
        {dataDetail && dataDetail.user && dataDetail.user.name}
      </span>
    );
  };

  return (
    <>
      <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
        {isToastr && (
          <ToastMessage
            type="Schedules"
            status="success"
            msg="Saved Successfully!"
          />
        )}

        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          {!isAddForm ? (
            <SidebarTitle icon={<MaleSVG />} title={renderTitle()} subTitle="" />
          ) : null}

          {!isAddForm && (
            <div
              className="resourceContent"
              style={{ flex: "unset", paddingLeft: "24px", paddingRight: "16px" }}
            >
              <div className="resourceInfo d-flex" style={{ paddingRight: 0 }}>
                <LoginFormInput
                  label={"Summary"}
                  value={state.summary}
                  type={"area"}
                  fullWidth={true}
                  noEditable
                  minRows={3}
                />
              </div>
              <hr />
              <div className="resourceInfo d-flex" style={{ paddingRight: 0 }}>
                <LoginFormInput
                  label={"Facts"}
                  value={state.facts}
                  handleChange={(value) => handleDetailChange('facts', value)}
                  type={"area"}
                  fullWidth={true}
                  minRows={6}
                />
              </div>
              <div
                className="resourceInfo d-flex"
                style={{ paddingRight: 0, marginTop: "0.5rem" }}
              >
                <LoginFormInput
                  label={"Corrective Actions"}
                  value={state.corrective}
                  handleChange={(value) => handleDetailChange('corrective', value)}
                  type={"area"}
                  fullWidth={true}
                  minRows={6}
                />
              </div>
              <hr />
            </div>
          )}

          {isAddForm && (
            <div
              className="resourceContent"
              style={{ flex: "unset", paddingLeft: "24px", paddingRight: "16px" }}
            >
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Employee Name"}
                  value={variance.employee_name}
                  type={"check"}
                  handleChange={handleChange("employee_name")}
                  required={true}
                  enableErrorText={enableError}
                  isError={validateField("text", variance.employee_name)}
                  helperText={"Employee Name is required"}
                  optionKey={"id"}
                  valueKey={"name"}
                  options={(users || []).map((value) => {
                    return { name: value.name, id: value.id };
                  })}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Variance Type"}
                  value={variance.variance_type}
                  type={"check"}
                  handleChange={handleChange("variance_type")}
                  required={true}
                  enableErrorText={enableError}
                  isError={validateField("text", variance.variance_type)}
                  helperText={"Variance Type is required"}
                  optionKey={"type"}
                  valueKey={"type"}
                  options={[
                    { type: "Timestamp" },
                    { type: "Behavior" },
                    { type: "Performance" },
                    { type: "Quality" },
                  ]}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Incident Date"}
                  value={variance.incident_date}
                  type={"date"}
                  handleChange={handleChange("incident_date")}
                  required={true}
                  enableErrorText={enableError}
                  isError={validateField("date", variance.incident_date)}
                  helperText={"Incident Date is required"}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Incident Time"}
                  value={variance.incident_time}
                  type={"time"}
                  handleChange={handleChange("incident_time")}
                  required={true}
                  enableErrorText={enableError}
                  isError={validateField("time", variance.incident_time)}
                  helperText={"Incident Time is required"}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Facility"}
                  value={variance.facility}
                  type={"check"}
                  handleChange={handleChange("facility")}
                  required={true}
                  enableErrorText={enableError}
                  isError={validateField("text", variance.facility)}
                  helperText={"Facility is required"}
                  optionKey={"id"}
                  valueKey={"name"}
                  options={(locations || []).map((value) => {
                    return { name: value.value, id: value.id };
                  })}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Summary"}
                  value={variance.summary}
                  type={"area"}
                  handleChange={handleChange("summary")}
                  required={true}
                  enableErrorText={enableError}
                  isError={validateField("text", variance.summary)}
                  helperText={"Summary is required"}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Points"}
                  value={variance.points}
                  handleChange={handleChange("points")}
                  required={true}
                  enableErrorText={enableError}
                  isError={validateField("text", variance.points)}
                  helperText={"Points is required"}
                />
              </div>
            </div>
          )}
          {!isAddForm && (
            <div
              className="actionContent"
              style={{ marginTop: "2rem", paddingRight: "1rem" }}
            >
              <button className="outlined-btn" onClick={openAddForm}>
                Add New Variance
              </button>
            </div>
          )}
          {isAddForm && (
            <div
              className="actionContent"
              style={{ marginTop: "2rem", paddingRight: "1rem" }}
            >
              <button className="outlined-btn" onClick={closeAddForm}>
                Back
              </button>
              <button style={{ marginLeft: "0.5rem" }} onClick={addNewVariance}>
                Save
              </button>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </>
  );
});

export default VariancesSidebar;
