import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Upload, message, Select, Checkbox } from "antd";
import { useDebounce } from "use-lodash-debounce";
import { CloudIconSVG, ExpandSVG } from "../../components/icons/SVGIcon";
import YesNo from "../../components/YesNo";
import {
  getDiseases,
  getSurgicalHistories,
} from "../../../services/api.services";
import {
  updateMedicalHistory,
  setPatientSnapshotData,
  searchAllergy,
} from "../../../store/actions";
import ICDAutoCompleteComponent from "../../components/ICDAutoComplete";
import MedicationAutoComplete from "../../components/MedicationAutoComplete";
import EditableFormInput from "../../components/EditableText/EditableFormInput";
import moment from "moment";
import { validateField } from "../../../services/validations";
import SurgicalHistoryAutoCompleteComponent from "../../components/SurgicalHistoryAutoComplete";

const { Panel } = Collapse;
const { Dragger } = Upload;
const { Option } = Select;

const initialState = {
  previous_medical_history: [],
  previous_surgical_history: [],
  medications: [],
  allergies: [],
  allAllergies: [],
  flu_vaccination: -1,
  pneumococcal: -1,
  pneumococcal_reason: "",
  flu_vaccination_reason: null,
  no_medication: false,
  no_drug_allergies: false,
  aortic_disease: -1,
  amputation: -1,
  heart_disease: -1,
  smoking_ppd: 0,
  alcoholic_drink_pw: "",
  previous_smoking_ppd: 0,
  years_of_smoking: "",
};

const draggerProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

let timer = null;

const MedicalHistory = ({ ...restProps }) => {
  const { resource, medical, loadingUpdateMedical } = useSelector((state) => ({
    resource: state.patientSnapshot.resource,
    medical: state.patientSnapshot.medical,
    loadingUpdateMedical: state.patientSnapshot.loadingUpdateMedical,
  }));

  const dispatch = useDispatch();

  const [isMounted, setMount] = useState(false);
  const [option, setOption] = useState(initialState);
  const accordion = useSelector(
    (state) => state.clinicProviderReview.accordion
  );
  const [hideScope, setHideScope] = useState(false);
  const [row, setRow] = useState(initialState);
  const [searchInput, setInput] = useState("");
  const [searching, setSearching] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [searchSurgicalInput, setSearchSurgicalInput] = useState("");
  const [searchingSurgical, setSearchingSurgical] = useState(false);
  const [isResultSurgical, setIsResultSurgical] = useState(false);
  const [type, setType] = useState("");
  const [ICDCodeName, setICDCodeName] = useState([]);
  const [codeNameSurgical, setCodeNameSurgical] = useState([]);
  const [ICDID, setICDID] = useState([]);
  const [is65, setIs65] = useState(false);
  const debouncedValue = useDebounce(searchInput, 400);
  const debouncedSurgicalValue = useDebounce(searchSurgicalInput, 400);
  const loadingAllergy = useSelector((state) => state.common.loadingAllergy);
  const allergiesRedux = useSelector((state) => state.common.allergies);
  const [loadingAllergyLocal, setLoadingAllergyLocal] = useState(false);

  const searchResult = async () => {
    setSearching(true);
    setIsResult(true);
    try {
      const result = await getDiseases(searchInput);
      if (isMounted) {
        const newOptions = result.data || [];
        const newOption = {
          ...option,
          [type == "medications-check" ? "medications" : type]: newOptions,
        };
        setOption(newOption);
        setSearching(false);
      }
    } catch (error) {
      const newOption = {
        ...option,
        [type == "medications-check" ? "medications" : type]: [],
      };
      setOption(newOption);
      setSearching(false);
    }
  };

  const searchResultSurgical = async () => {
    setSearchingSurgical(true);
    setIsResultSurgical(true);
    try {
      const result = await getSurgicalHistories(searchSurgicalInput);
      if (isMounted) {
        const newOptions = result.data || [];
        const newOption = {
          ...option,
          [type == "medications-check" ? "medications" : type]: newOptions,
        };
        setOption(newOption);
        setSearchingSurgical(false);
      }
    } catch (error) {
      const newOption = {
        ...option,
        [type == "medications-check" ? "medications" : type]: [],
      };
      setOption(newOption);
      setSearchingSurgical(false);
    }
  };

  useEffect(() => {
    setMount(true);

    return () => {
      setMount(false);
    };
  });

  useEffect(() => {
    if (resource) {
      if (moment().diff(resource?.date_of_birth, "years") >= 65) {
        setIs65(true);
      } else {
        setIs65(false);
      }
    }
  }, [resource]);

  useEffect(() => {
    if (debouncedValue) {
      searchResult();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (debouncedSurgicalValue) {
      searchResultSurgical();
    }
  }, [debouncedSurgicalValue]);

  useEffect(() => {
    const previous_surgical_history = (
      resource.previous_surgical_history || []
    ).map((sh) => sh.procedure_name || sh);
    const medications = (resource.medications || []).map((mh) => mh.name || mh);
    const allergies = (resource.allergies || []).map(
      (ah) => ah.allergy_name || ah
    );
    const newRow = {
      ...row,
      previous_surgical_history,
      medications,
      allergies,
      flu_vaccination: resource.flu_vaccination,
      pneumococcal: resource.pneumococcal,
      pneumococcal_reason: resource?.pneumococcal_reason,
      flu_vaccination_reason: resource?.flu_vaccination_reason,
      no_medication: resource?.no_medication,
      no_drug_allergies: row.no_drug_allergies,
      aortic_disease: resource.aortic_disease,
      amputation: resource.amputation,
      heart_disease: resource.heart_disease,
      smoking_ppd: Number(resource.smoking_ppd) || resource.smoking_ppd,
      previous_smoking_ppd:
        Number(resource.previous_smoking_ppd) || resource.previous_smoking_ppd,
      alcoholic_drink_pw:
        Number(resource.alcoholic_drink_pw) || resource.alcoholic_drink_pw,
      years_of_smoking:
        Number(resource.years_of_smoking) || resource.years_of_smoking,
    };
    setRow(newRow);
  }, []);

  useEffect(() => {
    if (resource && resource.icd_codes) {
      const arrayICDCodeName = (resource.icd_codes || []).map((r) => {
        return `${r?.disease?.icd_code_name}`;
      });
      let name = [];
      (arrayICDCodeName || []).map((item, index) => {
        if (!name.includes(item)) {
          name.push(item);
        }
      });
      setICDCodeName(name);
      //
      const arrayNameSurgical = (resource?.previous_surgical_history || []).map(
        (r) => {
          return `${r?.surgical_history?.name}`;
        }
      );
      let nameSurgical = [];
      (arrayNameSurgical || []).map((item, index) => {
        if (!nameSurgical.some((r) => r == item)) {
          nameSurgical.push(item);
        }
      });
      setCodeNameSurgical([...nameSurgical]);

      const arrayIDSurgical = (resource.previous_surgical_history || []).map(
        (r) => {
          return `${r?.surgical_history?.id}`;
        }
      );
      let idSurgical = [];
      (arrayIDSurgical || []).map((item, index) => {
        if (!idSurgical.includes(item)) {
          idSurgical.push(item);
        }
      });
      setICDID(idSurgical);
      //
      const previous_surgical_history = (
        resource.previous_surgical_history || []
      ).map((sh) => sh?.surgical_history?.id || sh);

      const medications = (resource.medications || []).map(
        (mh) => mh.name || mh
      );
      const allergies = (resource.allergies || []).map(
        (ah) => ah.allergy_name || ah
      );
      const newRow = {
        ...row,
        previous_surgical_history,
        previous_medical_history: name,
        medications,
        allergies,
        flu_vaccination: resource.flu_vaccination,
        pneumococcal: resource.pneumococcal,
        pneumococcal_reason: resource?.pneumococcal_reason,
        flu_vaccination_reason: resource?.flu_vaccination_reason,
        no_medication: resource?.no_medication,
        no_drug_allergies: resource?.no_drug_allergies,
        aortic_disease: resource.aortic_disease,
        amputation: resource.amputation,
        heart_disease: resource.heart_disease,
        smoking_ppd: Number(resource.smoking_ppd) || resource.smoking_ppd,
        previous_smoking_ppd:
          Number(resource.previous_smoking_ppd) ||
          resource.previous_smoking_ppd,
        alcoholic_drink_pw:
          Number(resource.alcoholic_drink_pw) || resource.alcoholic_drink_pw,
        years_of_smoking:
          Number(resource.years_of_smoking) || resource.years_of_smoking,
      };
      setRow(newRow);
    }
  }, [resource]);

  const changePanel = (selectedType) => {
    setType(selectedType);
    setInput("");
  };

  const handleUpdateOption = (selectedType) => (value) => {
    let newRow = {};
    if (selectedType == "flu_vaccination_reason") {
      newRow = {
        ...row,
        ["flu_vaccination_reason"]: value
          ? moment(value).format("MM/DD/YYYY")
          : "",
      };
    } else if (selectedType == "no_medication") {
      newRow = { ...row, ["no_medication"]: value.target.checked };
    } else if (selectedType == "no_drug_allergies") {
      newRow = { ...row, ["no_drug_allergies"]: value.target.checked };
    } else {
      newRow = {
        ...row,
        [selectedType == "medications-check" ? "medications" : selectedType]:
          value,
      };
    }
    setRow({ ...newRow });
    dispatch(setPatientSnapshotData("resource", { ...resource, ...newRow }));
    dispatch(updateMedicalHistory(newRow, resource.id));
  };

  const handleUpdateBox = (selectedType) => (e) => {
    const newRow = {
      ...row,
      [selectedType == "medications-check" ? "medications" : selectedType]: e,
    };
    setRow(newRow);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setPatientSnapshotData("resource", { ...resource, ...newRow }));
      dispatch(updateMedicalHistory(newRow, resource.id));
    }, 500);
  };

  const selectItem = (item, id) => {
    const checkExist = (
      row[type == "medications-check" ? "medications" : type] || []
    ).some((r) => {
      if (type == "previous_surgical_history") {
        return (
          r.surgical_history?.name.replace(/\s/g, "") ===
          item.replace(/\s/g, "")
        );
      } else {
        return r.replace(/\s/g, "") === item.replace(/\s/g, "");
      }
    });
    if (
      row &&
      row[type == "medications-check" ? "medications" : type] &&
      !checkExist
    ) {
      var ICDCheck = [...ICDCodeName];
      if (!ICDCodeName.some((r) => r === item)) {
        ICDCheck = [...ICDCheck, item];
        setICDCodeName([...ICDCheck]);
      }
      const newRows = [
        ...row[type == "medications-check" ? "medications" : type],
        item,
      ];
      //
      if (type == "previous_surgical_history") {
        var nameCheck = [...codeNameSurgical];
        if (!codeNameSurgical.some((r) => r == item)) {
          nameCheck = [...codeNameSurgical, item];
        }
        setCodeNameSurgical(nameCheck);

        var ICDIDCheck = [...ICDID];
        if (!ICDID.some((r) => r === id)) {
          ICDIDCheck = [...ICDIDCheck, id];
        }
        setICDID([...ICDIDCheck]);
      }
      //
      let newRow = {};
      if (type == "previous_surgical_history") {
        newRow = {
          ...row,
          ["previous_surgical_history"]: [...ICDIDCheck],
        };
      } else if (type == "previous_medical_history") {
        newRow = {
          ...row,
          ["previous_medical_history"]: [...ICDCheck],
        };
      } else {
        newRow = {
          ...row,
          [type == "medications-check" ? "medications" : type]: newRows,
        };
      }

      setIsResult(false);
      setInput("");
      dispatch(setPatientSnapshotData("resource", { ...resource, ...newRow }));
      dispatch(updateMedicalHistory(newRow, resource.id));
    }
  };

  const handleSearch = (search) => {
    setInput(search);
  };

  const handleSearchSurgical = (search) => {
    setSearchSurgicalInput(search);
  };

  const searchingAllergy = async (query) => {
    clearTimeout(timer);
    setLoadingAllergyLocal(true);
    timer = setTimeout(() => {
      setLoadingAllergyLocal(false);
      dispatch(searchAllergy(query));
    }, 500);
  };

  const removeRow = (item) => {
    const index = ICDCodeName.findIndex((r) => r === item);
    const newRowsICD = [...ICDCodeName];
    newRowsICD.splice(index, 1);
    setICDCodeName(newRowsICD);
    const newRow = {
      ...row,
      ["previous_medical_history"]: newRowsICD,
    };
    dispatch(setPatientSnapshotData("resource", { ...resource, ...newRow }));
    dispatch(updateMedicalHistory(newRow, resource.id));
  };

  const removeRowMedicationAllergies = (item, index) => {
    if (type == "previous_surgical_history") {
      const newRows = [...ICDID];
      newRows.splice(index, 1);
      setICDID([...newRows]);
      const newRow = {
        ...row,
        ["previous_surgical_history"]: newRows,
      };
      dispatch(setPatientSnapshotData("resource", { ...resource, ...newRow }));
      dispatch(updateMedicalHistory(newRow, resource.id));
    } else {
      const rowIndex = row[
        type == "medications-check" ? "medications" : type
      ].findIndex((r) => r === item);
      const newRows = [
        ...row[type == "medications-check" ? "medications" : type],
      ];
      newRows.splice(rowIndex, 1);
      const newRow = {
        ...row,
        [type == "medications-check" ? "medications" : type]: newRows,
      };
      dispatch(setPatientSnapshotData("resource", { ...resource, ...newRow }));
      dispatch(updateMedicalHistory(newRow, resource.id));
    }
  };

  useEffect(() => {
    let a = moment();
    let b = moment(resource.date_of_birth);
    if (a.diff(b, "years") >= 62) {
      setHideScope(false);
    } else {
      setHideScope(true);
    }
  }, [resource]);

  useEffect(() => {
    if (accordion && accordion.length > 0) {
      if (accordion == "Immunization") {
        setType("history-2");
      } else if (accordion == "medications") {
        setType("medications");
      }
    }
  }, [accordion]);

  return (
    <div className="resourceContent pr-7px">
      <Collapse activeKey={type} accordion onChange={changePanel}>
        <Panel
          header="Previous Medical History"
          className={
            resource?.icd_codes && resource?.icd_codes.length > 0
              ? ""
              : "invalid"
          }
          key="previous_medical_history"
        >
          <ICDAutoCompleteComponent
            title="previous-medical"
            rows={ICDCodeName}
            keyLabel={"icd_code_name"}
            removeItem={removeRow}
            addItem={searchResult}
            loading={searching}
            options={option[type]}
            isResult={isResult}
            query={searchInput}
            selectItem={selectItem}
            handleSearch={handleSearch}
          />
        </Panel>
        <Panel
          header="Immunization"
          className={
            resource?.pneumococcal != null ||
            resource?.pneumococcal == -1 ||
            resource?.pneumococcal == null
              ? ""
              : "invalid"
          }
          key="history-2"
        >
          {hideScope &&
          Number(moment().format("MM")) < 10 &&
          Number(moment().format("MM")) > 2 ? (
            <div>There is no immunization data needed at this time</div>
          ) : null}
          {Number(moment().format("MM")) >= 10 ||
          Number(moment().format("MM")) <= 2 ? (
            <div className="resourceInfo between half-pannel">
              <div style={{ width: "calc(100% - 180px)" }}>
                Have you received flu vaccination this season?
              </div>
              <YesNo
                option={row["flu_vaccination"]}
                setOption={handleUpdateOption("flu_vaccination")}
              />
            </div>
          ) : null}
          {row["flu_vaccination"] == 1 ? (
            <div className="resourceInfo between half-pannel">
              <div style={{ width: "calc(100% - 199px)" }}>
                Date vaccination received?
              </div>
              <div
                style={{
                  width: "150px",
                  minWidth: "150px",
                }}
              >
                <EditableFormInput
                  label={"Date"}
                  fullWidth={true}
                  value={
                    row["flu_vaccination_reason"]
                      ? moment(row["flu_vaccination_reason"])
                      : null
                  }
                  type="date"
                  handleChange={handleUpdateOption("flu_vaccination_reason")}
                />
              </div>
            </div>
          ) : null}
          {!hideScope ? (
            <React.Fragment>
              <div className="resourceInfo between half-pannel">
                <div style={{ width: "calc(100% - 180px)" }}>
                  Have you received the pneumococcal vaccination?
                </div>
                <YesNo
                  option={row["pneumococcal"]}
                  setOption={handleUpdateOption("pneumococcal")}
                />
              </div>
              {row["pneumococcal"] == 0 ? (
                <div className="resourceInfo between half-pannel">
                  <div style={{ width: "calc(100% - 199px)" }}>
                    Reasons why not?
                  </div>
                  <div
                    style={{
                      width: "150px",
                      minWidth: "150px",
                    }}
                  >
                    <EditableFormInput
                      label={"Reasons"}
                      fullWidth={true}
                      value={row["pneumococcal_reason"]}
                      type="check"
                      options={[
                        { value: "Medical Reason" },
                        { value: "Refused" },
                      ]}
                      optionKey={"value"}
                      valueKey={"value"}
                      handleChange={handleUpdateOption("pneumococcal_reason")}
                    />
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
        </Panel>
        <Panel
          header="Previous Surgical History"
          className={
            resource?.previous_surgical_history &&
            resource?.previous_surgical_history.length > 0
              ? ""
              : "invalid"
          }
          key="previous_surgical_history"
        >
          <SurgicalHistoryAutoCompleteComponent
            title="previous-surgical"
            loadingTable={loadingUpdateMedical}
            rows={codeNameSurgical}
            keyLabel={"name"}
            keyValue={"id"}
            removeItem={removeRowMedicationAllergies}
            addItem={searchResultSurgical}
            loading={searchingSurgical}
            options={option[type]}
            isResult={isResultSurgical}
            query={searchSurgicalInput}
            selectItem={selectItem}
            handleSearch={handleSearchSurgical}
          />
        </Panel>
        <Panel
          header="Family History"
          key="history-4"
          className={
            (resource?.amputation == 1 || resource?.amputation == 0) &&
            (resource?.aortic_disease == 1 || resource?.aortic_disease == 0)
              ? ""
              : "invalid"
          }
        >
          <div className="resourceInfo between half-pannel">
            <div style={{ width: "calc(100% - 180px)" }}>
              Anyone in family suffer from aortic aneurysm disease?
            </div>
            <YesNo
              option={row["aortic_disease"]}
              setOption={handleUpdateOption("aortic_disease")}
            />
          </div>
          <div className="resourceInfo between half-pannel">
            <div style={{ width: "calc(100% - 180px)" }}>
              Anyone in family suffer from amputation?
            </div>
            <YesNo
              option={row["amputation"]}
              setOption={handleUpdateOption("amputation")}
            />
          </div>
        </Panel>
        <Panel
          header="Social History"
          className={
            resource?.previous_smoking_ppd != null &&
            resource?.smoking_ppd != null &&
            resource?.years_of_smoking != null &&
            resource?.years_of_smoking != "" &&
            resource?.alcoholic_drink_pw != null
              ? ""
              : "invalid"
          }
          key="history-5"
        >
          <div className="resourceInfo between half-pannel">
            <div style={{ width: "calc(100% - 199px)" }}>
              Previous smoking history
            </div>
            <div
              style={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <EditableFormInput
                label={"Packs (ppd)"}
                fullWidth={true}
                value={row["previous_smoking_ppd"]}
                type="check"
                options={[
                  { value: 0 },
                  { value: 1 },
                  { value: 1.5 },
                  { value: 2 },
                  { value: 2.5 },
                  { value: 3 },
                ]}
                optionKey={"value"}
                valueKey={"value"}
                handleChange={handleUpdateBox("previous_smoking_ppd")}
              />
            </div>
          </div>
          <div className="resourceInfo between half-pannel">
            <div style={{ width: "calc(100% - 199px)" }}>
              Current smoking history
            </div>
            <div
              style={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <EditableFormInput
                label={"Packs (ppd)"}
                fullWidth={true}
                value={row["smoking_ppd"]}
                type="check"
                options={[
                  { value: 0.5 },
                  { value: 1 },
                  { value: 1.5 },
                  { value: 2 },
                  { value: 2.5 },
                  { value: 3 },
                ]}
                optionKey={"value"}
                valueKey={"value"}
                handleChange={handleUpdateBox("smoking_ppd")}
              />
            </div>
          </div>
          <div className="resourceInfo between half-pannel">
            <div style={{ width: "calc(100% - 199px)" }}>
              Total years smoking
            </div>
            <div
              style={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <EditableFormInput
                label={"Enter years"}
                fullWidth={true}
                value={row["years_of_smoking"]}
                handleChange={handleUpdateBox("years_of_smoking")}
              />
            </div>
          </div>
          <div className="resourceInfo between half-pannel">
            {is65 ||
            (resource?.gender &&
              resource?.gender.toLowerCase() === "female") ? (
              <div style={{ width: "calc(100% - 199px)" }}>
                How many times in the past year have you had more than 4
                drinks/day
              </div>
            ) : (
              <div style={{ width: "calc(100% - 199px)" }}>
                How many times in the past year have you had more than 5
                drinks/day
              </div>
            )}
            <div
              style={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <EditableFormInput
                label={"Days/year"}
                fullWidth={true}
                value={row["alcoholic_drink_pw"]}
                type="check"
                options={[{ value: "< 2 days" }, { value: "≥ 2 days" }]}
                optionKey={"value"}
                valueKey={"value"}
                handleChange={handleUpdateBox("alcoholic_drink_pw")}
              />
            </div>
          </div>
        </Panel>
        <Panel
          header="Allergies"
          className={
            (resource?.allergies && resource?.allergies.length > 0) ||
            resource?.no_drug_allergies == true
              ? ""
              : "invalid"
          }
          key="allergies"
        >
          <ICDAutoCompleteComponent
            title="allergies"
            rows={row["allergies"] || []}
            keyLabel={"name"}
            removeItem={removeRowMedicationAllergies}
            addItem={searchResult}
            exception={true}
            loading={loadingAllergyLocal || loadingAllergy}
            options={
              loadingAllergyLocal || loadingAllergy ? [] : allergiesRedux
            }
            isResult={isResult}
            selectItem={selectItem}
            handleSearch={searchingAllergy}
          />
          <div
            style={{ marginTop: 10 }}
            className="resourceInfo between half-pannel"
          >
            <Checkbox
              checked={row["no_drug_allergies"]}
              onChange={handleUpdateOption("no_drug_allergies")}
              style={{ width: "100%" }}
              disabled={row["allergies"] && row["allergies"].length > 0}
            >
              No Known Drug Allergies
            </Checkbox>
          </div>
        </Panel>
        <Panel
          header="Medications"
          className={
            (resource?.medications && resource?.medications.length > 0) ||
            resource?.no_medication == true
              ? ""
              : "invalid"
          }
          key="medications-check"
        >
          <MedicationAutoComplete
            title="medications"
            rows={(row && row["medications"]) || []}
            keyLabel={"full_medication"}
            removeItem={removeRowMedicationAllergies}
            addItem={(val) => console.log("searchResult")}
            loading={searching}
            options={[]}
            isResult={isResult}
            query={searchInput}
            selectItem={selectItem}
            minRows={8}
            handleSearch={(val) => console.log(val, "handleSearch")}
          />
          <div
            style={{ marginTop: 10 }}
            className="resourceInfo between half-pannel"
          >
            <Checkbox
              checked={row["no_medication"]}
              onChange={handleUpdateOption("no_medication")}
              style={{ width: "100%" }}
              disabled={row["medications"] && row["medications"].length > 0}
            >
              No Known Medications
            </Checkbox>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default MedicalHistory;
