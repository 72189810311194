import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Input, Select } from "antd";

import ClinicWorkflowSidebar from "../../../../common/views/QuickRegisterSidebar";
import {
  ExpandSVG,
  LeftCharvenSVG,
} from "../../../../common/components/icons/SVGIcon";
import ClinicalIndication from "./ClinicalIndication";
import UltrasoundResults from "./Results";
import Conclusion from "./Conclusion";
import DataTable from "../../../../common/components/DataTable/DataTable";
import {
  save_ultrasound_finding_value,
  get_ultrasound_finding_value,
  get_ultrasound_rule_result,
  setDetailUltrasoundProps,
  setGlobalLoading,
  getUltrasounds,
} from "../../../../store/actions";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";
const cookies = new Cookies();

let timer = null;
const initialConditions = [
  { step: "clinical", status: false },
  { step: "results", status: false },
  { step: "conclusion", status: false },
];

const formSteps = [
  {
    title: "Clinical Indication",
    content: ClinicalIndication,
  },
  {
    title: "Results",
    content: UltrasoundResults,
  },
  {
    title: "Conclusion",
    content: Conclusion,
  },
];

const UltrasoundFindings = ({ patientResource }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const apointment_ultrasound_id = cookies.get("apointment_ultrasound_id");
  const [localLoading, setLocalLoading] = useState(false);
  const conditions = useSelector((state) => state.common.referralConditions);
  const ultrasound_finding = useSelector(
    (state) => state.procedureDetail.ultrasound_type
  );
  const ultrasound_value = useSelector(
    (state) => state.common.ultrasound_value
  );
  const detail_ultrasound_result = useSelector(
    (state) => state.common.detail_ultrasound_result
  );
  const detailUltrasounds = useSelector(
    (state) => state.common.detailUltrasounds
  );
  const ultrasounds_all = useSelector((state) => state.common.ultrasounds);
  const [ultrasounds_store, set_ultrasounds_store] = useState([]);

  const [ultrasoundID, setUltrasoundID] = useState();
  const dispatch = useDispatch();
  const [activeIndex, setRow] = useState(-1);

  const is_locked = useSelector(
    (state) => state.clinicProviderReview.is_locked
  );

  useEffect(() => {
    if (detail_ultrasound_result) {
      dispatch(
        setDetailUltrasoundProps("detail_ultrasound_props", {
          conclusion_l: detail_ultrasound_result.left_conclusion,
          conclusion_r: detail_ultrasound_result.right_conclusion,
          result_l: detail_ultrasound_result.left_result,
          result_r: detail_ultrasound_result.right_result,
          disease_id:
            (detail_ultrasound_result.icd_codes &&
              detail_ultrasound_result.icd_codes[0] &&
              detail_ultrasound_result.icd_codes[0]["id"]) ||
            "",
        })
      );
    } else {
      dispatch(setDetailUltrasoundProps("detail_ultrasound_props", {}));
    }
  }, [detail_ultrasound_result]);

  useEffect(() => {
    dispatch(setGlobalLoading(localLoading));
  }, [localLoading]);

  useEffect(() => {
    if (ultrasound_finding && ultrasound_value) {
      const check = ultrasound_finding.map((r) => {
        return {
          finding_name:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`]["finding_name"]
              ? ultrasound_value[`${r.id}`]["finding_name"]
              : "",
          finding_id:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`]["finding_id"]
              ? ultrasound_value[`${r.id}`]["finding_id"]
              : "",
          ultrasound_type_id:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`]["ultrasound_type_id"]
              ? ultrasound_value[`${r.id}`]["ultrasound_type_id"]
              : "",
          appointment_id: apointment_ultrasound_id,
          [`${detailUltrasounds?.headers[0]}`]:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[0]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[0]}`]
              : "",
          [`${detailUltrasounds?.headers[1]}`]:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[1]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[1]}`]
              : "",
          [`${detailUltrasounds?.headers[2]}`]:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[2]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[2]}`]
              : "",
          [`${detailUltrasounds?.headers[3]}`]:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[3]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[3]}`]
              : "",
          [`${detailUltrasounds?.headers[4]}`]:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[4]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[4]}`]
              : "",
          [`${detailUltrasounds?.headers[5]}`]:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[5]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[5]}`]
              : "",
          [`${detailUltrasounds?.headers[6]}`]:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[6]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[6]}`]
              : "",
          [`${detailUltrasounds?.headers[7]}`]:
            ultrasound_value &&
              ultrasound_value[`${r.id}`] &&
              ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[7]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[7]}`]
              : "",
        };
      });
      set_ultrasounds_store([...check]);
    }
  }, [ultrasound_value, ultrasound_finding, detailUltrasounds]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleRow = async (index, id) => {
    setRow(index);
    if (!ultrasoundID || ultrasoundID !== id) {
      setUltrasoundID(id);
      dispatch(get_ultrasound_rule_result(apointment_ultrasound_id));
      handleSidebar();
    }
  };

  const callSaveAPI = async () => {
    if (
      ultrasound_finding &&
      ultrasound_value &&
      ultrasound_value.length == 0 &&
      detailUltrasounds &&
      Object.keys(detailUltrasounds).length > 0
    ) {
      setLocalLoading(true);
      let check = {};
      ultrasound_finding.map((r) => {
        check = {
          ...check,
          [`${r.id}`]: {
            finding_name: r.finding_name,
            finding_id: r.id,
            appointment_id: apointment_ultrasound_id,
            ultrasound_type_id: r.ultrasound_type_id,
            row1: "",
          },
        };
      });
      if (check && Object.keys(check).length > 0) {
        await dispatch(save_ultrasound_finding_value({ values: check }));
        await dispatch(get_ultrasound_finding_value(apointment_ultrasound_id));
      }
      setLocalLoading(false);
    }
  };

  useEffect(() => {
    callSaveAPI();
  }, [ultrasound_finding, ultrasound_value, detailUltrasounds]);

  const setOnChange = async (type, value, index) => {
    ultrasounds_store[index][type] = value;
    set_ultrasounds_store([...ultrasounds_store]);
    //
    let headerCheck = {};
    let check = {};
    ultrasounds_store.map((row) => {
      check = {
        ...check,
        [`${row.finding_id}`]: {
          finding_name: row.finding_name,
          finding_id: row.finding_id,
          appointment_id: row.appointment_id,
          [`${detailUltrasounds?.headers[0]}`]: row[
            `${detailUltrasounds?.headers[0]}`
          ],
          [`${detailUltrasounds?.headers[1]}`]: row[
            `${detailUltrasounds?.headers[1]}`
          ],
          [`${detailUltrasounds?.headers[2]}`]: row[
            `${detailUltrasounds?.headers[2]}`
          ],
          [`${detailUltrasounds?.headers[3]}`]: row[
            `${detailUltrasounds?.headers[3]}`
          ],
          [`${detailUltrasounds?.headers[4]}`]: row[
            `${detailUltrasounds?.headers[4]}`
          ],
          [`${detailUltrasounds?.headers[5]}`]: row[
            `${detailUltrasounds?.headers[5]}`
          ],
          [`${detailUltrasounds?.headers[6]}`]: row[
            `${detailUltrasounds?.headers[6]}`
          ],
          [`${detailUltrasounds?.headers[7]}`]: row[
            `${detailUltrasounds?.headers[7]}`
          ],
        },
      };
    });
    clearTimeout(timer);
    timer = setTimeout(async () => {
      if (check && Object.keys(check).length > 0) {
        setLocalLoading(true);
        await dispatch(save_ultrasound_finding_value({ values: check }));
        setLocalLoading(false);
      }
    }, 1000);
  };

  const checkUltra = (header, row) => {
    // true is select , false is input
    if (row.ultrasound_type_id == "2") {
      if (
        (header == "Stenosis" &&
          row.finding_name !== "Left Middle Vert" &&
          row.finding_name !== "Right Vert Flow") ||
        (header == "Plaque" &&
          row.finding_name !== "Left Middle Vert" &&
          row.finding_name !== "Right Vert Flow") ||
        (header == "Patency" &&
          row.finding_name !== "Left Middle Vert" &&
          row.finding_name !== "Right Vert Flow") ||
        (header == "Wave" &&
          row.finding_name !== "Left Middle Vert" &&
          row.finding_name !== "Right Vert Flow") ||
        (header == "Direction of Flow" &&
          (row.finding_name == "Left Middle Vert" ||
            row.finding_name == "Right Vert Flow"))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (row.ultrasound_type_id == "5") {
      if (
        (header == "Stenosis" &&
          row.finding_name !== "Left Distal Common Femoral") ||
        (header == "Plaque" &&
          row.finding_name !== "Left Distal Common Femoral") ||
        (header == "Patency" &&
          row.finding_name !== "Left Distal Common Femoral") ||
        (header == "Wave" &&
          row.finding_name !== "Left Distal Common Femoral") ||
        (header == "Direction of Flow" &&
          row.finding_name !== "Left Distal Common Femoral")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (row.ultrasound_type_id == "15") {
      return false;
    } else if (row.ultrasound_type_id == "24") {
      return false;
    } else if (row.ultrasound_type_id == "37") {
      return true;
    } else if (row.ultrasound_type_id == "10") {
      return true;
    } else if (row.ultrasound_type_id == "11") {
      if (
        header == "Stenosis" &&
        (row.finding_name == "Proximal Aorta" ||
          row.finding_name == "Middle Aorta" ||
          row.finding_name == "Distal Aorta" ||
          row.finding_name == "Left Common Iliac Artery" ||
          row.finding_name == "Left Proximal Renal Artery" ||
          row.finding_name == "Left Middle Renal Artery" ||
          row.finding_name == "Left Distal Renal Artery" ||
          row.finding_name == "Right Common Iliac Artery" ||
          row.finding_name == "Right Proximal Renal Artery" ||
          row.finding_name == "Right Middle Renal Artery" ||
          row.finding_name == "Right Distal Renal Artery")
      ) {
        return true;
      } else if (
        header == "Patency" &&
        (row.finding_name == "Left Renal Vein" ||
          row.finding_name == "Right Renal Vein")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (row.ultrasound_type_id == "8") {
      if (
        header == "Stenosis" &&
        (row.finding_name == "Left Axillary" ||
          row.finding_name == "Left Brachial" ||
          row.finding_name == "Left Ulnar" ||
          row.finding_name == "Right Subclavian" ||
          row.finding_name == "Right Axillary" ||
          row.finding_name == "Right Brachial" ||
          row.finding_name == "Right Ulnar")
      ) {
        return true;
      } else if (
        header == "Plaque" &&
        (row.finding_name == "Left Axillary" ||
          row.finding_name == "Left Brachial" ||
          row.finding_name == "Left Ulnar" ||
          row.finding_name == "Right Subclavian" ||
          row.finding_name == "Right Axillary" ||
          row.finding_name == "Right Brachial" ||
          row.finding_name == "Right Ulnar")
      ) {
        return true;
      } else if (
        header == "Wave" &&
        (row.finding_name == "Left Axillary" ||
          row.finding_name == "Left Brachial" ||
          row.finding_name == "Left Ulnar" ||
          row.finding_name == "Right Subclavian" ||
          row.finding_name == "Right Axillary" ||
          row.finding_name == "Right Brachial" ||
          row.finding_name == "Right Ulnar")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (row.ultrasound_type_id == "13") {
      return false;
    } else {
      if (
        header == "Stenosis" ||
        header == "Plaque" ||
        header == "Patency" ||
        header == "Wave" ||
        header == "Direction of Flow"
      ) {
        return true;
      }
      return false;
    }
  };

  const checkUltraInput = (header, row) => {
    // true is show , false is not show
    if (row.ultrasound_type_id == "2") {
      if (
        header == "PSV/EDV" &&
        (row.finding_name !== "Left Middle Vert" ||
          row.finding_name !== "Right Vert Flow")
      ) {
        return true;
      }
      return false;
    } else if (row.ultrasound_type_id == "5") {
      if (
        header == "PSV/EDV" &&
        row.finding_name !== "Left Distal Common Femoral"
      ) {
        return true;
      }
      return false;
    } else if (row.ultrasound_type_id == "15") {
      if (
        header == "PSV/EDV" &&
        (row.finding_name == "Left Brachial" ||
          row.finding_name == "Right Brachial")
      ) {
        return true;
      } else if (
        header == "Diameter" &&
        (row.finding_name != "Left Brachial" ||
          row.finding_name != "Right Brachial")
      ) {
        return true;
      }
      return false;
    } else if (row.ultrasound_type_id == "24") {
      return true;
    } else if (row.ultrasound_type_id == "11") {
      if (
        header == "PSV/EDV" &&
        (row.finding_name == "Proximal Aorta" ||
          row.finding_name == "Middle Aorta" ||
          row.finding_name == "Left Hillar" ||
          row.finding_name == "Right Hillar" ||
          row.finding_name == "Distal Aorta" ||
          row.finding_name == "Left Common Iliac Artery" ||
          row.finding_name == "Left Proximal Renal Artery" ||
          row.finding_name == "Left Middle Renal Artery" ||
          row.finding_name == "Left Distal Renal Artery" ||
          row.finding_name == "Right Common Iliac Artery" ||
          row.finding_name == "Right Proximal Renal Artery" ||
          row.finding_name == "Right Middle Renal Artery" ||
          row.finding_name == "Right Distal Renal Artery")
      ) {
        return true;
      } else if (
        header == "Diameter Sagittal" &&
        (row.finding_name == "Proximal Aorta" ||
          row.finding_name == "Middle Aorta" ||
          row.finding_name == "Left Length of Kidney" ||
          row.finding_name == "Left Width of Kidney" ||
          row.finding_name == "Right Common Iliac Artery" ||
          row.finding_name == "Distal Aorta")
      ) {
        return true;
      } else if (
        header == "Diameter Transverse" &&
        (row.finding_name == "Proximal Aorta" ||
          row.finding_name == "Middle Aorta" ||
          row.finding_name == "Left Length of Kidney" ||
          row.finding_name == "Left Width of Kidney" ||
          row.finding_name == "Right Common Iliac Artery" ||
          row.finding_name == "Distal Aorta")
      ) {
        return true;
      } else if (
        header == "RAR" &&
        (row.finding_name == "Left Common Iliac Artery" ||
          row.finding_name == "Left Max RAR" ||
          row.finding_name == "Right Proximal Renal Artery" ||
          row.finding_name == "Right Middle Renal Artery" ||
          row.finding_name == "Right Distal Renal Artery" ||
          row.finding_name == "Right Max RAR")
      ) {
        return true;
      } else if (
        header == "RI" &&
        (row.finding_name == "Left Hillar" ||
          row.finding_name == "Right Proximal Renal Artery" ||
          row.finding_name == "Right Middle Renal Artery" ||
          row.finding_name == "Right Distal Renal Artery" ||
          row.finding_name == "Right Hillar")
      ) {
        return true;
      } else if (
        header == "Length" &&
        (row.finding_name == "Left Length of Kidney" ||
          row.finding_name == "Left Width of Kidney" ||
          row.finding_name == "Right Length of Kidney" ||
          row.finding_name == "Right Width of Kidney")
      ) {
        return true;
      }
      return false;
    } else if (row.ultrasound_type_id == "8") {
      if (
        header == "PSV/EDV" &&
        (row.finding_name == "Left Axillary" ||
          row.finding_name == "Left Brachial" ||
          row.finding_name == "Left Ulnar" ||
          row.finding_name == "Right Subclavian" ||
          row.finding_name == "Right Axillary" ||
          row.finding_name == "Right Brachial" ||
          row.finding_name == "Right Ulnar")
      ) {
        return true;
      } else if (
        header == "Index" &&
        (row.finding_name == "Right BBI" || row.finding_name == "Left BBI")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (row.ultrasound_type_id == "13") {
      if (
        header == "PSV/EDV" &&
        row.finding_name !== "Left Cephalic Vein" &&
        row.finding_name !== "Left Basilic Vein" &&
        row.finding_name !== "Right Cephalic Vein" &&
        row.finding_name !== "Right Basilic Vein"
      ) {
        return true;
      } else if (
        header == "Diameter" &&
        (row.finding_name == "Left Cephalic Vein" ||
          row.finding_name == "Left Basilic Vein" ||
          row.finding_name == "Right Cephalic Vein" ||
          row.finding_name == "Right Basilic Vein")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    dispatch(getUltrasounds());
  }, []);

  const sidebarRef = useRef();
  const isResource = !patientResource || !Object.keys(patientResource).length;
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <DataTable
            title="ultrasound-findings"
            handleClickRow={(row, index) => handleRow(index, row.id)}
            tableWidth={960}
            disableIcon={true}
            labels={["Label", ...(detailUltrasounds?.headers || [])] || []}
            widths={new Array(
              (["Label", ...(detailUltrasounds?.headers || [])] || []).length
            ).fill(
              (["Label", ...(detailUltrasounds?.headers || [])] || []).length >
                0
                ? `${100 /
                (["", ...(detailUltrasounds?.headers || [])] || []).length
                }%`
                : "100%"
            )}
            columns={[
              { sortable: false, key: "finding_name" },
              { sortable: false, key: "detail0" },
              { sortable: false, key: "detail1" },
              { sortable: false, key: "detail2" },
              { sortable: false, key: "detail3" },
              { sortable: false, key: "detail4" },
              { sortable: false, key: "detail5" },
              { sortable: false, key: "detail6" },
              { sortable: false, key: "detail7" },
            ]}
            sorts={["", "", "", "", "", "", "", "", ""]}
            rowData={(ultrasounds_store || []).map((row, index) => ({
              id: row.finding_id,
              origin_record: row,
              finding_name: row.finding_name,
              detail0:
                detailUltrasounds.headers &&
                  detailUltrasounds.headers.length >= 1 ? (
                  checkUltra(detailUltrasounds?.headers[0], row) ? (
                    <Select
                      suffixIcon={<ExpandSVG />}
                      value={
                        row[`${detailUltrasounds?.headers[0]}`] || undefined
                      }
                      onChange={(value) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[0]}`,
                          value,
                          index
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[0] == "Stenosis"
                        ? ["None", "Moderate", "Severe", "Not Identified"]
                        : detailUltrasounds?.headers[0] == "Plaque"
                          ? [
                            "Homogeneous",
                            "Heterogenous",
                            "Not Identified",
                            "None",
                          ]
                          : detailUltrasounds?.headers[0] == "Wave"
                            ? ["Normal", "Abnormal", "Not Identified"]
                            : detailUltrasounds?.headers[0] == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : detailUltrasounds?.headers[0] == "Compressability"
                                ? [
                                  "Compressible",
                                  "Partially Compressible",
                                  "Non-Compressible",
                                  "Not Seen",
                                ]
                                : ["Yes", "No"]
                      ).map((r, index) => (
                        <Select.Option key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[0], row) ==
                    true ? (
                    <Input
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={
                        row[`${detailUltrasounds?.headers[0]}`] || undefined
                      }
                      onChange={(e) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[0]}`,
                          e.target.value,
                          index
                        );
                      }}
                      disabled={is_locked}
                    />
                  ) : detailUltrasounds?.headers[0] == "PSV/EDV" &&
                    row.finding_name !== "Left Distal Common Femoral" &&
                    row.finding_name !== "Right Vert Flow" ? (
                    ""
                  ) : null
                ) : null,

              detail1:
                detailUltrasounds.headers &&
                  detailUltrasounds.headers.length >= 1 ? (
                  checkUltra(detailUltrasounds?.headers[1], row) ? (
                    <Select
                      suffixIcon={<ExpandSVG />}
                      value={
                        row[`${detailUltrasounds?.headers[1]}`] || undefined
                      }
                      onChange={(value) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[1]}`,
                          value,
                          index
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[1] == "Stenosis"
                        ? ["None", "Moderate", "Severe", "Not Identified"]
                        : detailUltrasounds?.headers[1] == "Plaque"
                          ? [
                            "Homogeneous",
                            "Heterogenous",
                            "Not Identified",
                            "None",
                          ]
                          : detailUltrasounds?.headers[1] == "Wave"
                            ? ["Normal", "Abnormal", "Not Identified"]
                            : detailUltrasounds?.headers[1] == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : detailUltrasounds?.headers[1] == "Compressability"
                                ? [
                                  "Compressible",
                                  "Partially Compressible",
                                  "Non-Compressible",
                                  "Not Seen",
                                ]
                                : ["Yes", "No"]
                      ).map((r, index) => (
                        <Select.Option key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[1], row) ==
                    true ? (
                    <Input
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={
                        row[`${detailUltrasounds?.headers[1]}`] || undefined
                      }
                      onChange={(e) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[1]}`,
                          e.target.value,
                          index
                        );
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail2:
                detailUltrasounds.headers &&
                  detailUltrasounds.headers.length > 2 ? (
                  checkUltra(detailUltrasounds?.headers[2], row) ? (
                    <Select
                      suffixIcon={<ExpandSVG />}
                      value={
                        row[`${detailUltrasounds?.headers[2]}`] || undefined
                      }
                      onChange={(value) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[2]}`,
                          value,
                          index
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[2] == "Stenosis"
                        ? ["None", "Moderate", "Severe", "Not Identified"]
                        : detailUltrasounds?.headers[2] == "Plaque"
                          ? [
                            "Homogeneous",
                            "Heterogenous",
                            "Not Identified",
                            "None",
                          ]
                          : detailUltrasounds?.headers[2] == "Wave"
                            ? ["Normal", "Abnormal", "Not Identified"]
                            : detailUltrasounds?.headers[2] == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : detailUltrasounds?.headers[2] == "Compressability"
                                ? [
                                  "Compressible",
                                  "Partially Compressible",
                                  "Non-Compressible",
                                  "Not Seen",
                                ]
                                : ["Yes", "No"]
                      ).map((r, index) => (
                        <Select.Option key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[2], row) ==
                    true ? (
                    <Input
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={
                        row[`${detailUltrasounds?.headers[2]}`] || undefined
                      }
                      onChange={(e) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[2]}`,
                          e.target.value,
                          index
                        );
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail3:
                detailUltrasounds.headers &&
                  detailUltrasounds.headers.length > 3 ? (
                  checkUltra(detailUltrasounds?.headers[3], row) ? (
                    <Select
                      suffixIcon={<ExpandSVG />}
                      value={
                        row[`${detailUltrasounds?.headers[3]}`] || undefined
                      }
                      onChange={(value) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[3]}`,
                          value,
                          index
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[3] == "Stenosis"
                        ? ["None", "Moderate", "Severe", "Not Identified"]
                        : detailUltrasounds?.headers[3] == "Plaque"
                          ? [
                            "Homogeneous",
                            "Heterogenous",
                            "Not Identified",
                            "None",
                          ]
                          : detailUltrasounds?.headers[3] == "Wave"
                            ? ["Normal", "Abnormal", "Not Identified"]
                            : detailUltrasounds?.headers[3] == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : detailUltrasounds?.headers[3] == "Compressability"
                                ? [
                                  "Compressible",
                                  "Partially Compressible",
                                  "Non-Compressible",
                                  "Not Seen",
                                ]
                                : ["Yes", "No"]
                      ).map((r, index) => (
                        <Select.Option key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[3], row) ==
                    true ? (
                    <Input
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={
                        row[`${detailUltrasounds?.headers[3]}`] || undefined
                      }
                      onChange={(e) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[3]}`,
                          e.target.value,
                          index
                        );
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail4:
                detailUltrasounds.headers &&
                  detailUltrasounds.headers.length > 4 ? (
                  checkUltra(detailUltrasounds?.headers[4], row) ? (
                    <Select
                      suffixIcon={<ExpandSVG />}
                      value={
                        row[`${detailUltrasounds?.headers[4]}`] || undefined
                      }
                      onChange={(value) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[4]}`,
                          value,
                          index
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[4] == "Stenosis"
                        ? ["None", "Moderate", "Severe", "Not Identified"]
                        : detailUltrasounds?.headers[4] == "Plaque"
                          ? [
                            "Homogeneous",
                            "Heterogenous",
                            "Not Identified",
                            "None",
                          ]
                          : detailUltrasounds?.headers[4] == "Wave"
                            ? ["Normal", "Abnormal", "Not Identified"]
                            : detailUltrasounds?.headers[4] == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : detailUltrasounds?.headers[4] == "Compressability"
                                ? [
                                  "Compressible",
                                  "Partially Compressible",
                                  "Non-Compressible",
                                  "Not Seen",
                                ]
                                : ["Yes", "No"]
                      ).map((r, index) => (
                        <Select.Option key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[4], row) ==
                    true ? (
                    <Input
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={
                        row[`${detailUltrasounds?.headers[4]}`] || undefined
                      }
                      onChange={(e) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[4]}`,
                          e.target.value,
                          index
                        );
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail5:
                detailUltrasounds.headers &&
                  detailUltrasounds.headers.length > 5 ? (
                  checkUltra(detailUltrasounds?.headers[5], row) ? (
                    <Select
                      suffixIcon={<ExpandSVG />}
                      value={
                        row[`${detailUltrasounds?.headers[5]}`] || undefined
                      }
                      onChange={(value) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[5]}`,
                          value,
                          index
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[5] == "Stenosis"
                        ? ["None", "Moderate", "Severe", "Not Identified"]
                        : detailUltrasounds?.headers[5] == "Plaque"
                          ? [
                            "Homogeneous",
                            "Heterogenous",
                            "Not Identified",
                            "None",
                          ]
                          : detailUltrasounds?.headers[5] == "Wave"
                            ? ["Normal", "Abnormal", "Not Identified"]
                            : detailUltrasounds?.headers[5] == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : detailUltrasounds?.headers[5] == "Compressability"
                                ? [
                                  "Compressible",
                                  "Partially Compressible",
                                  "Non-Compressible",
                                  "Not Seen",
                                ]
                                : ["Yes", "No"]
                      ).map((r, index) => (
                        <Select.Option key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[5], row) ==
                    true ? (
                    <Input
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={
                        row[`${detailUltrasounds?.headers[5]}`] || undefined
                      }
                      onChange={(e) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[5]}`,
                          e.target.value,
                          index
                        );
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail6:
                detailUltrasounds.headers &&
                  detailUltrasounds.headers.length > 6 ? (
                  checkUltra(detailUltrasounds?.headers[6], row) ? (
                    <Select
                      suffixIcon={<ExpandSVG />}
                      value={
                        row[`${detailUltrasounds?.headers[6]}`] || undefined
                      }
                      onChange={(value) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[6]}`,
                          value,
                          index
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[6] == "Stenosis"
                        ? ["None", "Moderate", "Severe", "Not Identified"]
                        : detailUltrasounds?.headers[6] == "Plaque"
                          ? [
                            "Homogeneous",
                            "Heterogenous",
                            "Not Identified",
                            "None",
                          ]
                          : detailUltrasounds?.headers[6] == "Wave"
                            ? ["Normal", "Abnormal", "Not Identified"]
                            : detailUltrasounds?.headers[6] == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : detailUltrasounds?.headers[6] == "Compressability"
                                ? [
                                  "Compressible",
                                  "Partially Compressible",
                                  "Non-Compressible",
                                  "Not Seen",
                                ]
                                : ["Yes", "No"]
                      ).map((r, index) => (
                        <Select.Option key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[6], row) ==
                    true ? (
                    <Input
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={
                        row[`${detailUltrasounds?.headers[6]}`] || undefined
                      }
                      onChange={(e) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[6]}`,
                          e.target.value,
                          index
                        );
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail7:
                detailUltrasounds.headers &&
                  detailUltrasounds.headers.length > 7 ? (
                  checkUltra(detailUltrasounds?.headers[7], row) ? (
                    <Select
                      suffixIcon={<ExpandSVG />}
                      value={
                        row[`${detailUltrasounds?.headers[7]}`] || undefined
                      }
                      onChange={(value) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[7]}`,
                          value,
                          index
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[7] == "Stenosis"
                        ? ["None", "Moderate", "Severe", "Not Identified"]
                        : detailUltrasounds?.headers[7] == "Plaque"
                          ? [
                            "Homogeneous",
                            "Heterogenous",
                            "Not Identified",
                            "None",
                          ]
                          : detailUltrasounds?.headers[7] == "Wave"
                            ? ["Normal", "Abnormal", "Not Identified"]
                            : detailUltrasounds?.headers[7] == "Direction of Flow"
                              ? ["Antegrade", "Retrograde"]
                              : detailUltrasounds?.headers[7] == "Compressability"
                                ? [
                                  "Compressible",
                                  "Partially Compressible",
                                  "Non-Compressible",
                                  "Not Seen",
                                ]
                                : ["Yes", "No"]
                      ).map((r, index) => (
                        <Select.Option key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[7], row) ==
                    true ? (
                    <Input
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={
                        row[`${detailUltrasounds?.headers[7]}`] || undefined
                      }
                      onChange={(e) => {
                        setOnChange(
                          `${detailUltrasounds?.headers[7]}`,
                          e.target.value,
                          index
                        );
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
            }))}
            defaultActive={-1}
            disableDelete={true}
          />
        </div>
      </div>
      {detail_ultrasound_result &&
        Object.keys(detail_ultrasound_result).length > 0 && (
          <React.Fragment>
            <ClinicWorkflowSidebar
              ref={sidebarRef}
              isFinding={true}
              detail_ultrasound_result={detail_ultrasound_result}
              resource={patientResource}
              isShow={isSidebar}
              handleCloseSidebar={handleCloseSidebar}
              referralConditions={conditions || []}
              isNormal={true}
              formSteps={formSteps}
              initialConditions={initialConditions}
              disableIDImage={true}
              hasSubHeader={true}
              sidebarIcon={(<Icon type="funnel-plot" theme="twoTone" twoToneColor='#740937'/>)}
              sidebarTitle={
                detailUltrasounds && detailUltrasounds.name
                  ? detailUltrasounds.name
                  : "ULTRASOUND FINDINGS"
              }
            />
            {!isResource && (
              <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
            )}
          </React.Fragment>
        )}
    </div>
  );
};

export default UltrasoundFindings;
