import React from 'react';
import { useDispatch } from 'react-redux';
import { Input, DatePicker } from 'antd';
import { ExpandSVG, SearchSVG } from '../../../common/components/icons/SVGIcon';
import { getProcedurePending, setProcedureData } from '../../../store/actions';

const { Search } = Input;
const dateFormat = 'MM/DD/YYYY';

const PendingFilters = ({handleFilter, handleSearch}) => {
  const dispatch = useDispatch();

  const onChangeDate = (date, dateString) => {
    dispatch(setProcedureData('loadingPending', true));
    dispatch(getProcedurePending({procedure_date: dateString}));
  };

  const changeSearch = (value) => {
    handleSearch(value);
  };

  return (
    <div className='filterContainer'>
      <div className='searchFieldContainer'>
        <div className='searchField'>
          <Search
            placeholder="Search"
            onChange={e => changeSearch(e.target.value)}
            style={{ width: '100%' }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingFilters;
