import React, { useState, useMemo } from "react";
import {
  changeTab,
  add_plan_me,
  chooseSelectedPatientResource,
  selectPatient,
  patientExaminationLoadInit,
  clinicProviderPatientSnapshotLoadInit,
  getClinicWorkflowAppointments,
  setPatientSnapshotData,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const ExtendDataTable = ({
  title,
  data,
  disableHeader,
  options,
  widths,
  minRows,
  onClickRow,
  isActiveBorder = false,
}) => {
  const dispatch = useDispatch();
  const [activeIndex, setActive] = useState(-1);
  const result_meaningful_uses = useSelector(
    (state) => state.clinicProviderReview.result_meaningful_uses
  );
  const patient_id = cookies.get("current_patient_id");
  const current_appointment_id = cookies.get("current_appointment_id");
  const emptyRows = useMemo(() => {
    const rowCount = minRows ? minRows : 4;
    if (data && data.length) {
      const numberOfRows = data.length > rowCount ? 0 : rowCount - data.length;
      return new Array(numberOfRows).fill("empty");
    }
    return new Array(rowCount).fill("empty");
  }, [data]);
  const handle_action = async (row) => {
    const result = await dispatch(selectPatient(patient_id));
    await dispatch(setPatientSnapshotData("resource", { ...result }));
    if (row["tab"] && row["tab"] == "plan_order") {
      const save_success = await dispatch(
        add_plan_me(row["appointment_id"] ? row["appointment_id"] : "", {
          meaningful_use_id: row["id"] ? row["id"] : "",
        })
      );
      if (save_success && save_success.result && save_success.result == true) {
        await dispatch(
          changeTab({
            tabActive: row["tab"] ? row["tab"] : "",
            accordion: row["accordion"] ? row["accordion"] : "",
            step: row["step"] ? row["step"] : "",
          })
        );
      }
    } else {
      await dispatch(
        changeTab({
          tabActive: row["tab"] ? row["tab"] : "",
          accordion: row["accordion"] ? row["accordion"] : "",
          step: row["step"] ? row["step"] : "",
        })
      );
    }
  };

  return (
    <div className="subDataTable">
      {!disableHeader && (
        <div className="subDataTable__title" style={{ padding: "0" }}>
          {title}
        </div>
      )}
      {(data || []).map((row, index) => (
        <div
          className={`subDataTable__item ${
            activeIndex === index ? "active" : ""
          } ${row.row_color_status ? row.row_color_status : ""} ${
            isActiveBorder ? (row.row_color_status ? row.row_color_status + '-border' : "blue-border") : ""
          }`}
          key={`item-${row.item}-${index}-${row.key}`}
          onClick={() => {
            setActive(index);
            if (onClickRow) {
              onClickRow(index);
            }
          }}
        >
          {(options || []).map((keyOption, index) => (
            <span
              key={keyOption}
              style={{
                width: "100%",
                maxWidth: `${
                  widths && widths[index]
                    ? widths[index]
                    : "calc(100% / " + options.length + ")"
                }`,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                paddingRight: "1px",
              }}
            >
              {keyOption == "recommendation" ? (
                <p
                  onClick={() => {
                    handle_action(row);
                  }}
                  style={{ color: "#305AA2" }}
                >
                  {row[keyOption]}
                </p>
              ) : (
                row[keyOption]
              )}
            </span>
          ))}
        </div>
      ))}
      {(emptyRows || []).map((row, index) => (
        <div className={`subDataTable__item`} key={`item-${row}-${index}`} />
      ))}
    </div>
  );
};

export default ExtendDataTable;
