import React, { useState } from 'react';
import { DatePicker } from 'antd';
import { ExpandSVG } from '../../../../common/components/icons/SVGIcon';

const dateFormat = 'MM/DD/YYYY';

const HoursFilter = ({}) => {
  return (
    <div className="filterContainer">
      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: '180px', marginLeft: '0' }}>
          <DatePicker
            format={dateFormat}
            placeholder="Filter by Pay Period..."
            suffixIcon={<ExpandSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default HoursFilter;
