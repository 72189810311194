export const newPatientOptions = {
  demographics: [
    { value: "first_name", type: "default" },
    { value: "last_name", type: "default" },
    { value: "date_of_birth", type: "date", format: "MM/DD/YYYY" },
    { value: "phone", type: "phone" },
    { value: "zipcode", type: "default" },
    { value: "gender", type: "default" },
    { value: "referral_condition", type: "default" },
    { value: "address", type: "default" },
    { value: "state", type: "default" },
    { value: "city", type: "default" },
    { value: "referral_date", type: "default" },
  ],
  insurance: [{ value: "policy_number", type: "default" }],
  medical: [
    { value: "previous_medical_history", type: "array" },
    { value: "previous_surgical_history", type: "array" },
    { value: "medications", type: "array" },
    { value: "allergies", type: "array" },
    { value: "flu_vaccination", type: "check" },
    { value: "pneumococcal", type: "check" },
    { value: "aortic_disease", type: "check" },
    { value: "amputation", type: "check" },
    { value: "heart_disease", type: "check" },
    { value: "smoking_ppd", type: "default" },
    { value: "alcoholic_drink_pw", type: "default" },
  ],
  questions: [
    { value: "leg_swelling", type: "check" },
    { value: "smoked_cigarettes", type: "check" },
    { value: "history_aneurysm", type: "check" },
    { value: "suffered_dvt", type: "check" },
    { value: "leg_pain", type: "check" },
    { value: "stroke_symptoms", type: "check" },
  ],
  schedule: [],
  communication: [],
  attempt: [],
};

export const newCheckInOption = {
  demographics: [
    { value: "first_name", type: "default" },
    { value: "last_name", type: "default" },
    { value: "date_of_birth", type: "date", format: "MM/DD/YYYY" },
    { value: "phone_1", type: "phone" },
    { value: "phone_2", type: "phone" },
    { value: "email", type: "email" },
    { value: "ssn", type: "default" },
    { value: "address", type: "default" },
    { value: "apt", type: "default" },
    { value: "state", type: "default" },
    { value: "zipcode", type: "default" },
    { value: "referral_condition", type: "default" },
    // { value: "primary_care_provider_id", type: "default" },
    // { value: "referral_provider_id", type: "default" },
  ],
  insurance: [
    { value: "medical_id", type: "default" },
    { value: "policy_number", type: "default" },
  ],
  HIPAA: [{ value: "hipaa", type: "array" }],
  notes: [{ value: "notes", type: "array" }],
};

export const stateOptions = [
  { id: "AL", value: "Alabama" },
  { id: "AK", value: "Alaska" },
  { id: "AZ", value: "Arizona" },
  { id: "AR", value: "Arkansas" },
  { id: "CA", value: "California" },
  { id: "CO", value: "Colorado" },
  { id: "CT", value: "Connecticut" },
  { id: "DE", value: "Delaware" },
  { id: "DC", value: "District Of Columbia" },
  { id: "FL", value: "Florida" },
  { id: "GA", value: "Georgia" },
  { id: "HI", value: "Hawaii" },
  { id: "ID", value: "Idaho" },
  { id: "IL", value: "Illinois" },
  { id: "IN", value: "Indiana" },
  { id: "IA", value: "Iowa" },
  { id: "KS", value: "Kansas" },
  { id: "KY", value: "Kentucky" },
  { id: "LA", value: "Louisiana" },
  { id: "ME", value: "Maine" },
  { id: "MD", value: "Maryland" },
  { id: "MA", value: "Massachusetts" },
  { id: "MI", value: "Michigan" },
  { id: "MN", value: "Minnesota" },
  { id: "MS", value: "Mississippi" },
  { id: "MO", value: "Missouri" },
  { id: "MT", value: "Montana" },
  { id: "NE", value: "Nebraska" },
  { id: "NV", value: "Nevada" },
  { id: "NH", value: "New Hampshire" },
  { id: "NJ", value: "New Jersey" },
  { id: "NM", value: "New Mexico" },
  { id: "NY", value: "New York" },
  { id: "NC", value: "North Carolina" },
  { id: "ND", value: "North Dakota" },
  { id: "OH", value: "Ohio" },
  { id: "OK", value: "Oklahoma" },
  { id: "OR", value: "Oregon" },
  { id: "PA", value: "Pennsylvania" },
  { id: "RI", value: "Rhode Island" },
  { id: "SC", value: "South Carolina" },
  { id: "SD", value: "South Dakota" },
  { id: "TN", value: "Tennessee" },
  { id: "TX", value: "Texas" },
  { id: "UT", value: "Utah" },
  { id: "VT", value: "Vermont" },
  { id: "VA", value: "Virginia" },
  { id: "WA", value: "Washington" },
  { id: "WV", value: "West Virginia" },
  { id: "WI", value: "Wisconsin" },
  { id: "WY", value: "Wyoming" },
];

export const newSnapshotOption = {
  demographics: [
    { value: "first_name", type: "default" },
    { value: "last_name", type: "default" },
    { value: "date_of_birth", type: "date", format: "MM/DD/YYYY" },
    { value: "phone", type: "phone" },
    { value: "zipcode", type: "default" },
    { value: "referral_condition", type: "default" },
    { value: "address", type: "default" },
    { value: "state", type: "default" },
    { value: "city", type: "default" },
  ],
  insurance: [],
  medical: [
    { value: "previous_medical_history", type: "array" },
    { value: "previous_surgical_history", type: "array" },
    { value: "medications", type: "array" },
    { value: "allergies", type: "array" },
    { value: "flu_vaccination", type: "check" },
    { value: "pneumococcal", type: "check" },
    { value: "aortic_disease", type: "check" },
    { value: "amputation", type: "check" },
    { value: "heart_disease", type: "check" },
    { value: "smoking_ppd", type: "default" },
    { value: "alcoholic_drink_pw", type: "default" },
  ],
};
