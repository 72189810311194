import { createReducer } from "redux-create-reducer";
import moment from "moment";

import AT from "../actionTypes";

const initialState = {
  resource: null,
  isUpdated: false,
  isVerifyInsurance: false,
  isVerified: false,
  hasInsurance: null,
  errorMessage: "",
  // Sidebar
  step: 0,
  demographics: {},
  insurance: {},
  medical: {},
  loadedSnapshot: false,
  loadingUpdateMedical: false,
};

const SET_PATIENT_SNAPSHOT_DATA_SUCCEEDED = `${AT.SET_PATIENT_SNAPSHOT_DATA}_SUCCEEDED`;
const UPDATE_MEDICAL_HISTORY_SUCCEEDED = `${AT.UPDATE_MEDICAL_HISTORY}_SUCCEEDED`;
const UPDATE_MEDICAL_HISTORY_STARTED = `${AT.UPDATE_MEDICAL_HISTORY}_STARTED`;
const SET_FIRST_LOADED_SNAPSHOT_SUCCEEDED = `${AT.SET_FIRST_LOADED_SNAPSHOT}_SUCCEEDED`;

export default createReducer(initialState, {
  [UPDATE_MEDICAL_HISTORY_STARTED](state, action) {
    return Object.assign({}, state, { loadingUpdateMedical: true });
  },
  [UPDATE_MEDICAL_HISTORY_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      resource: action.payload,
      loadingUpdateMedical: false,
    });
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [SET_PATIENT_SNAPSHOT_DATA_SUCCEEDED](state, action) {
    console.log("set payload", action.payload.resource);
    return Object.assign({}, state, { resource: action.payload?.resource });
  },
  [SET_FIRST_LOADED_SNAPSHOT_SUCCEEDED](state, action) {
    return Object.assign({}, state, { loadedSnapshot: true });
  },
});
