/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Tooltip } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useHover } from "../../utils";
import './style.scss';

function ImageFile({
  resource,
  fileName,
  fileDoubleClicked,
  customStyle,
  onRemoveBtnClicked,
}) {
  const [hoverRef, isHovered] = useHover();

  const handlDoubleClick = () => {
    fileDoubleClicked(resource);
  };

  const handleRemoveItem = () => {
    onRemoveBtnClicked(resource.id);
  };

  return (
    <Tooltip placement="top" title={fileName}>
      <div
        ref={hoverRef}
        className="image-file-wrapper"
        onDoubleClick={handlDoubleClick}
        style={{ whiteSpace: "nowrap" }}
      >
        <img className={customStyle} src={resource.url} />
        {customStyle !== "history-image-file" &&
          <AiOutlineCloseCircle
            className="remove-item"
            onClick={handleRemoveItem}
            style={isHovered ? { display: "block" } : { display: "none" }}
          />
        }
      </div>
    </Tooltip>
  );
}

export default ImageFile;
