import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const get_meaningful_statistics = createActionThunk(
  AT.GET_MEANINGFUL_STATISTICS,
  (params) => API.get_meaningful_statistics(params)
);

export const getDeficiencyList = createActionThunk(
    AT.GET_DEFICIENCY_LIST,
    (params) => API.getDeficiencyList(params)
);

export const bulkFinalize = createActionThunk(
    AT.BULK_FINALIZE,
    () => API.bulkFinalize()
);