import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import DataTable from "../../../../common/components/DataTable/DataTable";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import { getDetailPolicies, getPolicies } from "../../../../store/actions";
import ManualPoliciesSidebar from "./ManualPoliciesSidebar";
import { StaticResource } from "../../constant";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const ManualPolicies = ({ loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);

  const policies = useSelector((state) => state.common.policies);
  const policiesDetail = useSelector((state) => state.common.policiesDetail);
  const tableRows = policies ? policies : [];

  const dispatch = useDispatch();

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  }

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (row, index) => {
    if (activeIndex != index) {
      setRow(index);
      dispatch(getDetailPolicies(row.id));
      setIsSidebar(true);
      
    }
  };

  const fetchList = () => {
    dispatch(getPolicies());
  };

  const fetchDetail = () => {
    if (policies.length > 0) {
      dispatch(getDetailPolicies(policies[0].id));
    }
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_manual_policies"
                labels={["Policy", "Title", "Updated"]}
                widths={["25%", "50%", "25%"]}
                columns={[
                  { sortable: false, key: "policy_number" },
                  { sortable: false, key: "policy_name" },
                  { sortable: false, key: "effective_date" },
                ]}
                sorts={["","",""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row.id,
                  origin: row,
                  policy_number: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ minWidth: "26px" }} />
                      <p>{row.policy_number}</p>
                    </div>
                  ),
                  policy_name: row.policy_name,
                  effective_date: row.effective_date &&
                    moment(row.effective_date).format("MM/DD/YY"),
                }))}
                fetchList={fetchList}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {activeIndex >= 0 && policiesDetail && Object.keys(policiesDetail).length > 0 && (
        <ManualPoliciesSidebar
          ref={sidebarRef}
          policiesDetail={policiesDetail}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
      {activeIndex >= 0 && policiesDetail && Object.keys(policiesDetail).length > 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default ManualPolicies;
