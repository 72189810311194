import React, { useState, useEffect } from "react";
import { Input, Icon } from "antd";
import { useSelector } from "react-redux";
import { CloseIconSVG } from "../../../../common/components/icons/SVGIcon";

let timer = null;
const ExaminationROS = ({ type, onChangeData, isLocked }) => {
  const originalData = useSelector((state) =>
    type === "ROS"
      ? state.patientExamination.ROSs
      : state.patientExamination.physical_examinations
  );

  const all_check = useSelector((state) =>
    type === "ROS"
      ? state.patientExamination.all_normal_ros
      : state.patientExamination.all_normal_exam
  );

  const [data, setData] = useState([]);
  const [dataUncheck, setDataUncheck] = useState([]);
  const [fisrtData, setFisrtData] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [valueCheck, setValuesCheck] = useState({});
  const [dataMap, setDataMap] = useState([]);
  const [fisrtDataRos, setFisrtDataRos] = useState(false);
  const [dataMapRos, setDataMapRos] = useState([]);
  const [comment, setComment] = useState("");
  const [commentRos, setCommentRos] = useState("");

  useEffect(() => {
    setData(originalData);
    setDataUncheck(originalData);
  }, [originalData]);

  useEffect(() => {
    if (
      !fisrtData &&
      originalData &&
      originalData.length > 0 &&
      type !== "ROS"
    ) {
      setDataMap(originalData);
      setFisrtData(true);
    }
  }, [originalData]);

  useEffect(() => {
    if (
      !fisrtDataRos &&
      originalData &&
      originalData.length > 0 &&
      type === "ROS"
    ) {
      setDataMapRos(originalData);
      setFisrtDataRos(true);
    }
  }, [originalData]);

  const onChangeComments = (index, comments) => {
    let data_ = [...data];
    data_[index]["comments"] = comments;
    setActiveRow(index);
    onChangeData(data_);
  };

  useEffect(() => {
    let commentsCheck = "";
    if (all_check == 1 || all_check == true) {
      if (
        dataMapRos[activeRow] &&
        dataMapRos[activeRow]["values"] &&
        dataMapRos[activeRow]["values"].length > 0 &&
        type === "ROS"
      ) {
        commentsCheck =
          dataMapRos[activeRow].comments ==
            dataMapRos[activeRow].default_comments ||
          dataMapRos[activeRow].comments == null
            ? dataMapRos[activeRow].default_comments
            : dataMapRos[activeRow].comments;

        for (const key in dataMapRos[activeRow]["values"]) {
          if (dataMapRos[activeRow]["values"].hasOwnProperty(key)) {
            const element = dataMapRos[activeRow]["values"][key];
            //fix comment option
            if (element.checked == true) {
              commentsCheck = commentsCheck.replace(
                element.replaced_value,
                element.option
              );
            } else {
              commentsCheck = commentsCheck.replace(
                element.option,
                element.replaced_value
              );
            }
          }
        }
        if (commentsCheck.includes("No No")) {
          const check = commentsCheck.split("No No");
          if (check) {
            check.map((r) => {
              commentsCheck = commentsCheck.replace("No No", "No");
            });
          }
        }
        if (commentsCheck.includes("Not Not")) {
          const check = commentsCheck.split("Not Not");
          if (check) {
            check.map((r) => {
              commentsCheck = commentsCheck.replace("Not Not", "Not");
            });
          }
        }
        setCommentRos(commentsCheck);
      }
    } else if (all_check == 0 || !all_check) {
      if (
        dataMapRos[activeRow] &&
        dataMapRos[activeRow]["values"] &&
        dataMapRos[activeRow]["values"].length > 0 &&
        type === "ROS"
      ) {
        commentsCheck = "";
        setCommentRos("");
        for (const key in dataMapRos[activeRow]["values"]) {
          if (dataMapRos[activeRow]["values"].hasOwnProperty(key)) {
            const element = data[activeRow]["values"][key];
            if (element.checked == true) {
              if (!commentsCheck.includes(`${element.option} `)) {
                commentsCheck += `${element.option}, `;
              }
            } else {
              commentsCheck = commentsCheck.replace(`${element.option}, `, "");
            }
          }
        }
        setCommentRos(commentsCheck);
      }
    }
  }, [dataMapRos, activeRow, valueCheck, all_check, type]);

  useEffect(() => {
    // fix change comment
    if (all_check == 1) {
      let data_ = [...originalData];
      for (const key in data_) {
        if (data_.hasOwnProperty(key)) {
          const element = data_[key];
          if (
            data_[key]["comments"] !== "" &&
            data_[key]["comments"] !== null
          ) {
            data_[key]["comments"] = data_[key]["comments"];
          } else {
            data_[key]["comments"] = data_[key]["default_comments"];
          }
        }
      }
      setData(data_);
    } else {
      let data_ = [...originalData];
      for (const key in data_) {
        if (data_.hasOwnProperty(key)) {
          const element = data_[key];
          data_[key]["comments"] = "";
        }
      }
      setData(data_);
    }
  }, [all_check, originalData]);

  useEffect(() => {
    if (type === "ROS") {
      let data_ = [...data];
      if (data_[activeRow]) {
        data_[activeRow]["comments"] = commentRos;
        setData(data_);
      }
    }
  }, [commentRos, type]);

  useEffect(() => {
    if (all_check == 1 || all_check == true) {
      if (
        dataMap[activeRow] &&
        dataMap[activeRow]["values"] &&
        dataMap[activeRow]["values"].length > 0 &&
        type !== "ROS"
      ) {
        let commentsCheck =
          dataMap[activeRow].comments == dataMap[activeRow].default_comments ||
          dataMap[activeRow].comments == null
            ? dataMap[activeRow].default_comments
            : dataMap[activeRow].comments;

        for (const key in dataMap[activeRow]["values"]) {
          if (dataMap[activeRow]["values"].hasOwnProperty(key)) {
            const element = data[activeRow]["values"][key];
            if (element.checked == true) {
              commentsCheck = commentsCheck.replace(
                element.replaced_value,
                element.option
              );
            }
          }
        }
        setComment(commentsCheck);

        for (const key in dataMap[activeRow]["values"]) {
          if (dataMap[activeRow]["values"].hasOwnProperty(key)) {
            const element = data[activeRow]["values"][key];
            if (element.checked == false) {
              commentsCheck = commentsCheck.replace(
                element.option,
                element.replaced_value
              );
            }
          }
        }
        if (commentsCheck.includes("No No")) {
          const check = commentsCheck.split("No No");
          if (check) {
            check.map((r) => {
              commentsCheck = commentsCheck.replace("No No", "No");
            });
          }
        }
        if (commentsCheck.includes("Not Not")) {
          const check = commentsCheck.split("Not Not");
          if (check) {
            check.map((r) => {
              commentsCheck = commentsCheck.replace("Not Not", "Not");
            });
          }
        }
        setComment(commentsCheck);
      }
    } else if (all_check == 0 || !all_check) {
      if (
        dataMap[activeRow] &&
        dataMap[activeRow]["values"] &&
        dataMap[activeRow]["values"].length > 0 &&
        type !== "ROS"
      ) {
        let commentsCheck = "";
        for (const key in dataMap[activeRow]["values"]) {
          if (dataMap[activeRow]["values"].hasOwnProperty(key)) {
            const element = data[activeRow]["values"][key];
            if (element.checked == true) {
              if (!commentsCheck.includes(`${element.option} `)) {
                commentsCheck += `${element.option}, `;
              }
            } else {
              commentsCheck = commentsCheck.replace(`${element.option}, `, "");
            }
          }
        }
        setComment(commentsCheck);
      }
    }
  }, [activeRow, dataMap, valueCheck, all_check, type]);

  useEffect(() => {
    let data_ = [...data];
    if (data_[activeRow]) {
      data_[activeRow]["comments"] = comment;
      setData(data_);
    }
  }, [comment]);

  const onChangeValues = async (index, values, item) => {
    let data_ = [...data];
    setActiveRow(index);
    setValuesCheck(values);
    data_[index]["values"] = values;
    await onChangeData(data_);
  };

  return (
    <React.Fragment>
      {data.map((item, index) => {
        return (
          <ROSItem
            key={`examination-item-${index}`}
            title={item.type}
            setActiveRow={setActiveRow}
            index={index}
            comments={item.comments}
            values={item.values}
            onChangeComments={(comments) => onChangeComments(index, comments)}
            onChangeValues={(values) => onChangeValues(index, values, item)}
            isLocked={isLocked}
          />
        );
      })}
    </React.Fragment>
  );
};

const ROSItem = (props) => {
  const [isCommentEditing, setCommentEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [values, setValues] = useState([]);
  const [comments, setComments] = useState("");

  useEffect(() => {
    setTitle(props.title);
    setComments(props.comments);
    setValues(props.values);
    console.log(props.isLocked, "from each item");
  }, [props.comments, props.values]);

  const openCommentEditBox = () => {
    setCommentEditing(!isCommentEditing);
  };

  const onClickOption = (index) => {
    if (props.isLocked === 0) {
      let values_ = [...values];
      values_[index]["checked"] = !values_[index]["checked"];
      props.onChangeValues(values_);
      setValues(values_);
    }
  };

  const onBlurComments = () => {
    props.onChangeComments(comments);
  };

  return (
    <div className={`ExaminationROSItem`}>
      <div className="ExaminationROSItem__label">{title}</div>
      <div className={`ExaminationROSItem__options`}>
        <div
          className={`ExaminationROSItem__icon ${
            isCommentEditing ? "open" : ""
          }`}
          onClick={() => {
            openCommentEditBox();
            props.setActiveRow(props.index);
          }}
        >
          <Icon type="edit" />
        </div>
        {(values || []).map((opt, index) => (
          <button
            tabIndex="1"
            key={`ros-item-${title}-${index}`}
            className={`ExaminationROSItem__option ${opt.checked && "active"} ${
              props.isLocked === 0 ? "not-locked" : ""
            }`}
            onClick={() => onClickOption(index)}
          >
            {opt.option}
          </button>
        ))}
      </div>

      <div
        className="ExaminationROSItem__box"
        onBlur={() => setCommentEditing(false)}
        style={{
          display: isCommentEditing ? "inline-block" : "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "5px",
            top: "5px",
            zIndex: 10,
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
          onClick={() => setCommentEditing(false)}
        >
          <CloseIconSVG />
        </div>
        <Input.TextArea
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          onBlur={() => onBlurComments()}
        />
      </div>
    </div>
  );
};

export default ExaminationROS;
