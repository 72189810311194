import React, { useEffect, useState } from "react";

const HPISnapList = ({ list }) => {
  const [conclusion, setConclusion] = useState([]);
  const [finding, setFinding] = useState([]);

  useEffect(() => {
    let Dataconclusion = {};
    let Arayconclusion = [];
    let Datafinding = {};
    let Arayfinding = [];

    if (list && list.length > 0) {
      (list || []).map((item, indexItem) => {
        Arayconclusion = [];
        Arayfinding = [];
        if (item.values && item.values.length > 0) {
          (item.values || []).map((value) => {
            if (value.field === "conclusion") {
              Arayconclusion.push(value);
              Dataconclusion = {
                ...Dataconclusion,
                [`conclusion${indexItem}`]: [...Arayconclusion],
              };
            } else if (value.field === "finding") {
              Arayfinding.push(value);
              Datafinding = {
                ...Datafinding,
                [`finding${indexItem}`]: [...Arayfinding],
              };
            }
          });
        }
      });
    }
    setConclusion(Dataconclusion);
    setFinding(Datafinding);
  }, [list]);

  return (
    <div className="hpiSnapList">
      {list.map((snap, index) => (
        <div className="snapItem" key={`snap-${index}`}>
          <div className="snapItem__status">
            {snap.type === "ultrasound" && (
              <div className="snapIcon red">{index + 1}</div>
            )}
            {snap.type === "procedure" && (
              <div className="snapIcon yellow">{index + 1}</div>
            )}
          </div>
          <div className="snapItem__content">
            <div className="snapDetail__titlebar">
              <div className="snapDetail__date">{snap.date}</div>
              <div className="snapDetail__title">{snap.title}</div>
            </div>
            <div className="snapDetail__descrption">
              <div className="snapDetail__content-block">
                <div
                  style={{ fontWeight: "bold" }}
                  className="snapDetail__content-title"
                >
                  Finding:{" "}
                </div>
                {finding &&
                  finding[`finding${index}`] &&
                  (finding[`finding${index}`] || []).map(
                    (valuecheck, indexValue) => {
                      if (valuecheck.value.trim() == '' || valuecheck.value == ' ') return (<></>);

                      return (
                        <div
                          className="description"
                          key={`snap-${indexValue}-finding`}
                        >
                          {valuecheck.value} &nbsp;
                        </div>
                      );
                    }
                  )}
              </div>
              <div
                className="snapDetail__content-block"
                style={{ marginTop: "10px" }}
              >
                <div
                  style={{ fontWeight: "bold" }}
                  className="snapDetail__content-title"
                >
                  Conclusion:{" "}
                </div>
                {conclusion &&
                  conclusion[`conclusion${index}`] &&
                  (conclusion[`conclusion${index}`] || []).map(
                    (valuecheck, indexValue) => {
                      if (valuecheck.value.trim() == '' || valuecheck.value == ' ') return (<></>);
                      return (
                        <div
                          className="description"
                          key={`snap-${indexValue}-conclusion`}
                        >
                          {valuecheck.value} &nbsp;
                        </div>
                      )
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
      ))}
      {list.length === 0 && <p>No Imaging data.</p>}
    </div>
  );
};

export default HPISnapList;
