import React from 'react';
import { BodySVG } from '../icons/BodyIcon';
import {
  CartoidDisease,
  AorticAneurysm,
  AccessManagement,
  MesentericIschemia,
  DeepVenous,
  PeripheralVascular,
  VenousInsufficiency,
  WoundDisease
} from '../icons/DiseaseSVG';
import './style.scss';

const DiseaseBody = () => {
  return (
    <div className={'imageSection'}>
      <BodySVG />
      <div className={'diseaseIcons'}>
        <span className={'cartoid'}>
          <CartoidDisease />
        </span>
        <span className={'aortic'}>
          <AorticAneurysm />
        </span>
        <span className={'management'}>
          <AccessManagement />
        </span>
        <span className={'mesenteric'}>
          <MesentericIschemia />
        </span>
        <span className={'deep'}>
          <DeepVenous />
        </span>
        <span className={'peripheral'}>
          <PeripheralVascular />
        </span>
        <span className={'venous'}>
          <VenousInsufficiency />
        </span>
        <span className={'wound'}>
          <WoundDisease />
        </span>
      </div>
    </div>
  );
};

export default DiseaseBody
