import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const getAllSupportModules = createActionThunk(AT.GET_ALL_SUPPORT_MODULES, () =>
  API.getAllSupportModules()
);

export const getSupportModules = createActionThunk(AT.GET_SUPPORT_MODULES, (user_id) =>
  API.getSupportModules(user_id)
);

export const createSupportModule = createActionThunk(AT.CREATE_NEW_SUPPORT_MODULE, (payload) => 
  API.createNewSupportModule(payload)
);

export const updateSupportModule = createActionThunk(AT.UPDATE_SUPPORT_MODULE, (id, payload) => 
  API.updateSupportModule(id, payload)
);

export const deleteSupportModule = createActionThunk(AT.DELETE_SUPPORT_MODULE, (id) => 
  API.deleteSupportModule(id)
);

export const closeSupportModule = createActionThunk(AT.CLOSE_SUPPORT_MODULE, (id, payload) => 
  API.closeSupportModule(id, payload)
);

export const updateSupportModuleHistory = createActionThunk(AT.UPDATE_SUPPORT_MODULE_HISTORY, (id, payload) => 
  API.updateSupportModuleHistory(id, payload)
);

export const updateAuthorReadStatus = createActionThunk(AT.UPDATE_AUTHOR_READ, (id) => 
  API.updateSupportModule(id)
);
