import React, { useState, useEffect, useRef } from "react";
import { Icon, Input, Select } from "antd";
import ScrollWrapper from "../ScrollBar";
import Loading from "../Loading";
import { SearchSVG, ExpandSVG, CheckSVG } from "../icons/SVGIcon";
import DeleteIconButton from "../icons/DeleteIconButton";
import { useOutsideDetect } from "../../utils";
import "./style.scss";

const { Option } = Select;
const { Search } = Input;
const selectArray = [
  { value: "ASAP", id: "asap" },
  { value: "Within 2 weeks", id: "2_week" },
  { value: "3 months", id: "3_month" },
  { value: "6 months", id: "6_month" },
  { value: "1 year", id: "1_year" },
];

const AutoCompleteComponent = ({
  rows,
  options,
  query,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keyLabel,
  keyValue,
  keyType,
  selectItem,
  removeItem,
  disableOptions,
  onclickRow,
  activeIndex,
  isSelectRow,
  handleValueSelect,
  onChangeSelect,
  valueSelected,
  processArray,
  minRows,
  onScroll,
  isLocked,
  twoColum,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [valueSelectedGlobal, setValueSelectedGlobal] = useState({});
  const [selectRow, setSelectRow] = useState(false);
  const [valueHandleSelect, setValueHandleSelect] = useState([]);
  const [toDelete, setToDelete] = useState(-1);

  const [openResult, setOpenResult] = useState(false);
  const data = rows ? rows : [];
  const optionData = options ? options : [];
  const minRowCount = minRows ? minRows : 4;
  let emptyRows = [];

  if (data.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - data.length).fill({ label: "" });
  }

  useEffect(() => {
    setValueSelectedGlobal(valueSelected);
  }, [valueSelected]);

  useEffect(() => {
    setValueHandleSelect(handleValueSelect);
  }, [handleValueSelect]);

  useEffect(() => {
    setSelectRow(isSelectRow);
  }, [isSelectRow]);

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setSelected(row[keyLabel]);
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keyLabel]);
    if (title === "previous-medical-cpt") {
      setSearch(`${row[keyLabel]} ${row[keyType]}`);
    }
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (selectItem) {
      selectItem(selected, selectedValue, selectedType);
    } else {
      if (addItem) {
        addItem();
      }
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target.value);
    if (handleSearch) {
      handleSearch(e.target.value);
    }
  };

  const clickHandle = (row, index) => {
    if (onclickRow) {
      onclickRow(row, index);
    }
  };

  const selectHandle = (value, index) => {
    setValueSelectedGlobal({
      ...valueSelectedGlobal,
      [index]: value,
    });
    onChangeSelect(value, index);
  };

  return (
    <div className="atuoComplete">
      <div className="atuoComplete__filter">
        <div
          ref={ref}
          className={`atuoComplete__filter-search origin ${
            disableSearchIcon ? "disable-icon" : ""
          }`}
        >
          <Search
            value={search}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{ width: "100%", height: "42.5px" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        <div
          className={`atuoComplete__filter-action ${
            isLocked === true || isLocked === 1 ? "disabled" : ""
          }`}
        >
          <button
            className="atuoComplete__filter-add"
            onClick={addSelectedItem}
            disabled={isLocked}
          >
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
        {!disableOptions && openResult && !!search && (
          <div ref={ref1} className="atuoComplete__filter-result">
            <div className="atuoComplete__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper
                onScrollFrame={
                  onScroll
                    ? (value) => {
                        onScroll(value);
                      }
                    : null
                }
                css="no-padding"
              >
                {!!optionData.length &&
                  optionData.map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`empty-${title}-${index}`}
                      onClick={() => handleSelectItem(row)}
                    >
                      {title === "previous-medical-cpt" ? (
                        <p style={{ paddingLeft: "28px" }}>
                          {row[keyLabel]} {row[keyType]}
                        </p>
                      ) : (
                        <p style={{ paddingLeft: "28px" }}>{row[keyLabel]}</p>
                      )}
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>

      <div className="atuoComplete__result">
        <div
          className="atuoComplete__result-list"
          style={{ height: (2.5 * minRowCount).toString() + "rem" }}
        >
          <ScrollWrapper css="no-padding x-hidden">
            {data.map((row, index) => (
              <div
                className={`atuoComplete__result-item`}
                style={{
                  margin: 0,
                  width: "100%",
                  position: "relative",
                  padding: 5,
                  paddingLeft: selectRow ? 15 : 10,
                  paddingRight: "32px",
                  backgroundColor: activeIndex === index ? "#dbe3ee" : "#fff",
                }}
                key={`value-${title}-${twoColum ? row[keyLabel] : row}`}
                onClick={() =>
                  clickHandle(twoColum ? row[keyLabel] : row, index)
                }
              >
                <div
                  style={{
                    display: "inline-flex",
                    marginRight: "0.5rem",
                    cursor: "pointer",
                    width:
                      selectRow &&
                      valueHandleSelect &&
                      valueHandleSelect[index] !== "clinic"
                        ? "80%"
                        : "100%",
                  }}
                >
                  {twoColum ? (
                    <React.Fragment>
                      <div style={{ marginLeft: selectRow ? 13 : 12 }}>
                        {row[keyLabel]}
                        <span style={{ marginRight: 5, marginLeft: 5 }}>- <span style={{fontWeight: 'bold'}}>{row[keyType]}</span></span>      
                      </div>
                    </React.Fragment>
                  ) : (
                    <p style={{ marginLeft: selectRow ? 13 : 12 }}>{row}</p>
                  )}
                </div>
                {selectRow &&
                valueHandleSelect &&
                valueHandleSelect[index] !== "clinic" ? (
                  <div
                    style={{
                      display: "flex",
                      width: "20%",
                    }}
                  >
                    <Select
                      suffixIcon={<ExpandSVG />}
                      allowClear={true}
                      value={valueSelectedGlobal && valueSelectedGlobal[index]}
                      placeholder="Urgency"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        selectHandle(value, index);
                      }}
                    >
                      {selectArray.map((r, index) => {
                        return (
                          <Option
                            style={{ width: "100%" }}
                            key={`${r.id}-${index}`}
                            value={r.id}
                          >
                            {r.value}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                ) : null}
                {
                  <div
                    className="icon_process"
                    style={{
                      width: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {processArray && processArray[index] ? <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/> : ""}
                  </div>
                }
                <div style={{ position: "absolute", right: "12px" }}>
                  <DeleteIconButton
                    selected={toDelete === index}
                    icon={"can"}
                    handleClick={() => {
                      if (toDelete === index) {
                        setToDelete(-1);
                        removeItem(twoColum ? row[keyLabel] : row, index);
                      } else {
                        setToDelete(index);
                      }
                    }}
                  />
                </div>
              </div>
            ))}
            {emptyRows.map((row, index) => (
              <div
                className="atuoComplete__result-item empty"
                key={`empty-${title}-${index}`}
              />
            ))}
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default AutoCompleteComponent;
