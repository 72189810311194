import React, {useEffect, useState, useMemo, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Cookies from "universal-cookie";
import { debounce } from "lodash";

import {
  CloseIcon,
  DevicesSVG,
  ConsumableSVG,
  DugsSVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import EditFormInput from "../../../../common/components/EditableText/EditableFormInput";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import SubDataTable from "../../../../common/components/SubDataTable";
import InventoryAddForm from "./InventorySearchAddForm";
import {
  setInventoryData,
  addNewInventory,
  updateInventoryItem,
  addToPurchaseList,
  addInventoryCostHistory,
  updateAngioSupply,
  updateNarcoticInventory,
  deleteInventoryItem,
  updateInventoryNotes,
  setGlobalLoading,
} from "../../../../store/actions";
import { Icon } from 'antd';

const cookies = new Cookies();

const filterSuggestion = (suggestions, userInput) => {
  let filteredSuggestions = [];
  if (!userInput) {
    filteredSuggestions = suggestions;
  }
  if (userInput) {
    filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
  }
  return filteredSuggestions;
};

const categories = {
  angiographic: "Angiographic Supply",
  narcotic: "Narcotic Inventory",
  other: "Other Inventory",
};

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const getTitle = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{ ...titleStyle, width: "50%", paddingLeft: 0, borderLeft: 0 }}
      >
        Date
      </div>
      <div style={{ ...titleStyle, width: "50%", textAlign: "left" }}>
        Cost Per Item
      </div>
    </div>
  );
};

const InventorySearchSidebar = React.forwardRef(({
  resource,
  locations,
  isShow,
  handleCloseSidebar,
},ref) => {
  const inventoryItem = useSelector((state) => state.inventory.inventory);
  const loading = useSelector((state) => state.inventory.loadingAPI);
  const updated = useSelector((state) => state.inventory.isUpdated);
  const deleted = useSelector((state) => state.inventory.isDeleted);
  const costHistory = useSelector(
    (state) => state.inventory.inventoryCostHistory
  );
  const loadingCost = useSelector(
    (state) => state.inventory.loadingCostHistory
  );

  const [notes, setNotes] = useState([]);
  const [searchInput, setInput] = useState('');
  const [item, setItem] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [purchaseNumber, setPurchaseNumber] = useState();
  const [isPurchase, setIsPurchase] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [reset, setReset] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inventoryItem) {
      const initialNotes = inventoryItem.notes
        ? inventoryItem.notes.split(",")
        : [];
      setNotes(initialNotes);

      setReset(false);
      setTimeout(() => {
        setReset(true);
      }, 1000);
      setItem(inventoryItem);
    }
  }, [inventoryItem]);

  useEffect(() => {
    if (updated) {
      dispatch(setInventoryData("isUpdated", false));
      setIsAdding(false);
    }
    if (deleted) {
      dispatch(setInventoryData("isDeleted", false));
      dispatch(setInventoryData("inventory", null));
      dispatch(setInventoryData("inventoryCostHistory", []));
      setIsDelete(false);
      setReset(false);
      setTimeout(() => {
        setReset(true);
      }, 1000);
      setItem({});
    }
  }, [updated, deleted]);

  useEffect(() => {
    if (inventoryItem && notes.length) {
      const itemNotes = notes.join(",");
      const updatedItem = { ...item, notes: itemNotes };
      setItem(updatedItem);
      if (!isAdding) {
        reset && changeHandler(updatedItem)
      }
    }
  }, [notes]);

  const noteRows = useMemo(() => {
    if (searchInput) {
      return filterSuggestion(notes, searchInput);
    }
    return notes;
  }, [notes, searchInput]);

  const openAddingForm = () => {
    setIsAdding(true);
  };

  const handleAddInventory = (inventory) => {
    dispatch(addNewInventory(inventory));
  };

  const handleSearch = (search) => {
    setInput(search);
  };

  const updateNotes = (note) => {
    const request = {
      type: inventoryItem.type,
      notes: note,
    };
    dispatch(updateInventoryNotes(inventoryItem.id, request));
  };

  const addNote = () => {
    const newNotes = [...notes, searchInput];
    setNotes(newNotes);
    setInput("");
    updateNotes(newNotes.join(","));
  };

  const removeNote = (row, index) => {
    const newNotes = [...notes];
    const rowIndex = notes.findIndex((note) => note === row);
    if (rowIndex > -1) {
      newNotes.splice(rowIndex, 1);
    } else {
      newNotes.splice(index, 1);
    }

    setNotes(newNotes);
    updateNotes(newNotes.join(","));
  };

  useEffect(() => {
    if (isShow && item) {
      dispatch(setGlobalLoading(loading || loadingCost))
    }
  }, [loading, loadingCost, isShow, item])

  const cost_per_item = useMemo(() => {
    if (item && item.cost && reset) {
      const quantity = item.quantity ? item.quantity : 1;
      return (item.cost / quantity).toFixed(2);
    }
    return null;
  }, [item]);

  const costRows = useMemo(() => {
    if (costHistory && costHistory.length) {
      const rows = costHistory.filter(ch => !!ch).map(ch => ({
        item: moment(ch.created_at).format('MM/DD/YYYY'),
        amount: '$' + parseFloat(ch.cost_per_item).toFixed(2)
      }));
      return rows;
    }
    if (cost_per_item) {
      return [
        {
          item: moment().format("MM/DD/YYYY"),
          amount: "$" + parseFloat(cost_per_item).toFixed(2),
        },
      ];
    }
    return [];
  }, [costHistory]);

  // Save updated item
  const onUpdateItem = (value) => {
    const costRequest = {
      type: inventoryItem.type,
      cost_per_item: cost_per_item
    }
    
    if (inventoryItem.type === 'other') {
      dispatch(updateInventoryItem(inventoryItem.id, value));
    } else if (inventoryItem.type === 'angiographic') {
      dispatch(updateAngioSupply(inventoryItem.id, value));
    } else if(inventoryItem.type === 'narcotic') {
      dispatch(updateNarcoticInventory(inventoryItem.id, value));
    }

    if (!costHistory.length && inventoryItem.cost) {
      // console.log('first cost');
      const quantity = inventoryItem.quantity ? inventoryItem.quantity : 1;
      const costItem =
        Math.round(((inventoryItem.cost || 0) * 100) / quantity) / 100;

      if (costItem !== null && costItem !== undefined) {
        dispatch(addInventoryCostHistory(inventoryItem.id, {
          type: inventoryItem.type,
          cost_per_item: costItem
        }));
      }
    }
    if (inventoryItem.cost && value.cost && inventoryItem.cost !== value.cost && cost_per_item !== null && cost_per_item !== undefined) {
      console.log('new cost', inventoryItem.cost, value.cost);
      dispatch(addInventoryCostHistory(inventoryItem.id, costRequest));
    }
  };

  const changeHandler = useCallback(debounce((value) => onUpdateItem(value), 400), []);

  const handleChange = (type, value) => {
    const newValue = {
      ...item,
      [type]: value
    }
    setItem(newValue);
    if (!isAdding) {
      reset && changeHandler(newValue)
    }
  }

  const onChangeInventory = type => value => {
    handleChange(type, value);
  };

  const validatePurchaseNumber = () => {
    const regex = /^\d+$/;
    if (regex.test(purchaseNumber) && purchaseNumber > 0) {
      return false;
    }
    return true;
  }

  // Duplicate item
  const duplicateItem = () => {
    const inventory = { ...inventoryItem };
    delete inventory.id;
    dispatch(addNewInventory(inventory));
  };

  // Delete inventory item
  const onDelete = () => {
    const param = {
      type: inventoryItem.type,
    };

    dispatch(deleteInventoryItem(inventoryItem.id, param));
  };

  // Add inventory to Purchase List
  const onAddToPurchaseList = () => {
    const user = cookies.get("cvai-current-user");
    if (validatePurchaseNumber()) {
      setIsPurchase(true);
    } else {
      onUpdateItem(item);
      dispatch(addToPurchaseList(
        inventoryItem.id,
        {
          quantity: +item.quantity || 0,
          purchase_number: +purchaseNumber,
          type: inventoryItem.type,
          added_user: user,
        })
      );
    }
  };

  if (!isShow || !item) {
    return null;
  }

  return (
    <>
      <div
        ref={ref}
        className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
      >
        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          {!isAdding && (
            <SidebarTitle
              icon={
                item.type === "narcotic" ? (
                  <Icon type="experiment" theme="twoTone" twoToneColor="#0043CE"/>
                ) : item.type === "angiographic" ? (
                  <Icon type="tag" theme="twoTone" twoToneColor="#6929C4"/>
                ) : (
                  <Icon type="shop" theme="twoTone" twoToneColor="#9F1853"/>
                )
              }
              title={item.name || ""}
              subTitle=""
            />
          )}

          {!isAdding && !isDelete && (
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
              }}
            >
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Name"}
                  value={item.name}
                  handleChange={onChangeInventory("name")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Product No"}
                  value={item.product_number}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label="Code No"
                  value={item.code_number}
                  handleChange={onChangeInventory("code_number")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Category"}
                  value={item.type}
                  handleChange={onChangeInventory("type")}
                  type={"check"}
                  options={[
                    { value: "angiographic", label: "Angiographic Supply" },
                    { value: "narcotic", label: "Narcotic Inventory" },
                    { value: "other", label: "Medications" },
                  ]}
                  valueKey={"label"}
                  optionKey={"value"}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Brand"}
                  value={item.brand}
                  handleChange={onChangeInventory("brand")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"PAR Level"}
                  value={item.par_level}
                  handleChange={onChangeInventory("par_level")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Bin Rate"}
                  value={item.bin_rate}
                  handleChange={onChangeInventory("bin_rate")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Location"}
                  value={item.location}
                  handleChange={onChangeInventory("location")}
                  type={"check"}
                  options={locations || []}
                  optionKey={"value"}
                  valueKey={"value"}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Specific Location"}
                  value={item.specific_location}
                  handleChange={onChangeInventory("specific_location")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Supplier Name"}
                  value={item.supplier_name}
                  handleChange={onChangeInventory("supplier_name")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Supplier E-Mail"}
                  value={item.supplier_email}
                  handleChange={onChangeInventory("supplier_email")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Supplier Fax"}
                  value={item.supplier_fax}
                  handleChange={onChangeInventory("supplier_fax")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Quantity per unit"}
                  value={item.quantity}
                  handleChange={onChangeInventory("quantity")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Unit"}
                  value={item.unit}
                  handleChange={onChangeInventory("unit")}
                  type={"check"}
                  options={[
                    { value: "Case", label: "Case" },
                    { value: "Box", label: "Box" },
                    { value: "Pack", label: "Pack" },
                    { value: "Each", label: "Each" },
                  ]}
                  valueKey={"label"}
                  optionKey={"value"}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Cost"}
                  value={item.cost && parseFloat(item.cost).toFixed(2)}
                  prefix={"$"}
                  handleChange={onChangeInventory("cost")}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Cost/Item"}
                  value={cost_per_item}
                  prefix={"$"}
                />
              </div>
              <div
                className="resourceInfo d-flex"
                style={{ marginTop: "0.5rem" }}
              >
                <b style={{ minWidth: "35%" }}>Cost History </b>
              </div>
              <div className="resourceInfo" style={{ position: "relative" }}>
                <SubDataTable title={getTitle()} data={costRows} half={true} />
              </div>
              <hr />

              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%" }}>Notes </b>
              </div>
              <div className="resourceInfo d-flex">
                <AutoCompleteComponent
                  title="inventory-notes"
                  rows={noteRows}
                  removeItem={removeNote}
                  addItem={addNote}
                  handleSearch={handleSearch}
                  disableOptions={true}
                  placeholder={"Add Information Note"}
                  disableSearchIcon={true}
                />
              </div>
              <div
                className="resourceInfo d-flex"
                style={{ marginTop: "2rem", alignItems: "center" }}
              >
                <b style={{ marginRight: "0.25rem" }}>Purchase </b>
                <div
                  style={{
                    width: "110px",
                    minWidth: "110px",
                    maxWidth: "110px",
                  }}
                >
                  <EditFormInput
                    label={"Number"}
                    required={true}
                    value={purchaseNumber}
                    enableErrorText={isPurchase}
                    handleChange={(value) => setPurchaseNumber(value)}
                    isError={validatePurchaseNumber()}
                    helperText={""}
                  />
                </div>
                <b style={{ marginLeft: "0.25rem" }}>{` at ${
                  item.cost ? "$" + parseFloat(item.cost).toFixed(2) : ""
                } ${item.unit || ""}`}</b>
              </div>
              <hr />
            </div>
          )}
          {!isAdding && !isDelete && (
            <div
              className="actionContent"
              style={{
                marginTop: "1.5rem",
                paddingRight: "1rem",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                className="outlined-btn"
                style={{ marginTop: "0.5rem" }}
                onClick={() => setIsDelete(true)}
              >
                Remove
              </button>
              <button
                onClick={duplicateItem}
                className="outlined-btn"
                style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
              >
                Duplicate
              </button>
              <button
                onClick={openAddingForm}
                className="outlined-btn"
                style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
              >
                Add Inventory
              </button>
              <button
                style={{
                  marginLeft: '0.5rem',
                  marginTop: '0.5rem',
                  paddingRight: 0
                }}
                onClick={onAddToPurchaseList}
              >
                Add to Purchase List
              </button>
            </div>
          )}

          {isAdding && (
            <InventoryAddForm
              handleSubmit={handleAddInventory}
              handleClose={() => setIsAdding(false)}
              loading={loading}
              showCategory={true}
              locations={locations}
            />
          )}
          {isDelete && (
            <>
              <div className="resourceInfo">
                <b style={{ width: "100%", textAlign: "center" }}>
                  Do you really want to delete this inventory?
                </b>
              </div>
              <div
                className="actionContent"
                style={{
                  marginTop: "1.5rem",
                  paddingRight: "1rem",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  className="outlined-btn"
                  style={{ marginTop: "0.5rem" }}
                  onClick={() => setIsDelete(false)}
                >
                  Back
                </button>
                <button
                  onClick={onDelete}
                  className="outlined-btn"
                  style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                >
                  Delete
                </button>
              </div>
            </>
          )}
        </ScrollWrapper>
      </div>
    </>
  );
});

export default InventorySearchSidebar;
