import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Upload, message, Icon } from 'antd';
import {
  CloseIcon, DevicesSVG, SummaryEmptySVG,
  LeftCharvenSVG, RedCloseSVG, CheckSVG,
  CreditReceivedSVG, ShippedSVG,
} from '../../../../common/components/icons/SVGIcon';
import SidebarTitle from '../../../../common/components/SidebarTitle';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import Loading from "../../../../common/components/Loading";
import {BarcodeSVG, MaleSVG} from "../../../../common/components/icons/FaxSVG";
import EditFormInput from "../../../../common/components/EditableText/LoginFormInput";
import {
  setInventoryData,
  getReturnCreditOrders,
  receivedReceivingItem,
  creditReceivingItem,
  shippedReceivingItem
} from "../../../../store/actions";

const { Dragger } = Upload;

const draggerProps = {
  name: 'file',
  multiple: false,
  action: '',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const categories = {
  angiographic: 'Angiographic Supply',
  narcotic: 'Narcotic Inventory',
  other: 'Other Inventory'
};

const ReturnCreditSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar }, ref) => {
  const order = useSelector(state => state.inventory.returnCredit);
  const loading = useSelector(state => state.inventory.loadingAPI);
  const updated = useSelector(state => state.inventory.isUpdated);
  
  const dispatch = useDispatch();

  const [orderItem, setOrderItem] = useState({});

  useEffect(() => {
    setOrderItem(order);
  }, [order]);

  useEffect(() => {
    if (updated) {
      dispatch(getReturnCreditOrders());
      dispatch(setInventoryData('isUpdated', false));
    }
  }, [updated]);
  
  const onReceived = () => {
    if (order) {
      dispatch(receivedReceivingItem(order.id, { received: true }));
      dispatch(setInventoryData('returnCredit', null));
    }
  };

  const onCreditReceived = () => {
    if (order) {
      dispatch(creditReceivingItem(order.id, { credit_received: true }));
      dispatch(setInventoryData('returnCredit', null));
    }
  };

  const onShipped = () => {
    if (order) {
      dispatch(shippedReceivingItem(order.id, { shipped: true }));
      setOrderItem({
        ...order,
        damaged: 0,
        credit_received: 0,
        shipped: 1,
        received: 0
      });
    }
  };

  const cost_per_item = useMemo(() => {
    if (order && order.cost) {
      const quantity = order.quantity ? order.quantity : 1;
      return Math.round(order.cost * 100 / quantity) / 100;
    }
    return null;
  }, [order]);

  if (!isShow || !order) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? 'show' : ''}`}>
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={
            orderItem && orderItem.shipped
              ? <Icon type="car" theme="twoTone" />
              : orderItem && orderItem.credit_received
                ? <CreditReceivedSVG />
                : orderItem && orderItem.received ? <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
                : <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28"/>
          }
          title={order.name}
          subTitle=""
        />

        <div className="resourceContent" style={{ flex: 'unset', paddingLeft: '24px', paddingRight: '20px' }}>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Product No'}
              value={order.product_number}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Code No'}
              value={order.code_number}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Category'}
              value={categories[order.type]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Brand'}
              value={order.brand}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'PAR Level'}
              value={order.par_level}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Bin Rate'}
              value={order.bin_rate}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Location'}
              value={order.location}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Specific Location'}
              value={order.specific_location}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Supplier E-Mail'}
              value={order.supplier_email}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Supplier Fax'}
              value={order.supplier_fax}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Quantity per unit'}
              value={order.quantity}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Unit'}
              value={order.unit}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Cost'}
              value={order.cost && parseFloat(order.cost).toFixed(2)}
              prefix={'$'}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Cost/Item'}
              value={cost_per_item}
              prefix={'$'}
            />
          </div>
          <hr />
          <div className="resourceInfo d-flex" style={{ alignItems: 'center' }}>
            <b>Image of Return/Packing Slip</b>
            <Dragger {...draggerProps} className="barcode-uploader" showUploadList={false}>
              <p className="ant-upload-drag-icon">
                <BarcodeSVG />
              </p>
            </Dragger>
          </div>
          <hr />
        </div>
        <div
          className="actionContent"
          style={{
            marginTop: '1.5rem',
            paddingRight: '1rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <button className="outlined-btn" style={{ marginTop: '0.5rem' }} onClick={onShipped}>Shipped</button>
          <button style={{ marginTop: '0.5rem', marginLeft: '0.5rem' }} onClick={onCreditReceived}>Credit Received</button>
          <button style={{ marginTop: '0.5rem', marginLeft: '0.5rem' }} onClick={onReceived}>Item Received</button>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default ReturnCreditSidebar
