import { createReducer } from "redux-create-reducer";
import AT from "../actionTypes";

const initialState = {
  saving: false,
  ROSs: [],
  physical_examinations: [],
  vitals: [],
  medicine_administration: {
    'pre_op': [],
    'intra_op': [],
    'post_op': [],
    discharge: [],
  },
  previous_medical_histories: [],
  previous_surgical_histories: [],
  allergies: [],
  medications: [],
  immunizations: [],
  family_histories: [],
  social_histories: [],
  vessel_states: [],
  all_normal_ros: null,
  all_normal_exam: null,
  can_copy_from_previous_visit: null,
  loadingEx: false,
  chiefComplaint: "",
  pre_operative: [],
  post_operative: [],
  all_normal_post_operative: false,
  all_normal_pre_operative: false,
  discharge: [],
  intra: [],
  loading_intra: false,
  complication: "",
  total_contrast_load: "",
  total_radiation_time: "",
};

const PATIENT_EXAMINATION_SET_DATA_SUCCEEDED = `${AT.PATIENT_EXAMINATION_SET_DATA}_SUCCEEDED`;

export default createReducer(initialState, {
  // intra
  [`${AT.SAVE_INTRA_OPERATIVE}_STARTED`](state, action) {
    return {
      ...state,
      loading_intra: true,
    };
  },
  [`${AT.SAVE_INTRA_OPERATIVE}_SUCCEEDED`](state, action) {
    return {
      ...state,
      loading_intra: false,
    };
  },
  [`${AT.LOAD_INTRA_OPERATIVE}_STARTED`](state, action) {
    return {
      ...state,
      loading_intra: true,
    };
  },
  [`${AT.LOAD_INTRA_OPERATIVE}_SUCCEEDED`](state, action) {
    const intra = action.payload;
    return {
      ...state,
      intra: intra.angio_procedures,
      loading_intra: false,
    };
  },
  //
  [`${AT.SAVE_CHIEF_COMPLAINT}_SUCCEEDED`](state, action) {
    return {
      ...state,
      loadingEx: false,
    };
  },
  [`${AT.SAVE_CHIEF_COMPLAINT}_STARTED`](state, action) {
    return {
      ...state,
      loadingEx: true,
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      saving: false,
      ROSs: [],
      physical_examinations: [],
      vitals: [],
      previous_medical_histories: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      immunizations: [],
      family_histories: [],
      social_histories: [],
      vessel_states: [],
      pre_operative: [],
      discharge: [],
      post_operative: [],
      allAllergies: [],
      intra: [],
      all_normal_ros: null,
      all_normal_exam: null,
      chiefComplaint: "",
      can_copy_from_previous_visit: null,
      complication: "",
      total_contrast_load: "",
      total_radiation_time: "",
    };
  },
  [`${AT.PATIENT_EXAMINATION_LOAD_INIT}_STARTED`](state, action) {
    return {
      ...state,
      loadingEx: true,
    };
  },
  [`${AT.ANGIO_PATIENT_SNAPSHOT_LOAD_INIT}_SUCCEEDED`](state, action) {
    if (action.payload) {
      let {
        immunizations,
        family_histories,
        social_histories,
        medication_administration,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        medications,
        pre_operative,
        discharge,
        post_operative,
        all_normal_post_operative,
        all_normal_pre_operative,
        complication,
        total_contrast_load,
        total_radiation_time,
      } = action.payload;

      return {
        ...state,
        complication,
        total_contrast_load,
        medication_administration,
        total_radiation_time,
        immunizations,
        family_histories,
        social_histories,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        pre_operative,
        discharge,
        all_normal_post_operative,
        all_normal_pre_operative,
        post_operative,
        medications,
      };
    }
    return { ...state };
  },
  [`${AT.PATIENT_EXAMINATION_LOAD_INIT}_SUCCEEDED`](state, action) {
    if (action.payload) {
      const {
        ROSs,
        physical_examinations,
        vitals,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        medications,
        immunizations,
        family_histories,
        social_histories,
        vessel_states,
        allMedications,
        allAllergies,
        all_normal_ros,
        chiefComplaint,
        all_normal_exam,
        can_copy_from_previous_visit,
      } = action.payload;

      return {
        ...state,
        ROSs,
        physical_examinations,
        vitals,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        medications,
        immunizations,
        family_histories,
        social_histories,
        vessel_states,
        allMedications,
        allAllergies,
        all_normal_ros,
        all_normal_exam,
        can_copy_from_previous_visit,
        loadingEx: false,
        chiefComplaint,
      };
    }

    return {
      ...state,
    };
  },
  [`${AT.PATIENT_EXAMINATION_COPY_LAST}_SUCCEEDED`](state, action) {
    const { ROSs, physical_examinations } = action.payload;

    return {
      ...state,
      ROSs,
      physical_examinations,
    };
  },
  [`${AT.PATIENT_EXAMINATION_SET_NOMAL_CHECK}_SUCCEEDED`](state, action) {
    const { value, type } = action.payload;
    if (type === "ROS") {
      return Object.assign({}, state, {
        ...state,
        all_normal_ros: value,
      });
    } else {
      return Object.assign({}, state, {
        ...state,
        all_normal_exam: value,
      });
    }
  },
  [PATIENT_EXAMINATION_SET_DATA_SUCCEEDED](state, action) {
    const { scope, examinations } = action.payload;
    if (scope === "ROSs") {
      return Object.assign({}, state, {
        ROSs: examinations,
      });
    } else if (scope === "physical_examinations") {
      return Object.assign({}, state, {
        physical_examinations: examinations,
      });
    } else if (scope === "previous_medical_histories") {
      return Object.assign({}, state, {
        previous_medical_histories: examinations,
      });
    } else if (scope === "previous_surgical_histories") {
      return Object.assign({}, state, {
        previous_surgical_histories: examinations,
      });
    } else if (scope === "allergies") {
      return Object.assign({}, state, {
        allergies: examinations,
      });
    } else if (scope === "medications") {
      return Object.assign({}, state, {
        medications: examinations,
      });
    } else if (scope === "immunizations") {
      return Object.assign({}, state, {
        immunizations: examinations,
      });
    } else if (scope === "family_histories") {
      return Object.assign({}, state, {
        family_histories: examinations,
      });
    } else if (scope === "social_histories") {
      return Object.assign({}, state, {
        social_histories: examinations,
      });
    } else if (scope === "vitals") {
      return Object.assign({}, state, {
        vitals: examinations,
      });
    } else if (scope === "vessel_states") {
      return Object.assign({}, state, {
        vessel_states: examinations,
      });
    }
    return Object.assign({}, state);
  },

  [`${AT.PATIENT_EXAMINATION_SAVE}_STARTED`](state, action) {
    return {
      ...state,
      saving: true,
    };
  },
  [`${AT.PATIENT_EXAMINATION_SAVE}_SUCCEEDED`](state, action) {
    const { physical_examination } = action.payload;
    return {
      ...state,
      saving: false,
    };
  },
});
