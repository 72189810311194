import React, { useState} from 'react';
import EditFormInput from "../../../../common/components/EditableText/LoginFormInput";
import { validateField } from '../../../../services/validations';

const InventorySearchAddForm = ({
  handleSubmit,
  handleClose,
  loading,
  showCategory,
  locations
}) => {
  const [item, setItem] = useState({});
  
  const changeItem = type => value => {
    const newItem = { ...item, [type]: value };
    setItem(newItem);
  };

  const disableButton = () => {
    if (
      validateField('text', item.name)
      || validateField('text', item.product_number)
      || validateField('text', item.brand)
      || validateField('text', item.location)
    ) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="resourceContent" style={{ flex: 'unset', paddingLeft: '24px', paddingRight: '20px' }}>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Product Name'}
            value={item.name}
            handleChange={changeItem('name')}
          />
        </div>
        {showCategory && (
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Category'}
              value={item.type}
              handleChange={changeItem('type')}
              type={'check'}
              options={[
                { value: 'angiographic', label: 'Angiographic Supply' },
                { value: 'narcotic', label: 'Narcotic Inventory' },
                { value: 'other', label: 'Medications' }
              ]}
              valueKey={'label'}
              optionKey={'value'}
            />
          </div>
        )}
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Product No'}
            value={item.product_number}
            handleChange={changeItem('product_number')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Code No'}
            value={item.code_number}
            handleChange={changeItem('code_number')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Brand'}
            value={item.brand}
            handleChange={changeItem('brand')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'PAR Level'}
            value={item.par_level}
            handleChange={changeItem('par_level')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Bin Rate'}
            value={item.bin_rate}
            handleChange={changeItem('bin_rate')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Location'}
            value={item.location}
            handleChange={changeItem('location')}
            type={'check'}
            options={locations || []}
            optionKey={'value'}
            valueKey={'value'}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Specific Location'}
            value={item.specific_location}
            handleChange={changeItem('specific_location')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Specific Location'}
            value={item.supplier_name}
            handleChange={changeItem('supplier_name')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Supplier E-Mail'}
            value={item.supplier_email}
            handleChange={changeItem('supplier_email')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Supplier Fax'}
            value={item.supplier_fax}
            handleChange={changeItem('supplier_fax')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Quantity per unit'}
            value={item.quantity}
            handleChange={changeItem('quantity')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Unit'}
            value={item.unit}
            handleChange={changeItem('unit')}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditFormInput
            label={'Cost'}
            value={item.cost}
            handleChange={changeItem('cost')}
          />
        </div>
        <hr />
      </div>
      <div
        className="actionContent"
        style={{
          marginTop: '1.5rem',
          paddingRight: '1rem',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <button
          onClick={handleClose}
          className="outlined-btn"
          style={{ marginTop: '0.5rem', }}
        >
          Back
        </button>
        <button
          onClick={() => handleSubmit(item)}
          style={{
            marginLeft: '0.5rem',
            marginTop: '0.5rem',
          }}
          disabled={disableButton()}
        >
          Save
        </button>
      </div>
    </React.Fragment>
  );
};

export default InventorySearchAddForm
