import React from "react";
import TableBlock from "./TableBlock";

const BlockComponent = ({
  props,
  detailUltrasounds,
  ultrasound_finding,
  ultrasound_value,
}) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const type = entity.getType();
  if (type == "TABLE") {
    return (
      <TableBlock
        detailUltrasounds={detailUltrasounds}
        ultrasound_finding={ultrasound_finding}
        ultrasound_value={ultrasound_value}
        blockProps={props.blockProps}
        block={props.block}
        contentState={props.contentState}
      />
    );
  }
  return null;
};

export default BlockComponent;
