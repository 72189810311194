import { createReducer } from 'redux-create-reducer';

import AT from '../actionTypes';

const initialState = {
  scanToken: "",
  loadingScanToken: true,
  scanCompareResult: '',
  idCardInfo: null,
  isEmptyIDCardInfo: true
};

//Get patient schedules
const GET_SCAN_TOKEN_SCHEDULES_STARTED = `${AT.GET_SCAN_TOKEN_SCHEDULES}_STARTED`;
const GET_SCAN_TOKEN_SCHEDULES_SUCCEEDED = `${AT.GET_SCAN_TOKEN_SCHEDULES}_SUCCEEDED`;
// Get id card info
const GET_ID_CARD_INFO_STARTED = `${AT.GET_ID_CARD_INFO}_STARTED`;
const GET_ID_CARD_INFO_SUCCEEDED = `${AT.GET_ID_CARD_INFO}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`] (state, action) {
    return {
      ...initialState
    }
  },
  [GET_SCAN_TOKEN_SCHEDULES_SUCCEEDED](state, action) {
    return Object.assign({}, state, {scanToken: action.payload, loadingScanToken: false});
  },
  [GET_SCAN_TOKEN_SCHEDULES_STARTED](state, action) {
    return Object.assign({}, state, {loadingScanToken: true});
  },
  [AT.SCAN_DRIVER_LICENSE_MATCHED](state, action) {
    return {
      ...state,
      scanCompareResult: 'matched'
    }
  },
  [AT.SCAN_DRIVER_LICENSE_INCORRECT](state, action) {
    return {
      ...state,
      scanCompareResult: 'incorrect'
    }
  },

  // Store CardInfo => panamacheng
  [GET_ID_CARD_INFO_STARTED](state, action) {
    return {
      ...state,
      isEmptyIDCardInfo: true 
    };
  },
  [GET_ID_CARD_INFO_SUCCEEDED](state, action) {
    return {
      ...state,
      idCardInfo: action.payload,
      isEmptyIDCardInfo: false 
    };
  },
});