import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, Icon, Modal } from "antd";
import pdf2base64 from "pdf-to-base64";

import {
  sendFaxPCPProcedure,
  sendFaxRPProcedure,
  printPacketProcedure,
  sendLetterProcedure,
  sendEmailProcedure,
  sendFaxHospital,
  saveHospitalCompleted,
} from "../../../services/api.services";
import {
  CheckSVG,
  RedCloseSVG,
} from "../../../common/components/icons/SVGIcon";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../common/components/Toast";
import { getSingleProcedure, setGlobalLoading } from "../../../store/actions";
import YesNo from "../../../common/components/YesNo";

const FinalStepComponent = ({ ...restProps }) => {
  const ref = useRef(null);
  const resource = useSelector((state) => state.procedure.resource);
  const dispatch = useDispatch();

  const [check, setCheck] = useState(false);

  useEffect(() => {
    const { checkAction } = restProps;
    if (checkAction) {
      checkAction(check);
    }
  }, [check]);

  const [checkOption, setCheckoption] = useState({
    fax_rp: 0,
    fax_pcp: 0,
    mail: 0,
    email: 0,
    print_packet: 0,
    fax_hospital: 0,
  });
  const [fax_rp_status, setRPStatus] = useState(0);
  const [fax_pcp_status, setPCPStatus] = useState(0);
  const [print_packet_status, setPrintStatus] = useState(0);
  const [mail_status, setMailStatus] = useState(0);
  const [email_status, setEMailStatus] = useState(0);
  const [fax_hospital_status, setFaxHospitalStatus] = useState(0);
  const [communicationOption, setCommunicationOption] = useState();

  const [lastCommsOptions, setLastCommsOptions] = useState({
    fax_rp: 0,
    fax_pcp: 0,
    mail: 0,
    email: 0,
    print_packet: 0,
    fax_hospital: 0,
  });

  const [message, setMessage] = useState("");
  const [openPrint, setPrintDialog] = useState(false);
  const [printLink, setPrintLink] = useState("");

  const [newPatientPacket, setNewPatientPacket] = useState({});
  const [twilioInfo, setTwilioInfo] = useState({});
  const [completedHospital, setCompletedHospital] = useState({
    completed_hospital: 0,
  });

  const handleCheckOption = async (val) => {
    setCommunicationOption(val);
    const procedureId = resource.id;
    setCheck(true);
    await dispatch(setGlobalLoading(true));
    let options = {
      fax_rp: 0,
      fax_pcp: 0,
      mail: 0,
      email: 0,
      print_packet: 0,
      fax_hospital: 0,
    };

    if (val.length == 0) {
      setLastCommsOptions({
        fax_rp: 0,
        fax_pcp: 0,
        mail: 0,
        email: 0,
        print_packet: 0,
        fax_hospital: 0,
      });
    }

    val.forEach(async (option) => {
      if (lastCommsOptions[option.value] === 1) {
        options[option.value] = 1;
        return;
      }

      switch (option.value) {
        case "fax_rp":
          try {
            const faxResult = await sendFaxRPProcedure(procedureId);
            if (faxResult.status === "success") {
              setRPStatus(1);
              setMessage(faxResult.message);
            } else {
              setRPStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setRPStatus(2);
          }
          break;

        case "fax_pcp":
          try {
            const pcpResult = await sendFaxPCPProcedure(procedureId);
            if (pcpResult.status === "success") {
              setPCPStatus(1);
              setMessage(pcpResult.message);
            } else {
              setPCPStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setPCPStatus(2);
          }
          break;

        case "mail":
          try {
            const letter = await sendLetterProcedure(procedureId);
            if (letter.status === "success") {
              setMailStatus(1);
              setMessage(letter.message);
            } else {
              setMailStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setMailStatus(2);
          }
          break;

        case "email":
          try {
            const email = await sendEmailProcedure(procedureId);
            if (email.status === "success") {
              setEMailStatus(1);
              setMessage(email.message);
            } else {
              setEMailStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setEMailStatus(2);
          }
          break;

        case "print_packet":
          try {
            const print = await printPacketProcedure(procedureId);
            if (print.url) {
              const base64 = await pdf2base64(print.url);
              setPrintLink(base64);
              setPrintDialog(true);
              setPrintStatus(1);
            } else {
              setPrintStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setPrintStatus(2);
          }
          break;

        case "fax_hospital":
          try {
            const fax_hospital = await sendFaxHospital(procedureId);
            if (fax_hospital.status === "success") {
              setFaxHospitalStatus(1);
              setMessage(fax_hospital.message);
            } else {
              setFaxHospitalStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setPrintStatus(2);
          }
          break;

        default:
          break;
      }

      options[option.value] = 1;
      setLastCommsOptions(options);
    });
    dispatch(setGlobalLoading(false));
  };

  const handleChangePatientPacket = (type) => (value) => {
    setNewPatientPacket({ ...newPatientPacket, [type]: value });
  };

  const handleChangeTwilioInfo = (type) => (value) => {
    setTwilioInfo({ ...twilioInfo, [type]: value });
  };

  const handleCommunication = async () => {
    const { customResource } = restProps;
    const procedureId = resource.id;
    const { fax_rp, fax_pcp, mail, print_packet, email, fax_hospital } =
      checkOption;
    await dispatch(setGlobalLoading(true));
    try {
      if (!!fax_rp) {
        const faxResult = await sendFaxRPProcedure(procedureId);
        if (faxResult.status === "success") {
          setRPStatus(1);
          setMessage(faxResult.message);
        } else {
          setRPStatus(2);
        }
        dispatch(setGlobalLoading(false));
      }
    } catch (error) {
      setRPStatus(2);
    }
    try {
      if (!!fax_pcp) {
        const pcpResult = await sendFaxPCPProcedure(procedureId);
        if (pcpResult.status === "success") {
          setPCPStatus(1);
          setMessage(pcpResult.message);
        } else {
          setPCPStatus(2);
        }
        dispatch(setGlobalLoading(false));
      }
    } catch (error) {
      setPCPStatus(2);
    }
    try {
      if (!!mail) {
        const letter = await sendLetterProcedure(procedureId);
        if (letter.status === "success") {
          setMailStatus(1);
          setMessage(letter.message);
        } else {
          setMailStatus(2);
        }
        dispatch(setGlobalLoading(false));
      }
    } catch (error) {
      setMailStatus(2);
    }
    try {
      if (!!print_packet) {
        const print = await printPacketProcedure(procedureId);
        if (print.url) {
          const base64 = await pdf2base64(print.url);
          setPrintLink(base64);
          setPrintDialog(true);
          setPrintStatus(1);
        } else {
          setPrintStatus(2);
        }
        dispatch(setGlobalLoading(false));
      }
    } catch (error) {
      setPrintStatus(2);
    }
    try {
      if (!!email) {
        const email = await sendEmailProcedure(procedureId);
        if (email.status === "success") {
          setEMailStatus(1);
          setMessage(email.message);
        } else {
          setEMailStatus(2);
        }
        dispatch(setGlobalLoading(false));
      }
    } catch (error) {
      setEMailStatus(2);
    }
    try {
      if (!!fax_hospital) {
        const fax_hospital = await sendFaxHospital(procedureId);
        if (fax_hospital.status === "success") {
          setFaxHospitalStatus(1);
          setMessage(fax_hospital.message);
        } else {
          setFaxHospitalStatus(2);
        }
        dispatch(setGlobalLoading(false));
      }
    } catch (error) {
      setFaxHospitalStatus(2);
    }
  };

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
    }
  }, [printLink]);

  let printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize),
          byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf",
      blob = b64toBlob(b64, contentType),
      blobUrl = URL.createObjectURL(blob);

    console.dir("new print");
    var iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        // setTimeout(iframe.contentWindow.print, 500);
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);

  useEffect(() => {
    if (resource) {
      const params = {
        completed_hospital:
          resource.completed_hospital !== null
            ? resource.completed_hospital
            : 0,
      };
      setCompletedHospital(params);
    }
  }, [resource]);

  const onSaveHospitalCheck = async (options) => {
    await dispatch(setGlobalLoading(true));
    const params = {
      ...options,
    };
    const save_hospital = await saveHospitalCompleted(resource.id, params);
    if (save_hospital.status == "success") {
      setMessage(save_hospital.message);
    }
    await dispatch(getSingleProcedure(resource.id));
    await dispatch(setGlobalLoading(false));
  };

  const handleOption = (value) => {
    setCheck(true);
    const params = { ...completedHospital, ["completed_hospital"]: value };
    setCompletedHospital(params);
    onSaveHospitalCheck(params);
  };

  const showToast = () => {
    return (
      <ToastMessage
        type="Success"
        status={"success"}
        msg={message ? message : "Sent successfully"}
      />
    );
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
      {message && showToast()}
      <div className="resourceInfo">
        <EditableFormInput
          label={"Communication"}
          type={"check"}
          options={[
            {
              value: "fax_rp",
              label: "Fax Documents to Referring Provider",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {fax_rp_status === 1 && (
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#24A148"
                    />
                  )}
                  {fax_rp_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "fax_pcp",
              label: "Fax Documents to Primary Care Provider",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {fax_pcp_status === 1 && (
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#24A148"
                    />
                  )}
                  {fax_pcp_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "print_packet",
              label: "Print Procedure Packet Materials",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {print_packet_status === 1 && (
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#24A148"
                    />
                  )}
                  {print_packet_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "mail",
              label: "Auto-Mail Procedure Packet Materials",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {mail_status === 1 && (
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#24A148"
                    />
                  )}
                  {mail_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "email",
              label: "E-Mail Procedure Packet Materials",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {email_status === 1 && (
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#24A148"
                    />
                  )}
                  {email_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "fax_hospital",
              label: "Fax Documents to Hospital Surgery Scheduling",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {fax_hospital_status === 1 && (
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#24A148"
                    />
                  )}
                  {fax_hospital_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
          ]}
          multiple
          disableSearch={true}
          disableMultipleCheckAll={true}
          optionKey={"value"}
          valueKey={"label"}
          handleChange={(val) => handleCheckOption(val)}
          value={communicationOption}
        />
      </div>
      <div className="resourceInfo" style={{ marginTop: "0.5rem" }}>
        <Checkbox
          checked={completedHospital.completed_hospital}
          onChange={(e) => handleOption(e.target.checked)}
        >
          Hospital Surgery Scheduling Completed
        </Checkbox>
      </div>
    </div>
  );
};

export default FinalStepComponent;
