import React, { useState, useEffect, useMemo } from "react";
import { CloseIcon } from "../../components/icons/SVGIcon";
import FormStepper from "../../components/FormStepper";
import { CheckSVG, RedCloseSVG } from "../icons/SVGIcon";
import ScrollWrapper from "../../components/ScrollBar";
import { validateSteps } from "../../../services/validations";
import { newSnapshotOption } from "../../constants/initialData";
import { MaleSVG } from "../../components/icons/FaxSVG";
import SidebarTitle from "../../components/SidebarTitle";
import Ultrasound from "./Disease";
import ClinicDisease from "./ClinicDisease";
import Clinic from "./Clinic";
import General from "./General";

import "../FormStepper/style.scss";
import { Icon } from "antd";

const PlanSidebar = React.forwardRef(
  (
    {
      isShow,
      handleCloseSidebar,
      plans,
      planTypes,
      typePlanId,
      sidebarIcon,
      changeSetProcessHandle,
      activeRow,
      ...restProps
    },
    ref
  ) => {
    const [step, setStep] = useState(0);
    const [formSteps, setFormSteps] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [bundle, setBundle] = useState("");
    const [planAll, setPlanAll] = useState([]);
    const [activeStatus, setActiveStatus] = useState(false);

    const checkSet = (value, active) => {
      setPlanAll(value);
      if (
        (value &&
          value["plan"] &&
          value["plan"][active] &&
          value["plan"][active]["processed"] &&
          value["plan"][active]["processed"] == 1) ||
        (value &&
          value["plan"] &&
          value["plan"][active] &&
          value["plan"][active]["processed"])
      ) {
        setActiveStatus(true);
      } else {
        setActiveStatus(false);
      }
      if (
        value &&
        value["plan"] &&
        value["plan"][active] &&
        value["plan"][active]["bundle"] &&
        value["plan"][active]["bundle"] === "procedure"
      ) {
        setBundle("procedure");
      } else if (
        value &&
        value["plan"] &&
        value["plan"][active] &&
        value["plan"][active]["bundle"] &&
        value["plan"][active]["bundle"] === "ultrasound_test"
      ) {
        setBundle("ultrasound");
      } else if (
        value &&
        value["plan"] &&
        value["plan"][active] &&
        value["plan"][active]["bundle"] &&
        value["plan"][active]["bundle"] === "clinic"
      ) {
        setBundle("clinic");
      } else {
        setBundle("general");
      }
    };

    useEffect(() => {
      if (plans && plans.length > 0) {
        const check = plans.find((r) => r.condition_id == typePlanId);
        if (check) {
          checkSet(check, activeRow);
        }
      }
    }, [plans, typePlanId, activeRow]);

    const handleSubmit = () => {};

    if (!isShow || !restProps.procedure_id_row) {
      return null;
    }

    return (
      <div
        ref={ref}
        className={`resourceContainer ${
          restProps.noSubHeader ? "" : "has-sub-header"
        } ${isShow ? "show" : ""}`}
      >
        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>

          <SidebarTitle
            icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
            title={"PATIENT DASHBOARD"}
            subTitle=""
          />
          <div className="formStepContainer">
            <div className={`formStepper active`}>
              <div className="formStepper__header">
                <div className="formStepper__status">
                  {!activeStatus && (
                    <span className="formStepper__status-check">
                      <Icon
                        type="question-circle"
                        theme="twoTone"
                        twoToneColor="#DA1E28"
                      />
                    </span>
                  )}
                  {activeStatus && (
                    <span className="formStepper__status-check">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#24A148"
                      />
                    </span>
                  )}
                </div>
                <div className="formStepper__title">{restProps.titlePlan}</div>
              </div>

              <div className="formStepper__content">
                {bundle && bundle.length > 0 && (
                  <React.Fragment>
                    {bundle == "procedure" ? (
                      <ClinicDisease
                        activeRow={activeRow}
                        changeSetProcessHandle={changeSetProcessHandle}
                        steps={formSteps}
                        currentStep={step}
                        typePlanId={typePlanId}
                        plans={plans}
                        handleStep={setStep}
                        handleContinue={handleSubmit}
                        conditions={conditions}
                        {...restProps}
                      />
                    ) : bundle == "ultrasound" ? (
                      <Ultrasound
                        activeRow={activeRow}
                        steps={formSteps}
                        currentStep={step}
                        typePlanId={typePlanId}
                        plans={plans}
                        handleStep={setStep}
                        handleContinue={handleSubmit}
                        conditions={conditions}
                        {...restProps}
                      />
                    ) : bundle == "clinic" ? (
                      <Clinic
                        activeRow={activeRow}
                        steps={formSteps}
                        currentStep={step}
                        typePlanId={typePlanId}
                        plans={plans}
                        handleStep={setStep}
                        handleContinue={handleSubmit}
                        conditions={conditions}
                        {...restProps}
                      />
                    ) : (
                      <General
                        activeRow={activeRow}
                        steps={formSteps}
                        currentStep={step}
                        typePlanId={typePlanId}
                        plans={plans}
                        handleStep={setStep}
                        handleContinue={handleSubmit}
                        conditions={conditions}
                        {...restProps}
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div style={{ paddingBottom: "2rem", width: "100%" }} />
        </ScrollWrapper>
      </div>
    );
  }
);

export default PlanSidebar;
