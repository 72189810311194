import React from 'react';
import InventoryModule from './components/InventoryModule';
import { YellowAlertSVG, DugsSVG, DevicesSVG, ConsumableSVG } from '../../common/components/icons/SVGIcon';

export const Children = {
  'inventory': InventoryModule
};

export const TabHeaders = [
  { label: 'Inventory Search', width: '111.7px' },
  { label: 'Angiographic Supplies', width: '145.2px' },
  { label: 'Narcotic Inventory', width: '121.2px' },
  { label: 'Purchase List', width: '87.3px' },
  { label: 'Receiving', width: '63px' },
  { label: 'Return/Credit', width: '89.7px' },
  { label: 'Invoices', width: '55px' },
  { label: 'Transactional History', width: 'auto' }
];
export const StaticResource = {
  first_name: 'James',
  last_name: 'Fisher',
  date_of_birth: '06/09/1973',
  phone: '(989) 897-8797',
  secondary_phone: '(989) 897-8797',
  email: 'haqqani@hotmail.com',
  ssn: '890-09-8977',
  address: '1109 W St Andrews Rd',
  apt_number: '',
  suite: '',
  bldg: '',
  state: 'Michigan',
  city: 'Midland',
  zipcode: '48642',
  clinic_date: '07/04/2019',
  result: '',
  icon: <YellowAlertSVG />
};

export const DashboardRows = [{icon: <DugsSVG />}, {icon: <DevicesSVG />}, {icon: <ConsumableSVG />}];
export const SuppliesRows = [{icon: <DevicesSVG />}, {icon: <ConsumableSVG />}];
export const InventoryRows = [{icon: <DugsSVG />}, {icon: <DugsSVG />}, {icon: <DugsSVG />}];
