import { createReducer } from 'redux-create-reducer';

import AT from '../actionTypes';

const initialState = {
  callback: null,
  searchForCallback: '',
  loadingCallback: false,
  errorMessage: '',
  // Sidebar
};

const SET_CALLBACK_SEARCH_SUCCEEDED = `${AT.SET_CALLBACK_SEARCH}_SUCCEEDED`;
const SET_STEP_DATA_SUCCEEDED = `${AT.SET_STEP_DATA}_SUCCEEDED`;
// MainSection
const GET_CALLBACK_SUCCEEDED = `${AT.GET_CALLBACK}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`] (state, action) {
    return {
      ...initialState
    }
  },
  [SET_CALLBACK_SEARCH_SUCCEEDED](state, action) {
    return Object.assign({}, state, {searchForCallback: action.payload});
  },
  [SET_STEP_DATA_SUCCEEDED](state, action) {
    const { loadingCallback, searchForCallback } = action.payload;

    return Object.assign({}, state, {
      loadingCallback: loadingCallback !== undefined ? loadingCallback : state.loadingCallback,
      searchForCallback: searchForCallback !== undefined ? searchForCallback : state.searchForCallback
    });
  },
  // Get callback process
  [GET_CALLBACK_SUCCEEDED](state, action) {
    const callback = action.payload;
    return Object.assign({}, state, {callback, loadingCallback: false});
  },
});
