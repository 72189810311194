import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";

import { addNewPatient as addPatient } from "../../../services/api.services";
import { validateSteps, validateField } from "../../../services/validations";
import {
  updatePatient as handleUpdatePatient,
  setStepData,
  addpatientPending,
  filterLocations,
  filterProviders,
  autoZip,
  removeZipAuto,
  getInitialPatientData,
  getPending,
  setGlobalLoading,
  selectPatient,
  actionAddAndSelect,
} from "../../../store/actions";

import {
  newPatientOptions,
  stateOptions,
} from "../../../common/constants/initialData";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";

import ToastMessage from "../../../common/components/Toast";

const { demographics } = newPatientOptions;

let timer = null;
const FirstStepComponent = ({ checkIsInsurances, handleContinue, setStateAddForm, ...restProps }) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const loading = useSelector((state) => state.newPatient.loadingResource);
  const isUpdated = useSelector((state) => state.newPatient.isUpdated);
  const zipAutoData = useSelector((state) => state.common.zipAuto);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const tabActive = useSelector(
    (state) => state.clinicProviderReview.tabActive
  );
  const dispatch = useDispatch();
  const [patient, setPatient] = useState({});
  const [isAddForm, setOpenAddForm] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [isToastr, setIsToastr] = useState(false);
  const [first, setFirst] = useState(false);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (resource && resource.id !== patient.id) {
      let address = "";
      if (resource.address) {
        const splitedAddress = resource.address.split(" ").map((addr) => {
          if (!addr || !addr.length) {
            return "";
          }
          if (addr.length < 2) {
            return addr.charAt(0).toUpperCase();
          }
          return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
        });
        address = splitedAddress.join(" ");
      }
      setPatient({ ...resource, address });
      setOpenAddForm(false);
      setEnableError(true);
      setReset(false);
      setTimeout(() => {
        setReset(true);
      }, 1000);
    }
    if (isUpdated && resource) {
      if (validateSteps(resource, demographics)) {
        dispatch(setStepData("isUpdated", false));
      }
    }
  }, [resource, isUpdated]);

  useEffect(() => {
    if (setStateAddForm) {
      setStateAddForm(isAddForm)
    }
  }, [isAddForm])

  useEffect(() => {
    setFirst(true);
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const updatePatient = async (patient, check) => {
    const updatedPatient = { ...patient };
    updatedPatient["date_of_birth"] =
      patient["date_of_birth"] &&
      moment(patient["date_of_birth"]).format("MM/DD/YYYY");
    updatedPatient["referral_date"] =
      patient.referral_date &&
      moment(patient.referral_date).format("MM/DD/YYYY");
    updatedPatient["primary_care_provider_id"] =
      patient["primary_care_provider_id"] || patient.pcp;
    // if (!validateSteps(updatedPatient, demographics)) {
    //   setEnableError(true);
    //   return;
    // }
    await dispatch(setStepData("loadingResource", true));
    await dispatch(setStepData("resource", updatedPatient));
    await dispatch(setStepData("demographics", updatedPatient));
    await dispatch(handleUpdatePatient(updatedPatient));
    if (check) {
      await dispatch(getPending({}));
    }
    // dispatch(removeZipAuto());
    // setIsToastr(true);
    // setTimeout(() => {
    //   setIsToastr(false);
    // }, 2000);
  };

  const changeHandler = useCallback(
    debounce((patient, check) => updatePatient(patient, check), 400),
    []
  );

  const setAutoZip = async () => {
    if (zipAutoData.success) {
      if (zipAutoData.addresses && zipAutoData.addresses[0]) {
        const address = zipAutoData.addresses[0];
        if (!isAddForm) {
          const newPatient = {
            ...resource,
            state: address.state_id,
            city: address.place,
            zipcode: patient.zipcode,
          };
          setPatient(newPatient);
          await dispatch(setStepData("demographics", newPatient));
          setReset(true);
        } else {
          const newPatient = {
            ...patient,
            state: address.state_id,
            city: address.place,
            zipcode: patient.zipcode,
          };
          setPatient(newPatient);
          setReset(true);
        }
      }
    } else {
      if (!isAddForm) {
        const newPatient = {
          ...resource,
          state: undefined,
          city: undefined,
          zipcode: patient.zipcode,
        };
        setPatient(newPatient);
        await dispatch(setStepData("demographics", newPatient));
        setReset(true);
      } else {
        const newPatient = {
          ...patient,
          state: undefined,
          city: undefined,
          zipcode: patient.zipcode,
        };
        setPatient(newPatient);
        setReset(true);
      }
    }
  };

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first) {
      setAutoZip();
    }
  }, [zipAutoData]);

  const onChangePatient = (type) => (value) => {
    const newPatient = {
      ...patient,
      [type]: value,
    };
    setPatient(newPatient);
    setEnableError(false);

    if (!isAddForm) {
      dispatch(setStepData("demographics", newPatient));
      if (type == "gender") {
        reset && changeHandler(newPatient, true);
      } else {
        reset && changeHandler(newPatient, false);
      }
    }
  };

  const checkZipAuto = async (value) => {
    clearTimeout(timer);
    const newPatient = {
      ...patient,
      zipcode: value,
    };
    setPatient(newPatient);
    if (!isAddForm) {
      await dispatch(setStepData("demographics", newPatient));
      reset && changeHandler(newPatient, false);
    }
    setReset(true);
    await dispatch(setStepData("loadingResource", true));
    timer = setTimeout(async () => {
      await dispatch(autoZip(value));
      await dispatch(setStepData("loadingResource", false));
    }, 1000);
  };

  const onChangeAddress = (type) => (value) => {
    let address = "";
    if (value) {
      const splitedAddress = value.split(" ").map((addr) => {
        if (!addr || !addr.length) {
          return "";
        }
        if (addr.length < 2) {
          return addr.charAt(0).toUpperCase();
        }
        return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
      });
      address = splitedAddress.join(" ");
    }

    const newPatient = {
      ...patient,
      [type]: address,
    };
    setPatient(newPatient);
    setEnableError(false);

    if (!isAddForm) {
      dispatch(setStepData("demographics", newPatient));
      reset && changeHandler(newPatient, false);
    }
  };

  const openAddForm = () => {
    setPatient({});
    dispatch(removeZipAuto());
    setOpenAddForm(true);
    setEnableError(true);
  };

  const addNewPatient = async () => {
    if (!validateSteps(patient, demographics)) {
      setEnableError(true);
      return;
    }
    await dispatch(setStepData("loadingResource", true));
    await dispatch(setStepData("isAdded", false));

    try {
      const result = await addPatient(patient);
      await setIsToastr(true);
      await dispatch(selectPatient(result.data.id || result?.data?.data?.id));
      await dispatch(getPending({}));
      if (checkIsInsurances) {
        checkIsInsurances(result.data.id || result?.data?.data?.id);
      }
      // await dispatch(
      //   setStepData("resource", result?.data?.data || result?.data)
      // );
      // await dispatch(addpatientPending(result?.data?.data || result?.data));
      // await dispatch(filterLocations(""));
      // await dispatch(filterProviders());
      // await dispatch(getInitialPatientData());
      await dispatch(removeZipAuto());
      await dispatch(setStepData("loadingResource", false));
      await dispatch(actionAddAndSelect());
      setOpenAddForm(false);
    } catch (error) {
      dispatch(setStepData("loadingResource", false));
      setOpenAddForm(false);
      setPatient(resource);
    }
    setTimeout(() => {
      setIsToastr(false);
    }, 2000);
  };

  const cancelAddForm = () => {
    setOpenAddForm(false);
    dispatch(removeZipAuto());
    setEnableError(true);
    setPatient(resource);
  };

  const showToast = () => {
    return (
      <ToastMessage
        type="New Patient"
        status={"success"}
        msg={toastMessage ? toastMessage : "New patient is added successfully"}
      />
    );
  };

  useEffect(() => {
    restProps.checkIsAdding(isAddForm);
  }, [isAddForm]);

  return (
    <>
      <div className="resourceContent pr-7px" style={{ position: "relative" }}>
        {isToastr && !errorRest && showToast()}
        {!isAddForm && (
          <React.Fragment>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"First Name"}
                value={patient.first_name}
                handleChange={onChangePatient("first_name")}
                required={true}
                isError={validateField("first_name", patient.first_name)}
                enableErrorText={enableError}
                helperText={"First Name is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Last Name"}
                value={patient.last_name}
                handleChange={onChangePatient("last_name")}
                required={true}
                isError={validateField("text", patient.last_name)}
                enableErrorText={enableError}
                helperText={"Last Name is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"DOB"}
                value={patient.date_of_birth}
                handleChange={onChangePatient("date_of_birth")}
                required={true}
                mask={"date"}
                isError={validateField("date", patient.date_of_birth)}
                enableErrorText={enableError}
                helperText={"Birthday should be MM/DD/YYYY!"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Gender"}
                handleChange={onChangePatient("gender")}
                value={patient.gender}
                required={true}
                isError={validateField("text", patient.gender)}
                enableErrorText={enableError}
                helperText={"Gender is Required!"}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "male", name: "Male" },
                  { value: "female", name: "Female" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Language"}
                handleChange={onChangePatient("preferred_language")}
                value={patient.preferred_language}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "English", name: "English" },
                  { value: "Spanish", name: "Spanish" },
                  { value: "Other", name: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Marital Status"}
                handleChange={onChangePatient("marital_status")}
                value={patient.marital_status}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "Married", name: "Married" },
                  { value: "Single", name: "Single" },
                  { value: "Divorced", name: "Divorced" },
                  { value: "Widowed", name: "Widowed" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Ethnicity"}
                handleChange={onChangePatient("ethnicity")}
                value={patient.ethnicity}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "White", name: "White" },
                  { value: "Hispanic", name: "Hispanic" },
                  {
                    value: "Not Hispanic or Latino",
                    name: "Not Hispanic or Latino",
                  },
                  { value: "Other", name: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Phone No"}
                value={patient.phone}
                handleChange={onChangePatient("phone")}
                required={true}
                isError={validateField("phone", patient.phone)}
                enableErrorText={enableError}
                helperText={"Phone Number should be (XXX) XXX-XXXX format"}
                mask={"phone"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Phone No"}
                value={patient.secondary_phone}
                handleChange={onChangePatient("secondary_phone")}
                mask={"phone"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"E-Mail"}
                value={patient.email}
                handleChange={onChangePatient("email")}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"SSN"}
                value={patient.ssn}
                handleChange={onChangePatient("ssn")}
                mask={"ssn"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Address"}
                value={patient.address}
                handleChange={onChangeAddress("address")}
                required={true}
                isError={validateField("text", patient.address)}
                enableErrorText={enableError}
                helperText={"Address is required"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Apt"}
                value={patient.apt_number}
                handleChange={onChangePatient("apt_number")}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"State"}
                value={patient.state || undefined}
                handleChange={onChangePatient("state")}
                type={"check"}
                valueKey={"value"}
                optionKey={"id"}
                options={stateOptions || []}
                required={true}
                isError={validateField("text", patient.state)}
                enableErrorText={enableError}
                helperText={"State is required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"City"}
                value={patient.city}
                handleChange={onChangePatient("city")}
                required={true}
                isError={validateField("text", patient.city)}
                enableErrorText={enableError}
                helperText={"City is required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Zip Code"}
                value={patient.zipcode}
                handleChange={(value) => {
                  checkZipAuto(value);
                }}
                required={true}
                isError={validateField("text", patient.zipcode)}
                enableErrorText={enableError}
                helperText={"Zip Code is required"}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Diagnosis"}
                value={patient.referral_condition || undefined}
                handleChange={onChangePatient("referral_condition")}
                required={true}
                type={"check"}
                isError={validateField("text", patient.referral_condition)}
                optionKey={"value"}
                options={restProps.referralConditions || []}
                enableErrorText={enableError}
                helperText={"Diagnosis is required"}
              />
            </div>
            {patient.referral_condition === "Other" && (
              <div className="resourceInfo d-flex required-field">
                <EditableFormInput
                  label={"Diagnosis Specifics"}
                  value={patient.diagnosis_specifics}
                  handleChange={onChangePatient("diagnosis_specifics")}
                />
              </div>
            )}
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Referring"}
                value={patient.referral_provider || undefined}
                handleChange={onChangePatient("referral_provider")}
                type={"check"}
                optionKey={"id"}
                valueKey={"full_name"}
                options={restProps.pcpList || []}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"PCP"}
                value={patient.primary_care_provider_id || undefined}
                handleChange={onChangePatient("primary_care_provider_id")}
                type={"check"}
                optionKey={"id"}
                valueKey={"full_name"}
                options={restProps.pcpList || []}
              />
            </div>

            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Referral Date"}
                value={patient.referral_date}
                type="date"
                handleChange={onChangePatient("referral_date")}
                required={true}
                isError={validateField("text", patient.referral_date)}
                enableErrorText={enableError}
                helperText={"Referral Date is required"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Pharmacy"}
                value={patient.pharmacy_id || undefined}
                handleChange={onChangePatient("pharmacy_id")}
                type={"check"}
                optionKey={"id"}
                valueKey={"value"}
                options={restProps.providers || []}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Preferred Provider"}
                value={patient.preferred_provider || undefined}
                handleChange={onChangePatient("preferred_provider")}
                type={"check"}
                optionKey={"id"}
                valueKey={"value"}
                options={restProps.providers || []}
              />
            </div>

            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Advance Directives"}
                value={patient.advanced_directive || undefined}
                handleChange={onChangePatient("advanced_directive")}
                required={true}
                type={"check"}
                isError={validateField("text", patient.advanced_directive)}
                optionKey={"value"}
                options={[
                  { value: "No Advance Directives On File" },
                  { value: "Advance Directives On File" },
                ]}
                enableErrorText={tabActive ? true : false}
                helperText={"Advance Directives"}
              />
            </div>
          </React.Fragment>
        )}
        {isAddForm && (
          <React.Fragment>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"First Name"}
                value={patient.first_name}
                handleChange={onChangePatient("first_name")}
                required={true}
                isError={validateField("text", patient.first_name)}
                enableErrorText={enableError}
                helperText={"First Name is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Last Name"}
                value={patient.last_name}
                handleChange={onChangePatient("last_name")}
                required={true}
                isError={validateField("text", patient.last_name)}
                enableErrorText={enableError}
                helperText={"Last Name is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"DOB"}
                value={patient.date_of_birth}
                handleChange={onChangePatient("date_of_birth")}
                required={true}
                mask={"date"}
                isError={validateField("date", patient.date_of_birth)}
                enableErrorText={enableError}
                helperText={"Birthday should be MM/DD/YYYY!"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Gender"}
                handleChange={onChangePatient("gender")}
                value={patient.gender}
                required={true}
                isError={validateField("text", patient.gender)}
                enableErrorText={enableError}
                helperText={"Gender is Required!"}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "male", name: "Male" },
                  { value: "female", name: "Female" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Language"}
                handleChange={onChangePatient("preferred_language")}
                value={patient.preferred_language}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "English", name: "English" },
                  { value: "Spanish", name: "Spanish" },
                  { value: "Other", name: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Marital Status"}
                handleChange={onChangePatient("marital_status")}
                value={patient.marital_status}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "Married", name: "Married" },
                  { value: "Single", name: "Single" },
                  { value: "Divorced", name: "Divorced" },
                  { value: "Widowed", name: "Widowed" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Ethnicity"}
                handleChange={onChangePatient("ethnicity")}
                value={patient.ethnicity}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "White", name: "White" },
                  { value: "Hispanic", name: "Hispanic" },
                  {
                    value: "Not Hispanic or Latino",
                    name: "Not Hispanic or Latino",
                  },
                  { value: "Other", name: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Phone No"}
                value={patient.phone}
                handleChange={onChangePatient("phone")}
                required={true}
                isError={validateField("phone", patient.phone)}
                enableErrorText={enableError}
                helperText={"Phone Number should be (XXX) XXX-XXXX format"}
                mask={"phone"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Phone No"}
                value={patient.secondary_phone}
                handleChange={onChangePatient("secondary_phone")}
                mask={"phone"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"E-Mail"}
                value={patient.email}
                handleChange={onChangePatient("email")}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"SSN"}
                value={patient.ssn}
                handleChange={onChangePatient("ssn")}
                mask={"ssn"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Address"}
                value={patient.address}
                handleChange={onChangeAddress("address")}
                required={true}
                isError={validateField("text", patient.address)}
                enableErrorText={enableError}
                helperText={"Address is required"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Apt"}
                value={patient.apt_number}
                handleChange={onChangePatient("apt_number")}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"State"}
                value={patient.state || undefined}
                handleChange={onChangePatient("state")}
                type={"check"}
                optionKey={"id"}
                valueKey={"value"}
                options={stateOptions || []}
                required={true}
                isError={validateField("text", patient.state)}
                enableErrorText={enableError}
                helperText={"State is required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"City"}
                value={patient.city}
                handleChange={onChangePatient("city")}
                required={true}
                isError={validateField("text", patient.city)}
                enableErrorText={enableError}
                helperText={"City is required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Zip Code"}
                value={patient.zipcode}
                handleChange={(value) => {
                  checkZipAuto(value);
                }}
                required={true}
                isError={validateField("text", patient.zipcode)}
                enableErrorText={enableError}
                helperText={"Zip Code is required"}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Diagnosis"}
                value={patient.referral_condition || undefined}
                handleChange={onChangePatient("referral_condition")}
                required={true}
                type={"check"}
                isError={validateField("text", patient.referral_condition)}
                optionKey={"value"}
                options={restProps.referralConditions || []}
                enableErrorText={enableError}
                helperText={"Diagnosis is required"}
              />
            </div>
            {patient.referral_condition === "Other" && (
              <div className="resourceInfo d-flex required-field">
                <EditableFormInput
                  label={"Diagnosis Specifics"}
                  value={patient.diagnosis_specifics}
                  handleChange={onChangePatient("diagnosis_specifics")}
                />
              </div>
            )}
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Referring"}
                value={patient.referral_provider || undefined}
                handleChange={onChangePatient("referral_provider")}
                required={false}
                type={"check"}
                optionKey={"id"}
                valueKey={"full_name"}
                options={restProps.pcpList || []}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"PCP"}
                value={patient.primary_care_provider_id || undefined}
                handleChange={onChangePatient("primary_care_provider_id")}
                type={"check"}
                optionKey={"id"}
                valueKey={"full_name"}
                options={restProps.pcpList || []}
              />
            </div>

            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Referral Date"}
                value={patient.referral_date}
                type="date"
                handleChange={onChangePatient("referral_date")}
                required={true}
                isError={validateField("text", patient.referral_date)}
                enableErrorText={enableError}
                helperText={"Referral Date is required"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Pharmacy"}
                value={patient.pharmacy_id || undefined}
                handleChange={onChangePatient("pharmacy_id")}
                type={"check"}
                optionKey={"id"}
                valueKey={"value"}
                options={restProps.providers || []}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Preferred Provider"}
                value={patient.preferred_provider || undefined}
                handleChange={onChangePatient("preferred_provider")}
                type={"check"}
                optionKey={"id"}
                valueKey={"value"}
                options={restProps.providers || []}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Advance Directives"}
                value={patient.advanced_directive || undefined}
                handleChange={onChangePatient("advanced_directive")}
                required={true}
                type={"check"}
                isError={validateField("text", patient.advanced_directive)}
                optionKey={"value"}
                options={[
                  { value: "No Advance Directives On File" },
                  { value: "Advance Directives On File" },
                ]}
                enableErrorText={tabActive ? true : false}
                helperText={"Advance Directives"}
              />
            </div>
          </React.Fragment>
        )}
        {!isAddForm && (
          <div
            className="actionContent"
            style={{ flexWrap: "wrap", marginTop: "1.5rem" }}
          >
            <button className="common-btn blue-btn" style={{
              display: 'flex',
              justifyContent: 'space-between'
            }} onClick={openAddForm}>
              <span>Add New Patient</span>
              <span>+</span>
            </button>
          </div>
        )}
        {isAddForm && (
          <div
            className="actionContent"
            style={{ flexWrap: "wrap", marginTop: "1.5rem" }}
          >
            <button
              className="common-btn outlined-btn"
              style={{ marginTop: "0.5rem" }}
              onClick={cancelAddForm}
            >
              Back
            </button>
            <button
              className="common-btn"
              style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
              onClick={addNewPatient}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FirstStepComponent;
