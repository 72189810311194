import React, { useState } from "react";
import { Input, Select, DatePicker, Menu, Slider, Dropdown } from "antd";
import moment from "moment";
import {
  ExpandSVG,
  SearchSVG,
} from "../../../../common/components/icons/SVGIcon";

const { Search } = Input;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const NoneEndovascularFilter = ({
  valueSearch,
  providers,
  onChangeDate,
  onChangeProvider,
}) => {
  const [search, setSearch] = useState("");
  const [slary, setSlary] = useState([0, 10]);
  const changeSearch = (value) => {
    setSearch(value);
    valueSearch(value);
  };

  const menu = (
    <Menu>
      <Menu.Item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Slider
          range
          style={{ width: "100%" }}
          step={1}
          defaultValue={slary}
          onChange={(value) => {
            setSlary(value);
          }}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
            placeholder="Search Patient"
            onChange={(e) => changeSearch(e.target.value)}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <DatePicker
            format={dateFormat}
            placeholder="Filter by DOS"
            suffixIcon={<ExpandSVG />}
            onChange={onChangeDate}
          />
        </div>
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <Select
            placeholder="Filter by Provider"
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={onChangeProvider}
          >
            {providers.map((items) => {
              return (
                <Option key={items.id} value={items.id}>
                  {items.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <Dropdown overlay={menu} placement="bottomCenter">
          <div
            className="statusFilter"
            style={{
              minWidth: "200px",
              backgroundColor: "#fff",
              marginTop: 11,
              flexDirection: "column",
              minHeight: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 13 }}>{`${slary[0]}$ <--> ${slary[1]}$`}</p>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default NoneEndovascularFilter;
