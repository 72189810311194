import React, {useState} from 'react';
import Loading from '../../../common/components/Loading';
import { BASE_PATH } from '../../../common/constants/constants'

const QRCode = require('qrcode.react');

/**
 * === Status ===
 * @param {scanStatus} enum ['', 'loading', 'token_showed', 'matched', 'incorrect']
 * @param {scanToken}
 * @param {scannedImage}
 * === Actions ===
 * @param {onStartScan}
 * @param {onCheckScan}
 * @param {onCancel}
 */
const ScanInsuranceCard = ({ 
  scanStatus, scanToken, scannedImage,
  onStartScan, onCheckScan, onCancel
}) => {

  return (
    <>
      <hr />
      <div className="resourceInfo center">
        {
          scanStatus=='' &&
          <img
            src={'/images/drivers-license-ph.png'}
            alt="driver card"
            style={{ width: '80%', margin: 'auto' }}
          />
        }
        {
          scanStatus=='loading' &&
          <div style={{ height: '120px', position: "relative" }}>
            <Loading />
          </div>
        }
        {
          scanStatus=='token_showed' && 
          <QRCode value={scanToken} />
        }
        {
          ( scanStatus=='matched' || scanStatus=='incorrect' ) &&
          <>
            <img 
              src={BASE_PATH+scannedImage}
              alt='Insurance Card'
              style={{ width: '80%', margin: 'auto' }}
            />
            {
              scanStatus=='incorrect' &&
              <span className='aler alert-danger'>
                Insurance Card is not matching
              </span>
            }            
          </>
        }
      </div>
      <hr />
      <div className="actionContent no-padding">
        {
          ( scanStatus=='' || scanStatus=='incorrect' ) &&
          <button className="outlined-btn" onClick={onStartScan}>
            Scan Insurance
          </button>
        }
        {
          scanStatus=='loading' && 
          <button className='outlined-btn' onClick={onCancel}>
            Back
          </button>
        }
        {
          scanStatus=='token_showed' &&
          <button onClick={onCheckScan}>
            Verify Insurance
          </button>
        }
      </div>
      <hr />      
    </>
  )
}

export default ScanInsuranceCard;
