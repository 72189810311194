import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import HoursSidebar from "./HoursSidebar";
import HoursFilter from "./HoursFilter";
import { useWindowSize } from "../../../../common/utils";
import { StaticResource, DashboardRows } from "../../constant";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import { getUsers, getDetailUsers } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import SubTopBar from "../../../../common/components/SubTopBar";
import { BASE_API } from "../../../../common/constants/constants";
import { Icon } from "antd";


const Hours = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const users = useSelector((state) => state.common.users);
  // const usersDetail = useSelector((state) => state.common.usersDetail);

  const tableRows =
    users && users.active_users && users.active_users.length > 0
      ? users.active_users
      : [];

  let emptyRows = [];
  const size = useWindowSize(ref);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const setIdLocal = async (id) => {
    await localStorage.setItem("IdEmploy", JSON.stringify(id));
  };

  const handleRow = (index, id) => () => {
    setRow(index);
    if (index !== activeIndex) {
      // dispatch(getDetailUsers(id));
      setIdLocal(id);
      handleSidebar();
    }
  };

  const fetchApi = async () => {
    dispatch(getUsers());
  };

  useEffect(() => {
    fetchApi();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }
  const sidebarRef = useRef();

  const draggerProps = {
    name: "file",
    multiple: true,
    action: BASE_API + "process_timesheet",
  };
  
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <HoursFilter />
          <div className="tableHeader">
            <div className="tableItem">
              <div
                className="th"
                style={{ width: "calc(31% - 2rem)", marginLeft: "2rem" }}
              >
                <b>Employee Name</b>
              </div>
              <div className="th" style={{ width: "23%" }}>
                <b>Position</b>
              </div>
              <div className="th" style={{ width: "23%" }}>
                <b>Regular Hours</b>
              </div>
              <div className="th" style={{ width: "23%" }}>
                <b>OT Hours</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper css="no-padding x-hidden">
                <div className="tableList">
                  {tableRows.map((row, index) => (
                    <div
                      className={`tableItem yellow ${
                        activeIndex === index ? "active" : ""
                      }`}
                      key={`resultTable-${title}-${index}`}
                      onClick={handleRow(index, row.id)}
                    >
                      <div className="td with-icon" style={{ width: "31%" }}>
                        <div className="iconField">
                          {row.meta?.overtime ? <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/> : <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>}
                        </div>
                        <p>{row.name}</p>
                      </div>
                      <div className="td" style={{ width: "23%" }}>
                        <p>{row.position}</p>
                      </div>
                      <div className="td" style={{ width: "23%" }}>
                        <p>
                          {row.meta &&
                            row.meta.hours != null &&
                            row.meta.hours + " hrs"}
                        </p>
                      </div>
                      <div className="td" style={{ width: "23%" }}>
                        <p>
                          {row.meta &&
                            row.meta.overtime != null &&
                            row.meta.overtime + " hrs"}
                        </p>
                      </div>
                    </div>
                  ))}
                  {emptyRows.map((row, index) => (
                    <div
                      className={`tableItem empty`}
                      key={`resultTable-empty-${title}-${index}`}
                    >
                      <div className="td" style={{ width: "31%" }} />
                      <div className="td" style={{ width: "23%" }} />
                      <div className="td" style={{ width: "23%" }} />
                      <div className="td" style={{ width: "23%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>

      {tableRows && !!tableRows.length && activeIndex >= 0 && (
        <HoursSidebar
          ref={sidebarRef}
          usersDetail={
            tableRows && tableRows[activeIndex]
              ? tableRows[activeIndex]
              : undefined
          }
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}

      {tableRows && !!tableRows.length && activeIndex >= 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Hours;
