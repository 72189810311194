import React, { useState, useEffect, useMemo } from "react";
import { Input, DatePicker, Select, TimePicker } from "antd";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ExpandSVG, RedRequiredCircleSVG } from "../icons/SVGIcon";
import "./style.scss";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
const timeFormat = "h:mm A";
const phoneMask = "(999) 999-9999";
const ssnMask = "999-99-9999";

const EditableMaterialText = ({
  label,
  value,
  handleChange,
  type,
  isEditable,
  isError,
  required,
  options,
  optionKey,
  valueKey,
  enableErrorText,
  helperText,
  mask,
  noWider,
  noEditable,
  reset,
  fullWidth,
  setBlur,
  getActionBlur,
  disabled,
}) => {
  const [editable, setEditable] = useState(true);
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
  const [enableHelper, setEnableHelper] = useState(false);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (value && !title) {
      setTitle(value || "");
    }
    if (!value && value === undefined && title) {
      setTitle("");
    }
    if (!isError) {
      setError(false);
      setEnableHelper(false);
    }
    if (enableErrorText && required && isError) {
      setEnableHelper(true);
      setError(true);
    }
    if (reset) {
      setTitle(value || "");
      setError(false);
      setEnableHelper(false);
    }
  }, [value, isEditable, isError, enableErrorText, reset]);

  const enableBlur = () => {
    if (setBlur) {
      getActionBlur(label);
    }
    // if (isError) {
    //   setError(true);
    // }
  };

  const onChange = (e) => {
    setTitle(e.target.value);
    if (handleChange) {
      handleChange(e.target.value);
    }
  };

  const onChangeSelect = (opt) => {
    setTitle(opt);
    if (handleChange) {
      handleChange(opt);
    }
  };

  const onChangeDate = (date, dateString) => {
    setTitle(dateString);
    if (handleChange) {
      handleChange(dateString);
    }
  };
  const onChangeTime = (time, timeString) => {
    setTitle(timeString);
    if (handleChange) {
      handleChange(timeString);
    }
  };

  const lableContent = useMemo(() => {
    if (required) {
      return <span>{label}</span>;
    }
    return label;
  }, [required]);

  const errorText = useMemo(() => {
    if (enableHelper) {
      return (
        <span>
          <RedRequiredCircleSVG /> {helperText || ""}
        </span>
      );
    }
    return "";
  }, [enableHelper]);

  return (
    <div
      className={`editableText material ${fullWidth ? "fullWidth" : ""} ${
        isEditable || !title || editable ? "open" : "no-open"
      }`}
    >
      {noEditable && (
        <TextField
          variant="outlined"
          className={!noWider ? "wide" : ""}
          label={lableContent}
          value={title || ""}
          style={{ pointerEvents: 'none' }}
        />
      )}
      {!noEditable &&
        !mask &&
        (isEditable || !title || editable) &&
        (!type || (type !== "date" && type !== "time" && type !== "check")) && (
          <TextField
            variant="outlined"
            className={!noWider ? "wide" : ""}
            label={lableContent}
            value={title || ""}
            onChange={onChange}
            error={error}
            onBlur={enableBlur}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            helperText={errorText}
          />
        )}
      {!noEditable &&
        mask &&
        (isEditable || !title || editable) &&
        (!type || (type !== "date" && type !== "time" && type !== "check")) && (
          <InputMask
            mask={mask === "phone" ? phoneMask : ssnMask}
            value={title || ""}
            onChange={onChange}
            onBlur={enableBlur}
            maskPlaceholder={null}
          >
            <TextField
              variant="outlined"
              className={!noWider ? "wide" : ""}
              label={lableContent}
              error={error}
              helperText={errorText}
              style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            />
          </InputMask>
        )}
      {!noEditable &&
        (isEditable || !title || editable) &&
        type &&
        type === "date" && (
          <div
            className={`MuiFormControl-root MuiTextField-root date-picker ${
              errorText ? "error" : ""
            } ${focus ? "Mui-focused Mui-focused" : ""}`}
          >
            {!!title && (
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
                {lableContent}
              </label>
            )}
            <DatePicker
              value={!!title && moment(title).isValid() ? moment(title) : null}
              format={dateFormat}
              placeholder={label}
              onChange={onChangeDate}
              suffixIcon={<ExpandSVG />}
              style={{ pointerEvents: disabled ? 'none' : 'auto' }}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
            {!!errorText && (
              <p
                className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error ${
                  !noWider ? "wide" : ""
                }`}
              >
                {errorText}
              </p>
            )}
          </div>
        )}
      {!noEditable &&
        (isEditable || !title || editable) &&
        type &&
        type === "time" && (
          <div
            className={`MuiFormControl-root MuiTextField-root date-picker ${
              errorText ? "error" : ""
            }`}
          >
            {!!title && (
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
                {lableContent}
              </label>
            )}
            <TimePicker
              style={{ width: "100%", pointerEvents: disabled ? 'none' : 'auto' }}
              format={timeFormat}
              use12Hours
              value={
                !!title && moment(title, timeFormat).isValid()
                  ? moment(title, timeFormat)
                  : null
              }
              placeholder={label}
              suffixIcon={<ExpandSVG />}
              onChange={onChangeTime}
            />
            {!!errorText && (
              <p
                className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error ${
                  !noWider ? "wide" : ""
                }`}
              >
                {errorText}
              </p>
            )}
          </div>
        )}
      {!noEditable &&
        (isEditable || !title || editable) &&
        type &&
        type === "check" && (
          <div
            className={`MuiFormControl-root MuiTextField-root date-picker ${
              errorText ? "error" : ""
            }`}
          >
            {!!title && (
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
                {lableContent}
              </label>
            )}
            <Select
              showSearch={true}
              optionFilterProp="children"
              placeholder={label}
              suffixIcon={<ExpandSVG />}
              value={title || undefined}
              onChange={onChangeSelect}
              allowClear={true}
              style={{ pointerEvents: disabled ? 'none' : 'auto' }}
              filterOption={(input, opt) =>
                input &&
                opt.props.children &&
                opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
            >
              {(options || []).map((condition, index) => (
                <Option
                  key={`condition-${condition.id}-${index}`}
                  value={condition[optionKey || "id"]}
                >
                  {condition[valueKey] || condition.value}
                </Option>
              ))}
            </Select>
            {!!errorText && (
              <p
                className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error ${
                  !noWider ? "wide" : ""
                }`}
              >
                {errorText}
              </p>
            )}
          </div>
        )}
    </div>
  );
};

export default EditableMaterialText;
