import { createReducer } from "redux-create-reducer";

import AT from "../actionTypes";

const initialState = {
  pending: null,
  callback: null,
  resource: {},
  events: null,
  activeOpenings: null,
  closedDates: null,
  openingTime: null,
  closingTime: null,
  searchForPending: "",
  searchForCallback: "",
  loadingPending: false,
  loadingCallback: false,
  loadingResource: false,
  loadingEvents: false,
  errorMessage: "",
  patientProcedures: [],
  // sidebar
  isConfirmed: false,
  rescheduled: false,
  timeSlots: [],
  usSlots: [],
  followupSlots: [],
  providers: [],
  locations: [],
  clincLocations: [],
  followupProviders: [],
  planDetails: {},
  loadingSchedule: false,
  loadingTimeSlots: false,
  loadingFollowUp: false,
  loadingPatientStart: false,
  timeSlot: -1,
  followSlot: -1,
  searchDrug: [],
  pre_operative_procedure: {},
  post_operative_procedure: {},
  search_plan_all: [],
  get_plan_patient: [],
  loadingSaveNewProcedure: false,
  detail_save_new_procedure: {},
  loadingDeletePlan: false,
  // procedurals
  loading_procedurals: false,
  list_procedurals: [],
  procedure: {},
  existing_followup: {},
  questions: [],
  loadingQuestion: false,
  call_attempts: [],
  loadingCallAttempt: false,
};
// procedurals
const LOAD_PROCEDURALS_STARTED = `${AT.LOAD_PROCEDURALS}_STARTED`;
const LOAD_PROCEDURALS_SUCCEEDED = `${AT.LOAD_PROCEDURALS}_SUCCEEDED`;
const SAVE_PROCEDURALS_STARTED = `${AT.SAVE_PROCEDURALS}_STARTED`;
const SAVE_PROCEDURALS_SUCCEEDED = `${AT.SAVE_PROCEDURALS}_SUCCEEDED`;

// MainSection
const GET_PROCEDURE_PENDING_SUCCEEDED = `${AT.GET_PROCEDURE_PENDING}_SUCCEEDED`;
const GET_PROCEDURE_CALLBACK_SUCCEEDED = `${AT.GET_PROCEDURE_CALLBACK}_SUCCEEDED`;
const GET_PROCEDURE_EVENTS_SUCCEEDED = `${AT.GET_PROCEDURE_EVENTS}_SUCCEEDED`;
const SAVE_PROCEDURE_SCHEDULE_SUCCEEDED = `${AT.SAVE_PROCEDURE_SCHEDULE}_SUCCEEDED`;
const SAVE_ULTRASOUND_SCHEDULE_SUCCEEDED = `${AT.SAVE_ULTRASOUND_SCHEDULE}_SUCCEEDED`;
const SAVE_PLAN_CLINIC_SCHEDULE_SUCCEEDED = `${AT.SAVE_PLAN_CLINIC_SCHEDULE}_SUCCEEDED`;
const SAVE_PROCEDURE_QUESTION_SUCCEEDED = `${AT.SAVE_PROCEDURE_QUESTION}_SUCCEEDED`;
const RESCHEDULE_PROCEDURE_SUCCEEDED = `${AT.RESCHEDULE_PROCEDURE}_SUCCEEDED`;
const RESCHEDULE_PROCEDURE_STARTED = `${AT.RESCHEDULE_PROCEDURE}_STARTED`;

const GET_TIME_SLOTS_SUCCEEDED = `${AT.GET_TIME_SLOTS}_SUCCEEDED`;
const GET_TIME_SLOTS_STARTED = `${AT.GET_TIME_SLOTS}_STARTED`;
const GET_FOLLOW_UP_STARTED = `${AT.GET_FOLLOW_UP}_STARTED`;
const GET_FOLLOW_UP_SUCCEEDED = `${AT.GET_FOLLOW_UP}_SUCCEEDED`;
const SELECT_PROCEDURE_SUCCEEDED = `${AT.SELECT_PROCEDURE}_SUCCEEDED`;

const CONFIRM_PROCEDURE_STARTED = `${AT.CONFIRM_PROCEDURE}_STARTED`;
const CONFIRM_PROCEDURE_SUCCEEDED = `${AT.CONFIRM_PROCEDURE}_SUCCEEDED`;
const SET_PROCEDURE_DATA_SUCCEEDED = `${AT.SET_PROCEDURE_DATA}_SUCCEEDED`;
const GET_PATIENT_PROCEDURE_STARTED = `${AT.GET_PATIENT_PROCEDURE}_STARTED`;
const GET_PATIENT_PROCEDURE_SUCCEEDED = `${AT.GET_PATIENT_PROCEDURE}_SUCCEEDED`;
const SEARCH_DRUG_SUCCEEDED = `${AT.SEARCH_DRUG}_SUCCEEDED`;
const GET_PRE_SUCCEEDED = `${AT.GET_PRE}_SUCCEEDED`;
const GET_POST_OPERATIVE_SUCCEEDED = `${AT.GET_POST_OPERATIVE}_SUCCEEDED`;

const SEARCH_PLAN_SUGGESTION_SUCCEEDED = `${AT.SEARCH_PLAN_SUGGESTION}_SUCCEEDED`;
const CHOOSE_SELECTED_PATIENT_RESOURCE_STARTED = `${AT.CHOOSE_SELECTED_PATIENT_RESOURCE}_STARTED`;
const GET_PLAN_PATIENT_SUCCEEDED = `${AT.GET_PLAN_PATIENT}_SUCCEEDED`;
const CHOOSE_PATIENT_ON_SCHEDULES_STARTED = `${AT.CHOOSE_PATIENT_ON_SCHEDULES}_STARTED`;
const SET_PATIENT_FOR_RECORDS_SUCCEEDED = `${AT.SET_PATIENT_FOR_RECORDS}_SUCCEEDED`;

const ADD_PROCEDURES_STARTED = `${AT.ADD_PROCEDURES}_STARTED`;
const ADD_PROCEDURES_SUCCEEDED = `${AT.ADD_PROCEDURES}_SUCCEEDED`;

const DELETE_PLAN_STARTED = `${AT.DELETE_PLAN}_STARTED`;
const DELETE_PLAN_SUCCEEDED = `${AT.DELETE_PLAN}_SUCCEEDED`;

const GET_SINGLE_PROCEDURE_STARTED = `${AT.GET_SINGLE_PROCEDURE}_STARTED`;
const GET_SINGLE_PROCEDURE_SUCCEEDED = `${AT.GET_SINGLE_PROCEDURE}_SUCCEEDED`;

const GET_EXISTING_FOLLOW_UP_SUCCEEDED = `${AT.GET_EXISTING_FOLLOW_UP}_SUCCEEDED`;
const RESET_FOLOW_SUCCEEDED = `${AT.RESET_FOLOW}_SUCCEEDED`;

const GET_QUESTIONS_FOR_PROCEDURE_STARTED = `${AT.GET_QUESTIONS_FOR_PROCEDURE}_STARTED`;
const GET_QUESTIONS_FOR_PROCEDURE_SUCCEEDED = `${AT.GET_QUESTIONS_FOR_PROCEDURE}_SUCCEEDED`;

const GET_PROCEDURE_CALL_ATTEMPT_STARTED = `${AT.GET_PROCEDURE_CALL_ATTEMPT}_STARTED`;
const GET_PROCEDURE_CALL_ATTEMPT_SUCCEEDED = `${AT.GET_PROCEDURE_CALL_ATTEMPT}_SUCCEEDED`;

const SAVE_PROCEDURE_CHOICE_SUCCEEDED = `${AT.SAVE_PROCEDURE_CHOICE}_SUCCEEDED`;

const ADD_PROCEDURE_CALL_ATTEMPT_SUCCEEDED = `${AT.ADD_PROCEDURE_CALL_ATTEMPT}_SUCCEEDED`;

export default createReducer(initialState, {
  [RESCHEDULE_PROCEDURE_STARTED](state, action) {
    const check = action.payload;
    if (check && check[3] && check[3] == true) {
      return Object.assign({}, state, {
        loadingTimeSlots: true,
      });
    } else {
      return Object.assign({}, state, {
        loadingTimeSlots: true,
        loadingFollowUp: true
      });
    }
  },
  [GET_FOLLOW_UP_STARTED](state) {
    return Object.assign({}, state, {
      loadingFollowUp: true,
    });
  },
  [GET_TIME_SLOTS_STARTED](state) {
    return Object.assign({}, state, {
      loadingTimeSlots: true,
    });
  },
  [RESET_FOLOW_SUCCEEDED](state) {
    return Object.assign({}, state, {
      followupSlots: [],
    });
  },
  //
  [SAVE_PROCEDURALS_STARTED](state) {
    return Object.assign({}, state, {
      loading_procedurals: true,
    });
  },
  [SAVE_PROCEDURALS_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_procedurals: false,
    });
  },
  [LOAD_PROCEDURALS_STARTED](state) {
    return Object.assign({}, state, {
      loading_procedurals: true,
    });
  },
  [LOAD_PROCEDURALS_SUCCEEDED](state, action) {
    const list_procedurals = action.payload;
    return Object.assign({}, state, {
      loading_procedurals: false,
      list_procedurals,
    });
  },
  //
  [ADD_PROCEDURES_STARTED](state) {
    return Object.assign({}, state, {
      loadingSaveNewProcedure: true,
    });
  },
  [ADD_PROCEDURES_SUCCEEDED](state, action) {
    const detail_save_new_procedure = action.payload;
    return Object.assign({}, state, {
      detail_save_new_procedure,
      loadingSaveNewProcedure: false,
    });
  },

  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      pending: null,
      callback: null,
      resource: {},
      events: null,
      activeOpenings: null,
      closedDates: null,
      openingTime: null,
      closingTime: null,
      searchForPending: "",
      searchForCallback: "",
      loadingPending: false,
      loadingCallback: false,
      loadingResource: false,
      loadingEvents: false,
      errorMessage: "",
      patientProcedures: [],
      // sidebar
      isConfirmed: false,
      rescheduled: false,
      timeSlots: [],
      usSlots: [],
      followupSlots: [],
      providers: [],
      locations: [],
      clincLocations: [],
      followupProviders: [],
      planDetails: {},
      loadingSchedule: false,
      loadingTimeSlots: false,
      loadingFollowUp: false,
      loadingPatientStart: false,
      timeSlot: -1,
      followSlot: -1,
      searchDrug: [],
      pre_operative_procedure: {},
      post_operative_procedure: {},
      search_plan_all: [],
      get_plan_patient: [],
    };
  },
  [SET_PATIENT_FOR_RECORDS_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      patientProcedures: [],
    });
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [GET_PATIENT_PROCEDURE_STARTED](state) {
    return Object.assign({}, state, {
      patientProcedures: [],
    });
  },
  [SEARCH_PLAN_SUGGESTION_SUCCEEDED](state, action) {
    const search_plan_all = action.payload;
    return Object.assign({}, state, { search_plan_all });
  },

  [CHOOSE_PATIENT_ON_SCHEDULES_STARTED](state) {
    return Object.assign({}, state, {
      get_plan_patient: [],
      patientProcedures: [],
      loadingPatientStart: true,
    });
  },

  [CHOOSE_SELECTED_PATIENT_RESOURCE_STARTED](state) {
    return Object.assign({}, state, {
      get_plan_patient: [],
      patientProcedures: [],
      loadingPatientStart: true,
    });
  },

  [GET_PLAN_PATIENT_SUCCEEDED](state, action) {
    const get_plan_patient = action.payload;
    return Object.assign({}, state, {
      get_plan_patient,
      loadingPatientStart: false,
    });
  },
  [GET_PRE_SUCCEEDED](state, action) {
    const pre_operative_procedure = action.payload;
    return Object.assign({}, state, { pre_operative_procedure });
  },
  [GET_POST_OPERATIVE_SUCCEEDED](state, action) {
    const post_operative_procedure = action.payload;
    return Object.assign({}, state, { post_operative_procedure });
  },
  [SET_PROCEDURE_DATA_SUCCEEDED](state, action) {
    return Object.assign({}, state, { ...action.payload });
  },
  [SEARCH_DRUG_SUCCEEDED](state, action) {
    const searchDrug = action.payload;
    return Object.assign({}, state, { searchDrug });
  },
  // Get pending process
  [GET_PROCEDURE_PENDING_SUCCEEDED](state, action) {
    const pending = action.payload;
    return Object.assign({}, state, { pending, loadingPending: false });
  },
  [GET_PROCEDURE_CALLBACK_SUCCEEDED](state, action) {
    const callback = action.payload;
    return Object.assign({}, state, { callback, loadingCallback: false });
  },
  [GET_PROCEDURE_EVENTS_SUCCEEDED](state, action) {
    const {
      events,
      active_openings: activeOpenings,
      closed_dates: closedDates,
      opening_time: openingTime,
      closing_time: closingTime
    } = action.payload;
    return Object.assign({}, state, {
      events,
      activeOpenings,
      closedDates,
      openingTime,
      closingTime,
      loadingEvents: false,
    });
  },
  [CONFIRM_PROCEDURE_STARTED](state, action) {
    return Object.assign({}, state, {
      isConfirmed: false,
      loadingResource: false,
    });
  },
  [CONFIRM_PROCEDURE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      isConfirmed: true,
      loadingResource: false,
    });
  },
  [SELECT_PROCEDURE_SUCCEEDED](state, action) {
    const resource = action.payload;
    return Object.assign({}, state, {
      resource,
      loadingResource: false,
      isConfirmed: false,
      rescheduled: false,
      timeSlots: [],
      usSlots: [],
      followupSlots: [],
      providers: [],
      locations: [],
      clincLocations: [],
      followupProviders: [],
      planDetails: {},
      timeSlot: -1,
      followSlot: -1,
    });
  },
  [RESCHEDULE_PROCEDURE_SUCCEEDED](state, action) {
    const data = action.payload;
    let planDetails = {};
    let timeSlots,
      followupSlots,
      usSlots,
      providers,
      locations,
      clincLocations,
      followupProviders = [];
    if (data) {
      planDetails = data.procedure_detail;
      if (data.time_slots.status !== "no_schedule") {
        const slotsData = data.time_slots && data.time_slots.data;
        if (slotsData) {
          const keysOfTimeSlots = Object.keys(slotsData);
          timeSlots = keysOfTimeSlots[0] ? slotsData[keysOfTimeSlots[0]] : [];
        }
      } else {
        timeSlots = [];
      }
      //followupSlots = [];
      usSlots = data.us_slots;
      providers = data.providers;
      console.log('providers should be loaded here', providers);
      locations = data.locations;
      clincLocations = data.clinic_locations;
      followupProviders = data.followup_providers;
    }
    return Object.assign({}, state, {
      planDetails,
      timeSlots,
      usSlots,
      providers,
      locations,
      clincLocations,
      followupProviders,
      rescheduled: true,
      loadingSchedule: false,
      loadingTimeSlots: false,
    });
  },
  [GET_TIME_SLOTS_SUCCEEDED](state, action) {
    const data = action.payload;
    let timeSlots = [];
    if (data) {
      const slotsData = data.time_slots && data.time_slots.data;
      if (slotsData) {
        const keysOfTimeSlots = Object.keys(slotsData);
        timeSlots = keysOfTimeSlots[0] ? slotsData[keysOfTimeSlots[0]] : [];
      }
    }
    return Object.assign({}, state, {
      timeSlots,
      loadingTimeSlots: false,
    });
  },
  [GET_FOLLOW_UP_SUCCEEDED](state, action) {
    const data = action.payload;
    let followupSlots,
      usSlots = [];
    if (data) {

      followupSlots =
        data.followup_slots && data.followup_slots[0]
          ? data.followup_slots[0]
          : [];
      usSlots = data.us_slots;
      console.log(followupSlots);
    }
    return Object.assign({}, state, {
      followupSlots,
      usSlots,
      loadingFollowUp: false,
    });
  },
  [SAVE_PROCEDURE_SCHEDULE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingSchedule: false,
    });
  },
  [SAVE_ULTRASOUND_SCHEDULE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingSchedule: false,
    });
  },
  [SAVE_PLAN_CLINIC_SCHEDULE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingSchedule: false,
    });
  },
  [SAVE_PROCEDURE_QUESTION_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingSchedule: false,
    })
  },
  [SAVE_PROCEDURE_CHOICE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingSchedule: false,
    })
  },
  [GET_PATIENT_PROCEDURE_SUCCEEDED](state, action) {
    const patientProcedures = action.payload;
    return Object.assign({}, state, {
      patientProcedures,
    });
  },
  [DELETE_PLAN_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingDeletePlan: true,
    });
  },
  [DELETE_PLAN_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingDeletePlan: false,
    });
  },
  [GET_SINGLE_PROCEDURE_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingResource: true,
    });
  },
  [GET_SINGLE_PROCEDURE_SUCCEEDED](state, action) {
    const procedure = action.payload;
    return Object.assign({}, state, {
      procedure,
      loadingResource: false,
    });
  },
  [GET_EXISTING_FOLLOW_UP_SUCCEEDED](state, action) {
    const existing_followup = action.payload;
    return Object.assign({}, state, {
      existing_followup,
    });
  },
  [GET_QUESTIONS_FOR_PROCEDURE_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingQuestion: true,
    });
  },
  [GET_QUESTIONS_FOR_PROCEDURE_SUCCEEDED](state, action) {
    const questions = action.payload;
    return Object.assign({}, state, {
      questions,
      loadingQuestion: false,
    });
  },
  [GET_PROCEDURE_CALL_ATTEMPT_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingCallAttempt: true,
    });
  },
  [GET_PROCEDURE_CALL_ATTEMPT_SUCCEEDED](state, action) {
    const call_attempts = action.payload;
    return Object.assign({}, state, {
      call_attempts,
      loadingCallAttempt: false,
    });
  },
  [ADD_PROCEDURE_CALL_ATTEMPT_SUCCEEDED](state, action) {
    let call_attempts = [];
    const result = action.payload;
    if (typeof state.call_attempts == "undefined") {
      call_attempts = result;
    } else {
      call_attempts = [...state.call_attempts, result];
    }
    return Object.assign({}, state, { call_attempts });
  },
});
