import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  CloseIcon,
  DugsSVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import EditFormInput from "../../../../common/components/EditableText/LoginFormInput";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import SubDataTable from "../../../../common/components/SubDataTable";
import {
  setInventoryData,
  addNewInventory,
  updateAngioSupply,
  addInventoryCostHistory,
  deleteInventoryItem,
  addToPurchaseList,
  updateInventoryNotes,
  setGlobalLoading,
} from "../../../../store/actions";
import { Icon } from "antd";

const filterSuggestion = (suggestions, userInput) => {
  let filteredSuggestions = [];
  if (!userInput) {
    filteredSuggestions = suggestions;
  }
  if (userInput) {
    filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
  }
  return filteredSuggestions;
};

const titleStyle = {
  textAlign: 'left',
  paddingLeft: '11px',
  color: 'rgba(0, 0, 0, 0.65)',
  fontWeight: 600,
  borderLeft: '1px solid #dfe3e6',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  fontSize: '14px'
}

const getTitle = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ ...titleStyle, width: "50%", paddingLeft: 0, borderLeft: 0 }}>Date</div>
      <div style={{ ...titleStyle, width: "50%", textAlign: "left" }}>Cost Per Item</div>
    </div>
  );
};

const SuppliesSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar }, ref) => {
  const inventoryItem = useSelector((state) => state.inventory.inventory);
  const loading = useSelector((state) => state.inventory.loadingAPI);
  const updated = useSelector((state) => state.inventory.isUpdated);
  const deleted = useSelector((state) => state.inventory.isDeleted);
  const costHistory = useSelector(
    (state) => state.inventory.inventoryCostHistory
  );
  const loadingCost = useSelector(
    (state) => state.inventory.loadingCostHistory
  );

  const [notes, setNotes] = useState([]);
  const [searchInput, setInput] = useState("");
  const [item, setItem] = useState();
  const [isAdding, setIsAdding] = useState(false);
  const [purchaseNumber, setPurchaseNumber] = useState();
  const [isPurchase, setIsPurchase] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (inventoryItem) {
      const initialNotes = inventoryItem.notes
        ? inventoryItem.notes.split(",")
        : [];
      setNotes(initialNotes);
    }
    setItem(inventoryItem);
  }, [inventoryItem]);

  useEffect(() => {
    if (updated) {
      dispatch(setInventoryData("isUpdated", false));
      setIsAdding(false);
    }
    if (deleted) {
      dispatch(setInventoryData("isDeleted", false));
      dispatch(setInventoryData("inventory", null));
      dispatch(setInventoryData("inventoryCostHistory", []));
      setIsDelete(false);
      setItem(null);
    }
  }, [updated, deleted]);

  useEffect(() => {
    if (inventoryItem && notes.length) {
      const itemNotes = notes.join(",");
      const updatedItem = { ...item, notes: itemNotes };
      setItem(updatedItem);
    }
  }, [notes]);

  useEffect(() => {
    if (isShow && item) {
      dispatch(setGlobalLoading(loadingCost || loading))
    }
  }, [loading, loadingCost, isShow, item]);

  const noteRows = useMemo(() => {
    if (searchInput) {
      return filterSuggestion(notes, searchInput);
    }
    return notes;
  }, [notes, searchInput]);

  const openAddingForm = () => {
    setIsAdding(true);
  };

  const handleAddInventory = (inventory) => {
    dispatch(addNewInventory({ ...inventory, type: "angiographic" }));
  };

  const onChangeInventory = (type) => (value) => {
    const updatedItem = { ...item, [type]: value };
    setItem(updatedItem);
  };

  const handleSearch = (search) => {
    setInput(search);
  };

  const updateNotes = (note) => {
    const request = {
      type: inventoryItem.type,
      notes: note,
    };
    dispatch(updateInventoryNotes(inventoryItem.id, request));
  };

  const addNote = () => {
    const newNotes = [...notes, searchInput];
    setNotes(newNotes);
    setInput("");
    updateNotes(newNotes.join(","));
  };

  const removeNote = (row, index) => {
    const newNotes = [...notes];
    newNotes.splice(index, 1);
    setNotes(newNotes);
    updateNotes(newNotes.join(","));
  };

  const cost_per_item = useMemo(() => {
    if (item && item.cost) {
      const quantity = item.quantity ? item.quantity : 1;
      return (item.cost / quantity).toFixed(2);
    }
    return null;
  }, [item]);

  const costRows = useMemo(() => {
    if (costHistory && costHistory.length) {
      return costHistory.map((ch) => ({
        item: ch.created_at
          ? moment(ch.created_at).format("MM/DD/YYYY")
          : undefined,
        amount: "$" + parseFloat(ch.cost_per_item).toFixed(2),
      }));
    }
    if (cost_per_item) {
      return [
        {
          item: moment().format("MM/DD/YYYY"),
          amount: "$" + parseFloat(cost_per_item).toFixed(2),
        },
      ];
    }
    return [];
  }, [costHistory]);

  const validatePurchaseNumber = () => {
    const regex = /^\d+$/;
    if (regex.test(purchaseNumber) && purchaseNumber > 0) {
      return false;
    }
    return true;
  };

  // Save updated item
  const onUpdateItem = () => {
    const costRequest = {
      type: "angiographic",
      cost_per_item: cost_per_item,
    };
    dispatch(updateAngioSupply(inventoryItem.id, item));

    if (!costHistory.length && inventoryItem.cost) {
      const quantity = inventoryItem.quantity ? inventoryItem.quantity : 1;
      const costItem =
        Math.round(((inventoryItem.cost || 0) * 100) / quantity) / 100;

      dispatch(
        addInventoryCostHistory(inventoryItem.id, {
          type: inventoryItem.type,
          cost_per_item: costItem,
        })
      );
    }
    if (inventoryItem.cost && item.cost && inventoryItem.cost !== item.cost) {
      dispatch(addInventoryCostHistory(inventoryItem.id, costRequest));
    }
  };

  // Delete inventory item
  const onDelete = () => {
    const param = {
      type: "angiographic",
    };

    dispatch(deleteInventoryItem(inventoryItem.id, param));
  };

  // Add inventory to Purchase List
  const onAddToPurchaseList = () => {
    if (validatePurchaseNumber()) {
      setIsPurchase(true);
    } else {
      onUpdateItem();
      dispatch(
        addToPurchaseList(inventoryItem.id, {
          quantity: item.quantity || 0,
          purchase_number: +purchaseNumber,
          type: "angiographic",
        })
      );
    }
  };

  if (!isShow || !item) {
    return null;
  }

  return (
    <>
      <div
        ref={ref}
        className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
      >
        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          {!isAdding && (
            <SidebarTitle
              icon={<Icon type="tag" theme="twoTone" twoToneColor="#6929C4"/>}
              title={item.name || ""}
              subTitle=""
            />
          )}

          {!isAdding && !isDelete && (
            <div
              className="resourceContent"
              style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
            >
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Product No"} value={item.product_number} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Code No"} value={item.code_number} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Category"} value={"Angiographic Supply"} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Brand"} value={item.brand} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"PAR Level"} value={item.par_level} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Bin Rate"} value={item.bin_rate} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Location"} value={item.location} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Specific Location"}
                  value={item.specific_location}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Supplier E-Mail"}
                  value={item.supplier_email}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Supplier Fax"} value={item.supplier_fax} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Quantity per unit"}
                  value={item.quantity}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Unit"} value={item.unit} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Cost"}
                  value={item.cost && parseFloat(item.cost).toFixed(2)}
                  prefix={"$"}
                />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput
                  label={"Cost/Item"}
                  prefix={"$"}
                  value={cost_per_item}
                />
              </div>
              <div
                className="resourceInfo d-flex"
                style={{ marginTop: "0.5rem" }}
              >
                <b style={{ minWidth: "35%" }}>Cost History </b>
              </div>
              <div className="resourceInfo" style={{ position: "relative" }}>
                <SubDataTable title={getTitle()} data={costRows} half={true} />
              </div>
              <hr />

              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%" }}>Notes </b>
              </div>
              <div className="resourceInfo d-flex">
                <AutoCompleteComponent
                  title="inventory-notes"
                  rows={noteRows}
                  removeItem={removeNote}
                  addItem={addNote}
                  handleSearch={handleSearch}
                  disableOptions={true}
                  placeholder={"Add Information Note"}
                  disableSearchIcon={true}
                />
              </div>
              <div
                className="resourceInfo d-flex"
                style={{ marginTop: "2rem", alignItems: "center" }}
              >
                <b style={{ marginRight: "0.25rem" }}>Purchase </b>
                <div
                  style={{
                    width: "110px",
                    minWidth: "110px",
                    maxWidth: "110px",
                  }}
                >
                  <EditFormInput
                    label={"Number"}
                    required={true}
                    value={purchaseNumber}
                    enableErrorText={isPurchase}
                    handleChange={(value) => setPurchaseNumber(value)}
                    isError={validatePurchaseNumber()}
                    helperText={""}
                  />
                </div>
                <b style={{ marginLeft: "0.25rem" }}>{` at ${
                  item.cost ? "$" + parseFloat(item.cost).toFixed(2) : ""
                } ${item.unit || ""}`}</b>
              </div>
              <hr />
            </div>
          )}
          {!isAdding && !isDelete && (
            <div
              className="actionContent"
              style={{
                marginTop: "1.5rem",
                paddingRight: "1rem",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                className="outlined-btn"
                style={{ marginTop: "0.5rem" }}
                onClick={() => setIsDelete(true)}
              >
                Remove
              </button>
            </div>
          )}

          {isDelete && (
            <>
              <div className="resourceInfo">
                <b style={{ width: "100%", textAlign: "center" }}>
                  Do you really want to delete this inventory?
                </b>
              </div>
              <div
                className="actionContent"
                style={{
                  marginTop: "1.5rem",
                  paddingRight: "1rem",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  className="outlined-btn"
                  style={{ marginTop: "0.5rem" }}
                  onClick={() => setIsDelete(false)}
                >
                  Back
                </button>
                <button
                  onClick={onDelete}
                  className="outlined-btn"
                  style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                >
                  Delete
                </button>
              </div>
            </>
          )}
        </ScrollWrapper>
      </div>
    </>
  );
});

export default SuppliesSidebar;
