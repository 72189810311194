import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const setProcedureDetails = createActionThunk(
  AT.SET_PROCEDURE_DETAILS,
  (type, newData) => ({ [type]: newData })
);

export const addProcedureDetails = createActionThunk(
  AT.ADD_PROCEDURE_DETAILS,
  (type, data) => ({ type, data })
);

export const refrest_state_tab = createActionThunk(
  AT.REFREST_STATE_TAB,
  () => {}
);

export const reset_new_patient = createActionThunk(
  AT.RESET_NEW_PATIENT,
  () => {}
);

export const getProcedureType = createActionThunk(AT.GET_PROCEDURE_TYPE, (id) =>
  API.getProcedureType(id)
);

export const saveProcedureType = createActionThunk(
  AT.SAVE_PROCEDURE_TYPE,
  (params) => API.saveProcedureType(params)
);

export const editProcedureType = createActionThunk(
  AT.SAVE_PROCEDURE_TYPE,
  (id, params) => API.editProcedureType(id, params)
);

export const deleteProcedureType = createActionThunk(
  AT.DELETE_PROCEDURE_TYPE,
  (id) => API.deleteProcedureType(id)
);

export const text_template_procedure = createActionThunk(
  AT.TEXT_TEMPLATE_PROCEDURE,
  (id, params) => API.text_template_procedure(id, params)
);

export const delete_referral_conditions = createActionThunk(
  AT.DELETE_REFERRAL_CONDITIONS,
  (id) => API.delete_referral_conditions(id)
);

// Condition type
export const getConditionVariable = createActionThunk(
  AT.GET_CONDITION_TYPE,
  (id) => API.getConditionVariable(id)
);

export const saveConditionVariable = createActionThunk(
  AT.SAVE_CONDITION_TYPE,
  (params) => API.saveConditionVariable(params)
);

export const editConditionVariable = createActionThunk(
  AT.EDIT_CONDITION_TYPE,
  (id, params) => API.editConditionVariable(id, params)
);

export const deleteConditionVariable = createActionThunk(
  AT.DELETE_CONDITION_TYPE,
  (id) => API.deleteConditionVariable(id)
);

export const updateConditionVariableICD = createActionThunk(
  AT.UPDATE_CONDITION_TYPE_ICD,
  (id, params) => API.updateConditionVariableICD(id, params)
);

export const update_cpt_rule = createActionThunk(
  AT.UPDATE_CPT_RULE,
  (id, params) => API.update_cpt_rule(id, params)
);

export const update_icd_rule = createActionThunk(
  AT.UPDATE_ICD_RULE,
  (id, params) => API.update_icd_rule(id, params)
);

export const text_template_condition = createActionThunk(
  AT.TEXT_TEMPLATE_CONDITION,
  (id, params) => API.text_template_condition(id, params)
);

export const text_template_ultrasound = createActionThunk(
  AT.TEXT_TEMPLATE_ULTRASOUND,
  (id, params) => API.text_template_ultrasound(id, params)
);

export const update_plan_condition = createActionThunk(
  AT.UPDATE_PLAN_CONDITION,
  (id, params) => API.update_plan_condition(id, params)
);
// clinic_procedure_type_variable
export const list_clinic_procedure_type_variable = createActionThunk(
  AT.LIST_CLINIC_PROCEDURE_TYPE_VARIABLE,
  (id) => API.list_clinic_procedure_type_variable(id)
);
export const save_clinic_procedure_type_variable = createActionThunk(
  AT.SAVE_CLINIC_PROCEDURE_TYPE_VARIABLE,
  (params) => API.save_clinic_procedure_type_variable(params)
);
export const detail_clinic_procedure_type_variable = createActionThunk(
  AT.DETAIL_CLINIC_PROCEDURE_TYPE_VARIABLE,
  (id) => API.detail_clinic_procedure_type_variable(id)
);
export const edit_clinic_procedure_type_variable = createActionThunk(
  AT.EDIT_CLINIC_PROCEDURE_TYPE_VARIABLE,
  (id, params) => API.edit_clinic_procedure_type_variable(id, params)
);
export const delete_clinic_procedure_type_variable = createActionThunk(
  AT.DELETE_CLINIC_PROCEDURE_TYPE_VARIABLE,
  (id) => API.delete_clinic_procedure_type_variable(id)
);

// clinic procedure type
export const list_clinic_procedure_type = createActionThunk(
  AT.LIST_CLINIC_PROCEDURE_TYPE,
  () => API.list_clinic_procedure_type()
);

export const save_clinic_procedure_type = createActionThunk(
  AT.SAVE_CLINIC_PROCEDURE_TYPE,
  (params) => API.save_clinic_procedure_type(params)
);

export const detail_clinic_procedure_type = createActionThunk(
  AT.DETAIL_CLINIC_PROCEDURE_TYPE,
  (id) => API.detail_clinic_procedure_type(id)
);

export const edit_clinic_procedure_type = createActionThunk(
  AT.EDIT_CLINIC_PROCEDURE_TYPE,
  (id, params) => API.edit_clinic_procedure_type(id, params)
);

export const delete_clinic_procedure_type = createActionThunk(
  AT.DELETE_CLINIC_PROCEDURE_TYPE,
  (id) => API.delete_clinic_procedure_type(id)
);

export const update_text_clinic_procedure_type = createActionThunk(
  AT.UPDATE_TEXT_CLINIC_PROCEDURE_TYPE,
  (id, params) => API.update_text_clinic_procedure_type(id, params)
);

export const update_cpt_clinic_procedure_type = createActionThunk(
  AT.UPDATE_CPT_CLINIC_PROCEDURE_TYPE,
  (id, params) => API.update_cpt_clinic_procedure_type(id, params)
);

export const update_icd_clinic_procedure_type = createActionThunk(
  AT.UPDATE_ICD_CLINIC_PROCEDURE_TYPE,
  (id, params) => API.update_icd_clinic_procedure_type(id, params)
);

// angio_type

export const get_angio_type = createActionThunk(AT.GET_ANGIO_TYPE, () =>
  API.get_angio_type()
);

export const save_angio_type = createActionThunk(AT.SAVE_ANGIO_TYPE, (params) =>
  API.save_angio_type(params)
);

export const detail_angio_type = createActionThunk(AT.DETAIL_ANGIO_TYPE, (id) =>
  API.detail_angio_type(id)
);

export const edit_angio_type = createActionThunk(
  AT.EDIT_ANGIO_TYPE,
  (id, params) => API.edit_angio_type(id, params)
);

export const delete_angio_type = createActionThunk(AT.DELETE_ANGIO_TYPE, (id) =>
  API.delete_angio_type(id)
);

export const update_angio_type_template = createActionThunk(
  AT.UPDATE_ANGIO_TYPE_TEMPLATE,
  (id, params) => API.update_angio_type_template(id, params)
);

// angio_type_variable
export const get_angio_type_variable = createActionThunk(
  AT.GET_ANGIO_TYPE_VARIABLE,
  (id) => API.get_angio_type_variable(id)
);

export const save_angio_type_variable = createActionThunk(
  AT.SAVE_ANGIO_TYPE_VARIABLE,
  (params) => API.save_angio_type_variable(params)
);

export const detail_angio_type_variable = createActionThunk(
  AT.DETAIL_ANGIO_TYPE_VARIABLE,
  (id) => API.detail_angio_type_variable(id)
);

export const edit_angio_type_variable = createActionThunk(
  AT.EDIT_ANGIO_TYPE_VARIABLE,
  (id, params) => API.edit_angio_type_variable(id, params)
);

export const delete_angio_type_variable = createActionThunk(
  AT.DELETE_ANGIO_TYPE_VARIABLE,
  (id) => API.delete_angio_type_variable(id)
);

// me_variable

export const get_me_variable_select = createActionThunk(
  AT.GET_ME_VARIABLE_SELECT,
  () => API.get_me_variable_select()
);

// me_variable_rule
export const get_me_variable_rule = createActionThunk(
  AT.GET_ME_VARIABLE_RULE,
  (type) => API.get_me_variable_rule(type)
);

export const save_me_variable_rule = createActionThunk(
  AT.SAVE_ME_VARIABLE_RULE,
  (params) => API.save_me_variable_rule(params)
);

export const detail_me_variable_rule = createActionThunk(
  AT.DETAIL_ME_VARIABLE_RULE,
  (id) => API.detail_me_variable_rule(id)
);

export const delete_me_variable_rule = createActionThunk(
  AT.DELETE_ME_VARIABLE_RULE,
  (id) => API.delete_me_variable_rule(id)
);

export const edit_me_variable_rule = createActionThunk(
  AT.EDIT_ME_VARIABLE_RULE,
  (id, params) => API.edit_me_variable_rule(id, params)
);

// prior_authorization_settings
export const get_prior_authorization_settings = createActionThunk(
  AT.GET_PRIOR_AUTHORIZATION_SETTINGS,
  () => API.get_prior_authorization_settings()
);

export const save_prior_authorization_settings = createActionThunk(
  AT.SAVE_PRIOR_AUTHORIZATION_SETTINGS,
  (param) => API.save_prior_authorization_settings(param)
);

// ultrasound type
export const get_ultrasound_type = createActionThunk(
  AT.GET_ULTRASOUND_TYPE_VARIABLE,
  (id) => API.get_ultrasound_type(id)
);

export const save_ultrasound_type = createActionThunk(
  AT.SAVE_ULTRASOUND_TYPE_VARIABLE,
  (params) => API.save_ultrasound_type(params)
);

export const detail_ultrasound_type = createActionThunk(
  AT.DETAIL_ULTRASOUND_TYPE_VARIABLE,
  (id) => API.detail_ultrasound_type(id)
);

export const update_ultrasound_type = createActionThunk(
  AT.UPDATE_ULTRASOUND_TYPE_VARIABLE,
  (id, params) => API.update_ultrasound_type(id, params)
);

export const delete_ultrasound_type = createActionThunk(
  AT.DELETE_ULTRASOUND_TYPE_VARIABLE,
  (id) => API.delete_ultrasound_type(id)
);

//

export const ultrasound_update_conclusion_rule = createActionThunk(
  AT.ULTRASOUND_UPDATE_CONCLUSION_RULE,
  (id, params) => API.ultrasound_update_conclusion_rule(id, params)
);
export const ultrasound_update_result_rule = createActionThunk(
  AT.ULTRASOUND_UPDATE_RESULT_RULE,
  (id, params) => API.ultrasound_update_result_rule(id, params)
);
export const ultrasound_update_cpt_rule = createActionThunk(
  AT.ULTRASOUND_UPDATE_CPT_RULE,
  (id, params) => API.ultrasound_update_cpt_rule(id, params)
);

// angio_condition_type
export const angio_condition_type = createActionThunk(
  AT.ANGIO_CONDITION_TYPE,
  () => API.angio_condition_type()
);

export const angio_condition_type_icd = createActionThunk(
  AT.ANGIO_CONDITION_TYPE_ICD,
  (id, params) => API.angio_condition_type_icd(id, params)
);

export const angio_condition_type_cpt = createActionThunk(
  AT.ANGIO_CONDITION_TYPE_CPT,
  (id, params) => API.angio_condition_type_cpt(id, params)
);

// angio_condition_type_choice
export const angio_condition_type_choice = createActionThunk(
  AT.ANGIO_CONDITION_TYPE_CHOICE,
  (id) => API.angio_condition_type_choice(id)
);
