import React, { useState, useEffect, useCallback } from "react";
import { debounce as lodashDebounce } from "lodash";
import { useDebounce } from "use-lodash-debounce";
import { useSelector, useDispatch } from "react-redux";
import ScrollWrapper from "../../../../../common/components/ScrollBar";
import EditableFormInput from "../../../../../common/components/EditableText/EditableFormInput";
import AutoCompleteSearch from "../../../../../common/components/AutoComplete/AutoCompleteSearch";
import ToastMessage from "../../../../../common/components/Toast";
import { 
  getPrimaryProviderDetails,
  updatePrimaryProviderDetail,
  createNewPrivateProvider
} from "../../../../../store/actions";

const HealthcareProviderLocation = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [provider, setProvider] = useState({});
  const [providerName, setProviderName] = useState("");
  const providers = useSelector((state) => state.adminSetting.providers);
  const isUpdated = useSelector((state) => state.adminSetting.isUpdated);
  const isSaved = useSelector((state) => state.adminSetting.isSaved);
  const [isUpdatedToast, setIsUpdatedToast] = useState(false);
  const [isSavedToast, setIsSavedToast] = useState(false);
  const [addNew, setAddNew] = useState(true);
  const [reset, setReset] = useState(false);
  
  const styleLeftElement = {
    width: '50%', 
    paddingRight: '1rem', 
    borderRight: 'solid 1px #f0f3f6', 
    paddingTop: '0.5rem'
  }

  const styleRightElement = {
    width: '50%', 
    paddingLeft: '1rem', 
    borderLeft: 'solid 1px #f0f3f6', 
    paddingTop: '0.5rem'
  }

  const debounce = useDebounce(search, 400);
  useEffect(() => {
    fetchData(debounce);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const fetchData = (query) => {
    if (!query) {
      setSearchResult([]);
      return;
    }
    dispatch(getPrimaryProviderDetails(query))
  };

  // set toast display.
  useEffect(() => {
    if (isUpdated) {
      setIsUpdatedToast(true);
      setTimeout(() => {
        setIsUpdatedToast(false);
      }, 3000);
    }

    if (isSaved) {
      setIsSavedToast(true);
      setTimeout(() => {
        setIsSavedToast(false);
      }, 3000);
    }
  }, [isUpdated, isSaved])

  useEffect(() => {
    if (providers.length > 0) {
      setSearchResult(providers);
    }
  }, [providers]);

  const handleBtnSaveClicked = (value) => {
    console.dir(provider);
    console.dir(value);
    if (!value.id) {
      dispatch(createNewPrivateProvider(provider));
    } else {
      dispatch(updatePrimaryProviderDetail(value.id, value));
    }
  }

  const changeHandler = useCallback(lodashDebounce((value) => handleBtnSaveClicked(value), 400), []);

  const handleProvider = type => value => {
    let newProvider = {};
    if (type === "provier_name") {
      setProviderName(value);
      newProvider = { 
        ...provider, 
        first_name: value.split(' ')[0],
        last_name: value.split(' ')[1]
      }
    } else {
      newProvider = { ...provider, [type]: value };
    }
    setProvider(newProvider);
    
    if (!addNew) {
      reset && changeHandler(newProvider);
    }
  };

  const handleBtnNewClicked = () => {
    setProvider([]);
    setProviderName("");
    setAddNew(true);
  }

  const handlePopulate = (value) => {
    setProvider(value);
    const name = !value.first_name && !value.last_name ? "" : `${value.first_name} ${value.last_name}`;
    setProviderName(name);
    setAddNew(false);
    setReset(false);
    setTimeout(() => {
      setReset(true);
    }, 1000);
  };


  return (
    <div
      className="documentOcrSetting"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: '100%'
      }}
    >
      <AutoCompleteSearch
        options={searchResult}
        handlePopulate={handlePopulate}
        handleSearch={val => setSearch(val)}
        title="Healthcare-provider-location"
        placeholder="Search Provider"
        actionLabel="Search"
        keyLabel="provider_full_name"
      />
      <div style={{ flex: 1, paddingTop: '1rem', width: '100%' }}>
        {isUpdatedToast &&
          <ToastMessage
            type="Primary Care Provider"
            status="success"
            msg="Successfully Updated the row!"
          /> 
        }
        {isSavedToast &&
          <ToastMessage
            type="Primary Care Provider"
            status="success"
            msg="Successfully Saved the row!"
          /> 
        }
        <ScrollWrapper css="no-padding x-hidden">
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingTop: "5px"
            }}
          >
            <div style={{
              width: '50%', 
              paddingRight: '1rem', 
              borderRight: 'solid 1px #f0f3f6' 
            }}>
              <EditableFormInput
                label="Provider Name"
                value={providerName}
                handleChange={handleProvider('provier_name')}
                fullWidth
              />
            </div>
            <div style={{
              width: '50%', 
              paddingLeft: '1rem', 
              borderLeft: 'solid 1px #f0f3f6'
            }}>
              <EditableFormInput
                label="Suffix Field"
                value={provider.suffix}
                type={'check'}
                options={[
                  { value: 'MD', label: 'MD' },
                  { value: 'DO', label: 'DO' },
                  { value: 'NP', label: 'NP' },
                  { value: 'PA', label: 'PA' },
                  { value: 'DPM', label: 'DPM' }
                ]}
                optionKey={'value'}
                valueKey={'label'}
                handleChange={handleProvider('suffix')}
                fullWidth
              />
            </div>
          </div>
          {/* <div style={{ marginTop: '0.5rem' }} /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Phone Number"
                value={provider.phone_number}
                mask={'phone'}
                handleChange={handleProvider('phone_number')}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Fax Number"
                value={provider.fax_number}
                mask={'phone'}
                handleChange={handleProvider('fax_number')}
                fullWidth
              />
            </div>
          </div>
          {/* <div style={{ marginTop: '0.5rem' }} /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="E-Mail"
                value={provider.email_address}
                handleChange={handleProvider('email_address')}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Address"
                value={provider.address}
                handleChange={handleProvider('address')}
                fullWidth
              />
            </div>
          </div>
          {/* <div style={{ marginTop: '0.5rem' }} /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Suite"
                value={provider.suite}
                handleChange={handleProvider('suite')}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="State"
                value={provider.state}
                handleChange={handleProvider('state')}
                fullWidth
              />
            </div>
          </div>
          {/* <div style={{ marginTop: '0.5rem' }} /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="City"
                value={provider.city}
                handleChange={handleProvider('city')}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Zip Code"
                value={provider.zipcode}
                handleChange={handleProvider('zipcode')}
                fullWidth
              />
            </div>
          </div>

          <div style={{ marginTop: '1.25rem', marginBottom: '1.5rem', float: 'right',  marginLeft: "10px", marginRight: "10px" }}>
            <button
              className={addNew ? "common-btn" : "common-btn outlined-btn"}
              onClick={addNew ? handleBtnSaveClicked : handleBtnNewClicked}
            >
              {!addNew ? 'Create New Provider' : 'Save New Provider'}
            </button>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
}

export default HealthcareProviderLocation;
