import React from 'react';
import FaxingModule from './components/FaxingModule';
import PatientReminders from './components/PatientReminders';
import ScanningModule from "./components/ScanningModule";
import { YellowAlertSVG } from '../../common/components/icons/SVGIcon';

export const Children = {
  'faxing': FaxingModule,
  'patient-reminders': PatientReminders,
  'scanning': ScanningModule,
};

export const FaxType = ['consultation', 'home-care']

export const IncomingFaxHistory = [{
  fax_date: '02/03/2017',
  patient_name: 'Thomas Smith',
  type: 'consultation'
}, {
  fax_date: '06/12/2018',
  patient_name: 'Mark Anthony',
  type: 'home-care'
}, {
  fax_date: '09/02/2018',
  patient_name: 'Facia Gross',
  type: 'consultation'
}, {
  fax_date: '05/09/2019',
  patient_name: 'Faye Donovan',
  type: 'consultation'
}];
export const OutgoingFaxHistory = [{
  fax_date: '02/03/2017',
  recipient: 'Dr Angus Reed, MD',
  sender: 'Agatha Christie'
}, {
  fax_date: '06/12/2018',
  recipient: 'Dr Angus Reed, MD',
  sender: 'Agatha Christie'
}, {
  fax_date: '09/02/2018',
  recipient: 'Dr Angus Reed, MD',
  sender: 'Agatha Christie'
}, {
  fax_date: '05/09/2019',
  recipient: 'Dr Angus Reed, MD',
  sender: 'Agatha Christie'
}];

export const StaticResource = {
  first_name: 'James',
  last_name: 'Fisher',
  date_of_birth: '06/09/1973',
  phone: '(989) 897-8797',
  secondary_phone: '(989) 897-8797',
  email: 'haqqani@hotmail.com',
  ssn: '890-09-8977',
  address: '1109 W St Andrews Rd',
  apt_number: '',
  suite: '',
  bldg: '',
  state: 'Michigan',
  city: 'Midland',
  zipcode: '48642',
  clinic_date: '07/04/2019',
  result: '',
  icon: <YellowAlertSVG />
};
