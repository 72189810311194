import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {connect} from 'react-redux';
import moment from "moment";
import { Icon, Tooltip } from "antd";
import DataTable from "../../common/components/DataTable/DataTable";
import { FemaleSVG, MaleSVG } from "../../common/components/icons/FaxSVG";
import SubTopBar from "../../common/components/SubTopBar";
import ScrollWrapper from "../../common/components/ScrollBar";
import NewPatientSideBar from "./components/NewPatientSideBar";
import CallbackFilter from "./components/CallbackFilter";
import PendingFilter from "./components/PendingFilter";
import CallbackTable from "./components/CallBackTable";
import PendingTable from "./components/PendingTable";
import CalendarContainer from "./CalendarContainer";
import SnapshotSideBar from "../../common/views/PatientSnapshot/SnapSidebar";
import {
  getPCPList,
  getReferralConditions,
  getReferredProviders,
  getLocations,
  getOnlyProviders,
  setStepData,
  search_insurance_companies,
  insurance_types,
  patient_insurances_id_insurances,
  selectPatient,
  setGlobalLoading,
  setPatientSnapshotData,
  changeTab,
} from "../../store/actions";
import "./style.scss";
import SidebarExtension from "../../common/components/SidebarExtension";
import CardBlock from "../../common/components/Card";

class NewPatientBinder extends Component {
  state = {
    isSidebar: false,
    selectedType: "call-back",
    active: false,
    activeID: null,
    selected: -1,
    stepProps: 0,
    settup: -1,
    search: {
      callback: "",
      pending: "",
    },
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  async componentDidMount() {
    const {
      locations,
      providers,
      referralConditions,
      referredProviders,
      pcpList,
      getLocations,
      getOnlyProviders,
      getReferralConditions,
      getReferredProviders,
      getPCPList,
      setStepData,
      search_insurance_companies,
      insurance_types,
      setPatientSnapshotData,
    } = this.props;
    // search_insurance_companies("");
    // insurance_types();
    // if (!locations) {
    //   getLocations();
    // }
    if (!providers) {
      getOnlyProviders();
    }
    // if (!referralConditions) {
    //   getReferralConditions();
    // }
    // if (!referredProviders) {
    //   getReferredProviders();
    // }
    if (!pcpList) {
      getPCPList(null, null);
    }
    setStepData("resource", null);
    setPatientSnapshotData("resource", {});
  }

  componentWillUpdate(props) {
    if (this.props.resource !== props.resource) {
      if (props.resource && Object.keys(props.resource).length > 0) {
        //this.props.patient_insurances_id_insurances(props.resource.id);
      }
    }
    if (this.props.action_add_and_select !== props.action_add_and_select) {
      if (!props.action_add_and_select) {
        this.setState({ isSidebar: false });
      }
    }
  }

  fetchSelect = (type, selected) => {
    this.setState(
      {
        stepProps: -1,
      },
      () => {
        setTimeout(() => {
          this.setState({
            stepProps: 0,
          });
        }, 0);
      }
    );
    this.setState({ selectedType: type, selected });
  };

  handleSearch = (type) => (value) => {
    this.setState({ search: { ...this.state.search, [type]: value } });
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true, active: false, activeID: null });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | New Patient</title>
        <meta name="title" content="FAACT Vascular | New Patient" />
      </Helmet>
    );
  }

  getStatus = (status, status1) => {
    return (
      <div className="statusHealth">
        <Icon type="mail" theme="twoTone" twoToneColor="#0f62fe" />
        {status}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="phone" theme="twoTone" twoToneColor="#F1C21B" />
        {status1}
      </div>
    );
  };

  onHandleClick = async (event) => {
    // check
    this.setState(
      {
        settup: 0,
      },
      () => {
        setTimeout(() => {
          this.setState({
            settup: -1,
          });
        }, 0);
      }
    );
    this.props.setGlobalLoading(true);
    await this.props.changeTab({
      tabActive: null,
      accordion: null,
      step: 0,
    });
    const result = await this.props.selectPatient(event.patient_id);
    await this.props.setPatientSnapshotData("resource", { ...result });
    this.setState({
      isSidebar: true,
      active: true,
      activeID: event.id,
    });
    setTimeout(() => {
      this.props.setGlobalLoading(false);
    }, 800);
  };

  renderSideComponent = () => {
    const { resource, resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (
      resourceCheck.first_name +
      " " +
      resourceCheck.last_name
    ).toUpperCase();
    const age = resourceCheck.date_of_birth
      ? "(" +
        moment().diff(resourceCheck.date_of_birth, "years") +
        // " yo " +
        // (!!resourceCheck.gender ? resourceCheck.gender.toUpperCase() : "MALE") +
        ")"
      : "";
    const allerrgies = [];
    if (resourceCheck.allergies) {
      resourceCheck.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
          allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >
        <div style={{display:"flex"}}>
          <div
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
            }}
          >
            {fullName} {age}
            {allerrgies.length === 0 && (
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                }}
              >
                <span
                  style={{
                    color: "#da1e28",
                    fontSize: "0.625rem",
                    fontWeight: 600,
                  }}
                >
                  {resourceCheck.no_drug_allergies ? "NKDA" : "Pending Data"}
                </span>
              </div>
            )}
            {allerrgies.length > 0 && <br />}
            {allerrgies.length > 0 && allerrgies.length < 3 && (
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            )}
            {allerrgies.length >= 3 && (
              <Tooltip
                placement="bottomRight"
                title={resourceCheck.allergies
                  .map((allergy) => allergy.allergy_name)
                  .join(", ")}
              >
                <div
                  style={{
                    textAlign: "right",
                    whiteSpace: "nowrap",
                    lineHeight: "10px",
                    color: "black",
                    fontSize: "0.625rem",
                    fontWeight: 600,
                  }}
                >
                  {allerrgies.map((allergy) => allergy)}
                </div>
              </Tooltip>
            )}
          </div>
        
          {resourceCheck.gender &&
          resourceCheck.gender.toUpperCase() == "MALE" ? (
            <MaleSVG
              style={{ marginLeft: "8px", height: "unset", width: "26px" }}
            />
          ) : (
            <FemaleSVG
              style={{ marginLeft: "8px", height: "unset", width: "26px" }}
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    const { selected, isSidebar, selectedType, search, active } = this.state;
    const { pending, callback, resource } = this.props;
    const pendingLengh = pending ? pending.length : 0;
    const callbackLength = callback ? callback.length : 0;
    const isResource = !resource || !Object.keys(resource).length;

    return (
      <div className="newPatientPage mainPage">
        {this.renderMetadata()}
        <SubTopBar
          title="New Patient Processing"
          noLeftSide={true}
          subStatus={this.getStatus(pendingLengh, callbackLength)}
          isBarcode={!isResource}
          sideComponent={this.renderSideComponent()}
        />
        {active ? (
          <SnapshotSideBar
            ref={this.sidebarRef}
            patientResource={this.props.patientResource}
            isShow={isSidebar}
            settup={this.state.settup}
            isScan={true}
            handleCloseSidebar={this.handleCloseSidebar}
          />
        ) : (
          <NewPatientSideBar
            ref={this.sidebarRef}
            stepProps={this.state.stepProps}
            selected={selected}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
          />
        )}

        <SidebarExtension
          sidebarToggle={this.toggleSidebar}
          sidebarReference={this.sidebarRef}
          sidebarState={isSidebar}
        />

        <div className="main multi-main">
          <ScrollWrapper css="no-padding x-hidden">
            <div
              className={`mainContainer ${
                isResource || !isSidebar ? "no-sidebar" : ""
              }`}
              style={{
                marginLeft: "-10px",
                marginRight: "-10px",
                paddingTop: "0",
                paddingBottom: "0",
                display: "flex",
                width: '96%',
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <div className="mainTableContainer">
                <CardBlock
                  title="New Patient Pending"
                  varient="half"
                  other="dashboard"
                >
                  <PendingFilter
                    searchValue={search.pending}
                    handleSearch={this.handleSearch("pending")}
                  />
                  <div className="tableSection">
                    <PendingTable
                      search={search.pending}
                      title="pending"
                      selectedType={selectedType}
                      handleSidebar={this.handleSidebar}
                      handleTableSelect={this.fetchSelect}
                    />
                  </div>
                </CardBlock>

                <CardBlock
                  title="New Patient Call Back"
                  varient="half"
                  other="dashboard"
                >
                  <CallbackFilter
                    searchValue={search.callback}
                    handleSearch={this.handleSearch("callback")}
                  />
                  <div className="tableSection">
                    <CallbackTable
                      search={search.callback}
                      title="callback"
                      selectedType={selectedType}
                      handleSidebar={this.handleSidebar}
                      handleTableSelect={this.fetchSelect}
                    />
                  </div>
                </CardBlock>
              </div>

              <CalendarContainer
                activeID={this.state.activeID}
                onHandleClick={this.onHandleClick}
              />
            </div>
          </ScrollWrapper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.common,
  pending: state.patientPending.pending,
  callback: state.patientCallback.callback,
  resource: state.newPatient.resource,
  action_add_and_select: state.common.action_add_and_select,
  patientResource: state.clinicProvider.patientResource,
  providers: state.common.only_providers,
  resourceCheck: state.patientSnapshot.resource,
});

const mapDispatchToProps = {
  getLocations,
  getOnlyProviders,
  getReferralConditions,
  getReferredProviders,
  getPCPList,
  setStepData,
  search_insurance_companies,
  insurance_types,
  patient_insurances_id_insurances,
  selectPatient,
  setPatientSnapshotData,
  changeTab,
  setGlobalLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPatientBinder);
