import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input, Select, TimePicker, DatePicker } from "antd";
import { useDebounce } from "use-lodash-debounce";
import moment from "moment";

import { SearchSVG, ExpandSVG } from "../../../common/components/icons/SVGIcon";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import Loading from "../../../common/components/Loading";
import ScrollWrapper from "../../../common/components/ScrollBar";
import CardBlock from "../../../common/components/Card";
import {
  useWindowSize,
  useOutsideDetect,
  getValueFromObjectArray,
} from "../../../common/utils";
import {
  setDashboardAppointments,
  getProcedureData,
  getLocations,
  getUltrasounds,
  get_angio_type,
  addProcedures_new,
  set_cpt_code_review,
  setGlobalLoading,
  deleteProcedure,
  setProcedureData,
} from "../../../store/actions";
import {
  getPatients,
  getPatientAppointment,
} from "../../../services/api.services";
import ToastMessage from "../../../common/components/Toast";

const { Search } = Input;
const { Option, OptGroup } = Select;

const AppointmentManager = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const procedureData = useSelector((state) => state.common.procedureData);
  const untrasound_list = useSelector(
    (state) => state.procedureDetail.untrasound_list
  );
  const angio_list = useSelector((state) => state.procedureDetail.angio_list);
  const patient = useSelector((state) => state.dashboard.appointments.patient);
  const dispatch = useDispatch();

  const [openPanel, setOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [providers, setProviders] = useState([]);
  const [locations, setLocations] = useState([]);

  const providersAll = useSelector((state) => state.common.providers);
  const locationsAll = useSelector((state) => state.common.locations);
  const [dataSelect, setDataSelect] = useState([]);
  const [procList, setProcList] = useState([]);
  const [angioList, setAngioList] = useState([]);
  const [ultrasoundList, setUltrasoundList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeSelect, setActiveSelect] = useState(-1);
  const [activeSearch, setActiveSearch] = useState(-1);
  const [activeTitle, setActiveTitle] = useState("");
  const [activeSearchRow, setActiveSearchRecords] = useState(null);
  const [patients, setPatients] = useState([]);
  const [editable, setEditable] = useState(false);
  const [saveAble, setSaveAble] = useState(false);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [sortOptions, setSort] = useState([
    { name: "date", direction: "" },
    { name: "time", direction: "" },
    { name: "type", direction: "" },
    { name: "location", direction: "" },
    { name: "provider", direction: "" },
  ]);
  useEffect(() => {
    if (appointments && appointments[activeSelect]) {
      if (
        appointments[activeSelect]["location_id"] &&
        appointments[activeSelect]["location_id"] !== "" &&
        appointments[activeSelect]["type"] === ""
      ) {
        const check = locationsAll.find(
          (r) => r.id === appointments[activeSelect]["location_id"]
        );
        if (check && check.providers_unique) {
          if (check.type === "clinic") {
            const procefind = procedureData.filter((r) => r.value === "Clinic");
            const proce = (procefind || []).map((r) => {
              return {
                ...r,
                value: r.value,
                type: "or",
                label: "Procedure",
              };
            });
            setProcList(proce);
            const ultra = (untrasound_list || []).map((r) => {
              return {
                ...r,
                value: r.name,
                type: "ultrasound",
                label: "Ultrasound",
              };
            });
            setUltrasoundList(ultra);
            setAngioList([]);
          } else if (check.type === "hospital") {
            const procefind = procedureData.filter(
              (r) => r.type === "or" && r.value !== "Clinic"
            );
            const proce = (procefind || []).map((r) => {
              return {
                ...r,
                value: r.value,
                type: "or",
                label: "Procedure",
              };
            });
            setProcList(proce);
            setUltrasoundList([]);
            setAngioList([]);
          } else if (check.type === "vhc") {
            const ultra = (untrasound_list || []).map((r) => {
              return {
                ...r,
                value: r.name,
                type: "ultrasound",
                label: "Ultrasound",
              };
            });
            setUltrasoundList(ultra);
            const angio = (angio_list || []).map((r) => {
              return {
                ...r,
                value: r.name,
                type: "angio",
                label: "OBL Procedure",
              };
            });
            setAngioList(angioList);
            setProcList([]);
          }
          setProviders(check.providers_unique);
        }
      } else if (
        appointments[activeSelect]["provider_id"] &&
        appointments[activeSelect]["provider_id"] !== "" &&
        appointments[activeSelect]["type"] === ""
      ) {
        const check = providersAll.find(
          (r) => r.id === appointments[activeSelect]["provider_id"]
        );

        if (check && check.locations_unique) {
          setLocations(check.locations_unique);
          const angio_find = (check.procedure_types || []).filter(
            (r) => r.type === "angio"
          );
          const angio = (angio_find || []).map((r) => {
            return {
              ...r,
              value: r.name,
              type: "angio",
              label: "OBL Procedure",
            };
          });
          setAngioList(angio);
          const procefind = (check.procedure_types || []).filter(
            (r) => r.type === "or" || r.type === "clinic"
          );
          const proce = (procefind || []).map((r) => {
            return {
              ...r,
              value: r.value,
              type: "or",
              label: "Procedure",
            };
          });
          setProcList(proce);
          const ultra = (untrasound_list || []).map((r) => {
            return {
              ...r,
              value: r.name,
              type: "ultrasound",
              label: "Ultrasound",
            };
          });
          setUltrasoundList(ultra);
        }
      } else if (
        appointments[activeSelect]["type"] &&
        appointments[activeSelect]["type"] === "or"
      ) {
        if (appointments[activeSelect]["appointment_type_id"]) {
          const check = procedureData.find(
            (r) => r.id == appointments[activeSelect]["appointment_type_id"]
          );
          if (check && check["locations"]) {
            setLocations(check["locations"]);
          }
          if (check && check["providers"]) {
            setProviders(check["providers"]);
          }
        }
      } else if (
        appointments[activeSelect]["type"] &&
        appointments[activeSelect]["type"] == "angio"
      ) {
        if (appointments[activeSelect]["appointment_type_id"]) {
          const check = angio_list.find(
            (r) => r.id == appointments[activeSelect]["appointment_type_id"]
          );
          if (check && check["locations"]) {
            setLocations(check["locations"]);
          }
          if (check && check["providers"]) {
            setProviders(check["providers"]);
          }
        }
      } else {
        setLocations(locationsAll);
        setProviders(providersAll);
      }
    }
  }, [appointments, procedureData, activeSelect]);

  useEffect(() => {
    const ultra = (untrasound_list || []).map((r) => {
      return {
        ...r,
        value: r.name,
        type: "ultrasound",
        label: "Ultrasound",
      };
    });
    const angio = (angio_list || []).map((r) => {
      return {
        ...r,
        value: r.name,
        type: "angio",
        label: "OBL Procedure",
      };
    });
    const proce = (procedureData || []).map((r) => {
      return {
        ...r,
        value: r.value,
        type: "or",
        label: "Procedure",
      };
    });
    setDataSelect([...proce, ...ultra, ...angio]);
    setProcList(proce);
    setAngioList(angio);
    setUltrasoundList(ultra);
  }, [untrasound_list, angio_list, procedureData]);

  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    dispatch(setDashboardAppointments("patient", null));
    dispatch(setDashboardAppointments("appointments", []));
    dispatch(getProcedureData());
    dispatch(getLocations());
    dispatch(getUltrasounds());
    dispatch(get_angio_type());
  }, []);

  useEffect(() => {
    if (debouncedValue && openPanel) {
      fetchPatients(search);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (ref2 && ref2.current) {
      const initialSize = {
        width: ref2.current.clientWidth,
        height: ref2.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref2]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const handleSort = (sorts) => {
    const newSorts = [...sortOptions];
    sorts.forEach((sort, index) => (newSorts[index].direction = sort));
    setSort(newSorts);
    fetchAppointments(getRequestParams(patient));
  };

  const saveAppointments = () => {
    // ToDo: Mike should work with backend API
    setTimeout(() => {
      setSaveAble(false);
    }, 1000);
  };

  const toggleEdit = () => {
    if (!saveAble) {
      setEditable(!editable);
    } else {
      saveAppointments();
    }
  };

  const toggleSave = async () => {
    if (
      appointments &&
      Array.isArray(appointments) &&
      appointments.length > 0 &&
      appointments.some(
        (r) =>
          r.appointment_date == null ||
          r.appointment_type_id == null ||
          r.date == null ||
          r.location_id == null ||
          r.patient_id == null ||
          r.provider_id == null ||
          r.time == null
      )
    ) {
    } else {
      if (
        appointments &&
        appointments[activeSelect] &&
        appointments[activeSelect]["id"] &&
        activeSearchRow &&
        activeSearchRow.id
      ) {
        const params = {
          appointment_date:
            `${
              appointments[activeSelect] && appointments[activeSelect]["date"]
            } ${
              appointments[activeSelect] && appointments[activeSelect]["time"]
            }` || "",
          date: appointments[activeSelect]["date"] || "",
          time: appointments[activeSelect]["time"] || "",
          appointment_type_id:
            appointments[activeSelect]["appointment_type_id"] || "",
          location_id: appointments[activeSelect]["location_id"] || "",
          provider_id: appointments[activeSelect]["provider_id"] || "",
          patient_id: activeSearchRow.id,
        };
        const editSuccess = await dispatch(
          set_cpt_code_review(appointments[activeSelect]["id"], params)
        );
        if (editSuccess) {
          await fetchAppointments(getRequestParams(activeSearchRow));
          setEditable(false);
          setSaveAble(false);
        }
      } else {
        if (appointments && appointments[activeSelect]) {
          const params = {
            ...appointments[activeSelect],
            appointment_date:
              `${
                appointments[activeSelect] && appointments[activeSelect]["date"]
              } ${
                appointments[activeSelect] && appointments[activeSelect]["time"]
              }` || "",
            date: appointments[activeSelect]["date"] || "",
            time: appointments[activeSelect]["time"] || "",
            appointment_type_id:
              appointments[activeSelect]["appointment_type_id"] || "",
            location_id: appointments[activeSelect]["location_id"] || "",
            provider_id: appointments[activeSelect]["provider_id"] || "",
            patient_id: activeSearchRow.id,
          };
          const saveSuccess = await dispatch(addProcedures_new(params));
          if (saveSuccess) {
            await fetchAppointments(getRequestParams(activeSearchRow));
            setEditable(false);
            setSaveAble(false);
          }
        }
      }
    }
  };

  const getLabel = (id, data, type) => {
    if (type) {
      const appointment =
        data && data.find((d) => d.id == id && d.type == type);
      return appointment ? appointment.value : "";
    } else {
      const appointment = data && data.find((d) => d.id === id);
      return appointment ? appointment.value : "";
    }
  };

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      setActiveSelect(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const size = useWindowSize(ref2);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = 5;
  }
  if (size.height) {
    rowCount = Math.floor(size.height / (16 * 2.5));
  } else {
    rowCount = Math.floor(initialSize.height / (16 * 2.5));
  }

  const fetchPatients = async (query = "") => {
    setLoading(true);
    const result = await getPatients(query);

    if (result && result.length > 0) {
      setPatients(result);
      setLoading(false);
    } else {
      setPatients([]);
      setLoading(false);
    }
  };

  const fetchAppointments = async (params) => {
    const result = await getPatientAppointment(params);
    if (result && result.length) {
      const data = result.map((item) => ({
        ...item,
        date:
          item.appointment_date &&
          moment(item.appointment_date).format("MM/DD/YYYY"),
        time:
          item.appointment_date &&
          moment(item.appointment_date).format("h:mm A"),
      }));
      setAppointments(data);
      dispatch(setDashboardAppointments("appointments", data));
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOpen(true);
  };

  const handleDetele = async (value, index) => {
    if (value.id) {
      const deleteSuccess = await dispatch(deleteProcedure(value.id));
      if (deleteSuccess) {
        await fetchAppointments(getRequestParams(activeSearchRow));
      }
    }
  };

  const handleSearchSelect = (row, index) => {
    setActiveSearch(index);
    setActiveSearchRecords(row);
    setSearch(
      `${row.first_name || ""} ${row.last_name || ""} - ${
        row.date_of_birth && moment(row.date_of_birth).format("MM/DD/YYYY")
      }`
    );
    setOpen(false);

    handleAddHistory(row);
  };

  const getRequestParams = (record) => {
    const request = {
      patient_id: record.id,
      appointment_date: moment().format("MM/DD/YYYY"),
      columns: sortOptions.filter((opt) => opt.direction),
    };
    return request;
  };

  const handleAddHistory = (searchRow = null) => {
    if (activeSearchRow) {
      setActiveRow(0);
      fetchAppointments(getRequestParams(activeSearchRow));
      dispatch(setDashboardAppointments("patient", activeSearchRow));
      //setActiveSearchRecords(null);
      setActiveTitle(search);
    } else if (searchRow) {
      setActiveRow(0);
      fetchAppointments(getRequestParams(searchRow));
      dispatch(setDashboardAppointments("patient", searchRow));
      //setActiveSearchRecords(null);
      setActiveTitle(search);
    }
  };

  const handleAddAppointment = () => {
    const newAppointment = {
      key: Date.now(),
      appointment_date: undefined,
      date: undefined,
      time: "08:00 AM",
      appointment_type_id: undefined,
      location: undefined,
      provider: undefined,
      type: "",
      patient_id: activeSearchRow.id,
    };
    setAppointments([newAppointment, ...appointments]);
  };

  const updateAppointments = (type, index, value) => {
    const currentAppointments = [...appointments];
    if (type === "date") {
      currentAppointments[index].date = value;
      currentAppointments[index].appointment_date =
        value + currentAppointments[index].time
          ? ` ${currentAppointments[index].time}`
          : "";
    } else if (type === "time") {
      currentAppointments[index].time = value;
      currentAppointments[index].appointment_date =
        currentAppointments[index].date &&
        currentAppointments[index].date + " " + value;
    } else if (type === "appointment_type_id") {
      const check = value.split("-");
      // appointment_type_id
      currentAppointments[index][type] = check[0];
      currentAppointments[index]["type"] = check[1];
    } else {
      currentAppointments[index][type] = value;
    }
    setSaveAble(true);
    setAppointments(currentAppointments);
  };

  const removeAppointment = (idx) => {
    const currentAppointments = [...appointments];
    currentAppointments.splice(idx, 1);
    setAppointments(currentAppointments);
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const handleKeyPress = async () => {
    if (activeSearchRow) {
      setActiveRow(0);
      fetchAppointments(getRequestParams(activeSearchRow));
      dispatch(setDashboardAppointments("patient", activeSearchRow));
      setActiveTitle(search);
      setSearch(
        `${activeSearchRow.first_name || ""} ${
          activeSearchRow.last_name || ""
        } - ${
          activeSearchRow.date_of_birth &&
          moment(activeSearchRow.date_of_birth).format("MM/DD/YYYY")
        }`
      );
    }
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (rowData && rowData[activeSearch + 1]) {
        setActiveSearchRecords(rowData[activeSearch + 1]);
        setActiveSearch(activeSearch + 1);
      }
    } else {
      setActiveSearch(activeSearch - 1);
      setActiveSearchRecords(rowData[activeSearch - 1]);
    }
  };

  return (
    <CardBlock
      title={`Appointment Manager`}
      varient="half"
      other="dashboard"
      subAction={
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {patient && appointments && appointments.length > 0 && (
            <button
              className="text-btn"
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                if (saveAble && editable) {
                  toggleSave();
                } else {
                  toggleEdit();
                }
              }}
            >
              {saveAble && editable ? "Save" : "Edit"}
              {saveAble && editable ? " +" : " -"}
            </button>
          )}
          {patient && appointments && (
            <button
              className="text-btn"
              style={{ marginLeft: "1rem" }}
              onClick={handleAddAppointment}
              disabled={!activeTitle}
            >
              Add +
            </button>
          )}
        </div>
      }
    >
      <div className="patientSearch">
        <div className="patientSearch__filter">
          <div ref={ref} className="patientSearch__filter-search">
            <Search
              value={search}
              placeholder="Search Patient"
              onChange={handleSearch}
              onKeyDownCapture={(e) => {
                if (e.keyCode == 40) {
                  onKeyDown("down");
                } else if (e.keyCode == 38) {
                  onKeyDown("up");
                }
              }}
              onPressEnter={handleKeyPress}
              style={{ width: "100%" }}
              suffix={<SearchSVG />}
              onFocus={() => setOpen(true)}
            />
          </div>
          {/* <div className="patientSearch__filter-action">
            <button
              className="patientSearch__filter-add"
              onClick={handleAddHistory}
            >
              <span>Search</span>
              <span>+</span>
            </button>
          </div> */}
        </div>
        {openPanel && search && rowCount !== NaN && rowData.length > 0 && (
          <div
            ref={ref1}
            className="fullContainer patientSearch__result"
            style={rowData.length <= rowCount - 1 ? { height: "auto" } : {}}
          >
            <div
              className="tableSection"
              style={{
                height: rowData.length <= rowCount - 1 ? "auto" : "100%",
                position: "relative",
              }}
            >
              <div className="tableContainer">
                <ScrollWrapper
                  css="no-padding"
                  onScrollFrame={onScroll}
                  disableScroll={rowData.length <= rowCount - 1}
                >
                  <div className="tableList">
                    {rowData.map((row, index) => (
                      <div
                        style={{ zIndex: 100 }}
                        className={`tableItem ${
                          index === 0 ? "no-border" : ""
                        } ${index === activeSearch ? "active" : ""}`}
                        key={`resultTable-${index}`}
                        onClick={() => handleSearchSelect(row, index)}
                      >
                        <div className="td with-icon" style={{ width: "100%" }}>
                          <p>{`${row.first_name || ""} ${
                            row.last_name || ""
                          } - ${
                            row.date_of_birth &&
                            moment(row.date_of_birth).format("MM/DD/YYYY")
                          }`}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </ScrollWrapper>
              </div>
            </div>
          </div>
        )}
        <div
          className="fullContainer"
          style={{ padding: "1rem 0 0 0", position: "relative", flex: 1 }}
        >
          {appointments && appointments.length > 0 && (
            <DataTable
              title="appointment_manager"
              labels={[
                "Date",
                "Time",
                "Appointment Type",
                "Location",
                "Provider",
              ]}
              widths={["15%", "15%", "24%", "22%", "24%"]}
              isNormal
              columns={[
                { sortable: true, key: "date" },
                { sortable: true, key: "time" },
                { sortable: true, key: "appointment_type" },
                { sortable: true, key: "location" },
                { sortable: true, key: "provider" },
              ]}
              handleClickRow={(value, index) => {
                setActiveSelect(index);
              }}
              handleDelete={(value, index) => {
                handleDetele(value, index);
                removeAppointment(index);
              }}
              handleSort={handleSort}
              rowData={(appointments || []).map((item, index) => {
                return {
                  key: item.key,
                  id: item.id,
                  date: editable ? (
                    <div
                      style={{
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DatePicker
                        onChange={(value) => {
                          updateAppointments(
                            "date",
                            index,
                            moment(value).format("MM/DD/YYYY")
                          );
                        }}
                        className={item?.date &&
                          item?.date != "Invalid date" &&
                          item?.date != null &&
                          item?.date.length > 0
                          ? ''
                          : 'date-error'}
                        style={{ 
                          width: "100%", 
                          border:
                            item?.date &&
                            item?.date != "Invalid date" &&
                            item?.date != null &&
                            item?.date.length > 0
                              ? "0px"
                              : "2px solid #FC0D1B",
                        }}
                        value={item.date && moment(item.date, "MM/DD/YYYY")}
                        format={"MM/DD/YYYY"}
                        placeholder="Date"
                        suffixIcon={<ExpandSVG />}
                      />
                    </div>
                  ) : (
                    item.date
                  ),
                  time: editable ? (
                    <div
                      style={{
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TimePicker
                        format={"h:mm A"}
                        use12Hours
                        placeholder="Time"
                        className={item?.time &&
                          item?.time != "Invalid date" &&
                          item?.time != null &&
                          item?.time.length > 0
                            ? ""
                            : "input-error"}
                        style={{
                          border: 
                            item?.time &&
                            item?.time != "Invalid date" &&
                            item?.time != null &&
                            item?.time.length > 0
                              ? "0px"
                              : "2px solid #FC0D1B",
                          maxWidth: "100%",
                          width: "100%",
                          background: "transparent",
                        }}
                        suffixIcon={<span />}
                        value={
                          item.time &&
                          item.time != "Invalid date" &&
                          moment(item.time, "h:mm A")
                        }
                        disabledHours={() => [
                          0, 1, 2, 3, 4, 5, 6, 18, 19, 20, 21, 22, 23,
                        ]}
                        minuteStep={15}
                        onChange={(value) =>
                          updateAppointments(
                            "time",
                            index,
                            moment(value).format("h:mm A")
                          )
                        }
                      />
                    </div>
                  ) : (
                    item.time
                  ),
                  appointment_type: editable ? (
                    <div
                      style={{
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        placeholder="Type"
                        suffixIcon={<ExpandSVG />}
                        value={
                          item.appointment_type_id && item.type
                            ? `${item.appointment_type_id}-${item.type}`
                            : undefined
                        }
                        allowClear={true}
                        onChange={(value) => {
                          if (value) {
                            updateAppointments(
                              "appointment_type_id",
                              index,
                              value
                            );
                          } else {
                            updateAppointments(
                              "appointment_type_id",
                              index,
                              ""
                            );
                          }
                        }}
                        className={
                          item.appointment_type_id && item.type 
                          ? ''
                          : 'select-error'
                        }
                        style={{
                          border: 
                            item.appointment_type_id && item.type
                            ? "0px"
                            : "2px solid #FC0D1B",
                          width: "100%",
                          height: "2rem",
                        }}
                      >
                        <OptGroup label="Hospital Procedure">
                          {(procList || []).map((type) => (
                            <Option
                              value={`${type.id}-${type.type}`}
                              key={`${type.id}-${index}-${type.type}`}
                            >
                              {type.value}
                            </Option>
                          ))}
                        </OptGroup>
                        <OptGroup label="Ultrasound">
                          {(ultrasoundList || []).map((type) => (
                            <Option
                              value={`${type.id}-${type.type}`}
                              key={`${type.id}-${index}-${type.type}`}
                            >
                              {type.value}
                            </Option>
                          ))}
                        </OptGroup>
                        <OptGroup label="OBL Procedure">
                          {(angioList || []).map((type) => (
                            <Option
                              value={`${type.id}-${type.type}`}
                              key={`${type.id}-${index}-${type.type}`}
                            >
                              {type.value}
                            </Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </div>
                  ) : (
                    getLabel(item.appointment_type_id, dataSelect, item.type)
                  ),
                  location: editable ? (
                    <div
                      style={{
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        placeholder="Location"
                        suffixIcon={<ExpandSVG />}
                        value={item.location_id}
                        allowClear={true}
                        onChange={(value) => {
                          updateAppointments("location_id", index, value);
                        }}
                        className={
                          item.location_id && item.location_id != null
                          ? ''
                          : 'select-error'
                        }
                        style={{
                          border: 
                            item.location_id && item.location_id != null
                            ? "0px"
                            : "2px solid #FC0D1B",
                          width: "100%",
                          height: "2rem",
                        }}
                      >
                        {(locations || []).map((loc, idx) => (
                          <Select.Option
                            key={`${loc.id}-${idx}`}
                            value={loc.id}
                          >
                            {loc.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    item.location && item.location.value
                  ),
                  provider: editable ? (
                    <div
                      style={{
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        placeholder="Provider"
                        suffixIcon={<ExpandSVG />}
                        value={item.provider_id}
                        allowClear={true}
                        onChange={(value) => {
                          updateAppointments("provider_id", index, value);
                        }}
                        className={
                          item.provider_id && item.provider_id != null
                          ? ''
                          : 'select-error'
                        }
                        style={{
                          border: 
                            item.provider_id && item.provider_id != null
                            ? "0px"
                            : "2px solid #FC0D1B",
                          width: "100%",
                          height: "2rem",
                        }}
                      >
                        {(providers || []).map((provider, idx) => (
                          <Select.Option
                            key={`${provider.id}-${idx}`}
                            value={provider.id}
                          >
                            {provider.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    item.provider && item.provider.value
                  ),
                };
              })}
              emptyRows={new Array(4).fill(1)}
              sorts={[
                sortOptions[0].direction,
                sortOptions[1].direction,
                sortOptions[2].direction,
                sortOptions[3].direction,
                sortOptions[4].direction,
              ]}
            />
          )}
          {patient && !appointments.length && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              No Appointments
            </div>
          )}
        </div>
      </div>
    </CardBlock>
  );
};

export default AppointmentManager;
