import { createReducer } from "redux-create-reducer";
import moment from "moment";

import AT from "../actionTypes";

const initialState = {
  schedules: [],
  loadingSchedules: true,
  addLoading: false,
  addSuccess: false,
  selectedPatientResource: {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phone: "",
    secondary_phone: "",
    zipcode: "",
    email: "",
    ssn: "",
    address: "",
    apt_number: "",
    state: "",
    city: "",
    medical_id: "",
    policy_number: "",
    notes: [],
  },
};

//Get patient schedules

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.SAVE_NEW_ULTRASOUND}_STARTED`](state, action) {
    return {
      ...state,
      addLoading: true,
    };
  },
  [`${AT.SAVE_NEW_ULTRASOUND}_SUCCESS`](state, action) {
    return {
      ...state,
      addLoading: false,
      addSuccess: true,
    };
  },
  [`${AT.GET_PATIENT_SCHEDULES}_STARTED`](state, action) {
    return {
      ...state,
      loadingSchedules: true,
    };
  },
  [`${AT.GET_PATIENT_SCHEDULES}_SUCCEEDED`](state, action) {
    action.payload.elements.map((element) => {
      var newStart = moment(element.start, "YYYY-MM-DD HH:mm:ss");
      var newEnd = moment(element.end, "YYYY-MM-DD HH:mm:ss");
      element.start = newStart.toDate();
      element.end = newEnd.toDate();
      return element;
    });
    return {
      ...state,
      schedules: action.payload.elements,
      loadingSchedules: false,
    };
  },
  [`${AT.SEARCH_PATIENT_SCHEDULES}`](state, action) {
    let newSchedules = [];
    const { searchValue } = action.payload;
    for (let schedule of state.schedules) {
      if (searchValue === "" || schedule.title.includes(searchValue)) {
        schedule.isHighlight = true;
      } else {
        schedule.isHighlight = false;
      }
      newSchedules.push(schedule);
    }

    return {
      ...state,
      schedules: newSchedules,
    };
  },
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      schedules: [],
      loadingSchedules: true,
      addLoading: false,
      addSuccess: false,
      selectedPatientResource: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        phone: "",
        secondary_phone: "",
        zipcode: "",
        email: "",
        ssn: "",
        address: "",
        apt_number: "",
        state: "",
        city: "",
        medical_id: "",
        policy_number: "",
        notes: [],
      },
    };
  },
});
