import moment from 'moment';

const regexForEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexForUsPhone = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
const regexForSSN = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/;

export const validateSteps = (data, keys) => {
  if (!keys || !keys.length) {
    return true;
  }

  const inValidIndex = (keys || []).findIndex(key => {
    if (key.type === 'check' && (data[key.value] === ""
      || data[key.value] === undefined
      || parseInt(data[key.value]) === -1)
    ) {
      return true;
    }
    if (key.type === 'default' && !data[key.value]) {
      return true;
    }
    if (key.type === 'array' && (!data[key.value] || !data[key.value].length)) {
      return true;
    }
    if (key.type === 'date' && (!data[key.value] || !moment(data[key.value], key.format).isValid())) {
      return true;
    }
    if (key.type === 'email' && (!data[key.value] || !regexForEmail.test(data[key.value].toLowerCase()))) {
      return true;
    }
    if (key.type === 'phone' && (!data[key.value] || !regexForUsPhone.test(data[key.value]))) {
      return true;
    }
  });

  if (inValidIndex === -1) {
    return true;
  }

  return false;
};

export const validateField = (type, value) => {
  if (!value) {
    return true;
  }
  if (type === 'check' && (value === ""
    || value === undefined
    || parseInt(value) === -1)
  ) {
    return true;
  }
  if (type === 'text' && !value) {
    return true;
  }
  if (type === 'date' && (!value || !moment(value, 'MM/DD/YYYY').isValid())) {
    return true;
  }
  if (
    type === 'time'
    && !moment(value, 'hh:mm').isValid()
    && !moment(value, 'hh:mm a').isValid()
    && !moment(value, 'hh:mm A').isValid()
  ) {
    return true;
  }
  if (type === 'email' && !regexForEmail.test(value.toLowerCase())) {
    return true;
  }
  if (type === 'phone' && !regexForUsPhone.test(value)) {
    return true;
  }
  if (type === 'array' && (!value || !value)) {
    return true;
  }
  if (type === 'ssn' && !regexForSSN.test(value)) {
    return true;
  }

  return false;
};
