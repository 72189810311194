import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
import React, { useState, useEffect } from "react";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { DevicesSVG } from "../../../../common/components/icons/SVGIcon";
import { getArteryLabel } from "../../../../common/constants/Artery";
import {
  getDiagnostic,
  getMedical,
} from "../../../../common/constants/Operations";
import "./style.scss";

const AccessDetails = ({
  icon,
  title,
  data,
  items,
  inventories,
  activeItem,
  activeIndex,
  handleSubmit,
  removeItem,
  current,
}) => {
  const [item, setItem] = useState({});
  const [checkItem, setCheckItem] = useState({});
  const [medical, setMedical] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  useEffect(() => {
    if (activeItem) {
      setMedical(getMedical(activeItem));
    }
    if (current) {
      let rowBody = "";
      if (current.body) {
        if (current.body["part"] == "m-body") {
          rowBody = "Left Internal Iliac Artery";
        } else if (current.body["part"] == "l-neck-1") {
          rowBody = "Left Carotid Artery";
        } else if (current.body["part"] == "r-body-2") {
          rowBody = "Right Renal Artery";
        } else if (current.body["part"] == "l-body-0") {
          rowBody = "Left Renal Artery";
        } else if (current.body["part"] == "r-neck-1") {
          rowBody = "Right Carotid Artery";
        } else if (current.body["part"] == "r-arm-4") {
          rowBody = "Right Axillary Artery";
        } else if (current.body["part"] == "r-arm-3") {
          rowBody = "Right Brachial Artery";
        } else if (current.body["part"] == "r-arm-1l") {
          rowBody = "Right Radial Artery";
        } else if (current.body["part"] == "r-arm-0") {
          rowBody = "Right Radial-Ulnar Artery";
        } else if (current.body["part"] == "r-arm-1r") {
          rowBody = "Right Ulnar Artery";
        } else if (current.body["part"] == "r-arm-2") {
          rowBody = "Right Ulnar-Radial Artery";
        } else if (current.body["part"] == "l-arm-4") {
          rowBody = "Left Axillary Artery";
        } else if (current.body["part"] == "l-arm-3") {
          rowBody = "Left Brachial Artery";
        } else if (current.body["part"] == "l-arm-1r") {
          rowBody = "Left Radial Artery";
        } else if (current.body["part"] == "l-arm-0") {
          rowBody = "Left Radial-Ulnar Artery";
        } else if (current.body["part"] == "l-arm-1l") {
          rowBody = "Left Ulnar Artery";
        } else if (current.body["part"] == "l-arm-2") {
          rowBody = "Left Ulnar-Radial Artery";
        } else if (current.body["part"] == "r-leg-0") {
          rowBody = "Right External Iliac Artery";
        } else if (current.body["part"] == "r-leg-1") {
          rowBody = "Right Common Femoral Artery";
        } else if (current.body["part"] == "r-leg-2") {
          rowBody = "Right Proximal Superficial Femoral Artery";
        } else if (current.body["part"] == "r-leg-3") {
          rowBody = "Right Mid Superficial Femoral Artery";
        } else if (current.body["part"] == "r-leg-5") {
          rowBody = "Right Tibioperoneal Trunk";
        } else if (current.body["part"] == "r-leg-4") {
          rowBody = "Right Distal Superficial Femoral Artery";
        } else if (current.body["part"] == "r-leg-7l") {
          rowBody = "Right Mid Anterior Tibial Artery";
        } else if (current.body["part"] == "r-leg-8l") {
          rowBody = "Right Distal Anterior Tibial Artery";
        } else if (current.body["part"] == "r-leg-9") {
          rowBody = "Right Plantar Arch";
        } else if (current.body["part"] == "r-leg-8r") {
          rowBody = "Right Distal Posterior Tibial Artery";
        } else if (current.body["part"] == "r-leg-7r") {
          rowBody = "Right Mid Posterior Tibial Artery";
        } else if (current.body["part"] == "r-leg-6r") {
          rowBody = "Right Proximal Posterior Tibial Artery";
        } else if (current.body["part"] == "r-leg-o-3") {
          rowBody = "Right Distal Peroneal Artery";
        } else if (current.body["part"] == "r-leg-o-1") {
          rowBody = "Right Profunda Femoris";
        } else if (current.body["part"] == "r-leg-o-0") {
          rowBody = "Right Internal Iliac Artery";
        } else if (current.body["part"] == "r-leg-o-2") {
          rowBody = "Right Proximal Peroneal Artery";
        } else if (current.body["part"] == "r-leg-6l") {
          rowBody = "Right Proximal Anterior Tibial Artery";
        } else if (current.body["part"] == "r-neck-0") {
          rowBody = "Right Common Carotid Artery";
        } else if (current.body["part"] == "r-lung") {
          rowBody = "Right Subclavian Artery";
        } else if (current.body["part"] == "l-lung") {
          rowBody = "Left Subciavian Artery";
        } else if (current.body["part"] == "r-body-1") {
          rowBody = "Superior Mesenteric Artery";
        } else if (current.body["part"] == "r-body-0") {
          rowBody = "Celiac Artery";
        } else if (current.body["part"] == "l-leg-0") {
          rowBody = "Left External Iliac Artery";
        } else if (current.body["part"] == "l-leg-1") {
          rowBody = "Left Common Femoral Artery";
        } else if (current.body["part"] == "l-leg-2") {
          rowBody = "Left Proximal Superficial Femoral Artery";
        } else if (current.body["part"] == "l-leg-3") {
          rowBody = "Left Mid Superficial Femoral Artery";
        } else if (current.body["part"] == "l-leg-5") {
          rowBody = "Left Tibioperoneal Trunk";
        } else if (current.body["part"] == "l-leg-4") {
          rowBody = "Left Distal Superficial Femoral Artery";
        } else if (current.body["part"] == "l-leg-7r") {
          rowBody = "Left Mid Posterior Tibial Artery";
        } else if (current.body["part"] == "l-leg-8r") {
          rowBody = "Left Distal Posterior Tibial Artery";
        } else if (current.body["part"] == "l-leg-9") {
          rowBody = "Left Plantar Arch";
        } else if (current.body["part"] == "l-leg-8l") {
          rowBody = "Left Distal Anterior Tibial Artery";
        } else if (current.body["part"] == "l-leg-7l") {
          rowBody = "Left Mid Anterior Tibial Artery";
        } else if (current.body["part"] == "l-leg-6l") {
          rowBody = "Left Proximal Anterior Tibial Artery";
        } else if (current.body["part"] == "l-leg-o-3") {
          rowBody = "Left Distal Peroneal Artery";
        } else if (current.body["part"] == "l-leg-o-1") {
          rowBody = "Left Profunda Femoris";
        } else if (current.body["part"] == "l-leg-o-0") {
          rowBody = "Left Internal Iliac Artery";
        } else if (current.body["part"] == "l-leg-o-2") {
          rowBody = "Left Proximal Peroneal Artery";
        } else if (current.body["part"] == "l-leg-6r") {
          rowBody = "Left Proximal Posterior Tibial Artery";
        } else if (current.body["part"] == "r-leg") {
          rowBody = "Right Common Iliac Artery";
        } else if (current.body["part"] == "l-leg") {
          rowBody = "Left Common Iliac Artery";
        } else if (current.body["part"] == "l-neck-0") {
          rowBody = "Left Common Carotid Artery";
        } else {
          rowBody = current.body["part"];
        }
        setCheckItem(rowBody);
      }
      if (current.details && current.details[activeIndex]) {
        const detail = {
          ...current.details[activeIndex],
          item: activeItem ? getMedical(activeItem).value : "",
          body: current.body ? getArteryLabel(current.body.part) : "",
          post_stenosis:
            current.details[activeIndex] &&
            current.details[activeIndex].post_stenosis
              ? current.details[activeIndex].post_stenosis
              : activeItem && getMedical(activeItem).type === "status"
              ? getMedical(activeItem).value
              : "",
          cost:
            current.details[activeIndex] && current.details[activeIndex].cost,
        };
        setItem(detail);
      }
    }
  }, [current, activeItem]);

  const handleChange = (type) => (value) => {
    if (type === 'product_description') {
      const productItem = items.find((product) => product.name === value);

      setItem({ ...item, product: productItem, cost: productItem.cost, [type]: value });
      onSave({ ...item, product: productItem, cost: productItem.cost, [type]: value });
    } else {
      setItem({ ...item, [type]: value });
      onSave({ ...item, [type]: value });
    }
  };

  const handleFields = (type) => (value) => {
    setItem({ ...item, [type]: value });
    onSave({ ...item, [type]: value });
  };

  const onSave = (detail) => {
    if (handleSubmit) {
      handleSubmit(detail);
    }
  };

  const removeMedical = () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    removeItem(activeItem);
  };

  return (
    <div className="optDetails">
      <div className="optDetails-header">
        {medical && medical.icon ? medical.icon : <DevicesSVG />}
        <div className="optDetails-header__title">
          {medical && medical.label ? medical.label : title}
        </div>
      </div>
      <div className="optDetails-body">
        <div className="optDetails-body__fields">
          <div className="optDetails-body__fields-left">
            {medical && !medical.state && (
              <>
                <div className="optDetails-body__control">
                  <EditableFormInput
                    label={"Product Description"}
                    value={item.product_description}
                    type={"check"}
                    options={items || []}
                    valueKey={"name"}
                    optionKey={"name"}
                    fullWidth
                    handleChange={handleChange("product_description")}
                  />
                </div>
                <div className="optDetails-body__control">
                  <EditableFormInput
                    label={"Cost"}
                    handleChange={handleChange("cost")}
                    fullWidth
                    value={item.cost}
                    isError={!item.cost}
                  />
                </div>
              </>
            )}
            <div className="optDetails-body__control">
              <EditableFormInput
                label={"Location"}
                value={item.body || checkItem}
                fullWidth
              />
            </div>
          </div>
          <div className="optDetails-body__fields-right">
            {medical &&
              (medical.fields || []).map((field) => (
                <div className="optDetails-body__control" key={field}>
                  {field === "numberOfPasses" && (
                    <EditableFormInput
                      label={"Number of Passes"}
                      fullWidth
                      value={item.number_of_passes}
                      handleChange={handleFields("number_of_passes")}
                    />
                  )}
                  {field === "postStenosis" && (
                    <EditableFormInput
                      label={"Post-Stenosis"}
                      fullWidth
                      type={"check"}
                      value={item.post_stenosis}
                      handleChange={handleFields("post_stenosis")}
                      options={[
                        { value: "0%", label: "0%" },
                        { value: "20%", label: "20%" },
                        { value: "40%", label: "40%" },
                        { value: "60%", label: "60%" },
                        { value: "80%", label: "80%" },
                        { value: "100%", label: "100%" },
                      ]}
                      optionKey={"value"}
                      valueKey={"label"}
                    />
                  )}
                  {field === "pulsesDelivered" && (
                    <EditableFormInput
                      label={"Pulses Delivered"}
                      fullWidth
                      value={item.pulses_delivered}
                      handleChange={handleFields("pulses_delivered")}
                    />
                  )}
                  {field === "frequency" && (
                    <EditableFormInput
                      label={"Frequency"}
                      fullWidth
                      value={item.frequency}
                      handleChange={handleFields("frequency")}
                    />
                  )}
                  {field === "atmInflation" && (
                    <EditableFormInput
                      label={"ATM Inflation"}
                      fullWidth
                      value={item.atm}
                      handleChange={handleFields("atm")}
                      type={"check"}
                      options={[
                        { value: "3", label: "3 ATM" },
                        { value: "4", label: "4 ATM" },
                        { value: "5", label: "5 ATM" },
                        { value: "6", label: "6 ATM" },
                        { value: "7", label: "7 ATM" },
                        { value: "8", label: "8 ATM" },
                        { value: "9", label: "9 ATM" },
                        { value: "10", label: "10 ATM" },
                        { value: "11", label: "11 ATM" },
                        { value: "12", label: "12 ATM" },
                        { value: "13", label: "13 ATM" },
                        { value: "14", label: "14 ATM" },
                        { value: "15", label: "15 ATM" },
                        { value: "16", label: "16 ATM" },
                      ]}
                      optionKey={"value"}
                      valueKey={"label"}
                    />
                  )}
                  {field === "inflationTime" && (
                    <EditableFormInput
                      label={"Inflation Time"}
                      fullWidth
                      value={item.inflation_time}
                      handleChange={handleFields("inflation_time")}
                      type={"check"}
                      options={[
                        { value: "1", label: "1 minutes" },
                        { value: "2", label: "2 minutes" },
                        { value: "3", label: "3 minutes" },
                        { value: "4", label: "4 minutes" },
                      ]}
                      optionKey={"value"}
                      valueKey={"label"}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>

        <div className="optDetails-body__action">
          <button
            className={`common-btn ${
              confirmDelete ? "delete-btn" : "outlined-btn"
            }`}
            style={
              confirmDelete
                ? {
                    marginRight: 0,
                    backgroundColor: "#CA373B",
                  }
                : {}
            }
            onClick={removeMedical}
          >
            {confirmDelete ? "Confirm Delete?" : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessDetails;
