/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo } from 'react';
import { Upload } from 'antd';
import { useDispatch } from "react-redux";
import { adminSettingLogoUpload } from "../../../../../store/actions";
import ScrollWrapper from '../../../../../common/components/ScrollBar';
function DocumentLogoUploader({resource}) {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    if (!resource.loading && !!resource.logo_url) {
      setLogoUrl(resource.logo_url);
      setFile(null);
      setFileContent("");
    }
  }, [resource]);

  const uploadProps = useMemo(() => {
    return {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        if (file.type === "image/jpeg" || file.type === "image/png") {
          const reader = new FileReader();
          reader.onload = e => {
            setFileContent(e.target.result);
          };
          reader.readAsDataURL(file);
          setFile(file);
          return false;
        } else {
          alert('Please choose the correct image type.')
          return true;
        }
      },
    }
  }, []);

  const handleImageUpload = (e) => {
    const payload = {
      type: "logo",
      is_second: false,
      image: fileContent
    }

    dispatch(adminSettingLogoUpload(payload));
  }

  return (
      <div className="documentLogoUpload" style={{
        height: '85%'
      }} >
        <div className="FileImporter">
          <Upload 
            className="FileImporter__file" 
            {...uploadProps}
            style={{
              maxWidth: '190px'
            }}
          >
            {file 
              ? file.name.length > 20 
                ? file.name.slice(0, 10) + '...'  + file.name.slice(file.name.length-7, file.name.length-4) + '.' + file.type.split('/')[1]
                : file.name 
              : 'Choose File...'}
          </Upload>
          <button 
            onClick={e => handleImageUpload(e)}
            className="common-btn blue-btn"
          >
            { fileContent ? 'Upload File' :  'Import File'}
          </button>
        </div>
        <ScrollWrapper css="no-padding x-hidden">
          <div className="img-logo" 
            style={{
              paddingTop: "0",
              paddingBottom: "0",
              padding: '20px',
              marginTop: "0.5rem",
              border: '1px solid #d6d6d6',
              position: "relative"
            }}>

            {resource.loading 
              ? null
              : !!logoUrl && 
                <img 
                  src={logoUrl} 
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    position: "relative",
                  }}
                />
            }
          </div>      
        </ScrollWrapper>
      </div>
  );
}

export default DocumentLogoUploader;