import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "antd";
import moment from "moment";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ToastMessage from "../../../../common/components/Toast";
import {
  loadUltrasoundHistoryForConditionHPI,
  setGlobalLoading
} from "../../../../store/actions";
import HPISnapList from "./HPISnapList";
import _ from "lodash";

const HPISideBar = React.forwardRef(({
  isShow,
  handleCloseSidebar,
  conditions,
  clickedCondition,
  sidebarIcon
}, ref) => {

  const [filteredDescList, setFilteredDescList] = useState([]);
  const descList = useSelector((state) => state.clinicProviderHPI.descList);
  const globalLoading = useSelector((state) => state.common.globalLoading);
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let filteredDescList_ = [];
    for (let row of conditions) {
      let { condition } = row;
      let sublist = descList || [];
      if (sublist) {
        filteredDescList_.push({ condition, sublist });
      } else {
        filteredDescList_.push({ condition, sublist: [] });
      }
    }
    setFilteredDescList(filteredDescList_);
  }, [conditions, descList]);

  const [clickedConditionOnMain, setClickedConditionOnMain] = useState("");

  const setkey = async (key) => {
    setClickedConditionOnMain(key);
    let condition = key;
    if (condition) {
      condition = condition.replace("collapse-", "");
      if (condition.includes("_")) {
        const check = condition.split("_");
        condition = check.join(" ")
      }
      if (patientResource && patientResource.id) {
        await dispatch(setGlobalLoading(true));
        await dispatch(loadUltrasoundHistoryForConditionHPI(patientResource.id, condition));
        await dispatch(setGlobalLoading(false));
      }
    }
  };

  useEffect(() => {
    if (clickedCondition) {
      setClickedConditionOnMain(clickedCondition);
    }
  }, [clickedCondition]);

  if (!isShow) {
    return null;
  }

  const fullName = (
    patientResource.first_name +
    " " +
    patientResource.last_name
  ).toUpperCase();
  const age = patientResource.date_of_birth
    ? "(" + moment().diff(patientResource.date_of_birth, "years") + " yo)"
    : "";

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={sidebarIcon ? sidebarIcon :<MaleSVG />}
          title={<span className="text-uppercase">Condition Details</span>}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ paddingLeft: "12px", paddingRight: "8px" }}
        >
          <Collapse
            onChange={(key) => setkey(key)}
            accordion
            activeKey={clickedConditionOnMain}
          >
            {filteredDescList.map((data, index) => (
              <Collapse.Panel
                header={data.condition}
                key={`${_.snakeCase(data.condition)}`}
              >
                {!globalLoading && data.sublist && <HPISnapList list={data.sublist} />}
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default HPISideBar;
