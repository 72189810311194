import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Select, DatePicker, TimePicker } from "antd";
import { ExpandSVG, LeftCharvenSVG, CloseIcon } from "../icons/SVGIcon";
import Loading from "../Loading";
import {
  getFollowUp,
  getTimeSlots,
  saveProcedureSchedule,
  setProcedureData,
  rescheduleProcedure,
  get_plan_patient,
  resetFolow, setGlobalLoading
} from "../../../store/actions";
import ScrollWrapper from "../ScrollBar";
import SidebarTitle from "../SidebarTitle";
import { MaleSVG } from "../icons/FaxSVG";
import ToastMessage from "../Toast";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const { Option } = Select;

const getValue = (options, target, key) => {
  let opt = [];
  if (options && key && target) {
    opt = (options || []).find(
      (option) => option[key].toString() === target.toString()
    );
  }
  if (opt) {
    return opt.value;
  }
  return "";
};
const ClinicDiseaseComponent = ({
  procedure_id_row,
  checkUltra,
  nextSave,
  titlePlan,
  activeRow,
  onButton,
  plans,
  planTypes,
  typePlanId,
  changeSetProcessHandle,
  currentPlanId,
}) => {
  const locations = useSelector((state) => state.procedure.locations);
  const current_patient_id = cookies.get("current_patient_id");
  const current_appointment_id = cookies.get("current_appointment_id");
  const providers = useSelector((state) => state.procedure.providers);
  const clincLocations = useSelector((state) => state.procedure.clincLocations);
  const followupProviders = useSelector(
    (state) => state.procedure.followupProviders
  );
  const data = useSelector((state) => state.patientSchedule);
  const resource = useSelector((state) => state.procedure.resource);
  const loading = useSelector((state) => state.procedure.loadingSchedule);
  const planDetails = useSelector((state) => state.procedure.planDetails);
  const patient_plan = useSelector((state) => state.procedure.get_plan_patient);
  const timeSlots = useSelector((state) => state.procedure.timeSlots);
  const followupSlots = useSelector((state) => state.procedure.followupSlots);
  const loadingFirst = useSelector((state) => state.procedure.loadingTimeSlots);
  const loadingSecond = useSelector((state) => state.procedure.loadingFollowUp);
  const firstClinic = useSelector((state) => state.procedure.timeSlot);
  const secondClinic = useSelector((state) => state.procedure.followSlot);
  const ultraSchedules = useSelector((state) => state.newPatient);
  // 
  const [appointment_date_check, setAppointment_date] = useState(null);
  const [followup_appointment_check, setFollowup_appointment] = useState(null);
  // 
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [firstCheck, setFirstCheck] = useState(false);
  const [checkDetail, setCheckDetail] = useState({});
  const [endarterectomy, setEndarterectomy] = useState([]);
  const [follow_Up, setFollow_Up] = useState([]);
  const dispatch = useDispatch();
  const fullName = (
    data.selectedPatientResource.first_name +
    " " +
    data.selectedPatientResource.last_name
  ).toUpperCase();

  const fetchFrist = async () => {
    await dispatch(setProcedureData("timeSlot", -1));
    await dispatch(setProcedureData("followSlot", -1));
    // await dispatch(resetFolow());
    setAppointment_date(null);
    setFollowup_appointment(null);
    await setFirstCheck(false)
    await setCheckDetail({})
    if (plans && plans.length > 0) {
      const check = plans.find(r => r.condition_id == typePlanId);
      if (check) {
        const checkDetail = (check["plan"] || []).filter(r => r.entity_id == procedure_id_row);
        if (checkDetail && checkDetail[0]) {
          await setCheckDetail(checkDetail[0])
        }
      }
    }
  }

  useEffect(() => {
    (timeSlots || []).map((slot, index) => {
      if (moment(slot.start).format("MM/DD/YYYY") === moment(checkDetail?.procedure?.appointment_date).format("MM/DD/YYYY")) {
        dispatch(setProcedureData("timeSlot", index));
      } else {
        dispatch(setProcedureData("timeSlot", -1));
      }
      if (checkDetail?.followup_appointment?.appointment_date)
      {
        const newOption = {
          location: "",
          provider: "",
          period: checkDetail?.procedure?.appointment_date ? moment(checkDetail?.procedure?.appointment_date).format("MM/DD/YYYY") : "",
          prev_date: "",
        }
        dispatch(getFollowUp(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          newOption,
          false,
          false
        ));
      }
    });
    dispatch(setGlobalLoading(false));
  }, [timeSlots])

  useEffect(() => {
    (followupSlots || []).map((slot, index) => {
      if (moment(slot.start).format("MM/DD/YYYY") === moment(checkDetail?.followup_appointment?.appointment_date).format("MM/DD/YYYY")) {
        dispatch(setProcedureData("followSlot", index));
      } else {
        dispatch(setProcedureData("followSlot", -1));
      }
    });
  }, [followupSlots])

  useEffect(() => {
    if (checkDetail && Object.keys(checkDetail).length > 0) {
      if (checkDetail?.procedure?.appointment_date) {
        const value = moment(checkDetail?.procedure?.appointment_date).format("MM/DD/YYYY")
        setAppointment_date(value || null)
      } else {
        setAppointment_date(null)
      }
      if (checkDetail?.followup_appointment?.appointment_date) {
        const value = moment(checkDetail?.followup_appointment?.appointment_date).format("MM/DD/YYYY")
        setFollowup_appointment(value || null)
      } else {
        setFollowup_appointment(null)
      }
    }
  }, [checkDetail])

  useEffect(() => {
    fetchFrist();
  }, [procedure_id_row, plans, typePlanId, activeRow])

  const [activeCustom, setActiveCustom] = useState({
    first: false,
    second: false,
  });

  const [customAppointment, setCustomAppointment] = useState({
    first: {},
    second: {},
  });

  const [timeOption, setTimeOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });
  const [followOption, setFollowOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });
  const [questions, setQuestions] = useState({
    taking_metformin: -1,
    taking_eliquis: -1,
    taking_coumadin: -1,
    taking_plavix: -1,
    receive_coronary_stent: -1,
  });

  const saveAllDate = (type) => {
    if (type === "time") {
      if (timeSlots && timeSlots[0]) {
        const checkTimeSlots = timeSlots[0];
        if (checkTimeSlots) {
          if (
            endarterectomy &&
            !endarterectomy.some((r) => r.start === checkTimeSlots.start)
          ) {
            setEndarterectomy([...endarterectomy, checkTimeSlots]);
          }
        }
      }
    }

    if (type === "follow") {
      if (followupSlots && followupSlots[0]) {
        const checkFollowupSlots = followupSlots[0];
        if (checkFollowupSlots) {
          if (
            follow_Up &&
            !follow_Up.some((r) => r.start === checkFollowupSlots.start)
          ) {
            setFollow_Up([...follow_Up, checkFollowupSlots]);
          }
        }
      }
    }
  };

  const handleCustomChange = (idx, type, value) => {
    const customAppointments = { ...customAppointment };
    customAppointments[idx][type] = value;
    setCustomAppointment(customAppointments);
  };

  const toggleCustom = (type) => {
    const isCustomArray = { ...activeCustom };
    const customAppointments = { ...customAppointment };
    isCustomArray[type] = !isCustomArray[type];
    customAppointments[type] = isCustomArray[type]
      ? {
        location: "",
        date: "",
        time: "",
        provider: "",
      }
      : {};

    setActiveCustom(isCustomArray);
    setCustomAppointment(customAppointments);
  };

  const callApiDetail = async () => {
    setLoadingGlobal(true);
    console.log(procedure_id_row, "Ádasdkmasdmkamksd")
    if (procedure_id_row && !loadingGlobal) {
      const getDetailSuccess = await dispatch(
        rescheduleProcedure(current_patient_id, procedure_id_row, {}, false)
      );
      if (getDetailSuccess) {
        setLoadingGlobal(false);
      }
    }
  };

  useEffect(() => {
    if (procedure_id_row && procedure_id_row != 0) {
      callApiDetail();
    }
  }, [procedure_id_row]);

  const title = useMemo(() => {
    if (planDetails && planDetails.procedure) {
      return planDetails.procedure;
    } else {
      if (
        planDetails &&
        planDetails.ultrasound &&
        planDetails.ultrasound.us_test
      ) {
        return planDetails.ultrasound.us_test.name;
      }
    }
  }, [planDetails]);

  const setFiirst = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };
  const setSecond = async (index) => {
    await setFirstCheck(true);
    await dispatch(setProcedureData("followSlot", index));
  };

  const onSaveSchedule = async (
    firstClinicCheck,
    timeSlotsCheck,
    secondClinicCheck,
    followupSlotsCheck
  ) => {
    const date = moment(new Date()).format("MM/DD/YYYY");
    const timeSlot =
      timeSlotsCheck && firstClinicCheck && firstClinicCheck > -1
        ? timeSlotsCheck[firstClinicCheck]
        : timeSlotsCheck && timeSlotsCheck[0]
          ? timeSlotsCheck[0]
          : "";
    const followup =
      followupSlotsCheck && secondClinicCheck && secondClinicCheck > -1
        ? followupSlotsCheck[secondClinicCheck]
        : followupSlotsCheck && followupSlotsCheck[0]
          ? followupSlotsCheck[0]
          : "";
    if (timeSlot && followup) {
      dispatch(setGlobalLoading(true));
      const params = {
        procedure_date: moment(timeSlot.start).format("MM/DD/YYYY"),
        procedure_time: moment(timeSlot.start).format("HH:mm A"),
        procedure_location: timeSlot.location,
        procedure_provider: timeSlot.provider,
        followup_date: moment(followup.start).format("MM/DD/YYYY"),
        followup_time: moment(followup.start).format("HH:mm A"),
        followup_location: followup.location,
        followup_provider: followup.provider,
        plan_id: currentPlanId,
      };
      const loadingSuccess = await dispatch(
        setProcedureData("loadingSchedule", true)
      );
      const SaveSuccess = await dispatch(
        saveProcedureSchedule(procedure_id_row, params)
      );
      // const loadList = await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
      if (loadingSuccess && SaveSuccess) {
        if (changeSetProcessHandle) {
          changeSetProcessHandle(activeRow, typePlanId, params, "ClinicDisease")
        }
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          // nextSave();
        }, 1000);
      }
      dispatch(setGlobalLoading(false));
    } else if (timeSlot && !followup) {
      dispatch(setGlobalLoading(true));
      const params = {
        procedure_date: moment(timeSlot.start).format("MM/DD/YYYY"),
        procedure_time: moment(timeSlot.start).format("HH:mm A"),
        procedure_location: timeSlot.location,
        procedure_provider: timeSlot.provider,
        plan_id: currentPlanId,
      };
      const loadingSuccess = await dispatch(
        setProcedureData("loadingSchedule", true)
      );
      const SaveSuccess = await dispatch(
        saveProcedureSchedule(procedure_id_row, params)
      );
      if (loadingSuccess && SaveSuccess) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          // nextSave();
        }, 1000);
      }
      dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    if (!loading && !loadingFirst && !loadingSecond) {
      setFirstCheck(true)
      if (firstClinic != -1 && secondClinic != -1 && firstCheck) {
        setTimeout(() => {
          onSaveSchedule(
            firstClinic,
            timeSlots,
            secondClinic,
            followupSlots
          );
        }, 400);
      }
    }
  }, [firstClinic, secondClinic, timeSlots, followupSlots]);

  const handleChangeSlots = (type) => (value) => {
    const prev_date =
      firstClinic !== -1
        ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
        : timeSlots[0]
          ? moment(timeSlots[0].start).format("MM/DD/YYYY")
          : "";
    const newOption = { ...timeOption, prev_date, [type]: value };
    setTimeOption(newOption);
    dispatch(setProcedureData("loadingTimeSlots", true));
    dispatch(getTimeSlots(current_patient_id, procedure_id_row, newOption));
    setFiirst(-1);
  };

  const handleChangeFollow = (type) => (value) => {
    const prev_date =
      secondClinic !== -1
        ? moment(followupSlots[secondClinic].start).format("MM/DD/YYYY")
        : followupSlots[0]
          ? moment(followupSlots[0].start).format("MM/DD/YYYY")
          : timeSlots[firstClinic]
            ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
            : "";
    const newOption = { ...followOption, prev_date, [type]: value };
    setFollowOption(newOption);
    dispatch(setProcedureData("loadingFollowUp", true));
    dispatch(getFollowUp(current_patient_id, procedure_id_row, newOption));
    setSecond(-1);
  };

  const selectTimeSlot = (row, index) => {
    setFollow_Up([]);
    const newOption = {
      location: row.location,
      provider: row.provider,
      prev_date: moment(row.start).format("MM/DD/YYYY"),
      period: moment(row.start).format("A"),
    };
    setFiirst(index);
    setSecond(-1);
    setFollowup_appointment(null)
    dispatch(setProcedureData("loadingFollowUp", true));
    dispatch(
      getFollowUp(
        current_patient_id,
        procedure_id_row,
        {
          ...followOption,
          prev_date: moment(row.start).format("MM/DD/YYYY"),
        },
        false,
        "true"
      )
    );
  };

  const onHandleClick = () => {
    if (nextSave) {
      nextSave();
      setFiirst(-1);
      setSecond(-1);
      setAppointment_date(null);
      setFollowup_appointment(null);
    }
  }

  const handleMoreDates = (type) => {
    saveAllDate(type);
    if (timeSlots && timeSlots.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(timeSlots[timeSlots.length - 1].start).format(
          "MM/DD/YYYY"
        ),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(
        getTimeSlots(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          option,
          false,
          false
        )
      );
      setFiirst(-1);
    }
    if (followupSlots && followupSlots.length > 0 && type === "follow") {
      const newOption = {
        ...followOption,
        prev_date: moment(followupSlots[followupSlots.length - 1].start).format(
          "MM/DD/YYYY"
        ),
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(
        getFollowUp(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          newOption,
          false,
          false
        )
      );
      setSecond(-1);
    }
  };

  const handleQuestions = (type) => (value) => {
    const questionnaire = { ...questions, [type]: value };
    setQuestions(questionnaire);
  };

  const previous = (type) => {
    if (endarterectomy && endarterectomy.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(
          endarterectomy[endarterectomy.length - 1].start
        ).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(
        getTimeSlots(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          option
        )
      );
      setFiirst(-1);
      const aa = endarterectomy;
      aa.splice(endarterectomy.length - 1, 1);
      setEndarterectomy(aa);
    }
    if (follow_Up && follow_Up.length > 0 && type === "follow") {
      const newOption = {
        ...followOption,
        prev_date: moment(follow_Up[follow_Up.length - 1].start).format(
          "MM/DD/YYYY"
        ),
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(
        getFollowUp(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          newOption
        )
      );
      setSecond(-1);
      const aa = follow_Up;
      aa.splice(follow_Up.length - 1, 1);
      setFollow_Up(aa);
    }
  };

  const customerDate = (value, type) => {
    if (type === "time") {
      setEndarterectomy([]);
      const option = {
        ...timeOption,
        prev_date: moment(value).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(
        getTimeSlots(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          option,
          true,
          false
        )
      );
      setFiirst(-1);
    }
    if (type === "follow") {
      setFollow_Up([]);
      const newOption = {
        ...followOption,
        prev_date: moment(value).format("MM/DD/YYYY"),
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(
        getFollowUp(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          newOption,
          true,
          false
        )
      );
      setSecond(-1);
    }
  };

  const checkActive = (slot) => {
    if (appointment_date_check && moment(slot.start).format("MM/DD/YYYY") === moment(appointment_date_check).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  }


  const checkFollowActive = (slot) => {
    if (followup_appointment_check && moment(slot.start).format("MM/DD/YYYY") === moment(followup_appointment_check).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  }
  return (
    <>
      {isToastr && (
        <ToastMessage
          type="Plan/Orders"
          status="success"
          msg="Save Orders Successfully!"
        />
      )}

      <div
        className="resourceContent"
      >
        <div
          className="resourceInfo d-flex"
          style={{ justifyContent: "space-between" }}
        >
          <b>{`${titlePlan}`}</b>
          <button
            className="text-btn"
            style={{ marginLeft: "1rem" }}
            onClick={() => toggleCustom("first")}
          >
            {`Custom ${activeCustom["first"] ? "-" : "+"}`}
          </button>
        </div>

        <div style={{ position: "relative", marginBottom: "4px" }}>
          <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Location"
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeSlots("location")}
              >
                {(locations || []).map((loc, index) => (
                  <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                    {loc.value}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              Date
              <div>
                <button
                  disabled={loading || loadingFirst}
                  className="more-dates backward"
                  style={{
                    padding: "2px",
                    minWidth: 'unset',
                    width: 'auto',
                    height: 'auto',
                    border: 'none',
                    background: 'transparent',
                    color: '#3d70be',
                    marginTop: 0,
                  }}
                  onClick={() => {
                    previous("time");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
                <button
                  disabled={loading || loadingFirst}
                  className="more-dates forward"
                  style={{
                    padding: "2px",
                    minWidth: 'unset',
                    width: 'auto',
                    height: 'auto',
                    border: 'none',
                    background: 'transparent',
                    color: '#3d70be',
                    marginTop: 0,
                  }}
                  onClick={() => {
                    handleMoreDates("time");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
              </div>
            </div>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Time"
                suffixIcon={<span />}
                onChange={handleChangeSlots("period")}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
              >
                <Option value="am">AM</Option>
                <Option value="pm">PM</Option>
              </Select>
            </div>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Provider"
                suffixIcon={<span />}
                allowClear={false}
                mod="multiple"
                onChange={handleChangeSlots("provider")}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
              >
                {(providers || []).map((provider, index) => (
                  <Option
                    key={`provider-${provider.id}-${index}`}
                    value={provider.id}
                  >
                    {provider.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {!loadingFirst && (timeSlots || []).map((slot, index) => (
            <React.Fragment key={`time-slots-${index}`}>
              <div
                className={`resourceInfo between t-row 
                ${checkActive(slot) === "active" && firstClinic !== index && firstClinic == -1 ? "active" : ""} ${firstClinic === index ? "active" : ""}`}
                style={{ alignItems: "center" }}
                onClick={() => selectTimeSlot(slot, index)}
              >
                <div className="w-25">
                  {getValue(locations, slot.location, "id")}
                </div>
                <div className="w-25">
                  {slot.start && moment(slot.start).format("MM/DD/YYYY")}
                </div>
                <div className="w-25">
                  {slot.start && moment(slot.start).format("HH:mm A")}
                </div>
                <div className="w-25">
                  {getValue(providers, slot.provider, "id")}
                </div>
              </div>
              <hr className="small-block" />
            </React.Fragment>
          ))}
          {loadingFirst && (
              <React.Fragment key={`time-slots-1`}>
                <div
                    className={`resourceInfo between t-row`}
                    style={{ alignItems: "center" }}
                >
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                </div>
                <hr className="small-block" />
                <div
                    className={`resourceInfo between t-row`}
                    style={{ alignItems: "center" }}
                >
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                </div>
                <hr className="small-block" />
                <div
                    className={`resourceInfo between t-row`}
                    style={{ alignItems: "center" }}
                >
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                </div>
              </React.Fragment>
          )}
        </div>

        {activeCustom["first"] && (
          <div
            className="resourceInfo between mt-2 sortableHeader"
            style={{ background: "#f4f7fb" }}
          >
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Location"
                value={customAppointment["first"].location || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={(value) =>
                  handleCustomChange("first", "location", value)
                }
              >
                {(locations || []).map((loc, index) => (
                  <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                    {loc.value}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                paddingLeft: 0,
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <DatePicker
                onChange={(value) => {
                  handleCustomChange(
                    "first",
                    "date",
                    moment(value).format("MM/DD/YYYY")
                  );
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: 0,
                }}
                format={"MM/DD/YYYY"}
                placeholder="Date"
                suffixIcon={<ExpandSVG />}
              />
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <TimePicker
                format={"h:mm A"}
                use12Hours
                placeholder="Time"
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: 0,
                }}
                disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                minuteStep={15}
                onChange={(value) =>
                  handleCustomChange(
                    "first",
                    "time",
                    moment(value).format("h:mm A")
                  )
                }
              />
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Provider"
                value={customAppointment["first"].provider || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={(value) =>
                  handleCustomChange("first", "provider", value)
                }
              >
                {(providers || []).map((provider, index) => (
                  <Option
                    key={`provider-${provider.id}-${index}`}
                    value={provider.id}
                  >
                    {provider.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}

        <div style={{ marginTop: '1.5rem' }}>
          <div
            className="resourceInfo d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <b>{`${titlePlan} Follow-Up`}</b>
            <button
              className="text-btn"
              style={{ marginLeft: "1rem" }}
              onClick={() => toggleCustom("second")}
            >
              {`Custom ${activeCustom["second"] ? "-" : "+"}`}
            </button>
          </div>

          <div style={{ position: "relative", marginBottom: "4px" }}>
            <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Location"
                  suffixIcon={<span />}
                  allowClear={false}
                  onChange={handleChangeFollow("location")}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  {(clincLocations || []).map((loc, index) => (
                    <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                      {loc.value}
                    </Option>
                  ))}
                </Select>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                Date
                <div>
                  <button
                    disabled={loading || loadingSecond}
                    className="more-dates backward"
                    style={{
                      padding: "2px",
                      minWidth: 'unset',
                      width: 'auto',
                      height: 'auto',
                      border: 'none',
                      background: 'transparent',
                      color: '#3d70be',
                      marginTop: 0,
                    }}
                    onClick={() => {
                      previous("follow");
                    }}
                  >
                    <LeftCharvenSVG />
                  </button>
                  <button
                    disabled={loading || loadingSecond}
                    className="more-dates forward"
                    style={{
                      padding: "2px",
                      minWidth: 'unset',
                      width: 'auto',
                      height: 'auto',
                      border: 'none',
                      background: 'transparent',
                      color: '#3d70be',
                      marginTop: 0,
                    }}
                    onClick={() => {
                      handleMoreDates("follow");
                    }}
                  >
                    <LeftCharvenSVG />
                  </button>
                </div>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Time"
                  onChange={handleChangeFollow("period")}
                  suffixIcon={<span />}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  <Option value="am">AM</Option>
                  <Option value="pm">PM</Option>
                </Select>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Provider"
                  suffixIcon={<span />}
                  allowClear={false}
                  onChange={handleChangeFollow("provider")}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  {(followupProviders || []).map((provider, index) => (
                    <Option
                      key={`provider-${provider.id}-${index}`}
                      value={provider.id}
                    >
                      {provider.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {!loadingSecond && (followupSlots || []).map((slot, index) => (
              <React.Fragment key={`time-slots-${index}`}>
                <div
                  className={`resourceInfo between t-row 
                  ${checkFollowActive(slot) === "active" && secondClinic !== index && secondClinic == -1 ? "active" : ""} ${secondClinic === index ? "active" : ""}`}
                  style={{ alignItems: "center" }}
                  onClick={() => setSecond(index)}
                >
                  <div className="w-25">
                    {getValue(clincLocations, slot.location, "id")}
                  </div>
                  <div className="w-25">
                    {slot.start && moment(slot.start).format("MM/DD/YYYY")}
                  </div>
                  <div className="w-25">
                    {slot.start && moment(slot.start).format("HH:mm A")}
                  </div>
                  <div className="w-25">
                    {getValue(followupProviders, slot.provider, "id")}
                  </div>
                </div>
                <hr className="small-block" />
              </React.Fragment>
            ))}
          </div>
          {loadingSecond && (
              <React.Fragment key={`time-slots-2`}>
                <div
                    className={`resourceInfo between t-row`}
                    style={{ alignItems: "center" }}
                >
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                </div>
                <hr className="small-block" />
                <div
                    className={`resourceInfo between t-row`}
                    style={{ alignItems: "center" }}
                >
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                </div>
                <hr className="small-block" />
                <div
                    className={`resourceInfo between t-row`}
                    style={{ alignItems: "center" }}
                >
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                  <div className="w-25">

                  </div>
                </div>
              </React.Fragment>
          )}
          {activeCustom["second"] && (
            <div
              className="resourceInfo between mt-2 sortableHeader"
              style={{ background: "#f4f7fb" }}
            >
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Location"
                  value={customAppointment["second"].location || undefined}
                  suffixIcon={<span />}
                  allowClear={false}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                  onChange={(value) =>
                    handleCustomChange("second", "location", value)
                  }
                >
                  {(clincLocations || []).map((loc, index) => (
                    <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                      {loc.value}
                    </Option>
                  ))}
                </Select>
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  paddingLeft: 0,
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <DatePicker
                  onChange={(value) => {
                    handleCustomChange(
                      "second",
                      "date",
                      moment(value).format("MM/DD/YYYY")
                    );
                  }}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    background: "transparent",
                    boxShadow: "none",
                    borderBottom: 0,
                  }}
                  format={"MM/DD/YYYY"}
                  placeholder="Date"
                  suffixIcon={<ExpandSVG />}
                />
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <TimePicker
                  format={"h:mm A"}
                  use12Hours
                  placeholder="Time"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    boxShadow: "none",
                    borderBottom: 0,
                  }}
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                  minuteStep={15}
                  onChange={(value) =>
                    handleCustomChange(
                      "second",
                      "time",
                      moment(value).format("h:mm A")
                    )
                  }
                />
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Provider"
                  value={customAppointment["second"].provider || undefined}
                  suffixIcon={<span />}
                  allowClear={false}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                  onChange={(value) =>
                    handleCustomChange("second", "provider", value)
                  }
                >
                  {(followupProviders || []).map((provider, index) => (
                    <Option
                      key={`provider-${provider.id}-${index}`}
                      value={provider.id}
                    >
                      {provider.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="actionContent"
        style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}
      >
        <button onClick={() => {
          onHandleClick()
        }}>Continue</button>
      </div>
    </>
  );
};

export default ClinicDiseaseComponent;
