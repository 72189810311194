import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import {
  LeftCharvenSVG,
  RedCloseSVG,
  CheckSVG,
} from "../../../../common/components/icons/SVGIcon";
import { getUsers, getDetailUsers } from "../../../../store/actions";
import { Icon } from "antd";

const Active = ({ activeIndex, title, loading, handleRow }) => {
  const ref = useRef(null);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const size = useWindowSize(ref);
  const users = useSelector((state) => state.common.users);
  const dispatch = useDispatch();
  const tableRows =
    users && users["active_users"] && users["active_users"].length > 0
      ? users["active_users"]
      : [];

  let emptyRows = [];

  const fetchApi = async () => {
    await dispatch(getUsers());
  };

  // const fetchUserDetail = () => {
  //   if (users[0]) {
  //     dispatch(getDetailUsers(users[0].id));
  //   }
  // };

  useEffect(() => {
    // if (users.length <= 0) {
    //   fetchApi();
    // } else {
    //   if (users) {
    //     fetchUserDetail();
    //   }
    // }
    fetchApi();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const getLocaleString = (str) => {
    if (!str) {
      return "$0.00";
    }
    const num = parseFloat(str).toFixed(2);
    const decimals = num.split(".")[1];
    if (!decimals || decimals === "00") {
      return "$" + Math.round(parseFloat(str)).toLocaleString() + ".00";
    }
    if (decimals.charAt(1) === "0") {
      return (
        "$" + (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + "0"
      );
    }
    return "$" + (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
  };

  return (
    <div className="fullContainer pt-2">
      <div className="tableHeader">
        <div className="tableItem">
          <div
            className="th"
            style={{ width: "calc(31% - 2rem)", marginLeft: "2rem" }}
          >
            <b>Employee Name</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Position</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Points</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Wage</b>
          </div>
        </div>
      </div>
      <div className="tableSection">
        <div ref={ref} className="tableContainer">
          <ScrollWrapper css="no-padding">
            <div className="tableList">
              {tableRows.map((row, index) => (
                <div
                  className={`tableItem yellow ${
                    activeIndex === index ? "active" : ""
                  }`}
                  key={`resultTable-${title}-${index}`}
                  onClick={handleRow(index, row)}
                >
                  <div className="td with-icon" style={{ width: "31%" }}>
                    <div className="iconField">
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
                    </div>
                    <p>{row.name}</p>
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    <p>{row.position}</p>
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    <p>{row.meta && row.meta.points}</p>
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    <p>{row.meta && getLocaleString(row.meta.salary)}</p>
                  </div>
                </div>
              ))}
              {emptyRows.map((row, index) => (
                <div
                  className={`tableItem empty`}
                  key={`resultTable-empty-${title}-${index}`}
                >
                  <div className="td" style={{ width: "31%" }} />
                  <div className="td" style={{ width: "23%" }} />
                  <div className="td" style={{ width: "23%" }} />
                  <div className="td" style={{ width: "23%" }} />
                </div>
              ))}
            </div>
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default Active;
