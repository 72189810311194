import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import ScrollWrapper from "../../../../common/components/ScrollBar";
import ReportingSidebar from "./ReportingSidebar";
import { StaticResource } from "../../constant";
import Loading from "../../../../common/components/Loading";
import {
  LeftCharvenSVG,
} from "../../../../common/components/icons/SVGIcon";
import EditableFormINput from "../../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../../common/components/Toast";
import {
  incident_reports_post,
  getLocations,
  getCurrentUserInfo,
  setGlobalLoading
} from "../../../../store/actions";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const { TextArea } = Input;
const cookies = new Cookies();

const Reporting = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(undefined);
  const [time, setTime] = useState(undefined);
  const [location, setLocation] = useState(undefined);
  const [summary, setSummary] = useState(undefined);
  const [variance, setVariance] = useState(undefined);
  const locations = useSelector((state) => state.common.locations);
  const authId = cookies.get("cvai-current-user");
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const fetchLocations = () => {
    dispatch(getLocations());
  };

  const fetchCurrentUser = () => {
    if (authId) {
      dispatch(getCurrentUserInfo(authId));
    }
  };

  const refereshState = () => {
    setSummary(undefined);
    setDate(undefined);
    setTime(undefined);
    setVariance(undefined);
    setTime(undefined);
    setLocation(undefined);
  };

  const saveAll = async () => {
    const checkSaveAll = await dispatch(
      incident_reports_post({
        user_id: authId,
        location_id: location,
        summary: summary,
      })
    );
    if (checkSaveAll) {
      setLoading(false);
      setIsToastr(true);
      refereshState();
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  useEffect(() => {
    fetchLocations();
    fetchCurrentUser();
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    } else {
      handleSidebar();
    }
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading])
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="timeOff__form" style={{ height: "100%" }}>
            {isToastr && (
              <ToastMessage
                type="Schedules"
                status="success"
                msg="Saved Successfully!"
              />
            )}
            <ScrollWrapper css="no-padding">
              <div className="timeOff__form-container">
                <div
                  className="timeOff__form-control"
                  style={{ maxWidth: "15%", padding: "0 0.25rem" }}
                >
                  <EditableFormINput
                    label={"Incident Date"}
                    value={date}
                    type="date"
                    handleChange={setDate}
                  />
                </div>

                <div
                  className="timeOff__form-control"
                  style={{ maxWidth: "15%", padding: "0 0.25rem" }}
                >
                  <EditableFormINput
                    label={"Incident Time"}
                    value={time}
                    type="time"
                    handleChange={setTime}
                  />
                </div>

                <div
                  className="timeOff__form-control"
                  style={{ maxWidth: "20%", padding: "0 0.25rem" }}
                >
                  <EditableFormINput
                    label={"Variance"}
                    value={variance}
                    type="check"
                    options={[
                      { value: "Quality", label: "Quality" },
                      { value: "Patient Safety", label: "Patient Safety" },
                      { value: "Employee Conduct", label: "Employee Conduct" },
                      { value: "Hazard", label: "Hazard" }
                    ]}
                    optionKey="value"
                    valueKey="label"
                    handleChange={setVariance}
                  />
                </div>

                <div
                  className="timeOff__form-control"
                  style={{ maxWidth: "30%", padding: "0 0.25rem" }}
                >
                  <EditableFormINput
                    label={"Facility"}
                    value={location}
                    type="check"
                    options={locations || []}
                    optionKey="id"
                    valueKey="value"
                    handleChange={setLocation}
                  />
                </div>
              </div>
              <div className="timeOff__form-result flex-column">
                <div className="timeOff__form-control--label pt-2 pb-2">
                  Details
                </div>
                <TextArea
                  onChange={(event) => {
                    setSummary(event.target.value);
                  }}
                  value={summary || undefined}
                  className="detail-text"
                  autoSize={{ minRows: 10 }}
                />
                <div
                  className="timeOff__form-action"
                  style={{ paddingBottom: "0.5rem" }}
                >
                  <button onClick={saveAll} className="common-btn">
                    Submit
                  </button>
                </div>
              </div>
            </ScrollWrapper>
          </div>
        </div>
      </div>
      <ReportingSidebar
        ref={sidebarRef}
        currentUserInfo={currentUserInfo}
        resource={StaticResource}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
      {currentUserInfo && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Reporting;
