/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";

import Loading from "../../../common/components/Loading";
import {
  addNewPatient as addPatient,
  editDemographicsOfPatient,
} from "../../../services/api.services";
import {
  setPatientSnapshotData,
  setGlobalLoading as setCommonGlobalLoading,
  autoZip,
  patientScheduleConfirmCheckin
} from "../../../store/actions";
import { validateSteps, validateField } from "../../../services/validations";
import {
  newSnapshotOption,
  stateOptions,
} from "../../../common/constants/initialData";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import PusherScan from "../../components/PusherScan";
import ToastMessage from "../../components/Toast";
import Cookies from "universal-cookie";

const { demographics } = newSnapshotOption;

let timer = null;
const Demographics = ({ handleContinue, ...restProps }) => {
  const resource = useSelector((state) => state.patientSnapshot.resource);
  const scanToken = useSelector((state) => state.scanDriverLicense.scanToken);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const [toastMessage, setToastMessage] = useState(null);
  const [checkInStatus, setCheckInStatus] = useState(false);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const tabActive = useSelector(
    (state) => state.clinicProviderReview.tabActive
  );
  const zipAutoData = useSelector((state) => state.common.zipAuto);
  const dispatch = useDispatch();
  const [patient, setPatient] = useState({});
  const [enableError, setEnableError] = useState(false);
  const [first, setFirst] = useState(false);
  const [qrData, setQRData] = useState(); // panamacheng
  const [clickedScanID, setClickedScanID] = useState(false); // panamacheng
  const cookies = new Cookies();

  const [reset, setReset] = useState(false);

  useEffect(() => {
    setFirst(true);
  }, []);

  // ID card display disable => panamacheng modified.
  // const [disableCard, setDisableCard] = useState(false);
  const { disableIDImage } = restProps; // remove this row after implementing the parent function for props.

  const procedure_id = cookies.get("current_appointment_id");

  useEffect(() => {
    dispatch(setCommonGlobalLoading(loadingGlobal));
  }, [loadingGlobal]);

  useEffect(() => {
    if (resource && resource.id !== patient.id) {
      let address = "";
      if (resource.address) {
        const splitedAddress = resource.address.split(" ").map((addr) => {
          if (!addr || !addr.length) {
            return "";
          }
          if (addr.length < 2) {
            return addr.charAt(0).toUpperCase();
          }
          return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
        });
        address = splitedAddress.join(" ");
      }
      setPatient({ ...resource, address });
      restProps.setOpenAddForm(false);
      setEnableError(true);
      setTimeout(() => {
        setReset(true);
      }, 1000);
    }
  }, [resource]);

  useEffect(() => {
    onClickedScanID();
  }, [resource, scanToken])

  const onClickedScanID = () => {
    const inputSignData = {
      type: "input-sign",
      signature_data: {
        patient_id: resource.id,
        card_type: "drivers_license_card",
        token: scanToken,
      },
    };

    setClickedScanID(true);
    setQRData(inputSignData);
  };

  const onClickedCancel = async () => {
    setClickedScanID(false);
  };

  const handleScanQRCode = () => {
    setClickedScanID(false);
  };

  const onChangePatient = (type) => async (value) => {
    if (type == "zipcode") {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(autoZip(value));
      }, 500);
      const newPatient = {
        ...patient,
        [type]: value,
      };
      setPatient(newPatient);
    } else {
      const newPatient = {
        ...patient,
        [type]: value,
      };
      setPatient(newPatient);
      setEnableError(false);
      if (!restProps.isAddForm) {
        reset && changeHandler(newPatient);
      }
    }
  };

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first) {
      if (zipAutoData.success) {
        if (zipAutoData.addresses && zipAutoData.addresses[0]) {
          const address = zipAutoData.addresses[0];
          const param = {
            ...patient,
            city: address.place,
          };
          setPatient(param);
          updatePatient(param);
          setReset(true);
        }
      } else {
        const param = {
          ...patient,
          city: undefined,
        };
        setPatient(param);
        updatePatient(param);
        setReset(true);
      }
    }
  }, [zipAutoData]);

  const onChangeAddress = (type) => (value) => {
    let address = "";
    if (value) {
      const splitedAddress = value.split(" ").map((addr) => {
        if (!addr || !addr.length) {
          return "";
        }
        if (addr.length < 2) {
          return addr.charAt(0).toUpperCase();
        }
        return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
      });
      address = splitedAddress.join(" ");
    }

    const newPatient = {
      ...patient,
      [type]: address,
    };
    setPatient(newPatient);
    setEnableError(false);
    if (!restProps.isAddForm) {
      reset && changeHandler(newPatient);
    }
  };

  const checkIn = () => {
    setCheckInStatus(true);
    dispatch(patientScheduleConfirmCheckin(procedure_id));
  };

  const openAddForm = () => {
    setPatient({});
    restProps.setOpenAddForm(true);
    setEnableError(true);
  };

  const addNewPatient = async () => {
    if (!validateSteps(patient, demographics)) {
      setEnableError(true);
      return;
    }
    setLoadingGlobal(true);
    try {
      const result = await addPatient(patient);
      restProps.setOpenAddForm(false);
      setPatient(result.data.data);
      await dispatch(setPatientSnapshotData("resource", result.data.data));
      setLoadingGlobal(false);

      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    } catch (error) {
      restProps.setOpenAddForm(false);
      setPatient(resource);
      setLoadingGlobal(false);
    }
  };

  const updatePatient = async (patient) => {
    const updatedPatient = { ...patient };
    updatedPatient["date_of_birth"] =
      patient["date_of_birth"] &&
      moment(patient["date_of_birth"]).format("MM/DD/YYYY");
    updatedPatient["referral_date"] =
      patient.referral_date &&
      moment(patient.referral_date).format("MM/DD/YYYY");
    updatedPatient["primary_care_provider_id"] =
      patient["primary_care_provider_id"] || patient.pcp;
    // if (!validateSteps(updatedPatient, demographics)) {
    //   setEnableError(true);
    //   return;
    // }
    setLoadingGlobal(true);
    try {
      await editDemographicsOfPatient(updatedPatient);
      restProps.setOpenAddForm(false);
      await dispatch(setPatientSnapshotData("resource", patient));
      setIsToastr(true);
      setLoadingGlobal(false);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    } catch (error) {
      restProps.setOpenAddForm(false);
      setLoadingGlobal(false);
    }
  };

  const changeHandler = useCallback(
    debounce((patient) => updatePatient(patient), 1000),
    []
  );

  const showToast = () => {
    return (
      <ToastMessage
        type="New Patient"
        status={"success"}
        msg={toastMessage ? toastMessage : "New patient is added successfully"}
      />
    );
  };

  const cancelAddForm = () => {
    restProps.setOpenAddForm(false);
    setEnableError(true);
    setPatient(resource);
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
      {isToastr && !errorRest && showToast()}
      {!restProps.isAddForm && (
        <React.Fragment>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"First Name"}
              value={patient.first_name}
              handleChange={onChangePatient("first_name")}
              required={true}
              isError={validateField("text", patient.first_name)}
              enableErrorText={enableError}
              helperText={"First Name is Required!"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Last Name"}
              value={patient.last_name}
              handleChange={onChangePatient("last_name")}
              required={true}
              isError={validateField("text", patient.last_name)}
              enableErrorText={enableError}
              helperText={"Last Name is Required!"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"DOB"}
              value={patient.date_of_birth}
              handleChange={onChangePatient("date_of_birth")}
              required={true}
              type="date"
              isError={validateField("date", patient.date_of_birth)}
              enableErrorText={enableError}
              helperText={"Birthday should be MM/DD/YYYY!"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Gender"}
              handleChange={onChangePatient("gender")}
              value={patient.gender}
              required={true}
              isError={validateField("text", patient.gender)}
              enableErrorText={enableError}
              helperText={"Gender is Required!"}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "male", name: "Male" },
                { value: "female", name: "Female" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Language"}
              handleChange={onChangePatient("preferred_language")}
              value={patient.preferred_language}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "English", name: "English" },
                { value: "Spanish", name: "Spanish" },
                { value: "Other", name: "Other" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Marital Status"}
              handleChange={onChangePatient("marital_status")}
              value={patient.marital_status}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "Married", name: "Married" },
                { value: "Single", name: "Single" },
                { value: "Divorced", name: "Divorced" },
                { value: "Widowed", name: "Widowed" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Ethnicity"}
              handleChange={onChangePatient("ethnicity")}
              value={patient.ethnicity}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "White", name: "White" },
                { value: "Hispanic", name: "Hispanic" },
                {
                  value: "Not Hispanic or Latino",
                  name: "Not Hispanic or Latino",
                },
                { value: "Other", name: "Other" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Phone No"}
              value={patient.phone}
              handleChange={onChangePatient("phone")}
              required={true}
              isError={validateField("phone", patient.phone)}
              enableErrorText={enableError}
              helperText={"Phone Number should be (XXX) XXX-XXXX format"}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Phone No"}
              value={patient.secondary_phone}
              handleChange={onChangePatient("secondary_phone")}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"E-Mail"}
              value={patient.email}
              handleChange={onChangePatient("email")}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"SSN"}
              value={patient.ssn}
              handleChange={onChangePatient("ssn")}
              mask={"ssn"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Address"}
              value={patient.address}
              handleChange={onChangeAddress("address")}
              required={true}
              isError={validateField("text", patient.address)}
              enableErrorText={enableError}
              helperText={"Address is required"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Apt"}
              value={patient.apt_number}
              handleChange={onChangePatient("apt_number")}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"State"}
              value={patient.state || undefined}
              handleChange={onChangePatient("state")}
              type={"check"}
              valueKey={"value"}
              optionKey={"id"}
              options={stateOptions || []}
              required={true}
              isError={validateField("text", patient.state)}
              enableErrorText={enableError}
              helperText={"State is required"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"City"}
              value={patient.city}
              handleChange={onChangePatient("city")}
              required={true}
              isError={validateField("text", patient.city)}
              enableErrorText={enableError}
              helperText={"City is required"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Zip Code"}
              value={patient.zipcode}
              handleChange={onChangePatient("zipcode")}
              required={true}
              isError={validateField("text", patient.zipcode)}
              enableErrorText={enableError}
              helperText={"Zip Code is required"}
            />
          </div>
          <hr />
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Diagnosis"}
              value={patient.referral_condition || undefined}
              handleChange={onChangePatient("referral_condition")}
              required={true}
              type={"check"}
              isError={validateField("text", patient.referral_condition)}
              optionKey={"value"}
              valueKey={"value"}
              options={restProps.referralConditions || []}
              enableErrorText={enableError}
              helperText={"Diagnosis is required"}
            />
          </div>
          {patient.referral_condition === "Other" && (
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Diagnosis Specifics"}
                value={patient.diagnosis_specifics}
                handleChange={onChangePatient("diagnosis_specifics")}
              />
            </div>
          )}
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Referring"}
              value={patient.referral_provider || undefined}
              handleChange={onChangePatient("referral_provider")}
              type={"check"}
              optionKey={"id"}
              valueKey={"full_name"}
              options={restProps.pcpList || []}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"PCP"}
              value={patient.primary_care_provider_id || undefined}
              handleChange={onChangePatient("primary_care_provider_id")}
              type={"check"}
              optionKey={"id"}
              valueKey={"full_name"}
              options={restProps.pcpList || []}
            />
          </div>

          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Referral Date"}
              value={patient.referral_date}
              type="date"
              handleChange={onChangePatient("referral_date")}
              required={true}
              isError={validateField("text", patient.referral_date)}
              enableErrorText={enableError}
              helperText={"Referral Date is required"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Pharmacy"}
              value={patient.pharmacy_id || undefined}
              handleChange={onChangePatient("pharmacy_id")}
              type={"check"}
              optionKey={"id"}
              valueKey={"value"}
              options={restProps.providers || []}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Preferred Provider"}
              value={patient.preferred_provider || undefined}
              handleChange={onChangePatient("preferred_provider")}
              type={"check"}
              optionKey={"id"}
              valueKey={"value"}
              options={restProps.providers || []}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Advance Directives"}
              value={patient.advanced_directive || undefined}
              handleChange={onChangePatient("advanced_directive")}
              required={true}
              type={"check"}
              isError={validateField("text", patient.advanced_directive)}
              optionKey={"value"}
              options={[
                { value: "No Advance Directives On File" },
                { value: "Advance Directives On File" },
              ]}
              enableErrorText={tabActive ? true : false}
              helperText={"Advance Directives"}
            />
          </div>

          {!!restProps.isScan && (
            <>
              {!disableIDImage && (
                <React.Fragment>
                  <hr />
                  {resource.id === undefined ? (
                    <Loading />
                  ) : (
                    <PusherScan
                      signType="input-sign" // define component type.
                      scanData={resource} // define initial data.
                      cardType="drivers_license_card"
                      handleScanQRCode={handleScanQRCode}
                      isQRcode={clickedScanID}
                      qrData={qrData}
                    />
                  )}
                  <hr />
                </React.Fragment>
              )}
            </>
          )}
        </React.Fragment>
      )}
      {restProps.isAddForm && (
        <React.Fragment>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"First Name"}
              value={patient.first_name}
              handleChange={onChangePatient("first_name")}
              required={true}
              isError={validateField("text", patient.first_name)}
              enableErrorText={enableError}
              helperText={"First Name is Required!"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Last Name"}
              value={patient.last_name}
              handleChange={onChangePatient("last_name")}
              required={true}
              isError={validateField("text", patient.last_name)}
              enableErrorText={enableError}
              helperText={"Last Name is Required!"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"DOB"}
              value={patient.date_of_birth}
              handleChange={onChangePatient("date_of_birth")}
              required={true}
              type="date"
              isError={validateField("date", patient.date_of_birth)}
              enableErrorText={enableError}
              helperText={"Birthday should be MM/DD/YYYY!"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Gender"}
              handleChange={onChangePatient("gender")}
              value={patient.gender}
              required={true}
              isError={validateField("text", patient.gender)}
              enableErrorText={enableError}
              helperText={"Gender is Required!"}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "male", name: "Male" },
                { value: "female", name: "Female" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Language"}
              handleChange={onChangePatient("preferred_language")}
              value={patient.preferred_language}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "English", name: "English" },
                { value: "Spanish", name: "Spanish" },
                { value: "Other", name: "Other" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Marital Status"}
              handleChange={onChangePatient("marital_status")}
              value={patient.marital_status}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "Married", name: "Married" },
                { value: "Single", name: "Single" },
                { value: "Divorced", name: "Divorced" },
                { value: "Widowed", name: "Widowed" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Ethnicity"}
              handleChange={onChangePatient("ethnicity")}
              value={patient.ethnicity}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "White", name: "White" },
                { value: "Hispanic", name: "Hispanic" },
                {
                  value: "Not Hispanic or Latino",
                  name: "Not Hispanic or Latino",
                },
                { value: "Other", name: "Other" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Phone No"}
              value={patient.phone}
              handleChange={onChangePatient("phone")}
              required={true}
              isError={validateField("phone", patient.phone)}
              enableErrorText={enableError}
              helperText={"Phone Number should be (XXX) XXX-XXXX format"}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Phone No"}
              value={patient.secondary_phone}
              handleChange={onChangePatient("secondary_phone")}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"E-Mail"}
              value={patient.email}
              handleChange={onChangePatient("email")}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"SSN"}
              value={patient.ssn}
              handleChange={onChangePatient("ssn")}
              mask={"ssn"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Address"}
              value={patient.address}
              handleChange={onChangeAddress("address")}
              required={true}
              isError={validateField("text", patient.address)}
              enableErrorText={enableError}
              helperText={"Address is required"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Apt"}
              value={patient.apt_number}
              handleChange={onChangePatient("apt_number")}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"State"}
              value={patient.state}
              handleChange={onChangePatient("state")}
              required={true}
              isError={validateField("text", patient.state)}
              enableErrorText={enableError}
              helperText={"State is required"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"City"}
              value={patient.city}
              handleChange={onChangePatient("city")}
              required={true}
              isError={validateField("text", patient.city)}
              enableErrorText={enableError}
              helperText={"City is required"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Zip Code"}
              value={patient.zipcode}
              handleChange={onChangePatient("zipcode")}
              required={true}
              isError={validateField("text", patient.zipcode)}
              enableErrorText={enableError}
              helperText={"Zip Code is required"}
            />
          </div>
          <hr />
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Diagnosis"}
              value={patient.referral_condition || undefined}
              handleChange={onChangePatient("referral_condition")}
              required={true}
              type={"check"}
              isError={validateField("text", patient.referral_condition)}
              optionKey={"value"}
              options={restProps.referralConditions || []}
              enableErrorText={enableError}
              helperText={"Diagnosis is required"}
            />
          </div>
          {patient.referral_condition === "Other" && (
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                label={"Diagnosis Specifics"}
                value={patient.diagnosis_specifics}
                handleChange={onChangePatient("diagnosis_specifics")}
              />
            </div>
          )}
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Referring"}
              value={patient.referral_provider || undefined}
              handleChange={onChangePatient("referral_provider")}
              type={"check"}
              optionKey={"id"}
              valueKey={"full_name"}
              options={restProps.pcpList || []}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"PCP"}
              value={patient.primary_care_provider_id || undefined}
              handleChange={onChangePatient("primary_care_provider_id")}
              type={"check"}
              optionKey={"id"}
              valueKey={"full_name"}
              options={restProps.pcpList || []}
            />
          </div>

          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Referral Date"}
              value={patient.referral_date}
              type="date"
              handleChange={onChangePatient("referral_date")}
              required={true}
              isError={validateField("text", patient.referral_date)}
              enableErrorText={enableError}
              helperText={"Referral Date is required"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Pharmacy"}
              value={patient.pharmacy_id || undefined}
              handleChange={onChangePatient("pharmacy_id")}
              type={"check"}
              optionKey={"id"}
              valueKey={"value"}
              options={restProps.providers || []}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Preferred Provider"}
              value={patient.preferred_provider || undefined}
              handleChange={onChangePatient("preferred_provider")}
              type={"check"}
              optionKey={"id"}
              valueKey={"value"}
              options={restProps.providers || []}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Advance Directives"}
              value={patient.advanced_directive || undefined}
              handleChange={onChangePatient("advanced_directive")}
              required={true}
              type={"check"}
              isError={validateField("text", patient.advanced_directive)}
              optionKey={"value"}
              options={[
                { value: "No Advance Directives On File" },
                { value: "Advance Directives On File" },
              ]}
              enableErrorText={tabActive ? true : false}
              helperText={"Advance Directives"}
            />
          </div>
        </React.Fragment>
      )}

      {!restProps.isAddForm && (
        <div className="actionContent" style={{ flexWrap: "wrap" }}>
            <button className="common-btn blue-btn" style={{
              display: 'flex',
              justifyContent: 'space-between'
            }} onClick={openAddForm}>
              <span>Add New Patient</span>
              <span>+</span>
            </button>
          {restProps.canCheckIn && (
            <>
              <button className={checkInStatus ? "outlined-btn" : "common-btn"} style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: "0.5rem", 
                marginBottom: "0.5rem"
              }} onClick={checkIn}>
                <span>Check In</span>
                <span>+</span>
              </button>
            </>
          )}
        </div>
      )}
      {restProps.isAddForm && (
        <div className="actionContent">
          <button
            className="common-btn outlined-btn"
            style={{ marginRight: "0.5rem" }}
            onClick={cancelAddForm}
          >
            Back
          </button>
          <button className="common-btn" onClick={addNewPatient}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default Demographics;
