import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {Select, DatePicker, TimePicker, Collapse, Icon, Input} from "antd";
import {ExpandSVG, LeftCharvenSVG, CloseIcon, CloseIconSVG} from "../icons/SVGIcon";
import ScrollWrapper from "../ScrollBar";
import Loading from "../Loading";
import {
  setProcedureData,
  getQuestionsForProcedure, get_plan_patient, setGlobalLoading, saveProcedureChoice
} from "../../../store/actions";
import ToastMessage from "../Toast";
import Cookies from "universal-cookie";
import _ from "lodash";
const cookies = new Cookies();

const { Option } = Select;

const GeneralComponent = ({
                           procedure_id_row,
                           handleContinue,
                           nextSave,
                           titleProps,
                           display,
                           titlePlan,
                           activeRow,
                           onButton,
                           plans,
                           planTypes,
                           typePlanId,
                           currentPlanId,
                           ...restProps
                         }) => {
  const current_patient_id = cookies.get("current_patient_id");
  const current_appointment_id = cookies.get("current_appointment_id");

  const activeRows = useSelector((state) => state.newPatient.activeRows);
  const questions = useSelector((state) => state.procedure.questions);

  const dispatch = useDispatch();
  const [isToastr, setIsToastr] = useState(false);
  const [checkDetail, setCheckDetail] = useState({});
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [data, setData] = useState([]);

  const fetchFrist = async () => {
    await dispatch(setProcedureData("timeSlot", -1));
    await setCheckDetail({})
    if (plans && plans.length > 0) {
      const check = plans.find(r => r.condition_id == typePlanId);
      if (check) {
        const checkDetail = (check["plan"] || []).filter(r => r.entity_id == procedure_id_row);
        if (checkDetail && checkDetail[0]) {
          await setCheckDetail(checkDetail[0])
        }
      }
    }
  };

  useEffect(() => {
    fetchFrist();
  }, [procedure_id_row, plans, typePlanId, activeRow]);

  useEffect(() => {
    setData(questions);
  }, [questions]);

  const callApiDetail = async () => {
    if (procedure_id_row) {
      const getDetailSuccess = await dispatch(getQuestionsForProcedure(procedure_id_row));
      if (getDetailSuccess) {
        setLoadingGlobal(false);
      }
    }
  };

  useEffect(() => {
    callApiDetail();
  }, [procedure_id_row]);

  const setFiirst = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const onHandleClick = () => {
    if (nextSave) {
      nextSave();
      setFiirst(-1);
    }
  };

  const onSelectChoice = (questionIndex, checked_choice) => {
    let data_ = [...data];
    if (
        data_[questionIndex]["checked_choice"] !=
        checked_choice
    ) {
      data_[questionIndex][
          "checked_choice"
          ] = checked_choice;
    } else if (
        data_[questionIndex]["checked_choice"] ==
        checked_choice
    ) {
      data[questionIndex]["checked_choice"] = "";
    }
    setData(data_);
    onChangeData(data_);
  };

  const onChangeData = (questions) => {
    const timer = setTimeout(async () => {
      dispatch(setGlobalLoading(true));
      const params = {
        questions: questions,
        plan_id: currentPlanId,
      };
      const SaveSuccess  = await dispatch(saveProcedureChoice(procedure_id_row, params));
      const loadingSuccess = await dispatch(
          setProcedureData("loadingSchedule", true)
      );
      const loadList = await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
      if (SaveSuccess && loadList && loadingSuccess) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          // nextSave();
        }, 1000);
      }
      dispatch(setGlobalLoading(false));
    }, 500);
  };

  return (
      <>
        <div
            className="resourceContent"
        >
          {isToastr && (
              <ToastMessage
                  type="Plan/Orders"
                  status="success"
                  msg="Save Plan Successfully!"
              />
          )}
          <React.Fragment key={`clinic-appointment-${0}`}>
              {(data || []).map((row, index) => {
                return (
                  <div className="content" key={`content-${index}`}>
                    <div className="hpiList">
                      <HPIConditionItem
                          key={`questionList-${index}`}
                          data={row}
                          onSelectChoice={(checked_choice) =>
                              onSelectChoice(index, checked_choice)
                          }
                      />
                    </div>
                  </div>
                )
              })}
          </React.Fragment>
        </div>
        <div
            className="actionContent"
            style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}
        >
          <button onClick={() => {
            onHandleClick()
          }}>Continue</button>
        </div>
      </>
  );
};

export default GeneralComponent;

const HPIConditionItem = ({ data, onSelectChoice }) => {
  const [isOpenedBox, setOpenBox] = useState(false);
  const [question, setQuestion] = useState("");
  const [checkedChoice, setCheckedChoice] = useState(null);
  const [change, setChange] = useState(null);
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    let question = "";
    let choices = [];
    let checked_choice = "";
    question = data?.question;
    choices = data?.choices;
    checked_choice = data?.checked_choice;
    if (data) {
      (data.choices || []).map((r) => {
        if (r.option == data.checked_choice) {
        } else if (r.option == "" || data.checked_choice == "") {
        }
      });
    }
    /*onChangeComments(comments);*/
    setQuestion(question);
    setChoices(choices);
    setCheckedChoice(checked_choice);
  }, [data, checkedChoice, change]);

  return (
      <div className={`HPIItem`}>
        <div className="HPIItem__label">{question}</div>
        <div className={`HPIItem__options`}>
          {choices &&
          choices.map((choice, index) => (
              <button
                  key={`question-choice-${index}`}
                  className={`HPIItem__option ${
                      choice.option == data?.checked_choice ? "active" : "non-active"
                  }`}
                  onClick={() => {
                    setChange(Math.random());
                    setCheckedChoice(choice.option);
                    onSelectChoice(choice.option);
                  }}
              >
                {choice.option}
              </button>
          ))}
        </div>
      </div>
  );
};