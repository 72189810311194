import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs, Input } from "antd";

import { StaticResource } from "../../constant";
import {
  LeftCharvenSVG,
  SearchSVG,
} from "../../../../common/components/icons/SVGIcon";

import ActiveTable from "./ActiveReferring";
import InActiveTable from "./InActiveReferring";
import ActiveSidebarReferring from "./ActiveSidebarNew";
import InActiveSidebarReferring from "./InActiveSidebarNew";
import { setPCPNull } from "../../../../store/actions";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const { TabPane } = Tabs;
const { Search } = Input;

const Referring = ({ data, title, loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [activeData, setData] = useState(null);
  const [search, setSearch] = useState(undefined);
  const [propsListTable, setPropsListTable] = useState({});
  const [propsListTableIn, setPropsListTableIn] = useState({});
  const [deleteListPCP, setDeleteListPCP] = useState({});
  const [deleteListPCPIn, setDeleteListPCPIn] = useState({});
  const [addProps, setAddProps] = useState({});

  const [activeTab, setActiveTab] = useState("active");

  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleRow = (index, data) => () => {
    setRow(index);
    // dispatch(getDetailUsers(id));
    setData(data);
    handleSidebar();
  };

  const onSearchValue = (searchInput) => {
    const valueSearchState = searchInput.target.value;
    setSearch(valueSearchState);
  };

  const AddNewCheck = (value) => {
    setAddProps(value);
    setRow(-1);
  };

  const handleChangeTab = async (tab) => {
    await dispatch(setPCPNull());
    setActiveTab(tab);
    setData(null);
    setRow(-1);
    setPropsListTable({});
    setPropsListTableIn({});
    setDeleteListPCP({});
    setDeleteListPCPIn({});
    setAddProps({});
  };

  const updatePropsListPCP = (id, value) => {
    if (id) {
      setPropsListTable(value);
    }
  };

  const updatePropsListPCPIn = (id, value) => {
    if (id) {
      setPropsListTableIn(value);
    }
  };

  const deletePropsListPCP = (id, value) => {
    if (id) {
      setDeleteListPCP(value);
    }
  };

  const deletePropsListPCPIn = (id, value) => {
    if (id) {
      setDeleteListPCPIn(value);
    }
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div
        className="eventsContainer has-sub-header"
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            right: "32px",
            maxWidth: "440px",
            width: "calc(100% - 240px)",
            top: "16px",
            zIndex: 20,
          }}
        >
          <div className="rightSearchField" style={{ width: "100%" }}>
            <Search
              placeholder="Search"
              value={search}
              onChange={onSearchValue}
              style={{ width: "100%" }}
              suffix={<SearchSVG />}
            />
          </div>
        </div>

        <Tabs className="patientChart" type="card" onChange={handleChangeTab}>
          <TabPane tab="Active" key="active">
            {activeTab === "active" && (
              <ActiveTable
                addProps={addProps}
                search={search}
                propsListTable={propsListTable}
                deleteListPCP={deleteListPCP}
                handleRow={handleRow}
                loading={loading}
                activeIndex={activeIndex}
                title={"activeEmployee"}
              />
            )}
          </TabPane>
          <TabPane tab="Inactive" key="in-active">
            {activeTab !== "active" && (
              <InActiveTable
                propsListTableIn={propsListTableIn}
                search={search}
                deleteListPCPIn={deleteListPCPIn}
                handleRow={handleRow}
                loading={loading}
                activeIndex={activeIndex}
                title={"inactiveEmployee"}
              />
            )}
          </TabPane>
        </Tabs>
      </div>

      {activeData && activeIndex >= 0 && activeTab === "active" ? (
        <ActiveSidebarReferring
          ref={sidebarRef}
          usersDetail={activeData ? activeData : undefined}
          updatePropsListPCP={(id, value) => {
            updatePropsListPCP(id, value);
          }}
          AddNewCheck={(value) => AddNewCheck(value)}
          deletePropsListPCP={(id, value) => {
            deletePropsListPCP(id, value);
          }}
          type="referring"
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      ) : null}

      {activeData && activeIndex >= 0 && activeTab !== "active" ? (
        <InActiveSidebarReferring
          ref={sidebarRef}
          usersDetail={activeData ? activeData : undefined}
          updatePropsListPCPIn={(id, value) => {
            updatePropsListPCPIn(id, value);
          }}
          deletePropsListPCPIn={(id, value) => {
            deletePropsListPCPIn(id, value);
          }}
          type="referring"
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      ) : null}

      {activeData && activeIndex >= 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Referring;
