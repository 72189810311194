import { createReducer } from "redux-create-reducer";
import moment from "moment";

import AT from "../actionTypes";

const initialState = {
  orc_category: [],
  ocr_words: [],
  loading: false,
  loadingAction: false,
};

const GET_OCR_CATEGORY_STARTED = `${AT.GET_OCR_CATEGORY}_STARTED`;
const GET_OCR_CATEGORY_SUCCEEDED = `${AT.GET_OCR_CATEGORY}_SUCCEEDED`;

const GET_OCR_WORDS_STARTED = `${AT.GET_OCR_WORDS}_STARTED`;
const GET_OCR_WORDS_SUCCEEDED = `${AT.GET_OCR_WORDS}_SUCCEEDED`;
// action
const POST_OCR_WORDS_STARTED = `${AT.POST_OCR_WORDS}_STARTED`;
const POST_OCR_WORDS_SUCCEEDED = `${AT.POST_OCR_WORDS}_SUCCEEDED`;
const PUT_OCR_WORDS_STARTED = `${AT.PUT_OCR_WORDS}_STARTED`;
const PUT_OCR_WORDS_SUCCEEDED = `${AT.PUT_OCR_WORDS}_SUCCEEDED`;
const DELETE_OCR_WORDS_STARTED = `${AT.DELETE_OCR_WORDS}_STARTED`;
const DELETE_OCR_WORDS_SUCCEEDED = `${AT.DELETE_OCR_WORDS}_SUCCEEDED`;

export default createReducer(initialState, {
  [DELETE_OCR_WORDS_STARTED](state, action) {
    return Object.assign({}, state, { loadingAction: true });
  },
  [DELETE_OCR_WORDS_SUCCEEDED](state, action) {
    return Object.assign({}, state, { loadingAction: false });
  },
  [PUT_OCR_WORDS_STARTED](state, action) {
    return Object.assign({}, state, { loadingAction: true });
  },
  [PUT_OCR_WORDS_SUCCEEDED](state, action) {
    return Object.assign({}, state, { loadingAction: false });
  },
  [POST_OCR_WORDS_STARTED](state, action) {
    return Object.assign({}, state, { loadingAction: true });
  },
  [POST_OCR_WORDS_SUCCEEDED](state, action) {
    return Object.assign({}, state, { loadingAction: false });
  },
  [GET_OCR_WORDS_STARTED](state, action) {
    return Object.assign({}, state, { loading: true });
  },
  [GET_OCR_WORDS_SUCCEEDED](state, action) {
    const ocr_words = action.payload;
    return Object.assign({}, state, {
      loading: false,
      ocr_words,
    });
  },
  [GET_OCR_CATEGORY_STARTED](state, action) {
    return Object.assign({}, state, { loading: true });
  },
  [GET_OCR_CATEGORY_SUCCEEDED](state, action) {
    const orc_category = action.payload;
    return Object.assign({}, state, {
      loading: false,
      orc_category,
    });
  },
});
