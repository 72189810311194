import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Loading from "../../../../common/components/Loading";
import {
  LeftCharvenSVG,
  CheckSVG,
  YellowAlertSVG,
  RedRequiredCircleSVG,
  RedCloseSVG,
} from "../../../../common/components/icons/SVGIcon";
import DataTable from "../../../../common/components/DataTable/DataTable";
import TopBar from "../../../../common/components/SubTopBar";
import {
  choosePatientOnSchedules,
  chooseSelectedPatientResource,
  clinicProviderReviewLoadInit,
  detail_angio_type,
  getDeficiencyList,
  getDetailUltrasounds,
  get_cpt_code_review,
  get_patient_notes,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  load_intra_operative,
  load_procedurals,
  patientExaminationLoadInit,
  patient_insurances_id_insurances,
  removeDataPatient,
  selectPatient,
  setActiveReviewChange,
  setGlobalLoading,
  setPatientSnapshotData,
  set_data_template_review,
} from "../../../../store/actions";
import { useHistory } from "react-router-dom";
import Filter from "./filter";
import { StaticResource } from "../../constant";
import { Icon } from "antd";
import Cookies from "universal-cookie";

let timer;
const cookies = new Cookies();

const DeficiencyModule = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [offLoad, setOffLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [forFilter, setForFilter] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const history = new useHistory();
  const deficiencyList = useSelector(
    (state) => state.adminMeaningful.deficiencyList
  );
  const loading_get_meantingful = useSelector(
    (state) => state.adminMeaningful.loading_get_meantingful
  );
  const dispatch = useDispatch();
  const [sortOption, setSortOption] = useState({});

  useEffect(() => {
    setLoading(loading_get_meantingful);
  }, [loading_get_meantingful]);

  const sortList = async () => {
    const sortColumn = [];
    for (let sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    setTableLoads([]);
    setLoading(true);
    setPage(1);
    if (!forFilter) {
      await dispatch(
        getDeficiencyList({
          per_page: 100,
          page: 1,
        })
      );
    } else {
      await dispatch(
        getDeficiencyList({
          per_page: 100,
          page: 1,
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    sortList();
  }, [sortOption]);

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const valueSearch = (value) => {
    clearTimeout(timer);
    setSearch(value);
    setLoading(true);
    timer = setTimeout(() => {
      setTableLoads([]);
      setForFilter(true);
      setPage(1);
      setLoading(false);
      dispatch(
        getDeficiencyList({
          per_page: 100,
          page: 1,
        })
      );
    }, 1000);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = async (row, index) => {
    if (row && row?.type == "or") {
      await dispatch(setGlobalLoading(true));
      cookies.set("current_patient_id", row.patient_id);
      await dispatch(removeDataPatient());
      await dispatch(get_patient_notes(row.patient_id));
      const result = await dispatch(choosePatientOnSchedules(row.patient_id));
      await dispatch(setPatientSnapshotData("resource", { ...result }));
      await dispatch(patient_insurances_id_insurances(row.patient_id));
      await dispatch(setGlobalLoading(false));
      history.push("/clinic-workflow/provider");
      await dispatch(setActiveReviewChange(true));
    }
    if (row && row?.type == "ultrasound") {
      await dispatch(setGlobalLoading(true));
      await dispatch(removeDataPatient());
      cookies.set("current_patient_id", row.patient_id);
      cookies.set("apointment_ultrasound_id", row.id);
      cookies.set("ultrasound_type_name", row.ultrasound_type);
      const current_ultrasound_id = cookies.get("current_ultrasound_id");
      await dispatch(selectPatient(row.patient_id));
      await dispatch(patientExaminationLoadInit(row.id));
      await dispatch(clinicProviderReviewLoadInit(row.id));
      await dispatch(get_patient_notes(row.patient_id));
      await dispatch(chooseSelectedPatientResource(row.patient_id));
      await dispatch(get_ultrasound_finding_value(row.id));
      await dispatch(get_ultrasound_type(current_ultrasound_id));
      await dispatch(getDetailUltrasounds(current_ultrasound_id));
      await dispatch(setGlobalLoading(false));
      history.push("/clinic-workflow/ultrasound");
      await dispatch(setActiveReviewChange(true));
    }
    if (row && row?.type == "angio") {
      await dispatch(setGlobalLoading(true));
      cookies.set("current_patient_id", row.patient_id);
      await cookies.set("current_appointment_id", row.id);
      await dispatch(get_patient_notes(row.patient_id));
      await dispatch(selectPatient(row.patient_id));
      await dispatch(choosePatientOnSchedules(row.patient_id));
      await dispatch(chooseSelectedPatientResource(row.patient_id));
      let data = [];
      const checkid = await dispatch(load_intra_operative(row.id));
      await dispatch(get_cpt_code_review(row.id));
      await dispatch(set_data_template_review([]));
      if (checkid && checkid.angio_procedures) {
        checkid.angio_procedures.map(async (r) => {
          const value = await dispatch(
            detail_angio_type(r.angio_procedure_type_id)
          );
          data = [...data, value];
          await dispatch(set_data_template_review([...data]));
        });
      }
      await dispatch(load_procedurals(row.id));
      await dispatch(setGlobalLoading(false));
      history.push("/obl-workflow/obl");
      await dispatch(setActiveReviewChange(true));
    }
    if (activeIndex !== index) {
      setRow(index);
    }
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    const sortColumn = [];
    for (let sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    if (t >= 1 && Number.isFinite(t) && !loading && !loading_get_meantingful) {
      if (offLoad) {
        setLoading(true);
        const list = await dispatch(
          getDeficiencyList({
            per_page: 100,
            page: page + 1,
          })
        );
        if (list) {
          if (deficiencyList && deficiencyList.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  const onChangeDate = (value) => {
    setTableLoads([]);
    const date = moment(value).format("MM/DD/YYYY");
    dispatch(
      getDeficiencyList({
        per_page: 100,
        page: 1,
      })
    );
    setStartDate(date);
  };

  useEffect(() => {
    if (
      !loading_get_meantingful &&
      deficiencyList &&
      deficiencyList.length > 0
    ) {
      if (page > 1) {
        setTableLoads([...tableLoad, ...deficiencyList]);
        setLoading(false);
      } else {
        setTableLoads([...deficiencyList]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    console.log(deficiencyList);
  }, [deficiencyList]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const renderSubStatus = () => {
    let warning = 0;

    if (tableLoad && tableLoad.length > 0) {
        warning = tableLoad.filter(row => {
          return !row.vaccination !== 'Yes'
          || !row.bmi !== 'Yes'
          || !row.advance_directive !== 'Yes'
          || !row.smoking_education !== 'Yes'
          || !row.hypertension_education !== 'Yes'
          || !row.alcoholic_education !== 'Yes'
          || !row.pain_management !== 'Yes'
          || !row.hypertension !== 'Yes'
          || !row.medication_reconciliation !== 'Yes';
        }).length || 0;
    }

    return (
      <div className="statusHealth">
        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
        {warning}
        <span style={{ minWidth: '10px', display: 'inline-block' }} />
      </div>
    );
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Deficiency Module" subStatus={renderSubStatus()} />
      <div
        className={`clinicWorkflow__main-container ${
          isSidebar ? "openSidebar" : ""
        }`}
      >
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <div className="fullContainer pt-2">
              <Filter
                onChangeDate={onChangeDate}
                valueSearch={(value) => valueSearch(value)}
              />
              <div className="tableSection">
                <div className="tableContainer">
                  <DataTable
                    title="meaningful_use"
                    tableWidth={1120}
                    handleSort={true}
                    labels={["Patient Name", "DOB", "DOS", "Appointment Type"]}
                    widths={["25%", "25%", "25%", "25%"]}
                    columns={[
                      { sortable: true, key: "patient_name" },
                      { sortable: false, key: "patient_dob" },
                      { sortable: true, key: "dos" },
                      { sortable: true, key: "appointment_type" },
                    ]}
                    sorts={[
                      sortOption.patient_name,
                      "",
                      sortOption.dos,
                      sortOption.vaccination,
                      "",
                      sortOption.bmi,
                      sortOption.advanced_directive,
                      sortOption.smoking_education,
                      sortOption.hypertension_education,
                      sortOption.alcoholic_education,
                      sortOption.pain_management,
                      sortOption.hypertension,
                      sortOption.medication_reconciliation,
                    ]}
                    rowData={(tableLoad || []).map((row, index) => {
                      const appointment_type_value =
                        row?.type == "angio"
                          ? row?.angio_type?.name
                          : row?.type == "ultrasound"
                          ? row?.ultrasound_type?.name
                          : row?.procedure_type && row?.procedure_type?.value;
                      return {
                        id: row.id,
                        type: row.type,
                        patient_id: row?.patient && row?.patient?.id,
                        patient_name: (
                          <div
                            style={{
                              display: "flex",
                              paddingLeft: "0.25rem",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {row.vaccination === 'Yes'
                              && row.bmi === 'Yes'
                              && row.advance_directive === 'Yes'
                              && row.smoking_education === 'Yes'
                              && row.hypertension_education === 'Yes'
                              && row.alcoholic_education === 'Yes'
                              && row.pain_management === 'Yes'
                              && row.hypertension === 'Yes'
                              && row.medication_reconciliation === 'Yes' && (
                              <div className="has-tooltip" style={{ paddingBottom: '4px' }}>
                                {" "}
                                <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
                              </div>
                            )}
                            {(!row.vaccination !== 'Yes'
                              || !row.bmi !== 'Yes'
                              || !row.advance_directive !== 'Yes'
                              || !row.smoking_education !== 'Yes'
                              || !row.hypertension_education !== 'Yes'
                              || !row.alcoholic_education !== 'Yes'
                              || !row.pain_management !== 'Yes'
                              || !row.hypertension !== 'Yes'
                              || !row.medication_reconciliation !== 'Yes') && (
                              <div className="has-tooltip" style={{ paddingBottom: '4px' }}>
                                {" "}
                                <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
                              </div>
                            )}
                            <p style={{ paddingLeft: '0.625rem' }}>{row?.patient && row?.patient?.name}</p>
                          </div>
                        ),
                        ultrasound_type:
                          (row?.ultrasound_type && row?.ultrasound_type?.name) ||
                          "",
                        patient_dob:
                          row?.patient &&
                          row?.patient?.date_of_birth &&
                          moment(
                            row?.patient && row?.patient?.date_of_birth
                          ).format("MM/DD/YYYY"),
                        dos:
                          row?.appointment_date &&
                          moment(row?.appointment_date).format("MM/DD/YYYY"),
                        appointment_type: appointment_type_value,
                        vaccination: row?.vaccination,
                        influenza: row?.influenza,
                        bmi: row?.bmi,
                        advanced_directive: row?.advance_directive,
                        smoking_education: row?.smoking_education,
                        hypertension_education: row?.hypertension_education,
                        alcoholic_education: row?.alcoholic_education,
                        pain_management: row?.pain_management,
                        hypertension: row?.hypertension,
                        medication_reconciliation: row?.medication_reconciliation,
                      };
                    })}
                    handleSort={(value, item) => {
                      handleSortOption(value, item);
                    }}
                    loadMore={loadMore}
                    handleClickRow={handleRow}
                    defaultActive={-1}
                    disableDelete={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeficiencyModule;
