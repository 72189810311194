import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, Icon } from "antd";
import moment from "moment";
import { debounce } from "lodash";
import './PriorSidebar.scss';

import {
  CloseIcon,
  RedRequiredCircleSVG,
  CheckSVG,
} from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Toastr from "../../../common/components/Toast";
import SidebarTitle from "../../../common/components/SidebarTitle";
import {
  savePriorAuthorization,
  setPriorData,
  setGlobalLoading,
} from "../../../store/actions";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import EditableMaterialText from "../../../common/components/EditableText/MaterialInput";
import NotesComponent from "../../../common/components/Notes";

const PriorSideBar = React.forwardRef(({ isShow, handleCloseSidebar }, ref) => {
  const loading = useSelector((state) => state.prior.loadingResource);
  const resource = useSelector((state) => state.prior.resource);
  const savedResource = useSelector((state) => state.prior.savedResoruces);
  const locations = useSelector((state) => state.common.locations);
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [prior, setPrior] = useState({});
  const [status, setStatus] = useState(-1);
  const [notRequired, setNotRequired] = useState(-1);
  const [timer, setTimer] = useState(0);
  const [valid, setValid] = useState(false);
  const [noauthorization, setNoauthorization] = useState(-1);
  const [locationId, setLocationId] = useState(0);
  const [reset, setReset] = useState(false);
  const [isAuthorized, setAuthorized] = useState(false);
  const [expirationError, setExpirationError] = useState(null);
  const [authCodeError, setAuthCodeError] = useState(null);


  const isToastr = useMemo(() => {
    if (savedResource) {
      setTimer(0);
      setTimeout(() => {
        setTimer(600);
      }, 2000);
      return true;
    }
    return false;
  }, [savedResource]);

  useEffect(() => {
    if (resource && resource.id !== prior.id) {
      const newTitle = `CTA Abdomen/Pelvis with runoff (CPT 677.4)`;
      setTitle(newTitle);
      setPrior({
        ...resource,
        date_of_birth: resource.date_of_birth
          ? moment(resource.date_of_birth).format("MM/DD/YYYY")
          : undefined,
        expiration_date: resource.expiration_date
          ? moment(resource.expiration_date).format("MM/DD/YYYY")
          : undefined,
        type: resource.system_type,
      });
      setStatus(resource.submitted_for_processing);
      setReset(false);
      setTimeout(() => {
        setReset(true);
      }, 1000);
    }
  }, [resource]);

  const changeStatus = (e) => {
    if (e.target.checked) {
      setStatus(1);
    } else {
      setStatus(0);
    }
  };
  const changeNoauthorization = (e) => {
    if (e.target.checked) {
      setNoauthorization(1);
    } else {
      setNoauthorization(0);
    }
  };

  const changeAuthNumber = (e) => {
    const newPrior = { ...prior, authorization_number: e.target.value };
    setPrior(newPrior);
  };
  const changeDate = (type) => (date, dateString) => {
    const newPrior = { ...prior, [type]: dateString };
    setPrior(newPrior);
  };
  const changeLocation = (e) => {
    const newPrior = { ...prior, location_id: e.target.value };
    setPrior(newPrior);
  };
  const isValid = !!prior.authorization_number && !!prior.expiration_date;

  const onSave = (value) => {
    if (resource) {
      dispatch(setPriorData("loadingResource", true));
      console.dir(value);
      dispatch(
        savePriorAuthorization(resource.id, {
          authorization_number: value.authorization_number,
          expiration_date: value.expiration_date,
          type: value.type,
          submitted_for_processing: status,
          no_authorization_required: noauthorization,
          location_id: value.location,
        })
      );
    }
  };

  const changeHandler = useCallback(debounce((value) => onSave(value), 400), []);

  const changePriorValue = (type) => (value) => {
    const newPrior = { ...prior, [type]: value };
    let failOut = false;
    setPrior(newPrior);

    if (
      newPrior.expiration_date === '' ||
      newPrior.expiration_date == null
    ) {
      setExpirationError(true);
      failOut = true;
      // set expiration error
    }

    if (
      newPrior.authorization_number === '' ||
      newPrior.authorization_number == null
    ) {
      setAuthCodeError(true);
      failOut = true;
    }

    if (failOut) {
      return;
    }

    if (reset) {
      setExpirationError(false);
      setAuthCodeError(false);
      setAuthorized(true);
      changeHandler(newPrior);
    }
  };

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        onSave(prior);
        // isShow = false;
      }, 400);
    }
  }, [status, noauthorization])

  useEffect(() => {
    if (isShow && resource) {
      dispatch(setGlobalLoading(loading))
    }
  }, [isShow, resource, loading])

  if (!isShow || !resource) {
    return null;
  }
  console.dir(resource);
  return (
    <>
      <div ref={ref} className={`resourceContainer ${isShow ? "show" : ""}`}>
        {isToastr && timer < 600 && (
          <div style={{ position: "fixed", top: "60px", right: "2px" }}>
            <Toastr
              type="Prior Authorization"
              status="success"
              msg="Saved Successfully!"
            />
          </div>
        )}
        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          <SidebarTitle
            icon={
              (prior.status !== "Authorization Required" || isAuthorized) ? (
                <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
              ) : (
                <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
              )
            }
            width={"width-16"}
            title={<span className="text-uppercase">{resource.type}</span>}
            subTitle=""
          />
          <div className="resourceContent" style={{ paddingLeft: "1.5rem" }}>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Patient Name"}
                value={prior.patient_name}
                noEditable={true}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"DOB"}
                value={prior.date_of_birth}
                noEditable={true}
                type="date"
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Physician"}
                value={prior.provider}
                noEditable={true}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                label={"Location"}
                value={prior.location || undefined}
                type={"check"}
                optionKey={"id"}
                valueKey={"value"}
                options={locations || []}
                handleChange={changePriorValue("location")}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Diagnosis"}
                value={prior.diagnosis}
                noEditable={true}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"CPT Code"}
                value={prior.cpt_code}
                noEditable={true}
              />
            </div>
            <div className={`resourceInfo d-flex ${prior.is_verified === 1 && prior.is_eligible === 0 ? 'insurance-not-verified' : prior.is_verified === 1 && prior.is_eligible === 1 ? 'insurance-verified' : ''}`}>
              <EditableFormInput
                required={true}
                isError={prior.is_verified === 1 && prior.is_eligible === 0}
                enableErrorText={true}

                label={"Primary Plan Name"}
                value={prior.plan_name}
                noEditable={true}
              />
            </div>

            <div className={`resourceInfo d-flex ${prior.is_verified === 1 && prior.is_eligible === 0 ? 'insurance-not-verified' : prior.is_verified === 1 && prior.is_eligible === 1 ? 'insurance-verified' : ''}`}>
              <EditableFormInput
                required={true}
                isError={prior.is_verified === 1 && prior.is_eligible === 0}
                enableErrorText={true}

                label={"Primary Policy Number"}
                value={prior.policy_number}
                noEditable={true}
              />
            </div>
            <div className={`resourceInfo d-flex ${prior.is_verified === 1 && prior.is_eligible === 0 ? 'insurance-not-verified' : prior.is_verified === 1 && prior.is_eligible === 1 ? 'insurance-verified' : ''}`}>
              <EditableFormInput
                required={true}
                isError={prior.is_verified === 1 && prior.is_eligible === 0}
                enableErrorText={true}
                
                label={"Primary Group Number"}
                value={prior.group_number}
                noEditable={true}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Prior Authorization Code"}
                value={prior.authorization_number}
                handleChange={changePriorValue("authorization_number")}
                
                required={true}
                isError={authCodeError}
                enableErrorText={authCodeError}
                helperText={'Prior Authorization Code Required'}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Expiration Date"}
                value={prior.expiration_date}
                type="date"
                handleChange={changePriorValue("expiration_date")}
                
                required={true}
                isError={expirationError}
                enableErrorText={expirationError}
                helperText={'Expiration Date Required'}
              />
            </div>
            {!isValid && (
              <div className="resourceInfo" style={{ marginTop: "2rem" }}>
                <Checkbox checked={status === 1} onChange={changeStatus}>
                  Submittied for Processing
                </Checkbox>
              </div>
            )}
            <div className="resourceInfo" style={{ marginTop: "" }}>
              <Checkbox
                checked={noauthorization === 1}
                onChange={changeNoauthorization}
              >
                No Authorization Required
              </Checkbox>
            </div>
            {/* {valid && (
              <div className='resourceInfo' style={{ marginTop: '1rem' }}>
                <Toastr type="Validation Error" status="failed" msg="Authorization Number and Expiration Date are required!" />
              </div>
            )} */}
            <div
              className="resourceInfo"
              style={{
                marginTop: "1rem",
                paddingLeft: "0px",
                paddingRight: "26px",
              }}
            >
              <NotesComponent minRows={4} />
            </div>
          </div>
          <div className="actionContent" style={{ paddingBottom: "2rem" }} />
        </ScrollWrapper>
      </div>
    </>
  );
});

export default PriorSideBar;
