import React, { Component } from "react";
import TopBar from "../../../common/components/SubTopBar";
import Dashboard from "./employeeModules/Dashboard";
import TimeOff from "./employeeModules/TimeOff";
import Reporting from "./employeeModules/Reporting";
import Evaluations from "./employeeModules/Evaluations";
import ManualPolicies from "./employeeModules/ManualPolicies";
import CME from "./employeeModules/CME";
import ProviderContribution from "./employeeModules/ProviderContribution";
import { TabHeaders } from "../constant";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { connect } from "react-redux";

export class EmployeeModule extends Component {
  state = {
    activeTab: 0,
  };

  render() {
    const { activeTab } = this.state;
    const { currentUserInfo } = this.props;
    return (
      <div className="clinicWorkflow__main-page">
        <TopBar title="Employee Module" subStatus={""} />
        <div className={`clinicWorkflow__main-container ${this.props.isSidebar ? 'openSidebar' : ''}`}>
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <ScrollWrapper css="no-padding tab-header-scroll">
                <div style={{ display: "flex" }}>
                  {TabHeaders.map((tab, index) => {
                    if (
                      (currentUserInfo && currentUserInfo.status === 1 &&
                        currentUserInfo.is_pcm === 1) ||
                      currentUserInfo.is_admin === 1
                    ) {
                      return (
                        <div
                          key={`tabHeaders-${tab.label}-${index}`}
                          className={`tabHeaders-item ${
                            activeTab === index ? "active" : ""
                          }`}
                          style={{ width: tab.width }}
                          onClick={() => this.setState({ activeTab: index })}
                        >
                          {tab.label}
                        </div>
                      );
                    } else {
                      if (tab.label === "Provider Contribution Margin") {
                        return null;
                      } else {
                        return (
                          <div
                            key={`tabHeaders-${tab.label}-${index}`}
                            className={`tabHeaders-item ${
                              activeTab === index ? "active" : ""
                            }`}
                            style={{ width: tab.width }}
                            onClick={() => this.setState({ activeTab: index })}
                          >
                            {tab.label}
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </ScrollWrapper>
            </div>
          </div>
          {activeTab === 0 && <Dashboard title="employee-dashboard" />}
          {activeTab === 1 && <TimeOff title="employee-timeoff" />}
          {activeTab === 2 && <Reporting title="employee-reporting" />}
          {activeTab === 3 && <Evaluations title="employee-evaluations" />}
          {activeTab === 4 && (
            <ManualPolicies title="employee-manual-policies" />
          )}
          {activeTab === 5 && <CME title="employee-cme" />}
          {activeTab === 6 && (
            <ProviderContribution title="employee-provider-contribution" />
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { 
    currentUserInfo: state.common.currentUserInfo,
    isSidebar: state.sidebar.isSidebarOpen
  };
}

export default connect(mapStateToProps, null)(EmployeeModule);
