import React, { useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { Icon } from 'antd';
import ScrollWrapper from "../ScrollBar";
import {
  SummaryEmptySVG,
} from "../icons/SVGIcon";
import 'material-design-icons/iconfont/material-icons.css';
import "./style.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js`;

const PDFViewer = ({ url, style }) => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(0);
  const [rotate, setRotate] = useState(0);
  const onLoadSuccess = (pdfInfo) => {
    if (pdfInfo && pdfInfo._pdfInfo) {
      setNumPages(pdfInfo._pdfInfo.numPages);
    }
  }

  const rotateHandler = (dir) => {
    if (rotate === 270 && dir === 1) {
      setRotate(0);
    } else if (rotate === 0 && dir === -1) {
      setRotate(270);
    } else {
      setRotate(rotate + dir * 90);
    }
  };

  const zoomHandler = (dir) => {
    if ((scale === 0 && dir === -1) || (scale === 4 && dir === 1)) {
      return;
    }
    setScale(scale + dir);
  };
  const resetRotate = () => {
    setRotate(0);
  };
  const resetScale = () => {
    setScale(0);
  };

  const paginationHandler = (dir) => {
    if (!numPages) {
      return;
    }
    if ((currentPage === 1 && dir === -1) || (currentPage === numPages && dir === 1)) {
      return;
    }
    setCurrentPage(currentPage + dir);
  };

  return (
    <div className="PDFViewContainer" style={style}>
      <ScrollWrapper css="no-padding">
        {url && <Document
          file={url}
          loading={(
            <>
              <div
                className="empty-container"
                style={{
                  height: "calc(100% - 90px)",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SummaryEmptySVG />
              </div>
            </>
          )}
          onLoadSuccess={onLoadSuccess}
          renderMode="canvas"
        >
          {!!numPages && (
            <Page
              pageNumber={currentPage}
              rotate={rotate}
              height={1188}
              className={`scale-${scale}`}
            />
          )}
        </Document>}
        {!url && (
          <div
            className="empty-container"
            style={{
              height: "calc(100% - 90px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SummaryEmptySVG />
          </div>
        )}
      </ScrollWrapper>
      {(
        <div className="PDFController">
          <div className="zoom">
            <button className="zoom_out" onClick={() => zoomHandler(-1)} disabled={scale === 0}>
                <Icon type="zoom-out" style={{fontSize: '1.15rem'}}/>
            </button>
            <button className="zoom_in" onClick={() => zoomHandler(1)} disabled={scale === 4}>
              <Icon type="zoom-in" style={{fontSize: '1.15rem'}}/>
            </button>
          </div>

          <div className="pagination">
            <button className="pagination_prev" onClick={() => paginationHandler(-1)} disabled={currentPage === 1}>
              <Icon type="left-circle" style={{fontSize: '1.15rem'}}/>
            </button>
            <div className="pagination_status" style={{fontSize: '.875rem'}}>
              <span>Page {currentPage} / {numPages}</span>
            </div>
            <button className="pagination_next" onClick={() => paginationHandler(1)} disabled={currentPage === numPages}>
              <Icon type="right-circle" style={{fontSize: '1.15rem'}}/>
            </button>
          </div>

          <div className="rotate">
            <button className="rotate_left" onClick={() => rotateHandler(-1)}>
              <Icon type="undo" style={{fontSize: '1.15rem'}}/>
            </button>
            <button className="rotate_right" onClick={() => rotateHandler(1)}>
              <Icon type="redo" style={{fontSize: '1.15rem'}}/>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
