import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import {
  setProcedureDetails,
  getPrimaryProviderDetails,
  addProcedureDetails,
  adminReferralConditionUpdate,
  getProviders,
} from "../../../store/actions";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";

const ConditionProviders = ({ resource }) => {
  const tableRef = useRef(null);
  const conditionState = useSelector(
    (state) => state.procedureDetail.conditions
  );
  const selectedReferralCondition = useSelector(
    (state) => state.adminReferralCondition.selectedReferralCondition
  );
  const [checkNew, setCheckNew] = useState(false);
  const dispatch = useDispatch();

  const updateConditionProviders = async (type, index, value) => {
    await dispatch(
      addProcedureDetails("conditions", {
        physician_name: value?.value,
        physician: value?.id,
      })
    );
    setCheckNew(true);
  };

  const removeConditionProvider = async (index) => {
    conditionState.splice(index, 1);
    await dispatch(setProcedureDetails("conditions", [...conditionState]));
    setCheckNew(true);
  };

  useEffect(() => {
    if (checkNew) {
      callApiUpdate();
    }
  }, [conditionState, checkNew]);

  useEffect(() => {
    if (selectedReferralCondition) {
      const providerDetail = (selectedReferralCondition.providers || []).map(
        (r) => {
          return {
            physician: r.id,
            physician_name: r.value,
          };
        }
      );
      dispatch(setProcedureDetails("conditions", [...providerDetail]));
    }
    setCheckNew(false);
  }, [selectedReferralCondition]);

  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const providers = useSelector((state) => state.common.providers);

  useEffect(() => {
    if (providers) {
      setSearchResult(providers);
    }
  }, [providers]);

  useEffect(() => {
    if (search && search.length > 0) {
      const checkfilter = providers.filter((r) =>
          r.value.toLowerCase().includes(search.toLowerCase())
      );
      setSearchResult(checkfilter);
    } else {
      setSearchResult(providers);
    }
  }, [search]);

  useEffect(() => {
    dispatch(getProviders());
  }, []);

  const emptyRows = useMemo(() => {
    if (tableRef && tableRef.current) {
      const total = Math.floor(tableRef.current.clientHeight / 40);
      const count =
        conditionState && conditionState.length > 0
          ? total - conditionState.length
          : total;

      return new Array(count).fill(1);
    }
    return [];
  }, [tableRef, conditionState]);

  const handlePopulate = (value) => {
    const name =
      !value.first_name && !value.last_name
        ? ""
        : `${value.first_name} ${value.last_name}`;
    // TODO: integrate with datatable
  };

  const callApiUpdate = () => {
    if (
      conditionState &&
      selectedReferralCondition &&
      Object.keys(selectedReferralCondition).length > 0
    ) {
      const renderArray = conditionState.map((r) => {
        return r.physician;
      });
      dispatch(
        adminReferralConditionUpdate(selectedReferralCondition.id, {
          value: selectedReferralCondition?.value,
          providers: renderArray,
        })
      );
    }
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          title="Procedure-Provider-Search"
          options={searchResult}
          handlePopulate={(value) =>
            updateConditionProviders("physician", conditionState.length, value)
          }
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Providers"
          keyLabel="value"
        />
      </div>

      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="condition_provider"
          labels={["Provider"]}
          widths={["100%"]}
          fullHeight={true}
          columns={[{ sortable: true, key: "provider" }]}
          handleDelete={(value, index) => removeConditionProvider(index)}
          rowData={(conditionState || []).map((item, index) => {
            return {
              provider: item.physician_name,
            };
          })}
          emptyRows={emptyRows}
          sorts={[""]}
        />
      </div>
    </div>
  );
};

export default ConditionProviders;
