import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Icon, Tooltip } from "antd";
import Cookies from "universal-cookie";
import TopBar from "../../../common/components/SubTopBar";
import ClinicSchedule from "./providerSubViews/ClinicSchedule";
import PatientOverview from "../../../common/views/PatientSnapshot";
import PatientChart from "../../../common/views/PatientChart";
import HPIContainer from "./providerSubViews/HPIContainer";
import OfficeProcedure from "./providerSubViews/OfficeProcedure";
import RosVitals from "./providerSubViews/RosVitals";
import {
  patientExaminationLoadInit,
  patientExaminationSetData,
  patientExaminationSave,
  getClinicWorkflowAppointments,
  patientExaminationCopyLast,
  patientExaminationSetNomalCheck,
  changeTab,
  getOnlyProviders,
  HPISaveCondition,
  clinicProviderReviewLoadInit,
  setActiveReviewChange,
} from "../../../store/actions";
import PhysicalExamination from "./providerSubViews/PhysicalExamination";
import PlanOrders from "../../../common/components/PlanOder/PlanOrders";
import Review from "./providerSubViews/Review";
import Appointments from "../../../common/views/Appointment";
import Medications from "../../../common/views/Medications";
import { TabHeaders } from "../constant";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";

const cookies = new Cookies();

class Provider extends Component {
  state = {
    activeTab: 0,
    checkSelectedInterval: null,
    isSelectedPatient: false,
    ischeck: false,
    rosCoppy: false,
    physicalCoppy: false,
  };

  componentDidMount() {
    this.props.getOnlyProviders();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tabActive && nextProps.tabActive == "patient_snapshot") {
      this.setState({
        activeTab: 1,
      });
    } else if (nextProps.tabActive && nextProps.tabActive == "plan_order") {
      this.setState({
        activeTab: 7,
      });
    } else if (nextProps.tabActive && nextProps.tabActive == "vital") {
      this.setState({
        activeTab: 4,
      });
    }
    const patientResourceProps = nextProps.patientResource || {};
    if (Object.keys(patientResourceProps).length > 0) {
      const current_patient_id = cookies.get("current_patient_id");
      // this.props.getClinicWorkflowAppointments({
      //   patient_id: current_patient_id,
      //   paging: 1,
      // });
      this.setState({
        isSelectedPatient: true,
        rosCoppy: false,
        physicalCoppy: false,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  refreshImageList = () => {
    // this.refs.patient_chart.refreshImageList()
  };

  goNextTab_ = () => {
    var checkSelectedInterval = setInterval(this.goNextTab, 50);
    this.setState({ ...this.state, checkSelectedInterval });
  };

  goNextTab = () => {
    // const { patientResource } = this.props;
    if (this.state.isSelectedPatient) {
      clearInterval(this.state.checkSelectedInterval);
      this.setState({
        ...this.state,
        activeTab: 1,
        checkSelectedInterval: null,
      });
    }
  };

  renderSideComponent = () => {
    const { patientResource, resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (
      resourceCheck.first_name +
      " " +
      resourceCheck.last_name
    ).toUpperCase();
    const age = resourceCheck.date_of_birth
      ? "(" +
        moment().diff(resourceCheck.date_of_birth, "years") +
        // " yo " +
        // (!!resourceCheck.gender
        //   ? resourceCheck.gender.toUpperCase()
        //   : "MALE") +
        ")"
      : "";
    const allerrgies = [];
    if (resourceCheck.allergies) {
      resourceCheck.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
          allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >

        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "24px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && <br />}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip
              placement="bottomRight"
              title={resourceCheck.allergies
                .map((allergy) => allergy.allergy_name)
                .join(", ")}
            >
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>
      
        {resourceCheck.gender &&
        resourceCheck.gender.toUpperCase() == "MALE" ? (
          <MaleSVG
            style={{ marginLeft: "8px", height: "unset", width: "26px" }}
          />
        ) : (
          <FemaleSVG
            style={{ marginLeft: "8px", height: "unset", width: "26px" }}
          />
        )}
      </div>
    );
  };

  conditionClickProps = (value) => {
    this.setState({
      stateCondition: value,
    });
  };

  goNextTabReview = () => {
    this.setState({
      activeTab: 9,
    });
    this.props.setActiveReviewChange(false);
  };

  RosChange = async (scope, data) => {
    await this.props.patientExaminationSetData(scope, data);
  };

  RosSave = async (patient_id, checkValue, ROSs, vitals) => {
    const procedure_id = cookies.get("current_appointment_id");
    await this.props.patientExaminationSave(procedure_id, {
      patient_id: patient_id,
      all_normal_ros: checkValue,
      ROSs,
      vitals,
    });
  };

  RosCoppy = async (id) => {
    const procedure_id = cookies.get("current_appointment_id");
    this.props.patientExaminationCopyLast(procedure_id, "ROS");
    this.setState({
      rosCoppy: true,
    });
  };

  RosCheckAll = async (scope, data, checked) => {
    await this.props.patientExaminationSetNomalCheck(checked ? 1 : 0, "ROS");
    await this.props.patientExaminationSetData(scope, data);
  };

  //
  PhysicalChange = async (scope, data) => {
    await this.props.patientExaminationSetData(scope, data);
  };

  PhysicalCoppy = async (id) => {
    const procedure_id = cookies.get("current_appointment_id");
    this.props.patientExaminationCopyLast(procedure_id, "patient_examination");
    this.setState({
      physicalCoppy: true,
    });
  };

  PhysicalCheckAll = async (scope, data, checked) => {
    await this.props.patientExaminationSetNomalCheck(
      checked ? 1 : 0,
      "Physical"
    );
    await this.props.patientExaminationSetData(scope, data);
  };

  changeTabIndex = async (index) => {
    const { patientResource, is_locked } = this.props;
    const cookies = new Cookies();

    const procedure_id = cookies.get("current_appointment_id");

    this.props.clinicProviderReviewLoadInit(procedure_id);
    if (is_locked == 0 || is_locked == null) {
      if (this.state.activeTab == 3) {
        const patient_id = patientResource.id;
        const conditionCheck = this.props.conditions;
        this.props.HPISaveCondition(patient_id, procedure_id, conditionCheck);
      }
      if (this.state.activeTab == 4) {
        const { ROSs, vitals, all_normal_ros } = this.props;
        const patient_id = patientResource.id;
        this.props.patientExaminationSave(procedure_id, {
          patient_id,
          all_normal_ros,
          ROSs,
          vitals,
        });
      }
      if (this.state.activeTab == 5) {
        const { physical_examinations, vessel_states, all_normal_exam } =
          this.props;
        const patient_id = patientResource.id;
        const cookies = new Cookies();
        const procedure_id = cookies.get("current_appointment_id");
        this.props.patientExaminationSave(procedure_id, {
          all_normal_exam: all_normal_exam,
          patient_id,
          physical_examinations,
          vessel_states,
        });
      }
    }
    await this.props.changeTab({
      tabActive: null,
      accordion: null,
      step: null,
    });
    this.setState({ activeTab: index });
  };

  render() {
    const { activeTab, isSelectedPatient, physicalCoppy, rosCoppy } =
      this.state;
    const { patientResource } = this.props;

    return (
      <div className={`clinicWorkflow__main-page`}>
        <TopBar
          title="Provider Module"
          subStatus={""}
          sideComponent={this.renderSideComponent()}
          onRefreshImageList={this.refreshImageList}
          isBarcode={isSelectedPatient}
        />
        <div
          className={`clinicWorkflow__main-container ${
            this.props.isSidebar ? "openSidebar" : ""
          }`}
        >
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <ScrollWrapper css="no-padding tab-header-scroll">
                <div style={{ display: "flex" }}>
                  {TabHeaders.map((tab, index) =>
                    this.state.isSelectedPatient ? (
                      <div
                        key={`tabHeaders-${tab.label}-${index}`}
                        className={`tabHeaders-item ${
                          activeTab === index ? "active" : ""
                        }`}
                        style={{ width: tab.width }}
                        onClick={() => this.changeTabIndex(index)}
                      >
                        {tab.label}
                      </div>
                    ) : (
                      <div
                        key={`tabHeaders-${tab.label}-${index}`}
                        className={`tabHeaders-item ${
                          activeTab === index ? "active" : ""
                        } ${index > 0 && "disabled"}`}
                        style={{ width: tab.width }}
                      >
                        {tab.label}
                      </div>
                    )
                  )}
                </div>
              </ScrollWrapper>
            </div>
          </div>
          {activeTab >= 0 && (
            <ClinicSchedule
              display={activeTab === 0}
              goNextTab={this.goNextTab_}
              goNextTabReview={this.goNextTabReview}
            />
          )}
          {patientResource && activeTab === 1 && (
            <PatientOverview
              sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
              isClinic={true}
              patientResource={patientResource}
              loadingResource={this.props.loadingResource}
            />
          )}
          {patientResource && activeTab === 2 && (
            <PatientChart 
              sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
              patientResource={patientResource} 
            />
          )}
          {patientResource && activeTab === 3 && <HPIContainer />}

          {patientResource && activeTab === 4 && (
            <RosVitals
              display={true}
              RosSave={this.RosSave}
              RosChange={this.RosChange}
              RosCheckAll={this.RosCheckAll}
              RosCoppy={this.RosCoppy}
              rosCoppy={rosCoppy}
              isSlide={patientResource}
            />
          )}

          {patientResource && activeTab === 5 && (
            <PhysicalExamination
              display={true}
              PhysicalChange={this.PhysicalChange}
              PhysicalCheckAll={this.PhysicalCheckAll}
              PhysicalCoppy={this.PhysicalCoppy}
              physicalCoppy={physicalCoppy}
              isSlide={patientResource}
            />
          )}

          {patientResource && activeTab === 6 && <OfficeProcedure />}
          {activeTab === 7 && <PlanOrders 
              navigateToReview={this.goNextTabReview}
              sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
          />}
          {activeTab === 8 && <Medications 
            sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
          />}
          {activeTab === 9 && <Review 
            sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
          />}
          {activeTab >= 0 && (
            <Appointments 
              sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
              display={activeTab === 10 ? "flex" : "none"} 
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_locked: state.clinicProviderReview.is_locked,
  patientResource: state.clinicProvider.patientResource,
  conditions: state.clinicProviderHPI.conditions,
  tabActive: state.clinicProviderReview.tabActive,
  loadingResource: state.clinicProvider.loadingResource,
  ROSs: state.patientExamination.ROSs,
  vitals: state.patientExamination.vitals,
  all_normal_ros: state.patientExamination.all_normal_ros,
  physical_examinations: state.patientExamination.physical_examinations,
  vessel_states: state.patientExamination.vessel_states,
  all_normal_exam: state.patientExamination.all_normal_exam,
  resourceCheck: state.patientSnapshot.resource,
  isSidebar: state.sidebar.isSidebarOpen,
  activeReview: state.common.activeReview,
});
const mapDispatchToProps = {
  patientExaminationLoadInit,
  patientExaminationSetData,
  patientExaminationSave,
  getClinicWorkflowAppointments,
  patientExaminationCopyLast,
  patientExaminationSetNomalCheck,
  changeTab,
  getOnlyProviders,
  HPISaveCondition,
  clinicProviderReviewLoadInit,
  setActiveReviewChange,
};
export default connect(mapStateToProps, mapDispatchToProps)(Provider);
