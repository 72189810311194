/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { Icon } from "antd";
import domtoimage from "dom-to-image";
import { RightArrowSVG } from "../icons/SVGIcon";
import "./style.scss";
import { useSelector, useDispatch } from "react-redux";
import { resetAllStatus, uploadUserMedia } from "../../../store/actions";

const cookies = new Cookies();

const useOutsideDetect = (ref1, ref2, handleClick, active) => {
  const handleClickOutside = (event) => {
    if (
      active &&
      ref1.current &&
      !ref1.current.contains(event.target) &&
      ref2.current &&
      !ref2.current.contains(event.target)
    ) {
      handleClick(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

const HeaderIconMenu = () => {
  const [openUser, setOpenUser] = useState(false);
  const history = useHistory();
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  useOutsideDetect(ref1, ref2, setOpenUser, openUser);

  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const dispatch = useDispatch();

  const handleLogOut = () => {
    cookies.remove("cvai-auth-token", { path: "/" });
    cookies.remove("cvai-token-type", { path: "/" });
    cookies.remove("cvai-token-expires", { path: "/" });
    cookies.remove("cvai-current-user", { path: "/" });
    cookies.remove("cvai-current-user_status", { path: "/" });
    cookies.remove("patien_id_search", { path: "/" });
    cookies.remove("current_patient_id", { path: "/" });
    setOpenUser(false);
    dispatch(resetAllStatus());

    setTimeout(() => {
      history.push("/login");
    }, 1000);
  };

  const getDisplayMedia = (options) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
        return navigator.mediaDevices.getDisplayMedia(options)
    }
    if (navigator.getDisplayMedia) {
        return navigator.getDisplayMedia(options)
    }
    if (navigator.webkitGetDisplayMedia) {
        return navigator.webkitGetDisplayMedia(options)
    }
    if (navigator.mozGetDisplayMedia) {
        return navigator.mozGetDisplayMedia(options)
    }
    throw new Error('getDisplayMedia is not defined')
  }

  const takeScreenshotStream = async () => {
    const width = window.outerWidth * (window.devicePixelRatio || 1)
    const height = window.outerHeight * (window.devicePixelRatio || 1)

    const errors = [];
    let stream;
    const mediaStreamConstraints = {
        audio: false,
        video: {
            width,
            height,
            frameRate: 1,
        },
    };

    try {
        stream = await getDisplayMedia(mediaStreamConstraints)
    } catch (ex) {
        errors.push(ex)
    }

    if (errors.length) {
        console.debug(...errors)
        if (!stream) {
            throw errors[errors.length - 1]
        }
    }

    return stream
}

  const handleCaptureScreen = async () => {
    try {
      const stream = await takeScreenshotStream()
      const video = document.createElement("video");

      const png = await new Promise((resolve, reject) => {
        video.onloadedmetadata = () => {
            video.play()
            video.pause()
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d')
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
            resolve(canvas)
        }
        video.srcObject = stream
    })

    stream.getTracks().forEach(function (track) {
        track.stop()
    })

    if (png == null) {
        throw new Error('Cannot take canvas screenshot')
    }

      const payload = {
        user_id: currentUserInfo.id,
        type: "image",
        data: png.toDataURL('png/image'),
      };

      await dispatch(uploadUserMedia(payload));
      history.push("/support/details?is_captured=true");
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <ul className="headerIconMenuContainer">
      <li className="iconMenuList accountMenu hide-mobile">
        <NavLink to="#" onClick={() => setOpenUser(false)}>
          {currentUserInfo && currentUserInfo.name
            ? `${currentUserInfo.name}'s Account`
            : ""}
        </NavLink>
      </li>
      <li className="iconMenuList">
        <NavLink to="#" style={{ paddingRight: "0px" }}>
          <Icon type="camera" onClick={handleCaptureScreen} />
        </NavLink>
      </li>
      {/* <li className="iconMenuList" style={{ paddingRight: '0px' }}>
        <NavLink to="#">
          <Icon type="video-camera" />
          <RecordScreen />
        </NavLink>
      </li> */}
      <li className="iconMenuList" ref={ref1}>
        <a
          className={openUser ? "opened-user" : ""}
          onClick={() => setOpenUser(!openUser)}
        >
          <Icon type="user" />
        </a>
        <ul ref={ref2} className={`userMenuList ${openUser ? "open" : ""}`}>
          <li className="menuList show-mobile">
            <a href={"#"} style={{ color: "#fff", textDecoration: "none" }}>
              {currentUserInfo && currentUserInfo.name
                ? `${currentUserInfo.name}`
                : ""}
            </a>
          </li>
          <hr className="show-mobile" />
          <li className="menuList" style={{ marginTop: "1.5rem" }}>
            <a href={"#"}>Profile and settings</a>
          </li>
          <li className="menuList">
            <a href={"#"}>Privacy</a>
          </li>
          <li className="menuList">
            <a href={"#"}>Feedback</a>
          </li>
          <li onClick={handleLogOut} className="menuList logout">
            <a>
              Log out <RightArrowSVG />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default HeaderIconMenu;
