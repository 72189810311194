import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import {
  setProcedureDetails,
  getUltrasounds,
  addProcedureDetails,
  updateDetailProcedureData,
} from "../../../store/actions";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";

const ProcedureTimes = [
  { value: 2, label: "2 week" },
  { value: 4, label: "4 weeks" },
  { value: 8, label: "8 weeks" },
  { value: 12, label: "12 weeks" },
  { value: 24, label: "24 weeks" },
  { value: 52, label: "52 weeks" },
];

const FollowUpUltrasounds = ({ resource }) => {
  const tableRef = useRef(null);
  const ultrasounds = useSelector((state) => state.procedureDetail.ultrasounds);
  const ultraSearch = useSelector((state) => state.common.ultrasounds);
  const detailProcedureData = useSelector(
    (state) => state.common.detailProcedureData
  );
  const [firstCheck, setFirstCheck] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const updateProviders = async (type, index, value) => {
    setFirstCheck(true);
    if (type === "ultrasound") {
      if (!ultrasounds.some((r) => r.ultrasound === value?.id)) {
        await dispatch(
          addProcedureDetails("ultrasounds", {
            name_ultrasound: value?.name,
            ultrasound: value?.id,
            weeks: "",
          })
        );
      }
    } else {
      ultrasounds[index][type] = value;
      await dispatch(setProcedureDetails("ultrasounds", [...ultrasounds]));
    }
    setFirstCheck(false);
  };

  const removeProvider = async (index) => {
    setFirstCheck(true);
    ultrasounds.splice(index, 1);
    await dispatch(setProcedureDetails("ultrasounds", [...ultrasounds]));
    setFirstCheck(false);
  };

  useEffect(() => {
    if (firstCheck) {
      callApiUpdate();
    }
  }, [ultrasounds]);

  useEffect(() => {
    if (detailProcedureData) {
      const providerDetail = (detailProcedureData.ultrasound_types || []).map(
        (r) => {
          return {
            name_ultrasound: r?.name,
            ultrasound: r.id,
            weeks:
              r?.pivot?.followup_weeks === 0
                ? undefined
                : r?.pivot?.followup_weeks,
          };
        }
      );
      dispatch(setProcedureDetails("ultrasounds", [...providerDetail]));
    }
  }, [detailProcedureData]);

  useEffect(() => {
    if (ultraSearch && ultraSearch.length > 0) {
      setSearchResult(ultraSearch);
    }
  }, [ultraSearch]);

  useEffect(() => {
    if (search && search.length > 0) {
      const checkfilter = ultraSearch.filter((r) =>
          r.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchResult(checkfilter);
    } else {
      setSearchResult(ultraSearch);
    }
  }, [search]);

  const emptyRows = useMemo(() => {
    if (tableRef && tableRef.current) {
      const total = Math.floor(tableRef.current.clientHeight / 40);
      const count = ultrasounds && ultrasounds.length > 0 ? total - ultrasounds.length : total;

      return new Array(count).fill(1);
    }
    return [];
  }, [tableRef, ultrasounds]);

  const callApiUpdate = () => {
    if (
      ultrasounds &&
      detailProcedureData &&
      Object.keys(detailProcedureData).length > 0
    ) {
      const renderArray = ultrasounds.map((r) => {
        return `"${r.ultrasound}":{"followup_weeks":${r.weeks}}`;
      });
      dispatch(
        updateDetailProcedureData(detailProcedureData.id, {
          value: detailProcedureData?.value,
          sort: detailProcedureData?.sort,
          side: detailProcedureData?.side,
          is_first_case: detailProcedureData?.is_first_case,
          number_of_week_followup: detailProcedureData?.number_of_week_followup,
          range_between_procedure: detailProcedureData?.range_between_procedure,
          ultrasounds: `{${renderArray.join()}}`,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getUltrasounds());
  }, []);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          title="Procedure-Follow-Up-Ultrasound"
          options={searchResult}
          handlePopulate={(value) =>
            updateProviders("ultrasound", ultrasounds.length, value)
          }
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Ultrasounds"
          keyLabel={"name"}
        />
      </div>

      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="follow_up_ultrasounds"
          labels={["Ultrasound", "Follow-Up"]}
          widths={["60%", "40%"]}
          fullHeight={true}
          handleDelete={(value, index) => removeProvider(index)}
          columns={[
            { sortable: true, key: "ultrasound" },
            { sortable: true, key: "follow" },
          ]}
          rowData={(ultrasounds || []).map((item, index) => {
            return {
              ultrasound: item.name_ultrasound,
              follow: (
                <Select
                  placeholder="Weeks"
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  value={item.weeks}
                  onChange={(value) => {
                    updateProviders("weeks", index, value);
                  }}
                  style={{ width: "100%", borderBottom: 0 }}
                >
                  {ProcedureTimes.map((r) => (
                    <Select.Option key={r.value} value={r.value}>
                      {r.label}
                    </Select.Option>
                  ))}
                </Select>
              ),
            };
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>
    </div>
  );
};

export default FollowUpUltrasounds;
