import { createReducer } from "redux-create-reducer";

import AT from "../actionTypes";

const initialState = {
  localfile: null,
  isOpen: new Array(16).fill(false),
  tool: null,
  activeDcmIndex: 0,
  activeDcm: null,
  images: [],
  measurements: [],
  layout: [1, 1], // first element represents the rows, second the columns
  dicomdir: null,
  fsCurrentDir: "",
  fsCurrentList: [],
  fsZippedFile: null,
  fsRefresh: false,
  sandboxedFile: null,
  urlImage: "http://devicetest.vascularhealthclinics.org/pacs/1/echo.dcm",
};

const CLEAR_STORE_SUCCEEDED = `${AT.CLEAR_STORE}_SUCCEEDED`;
const LOCALFILE_STORE_SUCCEEDED = `${AT.LOCALFILE_STORE}_SUCCEEDED`;
const DCM_IS_OPEN_SUCCEEDED = `${AT.DCM_IS_OPEN}_SUCCEEDED`;
const DCM_TOOL_SUCCEEDED = `${AT.DCM_TOOL}_SUCCEEDED`;
const ACTIVE_DCM_INDEX_SUCCEEDED = `${AT.ACTIVE_DCM_INDEX}_SUCCEEDED`;
const ACTIVE_DCM_SUCCEEDED = `${AT.ACTIVE_DCM}_SUCCEEDED`;
const DCM_IMAGE_SUCCEEDED = `${AT.DCM_IMAGE}_SUCCEEDED`;
const ACTIVE_MEASUREMENTS_SUCCEEDED = `${AT.ACTIVE_MEASUREMENTS}_SUCCEEDED`;
const LAYOUT_SUCCEEDED = `${AT.LAYOUT}_SUCCEEDED`;
const DICOMDIR_SUCCEEDED = `${AT.DICOMDIR}_SUCCEEDED`;
const FSCURRENTDIR_SUCCEEDED = `${AT.FSCURRENTDIR}_SUCCEEDED`;
const FSCURRENTLIST_SUCCEEDED = `${AT.FSCURRENTLIST}_SUCCEEDED`;
const FSZIPPEDFILE_SUCCEEDED = `${AT.FSZIPPEDFILE}_SUCCEEDED`;
const FSREFRESH_SUCCEEDED = `${AT.FSREFRESH}_SUCCEEDED`;
const SANDBOXEDFILE_STORE_SUCCEEDED = `${AT.SANDBOXEDFILE_STORE}_SUCCEEDED`;
const SET_URL_IMAGE_SUCCEEDED = `${AT.SET_URL_IMAGE}_SUCCEEDED`;

export default createReducer(initialState, {
  [SET_URL_IMAGE_SUCCEEDED](state, action) {
    const urlImage = action.payload;
    return Object.assign({}, state, {
      urlImage,
    });
  },
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      localfile: null,
      isOpen: new Array(16).fill(false),
      tool: null,
      activeDcmIndex: 0,
      activeDcm: null,
      images: [],
      measurements: [],
      layout: [1, 1], // first element represents the rows, second the columns
      dicomdir: null,
      fsCurrentDir: "",
      fsCurrentList: [],
      fsZippedFile: null,
      fsRefresh: false,
      sandboxedFile: null,
      urlImage: "http://devicetest.vascularhealthclinics.org/pacs/1/echo.dcm",
    };
  },
  [CLEAR_STORE_SUCCEEDED](state) {
    return Object.assign({}, state, {
      localFile: state.localFile,
      fsFile: state.fsFile,
      //allfiles: null,
      files: null,
      series: null,
      isOpen: state.isOpen.map((el, i) =>
        i === state.activeDcmIndex ? false : el
      ),
      tool: null,
      activeDcmIndex: state.activeDcmIndex,
      activeDcm: state.activeDcm,
      explorer: state.explorer,
      explorerActivePatientIndex: state.explorerActivePatientIndex,
      explorerActiveStudyIndex: state.explorerActiveStudyIndex,
      explorerActiveSeriesIndex: state.explorerActiveSeriesIndex,
      measurements: null,
      layout: state.layout,
      dicomdir: state.dicomdir,
      fsCurrentDir: state.fsCurrentDir,
      fsCurrentList: state.fsCurrentList,
      fsZippedFile: null,
      fsRefresh: state.fsRefresh,
      volume: null,
      //lut: null,
      dcmEnableTool: false,
    });
  },
  [ACTIVE_MEASUREMENTS_SUCCEEDED](state, action) {
    const check = action.payload || [];
    return Object.assign({}, state, {
      ...state,
      measurements: [...check],
    });
  },
  [LOCALFILE_STORE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      localFile: action.payload,
      fsFile: null,
    });
  },
  [DCM_IS_OPEN_SUCCEEDED](state, action) {
    const check = state.isOpen.map((el, i) =>
      i === action.payload.index ? action.payload?.value : el
    );
    return Object.assign({}, state, {
      ...state,
      isOpen: [...check],
      measurements: state.measurements,
      layout: state.layout,
      dicomdir: state.dicomdir,
      fsCurrentDir: state.fsCurrentDir,
      fsCurrentList: state.fsCurrentList,
    });
  },
  [DCM_TOOL_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      tool: action.payload,
      measurements: state.measurements,
      layout: state.layout,
      dicomdir: state.dicomdir,
      fsCurrentDir: state.fsCurrentDir,
      fsCurrentList: state.fsCurrentList,
    });
  },
  [ACTIVE_DCM_INDEX_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      activeDcmIndex: action.payload,
      measurements: state.measurements,
      layout: state.layout,
      dicomdir: state.dicomdir,
      fsCurrentDir: state.fsCurrentDir,
      fsCurrentList: state.fsCurrentList,
    });
  },
  [ACTIVE_DCM_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      activeDcm: action.payload,
      measurements: state.measurements,
      layout: state.layout,
      dicomdir: state.dicomdir,
      fsCurrentDir: state.fsCurrentDir,
      fsCurrentList: state.fsCurrentList,
    });
  },
  [DCM_IMAGE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      images: [...state.images, action.payload],
      measurements: state.measurements,
      layout: state.layout,
      dicomdir: state.dicomdir,
      fsCurrentDir: state.fsCurrentDir,
      fsCurrentList: state.fsCurrentList,
    });
  },
  [LAYOUT_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      images: [...state.images],
      measurements: state.measurements,
      layout: action.payload,
      dicomdir: state.dicomdir,
      fsCurrentDir: state.fsCurrentDir,
      fsCurrentList: state.fsCurrentList,
    });
  },
  [DICOMDIR_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      dicomdir: action.payload,
    });
  },
  [FSCURRENTDIR_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      fsCurrentDir: action.payload,
    });
  },
  [FSCURRENTLIST_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      fsCurrentList: [...action.payload],
    });
  },
  [FSZIPPEDFILE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      fsZippedFile: action.payload,
    });
  },
  [FSREFRESH_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      fsRefresh: !state.fsRefresh,
    });
  },
  [SANDBOXEDFILE_STORE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...state,
      localfile: null,
      sandboxedFile: action.payload,
    });
  },
});
