import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select, DatePicker } from 'antd';
import { ExpandSVG, SearchSVG } from '../../../common/components/icons/SVGIcon';
import {
  getPriorAuthorization,
  setPriorData
} from '../../../store/actions';

const { Search } = Input;
const { Option } = Select;

const PriorFilters = ({
  filter,
  handleSearch,
  handleChangeFilter,
}) => {
  const types = useSelector(state => state.prior.types);
  const authNumbers = useSelector(state => state.prior.authNumbers);

  const dispatch = useDispatch();

  const onChangeDate = (date, dateString) => {
    const newFilter = {
      ...filter,
      date_of_service: dateString
    }
    handleChangeFilter(newFilter);
    dispatch(setPriorData('loadingPriors', true));
    dispatch(getPriorAuthorization(newFilter));
  };

  const changeSearch = (value) => {
    handleSearch(value);
  };

  const changeType = value => {
    const selectedType = value ? (types || []).find(type => type.id === parseInt(value)) : '';

    const newFilter = {
      ...filter,
      type: selectedType.id
    };
    handleChangeFilter(newFilter);
    dispatch(setPriorData('loadingPriors', true));
    dispatch(getPriorAuthorization(newFilter));
  }

  const changeCode = value => {
    const newFilter = {
      ...filter,
      authorization_number: value
    };
    handleChangeFilter(newFilter);
  }

  const changeResponseStatus = (status) => {
    const newFilter = {
      ...filter,
      response_status: status
    };
    handleChangeFilter(newFilter);
  }

  const changeExpiration = (date, dateString) => {
    const newFilter = {
      ...filter,
      expiration_date: dateString
    };
    handleChangeFilter(newFilter);
  }


  return (
    <div className='filterContainer'>
      <div className='searchFieldContainer'>
        <div className='searchField'>
          <Search
            placeholder="Search"
            onChange={e => changeSearch(e.target.value)}
            style={{ width: '100%' }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className='statusFilterContainer'>
        <div className='typeFilter'>
          <Select
            placeholder="Filter by Type..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeType}
            style={{ width: 'calc(100% - 0.75rem)' }}
          >
            {(types || []).map((type, index) => (
              <Option
                key={`type-${type.id}-${index}`}
                value={type.id}
              >
                {type.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className='dateFilter'>
          <DatePicker
            format={'MM/DD/YYYY'}
            placeholder="Date of Service..."
            suffixIcon={<ExpandSVG />}
            onChange={onChangeDate}
            style={{ width: 'calc(100% - 0.75rem)' }}
          />
        </div>
        <div className="resultFilter">
          <Select
            placeholder="Authorization Code..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeCode}
            style={{ width: 'calc(100% - 0.75rem)' }}
          >
            {(authNumbers || []).map((code, index) => (
              <Option key={`auth-code-${code.authorization_number}-${index}`} value={code.authorization_number}>
                {code.authorization_number}
              </Option>
            ))}
          </Select>
        </div>
        <div className="resultFilter">
          <DatePicker
            format={'MM/DD/YYYY'}
            placeholder="Expiration Date..."
            suffixIcon={<ExpandSVG />}
            onChange={changeExpiration}
            style={{ width: 'calc(100% - 0.75rem)' }}
          />
        </div>
        <div className='resultFilter'>
          <Select
            placeholder="Authorization Code Result..."
            suffixIcon={<ExpandSVG />}
            onChange={changeResponseStatus}
            allowClear={true}
            style={{ width: '100%' }}
          >
            {([
              { authorization_result: 'Complete' },
              { authorization_result: 'Authorization Required' },
              { authorization_result: 'Processing' },
            ]).map((code, index) => (
              <Option key={`auth-code-${code.authorization_result}-${index}`} value={code.authorization_result}>
                {code.authorization_result}
              </Option>
            ))}
          </Select>
        </div>
      </div>

    </div>
  );
};

export default PriorFilters;
