import React, { Component } from "react";
import moment from "moment";
import DayCalendar from "../DayCalendar";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import {
  getClinicNursingLocations,
  getProviders,
  getPatientSchedules,
  choosePatientOnSchedules,
  searchPatientSchedules,
  removeDataPatient,
  patientExaminationLoadInit,
  get_patient_notes,
  selectPatient,
  patient_insurances_id_insurances,
  clinicProviderReviewLoadInit,
  setPatientSnapshotData,
} from "../../../../store/actions";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const resources = [
  {
    id: "clinic",
    title: "Clinic",
  },
  {
    id: "us",
    title: "Ultrasound",
  },
];

const cookies = new Cookies();

class ClinicSchedule extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  state = {
    isSidebar: false,
    events: [],
    searchValue: "",
    schedulesState: [],
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = () => {
    this.props.getClinicNursingLocations();
    this.props.getProviders();
    this.handleEvents(this.state.filters);
    this.loadInitialPaymentJs();
  };

  loadInitialPaymentJs = () => {
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 1,
      only_procedures: 0,
      only_us: 1,
      only_angio: 0,
    });
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: searchPatientName });
    } else {
      this.setState({ schedulesState: this.props.schedules });
    }
  };

  onSelectResource = async (patient_id) => {
    const { goNextTab } = this.props;
    cookies.set("current_patient_id", patient_id);
    await this.props.removeDataPatient();
    await this.props.get_patient_notes(patient_id);
    const result = await this.props.choosePatientOnSchedules(patient_id);
    await this.props.setPatientSnapshotData("resource", { ...result });
    await this.props.patient_insurances_id_insurances(patient_id);
    goNextTab();
  };

  onSelectAppointment = (appointment_id) => {
    cookies.set("current_appointment_id", appointment_id);
    this.props.clinicProviderReviewLoadInit(appointment_id);
    // this.props.patientExaminationLoadInit(appointment_id);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.schedules) {
      this.setState({ schedulesState: nextProps.schedules });
    }
    if (nextProps.activeReview) {
      if (nextProps.goNextTabReview) {
        nextProps.goNextTabReview();
      }
    }
  }

  render() {
    const { isSidebar } = this.state;
    const { events } = this.state;
    return (
      <div
        style={{ display: this.props.display ? "flex" : "none" }}
        className="clinicWorkflow__main-section"
      >
        <ScrollWrapper css="no-padding">
          <div className="eventsContainer has-sub-header">
            <div className="calendarContainer">
              <DayCalendar
                locations={this.props.locations || []}
                providers={this.props.providers || []}
                events={this.state.schedulesState || []}
                resources={resources}
                slotProviders={this.props.scheduleProviders || []}
                loading={
                  this.props.loadingSchedules || this.props.loadingResource
                }
                fetchEvents={this.handleEvents}
                valueSearch={this.state.searchValue}
                onSearch={this.onSearch}
                onSelectResource={this.onSelectResource}
                calendarType="clinic-schedule"
                onSelectAppointment={this.onSelectAppointment}
              />
            </div>
          </div>
        </ScrollWrapper>
        {/*         
        <ScheduleSideBar
          patientResource={this.props.patientResource}
          isShow={isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          paymentForm={window.SqPaymentForm}
        />
        */}
        {/* {!isSidebar && (
          <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={this.sidebarRef} sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.common.clinicNursingLocations,
  providers: state.common.providers,
  schedules: state.clinicProvider.schedules,
  scheduleProviders: state.patientSchedule.providers,
  patientResource: state.clinicProvider.patientResource,
  loadingSchedules: state.clinicProvider.loadingSchedules,
  loadingResource: state.clinicProvider.loadingResource,
  activeReview: state.common.activeReview,
});

const mapDispatchToProps = {
  getClinicNursingLocations,
  getProviders,
  getPatientSchedules,
  choosePatientOnSchedules,
  get_patient_notes,
  selectPatient,
  searchPatientSchedules,
  removeDataPatient,
  patientExaminationLoadInit,
  patient_insurances_id_insurances,
  clinicProviderReviewLoadInit,
  setPatientSnapshotData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSchedule);
