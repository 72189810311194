import React, { useState, useEffect } from "react";
import { Checkbox, Input } from "antd";
import moment from "moment";
import { CloseIcon } from "../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../common/components/icons/FaxSVG";
import SidebarTitle from "../../../common/components/SidebarTitle";
import FormStepper from "../../../common/components/FormStepper";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Loading from "../../../common/components/Loading";
import Demographics from "./Demographics";
import InsuranceDetail from "./InsuranceDetail";
import HIPDocumentation from "./HIPDocumentation";
import Notes from "./Notes";
import { newCheckInOption } from "../../../common/constants/initialData";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import {
  savePatientNotes,
  patientScheduleConfirmCheckin,
} from "../../../store/actions";
import { validateSteps } from "../../../services/validations";
import Cookies from "universal-cookie";

const initialConditions = [
  { step: "demographics", status: true },
  { step: "insurance", status: true },
  { step: "HIPAA", status: true },
  { step: "notes", status: true },
];

const formSteps = [
  {
    title: "Demographics",
    content: Demographics,
  },
  {
    title: "Prior Authorization",
    content: InsuranceDetail,
  },
  {
    title: "HIPAA Documentation",
    content: HIPDocumentation,
  },
  {
    title: "Notes",
    content: Notes,
  },
];

const ResourceContainer = React.forwardRef(
  (
    {
      resource,
      isShow,
      handleCloseSidebar,
      paymentForm,
      refreshCalendar,
      referralConditions,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const [step, setStep] = useState(0);
    const [conditions, setConditions] = useState(initialConditions);
    const patientsNote = useSelector((state) => state.newPatient.patientsNote);
    const scanCompareResult = useSelector(
      (state) => state.scanDriverLicense.scanCompareResult
    );
    const allNotes = useSelector((state) => state.patientSchedule.allNotes);
    const allNotesDeleted = useSelector(
      (state) => state.patientSchedule.allNotesDeleted
    );
    const isInsuranceVerified = useSelector(
      (state) => state.patientSchedule.isInsuranceVerified
    );

    const procedure_id = cookies.get("current_appointment_id");

    const stepData = useSelector((state) => ({
      demographics: state.common.demographics,
      insurance: state.common.insurance,
      HIPAA: state.common["HIPAA"],
      notes: state.common.notes,
    }));

    const handleContinue = ({ stepIndex, text }) => {};

    const onSubmit = () => {
      dispatch(savePatientNotes(resource.id, allNotes, allNotesDeleted));
      dispatch(patientScheduleConfirmCheckin(procedure_id));
      setTimeout(() => {
        refreshCalendar();
      }, 800);
    };

    useEffect(() => {
      if (step > 0) {
        const newConditions = [...conditions];
        for (let i = 0; i < step; i++) {
          const validate = validateSteps(
            stepData[conditions[i].step],
            newCheckInOption[conditions[i].step]
          );
          newConditions[i].status = validate;
        }
        if (patientsNote && patientsNote.length > 0) {
          newConditions[3].status = true;
        } else {
          newConditions[3].status = false;
        }
        setConditions(newConditions);
      }
    }, [step, patientsNote]);

    // if (scanCompareResult === "matched") {
    //   let newConditions = [...conditions];
    //   if (!newConditions[0].status) {
    //     newConditions[0].status = true;
    //     setConditions(newConditions);
    //   }
    // }
    // if (isInsuranceVerified) {
    //   let newConditions = [...conditions];
    //   if (!newConditions[1].status) {
    //     newConditions[1].status = true;
    //     setConditions(newConditions);
    //   }
    // }

    let patientAge = resource
      ? moment().diff(resource.date_of_birth, "years") + " yo"
      : "";

    let isSubmitable = false;
    isSubmitable = conditions.every((condition) => condition.status);

    if (!isShow || !resource || !Object.keys(resource).length) {
      return null;
    }

    return (
      <div ref={ref} className={`resourceContainer ${isShow ? "show" : ""}`}>
        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          {resource ? (
            <>
              {resource.first_name != "" && (
                <SidebarTitle
                  icon={<MaleSVG />}
                  title={
                    <span className="text-uppercase">PATIENT DASHBOARD</span>
                  }
                  subTitle=""
                />
              )}

              <FormStepper
                steps={formSteps}
                conditions={conditions}
                currentStep={step}
                handleStep={setStep}
                handleContinue={handleContinue}
                resource={resource}
                paymentForm={paymentForm}
                referralConditions={referralConditions}
              />

              {resource.first_name != "" && (
                <div
                  style={{
                    marginTop: "0.5rem",
                    paddingRight: "16px",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <button
                    className={"common-btn submit-btn"}
                    style={{ border: 0 }}
                    disabled={!isSubmitable}
                    onClick={onSubmit}
                  >
                    Confirm Check-In
                  </button>
                </div>
              )}
            </>
          ) : (
            <Loading />
          )}
        </ScrollWrapper>
      </div>
    );
  }
);

export default ResourceContainer;
