import React, { useState, useEffect, useRef } from "react";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ManualPoliciesSidebar from "./ManualPoliciesSidebar";
import { useWindowSize } from "../../../../common/utils";
import { StaticResource, DashboardRows } from "../../constant";
import moment from "moment";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import { getDetailPolicies, getPolicies } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import DataTable from "../../../../common/components/DataTable/DataTable";

const ManualPolicies = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const policies = useSelector((state) => state.common.policies);
  const policiesDetail = useSelector((state) => state.common.policiesDetail);
  const tableRows = policies ? policies : [];
  let emptyRows = [];
  const size = useWindowSize(ref);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  }

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index) => {
    if (activeIndex != index) {
      setRow(index);
    }
    if (!policiesDetail || policiesDetail.id !== tableRows[index].id) {
      dispatch(getDetailPolicies(tableRows[index].id));
      handleSidebar();
    }
  };

  const fetchList = () => {
    dispatch(getPolicies());
  };

  const fetchDetail = () => {
    if (policies.length > 0) {
      dispatch(getDetailPolicies(policies[0].id));
    }
  };

  useEffect(() => {
    if (policies.length <= 0) {
      fetchList();
    } else {
      // fetchDetail();
    }
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref, policies]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }
  const sidebarRef=useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="manual_policies"
                labels={[
                  "ID",
                  'Subject',
                  'Updated'
                ]}
                widths={['calc(25% - 2rem)', '50%', '25%']}
                columns={[
                  { sortable: false, key: 'id' }, 
                  { sortable: false, key: 'subject' }, 
                  { sortable: false, key: 'updated' },
                ]}
                disableDelete={true}
                handleClickRow={(row, index) => handleRow(index)}
                defaultActive={-1}
                rowData={tableRows.map((row, index) => {
                  return {
                    id: row.policy_number,
                    subject: row.policy_name,
                    updated: row.effective_date && moment(row.effective_date).format("MM/DD/YY"),
                  }
                })}
              />
            </div>
          </div>  
        </div>
      </div>

      {policiesDetail && activeIndex >=0 && (
        <ManualPoliciesSidebar
          ref={sidebarRef}
          policiesDetail={policiesDetail}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}

      {policiesDetail && activeIndex >=0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default ManualPolicies;
