import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Icon } from 'antd';
import MainWorkflow from './ClinicWorkflow';
import SubSideNav from '../../common/components/SubSideNav';
import "./style.scss";

export default class ClinicWorkFlow extends Component {
  sideNavLinks = [{
    link: '/clinic-workflow/check-in',
    label: 'Check-In'
  }, {
    link: '/clinic-workflow/ultrasound',
    label: 'Ultrasound Module'
  }, {
    link: '/clinic-workflow/provider',
    label: 'Provider Module'
  }];

  state = {
    isSideNav: true
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Clinc Workflow</title>
        <meta name="title" content="FAACT Vascular | Clinc Workflow" />
      </Helmet>
    )
  }

  openSideNav = () => {
    this.setState({ isSideNav: true });
  }

  closeSideNav = () => {
    this.setState({ isSideNav: false });
  }

  getTitle = () => (
    <div className="sub-title">
      <Icon type="apartment" />
      Clinic Workflow
    </div>
  );

  render() {
    const { location } = this.props;
    const { isSideNav } = this.state;
    return (
      <div className="clinicWorkflow">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
        />
        <MainWorkflow location={location} isSideNav={isSideNav} />
      </div>
    )
  }
}
