import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import VesselBody from "../../../../common/components/VesselBody";

const PhysicalExaminationSidebar = React.forwardRef(({
  isShow,
  handleCloseSidebar,
  onChangeDataVes,
  checkedAllNormalsP,
  sidebarIcon,
  isLocked
}, ref) => {
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const fullName = (
    patientResource?.first_name +
    " " +
    patientResource?.last_name
  ).toUpperCase();
  const age = patientResource?.date_of_birth
    ? "(" + moment().diff(patientResource?.date_of_birth, "years") + " yo)"
    : "";

  const vesselStates = useSelector(
    (state) => state.patientExamination.vessel_states
  );

  const all_normal_exam = useSelector(
    (state) => state.patientExamination.all_normal_exam
  );

  const [dots, setDots] = useState([]);
  useEffect(() => {
    let dots_ = [
      {
        left: -2,
        top: 7,
        rightDir: true,
        field: "Right Carotid",
        label: "(R) Carotid",
        state: vesselStates["Right Carotid"],
      },
      {
        right: -10,
        top: 7,
        field: "Left Carotid",
        label: "(L) Carotid",
        state: vesselStates["Left Carotid"],
      },
      {
        left: -2,
        top: 34,
        rightDir: true,
        field: "Right Radial",
        label: "(R) Radial",
        state: vesselStates["Right Radial"],
      },
      {
        right: -10,
        top: 34,
        field: "Left Radial",
        label: "(L) Radial",
        state: vesselStates["Left Radial"],
      },
      {
        left: -2,
        top: 44,
        rightDir: true,
        field: "Right Ulnar",
        label: "(R) Ulnar",
        state: vesselStates["Right Ulnar"],
      },
      {
        right: -10,
        top: 44,
        field: "Left Ulnar",
        label: "(L) Ulnar",
        state: vesselStates["Left Ulnar"],
      },
      {
        left: -2,
        top: 60,
        rightDir: true,
        field: "Right Femoral",
        label: "(R) Femoral",
        state: vesselStates["Right Femoral"],
      },
      {
        right: -10,
        top: 60,
        field: "Left Femoral",
        label: "(L) Femoral",
        state: vesselStates["Left Femoral"],
      },
      {
        left: -2,
        top: 68,
        rightDir: true,
        field: "Right Popliteal",
        label: "(R) Popliteal",
        state: vesselStates["Right Popliteal"],
      },
      {
        right: -10,
        top: 68,
        field: "Left Popliteal",
        label: "(L) Popliteal",
        state: vesselStates["Left Popliteal"],
      },
      {
        left: -2,
        top: 82,
        rightDir: true,
        field: "Right Dorsalis Pedis",
        label: "(R) DP",
        state: vesselStates["Right Dorsalis Pedis"],
      },
      {
        right: -10,
        top: 82,
        field: "Left Dorsalis Pedis",
        label: "(L) DP",
        state: vesselStates["Left Dorsalis Pedis"],
      },
      {
        left: -2,
        top: 90,
        rightDir: true,
        field: "Right Posterior Tibial",
        label: "(R) PT",
        state: vesselStates["Right Posterior Tibial"],
      },
      {
        right: -10,
        top: 90,
        field: "Left Posterior Tibial",
        label: "(L) PT",
        state: vesselStates["Left Posterior Tibial"],
      },
    ];
    setDots(dots_);
  }, [vesselStates]);

  useEffect(() => {
    let vesselStates_ = { ...vesselStates };
    for (let key in vesselStates_) {
      if (vesselStates_.hasOwnProperty(key)) {
        if (checkedAllNormalsP) {
          if (vesselStates_[key] == "D") {
            vesselStates_[key] = "2";
          }
        } else {
          if (vesselStates_[key] == "2") {
            vesselStates_[key] = "D";
          }
        }
      }
      onChangeDataVes(vesselStates_);
    }
  }, [checkedAllNormalsP]);

  const onChangeState = (field, state) => {
    let dots_ = [...dots];
    for (let node of dots_) {
      if (node.field === field) {
        node.state = state;
      }
    }
    setDots(dots_);
    let vesselStates_ = { ...vesselStates };
    vesselStates_[field] = state;
    onChangeDataVes(vesselStates_);
  };

  if (!isShow) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
          title={
            <span className="text-uppercase">
              PULSE EXAMINATION
            </span>
          }
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{
            paddingLeft: "24px",
            paddingRight: "8px",
            height: "calc(100% - 55px)",
            display: "flex",
            justifyContent: "center",
            minHeight: "600px",
            position: "relative",
          }}
        >
          <VesselBody
            dots={dots}
            onChangeState={(field, state) => onChangeState(field, state)}
            isLocked={isLocked}
          />
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default PhysicalExaminationSidebar;
