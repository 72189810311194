import React, { useState, useEffect } from "react";
import {
  CloseIcon,
  SummaryEmptySVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import Ratings from "../../../../common/components/Ratings";
import { RatingOptions } from "../../constant";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";

const ExampleValue =
  "Anyone with knowledge or concerns about the care of a patient may " +
  "file a complaint. You may use the form below to file a complaint " +
  "if you are concerned about the health care, treatment, or services " +
  "that you or another person received or did not receive in the " +
  "health care facilities. Some reasons for filing a complaint would " +
  "be abuse, neglect, poor care, not enough staff, unsafe or" +
  "unsanitary conditions, dietary problems, or mistreatment. Please " +
  "include as much information as possible when submitting your " +
  "complaint. The response and timing of any investigation will be " +
  "based upon the information you provide. Report a concern as soon " +
  "as possible since it will be easier for you to remember the facts. " +
  "Step 1: Please include as much information as possible when " +
  "submitting your complaint. The response and timing of any " +
  "investigation by the organization will be bas.";

const CMESidebar = React.forwardRef(
  ({ resource, isShow, handleCloseSidebar, evaluationsDetail }, ref) => {
    const [ratingsDetail, setRatingsDetail] = useState({
      job_score: -1,
      work_score: -1,
      punctuality_score: -1,
      initiative_score: -1,
      communication_score: -1,
      dependability_score: -1,
    });
    const [comment, setComment] = useState(ExampleValue);
    const [goal, setGoal] = useState(ExampleValue);

    useEffect(() => {
      if (evaluationsDetail) {
        setRatingsDetail({
          job_score: evaluationsDetail.job_score || -1,
          work_score: evaluationsDetail.work_score || -1,
          punctuality_score: evaluationsDetail.punctuality_score || -1,
          initiative_score: evaluationsDetail.initiative_score || -1,
          communication_score: evaluationsDetail.communication_score || -1,
          dependability_score: evaluationsDetail.dependability_score || -1,
        });
      }
    }, [evaluationsDetail]);

    const handleRatings = (type) => (value) => {
      const newDetail = { ...ratingsDetail, [type]: value };
      setRatingsDetail(newDetail);
    };

    const getOverallRating = () => {
      let sum = 0;
      for (const opt of RatingOptions) {
        sum += ratingsDetail[opt.key] === -1 ? 0 : ratingsDetail[opt.key];
      }

      return Math.round(sum);
    };

    if (!isShow) {
      return null;
    }

    if (isShow && !evaluationsDetail) {
      return (
        <div
          ref={ref}
          className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
        >
          <div
            className="emptyContentIcon"
            style={{
              width: "100%",
              height: "calc(100% - 120px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SummaryEmptySVG />
          </div>
          <div
            className="actionContent"
            style={{ marginTop: "2rem", paddingRight: "1rem" }}
          >
            <button>Create Evolution</button>
          </div>
        </div>
      );
    }

    const renderTitle = () => {
      return (
        <span className="text-uppercase">
          {evaluationsDetail &&
            evaluationsDetail.user &&
            evaluationsDetail.user.name}
        </span>
      );
    };

    return (
      <div
        ref={ref}
        className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
      >
        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          <SidebarTitle icon={<MaleSVG />} title={renderTitle()} subTitle="" />
          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "1rem" }}
          >
            <div className="resourceInfo">
              {RatingOptions.map((opt, index) => (
                <Ratings
                  key={`ratings-${opt.key}`}
                  isIcon={true}
                  label={opt.label}
                  defaultOption={ratingsDetail[opt.key]}
                  handleOption={handleRatings(opt.key)}
                />
              ))}
            </div>
            <hr />
            <div className="resourceInfo d-flex between">
              <b>Overall Rating: </b>
              <b style={{ paddingRight: "0.5rem" }}>{getOverallRating()}</b>
            </div>
            <div
              className="resourceInfo d-flex"
              style={{ marginTop: "1rem", width: "100%" }}
            >
              <EditableFormInput
                label={"Additional Comments"}
                type={"area"}
                handleChange={(value) => {
                  // Todo: Integrate API or edit function
                  setComment(value);
                }}
                fullWidth={true}
                value={comment}
                minRows={3}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex" style={{ width: "100%" }}>
              <EditableFormInput
                label={"Goals"}
                type={"area"}
                handleChange={(value) => {
                  // Todo: Integrate API or edit function
                  setGoal(value);
                }}
                fullWidth={true}
                value={goal}
                minRows={3}
              />
            </div>
            <hr />

            <div className="resourceInfo d-flex" style={{ width: "100%" }}>
              <EditableFormInput
                label={"Employee Name"}
                fullWidth={true}
                value={evaluationsDetail.user && evaluationsDetail.user.name}
              />
            </div>

            <div className="resourceInfo d-flex" style={{ width: "100%" }}>
              <EditableFormInput
                label={"CME Title"}
                fullWidth={true}
                value={
                  evaluationsDetail.user && evaluationsDetail.user.position
                }
              />
            </div>

            <div className="resourceInfo d-flex" style={{ width: "100%" }}>
              <EditableFormInput
                label={"CME Hours"}
                fullWidth={true}
                value={evaluationsDetail.points}
              />
            </div>

            <div className="resourceInfo d-flex" style={{ width: "100%" }}>
              <EditableFormInput
                label={"Uploaded Document"}
                fullWidth={true}
                value={evaluationsDetail.work_score}
              />
            </div>

            <hr />
          </div>
          <div
            className="actionContent"
            style={{
              marginTop: "2rem",
              paddingRight: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <button>Finalize +</button>
          </div>
        </ScrollWrapper>
      </div>
    );
  }
);

export default CMESidebar;
