import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Icon } from 'antd';
import SubSideNav from '../../common/components/SubSideNav';
import MainContainer from './Administrator';
import { sideNavLinks } from './constant';

const Administrator = ({ location }) => {
  const [isSideNav, setIsSideNav] = useState(true);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSideNav(false);
    }
  }, []);

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular | Administrator</title>
        <meta name="title" content="FAACT Vascular | Administrator" />
      </Helmet>
    )
  }

  const openSideNav = () => {
    setIsSideNav(true);
  }

  const closeSideNav = () => {
    setIsSideNav(false);
  }

  const getTitle = () => (
    <div className="sub-title">
      <Icon type="apartment" />
      Administrator
    </div>
  );

  return (
    <div className="clinicWorkflow">
      {renderMetadata()}
      <SubSideNav
        subMenu={sideNavLinks}
        title={getTitle()}
        isSideNav={isSideNav}
        openSideNav={openSideNav}
        closeSideNav={closeSideNav}
      />
      <MainContainer location={location} isSideNav={isSideNav} />
    </div>
  );
}

export default Administrator;
