import React, { useState } from 'react';

const ToggleButton = ({ handleClick, openSideBar }) => {
  const onClick = () => {
    if (!openSideBar) {
      handleClick();
    }
  }

  return (
    <button className={`toggleButton ${openSideBar ? 'active' : ''}`} onClick={onClick}>
      <span className={`toggleLine top ${openSideBar ? 'rotateR' : ''}`} />
      <span className={`toggleLine middle ${openSideBar ? 'point' : ''}`} />
      <span className={`toggleLine bottom ${openSideBar ? 'rotateL' : ''}`} />
    </button>
  )
}

export default ToggleButton
