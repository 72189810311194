import React, { useState, useEffect } from "react";
import { InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import { CloseIcon, SummaryEmptySVG } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import QuestionInput from "./QuestionInput";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import YesNo from "../../../../common/components/YesNo";
import EditableText from "../../../../common/components/EditableText";
import { Input } from 'antd';
import { 
  adminReferralConditionOpenNewPage,
  adminReferralConditionPost,
  adminReferralConditionUpdate,
  adminReferralConditionClearStatusMessage,
  setGlobalLoading
} from "../../../../store/actions";
import { validateField } from "../../../../services/validations";
import ToastMessage from "../../../../common/components/Toast";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import InputTextTemplate from './InputTextTemplate';
import InputIcdCodes from './InputIcdCodes';
import InputPlans from './InputPlans';

const ConditionDetailSidebar = React.forwardRef(({ isShow, handleCloseSidebar }, ref) => {
  const dispatch = useDispatch();
  const pageType = useSelector((state) => state.adminReferralCondition.pageType);  
  const loading = useSelector((state) => state.adminReferralCondition.loading);
  const statusMessage = useSelector((state) => state.adminReferralCondition.statusMessage);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);
  const allProviders = useSelector((state) => state.common.providers);
  const allUltrasounds = useSelector((state) => state.common.ultrasounds);
  
  const [name, setName] = useState('');
  const [inputProvider, setInputProvider] = useState('');
  const [providers, setProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [providerNames, setProviderNames] = useState([]);
  const [inputUltrasound, setInputUltrasound] = useState('');
  const [ultrasounds, setUltrasounds] = useState([]);
  const [filteredUltrasounds, setFilteredUltrasounds] = useState([]);
  const [ultrasoundNames, setUltrasoundNames] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [textTemplate, setTextTemplate] = useState([]);
  const [icdCodes, setIcdCodes] = useState([]);
  const [plans, setPlans] = useState([]);
  
  useEffect(() => {    
    if (pageType=='new') return;

    if (selectedReferralCondition) {
      setName(selectedReferralCondition.value);
      setTextTemplate(JSON.parse(selectedReferralCondition.text_template));
      setQuestions(selectedReferralCondition.questionsArranged);
      let providers_=[], providerNames_=[];
      for(let provider_ of selectedReferralCondition.providers) {
        providers_.push(provider_.id);
        providerNames_.push(provider_.value);
      }
      setProviders(providers_);
      setProviderNames(providerNames_);
      let ultrasounds_=[], ultrasoundNames_=[];
      for(let ultrasound_ of selectedReferralCondition.ultrasound_types) {
        ultrasounds_.push(ultrasound_.id);
        ultrasoundNames_.push(ultrasound_.name);
      }
      setUltrasounds(ultrasounds_);
      setUltrasoundNames(ultrasoundNames_);
      setIcdCodes(selectedReferralCondition.icd_codes);
      setPlans(selectedReferralCondition.plans);
    }
  }, [selectedReferralCondition])
  useEffect(() => {
    if (pageType!='new') return;

    setName('');
    setTextTemplate([]);
    setQuestions([]);
    let providers_=[], providerNames_=[];
    setProviders(providers_);
    setProviderNames(providerNames_);
    let ultrasounds_=[], ultrasoundNames_=[];
    setUltrasounds(ultrasounds_);
    setUltrasoundNames(ultrasoundNames_);
    setIcdCodes([]);
    setPlans([]);
  }, [pageType])

  const handleSearchProvider = (search) => {
    setInputProvider(search);
  }
  const selectItemProvider = (providerName, providerId) => {
    if (providerName === '') return;
    for (let providerId_ of providers) {
      if (providerId_ === providerId) return;
    }
    
    let listItems = [...providers];
    listItems.push(providerId);
    setProviders(listItems);
    listItems = [...providerNames];
    listItems.push(providerName);
    setProviderNames(listItems);
  }
  const removeRowProvider = (provider, index) => {
    let listItems = [...providers];
    listItems.splice(index, 1);
    setProviders(listItems);
    listItems = [...providerNames];
    listItems.splice(index, 1);
    setProviderNames(listItems);
  }
  const handleSearchUltrasound = (search) => {
    setInputUltrasound(search);
  }
  const selectItemUltrasound = (ultrasoundName, ultrasoundId) => {
    if (ultrasoundName === '') return;
    for (let ultrasoundId_ of ultrasounds) {
      if (ultrasoundId_ === ultrasoundId) return;
    }
    
    let listItems = [...ultrasounds];
    listItems.push(ultrasoundId);
    setUltrasounds(listItems);
    listItems = [...ultrasoundNames];
    listItems.push(ultrasoundName);
    setUltrasoundNames(listItems);
  }
  const removeRowUltrasound = (ultrasound, index) => {
    let listItems = [...ultrasounds];
    listItems.splice(index, 1);
    setUltrasounds(listItems);
    listItems = [...ultrasoundNames];
    listItems.splice(index, 1);
    setUltrasoundNames(listItems);
  }
  useEffect(() => {
    const filteredProviders_ = allProviders &&
      allProviders.filter(
        (provider) =>
          provider.value.toLowerCase()
            .includes(inputProvider.toLowerCase()) && 
          inputProvider != ''
      )
    setFilteredProviders(filteredProviders_);
    const filteredUltrasounds_ = allUltrasounds &&
      allUltrasounds.filter(
        (ultrasound) =>
          ultrasound.name.toLowerCase()
            .includes(inputUltrasound.toLowerCase()) && 
          inputUltrasound != ''
      )
    setFilteredUltrasounds(filteredUltrasounds_);
  }, [inputProvider, inputUltrasound])
  const addQuestion = (question) => {
    let listItems = [...questions];
    listItems.push(question);
    setQuestions(listItems);
  }
  const removeQuestion = (index) => {
    let listItems = [...questions];
    listItems.splice(index, 1);
    setQuestions(listItems);
  }
  const addQuestionChoice = (index, choice) => {
    let listItems = [...questions];
    listItems[index]['choices'].push(choice);
    setQuestions(listItems);
  }
  const removeQuestionChoice = (index, index2) => {
    let listItems = [...questions];
    listItems[index]['choices'].splice(index2, 1);
    setQuestions(listItems);
  }
  
  const onNew = () => {
    dispatch(adminReferralConditionOpenNewPage());
  }
  const onSave = () => {
    if (name=='') {
      alert('Please input name');
      return;
    }
    let data = {
      name, 
      providers, ultrasound_types: ultrasounds,
      questions,
      text_template: JSON.stringify(textTemplate),
      icd_codes: icdCodes,
      plans: plans
    };

    if (pageType=='update') {
      const condition_id = selectedReferralCondition.id;
      dispatch(adminReferralConditionUpdate(condition_id, data));
    } else {
      dispatch(adminReferralConditionPost(data));
    }    
  }

  useEffect(() => {
    if (!statusMessage) return;
    setTimeout(() => {
      dispatch(adminReferralConditionClearStatusMessage())
    }, 2800)    
  }, [statusMessage])

  useEffect(() => {
    if (isShow && (selectedReferralCondition && Object.keys(selectedReferralCondition).length)) {
      dispatch(setGlobalLoading(loading));
    }

  }, [loading, isShow, selectedReferralCondition])

  if (!isShow || (!selectedReferralCondition || !Object.keys(selectedReferralCondition).length)) {
    return null;
  }

  return (
    <>
      <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>     
        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          <SidebarTitle
            icon={<MaleSVG />}
            title={<span className="text-uppercase">{name}</span>}
            subTitle=""
          />        
          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
          >
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                reset={true}
                label={"Name"}
                value={name}
                handleChange={name => setName(name)}
                required={true}
                isError={validateField("text", name)}
                helperText={"Name is Required!"}
              />
            </div>
          </div>
          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
          >
            <div className="resourceInfo d-flex">
              <b>Procedure Providers</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <AutoCompleteComponent
                title="condition-providers"
                placeholder="Search Providers"
                keyLabel={"value"}
                keyValue={"id"}
                isResult={false}
                disableSearchIcon={false}
                handleSearch={handleSearchProvider}
                selectItem={selectItemProvider}
                removeItem={removeRowProvider}
                rows={providerNames}
                options={filteredProviders}
              />
            </div>
            <div className="resourceInfo d-flex">
              <b>Follow-Up Ultrasounds</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <AutoCompleteComponent
                title="condition-ultrasounds"
                placeholder="Search Ultrasounds"
                keyLabel={"name"}
                keyValue={"id"}
                isResult={false}
                disableSearchIcon={false}
                handleSearch={handleSearchUltrasound}
                selectItem={selectItemUltrasound}
                removeItem={removeRowUltrasound}
                rows={ultrasoundNames}
                options={filteredUltrasounds}
              />
            </div>
            <div className="resourceInfo d-flex">
              <b>Question</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <QuestionInput
                rows={questions}
                addQuestion={addQuestion}
                removeQuestion={removeQuestion}
                addQuestionChoice={addQuestionChoice}
                removeQuestionChoice={removeQuestionChoice}
              />
            </div>
            <div
              className="resourceInfo d-flex"
              style={{
                marginTop: "1rem",
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <b>Text Template</b>
            </div>
            <div className="resourceInfo" style={{ marginTop: '0.5rem' }}>
              {/*
              <Input.TextArea rows={4}
                value={textTemplate} 
                onChange={e => setTextTemplate(e.target.value)}
              />
              */}
              <InputTextTemplate
                questions={questions}
                textTemplate={textTemplate}
                onChangeTextTemplate={setTextTemplate}
              />
            </div>
            <div
              className="resourceInfo d-flex"
              style={{
                marginTOp: '0.5rem',
                justifyContent: 'flex-end'
              }}
            >
              <button 
                className="common-btn outlined-btn"
                onClick={() => setTextTemplate([])}
              >
                Clear
              </button>  
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: '0.5rem' }}>
              <b>ICD Codes</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: '0.5rem' }}>
              <InputIcdCodes
                icdCodes={icdCodes}
                onChangeIcdCodes={setIcdCodes}
                questions={questions}
              />
            </div>
            <div className="resourceInfo" style={{ marginTop: '0.5rem' }}>
              <InputPlans
                plans={plans}
                onChangePlans={setPlans}
              />
            </div>
          </div>
          {statusMessage && (
            <div
              className="resourceContent"
              style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
            >
              <ToastMessage
                type=""
                status="success"
                msg={statusMessage}                            
              />
            </div>
          )}
          <div
            className="actionContent"
            style={{ marginTop: "2rem", paddingRight: "1rem", paddingBottom: '2rem' }}
          >
            {pageType === 'update' &&
              <button className={"common-btn outlined-btn"} onClick={onNew}>
                New Condition
              </button>
            }
            <button style={{ marginLeft: "0.5rem" }} onClick={onSave}>
              Save Record
            </button>
          </div>        
        </ScrollWrapper>
      </div>
    </>
  );
});

export default ConditionDetailSidebar;
