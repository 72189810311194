import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select, Checkbox, Input } from 'antd'
import {
  procedureConsentOpenCreate,
  procedureConsentGetSignatureImage,
  procedureConsentSetSignatureImage,
  procedureConsentDelete,
  procedureConsentSearch,
} from '../../../../store/actions'
import QRCode from 'qrcode.react'
import EditableFormInput from '../../../../common/components/EditableText/EditableFormInput'

const ConsentCreateButtonSection = ({ openCreatePage }) => {
  const dispatch = useDispatch()
  const allProcedures = useSelector(
    (state) => state.procedureConsent.allProcedures
  )
  const allProviders = useSelector(
    (state) => state.procedureConsent.allProviders
  )
  const patientResource = useSelector(
    (state) => state.patientSchedule.selectedPatientResource
  )
  const [procedure_data, setProcedureData] = useState(undefined)
  const [provider_id, setProviderId] = useState('')
  const [is_surrogate, setIsSurrogate] = useState(false)
  const [surrogator_name, setSurrogatorName] = useState('')
  const [relation_to_patient, setRelationToPatient] = useState('')
  const [isCreateDirectly, setIsCreateDirectly] = useState(false)
  const newDocumentData = useSelector(
    (state) => state.procedureConsent.newDocumentData
  )
  const signatureImage = useSelector(
    (state) => state.procedureConsent.signature_image
  )
  const selectedDocumentData = useSelector(
    (state) => state.procedureConsent.selectedDocumentData
  )
  const searchData = useSelector((state) => state.procedureConsent.searchData)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const schedules = useSelector(state => state.patientSchedule.schedules)
  const [provider_data, setProviderData] = useState(undefined)
  const consentDocuments = useSelector(
    (state) => state.procedureConsent.consentDocuments
  )

  useEffect(() => {
    for (let schedule of schedules) {
      if (schedule['resourceId'] === 'procedure' && patientResource.id==schedule['patient_id']) {        
        setProcedureData({
          title: schedule['procedure_title'],
          description: schedule['procedure_description']
        })
        setProviderId(schedule['provider_id'])
        setProviderData({
          id: schedule['provider_id'],
          name: schedule['provider_name']
        })
        dispatch(procedureConsentGetSignatureImage(schedule['provider_id']))
      }
    }

    dispatch(procedureConsentOpenCreate(undefined))
  }, [schedules])
  const [documentsCount, setDocumentsCount] = useState(0)
  useEffect(() => {
    let documentsCount_ = consentDocuments.length    
    if (documentsCount != documentsCount_) {
      dispatch(procedureConsentOpenCreate(undefined))
    }  
    setDocumentsCount(documentsCount_)
  }, [consentDocuments])
  useEffect(() => {
    const reloadConsents = () => {
      const patient_id = patientResource.id
      dispatch(procedureConsentSearch(patient_id, searchData))
    }
    const reloadConsentsInterval = setInterval(reloadConsents, 3000)
    return () => {
      clearInterval(reloadConsentsInterval)
    }
  })

  // patientResoruce - referral_condition, referral_provider
  /*
  const onSetProcedure = (value) => {
    if (!value) {
      setProcedureData(undefined)
      return
    }
    let description = ''
    let title = value
    for (let p of allProcedures) {
      if (p['title'] === value) {
        description = p['description']
      }
    }
    setProcedureData({
      title, description
    })
  }

  const onSetProviderId = (provider_id_) => {
    console.log(provider_id_)
    setProviderId(provider_id_)
    dispatch(procedureConsentGetSignatureImage(provider_id_))
  }

  const uploadSignature = (event) => {
    let file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)    
    reader.onload = () => {
      const signatureImage_ = reader.result
      dispatch(procedureConsentSetSignatureImage(provider_id, signatureImage_))
    }
  }
  */

  const onCreateConsent = () => {

    // validation
    if (!procedure_data) {
      alert('Please select procedure')
      return
    }
    if (provider_id === '') {
      alert('Please select provider')
      return
    }
    if (is_surrogate) {
      if (surrogator_name === '') {
        alert('Surrogator name & relationship to patient should not be empty.')
        return
      }
      if (relation_to_patient === '') {
        alert('Surrogator name & relationship to patient should not be empty.')
        return
      }
    }
    if (!signatureImage) {
      alert('Please input provider\'s signature first.')
      return
    }
    // set initial data
    const patient_id = patientResource.id
    const patient_data = {
      first_name: patientResource.first_name,
      last_name: patientResource.last_name,
      date_of_birth: patientResource.date_of_birth,
    }
    const surrogate_data = {
      surrogator_name,
      relation_to_patient,
    }
    /*
    let provider_data = {};
    for (let provider_ of allProviders) {
      if (provider_.id==provider_id) {
        provider_data = provider_
      }
    }
    */
    const newDocumentData_ = {
      patient_id,
      patient_data,
      procedure_data,
      provider_id, provider_data,
      is_signed: true,
      is_surrogate,
      surrogate_data,
    }
    dispatch(procedureConsentOpenCreate(newDocumentData_))
    if (isCreateDirectly) {
      openCreatePage()
    }  
  }

  const onDeleteConsent = () => {
    if (selectedDocumentData) {
      let consent_id = selectedDocumentData.id
      dispatch(procedureConsentDelete(consent_id))
    }
    setIsConfirmDelete(false)
  }

  return (
    <>
      <div className="resourceInfo">
        <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
          {!isConfirmDelete &&
            <button onClick={() => { selectedDocumentData && setIsConfirmDelete(true); }} className="common-btn outlined-btn" style={{marginRight: '10px'}}>Delete</button>
          }
          {isConfirmDelete &&
            <>
              <button
                onClick={() => setIsConfirmDelete(false)}
                className="common-btn outlined-btn"
                style={{marginRight: '8px'}}
              >
                Back
              </button>
              <button
                onClick={onDeleteConsent}
                className="common-btn delete-btn"
                style={{marginRight: '10px'}}
              >
                Confirm Delete?
              </button>
            </>
          }
          <button onClick={onCreateConsent} className="common-btn">New App Consent</button>
        </div>
        {/*
        <p>
          <strong>New Consent Document</strong>
        </p>
        <div className="d-flex" style={{ marginTop: '30px', paddingBottom: '0.5rem', borderBottom: '1px solid #d9d9d9' }}>
          <EditableFormInput
            type={'check'}
            label={'Procedure...'}
            options={allProcedures}
            optionKey={'title'}
            valueKey={'title'}
            handleChange={onSetProcedure}
          />
        </div>
        <div className="d-flex" style={{marginTop: '0.5rem'}}>
          <EditableFormInput
            type={'check'}
            label={'Provider...'}
            options={allProviders}
            optionKey={'id'}
            valueKey={'name'}
            handleChange={onSetProviderId}
          />
        </div>
        <div className="d-flex" style={{marginTop: '30px'}}>
          <Checkbox
            onChange={(checked) => setIsSurrogate(!is_surrogate)}
            checked={is_surrogate}
          >
            Is surrogate?
          </Checkbox>
        </div>
        */}
        {is_surrogate && (
          <>
            <div className="d-flex" style={{marginTop: '30px', paddingBottom: '0.5rem', borderBottom: '1px solid #d9d9d9'}}>
              <EditableFormInput
                label={'Name of Substitute Decision Maker'}
                value={surrogator_name}
                handleChange={setSurrogatorName}
              />
            </div>
            <div className="d-flex" style={{marginTop: '0.5rem'}}>
              <EditableFormInput
                label={'Relationship to Patient'}
                value={relation_to_patient}
                handleChange={setRelationToPatient}
              />
            </div>
          </>
        )}
        {/*
        <div
          className="actionContent"
          style={{ marginTop: '2rem', paddingRight: '8px' }}
        >
          <button onClick={onCreateConsent} className="common-btn">New App Consent</button>
          <Checkbox checked={isCreateDirectly} onChange={(checked) => setIsCreateDirectly(!isCreateDirectly)} style={{marginLeft: '8px' , marginTop: '8px'}}>
            Create on here.
          </Checkbox>          
        </div>
        */}
        { newDocumentData &&
          <div className="resourceInfo" style={{ marginTop: '30px', minHeight: '220px' }}>
            <QRCode
              value={JSON.stringify({consent_data: newDocumentData, type: 'consent-sign'})}
              style={{width: 'calc(100% - 300px)', marginLeft: '150px', height: 'calc(100% - 300px)' }}
            />
            <p style={{ textAlign: 'center', fontSize: '16px', marginTop: '20px' }}>Place camera centered over the above QR code to read.</p>
          </div>
        }
      </div>
    </>
  )
}

export default ConsentCreateButtonSection
