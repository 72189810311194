import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../../common/components/Card";
import ScrollWrapper from "../../../common/components/ScrollBar";

import {
  getDetailUltrasounds,
  ultrasound_update_cpt_rule,
  ultrasound_update_result_rule,
  ultrasound_update_conclusion_rule,
} from "../../../store/actions";

import UltrasoundSearch from "./UltrasoundSearch";
import DiseaseCondition from "./DiseaseCondition";
import IndicationCode from "./IndicationCode";
import CPTCodeLogic from "./cptCodeLogic";
import DocumentationBlock from "./TemplateDocumentation";
import ResultsLogic from "./ResultsLogic";
import ConclusionLogic from "./ConclusionLogic";
import "../style.scss";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ProcedureDetail = (props) => {
  const [activeCard, setActiveCard] = useState('');
  const dispatch = useDispatch();
  const [resource, setResource] = useState(null);
  const detail_untrasound_list_type = useSelector(
    (state) => state.procedureDetail.detail_untrasound_list_type
  );

  const add_handle = async (type) => {
    if (type == "cpt") {
      const arrayCPT = detail_untrasound_list_type.cpt_rules.map((r) => {
        return {
          ultrasound_type_id: r.ultrasound_type_id,
          ultrasound_finding_id: r.ultrasound_finding_id,
          cpt_code_id: r.cpt_code_id,
          condition_serialize: r.condition_serialize,
        };
      });
      const params = {
        ultrasound_type_id: detail_untrasound_list_type.id,
        rules: [
          ...arrayCPT,
          {
            ultrasound_type_id: detail_untrasound_list_type.id,
            ultrasound_finding_id: "",
            cpt_code_id: "",
            condition_serialize: [
              {
                cpt_code: { code: "", short_desc: "" },
                ultrasound_finding_id: undefined,
                answer: undefined,
                comparison: ">=",
                header: "",
                operator: "Select",
              },
            ],
          },
        ],
      };
      await dispatch(
        ultrasound_update_cpt_rule(detail_untrasound_list_type.id, params)
      );
      await dispatch(getDetailUltrasounds(detail_untrasound_list_type.id));
    } else {
      if (type == "result") {
        const arrayResult = detail_untrasound_list_type.result_rules.map(
          (r) => {
            return {
              ultrasound_type_id: r.ultrasound_type_id,
              ultrasound_finding_id: r.ultrasound_finding_id,
              text_template: r.text_template,
              condition_serialize: r.condition_serialize,
            };
          }
        );
        const params = {
          ultrasound_type_id: detail_untrasound_list_type.id,
          rules: [
            ...arrayResult,
            {
              ultrasound_type_id: detail_untrasound_list_type.id,
              ultrasound_finding_id: "",
              text_template: "",
              condition_serialize: [
                {
                  ultrasound_finding_id: undefined,
                  answer: undefined,
                  comparison: ">=",
                  header: "",
                  operator: "Select",
                },
              ],
            },
          ],
        };
        await dispatch(
          ultrasound_update_result_rule(detail_untrasound_list_type.id, params)
        );
        await dispatch(getDetailUltrasounds(detail_untrasound_list_type.id));
      } else {
        const arrayConclucsion = detail_untrasound_list_type.conclucsion_rules.map(
          (r) => {
            return {
              ultrasound_type_id: r.ultrasound_type_id,
              ultrasound_finding_id: r.ultrasound_finding_id,
              text_template: r.text_template,
              condition_serialize: r.condition_serialize,
            };
          }
        );
        const params = {
          ultrasound_type_id: detail_untrasound_list_type.id,
          rules: [
            ...arrayConclucsion,
            {
              ultrasound_type_id: detail_untrasound_list_type.id,
              ultrasound_finding_id: "",
              text_template: "",
              condition_serialize: [
                {
                  ultrasound_finding_id: undefined,
                  answer: undefined,
                  comparison: ">=",
                  header: "",
                  operator: "Select",
                },
              ],
            },
          ],
        };
        await dispatch(
          ultrasound_update_conclusion_rule(
            detail_untrasound_list_type.id,
            params
          )
        );
        await dispatch(getDetailUltrasounds(detail_untrasound_list_type.id));
      }
    }
  };

  return (
    <div
      className="clinicWorkflow__main-section"
      style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}
    >
      <div className="fullContainer has-no-sidebar">
        <ScrollWrapper css="no-padding x-hidden">
          <div
            className="mainContainer"
            style={{
              marginLeft: "-10px",
              paddingTop: "0",
              paddingBottom: "0",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "stretch",
            }}
          >
            <CardBlock
              title="Search Ultrasound"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <UltrasoundSearch setResource={setResource} />
            </CardBlock>

            <CardBlock
              title="Disease Conditions"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <DiseaseCondition resource={resource} />
            </CardBlock>

            <CardBlock
              title="Indication Codes"
              varient="quarter"
              other=""
              contentPadding="1rem"
            >
              <IndicationCode resource={resource} />
            </CardBlock>

            <CardBlock 
              title="Template Documentation" 
              varient="quarter"
              other=""
            >
              <DocumentationBlock resource={resource} />
            </CardBlock>

            <CardBlock
              title="CPT Code Logic"
              onClick={() => {setActiveCard('cpt_code_logic')}}
              varient={(activeCard == '' || activeCard == 'cpt_code_logic') ? "half" : "quarter"}
              other=""
              contentPadding="1rem"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      detail_untrasound_list_type &&
                      Object.keys(detail_untrasound_list_type).length <= 0
                    }
                    onClick={() => {
                      add_handle("cpt");
                    }}
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                  >
                    Add +
                  </button>
                </div>
              }
            >
              <CPTCodeLogic resource={resource} />
            </CardBlock>

            <CardBlock
              title="Results Logic"
              onClick={() => {setActiveCard('results')}}
              varient={(activeCard == 'results') ? "half" : "quarter"}
              other=""
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      detail_untrasound_list_type &&
                      Object.keys(detail_untrasound_list_type).length <= 0
                    }
                    onClick={() => {
                      add_handle("result");
                    }}
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                  >
                    Add +
                  </button>
                </div>
              }
            >
              <ResultsLogic resource={resource} />
            </CardBlock>
            <CardBlock
              title="Conclusion Logic"
              onClick={() => {setActiveCard('conclusion')}}
              varient={(activeCard == 'conclusion') ? "half" : "quarter"}
              other=""
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      detail_untrasound_list_type &&
                      Object.keys(detail_untrasound_list_type).length <= 0
                    }
                    onClick={() => {
                      add_handle("conclusion");
                    }}
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                  >
                    Add +
                  </button>
                </div>
              }
            >
              <ConclusionLogic resource={resource} />
            </CardBlock>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default ProcedureDetail;
