import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "antd";
import Cookies from "universal-cookie";

import { SearchSVG, ExpandSVG } from "../../../common/components/icons/SVGIcon";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import ScrollWrapper from "../../../common/components/ScrollBar";
import {
  getDetailProcedureData,
  addProcedure,
  getProcedureData,
  getProcedureType,
  deleteProcedureData,
  updateDetailProcedureData,
  setGlobalLoading,
} from "../../../store/actions";
import "../style.scss";

const cookies = new Cookies();
const { Search } = Input;
const { Option } = Select;

let timer = null;
const ProcedureSearch = ({ setResource }) => {
  const loading = useSelector(
    (state) => state.common.loadingDetailProcedureData
  );
  const loading_procedure_type = useSelector(
    (state) => state.procedureDetail.loading_procedure_type
  );
  const dispatch = useDispatch();
  const [providerId, setProviderId] = useState(0);
  const procedureData = useSelector((state) => state.common.procedureData);
  const [activeRow, setActiveRow] = useState(-1);
  const [dataRow, setDataRow] = useState([]);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [search, setSearch] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  let emptyRows = [];

  const handleSelect = async (row, index) => {
    await setActiveRow(index);
    if (providerId !== row.id) {
      setProviderId(row.id);
      setResource(row.id);
      cookies.set("id_procedure_type", row.id);
      await dispatch(getDetailProcedureData(row.id));
      await dispatch(getProcedureType(row.id));
    }
  };

  const handleAddProcedure = async () => {
    setLoadingLocal(true);
    const param = {
      value: search,
      sort: 0,
      side: 0,
      is_first_case: 0,
      number_of_week_followup: 0,
      range_between_procedure: 0,
      locations: "[]",
      providers: "[]",
      ultrasounds: "[]",
    };
    const addsuccess = await dispatch(addProcedure(param));
    if (addsuccess) {
      setSearch("")
      setLoadingLocal(false);
      dispatch(getProcedureData());
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setActiveRow(-1);
    setSearch(value);
  };

  useEffect(() => {
    const arrayFilter = [...procedureData].reverse();
    const arraySucces = arrayFilter.filter((item) => {
      return item?.value.toLowerCase().includes(search.toLowerCase());
    });
    setDataRow([...arraySucces]);
  }, [procedureData, search]);

  const fetchAPI = async () => {
    setLoadingLocal(true);
    const loadSuccess = await dispatch(getProcedureData());
    if (loadSuccess) {
      setLoadingLocal(false);
    }
  };

  const handleDelete = async (index) => {
    setProviderId(-1);
    setResource(-1);
    cookies.set("id_procedure_type", -1);
    const deleteSuccess = await dispatch(deleteProcedureData(dataRow[index].id));
    const loadSucess = await dispatch(getProcedureData());
    if (deleteSuccess && loadSucess) {
      setActiveRow(-1);
    }
  };

  const onChangeDetail = async (e, row) => {
    setHeaderTitle(e.target.value);
    clearTimeout(timer);
    const params = {
      value: e.target.value,
    };
    timer = setTimeout(async () => {
      await dispatch(updateDetailProcedureData(row.id, params));
      await dispatch(getProcedureData());
    }, 500);
  };

  const onChangeHybrid = async (value, row) => {
    const params = {
      hybrid: value
    };
    timer = setTimeout(async () => {
      await dispatch(updateDetailProcedureData(row.id, params));
      await dispatch(getProcedureData());
    }, 500);
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  useEffect(() => {
    dispatch(
      setGlobalLoading(loadingLocal || loading || loading_procedure_type)
    );
  }, [loading, loadingLocal, loading_procedure_type]);

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div className="patientSearch__filter-search">
          <Search
            value={search}
            placeholder="Search Hospital Procedures"
            onChange={handleSearch}
            suffix={<SearchSVG />}
          />
        </div>
        <div className="patientSearch__filter-action">
          <button
            className="patientSearch__filter-add"
            onClick={handleAddProcedure}
          >
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
      </div>
      <div className="fullContainer" style={{ position: "relative" }}>
        <DataTable
          title="procedure_search_clinical"
          labels={[
            "Procedure",
            "Hybrid"
          ]}
          widths={["60%", "40%"]}
          fullHeight={true}
          columns={[
            { sortable: false, key: "procedure" },
            { sortable: false, key: "hybrid" },
          ]}
          handleDelete={(value, index) => handleDelete(index)}
          handleClickRow={(row, index) => handleSelect(row.data, index)}
          rowData={(dataRow || []).map((item, index) => {
            return {
              data: item,
              procedure: activeRow === index ? (
                <Input
                  value={headerTitle}
                  autoFocus={true}
                  onChange={(e) => {
                    onChangeDetail(e, item);
                  }}
                  onFocus={() => {
                    setHeaderTitle(item.value);
                  }}
                />
              ) : item.value,
              hybrid: (
                <Select
                  placeholder="Hybrid"
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  style={{ width: "100%", borderBottom: 0 }}
                  value={item.hybrid}
                  onChange={(e) => {
                    onChangeHybrid(e, item);
                  }}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              )
            }
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
        
      </div>
    </div>
  );
};

export default ProcedureSearch;
