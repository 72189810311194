import React, { useState } from "react";
import TopBar from "../../../common/components/SubTopBar";
import Details from "./LocationsComponent/Details";
import { LocationsHeaders } from "../constant";
import { useSelector } from "react-redux";

const LocationDetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Location Details" subStatus={""} isBarcode={false} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders">
            {LocationsHeaders.map((tab, index) => (
              <div
                key={`tabHeaders-${tab.label}-${index}`}
                className={`tabHeaders-item ${
                  activeTab === index ? "active" : ""
                }`}
                style={{ width: tab.width }}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>
        </div>
        {activeTab === 0 && <Details />}
      </div>
    </div>
  );
}

export default LocationDetails;
