import { createActionThunk } from 'redux-thunk-actions';
import AT from '../actionTypes';
import * as API from '../../services/api.services';

export const getAppointmentBoardAppointments = createActionThunk(
  AT.GET_APPOINTMENT_BOARD_APPOINTMENTS,
  (slug) => API.getAppointmentBoardAppointments(slug)
);

export const createAppointmentBoardClinical = createActionThunk(
  AT.CREATE_APPOINTMENT_BOARD_CLINICAL,
  (location_id) => API.createAppointmentBoard(location_id, 'clinic')
);

export const createAppointmentBoardPatient = createActionThunk(
  AT.CREATE_APPOINTMENT_BOARD_PATIENT,
  (location_id) => API.createAppointmentBoard(location_id, 'patient')
);
