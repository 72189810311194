import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import ScrollWrapper from "../../../../common/components/ScrollBar";
import ReviewSidebar from "./ReviewSidebar";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import ReviewText from "./ReviewText";
import {
  clinicProviderReviewLoadInit,
  get_cpt_code_review,
  patientExaminationLoadInit,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  getDetailUltrasounds,
} from "../../../../store/actions";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";

class Review extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  state = {
    isSidebar: false,
    valueText: "",
    dataCPT: [],
  };

  componentDidMount() {
    const cookies = new Cookies();
    const apointment_ultrasound_id = cookies.get("apointment_ultrasound_id");
    const current_ultrasound_id = cookies.get("current_ultrasound_id");
    this.props.clinicProviderReviewLoadInit(apointment_ultrasound_id);
    this.props.patientExaminationLoadInit(apointment_ultrasound_id);
    this.props.get_cpt_code_review(apointment_ultrasound_id);
    this.props.get_ultrasound_finding_value(apointment_ultrasound_id);
    this.props.get_ultrasound_type(current_ultrasound_id);
    this.props.getDetailUltrasounds(current_ultrasound_id);
    this.setState({ isSidebar: window.innerWidth <= 768 ? false : true });
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEditable = (status) => {
    this.setState({ isEditable: status });
  };

  handleContent = (e) => {
    this.setState({ content: e.target.value });
  };

  handleSubmit = () => {
    this.setState({ isEditable: false, content: this.state.editedContent });
  };

  cancleEditable = () => {
    this.setState({ isEditable: false });
  };

  changeValueText = (value) => {
    this.setState({ valueText: value });
  };

  onChangeCPT = (value) => {
    this.setState({ dataCPT: value });
  };

  render() {
    const { isSidebar, valueText, dataCPT } = this.state;
    return (
      <div className="clinicWorkflow__main-section">
        <div
          className="eventsContainer has-sub-header"
          style={{ paddingTop: "1rem" }}
        >
          <ScrollWrapper css="conditionList">
            <ReviewText
              dataCPT={dataCPT}
              changeValueText={(value) => {
                this.changeValueText(value);
              }}
            />
          </ScrollWrapper>
        </div>
        <ReviewSidebar
          ref={this.sidebarRef}
          onChangeCPT={(value) => {
            this.onChangeCPT(value);
          }}
          sidebarIcon={(<Icon type="funnel-plot" theme="twoTone" twoToneColor='#740937'/>)}
          valueText={valueText}
          isShow={isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
        />

        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={this.sidebarRef} sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  clinicProviderReviewLoadInit,
  get_cpt_code_review,
  patientExaminationLoadInit,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  getDetailUltrasounds,
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
