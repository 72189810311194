import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Tooltip } from 'antd';
import { LeftCharvenSVG, CheckSVG, RedRequiredCircleSVG } from '../../common/components/icons/SVGIcon';
import TopBar from '../../common/components/SubTopBar';
import ScrollWrapper from '../../common/components/ScrollBar';
import PriorSideBar from './components/PriorSideBar';
import PriorFilters from './components/PriorFilters';
import PriorResultTable from './components/PriorResultTable';
import { getPriorFilterTypes, getAuthorizationNumbers } from '../../store/actions';
import './style.scss';
import SidebarExtension from '../../common/components/SidebarExtension';
import { FemaleSVG, MaleSVG } from '../../common/components/icons/FaxSVG';

class PriorAuthorizationPage extends Component {
  state = {
    isSidebar: false,
    activeRender: false,
    filter: {
      type: '',
      authorization_number: '',
      date_of_service: ''
    },
    search: '',
  }

  componentDidMount() {
    this.mounted = true;
    const {
      types,
      authNumbers,
      getPriorFilterTypes,
      getAuthorizationNumbers
    } = this.props;
    if (!types) {
      getPriorFilterTypes();
    }
    if (!authNumbers) {
      getAuthorizationNumbers();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
    this.setState({
      activeRender: false
    }, () => {
      this.setState({
        activeRender: true
      })
    })
  }

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  }

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  }

  handleSearch = (search) => {
    this.setState({ search });
  }

  handleFilter = (filter) => {
    this.setState({ filter });
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Prior Authorizations</title>
        <meta name="title" content="FAACT Vascular | Prior Authorizations" />
      </Helmet>
    )
  }

  getStatus = (status, status1) => {
    return (
      <div className="statusHealth">
        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
        {status}
        <span style={{ minWidth: '10px', display: 'inline-block' }} />
        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
        {status1}
      </div>
    );
  }

  renderSideComponent = () => {
    const { resource } = this.props;
    if (!resource || !Object.keys(resource).length) {
      return null;
    }

    const fullName = (
      resource.patient_name
    ).toUpperCase();
    const age = resource.date_of_birth
      ? "(" +
      moment().diff(resource.date_of_birth, "years") +
      // " yo " +
      // (!!resource.gender ? resource.gender.toUpperCase() : "MALE") +
      ")"
      : "";

    const allerrgies = [];
    if (resource.allergies) {
      resource.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
            allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resource.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resource.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: '6px'
        }}
        >

        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "26px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resource.no_drug_allergies ? 'NKDA' : 'Pending Data'}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && <br />}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={resource.allergies.map(allergy => allergy.allergy_name).join(', ')}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>
      
        {resource.gender && resource.gender.toUpperCase() == 'MALE' ? 
          <MaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/> :
          <FemaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/>
        }
      </div>
    );
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const {
      isSidebar,
      filter,
      search,
      activeRender
    } = this.state;
    const { priors, resource } = this.props;
    const filteredPriors = priors ? priors.filter(pr => pr.status === 'Authorization Required') : [];
    const status = priors ? priors.length - filteredPriors.length : 0;
    const isResource = !resource || !Object.keys(resource).length;

    return (
      <div className="priorAuthorizationPage">
        {this.renderMetadata()}
        <TopBar
          title="Prior Authorizations"
          subStatus={this.getStatus(status, filteredPriors.length)}
          noLeftSide={true}
          sideComponent={this.renderSideComponent()}
          isBarcode={!isResource}
        // draggerProps
        // isDocumentUpload
        // isFileUpload
        // onRefreshImageList
        />
        {
          isSidebar && activeRender ? <PriorSideBar
            ref={this.sidebarRef}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
          /> : null
        }

        {!isResource && (
          <SidebarExtension defaultOpenState={true} startingWidth='calc(28.3% - 20px)' sidebarReference={this.sidebarRef} sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>
        )}
        <div className="main">
          <ScrollWrapper css="no-padding">
            <div className={`mainContainer ${isResource || !isSidebar ? 'no-sidebar' : ''}`}>
              <PriorFilters
                filter={filter}
                handleChangeFilter={this.handleFilter}
                handleSearch={this.handleSearch}
              />
              <div className="tableSection">
                <PriorResultTable
                  title="result"
                  filter={filter}
                  search={search}
                  handleSidebar={this.handleSidebar}
                />
              </div>
            </div>
          </ScrollWrapper>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  types: state.prior.types,
  authNumbers: state.prior.authNumbers,
  priors: state.prior.priors,
  resource: state.prior.resource
});

const mapDispatchToProps = {
  getPriorFilterTypes,
  getAuthorizationNumbers
};

export default connect(mapStateToProps, mapDispatchToProps)(PriorAuthorizationPage);
