import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import {
  getReferralConditions,
  updateDetailUltrasounds,
  getDetailUltrasounds,
} from "../../../store/actions";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";

const DiseaseCondition = ({ resource }) => {
  const tableRef = useRef(null);
  const detail_untrasound_list_type = useSelector(
    (state) => state.procedureDetail.detail_untrasound_list_type
  );
  const referralConditions = useSelector(
    (state) => state.common.referralConditions
  );
  const [dataCondition, setDataCondition] = useState([]);
  const [dataConditionID, setDataConditionID] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (detail_untrasound_list_type && detail_untrasound_list_type.conditions) {
      setDataCondition(detail_untrasound_list_type.conditions);
      setDataConditionID(
        (detail_untrasound_list_type.conditions || []).map((r) => r.id)
      );
    } else {
      setDataCondition([]);
      setDataConditionID([]);
    }
  }, [detail_untrasound_list_type]);

  useEffect(() => {
    dispatch(getReferralConditions());
  }, []);

  useEffect(() => {
    if (referralConditions) {
      setDataSearch(referralConditions);
    }
  }, [referralConditions]);

  useEffect(() => {
    if (search) {
      const fillterArray = referralConditions.filter((r) =>
        r.value.includes(search)
      );
      setDataSearch(fillterArray);
    }
  }, [search]);

  const addCondition = async (value) => {
    if (detail_untrasound_list_type && detail_untrasound_list_type.id) {
      const checkID = detail_untrasound_list_type.conditions.map((r) => {
        return r.id;
      });
      const params = {
        // ...detail_untrasound_list_type,
        conditions: [...checkID, value.id],
      };
      await dispatch(
        updateDetailUltrasounds(detail_untrasound_list_type.id, params)
      );
      await dispatch(getDetailUltrasounds(detail_untrasound_list_type.id));
    }
  };

  const removeProvider = async (index) => {
    if (detail_untrasound_list_type && detail_untrasound_list_type.id) {
      dataConditionID.splice(index, 1);
      const params = {
        // ...detail_untrasound_list_type,
        conditions: [...dataConditionID],
      };
      await dispatch(
        updateDetailUltrasounds(detail_untrasound_list_type.id, params)
      );
      await dispatch(getDetailUltrasounds(detail_untrasound_list_type.id));
    }
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          title="Procedure-Provider-Search"
          options={dataSearch}
          handlePopulate={(value) => addCondition(value)}
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Conditions"
          keyLabel="value"
        />
      </div>

      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="condition"
          labels={["Condition"]}
          widths={["100%"]}
          fullHeight={true}
          parentHeight={tableRef && tableRef.current && tableRef.current.clientHeight}
          columns={[{ sortable: true, key: "condition" }]}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(dataCondition || []).map((item, index) => {
            return {
              condition: item.value,
            };
          })}
          emptyRows={new Array(4).fill(1)}
          sorts={[""]}
        />
      </div>
    </div>
  );
};

export default DiseaseCondition;
