import React, { Component } from "react";
import { connect } from "react-redux";
import ConditionList from "./OfficeProcedureList";
import SideBar from "./OfficeProcedureSidebar";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import {
  OfficeGetConditions,
  OfficeSetConditions,
  OfficeSaveCondition,
} from "../../../../store/actions";
import DiseaseBody from "../../../../common/components/DiseasBody";
import _ from "lodash";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";

class OfficeProcedure extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  state = {
    isSidebar: false,
    clickedCondition: "",
    saveQueued: false,
    checkSavingFuncId: null,
  };
  timer = null;
  componentDidMount() {
    const { patientResource } = this.props;
    if (patientResource) {
      const cookies = new Cookies();
      const procedure_id = cookies.get("current_appointment_id");
      this.props.OfficeGetConditions(procedure_id);
      let checkSavingFuncId = setInterval(this.saveData, 1000);
      this.setState({ ...this.state, checkSavingFuncId, isSidebar: window.innerWidth <= 768 ? false : true });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.checkSavingFuncId);
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  onChangeData = (conditions) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.props.OfficeSetConditions(conditions);
      this.setState({ ...this.state, saveQueued: true });
    }, 500);
  };

  saveData = () => {
    const { saveQueued } = this.state;
    const { saving } = this.props;
    if (saveQueued && !saving) {
      const { patientResource } = this.props;
      const patient_id = patientResource.id;
      const { conditions } = this.props;
      const cookies = new Cookies();
      const procedure_id = cookies.get("current_appointment_id");
      this.props.OfficeSaveCondition(patient_id, procedure_id, conditions);
      this.setState({ ...this.state, saveQueued: false });
    }
  };

  onClickConditionPanel = (condition) => {
    if (condition) {
      condition = condition.replace("collapse-", "");
      this.setState({ ...this.state, clickedCondition: condition });
    } else {
      this.setState({ ...this.state, clickedCondition: "" });
    }
  };

  onSave = () => {
    const { patientResource } = this.props;
    const { conditions } = this.props;
    const patient_id = patientResource.id;
    const cookies = new Cookies();
    const procedure_id = cookies.get("current_appointment_id");
    this.props.OfficeSaveCondition(patient_id, procedure_id, conditions);
  };

  render() {
    const { isSidebar } = this.state;
    const { conditions, allConditions } = this.props;

    const { clickedCondition } = this.state;
    let conditionClassName = "";
    for (let condition_ of conditions) {
      conditionClassName += _.snakeCase(condition_["condition"]) + " ";
    }

    return (
      <div
        style={{
          display: !this.props.display
            ? "flex"
            : this.props.display === "flex"
            ? "flex"
            : "none",
        }}
        className="clinicWorkflow__main-section"
      >
        <div className="eventsContainer has-sub-header">
          <div className="patientContainer">
            <div
              className={`imageSection disease-icon ${_.snakeCase(
                clickedCondition
              )}-clicked ${conditionClassName}`}
            >
              <DiseaseBody />
            </div>
            <div className="detailSection">
              <ConditionList
                rows={conditions}
                options={allConditions}
                onChangeData={this.onChangeData}
                onClickConditionPanel={this.onClickConditionPanel}
              />
            </div>
          </div>
        </div>
        <SideBar
          ref={this.sidebarRef}
          sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
          isShow={this.props.isSlide || isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          conditions={conditions}
          clickedCondition={clickedCondition}
        />
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={this.sidebarRef} sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  conditions: state.clinicProviderOffice.conditions,
  allConditions: state.clinicProviderOffice.allConditions,
  saving: state.clinicProviderOffice.saving,
  patientResource: state.clinicProvider.patientResource,
});

const mapDispatchToProps = {
  OfficeGetConditions,
  OfficeSetConditions,
  OfficeSaveCondition,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfficeProcedure);
