import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import {
  getDiseases,
  updateDetailUltrasounds,
  getUltrasounds,
  deleteUltrasoundType,
  save_new_ultrasound,
  setGlobalLoading,
} from "../../../../store/actions";
import { validateSteps, validateField } from "../../../../services/validations";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import ToastMessage from "../../../../common/components/Toast";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import VariablesTable from "./VariablesTable";

const exam = [
  { value: "name", type: "default" },
  { value: "length_of_time", type: "default" },
  { value: "cpt_code", type: "default" },
];

const DetailsSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  DetailUltrasounds,
  Conditions,
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [name, setName] = useState("");
  const [lengthTime, setLengthTime] = useState("");
  const [cptCode, setCptCode] = useState(0);
  const [isToastr, setIsToastr] = useState(false);
  const [isDeleteToastr, setIsDeleteToastr] = useState(false);
  const diseases = useSelector((state) => state.common.diseases);
  const addLoading = useSelector((state) => state.ultrasound.addLoading);
  const addSuccess = useSelector((state) => state.ultrasound.addSuccess);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const dispatch = useDispatch();
  //
  const [inputCondition, setInputCondition] = useState("");
  const [isAddForm, setOpenAddForm] = useState(false);
  const [listResultCondition, setListResultCondition] = useState([]);
  const [listSlectedCondition, setListSlectedCondition] = useState([]);
  const [isLoadingCondition, setIsLoadingCondition] = useState(false);
  const [isResultCondition, setIsResultCondition] = useState(false);

  useEffect(() => {
    setIsToastr(addSuccess);
  }, [addSuccess]);

  const handleSearchCondition = (search) => {
    setInputCondition(search);
    setIsLoadingCondition(true);
    setIsResultCondition(true);
    const fillter =
      Conditions &&
      Conditions.filter(
        (location) =>
          location.value.toLowerCase().includes(search.toLowerCase()) &&
          search.length > 0
      );
    if (search.length === 0) {
      setIsLoadingCondition(false);
      setIsResultCondition(false);
    } else {
      setTimeout(() => {
        setListResultCondition(fillter);
        setIsLoadingCondition(false);
        setIsResultCondition(true);
      }, 600);
    }
  };

  const addNewUltraSound = async () => {
    const DataConditon = [];
    for (const key in listSlectedCondition) {
      const checked = Conditions.find(
        (r) => listSlectedCondition && r.value === listSlectedCondition[key]
      );
      DataConditon.push(checked && checked.id);
    }
    const data = {
      name: name,
      length_of_time: lengthTime,
      cpt_code: cptCode,
      conditions: DataConditon,
      codes: idDiseases,
    };
    if (!validateSteps(data, exam)) {
      setEnableError(true);
      return;
    } else {
      resetValue();
      await dispatch(save_new_ultrasound(data));
      await dispatch(getUltrasounds());
      if (isToastr) {
        setTimeout(() => {
          setIsToastr(false);
          setEnableError(false);
          setValue();
          setOpenAddForm(false);
        }, 2000);
      }
    }
  };

  const openAddForm = () => {
    resetValue();
    setOpenAddForm(true);
    setEnableError(false);
  };

  const cancelAddForm = () => {
    setOpenAddForm(false);
    setEnableError(false);
    setValue();
  };

  const selectItemCondition = (item) => {
    if (item.length > 0) {
      listSlectedCondition.push(item);
      setInputCondition("");
      setIsResultCondition(false);
    }
  };

  const removeRowCondition = (value) => {
    setIsLoadingCondition(true);
    const tasks = listSlectedCondition;
    const idDex = tasks.findIndex((r) => r === value);
    tasks.splice(idDex, 1);
    setListSlectedCondition(tasks);
    setTimeout(() => {
      setIsLoadingCondition(false);
    }, 0);
  };
  // diseases
  const [inputDiseases, setInputDiseases] = useState("");
  const [listSlectedDiseases, setListSlectedDiseases] = useState([]);
  const [isLoadingDiseases, setIsLoadingDiseases] = useState(false);
  const [isResultDiseases, setIsResultDiseases] = useState(false);
  const [idDiseases, setIdDiseases] = useState([]);

  const handleSearchDiseases = async (search) => {
    setInputDiseases(search);
    setIsLoadingDiseases(true);
    setIsResultDiseases(true);
    if (search.length === 0) {
      setIsLoadingDiseases(false);
      setIsResultDiseases(false);
    } else {
      const searchApi = await dispatch(getDiseases(search));
      if (searchApi) {
        setIsLoadingDiseases(false);
        setIsResultDiseases(true);
      }
    }
  };

  const selectItemDiseases = (item) => {
    if (item.length > 0 && diseases.length > 0) {
      listSlectedDiseases.push(item);
      const checked = diseases.find((r) => r.icd_code_name === item);
      setIdDiseases([...idDiseases, checked.id]);
      setInputDiseases("");
      setIsResultDiseases(false);
    }
  };

  const removeRowDiseases = (value, index) => {
    setIsLoadingDiseases(true);
    const tasksID = idDiseases;
    tasksID.splice(index, 1);
    setIdDiseases(tasksID);
    const tasks = listSlectedDiseases;
    const idDex = tasks.findIndex((r) => r === value);
    tasks.splice(idDex, 1);
    setListSlectedDiseases(tasks);

    setTimeout(() => {
      setIsLoadingDiseases(false);
    }, 0);
  };

  const clickOnSave = async () => {
    const DataConditon = [];
    for (const key in listSlectedCondition) {
      const checked = Conditions.find(
        (r) => listSlectedCondition && r.value === listSlectedCondition[key]
      );
      DataConditon.push(checked && checked.id);
    }
    const data = {
      name: name,
      length_of_time: lengthTime,
      cpt_code: cptCode,
      conditions: DataConditon,
      codes: idDiseases,
    };
    if (!validateSteps(data, exam)) {
      setEnableError(true);
      return;
    } else {
      setLoading(true);
      const saveAllSuccess = await dispatch(
        updateDetailUltrasounds(DetailUltrasounds.id, data)
      );
      const loadList = await dispatch(getUltrasounds());
      if (saveAllSuccess && loadList) {
        setLoading(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };

  const deleteUltrasound = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    const deleteSuccess = await dispatch(
      deleteUltrasoundType(DetailUltrasounds.id)
    );
    setConfirmDelete(false);
    const loadList = await dispatch(getUltrasounds());
    if (deleteSuccess && loadList) {
      setLoading(false);
      setIsDeleteToastr(true);
      setTimeout(() => {
        setIsDeleteToastr(false);
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  const setValue = () => {
    dispatch(getDiseases());
    setName(DetailUltrasounds.name);
    setLengthTime(DetailUltrasounds.length_of_time);
    setCptCode(DetailUltrasounds.cpt_code);
    setListSlectedCondition(
      (DetailUltrasounds.conditions || []).map((r) => r.value)
    );
    setListSlectedDiseases(
      (DetailUltrasounds.codes || []).map((r) => r.icd_code_name)
    );
    setIdDiseases((DetailUltrasounds.codes || []).map((r) => r.id));
  };

  const resetValue = () => {
    setListSlectedCondition([]);
    setListSlectedDiseases([]);
    setIdDiseases([]);
    setCptCode(undefined);
    setName(undefined);
    setLengthTime(undefined);
  };

  useEffect(() => {
    setValue();
    setReset(true);
    setConfirmDelete(false);
  }, [DetailUltrasounds]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  useEffect(() => {
    if (isShow && !DetailUltrasounds && Object.keys(DetailUltrasounds).length) {
      dispatch(setGlobalLoading(loading || addLoading))
    }
  }, [loading, addLoading, isShow, DetailUltrasounds])

  if (!isShow || !DetailUltrasounds || !Object.keys(DetailUltrasounds).length) {
    return null;
  }

  return (
    <>
      <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
        {isToastr && (
          <ToastMessage
            type="Ultrasounds"
            status="success"
            msg="Saved Successfully!"
          />
        )}
        {isDeleteToastr && (
          <ToastMessage
            type="Ultrasounds"
            status="success"
            msg="Ultrasound type deleted successfully!"
          />
        )}
        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          {!isAddForm ? (
            <SidebarTitle
              icon={<MaleSVG />}
              title={
                <span className="text-uppercase">{DetailUltrasounds.name}</span>
              }
              subTitle=""
            />
          ) : null}

          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
          >
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                enableErrorText={enableError}
                reset={reset}
                label={"Ultrasound Name"}
                value={name}
                handleChange={(value) => {
                  setName(value);
                }}
                required={true}
                isError={validateField("name", name)}
                helperText={"Ultrasound Name is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                reset={reset}
                enableErrorText={enableError}
                label={"Length of Time"}
                value={lengthTime}
                handleChange={(value) => {
                  setLengthTime(value);
                }}
                required={true}
                isError={validateField("length_of_time", lengthTime)}
                helperText={"Length of Time is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                reset={reset}
                label={"CPT Code"}
                enableErrorText={enableError}
                value={cptCode}
                handleChange={(value) => {
                  setCptCode(value);
                }}
                required={true}
                isError={validateField("cpt_code", cptCode)}
                helperText={"CPT is Required!"}
              />
            </div>

            <div className="resourceInfo d-flex" style={{ marginTop: "1.5rem" }}>
              <b>Disease Conditions</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <AutoCompleteComponent
                title="Disease-Conditions"
                placeholder="Search Disease Conditions"
                keyLabel={"value"}
                disableSearchIcon={false}
                loading={isLoadingCondition}
                isResult={isResultCondition}
                handleSearch={handleSearchCondition}
                query={inputCondition}
                selectItem={selectItemCondition}
                removeItem={removeRowCondition}
                rows={listSlectedCondition}
                options={listResultCondition}
              />
            </div>
            <VariablesTable />
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <b>Indication Codes</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <AutoCompleteComponent
                title="procedure-providers"
                placeholder="Search Indication Codes"
                keyLabel={"icd_code_name"}
                options={diseases}
                disableSearchIcon={false}
                loading={isLoadingDiseases}
                isResult={isResultDiseases}
                handleSearch={handleSearchDiseases}
                query={inputDiseases}
                selectItem={selectItemDiseases}
                removeItem={(value, index) => {
                  removeRowDiseases(value, index);
                }}
                rows={listSlectedDiseases}
              />
            </div>
            <hr />
          </div>
          {!isAddForm && (
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                flexWrap: "wrap",
              }}
            >
              <button
                onClick={openAddForm}
                className="common-btn outlined-btn"
                style={{ marginTop: "0.5rem" }}
              >
                New Ultrasound
              </button>
              <button
                onClick={clickOnSave}
                style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}
              >
                Save Record
              </button>
              <div
                style={{
                  justifyContent: "flex-end",
                  marginTop: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              >
                {!confirmDelete && (
                  <button
                    className="common-btn outlined-btn"
                    onClick={deleteUltrasound}
                  >
                    Delete
                  </button>
                )}
                {confirmDelete && (
                  <>
                    <button
                      className="common-btn outlined-btn"
                      onClick={() => setConfirmDelete(false)}
                    >
                      Back
                    </button>
                    <button
                      className="common-btn delete-btn"
                      style={{
                        marginLeft: "0.5rem",
                        backgroundColor: "#CA373B",
                        marginRight: 0,
                      }}
                      onClick={deleteUltrasound}
                    >
                      Confirm Delete ?
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {isAddForm && (
            <div className="actionContent">
              <button
                className="outlined-btn"
                style={{ marginRight: "0.5rem" }}
                onClick={cancelAddForm}
              >
                Back
              </button>
              <button onClick={addNewUltraSound}>Save</button>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </>
  );
});

export default DetailsSidebar;
