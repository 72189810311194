import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";

/*
// already defined on patientSchedule
export const getPatientSchedules = createActionThunk(
  AT.GET_PATIENT_SCHEDULES,
  ({ date, location, provider_id, patient_id }) =>
    API.getPatientSchedules({ date, location, provider_id, patient_id })
);

// already defined on patientSchedule
export const searchPatientSchedules = (searchValue) => (dispatch) =>
  dispatch({
    type: AT.SEARCH_PATIENT_SCHEDULES,
    payload: {
      searchValue,
    },
  });
*/

export const save_new_ultrasound = createActionThunk(
  AT.SAVE_NEW_ULTRASOUND,
  (param) => API.save_new_ultrasound(param)
);

// export const chooseSelectedPatientResource = createActionThunk(
//   AT.CHOOSE_SELECTED_PATIENT_RESOURCE,
//   (id) => API.getPatient({ id })
// );
