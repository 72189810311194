import React, { Component } from "react";
import { connect } from "react-redux";
import HPIConditionList from "./HPIConditionList";
import HPISideBar from "./HPISidebar";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import {
  HPIGetConditions,
  HPISetConditions,
  HPISaveCondition,
  loadUltrasoundHistoryForConditionHPI,
  setGlobalLoading,
  setGlobalToastr,
} from "../../../../store/actions";
import DiseaseBody from "../../../../common/components/DiseasBody";
import _ from "lodash";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";

class HPIContainer extends Component {
  state = {
    isSidebar: false,
    clickedCondition: "",
    saveQueued: false,
    checkSavingFuncId: null,
    first: false,
  };
  timer = null;
  componentDidMount() {
    const { patientResource } = this.props;
    if (patientResource) {
      const cookies = new Cookies();
      const procedure_id = cookies.get("current_appointment_id");
      this.props.HPIGetConditions(procedure_id);
      // let checkSavingFuncId = setInterval(this.saveData, 1000);
      this.setState({
        ...this.state,
        // checkSavingFuncId,]
        first: true,
        isSidebar: window.innerWidth <= 768 ? false : true,
      });
    }
  }

  // componentWillUnmount() {
  //   clearInterval(this.state.checkSavingFuncId);
  // }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  onChangeData = (conditions) => {
    const { patientResource, is_locked } = this.props;
    const patient_id = patientResource.id;
    const cookies = new Cookies();

    const procedure_id = cookies.get("current_appointment_id");
    this.props.HPISetConditions(conditions);
    if (this.state.first) {
      if (is_locked == 0 || is_locked == null) {
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          this.props.HPISaveCondition(patient_id, procedure_id, conditions);
          this.setState({ ...this.state, saveQueued: true });
        }, 500);
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
      }
    }
  };

  // saveData = () => {
  //   const { patientResource } = this.props;
  //   const patient_id = patientResource.id;
  //   const { conditions } = this.props;
  //   const cookies = new Cookies();
  //   const procedure_id = cookies.get("current_appointment_id");
  //   this.props.HPISaveCondition(patient_id, procedure_id, conditions);
  //   this.setState({ ...this.state, saveQueued: false });
  // };

  onClickConditionPanel = async (condition) => {
    const { patientResource } = this.props;
    if (condition) {
      condition = condition.replace("collapse-", "");
      this.setState({ ...this.state, clickedCondition: condition });
      if (condition && condition.length > 0 && condition.includes("_")) {
        const check = condition.split("_");
        condition = check.join(" ");
      }
      if (patientResource && patientResource.id) {
        await this.props.setGlobalLoading(true);
        await this.props.loadUltrasoundHistoryForConditionHPI(
          patientResource.id,
          condition
        );
        await this.props.setGlobalLoading(false);
      }
    } else {
      this.setState({ ...this.state, clickedCondition: "" });
    }
  };

  // onSave = () => {
  //   const { patientResource } = this.props;
  //   const { conditions } = this.props;
  //   const patient_id = patientResource.id;
  //   const cookies = new Cookies();
  //   const procedure_id = cookies.get("current_appointment_id");
  //   this.props.HPISaveCondition(patient_id, procedure_id, conditions);
  // };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  } 

  render() {
    const { isSidebar } = this.state;
    const { conditions, allConditions } = this.props;
    const { clickedCondition } = this.state;
    let conditionClassName = "";
    for (let condition_ of conditions) {
      conditionClassName += _.snakeCase(condition_["condition"]) + " ";
    }

    return (
      <div
        style={{
          display: !this.props.display
            ? "flex"
            : this.props.display === "flex"
            ? "flex"
            : "none",
        }}
        className="clinicWorkflow__main-section"
      >
        <div className="eventsContainer has-sub-header">
          <div className="patientContainer">
            <div
              className={`imageSection disease-icon ${_.snakeCase(
                clickedCondition
              )}-clicked ${conditionClassName}`}
            >
              <DiseaseBody />
            </div>
            <div className="detailSection">
              <HPIConditionList
                rows={conditions}
                options={allConditions}
                onChangeData={this.onChangeData}
                onClickConditionPanel={this.onClickConditionPanel}
              />
            </div>
          </div>
        </div>
        <HPISideBar
          ref={this.sidebarRef}
          sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
          isShow={this.props.isSlide || isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          conditions={conditions}
          clickedCondition={clickedCondition}
        />
        <SidebarExtension sidebarReference={this.sidebarRef} startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_locked: state.clinicProviderReview.is_locked,
  conditions: state.clinicProviderHPI.conditions,
  allConditions: state.clinicProviderHPI.allConditions,
  saving: state.clinicProviderHPI.saving,
  patientResource: state.clinicProvider.patientResource,
});

const mapDispatchToProps = {
  HPIGetConditions,
  HPISetConditions,
  HPISaveCondition,
  loadUltrasoundHistoryForConditionHPI,
  setGlobalLoading,
  setGlobalToastr,
};

export default connect(mapStateToProps, mapDispatchToProps)(HPIContainer);
