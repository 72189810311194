import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../../../common/components/Card";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import FileImporter from '../../../../common/components/FileImporter';
import DocumentCategorization from "./DocumentCategorization";
import DocumentOCRSettings from "./DocumentComponents/DocumentOCRSettings";
import HealthcareProviderLocation from "./DocumentComponents/HealthcareProviderLocation";
import DocumentLogoUpload from './DocumentComponents/DocumentLogoUpload';
import OrganizationDetails from './DocumentComponents/OrganizationDetails';
import DocumentTemplates from './DocumentComponents/DocumentPDFUpload';
import { adminSettingGetConfig } from "../../../../store/actions";

const DocumentCustomization = () => {
  const dispatch = useDispatch();
  const resource = useSelector((state) => state.adminSetting);
 
  useEffect(() => {
    dispatch(adminSettingGetConfig());
  }, []);

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: '0 0.5rem 0 1rem', height: 'calc(100vh - 208px)' }}>
      <div className="fullContainer has-no-sidebar">
        <ScrollWrapper css="no-padding x-hidden">
          <div
            className="mainContainer"
            style={{
              marginLeft: "-10px",
              paddingTop: "0",
              paddingBottom: "0",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "stretch",
            }}
          >
            <DocumentCategorization />
            <CardBlock
              title="Logo Upload"
              varient="quarter"
              other=""
            >
              <DocumentLogoUpload
                resource={resource}
              />
            </CardBlock>
            <CardBlock
              title="OCR Settings"
              varient="quarter"
              other=""
              contentPadding='1rem'
            >
              <DocumentOCRSettings 
                resource={resource}
              />
            </CardBlock>

            <CardBlock
              title="Healthcare Provider Location"
              varient="half"
              other=""
            >
              <HealthcareProviderLocation />
            </CardBlock>

            <CardBlock
              title="Organization Details"
              varient="quarter"
              other=""
              contentPadding='1rem'
            >
              <OrganizationDetails 
                resource={resource}
              />
            </CardBlock>
            <CardBlock
              title="Document Template"
              varient="quarter"
              other=""
              contentPadding='1rem'
            >
              <DocumentTemplates resource={resource} />
            </CardBlock>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
}

export default DocumentCustomization;
