import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Icon, Tooltip } from "antd";
import TopBar from "../../../common/components/SubTopBar";
import ScrollWrapper from "../../../common/components/ScrollBar";
import ClinicSchedule from "./providerSubViews/ClinicSchedule";
import PatientOverview from "../../../common/views/PatientSnapshot";
import PatientChart from "../../../common/views/PatientChart";
import Education from "../../../common/views/Education";
import PlanOrders from "../../../common/components/PlanOder/PlanOrders";
import Appointments from "../../../common/views/Appointment";
import Review from "./providerSubViews/Review";
import Consent from "./providerSubViews/Consen";
import PreOperative from "./providerSubViews/PreOperative";
import PostOperative from "./providerSubViews/PostOperative";
import Discharge from "./providerSubViews/Discharge";
import IntaOperative from "./providerSubViews/IntaOperative";
import { TabHeaders } from "../constant";
import Cookies from "universal-cookie";
import {
  patientExaminationSave,
  setActiveReviewChange,
} from "../../../store/actions";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";

const cookies = new Cookies();
export class Provider extends Component {
  state = {
    activeTab: 0,
    isSelectedPatient: false,
    dataPre: [],
    checkAllPre: false,
    patient_id_event: cookies.get("current_appointment_id"),
    patient_id: cookies.get("current_patient_id"),
    dataPostOp: [],
    checkAllPostOp: false,
    dataDis: [],
  };

  componentWillReceiveProps(nextProps) {
    const isSelectedPatientProps = nextProps.patientResource || null;
    if (isSelectedPatientProps) {
      this.setState({
        isSelectedPatient: true,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  goNextTab_ = () => {
    var checkSelectedInterval = setInterval(this.goNextTab, 50);
    this.setState({ ...this.state, checkSelectedInterval });
  };

  goNextTab = () => {
    // const { patientResource } = this.props;
    if (this.state.isSelectedPatient) {
      clearInterval(this.state.checkSelectedInterval);
      this.setState({
        ...this.state,
        activeTab: 1,
        checkSelectedInterval: null,
      });
    }
  };

  dataPostCheckAll = (value, checkAll) => {
    this.setState({
      dataPre: value,
      checkAllPre: checkAll,
    });
  };

  dataPostOpCheckAll = (value, checkAll) => {
    this.setState({
      dataPostOp: value,
      checkAllPostOp: checkAll,
    });
  };

  dataDisCheckAll = (value) => {
    this.setState({
      dataDis: value,
    });
  };

  renderSideComponent = () => {
    const { selectedResource, resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (
      resourceCheck.first_name +
      " " +
      resourceCheck.last_name
    ).toUpperCase();
    const age = resourceCheck.date_of_birth
      ? "(" +
        moment().diff(resourceCheck.date_of_birth, "years") +
        // " yo " +
        // (!!resourceCheck.gender
        //   ? resourceCheck.gender.toUpperCase()
        //   : "MALE") +
        ")"
      : "";

    const allerrgies = [];
    if (resourceCheck.allergies) {
      resourceCheck.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
          allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >

        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "26px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && <br />}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip
              placement="bottomRight"
              title={resourceCheck.allergies
                .map((allergy) => allergy.allergy_name)
                .join(", ")}
            >
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>
      
        {resourceCheck.gender &&
        resourceCheck.gender.toUpperCase() == "MALE" ? (
          <MaleSVG
            style={{ marginLeft: "8px", height: "unset", width: "26px" }}
          />
        ) : (
          <FemaleSVG
            style={{ marginLeft: "8px", height: "unset", width: "26px" }}
          />
        )}
      </div>
    );
  };

  goNextTabReview = () => {
    this.setState({
      activeTab: 9,
    });
    this.props.setActiveReviewChange(false);
  };

  handleTabIndex = async (index) => {
    if (this.state.activeTab == 4) {
      await this.props.patientExaminationSave(this.state.patient_id_event, {
        patient_id: this.state.patient_id,
        pre_operative: [...this.state.dataPre],
        all_normal_pre_operative: this.state.checkAllPre,
      });
    } else if (this.state.activeTab == 6) {
      this.props.patientExaminationSave(this.state.patient_id_event, {
        patient_id: this.state.patient_id,
        post_operative: [...this.state.dataPostOp],
        all_normal_post_operative: this.state.checkAllPostOp,
      });
    } else if (this.state.activeTab == 7) {
      await this.props.patientExaminationSave(this.state.patient_id_event, {
        patient_id: this.state.patient_id,
        discharge: [...this.state.dataDis],
      });
    }
    this.setState({ activeTab: index });
  };

  render() {
    const { selectedResource } = this.props;
    const { activeTab, isSelectedPatient } = this.state;
    return (
      <div className="clinicWorkflow__main-page">
        <TopBar
          title="OBL Module"
          subStatus={""}
          sideComponent={this.renderSideComponent()}
          isBarcode={isSelectedPatient}
        />
        <div
          className={`clinicWorkflow__main-container ${
            this.props.isSidebar ? "openSidebar" : ""
          }`}
        >
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <ScrollWrapper css="no-padding tab-header-scroll">
                <div style={{ display: "flex" }}>
                  {TabHeaders.map((tab, index) =>
                    isSelectedPatient ? (
                      <div
                        key={`tabHeaders-${tab.label}-${index}`}
                        className={`tabHeaders-item ${
                          activeTab === index ? "active" : ""
                        }`}
                        style={{ minWidth: tab.width }}
                        onClick={() => {
                          this.handleTabIndex(index);
                        }}
                      >
                        {tab.label}
                      </div>
                    ) : (
                      <div
                        key={`tabHeaders-${tab.label}-${index}`}
                        className={`tabHeaders-item ${
                          activeTab === index ? "active" : ""
                        } ${index > 0 && "disabled"}`}
                        style={{ minWidth: tab.width }}
                      >
                        {tab.label}
                      </div>
                    )
                  )}
                </div>
              </ScrollWrapper>
            </div>
          </div>
          {activeTab === 0 && (
            <ClinicSchedule
              goNextTab={this.goNextTab_}
              goNextTabReview={this.goNextTabReview}
            />
          )}
          {activeTab === 1 && (
            <PatientOverview 
            sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038"/>}
            patientResource={selectedResource} />
          )}
          {activeTab === 2 && (
            <PatientChart
              sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038"/>}
              patientResource={selectedResource} 
            />
          )}
          {activeTab === 3 && <Consent />}
          {activeTab === 4 && (
            <PreOperative
              dataPostCheckAll={(data, checkAll) => {
                this.dataPostCheckAll(data, checkAll);
              }}
            />
          )}
          {activeTab === 5 && <IntaOperative />}
          {activeTab === 6 && (
            <PostOperative
              dataPostOpCheckAll={(data, checkAll) => {
                this.dataPostOpCheckAll(data, checkAll);
              }}
            />
          )}
          {activeTab === 7 && (
            <Discharge
              dataDisCheckAll={(data) => {
                this.dataDisCheckAll(data);
              }}
            />
          )}
          {activeTab === 8 && <PlanOrders 
            navigateToReview={this.goNextTabReview}
            sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038"/>}
          />}
          {activeTab === 9 && <Review sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038"/>}/>}
          {activeTab === 10 && <Appointments />}
          {activeTab === 11 && <Education sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038"/>} patientResource={selectedResource} />}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  patientResource: state.patientSchedule.selectedPatientResource.id,
  selectedResource: state.patientSchedule.selectedPatientResource,
  resourceCheck: state.patientSnapshot.resource,
  isSidebar: state.sidebar.isSidebarOpen,
  activeReview: state.common.activeReview,
});

const mapDispatchToProps = {
  patientExaminationSave,
  setActiveReviewChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Provider);
