import React, { useEffect, useMemo, useRef, useState } from "react";
import { Select, Icon, Collapse, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";
import pdf2base64 from "pdf-to-base64";
import { useDebounce } from "use-lodash-debounce";

import { CloseIcon, ExpandSVG, ArrowDownSVG } from "../../../common/components/icons/SVGIcon";
import { SortingArrowIcon } from "../../../common/components/icons/SortingIcon";
import {
  IncomingFaxSVG,
  OutgoingFaxSVG,
} from "../../../common/components/icons/FaxSVG";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import ScrollWrapper from "../../../common/components/ScrollBar";
import SidebarTitle from "../../../common/components/SidebarTitle";
import EditableMaterialText from "../../../common/components/EditableText/MaterialInput";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import {
  getFaxLogs,
  detailFaxLog,
  printFax,
  emailFax,
  sendFax,
  getPatientsByQuery,
  saveFaxToDocument,
  clearFax,
  deleteFaxLog,
  set_default_toast,
  reset_fax_scan_document_log,
  get_ocr_category,
  get_list_patients
} from "../../../store/actions";
import { useOutsideDetect } from "../../../common/utils";
import ToastMessage from "../../../common/components/Toast";
import { validateField, validateSteps } from "../../../services/validations";

const cookies = new Cookies();
const { Option } = Select;
const { Search } = Input;
const { Panel } = Collapse;
const dateFormat = "MM/DD/YYYY";

// sorting by date
const sortByDate = (direct) => (a, b) => {
  const aDate = moment(a.created_at);
  const bDate = moment(b.created_at);

  if ((direct = "asc")) {
    return aDate.diff(bDate);
  } else if ((direct = "desc")) {
    return bDate.diff(aDate);
  } else {
    return 0;
  }
};

const FaxingSidebar = React.forwardRef(({ resource, isShow, activeTab, handleCloseSidebar }, ref) => {
  const tableRef = useRef(null);

  const title =
    activeTab === "incoming-fax"
      ? "INCOMING FAX HISTORY"
      : "OUTGOING FAX HISTORY";
  const dispatch = useDispatch();
  const listPatients  = useSelector((state) => state.common.list_patients);

  const fax_logs = useSelector((state) => state.patientRecords.fax_logs);
  const print_fax = useSelector((state) => state.patientRecords.print_fax);

  const detail_fax_logs = useSelector(
    (state) => state.patientRecords.detail_fax_logs
  );

  const existingPCP = useSelector(
    (state) => state.patientRecords.detail_fax_logs?.patient?.primary_care_provider
  );
  const existingProvider = useSelector(
    (state) => state.patientRecords.detail_fax_logs?.patient?.referral_provider_detail
  );
    console.dir(existingPCP);
  const patient_id = cookies.get("current_patient_id");
  const user_id = cookies.get("cvai-current-user");
  const [activeRow, setActiveRow] = useState(0);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [tableLoad, setTableLoad] = useState([]);
  const [reset, setReset] = useState(true);
  const [sender, setSender] = useState([]);
  const [recipi, setRecipi] = useState([]);
  const [patient, setPatient] = useState([]);
  const [type, setType] = useState([]);
  const [printLink, setPrintLink] = useState("");
  const [fax_id, setFax_id] = useState(undefined);
  const [selectRecipi, setSelectRecipi] = useState(null);
  const [selectSender, setSelectSender] = useState(null);
  const [selectDate, setSelectDate] = useState(null);
  const [selectType, setSelectType] = useState(null);
  const [selectPatient, setSelectPatient] = useState(null);
  const [selectDateIncom, setSelectDateIncom] = useState(null);
  const [faxValue, setFaxValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [openPanel, setOpen] = useState(false);
  const [search, setSearch] = useState({});
  const ref1 = useRef(null);
  const loading = useSelector((state) => state.dashboard.loadingPatients);
  const patients = useSelector((state) => state.dashboard.patients);
  const [activeSearch, setActiveSearch] = useState(-1);
  const debouncedValue = useDebounce(search, 400);
  const [offset, setOffset] = useState(0);
  const [typeComponent, setTypeComponent] = useState({});
  const [pcp, setPCP] = useState(null);
  const [referring, setReferring] = useState(null);

  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(true);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const loadingAction = useSelector((state) => state.dashboard.loadingAction);
  const toastAction = useSelector((state) => state.dashboard.toastAction);
  const [isToastr, setIsToastr] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [enableErrorEmail, setEnableErrorEmail] = useState(false);
  const orc_category = useSelector((state) => state.setting.orc_category);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [sortOption, setSortOption] = useState({
    outgoing: {
      date: "",
      patient_name: "",
      type: "",
    },
    incoming: {
      date: "",
      patient_name: "",
      type: "",
    },
  });
  // const [types, setCategories] = useState({ outgoing: [], incoming: [] });
  const [types, setCategories] = useState({ outgoing: "", incoming: "" });
  const [isOpenFax, setOpenFax] = useState(false);
  const [isOpenEmail, setOpenEmail] = useState(false);

  const toggleOpenFax = (e) => {
    e.stopPropagation();
    setOpenFax(!isOpenFax);
  }
  const toggleOpenEmail = (e) => {
    e.stopPropagation();
    setOpenEmail(!isOpenEmail);
  }

  useOutsideDetect(ref1, openPanel, setOpen);
  useEffect(() => {
    if (debouncedValue && openPanel) {
      dispatch(
        getPatientsByQuery(
          search && search[activeRow] && search[activeRow].value
        )
      );
    }
  }, [debouncedValue]);

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const fetchApiSelect = async () => {
    await dispatch(get_ocr_category());
  };

  useEffect(() => {
    fetchApiSelect();
    dispatch(get_list_patients(1, 1, ''));
  }, []);

  useEffect(() => {
    setFaxValue(undefined);
    setEmailValue(undefined);
    setConfirmDelete(false);
  }, [fax_id]);

  useEffect(() => {
    if (activeRow) {
      setOpen(false);
    }
  }, [activeRow]);

  const selectedPatient = useMemo(() => {
    if (!patientId) {
      return '';
    }
    return (listPatients || []).find(p => p.id === patientId);
  }, [patientId, listPatients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > rowData.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const emptyRows = useMemo(() => {
    if (!tableRef || !tableRef.current) {
      return [];
    }
    const totalRowsCount = Math.floor(tableRef.current.offsetHeight / 40);
    const emptyRowsCount = totalRowsCount - tableLoad.length;
    if (emptyRowsCount < 1) {
      return [];
    }

    return new Array(emptyRowsCount).fill({ document: null });
  }, [tableLoad]);

  const fetchAPI = () => {
    dispatch(
      getFaxLogs({
        patient_id: patient_id,
        user_id: user_id,
        direction: activeTab === "incoming-fax" ? "incoming" : "outgoing",
      })
    );
  };

  useEffect(() => {
    if (tableLoad.length > 0) {
      setActiveRow(0);
      setFax_id(tableLoad[0].id)
      dispatch(detailFaxLog(tableLoad[0].id));
    }
  }, [tableLoad, activeTab])

  const handleRow = (index, id) => async () => {
    if (activeRow != index) {
      setActiveRow(index);
      setFax_id(id);
      dispatch(detailFaxLog(id));
    }
  };

  const handleSortOption = async (key, type, value) => {
    let dateSort = "";
    let patientSort = "";

    if (type == "date") {
      dateSort = value;
      patientSort = "";
      setSortOption({
        ...sortOption,
        [key]: {
          [type]: value,
          ["patient_name"]: "",
        },
      });
    } else if (type == "patient_name") {
      dateSort = "";
      patientSort = value;
      setSortOption({
        ...sortOption,
        [key]: {
          [type]: value,
          ["date"]: "",
        },
      });
    }
    dispatch(
      getFaxLogs({
        patient_id: patient_id,
        user_id: user_id,
        direction: key,
        dateSort,
        patientSort,
      })
    );
  };

  const fetchApiDetail = () => {
    if (!fax_logs || !fax_logs.length) {
      dispatch(clearFax());
    }
  };

  const faxSend = async () => {
    if (fax_id) {
      setEnableError(false);
      const formData = new FormData();
      formData.append("fax_to", faxValue);
      formData.append("user_id", user_id);
      formData.append("pcp", pcp);
      formData.append("rp", referring);
      await dispatch(sendFax(fax_id, formData));
      setMessage("Send Fax Successfully!");
      setFaxValue("");

    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const sendEmail = () => {
    if (fax_id) {
      const data = {
        email: emailValue,
      };
      if (!validateSteps(data, [{ value: "email", type: "email" }])) {
        setEnableErrorEmail(true);
        return;
      } else {
        setEnableErrorEmail(false);
        const formData = new FormData();
        formData.append("email_address", emailValue);
        dispatch(emailFax(fax_id, formData));
        setMessage("Send Email Successfully!");
        setEmailValue("");
      }
    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const saveFaxToDocumentRow = async (id, typeId) => {
    const formData = new FormData();
    formData.append(
      "type",
      typeComponent && typeComponent[typeId] && typeComponent[typeId].id
    );
    formData.append("patient_id", search && search[id] && search[id].id);
    const save = await dispatch(saveFaxToDocument(fax_id, formData));
    if (save) {
      fetchAPI();
      setSearch({});
      setTypeComponent({});
    }
  };

  const sendPrint = async () => {
    if (fax_id) {
      dispatch(printFax(fax_id));
    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const checkPrint = async () => {
    const base64 = await pdf2base64(print_fax.url);
    setMessage("Print Successfully!");
    setIsToastr(true);
    setPrintLink(base64);
    await dispatch(reset_fax_scan_document_log());
    setTimeout(() => {
      setIsToastr(false);
      setMessage(null);
    }, 2000);
  };

  useEffect(() => {
    if (print_fax && Object.keys(print_fax).length > 0) {
      checkPrint();
    }
  }, [print_fax]);

  const showToast = () => {
    return (
      <ToastMessage
        type="Faxing Module"
        status={status ? "success" : "failed"}
        msg={message ? message : "Update Successfully!"}
      />
    );
  };

  useEffect(() => {
    if (detail_fax_logs) {
      setReset(true);
    }
  }, [detail_fax_logs]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const handleSearch = (e, index) => {
    setSearch({ ...search, [index]: { value: e.target.value } });
    setOpen(true);
  };
  const selectTypeComponent = (value, id) => {
    setTypeComponent({
      ...typeComponent,
      [`${id}`]: { value: value, id: value },
    });
  };

  const handleSearchSelect = (row) => {
    setSearch({
      ...search,
      [activeRow]: {
        value: `${row.first_name || ""} ${row.last_name || ""} - ${
          row.date_of_birth && moment(row.date_of_birth).format("MM/DD/YYYY")
        }`,
        id: row.id,
      },
    });
    setOpen(false);
  };

  const clickAccept = () => {
    if (
      activeRow >= 0 &&
      fax_id &&
      typeComponent &&
      typeComponent[fax_id] &&
      typeComponent[fax_id]["id"] &&
      search &&
      search[activeRow] &&
      search[activeRow]["value"]
    ) {
      saveFaxToDocumentRow(activeRow, fax_id);
    } else {
      alert("please set all info");
    }
  };
  useEffect(() => {
    const arrSender = [];
    const arrRecipi = [];
    const arrPatient = [];
    const arrType = [];

    setTableLoad(fax_logs);
    if (fax_logs) {
      fetchApiDetail();
      var state = {};
      var arrTypeComponent = {};
      fax_logs.map((r, index) => {
        if (r.type) {
          arrTypeComponent = {
            ...arrTypeComponent,
            [`${r.id}`]: {
              value: r.type?.category,
              id: r.type?.id,
            },
          };
        }
        if (r.patient) {
          state = {
            ...state,
            [index]: {
              value: `${r.patient.first_name || ""} ${
                r.patient.last_name || ""
              }`,
              id: r.patient.id,
            },
          };
          setSearch(state);
        }
        if (r.user && r.user.id) {
          if (
            arrSender &&
            !arrSender.some((check) => check.id && check.id === r.user.id)
          ) {
            arrSender.push(r.user);
          }
        }
        if (r.pcp && r.pcp.id) {
          if (
            arrRecipi &&
            !arrRecipi.some((check) => check.id && check.id === r.pcp.id)
          ) {
            arrRecipi.push(r.pcp);
          }
        }
        if (r.patient && r.patient.id) {
          if (
            arrPatient &&
            !arrPatient.some((check) => check.id && check.id === r.patient.id)
          ) {
            arrPatient.push(r.patient);
          }
        }
        if (r.type) {
          if (arrType && !arrType.some((check) => check === r.type?.id)) {
            arrType.push(r.type);
          }
        }
      });
    }
    setTypeComponent(arrTypeComponent);
    setType(arrType);
    setSender(arrSender);
    setRecipi(arrRecipi);
    setPatient(arrPatient);
  }, [fax_logs]);

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
      setTimeout(() => {
        setPrintLink("");
      }, 1000);
    }
  }, [printLink]);

  let printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize),
              byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
  
          byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    const contentType = "application/pdf",
    blob = b64toBlob(b64, contentType),
    blobUrl = URL.createObjectURL(blob);

    console.dir('new print');
    var iframe = null;

    iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        // setTimeout(iframe.contentWindow.print, 500);
        iframe.contentWindow.print();
      }, 500);
    };
  
    iframe.src = blobUrl;
  }

  useEffect(() => {
    if (toastAction) {
      setIsToastr(true);
      setTimeout(() => {
        dispatch(set_default_toast());
        setIsToastr(false);
      }, 2000);
    }
  }, [toastAction]);

  useEffect(() => {
    if (activeTab == "incoming-fax") {
      if (types && types.incoming && types.incoming.length > 0 && fax_logs) {
        const arrClone = [...fax_logs];
        let arrFilter = [];
        arrFilter = arrClone.filter((r) => {
          if (r.type && r.type.category && r.type.category == types.incoming) {
            return r;
          }
        });
        setTableLoad([...arrFilter]);
      } else if (
        (types && types.incoming && types.incoming.length <= 0) ||
        !types.incoming
      ) {
        setTableLoad([...fax_logs]);
      }
    } else {
      if (types && types.outgoing && types.outgoing.length > 0 && fax_logs) {
        const arrClone = [...fax_logs];
        let arrFilter = [];
        arrFilter = arrClone.filter((r) => {
          if (r.type && r.type.category && r.type.category == types.outgoing) {
            return r;
          }
        });
        setTableLoad([...arrFilter]);
      } else if (
        (types && types.outgoing && types.outgoing.length <= 0) ||
        !types.outgoing
      ) {
        setTableLoad([...fax_logs]);
      }
    }
  }, [types, fax_logs, activeTab]);

  useEffect(() => {
    fetchAPI();
    setActiveRow(-1);
    setFax_id(-1);
    setActiveDelete(-1);
  }, [activeTab]);
  // incomming
  useEffect(() => {
    let filterFax = [];
    if (selectType && !selectPatient && !selectDateIncom) {
      filterFax = fax_logs.filter((items) => {
        return ` ${items.type?.category} `
          .toLowerCase()
          .includes(` ${selectType} `.toLowerCase());
      });
      setTableLoad(filterFax);
    } else if (selectPatient && !selectType && !selectDateIncom) {
      filterFax = fax_logs.filter((items) => {
        return ` ${items.patient_id} `
          .toLowerCase()
          .includes(` ${selectPatient} `.toLowerCase());
      });
      setTableLoad(filterFax);
    } else if (!selectPatient && !selectType && selectDateIncom) {
      filterFax = fax_logs.filter((items) => {
        return `${items.created_at}`
          .toLowerCase()
          .includes(`${selectDateIncom}`.toLowerCase());
      });
      setTableLoad(filterFax);
    } else if (selectPatient && selectType && !selectDateIncom) {
      filterFax = fax_logs.filter((items) => {
        return (
          ` ${items.type?.category} `
            .toLowerCase()
            .includes(` ${selectType} `.toLowerCase()) &&
          ` ${items.patient_id} `
            .toLowerCase()
            .includes(` ${selectPatient} `.toLowerCase())
        );
      });
      setTableLoad(filterFax);
    } else if (selectPatient && !selectType && selectDateIncom) {
      filterFax = fax_logs.filter((items) => {
        return (
          `${items.created_at}`
            .toLowerCase()
            .includes(`${selectDateIncom}`.toLowerCase()) &&
          ` ${items.patient_id} `
            .toLowerCase()
            .includes(` ${selectPatient} `.toLowerCase())
        );
      });
      setTableLoad(filterFax);
    } else if (!selectPatient && selectType && selectDateIncom) {
      filterFax = fax_logs.filter((items) => {
        return (
          ` ${items.type?.category} `
            .toLowerCase()
            .includes(` ${selectType} `.toLowerCase()) &&
          `${items.created_at}`
            .toLowerCase()
            .includes(`${selectDateIncom}`.toLowerCase())
        );
      });
      setTableLoad(filterFax);
    } else if (selectType && selectPatient && selectDateIncom) {
      filterFax = fax_logs.filter((items) => {
        return (
          ` ${items.type?.category} `
            .toLowerCase()
            .includes(` ${selectType} `.toLowerCase()) &&
          ` ${items.patient_id} `
            .toLowerCase()
            .includes(` ${selectPatient} `.toLowerCase()) &&
          `${items.created_at}`
            .toLowerCase()
            .includes(`${selectDateIncom}`.toLowerCase())
        );
      });
      setTableLoad(filterFax);
    } else if (!selectType && !selectPatient && !selectDateIncom) {
      setTableLoad(fax_logs);
    }
  }, [selectType, selectPatient, selectDateIncom]);
  // outcoming
  useEffect(() => {
    let filterFax = [];
    if (selectRecipi && !selectSender && !selectDate) {
      filterFax = fax_logs.filter((items) => {
        return ` ${items.pcp_id} `
          .toLowerCase()
          .includes(` ${selectRecipi} `.toLowerCase());
      });
      setTableLoad(filterFax);
    } else if (selectSender && !selectRecipi && !selectDate) {
      filterFax = fax_logs.filter((items) => {
        return ` ${items.user.id} `
          .toLowerCase()
          .includes(` ${selectSender} `.toLowerCase());
      });
      setTableLoad(filterFax);
    } else if (!selectSender && !selectRecipi && selectDate) {
      filterFax = fax_logs.filter((items) => {
        return `${items.created_at}`
          .toLowerCase()
          .includes(`${selectDate}`.toLowerCase());
      });
      setTableLoad(filterFax);
    } else if (selectSender && selectRecipi && !selectDate) {
      filterFax = fax_logs.filter((items) => {
        return (
          ` ${items.pcp_id} `
            .toLowerCase()
            .includes(` ${selectRecipi} `.toLowerCase()) &&
          ` ${items.user.id} `
            .toLowerCase()
            .includes(` ${selectSender} `.toLowerCase())
        );
      });
      setTableLoad(filterFax);
    } else if (selectSender && !selectRecipi && selectDate) {
      filterFax = fax_logs.filter((items) => {
        return (
          `${items.created_at}`
            .toLowerCase()
            .includes(`${selectDate}`.toLowerCase()) &&
          ` ${items.user.id} `
            .toLowerCase()
            .includes(` ${selectSender} `.toLowerCase())
        );
      });
      setTableLoad(filterFax);
    } else if (!selectSender && selectRecipi && selectDate) {
      filterFax = fax_logs.filter((items) => {
        return (
          ` ${items.pcp_id} `
            .toLowerCase()
            .includes(` ${selectRecipi} `.toLowerCase()) &&
          `${items.created_at}`
            .toLowerCase()
            .includes(`${selectDate}`.toLowerCase())
        );
      });
      setTableLoad(filterFax);
    } else if (selectRecipi && selectSender && selectDate) {
      filterFax = fax_logs.filter((items) => {
        return (
          ` ${items.pcp_id} `
            .toLowerCase()
            .includes(` ${selectRecipi} `.toLowerCase()) &&
          ` ${items.user.id} `
            .toLowerCase()
            .includes(` ${selectSender} `.toLowerCase()) &&
          `${items.created_at}`.includes(`${selectDate}`)
        );
      });
      setTableLoad(filterFax);
    } else if (!selectRecipi && !selectSender && !selectDate) {
      setTableLoad(fax_logs);
    }
  }, [selectRecipi, selectSender, selectDate]);

  // const sortedTableLoad = useMemo(() => {
  //   if (!tableLoad) {
  //     return [];
  //   }
  //   const sortOpt =
  //     activeTab === "incoming-fax" ? sortOption.incoming : sortOption.outgoing;
  //   return tableLoad.sort(sortByDate(sortOpt));
  // }, [sortOption, tableLoad, activeTab]);

  if (!isShow) {
    return null;
  }

  const DeleteApi = async () => {
    if (fax_id) {
      if (!confirmDelete) {
        setConfirmDelete(true);
        return;
      }

      await dispatch(deleteFaxLog(fax_id));
      setConfirmDelete(false);
      setMessage("Delete Fax Log Successfully!");
      fetchAPI();
      if (fax_id === activeDelete) {
        setFax_id(-1);
      }
      setActiveDelete(-1);
    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  return (
    <>
      <div ref={ref} className={`resourceContainer ${isShow ? "show" : ""}`}>
        {isToastr && !errorRest && showToast()}
        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          <SidebarTitle
            icon={<Icon type="printer" theme="twoTone" twoToneColor="#6929C4"/>}
            title={<span className="text-uppercase">{title}</span>}
          />

          <div
            className="resourceContent fix-icon"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingBottom: "2rem",
              height: "calc(100% - 70px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {activeTab === "incoming-fax" && (
              <div
                className="resourceInfo d-flex tableHeader sortableHeader"
                style={{
                  alignItems: "flex-end",
                  background: "#f4f7fb",
                  borderBottom: "1px solid #dfe3e6",
                }}
              >
                <div
                  className={`th sortable ${
                    !sortOption.incoming.date ? "hide-icon" : ""
                  }`}
                  style={{
                    width: "20%",
                    minWidth: "20%",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <b
                    style={{
                      width: "100%",
                      minWidth: "20%",
                      borderBottom: "none",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    Date
                  </b>
                  <SortingArrowIcon
                    sort={sortOption.incoming.date}
                    handleSortASC={() =>
                      handleSortOption("incoming", "date", "asc")
                    }
                    handleSortDESC={() =>
                      handleSortOption("incoming", "date", "desc")
                    }
                  />
                </div>
                <div
                  className={`th sortable ${
                    !sortOption.incoming.patient_name ? "hide-icon" : ""
                  }`}
                  style={{
                    width: "38%",
                    maxWidth: "38%",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <b
                    style={{
                      width: "100%",
                      minWidth: "20%",
                      borderBottom: "none",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    Patient Name
                  </b>

                  <SortingArrowIcon
                    sort={sortOption.incoming.patient_name}
                    handleSortASC={() =>
                      handleSortOption("incoming", "patient_name", "asc")
                    }
                    handleSortDESC={() =>
                      handleSortOption("incoming", "patient_name", "desc")
                    }
                  />
                </div>
                <div
                  className={`th sortable hide-icon`}
                  style={{
                    width: "calc(42%)",
                    maxWidth: "calc(42%)",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <Select
                    placeholder="Categorization"
                    suffixIcon={<span />}
                    mod="multiple"
                    value={
                      types.incoming && types.incoming.length > 0
                        ? types.incoming
                        : undefined
                    }
                    onClear={() => {
                      setCategories({ ...types, incoming: "" });
                    }}
                    onChange={(value) => {
                      setCategories({ ...types, incoming: value });
                    }}
                    allowClear={true}
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      backgroundColor: "transparent",
                      borderBottom: 0,
                    }}
                  >
                    {orc_category.map((r) => (
                      <Option key={r.category} value={r.category}>
                        <span style={{ textTransform: "capitalize" }}>
                          {r.category.toLowerCase()}
                        </span>
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            )}
            {activeTab !== "incoming-fax" && (
              <div
                className="resourceInfo d-flex sortableHeader"
                style={{
                  alignItems: "flex-end",
                  background: "#f4f7fb",
                  borderBottom: "1px solid #dfe3e6",
                }}
              >
                <div
                  className={`th sortable ${
                    !sortOption.outgoing.date ? "hide-icon" : ""
                  }`}
                  style={{
                    width: "20%",
                    minWidth: "20%",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <b
                    style={{
                      width: "100%",
                      minWidth: "20%",
                      borderBottom: "none",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    Date
                  </b>

                  <SortingArrowIcon
                    sort={sortOption.outgoing.date}
                    handleSortASC={() =>
                      handleSortOption("outgoing", "date", "asc")
                    }
                    handleSortDESC={() =>
                      handleSortOption("outgoing", "date", "desc")
                    }
                  />
                </div>

                <div
                  className={`th sortable ${
                    !sortOption.outgoing.patient_name ? "hide-icon" : ""
                  }`}
                  style={{
                    width: "38%",
                    maxWidth: "38%",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <b
                    style={{
                      width: "100%",
                      minWidth: "20%",
                      borderBottom: "none",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    Patient Name
                  </b>

                  <SortingArrowIcon
                    sort={sortOption.outgoing.patient_name}
                    handleSortASC={() =>
                      handleSortOption("outgoing", "patient_name", "asc")
                    }
                    handleSortDESC={() =>
                      handleSortOption("outgoing", "patient_name", "desc")
                    }
                  />
                </div>

                <div
                  className={`th sortable hide-icon`}
                  style={{
                    width: "calc(42%)",
                    maxWidth: "calc(42%)",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <Select
                    placeholder="Categorization"
                    suffixIcon={<span />}
                    mod="multiple"
                    value={
                      types.outgoing && types.outgoing.length > 0
                        ? types.outgoing
                        : undefined
                    }
                    allowClear={true}
                    onClear={() => {
                      setCategories({ ...types, outgoing: "" });
                    }}
                    onChange={(value) => {
                      // handleSortOption("outgoing", "type", value);
                      // selectTypeComponent({ ...types, outgoing: value });
                      setCategories({ ...types, outgoing: value });
                    }}
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      backgroundColor: "transparent",
                      borderBottom: 0,
                    }}
                  >
                    {orc_category.map((r) => (
                      <Option key={r.category} value={r.category}>
                        <span style={{ textTransform: "capitalize" }}>
                          {r.category.toLowerCase()}
                        </span>
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            <div className="resourceInfo d-flex" style={{ flex: 1 }}>
              {activeTab === "incoming-fax" && (
                <div
                  className="tableList"
                  ref={tableRef}
                  style={{ height: "calc(100% - 50px)", minHeight: "25vh" }}
                >
                  <ScrollWrapper css="no-padding x-hidden">
                    {(tableLoad || []).map((item, index) => (
                      <div
                        className={`tableItem yellow ${
                          activeRow === index ? "active" : ""
                        }`}
                        key={`fax-${index}`}
                        onClick={handleRow(index, item.id)}
                      >
                        <div
                          className="tableItemField"
                          style={{ width: "20%", minWidth: "20%" }}
                        >
                          <p>{moment(item.created_at).format("MM/DD/YYYY")}</p>
                        </div>
                        <div
                          className="tableItemField"
                          style={{ width: "38%" }}
                        >
                          <div style={{ paddingLeft: "0px", display: activeRow === index ? 'block' : 'none' }}>
                            <div className="patientSearch">
                              <div className="patientSearch__filter">
                                <div className="patientSearch__filter-search">
                                  <Input
                                    style={{
                                      width: "100%",
                                      textTransform: "capitalize !important",
                                    }}
                                    value={
                                      search &&
                                      search[index] &&
                                      search[index].value
                                    }
                                    placeholder={"Patient Name"}
                                    onChange={(value) => {
                                      handleSearch(value, index);
                                    }}
                                    onFocus={() => {
                                      setActiveRow(index);
                                      if (
                                        search &&
                                        search[index] &&
                                        search[index].value &&
                                        search[index].value.length > 0
                                      ) {
                                        setOpen(true);
                                      } else {
                                        setOpen(false);
                                      }
                                    }}
                                    allowClear={false}
                                  />
                                </div>
                              </div>
                              {openPanel && activeRow === index && (
                                <div
                                  style={{
                                    zIndex: 10,
                                    position: "absolute",
                                    width: 350,
                                    height: 400,
                                  }}
                                  key={`resultTable-${title}-${index}`}
                                  ref={ref1}
                                  className="fullContainer patientSearch__result"
                                >
                                  <div
                                    className="tableSection"
                                    style={{
                                      height: "100%",
                                      maxHeight: "201px",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="tableContainer">
                                      <ScrollWrapper
                                        css="no-padding x-hidden"
                                        onScrollFrame={onScroll}
                                      >
                                        <div className="tableList">
                                          {search &&
                                            (rowData || []).map(
                                              (row, index) => (
                                                <div
                                                  className={`tableItem ${
                                                    index === 0
                                                      ? "no-border"
                                                      : ""
                                                  } ${
                                                    index === activeSearch
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  key={`resultTable-${index}`}
                                                  onClick={() =>
                                                    handleSearchSelect(row)
                                                  }
                                                >
                                                  <div
                                                    className="td with-icon icon-big"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <p>{`${
                                                      row.first_name || ""
                                                    } ${
                                                      row.last_name || ""
                                                    } - ${
                                                      row.date_of_birth &&
                                                      moment(
                                                        row.date_of_birth
                                                      ).format("MM/DD/YYYY")
                                                    }`}</p>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </ScrollWrapper>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <p style={{ display: activeRow !== index ? 'block' : 'none' }}>{
                            search &&
                            search[index] &&
                            search[index].value
                          }</p>
                        </div>
                        <div
                          className="tableItemField"
                          style={{ width: "42%" }}
                        >
                          <Select
                            placeholder="Categorization"
                            suffixIcon={<ExpandSVG />}
                            value={
                              typeComponent &&
                              typeComponent[`${item.id}`] &&
                              typeComponent[`${item.id}`]["value"]
                            }
                            onChange={(value) => {
                              selectTypeComponent(value, item.id);
                            }}
                            allowClear={false}
                            style={{
                              flex: 1,
                              width: "100%",
                              maxWidth: "calc(100% - 48px)",
                              borderBottom: 0,
                              display: activeRow !== index && 'none'
                            }}
                          >
                            {orc_category.map((r) => (
                              <Option key={r.category} value={r.category}>
                                <span style={{ textTransform: "capitalize" }}>
                                  {r.category.toLowerCase()}
                                </span>
                              </Option>
                            ))}
                          </Select>

                          <p style={{
                            width: "100%",
                            maxWidth: "calc(100% - 48px)",
                            display: activeRow !== index ? 'block' : 'none'
                          }}>{
                            typeComponent &&
                            typeComponent[`${item.id}`] &&
                            typeComponent[`${item.id}`]["value"]
                          }</p>
                          
                          <Icon
                            className="action"
                            type="cloud-upload"
                            onClick={() => saveFaxToDocumentRow(index, item.id)}
                          />

                          <DeleteIcon
                            icon="can"
                            selected={activeDelete === item.id}
                            handleClick={() => {
                              if (activeDelete !== item.id) {
                                setActiveDelete(item.id);
                              } else {
                                DeleteApi()
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    {emptyRows.map((item, index) => (
                      <div
                        className="tableItem"
                        key={`chart-empty-table-${index}`}
                      >
                        <div
                          className="tableItemField"
                          style={{ width: "30%", minWidth: "30%" }}
                        />
                        <div
                          className="tableItemField"
                          style={{ width: "40%" }}
                        />
                        <div
                          className="tableItemField"
                          style={{ width: "30%", paddingRight: "10px" }}
                        />
                      </div>
                    ))}
                  </ScrollWrapper>
                </div>
              )}
              {activeTab !== "incoming-fax" && (
                <div
                  className="tableList"
                  ref={tableRef}
                  style={{ height: "calc(100% - 50px)", minHeight: "25vh" }}
                >
                  <ScrollWrapper css="no-padding x-hidden">
                    {(tableLoad || []).map((item, index) => (
                      <div
                        className={`tableItem yellow ${
                          activeRow === index ? "active" : ""
                        }`}
                        onClick={handleRow(index, item.id)}
                        key={`fax-${index}`}
                      >
                        <div
                          className="tableItemField"
                          style={{ width: "20%", minWidth: "20%" }}
                        >
                          <p>{moment(item.created_at).format("MM/DD/YYYY")}</p>
                        </div>
                        <div
                          className="tableItemField"
                          style={{ width: "38%" }}
                        >
                          <div style={{ paddingLeft: "0px", display: activeRow !== index && 'none' }}>
                            <div className="patientSearch">
                              <div className="patientSearch__filter">
                                <div className="patientSearch__filter-search">
                                  <Input
                                    style={{
                                      width: "100%",
                                      textTransform: "capitalize !important",
                                    }}
                                    value={
                                      search &&
                                      search[index] &&
                                      search[index].value
                                    }
                                    placeholder={"Patient Name"}
                                    onChange={(value) => {
                                      handleSearch(value, index);
                                    }}
                                    onFocus={() => {
                                      setActiveRow(index);
                                      if (
                                        search &&
                                        search[index] &&
                                        search[index].value &&
                                        search[index].value.length > 0
                                      ) {
                                        setOpen(true);
                                      } else {
                                        setOpen(false);
                                      }
                                    }}
                                    allowClear={false}
                                  />
                                </div>
                              </div>
                              {openPanel && activeRow === index && (
                                <div
                                  style={{
                                    zIndex: 10,
                                    position: "absolute",
                                    width: 350,
                                    height: 400,
                                  }}
                                  key={`resultTable-${title}-${index}`}
                                  ref={ref1}
                                  className="fullContainer patientSearch__result"
                                >
                                  <div
                                    className="tableSection"
                                    style={{
                                      height: "100%",
                                      maxHeight: "201px",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="tableContainer">
                                      <ScrollWrapper
                                        css="no-padding x-hidden"
                                        onScrollFrame={onScroll}
                                      >
                                        <div className="tableList">
                                          {search &&
                                            (rowData || []).map(
                                              (row, index) => (
                                                <div
                                                  className={`tableItem ${
                                                    index === 0
                                                      ? "no-border"
                                                      : ""
                                                  } ${
                                                    index === activeSearch
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  key={`resultTable-${index}`}
                                                  onClick={() =>
                                                    handleSearchSelect(row)
                                                  }
                                                >
                                                  <div
                                                    className="td with-icon icon-big"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <p>{`${
                                                      row.first_name || ""
                                                    } ${
                                                      row.last_name || ""
                                                    } - ${
                                                      row.date_of_birth &&
                                                      moment(
                                                        row.date_of_birth
                                                      ).format("MM/DD/YYYY")
                                                    }`}</p>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </ScrollWrapper>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <p style={{ display: activeRow !== index ? 'block' : 'none' }}>{
                            search &&
                            search[index] &&
                            search[index].value
                          }</p>
                        </div>
                        <div
                          className="tableItemField"
                          style={{ width: "42%" }}
                        >
                          <Select
                            placeholder="Categorization Report"
                            suffixIcon={<ExpandSVG />}
                            value={
                              typeComponent &&
                              typeComponent[`${item.id}`] &&
                              typeComponent[`${item.id}`]["value"]
                            }
                            onChange={(value) => {
                              selectTypeComponent(value, item.id);
                            }}
                            allowClear={false}
                            style={{
                              flex: 1,
                              width: "100%",
                              maxWidth: "calc(100% - 48px)",
                              borderBottom: 0,
                              display: activeRow !== index && 'none'
                            }}
                          >
                            {orc_category.map((r) => (
                              <Option key={r.category} value={r.category}>
                                <span style={{ textTransform: "capitalize" }}>
                                  {r.category.toLowerCase()}
                                </span>
                              </Option>
                            ))}
                          </Select>

                          <p style={{
                            width: "100%",
                            maxWidth: "calc(100% - 48px)",
                            display: activeRow !== index ? 'block' : 'none'
                          }}>{
                            typeComponent &&
                            typeComponent[`${item.id}`] &&
                            typeComponent[`${item.id}`]["value"]
                          }</p>

                          <Icon
                            className="action"
                            type="cloud-upload"
                            onClick={() => saveFaxToDocumentRow(index, item.id)}
                          />

                          <DeleteIcon
                            icon="can"
                            selected={activeDelete === item.id}
                            handleClick={() => {
                              if (activeDelete !== item.id) {
                                setActiveDelete(item.id);
                              } else {
                                DeleteApi()
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    {emptyRows.map((item, index) => (
                      <div
                        className="tableItem"
                        key={`chart-empty-table-${index}`}
                      >
                        <div
                          className="tableItemField"
                          style={{ width: "30%", minWidth: "30%" }}
                        />
                        <div
                          className="tableItemField"
                          style={{ width: "35%" }}
                        />
                        <div
                          className="tableItemField"
                          style={{ width: "35%", paddingRight: "10px" }}
                        />
                      </div>
                    ))}
                  </ScrollWrapper>
                </div>
              )}
            </div>

            <div
              className="resourceInfo d-flex"
              style={{ justifyContent: "flex-end", marginTop: "0.5rem" }}
            />

            <Collapse accordion x={{ marginTop: "2.5rem" }}>
              <Panel header="Fax" key="fax">
                <div
                  className="resourceInfo d-flex"
                  style={{ justifyContent: "space-between", alignItems: "flex-end" }}
                >
                  <div
                    style={{
                      flex: 1,
                      position: 'relative',
                      maxWidth: '400px'
                    }}
                    onClick={() => setOpenFax(false)}
                  >
                    <EditableFormInput
                        handleChange={(value) => {
                          setFaxValue(value);
                        }}
                        value={faxValue}
                        label={"Fax No"}
                        mask={"phone"}
                        required={true}
                        enableErrorText={enableError}
                        isError={validateField("fax_to", faxValue)}
                        helperText={"Fax No is Required!"}
                      />

                      {((existingPCP && existingPCP.fax_number)
                          || (existingProvider && existingProvider.fax_number))
                        && (
                        <div
                          style={{
                            position: 'absolute',
                            display: 'inline-flex',
                            width: '40px',
                            height: '40px',
                            zIndex: 20,
                            top: '2px',
                            right: '2px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            transform: isOpenFax ? 'rotate(180deg)' : 'rotate(0deg)'
                          }}
                          className="dropdown-button"
                          onClick={toggleOpenFax}
                        >
                          <ArrowDownSVG />
                        </div>
                      )}

                      {isOpenFax
                        && (
                          (existingPCP && existingPCP.fax_number)
                          || (existingProvider && existingProvider.fax_number))
                        && (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 20,
                            left: 0,
                            right: 0,
                            top: '42px',
                            background: '#fff',
                            border: '1px solid #ddd'
                          }}
                        >
                          {existingPCP && existingPCP.fax_number && (
                            <div
                              style={{
                                width: '100%',
                                height: '40px',
                                borderBottom: '1px solid #ddd',
                                padding: '0 14px',
                                fontSize: '14px',
                                color: '#000',
                                lineHeight: '40px',
                                cursor: 'pointer',
                                background: pcp === 'pcp' ? '#dbe3ee' : '#fff'
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setFaxValue(existingPCP.fax_number);
                                if (existingPCP.email) {
                                  setEmailValue(existingPCP.email);
                                }
                                setPCP('pcp');
                                setOpenFax(false)
                              }}
                            >
                              PCP
                            </div>
                          )}
                          {existingProvider && existingProvider.fax_number && (
                            <div
                              style={{
                                width: '100%',
                                height: '40px',
                                padding: '0 14px',
                                fontSize: '14px',
                                color: '#000',
                                lineHeight: '40px',
                                cursor: 'pointer',
                                background: pcp === 'referring_provider' ? '#dbe3ee' : '#fff'
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setFaxValue(existingProvider.fax_number);
                                if (existingProvider.email) {
                                  setEmailValue(existingProvider.email);
                                }
                                setPCP('referring_provider');
                                setOpenFax(false)
                              }}
                            >
                              Referring Provider
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                  <button
                    onClick={faxSend}
                    className="common-btn blue-btn"
                    style={{ marginLeft: "8px", border: 0 }}
                  >
                    Send
                  </button>
                </div>
              </Panel>
              <Panel header="E-Mail" key="e-mail">
                <div
                  className="resourceInfo d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      flex: 1
                    }}
                  >
                    <EditableMaterialText
                      reset={reset}
                      handleChange={(value) => {
                        setEmailValue(value);
                      }}
                      value={ selectedPatient && selectedPatient.email
                        ? selectedPatient.email
                        : emailValue
                      }
                      label={"E-Mail"}
                      required={true}
                      enableErrorText={enableErrorEmail}
                      isError={validateField("email", emailValue)}
                      helperText={"E-Mail is Required!"}
                    />
                  </div>
                  <button
                    className="common-btn blue-btn"
                    onClick={sendEmail}
                    style={{ marginLeft: "8px", border: 0 }}
                  >
                    Send
                  </button>
                </div>
              </Panel>
              <Panel header="Print" key="print">
                <div
                  className="resourceInfo d-flex"
                  style={{ alignItems: "center", justifyContent: "flex-end" }}
                  onClick={sendPrint}
                >
                  <button className="common-btn" style={{ border: 0 }}>
                    Print
                  </button>
                </div>
              </Panel>
            </Collapse>
          </div>
        </ScrollWrapper>
      </div>
    </>
  );
});

export default FaxingSidebar;
