import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { NotifySVG, AlamSVG } from '../icons/SVGIcon';

export const CustomEvents = ({ icons }) => (
  <PerfectScrollbar style={{
    height: '100%',
    textAlign: 'right'
    }}
  >
    <div style={{
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      flexWrap: 'wrap-reverse',
      minHeight: '100%'
    }}>
    
      {(icons || []).map(icon => (
        <div
          key={`event-icon-${icon.id}`}
          style={{ display: 'inline-flex' }}
        >
          <img src={icon.icon} alt="event icon" style={{ height: '18px', width: '18px' }} />
        </div>
      ))}
      {!icons && <AlamSVG />}
      {!icons && <NotifySVG />}
    </div>
  </PerfectScrollbar>
);

export default class CustomWeek extends React.Component {
  render() {
    return (
      <TimeGrid min='6:00am' max='6:00pm' timeslots={4} />
    )
  }
}
