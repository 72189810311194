import React, { useState, useEffect } from "react";
import "./style.scss";
import { BloodVessel, NodeIcon } from "../icons/BloodVessel";

const VesselBody = (props) => {
  const onChangeState = (field, state) => {
    props.onChangeState(field, state);
  };

  return (
    <div className={"blood-veseel-wrapper"}>
      <div className="bodyIcon">
        <BloodVessel />
      </div>
      {props.dots.map((node, index) => (
        <StateNode
          node={node}
          key={`state-node-${index}`}
          onChangeState={onChangeState}
          isLocked={props.isLocked}
        />
      ))}
    </div>
  );
};

const StateNode = (props) => {
  const [node, setNode] = useState(undefined);
  useEffect(() => {
    setNode(props.node);
  }, [props.node]);
  const onClickListItem = (newState) => {
    let node_ = { ...node };
    node_["state"] = newState;
    setNode(node_);
    props.onChangeState(node.field, newState);
  };
  if (!node) return <></>;

  return (
    <div
      className="nodeState"
      style={{
        left: node.left ? node.left + "px" : "auto",
        top: node.top ? node.top + "%" : "auto",
        right: node.right ? node.right + "px" : "auto",
        bottom: node.bottom ? node.bottom + "%" : "auto",
      }}
      title={node.field}
    >
      <div className={`nodeState__text ${props.isLocked === 0 && 'not-locked'}`}>
        {`${node.label}`}

        {["0", "1", "2", "D"].includes(node.state) && (
          <div
            className="nodeState__active_dropdown"
            style={{
              right: node.rightDir ? "-1px" : "unset",
              left: node.rightDir ? "unset" : "-1px",
              transform: node.rightDir
                ? "translateX(100%)"
                : "translateX(-100%)",
            }}
          >
            <p className="active">{node.state}</p>
          </div>
        )}

        <div
          className="nodeState__dropdown"
          style={{
            right: node.rightDir ? "-1px" : "unset",
            left: node.rightDir ? "unset" : "-1px",
            transform: node.rightDir ? "translateX(100%)" : "translateX(-100%)",
          }}
        >
          {["0", "1", "2", "D"].map((state, index2) => (
            <p
              onClick={() => onClickListItem(state)}
              key={`nodestate-dropdown-${index2}`}
              className={state === node.state ? "active" : ""}
            >
              {state}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VesselBody;
