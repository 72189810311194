import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";

import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import { validateField } from "../../../../services/validations";
import ToastMessage from "../../../../common/components/Toast";
import YesNo from "../../../../common/components/YesNo/YesNoOne";
import {
  saveDetailUsers,
  disableUser,
  getUsers,
  enableUser,
} from "../../../../store/actions";
import EditableInput from "../../../../common/components/EditableText/EditableFormInput";
import AddUserDetails from "./AddUserDetail";

const InActiveSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  usersDetail,
}, ref) => {
  const [isToastr, setIsToastr] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [isEnable, setIsEnable] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [salaryOption, setSalaryOption] = useState("Salary");

  const [isReset, setIsReset] = useState(false);
  const [state, setState] = useState({});

  const dispatch = useDispatch();

  const openAddForm = () => {
    setIsAddForm(true);
  };
  const closeAddForm = () => {
    setIsAddForm(false);
  };

  const fetchState = () => {
    setState({
      id: usersDetail.id,
      isProvider:
        usersDetail.is_provider === -1 ? null : usersDetail.is_provider,
      isPCM: usersDetail.is_pcm === -1 ? null : usersDetail.is_pcm,
      isAdmin: usersDetail.is_admin === -1 ? null : usersDetail.is_admin,
      isSupport: usersDetail.is_support === -1 ? null : usersDetail.is_support,
      isTechnician:
        usersDetail.is_technician === -1 ? null : usersDetail.is_technician,
      points: usersDetail.meta ? usersDetail.meta.points : "",
      userName: usersDetail.username,
      email: usersDetail.email,
      name: usersDetail.name,
      login: usersDetail.username,
      salary: usersDetail.meta && usersDetail.meta.salary,
      exemptions: usersDetail.meta && usersDetail.meta.exemption,
      hours: usersDetail.vacation_hours_per_year,
      hire: usersDetail.meta && usersDetail.meta.hire_date,
      end: usersDetail.meta && usersDetail.meta.end_date,
      ops_provider_id: usersDetail.ops_provider_id,
      npi: usersDetail.npi,
      dea: usersDetail.dea,
    });
    setIsEnable(!!usersDetail.status);
  };

  const saveAll = async (value) => {
    const saveSuccess = await dispatch(
      saveDetailUsers(value.id, {
        name: value.name,
        username: value.login,
        password: value.password,
        salary: value.salary,
        exemption: value.exemptions,
        email: value.email,
        points: value.points,
        vacation_hours: value.hours,
        hire_date: value.hire,
        end_date: value.end,
        is_provider: value.isProvider,
        is_pcm: value.isPCM,
        is_admin: value.isAdmin,
        is_support: value.isSupport,
        is_technician: value.isTechnician,
        ops_provider_id: value.ops_provider_id,
        npi: value.npi,
        dea: value.dea,
      })
    );
    const loadListSuccess = await dispatch(getUsers());
    if (saveSuccess && loadListSuccess) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const changeHandler = useCallback(
    debounce((value) => saveAll(value), 400),
    []
  );

  const handleChange = (type, value) => {
    const newValue = {
      ...state,
      [type]: value,
    };
    setState(newValue);
    if (!isAddForm) {
      isReset && changeHandler(newValue);
    }
  };

  const handleChangeOption = (key, value) => {
    console.dir(key);
    if (key === "isProvider") {
      handleChange("isProvider", value);
    } else if (key === "isPCM") {
      handleChange("isPCM", value);
    } else if (key === "isTechnician") {
      handleChange("isTechnician", value);
    } else if (key === "isSupport") {
      console.dir('change support');
      handleChange("isSupport", value);
    } else {
      handleChange("isAdmin", value);
    }
  };

  const disableClickUser = async () => {
    if (Object.keys(usersDetail).length > 0) {
      const disableSuccess = await dispatch(disableUser(usersDetail.id));
      const loadListSuccess = await dispatch(getUsers());
      if (disableSuccess && loadListSuccess) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          handleCloseSidebar();
          setState({});
        }, 1000);
      }
    }
  };

  const enableClickUser = async () => {
    if (Object.keys(usersDetail).length > 0) {
      const enableSuccess = await dispatch(enableUser(usersDetail.id));
      const loadListSuccess = await dispatch(getUsers());
      if (enableSuccess && loadListSuccess) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          handleCloseSidebar();
          setState({});
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (usersDetail && usersDetail.id !== state.id) {
      fetchState();
      setReset(true);
      setIsReset(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
  }, [usersDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  if (!isShow || !usersDetail) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      {isToastr && (
        <ToastMessage
          type="User"
          status="success"
          msg="Changed Successfully!"
        />
      )}
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={<MaleSVG />}
          title={<span className="text-uppercase">{usersDetail.name}</span>}
          subTitle=""
        />
        {isAddForm && <AddUserDetails closeAddForm={closeAddForm} />}
        {!isAddForm && (
          <React.Fragment>
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
              }}
            >
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"Name"}
                  value={state.name}
                  handleChange={(value) => {
                    handleChange("name", value);
                  }}
                  required={true}
                  isError={validateField("text", state.name)}
                  helperText={"Name is Required!"}
                />
              </div>
            </div>
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
              }}
            >
              <div
                className="resourceInfo d-flex align-items-end required-field"
                style={{ marginTop: "0.5rem" }}
              >
                <EditableInput
                  label={"Salary/Wage"}
                  value={salaryOption}
                  type={"check"}
                  handleChange={(value) => {
                    setSalaryOption(value);
                  }}
                  options={[
                    { value: "Salary", label: "Salary" },
                    { value: "Wage", label: "Wage" },
                  ]}
                  optionKey={"label"}
                  valueKey={"label"}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={salaryOption}
                  value={state.salary}
                  prefix={"$"}
                  handleChange={(value) => {
                    handleChange("salary", value);
                  }}
                  required={true}
                  isError={validateField("text", state.salary)}
                  helperText={"Salary/Wage is Required!"}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"Points"}
                  value={state.points}
                  handleChange={(value) => {
                    handleChange("points", value);
                  }}
                  required={true}
                  isError={validateField("text", state.points)}
                  helperText={"Points is Required!"}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"Email"}
                  value={state.email}
                  handleChange={(value) => {
                    handleChange("state", value);
                  }}
                  required={true}
                  isError={validateField("email", state.email)}
                  helperText={"Email is Required!"}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"Exemptions"}
                  value={state.exemptions}
                  handleChange={(value) => {
                    handleChange("exemptions", value);
                  }}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"Vacation Hours/yr"}
                  value={state.hours}
                  handleChange={(value) => {
                    handleChange("hours", value);
                  }}
                  unit={"hrs"}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"Hire Date"}
                  value={state.hire}
                  handleChange={(value) => {
                    handleChange("hire", value);
                  }}
                  type={"date"}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"End Date"}
                  value={state.end}
                  handleChange={(value) => {
                    handleChange("end", value);
                  }}
                  type={"date"}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"Login"}
                  value={state.login}
                  handleChange={(value) => {
                    handleChange("login", value);
                  }}
                  required={true}
                  isError={validateField("text", state.userName)}
                  helperText={"User name is required"}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableInput
                  label={"Password"}
                  value={"*******"}
                  handleChange={(value) => {
                    handleChange("password", value);
                  }}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end">
                <EditableInput
                  label={"Confirm Password"}
                  value={state.confirm_password}
                  value={state.confirm_password}
                  handleChange={(value) => {
                    handleChange("confirm_password", value);
                  }}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end">
                <EditableInput
                    label={"OPS Provider ID"}
                    value={state.ops_provider_id}
                    handleChange={(value) => {
                      handleChange("ops_provider_id", value);
                    }}
                />
              </div>
              {state.isProvider && (
                <div>
                  <div className="resourceInfo d-flex align-items-end">
                    <EditableInput
                        label={"DEA"}
                        value={state.dea}
                        handleChange={(value) => {
                          handleChange("dea", value);
                        }}
                    />
                  </div>
                  <div className="resourceInfo d-flex align-items-end">
                    <EditableInput
                        label={"NPI"}
                        value={state.npi}
                        handleChange={(value) => {
                          handleChange("npi", value);
                        }}
                    />
                  </div>
                </div>
              )}
              <hr />
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "37%" }}>Provider: </b>
                <YesNo
                  option={state.isProvider}
                  setOption={(value) => {
                    handleChangeOption("isProvider", value);
                  }}
                />
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "37%" }}>Technician: </b>
                <YesNo
                  option={state.isTechnician}
                  setOption={(value) => {
                    handleChangeOption("isTechnician", value);
                  }}
                />
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "37%" }}>PCM: </b>
                <YesNo
                  option={state.isPCM}
                  setOption={(value) => {
                    handleChangeOption("isPCM", value);
                  }}
                />
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "37%" }}>Admin: </b>
                <YesNo
                  option={state.isAdmin}
                  setOption={(value) => {
                    handleChangeOption("isAdmin", value);
                  }}
                />
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "37%" }}>Support: </b>
                <YesNo
                  option={state.isSupport}
                  setOption={(value) => {
                    handleChangeOption("isSupport", value);
                  }}
                />
              </div>
            </div>
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                flexWrap: "wrap",
              }}
            >
              <button
                className="outlined-btn"
                style={{ marginTop: "0.5rem" }}
                onClick={openAddForm}
              >
                Add New User
              </button>
              {Object.keys(usersDetail).length > 0 && !isEnable ? (
                <button
                  onClick={enableClickUser}
                  className="outlined-btn"
                  style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}
                >
                  Enable User
                </button>
              ) : (
                <button
                  onClick={disableClickUser}
                  className="outlined-btn"
                  style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}
                >
                  Delete User
                </button>
              )}
            </div>
          </React.Fragment>
        )}
      </ScrollWrapper>
    </div>
  );
});

export default InActiveSidebar;
