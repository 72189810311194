import React from 'react';
import { Select } from 'antd';
import { ExpandSVG } from '../../../../common/components/icons/SVGIcon';

const { Option } = Select;

const SchedulesFilter = ({}) => {
  return (
    <div className="filterContainer">
      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: '260px', marginLeft: '0' }}>
          <Select
            placeholder="Filter by Employee Class..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
          >
            <Option value="code1">Class 1</Option>
            <Option value="code2">Class 2</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default SchedulesFilter;
