import React, { useEffect, useState } from "react";
import moment from "moment";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { useSelector, useDispatch } from "react-redux";
import ExaminationVitals from "./ExaminationVitals";
import { save_chief_complaint } from "../../../../store/actions";
import Cookies from "universal-cookie";
import { Icon } from "antd";

const cookies = new Cookies();
let timer = null;
const ROSSidebar = React.forwardRef(({ onChangeData, isShow, handleCloseSidebar, isLocked, sidebarIcon }, ref) => {
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const fullName = (
    patientResource?.first_name +
    " " +
    patientResource?.last_name
  ).toUpperCase();
  const age = patientResource?.date_of_birth
    ? "(" + moment().diff(patientResource?.date_of_birth, "years") + " yo)"
    : "";
  const procedure_id = cookies.get("current_appointment_id");
  const vitals = useSelector((state) => state.patientExamination.vitals);

  const [chiefComplaint, setChiefComplaint] = useState("");

  const chiefComplaintAPI = useSelector(
    (state) => state.patientExamination.chiefComplaint
  );

  useEffect(() => {
    setChiefComplaint(chiefComplaintAPI);
  }, [chiefComplaintAPI]);

  const dispatch = useDispatch();

  const onchangeChief = (value) => {
    setChiefComplaint(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(save_chief_complaint(procedure_id, { chief_complaint: value }));
    }, 500);
  };

  if (!isShow) {
    return null;
  }
  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
          title={<span className="text-uppercase">CHIEF COMPLAINT</span>}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{
            paddingLeft: "24px",
            paddingRight: "8px",
            marginBottom: "0.5rem",
          }}
        >
          <div className={`resourceInfo d-flex ${isLocked === 1 ? "disabled" : ""}`}>
            <EditableFormInput
              label={"Chief Complaint"}
              value={chiefComplaint}
              handleChange={(value) => onchangeChief(value)}
              disabled={isLocked}
            />
          </div>
        </div>

        <SidebarTitle
          icon={<Icon type="interaction" theme="twoTone" twoToneColor="#740937"/>}
          title={<span className="text-uppercase">Vital Signs</span>}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ paddingLeft: "24px", paddingRight: "8px" }}
        >
          <ExaminationVitals
            data={vitals}
            onChangeData={(data) => onChangeData("vitals", data)}
            isLocked={isLocked}
          />
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default ROSSidebar;
