import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import './style.scss'

const YesNo = ({ title, subStatus, option, setOption, horizontal }) => {
  const [isYes, setYes] = useState(-1);

  useEffect(() => {
    if (option === 0 || option === 1) {
      setYes(option)
    }
  }, [option]);

  const handleCheckBox = (val) => {
    setYes(val)
    if (setOption) {
      setOption(val)
    }
  }

  return (
    <div style={{ minWidth: horizontal ? '70px' : '115px' }}>
      <Checkbox style={{ marginLeft: horizontal ? '8px' : 0 }} checked={isYes === 1} onChange={() => handleCheckBox(1)}>Yes</Checkbox>
      <Checkbox checked={isYes === 0} onChange={() => handleCheckBox(0)}>No</Checkbox>
    </div>
  )
};

export default YesNo;
