import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import ScrollWrapper from "../ScrollBar";
import Loading from "../Loading";
import { SearchSVG } from "../icons/SVGIcon";
import DeleteIconButton from "../icons/DeleteIconButton";
import { useOutsideDetect } from "../../utils";
import "./style.scss";

const { Search } = Input;

const MedicationAutoComplete = ({
  rows,
  options,
  query,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keyLabel,
  keyValue,
  keyType,
  selectItem,
  removeItem,
  disableOptions,
  onclickRow,
  activeIndex,
  isSelectRow,
  handleValueSelect,
  valueSelected,
  minRows
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [openResult, setOpenResult] = useState(false);
  const [removeable, setRemoveable] = useState(-1);

  const data = rows ? rows : [];
  const optionData = options ? options : [];
  const minRowCount = minRows ? minRows : 4;
  let emptyRows = [];

  if (data.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - data.length).fill({ label: "" });
  }

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setSelected(row[keyLabel]);
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keyLabel]);
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (selectItem) {
      selectItem(selected, selectedValue, selectedType);
    } else {
      if (addItem) {
        addItem();
      }
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target.value);
    if (handleSearch) {
      handleSearch(e.target.value);
    }
  };

  const clickHandle = (row, index) => {
    if (onclickRow) {
      onclickRow(row, index);
    }
  };

  return (
    <div className="atuoComplete">
      <div className="atuoComplete__filter">
        <div
          ref={ref}
          className={`atuoComplete__filter-search ${
            disableSearchIcon ? "disable-icon" : ""
          }`}
        >
          <Search
            value={search}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{ width: "100%" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        <div className="atuoComplete__filter-action">
          <button
            className="atuoComplete__filter-add"
            onClick={addSelectedItem}
          >
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
        {!disableOptions && openResult && !!search && (
          <div ref={ref1} className="atuoComplete__filter-result">
            <div className="atuoComplete__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper css="no-padding">
                {!!optionData.length &&
                  optionData.map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`empty-${title}-${index}`}
                      onClick={() => handleSelectItem(row)}
                    >
                      <p style={{ paddingLeft: '28px' }}>{row[keyLabel]}</p>
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>

      <div className="atuoComplete__result">
        <div className="atuoComplete__result-list" style={{ height: (2.5 * minRowCount).toString() + 'rem' }}>
          <ScrollWrapper css="no-padding x-hidden">
            {data.map((row, index) => (
              <div
                className={`atuoComplete__result-item`}
                style={{
                  margin: 0,
                  width: "100%",
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: '32px',
                  position: 'relative',
                  backgroundColor: activeIndex === index ? "#dbe3ee" : "#fff",
                  border:
                    activeIndex === index
                      ? "1px solid #dbe3ee"
                      : "1px solid #dfe3e6",
                  borderBottom:
                    activeIndex === index
                      ? "0px solid #dbe3ee"
                      : "0px solid #dfe3e6"
                }}
                key={`value-${title}-${row.drug_name}-${index}`}
                onClick={() => clickHandle(row, index)}
              >
                <div
                  style={{
                    display: "inline-flex",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 12,
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      maxWidth: '100%'
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                    >{row.medication.drug_name} {row.dose}</div>
                  </div>
                </div>

                <div style={{ position: 'absolute', right: '12px' }}>
                  <DeleteIconButton
                    selected={removeable === index}
                    icon={'can'}
                    handleClick={() => {
                      if (removeable === index) {
                        removeItem(row, index);
                        setRemoveable(-1);
                      } else {
                        setRemoveable(index);
                      }
                    }}
                  />
                </div>
                
              </div>
            ))}
            {emptyRows.map((row, index) => (
              <div
                className="atuoComplete__result-item empty"
                key={`empty-${title}-${index}`}
              />
            ))}
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default MedicationAutoComplete;
