import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { CheckSVG, RedRequiredCircleSVG } from "../../../common/components/icons/SVGIcon";
import {
  getReminders,
  selectReminder,
  setReminderData,
  setGlobalLoading,
} from "../../../store/actions";
import useInterval from '@use-it/interval';
import { Icon } from "antd";
import DataTable from "../../../common/components/DataTable/DataTable";

const filterSuggestion = (suggestions, userInput, option) => {
  if (
    !userInput &&
    (!option || (!option.type && !option.result && !option.attempts))
  ) {
    return suggestions;
  }

  let suggestionsByResult = suggestions;
  if (option && option.result) {
    suggestionsByResult = suggestionsByResult.filter(
      (suggestion) =>
        suggestion.status &&
        suggestion.status
          .split(" ")
          .join("")
          .toLowerCase()
          === option.result.split(" ").join("").toLowerCase()
    );
  }

  if (option.type) {
    suggestionsByResult = suggestionsByResult.filter(
      (suggestion) =>
        suggestion.procedure &&
        suggestion.procedure
          .split(" ")
          .join("")
          .toLowerCase()
          .indexOf(option.type.split(" ").join("").toLowerCase()) > -1
    );
  }

  if (option.attempts) {
    suggestionsByResult = suggestionsByResult.filter(
      (suggestion) =>
        parseInt(suggestion.no_of_attempts) >= parseInt(option.attempts)
    );
  }

  return suggestionsByResult.filter(
    (suggestion) =>
      suggestion.patient_name &&
      suggestion.patient_name
        .split(" ")
        .join("")
        .toLowerCase()
        .indexOf(userInput.split(" ").join("").toLowerCase()) > -1
  );
};

const DashboardResultTable = ({
  title,
  search,
  filter,
  handleSidebar,
  handleChangeRowClick,
}) => {
  const ref = useRef(null);

  const loading = useSelector((state) => state.reminders.loadingReminders);
  const data = useSelector((state) => state.reminders.reminders);
  const resource = useSelector((state) => state.reminders.resource);

  const dispatch = useDispatch();

  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [takeCount, setTakeCount] = useState(30);
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    dispatch(setReminderData("loadingReminders", true));
    dispatch(setReminderData("resource", undefined));
    dispatch(getReminders());
  }, []);

  useEffect(() => {
    if (ref) {
      const count = Math.round(ref.current.clientHeight / 40) + 3;
      setTakeCount(count);
    }
  }, [ref]);

  useEffect(() => {
    if (!data) {
      dispatch(setReminderData("loadingReminders", true));
      dispatch(getReminders());
    }

    if (tempData != data)
    {
      setTempData(data);
    }
  }, [data]);

  useInterval(() => {
    //dispatch(getReminders());
  }, 10000);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading])

  const tableRows = useMemo(() => {
    if (tempData) {
      // setOffset(0);
      return filterSuggestion(tempData, search, filter);
    }
    return [];
  }, [tempData, search, filter]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      return tableRows.slice(0, offset + takeCount);
    }
  }, [offset, tableRows]);

  const handleRow = (index) => {
    if (index !== activeIndex) {
      setRow(index);
      if (handleChangeRowClick) {
        handleChangeRowClick(index);
      }
      handleSidebar();
      const patient_id = rowData[index].patient_id || rowData[index].id;
      dispatch(setReminderData("loadingResource", true));
      dispatch(selectReminder(patient_id, rowData[index].procedure_date));
    }
  };

  const onScroll = (values) => {
    if (values.scrollTop > offset * 40 + 80) {
      setOffset(offset + takeCount);
    }
  };

  return (
    <div className="tableContainer" ref={ref}>
      <DataTable
        title="clinic_reminders"
        labels={["Name", 'Procedure', 'Date', 'Status', 'Last Automated Time', 'No. Of Attempts']}
        widths={['23%','15.4%','15.4%','15.4%', '15.4%']}
        disableDelete={true}
        loadMore={onScroll}
        hideHeader={true}
        handleClickRow={(row, index) => {handleRow(index)}}
        columns={[
          { sortable: false, key: 'name' },
          { sortable: false, key: 'procedure' },
          { sortable: false, key: 'date' },
          { sortable: false, key: 'status' },
          { sortable: false, key: 'last_call' },
          { sortable: false, key: 'total_calls' },
        ]}
        rowData={(rowData || []).map((row, index) => {
          return {
            name: (
              <div 
                className="iconField td with-icon"
                style={{
                  display: "flex",
                  paddingLeft: "0.25rem",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {row.icon && <div className="">{row.icon}</div>}
                {!row.icon && (
                  <div className="">
                    {row.status === 'Not Confirmed' ? (
                      <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
                    ) : <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>}
                  </div>
                )}
                <p style={{ paddingLeft: "0.75rem" }} >{row.patient_name}</p>
              </div>
            ),
            procedure: row.procedure,
            date: !!row.procedure_date && moment(row.procedure_date).isValid() && moment(row.procedure_date).format("MM/DD/YYYY HH:mm"),
            status: row.status,
            last_call: row.last_automated_time && moment(row.last_automated_time).isValid() && moment(row.last_automated_time).format("MM/DD/YYYY HH:mm"),
            total_calls: row.no_of_attempts
          }
        })}
      />
      {/* <ScrollWrapper css="no-padding" onScrollFrame={onScroll}>
        <div className="tableList" style={{ minWidth: '768px' }}>
          {(rowData || []).map((row, index) => (
            <div
              className={`tableItem yellow ${
                activeIndex === index ? "active" : ""
              }`}
              key={`resultTable-${title}-${index}`}
              onClick={handleRow(index)}
            >
              <div className="nameField">
                {row.icon && <div className="iconField td with-icon">{row.icon}</div>}
                {!row.icon && (
                  <div className="iconField td with-icon">
                    {row.status === 'Not Confirmed' ? (
                      <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
                    ) : <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>}
                  </div>
                )}
                <p>{row.patient_name}</p>
              </div>
              <div className="typeField">
                <p>{row.procedure}</p>
              </div>
              <div className="dateField">
                <p>
                  {!!row.procedure_date &&
                    moment(row.procedure_date).isValid() &&
                    moment(row.procedure_date).format("MM/DD/YYYY HH:mm")}
                </p>
              </div>
              <div className="resultField">
                <p>{row.status || ""}</p>
              </div>
              <div className="statusField">
                <p>
                  {row.last_automated_time && moment(row.last_automated_time).isValid() && moment(row.last_automated_time).format("MM/DD/YYYY HH:mm")}
                </p>
              </div>
              <div className="statusField">
                <p>{row.no_of_attempts}</p>
              </div>
            </div>
          ))}
        </div>
      </ScrollWrapper> */}
    </div>
  );
};

export default DashboardResultTable;
