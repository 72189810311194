import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from "draft-js";

import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import { defaultSuggestionsFilter } from "draft-js-mention-plugin";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { text_template_procedure } from "../../../store/actions";
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from "draft-js-buttons";

import ScrollWrapper from "../../../common/components/ScrollBar";

const mentionPlugin = createMentionPlugin({
  mentionTrigger: "[",
});

const toolbarPlugin = createToolbarPlugin();

let timer = null;
const TemplateDocumentation = ({ resource }) => {
  const [editorState, setEditor] = useState(EditorState.createEmpty());
  const [checkResoure, setCheckResoure] = useState(null);
  const [answerCheck, setAnswerCheck] = useState([]);
  const [variableCheck, setVariableCheck] = useState([]);
  const [SearchVariable, setSearchVariable] = useState([]);
  const [content, setContent] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [focusEditor, setFocusEditor] = useState(false);
  const [selectVariable, setSelectVariable] = useState("");
  const dispatch = useDispatch();
  const variable = useSelector((state) => state.procedureDetail.variable);
  const detailProcedureData = useSelector(
    (state) => state.common.detailProcedureData
  );

  useEffect(() => {
    setCheckResoure(resource);
  }, [resource]);

  const onChange = (editorState) => {
    setEditor(editorState);
    let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const newContentState = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setContent(newContentState);
    if (
      detailProcedureData &&
      Object.keys(detailProcedureData).length > 0 &&
      focusEditor
    ) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          text_template_procedure(detailProcedureData?.id, {
            text_template: data,
          })
        );
      }, 500);
    }
  };

  useEffect(() => {
    if (variable) {
      const variableMap = (variable || []).map((r) => {
        return {
          link: r.id,
          name: `[${r.variable_name}]`,
          check: r.variable_name,
        };
      });
      setVariableCheck([...variableMap]);
    }
  }, [variable]);

  useEffect(() => {
    if (detailProcedureData && Object.keys(detailProcedureData).length > 0) {
      if (
        detailProcedureData.text_template &&
        detailProcedureData.text_template.length > 0
      ) {
        try {
          const contentState = convertFromRaw(
            JSON.parse(detailProcedureData.text_template)
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        } catch (error) {
          setEditor(EditorState.createEmpty());
        }
      } else {
        setEditor(EditorState.createEmpty());
      }
    }
  }, [detailProcedureData]);

  useEffect(() => {
    if (selectVariable && variable) {
      const answerCheck = (variable || []).find(
        (r) => r.variable_name == selectVariable
      );
      if (answerCheck) {
        const allAnswerCheck = (answerCheck.answer || []).map((item, index) => {
          return { link: `${item.id} - ${index}`, name: `[${item.answer}]` };
        });
        setAnswerCheck([...allAnswerCheck]);
      }
    }
  }, [selectVariable]);

  // useEffect(() => {
  //   if (content) {
  //     const hrefContent = content;
  //     // check in <li></li>
  //     let checkitemLI = null;
  //     if (hrefContent && hrefContent.length > 0) {
  //       const [itemCash, ...itemCashes] =
  //         hrefContent && hrefContent.split("<li>");
  //       checkitemLI = (itemCashes || []).map((item) => {
  //         return item.split("</li>")[0];
  //       });
  //     } else {
  //       checkitemLI = null;
  //     }
  //     let checkitemALi = null;
  //     if (checkitemLI && checkitemLI.length > 0) {
  //       const charCheck = checkitemLI[checkitemLI.length - 1];
  //       const [itemA, ...itemCheckA] = charCheck && charCheck.split(`-&gt;`);
  //       checkitemALi = (itemCheckA || []).map((item) => {
  //         return item.split("</li>")[0];
  //       });
  //       if (
  //         (checkitemALi &&
  //           checkitemALi[checkitemALi.length - 1] &&
  //           checkitemALi[checkitemALi.length - 1] === "&nbsp;&nbsp;") ||
  //         (checkitemALi &&
  //           checkitemALi[checkitemALi.length - 1] &&
  //           checkitemALi[checkitemALi.length - 1] === " [") ||
  //         (checkitemALi &&
  //           checkitemALi[checkitemALi.length - 1] &&
  //           checkitemALi[checkitemALi.length - 1] === " [&nbsp;")
  //       ) {
  //         setIsChange(true);
  //       } else {
  //         setIsChange(false);
  //       }
  //     }
  //     // check in <p></p>
  //     let checkitemP = null;
  //     if (hrefContent && hrefContent.length > 0) {
  //       const [itemCash, ...itemCashes] =
  //         hrefContent && hrefContent.split("<p>");
  //       checkitemP = (itemCashes || []).map((item) => {
  //         return item.split("</p>")[0];
  //       });
  //     } else {
  //       checkitemP = null;
  //     }
  //     let checkitemA = null;
  //     if (checkitemP && checkitemP.length > 0) {
  //       const charCheck = checkitemP[checkitemP.length - 1];
  //       const [itemA, ...itemCheckA] = charCheck && charCheck.split(`-&gt;`);
  //       checkitemA = (itemCheckA || []).map((item) => {
  //         return item.split("</a>")[0];
  //       });
  //       if (
  //         (checkitemA &&
  //           checkitemA[checkitemA.length - 1] &&
  //           checkitemA[checkitemA.length - 1] === "&nbsp;&nbsp;") ||
  //         (checkitemA &&
  //           checkitemA[checkitemA.length - 1] &&
  //           checkitemA[checkitemA.length - 1] === " [") ||
  //         (checkitemA &&
  //           checkitemA[checkitemA.length - 1] &&
  //           checkitemA[checkitemA.length - 1] === " [[") ||
  //         (checkitemA &&
  //           checkitemA[checkitemA.length - 1] &&
  //           checkitemA[checkitemA.length - 1] === " [&nbsp;")
  //       ) {
  //         setIsChange(true);
  //       } else {
  //         setIsChange(false);
  //       }
  //     }
  //   }
  // }, [content]);

  const onSearchChange = ({ value }) => {
    setSearchVariable(
      defaultSuggestionsFilter(value, isChange ? answerCheck : variableCheck)
    );
  };

  const onAddMention = (value) => {
    setSelectVariable(value?.check);
  };

  const { MentionSuggestions } = mentionPlugin;
  const { Toolbar } = toolbarPlugin;
  let plugins = [mentionPlugin, toolbarPlugin];

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div className="custom-toolbar-editor">
        <Toolbar>
          {(externalProps) => (
            <div className="editor-custom-toolbar">
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
            </div>
          )}
        </Toolbar>
      </div>
      <div
        style={{
          border: "1px solid #f2f2f2",
          padding: "10px",
          height: "calc(100% - 40px)",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <ScrollWrapper css="no-padding x-hidden">
          {checkResoure !== null && checkResoure !== undefined ? (
            <Editor
              onFocus={() => {
                setFocusEditor(true);
              }}
              onBlur={() => {
                setFocusEditor(false);
              }}
              editorState={editorState}
              onChange={onChange}
              plugins={plugins}
              placeholder="Procedure Details"
            />
          ) : null}
        </ScrollWrapper>
      </div>
      <div className="mention-suggestion">
        <MentionSuggestions
          onSearchChange={onSearchChange}
          suggestions={isChange ? answerCheck : variableCheck}
          onAddMention={onAddMention}
        />
      </div>
    </div>
  );
};

export default TemplateDocumentation;
