import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";

export const getPriorFilterTypes = createActionThunk(
  AT.GET_PRIOR_FILTER_TYPES,
  () => API.getFilterListOfType()
);

export const getPriorAuthorization = createActionThunk(
  AT.GET_PRIOR_AUTHORIZATION,
  (params) => API.getPriorAuthorization(params)
);

export const savePriorAuthorization = createActionThunk(
  AT.SAVE_PROIR_AUTHORIZATION,
  (id, params) => API.savePriorAuthorization(id, params)
);

export const setDefaultSaveResource = createActionThunk(
  AT.SAVE_DEFAULT_SAVE_RESOURCE,
  () => {}
);

export const selectPrior = createActionThunk(AT.SELECT_PRIOR, (prior) => prior);

export const getAuthorizationNumbers = createActionThunk(
  AT.GET_AUTHORIZATION_NUMBERS,
  () => API.getAuthorizationNumbers()
);

export const setPriorData = createActionThunk(
  AT.SET_PRIOR_DATA,
  (type, value) => ({ [type]: value })
);
