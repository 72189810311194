import React from "react";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import moment from "moment";
import { Icon } from "antd";
const DashboardSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  currentUserInfo,
},ref) => {
  if (!isShow || !currentUserInfo) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper css="x-hidden">
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={<Icon type="bank" theme="twoTone" twoToneColor="#9F1853"/>}
          title={(
            <span className="text-uppercase">
            {
              currentUserInfo.name +
              (currentUserInfo.position && "(" + currentUserInfo.position + ")")
            }
            </span>
          )}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px", paddingBottom: '2rem' }}
        >
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"First Name"}
              value={currentUserInfo.name}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Last Name"}
              value={currentUserInfo.name}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Address"}
              value={"3495272345735"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Apt"}
              value={"BD"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"City"}
              value={"Midland"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"State"}
              value={"Midland"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"SSN"}
              value={"231456897"}
              mask={"ssn"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Phone"}
              value={"5413257890"}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"E-Mail"}
              value={currentUserInfo.email}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Pay Scale"}
              value={"Case"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Employee Score"}
              value={currentUserInfo.points}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Wage"}
              value={currentUserInfo.meta && currentUserInfo.meta.salary}
              prefix={'$'}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Start Date"}
              value={
                currentUserInfo.meta &&
                moment(currentUserInfo.meta.start_date).format("MM/DD/YYYY")
              }
              type={"date"}
              noEditable={true}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"PTO Hours"}
              value={currentUserInfo.vacation_hours_per_year}
              unit={"hours"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Shift Start Time"}
              value={
                currentUserInfo.meta &&
                moment(currentUserInfo.meta.shift_start_time).format("kk:mm A")
              }
              type={"time"}
              noEditable={true}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              label={"Shift End Time"}
              value={
                currentUserInfo.meta &&
                moment(currentUserInfo.meta.shift_end_time).format("kk:mm A")
              }
              type={"time"}
              noEditable={true}
            />
          </div>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default DashboardSidebar;
