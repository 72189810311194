import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { StaticResource, InventoryRows } from "../../constant";
import NarcoticSidebar from "./NarcoticInventorySidebar";
import NarcoticFilter from "./NarcoticInventoryFilter";
import {
  LeftCharvenSVG,
  DevicesSVG,
} from "../../../../common/components/icons/SVGIcon";
import {
  setInventoryData,
  getInventoryCostHistory,
  setGlobalLoading,
} from "../../../../store/actions";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";

const filterDataByOption = (data, option) => {
  const { quantity, location, startDate, endDate } = option;
  let filteredData = data;
  if (!quantity && !location && !startDate && !endDate) {
    return filteredData;
  }
  if (endDate) {
    filteredData = filteredData.filter(
      (d) => d.date && moment(endDate).diff(moment(d.date)) >= 0
    );
  }
  if (startDate) {
    filteredData = filteredData.filter(
      (d) => d.date && moment(d.date).diff(moment(startDate)) >= 0
    );
  }
  if (quantity) {
    filteredData = filteredData.filter((d) => d.quantity >= +quantity);
  }
  if (location) {
    filteredData = filteredData.filter((d) => d.location === location);
  }
  return filteredData;
};

const NarcoticInventory = ({ data, title, locations }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const narcotics = useSelector((state) => state.inventory.narcotics);
  const inventory = useSelector((state) => state.inventory.inventory);
  const filterOption = useSelector((state) => state.inventory.inventoryFilter);
  const loadingSearch = useSelector((state) => state.inventory.loadingAPI);

  const dispatch = useDispatch();

  const tableRows = useMemo(() => {
    if (narcotics && narcotics.length) {
      return filterDataByOption(narcotics, filterOption);
    }
    return [];
  }, [narcotics, filterOption]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData("inventory", tableRows[index]));
    dispatch(getInventoryCostHistory(tableRows[index].id, "narcotic"));
    handleSidebar();
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    dispatch(setGlobalLoading(loadingSearch))
  }, [loadingSearch])

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const onScroll = (values) => {
    if (
      values.scrollTop > values.scrollHeight - values.clientHeight - 80 &&
      offset < tableRows.length
    ) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <NarcoticFilter locations={locations} />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="inventory_supplies"
                loadMore={onScroll}
                defaultActive={-1}
                hideHeader={true}
                disableDelete={true}
                labels={['Name', 'Date', 'Quantity', 'Location']}
                widths={['calc(72% - 130px)', 'calc(28% - 130px)', '130px', '130px']}
                columns={[
                  { sortable: false, key: 'name' },
                  { sortable: false, key: 'date' },
                  { sortable: false, key: 'quantity' },
                  { sortable: false, key: 'location' },
                ]}
                rowData={rowData.map((row, index) => {
                  return { 
                    name: (
                      <div 
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Icon type="experiment" theme="twoTone" twoToneColor="#0043CE"/>
                        <p style={{ paddingLeft: "0.75rem" }} >{row.name || ''}</p>
                      </div>
                    ),
                    quantity: row.quantity,
                    date: row.date && moment(row.date).format("MM/DD/YYYY"),
                    location: row.location,
                  }
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {inventory && activeIndex >= 0 && (
        <NarcoticSidebar
          ref={sidebarRef}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
          locations={locations}
        />
      )}
      {inventory && activeIndex >= 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default NarcoticInventory;
