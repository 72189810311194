import React from "react";
import { Tooltip } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useHover } from "../../utils";
import { ImFilePlay } from 'react-icons/im';
import './style.scss';

function VideoFile({
  resource,
  fileName,
  fileDoubleClicked,
  customStyle,
  onRemoveBtnClicked,
}) {
  const [hoverRef, isHovered] = useHover();
  const handlDoubleClick = () => {
    fileDoubleClicked(resource);
  };
  const handleRemoveItem = () => {
    onRemoveBtnClicked(resource.id);
  };
  
  return (
    <Tooltip placement="top" title={fileName}>
      <div
        ref={hoverRef}
        onDoubleClick={handlDoubleClick}
        style={{ whiteSpace: "nowrap" }}
        className="video-file-wrapper"
      >
        <ImFilePlay 
          className={customStyle} 
          draggable="true" />
        <AiOutlineCloseCircle
          className="remove-item"
          onClick={handleRemoveItem}
          style={isHovered ? { display: "block" } : { display: "none" }}
        />
      </div>
    </Tooltip>
  );
}

export default VideoFile;
