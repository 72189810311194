import PersonalTickets from './components/PersonalTickets';
import KnowledgeBase from './components/KnowledgeBase';
import AllTickets from './components/AllTickets';

export const Children = {
  'details': PersonalTickets,
  'knowledge-base': KnowledgeBase,
  'all-tickets': AllTickets,
};

export const TabHeaders = [
  { label: 'All Tickets', width: 'auto' },
  { label: 'Open Tickets', width: 'auto' },
  { label: 'Closed Tickets', width: 'auto' }
];
