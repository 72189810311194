import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const patientExaminationLoadInit = createActionThunk(
  AT.PATIENT_EXAMINATION_LOAD_INIT,
  (procedure_id) => API.patientExaminationLoadInit(procedure_id)
);

export const patientExaminationSetData = createActionThunk(
  AT.PATIENT_EXAMINATION_SET_DATA,
  (scope, examinations) => ({ scope, examinations })
);

export const patientExaminationCopyLast = createActionThunk(
  AT.PATIENT_EXAMINATION_COPY_LAST,
  (patient_id, scope) => API.patientExaminationCopyLast(patient_id, scope)
);

export const patientExaminationSetNomalCheck = createActionThunk(
  AT.PATIENT_EXAMINATION_SET_NOMAL_CHECK,
  (value, type) => ({ value, type })
);

export const patientExaminationSave = createActionThunk(
  AT.PATIENT_EXAMINATION_SAVE,
  (procedure_id, data) => API.patientExaminationSave(procedure_id, data)
);

export const save_chief_complaint = createActionThunk(
  AT.SAVE_CHIEF_COMPLAINT,
  (id, param) => API.save_chief_complaint(id, param)
);
