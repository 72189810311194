import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import ClinicWorkflowSidebar from "../../../../common/views/QuickRegisterSidebar";
import DayCalendarUltrasound from "../DayCalendarUltrasound";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import Demographics from "../../../../common/views/PatientSnapshot/Demographics";
import MedicalHistory from "../../../../common/views/PatientSnapshot/MedicalHistory";
import Notes from "../../../../common/components/Notes";
import {
  getPatientSchedules,
  chooseSelectedPatientResourceByRandom,
  chooseSelectedPatientResource,
  getReferralConditions,
  getDetailUltrasounds,
  searchPatientSchedules,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  selectPatient,
  get_patient_notes,
  patientExaminationLoadInit,
  clinicProviderReviewLoadInit,
} from "../../../../store/actions";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";

const resources = [
  {
    id: "us",
    title: "Ultrasound",
  },
];

const cookies = new Cookies();

const initialConditions = [
  { step: "demographics", status: true },
  { step: "medical", status: true },
  { step: "notes", status: true },
];

const formSteps = [
  {
    title: "Demographics",
    content: Demographics,
  },
  {
    title: "Medical History",
    content: MedicalHistory,
  },
  {
    title: "Notes",
    content: Notes,
  },
];

class ClinicSchedule extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  state = {
    isSidebar: false,
    events: [],
    searchValue: "",
    schedulesState: [],
    block: true,
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = () => {
    this.handleEvents(this.state.filters);
    this.loadInitialPaymentJs();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.schedules) {
      this.setState({ schedulesState: nextProps.schedules });
    }
    if (nextProps.activeReview) {
      const current_ultrasound_name = cookies.get("ultrasound_type_name");
      this.setState({ sidebarTitle: current_ultrasound_name });
      if (nextProps.goNextTabReview) {
        nextProps.goNextTabReview();
      }
      this.setState({
        block: true,
      });
      this.handleSidebar();
    } else {
      this.setState({
        block: false,
      });
    }
  }

  loadInitialPaymentJs = () => {
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    if (!this.state.block) {
      this.props.getPatientSchedules({
        date,
        location,
        provider_id,
        patient_id: "",
        only_clinics: 0,
        only_procedures: 0,
        only_us: 1,
        only_angio: 0,
      });
    }
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: searchPatientName });
    } else {
      this.setState({ schedulesState: this.props.schedules });
    }
  };

  onSelectResource = (patient_id, content, event) => {
    cookies.set("current_patient_id", patient_id);
    cookies.set("apointment_ultrasound_id", event.id);
    const current_ultrasound_id = cookies.get("current_ultrasound_id");
    this.props.selectPatient(patient_id);
    this.props.patientExaminationLoadInit(event.id);
    this.props.clinicProviderReviewLoadInit(event.id);
    this.props.get_patient_notes(patient_id);
    this.props.chooseSelectedPatientResource(patient_id);
    this.props.get_ultrasound_finding_value(event.id);
    this.props.get_ultrasound_type(current_ultrasound_id);
    this.props.getDetailUltrasounds(current_ultrasound_id);
    this.handleSidebar();
    this.setState({ sidebarTitle: content });
  };

  render() {
    const { isSidebar, sidebarTitle } = this.state;
    const { selectedPatientResource } = this.props;
    const isResource =
      !selectedPatientResource || !Object.keys(selectedPatientResource).length;

    return (
      <div
        style={{ display: this.props.display ? "flex" : "none" }}
        className="clinicWorkflow__main-section"
      >
        <ScrollWrapper css="no-padding">
          <div className="eventsContainer has-sub-header">
            <div className="calendarContainer">
              <DayCalendarUltrasound
                locations={this.props.locations || []}
                providers={this.props.providers || []}
                events={this.state.schedulesState || []}
                resources={resources}
                slotProviders={this.props.scheduleProviders || []}
                loading={
                  this.props.loadingSchedules || this.props.loadingResource
                }
                fetchEvents={this.handleEvents}
                onSearch={this.onSearch}
                valueSearch={this.state.searchValue}
                onSelectResource={this.onSelectResource}
                calendarType="check-in"
              />
            </div>
          </div>
        </ScrollWrapper>
        <ClinicWorkflowSidebar
          ref={this.sidebarRef}
          resource={selectedPatientResource}
          isShow={isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          paymentForm={window.SqPaymentForm}
          refreshCalendar={this.refreshCalendar}
          referralConditions={this.props.referralConditions || []}
          isNormal={true}
          formSteps={formSteps}
          initialConditions={initialConditions}
          disableIDImage={true}
          hasSubHeader={true}
          sidebarTitle={sidebarTitle}
          sidebarIcon={(<Icon type="funnel-plot" theme="twoTone" twoToneColor='#740937'/>)}
        />
        <SidebarExtension
          startingWidth="calc(28.3% - 20px + 0.3rem)"
          sidebarReference={this.sidebarRef}
          sidebarToggle={this.toggleSidebar}
          sidebarState={isSidebar}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.common.clinicNursingLocations,
  providers: state.common.providers,
  schedules: state.patientSchedule.schedules,
  scheduleProviders: state.patientSchedule.providers,
  loadingSchedules: state.patientSchedule.loadingSchedules,
  referralConditions: state.common.referralConditions,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  loadingResource: state.patientSchedule.loadingResource,
  activeReview: state.common.activeReview,
});

const mapDispatchToProps = {
  getPatientSchedules,
  chooseSelectedPatientResourceByRandom,
  chooseSelectedPatientResource,
  getReferralConditions,
  getDetailUltrasounds,
  searchPatientSchedules,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  selectPatient,
  get_patient_notes,
  patientExaminationLoadInit,
  clinicProviderReviewLoadInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSchedule);
