import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import { useDebounce } from "use-lodash-debounce";

import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import {
  setProcedureDetails,
  addProcedureDetails,
  edit_clinic_procedure_type,
  getProviders,
} from "../../../store/actions";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";

const ProcedureTimes = [
  { value: 30, label: "30 mins" },
  { value: 60, label: "60 mins" },
  { value: 90, label: "90 mins" },
  { value: 120, label: "120 mins" },
  { value: 150, label: "150 mins" },
  { value: 180, label: "180 mins" },
  { value: 210, label: "210 mins" },
  { value: 240, label: "240 mins" },
  { value: 270, label: "270 mins" },
  { value: 300, label: "300 mins" },
];

const ProcedureProviders = ({ resource }) => {
  const tableRef = useRef(null);
  const providersState = useSelector(
    (state) => state.procedureDetail.providers_clinic
  );
  const detail_clinic_type = useSelector(
    (state) => state.procedureDetail.detail_clinic_type
  );
  const dispatch = useDispatch();

  const updateProviders = async (type, index, value) => {
    if (!providersState.some((r) => r.physician === value?.id)) {
      await dispatch(
        addProcedureDetails("providers_clinic", {
          physician_name: value?.value,
          physician: value?.id,
        })
      );
      callApiUpdate([
        ...providersState,
        {
          physician_name: value?.value,
          physician: value?.id,
        },
      ]);
    }
  };
  const removeProvider = async (index) => {
    providersState.splice(index, 1);
    await dispatch(
      setProcedureDetails("providers_clinic", [...providersState])
    );
    callApiUpdate([...providersState]);
  };

  useEffect(() => {
    if (detail_clinic_type) {
      const providerDetail = (detail_clinic_type.providers || []).map((r) => {
        return {
          physician: r.id,
          physician_name: r.value,
        };
      });
      dispatch(setProcedureDetails("providers_clinic", [...providerDetail]));
    }
  }, [detail_clinic_type]);

  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const providers = useSelector((state) => state.common.providers);

  useEffect(() => {
    if (search && search.length > 0) {
      const checkfilter = providers.filter((r) =>
          r.value.toLowerCase().includes(search.toLowerCase())
      );
      setSearchResult(checkfilter);
    } else {
      setSearchResult(providers);
    }
  }, [search]);

  useEffect(() => {
    if (providers) {
      setSearchResult(providers);
    }
  }, [providers]);

  const handlePopulate = (value) => {
    const name =
      !value.first_name && !value.last_name
        ? ""
        : `${value.first_name} ${value.last_name}`;
    // TODO: integrate with datatable
  };

  useEffect(() => {
    dispatch(getProviders());
  }, []);

  const callApiUpdate = async (value) => {
    if (
      providersState &&
      detail_clinic_type &&
      Object.keys(detail_clinic_type).length > 0
    ) {
      const renderArray = value.map((r) => {
        return r.physician;
      });
      await dispatch(
        edit_clinic_procedure_type(detail_clinic_type.id, {
          name: detail_clinic_type?.name,
          providers: renderArray,
        })
      );
    }
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          title="Procedure-Provider-Search"
          options={searchResult}
          handlePopulate={(value) =>
            updateProviders("physician", providersState.length, value)
          }
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Providers"
          keyLabel="value"
        />
      </div>

      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="procedure_provider"
          labels={["Provider"]}
          widths={["100%"]}
          fullHeight={true}
          parentHeight={
            tableRef && tableRef.current && tableRef.current.clientHeight
          }
          columns={[{ sortable: true, key: "provider" }]}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(providersState || []).map((item, index) => {
            return {
              provider: item.physician_name,
            };
          })}
          emptyRows={new Array(4).fill(1)}
          sorts={[""]}
        />
      </div>
    </div>
  );
};

export default ProcedureProviders;
