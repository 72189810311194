import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CloseIcon, SummaryEmptySVG } from "../../components/icons/SVGIcon";
import FormStepper from "../../components/FormStepper";
import ScrollWrapper from "../../components/ScrollBar";
import Toastr from "../../components/Toast";
import Demographics from "./Demographics";
import InsuranceDetail from "./InsuranceDetail";
import MedicalHistory from "./MedicalHistory";
import { validateSteps } from "../../../services/validations";
import { newSnapshotOption } from "../../constants/initialData";
import { MaleSVG } from "../../components/icons/FaxSVG";
import SidebarTitle from "../../components/SidebarTitle";
import Notes from "../../../common/components/Notes";
import { Icon } from "antd";

const initialCondition = [
  { step: "demographics", status: true },
  { step: "insurance", status: false },
  { step: "medical", status: false },
  { step: "notes", status: false },
];

const formSteps = [
  {
    title: "Demographics",
    isConfirmed: true,
    content: Demographics,
  },
  {
    title: "Insurance Details",
    isConfirmed: true,
    content: InsuranceDetail,
  },
  {
    title: "Medical History",
    isConfirmed: false,
    content: MedicalHistory,
  },
  {
    title: "Notes",
    isConfirmed: true,
    content: Notes,
  },
];

const SnapSidebar = React.forwardRef(
  (
    { isShow, settup, sidebarIcon, handleCloseSidebar, opener, ...restProps },
    ref
  ) => {
    const resource = useSelector((state) => state.patientSnapshot.resource);
    const locations = useSelector((state) => state.common.locations);
    const providers = useSelector((state) => state.common.only_providers);
    const stepApi = useSelector((state) => state.clinicProviderReview.step);
    const filteredLocations = useSelector(
      (state) => state.common.filteredLocations
    );
    const filteredProviders = useSelector(
      (state) => state.common.filteredProviders
    );
    const referralConditions = useSelector(
      (state) => state.common.referralConditions
    );
    const patient_all_insurances_id = useSelector(
      (state) => state.newPatient.patient_all_insurances_id
    );
    const referredProviders = useSelector(
      (state) => state.common.referredProviders
    );
    const enterInsuranceCheck = useSelector(
      (state) => state.newPatient.enterInsuranceCheck
    );
    const pcpList = useSelector((state) => state.common.pcpList);
    const patientsNote = useSelector((state) => state.newPatient.patientsNote);
    const [hideScope, setHideScope] = useState(false);
    const isAdded = useSelector((state) => state.patientPending.isAdded);
    const dispatch = useDispatch();
    const sidebarRef = useRef();

    const [step, setStep] = useState(0);
    const [title, setTitle] = useState("");
    const [conditions, setConditions] = useState(initialCondition);
    const [timer, setTimer] = useState(0);
    const [isAddForm, setIsAddForm] = useState(false);
    const [stepData, setStepData] = useState({
      demographics: {},
      insurance: {},
      medical: {},
    });

    useEffect(() => {
      if (settup) {
        if (settup == -1) {
          setStep(0);
        } else {
          setStep(settup);
        }
      }
    }, [settup]);

    useEffect(() => {
      if (stepApi && stepApi.length > 0) {
        setStep(Number(stepApi - 1));
      }
    }, [stepApi]);

    const isToastr = useMemo(() => {
      if (isAdded) {
        setTimer(0);
        setTimeout(() => {
          setTimer(600);
        }, 2000);
        return true;
      }
      return false;
    }, [isAdded]);

    useEffect(() => {
      if (resource) {
        let a = moment();
        let b = moment(resource.date_of_birth);
        let ops_patient_id = resource.id ? resource.id : "";
        const fullName = `${resource.first_name || ""} ${
          resource.last_name || ""
        } `;
        const newTitle =
          fullName.toUpperCase() +
          `(` +
          a.diff(b, "years") +
          ` yo MALE)` +
          ` (OPS ID: ${ops_patient_id})`;
        setTitle(newTitle);
        if (a.diff(b, "years") >= 62) {
          setHideScope(false);
        } else {
          if (
            Number(moment().format("MM")) < 10 &&
            Number(moment().format("MM")) > 2
          ) {
            setHideScope(true);
          } else {
            setHideScope(false);
          }
        }
        setStepData({
          demographics: { ...resource },
          insurance: {},
          medical: {
            previous_surgical_history:
              resource?.previous_surgical_history || [],
            medications: resource?.medications || [],
            allergies: resource?.allergies || [],
            previous_medical_history: resource?.icd_codes || [],
            flu_vaccination: resource?.flu_vaccination,
            pneumococcal: resource?.pneumococcal,
            aortic_disease: resource?.aortic_disease,
            amputation: resource?.amputation,
            smoking_ppd: resource?.smoking_ppd,
            alcoholic_drink_pw: resource?.alcoholic_drink_pw,
            previous_smoking_ppd: resource?.previous_smoking_ppd,
            years_of_smoking: resource?.years_of_smoking,
            no_medication: resource?.no_medication,
            no_drug_allergies: resource?.no_drug_allergies,
          },
        });
      }
    }, [resource]);

    console.log(patient_all_insurances_id, "patient_all_insurances_id");

    useEffect(() => {
      if (resource) {
        const newConditions = [...conditions];
        for (let i = 0; i < 3; i++) {
          const validate = validateSteps(
            stepData[conditions[i].step],
            newSnapshotOption[conditions[i].step]
          );
          newConditions[i].status = validate;
        }
        if (stepData["medical"]) {
          if (
            stepData["medical"]["alcoholic_drink_pw"] != "" &&
            ((stepData["medical"]["allergies"] &&
              stepData["medical"]["allergies"].length > 0) ||
              stepData["medical"]["no_drug_allergies"] == true) &&
            stepData["medical"]["amputation"] != -1 &&
            (hideScope
              ? stepData["medical"]["pneumococcal"] != null ||
                stepData["medical"]["pneumococcal"] == -1 ||
                stepData["medical"]["pneumococcal"] == null
              : stepData["medical"]["pneumococcal"] != -1) &&
            stepData["medical"]["aortic_disease"] != -1 &&
            ((stepData["medical"]["medications"] &&
              stepData["medical"]["medications"].length > 0) ||
              stepData["medical"]["no_medication"] == true) &&
            stepData["medical"]["previous_medical_history"] &&
            stepData["medical"]["previous_medical_history"].length > 0 &&
            stepData["medical"]["previous_smoking_ppd"] != "" &&
            stepData["medical"]["previous_surgical_history"] &&
            stepData["medical"]["previous_surgical_history"].length > 0 &&
            stepData["medical"]["smoking_ppd"] != "" &&
            stepData["medical"]["years_of_smoking"] != ""
          ) {
            newConditions[2].status = true;
          } else {
            newConditions[2].status = false;
          }
        }
        if (
          patient_all_insurances_id &&
          patient_all_insurances_id.some(
            (variable) =>
              variable.is_verified &&
              variable.is_verified === 1 &&
              variable.is_eligible &&
              variable.is_eligible === 1
          )
        ) {
          newConditions[1].status = true;
        } else {
          newConditions[1].status = false;
        }
        if (enterInsuranceCheck) {
          newConditions[1].status = true;
        }
        if (patientsNote && patientsNote.length > 0) {
          newConditions[3].status = true;
        } else {
          newConditions[3].status = false;
        }
        setConditions(newConditions);
      }
    }, [
      resource,
      step,
      stepData,
      patient_all_insurances_id,
      hideScope,
      enterInsuranceCheck,
      patientsNote,
    ]);

    const handleSubmit = () => {
      if (step < 4) {
        // console.log("handle submit", step);
        const validate = conditions[step];
        setStep(step);
        // dispatch(setStepData("step", step));
      } else {
        // Todo: handleSubmit
        console.log("save");
      }
    };
    const handlePrev = () => {
      setStep(step - 1);
    };

    if (!isShow) {
      return null;
    }

    if (!resource) {
      return (
        <div ref={ref} className={`resourceContainer ${isShow ? "show" : ""}`}>
          <div
            className="emptyContentIcon"
            style={{
              width: "100%",
              height: "calc(100% - 160px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SummaryEmptySVG />
          </div>
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={`resourceContainer ${
          restProps.noSubHeader ? "" : "has-sub-header"
        } ${isShow ? "show" : ""}`}
      >
        {isToastr && timer < 600 && (
          <div
            style={{ position: "fixed", top: "60px", right: "2px", zIndex: 10 }}
          >
            <Toastr
              type="New Patient"
              status="success"
              msg="Added New Patient Successfully!"
            />
          </div>
        )}

        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>

          <SidebarTitle
            icon={
              sidebarIcon ? (
                sidebarIcon
              ) : (
                <Icon type="mail" theme="twoTone" twoToneColor="#0f62fe" />
              )
            }
            title={isAddForm ? "ADD NEW PATIENT" : "PATIENT DASHBOARD"}
            subTitle=""
          />

          <FormStepper
            steps={formSteps}
            currentStep={step}
            customResource={resource}
            handleStep={setStep}
            handleContinue={handleSubmit}
            locations={locations}
            providers={providers}
            filteredLocations={filteredLocations}
            filteredProviders={filteredProviders}
            conditions={conditions}
            referredProviders={referredProviders}
            referralConditions={referralConditions}
            pcpList={
              pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList
            }
            isScan={restProps.isScan}
            setOpenAddForm={setIsAddForm}
            isAddForm={isAddForm}
          />
          <div style={{ paddingBottom: "2rem", width: "100%" }} />
        </ScrollWrapper>
      </div>
    );
  }
);

export default SnapSidebar;
