import React from 'react';
import EmployeeModule from './components/EmployeeModule';
import { YellowAlertSVG, CheckSVG } from '../../common/components/icons/SVGIcon';
import DeficiencyModule from './components/MeaningfulUse';
import { Icon } from 'antd';

export const Children = {
  'employee': EmployeeModule,
  'deficiency': DeficiencyModule
};

export const TabHeaders = [
  { label: 'Dashboard', width: '71px' },
  { label: 'Time-Off Request', width: '115px' },
  { label: 'Variance Reporting', width: '124px' },
  { label: 'Evaluations', width: '76px' },
  { label: 'Manual & Policies', width: '115px' },
  { label: 'CME', width: '31px' },
  { label: 'Provider Contribution Margin', width: 'auto' }
];
export const StaticResource = {
  first_name: 'James',
  last_name: 'Fisher',
  date_of_birth: '06/09/1973',
  phone: '(989) 897-8797',
  secondary_phone: '(989) 897-8797',
  email: 'haqqani@hotmail.com',
  ssn: '890-09-8977',
  address: '1109 W St Andrews Rd',
  apt_number: '',
  suite: '',
  bldg: '',
  state: 'Michigan',
  city: 'Midland',
  zipcode: '48642',
  clinic_date: '07/04/2019',
  result: '',
  icon: <YellowAlertSVG />
};

export const DashboardRows = [{icon:<Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>}, {icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>}];
