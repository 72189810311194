import React, { useEffect, useRef, useState } from 'react';
import CardBlock from '../../../../common/components/Card';
import TopBar from '../../../../common/components/SubTopBar';
import { Line } from 'react-chartjs-2';
import {
  DatePicker,
  Input,
  Select,
} from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { getPCMSummary } from '../../../../store/actions';
import moment from 'moment';
const { Option } = Select;

const Summary = () => {
  const dispatch = useDispatch();
  const dateFormat = "MM/DD/YYYY";
  const pcmSummary = useSelector((state) => state.common.pcm_summary);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [chartType, setChartType] = useState('accounts_receivable');

  const getMillionsValue = (value) => {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "K", "M", "B","T"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
  }

  const chartTypes = [
    {
      name: 'Accounts Receivable',
      id: 'accounts_receivable',
    },
    {
      name: '0-30',
      id: '0_30'
    },
    {
      name: '30-60',
      id: '30_60'
    },
    {
      name: '60-90',
      id: '60_90'
    },
    {
      name: '90-120',
      id: '90_120'
    },
    {
      name: '120+',
      id: 'gt_120'
    },
    {
      name: 'Billing Frequency',
      id: 'billing_frequency'
    },
    {
      name: 'Assignment Activity',
      id: 'assignment_activity'
    }
  ];
  const chartOptions = {
    responsive: true,
    animation: false,
    maintainAspectRatio: false,
    defaults: {
      elements: {
        point: {
          backgroundColor: '#000000',
          borderColor: '#000000'
        },
      },
    },
    layout: {
      padding: 30,
    },
    plugins: {
      legend: {
          position: 'right',
          display: false,
          labels: {
              color: '#152935',
              family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          }
      }
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          font: {
            size: '0.875rem',
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: '#152935',
          callback: function(value, index, values) {
            return getMillionsValue(value);
          }
        }
      },
      x: {
        ticks: {
          padding: 10,
          font: {
            size: '0.875rem',
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: '#000',
        }
      }
    }
  };
  let [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  

  useEffect(() => {
    if (!chartType) return;
    dispatch(getPCMSummary(chartType, startDate, endDate))
  }, [startDate, endDate, chartType])

  useEffect(() => {
    dispatch(getPCMSummary(chartType, startDate, endDate))
  }, [])

  useEffect(() => {
    if (!pcmSummary) return;
    let newData = {};
    newData.labels = pcmSummary.data.map((data) => {
      return moment(data.created_at).format('MM/DD/YYYY');
    })

    // newData.labels = getDateRange(startDate, endDate, 'days').map((date) => {
    //   return date.format('MM-DD-YYYY');
    // })
    console.dir(pcmSummary);

    switch (chartType) {
      case 'billing_frequency':
        newData.datasets = [
          {
            label: 'Billings',
            data: pcmSummary.data.map((item) => {
              return item.count
            }),
            fill: false,
            backgroundColor: 'blue',
            borderColor: 'blue',
            pointBackgroundColor: '#000000'
          }
        ]
        break;
      case 'assignment_activity':
          newData.datasets = [
            {
              label: 'Less Than 30',
              data: pcmSummary.data.map((item) => {
                return item.aging_count
              }),
              fill: true,
              backgroundColor: 'green',
              borderColor: 'green',
              pointBackgroundColor: '#000000'
            },
            {
              label: 'Correction Team',
              data: pcmSummary.data.map((item) => {
                return item.correction_count
              }),
              fill: false,
              backgroundColor: 'red',
              borderColor: 'red',
              pointBackgroundColor: '#000000'
            },
            {
              label: 'Research  Team',
              data: pcmSummary.data.map((item) => {
                return item.research_count
              }),
              fill: false,
              backgroundColor: 'orange',
              borderColor: 'orange',
              pointBackgroundColor: '#000000'
            },
            {
              label: 'Payment Posting',
              data: pcmSummary.data.map((item) => {
                return item.count_payment_posting
              }),
              fill: false,
              backgroundColor: '#99ff33',
              borderColor: '#99ff33',
              pointBackgroundColor: '#000000'
            },
            {
              label: 'Adjust Off',
              data: pcmSummary.data.map((item) => {
                return item.count_adjust_off
              }),
              fill: false,
              backgroundColor: 'yellow',
              borderColor: 'yellow',
              pointBackgroundColor: '#000000'
            },
            {
              label: 'Positive Patient Balance',
              data: pcmSummary.data.map((item) => {
                return item.positive_patient_balance
              }),
              fill: false,
              backgroundColor: '#3399ff',
              borderColor: '#3399ff',
              pointBackgroundColor: '#000000'
            },
            {
              label: 'Research - Call Logged',
              data: pcmSummary.data.map((item) => {
                return item.contacted_count
              }),
              fill: false,
              backgroundColor: '#33cc33',
              borderColor: '#33cc33',
              pointBackgroundColor: '#000000'
            },
          ]
          break;
      default:
        newData.datasets = [
          {
            label: 'Patient',
            data: pcmSummary.data.map((item) => {
              console.dir(item);
              return item[chartType + '_patient']
            }),
            fill: true,
            backgroundColor: '#D4BBFF',
            borderColor: '#D4BBFF',
            pointBackgroundColor: '#000000'
          },
          {
            label: 'Insurance',
            data: pcmSummary.data.map((item) => {
              return item[chartType + '_insurance']
            }),
            fill: true,
            backgroundColor: '#82CFFF',
            borderColor: '#82CFFF',
            pointBackgroundColor: '#000000'
          },
          {
            label: 'Net',
            data: pcmSummary.data.map((item) => {
              return item[chartType + '_net']
            }),
            fill: true,
            backgroundColor: '#FFAFD2',
            borderColor: '#FFAFD2',
            pointBackgroundColor: '#000000'
          },
        ]
        break;
    }

    setData(newData);

  }, [pcmSummary])

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Summary" />
      <div className="clinicWorkflow__main-section eventsContainer" >
        <div className="fullContainer">
            <div className='cardBlock' style={{height: '75%', width: '100%'}}>
              <div className="filterContainer" style={{padding: 0, paddingRight: '35px', paddingLeft: '35px'}}>
                <div className="cardBlock__title" >{chartTypes.find((element) => {
                  return element.id === chartType
                })?.name}</div>
                <div className="statusFilter" style={{ minWidth: "200px", marginLeft: 'auto' }}>      
                  <Select
                    placeholder="Chart Type"
                    value={chartType}
                    onChange={(val) => {setChartType(val)}}
                    allowClear={true}
                  >
                    {chartTypes.map((items) => {
                      return (
                        <Option key={items.id} value={items.id}>
                          {items.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="statusFilter" style={{ minWidth: "200px" }}>
                  <DatePicker
                    format={dateFormat}
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(val) => {setStartDate(val)}}
                  />
                </div>
                <div className="statusFilter" style={{ minWidth: "200px" }}>
                  <DatePicker
                    format={dateFormat}
                    placeholder="End Date"
                    value={endDate}
                    onChange={(val) => {setEndDate(val)}}
                  />
                </div>
              </div>
              <div style={{height: '90%'}}>
                <Line data={data} options={chartOptions} height={75}/>
              </div>
            </div>
            <div className='cardBlock' style={{height: '25%', width: '100%'}}></div>
        </div>
      </div>
    </div>
  )
}

export default Summary;
