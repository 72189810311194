import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Upload, Select, DatePicker } from "antd";
import { BASE_API } from "../../constants/constants";
import { BarcodeSVG } from "../icons/FaxSVG";
import { ExpandSVG } from "../icons/SVGIcon";
import moment from "moment";
import AT from "../../../store/actionTypes";
import {
  saveUploadedPatientDocument,
  searchPatientDocuments,
  setGlobalLoading
} from "../../../store/actions";

const { Dragger } = Upload;
const { Option } = Select;

const defaultDraggerProps = {
  name: "file",
  multiple: true,
  action: BASE_API + "clinic-provider/upload-document",
};

const DocumentUpload = ({ draggerProps, sideComponent }) => {
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const providers = useSelector((state) => state.common.providers);
  const uploading = useSelector((state) => state.clinicProvider.uploading);
  const [files, setFiles] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [category, setCategory] = useState("");
  const [date, setDate] = useState(null);
  const [provider, setProvider] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (uploading === "uploading") {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [uploading])

  const onChangeUpload = (info) => {
    const { status } = info.file;
    if (info.file || info.fileList) {
      setFiles(info.file || info.fileList);
    }
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "uploading") {
      if (uploaded) {
        setUploaded(false);
        dispatch({
          type: AT.UPLOAD_STARTED,
        });
      }
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setUploaded(true);
      dispatch({
        type: AT.UPLOAD_DONE,
      });
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploaded(true);
      dispatch({
        type: AT.UPLOAD_REFRESH,
      });
    }
  };

  const handleChangeCategory = (value) => {
    setCategory(value);
  };

  const handleDate = (date, dateString) => {
    setDate(dateString);
  };

  const dragProps = useMemo(() => {
    if (draggerProps) {
      return draggerProps;
    }
    return defaultDraggerProps;
  }, [draggerProps]);

  const onSave = () => {
    const patient_id = patientResource.id;
    const filename = files.name;
    const provider_id = provider;
    dispatch(
      saveUploadedPatientDocument({
        patient_id,
        category,
        provider_id,
        filename,
      })
    );
    setTimeout(() => {
      dispatch(
        searchPatientDocuments({
          patient_id,
          date: "",
          category: "",
          provider_id: "",
        })
      );
    }, 500);
  };

  if (!patientResource) return <></>;

  return (
    <div
      className="side-component"
      style={{
        display: "inline-flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      {files && uploading == "done" && (
        <React.Fragment>
          <Select
            placeholder="Category"
            suffixIcon={<ExpandSVG />}
            onChange={handleChangeCategory}
            className="hide-tablet"
            style={{
              minWidth: "216px",
              marginRight: "0.5rem",
            }}
          >
            <Option value="Laboratory">Laboratory</Option>
            <Option value="New Patient Pack">Referral Pack</Option>
          </Select>
          {category === "Laboratory" && (
            <DatePicker
              value={date ? moment(date) : null}
              format={"MM/DD/YYYY"}
              placeholder={"Date"}
              suffixIcon={<ExpandSVG />}
              onChange={handleDate}
              className="hide-tablet"
              style={{
                minWidth: "161px",
                marginRight: "0.5rem",
              }}
            />
          )}
          {category === "Laboratory" && (
            <Select
              placeholder="Internal"
              suffixIcon={<ExpandSVG />}
              className="hide-tablet"
              style={{
                minWidth: "120px",
                marginRight: "0.5rem",
                marginLeft: "0px",
              }}
            >
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          )}
          <Select
            placeholder="Providers"
            className="hide-tablet"
            style={{
              minWidth: "120px",
              marginRight: "0.5rem",
              marginLeft: "0px",
            }}
            value={provider}
            onChange={setProvider}
          >
            {providers.map((provider, index) => (
              <Option key={`upload-box-provider-${index}`} value={provider.id}>
                {provider.value}
              </Option>
            ))}
          </Select>
          <button
            className="common-btn blue-btn hide-tablet"
            disabled={category == "" || !provider}
            style={{
              border: 0,
              paddingRight: "12px",
              minWidth: "130px",
              marginRight: "0.5rem",
              marginLeft: "0px",
            }}
            onClick={onSave}
          >
            Save
          </button>
        </React.Fragment>
      )}
      {sideComponent && sideComponent}
      {uploading === "" && (
        <Dragger
          {...dragProps}
          onChange={onChangeUpload}
          className="barcode-uploader"
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <BarcodeSVG />
          </p>
        </Dragger>
      )}
    </div>
  );
};

export default DocumentUpload;
