import React, { useState, useEffect } from "react";
import { Input, Icon } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CloseIconSVG } from "../../../../common/components/icons/SVGIcon";
import { ProcedureSpecificationTemp } from "../../constant";
import { getProcedureType } from "../../../../store/actions";
import Loading from "../../../../common/components/Loading";

const ROS = ({ type, onChangeData, dataCheck, keyPanel, dataAll }) => {
  const [data, setData] = useState([]);
  const [array_type, set_array_type] = useState([]);

  const procedure_type = useSelector(
    (state) => state.procedureDetail.procedure_type
  );

  const paramsChart = useSelector((state) => state.patientRecords.paramsChart);
  const dispatch = useDispatch();

  const onChangeComments = (index, comments) => {
    let data_ = [...data];
    data_[index]["comments"] = comments;
    onChangeData(data_);
  };
  const onChangeValues = (index, values) => {
    let data_ = [...data];
    data_[index]["values"] = values;
    onChangeData(data_);
  };

  useEffect(() => {
    const arrayCheck = (array_type || []).map((item) => {
      var idvari = 0;
      return {
        comments: "",
        id: item?.id,
        type:
          item?.question && item?.question.length > 18
            ? `${item?.question.substring(0, 18)}...`
            : item?.question,
        typeFull: item?.question,
        variable_name: item?.variable_name,
        values: (item?.answers || []).map((r) => {
          idvari = r.procedure_type_variable_id;
          return {
            id: r.id,
            option: r.answer,
            checked: false,
            text_template: r.text_template,
            procedure_type_variable_id: r.procedure_type_variable_id,
            variable_name: item?.variable_name,
          };
        }),
        procedure_type_variable_id: idvari,
      };
    });
    var checkTrue = [];
    if (dataCheck && dataCheck.specifications) {
      dataCheck.specifications.map((r, index) => {
        checkTrue = [
          ...checkTrue,
          {
            findindex: arrayCheck.findIndex(
              (i) => i.id == r.procedure_type_variable_id
            ),
            valueindex:
              arrayCheck[index] && arrayCheck[index]["values"]
                ? arrayCheck[index]["values"].findIndex(
                  (check) => check.option == r.answer
                )
                : -1,
            valuecheck: r.answer,
          },
        ];
      });
    }
    (checkTrue || []).map((r) => {
      if (
        arrayCheck[r.findindex] &&
        arrayCheck[r.findindex]["values"] &&
        arrayCheck[r.findindex]["values"][r.valueindex] &&
        arrayCheck[r.findindex]["values"][r.valueindex]["checked"] !== undefined
      ) {
        arrayCheck[r.findindex]["values"][r.valueindex]["checked"] = true;
      }
    });
    setData([...arrayCheck]);
    if (dataAll && dataAll["ros"] && dataAll["ros"].length > 0) {
      setData([...dataAll["ros"]]);
    }
  }, [dataCheck, array_type, dataAll]);

  useEffect(() => {
    set_array_type([...array_type, ...procedure_type]);
  }, [procedure_type]);

  useEffect(() => {
    set_array_type([]);
    (paramsChart.appointment_type_id || []).map(async (r) => {
      return await dispatch(getProcedureType(r.value));
    });
  }, []);

  useEffect(() => {
    if (
      paramsChart &&
      paramsChart.appointment_type_id &&
      keyPanel !== "procedure-specifications"
    ) {
      set_array_type([]);
      (paramsChart.appointment_type_id || []).map(async (r) => {
        return await dispatch(getProcedureType(r.value));
      });
    }
  }, [paramsChart, keyPanel]);

  return (
    <React.Fragment>
      {data.map((item, index) => (
        <ROSItem
          key={`examination-item-${index}`}
          title={item.typeFull}
          comments={item.comments}
          values={item.values}
          onChangeComments={(comments) => onChangeComments(index, comments)}
          onChangeValues={(values) => onChangeValues(index, values)}
        />
      ))}
    </React.Fragment>
  );
};

const ROSItem = (props) => {
  const [title, setTitle] = useState("");
  const [values, setValues] = useState([]);
  const loading_procedure_type = useSelector(
    (state) => state.procedureDetail.loading_procedure_type
  );

  useEffect(() => {
    setTitle(props.title);
    setValues(props.values);
  }, [props.comments, props.values]);

  const onClickOption = (index) => {
    let values_ = [...values];
    values_.map((r, i) => {
      values_[i]["checked"] = false;
    });
    values_[index]["checked"] = !values_[index]["checked"];
    props.onChangeValues(values_);
    setValues(values_);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", position: "relative" }}
      className={`ExaminationROSItem`}
    >
      {loading_procedure_type && <Loading></Loading>}
      <div style={{ width: "40%" }}>
        <div
          className="ExaminationROSItem__label"
          style={{ width: "100%", overflow: "hidden" }}
        >
          {title}
        </div>
      </div>
      <div
        style={{
          width: "60%",
        }}
      >
        <div
          style={{ width: "100%" }}
          className={`ExaminationROSItem__options`}
        >
          {(values || []).map((opt, index) => (
            <button
              key={`ros-item-${title}-${index}`}
              className={`ExaminationROSItem__option ${opt.checked && "active"}`}
              onClick={() => onClickOption(index)}
            >
              {opt.option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ROS;
