import { createReducer } from 'redux-create-reducer';

import AT from '../actionTypes';

/**
 * scanStatus: '', 'loading', 'token_showed', 'matched', 'incorrect' 
 */
const initialState = {
  scanToken: '',
  scanStatus: '',
  scannedImage: ''
};

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`] (state, action) {
    return {
      ...initialState
    }
  },
  [`${AT.GET_SCAN_TOKEN_INSURANCE_CARD}_REQUEST`](state, action) {
    return {
      ...state,
      scanStatus: 'loading'
    }
  },
  [`${AT.GET_SCAN_TOKEN_INSURANCE_CARD}_SUCCEEDED`](state, action) {
    const { token } = action.payload;
    return {
      ...state,
      scanToken: token,
      scanStatus: 'token_showed'
    }
  },
  [`${AT.CHECK_SCAN_INSURANCE_CARD_RESULT}_SUCCEEDED`](state, action) {
    const { status, image } = action.payload;
    if (status === 'still_not') {
      // just skip      
    } else if (status === 'matched') {
      return {
        ...state,
        scanStatus: 'matched',
        scannedImage: image
      }
    } else if (status === 'incorrect') {
      return {
        ...state,
        scanStatus: 'incorrect',
        scannedImage: image
      }
    }
    return state;
  },
  [`${AT.CANCEL_SCAN_INSURANCE_CARD}`](state, action) {
    return {
      ...state,
      scanStatus: '',
      scanToken: '',
      scannedImage: ''
    }
  }
})
