import React, { useState } from 'react';
import { Input, Select } from 'antd';
import { ExpandSVG, SearchSVG } from '../../../common/components/icons/SVGIcon';

const { Search } = Input;
const { Option } = Select;

const PendingFilters = ({}) => {
  return (
    <div className='filterContainer'>
      <div className='searchFieldContainer'>
        <div className='searchField'>
          <Search
            placeholder="Search"
            onSearch={value => console.log(value)}
            style={{ width: '100%' }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className='statusFilterContainer'>
        <div className='statusFilter'>
          <Select placeholder="Filter by Disease Condition..." suffixIcon={<ExpandSVG />} allowClear={true}>
            <Option value="missing1">Condition 1</Option>
            <Option value="missing2">Condition 2</Option>
          </Select>
        </div>
      </div>

    </div>
  );
};

export default PendingFilters;
