import React, { Component } from "react";
import { Checkbox } from "antd";
import { HPIItem } from "../../../../common/components/AutoAccordionProcedure";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import DischargeSidebar from "./DischargeSidebar";
import { StaticResource, DischargeItems } from "../../constant";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import moment from "moment";
import {
  get_post_operative_procedure,
  setProcedureReview,
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
} from "../../../../store/actions";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const cookies = new Cookies();

let timer = null;
export class Discharge extends Component {
  state = {
    isSidebar: window.innerWidth <= 768 ? false : true,
    dataAll: {},
    post_operative_procedure_data: {},
    procedure_id_event: cookies.get("current_appointment_id"),
    procedure_id: cookies.get("current_patient_id"),
    post_operations_state: [],
    dataPost: [],
  };

  async componentDidMount() {
    await this.props.angioPatientSnapshotLoadInit(
      this.state.procedure_id_event
    );
    if (this.props.discharge) {
      let dataCheck = [];
      let name = "";
      const arraycheck = this.props.discharge.map((r) => {
        if (r.type) {
          if (r.type === "Patient Admission") {
            name = "patient_admission";
          } else if (r.type === "NPO Status") {
            name = "npo_status";
          } else if (r.type === "Contrast Allergy") {
            name = "contrast_allergy";
          } else if (r.type === "Latex Allergy") {
            name = "latex_allergy";
          } else if (r.type === "Anticoagulation") {
            name = "anticoagulation";
          } else if (r.type === "Last Anticoagulation") {
            name = "last_anticoagulation";
          } else if (r.type === "Neurological") {
            name = "neurological";
          } else if (r.type === "Breath Sounds") {
            name = "breath_sounds";
          } else if (r.type === "Respiratory Pattern") {
            name = "respiratory_pattern";
          } else if (r.type === "Cardiac") {
            name = "cardiac";
          } else if (r.type === "INR") {
            name = "inr";
          } else if (r.type === "Creatinine") {
            name = "creatinine";
          } else if (r.type === "ASA Classification") {
            name = "asa_classification";
          } else if (r.type === "Reviewed") {
            name = "reviewed";
          } else if (r.type === "Informed") {
            name = "informed";
          } else if (r.type == "Recovery Entry Time") {
            name = "recovery_entry_time";
          } else if (r.type == "Recovery Bed") {
            name = "recovery_bed";
          } else if (r.type == "Accepting Nurse") {
            name = "accepting_nurse";
          } else if (r.type == "Notes") {
            name = "notes";
          } else {
            name = r.type;
          }
          dataCheck = [
            ...dataCheck,
            { field_name: name, field_value: r.value },
          ];
        }
        return {
          type: r.type,
          comments: "",
          default_comments: "",
          value: r.value,
        };
      });
      if (this.props.dataDisCheckAll) {
        this.props.dataDisCheckAll([...arraycheck]);
      }
      this.setState({ dataPost: arraycheck });
      this.setState({
        post_operative_procedure_data: { fields: dataCheck },
      });
    }
  }

  // async componentDidMount() {
  //   let dataCheck = {};
  //   const post_operations_state = [];
  //   await this.props.get_post_operative_procedure(this.state.procedure_id);
  //   const { post_operative_procedure } = this.props;
  //   this.setState({
  //     post_operative_procedure_data: post_operative_procedure,
  //   });
  //   if (
  //     post_operative_procedure &&
  //     Object.keys(post_operative_procedure).length > 0
  //   ) {
  //     (post_operative_procedure.fields || []).map((r) => {
  //       const value =
  //         r.field_value && r.field_value != "" && r.field_value != null
  //           ? r.field_value
  //           : "";
  //       if (r.field_name === "recovery_entry_time") {
  //         dataCheck = { ...dataCheck, ["recovery_entry_time"]: value };
  //         post_operations_state.push({ type: 'Recovery Entry Time', value });
  //       } else if (r.field_name === "recovery_bed") {
  //         dataCheck = { ...dataCheck, ["recovery_bed"]: value };
  //         post_operations_state.push({ type: 'Recovery Bed', value });
  //       } else if (r.field_name === "accepting_nurse") {
  //         dataCheck = { ...dataCheck, ["accepting_nurse"]: value };
  //         post_operations_state.push({ type: 'Accepting Nurse', value });
  //       } else if (r.field_name === "notes") {
  //         dataCheck = { ...dataCheck, ["notes"]: value };
  //         post_operations_state.push({ type: 'Notes', value });
  //       } else if (r.field_name === "neurological") {
  //         dataCheck = { ...dataCheck, ["neurological"]: value };
  //         post_operations_state.push({ type: 'Neurological', value });
  //       } else if (r.field_name === "breath_sounds") {
  //         dataCheck = { ...dataCheck, ["breath_sounds"]: value };
  //         post_operations_state.push({ type: 'Breath Sounds', value });
  //       } else if (r.field_name === "respiratory_pattern") {
  //         dataCheck = { ...dataCheck, ["respiratory_pattern"]: value };
  //         post_operations_state.push({ type: 'Respiratory Pattern', value });
  //       } else if (r.field_name === "cardiac") {
  //         dataCheck = { ...dataCheck, ["cardiac"]: value };
  //         post_operations_state.push({ type: 'Cardiac', value });
  //       }
  //       this.setState({
  //         dataAll: dataCheck,
  //         post_operations_state,
  //       });
  //       this.props.setProcedureReview('post_operations_state', post_operations_state);
  //     });
  //   }
  // }

  // UNSAFE_componentWillReceiveProps() {
  // }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  onChangeOperations = (value, type) => {
    const { dataPost } = this.state;
    const operationIndex = dataPost.findIndex((pre) => pre.type === type);
    if (operationIndex > -1) {
      dataPost[operationIndex] = { type, value };
    } else {
      dataPost.push({ type, value });
    }
    this.setState({ dataPost });
    this.props.dataDisCheckAll([...this.state.dataPost]);
    // this.props.setProcedureReview("post_operations", post_operations_state);
  };

  onChangeTimePicker = (value, type) => {
    const { dataAll } = this.state;
    const time = moment(value).format("hh:mm A");
    if (type === "Recovery Entry Time") {
      this.setState({ dataAll: { ...dataAll, recovery_entry_time: time } });
    } else {
      this.setState({ dataAll: { ...dataAll, [type]: time } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeSelect = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Recovery Bed") {
      this.setState({ dataAll: { ...dataAll, recovery_bed: value } });
    } else if (type === "Accepting Nurse") {
      this.setState({ dataAll: { ...dataAll, accepting_nurse: value } });
    } else {
      this.setState({ dataAll: { ...dataAll, [type]: value } });
    }
    this.onChangeOperations(value, type);
  };

  clickChangeRow = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Neurological") {
      this.setState({
        dataAll: {
          ...dataAll,
          neurological:
            dataAll &&
            dataAll["neurological"] &&
            dataAll["neurological"].some((r) => r === value)
              ? [...(dataAll["neurological"] || []).filter((r) => r !== value)]
              : [...(dataAll["neurological"] || []), value],
        },
      });
    } else if (type === "Breath Sounds") {
      this.setState({
        dataAll: {
          ...dataAll,
          breath_sounds:
            dataAll &&
            dataAll["breath_sounds"] &&
            dataAll["breath_sounds"].some((r) => r === value)
              ? [...(dataAll["breath_sounds"] || []).filter((r) => r !== value)]
              : [...(dataAll["breath_sounds"] || []), value],
        },
      });
    } else if (type === "Respiratory Pattern") {
      this.setState({
        dataAll: {
          ...dataAll,
          respiratory_pattern:
            dataAll &&
            dataAll["respiratory_pattern"] &&
            dataAll["respiratory_pattern"].some((r) => r === value)
              ? [
                  ...(dataAll["respiratory_pattern"] || []).filter(
                    (r) => r !== value
                  ),
                ]
              : [...(dataAll["respiratory_pattern"] || []), value],
        },
      });
    } else if (type === "Cardiac") {
      this.setState({
        dataAll: {
          ...dataAll,
          cardiac:
            dataAll &&
            dataAll["cardiac"] &&
            dataAll["cardiac"].some((r) => r === value)
              ? [...(dataAll["cardiac"] || []).filter((r) => r !== value)]
              : [...(dataAll["cardiac"] || []), value],
        },
      });
    } else {
      this.setState({ dataAll: { ...dataAll, [type]: value } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeInput = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Notes") {
      this.setState({ dataAll: { ...dataAll, notes: value } });
    } else {
      this.setState({ dataAll: { ...dataAll, [type]: value } });
    }
    this.onChangeOperations(value, type);
  };

  componentDidUpdate(nextprops, nextstate) {
    if (
      [...nextstate.post_operations_state] !==
        [...this.state.post_operations_state] &&
      this.state.post_operations_state.length > 0
    ) {
      if (nextstate.dataPost && nextstate.dataPost.length > 0) {
        nextstate.post_operations_state.map((r) => {
          const indexcheck = nextstate.dataPost.findIndex(
            (index) => index.type == r.type
          );
          if (
            this.state.dataPost[indexcheck] &&
            this.state.dataPost[indexcheck]["value"]
          ) {
            this.state.dataPost[indexcheck]["value"] = r.value;
          }
        });
      }
    }
  }
  
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  
  render() {
    const { isSidebar } = this.state;

    return (
      <div className="clinicWorkflow__main-section">
        <div
          className="eventsContainer has-sub-header"
          style={{ paddingTop: "1rem" }}
        >
          <ScrollWrapper css="no-padding x-hidden">
            <div className="conditionList" style={{ minHeight: "100%" }}>
              <div className="listSection">
                {DischargeItems.map((condition, index) => (
                  <HPIItem
                    key={`ros-vitals-${index}`}
                    label={condition.label}
                    isIcon={condition.isIcon}
                    options={condition.options}
                    type={condition.type}
                    value={this.state.post_operative_procedure_data}
                    multiple={condition.multiple}
                    multiline={condition.multiLine}
                    onChangeTimePicker={(value, type) =>
                      this.onChangeTimePicker(value, type)
                    }
                    onChangeSelect={(value, type) =>
                      this.onChangeSelect(value, type)
                    }
                    clickChangeRow={(value, type) => {
                      this.clickChangeRow(value, type);
                    }}
                    onChangeInput={(value, type) => {
                      this.onChangeInput(value, type);
                    }}
                    isLocked={this.props.is_locked}
                  />
                ))}
              </div>
            </div>
          </ScrollWrapper>
        </div>
        {isSidebar === true && (
          <DischargeSidebar
            ref={this.sidebarRef}
            value={this.state.post_operative_procedure_data}
            resource={StaticResource}
            dataAll={this.state.dataAll}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
          />
        )}
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={this.sidebarRef} sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  // post_operative_procedure: state.procedure.post_operative_procedure,
  discharge: state.patientExamination.discharge,
  is_locked: state.common.is_locked
});

const mapDispatchToProps = {
  get_post_operative_procedure,
  setProcedureReview,
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Discharge);
