import React, { useState } from "react";
import TopBar from "../../../common/components/SubTopBar";
import DetailsPage from "./KnowledgeBaseComponent";
import { TabHeaders } from "../constant";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { useSelector } from "react-redux";

const KnowledgeBaseModule = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Knowledge Base" subStatus={""} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <ScrollWrapper css="no-padding tab-header-scroll">
              <div style={{ display: "flex" }}>
                {TabHeaders.map((tab, index) => (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? 'active' : ''}`}
                    style={{ width: tab.width }}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
        {activeTab === 0 && <DetailsPage title="knowledge-base-detail" />}
      </div>
    </div>
  );
}

export default KnowledgeBaseModule;
