import React, { useState, useMemo, useEffect } from "react";
import { message, Upload, Select, Checkbox, DatePicker } from "antd";
import { BASE_API } from "../../constants/constants";
import { BarcodeSVG } from "../icons/FaxSVG";
import { ExpandSVG } from "../icons/SVGIcon";
import moment from "moment";
import YesNo from "../YesNo";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  put_patient_document,
  get_filter_patient_document,
} from "../../../store/actions";

const cookies = new Cookies();
const { Dragger } = Upload;
const { Option } = Select;

const BarcodeUpload = ({ draggerProps, sideComponent }) => {
  const [selectedCategories, setCategories] = useState([]);
  const [files, setFiles] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [category, setCategory] = useState("");
  const [internal, setInternal] = useState(undefined);
  const [date, setDate] = useState(null);
  const providers = useSelector((state) => state.common.providers);
  const toastPutBarCode = useSelector((state) => state.common.toastPutBarCode);
  const [provider, setProvider] = useState(undefined);
  const patient_id = cookies.get("current_patient_id");
  const document_id = cookies.get("id_document_response");
  const dispatch = useDispatch();

  const defaultDraggerProps = {
    name: "file",
    multiple: true,
    action: BASE_API + `patients/${patient_id}/upload`,
  };

  const onChangeUpload = (info) => {
    if (info && Object.keys(info).length > 0 && info.file) {
      if (info.file.response && Object.keys(info.file.response).length > 0) {
        if (info.file.response.document_id) {
          cookies.set("id_document_response", info.file.response.document_id);
        }
      }
    }
    const { status } = info.file;

    if (info.file || info.fileList) {
      setFiles(info.file || info.fileList);
    }
    if (status !== "uploading") {
    }
    if (status === "uploading") {
      if (uploaded) {
        setUploaded(false);
      }
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setUploaded(true);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploaded(true);
    }
  };

  const handleChagneCategory = (value) => {
    setCategory(value);
  };

  const handleDate = (date, dateString) => {
    setDate(dateString);
  };

  const saveAll = async () => {
    const data = {
      provider_id: provider,
      category: category,
      internal: internal === "yes" ? 1 : 0,
      created_at: date,
    };
    await dispatch(put_patient_document(document_id, data));
    const params = {
      patient_id: patient_id,
      category: "",
      provider_id: "",
      created_at: "",
    };
    await dispatch(get_filter_patient_document(params));
  };

  const dragProps = useMemo(() => {
    if (draggerProps) {
      return draggerProps;
    }
    return defaultDraggerProps;
  }, [draggerProps]);

  return (
    <div
      className="side-component"
      style={{
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      {files && (
        <React.Fragment>
          <Select
            placeholder="Category"
            suffixIcon={<ExpandSVG />}
            onChange={handleChagneCategory}
            className="hide-tablet"
            style={{
              minWidth: "216px",
              marginRight: "0.5rem",
            }}
          >
            <Option value="Laboratory">Laboratory</Option>
            <Option value="New Patient Pack">Referral Packet</Option>
            <Option value="operative_reports">Operative Reports</Option>
            <Option value="consultation_notes">Consultation Notes</Option>
            <Option value="clinical_data">Clinical Data</Option>
            <Option value="imaging_reports">Imaging Reports</Option>
            <Option value="images">Images</Option>
            <Option value="employee_time_data">Employee Time Data</Option>
          </Select>
          <Select
            placeholder="Providers"
            className="hide-tablet"
            style={{
              minWidth: "161px",
              marginRight: "0.5rem",
              marginLeft: "0px",
            }}
            value={provider}
            onChange={setProvider}
          >
            {providers.map((provider, index) => (
              <Option key={`upload-box-provider-${index}`} value={provider.id}>
                {provider.value}
              </Option>
            ))}
          </Select>
          {category === "Laboratory" && (
            <DatePicker
              value={date ? moment(date) : null}
              format={"MM/DD/YYYY"}
              placeholder={"Date"}
              suffixIcon={<ExpandSVG />}
              onChange={handleDate}
              className="hide-tablet"
              style={{
                minWidth: "140px",
                marginRight: "0.5rem",
              }}
            />
          )}
          {category === "Laboratory" && (
            <Select
              placeholder="Internal"
              suffixIcon={<ExpandSVG />}
              onChange={setInternal}
              value={internal}
              className="hide-tablet"
              style={{
                minWidth: "120px",
                marginRight: "0.5rem",
                marginLeft: "0px",
              }}
            >
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          )}
          <button
            className="common-btn blue-btn hide-tablet"
            onClick={saveAll}
            style={{
              border: 0,
              paddingRight: "12px",
              minWidth: "130px",
              marginRight: "0.5rem",
              marginLeft: "0px",
            }}
          >
            Save
          </button>
        </React.Fragment>
      )}
      {sideComponent && sideComponent}
      <Dragger
        {...dragProps}
        onChange={onChangeUpload}
        className="barcode-uploader"
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <BarcodeSVG />
        </p>
      </Dragger>
    </div>
  );
};

export default BarcodeUpload;
