import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";

export const registerNewPatient = createActionThunk(
  AT.REGISTER_NEW_PATIENT,
  (params) => API.addNewPatient(params)
);
export const getPatientsByQuery = createActionThunk(
  AT.GET_PATIENTS_WITH_QUERY,
  (query) => API.getPatients(query)
);
export const setPatientHistory = createActionThunk(
  AT.SET_PATIENT_HISTORY,
  (patient) => patient
);

export const getPatientSearchHistory = createActionThunk(
  AT.GET_SEARCH_HISTORY_LIST,
  () => API.getSearchHistory()
);

export const addPatientSearchHistory = createActionThunk(
  AT.ADD_SEARCH_HISTORY,
  (patientId) => API.addSearchHistory(patientId)
);

export const getGlobalMessages = createActionThunk(AT.GET_GLOBAL_MESSAGES, () =>
  API.getGlobalMessages()
);

export const addGlobalMessage = createActionThunk(
  AT.ADD_GLOBAL_MESSAGE,
  (message) => API.addGlobalMessage(message)
);

export const deleteGlobalMessage = createActionThunk(
  AT.DELETE_GLOBAL_MESSAGE,
  (id) => API.deleteGlobalMessage(id)
);

export const save_quick_register = createActionThunk(
  AT.SAVE_QUICK_MESSAGE,
  (param) => API.save_quick_register(param)
);

export const setDashboardDemographics = createActionThunk(
  AT.SET_DASHBORD_PATIENT_DEMOGRAPHICS,
  (demographic) => ({ demographic })
);

export const setDashboardAppointments = createActionThunk(
  AT.SET_DASHBOARD_APPOINTMENTS,
  (type, data) => ({ type, data })
);
