import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import { useWindowSize } from '../../../../common/utils';
import { StaticResource, DashboardRows } from '../../constant';
import Loading from "../../../../common/components/Loading";
import ReceivingSidebar from './ReceivingSidebar';
import ReceivingFilter from './ReceivingFilter';
import {
  LeftCharvenSVG, RedCloseSVG,
  CheckSVG, WaitingSVG, CreditReceivedSVG
} from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import {
  getReceivingOrders,
  setInventoryData
} from "../../../../store/actions";
import moment from 'moment';
import SidebarExtension from '../../../../common/components/SidebarExtension';
import { Icon } from 'antd';
import DataTable from '../../../../common/components/DataTable/DataTable';

const filterDataByOption = (data, option) => {
  const { search, unit, purchase_number, user, requestedDate } = option;
  let filteredData = data;
  if (!search && !unit && !purchase_number && !user && (!requestedDate || !moment(requestedDate).isValid())) {
    return filteredData;
  }
  if (search) {
    filteredData = filteredData.filter(d => d.name && d.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }
  if (unit) {
    filteredData = filteredData.filter(d => d.unit && d.unit.toLowerCase() === unit.toLowerCase());
  }
  if (user) {
    filteredData = filteredData.filter(d => d.added_user && d.added_user === user);
  }
  if (purchase_number) {
    filteredData = filteredData.filter(d => d.purchase_number && d.purchase_number >= purchase_number);
  }
  if (requestedDate && moment(requestedDate).isValid()) {
    filteredData = filteredData.filter(d => d.requested_date && moment(d.requested_date).diff(moment(requestedDate)) >= 0);
  }
  return filteredData;
};

const Receiving = ({ data, title, loading }) => {
  const receivings = useSelector(state => state.inventory.receivings);
  const users = useSelector(state => state.inventory.users);
  const receivingOrder = useSelector(state => state.inventory.receivingOrder);
  const filterOption = useSelector(state => state.inventory.orderFilter);

  const dispatch = useDispatch();

  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [initialSize, setSize] = useState({ width: undefined, height: undefined});

  const tableRows = useMemo(() => {
    if (receivings && receivings.length) {
      return filterDataByOption(receivings, filterOption);
    }
    return [];
  }, [receivings, filterOption]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleSidebar = () => {
    setIsSidebar(true);
  }

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  }

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  }

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData('receivingOrder', rowData[index]));
    handleSidebar();
  }

  useEffect(() => {
    console.log('ref changed');
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight
      }
      setSize(initialSize);
    }
  }, [ref]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const onScroll = (values) => {
    if (values.scrollTop > values.scrollHeight - values.clientHeight - 80 && offset < tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  }

  const getUserName = (userId) => {
    const findUser = users.find(user => user.id === userId);
    if (findUser) {
      return findUser.name;
    }
    return '';
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <ReceivingFilter />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="receiving_list"
                loadMore={onScroll}
                defaultActive={-1}
                hideHeader={true}
                disableDelete={true}
                labels={['Name', 'Purchase Number', 'Unit', 'Requested Date', 'Added User']}
                widths={['44.3%', '130px', '130px', 'calc(35.2% - 260px)', 'calc(35.2% - 260px)']}
                columns={[
                  { sortable: false, key: 'name' },
                  { sortable: false, key: 'purchase_number' },
                  { sortable: false, key: 'unit' },
                  { sortable: false, key: 'requested_date' },
                  { sortable: false, key: 'added_user' },
                ]}
                rowData={rowData.map((row, index) => {
                  return { 
                    name: (
                      <div 
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {
                          row.type === 'narcotic'
                          ? <Icon type="experiment" theme="twoTone" twoToneColor="#0043CE"/>
                          : row.type === 'angiographic'
                          ? <Icon type="tag" theme="twoTone" twoToneColor="#6929C4"/>
                          : <Icon type="shop" theme="twoTone" twoToneColor="#9F1853"/>
                        }
                        <p style={{ paddingLeft: "0.75rem" }} >{row.name || ''}</p>
                      </div>
                    ),
                    purchase_number: row.purchase_number,
                    unit: row.unit,
                    requested_date: row.requested_date && moment(row.requested_date).format("MM/DD/YYYY"),
                    added_user: getUserName(row.added_user),
                  }
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {receivingOrder && activeIndex >= 0 && (
        <ReceivingSidebar
          ref={sidebarRef}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
      {receivingOrder && activeIndex >= 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Receiving;
