import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import moment from "moment";
import Cookies from "universal-cookie";

import { getLocations, setGlobalLoading } from "../../../../store/actions";
import dataHeader from "./formarterData";

const cookies = new Cookies();

const ReviewText = ({ changeValueText }) => {
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const authName = cookies.get("cvai-current-user-provider-name");
  const hpi_conditions = useSelector(
    (state) => state.clinicProviderReview.hpi_conditions
  );
  const ROSs = useSelector((state) => state.clinicProviderReview.ROSs);
  const physical_examinations = useSelector(
    (state) => state.clinicProviderReview.physical_examinations
  );
  const vitals = useSelector((state) => state.clinicProviderReview.vitals);
  const vessel_states = useSelector(
    (state) => state.clinicProviderReview.vessel_states
  );
  const icd_codes = useSelector(
    (state) => state.clinicProviderReview.icd_codes
  );
  const medical_history = useSelector(
    (state) => state.clinicProviderReview.medical_history
  );
  const previous_surgical_histories = useSelector(
    (state) => state.clinicProviderReview.previous_surgical_histories
  );

  const medications = useSelector(
    (state) => state.clinicProviderReview.medications
  );

  const allergies = useSelector(
    (state) => state.clinicProviderReview.allergies
  );

  const family_history = useSelector(
    (state) => state.clinicProviderReview.family_history
  );

  const chiefComplaint = useSelector(
    (state) => state.clinicProviderReview.chiefComplaint
  );

  const social_history = useSelector(
    (state) => state.clinicProviderReview.social_history
  );

  const is_locked = useSelector(
    (state) => state.clinicProviderReview.is_locked
  );

  const descList = useSelector((state) => state.clinicProviderReview.descList);

  const contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
  const editorState = EditorState.createWithContent(contentState);
  const [text, setText] = useState(editorState);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (patientResource && Object.keys(patientResource).length > 0) {
      setLoading(true);
      dispatch(setGlobalLoading(true));
      let data = JSON.stringify(convertToRaw(text.getCurrentContent()));
      // header 1
      const [head1, ...itemHead1] =
        data && data.split(`},{"key":"text1","text":"`);
      const checkHead1 = (itemHead1 || []).map((item) => {
        return item.split(`",`)[0];
      });
      if (checkHead1) {
        data = data.replace(
          `{"key":"text1","text":"${checkHead1[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text1","text":"${
            patientResource.first_name && patientResource.last_name
              ? patientResource.first_name + " " + patientResource.last_name
              : ""
          }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // header 2
      const [head2, ...itemHead2] =
        data && data.split(`},{"key":"text2","text":"`);
      const checkHead2 = (itemHead2 || []).map((item) => {
        return item.split(`",`)[0];
      });
      if (checkHead2) {
        data = data.replace(
          `{"key":"text2","text":"${checkHead2[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text2","text":"${
            patientResource.id ? patientResource.id : ""
          }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"nonet2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      let plan_text = "";
      let patient_active_text = "";
      let hip_text = "";
      let footer_text = "";
      let data_review_text = "";

      for (let key in descList) {
        if (descList.hasOwnProperty(key)) {
          let row = descList[key];
          for (let dec_row of row) {
            data_review_text += `${
              dec_row
                ? `{"key":"keyemtdec${key}-${dec_row.title}-${
                    dec_row.date
                  }","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keydec${key}-${
                    dec_row.title
                  }-${dec_row.date}","text":"${dec_row.title} US (${
                    dec_row.date
                  })","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                    dec_row.title.length + 6 + dec_row.date.length
                  },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},
                  {"key":"keyfindleft${key}-${dec_row.title}-${
                    dec_row.date
                  }","text":"Left Findings: ${
                    dec_row.values[0].value ? dec_row.values[0].value : ""
                  }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},
                  {"key":"keyfindright${key}-${dec_row.title}-${
                    dec_row.date
                  }","text":"Right Findings: ${
                    dec_row.values[1].value ? dec_row.values[1].value : ""
                  }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},
                  {"key":"keyconcsleft${key}-${dec_row.title}-${
                    dec_row.date
                  }","text":"Left Conclusion: ${
                    dec_row.values[2].value ? dec_row.values[2].value : ""
                  }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":16,"style":"BOLD"}],"entityRanges":[],"data":{}},
                  {"key":"keyconcsright${key}-${dec_row.title}-${
                    dec_row.date
                  }","text":"Right Conclusion: ${
                    dec_row.values[3].value ? dec_row.values[3].value : ""
                  }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":16,"style":"BOLD"}],"entityRanges":[],"data":{}},`
                : ""
            }`;
          }
        }
      }
      //
      for (let key in hpi_conditions) {
        if (key === "General") {
          if (hpi_conditions.hasOwnProperty(key)) {
            let row = hpi_conditions[key];
            for (let sub_row of row) {
              if (sub_row["plans"]) {
                for (let plans_row of sub_row["plans"]) {
                  plan_text += `${
                    plans_row
                      ? `{"key":"keyid${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                          key.length + 1
                        },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                      : ""
                  }`;
                }
              }
            }
          }
        }
      }

      for (let key in hpi_conditions) {
        if (key !== "General") {
          if (hpi_conditions.hasOwnProperty(key)) {
            let row = hpi_conditions[key];
            for (let sub_row of row) {
              if (sub_row["plans"] && sub_row["plans"].length > 0) {
                for (let plans_row of sub_row["plans"]) {
                  plan_text += `${
                    plans_row
                      ? `{"key":"keyid${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                          key.length + 1
                        },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                      : ""
                  }`;
                }
              } else {
                plan_text += `{"key":"keyid${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                  key.length + 1
                },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"keyplanone${key}","text":"None","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              }
              patient_active_text += `{"key":"keyptac${key}","text":"${key}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              // text_template
              if (sub_row["text_template"].includes("blocks")) {
                let valueImport = sub_row["text_template"].replace(
                  `{"blocks":[`,
                  ""
                );
                if (valueImport.includes(`"entityMap":[{`)) {
                  const [enti, ...entis] =
                    valueImport && valueImport.split(`],"entityMap":[{"`);
                  const checkEnti = (entis || []).map((item) => {
                    return item.split(`]}`)[0];
                  });
                  if (checkEnti && checkEnti[0].length > 0) {
                    valueImport = valueImport.replace(
                      `],"entityMap":[{"${checkEnti[0]}]}`,
                      ``
                    );
                  }
                  if (valueImport.includes(`],"entityMap":[]}`)) {
                    valueImport = valueImport.replace(`],"entityMap":[]}`, "");
                  }
                }
                if (valueImport && valueImport.length > 0) {
                  hip_text += `{"key":"textspace${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keythip${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                    key.length + 1
                  },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},${valueImport},`;
                  hip_text = hip_text.replace(`],"entityMap":[]}`, "");
                }
              }
              if (sub_row["footer_template"].includes("blocks")) {
                // footer_template
                let valueImportFooter = sub_row["footer_template"].replace(
                  `{"blocks":[`,
                  ""
                );
                if (valueImportFooter.includes(`"entityMap":[{`)) {
                  const [enti, ...entis] =
                    valueImportFooter &&
                    valueImportFooter.split(`],"entityMap":[{"`);
                  const checkEnti = (entis || []).map((item) => {
                    return item.split(`]}`)[0];
                  });
                  if (checkEnti && checkEnti[0].length > 0) {
                    valueImportFooter = valueImportFooter.replace(
                      `],"entityMap":[{"${checkEnti[0]}]}`,
                      ``
                    );
                  }
                  if (valueImportFooter.includes(`],"entityMap":[]}`)) {
                    valueImportFooter = valueImportFooter.replace(
                      `],"entityMap":[]}`,
                      ""
                    );
                  }
                } else {
                  valueImportFooter = valueImportFooter.replace(
                    `],"entityMap":{}}`,
                    ``
                  );
                }
                if (valueImportFooter && valueImportFooter.length > 0) {
                  footer_text += `{"key":"textspaceend${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keydisc${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                    key.length + 1
                  },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},${valueImportFooter},`;
                  footer_text = footer_text.replace(`],"entityMap":[]}`, "");
                }
              }
            }
          }
        }
      }
      let ros_text = "";
      for (let row of ROSs) {
        ros_text += `{"key":"keyros${row.type}","text":"${row.type}: ${
          row.comments
        }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
          row.type.length + 1
        },"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }
      if (hip_text && hip_text.length > 0) {
        // header 3
        const [head3, ...itemHead3] =
          data &&
          data.split(
            `{"key":"6qn3","text":"HISTORY OF PRESENTING ILLNESS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":29,"style":"BOLD"},{"offset":0,"length":29,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead3 = (itemHead3 || []).map((item) => {
          return item.split(
            `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(hip_text)) {
          data = data.replace(
            `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${hip_text}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }
      if (ros_text && ros_text.length > 0) {
        // header 4
        const [head4, ...itemHead4] =
          data &&
          data.split(
            `{"key":"text4empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},`
          );
        const checkHead4 = (itemHead4 || []).map((item) => {
          return item.split(
            `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(ros_text)) {
          data = data.replace(
            `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `{"key":"textempty4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${ros_text}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }
      let vitals_text = "";
      for (let row of vitals) {
        vitals_text += `{"key":"keyvit${row.type}","text":"${row.type}: ${
          row.value
        }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
          row.type.length + 1
        },"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }
      if (vitals_text && vitals_text.length > 0) {
        // header 5
        const [head5, ...itemHead5] =
          data &&
          data.split(
            `{"key":"e3e6l","text":"OBJECTIVE","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"UNDERLINE"},{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}},`
          );
        const checkHead5 = (itemHead5 || []).map((item) => {
          return item.split(
            `{"key":"text5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(vitals_text)) {
          data = data.replace(
            `{"key":"text5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `{"key":"textempty5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${vitals_text}{"key":"text5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let vessel_states_text_right = "";
      let vessel_states_text_left = "";
      let physical_examinations_text = "";

      for (let row of vessel_states) {
        if (row.type.includes("Right ") && row.type && row.type.length > 0) {
          vessel_states_text_right += `${
            row.value && row.type
              ? ` ${row.type.replace("Right ", "").toUpperCase()}: ${
                  row.value
                }${row.value == "2" ? "+" : ""},`
              : ""
          }`;
        } else if (
          row.type.includes("Left ") &&
          row.type &&
          row.type.length > 0
        ) {
          vessel_states_text_left += `${
            row.value && row.type
              ? ` ${row.type.replace("Left ", "").toUpperCase()}: ${row.value}${
                  row.value == "2" ? "+" : ""
                },`
              : ""
          }`;
        }
      }

      for (let row of physical_examinations) {
        physical_examinations_text += `{"key":"key${row.type}","text":"${
          row.type
        }: ${
          row.comments
        }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
          row.type.length + 1
        },"style":"BOLD"}],"entityRanges":[],"data":{}},${
          row.type == "Extremities"
            ? `{"key":"rplus","text":"Right Pulses: ${
                vessel_states_text_right
                  ? vessel_states_text_right.substr(
                      0,
                      vessel_states_text_right.length - 1
                    )
                  : ""
              }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"rlpes","text":"Left Pulses: ${
                vessel_states_text_left
                  ? vessel_states_text_left.substr(
                      0,
                      vessel_states_text_left.length - 1
                    )
                  : ""
              }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}},`
            : ""
        }`;
      }

      if (physical_examinations_text && physical_examinations_text.length > 0) {
        // header 6
        const [head6, ...itemHead6] =
          data &&
          data.split(
            `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},`
          );
        const checkHead6 = (itemHead6 || []).map((item) => {
          return item.split(
            `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(physical_examinations_text)) {
          data = data.replace(
            `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${physical_examinations_text}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let icd_codes_text = "";
      for (let row of icd_codes) {
        icd_codes_text += `${
          row.icd_name
            ? `{"key":"keyicd${row.icd_name}","text":"${row.icd_name}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            : ""
        }`;
      }
      if (icd_codes_text && icd_codes_text.length > 0) {
        // header 8
        const [head8, ...itemHead8] =
          data &&
          data.split(
            `{"key":"7qqlb","text":"ASSESSMENT","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"UNDERLINE"},{"offset":0,"length":10,"style":"BOLD"}],"entityRanges":[],"data":{}},`
          );
        const checkHead8 = (itemHead8 || []).map((item) => {
          return item.split(
            `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(icd_codes_text)) {
          data = data.replace(
            `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `{"key":"textempty8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${icd_codes_text}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }
      if (plan_text && plan_text.length > 0) {
        // header 9
        const [head9, ...itemHead9] =
          data &&
          data.split(
            `{"key":"fjqg5","text":"PLAN","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":4,"style":"BOLD"},{"offset":0,"length":4,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead9 = (itemHead9 || []).map((item) => {
          return item.split(
            `{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(plan_text)) {
          data = data.replace(
            `{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `{"key":"textempty9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${plan_text}{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }
      let medical_history_text = "";
      if (medical_history && medical_history.length > 0) {
        for (let row of medical_history) {
          medical_history_text += `{"key":"keymedi${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }

      if (footer_text && footer_text.length > 0) {
        // header 10
        const [head10, ...itemHead10] =
          data &&
          data.split(
            `{"key":"discu1s","text":"DISCUSSION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"},{"offset":0,"length":10,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead10 = (itemHead10 || []).map((item) => {
          return item.split(
            `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(footer_text)) {
          data = data.replace(
            `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${footer_text}{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      if (medical_history_text && medical_history_text.length > 0) {
        // header 11
        const [head11, ...itemHead11] =
          data &&
          data.split(
            `{"key":"0i92j","text":"PAST MEDICAL HISTORY","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":20,"style":"BOLD"},{"offset":0,"length":20,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead11 = (itemHead11 || []).map((item) => {
          return item.split(
            `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(medical_history_text)) {
          data = data.replace(
            `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${medical_history_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let previous_surgical_histories_text = "";
      for (let row of previous_surgical_histories) {
        previous_surgical_histories_text += `${
          !row.id
            ? `{"key":"keyprev${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            : `{"key":"keyprev${row.id}${row.procedure_name}","text":"${row.procedure_name}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        }`;
      }
      if (
        previous_surgical_histories_text &&
        previous_surgical_histories_text.length > 0
      ) {
        // header 12
        const [head12, ...itemHead12] =
          data &&
          data.split(
            `{"key":"3d92j","text":"PAST SURGICAL HISTORY","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":21,"style":"BOLD"},{"offset":0,"length":21,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead12 = (itemHead12 || []).map((item) => {
          return item.split(
            `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (checkHead12 && checkHead12[0] && checkHead12[0].length > 0) {
          data = data.replace(
            `${checkHead12[0]},{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${previous_surgical_histories_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        } else {
          data = data.replace(
            `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${previous_surgical_histories_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let family_history_text = "";
      for (let row of family_history) {
        family_history_text += `${
          row
            ? `{"key":"keyprev${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            : ""
        }`;
      }

      if (family_history_text && family_history_text.length > 0) {
        // header 15
        const [head15, ...itemHead15] =
          data &&
          data.split(
            `{"key":"smxksj","text":"SOCIAL HISTORY","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"},{"offset":0,"length":14,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead15 = (itemHead15 || []).map((item) => {
          return item.split(
            `{"key":"text15","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(family_history_text)) {
          data = data.replace(
            `{"key":"text15","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${family_history_text}{"key":"text15","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let social_history_text = "";
      for (let row of social_history) {
        social_history_text += `${
          row
            ? `{"key":"keyprev${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            : ""
        }`;
      }

      if (social_history_text && social_history_text.length > 0) {
        // header 15
        const [head16, ...itemHead16] =
          data &&
          data.split(
            `{"key":"fmlis","text":"FAMILY HISTORY","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"},{"offset":0,"length":14,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead15 = (itemHead16 || []).map((item) => {
          return item.split(
            `{"key":"text16","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(social_history_text)) {
          data = data.replace(
            `{"key":"text16","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${social_history_text}{"key":"text16","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let medications_text = "";
      if (medications.legnth > 0) {
        for (let row of medications) {
          medications_text += `${
            row.drug_name && row.id
              ? `{"key":"keymedi${row.id}","text":"${row.full_medication}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              : ""
          }`;
        }
      } else {
        medications_text += `${`{"key":"keymedi1","text":"No Known Medications","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`}`;
      }

      if (medications_text && medications_text.length > 0) {
        // header 13
        const [head13, ...itemHead13] =
          data &&
          data.split(
            `{"key":"xao2j","text":"MEDICATIONS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"},{"offset":0,"length":11,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead13 = (itemHead13 || []).map((item) => {
          return item.split(
            `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(medications_text)) {
          data = data.replace(
            `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${medications_text}{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let allergies_text = "";
      if (allergies.length > 0) {
        for (let row of allergies) {
          allergies_text += `${
            row?.allergy_name
              ? `{"key":"keyall${row?.allergy_name}","text":"${row?.allergy_name}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              : ""
          }`;
        }
      }

      if (allergies_text && allergies_text.length > 0) {
        // header 14
        const [head14, ...itemHead14] =
          data &&
          data.split(
            `{"key":"xmlsj","text":"ALLERGIES","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"},{"offset":0,"length":9,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead14 = (itemHead14 || []).map((item) => {
          return item.split(
            `{"key":"text14","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(allergies_text)) {
          data = data.replace(
            `{"key":"text14","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${allergies_text}{"key":"text14","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      if (data_review_text && data_review_text.length > 0) {
        // header 17
        const [head17, ...itemHead17] =
          data &&
          data.split(
            `{"key":"darev","text":"DATA REVIEWED","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"},{"offset":0,"length":14,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead17 = (itemHead17 || []).map((item) => {
          return item.split(
            `{"key":"text17","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(data_review_text)) {
          data = data.replace(
            `{"key":"text17","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${data_review_text}{"key":"text17","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let provider_text = "";
      if (authName && authName.length > 0) {
        provider_text = `{"key":"prvnmc","text":"${authName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        // header 18
        if (!data.includes(provider_text)) {
          data = data.replace(
            `{"key":"text18","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${provider_text}{"key":"text18","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      if (patient_active_text && patient_active_text.length > 0) {
        // header 21
        const [head21, ...itemHead21] =
          data &&
          data.split(
            `{"key":"pactp3","text":"PATIENT ACTIVE PROBLEM LIST","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":27,"style":"BOLD"},{"offset":0,"length":27,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead21 = (itemHead21 || []).map((item) => {
          return item.split(
            `{"key":"text21","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (!data.includes(patient_active_text)) {
          data = data.replace(
            `{"key":"text21","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${patient_active_text}{"key":"text21","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      let date_signed_text = "";
      date_signed_text = `{"key":"keydatesigned","text":"${moment().format(
        "MM/DD/YYYY"
      )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      if (
        date_signed_text &&
        date_signed_text.length > 0 &&
        !data.includes(date_signed_text)
      ) {
        data = data.replace(
          `{"key":"text20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${date_signed_text}{"key":"text20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      if (chiefComplaint) {
        if (!data.includes(chiefComplaint)) {
          data = data.replace(
            `{"key":"text22","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"chieftex","text":"${chiefComplaint}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text22","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
      }
      const contentState = convertFromRaw(JSON.parse(data));
      const editorState = EditorState.createWithContent(contentState);
      changeValueText(data);
      if (data.length > 6500) {
        setLoading(false);
        dispatch(setGlobalLoading(false));
      }
      setText(editorState);
    }
  }, [
    patientResource,
    hpi_conditions,
    ROSs,
    physical_examinations,
    vitals,
    vessel_states,
    icd_codes,
    medical_history,
    chiefComplaint,
  ]);

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  const onChange = (editorState) => {
    if (!loading) {
      setText(editorState);
      let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      changeValueText(data);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Editor
        toolbar={{
          options: ["inline", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            options: ["unordered", "ordered"],
          },
        }}
        editorStyle={{
          border: "1px solid #f2f2f2",
          padding: "10px",
          height: "100%",
          backgroundColor: "#fff",
        }}
        placeholder="Patient Review"
        editorState={loading ? EditorState.createEmpty() : text}
        onEditorStateChange={onChange}
        readOnly={is_locked}
      />
    </div>
  );
};

export default ReviewText;
