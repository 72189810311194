import React, { useState, useEffect } from 'react';
import AutoCompleteComponent from '../../../common/components/AutoComplete';

const filterSuggestion = (suggestions, userInput) => {
  let filteredSuggestions = [];
  if (userInput) {
    filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
  }
  return filteredSuggestions;
}

const HIPDocumentationComponent = ({ resource, handleContinue }) => {
  const [rows, setRows] = useState([]);
  const [searchInput, setInput] = useState('');

  useEffect(() => {}, [rows]);

  const handleSearch = (search) => {
    setInput(search);
  }
  const addRow = () => {
    const newRows = [...rows, { label: searchInput }];
    setRows(newRows);
  }
  const removeRow = (item) => {
    const rowIndex = rows.findIndex(row => row.label === item.label);
    const newRows = rows.splice(rowIndex, 1);
    setRows(newRows);
  }

  return (
    <div className="resourceContent">
      <div className="resourceInfo d-flex">
        <AutoCompleteComponent
          title="previous-medical"
          rows={filterSuggestion(rows, searchInput)}
          removeItem={removeRow}
          addItem={addRow}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default HIPDocumentationComponent
