import React, { Component } from "react";
import { HPIItem } from "../../../../common/components/AutoAccordionProcedure";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import PreOperativeSideBar from "./PreOperativeSidebar";
import { StaticResource, OperativesTemp } from "../../constant";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import moment from "moment";
import {
  get_pre_operative_procedure,
  setProcedureReview,
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
} from "../../../../store/actions";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { Checkbox, Icon } from "antd";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const cookies = new Cookies();

// let timer = null;
export class PreOperative extends Component {
  state = {
    isSidebar: window.innerWidth <= 768 ? false : true,
    dataAll: {},
    patient_id_event: cookies.get("current_appointment_id"),
    patient_id: cookies.get("current_patient_id"),
    pre_operative_procedure_data: {},
    pre_operations_state: [],
    dataPost: [],
    checkedAllNormalsP: false,
  };
  timer = null;

  async componentDidMount() {
    const pre = await this.props.angioPatientSnapshotLoadInit(
      this.state.patient_id_event
    );
    if (
      pre.all_normal_pre_operative == true ||
      pre.all_normal_pre_operative == 1
    ) {
      this.setState({
        checkedAllNormalsP: true,
      });
    } else {
      this.setState({
        checkedAllNormalsP: false,
      });
    }
    if (pre.pre_operative) {
      let dataCheck = [];
      let name = "";
      const arraycheck = pre.pre_operative.map((r) => {
        if (r.type) {
          if (r.type === "Patient Admission") {
            name = "patient_admission";
          } else if (r.type === "NPO Status") {
            name = "npo_status";
          } else if (r.type === "Contrast Allergy") {
            name = "contrast_allergy";
          } else if (r.type === "Latex Allergy") {
            name = "latex_allergy";
          } else if (r.type === "Anticoagulation") {
            name = "anticoagulation";
          } else if (r.type === "Last Anticoagulation") {
            name = "last_anticoagulation";
          } else if (r.type === "Breath Sounds") {
            name = "breath_sounds";
          } else if (r.type === "Respiratory Pattern") {
            name = "respiratory_pattern";
          } else if (r.type === "Cardiac") {
            name = "cardiac";
          } else if (r.type === "INR") {
            name = "inr";
          } else if (r.type === "Creatinine") {
            name = "creatinine";
          } else if (r.type === "ASA Classification") {
            name = "asa_classification";
          } else if (r.type === "Reviewed") {
            name = "reviewed";
          } else if (r.type === "Informed") {
            name = "informed";
          } else {
            name = r.type;
          }
          dataCheck = [
            ...dataCheck,
            {
              field_name: name,
              field_value: r.value || r.values || "",
              default_comments: r.default_comments,
              comments: r.comments,
            },
          ];
        }
        if (
          r.type == "Respiratory" ||
          r.type == "Cardiovascular" ||
          r.type == "Constitutional" ||
          r.type == "Neurological"
        ) {
          return {
            type: r.type,
            comments: r.comments,
            default_comments: r.default_comments,
            extra: r.extra,
            values: r.value || r.values || "",
          };
        } else {
          return {
            type: r.type,
            comments: r.comments,
            default_comments: r.default_comments,
            extra: r.extra,
            value: r.value || r.values || "",
          };
        }
      });
      if (this.props.dataPostCheckAll) {
        this.props.dataPostCheckAll(
          [...arraycheck],
          pre.all_normal_pre_operative == true ||
            pre.all_normal_pre_operative == 1
            ? true
            : false
        );
      }
      this.setState({ dataPost: arraycheck });
      this.setState({
        pre_operative_procedure_data: { fields: dataCheck },
      });
    }
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  onChangeOperations = async (value, type) => {
    const { pre_operations_state } = this.state;
    const operationIndex = pre_operations_state.findIndex(
      (pre) => pre.type === type
    );
    if (operationIndex > -1) {
      pre_operations_state[operationIndex] = { type, value };
    } else {
      pre_operations_state.push({ type, value });
    }
    this.setState({ pre_operations_state });
    pre_operations_state.map((r) => {
      const indexcheck = this.state.dataPost.findIndex(
        (index) => index.type == r.type
      );
      this.state.dataPost[indexcheck]["value"] = r.value;
    });
    if (this.props.dataPostCheckAll) {
      this.props.dataPostCheckAll(
        [...this.state.dataPost],
        this.state.checkedAllNormalsP
      );
    }
 
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.patientExaminationSave(this.state.patient_id_event, {
        patient_id: this.state.patient_id,
        pre_operative: [...this.state.dataPost],
        all_normal_pre_operative: this.state.checkedAllNormalsP,
      });
    }, 500);
  };

  onChangeTimePicker = (value, type) => {
    const { dataAll } = this.state;
    const time = moment(value).format("hh:mm A");
    if (type === "Patient Admission") {
      this.setState({ dataAll: { ...dataAll, patient_admission: time } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeDatePicker = (value, type) => {
    const { dataAll } = this.state;
    const time = moment(value).format("MM/DD/YYYY");
    if (type === "Last Anticoagulation") {
      this.setState({ dataAll: { ...dataAll, last_anticoagulation: time } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeSelect = (value, type) => {
    const { dataAll } = this.state;
    if (type === "NPO Status") {
      this.setState({ dataAll: { ...dataAll, npo_status: value } });
    } else if (type === "Anticoagulation") {
      this.setState({ dataAll: { ...dataAll, anticoagulation: value } });
    } else if (type === "ASA Classification") {
      this.setState({ dataAll: { ...dataAll, asa_classification: value } });
    }
    this.onChangeOperations(value, type);
  };

  clickChangeRow = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Contrast Allergy") {
      this.setState({
        dataAll: {
          ...dataAll,
          contrast_allergy: value,
        },
      });
    } else if (type === "Latex Allergy") {
      this.setState({
        dataAll: {
          ...dataAll,
          latex_allergy: value,
        },
      });
    } else if (type === "Breath Sounds") {
      this.setState({
        dataAll: {
          ...dataAll,
          breath_sounds:
            dataAll &&
            dataAll["breath_sounds"] &&
            dataAll["breath_sounds"].some((r) => r === value)
              ? [...(dataAll["breath_sounds"] || []).filter((r) => r !== value)]
              : [...(dataAll["breath_sounds"] || []), value],
        },
      });
    } else if (type === "Respiratory Pattern") {
      this.setState({
        dataAll: {
          ...dataAll,
          respiratory_pattern:
            dataAll &&
            dataAll["respiratory_pattern"] &&
            dataAll["respiratory_pattern"].some((r) => r === value)
              ? [
                  ...(dataAll["respiratory_pattern"] || []).filter(
                    (r) => r !== value
                  ),
                ]
              : [...(dataAll["respiratory_pattern"] || []), value],
        },
      });
    } else if (type === "Cardiac") {
      this.setState({
        dataAll: {
          ...dataAll,
          cardiac:
            dataAll &&
            dataAll["cardiac"] &&
            dataAll["cardiac"].some((r) => r === value)
              ? [...(dataAll["cardiac"] || []).filter((r) => r !== value)]
              : [...(dataAll["cardiac"] || []), value],
        },
      });
    } else {
      this.setState({
        dataAll: {
          ...dataAll,
          [`${type}`]: value,
        },
      });
    }
    this.onChangeOperations(value, type);
  };

  onChangeInput = (value, type) => {
    const { dataAll } = this.state;
    if (type === "INR") {
      this.setState({ dataAll: { ...dataAll, inr: value } });
    } else if (type === "Creatinine") {
      this.setState({ dataAll: { ...dataAll, creatinine: value } });
    }
    this.onChangeOperations(value, type);
  };

  onCheckBox = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Reviewed") {
      this.setState({ dataAll: { ...dataAll, reviewed: value ? 1 : 0 } });
    } else {
      this.setState({ dataAll: { ...dataAll, informed: value ? 1 : 0 } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeCommentCheck = (value, type) => {
    const indexcheck = this.state.dataPost.findIndex(
      (index) => index.type === type
    );
    if (indexcheck >= 0) {
      this.state.dataPost[indexcheck]["comments"] = value;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.patientExaminationSave(this.state.patient_id_event, {
          patient_id: this.state.patient_id,
          pre_operative: [...this.state.dataPost],
          all_normal_pre_operative: this.state.checkedAllNormalsP,
        });
      }, 500);
    }
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  
  render() {
    const { isSidebar } = this.state;
    const { pre_operations_state } = this.state;
    return (
      <div className="clinicWorkflow__main-section">
        <div
          className="eventsContainer has-sub-header"
          style={{ paddingTop: "1rem" }}
        >
          <ScrollWrapper css="no-padding x-hidden">
            <div className="conditionList" style={{ minHeight: "100%" }}>
              <Checkbox
                checked={this.state.checkedAllNormalsP}
                onChange={(e) =>
                  this.setState({
                    checkedAllNormalsP: e.target.checked,
                  })
                }
                disabled={this.props.is_locked}
              >
                All negative except selections
              </Checkbox>

              <div className="listSection">
                {OperativesTemp.map((condition, index) => (
                  <HPIItem
                    checkedAllNormalsP={this.state.checkedAllNormalsP}
                    mode={condition.mode}
                    format={condition.format}
                    key={`ros-vitals-${index}`}
                    label={condition.label}
                    isIcon={condition.isIcon}
                    options={condition.options}
                    value={this.state.pre_operative_procedure_data}
                    type={condition.type}
                    multiple={condition.multiple}
                    onChangeCommentCheck={(value, type) => {
                      this.onChangeCommentCheck(value, type);
                    }}
                    onChangeTimePicker={(value, type) =>
                      this.onChangeTimePicker(value, type)
                    }
                    onChangeDatePicker={(value, type) =>
                      this.onChangeDatePicker(value, type)
                    }
                    onChangeSelect={(value, type) =>
                      this.onChangeSelect(value, type)
                    }
                    clickChangeRow={(value, type) => {
                      this.clickChangeRow(value, type);
                    }}
                    onChangeInput={(value, type) => {
                      this.onChangeInput(value, type);
                    }}
                    onCheckBox={(value, type) => {
                      this.onCheckBox(value, type);
                    }}
                    isLocked={this.props.is_locked}
                  />
                ))}
              </div>
            </div>
          </ScrollWrapper>
        </div>
        {isSidebar === true && (
          <PreOperativeSideBar
            sidebarIcon={<Icon type="copy" theme="twoTone" twoToneColor="#198038"/>}
            ref={this.sidebarRef}
            value={this.state.pre_operative_procedure_data}
            resource={StaticResource}
            dataAll={this.state.dataAll}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
          />
        )} 
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={this.sidebarRef} sidebarToggle={this.toggleSidebar} sidebarState={isSidebar}/>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  // pre_operative_procedure: state.procedure.pre_operative_procedure,
  // pre_operations: state.procedureReview.pre_operations,
  all_normal_pre_operative: state.patientExamination.all_normal_pre_operative,
  pre_operative: state.patientExamination.pre_operative,
  is_locked: state.common.is_locked
});

const mapDispatchToProps = {
  get_pre_operative_procedure,
  setProcedureReview,
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreOperative);
