import React, { useState } from 'react';
import TopBar from '../../../common/components/SubTopBar';
import DocumentCustomization from './SettingsComponent/DocumentCustomization';
import { SettingsHeader } from '../constant';
import { useSelector } from 'react-redux';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen)
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Settings" subStatus={''} isBarcode={false} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders">
            {SettingsHeader.map((tab, index) => (
              <div
                key={`tabHeaders-${tab.label}-${index}`}
                className={`tabHeaders-item ${activeTab === index ? 'active' : ''}`}
                style={{ width: tab.width }}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>
        </div>
        {activeTab === 0 && <DocumentCustomization />}
      </div>
    </div>
  )
}

export default Settings;
