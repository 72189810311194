import React, { useState, useEffect, useRef } from "react";
import { Input, Select } from "antd";
import ScrollWrapper from "../ScrollBar";
import Loading from "../Loading";
import { SearchSVG, ExpandSVG, CheckSVG } from "../icons/SVGIcon";
import DeleteIconButton from "../icons/DeleteIconButton";
import DataTable from "../DataTable/WhiteHeaderTableWithLock";

import { useOutsideDetect } from "../../utils";
import "./style.scss";

const { Option } = Select;
const { Search } = Input;
const selectArray = [
  { value: "ASAP", id: "asap" },
  { value: "Within 2 weeks", id: "2_week" },
  { value: "3 months", id: "3_month" },
  { value: "6 months", id: "6_month" },
  { value: "1 year", id: "1_year" },
];

const CptReview = ({
  rows,
  options,
  query,
  DataOpSearch,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keySearch,
  keyValue,
  keyType,
  selectItem,
  removeItem,
  disableOptions,
  onclickRow,
  activeIndex,
  isSelectRow,
  isModifier,
  handleValueSelect,
  onChangeSelect,
  valueSelected,
  processArray,
  minRows,
  itemKeyRight,
  isLocked,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [valueSelectedGlobal, setValueSelectedGlobal] = useState({});
  const [selectRow, setSelectRow] = useState(false);
  const [valueHandleSelect, setValueHandleSelect] = useState([]);
  const [opData, setOpData] = useState([]);
  const [optionData, setOptionData] = useState([]);

  const [openResult, setOpenResult] = useState(false);
  const [removeable, setRemoveable] = useState(-1);

  const data = rows ? rows : [];
  const dataSearch = DataOpSearch ? DataOpSearch : [];
  const minRowCount = minRows ? minRows : 3;

  let emptyRows = [];

  // useEffect(() => {
  //   if (optionData && optionData.length > 0) {
  //     const fixSpace = (optionData || []).map((item) => {
  //       const getCheck = item.icd_name || {};
  //       const convertCheck = getCheck.split(" ")[0];
  //       let joinCheck = getCheck.replace(convertCheck, "");
  //       joinCheck = joinCheck.trim();
  //       if (convertCheck.length === 7) {
  //         return { ...item, icd_name: convertCheck + " " + joinCheck };
  //       } else if (convertCheck.length === 6) {
  //         return { ...item, icd_name: convertCheck + "  " + joinCheck };
  //       } else if (convertCheck.length === 5) {
  //         return { ...item, icd_name: convertCheck + "    " + joinCheck };
  //       } else if (convertCheck.length === 4) {
  //         return { ...item, icd_name: convertCheck + "     " + joinCheck };
  //       } else if (convertCheck.length === 3) {
  //         return { ...item, icd_name: convertCheck + "      " + joinCheck };
  //       } else if (convertCheck.length === 2) {
  //         return { ...item, icd_name: convertCheck + "       " + joinCheck };
  //       } else if (convertCheck.length === 1) {
  //         return { ...item, icd_name: convertCheck + "        " + joinCheck };
  //       } else if (convertCheck.length === 0) {
  //         return { ...item, icd_name: convertCheck + "         " + joinCheck };
  //       }
  //     });
  //     setOpData(fixSpace);
  //   } else {
  //     setOpData([]);
  //   }
  // }, [optionData]);
  if (data.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - data.length).fill({ label: "" });
  }

  useEffect(() => {
    setValueSelectedGlobal(valueSelected);
  }, [valueSelected]);

  useEffect(() => {
    setValueHandleSelect(handleValueSelect);
  }, [handleValueSelect]);

  useEffect(() => {
    setSelectRow(isSelectRow);
  }, [isSelectRow]);

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setSelected(row[keySearch]);
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keySearch]);
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (selectItem) {
      selectItem(selected, selectedValue, selectedType);
    } else {
      if (addItem) {
        addItem();
      }
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target.value);
    if (handleSearch) {
      handleSearch(e.target.value);
    }
  };

  const clickHandle = (row, index) => {
    if (onclickRow) {
      onclickRow(row, index);
    }
  };

  const selectHandle = (value, index) => {
    setValueSelectedGlobal({
      ...valueSelectedGlobal,
      [index]: value,
    });
    onChangeSelect(value, index);
  };

  return (
    <div className="atuoComplete">
      <div className="atuoComplete__filter">
        <div
          ref={ref}
          className={`atuoComplete__filter-search ${
            disableSearchIcon ? "disable-icon" : ""
          }`}
        >
          <Search
            onPressEnter={() => {
              if (dataSearch && dataSearch.length > 0) {
                handleSelectItem(dataSearch[0]);
              }
            }}
            value={search}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{ width: "100%" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        <div
          className={`atuoComplete__filter-action ${
            isLocked === 1 ? "disabled" : ""
          }`}
        >
          <button
            className="atuoComplete__filter-add"
            onClick={addSelectedItem}
            disabled={isLocked}
          >
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
        {!disableOptions && openResult && !!search && (
          <div ref={ref1} className="atuoComplete__filter-result">
            <div className="atuoComplete__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper css="no-padding">
                {!!dataSearch.length &&
                  dataSearch.map((row, index) => {
                    return (
                      <div
                        className="atuoComplete__result-item"
                        key={`empty-${row[keySearch]}-${index}`}
                        onClick={() => handleSelectItem(row)}
                      >
                        <p style={{ paddingLeft: "28px", whiteSpace: "pre" }}>
                          {row[keySearch]}
                        </p>
                      </div>
                    );
                  })}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>

      <div className="atuoComplete__result" style={{ marginTop: "0.5rem" }}>
        <div
          className="atuoComplete__result-list"
          style={{
            height: (2.5 * (minRowCount + 1) + 0.5).toString() + "rem",
            borderLeft: "1px solid #dfe3e6",
            borderRight: "1px solid #dfe3e6",
          }}
        >
          <DataTable
            title="cpt_code_review"
            labels={
              isModifier
                ? ["CPT Code", "Modifier", "Units"]
                : ["CPT Code", "Description"]
            }
            widths={isModifier ? ["25%", "50%", "25%"] : ["30%", "70%"]}
            disableFirstBorder
            isNormal
            columns={
              isModifier
                ? [
                    { sortable: true, key: "cpt_code" },
                    { sortable: false, key: "modifier" },
                    { sortable: false, key: "units" },
                  ]
                : [
                    { sortable: true, key: "cpt_code" },
                    { sortable: false, key: "description" },
                  ]
            }
            handleDelete={(value, index) => removeItem(index)}
            handleClickRow={(row, index) =>
              clickHandle(row.row[keySearch], index)
            }
            rowData={(data || []).map((item, index) => {
              return {
                key: item.key,
                id: item.id,
                row: item,
                cpt_code: item.cpt_code,
                description: item.short_desc,
                modifier: item.pivot && item.pivot.modifier,
                units: item.pivot && item.pivot.units,
              };
            })}
            emptyRows={emptyRows}
            sorts={["", "", ""]}
            isLocked={isLocked}
          />
        </div>
      </div>
    </div>
  );
};

export default CptReview;
