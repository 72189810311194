import React, { useState, useEffect, useMemo, useRef } from "react";
import './style.scss';

const ButtonDropdown = ({options = [], onSelect, label}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <div
        style={{
            marginLeft: 'auto',
            position: "relative",
            maxWidth: "400px",
        }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
            <div
            style={{
                zIndex: 20,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
            }}
            className="dropdown-button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
            <button
                style={{
                    backgroundColor: isDropdownOpen ? '#dbe3ee' : '',
                    marginRight: "6px"
                }}
                className="common-btn outlined-btn"
                >
                {label}
            </button>
        </div>
                
        {isDropdownOpen && (
            <div
                style={{
                position: "absolute",
                zIndex: 20,
                left: 0,
                right: 0,
                top: "42px",
                background: "#fff",
                border: "1px solid #ddd",
                }}
            >
                {options.map((option, index) => {
                    return (
                        <div
                            key={index}
                            className="dropdownOption"
                            style={{
                            width: "100%",
                            height: "40px",
                            borderBottom: "1px solid #ddd",
                            padding: "0 14px",
                            fontSize: "14px",
                            color: "#000",
                            lineHeight: "40px",
                            cursor: "pointer",
                            }}
                            onClick={(e) => {onSelect(option.value, index)}}
                        >
                            {option.display}
                        </div>
                    )
                })}
            </div>
            )}
        </div>
    )
}

export default ButtonDropdown;