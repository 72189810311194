import React, {useEffect, useState} from 'react';
import ScrollWrapper from '../ScrollBar';
import {Icon} from 'antd';
import draftToHtml from 'draftjs-to-html';
import {VideoFile, ImageFile} from '../MediaFiles';

function History({
  resource,
  userInfo,
  isSentMessage,
  fileDoubleClicked,
  onHistoryContentClicked,
}) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!!userInfo) {
      setUser(userInfo);
    }
  }, [userInfo]);
  return (
    <div className="historyarea-wrapper" >
      {!!resource && !!user && 
        <ScrollWrapper 
          type="history" 
          isEnd={isSentMessage}
        >
        { resource.map((element, index) => {
          const markup = draftToHtml(JSON.parse(element.comment), {
            trigger: '#',
            separator: ' ',
          });
          const userName = userInfo !== undefined && element.is_dev === 1 ? "Support Staff" : user.name;
          const attachedFiles = !!element.attached_files ? JSON.parse(element.attached_files) : null;
          return (
            <div className="sub-messages" key={index}>
              <div className="message-header-wrapper">
                <Icon 
                  className="user-avatar" 
                  type="user"
                  style={element.is_dev === 1 ? {color: "#671c81"} : {color: "#0f62fe"}}
                />
                <p 
                  className="user-name" 
                  style={element.is_dev === 1 ? {color: "#671c81"} : {color: "#0f62fe"}}
                >
                  {userName}
                  <span className="message-date-time"> 
                    {element.updated_at}
                  </span>
                </p>
              </div>
              <div 
                className="message-content-wrapper"
                onClick={e => onHistoryContentClicked(element)}
                dangerouslySetInnerHTML={{__html: markup}} 
              />
              {!!attachedFiles && attachedFiles.length > 0 &&
                attachedFiles.map(file => (
                  file.type === "image" ? (
                    <ImageFile 
                      resource={file} 
                      key={file.id} 
                      customStyle="history-image-file"
                      fileDoubleClicked={fileDoubleClicked}
                    />
                  ) : (
                    <VideoFile resource={file} key={file.id} width="100px" />
                  )
                ))
              }
            </div>
          )
        })}
        </ScrollWrapper>
      }
    </div>
  )
}

export default History;
