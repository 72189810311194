import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getIDCardInfo, adminUserGetSignatureImage } from "../../../store/actions";
import PusherConnector from './pusherConnector';
import DisplayIDCard from './displayIDCard';
import DisplaySignature from './displaySignature';
import GenerateQRCode from './generateQRCode';

function PusherScan ({
  signType, // define component type.
  scanData, // define initial data.
  cardType, // define id card type
  isQRcode, // is QRcode.
  qrData, // qr code data.
  handleScanQRCode, // clicked the scan ID button.
}) {
  const dispatch = useDispatch();
  const [isScanned, setIsScanned] = useState(false);
  const [isCaptured, setIsCaptured] = useState(false);
  const idCardInfo = useSelector(state => state.scanDriverLicense.idCardInfo);
  const isEmptyIDCardInfo = useSelector(state => state.scanDriverLicense.isEmptyIDCardInfo);
  const signatureImage = useSelector((state) => state.adminUser.signatureImage);
  const presenterId = signType === "admin-sign" ? scanData.provider_id : scanData.id;

  useEffect(() => {
    if (signType === "admin-sign") {
      dispatch(adminUserGetSignatureImage(presenterId));
    } else {
      dispatch(getIDCardInfo(presenterId, cardType));
    }
  }, [])

  useEffect(() => {
    const pusher = new PusherConnector();
    const channel = pusher.createChannel(signType);

    pusher.bindEvent(channel, 'is-scanned', async callback => {
      const data = await callback;
      if (signType === "admin-sign") {
        if(data.provider_id === presenterId) {
          setIsScanned(true);
          handleScanQRCode();
        }
      } else {
        if (data.card_type === cardType && data.patient_id === presenterId) {
          setIsScanned(true);
          handleScanQRCode();
        }
      }
    });

    pusher.bindEvent(channel, 'is-captured', async callback => {
      const data = await callback;
      if (data.card_type === cardType && data.patient_id === presenterId) {
        setIsCaptured(true);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanData]);

  useEffect(() => {
    if (isScanned || isCaptured) {
      if (signType !== "admin-sign") {
        dispatch(getIDCardInfo(presenterId, cardType));
      } else {

      }
    }
    setIsCaptured(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanned, isCaptured])

  return (
    signType === "admin-sign" 
    ? (
      isQRcode 
      ? <GenerateQRCode qrData={qrData} /> 
      : <DisplaySignature signatureImage={signatureImage}
    /> 
    ) : ( 
      isEmptyIDCardInfo 
      ? null
      : isQRcode 
        ? <GenerateQRCode qrData={qrData} /> 
        : <DisplayIDCard 
            data={idCardInfo}
            cardType={cardType}
          />
    )
  )
}

export default PusherScan;
