import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Collapse } from "antd";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ConditionDetailSidebar from "./ConditionDetailSidebar";
import Loading from "../../../../common/components/Loading";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import {
  getProviders,
  getUltrasounds,
  adminReferralConditionList,
  adminReferralConditionGet,
  setAdminReferralCondition,
} from "../../../../store/actions";

const { Option } = Select;

const ConditionDetail = ({ data, title }) => {
  const referralConditions = useSelector(
    (state) => state.adminReferralCondition.referralConditions
  );
  const loading = useSelector((state) => state.adminReferralCondition.loading);

  const dispatch = useDispatch();

  const [procedure, setProcedure] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const onChnageProcedure = (value) => {
    setProcedure(value);
  };

  const fetchData = () => {
    dispatch(getProviders());
    dispatch(adminReferralConditionList());
    dispatch(getUltrasounds());
    dispatch(setAdminReferralCondition("selectedReferralCondition", undefined));
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer">
        <div className="fullContainer patientContainer pt-2" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          <div style={{ textAlign: 'left', color: '#152934', marginTop: '1rem' }}>
            <b>Select a Procedure</b>
          </div>
          <div style={{ width: '100%', marginTop: '0.5rem' }}>
            <Select
              placeholder="Select a Procedure"
              suffixIcon={<ExpandSVG />}
              onChange={onChnageProcedure}
              allowClear={true}
              style={{
                width: '100%',
                maxWidth: '100%'
              }}
            >
              {(referralConditions || []).map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.value}
                  </Option>
                );
              })}
            </Select>
          </div>

          <div className="detailSection" style={{ marginTop: '1rem', width: '100%', paddingLeft: 0 }}>
            <Collapse accordion>
              <Collapse.Panel
                header={'General Specifications'}
                key="general-specifications"
              >
                <div className="content">
                  General Specifications
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                header={'Procedure Providers'}
                key="procedure-providers"
              >
                <div className="content">
                  Procedure Providers
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                header={'Procedure Locations'}
                key="procedure-locations"
              >
                <div className="content">
                  Procedure Locations
                </div>
              </Collapse.Panel>

              <Collapse.Panel
                header={'Follow-Up Ultrasounds'}
                key="follow-up-ultrasounds"
              >
                <div className="content">
                  Follow-Up ultrasounds
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                header={'Template Documentation'}
                key="template-documentation"
              >
                <div className="content">
                  Template Documentation
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                header={'CPT Codes'}
                key="cpt-codes"
              >
                <div className="content">
                  CPT Codes
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ConditionDetail;
