import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Icon } from "antd";
import MainContainer from "./ProcedureWorkflow";
import SubSideNav from "../../common/components/SubSideNav";

export default class ClinicWorkFlow extends Component {
  sideNavLinks = [
    {
      link: "/obl-workflow/check-in",
      label: "Check-In",
    },
    {
      link: "/obl-workflow/obl",
      label: "OBL Module",
    },
  ];

  state = {
    isSideNav: true,
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | OBL Workflow</title>
        <meta name="title" content="FAACT Vascular | OBL Workflow" />
      </Helmet>
    );
  }

  openSideNav = () => {
    this.setState({ isSideNav: true });
  };

  closeSideNav = () => {
    this.setState({ isSideNav: false });
  };

  getTitle = () => (
    <div className="sub-title">
      <Icon type="key" />
      OBL Workflow
    </div>
  );

  render() {
    const { location } = this.props;
    const { isSideNav } = this.state;
    return (
      <div className="clinicWorkflow">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
        />
        <MainContainer location={location} isSideNav={isSideNav} />
      </div>
    );
  }
}
