import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ClinicalCalendar from "./components/ClinicalCalendar";
import {
  getEvents,
  setStepData,
  getClinicLocations, getOnlyProviders,
} from "../../store/actions";
import CardBlock from "../../common/components/Card";

const CalendarContainer = (props) => {
  const events = useSelector((state) => state.clinicEvents.events);
  const activeOpenings = useSelector(
    (state) => state.clinicEvents.activeOpenings
  );
  const closedDates = useSelector(
    (state) => state.clinicEvents.closedDates
  );
  const openingTime = useSelector((state) => state.clinicEvents.openingTime);
  const closingTime = useSelector((state) => state.clinicEvents.closingTime);

  const clinicLocations = useSelector((state) => state.common.clinicLocations);
  const providers = useSelector((state) => state.common.only_providers);
  const dispatch = useDispatch();

  const fetchApi = () => {
    dispatch(getClinicLocations());
    dispatch(getOnlyProviders());
  };

  useEffect(() => {
    const date = moment(new Date()).format("MM/DD/YYYY");
    dispatch(setStepData("loadingEvents", true));
    dispatch(getEvents({ date }));
  }, []);

  useEffect(() => {
    fetchApi();
  }, [events]);

  const handleEvents = (date, location, provider) => {
    dispatch(setStepData("loadingEvents", true));
    dispatch(getEvents({ date, location, provider }));
  };

  const onClickEvent = (event) => {
    if (props.onHandleClick) {
      props.onHandleClick(event)
    }
  }

  return (
    <CardBlock title="Clinical Calendar" other="dashboard">
      <div className="calendarContainer" style={{ position: "relative" }}>
        <ClinicalCalendar
          activeID={props?.activeID}
          onClickEvent={onClickEvent}
          eventData={events || []}
          fetchEvents={handleEvents}
          locations={clinicLocations || []}
          providers={providers || []}
          activeOpenings={activeOpenings || []}
          closedDates={closedDates || []}
          openingTime={openingTime || ""}
          closingTime={closingTime || ""}
        />
      </div>
    </CardBlock>
  );
};

export default CalendarContainer;
