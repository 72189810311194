import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addNewPatient as addPatient } from "../../../services/api.services";
import { validateSteps, validateField } from "../../../services/validations";
import {
  setStepData,
  addpatientPending,
  filterLocations,
  filterProviders,
  getPending,
  autoZip,
  selectPatient,
  actionAddAndSelect,
} from "../../../store/actions";
import {
  newPatientOptions,
  stateOptions,
} from "../../../common/constants/initialData";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../common/components/Toast";

const { demographics } = newPatientOptions;
let timer = null;
const AddNewPatient = ({
  checkIsInsurances,
  handleClose,
  referralConditions,
  pcpList,
  ...restProps
}) => {
  const loading = useSelector((state) => state.newPatient.loadingResource);
  const tabActive = useSelector(
    (state) => state.clinicProviderReview.tabActive
  );
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [zip, setZip] = useState("");
  const [cityCode, setCity] = useState("");
  const [stateId, setStateId] = useState("");
  const [fisrt, setFisrt] = useState(false);

  const zipAutoData = useSelector((state) => state.common.zipAuto);
  const [messageToast, setMessageToast] = useState(null);
  const [isToastr, setIsToastr] = useState(false);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);

  const dispatch = useDispatch();

  const [patient, setPatient] = useState({});
  const [enableError, setEnableError] = useState(true);

  useEffect(() => {
    setFisrt(true);
  }, []);

  const onChangePatient = (type) => (value) => {
    const newPatient = {
      ...patient,
      [type]: value,
    };
    setPatient(newPatient);
    setEnableError(false);
  };

  const checkZipAuto = (value) => {
    clearTimeout(timer);
    setZip(value);
    setLoadingGlobal(true);
    timer = setTimeout(() => {
      setLoadingGlobal(false);
      dispatch(autoZip(value));
    }, 1000);
  };

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && fisrt) {
      if (zipAutoData.success) {
        if (zipAutoData.addresses && zipAutoData.addresses[0]) {
          const address = zipAutoData.addresses[0];
          setCity(address.place);
          setStateId(address.state_id);
        }
      } else {
        setCity(undefined);
        setStateId("");
      }
    }
  }, [zipAutoData]);

  const onChangeAddress = (type) => (value) => {
    let address = "";
    if (value) {
      const splitedAddress = value.split(" ").map((addr) => {
        if (!addr || !addr.length) {
          return "";
        }
        if (addr.length < 2) {
          return addr.charAt(0).toUpperCase();
        }
        return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
      });
      address = splitedAddress.join(" ");
    }

    const newPatient = {
      ...patient,
      [type]: address,
    };
    setPatient(newPatient);
    setEnableError(false);
  };

  const addNewPatient = async () => {
    const data = {
      ...patient,
      zipcode: zip,
      city: cityCode,
      state: stateId,
    };
    if (!validateSteps(data, demographics)) {
      setEnableError(true);
      return;
    } else {
      dispatch(setStepData("loadingResource", true));
      dispatch(setStepData("isAdded", false));
      try {
        const result = await addPatient(data);
        await setIsToastr(true);
        //await dispatch(setStepData("resource", result.data.data));
        //await dispatch(addpatientPending(result.data.data));
        await dispatch(selectPatient(result.data.id || result?.data?.data?.id));
        await dispatch(getPending({}));
        if (checkIsInsurances) {
          checkIsInsurances(result.data.id || result?.data?.data?.id);
        }
        await dispatch(actionAddAndSelect());
        //await dispatch(filterLocations("", "clinic"));
        //await dispatch(filterProviders());
        await dispatch(setStepData("loadingResource", false));
        handleClose();
      } catch (error) {
        dispatch(setStepData("loadingResource", false));
        handleClose();
      }
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };
  const showToast = () => {
    return (
      <ToastMessage
        type="New Patient"
        status={"success"}
        msg={messageToast ? messageToast : "New patient is added successfully"}
      />
    );
  };

  return (
    <>
      <div className="resourceContent" style={{ paddingLeft: "22px" }}>
        {isToastr && !errorRest && showToast()}
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"First Name"}
            value={patient.first_name}
            handleChange={onChangePatient("first_name")}
            required={true}
            isError={validateField("text", patient.first_name)}
            enableErrorText={enableError}
            helperText={"First Name is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Last Name"}
            value={patient.last_name}
            handleChange={onChangePatient("last_name")}
            required={true}
            isError={validateField("text", patient.last_name)}
            enableErrorText={enableError}
            helperText={"Last Name is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"DOB"}
            value={patient.date_of_birth}
            handleChange={onChangePatient("date_of_birth")}
            required={true}
            mask={"date"}
            isError={validateField("date", patient.date_of_birth)}
            enableErrorText={enableError}
            helperText={"Birthday should be MM/DD/YYYY!"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Gender"}
            handleChange={onChangePatient("gender")}
            value={patient.gender}
            required={true}
            isError={validateField("text", patient.gender)}
            enableErrorText={enableError}
            helperText={"Gender is Required!"}
            type={"check"}
            optionKey={"value"}
            valueKey={"name"}
            options={[
              { value: "male", name: "Male" },
              { value: "female", name: "Female" },
            ]}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Language"}
            handleChange={onChangePatient("preferred_language")}
            value={patient.preferred_language}
            type={"check"}
            optionKey={"value"}
            valueKey={"name"}
            options={[
              { value: "English", name: "English" },
              { value: "Spanish", name: "Spanish" },
              { value: "Other", name: "Other" },
            ]}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Marital Status"}
            handleChange={onChangePatient("marital_status")}
            value={patient.marital_status}
            type={"check"}
            optionKey={"value"}
            valueKey={"name"}
            options={[
              { value: "Married", name: "Married" },
              { value: "Single", name: "Single" },
              { value: "Divorced", name: "Divorced" },
              { value: "Widowed", name: "Widowed" },
            ]}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Ethnicity"}
            handleChange={onChangePatient("ethnicity")}
            value={patient.ethnicity}
            type={"check"}
            optionKey={"value"}
            valueKey={"name"}
            options={[
              { value: "White", name: "White" },
              { value: "Hispanic", name: "Hispanic" },
              {
                value: "Not Hispanic or Latino",
                name: "Not Hispanic or Latino",
              },
              { value: "Other", name: "Other" },
            ]}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Phone No"}
            value={patient.phone}
            handleChange={onChangePatient("phone")}
            required={true}
            isError={validateField("phone", patient.phone)}
            enableErrorText={enableError}
            helperText={"Phone Number should be (XXX) XXX-XXXX format"}
            mask={"phone"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Phone No"}
            value={patient.secondary_phone}
            handleChange={onChangePatient("secondary_phone")}
            mask={"phone"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"E-Mail"}
            value={patient.email}
            handleChange={onChangePatient("email")}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"SSN"}
            value={patient.ssn}
            handleChange={onChangePatient("ssn")}
            mask={"ssn"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Address"}
            value={patient.address}
            handleChange={onChangeAddress("address")}
            required={true}
            isError={validateField("text", patient.address)}
            enableErrorText={enableError}
            helperText={"Address is required"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Apt"}
            value={patient.apt_number}
            handleChange={onChangePatient("apt_number")}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"State"}
            value={stateId}
            handleChange={(value) => {
              setStateId(value);
            }}
            type={"check"}
            valueKey={"value"}
            optionKey={"id"}
            options={stateOptions || []}
            required={true}
            isError={validateField("text", stateId)}
            enableErrorText={enableError}
            helperText={"State is required"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"City"}
            value={cityCode}
            handleChange={(value) => {
              setCity(value);
            }}
            required={true}
            isError={validateField("text", cityCode)}
            enableErrorText={enableError}
            helperText={"City is required"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Zip Code"}
            value={zip}
            handleChange={(value) => {
              checkZipAuto(value);
            }}
            isError={validateField("text", zip)}
            enableErrorText={enableError}
            required={true}
            helperText={"Zip Code is Required!"}
          />
        </div>
        <hr />
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Diagnosis"}
            value={patient.referral_condition || undefined}
            handleChange={onChangePatient("referral_condition")}
            required={true}
            type={"check"}
            isError={validateField("text", patient.referral_condition)}
            optionKey={"value"}
            options={referralConditions || []}
            enableErrorText={enableError}
            helperText={"Diagnosis is required"}
          />
        </div>
        {patient.referral_condition === "Other" && (
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              label={"Diagnosis Specifics"}
              value={patient.diagnosis_specifics}
              handleChange={onChangePatient("diagnosis_specifics")}
            />
          </div>
        )}
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Referring"}
            value={patient.referral_provider || undefined}
            handleChange={onChangePatient("referral_provider")}
            type={"check"}
            optionKey={"id"}
            valueKey={"full_name"}
            options={pcpList || []}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"PCP"}
            value={patient.primary_care_provider_id || undefined}
            handleChange={onChangePatient("primary_care_provider_id")}
            type={"check"}
            optionKey={"id"}
            valueKey={"full_name"}
            options={pcpList || []}
          />
        </div>

        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Referral Date"}
            value={patient.referral_date}
            type="date"
            handleChange={onChangePatient("referral_date")}
            required={true}
            isError={validateField("text", patient.referral_date)}
            enableErrorText={enableError}
            helperText={"Referral Date is required"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Pharmacy"}
            value={patient.pharmacy_id || undefined}
            handleChange={onChangePatient("pharmacy_id")}
            type={"check"}
            optionKey={"id"}
            valueKey={"value"}
            options={restProps.providers || []}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Preferred Provider"}
            value={patient.preferred_provider || undefined}
            handleChange={onChangePatient("preferred_provider")}
            type={"check"}
            optionKey={"id"}
            valueKey={"value"}
            options={restProps.providers || []}
          />
        </div>

        <div className="resourceInfo d-flex">
          <EditableFormInput
            label={"Advance Directives"}
            value={patient.advanced_directive || undefined}
            handleChange={onChangePatient("advanced_directive")}
            required={true}
            type={"check"}
            isError={validateField("text", patient.advanced_directive)}
            optionKey={"value"}
            options={[
              { value: "No Advance Directives On File" },
              { value: "Advance Directives On File" },
            ]}
            enableErrorText={tabActive ? true : false}
            helperText={"Advance Directives"}
          />
        </div>

        <div
          className="actionContent"
          style={{
            paddingBottom: "1rem",
            marginTop: "1.5rem",
            flexWrap: "wrap",
          }}
        >
          <button
            className="common-btn outlined-btn"
            style={{ marginTop: "0.5rem" }}
            onClick={handleClose}
          >
            Back
          </button>
          <button
            className="common-btn blue-btn"
            style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
            onClick={addNewPatient}
          >
            Add New Record
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewPatient;
