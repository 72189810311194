import React, { Component } from 'react'
import { connect } from 'react-redux'
import PDFView from '../../../../common/../common/components/PDFView'
import ConsentSideBar from './ConsenSidebar'
import { LeftCharvenSVG, SummaryEmptySVG } from '../../../../common/components/icons/SVGIcon'
import { procedureConsentLoadInit, setSidebarState } from '../../../../store/actions'
import ConsentPreCreateDocument from './ConsentPreCreateDocument'
import SidebarExtension from '../../../../common/components/SidebarExtension'
import { Icon } from 'antd'

class Consent extends Component {
  state = {
    isSidebar: true,
    isOpenedCreatePage: false,
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true })
    this.props.setSidebarState(true);
  }

  toggleSidebar = () => {
    this.props.setSidebarState(!this.state.isSidebar);
    this.setState({ isSidebar: !this.state.isSidebar })
  }

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false })
    this.props.setSidebarState(false);
  }

  componentDidMount() {
    const { patientResource } = this.props
    const patient_id = patientResource.id
    this.props.procedureConsentLoadInit(patient_id)
    this.props.setSidebarState(this.state.isSidebar);
  }
  
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  
  render() {
    const { isSidebar, isOpenedCreatePage } = this.state
    const { selectedDocumentData } = this.props

    console.dir(this.props.testing);

    return (
      <div className={`clinicWorkflow__main-section`}>
        <div className="eventsContainer has-sub-header">
          <div style={{ width: '100%', height: '100%', paddingTop: '1rem' }}>
            <PDFView style={{height: '100%'}} url={selectedDocumentData && selectedDocumentData.fileurl} />
          </div>
        </div>

        {isSidebar && (
          <ConsentSideBar
            sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038"/>}
            ref={this.sidebarRef}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
            openCreatePage={this.openCreatePage}
          />
        )}
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarToggle={this.toggleSidebar} sidebarReference={this.sidebarRef} sidebarState={isSidebar}/>

        {isOpenedCreatePage && 
          <ConsentPreCreateDocument closeCreatePage={this.closeCreatePage} />
        }
      </div>
    )
  }

  openCreatePage = () => {
    this.setState({...this.state, isOpenedCreatePage: true })
  }

  closeCreatePage = () => {
    this.setState({...this.state, isOpenedCreatePage: false })
  }
}

const mapStateToProps = (state) => ({
  patientResource: state.patientSchedule.selectedPatientResource,
  selectedDocumentData: state.procedureConsent.selectedDocumentData,
  testing: state
})

const mapDispatchToProps = {
  procedureConsentLoadInit,
  setSidebarState
}

export default connect(mapStateToProps, mapDispatchToProps)(Consent)
