import React, { useState, useMemo } from "react";
import { message, Upload } from "antd";
import { BASE_API } from "../../constants/constants";
import { BarcodeSVG } from "../icons/FaxSVG";
import Cookies from "universal-cookie";

const { Dragger } = Upload;
const cookies = new Cookies();

const FileUpload = ({ draggerProps, sideComponent }) => {
  const [files, setFiles] = useState(null);
  const [uploaded, setUploaded] = useState(false);

  const patient_id = cookies.get("current_patient_id");

  const onChangeUpload = (info) => {
    const { status } = info.file;
    if (info.file || info.fileList) {
      setFiles(info.file || info.fileList);
    }
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "uploading") {
      if (uploaded) {
        setUploaded(false);
      }
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setUploaded(true);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploaded(true);
    }
  };

  const dragProps = useMemo(() => {
    if (draggerProps) {
      return draggerProps;
    }
    return {
      name: "file",
      multiple: true,
      action: BASE_API + `patients/${patient_id}/upload`,
    };
  }, [draggerProps, patient_id]);

  return (
    <div
      className="side-component"
      style={{
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      {sideComponent && sideComponent}
      <Dragger
        {...dragProps}
        onChange={onChangeUpload}
        className="barcode-uploader"
        showUploadList={true}
      >
        <p className="ant-upload-drag-icon">
          <BarcodeSVG />
        </p>
      </Dragger>
    </div>
  );
};

export default FileUpload;
