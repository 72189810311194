import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { useSelector, useDispatch } from 'react-redux'

import DeleteIconButton from "../../../../common/components/icons/DeleteIconButton";
import ExtendDataTable from '../../../../common/components/SubDataTable/ExtendDataTable';

const VariablesTable = () => {
  const [variables, setVariables] = useState([]);
  const [active, setActive] = useState(-1);

  const handleAddNew = () => {
    const newVariable = {
      key: Date.now(),
      label: 'Label',
      unit: ''
    };
    setVariables([...variables, newVariable]);
  };

  const removeVaraible = (index) => {
    if (active !== index) {
      setActive(index);
      return;
    }
    const vars = [...variables];
    vars.splice(index, 1);
    setActive(-1);
    setVariables(vars);
  }

  const handleChange = (type, value, index) => {
    const vars = [...variables];
    vars[index][type] = value;
    setVariables(vars);
  }

  return (
    <>
      <div className="resourceInfo d-flex" style={{ justifyContent: 'space-between', marginTop: '0.5rem' }}>
        <b>Variables</b>
        <button
          className="text-btn"
          style={{ marginLeft: "1rem" }}
          onClick={handleAddNew}
        >
          Add +
        </button>
      </div>

      <div className="resourceInfo d-flex" style={{ marginTop: '0.5rem' }}>
        <ExtendDataTable
          title={(
            <>
              <div style={{ width: "75%", textAlign: "left" }}>Label</div>
              <div style={{ width: "25%", textAlign: "left" }}>Unit</div>
            </>
          )}
          data={
            variables.map((variable, index) => ({
              key: variable.key,
              label: (
                <Input
                  style={{ width: '100%', color: '#152935', boxShadow: 'none' }}
                  value={variable.label}
                  onChange={(e) => handleChange('label', e.target.value, index)}
                />
              ),
              unit: (
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <div style={{ paddingRight: '10px', flex: 1 }}>
                    <Input
                      style={{ width: '100%', color: '#152935', boxShadow: 'none' }}
                      value={variable.unit}
                      onChange={(e) => handleChange('unit', e.target.value, index)}
                    />
                  </div>
                  <DeleteIconButton icon={'can'} handleClick={() => removeVaraible(index)} selected={active === index} />
                </div>
              )
            }))
          }
          options={['label', 'unit']}
          widths={['75%', '25%']}
        />
      </div>
    </>
  );
};

export default VariablesTable;
