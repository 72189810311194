/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor';
import {Tooltip,Icon} from 'antd';
import './style.scss';

const myTheme = {
  'common.backgroundColor': '#f4f7fb',
  'common.border': '0px',
  // header
  'header.display': 'none',
  'downloadButton.display': 'none',
  'loadButton.display': 'none',
  'submenu.display': 'none',
  
  'menu.iconSize.width': '18px',
  'menu.iconSize.height': '18px',
  // 'submenu.iconSize.width': '18px',
  // 'submenu.iconSize.height': '18px',

  // editor control
  "controls.backgroundColor": "#000",
  "controls.height": '40px',
  // submenu primary color
  // 'submenu.backgroundColor': '#000',
  // 'submenu.partition.color': '#858585',
  // rango style
  // 'range.pointer.color': '#fff',
  // 'range.bar.color': '#666',
  // 'range.subbar.color': '#d1d1d1',

  // 'range.disabledPointer.color': '#414141',
  // 'range.disabledBar.color': '#282828',
  // 'range.disabledSubbar.color': '#414141',

  // 'range.value.color': '#fff',
  // 'range.value.fontWeight': 'lighter',
  // 'range.value.fontSize': '11px',
  // 'range.value.border': '1px solid #353535',
  // 'range.value.backgroundColor': '#151515',
  // 'range.title.color': '#fff',
  // 'range.title.fontWeight': 'lighter',

  // colorpicker style
  // 'colorpicker.button.border': '1px solid #1e1e1e',
  // 'colorpicker.title.color': '#fff'
};

function Editor({imageData, onSaveImage, onCloseEditor, disableEditing = false}) {
  const [uiElement, setUIElement] = useState();
  const editorRef = useRef();
  useEffect(() => {
    console.log('imageData', imageData);
    if (!!imageData) {
      setUIElement({
        loadImage: {
          path: imageData.url + '?t=' + new Date().getTime(),
          name: `image-${imageData.id}`
        },
        theme: myTheme,
        menu: disableEditing ? [] : ['crop', 'draw', 'text'],
        uiSize: {width: "100%"},
        menuBarPosition: 'bottom',
      })
    }
  }, [imageData]);

  const handleCloseEditor = () => {
    const editorInstance = editorRef.current.getInstance();
    const data = editorInstance.toDataURL();
    onSaveImage({
      updatedImage: data,
      originImage: imageData
    })
    onCloseEditor();
  }
  
  return (
    !!uiElement && (
      <div className= "image-editor-wrapper">
        <ImageEditor
          ref={editorRef}
          includeUI={uiElement}
          selectionStyle={{
            cornerSize: 5,
            rotatingPointOffset: 70
          }}
          usageStatistics={true}
        />
       
        <Tooltip placement="top" title="Close Image Editor">
          <Icon
            type="close" 
            className="image-close-icon"
            onClick={handleCloseEditor}
          />
        </Tooltip>
        
      </div>
    )
  )
}

export default Editor;
