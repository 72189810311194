import React from 'react';
var QRCode = require('qrcode.react');

function GenerateQRCode (data) {
  return (
    <div className="resourceInfo d-flex" style={{ textAlign: "left", maxWidth: '575px', maxHeight: '250px' }}>
      <QRCode style={{ width: '40%', minWidth: '140px', height: 'auto' }} value={JSON.stringify(data.qrData)}/>
      <div style={{ marginLeft: '1.5rem', textAlign: 'left', width: '60%' }}>
        Scanning the QR code on your mobile device FAACT App will launch instructions
        to capture data. Please do the following to proceed: <br /><br />
        <ol prefix="1" style={{ paddingLeft: '14px' }}>
          <li>
            <p>Download the FAACT App from the app store</p>
          </li>
          <li>
            <p>Launch the application and allow it to access your camera</p>
          </li>
          <li>
            <p>Hold your device over the QR code so that it is clearly visible within your devices screen</p>
          </li>
          <li>
            <p>Capture the QR code and follow the instructions</p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default GenerateQRCode;