import { createReducer } from 'redux-create-reducer';

import AT from '../actionTypes';

const initialState = {
  pending: null,
  searchForPending: '',
  loadingPending: false,
  errorMessage: '',
  isAdded: false
};

const SET_PENDING_SEARCH_SUCCEEDED = `${AT.SET_PENDING_SEARCH}_SUCCEEDED`;
const SET_STEP_DATA_SUCCEEDED = `${AT.SET_STEP_DATA}_SUCCEEDED`;
// MainSection
const GET_PENDING_SUCCEEDED = `${AT.GET_PENDING}_SUCCEEDED`;
const ADD_PATIENT_PENDING_SUCCEEDED = `${AT.ADD_PATIENT_PENDING}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`] (state, action) {
    return {
      ...initialState
    }
  },
  [SET_PENDING_SEARCH_SUCCEEDED](state, action) {
    return Object.assign({}, state, {searchForPending: action.payload});
  },
  [SET_STEP_DATA_SUCCEEDED](state, action) {
    const { loadingPending, searchForPending, isAdded } = action.payload;

    return Object.assign({}, state, {
      loadingPending: loadingPending !== undefined ? loadingPending : state.loadingPending,
      searchForPending: searchForPending !== undefined ? searchForPending : state.searchForPending,
      isAdded: isAdded !== undefined ? isAdded : state.isAdded
    });
  },
  // Get pending process
  [GET_PENDING_SUCCEEDED](state, action) {
    const pending = action.payload;
    return Object.assign({}, state, {pending, loadingPending: false});
  },
  [ADD_PATIENT_PENDING_SUCCEEDED](state, action) {
    const pending = action.payload;
    return Object.assign({}, state, {pending: [pending, ...state.pending], isAdded: true});
  },
});
