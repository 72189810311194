import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import 'antd/dist/antd.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-alice-carousel/lib/alice-carousel.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(<Provider store={store}><App /></Provider>, rootElement)
} else {
    render(<Provider store={store}><App /></Provider>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
