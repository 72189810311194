import React, { useState, useEffect } from "react";
import {
  CloseIcon,
  SummaryEmptySVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import { validateField } from "../../../../services/validations";
import { message, Upload } from "antd";
import {
  approve_timeoff_requests,
  timeoff_requests,
  timeoff_requests_all,
  setGlobalLoading
} from "../../../../store/actions";
import { useDispatch } from "react-redux";
import ToastMessage from "../../../../common/components/Toast";
import Cookies from "universal-cookie";
import { BASE_API } from "../../../../common/constants/constants";

const VacationRequestSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  timeDetail,
  timeRemaining,
}, ref) => {
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [amountHours, setAmountHours] = useState("");
  const [reason, setReason] = useState("");
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [uploaded, setUploaded] = useState(false);
  const draggerProps = {
    name: "file",
    multiple: true,
    action: BASE_API + "timeoff_requests/" + timeDetail.id + "/upload",
  };
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const authId = cookies.get("cvai-current-user");

  const fetchList = () => {
    const IdEmploy = JSON.parse(localStorage.getItem("IdEmploy"));
    dispatch(timeoff_requests(IdEmploy));
    dispatch(timeoff_requests_all());
  };

  const rejectClick = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("proof_path", image.originFileObj);
    formData.append("start_time", startTime);
    formData.append("end_time", endTime);
    formData.append("amount_of_hours", amountHours);
    formData.append("user_id", timeDetail.user_id);
    formData.append("is_approved", 0);
    formData.append("approved_user_id", authId);
    formData.append("reason", reason);
    formData.set("_method", "PUT");
    const checkSaveAll = await dispatch(
      approve_timeoff_requests(timeDetail.id, formData)
    );
    if (checkSaveAll) {
      fetchList();
      setLoading(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const approveClick = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("proof_path", image.originFileObj);
    formData.append("start_time", startTime);
    formData.append("end_time", endTime);
    formData.append("amount_of_hours", amountHours);
    formData.append("user_id", timeDetail.user_id);
    formData.append("is_approved", 1);
    formData.append("approved_user_id", authId);
    formData.append("reason", reason);
    formData.set("_method", "PUT");
    const checkSaveAll = await dispatch(
      approve_timeoff_requests(timeDetail.id, formData)
    );
    if (checkSaveAll) {
      fetchList();
      setLoading(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (isShow && timeDetail) {
      dispatch(setGlobalLoading(loading));
    }
  }, [loading, isShow, timeDetail])

  const onChangeUpload = (info) => {
    const { status } = info.file;
    if (info.file || info.fileList) {
      setImage(info.file || info.fileList);
    }
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "uploading") {
      if (uploaded) {
        setUploaded(false);
      }
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setUploaded(true);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploaded(true);
    }
  };

  const fetchStage = () => {
    if (Object.keys(timeDetail).length > 0) {
      setStartTime(timeDetail.start_time);
      setEndTime(timeDetail.end_time);
      setAmountHours(timeDetail.amount_of_hours);
      setReason(timeDetail.reason);
    }
  };

  useEffect(() => {
    if (timeDetail) {
      fetchStage();
      setReset(true);
    }
  }, [timeDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  if (!isShow || !timeDetail) {
    return null;
  }

  const renderTitle = () => {
    return (
      <span className="text-uppercase">
        {timeDetail && timeDetail.user && timeDetail.user.name}
      </span>
    );
  };

  return (
    <>
      <div
        ref={ref}
        className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
      >
        {isToastr && (
          <ToastMessage
            type="Schedules"
            status="success"
            msg="Saved Successfully!"
          />
        )}

        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          <SidebarTitle icon={<MaleSVG />} title={renderTitle()} subTitle="" />

          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
          >
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Position"}
                noEditable={true}
                value={
                  timeDetail && timeDetail.user && timeDetail.user.position
                }
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableMaterialText
                reset={reset}
                label={"Start Date / Time"}
                handleChange={(value) => {
                  setStartTime(value);
                }}
                required={true}
                isError={validateField("text", startTime)}
                enableErrorText={enableError}
                helperText={"Start Time Is Require!"}
                value={startTime}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableMaterialText
                reset={reset}
                handleChange={(value) => {
                  setEndTime(value);
                }}
                label={"End Date / Time"}
                value={endTime}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableMaterialText
                reset={reset}
                handleChange={(value) => {
                  setAmountHours(value);
                }}
                label={"Hours"}
                value={amountHours}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableMaterialText
                reset={reset}
                label={"Reason"}
                value={reason}
                type={"check"}
                optionKey={"value"}
                handleChange={(value) => {
                  setReason(value);
                }}
                valueKey={"label"}
                options={[
                  { value: "vacation", label: "Vacation" },
                  { value: "illness", label: "Illness" },
                  { value: "bereavement", label: "Bereavement" },
                  { value: "education", label: "Education" },
                  { value: "other", label: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Number of Hours"}
                noEditable={true}
                value={
                  timeDetail &&
                  timeDetail.user &&
                  timeDetail.user.vacation_hours_per_year
                }
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Vacation Hours Remaining"}
                noEditable={true}
                value={
                  timeDetail &&
                  timeDetail.user &&
                  timeDetail.user.vacation_hours_per_year - timeRemaining
                }
              />
            </div>
            <hr />
            <div className="detailContent" style={{ display: "flex" }}>
              <div className="detailInfo">
                Documentation for absence (physician note, bereavement note or
                other docmentation.
              </div>
              <Upload
                onChange={onChangeUpload}
                className="barcode-uploader"
                {...draggerProps}
              >
                <button
                  className="common-btn outlined-btn"
                  style={{
                    marginTop: "6px",
                    marginLeft: "8px",
                    height: "40px",
                    lineHeight: 1,
                  }}
                >
                  Upload Image
                </button>
              </Upload>
            </div>

            <hr />
          </div>
          <div
            className="actionContent"
            style={{ marginTop: "2rem", paddingRight: "1rem" }}
          >
            <button
              onClick={rejectClick}
              className="outlined-btn"
              style={{ marginRight: "0.5rem" }}
            >
              Reject
            </button>
            <button onClick={approveClick}>Approve</button>
          </div>
        </ScrollWrapper>
      </div>
    </>
  );
});

export default VacationRequestSidebar;
