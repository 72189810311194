import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Select, DatePicker, TimePicker } from "antd";
import { ExpandSVG, LeftCharvenSVG, CloseIcon } from "../icons/SVGIcon";
import ScrollWrapper from "../ScrollBar";
import Loading from "../Loading";
import {
  get_plan_patient, getFollowUp, getTimeSlots,
  rescheduleProcedure,
  saveClinicSchedule,
  saveProcedureSchedule,
  setProcedureData,
  setStepData,
  saveUltrasoundSchedule,
  setGlobalLoading,
} from "../../../store/actions";
import ToastMessage from "../Toast";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const { Option } = Select;

const filterOptions = (options, filterOption, type) => {
  if (!filterOption || !filterOption.type || !filterOption.list.length) {
    return options;
  }
  if (type !== filterOption.type) {
    return options;
  }
  return options.filter((option) => filterOption.list.includes(option.id));
};

const getValue = (options, target, key) => {
  const opt = (options || []).find(
    (option) => option[key] && target && option[key].toString() === target.toString()
  );
  if (opt) {
    return opt.value;
  }
  return "";
};

const DiseaseComponent = ({
  procedure_id_row,
  handleContinue,
  nextSave,
  titleProps,
  display,
  titlePlan,
  activeRow,
  onButton,
  plans,
  planTypes,
  typePlanId,
  currentPlanId,
  ...restProps
}) => {
  const current_patient_id = cookies.get("current_patient_id");
  const current_appointment_id = cookies.get("current_appointment_id");
  const data = useSelector((state) => state.patientSchedule);
  const loading = useSelector((state) => state.procedure.loadingSchedule);
  const loadingFirst = useSelector((state) => state.procedure.loadingTimeSlots);
  const timeSlots = useSelector((state) => state.procedure.timeSlots);
  const providers = useSelector((state) => state.procedure.providers);
  const locations = useSelector((state) => state.procedure.locations);
  const followupProviders = useSelector(
    (state) => state.procedure.followupProviders
  );

  const activeRows = useSelector((state) => state.newPatient.activeRows);
  const [appointment_1, setAppointment_1] = useState([]);
  const dispatch = useDispatch();
  const { filteredLocations, filteredProviders } = restProps;
  const [isToastr, setIsToastr] = useState(false);

  const [activeCustom, setActiveCustom] = useState(false);

  const [customAppointment, setCustomAppointment] = useState({});
  const [checkDetail, setCheckDetail] = useState({});
  const [firstCheck, setFirstCheck] = useState(false);
  const [appointment_date_check, setAppointment_date] = useState(null);
  const [endarterectomy, setEndarterectomy] = useState([]);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const firstClinic = useSelector((state) => state.procedure.timeSlot);
  const [timeOption, setTimeOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });

  const saveAllDate = (type) => {
    if (type === "time") {
      if (timeSlots && timeSlots[0]) {
        const checkTimeSlots = timeSlots[0];
        if (checkTimeSlots) {
          if (
            endarterectomy &&
            !endarterectomy.some((r) => r.start === checkTimeSlots.start)
          ) {
            setEndarterectomy([...endarterectomy, checkTimeSlots]);
          }
        }
      }
    }
  };

  const fetchFrist = async () => {
    await dispatch(setProcedureData("timeSlot", -1));
    setAppointment_date(null);
    await setFirstCheck(false);
    await setCheckDetail({})
    if (plans && plans.length > 0) {
      const check = plans.find(r => r.condition_id == typePlanId);
      if (check) {
        const checkDetail = (check["plan"] || []).filter(r => r.entity_id == procedure_id_row);
        if (checkDetail && checkDetail[0]) {
          await setCheckDetail(checkDetail[0])
        }
      }
    }
  };

  useEffect(() => {
    fetchFrist();
  }, [procedure_id_row, plans, typePlanId, activeRow]);


  useEffect(() => {
    (timeSlots || []).map((slot, index) => {
      if (moment(slot.start).format("MM/DD/YYYY") === moment(checkDetail?.ultrasound?.appointment_date).format("MM/DD/YYYY")) {
        dispatch(setProcedureData("timeSlot", index));
      } else {
        dispatch(setProcedureData("timeSlot", -1));
      }
    });

  }, [timeSlots]);

  useEffect(() => {
    if (checkDetail && Object.keys(checkDetail).length > 0) {
      if (checkDetail?.ultrasound?.appointment_date) {
        const value = moment(checkDetail?.ultrasound?.appointment_date).format("MM/DD/YYYY")
        setAppointment_date(value || null)
      } else {
        setAppointment_date(null)
      }
    }
  }, [checkDetail])

  const handleCustomChange = (idx, type, value) => {
    const customAppointments = { ...customAppointment };
    customAppointments[idx][type] = value;
    setCustomAppointment(customAppointments);
  };

  const toggleCustom = (type) => {
    const isCustomArray = { ...activeCustom };
    const customAppointments = { ...customAppointment };
    isCustomArray[type] = !isCustomArray[type];
    customAppointments[type] = isCustomArray[type]
      ? {
        location: "",
        date: "",
        time: "",
        provider: "",
      }
      : {};

    setActiveCustom(isCustomArray);
    setCustomAppointment(customAppointments);
  };

  const callApiDetail = async () => {
    if (procedure_id_row) {
      const getDetailSuccess = await dispatch(
        rescheduleProcedure(current_patient_id, procedure_id_row, {}, true)
      );
      if (getDetailSuccess) {
        setLoadingGlobal(false);
      }
    }
  };

  useEffect(() => {
    callApiDetail();
  }, [procedure_id_row]);

  const setFiirst = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const handleMoreDates = (type) => {
    saveAllDate(type);
    if (timeSlots && timeSlots.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(timeSlots[timeSlots.length - 1].start).format(
          "MM/DD/YYYY"
        ),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(
        getTimeSlots(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          option,
          false,
          false
        )
      );
      setFiirst(-1);
    }
  };

  const previous = (type) => {
    if (endarterectomy && endarterectomy.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(
          endarterectomy[endarterectomy.length - 1].start
        ).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(
        getTimeSlots(
          data &&
          data.selectedPatientResource &&
          data.selectedPatientResource.id,
          procedure_id_row,
          option
        )
      );
      setFiirst(-1);
      const aa = endarterectomy;
      aa.splice(endarterectomy.length - 1, 1);
      setEndarterectomy(aa);
    }
  };

  const saveSchedule = async (firstClinicCheck,
    timeSlotsCheck
  ) => {
    const timeSlot =
      timeSlotsCheck && firstClinicCheck && firstClinicCheck > -1
        ? timeSlotsCheck[firstClinicCheck]
        : timeSlotsCheck && timeSlotsCheck[0]
          ? timeSlotsCheck[0]
          : "";
    if (timeSlot) {
      const params = {
        procedure_date: moment(timeSlot.start).format("MM/DD/YYYY"),
        procedure_time: moment(timeSlot.start).format("HH:mm A"),
        procedure_location: timeSlot.location,
        procedure_provider: timeSlot.doctor,
        procedure_technician: timeSlot.provider,
        plan_id: currentPlanId,
      };
      dispatch(setGlobalLoading(true));
      const loadingSuccess = await dispatch(
        setProcedureData("loadingSchedule", true)
      );
      const SaveSuccess = await dispatch(
        saveUltrasoundSchedule(procedure_id_row, params)
      );

      const loadList = await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
      if (loadingSuccess && SaveSuccess && loadList) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          // nextSave();
        }, 1000);
      }
      dispatch(setGlobalLoading(false));
    }
  };

  const selectTimeSlot = (row, index) => {
    setFiirst(index);
  };

  const handleChangeSlots = (type) => (value) => {
    const prev_date =
      firstClinic !== -1
        ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
        : timeSlots[0]
          ? moment(timeSlots[0].start).format("MM/DD/YYYY")
          : "";
    const newOption = { ...timeOption, prev_date, [type]: value };
    setTimeOption(newOption);
    dispatch(setProcedureData("loadingTimeSlots", true));
    dispatch(getTimeSlots(current_patient_id, procedure_id_row, newOption));
    setFiirst(-1);
  };

  useEffect(() => {
    if (!loading && !loadingFirst) {
      setFirstCheck(true)
      if (firstClinic != -1 && firstCheck) {
        setTimeout(() => {
          saveSchedule(
            firstClinic,
            timeSlots,
          );
        }, 400);
      }
    }
  }, [firstClinic, timeSlots]);

  const checkActive = (slot) => {
    if (appointment_date_check && moment(slot.start).format("MM/DD/YYYY") === moment(appointment_date_check).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  }

  const onHandleClick = () => {
    if (nextSave) {
      nextSave();
      setFiirst(-1);
      setAppointment_date(null);
    }
  };

  return (
    <>
      <div
        className="resourceContent"
      >
        {isToastr && (
          <ToastMessage
            type="Plan/Orders"
            status="success"
            msg="Save Orders Successfully!"
          />
        )}
        <React.Fragment key={`clinic-appointment-${0}`}>
          <div
            className="resourceInfo d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <b>{`${titlePlan} (Ultrasound)`}</b>
            <button
              className="text-btn"
              style={{ marginLeft: "1rem" }}
              onClick={() => toggleCustom()}
            >
              {`Custom ${activeCustom ? "-" : "+"}`}
            </button>
          </div>

          <div style={{ marginBottom: "4px" }}>
            <div
              className="resourceInfo between mt-2 sortableHeader"
              style={{ background: "#f4f7fb" }}
            >
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Location"
                  suffixIcon={<span />}
                  allowClear={false}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                  onChange={handleChangeSlots("location")}
                >
                  {(locations || []).map((loc, index) => (
                    <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                      {loc.value}
                    </Option>
                  ))}
                </Select>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                Date
                <div>
                  <button
                    className="more-dates backward"
                    style={{
                      marginRight: "0.5rem",
                      padding: "2px",
                      minWidth: "unset",
                      width: "auto",
                      height: "auto",
                      border: "none",
                      background: "transparent",
                      color: "#3d70be",
                      marginTop: 0,
                    }}
                    onClick={() => {
                      previous("time");
                    }}
                  >
                    <LeftCharvenSVG />
                  </button>
                  <button
                    className="more-dates forward"
                    style={{
                      padding: "2px",
                      minWidth: "unset",
                      width: "auto",
                      height: "auto",
                      border: "none",
                      background: "transparent",
                      color: "#3d70be",
                      marginTop: 0,
                    }}
                    onClick={() => {
                      handleMoreDates("time");
                    }}
                  >
                    <LeftCharvenSVG />
                  </button>
                </div>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Time"
                  suffixIcon={<span />}
                  onChange={handleChangeSlots("period")}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  <Option value="am">AM</Option>
                  <Option value="pm">PM</Option>
                </Select>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Provider"
                  suffixIcon={<span />}
                  allowClear={false}
                  mod="multiple"
                  onChange={handleChangeSlots("provider")}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  {(providers || []).map((provider, index) => (
                    <Option
                      key={`provider-${provider.id}-${index}`}
                      value={provider.id}
                    >
                      {provider.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {!loadingFirst && (timeSlots || []).map(
              (slot, index) => (
                <React.Fragment key={`ime-slots-${index}`}>
                  <div
                    className={`resourceInfo between t-row 
                      ${checkActive(slot) === "active" && firstClinic !== index && firstClinic == -1 ? "active" : ""} ${firstClinic === index ? "active" : ""}`}
                    style={{ alignItems: "center" }}
                    onClick={() => selectTimeSlot(slot, index)}
                  >
                    <div className="w-25">
                      {getValue(locations, slot.location, "id")}
                    </div>
                    <div className="w-25">
                      {slot.start && moment(slot.start).format("MM/DD/YYYY")}
                    </div>
                    <div className="w-25">
                      {slot.start && moment(slot.start).format("HH:mm A")}
                    </div>
                    <div className="w-25">
                      {getValue(providers, slot.doctor, "id")}
                    </div>
                  </div>
                  <hr className="small-block" />
                </React.Fragment>
              )
            )}
            {loadingFirst && (
                <React.Fragment key={`time-slots-1`}>
                  <div
                      className={`resourceInfo between t-row`}
                      style={{ alignItems: "center" }}
                  >
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                  </div>
                  <hr className="small-block" />
                  <div
                      className={`resourceInfo between t-row`}
                      style={{ alignItems: "center" }}
                  >
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                  </div>
                  <hr className="small-block" />
                  <div
                      className={`resourceInfo between t-row`}
                      style={{ alignItems: "center" }}
                  >
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                    <div className="w-25">

                    </div>
                  </div>
                </React.Fragment>
            )}
          </div>

          {activeCustom["first"] && (
            <div
              className="resourceInfo between mt-2 sortableHeader"
              style={{ background: "#f4f7fb" }}
            >
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Location"
                  value={customAppointment["first"].location || undefined}
                  suffixIcon={<span />}
                  allowClear={false}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                  onChange={(value) =>
                    handleCustomChange("first", "location", value)
                  }
                >
                  {(locations || []).map((loc, index) => (
                    <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                      {loc.value}
                    </Option>
                  ))}
                </Select>
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  paddingLeft: 0,
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <DatePicker
                  onChange={(value) => {
                    handleCustomChange(
                      "first",
                      "date",
                      moment(value).format("MM/DD/YYYY")
                    );
                  }}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    background: "transparent",
                    boxShadow: "none",
                    borderBottom: 0,
                  }}
                  format={"MM/DD/YYYY"}
                  placeholder="Date"
                  suffixIcon={<ExpandSVG />}
                />
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <TimePicker
                  format={"h:mm A"}
                  use12Hours
                  placeholder="Time"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    boxShadow: "none",
                    borderBottom: 0,
                  }}
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                  minuteStep={15}
                  onChange={(value) =>
                    handleCustomChange(
                      "first",
                      "time",
                      moment(value).format("h:mm A")
                    )
                  }
                />
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  placeholder="Provider"
                  value={customAppointment["first"].provider || undefined}
                  suffixIcon={<span />}
                  allowClear={false}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                  onChange={(value) =>
                    handleCustomChange("first", "provider", value)
                  }
                >
                  {(providers || []).map((provider, index) => (
                    <Option
                      key={`provider-${provider.id}-${index}`}
                      value={provider.id}
                    >
                      {provider.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
      <div
        className="actionContent"
        style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}
      >
        <button onClick={() => {
          onHandleClick()
        }}>Continue</button>
      </div>
    </>
  );
};

export default DiseaseComponent;
