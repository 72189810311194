import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";
import { Tooltip } from 'antd';
import TopBar from "../../../common/components/SubTopBar";
import PatientSnapshot from "../../../common/views/PatientSnapshot";
import PatientChart from "../../../common/views/PatientChart";
import Appointments from "../../../common/views/Appointment";
import Communication from "./clinicalModules/Communication";
import Logs from "./clinicalModules/Logs";
import PatientCharting from "./clinicalModules/Charting";
import { TabHeaders } from "../constant";
import ScrollWrapper from "../../../common/components/ScrollBar";
import PlanOrders from "../../../common/components/PlanOder/PlanOrders";
import Medications from "../../../common/views/Medications";
import {
  setPatientForRecords,
  removeDataPatient,
  removeParamsChart,
} from "../../../store/actions";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";

const cookies = new Cookies();
class ClinicalModule extends Component {
  state = {
    activeTab: 1,
    patient_id: cookies.get("current_patient_id"),
  };

  fetchApiDetailReload = async () => {
    if (!this.props.patient_resource) {
      await this.props.removeDataPatient();
      await this.props.setPatientForRecords(this.state.patient_id);
    }
  };

  componentDidMount() {
    this.fetchApiDetailReload();
  }

  renderSideComponent = () => {
    const { patient_resource, resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (
      resourceCheck.first_name +
      " " +
      resourceCheck.last_name
    ).toUpperCase();
    const age = resourceCheck.date_of_birth
      ? "(" +
      moment().diff(resourceCheck.date_of_birth, "years") +
      // " yo " +
      // (!!resourceCheck.gender ? resourceCheck.gender : "MALE") +
      ")"
      : "";

    const allerrgies = [];
    if (resourceCheck.allergies) {
      resourceCheck.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
            allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
        >

        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "24px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck.no_drug_allergies ? 'NKDA' : 'Pending Data'}
              </span>
            </div>
          )}
          {allerrgies.length > 0 && <br />}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={resourceCheck.allergies.map(allergy => allergy.allergy_name).join(', ')}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>
      
        {resourceCheck.gender && resourceCheck.gender.toUpperCase() == 'MALE' ? 
          <MaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/> :
          <FemaleSVG style={{marginLeft: '8px', height: 'unset', width: '26px'}}/>
        }
      </div>
    );
  };

  setAcitive = (value) => {
    this.setState({
      activeTab: value,
    });
  };

  render() {
    const { patient_resource } = this.props;
    const { activeTab } = this.state;
    const title = "Clinical Module";

    return (
      <div className="clinicWorkflow__main-page">
        <TopBar
          title={title}
          subStatus={""}
          sideComponent={this.renderSideComponent()}
          isBarcode={true}
        />
        <div className={`clinicWorkflow__main-container ${this.props.isSidebar ? 'openSidebar' : ''}`}>
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <ScrollWrapper css="no-padding tab-header-scroll">
                <div style={{ display: "flex" }}>
                  {TabHeaders.map((tab, index) => (
                    <div
                      key={`tabHeaders-${tab.label}-${index}`}
                      className={`tabHeaders-item ${activeTab === index ? "active" : ""
                        }`}
                      style={{ width: tab.width }}
                      onClick={() => {
                        this.setState({ activeTab: index });
                        this.props.removeParamsChart();
                      }}
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>

          {activeTab === 0 && patient_resource && (
            <PatientSnapshot patientResource={patient_resource} isScan={true} />
          )}
          {activeTab === 1 && patient_resource && (
            <PatientChart patientResource={patient_resource} />
          )}
          {activeTab === 2 && patient_resource && <PlanOrders navigateToReview={() => {this.setActive(7)}} />}
          {activeTab === 3 && patient_resource && <Medications />}
          {activeTab === 4 && patient_resource && (
            <Appointments patient_resource={patient_resource} />
          )}
          {activeTab === 5 && <Communication />}
          {activeTab === 6 && <Logs />}
          {activeTab === 7 && (
            <PatientCharting
              setAcitive={(value) => {
                this.setAcitive(value);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  patient_resource: state.patientRecords.resource,
  resourceCheck: state.patientSnapshot.resource,
  isSidebar: state.sidebar.isSidebarOpen
});

const mapDispatchToProps = {
  setPatientForRecords,
  removeDataPatient,
  removeParamsChart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalModule);
