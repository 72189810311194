import React, { Component } from "react";
import { Checkbox } from "antd";
import { HPIItem } from "../../../../common/components/AutoAccordionProcedure";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import PostOperativeSideBar from "./PostOperativeSidebar";
import { StaticResource, PostOperativesTemp } from "../../constant";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import moment from "moment";
import {
  get_post_operative_procedure,
  setProcedureReview,
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
} from "../../../../store/actions";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const cookies = new Cookies();

export class PostOperative extends Component {
  state = {
    isSidebar: window.innerWidth <= 768 ? false : true,
    dataAll: {},
    post_operative_procedure_data: {},
    procedure_id_event: cookies.get("current_appointment_id"),
    procedure_id: cookies.get("current_patient_id"),
    post_operations_state: [],
    dataPost: [],
    checkedAllNormalsP: false,
  };

  timer = null;

  async componentDidMount() {
    const post = await this.props.angioPatientSnapshotLoadInit(
      this.state.procedure_id_event
    );
    if (
      post.all_normal_post_operative == true ||
      post.all_normal_post_operative == 1
    ) {
      this.setState({
        checkedAllNormalsP: true,
      });
    } else {
      this.setState({
        checkedAllNormalsP: false,
      });
    }
    if (post.post_operative) {
      let dataCheck = [];
      let name = "";
      const arraycheck = post.post_operative.map((r) => {
        if (r.type) {
          if (r.type === "Patient Admission") {
            name = "patient_admission";
          } else if (r.type === "NPO Status") {
            name = "npo_status";
          } else if (r.type === "Contrast Allergy") {
            name = "contrast_allergy";
          } else if (r.type === "Latex Allergy") {
            name = "latex_allergy";
          } else if (r.type === "Anticoagulation") {
            name = "anticoagulation";
          } else if (r.type === "Last Anticoagulation") {
            name = "last_anticoagulation";
          } else if (r.type === "Breath Sounds") {
            name = "breath_sounds";
          } else if (r.type === "Respiratory Pattern") {
            name = "respiratory_pattern";
          } else if (r.type === "Cardiac") {
            name = "cardiac";
          } else if (r.type === "INR") {
            name = "inr";
          } else if (r.type === "Creatinine") {
            name = "creatinine";
          } else if (r.type === "ASA Classification") {
            name = "asa_classification";
          } else if (r.type === "Reviewed") {
            name = "reviewed";
          } else if (r.type === "Informed") {
            name = "informed";
          } else if (r.type == "Recovery Entry Time") {
            name = "recovery_entry_time";
          } else if (r.type == "Recovery Bed") {
            name = "recovery_bed";
          } else if (r.type == "Accepting Nurse") {
            name = "accepting_nurse";
          } else if (r.type == "Notes") {
            name = "notes";
          } else {
            name = r.type;
          }
          dataCheck = [
            ...dataCheck,
            {
              field_name: name,
              field_value: r.value || r.values || "",
              default_comments: r.default_comments,
              comments: r.comments,
            },
          ];
        }
        if (
          r.type == "Respiratory" ||
          r.type == "Cardiovascular" ||
          r.type == "Constitutional" ||
          r.type == "Neurological"
        ) {
          return {
            type: r.type,
            comments: r.comments,
            default_comments: r.default_comments,
            extra: r.extra,
            values: r.value || r.values || "",
          };
        } else {
          return {
            type: r.type,
            comments: r.comments,
            default_comments: r.default_comments,
            extra: r.extra,
            value: r.value || r.values || "",
          };
        }
      });
      if (this.props.dataPostOpCheckAll) {
        this.props.dataPostOpCheckAll(
          [...arraycheck],
          post.all_normal_post_operative == true ||
            post.all_normal_post_operative == 1
            ? true
            : false
        );
      }
      this.setState({ dataPost: arraycheck });
      if (dataCheck) {
        this.setState({
          post_operative_procedure_data: { fields: dataCheck },
        });
      }
    }
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };


  onChangeOperations = (value, type) => {
    const { post_operations_state } = this.state;
    const operationIndex = post_operations_state.findIndex(
      (pre) => pre.type === type
    );
    if (operationIndex > -1) {
      post_operations_state[operationIndex] = { type, value };
    } else {
      post_operations_state.push({ type, value });
    }
    this.setState({ post_operations_state });
    post_operations_state.map((r) => {
      const indexcheck = this.state.dataPost.findIndex(
        (index) => index.type == r.type
      );
      this.state.dataPost[indexcheck]["value"] = r.value;
    });
    if (this.props.dataPostOpCheckAll) {
      this.props.dataPostOpCheckAll(
        [...this.state.dataPost],
        this.state.checkedAllNormalsP
      );
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.patientExaminationSave(this.state.procedure_id_event, {
        patient_id: this.state.procedure_id,
        post_operative: [...this.state.dataPost],
        all_normal_post_operative: this.state.checkedAllNormalsP,
      });
    }, 500);

  };

  onChangeTimePicker = (value, type) => {
    const { dataAll } = this.state;
    const time = moment(value, 'hh:mm A').format("hh:mm A");
    console.log('time2', time);
    if (type === "Recovery Entry Time") {
      this.setState({ dataAll: { ...dataAll, recovery_entry_time: time } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeSelect = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Recovery Bed") {
      this.setState({ dataAll: { ...dataAll, recovery_bed: value } });
    } else if (type === "Accepting Nurse") {
      this.setState({ dataAll: { ...dataAll, accepting_nurse: value } });
    }
    this.onChangeOperations(value, type);
  };

  clickChangeRow = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Breath Sounds") {
      this.setState({
        dataAll: {
          ...dataAll,
          breath_sounds:
            dataAll &&
            dataAll["breath_sounds"] &&
            dataAll["breath_sounds"].some((r) => r === value)
              ? [...(dataAll["breath_sounds"] || []).filter((r) => r !== value)]
              : [...(dataAll["breath_sounds"] || []), value],
        },
      });
    } else if (type === "Respiratory Pattern") {
      this.setState({
        dataAll: {
          ...dataAll,
          respiratory_pattern:
            dataAll &&
            dataAll["respiratory_pattern"] &&
            dataAll["respiratory_pattern"].some((r) => r === value)
              ? [
                  ...(dataAll["respiratory_pattern"] || []).filter(
                    (r) => r !== value
                  ),
                ]
              : [...(dataAll["respiratory_pattern"] || []), value],
        },
      });
    } else if (type === "Cardiac") {
      this.setState({
        dataAll: {
          ...dataAll,
          cardiac:
            dataAll &&
            dataAll["cardiac"] &&
            dataAll["cardiac"].some((r) => r === value)
              ? [...(dataAll["cardiac"] || []).filter((r) => r !== value)]
              : [...(dataAll["cardiac"] || []), value],
        },
      });
    }
    this.onChangeOperations(value, type);
  };

  onChangeInput = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Notes") {
      this.setState({ dataAll: { ...dataAll, notes: value } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeCommentCheck = (value, type) => {
    const indexcheck = this.state.dataPost.findIndex(
      (index) => index.type === type
    );
    if (indexcheck >= 0) {
      this.state.dataPost[indexcheck]["comments"] = value;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.patientExaminationSave(this.state.procedure_id_event, {
          patient_id: this.state.procedure_id,
          post_operative: [...this.state.dataPost],
          all_normal_post_operative: this.state.checkedAllNormalsP,
        });
      }, 500);
    }
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar } = this.state;
    return (
      <div className="clinicWorkflow__main-section">
        <div
          className="eventsContainer has-sub-header"
          style={{ paddingTop: "1rem" }}
        >
          <ScrollWrapper css="no-padding x-hidden">
            <div className="conditionList" style={{ minHeight: "100%" }}>
              <Checkbox
                checked={this.state.checkedAllNormalsP}
                onChange={(e) =>
                  this.setState({
                    checkedAllNormalsP: e.target.checked,
                  })
                }
                disabled={this.props.is_locked}
              >
                All negative except selections
              </Checkbox>
              <div className="listSection">
                {PostOperativesTemp.map((condition, index) => (
                  <HPIItem
                    checkedAllNormalsP={this.state.checkedAllNormalsP}
                    mode={condition.mode}
                    format={condition.format}
                    key={`ros-vitals-${index}`}
                    maxWidth={condition.maxWidth}
                    label={condition.label}
                    isIcon={condition.isIcon}
                    options={condition.options}
                    type={condition.type}
                    value={this.state.post_operative_procedure_data}
                    multiple={condition.multiple}
                    onChangeCommentCheck={(value, type) => {
                      this.onChangeCommentCheck(value, type);
                    }}
                    onChangeTimePicker={(value, type) =>
                      this.onChangeTimePicker(value, type)
                    }
                    onChangeSelect={(value, type) =>
                      this.onChangeSelect(value, type)
                    }
                    clickChangeRow={(value, type) => {
                      this.clickChangeRow(value, type);
                    }}
                    onChangeInput={(value, type) => {
                      this.onChangeInput(value, type);
                    }}
                    isLocked={this.props.is_locked}
                  />
                ))}
              </div>
            </div>
          </ScrollWrapper>
        </div>
        {isSidebar === true && (
          <PostOperativeSideBar
            ref={this.sidebarRef}
            value={this.state.post_operative_procedure_data}
            resource={StaticResource}
            dataAll={this.state.dataAll}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
          />
        )}
        <SidebarExtension
          startingWidth="calc(28.3% - 20px + 0.3rem)"
          sidebarReference={this.sidebarRef}
          sidebarToggle={this.toggleSidebar}
          sidebarState={isSidebar}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  // post_operative_procedure: state.procedure.post_operative_procedure,
  post_operative: state.patientExamination.post_operative,
  patient_examination: state.patientExamination,
  is_locked: state.common.is_locked,
});

const mapDispatchToProps = {
  get_post_operative_procedure,
  setProcedureReview,
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostOperative);
