import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { StaticResource } from "../../constant";
import {
  LeftCharvenSVG,
  CheckSVG,
  YellowAlertSVG,
  CreditReceivedSVG,
  RedRequiredCircleSVG,
  RedCloseSVG,
  GearIconSVG,
  GreenGearIconSVG,
} from "../../../../common/components/icons/SVGIcon";
import { FemaleSVG, MaleSVG } from "../../../../common/components/icons/FaxSVG";
import DataTable from "../../../../common/components/DataTable/DataTable";
import {
  getPCMOverview,
  getPCMProvider,
  getPCMDetail,
  setGlobalLoading,
} from "../../../../store/actions";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import OverviewSidebar from "./OverviewSidebar";
import { Icon } from "antd";
import OverviewFilter from "./OverviewFilter";
import Scrollbars from "react-custom-scrollbars";
import ScrollWrapper from "../../../../common/components/ScrollBar";
let timer;

const Overview = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [offLoad, setOffLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [userProvider, setUserProvider] = useState(null);
  const [customReport, setCustomReport] = useState(null);
  const [forFilter, setForFilter] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [assignment, setAssignment] = useState(null);

  const dispatch = useDispatch();
  const pcm = useSelector((state) => state.common.pcm_overview);
  const pcmDetail = useSelector((state) => state.common.pcmDetail);
  const ScheduleRow = useSelector((state) => state.common.pcmProvider);

  const [sortOption, setSortOption] = useState({});

  const sortList = async () => {
    const sortColumn = [];
    for (let sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }

    setTableLoads([]);
    setLoading(true);
    setPage(1);
    if (!forFilter) {
      await dispatch(
        getPCMOverview(1, {
          startDate,
          search: search,
          column: sortColumn,
          overviewStatus: assignment,
          customReport: customReport
        })
      );
    } else {
      await dispatch(
        getPCMOverview(1, {
          startDate,
          search: search,
          column: sortColumn,
          overviewStatus: assignment,
          customReport: customReport
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    sortList();
  }, [sortOption, customReport]);

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const valueSearch = (value) => {
    clearTimeout(timer);
    setSearch(value);
    setLoading(true);
    timer = setTimeout(() => {
      setTableLoads([]);
      setForFilter(true);
      setPage(1);
      setLoading(false);
      dispatch(
        getPCMOverview(1, {
          startDate,
          search: value,
          customReport: customReport
        })
      );
    }, 1000);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = async (row, index) => {
    if (activeIndex !== index) {
      setRow(index);
      setLoading(true);
      const detailSuccess = await dispatch(getPCMDetail(row.id));
      if (detailSuccess) {
        setLoading(false);
      }
      handleSidebar();
    }
  };

  const fetchList = async () => {
    const listProvider = await dispatch(getPCMProvider());
    if (listProvider) {
      setLoading(false);
    }
  };

  const fetchDetail = () => {
    if (tableLoad.length > 0) {
      dispatch(getPCMDetail(tableLoad[0].id));
    }
  };

  useEffect(() => {
    if (pcmDetail && Object.keys(pcmDetail).length > 0) {
      const indexEdit = tableLoad.findIndex((r) => r.id === pcmDetail.id);
      let listItems = [...tableLoad];
      listItems.splice(indexEdit, 1, { ...pcmDetail });
      setTableLoads([...listItems]);
    }
  }, [pcmDetail]);

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    const sortColumn = [];
    for (let sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad && !forFilter) {
        setLoading(true);
        const listPCM = await dispatch(
          getPCMOverview(page + 1, {
            column: sortColumn,
            overviewStatus: assignment,
            customReport: customReport
          })
        );
        if (listPCM) {
          if (pcm && pcm.list && pcm.list.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      } else if (offLoad && forFilter) {
        setLoading(true);
        const listPCMFilter = await dispatch(
          getPCMOverview(page + 1, {
            startDate,
            search: search,
            column: sortColumn,
            overviewStatus: assignment,
            customReport: customReport
          })
        );
        if (listPCMFilter) {
          if (pcm && pcm.list && pcm.list.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  const onChangeDate = (value) => {
    const date = moment(value).format("MM/DD/YYYY");
    setStartDate(date);
  };

  const onChangeProvider = (value) => {
    setUserProvider(value);
  };

  const onChangeAssignment = (value) => {
    setAssignment(value);
  };

  const onSelectCustomReport = (value) => {
    switch (value) {
      case 'insurance_aging':
        setCustomReport(value);
        setSortOption({
          ...sortOption,
          aging: 'asc'
        })
        break;
    
      default:
        setSortOption({});
        setCustomReport(null);
        break;
    }
  }

  const callApiFilter = async (type) => {
    if (type === "full") {
      setLoading(true);
      const filterSuccess = await dispatch(
        getPCMOverview(1, {
          overviewStatus: assignment,
          startDate: startDate,
          search: search,
          customReport: customReport
        })
      );
      if (filterSuccess) {
        setLoading(false);
      }
    } else {
      setLoading(true);
      const filterSuccess = await dispatch(
        getPCMOverview(1, {
          overviewStatus: assignment,
          startDate: startDate,
          search: search,
          customReport: customReport
        })
      );
      if (filterSuccess) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (startDate && !userProvider) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("full");
      setForFilter(true);
    } else if (userProvider && startDate) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("full");
      setForFilter(true);
    } else if (userProvider && !startDate) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("user");
      setForFilter(true);
    } else {
      setPage(1);
      setTableLoads([]);
      callApiFilter("full");
      setForFilter(true);
    }
  }, [userProvider, startDate, assignment]);

  const updateState = (value, data) => {
    const tasks = tableLoad;
    setTableLoads(
        tasks.map((r) => {
          if (r.id === value.id) {
            return { ...r, direct_cost: data.direct };
          } else {
            return r;
          }
        })
    );
  };

  const updateLocal = (value) => {
    const index = tableLoad.findIndex((r) => r.id == value.id);
    if (index != -1) {
      const arr = [...tableLoad];
      arr[index]["icon_overview_status"] = value.status;
      setTableLoads([...arr]);
    }
  };

  const moveState = (id, data) => {
    const tasks = tableLoad;
    setTableLoads(
        tasks.filter((r) => {
          if (r.id !== id) {
            return r;
          }
        })
    );
    setRow(-1);
  };

  useEffect(() => {
    if (pcm && pcm.list && pcm.list.length > 0) {
      if (page > 1) {
        // if (tableLoad && tableLoad.length === 0) {
        //   // dispatch(getPCMDetail(pcm.endovascular[0] && pcm.endovascular[0].id));
        // }
        setTableLoads([...tableLoad, ...pcm.list]);
        setLoading(false);
      } else {
        setTableLoads([...pcm.list]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [pcm]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  console.log(sortOption, "sortOption");

  const sidebarRef = useRef();
  return (
      <div className="clinicWorkflow__main-section">
        <div className="eventsContainer">
          <div className="fullContainer pt-2">
            <OverviewFilter
                onChangeDate={onChangeDate}
                hideProvider={true}
                // onChangeProvider={onChangeProvider}
                onChangeAssignment={onChangeAssignment}
                onSelectCustomReport={onSelectCustomReport}
                providers={ScheduleRow || []}
                valueSearch={(value) => valueSearch(value)}
            />
            <div className="tableSection">
              <div className="tableContainer">
                <DataTable
                    title="pcm_endovascular"
                    tableWidth={1920}
                    labels={[
                      "Patient Name",
                      "ID",
                      "Encounter",
                      "DOB",
                      "DOS",
                      "Aging",
                      "Fee",
                      "Allowed Amount",
                      "Total Paid",
                      "Insurance Balance",
                      "Primary Insurance",
                      "Primary Insurance Number",
                      "Secondary Insurance",
                      "Secondary Insurance Number",
                      "Patient Balance",
                      "Net",
                    ]}
                    widths={[
                      "9%",
                      "4%",
                      "5%",
                      "5%",
                      "5%",
                      "5%",
                      "4%",
                      "6%",
                      "6%",
                      "7%",
                      "7%",
                      "7%",
                      "8%",
                      "8%",
                      "7%",
                    ]}
                    columns={[
                      { sortable: true, key: "patient_name" },
                      { sortable: true, key: "patient_id" },
                      { sortable: true, key: "ops_claim_id" },
                      { sortable: false, key: "patient_dob" },
                      { sortable: true, key: "dos" },
                      { sortable: true, key: "aging" },
                      { sortable: true, key: "fee" },
                      { sortable: true, key: "allowed_amount" },
                      { sortable: true, key: "insurance_amount" },
                      { sortable: true, key: "insurance_balance" },
                      { sortable: false, key: "insurance_company" },
                      { sortable: false, key: "insurance_id" },
                      { sortable: false, key: "secondary_company" },
                      { sortable: false, key: "secondary_insurance_id" },
                      { sortable: true, key: "patient_balance" },
                      { sortable: true, key: "net" },
                    ]}
                    sorts={[
                      "",
                      sortOption.patient_name,
                      sortOption.ops_claim_id,
                      "",
                      sortOption.dos,
                      sortOption.aging,
                      sortOption.fee,
                      sortOption.allowed_amount,
                      sortOption.insurance_amount,
                      sortOption.insurance_balance,
                      "",
                      "",
                      "",
                      "",
                      sortOption.patient_balance,
                      sortOption.net,
                    ]}
                    rowData={(tableLoad || []).map((row, index) => ({
                      id: row.id,
                      patient_name: (
                          <div
                              className="iconField td with-icon"
                              style={{
                                display: "flex",
                                paddingLeft: "0.25rem",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                          >
                            {row.icon_overview_status === "green" && (
                                <div className="has-tooltip">
                                  <Icon type="clock-circle" style={{zIndex: 1}} theme="twoTone" twoToneColor="#24A148"/>
                                  <span
                                      className="tooltip tooltip-without-border"
                                      role="tooltip"
                                      style={{zIndex: 5}}
                                  >
                            {"Less Than 30 Days"}
                          </span>
                                </div>
                            )}
                            {row.icon_overview_status === "correction" && (
                                <div className="has-tooltip">
                                  <Icon type="warning" style={{zIndex: 1}} theme="twoTone" twoToneColor="#FF832B"/>
                                  <span
                                      className="tooltip tooltip-without-border"
                                      role="tooltip"
                                      style={{zIndex: 5}}
                                  >
                            {"Correction Team"}
                          </span>
                                </div>
                            )}
                            {row.icon_overview_status === "research" && (
                                <div className="has-tooltip">
                                  <Icon type="phone" style={{zIndex: 1}} theme="twoTone" twoToneColor="#005ef6"/>
                                  <span
                                      className="tooltip tooltip-without-border"
                                      role="tooltip"
                                      style={{zIndex: 5}}
                                  >
                            {"Research Team - Action Required"}
                          </span>
                                </div>
                            )}
                            {row.icon_overview_status === "contacted" && (
                                <div className="has-tooltip">
                                  <Icon type="phone" style={{zIndex: 1}} theme="twoTone" twoToneColor="#DA1E28"/>
                                  <span
                                      className="tooltip tooltip-without-border"
                                      role="tooltip"
                                      style={{zIndex: 5}}
                                  >
                            {"Research Team - Call Logged"}
                          </span>
                                </div>
                            )}
                            {row.icon_overview_status === "payment_posting" && (
                                <div className="has-tooltip">
                                  <Icon type="dollar" style={{zIndex: 1}} theme="twoTone" twoToneColor="#F1C21B"/>
                                  <span
                                      className="tooltip tooltip-without-border"
                                      role="tooltip"
                                      style={{zIndex: 5}}
                                  >
                            {"Payment Pending"}
                          </span>
                                </div>
                            )}
                            {row.icon_overview_status === "adjust_off" && (
                                <div className="has-tooltip">
                                  <Icon type="thunderbolt" style={{zIndex: 1}} theme="twoTone" twoToneColor="#6929C4"/>
                                  <span
                                      className="tooltip tooltip-without-border"
                                      role="tooltip"
                                      style={{zIndex: 5}}
                                  >
                            {"Adjust Off"}
                          </span>
                                </div>
                            )}
                            {row.icon_overview_status === "fail" && (
                                <div className="has-tooltip">
                                  <Icon type="file-excel" style={{zIndex: 1}} theme="twoTone" twoToneColor="#DA1E28"/>
                                  <span
                                      className="tooltip tooltip-without-border"
                                      role="tooltip"
                                      style={{zIndex: 5}}
                                  >
                            {"Billing Error"}
                          </span>
                                </div>
                            )}
                            {row.icon_overview_status === "positive_patient_balance" && (
                                <div className="has-tooltip">
                                  <Icon type="account-book" style={{paddingTop: '7px', zIndex: 1}} theme="twoTone" twoToneColor="#ff7eb6"/>
                                  <span
                                      className="tooltip tooltip-without-border"
                                      role="tooltip"
                                      style={{zIndex: 5}}
                                  >
                            {"Collection"}
                          </span>
                                </div>
                            )}
                            {!row.icon_overview_status && row.aging >= 30 && (
                                <span style={{ minWidth: "26px" }}></span>
                            )}
                            <p style={{ paddingLeft: "0.75rem" }}>{row.patient_name}</p>
                          </div>
                      ),
                      patient_dob:
                          row.patient_dob && moment(row.patient_dob).format("MM/DD/YYYY"),
                      dos:
                          row.date_of_procedure &&
                          moment(row.date_of_procedure).format("MM/DD/YYYY"),
                      proceduralist: row.provider_name,
                      fee: row.fee,
                      allowed_amount: row.allowed_amount,
                      total_payment: row.payment,
                      net: row.net,
                      ros: row.location,
                      direct_cost: row.direct_cost,
                      contribution_margin: row.contribution_amount,
                      insurance_balance: row.insurance_balance,
                      patient_balance: row.patient_balance,
                      insurance_amount: row.insurance_amount,
                      ops_claim_id: row.ops_claim_id,
                      aging: row.aging,
                      insurance_company: row.insurance_company,
                      insurance_id: row.insurance_id,
                      secondary_company: row.secondary_company,
                      secondary_insurance_id: row.secondary_insurance_id,
                      patient_id: row.patient_id
                    }))}
                    handleSort={(value, item) => {
                      handleSortOption(value, item);
                    }}
                    fetchList={fetchList}
                    loadMore={loadMore}
                    handleClickRow={handleRow}
                    defaultActive={-1}
                    disableDelete={true}
                />
              </div>
            </div>
          </div>
        </div>

        {pcmDetail && activeIndex >= 0 && (
            <OverviewSidebar
                updateLocal={updateLocal}
                ref={sidebarRef}
                updateState={(value, data) => {
                  updateState(value, data);
                }}
                moveState={(value, data) => {
                  moveState(value, data);
                }}
                detailPCM={activeIndex >= 0 && pcmDetail ? pcmDetail : undefined}
                resource={StaticResource}
                isShow={isSidebar}
                handleCloseSidebar={handleCloseSidebar}
                iconOverviewStatus={assignment}
            />
        )}

        {pcmDetail && activeIndex >= 0 && (
            <SidebarExtension
                startingWidth="calc(28.3% - 20px + 0.3rem)"
                sidebarReference={sidebarRef}
                sidebarToggle={toggleSidebar}
                sidebarState={isSidebar}
            />
        )}
      </div>
  );
};

export default Overview;
