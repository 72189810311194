import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";
import {BASE_API} from "../../common/constants/constants";

export const getProcedurePending = createActionThunk(
  AT.GET_PROCEDURE_PENDING,
  ({ procedure_date }) => API.getProcedurePending({ procedure_date })
);

export const getProcedureCallback = createActionThunk(
  AT.GET_PROCEDURE_CALLBACK,
  ({ procedure_date }) => API.getProcedureCallback({ procedure_date })
);

export const selectProcedure = createActionThunk(
  AT.SELECT_PROCEDURE,
  (procedure) => procedure
);

export const confirmProcedure = createActionThunk(AT.CONFIRM_PROCEDURE, (id) =>
  API.confirmProcedure(id)
);

export const rescheduleProcedure = createActionThunk(
  AT.RESCHEDULE_PROCEDURE,
  (id, procedure_id, params, check) =>
    API.rescheduleProcedure(id, procedure_id, params, check)
);

export const getQuestionsForProcedure = createActionThunk(
    AT.GET_QUESTIONS_FOR_PROCEDURE,
    (id) => API.getQuestionsForProcedure(id)
);

export const searchDrug = createActionThunk(AT.SEARCH_DRUG, (query) =>
  API.searchDrug(query)
);

export const resetFolow = createActionThunk(
  AT.RESET_FOLOW,
  () => { }
);

export const add_pre_operative_procedure = createActionThunk(
  AT.ADD_PRE,
  (param) => API.add_pre_operative_procedure(param)
);
export const get_pre_operative_procedure = createActionThunk(AT.GET_PRE, (id) =>
  API.get_pre_operative_procedure(id)
);
export const get_post_operative_procedure = createActionThunk(
  AT.GET_POST_OPERATIVE,
  (id) => API.get_post_operative_procedure(id)
);
export const add_post_operative_procedure = createActionThunk(
  AT.GET_POST_OPERATIVE,
  (param) => API.add_post_operative_procedure(param)
);
export const getFollowUp = createActionThunk(
  AT.GET_FOLLOW_UP,
  (id, procedure_id, params, custom, load_next) =>
    API.getProcedureFollowUp(id, procedure_id, params, custom, load_next)
);

export const search_plan_suggestion = createActionThunk(
  AT.SEARCH_PLAN_SUGGESTION,
  () => API.search_plan_suggestion()
);

export const get_plan_patient = createActionThunk(
  AT.GET_PLAN_PATIENT,
  (patient_id, procedure_id) => API.get_plan_patient(patient_id, procedure_id)
);

export const delete_plan = createActionThunk(AT.DELETE_PLAN, (plan_id) =>
  API.delete_plan(plan_id)
);

export const save_plan_patient = createActionThunk(
  AT.SAVE_PLAN_PATIENT,
  (param) => API.save_plan_patient(param)
);

export const getTimeSlots = createActionThunk(
  AT.GET_TIME_SLOTS,
  (id, procedure_id, params, custom) =>
    API.rescheduleProcedure(id, procedure_id, params, custom)
);

export const getProcedureSchedule = createActionThunk(
  AT.GET_PROCEDURE_SCHEDULE,
  (id) => API.getAvailableProcedure(id)
);

export const getProcedureEvents = createActionThunk(
  AT.GET_PROCEDURE_EVENTS,
  ({ date, location, provider }) =>
    API.getProcedureEvents({ date, location, provider })
);

export const saveProcedureSchedule = createActionThunk(
  AT.SAVE_PROCEDURE_SCHEDULE,
  (id, params) => API.saveProcedureSchedule(id, params)
);

export const saveUltrasoundSchedule = createActionThunk(
  AT.SAVE_ULTRASOUND_SCHEDULE,
  (id, params) => API.saveUltrasoundSchedule(id, params)
);

export const savePlanClinicSchedule = createActionThunk(
  AT.SAVE_PLAN_CLINIC_SCHEDULE,
  (id, params) => API.savePlanClinicSchedule(id, params)
);

export const saveProcedureQuestion = createActionThunk(
  AT.SAVE_PROCEDURE_QUESTION,
  (id, params) => API.saveProcedureQuestion(id, params)
);

export const setProcedureData = createActionThunk(
  AT.SET_PROCEDURE_DATA,
  (type, value) => ({ [type]: value })
);

export const getClinicWorkflowAppointments = createActionThunk(
  AT.GET_PATIENT_PROCEDURE,
  ({ patient_id, start_date, end_date, type, paging }) =>
    API.getClinicWorkflowAppointments({
      patient_id,
      start_date,
      end_date,
      type,
      paging,
    })
);

export const addProcedures_new = createActionThunk(
  AT.ADD_PROCEDURES,
  (params) => API.addProcedures_new(params)
);

// procedurals

export const load_procedurals = createActionThunk(AT.LOAD_PROCEDURALS, (id) =>
  API.load_procedurals(id)
);

export const save_procedurals = createActionThunk(
  AT.SAVE_PROCEDURALS,
  (id, params) => API.save_procedurals(id, params)
);

export const save_info_side = createActionThunk(
  AT.SAVE_INFO_SIDE,
  (id, params) => API.save_info_side(id, params)
);

export const getSingleProcedure = createActionThunk(
  AT.GET_SINGLE_PROCEDURE,
  (id) => API.getSingleProcedure(id)
);

export const getExistingFollowUp = createActionThunk(
  AT.GET_EXISTING_FOLLOW_UP,
  (id) => API.getExistingFollowUp(id)
);

export const saveProcedureChoice = createActionThunk(
    AT.SAVE_PROCEDURE_CHOICE,
    (id, params) => API.saveProcedureChoice(id, params)
);

export const getProcedureCallAttempt = createActionThunk(
    AT.GET_PROCEDURE_CALL_ATTEMPT,
    (procedure_id) => API.getProcedureCallAttempt(procedure_id)
);

export const addProcedureCallAttempt = createActionThunk(
    AT.ADD_PROCEDURE_CALL_ATTEMPT,
    (params) => API.addProcedureCallAttempt(params)
);

export const deleteProcedureCallAttempt = createActionThunk(
    AT.DELETE_PROCEDURE_CALL_ATTEMPT,
    (id) => API.deleteProcedureCallAttempt(id)
);