import React, { useState, useEffect } from 'react';
import AutoCompleteComponent from '../../../common/components/AutoComplete';
import { connect } from 'react-redux';
import { 
  getPatientNotes, 
  addPatientNote,
  deletePatientNote
} from '../../../store/actions';

const filterSuggestion = (suggestions, userInput) => {
  let filteredSuggestions = [];
  if (userInput) {
    filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
  }
  return filteredSuggestions;
};

class NotesComponent extends React.Component {
  state = {
    rows: [],
    searchInput: ''
  }

  componentDidMount() {
    const { resource } = this.props;
    this.props.getPatientNotes(resource.id);
  }

  render() {
    const { allNotes } = this.props;
    let { rows, searchInput } = this.state;
    if (allNotes.length) {
      if (allNotes.length != rows.length) {
        rows = [];
        for (let notes of allNotes) {
          rows.push( `${notes.notes}` );
        }
        this.setState({
          ...this.state, rows
        })
      }      
    }

    return (
      <div className="resourceContent">
        <div className="resourceInfo d-flex">
          <AutoCompleteComponent
            title="previous-medical"
            rows={rows}
            removeItem={this.removeRow}
            addItem={this.addRow}
            handleSearch={this.handleSearch}
            placeholder={"Add Information Note"}
            disableSearchIcon={true}
            selectItem={this.addNote}
          />
        </div>
      </div>
    );
  }

  handleSearch = (search) => {
    this.setInput(search);
  }
  addRow = () => {
    const { rows, searchInput } = this.state;
    const newRows = [...rows, { label: searchInput }];
    this.setRows(newRows);
  }
  removeRow = (item, rowIndex) => {
    this.props.deletePatientNote(rowIndex);
  }
  setRows = (rows) => {
    this.setState({
      ...this.state,
      rows
    })
  }
  setInput = (searchInput) => {
    this.setState({
      ...this.state,
      searchInput
    })
  } 
  addNote = () => {
    const { searchInput } = this.state;
    const { resource } = this.props;
    this.props.addPatientNote(resource.id, searchInput);
  }
  
};

const mapStateToProps = state => ({
  allNotes: state.patientSchedule.allNotes
})

const mapDispatchToProps = {
  getPatientNotes,
  addPatientNote,
  deletePatientNote
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(NotesComponent)
