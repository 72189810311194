import React from 'react';

const BillingAndCharges = () => {
  return (
    <div className="resourceContent">
      Billing And Charges
    </div>
  );
};

export default BillingAndCharges;
