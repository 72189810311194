import { createReducer } from "redux-create-reducer";

import AT from "../actionTypes";

const initialState = {
  addingPatient: false,
  isRegistered: false,
  loadingPatients: false,
  patients: null,
  patientHistory: [],
  searchHistory: [],
  loadingHistory: false,
  addedSearchHistory: false,
  addedGlobalMessage: false,
  messages: [],
  loadingAction: false,
  toastAction: false,
  loadingWideMess: false,
  toastWideMess: false,
  loadingSaveQuick: false,
  messageSaveQuick: {},
  patientDemographics: null,
  appointments: {
    patient: null,
    appointments: []
  },
};

const REGISTER_NEW_PATIENT_STARTED = `${AT.REGISTER_NEW_PATIENT}_STARTED`;
const REGISTER_NEW_PATIENT_SUCCEEDED = `${AT.REGISTER_NEW_PATIENT}_SUCCEEDED`;

const GET_PATIENTS_WITH_QUERY_STARTED = `${AT.GET_PATIENTS_WITH_QUERY}_STARTED`;
const GET_PATIENTS_WITH_QUERY_SUCCEEDED = `${AT.GET_PATIENTS_WITH_QUERY}_SUCCEEDED`;

const SET_PATIENT_HISTORY_SUCCEEDED = `${AT.SET_PATIENT_HISTORY}_SUCCEEDED`;

// Patient search history on dashboard
const ADD_SEARCH_HISTORY_SUCCEEDED = `${AT.ADD_SEARCH_HISTORY}_SUCCEEDED`;
const GET_SEARCH_HISTORY_LIST_STARTED = `${AT.GET_SEARCH_HISTORY_LIST}_STARTED`;
const GET_SEARCH_HISTORY_LIST_SUCCEEDED = `${AT.GET_SEARCH_HISTORY_LIST}_SUCCEEDED`;
const GET_GLOBAL_MESSAGES_STARTED = `${AT.GET_GLOBAL_MESSAGES}_STARTED`;
const GET_GLOBAL_MESSAGES_SUCCEEDED = `${AT.GET_GLOBAL_MESSAGES}_SUCCEEDED`;
const ADD_GLOBAL_MESSAGE_STARTED = `${AT.ADD_GLOBAL_MESSAGE}_STARTED`;
const ADD_GLOBAL_MESSAGE_SUCCEEDED = `${AT.ADD_GLOBAL_MESSAGE}_SUCCEEDED`;
const DELETE_GLOBAL_MESSAGE_STARTED = `${AT.DELETE_GLOBAL_MESSAGE}_STARTED`;
const DELETE_GLOBAL_MESSAGE_SUCCEEDED = `${AT.DELETE_GLOBAL_MESSAGE}_SUCCEEDED`;

// faxing
const SEND_FAX_STARTED = `${AT.SEND_FAX}_STARTED`;
const SEND_FAX_SUCCEEDED = `${AT.SEND_FAX}_SUCCEEDED`;
const EMAIL_FAX_STARTED = `${AT.EMAIL_FAX}_STARTED`;
const EMAIL_FAX_SUCCEEDED = `${AT.EMAIL_FAX}_SUCCEEDED`;
const PRINT_FAX_STARTED = `${AT.PRINT_FAX}_STARTED`;
const PRINT_FAX_SUCCEEDED = `${AT.PRINT_FAX}_SUCCEEDED`;
const SET_DEFAULT_TOAST_SUCCEEDED = `${AT.SET_DEFAULT_TOAST}_SUCCEEDED`;
const DELETE_FAX_LOG_STARTED = `${AT.DELETE_FAX_LOG}_STARTED`;
const DELETE_FAX_LOG_SUCCEEDED = `${AT.DELETE_FAX_LOG}_SUCCEEDED`;
// scaning
const SEND_SCAN_STARTED = `${AT.SEND_SCAN}_STARTED`;
const SEND_SCAN_SUCCEEDED = `${AT.SEND_SCAN}_SUCCEEDED`;
const EMAIL_SCAN_STARTED = `${AT.EMAIL_SCAN}_STARTED`;
const EMAIL_SCAN_SUCCEEDED = `${AT.EMAIL_SCAN}_SUCCEEDED`;
const PRINT_SCAN_STARTED = `${AT.PRINT_SCAN}_STARTED`;
const PRINT_SCAN_SUCCEEDED = `${AT.PRINT_SCAN}_SUCCEEDED`;
const DELETE_SCAN_LOG_STARTED = `${AT.DELETE_SCAN_LOG}_STARTED`;
const DELETE_SCAN_LOG_SUCCEEDED = `${AT.DELETE_SCAN_LOG}_SUCCEEDED`;

const SAVE_QUICK_MESSAGE_STARTED = `${AT.SAVE_QUICK_MESSAGE}_STARTED`;
const SAVE_QUICK_MESSAGE_SUCCEEDED = `${AT.SAVE_QUICK_MESSAGE}_SUCCEEDED`;
const SAVE_QUICK_MESSAGE_ENDED = `${AT.SAVE_QUICK_MESSAGE}_ENDED`;

// Patient Demographics
const SET_DASHBORD_PATIENT_DEMOGRAPHICS_SUCCEEDED = `${AT.SET_DASHBORD_PATIENT_DEMOGRAPHICS}_SUCCEEDED`;
// Appointment Manager
const SET_DASHBOARD_APPOINTMENTS_SUCCEEDED = `${AT.SET_DASHBOARD_APPOINTMENTS}_SUCCEEDED`;

export default createReducer(initialState, {
  [SAVE_QUICK_MESSAGE_STARTED](state) {
    return Object.assign({}, state, {
      loadingSaveQuick: true,
      isRegistered: false,
      addingPatient: true,
    });
  },
  [SAVE_QUICK_MESSAGE_SUCCEEDED](state, action) {
    const messageSaveQuick = action.payload;
    return Object.assign({}, state, {
      loadingSaveQuick: false,
      isRegistered: true,
      messageSaveQuick,
    });
  },
  [SAVE_QUICK_MESSAGE_ENDED](state) {
    return Object.assign({}, state, {
      loadingSaveQuick: false,
      addingPatient: false,
    });
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [DELETE_GLOBAL_MESSAGE_STARTED](state) {
    return Object.assign({}, state, {
      loadingWideMess: true,
      toastWideMess: false,
    });
  },
  [DELETE_GLOBAL_MESSAGE_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingWideMess: false,
      toastWideMess: true,
    });
  },
  [DELETE_SCAN_LOG_STARTED](state) {
    return Object.assign({}, state, {
      loadingAction: true,
      toastAction: false,
    });
  },
  [DELETE_SCAN_LOG_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingAction: false,
      toastAction: true,
    });
  },
  [PRINT_SCAN_STARTED](state) {
    return Object.assign({}, state, {
      loadingAction: true,
    });
  },
  [PRINT_SCAN_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingAction: false,
    });
  },
  [EMAIL_SCAN_STARTED](state) {
    return Object.assign({}, state, {
      loadingAction: true,
      toastAction: false,
    });
  },
  [EMAIL_SCAN_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingAction: false,
      toastAction: true,
    });
  },
  [SEND_SCAN_STARTED](state) {
    return Object.assign({}, state, {
      loadingAction: true,
      toastAction: false,
    });
  },
  [SEND_SCAN_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingAction: false,
      toastAction: true,
    });
  },
  [SET_DEFAULT_TOAST_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingAction: false,
      toastAction: false,
      toastWideMess: false,
    });
  },
  [DELETE_FAX_LOG_STARTED](state) {
    return Object.assign({}, state, {
      loadingAction: true,
      toastAction: false,
    });
  },
  [DELETE_FAX_LOG_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingAction: false,
      toastAction: true,
    });
  },
  [PRINT_FAX_STARTED](state) {
    return Object.assign({}, state, {
      loadingAction: true,
    });
  },
  [PRINT_FAX_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingAction: false,
    });
  },
  [EMAIL_FAX_STARTED](state) {
    return Object.assign({}, state, {
      loadingAction: true,
      toastAction: false,
    });
  },
  [EMAIL_FAX_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingAction: false,
      toastAction: true,
    });
  },
  [SEND_FAX_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAction: true,
      toastAction: false,
    });
  },
  [SEND_FAX_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingAction: false,
      toastAction: true,
    });
  },
  [GET_SEARCH_HISTORY_LIST_STARTED](state, action) {
    return Object.assign({}, state, { addedSearchHistory: false, loadingHistory: true });
  },
  [GET_SEARCH_HISTORY_LIST_SUCCEEDED](state, action) {
    return Object.assign({}, state, { searchHistory: action.payload, loadingHistory: false });
  },
  [ADD_SEARCH_HISTORY_SUCCEEDED](state, action) {
    return Object.assign({}, state, { addedSearchHistory: true });
  },
  [REGISTER_NEW_PATIENT_STARTED](state, action) {
    return Object.assign({}, state, {
      addingPatient: true,
      isRegistered: false,
    });
  },
  [REGISTER_NEW_PATIENT_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      addingPatient: false,
      isRegistered: true,
    });
  },
  // Search Patients
  [GET_PATIENTS_WITH_QUERY_STARTED](state, action) {
    return Object.assign({}, state, { loadingPatients: true });
  },
  [GET_PATIENTS_WITH_QUERY_SUCCEEDED](state, action) {
    const patients = action.payload;
    return Object.assign({}, state, { loadingPatients: false, patients });
  },
  [GET_GLOBAL_MESSAGES_STARTED](state, action) {
    return Object.assign({}, state, { loadingMessages: true });
  },
  [GET_GLOBAL_MESSAGES_SUCCEEDED](state, action) {
    const messages = action.payload;
    return Object.assign({}, state, { loadingMessages: false, messages });
  },
  [ADD_GLOBAL_MESSAGE_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingWideMess: true,
      toastWideMess: false,
    });
  },
  [ADD_GLOBAL_MESSAGE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      addedGlobalMessage: true,
      loadingWideMess: false,
      toastWideMess: true,
    });
  },
  [SET_PATIENT_HISTORY_SUCCEEDED](state, action) {
    const patient = action.payload;
    const { patientHistory } = state;
    let newHistory = [];
    if (patientHistory.length < 20) {
      newHistory = [patient, ...patientHistory];
    } else {
      newHistory = [patient, ...patientHistory];
      newHistory.pop();
    }
    return Object.assign({}, state, { patientHistory: newHistory });
  },
  [SET_DASHBORD_PATIENT_DEMOGRAPHICS_SUCCEEDED](state, action) {
    const { demographic } = action.payload;
    return Object.assign({}, state, { patientDemographics: demographic });
  },
  [SET_DASHBOARD_APPOINTMENTS_SUCCEEDED](state, action) {
    const { type, data } = action.payload;
    return Object.assign({}, state, { appointments: { ...state.appointments, [type]: data } });
  },
});
