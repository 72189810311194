import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import moment from "moment";
import { Icon, Input, Select, TimePicker } from "antd";
import MaskedInput from "antd-mask-input";
import {
  CloseIcon,
  ExpandSVG,
  YellowAlertSVG,
} from "../../../../common/components/icons/SVGIcon";
import DeleteIcon from "../../../../common/components/icons/DeleteIconButton";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import ExtendDataTable from "../../../../common/components/SubDataTable/ExtendDataTable";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { filterMedicationsByQuery as filterDataByQuery } from "../../constant";
import DeleteIconButton from "../../../../common/components/icons/DeleteIconButton";
import AutoCompleteDataTable from "../../../../common/components/AutoComplete/AutoCompleteDataTable";


const titleStyle = {
  width: "25%",
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  height: "100%",
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
};

const getTitle = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ ...titleStyle, paddingLeft: "0", borderLeft: 0 }}>Time</div>
      <div style={titleStyle}>BP</div>
      <div style={titleStyle}>HR</div>
      <div style={titleStyle}>Sp02</div>
    </div>
  );
};

const IntaOperativeSideBar = React.forwardRef(
  ({ resource, isShow, handleCloseSidebar, dataOnchange }, ref) => {
    const supplies = useSelector((state) => state.inventory.narcotics);
    const [inputMedication, setInputMedication] = useState("");
    const [loading, setLoading] = useState("");
    const [medicationNames, setMedicationNames] = useState([]);
    const [medications, setMedications] = useState([]);
    const [filteredMedications, setFilteredMedications] = useState([]);
    const [operatives, setOperatives] = useState([]);
    const [activeDelete, setActiveDelete] = useState(-1);
    const [hasTotalRadiationTime, setHasTotalRadiationTime] = useState(false);
    const [hasTotalContrastLoad, setHasTotalContrastLoad] = useState(false);
    const [hasComplication, setHasComplication] = useState(false);
    const dispatch = useDispatch();
    //
    const complication_value = useSelector(
      (state) => state.patientExamination.complication
    );
    const total_contrast_load = useSelector(
      (state) => state.patientExamination.total_contrast_load
    );
    const total_radiation_time = useSelector(
      (state) => state.patientExamination.total_radiation_time
    );
    const is_locked = useSelector((state) => state.common.is_locked);
    //
    const [totalRadiation, setTotalRadiation] = useState("");
    const [totalContrast, setTotalContrast] = useState("");
    const [complication, setComplication] = useState("");
    const debouncedSearch = useDebounce(inputMedication, 400);

    useEffect(() => {
      if (totalRadiation === '') {
        setHasTotalRadiationTime(false);
      } else {
        setHasTotalRadiationTime(true);
      }

      if (totalContrast === '') {
        setHasTotalContrastLoad(false);
      } else {
        setHasTotalContrastLoad(true);
      }

      if (complication === '') {
        setHasComplication(false);
      } else {
        setHasComplication(true);
      }
    }, [totalRadiation, totalContrast, complication])


    useEffect(() => {
      setComplication(complication_value);
    }, [complication_value]);

    useEffect(() => {
      setTotalContrast(total_contrast_load);
    }, [total_contrast_load]);

    useEffect(() => {
      setTotalRadiation(total_radiation_time);
    }, [total_radiation_time]);

    useEffect(() => {
      const params = {
        total_radiation_time: totalRadiation,
        total_contrast_load: totalContrast,
        complication: complication,
        medications: medications
      };
      dataOnchange(params);
    }, [complication, totalContrast, totalRadiation, medications]);

    useEffect(() => {
      if (debouncedSearch) {
        const allInventories = filterDataByQuery(supplies, debouncedSearch);
        setFilteredMedications(allInventories);
      }
    }, [debouncedSearch]);

    const addOperative = () => {
      setOperatives([
        {
          time: moment().format("h:mm A"),
          bp: "",
          hr: "",
          sp: "",
        },
        ...operatives,
      ]);
    };

    const handleChange = (type, value, index) => {
      const updatedOperatives = [...operatives];
      updatedOperatives[index][type] = value;
      setOperatives(updatedOperatives);
    };

    const handleSearchMedication = (search) => {
      setInputMedication(search);
    };

    const selectItemMedication = (medicationName, medicationId) => {
      if (medicationName === "") return;
      for (let medicationId_ of medications) {
        if (medicationId_ === medicationId) return;
      }

      let listItems = [...medications];
      listItems.push({
        id: medicationId,
        name: medicationName,
        dose: 0,
        route: ''
      });
      setMedications(listItems);
    };

    const removeRowMedication = (medication, index) => {
      let listItems = [...medications];
      listItems.splice(index, 1);
      setMedications(listItems);
    };

    const handleMedicationChange = (type, value, index) => {
      const updatedMedications = [...medications];
      updatedMedications[index][type] = value;
      setMedications(updatedMedications);
    };

    const patientExamination = useSelector((state) => state.patientExamination);

    useEffect(() => {
      setMedications(patientExamination?.medication_administration?.intra_op || []);
    }, [patientExamination])

    const removeItem = (index) => {
      if (activeDelete !== index) {
        setActiveDelete(index);
      } else {
        const updatedOperatives = [...operatives];
        updatedOperatives.splice(index, 1);
        setOperatives(updatedOperatives);
        setActiveDelete(-1);
      }
    };

    return (
      <div
        ref={ref}
        className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
      >
        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          <SidebarTitle icon={<Icon type="interaction" theme="twoTone" twoToneColor="#198038"/>} title="VITAL SIGNS" subTitle="" />

          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "1.5rem",
              position: "relative",
              zIndex: 5,
            }}
          >
            <button
              className="text-btn"
              style={{
                marginRight: "1rem",
                position: "absolute",
                top: "-24px",
                right: "22px",
                color:
                  is_locked === true || is_locked === 1 ? "#9B9C9F" : "#305AA2",
              }}
              onClick={addOperative}
              disabled={is_locked}
            >
              Add +
            </button>
            <div
              className="resourceInfo"
              style={{ marginTop: "0px", paddingTop: 0, paddingRight: 0 }}
            >
              <ExtendDataTable
                title={getTitle()}
                onClickRow={(index) =>
                  console.log("=== active sign ===", index)
                }
                data={(operatives || []).map((opt, index) => ({
                  key: `intra-operative-${Math.random()}-${index}`,
                  time: (
                    <TimePicker
                      format={"h:mm A"}
                      use12Hours
                      placeholder="Time"
                      style={{
                        maxWidth: "calc(100% - 5px)",
                        width: "calc(100% - 5px)",
                        marginRight: "5px",
                        background: "transparent",
                      }}
                      suffixIcon={<span />}
                      value={opt.time && moment(opt.time, "h:mm A")}
                      onChange={(value) =>
                        handleChange(
                          "time",
                          moment(value).format("h:mm A"),
                          index
                        )
                      }
                    />
                  ),
                  bp: (
                    <MaskedInput
                      style={{
                        width: "calc(100% - 5px)",
                        marginRight: "5px",
                        borderBottom: "1px solid #8d8d8d !important",
                        boxShadow: "none",
                      }}
                      mask="111/111"
                      value={opt.bp}
                      placeholder={"BP"}
                      onChange={(e) => {
                        // handleChange("bp", e.target.value, index);
                      }}
                      onFocus={(e) => {
                        e.preventDefault();
                      }}
                      onBlur={(e) => {
                        handleChange("bp", e.target.value, index);
                      }}
                      allowClear={false}
                    />
                  ),
                  hr: (
                    <Input
                      style={{
                        width: "calc(100% - 5px)",
                        marginRight: "5px",
                        borderBottom: "1px solid #8d8d8d !important",
                        boxShadow: "none",
                      }}
                      defaultValue={opt.hr}
                      placeholder={"HR"}
                      maxLength={3}
                      mask="111"
                      onChange={(e) => {
                        // handleChange("hr", e.target.value, index);
                      }}
                      onFocus={(e) => {
                        e.preventDefault();
                      }}
                      onBlur={(e) => {
                        handleChange("hr", e.target.value, index);
                      }}
                      allowClear={false}
                    />
                  ),
                  sp: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        paddingRight: "10px",
                      }}
                    >
                      <div style={{ flex: 1, paddingRight: "10px" }}>
                        <Input
                          style={{
                            width: "100%",
                            borderBottom: "1px solid #8d8d8d !important",
                            boxShadow: "none",
                          }}
                          maxLength={3}
                          mask="111"
                          defaultValue={opt.sp}
                          placeholder={"SP"}
                          onChange={(e) => {
                            // handleChange("sp", e.target.value, index);
                          }}
                          onFocus={(e) => {
                            e.preventDefault();
                          }}
                          onBlur={(e) => {
                            handleChange("sp", e.target.value, index);
                          }}
                          allowClear={false}
                        />
                      </div>
                      <DeleteIcon
                        handleClick={() => removeItem(index)}
                        selected={activeDelete === index}
                        icon="can"
                      />
                    </div>
                  ),
                }))}
                options={["time", "bp", "hr", "sp"]}
                widths={["25%", "25%", "25%", "25%"]}
                minRows={9}
              />
            </div>
          </div>
 
        <SidebarTitle
          icon={<Icon type="experiment" theme="twoTone" twoToneColor="#198038"/>}
          title="MEDICATION ADMINISTRATION"
        />

        <div
          className="resourceContent"
          style={{
            flex: "unset",
            paddingLeft: "24px",
            paddingRight: "1rem",
            paddingBottom: "1.5rem",
          }}
        >
          <div
            className="resourceInfo between flex-end"
            style={{ paddingRight: 0, marginTop: "0px", paddingTop: 0 }}
          >
            <AutoCompleteDataTable
              title="condition-medications"
              placeholder="Search Medications"
              keyLabel={"name"}
              keyValue={"id"}
              isResult={false}
              loading={loading}
              disableSearchIcon={false}
              handleSearch={handleSearchMedication}
              selectItem={selectItemMedication}
              removeItem={removeRowMedication}
              tableData={medications.map((row,index) => {
                return {
                  name: row.name,
                  dose: (
                    <Input
                      style={{
                        width: "calc(100% - 5px)",
                        marginRight: "5px",
                        borderBottom: "1px solid #8d8d8d !important",
                        boxShadow: "none",
                      }}
                      value={row.dose}
                      onChange={(e) => {
                        handleMedicationChange('dose', e.target.value, index);
                      }}
                      suffix="mcg/mL"
                      maxLength={3}
                      allowClear={false}
                    />
                  ),
                  route: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        paddingRight: "10px",
                      }}
                    >
                      <div style={{ flex: 1, paddingRight: "10px" }}>
                        <Select
                          suffixIcon={<ExpandSVG />}
                          allowClear={true}
                          value={row.route}
                          placeholder="Route"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            handleMedicationChange('route', val, index);
                          }}
                        >
                          {['IV','PO','IM'].map((r, index) => {
                            return (
                              <Select.Option
                                style={{ width: "100%" }}
                                key={`${r}-${index}`}
                                value={r}
                              >
                                {r}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </div>
                      <DeleteIcon
                        handleClick={() => {removeRowMedication('', index)}}
                        icon="can"
                      />
                    </div>
                  ),
                }
              })}
              options={filteredMedications}
              minRows={9}
              isLocked={is_locked}
              tableTitle="medication-administration"
              tableTitleOptions={['Medication', 'Dose', 'Route',]}
              tableOptions={['name','dose','route']}
              tableWidths={['50%','23%','27%']}
            />
          </div>
        </div>

          <SidebarTitle icon={<span />} title="DOCUMENTATION" />

          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "1rem",
              marginTop: "5px",
            }}
          >
            <div className="resourceInfo">
              <EditableFormInput
                label={"Total Radiation Time"}
                value={totalRadiation}
                unit={"min"}
                isError={!hasTotalRadiationTime}
                enableErrorText={!hasTotalRadiationTime}
                required={true}
                helperText='Must Enter Total Radiation Time'
                handleChange={setTotalRadiation}
                disabled={is_locked}
              />
            </div>
            <div className="resourceInfo">
              <EditableFormInput
                label={"Total Contrast Load"}
                value={totalContrast}
                unit={"mL"}
                isError={!hasTotalContrastLoad}
                enableErrorText={!hasTotalContrastLoad}
                required={true}
                helperText='Must Enter Contrast Load'
                handleChange={setTotalContrast}
                disabled={is_locked}
              />
            </div>
            <div className="resourceInfo">
              <EditableFormInput
                label={"Complication"}
                value={complication}
                isError={!hasComplication}
                enableErrorText={!hasComplication}
                required={true}
                helperText='Must Select Complication'
                handleChange={setComplication}
                type={"check"}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                optionKey={"label"}
                valueKey={"value"}
                disabled={is_locked}
              />
            </div>
          </div>
        </ScrollWrapper>
      </div>
    );
  }
);

export default IntaOperativeSideBar;
