import React, { Component } from "react";
import { connect } from "react-redux";
import ScrollWrapper from "../../components/ScrollBar";
import { BASE_SERVER, DICOM_WEB, S3_PATH } from "../../constants/constants";
import ReactPlayer from "react-player";
import Dicom from "../../components/Dicom";
import { setURLImage } from "../../../store/actions";

class VideoImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      Hidelink: false,
      Videofile: false,
      imageCheck: "",
      width: 800,
      height: 500,
      url: "",
      checkRender: true,
      isPlay: true,
    };
    this.selectRef = React.createRef();
  }

  componentWillReceiveProps(nextProp) {
    if (
      nextProp.image_list &&
      nextProp.image_list.length > 0 &&
      nextProp.id_image === 0
    ) {
      this.setState({
        Hidelink: false,
        images:
          (nextProp.image_list &&
            nextProp.image_list[0] &&
            nextProp.image_list[0].images) ||
          [],
      });
    } else if (
      nextProp.image_list &&
      nextProp.image_list.length > 0 &&
      nextProp.id_image > 0
    ) {
      this.setState({
        Hidelink: false,
        images:
          (nextProp.image_list &&
            nextProp.image_list[`${nextProp.id_image}`] &&
            nextProp.image_list[`${nextProp.id_image}`].images) ||
          [],
      });
    } else if (!nextProp.image_list || nextProp.image_list.length === 0) {
      this.setState({
        Hidelink: true,
      });
    }
  }

  actionClick = async (value) => {
    if (value.indexOf("MP4") > -1 || value.indexOf("mp4") > -1) {
      await this.setState({
        Videofile: true,
        imageCheck: "",
        url: value,
      });
    } else {
      await this.setState({
        Videofile: false,
        checkRender: false,
      });
      if (!this.state.Videofile) {
        await this.props.setURLImage(value);
        this.setState({
          checkRender: true,
        });
      }
      // let e = await document.getElementById("viewerFrame");
      // e.src = DICOM_WEB + "?" + value;
    }
  };

  componentDidMount() {
    const width = this.selectRef.current.clientWidth;
    this.setState({
      width: width,
    });
  }

  render() {
    const { Hidelink, checkRender } = this.state;
    const { Videofile, url } = this.state;
    return (
      <div style={{ height: "100%", width: "100%" }} ref={this.selectRef}>
        {!Videofile ? (
          <div
            style={{
              backgroundColor: "black",
              height: '100%',
              width: "100%"
            }}
          >
            {checkRender ? (
              <Dicom widthMax={this.state.width || 800} />
            ) : (
              <div className="size-dicom-default-view"></div>
            )}
          </div>
        ) : (
          <div className="player-wrapper video-size-dicom">
            <ReactPlayer
              ref="videos"
              className="react-player"
              url={this.state.url}
              width="100%"
              height="100%"
              playing={this.state.isPlay}
              muted={true}
              volume={0}
              loop
              playsinline={true}
              autoPlay
            />
            <div className="player-control">
              <div className="player-control-container">
                <div
                  className="button-video-back"
                  onClick={() => {
                    this.setState({
                      isPlay: false,
                    });
                    this.refs.videos.seekTo(
                      this.refs.videos.getCurrentTime() - 1
                    );
                  }}
                >
                  <i class="play-backwards"></i>
                </div>
                <div
                  className="button-video-play-pause"
                  onClick={() => {
                    this.setState({
                      isPlay: !this.state.isPlay,
                    });
                  }}
                >
                  {this.state.isPlay ? (
                    <i class="play-pause"></i>
                  ) : (
                    <i className="play-button"></i>
                  )}
                </div>
                <div
                  className="button-forward"
                  onClick={() => {
                    this.setState({
                      isPlay: false,
                    });
                    this.refs.videos.seekTo(
                      this.refs.videos.getCurrentTime() + 1
                    );
                  }}
                >
                  <i class="play-forwards"></i>
                </div>
              </div>
            </div>
          </div>
        )}
        {!Hidelink ? (
          <div
            style={{
              height: "15%",
              marginTop: 5,
              width: "100%",
              maxWidth: this.state.width,
            }}
          >
            <ScrollWrapper css="no-padding tab-header-scroll">
              <div
                style={{
                  display: "flex",
                }}
              >
                {this.state.images.map((items, index) => (
                  <div
                    style={{
                      backgroundImage: "url(" + items.thumbnail_url + ")",
                      marginLeft: index > 0 ? 5 : 0,
                    }}
                    key={items.id}
                    className="image-dicom"
                    onClick={() => {
                      this.actionClick(items.image_location);
                    }}
                  />
                ))}
              </div>
            </ScrollWrapper>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  image_list: state.common.image_list,
  id_image: state.common.id_image,
});

const mapDispatchToProps = {
  setURLImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoImageViewer);
