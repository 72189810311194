import React, { Component } from "react";
import './PaymentForm.scss';

export default class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardBrand: "",
      nonce: undefined,
      googlePay: false,
      applePay: false,
      masterpass: false,
    };
    this.requestCardNonce = this.requestCardNonce.bind(this);
  }

  requestCardNonce() {
    this.paymentForm.requestCardNonce();
  }

  componentDidMount() {
    /**
     * real account info
     * applicationId: sq0idp-83EvT45kRgQvY0cXERCjMQ
     * locationId: 2DQ147WJ97CRM
     * sandbox account info
     * applicationId: sandbox-sq0idb-UuNDp7QLB8KM_0oyXaMMLQ
     * locationId: 5XXN7M9KZ11RV
     */ 

    const config = {
      applicationId: "sq0idp-83EvT45kRgQvY0cXERCjMQ",
      locationId: "2DQ147WJ97CRM",
      inputClass: "sq-input",
      autoBuild: false,
      inputStyles: [
        {
          fontSize: "16px",
          fontFamily: "Helvetica Neue",
          padding: "16px",
          color: "#373F4A",
          backgroundColor: "transparent",
          lineHeight: "1.15em",
          placeholderColor: "#000",
          _webkitFontSmoothing: "antialiased",
          _mozOsxFontSmoothing: "grayscale",
        },
      ],
      applePay: {
        elementId: "sq-apple-pay",
      },
      masterpass: {
        elementId: "sq-masterpass",
      },
      googlePay: {
        elementId: "sq-google-pay",
      },
      cardNumber: {
        elementId: "sq-card-number",
        placeholder: "Credit/Debit Card Number",
      },
      cvv: {
        elementId: "sq-cvv",
        placeholder: "CVV",
      },
      expirationDate: {
        elementId: "sq-expiration-date",
        placeholder: "Expiration MM/YY",
      },
      postalCode: {
        elementId: "sq-postal-code",
        placeholder: "Zip",
      },
      name: {
        elementId: 'sq-name',
        placeholder: 'Name'
      },
      callbacks: {
        methodsSupported: (methods) => {
          if (methods.googlePay) {
            this.setState({
              googlePay: methods.googlePay,
            });
          }
          if (methods.applePay) {
            this.setState({
              applePay: methods.applePay,
            });
          }
          if (methods.masterpass) {
            this.setState({
              masterpass: methods.masterpass,
            });
          }
          return;
        },
        createPaymentRequest: () => {
          return {
            requestShippingAddress: false,
            requestBillingInfo: true,
            currencyCode: "USD",
            countryCode: "US",
            total: {
              label: "MERCHANT NAME",
              amount: "20",
              pending: false,
            },
            lineItems: [
              {
                label: "Subtotal",
                amount: "20",
                pending: false,
              },
            ],
          };
        },
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            console.log("Encountered errors:");
            let errorString = '';
            errors.forEach(function (error) {
              errorString += error.message+'<br/>';
              console.log("  " + error.message);
            });
            document.getElementById("sq-errors").innerHTML = errorString;

            return;
          }
          this.setState({
            nonce: nonce,
          });
        },
        unsupportedBrowserDetected: () => {},
        inputEventReceived: (inputEvent) => {
          switch (inputEvent.eventType) {
            case "focusClassAdded":
              break;
            case "focusClassRemoved":
              break;
            case "errorClassAdded":
              document.getElementById("sq-errors").innerHTML =
                "Please fix card information errors before continuing.";
              break;
            case "errorClassRemoved":
              document.getElementById("sq-errors").style.display = "none";
              break;
            case "cardBrandChanged":
              if (inputEvent.cardBrand !== "unknown") {
                this.setState({
                  cardBrand: inputEvent.cardBrand,
                });
              } else {
                this.setState({
                  cardBrand: "",
                });
              }
              break;
            case "postalCodeChanged":
              break;
            default:
              break;
          }
        },
        paymentFormLoaded: function () {
          document.getElementById("sq-name").style.display = "inline-flex";
        },
      },
    };
    this.paymentForm = new this.props.paymentForm(config);
    this.paymentForm.build();
  }

  render() {
    const { resource } = this.props;
    return (
      <div className='sqOuterContainer'>
        <div className="form-container">
          <div id="sq-walletbox">
            <button
              style={{ display: this.state.applePay ? "inherit" : "none" }}
              className="wallet-button"
              id="sq-apple-pay"
            />
            <button
              style={{ display: this.state.masterpass ? "inherit" : "none" }}
              className="wallet-button"
              id="sq-masterpass"
            />
            <button
              style={{ display: this.state.googlePay ? "inherit" : "none" }}
              className="wallet-button"
              id="sq-google-pay"
            />
            <hr />
          </div>

          <div id="sq-ccbox">
            <p>
              <span style={{color: '#333', fontWeight: 'normal'}}>Enter your card information: </span>
              <span style={{ float: 'right' }}>
                {this.state.cardBrand.toUpperCase()}
              </span>
            </p>
            <div id="cc-field-wrapper">
              <div id="sq-card-number" style={styles.inputField} />
              <input type="hidden" id="card-nonce" name="nonce" />
              <br/>
              <div id="sq-expiration-date" style={styles.inputField} />
              <div id="sq-cvv" style={styles.inputField} />
              <a href='javascript:void(0)' id="sq-helpicon">&nbsp;</a>
            </div>
            <input
              id="sq-name"
              type="text" placeholder="Name" style={styles.name} defaultValue={resource.first_name+' '+resource.last_name}
            />
            <div id="sq-postal-code" style={Object.assign({}, styles.inputField)} />
          </div>
          <button
            onClick={this.requestCardNonce}
            style={styles.payButton}
          >
            Pay
          </button>
        </div>
        <div id='sq-errors' style={{
          fontSize: '14px', fontWeight: 400, 
          lineHeight: '1.125rem', letterSpacing: '0.16px',
          color: '#152934', wordBreak: 'break-word'
        }}>
          
        </div>
      </div>
    );
  }
}

const styles = {
  inputField: {
    border: '1px solid #d6d6d6'
  },
  name: {
    verticalAlign: "top",
    display: "none",
    margin: 0,
    border: "none",
    fontSize: "16px", fontFamily: "Helvetica Neue",
    padding: "16px",
    color: "#373F4A",
    backgroundColor: "transparent",
    lineHeight: "1.15em",
    placeholderColor: "#000",
    boxShadow: "none",
    _webkitFontSmoothing: "antialiased",
    _mozOsxFontSmoothing: "grayscale",
  },
  payButton: {
    backgroundColor: '#3d70b2',
    color: '#ffffff', fontSize: '.875rem', fontWeight: '400',
    lineHeight: 1, width: 'auto', 
    minWidth: '150px', height: 'auto', minHeight: '2.5rem',
    padding: 'calc(0.875rem - 3px) 12px calc(0.875rem - 3px) 12px',
    display: 'inline-flex',
    justifyContent: 'flex-start', alignItems: 'center',
    textDecoration: 'none', outline: 'none',
    border: 'none', boxShadow: 'none',
    whiteSpace: 'nowrap', cursor: 'pointer',
    float: 'right', marginTop: 15
  }
}
