import React, { useState, useEffect, useRef } from "react";
import { SortingArrowIcon } from "../icons/SortingIcon";
import DeleteIconButton from "../icons/DeleteIconButton";
import ScrollWrapper from "../ScrollBar";
import { useWindowSize } from "../../utils";

import "./style.scss";
import Loading from "../Loading";

const DataTable = ({
  labels,
  widths,
  tableWidth,
  rowData,
  columns,
  sorts,
  title,
  hideHeader,
  allActive,
  defaultActive,
  handleClickRow,
  handleSort,
  disableDelete,
  handleDelete,
  loadMore,
  fetchList,
  disableIcon,
  isXHidden,
  loading,
}) => {
  const tableRef = useRef(null);
  const [sortOptions, setSortOptions] = useState([]);
  const [activeRow, setActiveRow] = useState(defaultActive || 0);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const size = useWindowSize(tableRef);

  let emptyRows = [];

  useEffect(() => {
    if (sorts) {
      setSortOptions(sorts);
    }
  }, [sorts]);

  useEffect(() => {
    if (rowData.length <= 0 && fetchList) {
      fetchList();
    } else {
      // fetchDetail();
    }
    if (tableRef) {
      const initialSize = {
        width: tableRef.current.clientWidth,
        height: tableRef.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [tableRef]);

  const handleSortOption = (index, sort) => {
    const newSorts = [...sortOptions];
    newSorts[index] = sort;
    setSortOptions(newSorts);

    if (handleSort) {
      handleSort(columns[index].key, sort);
    }
  };

  const handleRow = (index, row) => {
    setActiveRow(index);

    if (handleClickRow) {
      handleClickRow(row, index);
    }
  };

  const handleDeleteRow = (index, row) => {
    if (activeDelete !== index) {
      setActiveDelete(index);
      return;
    }
    if (handleDelete) {
      handleDelete(row, index);
    }
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = rowData.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > rowData.length) {
    emptyRows = new Array(rowCount - rowData.length).fill({});
  }

  const onLoadMore = (values) => {
    if (loadMore) {
      loadMore(values);
    }
  };

  return (
    <div className="primaryDataTable" ref={tableRef}>
      <ScrollWrapper onScrollFrame={onLoadMore} css={`no-padding ${isXHidden ? 'x-hidden' : ''}`}>
        {!hideHeader && (
          <div
            className="primaryDataTable-header sortableHeader"
            style={{
              minWidth: tableWidth ? `${tableWidth}px` : "768px",
              minHeight: "50px",
            }}
          >
            {labels &&
              labels.length > 0 &&
              labels.map((label, index) => (
                <div
                  className={`th sortable ${
                    !sortOptions[index] ? "hide-icon" : ""
                  }`}
                  style={{
                    width: disableIcon
                      ? `${widths[index]}`
                      : index === 0
                      ? `calc(${widths[index]} - 3rem`
                      : widths[index] || `calc(100% / ${labels.length})`,
                    minWidth: disableIcon
                      ? `${widths[index]}`
                      : index === 0
                      ? `calc(${widths[index]} - 3rem`
                      : widths[index] || `calc(100% / ${labels.length})`,
                    display: hideHeader ? "none" : "flex",
                    alignItems: "center",
                    height: "50px",
                    marginLeft: disableIcon ? 0 : index === 0 ? "3rem" : 0,
                  }}
                  key={`${title}-head-${index}`}
                >
                  <div
                    className="primaryDataTable-header__label"
                    style={{
                      width: "100%",
                      minWidth: "20%",
                      borderBottom: "none",
                      paddingLeft: "0.75rem",
                      color: "rgba(0, 0, 0, 0.65)",
                    }}
                  >
                    {label}
                  </div>

                  {columns && columns[index] && columns[index].sortable && (
                    <SortingArrowIcon
                      sort={sortOptions[index]}
                      handleSortASC={() => handleSortOption(index, "asc")}
                      handleSortDESC={() => handleSortOption(index, "desc")}
                    />
                  )}
                </div>
              ))}
          </div>

        )}

        <div
          className="primaryDataTable-body"
          style={{ minWidth: tableWidth ? `${tableWidth}px` : "768px" }}
        >
          <div
            className="tableList"
            style={{ height: "100%", minHeight: "15vh" }}
          >
            {rowData &&
              rowData.length > 0 &&
              rowData.map((row, index) => (
                <div
                  className={`tableItem yellow ${
                    activeRow === index ? "active" : ""
                  } ${allActive ? "active" : ""}`}
                  key={`${title}-body-${index}-${row.key}`}
                  onClick={() => handleRow(index, row)}
                >
                  {columns &&
                    columns.length > 0 &&
                    columns.map((col, idx) => (
                      <div
                        className="tableItemField"
                        style={{
                          width: widths[idx] || `calc(100% / ${labels.length})`,
                          minWidth:
                            widths[idx] || `calc(100% / ${labels.length})`,
                        }}
                        key={`${title}-col-${index}-${idx}`}
                      >
                        <div
                          className={
                            idx === labels.length - 1
                              ? !disableDelete
                                ? "last-value with-delete p"
                                : "last-value p"
                              : !labels || !labels.length
                              ? "pr-0 p"
                              : "p"
                          }
                        >
                          {row[col.key]}
                        </div>
                        {!disableDelete &&
                          idx === labels.length - 1 &&
                          !row.disableDelete && (
                            <DeleteIconButton
                              handleClick={() => handleDeleteRow(index, row)}
                              selected={activeDelete === index}
                              icon={"can"}
                              extraStyle={{
                                position: "absolute",
                                display: "inline-block",
                                right: "0.75rem",
                                top: "10px",
                                cursor: "pointer",
                              }}
                            />
                          )}
                      </div>
                    ))}
                </div>
              ))}
            {(emptyRows || []).map((item, index) => (
              <div className="tableItem" key={`chart-empty-table-${index}`}>
                <div
                  className="tableItemField"
                  style={{ width: "100%", minWidth: "100%" }}
                />
              </div>
            ))}
          </div>
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default DataTable;
