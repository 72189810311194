import React, { useState, useEffect, useRef } from "react";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import VariancesSidebar from "./VariancesSidebar";
import { useWindowSize } from "../../../../common/utils";
import { StaticResource, DashboardRows } from "../../constant";
import {
  ExpandSVG,
  LeftCharvenSVG,
} from "../../../../common/components/icons/SVGIcon";
import { Select } from "antd";
import {
  incident_reports_all,
  getUsers,
  getLocations,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import DataTable from "../../../../common/components/DataTable/DataTable";

const { Option } = Select;

const Variances = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const dispatch = useDispatch();

  const incidents = useSelector((state) => state.common.incident_reports_all);
  const users = useSelector((state) => state.common.users);
  const locations = useSelector((state) => state.common.locations);

  const tableRows = incidents.length > 0 ? incidents : [];
  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleRow = (index) => {
    setRow(index);
    handleSidebar();
  };

  const fetchList = () => {
    dispatch(incident_reports_all());
    dispatch(getUsers());
    dispatch(getLocations());
  };

  useEffect(() => {
    fetchList();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="vacation_request"
                labels={[
                  "Employee Name",
                  'Variance',
                  'Incident Date / Time',
                  'Facility'
                ]}
                widths={['calc(31% - 2rem)', '23%', '23%', '23%']}
                columns={[
                  { sortable: false, key: 'name' }, 
                  { sortable: false, key: 'variance' }, 
                  { sortable: false, key: 'incident' }, 
                  { sortable: false, key: 'facility' }, 
                ]}
                disableDelete={true}
                handleClickRow={(row, index) => handleRow(index)}
                defaultActive={-1}
                rowData={tableRows.map((row, index) => {
                  return {
                    name: row.user && row.user.username,
                    variance: row.variance_type,
                    incident: row.created_at,
                    facility: row.location && row.location.city
                  }
                })}
              />
            </div>
          </div>
        </div>
      </div>

      {incidents && !!incidents.length && activeIndex >= 0 && (
        <VariancesSidebar
        ref={sidebarRef}
          locations={locations || []}
          users={users && users.active_users}
          dataDetail={
            incidents && incidents[activeIndex]
              ? incidents[activeIndex]
              : undefined
          }
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}

      {incidents && !!incidents.length && activeIndex >= 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Variances;
