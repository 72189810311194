/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { MdSend } from "react-icons/md";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {VideoFile, ImageFile} from "../MediaFiles";
import {
  EditorState,
  // ContentState,
  // convertFromHTML,
  // convertToRaw,
  convertFromRaw,
} from "draft-js";

function DnDTextArea({
  title,
  onInPutAreaChange,
  onSentMessage,
  onSubmit,
  isReset,
  mediaFiles,
  onRemoveBtnClicked,
  fileDoubleClicked,
  updatableContent
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [focus, setFocus] = useState(false);
  useEffect(() => {
    if (isReset) setEditorState(EditorState.createEmpty());
  }, [isReset]);

  useEffect(() => {
    if (!!updatableContent) {
      const {comment} = updatableContent;
      const contentState = convertFromRaw( JSON.parse( comment) );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState); 
    }
  }, [updatableContent]);

  const handleEditorStateChange = (editorStateData) => {
    setEditorState(editorStateData);
    onInPutAreaChange(editorStateData);
  };

  const handleSubmitBtnClicked = () => {
    onSubmit();
    onSentMessage();
    setEditorState(EditorState.createEmpty());
  };

  return (
    <div className={`inputarea-wrapper ${focus ? 'focus-wrapper' : ''}`}>
      <div className="DraftEditorPlaceholder-inner">
        {title}
      </div>
      {mediaFiles.length > 0 && (
        <div className="media-files-area">
          {mediaFiles.map((element, index) => {
            const fileName = element.url.replace(
              "https://cvaipatientfiles.s3.amazonaws.com/supports/",
              ""
            );
            return (
              <div className="item-media-file" key={index}>
                {element.type === "image" && (
                  <ImageFile
                    resource={element}
                    fileName={fileName}
                    customStyle="item-image"
                    fileDoubleClicked={fileDoubleClicked}
                    onRemoveBtnClicked={onRemoveBtnClicked}
                  />
                )}
                {element.type === "video" && (
                  <VideoFile
                    fileName={fileName}
                    resource={element}
                    customStyle="item-video-file"
                    fileDoubleClicked={fileDoubleClicked}
                    onRemoveBtnClicked={onRemoveBtnClicked}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      <Editor
        toolbar={{
          options: ["inline", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            options: ["unordered", "ordered"],
          },
        }}
        wrapperClassName="input-wrapper-area"
        toolbarClassName="input-toolbar-area"
        editorClassName="input-editor-area"
        editorState={editorState}
        placeholder={title}
        onFocus={(e) => setFocus(true)}
        onBlur={(e) => setFocus(false)}
        onEditorStateChange={(val) => handleEditorStateChange(val)}
        toolbarCustomButtons={[
          <SendMessage 
            onClick={handleSubmitBtnClicked}
          />
        ]}
      />
    </div>
  );
}

function SendMessage ({onClick}) {
  const handleSendMessage = () => {
    onClick();
  }

  return (
    <div onClick={handleSendMessage}>
      <MdSend className="message-sender"/>
    </div>
  );
}

export default DnDTextArea;
