import { createReducer } from 'redux-create-reducer';
import moment from 'moment';
import AT from '../actionTypes';

const initialState = {
  planSuggestions: []
}

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`] (state, action) {
    return {
      ...initialState
    }
  },
  [`${AT.CLINIC_PLAN_LOAD_INIT}_SUCCEEDED`] (state, action) {
    let { planSuggestions } = action.payload;
    
    return {
      ...state,
      planSuggestions
    }
  }  
})
