import { Icon } from 'antd';
import React from 'react';
import { CheckSVG, RedCloseSVG } from '../icons/SVGIcon';
import './style.scss';

const FormStepper = ({
  steps,
  conditions,
  currentStep,
  handleStep,
  handleContinue,
  onCloseStep,
  resource,
  compelete_step_communication,
  onGetPending,
  ...restProps
}) => {
  const onStepClick = stepIndex => () => {
    handleStep(stepIndex);
    if (onCloseStep) {
      if (compelete_step_communication) {
        if (onGetPending) {
          onGetPending();
        }
      }
      onCloseStep(currentStep);
    }
  };

  return (
    <div className='formStepContainer'>
      {steps.length > 0 && steps.map((step, index) => {
        return (
          <div
            key={`step-${step}-${index}`}
            className={`formStepper ${index <= currentStep ? 'active' : ''}`}
          >
            <div className='formStepper__header' onClick={onStepClick(index)}>
              <div className='formStepper__status'>
                {/* {index === currentStep && <span className='formStepper__status-active'>{index + 1}</span>} */}
                {index >= 0 && !conditions && <span className='formStepper__status-check'><Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/></span>}
                {index >= 0 && conditions && !conditions[index] && <span className='formStepper__status-check'><Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/></span>}
                {index >= 0 && conditions && conditions[index] && conditions[index].status && <span className='formStepper__status-check'><Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/></span>}
                {index >= 0 && conditions && conditions[index] && !conditions[index].status && <span className='formStepper__status-check'><Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28"/></span>}
                {/* {index > currentStep && <span className='formStepper__status-deactive'>{index + 1}</span>} */}
              </div>
              <div className='formStepper__title'>{step.title}</div>
            </div>

            <div className='formStepper__content'>
              {index === currentStep && step.content && <step.content resource={resource} handleContinue={handleContinue} stepIndex={index} {...restProps} />}
            </div>
          </div>
        )
      })}
    </div>


  );
};

export default FormStepper;
