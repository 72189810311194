import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CardBlock from "../../../common/components/Card";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Loading from "../../../common/components/Loading";

import {
  setProcedureDetails,
  search_insurance_companies,
  get_me_variable_rule,
  save_me_variable_rule,
  save_prior_authorization_settings,
  get_prior_authorization_settings,
} from "../../../store/actions";

import NewPatientBlock from "./NewPatient";
import EstablishedPatientBlock from "./EstablishedPatient";
import PriorAuthorization from "./PriorAuthorization";
import OutgoingReferrals from "./OutgoingReferrals";
import CarrierInsuranceInformation from "./AddCarrier";
import TimeAlottment from "./TimeAlottment";
import "../style.scss";

const MeaningfulUseDetail = (props) => {
  const [resource, setResource] = useState(null);
  const [localCarriers, setLocalCarriers] = useState([]);
  const new_patients = useSelector(
    (state) => state.procedureDetail.new_patients
  );
  const established_patients = useSelector(
    (state) => state.procedureDetail.established_patients
  );
  const meaningful_uses = useSelector(
    (state) => state.procedureDetail.meaningful_uses
  );
  const dispatch = useDispatch();
  const detail_new_patient_type = useSelector(
    (state) => state.procedureDetail.detail_new_patient_type
  );
  const loading_search_insurance_companies = useSelector(
    (state) => state.newPatient.loading_search_insurance_companies
  );
  const loading_get_me_variable = useSelector(
    (state) => state.procedureDetail.loading_get_me_variable
  );
  const detail_established_patient_type = useSelector(
    (state) => state.procedureDetail.detail_established_patient_type
  );
  const prior_authorization_settings = useSelector(
    (state) => state.procedureDetail.prior_authorization_settings
  );

  const carriers = useSelector((state) => state.procedureDetail.carriers);
  const insurances = useSelector((state) => state.procedureDetail.insurances);

  const updateCPTByNew = async (codes, type) => {
    if (type == "meaningful_uses") {
      const arrayADD = codes.map((r) => {
        return {
          condition_serialize: r?.condition_serialize
            ? [...r?.condition_serialize]
            : [
                {
                  question_id: undefined,
                  answer: undefined,
                  question_name: undefined,
                  comparison: ">=",
                  operator: "Select",
                },
              ],
        };
      });
      let param = {
        condition_serialize: [
          {
            question_id: undefined,
            question_name: undefined,
            answer: undefined,
            comparison: ">=",
            operator: "Select",
          },
        ],
      };
      await dispatch(
        setProcedureDetails(
          type,
          arrayADD && Object.keys(arrayADD).length > 0
            ? [...arrayADD, param]
            : [param]
        )
      );
      const paramsRule = {
        type:
          type == "new_patients"
            ? "new_patient"
            : type == "established_patients"
            ? "established_patient"
            : "meaningful_use",
        condition_serialize: param.condition_serialize,
      };
      const valueType =
        type == "new_patients"
          ? "new_patient"
          : type == "established_patients"
          ? "established_patient"
          : "meaningful_use";
      await dispatch(save_me_variable_rule(paramsRule));
      await dispatch(get_me_variable_rule(valueType));
    } else {
      const arrayADD = codes.map((r) => {
        return {
          cpt_code_id: r?.cpt_code_id,
          type:
            type == "new_patients"
              ? "new_patient"
              : type == "established_patients"
              ? "established_patient"
              : "meaningful_use",
          condition_serialize: r?.condition_serialize
            ? [...r?.condition_serialize]
            : [
                {
                  type:
                    type == "new_patients"
                      ? "new_patient"
                      : type == "established_patients"
                      ? "established_patient"
                      : "meaningful_use",
                  cpt_code: { code: "", short_desc: "" },
                  question_id: undefined,
                  answer: undefined,
                  question_name: undefined,
                  comparison: ">=",
                  operator: "Select",
                },
              ],
        };
      });
      let param = {
        cpt_code_id: 1,
        type:
          type == "new_patients"
            ? "new_patient"
            : type == "established_patients"
            ? "established_patient"
            : "meaningful_use",
        condition_serialize: [
          {
            type:
              type == "new_patients"
                ? "new_patient"
                : type == "established_patients"
                ? "established_patient"
                : "meaningful_use",
            cpt_code: { code: "", short_desc: "" },
            question_id: undefined,
            question_name: undefined,
            answer: undefined,
            comparison: ">=",
            operator: "Select",
          },
        ],
      };
      await dispatch(
        setProcedureDetails(
          type,
          arrayADD && Object.keys(arrayADD).length > 0
            ? [...arrayADD, param]
            : [param]
        )
      );
    }
  };

  const handleAddNewData = async (type, data) => {
    const dataOld = [...prior_authorization_settings];
    const saveSuccess = await dispatch(
      save_prior_authorization_settings({
        settings: [...dataOld, data],
      })
    );
    if (saveSuccess) {
      await dispatch(get_prior_authorization_settings());
      await dispatch(search_insurance_companies(""));
    }
  };

  const handleAddNewCarrier = async (type, data) => {
    // const dataOld = [...carriers];
    // const saveSuccess = await dispatch(
    //   save_prior_authorization_settings({
    //     settings: [...dataOld, data],
    //   })
    // );
    // if (saveSuccess) {
    //   await dispatch(get_prior_authorization_settings());
    //   await dispatch(search_insurance_companies(""));
    // }
    setLocalCarriers([...localCarriers, data]);
  };

  return (
    <div
      className="clinicWorkflow__main-section"
      style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}
    >
      <div className="fullContainer has-no-sidebar">
        <ScrollWrapper css="no-padding x-hidden">
          <div
            className="mainContainer"
            style={{
              marginLeft: "-10px",
              paddingTop: "0",
              paddingBottom: "0",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "stretch",
            }}
          >
            <CardBlock
              title="New Patient"
              varient="half"
              other=""
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      detail_new_patient_type &&
                      Object.keys(detail_new_patient_type).length <= 0
                    }
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      if (!loading_get_me_variable) {
                        updateCPTByNew(new_patients, "new_patients");
                      }
                    }}
                  >
                    Add +
                  </button>
                </div>
              }
            >
              <NewPatientBlock />
            </CardBlock>

            <CardBlock
              title="Established Patient"
              varient="half"
              other=""
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={
                      detail_established_patient_type &&
                      Object.keys(detail_established_patient_type).length <= 0
                    }
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      if (!loading_get_me_variable) {
                        updateCPTByNew(
                          established_patients,
                          "established_patients"
                        );
                      }
                    }}
                  >
                    Add +
                  </button>
                </div>
              }
            >
              <EstablishedPatientBlock />
            </CardBlock>

            <CardBlock title="Outgoing Referrals" varient="quarter" other="">
              <OutgoingReferrals />
            </CardBlock>

            <CardBlock
              title="Require Prior Authorization"
              varient="quarter"
              other=""
              contentPadding="1rem"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    disabled={loading_get_me_variable}
                    className="text-btn"
                    onClick={() => {
                      handleAddNewData("insurances", {
                        insurance_company_id: "",
                        name_isurance: "",
                        cpt_code: "",
                      });
                    }}
                  >
                    Add Insurance +
                  </button>
                </div>
              }
            >
              <PriorAuthorization />
            </CardBlock>

            <CardBlock
              title="Carrier Insurance Information"
              varient="quarter"
              other=""
              // subAction={
              //   <div
              //     style={{
              //       display: "inline-flex",
              //       alignItems: "center",
              //       justifyContent: "flex-end",
              //     }}
              //   >
              //     <button
              //       disabled={loading_get_me_variable}
              //       className="text-btn"
              //       onClick={() => {
              //         handleAddNewCarrier("carriers", {
              //           carrier_insurance: "",
              //           name: "",
              //           address: "",
              //           city: "",
              //           state: "",
              //           zip: "",
              //           phone: "",
              //           fax: ""
              //         });
              //       }}
              //     >
              //       Add Carrier +
              //     </button>
              //   </div>
              // }
            >
              <CarrierInsuranceInformation
              // data={localCarriers}
              // setData={setLocalCarriers}
              />
            </CardBlock>
            <CardBlock
              title="Time Alottment"
              varient="quarter"
              other=""
            >
              <TimeAlottment />
            </CardBlock>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default MeaningfulUseDetail;
