import React, { useState } from 'react';
import { Checkbox } from 'antd';
import './style.scss'

const YesNo = ({ title, subStatus, option, setOption, horizontal }) => {
  const [isYes, setYes] = useState(-1);

  return (
    <div style={{ minWidth: horizontal ? '70px' : '115px' }}>
      <Checkbox style={{ marginLeft: horizontal ? '8px' : 0 }} checked={option === 1} onChange={() => setOption(1)}>Yes</Checkbox>
      <Checkbox checked={option === 0} onChange={() => setOption(0)}>No</Checkbox>
    </div>
  )
};

export default YesNo;
