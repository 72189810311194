import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Icon } from 'antd';
import SubSideNav from '../../common/components/SubSideNav';
import MainContainer from './InventoryWorkflow';

export default class InventoryWorkflow extends Component {
  sideNavLinks = [{
    link: '/inventory-workflow/inventory',
    label: 'Inventory Module'
  }];

  state = {
    isSideNav: true
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Inventory Workflow</title>
        <meta name="title" content="FAACT Vascular | Inventory Workflow" />
      </Helmet>
    )
  }

  openSideNav = () => {
    this.setState({ isSideNav: true });
  }

  closeSideNav = () => {
    this.setState({ isSideNav: false });
  }

  getTitle = () => (
    <div className="sub-title">
      <Icon type="apartment" />
      Inventory Workflow
    </div>
  );

  render() {
    const { location } = this.props;
    const { isSideNav } = this.state;
    return (
      <div className="clinicWorkflow">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
        />
        <MainContainer location={location} isSideNav={isSideNav} />
      </div>
    )
  }
}
