import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Input, Empty, Icon } from "antd";

import {
  setStepData,
  search_insurance_companies,
  save_patient_insurances,
  patient_insurances_id_insurances,
  edit_patient_insurances,
  delete_patient_insurances,
  check_patient_insurances,
  reset_check_patient,
  setGlobalLoading, setEnterInsuranceCheck,
} from "../../../store/actions";
import { validateSteps } from "../../../services/validations";
import { newPatientOptions } from "../../../common/constants/initialData";
import Loading from "../../../common/components/Loading";
import { ExpandSVG, TrashSVG } from "../../../common/components/icons/SVGIcon";
import ExtendDataTable from "../../../common/components/SubDataTable/ExtendDataTable";
import ToastMessage from "../../../common/components/Toast";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const { Option } = Select;

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const { insurance: insuranceOption } = newPatientOptions;
let timer = null;
const SecondStepComponent = ({ handleContinue }) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const insurance = useSelector((state) => state.newPatient.insurance);
  const loading = useSelector((state) => state.newPatient.isVerifyInsurance);

  const dispatch = useDispatch();
  const search_insurance_companies_value = useSelector(
    (state) => state.newPatient.search_insurance_companies
  );
  const patient_id_get = cookies.get("current_patient_id");
  const loading_patient_insurances = useSelector(
    (state) => state.newPatient.loading_patient_insurances
  );
  const insurance_types = useSelector(
    (state) => state.newPatient.insurance_types
  );
  const loading_search_insurance_companies = useSelector(
    (state) => state.newPatient.loading_search_insurance_companies
  );
  const patient_all_insurances_id = useSelector(
    (state) => state.newPatient.patient_all_insurances_id
  );
  const check_patient = useSelector(
    (state) => state.newPatient.check_patient_insurances
  );

  const [stateFocus, setStateFocus] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [primaryInsurances, setPrimaryInsurances] = useState([]);
  const [secondInsurances, setSecondInsurances] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [active, setActive] = useState(-1);
  const [isDelete, setIsDelete] = useState(-1);
  const [activeCheck, setActiveCheck] = useState(-1);

  const handleAddNew = () => {
    const newInsurance = {
      key: Date.now(),
      id: null,
      insurance_id: "",
      policy_no: "",
      group_no: "",
      type: "",
      posted: false,
      insurance_name: "",
      is_verified: false,
      is_eligible: false
    };
    setInsurances([...insurances, newInsurance]);
  };

  useEffect(() => {
    if (check_patient && Object.keys(check_patient).length > 0) {
      setTimeout(() => {
        dispatch(reset_check_patient());
      }, 2000);
    }
  }, [check_patient]);

  useEffect(() => {
    dispatch(
      setGlobalLoading(
        loading ||
          loading_patient_insurances ||
          loading_search_insurance_companies ||
          loadingLocal
      )
    );
  }, [
    loading,
    loading_patient_insurances,
    loading_search_insurance_companies,
    loadingLocal,
  ]);

  const removeVaraible = async (index) => {
    const arrayCheck = [...insurances];
    const activeArray = arrayCheck[index];
    if (activeArray && activeArray["id"] && activeArray["posted"]) {
      const deleteSuccess = await dispatch(
        delete_patient_insurances(activeArray["id"])
      );
      if (deleteSuccess) {
        await dispatch(patient_insurances_id_insurances(resource.id));
        setActiveCheck(-1);
        setActive(-1);
        setIsDelete(-1);
      }
    } else {
      const vars = [...insurances];
      vars.splice(index, 1);
      setInsurances(vars);
      setIsDelete(-1);
      setActive(-1);
      setActiveCheck(-1);
    }
  };

  useEffect(() => {
    if (isDelete >= 0) {
      removeVaraible(isDelete);
    }
  }, [isDelete]);

  useEffect(() => {
      const arrayCheck = (patient_all_insurances_id || []).map((item, index) => {
        return {
          key: Date.now(),
          posted: true,
          insurance_name: `${item?.insurance_company?.name}`,
          id: item.id,
          insurance_id: item.insurance_company_id,
          policy_no: item.policy_number,
          group_no: item.group_number,
          type: item.insurance_type_id,
          is_verified: item.is_verified,
          is_eligible: item.is_eligible
        };
      });
      setInsurances([...arrayCheck]);
  }, [patient_all_insurances_id]);

  const handleChangeInsurance = (type, value, index) => {
    if (type == "insurance") {
      const vars = [...insurances];
      vars[index]["insurance_id"] = value[0];
      vars[index]["insurance_name"] = `${value[1]}`;
      setInsurances(vars);
    } else {
      const vars = [...insurances];
      vars[index][type] = value;
      setInsurances(vars);
    }
  };

  const apiPostEdit = async () => {
    setActive(-1);
    const arrayCheck = [...insurances];
    const activeArray = arrayCheck[active];
    if (
      activeArray &&
      activeArray["posted"] == false &&
      activeArray["insurance_id"] &&
      activeArray["policy_no"] &&
      activeArray["type"]
    ) {
      const saveSuccess = await dispatch(
        save_patient_insurances({
          patient_id: resource.id,
          insurance_company_id: activeArray["insurance_id"],
          policy_number: activeArray["policy_no"],
          group_number: activeArray["group_no"] ? activeArray["group_no"] : "",
          insurance_type_id: activeArray["type"],
        })
      );
      if (saveSuccess) {
        setIsSave(true);
        setTimeout(() => {
          setIsSave(false);
        }, 2000);
        await dispatch(patient_insurances_id_insurances(resource.id));
        await dispatch(setEnterInsuranceCheck());
      }
    } else if (
      activeArray &&
      activeArray["posted"] == true &&
      activeArray["insurance_id"] &&
      activeArray["policy_no"] &&
      activeArray["type"] &&
      activeArray["id"]
    ) {
      const updateSuccess = await dispatch(
        edit_patient_insurances(activeArray["id"], {
          patient_id: resource.id,
          insurance_company_id: activeArray["insurance_id"],
          policy_number: activeArray["policy_no"],
          group_number: activeArray["group_no"] ? activeArray["group_no"] : "",
          insurance_type_id: activeArray["type"],
        })
      );
      if (updateSuccess) {
        setIsEdit(true);
        setTimeout(() => {
          setIsEdit(false);
        }, 2000);
        await dispatch(patient_insurances_id_insurances(resource.id));
      }
    }
  };

  useEffect(() => {
    if (
      insurances &&
      insurances.length > 0 &&
      active >= 0 &&
      !loading_patient_insurances &&
      !loadingLocal
    ) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        apiPostEdit();
      }, 500);
    }
  }, [insurances]);

  useEffect(() => {
    // (isVerified && resource && hasInsurance)
    if (resource) {
      const primary = (resource.coverages || []).filter(
        (coverage) => coverage.type === "primary"
      );
      const secondary = (resource.coverages || []).filter(
        (coverage) => coverage.type === "secondary"
      );
      setPrimaryInsurances(primary);
      setSecondInsurances(secondary);
      if (validateSteps(insurance, insuranceOption)) {
      }
    }
  }, [resource]); // resource, isVerified, hasInsurance

  const handleChange = (type) => (value) => {
    setEnableError(false);
    dispatch(setStepData("insurance", { ...insurance, [type]: value }));
  };

  const handleClickVerify = async () => {
    if (activeCheck >= 0) {
      const arrayCheck = [...insurances];
      if (arrayCheck[activeCheck]) {
        const activeArray = arrayCheck[activeCheck];
        if (activeArray && activeArray["id"]) {
          await dispatch(check_patient_insurances(activeArray["id"]));
          await dispatch(patient_insurances_id_insurances(resource.id));
        }
      }
    }
  };

  const searchInsurance = (value) => {
    clearTimeout(timer);
    setLoadingLocal(true);
    timer = setTimeout(() => {
      setLoadingLocal(false);
      dispatch(search_insurance_companies(value));
    }, 500);
  };

  useEffect(() => {
    dispatch(search_insurance_companies(""));
  }, []);

  return (
    <div
      className="resourceContent pr-7px"
      style={{ position: "relative", marginTop: "-8px" }}
    >
      {check_patient &&
        Object.keys(check_patient).length > 0 &&
        check_patient.status && (
          <ToastMessage
            type="Insurance Details"
            status="success"
            msg={check_patient.message}
          />
        )}
      {isEdit && (
        <ToastMessage
          type="Insurance Details"
          status="success"
          msg={"Insurance Is Edited"}
        />
      )}
      {isSave && (
        <ToastMessage
          type="Insurance Details"
          status="success"
          msg={"Insurance Is Saved"}
        />
      )}
      <div
        className="resourceInfo d-flex"
        style={{
          justifyContent: "flex-end",
          marginTop: "0.5rem",
          position: "absolute",
          top: "-34px",
          right: "12px",
        }}
      >
        <button
          className="text-btn"
          style={{ marginLeft: "1rem" }}
          onClick={handleAddNew}
        >
          Add +
        </button>
      </div>

      <div className="resourceInfo d-flex" style={{ marginTop: "1.375rem" }}>
        <ExtendDataTable
          onClickRow={(index) => {
            if (!loading_patient_insurances) {
              setActive(index);
              setActiveCheck(index);
            }
          }}
          isActiveBorder={true}
          title={
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  ...titleStyle,
                  width: "calc(100% - 405px)",
                  height: "100%",
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "4px",
                  paddingLeft: "0",
                  borderLeft: 0,
                }}
              >
                Insurance Name
              </div>
              <div
                style={{
                  ...titleStyle,
                  width: "150px",
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "4px",
                }}
              >
                Policy No
              </div>
              <div
                style={{
                  ...titleStyle,
                  width: "150px",
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "4px",
                }}
              >
                Group No
              </div>
              <div
                style={{
                  ...titleStyle,
                  width: "105px",
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                Type
              </div>
            </div>
          }
          data={insurances.map((variable, index) => ({
            key: variable.key,
            row_color_status:
              (variable.is_verified && variable.is_verified === 1 && variable.is_eligible && variable.is_eligible === 1)
                ? "green"
                : (variable.is_verified === 1 && variable.is_eligible === 0) ? "pink" : "",
            insurance_id: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingRight: 0,
                }}
              >
                <div
                  style={{
                    paddingRight: 0,
                    flex: 1,
                    width: "100%",
                  }}
                >
                  <Select
                    placeholder={"Insurance..."}
                    showSearch
                    optionFilterProp="children"
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    value={
                      !stateFocus
                        ? variable?.insurance_name
                        : [variable?.insurance_name, variable?.insurance_id]
                    }
                    onFocus={() => {
                      setStateFocus(true);
                    }}
                    onBlur={() => {
                      setStateFocus(false);
                    }}
                    filterOption={(inp, opt) =>
                      opt.props.children
                        .toLowerCase()
                        .indexOf(inp.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      loading_search_insurance_companies ? (
                        <div style={{ height: 100 }}>
                          <Loading />
                        </div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onSearch={(value) => {
                      searchInsurance(value);
                    }}
                    onChange={(value) => {
                      handleChangeInsurance("insurance", value, index);
                    }}
                    style={{ width: "100%", maxWidth: "100%", borderBottom: "1px solid #fff" }}
                  >
                    {(search_insurance_companies_value || []).map(
                      (item, index) => (
                        <Option
                          key={item.id}
                          value={stateFocus ? [item.id, item.name] : item.id}
                        >
                          {item.name}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
              </div>
            ),
            policy_no: (
              <div style={{ width: "100%", paddingRight: 0 }}>
                <Input
                  placeholder="Policy No"
                  style={{ width: "100%", color: "#152935", boxShadow: "none" }}
                  value={variable.policy_no}
                  onChange={(e) =>
                    handleChangeInsurance("policy_no", e.target.value, index)
                  }
                />
              </div>
            ),
            group_no: (
              <div style={{ width: "100%", paddingRight: 0 }}>
                <Input
                  placeholder="Group No"
                  style={{ width: "100%", color: "#152935", boxShadow: "none" }}
                  value={variable.group_no}
                  onChange={(e) =>
                    handleChangeInsurance("group_no", e.target.value, index)
                  }
                />
              </div>
            ),
            type: (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                  paddingRight: "6px",
                }}
              >
                <div style={{ flex: 1, paddingRight: "8px" }}>
                  <Select
                    placeholder={"Type..."}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    value={variable.type || undefined}
                    onChange={(value) =>
                      handleChangeInsurance("type", value, index)
                    }
                    style={{ width: "100%", borderBottom: "1px solid #fff" }}
                  >
                    {(insurance_types || []).map((item, index) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="remove-icon" onClick={() => setIsDelete(index)}>
                  <Icon type="delete"/>
                </div>
              </div>
            ),
          }))}
          options={["insurance_id", "policy_no", "group_no", "type"]}
          widths={["calc(100% - 405px)", "150px", "150px", "105px"]}
          minRows={3}
        />
      </div>
      <div className="actionContent">
        <button
          disabled={
            insurances &&
            insurances[activeCheck] &&
            insurances[activeCheck]["id"]
              ? false
              : true
          }
          className="common-btn"
          onClick={handleClickVerify}
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default SecondStepComponent;
