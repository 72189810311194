import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDetailUltrasoundProps,
  save_ultrasound_rule_result,
  setGlobalLoading,
  get_ultrasound_rule_result,
} from "../../../../store/actions";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../../common/components/Toast";
import Loading from "../../../../common/components/Loading";
import Cookies from "universal-cookie";
import { debounce } from "lodash";

const cookies = new Cookies();
const Conclusion = ({ resource, ...restProps }) => {
  const [detailL, setDetailL] = useState("");
  const [isToastr, set_isToastr] = useState(false);
  const [first, setFirst] = useState(false);
  const [detailR, setDetailR] = useState("");
  const loading_ultrasound_value = useSelector(
    (state) => state.common.loading_ultrasound_value
  );
  const dispatch = useDispatch();
  const detail_ultrasound_props = useSelector(
    (state) => state.common.detail_ultrasound_props
  );
  const apointment_ultrasound_id = cookies.get("apointment_ultrasound_id");
  const is_locked = useSelector(
      (state) => state.clinicProviderReview.is_locked
  );

  useEffect(() => {
    if (detail_ultrasound_props && detail_ultrasound_props.conclusion_l) {
      setDetailL(detail_ultrasound_props.conclusion_l);
    }
    if (detail_ultrasound_props && detail_ultrasound_props.conclusion_r) {
      setDetailR(detail_ultrasound_props.conclusion_r);
    }
  }, [detail_ultrasound_props]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading_ultrasound_value));
  }, [loading_ultrasound_value]);

  useEffect(() => {
    setFirst(true);
  }, []);

  const updateCheck = async (detailCheck) => {
    await dispatch(
      save_ultrasound_rule_result(apointment_ultrasound_id, {
        left_result: detailCheck?.result_l,
        right_result: detailCheck?.result_r,
        left_conclusion: detailCheck?.conclusion_l,
        right_conclusion: detailCheck?.conclusion_r,
        disease_id: detailCheck?.disease_id,
      })
    );
    set_isToastr(true);
    setTimeout(() => {
      set_isToastr(false);
    }, 2000);
  };

  const changeHandler = useCallback(
    debounce((value) => updateCheck(value), 400),
    []
  );

  useEffect(() => {
    if (first) {
      changeHandler(detail_ultrasound_props);
    }
  }, [detail_ultrasound_props]);

  const onchangeValue = (value, type) => {
    if (type == "right") {
      dispatch(
        setDetailUltrasoundProps("detail_ultrasound_props", {
          ...detail_ultrasound_props,
          conclusion_r: value,
        })
      );
      setDetailR(value);
    } else {
      dispatch(
        setDetailUltrasoundProps("detail_ultrasound_props", {
          ...detail_ultrasound_props,
          conclusion_l: value,
        })
      );
      setDetailL(value);
    }
  };

  return (
    <div className="resourceContent pr-7px">
      <div className="resourceInfo">
        {isToastr && (
          <ToastMessage
            type="Ultrasound"
            status="success"
            msg="Save Ultrasound Finding Successfully!"
          />
        )}
        <EditableFormInput
          label={"Left Conclusion Details"}
          value={detailL}
          type={"area"}
          fullWidth
          handleChange={(value) => {
            onchangeValue(value, "left");
          }}
          minRows={12}
          disabled={is_locked}
        />
      </div>
      <div className="resourceInfo">
        <EditableFormInput
          label={"Right Conclusion Details"}
          value={detailR}
          type={"area"}
          handleChange={(value) => {
            onchangeValue(value, "right");
          }}
          fullWidth
          minRows={12}
          disabled={is_locked}
        />
      </div>
    </div>
  );
};

export default Conclusion;
