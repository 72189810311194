import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Icon } from 'antd';
import MainWorkflow from './RegisterWrapper';
import SubSideNav from '../../common/components/SubSideNav';

export default class ClinicWorkFlow extends Component {
  sideNavLinks = [{
    link: '/subsidiary-workflow/check-in',
    label: 'Subsidiary Module'
  }];

  state = {
    isSideNav: true
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Subsidiary Workflow</title>
        <meta name="title" content="FAACT Vascular | Subsidiary Workflow" />
      </Helmet>
    )
  }

  openSideNav = () => {
    this.setState({ isSideNav: true });
  }

  closeSideNav = () => {
    this.setState({ isSideNav: false });
  }

  getTitle = () => (
    <div className="sub-title">
      <Icon type="user" />
      Subsidiary Workflow
    </div>
  );

  render() {
    const { location } = this.props;
    const { isSideNav } = this.state;
    return (
      <div className="clinicWorkflow">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
        />
        <MainWorkflow location={location} isSideNav={isSideNav} />
      </div>
    )
  }
}
