import React from "react";
import EmployeeDetail from "./components/EmployeeDetail";
import Users from "./components/Users";
import LocationDetails from "./components/LocationDetails";
import ProcedureDetails from "./components/ProcedureDetails";
import UltrasoundDetails from "./components/UltrasoundDetails";
import OldProcedureDetails from "./components/OldProcedureDetails";
import Settings from "./components/Settings";
import MeaningfulUse from "./components/MeaningfulUser";
import {
  CheckSVG,
  YellowAlertSVG,
} from "../../common/components/icons/SVGIcon";
import { Icon } from "antd";
export const sideNavLinks = [{
  link: '/administrator/employee',
  label: 'Employee Details'
}, {
  link: '/administrator/users',
  label: 'Users'
}, {
  link: '/administrator/location-details',
  label: 'Location Details'
},
{
  link: '/administrator/clinical-logic',
  label: 'Clinical Logic'
},
{
  link: '/administrator/settings',
  label: 'Settings'
},{
  link: '/administrator/meaningful-use',
  label: 'Meaningful Use'
}];

export const Children = {
  employee: EmployeeDetail,
  users: Users,
  "location-details": LocationDetails,
  "clinical-logic": ProcedureDetails,
  "old-procedure-details": OldProcedureDetails,
  settings: Settings,
  "meaningful-use": MeaningfulUse,
};

export const EmployeeHeaders = [
  { label: "Hours", width: "38.7px" },
  { label: "Vacation Requests", width: "120.2px" },
  { label: "Variances", width: "63.7px" },
  { label: "Evaluations", width: "75.8px" },
  { label: "Manual & Policies", width: "114.7px" },
  { label: "Schedules", width: "67.4px" },
  { label: "CME", width: "28.9px" },
  { label: "Documents", width: "auto" },
];
export const SettingsHeader = [
  { label: "Document Customization", width: "auto" },
];
export const UsersHeaders = [
  { label: "Employees", width: "71px" },
  { label: "Referring Providers", width: "125.6px" },
  { label: "Patient Database", width: "auto" },
];
export const LocationsHeaders = [{ label: "Details", width: "46px" }];
export const UltrasoundHeaders = [{ label: "Details", width: "46px" }];
export const PCMHeaders = [
  { label: "Summary", width: "88px" },
  { label: "Overview", width: "88px" },
  { label: "Endovascular", width: "88px" },
  { label: "Non-Endovascular", width: "119.5px" },
  { label: "Provider Report", width: "auto" },
];

export const ProcedureDetailsHeaders = [
  { label: "Hospital Procedures", width: "144px" },
  { label: "Condition Details", width: "111.4px" },
  { label: "OBL Procedures", width: "103.9px" },
  { label: "Clinic Details", width: "114px" },
  { label: "E/M Details", width: "75px" },
  { label: "Ultrasound Details", width: "auto" }
];

export const DashboardRows = [
  { icon: <YellowAlertSVG /> },
  { icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/> },
];

export const RatingOptions = [
  { label: "Job Knowledge:", key: "job_score" },
  { label: "Work Quality:", key: "work_score" },
  { label: "Punctuality:", key: "punctuality_score" },
  { label: "Initiative:", key: "initiative_score" },
  { label: "Communication/Listening:", key: "communication_score" },
  { label: "Dependability:", key: "dependability_score" },
];

export const StaticResource = {
  first_name: "James",
  last_name: "Fisher",
  date_of_birth: "06/09/1973",
  phone: "(989) 897-8797",
  secondary_phone: "(989) 897-8797",
  email: "haqqani@hotmail.com",
  ssn: "890-09-8977",
  address: "1109 W St Andrews Rd",
  apt_number: "",
  suite: "",
  bldg: "",
  state: "Michigan",
  city: "Midland",
  zipcode: "48642",
  clinic_date: "07/04/2019",
  result: "",
  icon: <YellowAlertSVG />,
};

export const ScheduleRow = [
  {
    name: "Omar Haqqani, MD",
    mon: "Angio Midland",
    tue: "Angio Midland",
    wed: "Angio Midland",
    thu: "Angio Midland",
    fri: "Angio Midland",
    sat: "Angio Midland",
  },
  {
    name: "Majid Aized, MD",
    mon: "Clinic Bay City",
    tue: "Clinic Bay City",
    wed: "Clinic Bay City",
    thu: "Clinic Bay City",
    fri: "Clinic Bay City",
    sat: "Clinic Bay City",
  },
  {
    name: "Patrick Coffey, DO",
    mon: "Clinic Midland",
    tue: "Clinic Midland",
    wed: "Clinic Midland",
    thu: "Clinic Midland",
    fri: "Clinic Midland",
    sat: "Clinic Midland",
  },
  {
    name: "Steven Mattichak, MD",
    mon: "Clinic Gladwin",
    tue: "Clinic Gladwin",
    wed: "Clinic Gladwin",
    thu: "Clinic Gladwin",
    fri: "Clinic Gladwin",
    sat: "Clinic Gladwin",
  },
  {
    name: "Shikha Sharma, MD",
    mon: "Clinic Marlette",
    tue: "Clinic Marlette",
    wed: "Clinic Marlette",
    thu: "Clinic Marlette",
    fri: "Clinic Marlette",
    sat: "Clinic Marlette",
  },
  {
    name: "Kurt Anderson, DO",
    mon: "Nursing Homes",
    tue: "Nursing Homes",
    wed: "Nursing Homes",
    thu: "Nursing Homes",
    fri: "Nursing Homes",
    sat: "Nursing Homes",
  },
  {
    name: "Leah Martin, NP",
    mon: "Clinic Gladwin",
    tue: "Clinic Gladwin",
    wed: "Clinic Gladwin",
    thu: "Clinic Gladwin",
    fri: "Clinic Gladwin",
    sat: "Clinic Gladwin",
  },
  {
    name: "Leah Martin, NP",
    mon: "Clinic MP",
    tue: "Clinic MP",
    wed: "Clinic MP",
    thu: "Clinic MP",
    fri: "Clinic MP",
    sat: "Clinic MP",
  },
];
