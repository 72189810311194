import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import NoneEndovascularSidebar from "./NoneEndovascularSidebar";
import NoneEndovascularFilter from "./NoneEndovascularFilter";
import { StaticResource } from "../../constant";
import Loading from "../../../../common/components/Loading";
import {
  LeftCharvenSVG,
  CheckSVG,
  YellowAlertSVG,
  RedRequiredCircleSVG,
  RedCloseSVG,
} from "../../../../common/components/icons/SVGIcon";
import DataTable from "../../../../common/components/DataTable/DataTable";
import {
  // getPCM,
  getPCMProvider,
  getPCMDetail,
  getPCMFilter,
  setGlobalLoading,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { Icon } from "antd";
let timer;

const NoneEndovascular = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [offLoad, setOffLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [userProvider, setUserProvider] = useState(null);
  const [forFilter, setForFilter] = useState(false);

  const pcm = useSelector((state) => state.common.pcm);
  const pcmDetail = useSelector((state) => state.common.pcmDetail);
  const pcmFilter = useSelector((state) => state.common.pcmFilter);
  const ScheduleRow = useSelector((state) => state.common.pcmProvider);
  const dispatch = useDispatch();

  const [sortOption, setSortOption] = useState({});

  const sortList = async () => {
    const sortColumn = [];
    for (let sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }

    setTableLoads([]);
    setLoading(true);
    setPage(1);
    if (!forFilter) {
      // await dispatch(getPCM(1, "nonendo", { column: sortColumn }));
      await dispatch(
        getPCMFilter({
          page: 1,
          type: "nonendo",
          column: sortColumn,
        })
      );
    } else {
      await dispatch(
        getPCMFilter({
          user_id: userProvider,
          startDate,
          page: 1,
          type: "nonendo",
          search: search,
          column: sortColumn,
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    sortList();
  }, [sortOption]);

  useEffect(() => {
    if (pcmDetail && Object.keys(pcmDetail).length > 0) {
      const indexEdit = tableLoad.findIndex((r) => r.id === pcmDetail.id);
      let listItems = [...tableLoad];
      listItems.splice(indexEdit, 1, { ...pcmDetail });
      setTableLoads([...listItems]);
    }
  }, [pcmDetail]);

  useEffect(() => {
    if (userProvider && startDate) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("full");
      setForFilter(true);
    } else if (userProvider && !startDate) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("user");
      setForFilter(true);
    } else if (!userProvider && !startDate) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("null");
      setForFilter(true);
    } else if (!userProvider && startDate) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("null");
      setForFilter(true);
    }
  }, [userProvider, startDate]);

  useEffect(() => {
    if (pcm && pcm.nonendovasculars && pcm.nonendovasculars.length > 0) {
      // if (tableLoad && tableLoad.length === 0) {
      // dispatch(
      //   getPCMDetail(pcm.nonendovasculars[0] && pcm.nonendovasculars[0].id)
      // );
      // }
      if (page > 1) {
        setTableLoads([...tableLoad, ...pcm.nonendovasculars]);
        setLoading(false);
      } else {
        setTableLoads([...pcm.nonendovasculars]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [pcm]);

  useEffect(() => {
    if (pcmFilter && pcmFilter.nonendovasculars) {
      if (tableLoad && tableLoad.length === 0) {
        // dispatch(
        //   getPCMDetail(
        //     pcmFilter.nonendovasculars[0] && pcmFilter.nonendovasculars[0].id
        //   )
        // );
      }
      setTableLoads([...tableLoad, ...pcmFilter.nonendovasculars]);
    }
  }, [pcmFilter]);

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const fetchList = async () => {
    const listProvider = await dispatch(getPCMProvider());
    if (listProvider) {
      setLoading(false);
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = async (row, index) => {
    if (activeIndex !== index) {
      setRow(index);
      setLoading(true);
      const detailSuccess = await dispatch(getPCMDetail(row.id));
      if (detailSuccess) {
        setLoading(false);
      }
      handleSidebar();
    }
  };

  const valueSearch = (value) => {
    clearTimeout(timer);
    setSearch(value);
    setLoading(true);
    timer = setTimeout(() => {
      setTableLoads([]);
      setForFilter(true);
      setPage(1);
      setLoading(false);
      dispatch(
        getPCMFilter({
          user_id: userProvider,
          type: "nonendo",
          startDate,
          page: 1,
          search: value,
        })
      );
    }, 1000);
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    const sortColumn = [];
    for (let sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }

    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad && !forFilter) {
        setLoading(true);
        // const listPCM = await dispatch(
        //   getPCM(page + 1, "nonendo", { column: sortColumn })
        // );
        const listPCM = await getPCMFilter({
          type: "nonendo",
          page: page + 1,
          column: sortColumn,
        });
        if (listPCM) {
          if (pcm && pcm.nonendovasculars && pcm.nonendovasculars.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      } else if (offLoad && forFilter) {
        setLoading(true);
        const listPCMFilter = await dispatch(
          getPCMFilter({
            user_id: userProvider,
            type: "nonendo",
            startDate,
            page: page + 1,
            search: search,
            column: sortColumn,
          })
        );
        if (listPCMFilter) {
          if (
            pcmFilter &&
            pcmFilter.nonendovasculars &&
            pcmFilter.nonendovasculars.length > 0
          ) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  const onChangeDate = (value) => {
    const date = moment(value).format("MM/DD/YYYY");
    setStartDate(date);
  };

  const onChangeProvider = (value) => {
    if (value) {
      setUserProvider(value);
    } else {
      setUserProvider(null);
    }
  };

  const updateState = (value, data) => {
    const tasks = tableLoad;
    setTableLoads(
      tasks.map((r) => {
        if (r.id === value.id) {
          return { ...r, direct_cost: data.direct };
        } else {
          return r;
        }
      })
    );
  };

  const moveState = (id, data) => {
    const tasks = tableLoad;
    setTableLoads(
      tasks.filter((r) => {
        if (r.id !== id) {
          return r;
        }
      })
    );
    setRow(-1);
  };

  const callApiFilter = async (type) => {
    if (type === "full") {
      setLoading(true);
      const filterSuccess = await dispatch(
        getPCMFilter({
          user_id: userProvider,
          type: "nonendo",
          startDate,
          page: 1,
          search: search,
        })
      );
      if (filterSuccess) {
        setLoading(false);
      }
    } else if (type === "user") {
      setLoading(true);
      const filterSuccess = await dispatch(
        getPCMFilter({
          user_id: userProvider,
          type: "nonendo",
          startDate: null,
          page: 1,
          search: search,
        })
      );
      if (filterSuccess) {
        setLoading(false);
      }
    } else if (type === "null") {
      setLoading(true);
      const filterSuccess = await dispatch(
        getPCMFilter({
          user_id: null,
          type: "nonendo",
          startDate: null,
          page: 1,
          search: search,
        })
      );
      if (filterSuccess) {
        setLoading(false);
      }
    } else {
      setLoading(true);
      const filterSuccess = await dispatch(
        getPCMFilter({
          user_id: null,
          type: "nonendo",
          startDate,
          page: 1,
          search: search,
        })
      );
      if (filterSuccess) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer">
        <div className="fullContainer pt-2">
          <NoneEndovascularFilter
            onChangeDate={onChangeDate}
            onChangeProvider={onChangeProvider}
            providers={ScheduleRow || []}
            valueSearch={(value) => valueSearch(value)}
          />
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="pcm_non_endovascular"
                tableWidth={1000}
                labels={[
                  "Patient Name",
                  "DOB",
                  "DOS",
                  "Proceduralist",
                  "Fee",
                  "Allowed Amount",
                  "Total Paid",
                  "Insurance Balance",
                  "Patient Balance",
                  "Net",
                  "CM",
                ]}
                widths={[
                  "12%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "8%",
                ]}
                columns={[
                  { sortable: true, key: "patient_name" },
                  { sortable: false, key: "patient_dob" },
                  { sortable: true, key: "dos" },
                  { sortable: false, key: "proceduralist" },
                  { sortable: true, key: "fee" },
                  { sortable: true, key: "allowed_amount" },
                  { sortable: true, key: "insurance_amount" },
                  { sortable: true, key: "insurance_balance" },
                  { sortable: true, key: "patient_balance" },
                  { sortable: true, key: "net" },
                  { sortable: true, key: "contribution_margin" },
                ]}
                sorts={[
                  sortOption.patient_name,
                  "",
                  sortOption.dos,
                  "",
                  sortOption.fee,
                  sortOption.allowed_amount,
                  sortOption.insurance_amount,
                  sortOption.insurance_balance,
                  sortOption.patient_balance,
                  sortOption.net,
                  sortOption.contribution_margin,
                ]}
                rowData={(tableLoad || []).map((row, index) => ({
                  id: row.id,
                  patient_name: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {row.icon_status === "green" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon
                            type="clock-circle"
                            theme="twoTone"
                            twoToneColor="#24A148"
                          />
                          <span
                            className="tooltip tooltip-without-border"
                            role="tooltip"
                          >
                            {"0 Net balance"}
                          </span>
                        </div>
                      )}
                      {row.icon_status === "yellow" && <YellowAlertSVG />}
                      {row.icon_status === "red" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon
                            type="warning"
                            theme="twoTone"
                            twoToneColor="#FF832B"
                          />
                          <span
                            className="tooltip tooltip-without-border"
                            role="tooltip"
                          >
                            {"Net balance > 0"}
                          </span>
                        </div>
                      )}
                      {row.icon_status === "red_x" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon
                            type="thunderbolt"
                            theme="twoTone"
                            twoToneColor="#6929C4"
                          />
                          <span
                            className="tooltip tooltip-without-border"
                            role="tooltip"
                          >
                            {"<70% and < 365 days"}
                          </span>
                        </div>
                      )}
                      {!row.icon_status && (
                        <span style={{ minWidth: "26px" }}></span>
                      )}
                      <p style={{ paddingLeft: "0.75rem" }}>{row.patient_name}</p>
                    </div>
                  ),
                  patient_dob:
                    row.patient_dob && moment(row.patient_dob).format("MM/DD/YYYY"),
                  dos:
                    row.date_of_procedure &&
                    moment(row.date_of_procedure).format("MM/DD/YYYY"),
                  proceduralist: row.provider_name,
                  fee: row.fee,
                  allowed_amount: row.allowed_amount,
                  total_payment: row.payment,
                  net: row.net,
                  ros: row.location,
                  contribution_margin: row.contribution_amount,
                  insurance_balance: row.insurance_balance,
                  patient_balance: row.patient_balance,
                  insurance_amount: row.insurance_amount,
                }))}
                handleSort={(value, item) => {
                  handleSortOption(value, item);
                }}
                fetchList={fetchList}
                loadMore={loadMore}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>

      {pcmDetail && activeIndex >= 0 && (
        <NoneEndovascularSidebar
          ref={sidebarRef}
          detailPCM={activeIndex >= 0 && pcmDetail ? pcmDetail : undefined}
          updateState={(value, data) => {
            updateState(value, data);
          }}
          moveState={(value, data) => {
            moveState(value, data);
          }}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}

      {pcmDetail && activeIndex >= 0 && (
        <SidebarExtension
          startingWidth="calc(28.3% - 20px + 0.3rem)"
          sidebarReference={sidebarRef}
          sidebarToggle={toggleSidebar}
          sidebarState={isSidebar}
        />
      )}
    </div>
  );
};

export default NoneEndovascular;
