import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Input } from 'antd';
import { SearchSVG, ArrowDownSVG } from '../icons/SVGIcon';
import SearchPatient from './SearchPatient';

import './style.scss';

const { Search } = Input;

const useOutsideDetect = (ref1, handleClick) => {
  const handleClickOutside = (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      handleClick(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

const HeaderMenu = ({shouldDisplay}) => {
  const manageDropdown = useRef(null);
  const [openManage, setManage] = useState(false);
  useOutsideDetect(manageDropdown, setManage);
  const handleManage = () => {
    if (!openManage) {
      setManage(true);
    }
  }

  return (
    <ul style={{ display: (shouldDisplay ? '' : 'none')}} className='headerMenuContainer'>
      <SearchPatient />
      <li className='headerListItem'>
        <NavLink to='/about'>About</NavLink>
      </li>
      <li className='headerListItem'>
        <NavLink to='/support/details'>Support</NavLink>
      </li>
      <li className='headerListItem'>
        <NavLink to='/employee-workflow/deficiency'>Deficiency</NavLink>
      </li>
      <li className='headerListItem'>
        <ul className={`headerDropdownMenu ${openManage ? 'open' : ''}`}>
          <li className={`dropdownText headerListItem ${openManage ? 'open' : ''}`} onClick={handleManage}>
            Manage
            <ArrowDownSVG />
          </li>
          <li>
            <ul ref={manageDropdown} className='dropdownList'>
              <li className='dropdownItem'>
                <NavLink to='#' onClick={() => setManage(false)}>Enterprise</NavLink>
              </li>
              <li className='dropdownItem'>
                <NavLink to='#' onClick={() => setManage(false)}>Account</NavLink>
              </li>
              <li className='dropdownItem'>
                <NavLink to='#' onClick={() => setManage(false)}>Billing and usage</NavLink>
              </li>
              <li className='dropdownItem'>
                <NavLink to='#' onClick={() => setManage(false)}>Access(IAM)</NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  )
}

export default HeaderMenu
