import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Icon, Select } from "antd";
import Cookies from "universal-cookie";

import {
  CheckSVG,
  CloseIcon,
  RedCloseSVG,
} from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import SubDataTable from "../../../../common/components/SubDataTable/ExtendDataTable";
import ExtendDataTable from "../../../../common/components/SubDataTable/ExtendDataTable";
import CptReview from "../../../../common/components/CptReview/index";
import {
  set_cpt_code_review,
  search_cpt_code,
  get_plan_patient,
  reset_appointment_pdf,
  get_cpt_code_review,
  unlock_finalize,
  save_temp,
  setGlobalLoading,
  setGlobalToastr,
} from "../../../../store/actions";

const { Option } = Select;
const initialState = {
  cpt_code: 0,
  cpt_description: "",
};
const cookies = new Cookies();
let timer = null;

const dataTableRows = [
  { item: "", amount: "" },
  { item: "", amount: "" },
  { item: "", amount: "" },
  { item: "", amount: "" },
];

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const ReviewSideBar = React.forwardRef(
  (
    { isShow, handleCloseSidebar, valueText, onChangeCPT, sidebarIcon },
    ref
  ) => {
    const patientResource = useSelector(
      (state) => state.clinicProvider?.patientResource
    );
    const fullName = (
      patientResource?.first_name +
      " " +
      patientResource?.last_name
    ).toUpperCase();
    const age = patientResource?.date_of_birth
      ? "(" + moment().diff(patientResource.date_of_birth, "years") + " yo)"
      : "";
    const usersCheck = useSelector((state) => state.common.currentUserInfo);
    const appointment_pdf = useSelector(
      (state) => state.common.appointment_pdf
    );
    const [row, setRow] = useState(-1);
    const [dataSearch, setDataSearch] = useState([]);
    const [cptData, setCptData] = useState([]);
    const [isResult, setIsResult] = useState(false);
    const [searchInput, setInput] = useState("");
    const [searching, setSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const current_patient_id = cookies.get("current_patient_id");
    const current_appointment_id = cookies.get("current_appointment_id");
    const dispatch = useDispatch();
    const procedure_id = cookies.get("current_appointment_id");
    const CPT = useSelector((state) => state.common.CPT);
    const searchData = useSelector((state) => state.common.searchCPT);
    const patient_plan = useSelector(
      (state) => state.procedure.get_plan_patient
    );
    const [dataPlan, setDataPlan] = useState([]);
    const [printLink, setPrintLink] = useState("");
    const [medicalDecision, setMeicalDecision] = useState("");
    const [isLock, setIsLock] = useState(0);
    const [loadingButton, setLoadingButton] = useState(true);
    const [icdData, setIcdData] = useState([]);

    useEffect(() => {
      if (CPT.is_locked && CPT.is_locked === 1) {
        setIsLock(CPT.is_locked);
        setLoadingButton(false);
      } else {
        setLoadingButton(false);
      }
    }, [CPT]);

    useEffect(() => {
      if (CPT && CPT.cpt_codes && CPT.cpt_codes.length > 0) {
        const checkedArray = CPT.cpt_codes.map((r) => {
          return {
            cpt_code: r.code,
            short_desc: r.short_desc,
            pivot: r.pivot,
          };
        });
        onChangeCPT([...checkedArray]);
        setCptData(checkedArray);
      }
      if (CPT && CPT.icd_codes && CPT.icd_codes.length > 0) {
        const checkedArray = CPT.icd_codes.map((r) => {
          return {
            icd_code: r.icd_code,
            icd_name: r.icd_name,
          };
        });
        setIcdData(checkedArray);
      }
    }, [CPT]);

    const medical_decision_making = useSelector(
      (state) => state.clinicProviderReview.medical_decision_making
    );

    const meaningful_uses = useSelector(
      (state) => state.clinicProviderReview.meaningful_uses
    );

    const orderTitle = () => {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...titleStyle,
              minWidth: "50%",
              width: "50%",
              paddingLeft: 15,
              borderLeft: 0,
            }}
          >
            Review
          </div>
          <div style={{ ...titleStyle, minWidth: "25%", width: "25%" }}>
            Result
          </div>
        </div>
      );
    };

    // useEffect(() => {
    //   setIsLock(is_locked);
    // }, [is_locked]);

    useEffect(() => {
      setMeicalDecision(medical_decision_making);
    }, [medical_decision_making]);

    useEffect(() => {
      dispatch(get_plan_patient(current_patient_id, current_appointment_id));
    }, []);
    const removeRow = (index) => {
      let newRows = [];
      newRows = [...cptData];
      newRows.splice(index, 1);
      setCptData([...newRows]);
      onChangeCPT([...newRows]);
    };

    const selectItem = (itemcheck, item, type) => {
      const newRows = cptData;
      let checkExist = false;
      cptData.some((r) => {
        if (r.cpt_code === item) {
          checkExist = true;
        }
      });
      if (!checkExist) {
        newRows.push({ cpt_code: item, short_desc: type });
        setCptData(newRows);
        onChangeCPT([...newRows]);
        setIsResult(false);
        setInput("");
      } else {
        setIsResult(false);
        setInput("");
      }
    };

    useEffect(() => {
      if (patient_plan && patient_plan.length > 0) {
        let checkArray = [];
        patient_plan.map((item) => {
          (item?.plan || []).map((r) => {
            if (r.bundle === "ultrasound_test") {
              if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.side + " " + r?.ultrasound?.ultrasound_type?.name,
                    processed: r.processed,
                  },
                ];
              } else {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.ultrasound?.ultrasound_type?.name,
                    processed: r.processed,
                  },
                ];
              }
            } else if (r.bundle === "procedure") {
              if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.side + " " + r?.procedure?.procedure_type?.value,
                    processed: r.processed,
                  },
                ];
              } else {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.procedure?.procedure_type?.value,
                    processed: r.processed,
                  },
                ];
              }
            } else if (r.bundle === "general") {
              checkArray = [
                ...checkArray,
                {
                  value: r?.procedure_extra?.procedure_type?.value,
                  processed: r.processed,
                },
              ];
            }
          });
        });
        setDataPlan(checkArray);
      }
    }, [patient_plan]);

    const handleSearch = async (search) => {
      await setInput(search);
      clearTimeout(timer);
      setLoading(true);
      timer = setTimeout(async () => {
        await dispatch(search_cpt_code(search));
        setLoading(false);
      }, 1000);
    };

    const saveTemp = async (value) => {
      if (isLock != 1) {
        setMeicalDecision(value);
        setLoading(true);
        const param = {
          medical_decision_making: value,
        };
        await dispatch(save_temp(procedure_id, param));
        setLoading(false);
      }
    };

    const saveAll = async () => {
      setGlobalLoading(true);
      setLoading(true);
      const param = {
        text_template: valueText,
        cpt_codes: cptData.map((r) => {
          return r.cpt_code;
        }),
        medical_decision_making: medicalDecision,
      };

      if (isLock == 1) {
        await dispatch(unlock_finalize(procedure_id));
        setIsLock(0);
      } else {
        await dispatch(set_cpt_code_review(procedure_id, param));
        setIsLock(1);
        await dispatch(
          setGlobalToastr({
            message: "The procedure is finalized",
          })
        );
      }
      await dispatch(get_cpt_code_review(procedure_id));

      setLoading(false);
      setGlobalLoading(false);
    };

    useEffect(() => {
      if (searchData.length > 0) {
        const checkedArray = searchData.map((item) => {
          return {
            id: item.id,
            cpt_code: item.code,
            short_desc: item.short_desc,
            created_at: item.created_at,
            updated_at: item.updated_at,
            fee: item.fee,
            cpt_code_name: item.cpt_code_name,
          };
        });
        setDataSearch(checkedArray);
      }
    }, [searchData]);

    useEffect(() => {
      if (isShow) {
        dispatch(setGlobalLoading(loading));
      }
    }, [loading, isShow]);

    if (!isShow) {
      return null;
    }

    const getTitle = () => {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...titleStyle,
              minWidth: "25%",
              width: "25%",
              borderLeft: 0,
              paddingLeft: 0,
            }}
          >
            ICD Code
          </div>
          <div style={{ ...titleStyle, minWidth: "75%", width: "75%" }}>
            Diagnosis
          </div>
        </div>
      );
    };

    return (
      <>
        <div
          ref={ref}
          className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
        >
          <ScrollWrapper>
            <div className="headerContainer">
              <button onClick={handleCloseSidebar}>
                <CloseIcon />
              </button>
            </div>
            <SidebarTitle
              icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
              title={<span className="text-uppercase">Summary Dashboard</span>}
              subTitle=""
            />

            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "8px",
                paddingBottom: "1rem",
                minHeight: "45vh",
              }}
            >
              <div className="resourceInfo" style={{ marginTop: "1rem" }}>
                <SubDataTable
                  title={orderTitle()}
                  data={(dataPlan || []).map((item) => ({
                    label: (
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "8px",
                        }}
                      >
                        <span className="oneLineText">{item.value}</span>
                      </p>
                    ),
                    value: (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 5 }}>
                          {item.processed === 1 ? (
                            <span
                              className="normal-icon"
                              style={{
                                width: "1rem",
                                minWidth: "1rem",
                                height: "18px",
                                paddingLeft: "4px",
                              }}
                            >
                              <Icon
                                type="check-circle"
                                theme="twoTone"
                                twoToneColor="#24A148"
                              />
                            </span>
                          ) : (
                            <span
                              className="normal-icon"
                              style={{
                                width: "1rem",
                                minWidth: "1rem",
                                height: "18px",
                                paddingLeft: "4px",
                              }}
                            >
                              <Icon
                                type="question-circle"
                                theme="twoTone"
                                twoToneColor="#DA1E28"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    ),
                  }))}
                  options={["label", "value"]}
                  widths={["50%", "50%"]}
                  minRows={8}
                />
              </div>

              <div className="resourceInfo" style={{ marginTop: "1rem" }}>
                <ExtendDataTable
                  title={getTitle()}
                  data={icdData}
                  options={["icd_code", "icd_name"]}
                  widths={["25%", "75%"]}
                  minRows={3}
                />
              </div>

              <div
                className="resourceInfo between"
                style={{ marginTop: "1rem" }}
              >
                <CptReview
                  DataOpSearch={dataSearch}
                  keySearch="cpt_code_name"
                  keyType="short_desc"
                  title="cpt_review"
                  keyValue="cpt_code"
                  rows={cptData}
                  removeItem={removeRow}
                  loading={searching}
                  isResult={isResult}
                  query={searchInput}
                  isModifier={true}
                  selectItem={selectItem}
                  handleSearch={handleSearch}
                  minRows={5}
                  isLocked={isLock}
                />
              </div>
            </div>
            {usersCheck && usersCheck?.is_provider == 1 ? (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: isLock == 1 ? "#C6C6C6" : "#1164F2",
                      }}
                      onClick={saveAll}
                    >
                      {isLock == 1 ? "Unfinalize" : "Finalize +"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#1164F2",
                      }}
                    >
                      {"Save for Review"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            )}
            {/* {!loadingButton && (
              <div
                className="actionContent"
                style={{
                  marginTop: "2rem",
                  paddingRight: "1rem",
                }}
              >
                <button
                  style={{
                    backgroundColor: isLock == 1 ? "#C6C6C6" : "#1164F2",
                  }}
                  onClick={saveAll}
                >
                  {isLock == 1 ? "Unfinalize" : "Finalize +"}
                </button>
              </div>
            )} */}
          </ScrollWrapper>
        </div>
      </>
    );
  }
);

export default ReviewSideBar;
