import React, { useState, useEffect } from "react";
import EditableText from "../../../common/components/EditableText";
import { CheckSVG } from "../../../common/components/icons/SVGIcon";
import EditableMaterialText from "../../../common/components/EditableText/MaterialInput";
import { validateField } from "../../../services/validations";
import { useSelector, useDispatch } from "react-redux";
import ScanInsuranceCard from "./ScanInsuranceCard";
import ToastMessage from "../../../common/components/Toast";
import {
  patientScheduleVerifyInsurance,
  setDataFieldCheckIn,
} from "../../../store/actions";
import PaymentForm from "./PaymentForm";

const InsuranceDetail = ({ resource, paymentForm }) => {
  const [medical_id, setMedicalId] = useState(resource.medical_id);
  const [policy_number, setPolicyNumber] = useState(resource.policy_number);
  const [isOpenedPayment, setOpenedPayment] = useState(false);
  const [allFields, setAllFields] = useState({});

  const isInsuranceVerified = useSelector(
    (state) => state.patientSchedule.isInsuranceVerified
  );
  const coverages = useSelector((state) => state.patientSchedule.coverages);

  const dispatch = useDispatch();

  let primaryInsurances = [];
  let secondInsurances = [];
  for (let insurance of coverages) {
    if (insurance.type === "primary") {
      primaryInsurances.push(insurance);
    }
    if (insurance.type === "secondary") {
      secondInsurances.push(insurance);
    }
  }

  const onVerifiy = () => {
    const patientId = resource.id;
    dispatch(
      patientScheduleVerifyInsurance(patientId, {
        policy_number
      })
    );
  };

  const onClickOpenPayment = () => {
    if (isOpenedPayment) return;
    if (resource && resource.first_name != "") {
      setOpenedPayment(true);
    }
  };


  return (
    <div className="resourceContent">
      <div className="resourceInfo d-flex">
        <EditableMaterialText
          label={"Policy No"}
          value={policy_number}
          handleChange={(value) => {setPolicyNumber(value);}}
        />
      </div>
      <hr />
      {isInsuranceVerified ? (
        <>
          <div
            className="resourceContent pr-7px"
            style={{ position: "relative" }}
          >
            {primaryInsurances && !!primaryInsurances.length && (
              <React.Fragment>
                <div className="resourceInfo" style={{ marginTop: "0.5rem" }}>
                  <b>Primary Insurance</b>
                </div>
                <div className="resourceInfo">
                  {(primaryInsurances || []).map((primary, index) => (
                    <div key={`primary-insurance-${index}`}>
                      {primary.coverage_name}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
            {secondInsurances && !!secondInsurances.length && (
              <React.Fragment>
                <div className="resourceInfo" style={{ marginTop: "0.5rem" }}>
                  <b>Secondary Insurance</b>
                </div>
                <div className="resourceInfo">
                  {(secondInsurances || []).map((second, index) => (
                    <div key={`second-insurance-${index}`}>
                      {second.coverage_name}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
            <ToastMessage msg={"Insurance Verified"} status={"default"} />
          </div>
        </>
      ) : (
        <div className="actionContent no-padding">
          <button onClick={onVerifiy}>Verify Insurance</button>
        </div>
      )}
    </div>
  );
};

export default InsuranceDetail;
