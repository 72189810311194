import React, { Component } from "react";
//import 'react-dropzone-uploader/dist/styles.css'
import "../../../../styles/dz.css";
import Dropzone from "react-dropzone-uploader";
import { BASE_API } from "../../../../common/constants/constants";

export default class ImageUpload extends Component {
  state = {
    isSidebar: false,
    image: "",
  };

  getUploadParams = () => {
    return {
      url: BASE_API + "patients/1/upload",
    };
  };

  handleChangeStatus = ({ meta }, status, allFiles) => {
    if (meta.status === "done" && meta.percent === 100) {
      alert("success");
      allFiles.forEach((f) => f.remove());
      this.props.onRefreshImageList();
    }
  };

  handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  handleUploads = (files, allFiles) => {
    /**
     * Upload files to Laravel
     */

    /**
     * Files is an array with multiple objects
     * TODO fetch the actual patient ID
     */
    let formData = new FormData();

    // eslint-disable-next-line array-callback-return
    allFiles.map((file, index) => {
      formData.append("images[]", file);
    });

    fetch(BASE_API + "patients/1/upload", {
      method: "POST",
      body: formData,
      headers: { "X-Requested-With": "XMLHttpRequest" },
    })
      .then((response) => {
        alert("response");
        allFiles.forEach((f) => f.remove());
      })
      .then((success) => {
        alert("Success");
        allFiles.forEach((f) => f.remove());
      })
      .catch((error) => {
        alert("error");
        allFiles.forEach((f) => f.remove());
      });

    allFiles.forEach((f) => f.remove());
  };

  //
  //

  render() {
    return (
      <section>
        <Dropzone
          maxFilesize={5000}
          accept="image/*,.pdf,.dcm,.orig"
          getUploadParams={this.getUploadParams}
          onChangeStatus={this.handleChangeStatus}
          onSubmit={this.handleSubmit}
          inputContent={(files, extra) =>
            extra.reject ? "" : "Drop files here or click to upload"
          }
          submitButtonDisabled={(files) => files.length < 1}
          styles={{
            dropzone: {
              border: "2px dotted grey",
              minHeight: 60,
              maxHeight: 60,
              minWidth: 280,
              maxWidth: 280,
              position: "absolute",
              right: 230,
              //overflow: 'hidden',
              overflow: "overlay",
              top: 6,
              color: "black",
              fontSize: 12,
            },
            "dzu-inputLabel": {
              color: "black",
            },
          }}
        >
          <div>
            <p>Hello Test</p>
          </div>
        </Dropzone>
      </section>
    );
  }
}
