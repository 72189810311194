import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ScrollWrapper from "../../common/components/ScrollBar";
import { useWindowSize } from "../../common/utils";
import Loading from "../../common/components/Loading";
import { MaleSVG } from "../../common/components/icons/FaxSVG";

const ClinicScheduleTable = ({appointments}) => {
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  const tableRows = appointments;

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index, id) => () => {
    setRow(index);
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  return (
    <div className="clinicWorkflow__main-section" style={{ height: '100%' }}>
      <div className="eventsContainer" style={{ width: '100%', height: '100%' }}>
        <div className="fullContainer pt-2">
          <div className="tableHeader">
            <div className="tableItem">
              <div
                className="th"
                style={{ width: "calc(20% - 2rem)", marginLeft: "2rem" }}
              >
                <b>Appointment Time</b>
              </div>
              <div className="th" style={{ width: "20%" }}>
                <b>Patient Name</b>
              </div>
              <div className="th" style={{ width: "30%" }}>
                <b>Procedure</b>
              </div>
              <div className="th" style={{ width: "15%" }}>
                <b>Contrast Allergy</b>
              </div>
              <div className="th" style={{ width: "15%" }}>
                <b>Status</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper css="no-padding x-hidden">
                <div className="tableList">
                  {tableRows.map((row, index) => (
                    <div
                      className={`tableItem yellow ${
                        activeIndex === index ? "active" : ""
                      }`}
                      key={`location-schedule-${index}`}
                      onClick={handleRow(index, row.id)}
                    >
                      <div className="td with-icon" style={{ width: "20%" }}>
                        <div className="iconField">
                          <MaleSVG />
                        </div>
                        <p>{moment(row.appointment_date).format("hh:mm A")}</p>
                      </div>
                      <div className="td" style={{ width: "20%" }}>
                        <p>{row.patient.first_name} {row.patient.last_name}</p>
                      </div>
                      <div className="td" style={{ width: "30%" }}>
                        <p>{row.procedure_name}</p>
                      </div>
                      <div className="td" style={{ width: "15%" }}>
                        <p></p>
                      </div>
                      <div className="td" style={{ width: "15%" }}>
                        {!row.display_status ? '' : (
                          <p>{row.display_status} at {row.display_status_time}</p>
                        )}
                      </div>
                    </div>
                  ))}
                  {emptyRows.map((row, index) => (
                    <div
                      className={`tableItem empty`}
                      key={`schedule-empty-${index}`}
                    >
                      <div className="td" style={{ width: "31%" }} />
                      <div className="td" style={{ width: "23%" }} />
                      <div className="td" style={{ width: "23%" }} />
                      <div className="td" style={{ width: "23%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicScheduleTable;
