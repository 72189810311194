import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ClinicalCalendar from "./components/ClinicalCalendar";
import {
  getProcedureEvents,
  setProcedureData,
  getProcedureLocations,
  getProviders, getOnlyProviders,
} from "../../store/actions";
import CardBlock from "../../common/components/Card";

const ProcedureCalendar = (props) => {
  const events = useSelector((state) => state.procedure.events);
  const activeOpenings = useSelector((state) => state.procedure.activeOpenings);
  const closedDates = useSelector(
    (state) => state.procedure.closedDates
  );
  const openingTime = useSelector((state) => state.procedure.openingTime);
  const closingTime = useSelector((state) => state.procedure.closingTime);

  const locations = useSelector((state) => state.common.procedureLocations);
  const providers = useSelector((state) => state.common.only_providers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProcedureData("resource", null));
    const date = moment(new Date()).format("MM/DD/YYYY");
    dispatch(setProcedureData("loadingEvents", true));
    dispatch(getProcedureEvents({ date }));
  }, []);

  useEffect(() => {
    if (!locations) {
      dispatch(getProcedureLocations());
    }
    if (!providers) {
      dispatch(getOnlyProviders());
    }
  }, [events, locations, providers]);

  const handleEvents = (date, location, provider) => {
    dispatch(setProcedureData("loadingEvents", true));
    dispatch(getProcedureEvents({ date, location, provider }));
  };

  const onClickEvent = (event) => {
    if (props.onHandleClick) {
      props.onHandleClick(event)
    }
  }

  return (
    <CardBlock title="Procedure Calendar" other="dashboard" >
      <div className="calendarContainer" style={{ position: "relative" }}>
        <ClinicalCalendar
          activeID={props?.activeID}
          onClickEvent={onClickEvent}
          eventData={events || []}
          fetchEvents={handleEvents}
          locations={locations || []}
          providers={providers || []}
          activeOpenings={activeOpenings || []}
          closedDates={closedDates || []}
          openingTime={openingTime || ""}
          closingTime={closingTime || ""}
        />
      </div>
    </CardBlock>
  );
};

export default ProcedureCalendar;
