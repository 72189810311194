import React, { useMemo } from "react";
import { RightArrowSVG } from "../../../../common/components/icons/SVGIcon";
import { getMedical } from "../../../../common/constants/Operations";
import { getArteryLabel } from "../../../../common/constants/Artery";
import DetailItem from "./DetailItem";
import "./style.scss";

const ArteryDetail = ({
  data,
  diagnostic,
  medicals,
  parent,
  handleOperation,
  handleMedicals,
  isDraggable,
}) => {
  const getIcon = (opt) => {
    const operation = getMedical(opt);
    if (operation) {
      return operation.icon;
    }
    return "";
  };

  const getValue = (opt) => {
    const operation = getMedical(opt);
    if (operation) {
      return operation.label;
    }
    return "";
  };

  const handleClick = (opt, index) => {
    if (handleOperation) {
      handleOperation(opt, index);
    }
  };

  const moveMedical = (from, to, medical) => {
    const newMedicals = [...medicals];
    if (from !== -1) {
      newMedicals.splice(from, 1);
    }
    newMedicals.splice(to, 0, medical);
    if (handleMedicals) {
      handleMedicals(newMedicals, from, to);
    }
  };

  if (!medicals || !medicals.length) {
    return null;
  }

  const stenosis = medicals
    .map((mc) => {
      const operation = getMedical(mc);
      return operation;
    })
    .filter((sten) => sten && sten.state && sten.state === "stenosis");

  const stents = medicals
    .map((mc) => {
      const operation = getMedical(mc);
      return operation;
    })
    .filter((sten) => sten && sten.state && sten.state === "in-stent");

  const positionStyle = parent && {
    top: `${data && data.offset && data.offset.y}px`,
    right:
      data && data.offset && data.offset.x <= parent / 2 - 32
        ? parent - 10 - data.offset.x + "px"
        : "unset",
    left:
      data && data.offset && data.offset.x > parent / 2 - 32
        ? data.offset.x + 38 + "px"
        : "unset",
    flexDirection: "column",
    paddingTop: "10px",
    transform: "translateY(135%)",
    background: "transparent",
    display:
      !!getArteryLabel(data.part) &&
      getArteryLabel(data.part) !== undefined &&
      getArteryLabel(data.part) !== "undefined"
        ? "inline-flex"
        : "none",
  };

  return (
    <div
      className="arteryDetail"
      style={parent && positionStyle ? positionStyle : { position: "relative" }}
    >
      {!parent &&
        medicals.map((opt, index) => (
          <React.Fragment key={`${opt}-${index}`}>
            {index !== 0 && (
              <div className="arteryDetail-arrow">
                <RightArrowSVG />
              </div>
            )}
            <DetailItem
              isDraggable={isDraggable}
              label={getValue(opt)}
              icon={getIcon(opt)}
              pos={index}
              item={opt}
              moveItem={moveMedical}
              handleClick={() => handleClick(opt, index)}
            />
          </React.Fragment>
        ))}
      {!!parent && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "4px", color: "#000", fontWeight: 400 }}>
              {!!stenosis && stenosis[0] && stenosis[0].value}
            </div>
            {!!stenosis && stenosis[0] && (
              <div
                className="arteryDetail-arrow small"
                style={{ marginTop: 0 }}
              >
                <RightArrowSVG />
              </div>
            )}
            {!!stenosis && stenosis[0] && (
              <div
                style={{ marginLeft: "4px", color: "#000", fontWeight: 400 }}
              >
                {stenosis.length > 1
                  ? stenosis[stenosis.length - 1].value
                  : "0%"}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {!!parent && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "4px", color: "#000", fontWeight: 400 }}>
              {!!stents && stents[0] && stents[0].value}
            </div>
            {!!stents && stents[0] && (
              <div
                className="arteryDetail-arrow small"
                style={{ marginTop: 0 }}
              >
                <RightArrowSVG />
              </div>
            )}
            {!!stents && stents[0] && (
              <div
                style={{ marginLeft: "4px", color: "#000", fontWeight: 400 }}
              >
                {stents.length > 1 ? stents[stents.length - 1].value : "0%"}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ArteryDetail;
