import React, { useState, useEffect } from "react";
import History from "./History";
import DnDTextArea from "./DnDTextArea";
import "./style.scss";
import ScrollWrapper from "../ScrollBar";

function SlackEditor({
  inputBoxTitle,
  onEditorChange,
  historyData,
  userInfo,
  onSubmit,
  isReset,
  mediaFiles,
  onRemoveBtnClicked,
  fileDoubleClicked,
  onSelectedHistory,
}) {
  const [isSentMessage, setIsSentMessage] = useState(false);
  const [updatableContent, setUpdatableContent] = useState(null);
  const handleSentMessage = () => setIsSentMessage(true);
  useEffect(() => {
    setIsSentMessage(true);
  }, []);
  const handleHistoryContentClicked = (value) => {
    setUpdatableContent(value);
    onSelectedHistory(value.id);
  }
  return (
    <div className="slack-editor-wrapper">
      <History
        resource={historyData}
        userInfo={userInfo}
        isSentMessage={isSentMessage}
        fileDoubleClicked={fileDoubleClicked}
        onHistoryContentClicked={handleHistoryContentClicked}
      />
      <DnDTextArea
        title={inputBoxTitle}
        onInPutAreaChange={onEditorChange}
        onSubmit={onSubmit}
        isReset={isReset}
        onSentMessage={handleSentMessage}
        mediaFiles={mediaFiles}
        onRemoveBtnClicked={onRemoveBtnClicked}
        fileDoubleClicked={fileDoubleClicked}
        updatableContent={updatableContent}
      />
    </div>
  );
}

export default SlackEditor;
