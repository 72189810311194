import React, { useState, useEffect, useMemo } from "react";
import { message, Checkbox, Upload, Icon } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  CloseIcon,
  CreditReceivedSVG,
  SummaryEmptySVG,
  RedCloseSVG,
  CheckSVG,
  WaitingSVG,
} from "../../../../common/components/icons/SVGIcon";
import { BarcodeSVG } from "../../../../common/components/icons/FaxSVG";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import EditFormInput from "../../../../common/components/EditableText/LoginFormInput";
import Loading from "../../../../common/components/Loading";
import {
  setInventoryData,
  updateReceivingItem,
  getReceivingOrders,
  receivedReceivingItem,
} from "../../../../store/actions";

const { Dragger } = Upload;

const draggerProps = {
  name: "file",
  multiple: false,
  action: "",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const categories = {
  angiographic: "Angiographic Supply",
  narcotic: "Narcotic Inventory",
  other: "Other Inventory",
};

const ReceivingSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar },ref) => {
  const order = useSelector((state) => state.inventory.receivingOrder);
  const loading = useSelector((state) => state.inventory.loadingAPI);
  const updated = useSelector((state) => state.inventory.isUpdated);

  const dispatch = useDispatch();

  const [damaged, setDamaged] = useState(false);
  const [received, setReceived] = useState(false);
  const [credit_received, setCreditReceived] = useState(false);

  useEffect(() => {
    if (order && order.damaged) {
      setDamaged(order.damaged);
    } else {
      setDamaged(false);
    }
    if (order && order.received) {
      setReceived(true);
    } else {
      setReceived(false);
    }
    if (order && order.credit_received) {
      setCreditReceived(true);
    } else {
      setCreditReceived(false);
    }
  }, [order]);

  useEffect(() => {
    if (updated) {
      dispatch(getReceivingOrders());
      dispatch(setInventoryData("isUpdated", false));
      if (received) {
        dispatch(setInventoryData("receivingOrder", false));
        setReceived(false);
      }
    }
  }, [updated]);

  const updateReceivingOrder = () => {
    if (order) {
      if (damaged) {
        dispatch(updateReceivingItem(order.id, { damaged }));
      }
      if (received) {
        dispatch(receivedReceivingItem(order.id, { received }));
      }
    }
  };

  useEffect(() => {
    updateReceivingOrder();
  }, [damaged, received]);

  const handleDamaged = (e) => {
    const checked = e.target.checked;
    setDamaged(!!checked);
    if (!!checked) {
      setReceived(false);
      setCreditReceived(false);
    }
  };

  const handleReceived = (e) => {
    const checked = e.target.checked;
    setReceived(!!checked);
    if (!!checked) {
      setDamaged(false);
      setCreditReceived(false);
    }
  };

  const cost_per_item = useMemo(() => {
    if (order && order.cost) {
      const quantity = order.quantity ? order.quantity : 1;
      return (order.cost / quantity).toFixed(2);
    }
    return null;
  }, [order]);

  if (!isShow || !order) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={
            received ? (
              <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
            ) : credit_received ? (
              <CreditReceivedSVG />
            ) : !damaged ? (
              <WaitingSVG />
            ) : (
              <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28"/>
            )
          }
          title={order.name}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
        >
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Product No'}
              value={order.product_number}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Code No'}
              value={order.code_number}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Category'}
              value={categories[order.type]}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Brand'}
              value={order.brand}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'PAR Level'}
              value={order.par_level}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Bin Rate'}
              value={order.bin_rate}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Location'}
              value={order.location}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Specific Location"}
              value={order.specific_location}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Supplier E-Mail"}
              value={order.supplier_email}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Supplier Fax'}
              value={order.supplier_fax}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Quantity per unit'}
              value={order.quantity}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={'Unit'}
              value={order.unit}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Cost"}
              value={order.cost && parseFloat(order.cost).toFixed(2)}
              prefix={'$'}
              disabled
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Cost/Item"}
              value={cost_per_item}
              prefix={'$'}
              disabled
            />
          </div>
          <hr />
          <div className="resourceInfo d-flex" style={{ alignItems: "center" }}>
            <b>Image of Return/Packing Slip</b>
            <Dragger
              {...draggerProps}
              className="barcode-uploader"
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <BarcodeSVG />
              </p>
            </Dragger>
          </div>
          <hr />
          <div className="resourceInfo d-flex">
            <Checkbox checked={damaged} onChange={handleDamaged}>
              Inaccurate/Damaged Items
            </Checkbox>
          </div>
          <div className="resourceInfo d-flex">
            <Checkbox
              checked={received || credit_received}
              onChange={handleReceived}
            >
              Received
            </Checkbox>
          </div>
          <hr />
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default ReceivingSidebar;
