import React, { useState, useEffect, useMemo, useRef } from "react";
import { Input, DatePicker, Select, TimePicker, Checkbox } from "antd";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import AutoComplete from "@material-ui/lab/Autocomplete";
import MultiSelect from "react-multi-select-component";
import moment from "moment";
import { ExpandSVG, RedRequiredCircleSVG } from "../icons/SVGIcon";
import CustomItem from "./CustomItem";
import "./style.scss";

const { Option } = Select;
const { Password } = Input;
const dateFormat = "MM/DD/YYYY";
const timeFormat = "h:mm A";
const phoneMask = "(999) 999-9999";
const ssnMask = "999-99-9999";
const dateMask = "99/99/9999";
const specialMask = "999 / 999";

const getLocaleString = (str) => {
  if (!str) {
    return "0.00";
  }
  const num = parseFloat(str).toFixed(2);
  const decimals = num.split(".")[1];
  if (!decimals || decimals === "00") {
    return Math.round(parseFloat(str)).toLocaleString() + ".00";
  }
  if (decimals.charAt(1) === "0") {
    return (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + "0";
  }
  return (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
};

const EditableFormInput = ({
  label,
  value,
  handleChange,
  type,
  isError,
  required,
  options,
  optionKey,
  valueKey,
  enableErrorText,
  helperText,
  mask,
  fullWidth,
  noEditable,
  prefix,
  unit,
  minRows,
  autoFocus,
  tabIndex,
  multiple,
  reset,
  onCustomBlur,
  customStyle,
  onChangeSearch,
  autoClearSearchValue,
  notFoundContent,
  hideIconSelect,
  disabled,
  disableMultipleCheckAll,
}) => {
  const [error, setError] = useState(false);
  const [enableHelper, setEnableHelper] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef && selectRef.current && selectRef.current.rcSelect.inputRef) {
      selectRef.current.rcSelect.inputRef.tabIndex = tabIndex;
    }
  }, [selectRef]);

  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === "Tab" && selectRef && tabIndex >= 0) {
        setFocus(document.activeElement.tabIndex === tabIndex);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!isError) {
      setError(false);
      setEnableHelper(false);
    }
    if (enableErrorText && required && isError) {
      setEnableHelper(true);
      setError(true);
    }
    if (value && prefix !== "$") {
      setInputValue(value);
    }

    if (value && prefix === "$") {
      const str = value.replace(/,/g, "");
      if (focus) {
        setInputValue(str);
      } else {
        setInputValue(getLocaleString(str));
      }
    }
  }, [value, isError, enableErrorText]);

  const changeHandler = (str) => handleChange && handleChange(str);

  const onChangeSelect = (opt, value) => {
    if (opt) {
      changeHandler(value[optionKey] || opt);
      setFocus(false);
    }
  };

  const onChangeMultiSelect = (value) => {
    changeHandler(value);
  };

  const onSearch = (opt, value) => {
    if (onChangeSearch && opt) {
      onChangeSearch(value || opt);
    }
  };

  const lableContent = useMemo(() => {
    if (required) {
      return <span>{label}</span>;
    }
    return label;
  }, [required, label]);

  const errorText = useMemo(() => {
    if (enableHelper && helperText) {
      return (
        <span>
          <div
            style={{
              width: "100%",
              height: 20,
              marginTop: 5,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <RedRequiredCircleSVG />
            <p style={{ marginLeft: 10, color: "red" }}>
              {`   ${helperText}` || ""}
            </p>
          </div>
        </span>
      );
    }
    return "";
  }, [enableHelper]);

  const maskValue = useMemo(() => {
    if (mask === "phone") {
      return phoneMask;
    }
    if (mask === "ssn") {
      return ssnMask;
    }
    if (mask === "date") {
      return dateMask;
    }
    if (mask === "special") {
      return specialMask;
    }
    return "";
  }, [mask]);

  const getValue = () => {
    const optionValue = options.find(
      (condition) =>
        condition[optionKey] === value || condition[valueKey] === value
    );
    if (optionValue) {
      return optionValue[valueKey];
    }
    return value;
  };

  return (
    <div
      style={{
        maxWith: 210,
        width: "100%",
        minWidth: 60,
        minHeight: 18,
      }}
    >
      {!noEditable && type && type === "check" && !multiple && (
          <div className={`editableText material ${fullWidth ? "fullWidth" : ""}`}>

          <div
              className={`MuiFormControl-root MuiTextField-root date-picker ${
                  errorText ? "error" : ""
              }`}
          >
          <Select
            showSearch={true}
            ref={selectRef}
            showArrow={hideIconSelect ? false : true}
            optionFilterProp="children"
            suffixIcon={<ExpandSVG onClick={() => setFocus(!focus)} />}
            allowClear={false}
            value={options && (value || undefined)}
            placeholder={label}
            style={{ width: "100%", border: errorText ? "1px solid #da1e28" : "", color: errorText ? "#da1e28": "" }}
            onChange={onChangeSelect}
            disabled={disabled}
          >
            {(options || []).map((condition, index) => (
              <Option
                key={`condition-${condition.id}-${index}`}
                value={condition[optionKey || "id"]}
              >
                {condition[valueKey] || condition.value}
              </Option>
            ))}
          </Select>

          {/* {!!errorText && <p>{errorText}</p>} */}
        </div>
          </div>
      )}
    </div>
  );
};

export default EditableFormInput;
