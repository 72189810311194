import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const uploadUserMedia = createActionThunk(AT.UPLOAD_USER_MEDIA, (payload) =>
  API.uploadUserMedia(payload)
);

export const getAllUserMedia = createActionThunk(AT.GET_ALL_USER_MEDIA, (payload) =>
  API.getAllUserMedia(payload)
);

export const removeUserMedia = createActionThunk(AT.REMOVE_USER_MEDIA, (payload) =>
  API.removeUserMedia(payload)
);

export const updateUserMedia = createActionThunk(AT.UPDATE_USER_MEDIA, (id, payload) =>
  API.updateUserMedia(id, payload)
);
