import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ScrollWrapper from '../../../../common/components/ScrollBar';
import { useWindowSize } from '../../../../common/utils';
import { StaticResource } from '../../constant';
import InventorySearchSidebar from './InventorySearchSidebar';
import InventoryFilter from './InventoryFilter';
import {
  LeftCharvenSVG, DevicesSVG,
  ConsumableSVG, DugsSVG
} from "../../../../common/components/icons/SVGIcon";
import {
  setInventoryData,
  getInventoryCostHistory,
  setGlobalLoading
} from "../../../../store/actions";
import SidebarExtension from '../../../../common/components/SidebarExtension';
import { Icon } from 'antd';
import DataTable from '../../../../common/components/DataTable/DataTable';

const filterDataByOption = (data, option) => {
  const { specific_location, location, inventory } = option;
  let filteredData = data;
  if (!specific_location && !location && !inventory) {
    return filteredData;
  }
  if (inventory) {
    filteredData = filteredData.filter(d => d.quantity >= inventory);
  }
  if (specific_location) {
    filteredData = filteredData.filter(d => d.specific_location === specific_location);
  }
  if (location) {
    filteredData = filteredData.filter(d => d.location === location);
  }
  return filteredData;
};

const InventorySearch = ({ data, title, locations }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [initialSize, setSize] = useState({ width: undefined, height: undefined});
  const inventories = useSelector(state => state.inventory.inventories);
  const inventory = useSelector(state => state.inventory.inventory);
  const filterOption = useSelector(state => state.inventory.inventoryFilter);
  const loadingSearch = useSelector(state => state.inventory.loadingAPI);

  const dispatch = useDispatch();

  const tableRows = useMemo(() => {
    if (inventories && inventories.length) {
      return filterDataByOption(inventories, filterOption);
    }
    return [];
  }, [inventories, filterOption]);
  let emptyRows = [];
  const size = useWindowSize(ref);

  useEffect(() => {
    console.log('ref changed');
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight
      }
      setSize(initialSize);
    }
  }, [ref])

  useEffect(() => {
    dispatch(setGlobalLoading(loadingSearch))
  }, [loadingSearch])

  const handleSidebar = () => {
    setIsSidebar(true);
  }

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  }

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  }

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData('inventory', rowData[index]));
    dispatch(getInventoryCostHistory(rowData[index].id, 'other'));
    handleSidebar();
  }

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const onScroll = (values) => {
    if (values.scrollTop > values.scrollHeight - values.clientHeight - 80 && offset < tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  }
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <InventoryFilter locations={locations} />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="inventory_search"
                hideHeader={true}
                disableDelete={true}
                loadMore={onScroll}
                defaultActive={-1}
                labels={['Name', 'Quantity', 'Location', 'Specific Location']}
                widths={['50%', '130px', '130px', 'calc(50% - 260px)']}
                columns={[
                  { sortable: false, key: 'name' },
                  { sortable: false, key: 'quantity' },
                  { sortable: false, key: 'location' },
                  { sortable: false, key: 'specific_location' },
                ]}
                rowData={rowData.map((row, index) => {
                  return { 
                    name: (
                      <div 
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {
                          row.type === 'narcotic'
                          ? <Icon type="experiment" theme="twoTone" twoToneColor="#0043CE"/>
                          : row.type === 'angiographic'
                          ? <Icon type="tag" theme="twoTone" twoToneColor="#6929C4"/>
                          : <Icon type="shop" theme="twoTone" twoToneColor="#9F1853"/>
                        }
                        <p style={{ paddingLeft: "0.75rem" }} >{row.name || ''}</p>
                      </div>
                    ),
                    quantity: row.quantity,
                    location: row.location,
                    specific_location: row.specific_location
                  }
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {inventory && activeIndex >= 0 && (
        <InventorySearchSidebar
          ref={sidebarRef}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
          locations={locations}
        />
      )}
      {inventory && activeIndex >= 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default InventorySearch;
