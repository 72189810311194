import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { validateField } from '../../../services/validations';
import LoginFormInput from "../../../common/components/EditableText/LoginFormInput";
import EditableFormInput from '../../../common/components/EditableText/EditableFormInput';

const LoginForm = ({ handleSubmit, loginError }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [enableError, setEnableError] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (validateField("text", userName) || validateField("text", password)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [userName, password]);

  const handleLogin = () => {
    if (invalid) {
      setEnableError(true);
      return;
    }
    handleSubmit(userName, password);
  };

  const handleLoginKeyDown = (event) => {
    if (event.key === 'Enter')
    {
      if (invalid) {
        setEnableError(true);
        return;
      }
      handleSubmit(userName, password);
    }
  }

  return (
    <div className='loginForm'>
      <div className='formContainer'>
        <div className='formContainer__title'>
          <img src="/images/faact_logo_black.svg" alt="black logo" style={{ maxWidth: '768px' }} />
        </div>

        <div className='formContainer__username'>
          <div className='formContainer__username-labelWrapper'>
            <EditableFormInput
              label={'ID'}
              value={userName}
              handleChange={(value) => setUserName(value)}
              isError={validateField("text", userName)}
              required={true}
              fullWidth={true}
              enableErrorText={enableError}
              helperText={"ID is Required!"}
              autoFocus={true}
            />
          </div>
        </div>
        <div className='formContainer__password'>
          <div className='formContainer__password-labelWrapper'>
            <EditableFormInput
              label={'Password'}
              value={password}
              handleChange={(value) => setPassword(value)}
              isError={validateField("text", password)}
              required={true}
              fullWidth={true}
              type={'password'}
              enableErrorText={enableError}
              helperText={"Password is Required!"}
              onKeyDown={handleLoginKeyDown}
            />
          </div>
        </div>

        <div className='formContainer__checbox'>
          <Checkbox>Remember me</Checkbox>
          <div className='formContainer__buttonWrapper-forgot'>
            <div className='formContainer__buttonWrapper-forgot-links'>
              <a href='#' style={{paddingTop: '2px'}}>Forgot password?</a>
            </div>
          </div>
        </div>

        {loginError && (<div style={{ color: '#da1e28', fontSize: '0.9em', marginBottom: '12px' }}>Login ID or Password is invalid!</div>)}

        <div className='formContainer__buttonWrapper'>
            <button
              id="loginButton"
              className='common-btn'
              style={{ marginTop: '0.4rem', border: 0, marginLeft: 'auto'}}
              onClick={handleLogin}
              onKeyDown={handleLoginKeyDown}
            >
              Log in
            </button>
          </div>

      </div>
    </div>
  );
};

export default LoginForm;
