import React, { useState } from "react";
import TopBar from "../../../common/components/SubTopBar";
import Hours from "./EmployeeDetails/Hours";
import VacationRequest from "./EmployeeDetails/VacationRequest";
import Documents from "./EmployeeDetails/document";
import Variances from "./EmployeeDetails/Variances";
import Evaluations from "./EmployeeDetails/Evaluations";
import Schedules from "./EmployeeDetails/Schedules";
import ManualPolicies from "./EmployeeDetails/ManualPolicies";
import CME from "./EmployeeDetails/CME";
import { EmployeeHeaders } from "../constant";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { BASE_API } from "../../../common/constants/constants";
import { useSelector } from "react-redux";

const draggerProps = {
  name: "file",
  multiple: true,
  action: BASE_API + "process_timesheet",
};



const EmployeeDetail = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar
        title="Employee Details"
        subStatus={""}
        isBarcode={false}
        isFileUpload={false}
        draggerProps={draggerProps}
      />
      <div className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <ScrollWrapper css="no-padding tab-header-scroll">
              <div style={{ display: "flex" }}>
                {EmployeeHeaders.map((tab, index) => (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${
                      activeTab === index ? "active" : ""
                    }`}
                    style={{ width: tab.width }}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
        {activeTab === 0 && <Hours />}
        {activeTab === 1 && <VacationRequest />}
        {activeTab === 2 && <Variances />}
        {activeTab === 3 && <Evaluations />}
        {activeTab === 4 && <ManualPolicies />}
        {activeTab === 5 && <Schedules />}
        {activeTab === 6 && <CME />}
        {activeTab === 7 && <Documents />}
      </div>
    </div>
  );
}

export default EmployeeDetail;
