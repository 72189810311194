import React, { useCallback, useEffect, useState } from "react";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import {
  setDetailUltrasoundProps,
  save_ultrasound_rule_result,
} from "../../../../store/actions";
import ToastMessage from "../../../../common/components/Toast";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const UltrasoundResults = ({ resource, ...restProps }) => {
  const [detailL, setDetailL] = useState("");
  const [detailR, setDetailR] = useState("");
  const [first, setFirst] = useState(false);
  const detail_ultrasound_result = useSelector(
    (state) => state.common.detail_ultrasound_result
  );
  const apointment_ultrasound_id = cookies.get("apointment_ultrasound_id");

  const dispatch = useDispatch();

  const detail_ultrasound_props = useSelector(
    (state) => state.common.detail_ultrasound_props
  );
  const is_locked = useSelector(
      (state) => state.clinicProviderReview.is_locked
  );
  const [isToastr, set_isToastr] = useState(false);

  useEffect(() => {
    setDetailL(detail_ultrasound_props.result_l);
    setDetailR(detail_ultrasound_props.result_r);
  }, [detail_ultrasound_props]);

  const detailUltrasounds = useSelector(
    (state) => state.common.detailUltrasounds
  );

  useEffect(() => {
    setFirst(true);
  }, []);

  const [ultrasound_code, setUltrasound_code] = useState([]);

  useEffect(() => {
    if (detailUltrasounds && detailUltrasounds.codes) {
      setUltrasound_code([...detailUltrasounds.codes]);
    }
  }, [detailUltrasounds]);

  const updateCheck = async (detailCheck) => {
    await dispatch(
      save_ultrasound_rule_result(apointment_ultrasound_id, {
        left_result: detailCheck?.result_l,
        right_result: detailCheck?.result_r,
        left_conclusion: detailCheck?.conclusion_l,
        right_conclusion: detailCheck?.conclusion_r,
        disease_id: detailCheck?.disease_id,
      })
    );
    set_isToastr(true);
    setTimeout(() => {
      set_isToastr(false);
    }, 2000);
  };

  const changeHandler = useCallback(
    debounce((value) => updateCheck(value), 400),
    []
  );

  useEffect(() => {
    if (first) {
      changeHandler(detail_ultrasound_props);
    }
  }, [detail_ultrasound_props]);

  const onchangeValue = async (value, type) => {
    let detailCheck = {};
    if (type == "right") {
      detailCheck = await dispatch(
        setDetailUltrasoundProps("detail_ultrasound_props", {
          ...detail_ultrasound_props,
          result_r: value,
        })
      );
      setDetailR(value);
    } else {
      detailCheck = await dispatch(
        setDetailUltrasoundProps("detail_ultrasound_props", {
          ...detail_ultrasound_props,
          result_l: value,
        })
      );
      setDetailL(value);
    }
  };

  return (
    <div className="resourceContent pr-7px">
      {isToastr && (
        <ToastMessage
          type="Ultrasound"
          status="success"
          msg="Save Ultrasound Finding Successfully!"
        />
      )}
      <div className="resourceInfo">
        <EditableFormInput
          label={"Left Result Details"}
          value={detailL}
          type={"area"}
          fullWidth
          handleChange={(value) => {
            onchangeValue(value, "left");
          }}
          minRows={12}
          disabled={is_locked}
        />
      </div>
      <div className="resourceInfo">
        <EditableFormInput
          label={"Right Result Details"}
          value={detailR}
          type={"area"}
          handleChange={(value) => {
            onchangeValue(value, "right");
          }}
          fullWidth
          minRows={12}
          disabled={is_locked}
        />
      </div>
    </div>
  );
};

export default UltrasoundResults;
