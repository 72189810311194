import React, { Component } from "react";
import { Checkbox, Icon } from "antd";
import { connect } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import PhysicalExaminationSidebar from "./PhysicalExaminationSidebar";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import {
  patientExaminationLoadInit,
  patientExaminationCopyLast,
  patientExaminationSetData,
  patientExaminationSave,
  patientExaminationSetNomalCheck,
  setGlobalToastr,
} from "../../../../store/actions";
import ExaminationROS from "./ExaminationROS";
import Cookies from "universal-cookie";
import Loading from "../../../../common/components/Loading";
import SidebarExtension from "../../../../common/components/SidebarExtension";
const cookies = new Cookies();
class PhysicalExamination extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  state = {
    isSidebar: true,
    checkedCopyLast: false,
    saveQueued: false,
    checkSavingFuncId: null,
    checkedAllNormalsP: null,
    first: false,
    firstCheck: false,
    twoCheck: false,
  };
  timer = null;
  componentDidMount() {
    const { patientResource, all_normal_exam } = this.props;
    if (patientResource) {
      this.setState({
        ...this.state,
        isSidebar: window.innerWidth <= 768 ? false : true,
      });
    }
    this.setState({
      first: false,
    });
    if (all_normal_exam == 1) {
      this.setState({
        checkedAllNormalsP: true,
      });
    } else if (all_normal_exam == 0) {
      this.setState({
        checkedAllNormalsP: false,
      });
    } else {
      this.setState({
        checkedAllNormalsP: null,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      first: false,
    });
  }

  handleSidebar = () => {
    this.setState({ ...this.state, isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ ...this.state, isSidebar: false });
  };

  onChangeData = async (scope, data) => {
    // this.props.patientExaminationSetData(scope, data);
    // await this.props.PhysicalChange(scope, data);
    this.setState({ ...this.state, saveQueued: true });
    this.setState({
      firstCheck: true,
    });
    if (this.state.firstCheck) {
      this.saveData();
    }
  };

  onChangeDataVes = async (scope, data) => {
    await this.props.patientExaminationSetData(scope, data);
    await this.props.PhysicalChange(scope, data);
    this.setState({ ...this.state, saveQueued: true });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.setState({
        twoCheck: true,
      });
    }, 1000);
    if (this.state.twoCheck) {
      this.saveData();
    }
  };

  saveData = () => {
    const { physical_examinations, vessel_states, all_normal_exam, is_locked } =
      this.props;
    const { patientResource } = this.props;
    const patient_id = patientResource.id;
    const cookies = new Cookies();
    if (is_locked == 0 || is_locked == null) {
      clearTimeout(this.timer);
      const procedure_id = cookies.get("current_appointment_id");
      this.timer = setTimeout(async () => {
        const saveSuccess = this.props.patientExaminationSave(procedure_id, {
          all_normal_exam: all_normal_exam,
          patient_id,
          physical_examinations,
          vessel_states,
        });
        // if (saveSuccess) {
        //   await this.props.patientExaminationLoadInit(procedure_id);
        // }
      }, 1000);
    } else {
      this.props.setGlobalToastr({
        header: "Save Fail!",
        message: "Please Unlock Finalize",
        type: "failed",
      });
    }
    this.setState({ ...this.state, saveQueued: false });
  };

  doCopyLast = (checked) => {
    const { patientResource, is_locked } = this.props;
    if (checked) {
      if (is_locked == 0 || is_locked == null) {
        clearTimeout(this.timer);
        this.props.PhysicalCoppy(patientResource.id);
        this.setState({ ...this.state, checkedCopyLast: true });
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
      }
    }
  };

  checkAllNormals = async (checked) => {
    await this.setState({
      first: false,
    });
    const { physical_examinations, vessel_states, patientResource, is_locked } =
      this.props;
    const patient_id = patientResource.id;
    const cookies = new Cookies();
    const procedure_id = cookies.get("current_appointment_id");
    if (is_locked == 0 || is_locked == null) {
      await this.setState({
        ...this.state,
        saveQueued: true,
        checkedAllNormalsP: checked,
      });
      let physical_examinations_ = [...physical_examinations];
      await this.props.PhysicalCheckAll(
        "physical_examinations",
        physical_examinations_,
        checked
      );
      this.props.patientExaminationSave(procedure_id, {
        all_normal_exam: checked ? 1 : 0,
        patient_id: patient_id,
        physical_examinations,
        vessel_states,
      });
    } else {
      this.props.setGlobalToastr({
        header: "Save Fail!",
        message: "Please Unlock Finalize",
        type: "failed",
      });
    }
  };

  render() {
    const { isSidebar } = this.state;
    const { checkedCopyLast, checkedAllNormalsP } = this.state;
    const { loadingEx } = this.props;

    return (
      <div
        style={{ display: this.props.display ? "flex" : "none" }}
        className="clinicWorkflow__main-section"
      >
        <div
          className="eventsContainer has-sub-header"
          style={{ paddingTop: "1rem" }}
        >
          <ScrollWrapper css="no-padding">
            <div className="conditionList">
              <Checkbox
                checked={this.props.physicalCoppy}
                onChange={(e) => this.doCopyLast(e.target.checked)}
                disabled={this.props.can_copy_from_previous_visit === 0}
              >
                Copy from previous visit
              </Checkbox>
              <Checkbox
                checked={checkedAllNormalsP}
                onChange={(e) => this.checkAllNormals(e.target.checked)}
                disabled={this.props.is_locked}
              >
                All negative except selections
              </Checkbox>
              <div className="listSection">
                <ExaminationROS
                  type={"physical_examination"}
                  onChangeData={(data) => {
                    this.onChangeData("physical_examinations", data);
                  }}
                  isLocked={this.props.is_locked}
                />
              </div>
            </div>
          </ScrollWrapper>
        </div>
        {this.props.isSlide && (
          <PhysicalExaminationSidebar
            ref={this.sidebarRef}
            isShow={isSidebar}
            sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor='#740937'/>}
            checkedAllNormalsP={checkedAllNormalsP}
            handleCloseSidebar={this.handleCloseSidebar}
            onChangeDataVes={(data) =>
              this.onChangeDataVes("vessel_states", data)
            }
            isLocked={this.props.is_locked}
          />
        )}

        {this.props.isSlide && (
          <SidebarExtension
            startingWidth="calc(28.3% - 20px + 0.3rem)"
            sidebarReference={this.sidebarRef}
            sidebarToggle={this.toggleSidebar}
            sidebarState={isSidebar}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_locked: state.clinicProviderReview.is_locked,
  patientResource: state.clinicProvider.patientResource,
  loadingEx: state.patientExamination.loadingEx,
  physical_examinations: state.patientExamination.physical_examinations,
  vessel_states: state.patientExamination.vessel_states,
  saving: state.patientExamination.saving,
  all_normal_exam: state.patientExamination.all_normal_exam,
  can_copy_from_previous_visit:
    state.patientExamination.can_copy_from_previous_visit,
});

const mapDispatchToProps = {
  patientExaminationLoadInit,
  patientExaminationCopyLast,
  patientExaminationSetData,
  patientExaminationSave,
  patientExaminationSetNomalCheck,
  setGlobalToastr,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhysicalExamination);
