import { type } from "ramda";
import { createReducer } from "redux-create-reducer";

import AT from "../actionTypes";

const initialState = {
  slug: null,
  location: null,
  type: null,
  appointments: null,
  clinicalSlug: null,
  patientSlug: null
};
// CREATE_APPOINTMENT_BOARD_CLINICAL
const GET_APPOINTMENT_BOARD_APPOINTMENTS_SUCCEEDED = `${AT.GET_APPOINTMENT_BOARD_APPOINTMENTS}_SUCCEEDED`;
const CREATE_APPOINTMENT_BOARD_CLINICAL_SUCCEEDED = `${AT.CREATE_APPOINTMENT_BOARD_CLINICAL}_SUCCEEDED`;
const CREATE_APPOINTMENT_BOARD_PATIENT_SUCCEEDED = `${AT.CREATE_APPOINTMENT_BOARD_PATIENT}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [GET_APPOINTMENT_BOARD_APPOINTMENTS_SUCCEEDED](state, action) {
    const {appointments, slug, location, type} = action.payload;
    return Object.assign({}, state, {
      slug: slug,
      type: type,
      location: location,
      appointments: appointments,
    });
  },
  [CREATE_APPOINTMENT_BOARD_CLINICAL_SUCCEEDED](state, action) {
    const {slug} = action.payload;
    return Object.assign({}, state, {
      clinicalSlug: slug,
    });
  },
  [CREATE_APPOINTMENT_BOARD_PATIENT_SUCCEEDED](state, action) {
    const {slug} = action.payload;
    return Object.assign({}, state, {
      patientSlug: slug,
    });
  },
});
