import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Icon } from 'antd';
import { LeftCharvenSVG } from '../icons/SVGIcon';
import './style.scss';

const SubSideNav = ({
  subMenu,
  title,
  isSideNav,
  openSideNav,
  closeSideNav
}) => {

  const onClickHander = () => {
    if (isSideNav) {
      closeSideNav()
    } else {
      openSideNav()
    }
  }

  const handleOpen = () => {
    if (!isSideNav) {
      openSideNav()
    }
  }

  return (
    <div className={`SubSideNav ${isSideNav ? '' : 'collapsed'}`}>
      <div className={`SubSideNav__main ${isSideNav ? '' : 'collapsed'}`} onClick={handleOpen}>
        <div className={`SubSideNav__main-list ${isSideNav ? '' : 'collapsed'}`}>
          {title && (
            <div className="SubSideNav__main-title-logo">
              {title}
            </div>
          )}
          {(subMenu || []).map((nav, index) => (
            <div className="SubSideNav__main-item" key={`subsidenav-${index}`}>
              <NavLink to={nav.link}>{nav.label}</NavLink>
            </div>
          ))}
        </div>
      </div>
      <div className={`SubSideNav__handle ${isSideNav ? '' : 'collapsed'}`} onClick={onClickHander}>
        <LeftCharvenSVG />
      </div>
    </div>
  );
};

export default SubSideNav;
