import React, { useState, useEffect, useRef } from "react";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ReviewText from "./ReviewText";
import ReviewSidebar from "./ReviewSidebar";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import { StaticResource, TempReview } from "../../constant";
import {
  choosePatientOnSchedules,
  get_cpt_code_review,
  angioPatientSnapshotLoadInit,
  load_procedurals,
  load_intra_operative,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const cookies = new Cookies();
const Review = ({sidebarIcon}) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [valueText, set_valueText] = useState("");
  const [content, setContent] = useState(TempReview);
  const [editedContent, setEditedContent] = useState(TempReview);
  const current_patient_id = cookies.get("current_patient_id");
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const [dataCPT, set_dataCPT] = useState([]);
  const dispatch = useDispatch();

  const onChangeCPT = (value) => {
    set_dataCPT(value);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleEditable = (status) => {
    setIsEditable(status);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleContent = (e) => {
    setContent(e.target.value);
  };

  const handleSubmit = () => {
    setIsEditable(false);
    setContent(editedContent);
  };

  const cancleEditable = () => {
    setIsEditable(false);
  };

  useEffect(() => {
    if (patientResource) {
      const patient_id = patientResource && patientResource.id;
      const cookies = new Cookies();
      const procedure_id = cookies.get("current_appointment_id");
      dispatch(angioPatientSnapshotLoadInit(procedure_id));
      dispatch(load_procedurals(procedure_id));
      dispatch(load_intra_operative(procedure_id));
    }
  }, [patientResource]);

  const changeValueText = (value) => {
    set_valueText(value);
  };

  useEffect(() => {
    // dispatch(choosePatientOnSchedules(current_patient_id));
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div
        className="eventsContainer has-sub-header"
        style={{ paddingTop: "1rem" }}
      >
        <ScrollWrapper css="conditionList x-hidden">
          {patientResource && (
            <ReviewText
              dataCPT={dataCPT}
              changeValueText={(value) => {
                changeValueText(value);
              }}
            />
          )}
        </ScrollWrapper>
      </div>
      {isSidebar && (
        <ReviewSidebar
          ref={sidebarRef}
          onChangeCPT={(value) => {
            onChangeCPT(value);
          }}
          sidebarIcon={sidebarIcon}
          resource={StaticResource}
          isShow={isSidebar}
          valueText={valueText}
          handleCloseSidebar={handleCloseSidebar}
          handleEditable={handleSubmit}
          cancleEditable={cancleEditable}
        />
      )}
      <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
    </div>
  );
};

export default Review;
