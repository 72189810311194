import React, { useState, useMemo } from 'react';
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";

const InputTextTemplate = ({
  textTemplate,
  questions,
  onChangeTextTemplate
}) => {
  const [word, setWord] = useState('');
  const [scope, setScope] = useState('');
  const [formType, setFormType] = useState('new');
  const [currentIndex, setIndex] = useState(textTemplate.length-1);
  const [varType, setVarType] = useState('choice');

  const questionScopes = useMemo(() => {
    if (questions && questions.length > 0) {
      const scopes = [{ value: 'Any', option: '' }];
      questions.map((question) => {
        scopes.push({ value: `${question.question} [Comments]`, option: `${question.question}::comment`});
        question.choices.map((choice) => {
          scopes.push({
            value: `${question.question}->${choice}`,
            option: `${question.question}->${choice}`
          });
        });
      });
      return scopes;
    }
    return [{ value: 'Any', option: '' }];
  }, [questions]);

  const onClickInsertItem = (index) => {
    setWord('');
    setScope('');
    setFormType('new');
    setVarType('choice');
    setIndex(index);
  }
  const onClickItem = (index) => {
    setWord(textTemplate[index]['text']);
    setScope(textTemplate[index]['scope']);
    setFormType('modify');
    setVarType('choice');
    if (textTemplate[index]['scope'].includes('::comment')) {
      setVarType('comment');
    }
    setIndex(index);
  }
  const onClickModify = () => {
    if (word=='') return;
    let newTextTemplate = [...textTemplate];
    let textTemplateItem = {scope: scope, text: word};    
    newTextTemplate.splice(currentIndex, 1, textTemplateItem);
    onChangeTextTemplate(newTextTemplate);
  }
  const onClickInsert = () => {
    if (varType=='choice' && word=='') return;
    let newTextTemplate = [...textTemplate];
    let textTemplateItem = {scope: scope, text: word};    
    newTextTemplate.splice(currentIndex, 0, textTemplateItem);
    setIndex(currentIndex+1);
    setWord('');
    setScope('');
    setVarType('choice');
    onChangeTextTemplate(newTextTemplate);
  }
  const onClickDelete = () => {
    let newTextTemplate = [...textTemplate];
    newTextTemplate.splice(currentIndex, 1);
    setWord('');
    setScope('');
    setVarType('choice');
    setFormType('new');
    setIndex(newTextTemplate.length);
    onChangeTextTemplate(newTextTemplate);
  }

  const onChangeChoice = (selectedChoice) => {
    setScope(selectedChoice); 
    setVarType('choice');
    if (selectedChoice.includes('::comment')) {
      setVarType('comment');
    }
  }

  const onClickMovePrev = () => {
    if (currentIndex==0) return;
    let prevTemplateItem = {...textTemplate[currentIndex-1]};
    let curTemplateItem = {...textTemplate[currentIndex]};
    textTemplate[currentIndex-1] = curTemplateItem;
    textTemplate[currentIndex] = prevTemplateItem;
    let newTextTemplate = [...textTemplate];
    setIndex(currentIndex-1);
    onChangeTextTemplate(newTextTemplate);
  }

  const onClickMoveNext = () => {
    if (currentIndex==textTemplate.length-1) return;
    let nextTemplateItem = {...textTemplate[currentIndex+1]};
    let curTemplateItem = {...textTemplate[currentIndex]};
    textTemplate[currentIndex+1] = curTemplateItem;
    textTemplate[currentIndex] = nextTemplateItem;
    let newTextTemplate = [...textTemplate];
    setIndex(currentIndex+1);
    onChangeTextTemplate(newTextTemplate);
  }

  return (
    <React.Fragment>
      <div style={{
        width: '100%', height: '150px', overflowY: 'auto',
        border: '1px solid #dfe3e6', 
        padding: '4px',
        cursor: 'text'
      }}>
        {textTemplate.map((word, index) => (
          <React.Fragment key={`text-template-${index}`}>
            <span
              style={{
                backgroundColor: '#e4e7eb',
                borderRadius: '0 5px 5px 0',
                cursor: 'pointer', 
                display: 'inline-block',
                marginTop: '2px', marginBottom: '2px'
              }}
              onClick={() => onClickItem(index)}
            >
              {word.scope != '' &&
                <strong 
                  style={{
                    color: '#fff',
                    padding: '0 2px',
                    backgroundColor: '#005ef6',
                    borderRadius: '5px 0 0 5px'
                  }}
                >
                  {word.scope}
                </strong>
              }
              <span style={{paddingLeft: '4px'}}>
                {word.text}
              </span>          
            </span>
            {textTemplate.length-1 != index ?
              <span 
                style={{ 
                  width: '10px',
                  border: '1px solid #e4e7eb',
                  borderRadius: '5px',
                  display: 'inline-block'
                }} 
                onClick={() => onClickInsertItem(index+1)}
              >
                &nbsp;
              </span>
            :
              <span 
                className="small-blue-btn medium"
                onClick={() => onClickInsertItem(index+1)}
              >
                +
              </span>
            }            
          </React.Fragment>
        ))}
      </div>
      <div 
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '1rem'
        }}
      >
        {formType=='modify' &&
          <div style={{ flex: 0, marginRight: '0.5rem' }}>
            <button 
              className="small-blue-btn normal"
              onClick={() => onClickDelete()}
            >
              <span>Delete</span>
            </button>
          </div>
        }
        {varType=='choice' &&
          <div style={{ flex: 1, width: '50%', paddingRight: '0.5rem' }}>
            <EditableFormInput
              label={'Template'}
              value={word}
              handleChange={setWord}
            />
          </div>
        }
        {formType=='new' && 
          <div style={{ flex: 1, paddingRight: '0.5rem' }}>
            <EditableFormInput
              label={"Scope"}
              value={scope}
              type={'check'}
              handleChange={onChangeChoice}
              options={questionScopes}
              valueKey={'value'}
              optionKey={'option'}
            />
          </div>
        }        
        {formType=='new' &&
          <div style={{ flex: 0 }}>          
            <button 
              className="small-blue-btn normal"
              onClick={onClickInsert}
            >
              Add
            </button>
        </div>
        }
        {formType=='modify' && varType!='comment' &&
          <div style={{ flex: 0 }}>
            <button 
              className="small-blue-btn normal"
              onClick={onClickModify}
            >
              Update
            </button>
          </div>
        }
        {formType=='modify' &&
          <div style={{ flex: 0 }}>
            <button 
              className="small-blue-btn normal"
              onClick={onClickMovePrev}
            >
              &lt;
            </button>
          </div>
        }
        {formType=='modify' &&
          <div style={{ flex: 0 }}>
            <button 
              className="small-blue-btn normal"
              onClick={onClickMoveNext}
            >
              &gt;
            </button>
          </div>
        }
      </div>
    </React.Fragment>
  )

}

export default InputTextTemplate;

