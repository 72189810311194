import React, { useState, useEffect } from "react";
import BarcodeUpload from "../BarcodeUpload";
import FileUpload from "../FileUpload";
import DocumentUpload from "../DocumentUpload";

import "./style.scss";
import ImageUpload from "../../../views/clinicWorkflow/components/providerSubViews/ImageUpload";
import { useSelector, useDispatch } from "react-redux";
import { CheckSVG } from "../icons/SVGIcon";
import { set_toast_put_bar_code } from "../../../store/actions";
import { Icon } from "antd";

const SubTopBar = ({
  title,
  subStatus,
  sideComponent,
  isBarcode,
  draggerProps,
  onRefreshImageList,
  isFileUpload,
  isDocumentUpload,
  noLeftSide,
  noSubHeader,
}) => {
  const [isToast, setIsToast] = useState(false);
  const toastPutBarCode = useSelector((state) => state.common.toastPutBarCode);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toastPutBarCode) {
      setIsToast(true);
      setTimeout(() => {
        dispatch(set_toast_put_bar_code());
        setIsToast(false);
      }, 2000);
    }
  }, [toastPutBarCode]);
  return (
    <div className={`subTopBar-c ${noSubHeader ? 'no-sub-header' : ''}`} style={{ paddingLeft: noLeftSide ? '5%' : '2rem' }}>
      <div className={`subTopTitle ${noSubHeader ? 'no-sub-header' : ''}`}>
        <div className="mainContent">{`${title}`}</div>
        {subStatus && subStatus}
      </div>
      {isToast ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: 5,
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
            }}
          >
            Upload Successfully <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
          </div>
        </div>
      ) : null}
      {sideComponent}
      {/* {(!isBarcode && !isFileUpload && !isDocumentUpload) && !!sideComponent && sideComponent} */}
      {/* {isBarcode && <BarcodeUpload draggerProps={draggerProps} sideComponent={sideComponent} />}
      {isFileUpload && <FileUpload draggerProps={draggerProps} sideComponent={sideComponent} />}
      {isDocumentUpload && <DocumentUpload draggerProps={draggerProps} sideComponent={sideComponent} />} */}
    </div>
  );
};

export default SubTopBar;
