import React, { useState, useEffect, useRef } from "react";
import ScrollWrapper from "../../components/ScrollBar";
import { useWindowSize } from "../../utils";
import { CheckSVG, YellowAlertSVG } from "../../components/icons/SVGIcon";
import { LaboratoryLabels } from "./constant";

const LaboratoryTable = () => {
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const tableRows = LaboratoryLabels ? LaboratoryLabels : [];
  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index) => () => {
    setRow(index);
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  return (
    <div className="fullContainer pt-2">
      <div className="tableHeader">
        <div className="tableItem">
          <div className="th" style={{ borderLeft: 0, width: "20%" }}>
            <b></b>
          </div>
          <div className="th" style={{ width: "20%" }}>
            <b>1/24/2020</b>
          </div>
          <div className="th" style={{ width: "20%" }}>
            <b>1/24/2020</b>
          </div>
          <div className="th" style={{ width: "20%" }}>
            <b>1/24/2020</b>
          </div>
          <div className="th" style={{ width: "20%" }}>
            <b>1/24/2020</b>
          </div>
        </div>
      </div>
      <div className="tableSection">
        <div ref={ref} className="tableContainer">
          <ScrollWrapper css="no-padding x-hidden">
            <div className="tableList">
              {tableRows.map((row, index) => (
                <div
                  className={`tableItem ${
                    activeIndex === index ? "active" : ""
                  }`}
                  key={`laboratory-table-${index}`}
                  onClick={handleRow(index)}
                >
                  <div className="td with-icon" style={{ width: "20%" }}>
                    <p>{row}</p>
                  </div>
                  <div className="td with-icon" style={{ width: "20%" }}>
                    <p>{""}</p>
                  </div>
                  <div className="td" style={{ width: "20%" }}>
                    <p>{""}</p>
                  </div>
                  <div className="td" style={{ width: "20%" }}>
                    <p>{""}</p>
                  </div>
                  <div className="td" style={{ width: "20%" }}>
                    <p>{""}</p>
                  </div>
                </div>
              ))}
              {emptyRows.map((row, index) => (
                <div
                  className={`tableItem empty`}
                  key={`laboratory-empty-${index}`}
                >
                  <div className="td" style={{ width: "20%" }} />
                  <div className="td" style={{ width: "20%" }} />
                  <div className="td" style={{ width: "20%" }} />
                  <div className="td" style={{ width: "20%" }} />
                  <div className="td" style={{ width: "20%" }} />
                </div>
              ))}
            </div>
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default LaboratoryTable;
