import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import ScrollWrapper from "../ScrollBar";
import Loading from "../Loading";
import { SearchSVG } from "../icons/SVGIcon";
import { useOutsideDetect } from "../../utils";
import "./style.scss";

const { Search } = Input;

const AutoCompleteSearch = ({
  options,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  handlePopulate,
  keyLabel,
  disableOptions,
  actionLabel,
  handleCurrentItem,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);
  const [openResult, setOpenResult] = useState(false);
  const optionData = options ? options : [];

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setSelected(row);
    if (keyLabel === "provider_full_name") {
      setSearch(`${row.first_name} ${row.last_name}`);
    } else {
      setSearch(row[keyLabel]);
      if (handleCurrentItem) {
        handleCurrentItem(row);
      }
    }
    setOpenResult(false);
    handlePopulate(row);
  };

  const handleSearchItem = () => {
    if (handlePopulate) {
      handlePopulate(selected);
    }
    // setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target.value);
    if (handleSearch) {
      handleSearch(e.target.value);
    }
  };

  const handleKeyPress = async () => {
    if (optionData && optionData[activeIndex]) {
      setActiveIndex(-1);
      if (keyLabel !== "provider_full_name") {
        if (handleCurrentItem) {
          handleCurrentItem(optionData[activeIndex]);
        }
      }
      setOpenResult(false);
      if (handlePopulate) {
        handlePopulate(selected);
      }
      setSearch("");
    }
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (optionData && optionData[activeIndex + 1]) {
        setSelected(optionData[activeIndex + 1]);
        setActiveIndex(activeIndex + 1);
      }
    } else {
      if (optionData && optionData[activeIndex - 1]) {
        setSelected(optionData[activeIndex - 1]);
        setActiveIndex(activeIndex - 1);
      }
    }
  };

  return (
    <div className="atuoComplete">
      <div className="atuoComplete__filter">
        <div
          ref={ref}
          className={`atuoComplete__filter-search origin ${
            disableSearchIcon ? "disable-icon" : ""
          }`}
        >
          <Search
            value={search}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 40) {
                onKeyDown("down");
              } else if (e.keyCode == 38) {
                onKeyDown("up");
              }
            }}
            onPressEnter={handleKeyPress}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{ width: "100%", height: "42.5px" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        {actionLabel == "Search" ? null : (
          <div className="atuoComplete__filter-action">
            <button
              className="atuoComplete__filter-add"
              onClick={handleSearchItem}
            >
              <span>{actionLabel || "Add"}</span>
              <span>+</span>
            </button>
          </div>
        )}

        {!disableOptions && openResult && !!search && (
          <div
            ref={ref1}
            className="atuoComplete__filter-result"
            style={{ minHeight: "150px" }}
          >
            <div className="atuoComplete__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper css="no-padding">
                {optionData.length > 0 &&
                  optionData.map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      style={{
                        backgroundColor:
                          index == activeIndex ? "#DCE3EE" : "#fff",
                      }}
                      key={`empty-${title}-${index}`}
                      onClick={() => handleSelectItem(row)}
                    >
                      {keyLabel === "provider_full_name" && (
                        <p style={{ paddingLeft: "28px" }}>
                          {row.first_name} {row.last_name}
                        </p>
                      )}
                      {keyLabel !== "provider_full_name" && (
                        <p style={{ paddingLeft: "28px" }}>{row[keyLabel]}</p>
                      )}
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoCompleteSearch;
