import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Upload, message, Button, DatePicker } from "antd";

import {
  CloseIcon,
  ExpandSVG,
  SummaryEmptySVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { BASE_API } from "../../../../common/constants/constants";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  getPolicies,
  putPolicies,
  postPolicies,
} from "../../../../store/actions";
import ToastMessage from "../../../../common/components/Toast";

const draggerProps = {
  name: "file",
  multiple: true,
  action: BASE_API + "patients/1/upload",
};

const ManualPoliciesSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  policiesDetail,
}, ref) => {
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [image, setImage] = useState([]);
  const [isToastr, setIsToastr] = useState(false);

  const [policyNumber, setPolicyNumber] = useState("");
  const [policyName, setPolicyName] = useState("");

  const [state, setState] = useState({
    purposeEdit: EditorState.createEmpty(),
    appliesEdit: EditorState.createEmpty(),
    policyEdit: EditorState.createEmpty(),
    proceduresEdit: EditorState.createEmpty()
  })
  const [isReset, setIsReset] = useState(false);
 
  const dispatch = useDispatch();
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);

  const fetchState = () => {
    if (policiesDetail) {
      setReset(true);
      const purposeBlock = convertFromHTML(
        policiesDetail.purpose ? policiesDetail.purpose : ""
      );
      const appliesBlock = convertFromHTML(
        policiesDetail.apply_to ? policiesDetail.apply_to : ""
      );
      const policyBlock = convertFromHTML(
        policiesDetail.policy ? policiesDetail.policy : ""
      );
      const proceduresBlock = convertFromHTML(
        policiesDetail.procedures ? policiesDetail.procedures : ""
      );
      setState({
        id: policiesDetail.id,
        purposeEdit: purposeBlock ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            purposeBlock.contentBlocks,
            purposeBlock.entityMap
          )
        ) : EditorState.createEmpty(),
        appliesEdit: appliesBlock ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            appliesBlock.contentBlocks,
            appliesBlock.entityMap
          )
        ) : EditorState.createEmpty(),
        policyEdit: policyBlock ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            policyBlock.contentBlocks,
            policyBlock.entityMap
          )
        ) : EditorState.createEmpty(),
        proceduresEdit: proceduresBlock ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            proceduresBlock.contentBlocks,
            proceduresBlock.entityMap
          )
        ) : EditorState.createEmpty(),
        date: moment(policiesDetail.effective_date)
      });
    }
  };

  const onChangeUpload = (info) => {
    const { status } = info.file;
    if (info.file || info.fileList) {
      setImage(info.file || info.fileList);
    }
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "uploading") {
      if (uploaded) {
        setUploaded(false);
      }
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setUploaded(true);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploaded(true);
    }
  };

  // const changePolicies = (type) => (value) => {
  //   const newPolicies = { ...policies, [type]: value };
  //   setPolicies(newPolicies);
  // };

  const resetPolicies = () => {
    setPolicyNumber("");
    setPolicyName("");
    setState({
      purposeEdit: EditorState.createEmpty(),
      appliesEdit: EditorState.createEmpty(),
      policyEdit: EditorState.createEmpty(),
      proceduresEdit: EditorState.createEmpty()
    })
    setIsNew(true);
  };

  const cancelNewPolicies = () => {
    fetchState();
    setIsNew(false);
  };

  const updateAll = async (value) => {
    if (
      Object.keys(currentUserInfo).length > 0 &&
      Object.keys(policiesDetail).length > 0
    ) {
      const saveSuccess = await dispatch(
        putPolicies(policiesDetail.id, {
          policy_number: policiesDetail.policy_number,
          purpose: stateToHTML(value.purposeEdit.getCurrentContent()),
          policy: stateToHTML(value.policyEdit.getCurrentContent()),
          apply_to: stateToHTML(value.appliesEdit.getCurrentContent()),
          procedures: stateToHTML(value.proceduresEdit.getCurrentContent()),
          approved_by: policiesDetail.approved_by,
          effective_date: moment(value.date).format("MM/DD/YYYY"),
        })
      );
      const getList = await dispatch(getPolicies());
      if (saveSuccess && getList) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      }
    }
  };

  const changeHandler = useCallback(debounce((value) => updateAll(value), 400), []);

  const handleStateChange = (type, value) => {
    const newState = {
      ...state,
      [type]: value
    };
    setState(newState);
    if (!isNew) {
      isReset && changeHandler(newState);
    }
  }

  const saveNewPolicies = async () => {
    const saveNewSuccess = await dispatch(
      postPolicies({
        policy_number: policyNumber,
        policy_name: policyName,
        purpose: stateToHTML(state.purposeEdit.getCurrentContent()),
        policy: stateToHTML(state.policyEdit.getCurrentContent()),
        apply_to: stateToHTML(state.appliesEdit.getCurrentContent()),
        procedures: stateToHTML(state.proceduresEdit.getCurrentContent()),
        approved_by: currentUserInfo.name,
        effective_date: moment(state.date).format("MM/DD/YYYY"),
      })
    );
    const getNewList = await dispatch(getPolicies());
    if (saveNewSuccess && getNewList) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
    setIsNew(false);
  };

  useEffect(() => {
    if (policiesDetail && policiesDetail.id !== state.id) {
      fetchState();
      setIsReset(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
  }, [policiesDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  if (!isShow || !policiesDetail || !Object.keys(policiesDetail).length) {
    return null;
  }

  const renderTitle = () => {
    return (
      <span className="text-uppercase">
        {!isNew
          ? `Policy ${policiesDetail.policy_number} - ${policiesDetail.policy_name}`
          : ""}
      </span>
    );
  };
  console.dir(ref);
  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      {isToastr && (
        <ToastMessage
          type="Policy"
          status="success"
          msg="Saved Successfully!"
        />
      )}
      <ScrollWrapper css="x-hidden">
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>

        {!isNew ? (
          <SidebarTitle icon={<MaleSVG />} title={renderTitle()} subTitle="" />
        ) : null}

        <div
          className="resourceContent"
          style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
        >
          {isNew ? (
            <>
              <div className="resourceInfo">
                <EditableFormInput
                  label={"Policy No"}
                  value={policyNumber}
                  handleChange={(value) => {
                    setPolicyNumber(value);
                  }}
                />
              </div>
              <div className="resourceInfo">
                <EditableFormInput
                  label={"Policy Name"}
                  value={policyName}
                  handleChange={(value) => {
                    setPolicyName(value);
                  }}
                />
              </div>

              <div className="resourceInfo flex">
                <b style={{ minWidth: "35%" }}>Purpose: </b>
                <div style={{ marginTop: "5px" }}>
                  <Editor
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    editorStyle={{
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                    }}
                    placeholder="Purpose"
                    editorState={state.purposeEdit}
                    onEditorStateChange={(value) => {
                      handleStateChange('purposeEdit', value);
                    }}
                  />
                </div>
              </div>
              <div className="resourceInfo flex">
                <b style={{ minWidth: "35%" }}>Applies to: </b>
                <div style={{ marginTop: "5px" }}>
                  <Editor
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    editorStyle={{
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                    }}
                    placeholder="Applies"
                    editorState={state.appliesEdit}
                    onEditorStateChange={(value) => {
                      handleStateChange('appliesEdit', value);
                    }}
                  />
                </div>
              </div>
              <div className="resourceInfo flex">
                <b style={{ minWidth: "35%" }}>Policy: </b>
                <div style={{ marginTop: "5px" }}>
                  <Editor
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    editorStyle={{
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                    }}
                    placeholder="Policy"
                    editorState={state.policyEdit}
                    onEditorStateChange={(value) => {
                      handleStateChange('policyEdit', value);
                    }}
                  />
                </div>
              </div>

              <div className="resourceInfo flex">
                <b style={{ minWidth: "35%" }}>Procedures: </b>
                <div style={{ marginTop: "5px" }}>
                  <Editor
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    editorStyle={{
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                    }}
                    editorState={state.proceduresEdit}
                    placeholder="Procedures"
                    onEditorStateChange={(value) => {
                      handleStateChange('proceduresEdit', value);
                    }}
                  />
                </div>
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%", marginTop: "5px" }}>
                  Form Attachment:{" "}
                </b>
                <div className="detailContent" style={{ display: "flex" }}>
                  <div style={{ marginLeft: "10px" }}>
                    <Upload
                      onChange={onChangeUpload}
                      className="barcode-uploader"
                      {...draggerProps}
                    >
                      <Button>Upload File</Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%" }}>Approved by: </b>
                <div style={{ marginLeft: "0.5rem" }}>
                  {currentUserInfo.name || ""}
                </div>
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%", marginTop: "10px" }}>
                  Effective date:{" "}
                </b>
                <div style={{ marginLeft: "0.5rem" }}>
                  <DatePicker
                    format={"MM/DD/YYYY"}
                    placeholder="Effective date"
                    suffixIcon={<ExpandSVG />}
                    onChange={(value) => {
                      handleStateChange('date', value);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="resourceInfo flex">
                <b style={{ minWidth: "35%" }}>Purpose: </b>
                <div style={{ marginTop: "5px" }}>
                  <Editor
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    editorStyle={{
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                    }}
                    editorState={state.purposeEdit}
                    placeholder="Purpose"
                    onEditorStateChange={(value) => {
                      handleStateChange('purposeEdit', value);
                    }}
                  />
                </div>
              </div>
              <div className="resourceInfo flex">
                <b style={{ minWidth: "35%" }}>Applies to: </b>
                <div style={{ marginTop: "5px" }}>
                  <Editor
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    editorStyle={{
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                    }}
                    editorState={state.appliesEdit}
                    placeholder="Applies"
                    onEditorStateChange={(value) => {
                      handleStateChange('appliesEdit', value);
                    }}
                  />
                </div>
              </div>
              <div className="resourceInfo flex">
                <b style={{ minWidth: "35%" }}>Policy: </b>
                <div style={{ marginTop: "5px" }}>
                  <Editor
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    editorStyle={{
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                    }}
                    editorState={state.policyEdit}
                    placeholder="Policy"
                    onEditorStateChange={(value) => {
                      handleStateChange('policyEdit', value);
                    }}
                  />
                </div>
              </div>

              <div className="resourceInfo flex">
                <b style={{ minWidth: "35%" }}>Procedures: </b>
                <div style={{ marginTop: "5px" }}>
                  <Editor
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    editorStyle={{
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                    }}
                    editorState={state.proceduresEdit}
                    placeholder="Procedures"
                    onEditorStateChange={(value) => {
                      handleStateChange('proceduresEdit', value);
                    }}
                  />
                </div>
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%", marginTop: "5px" }}>
                  Form Attachment:{" "}
                </b>
                <div className="detailContent" style={{ display: "flex" }}>
                  <div style={{ marginLeft: "10px" }}>
                    <Upload
                      onChange={onChangeUpload}
                      className="barcode-uploader"
                      {...draggerProps}
                    >
                      <Button>Upload File</Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%" }}>Approved by: </b>
                <div style={{ marginLeft: "0.5rem" }}>
                  {policiesDetail.approved_by}
                </div>
              </div>
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%", marginTop: "10px" }}>
                  Effective date:{" "}
                </b>
                <div style={{ marginLeft: "0.5rem" }}>
                  <DatePicker
                    format={"MM/DD/YYYY"}
                    value={state.date}
                    placeholder="Effective date"
                    suffixIcon={<ExpandSVG />}
                    onChange={(value) => {
                      handleStateChange('date', value);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {!isNew && (
          <div
            className="actionContent"
            style={{
              marginTop: "2rem",
              paddingRight: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <button className={"outlined-btn"} onClick={resetPolicies}>
              New Policy or Form
            </button>
          </div>
        )}
        {isNew && (
          <div
            className="actionContent"
            style={{
              marginTop: "2rem",
              paddingRight: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <button className={"outlined-btn"} onClick={cancelNewPolicies}>
              Back
            </button>
            <button style={{ marginLeft: "0.5rem" }} onClick={saveNewPolicies}>
              Save
            </button>
          </div>
        )}
      </ScrollWrapper>
    </div>
  );
});

export default ManualPoliciesSidebar;
