import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Cookies from "universal-cookie";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Map } from "immutable";
import moment from "moment";
import {
  getLocations,
  setParamsChart,
  setGlobalLoading,
} from "../../../../store/actions";
import dataHeader from "./formarterData";
import "../../../../common/components/TableDrafJS/Table.css";
import BlockComponent from "../../../../common/components/TableDrafJS/BlockComponent";
import createTable from "../../../../common/components/TableDrafJS/createTable";

const cookies = new Cookies();

const ReviewText = ({ changeValueText, dataCPT }) => {
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const authName = cookies.get("cvai-current-user-provider-name");
  const hpi_conditions = useSelector(
    (state) => state.clinicProviderReview.hpi_conditions
  );
  const apointment_ultrasound_id = cookies.get("apointment_ultrasound_id");
  const [ultrasounds_store, set_ultrasounds_store] = useState([]);
  const [loading, setLoading] = useState(true);

  const detailUltrasounds = useSelector(
    (state) => state.common.detailUltrasounds
  );

  const CPT = useSelector((state) => state.common.CPT);

  const ultrasound_finding = useSelector(
    (state) => state.procedureDetail.ultrasound_type
  );

  const ultrasound_value = useSelector(
    (state) => state.common.ultrasound_value
  );

  const contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
  const editorState = EditorState.createWithContent(contentState);
  const [text, setText] = useState(editorState);
  const dispatch = useDispatch();
  //
  const [rowsLeft, set_rowsLeft] = useState([]);

  useEffect(() => {
    let arrayRow = [];
    let arrayLabel = [];
    let ArrayHeader = [];
    const rowsL = [];
    if (ultrasounds_store && ultrasounds_store.length > 0) {
      arrayRow = ultrasounds_store.filter((r) =>
        r.finding_name.includes("Left")
      );
      arrayRow = arrayRow.map((r) => {
        return {
          label: r.finding_name.replace("Left ", ""),
          [`${detailUltrasounds.headers[0]}`]: r[
            `${detailUltrasounds.headers[0]}`
          ],
          [`${detailUltrasounds.headers[1]}`]: r[
            `${detailUltrasounds.headers[1]}`
          ],
          [`${detailUltrasounds.headers[2]}`]: r[
            `${detailUltrasounds.headers[2]}`
          ],
          [`${detailUltrasounds.headers[3]}`]: r[
            `${detailUltrasounds.headers[3]}`
          ],
          [`${detailUltrasounds.headers[4]}`]: r[
            `${detailUltrasounds.headers[4]}`
          ],
          [`${detailUltrasounds.headers[5]}`]: r[
            `${detailUltrasounds.headers[5]}`
          ],
          [`${detailUltrasounds.headers[6]}`]: r[
            `${detailUltrasounds.headers[6]}`
          ],
          [`${detailUltrasounds.headers[7]}`]: r[
            `${detailUltrasounds.headers[7]}`
          ],
        };
      });
    }
    //
    if (detailUltrasounds && detailUltrasounds.headers) {
      if (detailUltrasounds?.headers.some((r) => r == "PSV/EDV")) {
        const arrayFilter = detailUltrasounds?.headers.filter(
          (r) => r !== "PSV/EDV"
        );
        ArrayHeader = ["", "PSV", "EDV", ...arrayFilter];
      } else {
        ArrayHeader = ["", ...detailUltrasounds?.headers];
      }
    }
    //
    for (let i = 0; i < arrayRow.length; i += 1) {
      arrayLabel = {
        ...arrayLabel,
        [0]: ArrayHeader,
        [i + 1]: [
          arrayRow[i]["label"],
          arrayRow[i][`${detailUltrasounds.headers[0]}`],
          arrayRow[i][`${detailUltrasounds.headers[1]}`],
          arrayRow[i][`${detailUltrasounds.headers[2]}`],
          arrayRow[i][`${detailUltrasounds.headers[3]}`],
          arrayRow[i][`${detailUltrasounds.headers[4]}`],
          arrayRow[i][`${detailUltrasounds.headers[5]}`],
          arrayRow[i][`${detailUltrasounds.headers[6]}`],
          arrayRow[i][`${detailUltrasounds.headers[7]}`],
        ],
      };
    }

    if (arrayRow.length + 1 > 1) {
      for (let i = 0; i < arrayRow.length + 1; i += 1) {
        const cols = [];
        for (let j = 0; j < ArrayHeader.length; j += 1) {
          cols.push(arrayLabel && arrayLabel[i] && arrayLabel[i][j]);
        }
        rowsL.push(cols);
      }
    }
    set_rowsLeft(rowsL);
  }, [detailUltrasounds, ultrasounds_store]);

  // table
  const [tableEdits, setTableEdits] = React.useState(Map());
  const blockRenderer = (block) => {
    if (
      block.getType() === "atomic" &&
      detailUltrasounds &&
      ultrasound_finding &&
      ultrasounds_store
    ) {
      return {
        component: (props) => {
          return (
            <BlockComponent
              detailUltrasounds={detailUltrasounds}
              ultrasound_finding={ultrasound_finding}
              ultrasound_value={ultrasounds_store}
              props={props}
            ></BlockComponent>
          );
        },
        editable: true,
      };
    }
    return null;
  };

  const onChange = (editorState) => {
    if (!loading) {
      setText(editorState);
      let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      changeValueText(data);
    }
  };

  useEffect(() => {
    // map value
    if (ultrasound_finding && ultrasound_value) {
      const check = ultrasound_finding.map((r) => {
        return {
          finding_name:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`]["finding_name"]
              ? ultrasound_value[`${r.id}`]["finding_name"]
              : "",
          finding_id:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`]["finding_id"]
              ? ultrasound_value[`${r.id}`]["finding_id"]
              : "",
          appointment_id: apointment_ultrasound_id,
          [`${detailUltrasounds?.headers[0]}`]:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[0]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[0]}`]
              : "",
          [`${detailUltrasounds?.headers[1]}`]:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[1]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[1]}`]
              : "",
          [`${detailUltrasounds?.headers[2]}`]:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[2]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[2]}`]
              : "",
          [`${detailUltrasounds?.headers[3]}`]:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[3]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[3]}`]
              : "",
          [`${detailUltrasounds?.headers[4]}`]:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[4]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[4]}`]
              : "",
          [`${detailUltrasounds?.headers[5]}`]:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[5]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[5]}`]
              : "",
          [`${detailUltrasounds?.headers[6]}`]:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[6]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[6]}`]
              : "",
          [`${detailUltrasounds?.headers[7]}`]:
            ultrasound_value &&
            ultrasound_value[`${r.id}`] &&
            ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[7]}`]
              ? ultrasound_value[`${r.id}`][`${detailUltrasounds?.headers[7]}`]
              : "",
        };
      });
      set_ultrasounds_store([...check]);
    }
  }, [ultrasound_value, ultrasound_finding, detailUltrasounds]);

  useEffect(() => {
    if (
      detailUltrasounds &&
      detailUltrasounds.headers &&
      ultrasounds_store &&
      ultrasounds_store.length > 0
    ) {
      setTableEdits(Map());
      setText(createTable(editorState, detailUltrasounds, ultrasounds_store));
    }
  }, [detailUltrasounds, ultrasounds_store]);
  useEffect(() => {
    let innerHtml = "";
    setLoading(true);
    dispatch(setGlobalLoading(true));
    if (rowsLeft && rowsLeft.length > 0) {
      let data = JSON.stringify(convertToRaw(text.getCurrentContent()));
      for (let i = 0; i < rowsLeft.length; i += 1) {
        innerHtml += `"${i}":${JSON.stringify(rowsLeft[i])}${
          i == rowsLeft.length - 1 ? "" : ","
        }`;
      }
      if (innerHtml) {
        // entityTable
        const [entityTable, ...itemEntityTable] =
          data && data.split(`"entityMap":{`);
        const checkEntityTable = (itemEntityTable || []).map((item) => {
          return item.split(`}}`)[0];
        });
        let provider_text = "";
        let ultrasound_no_text = "";
        if (CPT.doctor && CPT.doctor.length > 0) {
          provider_text = `{"key":"prvnmc","text":"${CPT.doctor}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          // header 8 Provider
          if (!data.includes(provider_text)) {
            data = data.replace(
              `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${provider_text}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        if (CPT.provider_name && CPT.provider_name.length > 0) {
          ultrasound_no_text = `{"key":"ulsnd","text":"${CPT.provider_name}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          if (!data.includes(ultrasound_no_text)) {
            data = data.replace(
              `{"key":"text24","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${ultrasound_no_text}{"key":"text24","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        if (checkEntityTable && checkEntityTable[0]) {
          if (checkEntityTable[0].length > 10) {
            data = data.replace(
              `"entityMap":{${checkEntityTable[0]}}}}}}`,
              `"entityMap":{${checkEntityTable[0]}}}},"1":{"type":"TABLE","mutability":"IMMUTABLE","data":{"row":${rowsLeft.length},"column":${rowsLeft[0].length},"caption":"Left","cell":{${innerHtml}}}}}}`
            );
          }
        }
        // position table
        const [headTable, ...itemPositionTable] =
          data &&
          data.split(
            `{"key":"cmksa","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        const checkPositionTable = (itemPositionTable || []).map((item) => {
          return item.split(
            `{"key":"icd8j2","text":"ICD/INDICATION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"},{"offset":0,"length":14,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (checkPositionTable && checkPositionTable[0]) {
          if (checkPositionTable[0].length > 0) {
            if (checkPositionTable[0].includes(`"type":"atomic"`)) {
              data = data.replace(checkPositionTable[0], "");
              data = data.replace(
                `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${checkPositionTable[0]}`
              );
            }
          }
        }
        // header 10 DATE FINALIZED:
        let date_signed_text = "";
        date_signed_text = `{"key":"keydatesigned","text":"${moment().format(
          "MM/DD/YYYY"
        )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        if (
          date_signed_text &&
          date_signed_text.length > 0 &&
          !data.includes(date_signed_text)
        ) {
          data = data.replace(
            `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${date_signed_text}{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }

        // for loot cpt
        let cpt_text = `{"key":"txtcptspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        if (dataCPT.length > 0) {
          for (let i = 0; i < dataCPT.length; i += 1) {
            cpt_text += `{"key":"cpttxt${i}","text":"${
              dataCPT && dataCPT[i] && dataCPT[i]["cpt_code"]
            } ${
              dataCPT && dataCPT[i] && dataCPT[i]["short_desc"]
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        }
        if (cpt_text.length > 0) {
          // header 11 CPT:
          const [header11, ...item11] =
            data &&
            data.split(
              `{"key":"cpt2c5","text":"CPT","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":3,"style":"BOLD"},{"offset":0,"length":3,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
            );
          const checkHeader11 = (item11 || []).map((item) => {
            return item.split(
              `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            )[0];
          });
          if (checkHeader11 && checkHeader11[0] && checkHeader11[0] != "") {
            data = data.replace(
              `${checkHeader11[0]}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
              `${cpt_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
            );
          } else {
            data = data.replace(
              `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
              `${cpt_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
            );
          }
        }

        // for loot icd
        let icd_text = `{"key":"txticdspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        if (CPT && CPT.icd_codes && CPT.icd_codes.length > 0) {
          for (let i = 0; i < CPT.icd_codes.length; i += 1) {
            icd_text += `{"key":"icdtxt${i}","text":"${
              CPT.icd_codes &&
              CPT.icd_codes[i] &&
              CPT.icd_codes[i]["icd_code_name"]
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        }
        if (!data.includes(icd_text) && icd_text.length > 0) {
          // header 12 ICD:
          data = data.replace(
            `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${icd_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        // header 3 Right Result:
        let result_r = "";
        if (CPT && CPT.right_result) {
          result_r = `{"key":"rrsdd","text":"${CPT.right_result}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        } else {
          result_r = "";
        }
        if (result_r && result_r.length > 0 && !data.includes(result_r)) {
          data = data.replace(
            `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${result_r}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }

        // header 4 Left Result:
        let result_l = "";
        if (CPT && CPT.left_result) {
          result_l = `{"key":"lfsdd","text":"${CPT.left_result}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        } else {
          result_l = "";
        }
        if (result_l && result_l.length > 0 && !data.includes(result_l)) {
          data = data.replace(
            `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${result_l}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }

        // header 6 Right CONCLUSION::
        let conclision_r = "";
        if (CPT && CPT.right_conclusion) {
          conclision_r = `{"key":"rcfcsn","text":"${CPT.right_conclusion}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        } else {
          conclision_r = "";
        }
        if (
          conclision_r &&
          conclision_r.length > 0 &&
          !data.includes(conclision_r)
        ) {
          data = data.replace(
            `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${conclision_r}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }

        // header 7 Left CONCLUSION::
        let conclision_l = "";
        if (CPT && CPT.left_conclusion) {
          conclision_l = `{"key":"lfscsn","text":"${CPT.left_conclusion}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        } else {
          conclision_l = "";
        }
        if (
          conclision_l &&
          conclision_l.length > 0 &&
          !data.includes(conclision_l)
        ) {
          data = data.replace(
            `{"key":"text7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${conclision_l}{"key":"text7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }

        // header 13 DISCUSSION:
        if (
          detailUltrasounds &&
          detailUltrasounds.footer_template &&
          detailUltrasounds.footer_template.includes("blocks")
        ) {
          let valueImportFooter = `{"key":"txtdisspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${detailUltrasounds.footer_template.replace(
            `{"blocks":[`,
            ""
          )}`;
          if (valueImportFooter.includes(`"entityMap":[{`)) {
            const [enti, ...entis] =
              valueImportFooter && valueImportFooter.split(`],"entityMap":[{"`);
            const checkEnti = (entis || []).map((item) => {
              return item.split(`]}`)[0];
            });
            if (valueImportFooter.includes(`],"entityMap":{}}`)) {
              valueImportFooter = valueImportFooter.replace(
                `],"entityMap":{}}`,
                ""
              );
            }
            if (checkEnti && checkEnti[0].length > 0) {
              valueImportFooter = valueImportFooter.replace(
                `],"entityMap":[{"${checkEnti[0]}]}`,
                ``
              );
            }
            if (valueImportFooter.includes(`],"entityMap":[]}`)) {
              valueImportFooter = valueImportFooter.replace(
                `],"entityMap":[]}`,
                ""
              );
            }
          } else {
            valueImportFooter = valueImportFooter.replace(
              `],"entityMap":{}}`,
              ""
            );
          }
          if (valueImportFooter && valueImportFooter.length > 0) {
            data = data.replace(
              `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${valueImportFooter},{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        const contentState = convertFromRaw(JSON.parse(data));
        const editorState = EditorState.createWithContent(contentState);
        changeValueText(data);
        setText(editorState);
        if (data && data.length > 5000 && CPT.icd_codes && CPT.cpt_codes) {
          setLoading(false);
          dispatch(setGlobalLoading(false));
        }
      }
    } else {
      let data = JSON.stringify(convertToRaw(text.getCurrentContent()));
      // position table
      const [headTable, ...itemPositionTable] =
        data &&
        data.split(
          `{"key":"cmksa","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      const checkPositionTable = (itemPositionTable || []).map((item) => {
        return item.split(
          `{"key":"icd8j2","text":"ICD/INDICATION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"},{"offset":0,"length":14,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        )[0];
      });
      // for loot cpt
      let cpt_text = `{"key":"txtcptspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      if (dataCPT.length > 0) {
        for (let i = 0; i < dataCPT.length; i += 1) {
          cpt_text += `{"key":"cpttxt${i}","text":"${
            dataCPT && dataCPT[i] && dataCPT[i]["cpt_code"]
          } ${
            dataCPT && dataCPT[i] && dataCPT[i]["short_desc"]
          }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }

      if (cpt_text.length > 0) {
        // header 11 CPT:
        const [header11, ...item11] =
          data &&
          data.split(
            `{"key":"cpt2c5","text":"CPT","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":3,"style":"BOLD"},{"offset":0,"length":3,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHeader11 = (item11 || []).map((item) => {
          return item.split(
            `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        if (checkHeader11 && checkHeader11[0] && checkHeader11[0] != "") {
          data = data.replace(
            `${checkHeader11[0]}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${cpt_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        } else {
          data = data.replace(
            `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${cpt_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        // for loot icd
        let icd_text = `{"key":"txticdspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        if (CPT && CPT.icd_codes && CPT.icd_codes.length > 0) {
          for (let i = 0; i < CPT.icd_codes.length; i += 1) {
            icd_text += `{"key":"icdtxt${i}","text":"${
              CPT.icd_codes &&
              CPT.icd_codes[i] &&
              CPT.icd_codes[i]["icd_code_name"]
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        }
        if (!data.includes(icd_text) && icd_text.length > 0) {
          // header 12 ICD:
          data = data.replace(
            `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${icd_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        const contentState = convertFromRaw(JSON.parse(data));
        const editorState = EditorState.createWithContent(contentState);
        changeValueText(data);
        setText(editorState);
        if (data && data.length > 5000 && CPT.icd_codes && CPT.cpt_codes) {
          setLoading(false);
          dispatch(setGlobalLoading(false));
        }
      }
      if (checkPositionTable && checkPositionTable[0]) {
        if (checkPositionTable[0].length > 0) {
          if (checkPositionTable[0].includes(`"type":"atomic"`)) {
            data = data.replace(checkPositionTable[0], "");
            data = data.replace(
              `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${checkPositionTable[0]}`
            );
            let provider_text = "";
            let ultrasound_no_text = "";
            if (CPT.doctor && CPT.doctor.length > 0) {
              provider_text = `{"key":"prvnmc","text":"${CPT.doctor}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              // header 8 Provider
              if (!data.includes(provider_text)) {
                data = data.replace(
                  `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                  `${provider_text}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                );
              }
            }
            if (authName && authName.length > 0) {
              ultrasound_no_text = `{"key":"ulsnd","text":"${authName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              if (!data.includes(ultrasound_no_text)) {
                data = data.replace(
                  `{"key":"text24","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                  `${ultrasound_no_text}{"key":"text24","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                );
              }
            }
            // header 10 DATE FINALIZED:
            let date_signed_text = "";
            date_signed_text = `{"key":"keydatesigned","text":"${moment().format(
              "MM/DD/YYYY"
            )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            if (
              date_signed_text &&
              date_signed_text.length > 0 &&
              !data.includes(date_signed_text)
            ) {
              data = data.replace(
                `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
                `${date_signed_text}{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
              );
            }
            // header 3 Right Result:
            let result_r = "";
            if (CPT && CPT.right_result) {
              result_r = `{"key":"rrsdd","text":"${CPT.right_result}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            } else {
              result_r = "";
            }
            if (result_r && result_r.length > 0 && !data.includes(result_r)) {
              data = data.replace(
                `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
                `${result_r}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
              );
            }

            // header 4 Left Result:
            let result_l = "";
            if (CPT && CPT.left_result) {
              result_l = `{"key":"lfsdd","text":"${CPT.left_result}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            } else {
              result_l = "";
            }
            if (result_l && result_l.length > 0 && !data.includes(result_l)) {
              data = data.replace(
                `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
                `${result_l}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
              );
            }

            // header 6 Right CONCLUSION::
            let conclision_r = "";
            if (CPT && CPT.right_conclusion) {
              conclision_r = `{"key":"rcfcsn","text":"${CPT.right_conclusion}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            } else {
              conclision_r = "";
            }
            if (
              conclision_r &&
              conclision_r.length > 0 &&
              !data.includes(conclision_r)
            ) {
              data = data.replace(
                `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
                `${conclision_r}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
              );
            }

            // header 7 Left CONCLUSION::
            let conclision_l = "";
            if (CPT && CPT.left_conclusion) {
              conclision_l = `{"key":"lfscsn","text":"${CPT.left_conclusion}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            } else {
              conclision_l = "";
            }
            if (
              conclision_l &&
              conclision_l.length > 0 &&
              !data.includes(conclision_l)
            ) {
              data = data.replace(
                `{"key":"text7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
                `${conclision_l}{"key":"text7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
              );
            }

            // for loot icd
            let icd_text = `{"key":"txticdspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            if (CPT && CPT.icd_codes && CPT.icd_codes.length > 0) {
              for (let i = 0; i < CPT.icd_codes.length; i += 1) {
                icd_text += `{"key":"icdtxt${i}","text":"${
                  CPT.icd_codes &&
                  CPT.icd_codes[i] &&
                  CPT.icd_codes[i]["icd_code_name"]
                }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              }
            }
            if (!data.includes(icd_text) && icd_text.length > 0) {
              // header 12 ICD:
              data = data.replace(
                `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
                `${icd_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
              );
            }
            // header 13 DISCUSSION:
            if (
              detailUltrasounds &&
              detailUltrasounds.footer_template &&
              detailUltrasounds.footer_template.includes("blocks")
            ) {
              let valueImportFooter = `{"key":"txtdisspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${detailUltrasounds.footer_template.replace(
                `{"blocks":[`,
                ""
              )}`;
              if (valueImportFooter.includes(`"entityMap":[{`)) {
                const [enti, ...entis] =
                  valueImportFooter &&
                  valueImportFooter.split(`],"entityMap":[{"`);
                const checkEnti = (entis || []).map((item) => {
                  return item.split(`]}`)[0];
                });
                if (valueImportFooter.includes(`],"entityMap":{}}`)) {
                  valueImportFooter = valueImportFooter.replace(
                    `],"entityMap":{}}`,
                    ""
                  );
                }
                if (checkEnti && checkEnti[0].length > 0) {
                  valueImportFooter = valueImportFooter.replace(
                    `],"entityMap":[{"${checkEnti[0]}]}`,
                    ``
                  );
                }
                if (valueImportFooter.includes(`],"entityMap":[]}`)) {
                  valueImportFooter = valueImportFooter.replace(
                    `],"entityMap":[]}`,
                    ""
                  );
                }
              } else {
                valueImportFooter = valueImportFooter.replace(
                  `],"entityMap":{}}`,
                  ""
                );
              }
              if (valueImportFooter && valueImportFooter.length > 0) {
                data = data.replace(
                  `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                  `${valueImportFooter},{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                );
              }
            }

            const contentState = convertFromRaw(JSON.parse(data));
            const editorState = EditorState.createWithContent(contentState);
            changeValueText(data);
            if (data && data.length > 5000 && CPT.icd_codes && CPT.cpt_codes) {
              setLoading(false);
              dispatch(setGlobalLoading(false));
            }
            setText(editorState);
          }
        }
      }
    }
  }, [rowsLeft, CPT, dataCPT, detailUltrasounds]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="RichEditor-editor">
        <Editor
          toolbar={{
            options: ["inline", "list"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          editorStyle={{
            border: "1px solid #f2f2f2",
            padding: "10px",
            height: "100%",
            backgroundColor: "#fff",
          }}
          placeholder="Patient Review"
          editorState={loading ? EditorState.createEmpty() : text}
          blockRendererFn={blockRenderer}
          readOnly={tableEdits.count()}
          onEditorStateChange={onChange}
        />
      </div>
    </div>
  );
};

export default ReviewText;
