import React, { useState, useEffect, useRef } from "react";
import { Icon, Input, Select } from "antd";
import ScrollWrapper from "../ScrollBar";
import Loading from "../Loading";
import { SearchSVG, ExpandSVG, CheckSVG } from "../icons/SVGIcon";
import DataTable from "../DataTable/WhiteHeaderTable";
import { useOutsideDetect } from "../../utils";
import "./style.scss";
import EditableFormInput from "../EditableText/EditPlanOrder";
//import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import { validateSteps, validateField } from "../../../services/validations";

const { Option } = Select;
const { Search } = Input;
const selectArray = [
  { value: "ASAP", id: "asap" },
  { value: "Within 2 weeks", id: "2_week" },
  { value: "3 months", id: "3_month" },
  { value: "6 months", id: "6_month" },
  { value: "1 year", id: "1_year" },
];

const AutoCompleteComponentPlanOder = ({
  rows,
  options,
  query,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keyLabel,
  keyValue,
  keyType,
  selectItem,
  removeItem,
  disableOptions,
  onclickRow,
  activeIndex,
  isSelectRow,
  handleValueSelect,
  onChangeSelect,
  valueSelected,
  processArray,
  keyIdPlan,
  isWhite,
  sideHandle,
  onChangeSelectSide,
  isGeneral,
  keyCheck,
  hasSideHandle,
  enableError,
  isLocked,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedIdPlan, setSelectedIdPlan] = useState("");

  const [valueSelectedGlobal, setValueSelectedGlobal] = useState({});
  const [selectRow, setSelectRow] = useState(false);
  const [valueHandleSelect, setValueHandleSelect] = useState([]);
  const [removeable, setRemoveable] = useState(-1);
  const [hasSide, setHasSide] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const data = rows && rows[keyIdPlan] ? rows[keyIdPlan] : [];
  const optionData = options ? options : [];
  let emptyRows = [];
  if (data.length < 5) {
    emptyRows = new Array(4 - data.length).fill({ label: "" });
  }

  useEffect(() => {
    setSelectedIdPlan(keyIdPlan);
  }, [keyIdPlan]);

  useEffect(() => {
    setValueSelectedGlobal(valueSelected[keyIdPlan] || []);
  }, [valueSelected]);

  useEffect(() => {
    setValueHandleSelect(handleValueSelect[keyIdPlan] || []);
  }, [handleValueSelect]);

  useEffect(() => {
    setSelectRow(isSelectRow);
  }, [isSelectRow]);

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setSelected(row[keyLabel]);
    if (row.has_side && row.has_side === true) {
      setHasSide(true);
    } else {
      setHasSide(false);
    }
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keyLabel]);
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (selectItem) {
      selectItem(
        selected,
        selectedValue,
        selectedType,
        selectedIdPlan,
        hasSide
      );
    } else {
      if (addItem) {
        addItem();
      }
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target.value);
    if (handleSearch) {
      handleSearch(e.target.value);
    }
  };

  const clickHandle = (row, index, type, rowAll) => {
    if (onclickRow) {
      onclickRow(row, index, type, rowAll);
    }
  };

  const selectHandle = (value, index, type) => {
    const arrayMap = valueSelectedGlobal.map((r, i) => {
      if (index === i) {
        return value;
      }
      return r;
    });
    setValueSelectedGlobal(arrayMap);
    onChangeSelect(value, index, type);
  };

  const selectHandleSide = (value, index, type) => {
    onChangeSelectSide(value, index, type);
  };

  return (
    <div
      className="atuoComplete"
      style={{ padding: "1rem", background: "#fff" }}
    >
      <div className="atuoComplete__filter">
        <div
          ref={ref}
          className={`atuoComplete__filter-search ${
            disableSearchIcon ? "disable-icon" : ""
          } ${isWhite ? "white" : ""}`}
        >
          <Search
            disabled={isLocked === 1 ? true : false}
            value={search}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{ width: "100%" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        <div
          className={`atuoComplete__filter-action ${
            isLocked === 1 ? "disabled" : ""
          }`}
        >
          <button
            className="atuoComplete__filter-add"
            onClick={addSelectedItem}
            disabled={isLocked}
          >
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
        {!disableOptions && openResult && !!search && (
          <div
            ref={ref1}
            className="atuoComplete__filter-result"
            style={{ left: "1rem", top: "1rem" }}
          >
            <div className="atuoComplete__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper css="no-padding">
                {!!optionData.length &&
                  optionData.map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`empty-${title}-${index}-${keyIdPlan}`}
                      onClick={() => handleSelectItem(row)}
                    >
                      <p style={{ paddingLeft: "28px" }}>{row[keyLabel]}</p>
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>

      <div
        className="atuoComplete__result fullContainer"
        style={{ height: "calc(15vh + 58px)", background: "#fff" }}
      >
        <DataTable
          title="plan_orders_autocomplete"
          isNormal={true}
          keyCheck={keyCheck}
          activeIndex={activeIndex}
          noneAllActive={true}
          isNotFirstactive={true}
          labels={isGeneral ? ["Plan"] : ["Plan", "Side", "Time to Schedule"]}
          widths={isGeneral ? ["100%"] : ["40%", "30%", "30%"]}
          showIcon={true}
          columns={
            isGeneral
              ? [{ sortable: true, key: "row" }]
              : [
                  { sortable: true, key: "row" },
                  { sortable: false, key: "side" },
                  { sortable: false, key: "schedule" },
                ]
          }
          handleDelete={(value, index) =>
            removeItem(value.data, index, keyIdPlan)
          }
          handleClickRow={(row, index) =>
            clickHandle(row.data, index, keyIdPlan, row)
          }
          rowData={(data || []).map((item, index) => {
            if (isGeneral) {
              return {
                key: item?.key,
                id: item?.id,
                data: item,
                isShowPaddingHeight: enableError,
                keyIdPlan: keyIdPlan,
                activeIndex: activeIndex,
                processCheck:
                  processArray &&
                  processArray[keyIdPlan] &&
                  processArray[keyIdPlan][index],
                row: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {processArray &&
                    processArray[keyIdPlan] &&
                    processArray[keyIdPlan][index] ? (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#24A148"
                        />
                      </span>
                    ) : (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Icon
                          type="question-circle"
                          theme="twoTone"
                          twoToneColor="#DA1E28"
                        />
                      </span>
                    )}
                    <p style={{ marginLeft: selectRow ? 13 : 12 }}>{item}</p>
                  </div>
                ),
              };
            } else {
              return {
                key: item?.key,
                id: item?.id,
                data: item,
                keyIdPlan: keyIdPlan,
                activeIndex: activeIndex,
                isShowPaddingHeight: enableError,
                processCheck:
                  processArray &&
                  processArray[keyIdPlan] &&
                  processArray[keyIdPlan][index],
                side: (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {hasSideHandle[keyIdPlan] &&
                    hasSideHandle[keyIdPlan][index] &&
                    hasSideHandle[keyIdPlan][index] === true ? (
                      <EditableFormInput
                        label={"Side"}
                        handleChange={(value) => {
                          selectHandleSide(value, index, keyIdPlan);
                        }}
                        placeholder="Side"
                        value={
                          sideHandle[keyIdPlan] && sideHandle[keyIdPlan][index]
                            ? sideHandle[keyIdPlan][index]
                            : undefined
                        }
                        isError={validateField(
                          "text",
                          sideHandle[keyIdPlan] && sideHandle[keyIdPlan][index]
                            ? sideHandle[keyIdPlan][index]
                            : undefined
                        )}
                        type={"check"}
                        fullWidth={true}
                        helperText={"Side is Required!"}
                        enableErrorText={enableError}
                        required={true}
                        style={{ width: "100%" }}
                        allowClear={true}
                        optionKey={"name"}
                        valueKey={"name"}
                        disabled={isLocked}
                        options={[
                          { name: "Left" },
                          { name: "Right" },
                          { name: "Bilateral" },
                          { name: "Not applicable" },
                        ]}
                      />
                    ) : (
                      <span style={{ minWidth: "26px" }}></span>
                    )}
                  </div>
                ),
                row: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {processArray &&
                    processArray[keyIdPlan] &&
                    processArray[keyIdPlan][index] ? (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          paddingLeft: "4px",
                        }}
                      >
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#24A148"
                        />
                      </span>
                    ) : (
                      <span style={{ minWidth: "26px" }}></span>
                    )}
                    <p style={{ marginLeft: selectRow ? 13 : 12 }}>{item}</p>
                  </div>
                ),
                schedule:
                  selectRow && valueHandleSelect ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <EditableFormInput
                        label={"Urgency"}
                        handleChange={(value) => {
                          selectHandle(value, index, keyIdPlan);
                        }}
                        value={
                          valueSelectedGlobal[index]
                            ? valueSelectedGlobal[index]
                            : undefined
                        }
                        isError={validateField(
                          "text",
                          valueSelectedGlobal[index]
                            ? valueSelectedGlobal[index]
                            : undefined
                        )}
                        fullWidth={true}
                        helperText={"Urgency is Required!"}
                        enableErrorText={enableError}
                        required={true}
                        type={"check"}
                        style={{ width: "100%" }}
                        allowClear={true}
                        optionKey={"id"}
                        valueKey={"value"}
                        options={selectArray}
                        disabled={isLocked}
                      />
                    </div>
                  ) : undefined,
              };
            }
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>
    </div>
  );
};

export default AutoCompleteComponentPlanOder;
