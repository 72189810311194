import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import YesNo from "../../../common/components/YesNo";
import {
  saveProcedureQuestion,
  setProcedureData,
  setGlobalLoading, getSingleProcedure,
} from "../../../store/actions";

const QuestionnaireStep = ({ handleContinue, checkQuestion }) => {

  const resource = useSelector((state) => state.procedure.resource);
  const procedure = useSelector((state) => state.procedure.procedure);
  const loading = useSelector((state) => state.procedure.loadingSchedule);
  const [dataFetched, setDataFetched] = useState(false);
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState({
    taking_metformin: -1,
    taking_eliquis: -1,
    taking_coumadin: -1,
    taking_plavix: -1,
    receive_coronary_stent: -1,
  });

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (procedure && procedure.patient) {
      const intialQuestions = {
        taking_metformin:
          procedure.patient.taking_metformin !== null
            ? procedure.patient.taking_metformin
            : -1,
        taking_eliquis:
          procedure.patient.taking_eliquis !== null
            ? procedure.patient.taking_eliquis
            : -1,
        taking_coumadin:
          procedure.patient.taking_coumadin !== null
            ? procedure.patient.taking_coumadin
            : -1,
        taking_plavix:
          procedure.patient.taking_plavix !== null
            ? procedure.patient.taking_plavix
            : -1,
        receive_coronary_stent:
          procedure.patient.receive_coronary_stent !== null
            ? procedure.patient.receive_coronary_stent
            : -1,
      };
      setQuestions(intialQuestions);
      checkQuestion(intialQuestions);
      setDataFetched(true);
    }
  }, [procedure]);

  // const onSaveSchedule = async (
  //   questionsCheck,
  // ) => {

  //   const params = {
  //     ...questionsCheck,
  //   };

  //   await dispatch(setProcedureData("loadingSchedule", true));
  //   await dispatch(saveProcedureQuestion(resource.id, params));
  //   await dispatch(getSingleProcedure(resource.id));
  // };



  const handleQuestions = (type) => (value) => {
    const questionnaire = { ...questions, [type]: value };
    setQuestions(questionnaire);
    checkQuestion(questionnaire);
  };

  return (
    <>
      <div className="resourceContent pr-7px" style={{ position: "relative" }}>

        <div
          className="resourceInfo between half-pannel"
          style={{ paddingLeft: "0.5rem" }}
        >
          <div style={{ width: "calc(100% - 180px)" }}>
            Is patient taking Metformin?{" "}
          </div>
          <YesNo
            option={questions.taking_metformin}
            setOption={handleQuestions("taking_metformin")}
          />
        </div>
        <div
          className="resourceInfo between half-pannel"
          style={{ paddingLeft: "0.5rem" }}
        >
          <div style={{ width: "calc(100% - 180px)" }}>
            Is patient on Eliquis or Xarelto?{" "}
          </div>
          <YesNo
            option={questions.taking_eliquis}
            setOption={handleQuestions("taking_eliquis")}
          />
        </div>
        <div
          className="resourceInfo between half-pannel"
          style={{ paddingLeft: "0.5rem" }}
        >
          <div style={{ width: "calc(100% - 180px)" }}>
            Is patient on Coumadin?{" "}
          </div>
          <YesNo
            option={questions.taking_coumadin}
            setOption={handleQuestions("taking_coumadin")}
          />
        </div>
        <div
          className="resourceInfo between half-pannel"
          style={{ paddingLeft: "0.5rem" }}
        >
          <div style={{ width: "calc(100% - 180px)" }}>
            Is patient on Plavix?{" "}
          </div>
          <YesNo
            option={questions.taking_plavix}
            setOption={handleQuestions("taking_plavix")}
          />
        </div>
        <div
          className="resourceInfo between half-pannel"
          style={{ paddingLeft: "0.5rem" }}
        >
          <div style={{ width: "calc(100% - 180px)" }}>
            Has patient received coronary stent within the past 1 year{" "}
          </div>
          <YesNo
            option={questions.receive_coronary_stent}
            setOption={handleQuestions("receive_coronary_stent")}
          />
        </div>

        <div
          className="actionContent"
          style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}
        />
      </div>
    </>
  );
};

export default QuestionnaireStep;
