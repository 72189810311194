import { createReducer } from "redux-create-reducer";
import moment from "moment";

import AT from "../actionTypes";

const initialSelectedPatientResource = {
  first_name: "",
  last_name: "",
  date_of_birth: "",
  phone: "",
  secondary_phone: "",
  zipcode: "",
  email: "",
  ssn: "",
  address: "",
  apt_number: "",
  state: "",
  city: "",
  medical_id: "",
  policy_number: "",
  notes: [],
};

const initialState = {
  schedules: [],
  providers: [],
  loadingSchedules: true,
  loadingResource: false,
  selectedPatientResource: {}, // initialSelectedPatientResource
  allNotes: [],
  loadingNotes: false,
  allNotesDeleted: [],
  isInsuranceVerified: false,
  hasInsurance: false,
  coverages: [],
  data_template_review: [],
  data_complication: "",
};

//Get patient schedules
const GET_PATIENT_SCHEDULES_STARTED = `${AT.GET_PATIENT_SCHEDULES}_STARTED`;
const GET_PATIENT_SCHEDULES_SUCCEEDED = `${AT.GET_PATIENT_SCHEDULES}_SUCCEEDED`;
const SET_DATA_TEMPLATE_REVIEW_SUCCEEDED = `${AT.SET_DATA_TEMPLATE_REVIEW}_SUCCEEDED`;
const SET_DATA_COMPLICATION_SUCCEEDED = `${AT.SET_DATA_COMPLICATION}_SUCCEEDED`;
const CHOOSE_SELECTED_PATIENT_RESOURCE_BY_RANDOM_SUCCEEDED = `${AT.CHOOSE_SELECTED_PATIENT_RESOURCE_BY_RANDOM}_SUCCEEDED`;

export default createReducer(initialState, {
  [SET_DATA_COMPLICATION_SUCCEEDED](state, action) {
    return {
      ...state,
      data_complication: action.payload || "",
    };
  },
  [SET_DATA_TEMPLATE_REVIEW_SUCCEEDED](state, action) {
    return {
      ...state,
      data_template_review: [...(action.payload || [])],
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.CHOOSE_PATIENT_ON_SCHEDULES}_SUCCEEDED`](state, action) {
    return {
      ...state,
      selectedPatientResource: action.payload,
      allNotes: [],
      allNotesDeleted: [],
      isInsuranceVerified: false,
      hasInsurance: false,
      coverages: [],
    };
  },
  [GET_PATIENT_SCHEDULES_SUCCEEDED](state, action) {
    action.payload.elements.map((element) => {
      var newStart = moment(element.start, "YYYY-MM-DD HH:mm:ss");
      var newEnd = moment(element.end, "YYYY-MM-DD HH:mm:ss");
      element.start = newStart.toDate();
      element.end = newEnd.toDate();
      return element;
    });
    return Object.assign({}, state, {
      schedules: action.payload.elements,
      loadingSchedules: false,
      providers: action.payload.providers,
    });
  },
  [GET_PATIENT_SCHEDULES_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingSchedules: true,
      selectedPatientResource: {},
    });
  },
  [CHOOSE_SELECTED_PATIENT_RESOURCE_BY_RANDOM_SUCCEEDED](state, action) {
    const selectedPatientResource = action.payload[0];
    return {
      ...state,
      selectedPatientResource,
    };
  },
  [`${AT.GET_PATIENT_NOTES}_SUCCEEDED`](state, action) {
    let allNotes = action.payload.data;
    if (allNotes === "") {
      return state;
    }
    allNotes.map((notes) => {
      notes["status"] = "original";
    });
    return {
      ...state,
      allNotes,
      loadingNotes: false,
    };
  },
  [`${AT.ADD_PATIENT_NOTE}`](state, action) {
    let newNotes = [...state.allNotes];
    newNotes.push({
      status: "added",
      patient_id: action.payload.patient_id,
      notes: action.payload.notes,
    });
    return {
      ...state,
      allNotes: newNotes,
    };
  },
  [`${AT.DELETE_PATIENT_NOTE}`](state, action) {
    let allNotes = [...state.allNotes];
    let allNotesDeleted = [...state.allNotesDeleted];
    const { rowIndex } = action.payload;
    if (allNotes[rowIndex].status === "original") {
      allNotes[rowIndex].status = "deleted";
      allNotesDeleted.push(allNotes[rowIndex]);
    }
    allNotes.splice(rowIndex, 1);

    return {
      ...state,
      allNotes,
      allNotesDeleted,
    };
  },
  [`${AT.SEARCH_PATIENT_SCHEDULES}`](state, action) {
    let newSchedules = [];
    const { searchValue } = action.payload;
    for (let schedule of state.schedules) {
      if (searchValue === "" || schedule.title.includes(searchValue)) {
        schedule.isHighlight = true;
      } else {
        schedule.isHighlight = false;
      }
      newSchedules.push(schedule);
    }

    return {
      ...state,
      schedules: newSchedules,
    };
  },
  [`${AT.PATIENT_SCHEDULE_VERIFY_INSURANCE}_SUCCEEDED`](state, action) {
    const result = action.payload;

    if (result && result.coverages.length > 0) {
      return {
        ...state,
        coverages: result.coverages,
        isInsuranceVerified: true,
        hasInsurance: true,
      };
    } else {
      return {
        ...state,
        coverages: result.coverages,
        isInsuranceVerified: true,
        hasInsurance: false,
      };
    }
    return state;
  },
  [`${AT.PATIENT_SCHEDULE_CONFIRM_CHECKIN}_SUCCEEDED`](state, action) {
    // --- should call GET_PATIENT_SCHEDULES again ----

    return state;
  },
  [`${AT.CHOOSE_SELECTED_PATIENT_RESOURCE}_STARTED`](state, action) {
    return {
      ...state,
      loadingResource: true,
    };
  },
  [`${AT.CHOOSE_SELECTED_PATIENT_RESOURCE}_SUCCEEDED`](state, action) {
    return {
      ...state,
      selectedPatientResource: action.payload,
      allNotes: [],
      allNotesDeleted: [],
      isInsuranceVerified: false,
      coverages: [],
      hasInsurance: false,
      loadingResource: false,
    };
  },
});
