import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import DataTable from "../../../../common/components/DataTable/DataTable";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import {
  getCurrentUserInfo,
  getEvaluationsTagert,
  getEvaluationsUser,
} from "../../../../store/actions";
import EvaluationsSidebar from "./EvaluationsSidebar";
import { StaticResource } from "../../constant";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const cookies = new Cookies();

const Evaluations = ({ data, title, loading }) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [activeIndex, setRow] = useState(-1);

  const evaluationsTagert = useSelector(
    (state) => state.common.evaluationsTagert
  );
  const evaluationsUser = useSelector((state) => state.common.evaluationsUser);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);

  const authId = cookies.get("cvai-current-user");

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  const tableRows = evaluationsTagert ? evaluationsTagert : [];

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  }

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (row, index) => () => {
    setRow(index);
  };

  const fetchList = () => {
    dispatch(getEvaluationsUser(authId));
    dispatch(getEvaluationsTagert(authId));
  };

  const fetchCurrentUser = () => {
    if (authId) {
      dispatch(getCurrentUserInfo(authId));
      handleSidebar()
    }
  };

  const fetchData = () => {
    fetchList();
    fetchCurrentUser();
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_evaluations"
                tableWidth={920}
                labels={["Evaluation Date", "Type", "Reason", "Points", "Results"]}
                widths={["24%", "18%", "22%", "18%", "18%"]}
                columns={[
                  { sortable: false, key: "created_at" },
                  { sortable: false, key: "type" },
                  { sortable: false, key: "reason" },
                  { sortable: false, key: "points" },
                  { sortable: false, key: "corective_actions" },
                ]}
                sorts={["","","","",""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row.id,
                  created_at: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ minWidth: "26px" }} />
                      <p>{row.created_at}</p>
                    </div>
                  ),
                  type: "Variance",
                  reason: row.variance_type,
                  points: row.points,
                  corective_actions: row.corective_actions,
                }))}
                fetchList={fetchData}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {activeIndex >= 0 && evaluationsTagert.length > 0 && (
        <EvaluationsSidebar
          ref={sidebarRef}
          currentUserInfo={currentUserInfo}
          evaluationsTagertDetail={evaluationsTagert[activeIndex]}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
      {activeIndex >= 0 && currentUserInfo && evaluationsTagert.length > 0 && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default Evaluations;
