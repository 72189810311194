import Axios from "axios";
import Cookies from "universal-cookie";
import { BASE_API, INVENTORY_API } from "../common/constants/constants";
import store from "../store";
import {
  setGlobalErrorMessage,
  getGlobalMessages as getGlobalMessagesAction,
} from "../store/actions";

const cookies = new Cookies();
const dispatch = store.dispatch;

const getAxiosForRedux = (url, payload = {}, params = {}) => {
  const authToken = cookies.get("cvai-auth-token");
  return Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    params,
  })
    .then((result) => {
      if (!!Object.keys(payload).length) {
        return { data: result.data, ...payload };
      }
      return result.data;
    })
    .catch((error) => {
      // alert(error);
      if (payload.pending) {
        console.log("pending data error");
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message === ""
      ) {
        dispatch(
          setGlobalErrorMessage({
            message: `Error ${error.response.status}`,
            status: "failed",
          })
        );
      } else if (error && error.response && error.response.data) {
        dispatch(setGlobalErrorMessage(error.response.data));
      }
    });
};

const deleteAxios = (url, payload = {}) => {
  const authToken = cookies.get("cvai-auth-token");
  return Axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  })
    .then((result) => {
      if (!!Object.keys(payload).length) {
        return { data: result.data, ...payload };
      }
      return result.data;
    })
    .catch((error) => {
      // alert(error);
      if (error.response.data && error.response.data.message === "") {
        dispatch(
          setGlobalErrorMessage({
            message: `Error ${error.response.status}`,
            status: "failed",
          })
        );
      } else {
        dispatch(setGlobalErrorMessage(error.response.data));
      }
    });
};

const postAxiosForRedux = (url, params, headers = {}, payload = {}) => {
  const authToken = cookies.get("cvai-auth-token");
  return Axios.post(url, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
      ...headers,
    },
  })
    .then((result) => {
      if (payload.data) {
        return { data: result.data, ...payload.data };
      }
      return result.data;
    })
    .catch((error) => {
      if (error.response.data && error.response.data.message === "") {
        dispatch(
          setGlobalErrorMessage({
            message: `Error ${error.response.status}`,
            status: "failed",
          })
        );
      } else {
        dispatch(setGlobalErrorMessage(error.response.data));
      }
    });
};

const putAxiosForRedux = (url, params, payload = {}) => {
  const authToken = cookies.get("cvai-auth-token");
  return Axios.put(url, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  })
    .then((result) => {
      if (payload.data) {
        return { data: result.data, ...payload.data };
      }
      return result.data;
    })
    .catch((error) => {
      dispatch(setGlobalErrorMessage(error.response.data));
    });
};

const getAxios = (url) => {
  const authToken = cookies.get("cvai-auth-token");

  return Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  }).catch((error) => {
    dispatch(setGlobalErrorMessage(error.response.data));
  });
};

const putAxios = (url, params) => {
  const authToken = cookies.get("cvai-auth-token");

  return Axios.put(url, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      dispatch(setGlobalErrorMessage(error.response.data));
    });
};

const postAxios = (url, params, headers = {}) => {
  const authToken = cookies.get("cvai-auth-token");

  return Axios.post(url, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
      ...headers,
    },
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      dispatch(setGlobalErrorMessage(error.response.data));
    });
};

export const postProviderSaveSchedule = (id, params) => {
  const url = `${BASE_API}providers/${id}/save_schedule`;
  return postAxios(url, params);
};

export const deleteProviderSchedule = (id, params) => {
  const url = `${BASE_API}providers/${id}/delete_schedule`;
  return postAxios(url, params);
};

export const saveProcedureCondition = (id, params) => {
  const url = `${BASE_API}providers/${id}/save_procedure_condition`;
  return postAxios(url, params);
};

export const getCurrentUserInfo = () => {
  const url = `${BASE_API}user`;
  return getAxios(url).then((result) => {
    if (result && result.data) {
      cookies.set("cvai-current-user-name", result.data.name);
      cookies.set("cvai-current-user", result.data.id);
      cookies.set("cvai-current-user_status", result.data.status);
      return result.data;
    }
  });
};

export const getCurrentUser = () => {
  const url = `${BASE_API}user`;
  return getAxios(url).then((result) => {
    if (result && result.data) {
      return result.data;
    }
  });
};

export const getProcedureEvents = ({ date, location, provider }) => {
  const url = `${BASE_API}calendar/procedure?date=${date || ""}&location=${
    location || ""
  }&provider=${provider || ""}`;
  return getAxiosForRedux(url);
};

export const getUltrasoundEvents = ({ date, location, provider }) => {
  const url = `${BASE_API}calendar/ultrasound?date=${date || ""}&location=${
    location || ""
  }&provider=${provider || ""}`;
  return getAxiosForRedux(url);
};

export const getUsers = () => {
  const url = `${BASE_API}users`;
  return getAxiosForRedux(url);
};

export const postUser = (params) => {
  const url = `${BASE_API}users`;
  return postAxiosForRedux(url, params);
};
export const disableUser = (id) => {
  const url = `${BASE_API}users/${id}/disable_user`;
  return postAxiosForRedux(url);
};

export const enableUser = (id) => {
  const url = `${BASE_API}users/${id}/enable_user`;
  return postAxiosForRedux(url);
};

export const getDetailUsers = (id) => {
  const url = `${BASE_API}users/${id}`;
  return getAxiosForRedux(url);
};

export const get_ocr_category = () => {
  const url = `${BASE_API}ocr_category`;
  return getAxiosForRedux(url);
};

export const get_ocr_words = () => {
  const url = `${BASE_API}ocr_words`;
  return getAxiosForRedux(url);
};

export const post_ocr_words = (params) => {
  const url = `${BASE_API}ocr_words`;
  return postAxiosForRedux(url, params);
};

export const put_ocr_words = (id, params) => {
  const url = `${BASE_API}ocr_words/${id}`;
  return putAxiosForRedux(url, params);
};

export const delete_ocr_words = (id, params) => {
  const url = `${BASE_API}ocr_words/${id}`;
  return deleteAxios(url, params);
};

export const saveDetailUsers = (id, params) => {
  const url = `${BASE_API}users/${id}`;
  return putAxiosForRedux(url, params);
};

export const saveEmployeeInfo = (id, params) => {
  const url = `${BASE_API}users/${id}/update_employee_details`;
  return putAxiosForRedux(url, params);
};

export const getClinicEvents = ({ date, location, provider }) => {
  const url = `${BASE_API}calendar/clinic?date=${date || ""}&location=${
    location || ""
  }&provider=${provider || ""}`;
  return getAxiosForRedux(url);
};

export const getLocations = (id = "", type = "all") => {
  const url = `${BASE_API}locations?patient_id=${id}&type=${type}`;
  return getAxiosForRedux(url);
};

export const getDetailLocations = (id) => {
  const url = `${BASE_API}locations/${id}`;
  return getAxiosForRedux(url);
};

// insurance_companies

export const search_insurance_companies = (query) => {
  const url = `${BASE_API}insurance_companies?q=${query}`;
  return getAxiosForRedux(url);
};

export const detail_insurance_companies = (id) => {
  const url = `${BASE_API}insurance_companies/${id}`;
  return getAxiosForRedux(url);
};

export const save_insurance_companies = (params) => {
  const url = `${BASE_API}insurance_companies`;
  return postAxiosForRedux(url, params);
};

export const edit_insurance_companies = (id, params) => {
  const url = `${BASE_API}insurance_companies/${id}`;
  return putAxiosForRedux(url, params);
};

export const delete_insurance_companies = (id) => {
  const url = `${BASE_API}insurance_companies/${id}`;
  return deleteAxios(url);
};

export const payer_id_types = () => {
  const url = `${BASE_API}insurance_companies/payer_id`;
  return getAxiosForRedux(url);
};
//

export const insurance_types = () => {
  const url = `${BASE_API}insurance_types`;
  return getAxiosForRedux(url);
};
// clinic_procedure_type

export const list_clinic_procedure_type = () => {
  const url = `${BASE_API}clinic_procedure_type`;
  return getAxiosForRedux(url);
};

export const save_clinic_procedure_type = (params) => {
  const url = `${BASE_API}clinic_procedure_type`;
  return postAxiosForRedux(url, params);
};

export const detail_clinic_procedure_type = (id) => {
  const url = `${BASE_API}clinic_procedure_type/${id}`;
  return getAxiosForRedux(url);
};

export const edit_clinic_procedure_type = (id, params) => {
  const url = `${BASE_API}clinic_procedure_type/${id}`;
  return putAxiosForRedux(url, params);
};

export const delete_clinic_procedure_type = (id) => {
  const url = `${BASE_API}clinic_procedure_type/${id}`;
  return deleteAxios(url);
};

export const update_text_clinic_procedure_type = (id, params) => {
  const url = `${BASE_API}clinic_procedure_type/${id}/update_text_template`;
  return postAxiosForRedux(url, params);
};

export const update_cpt_clinic_procedure_type = (id, params) => {
  const url = `${BASE_API}clinic_procedure_type/${id}/update_cpt_rule`;
  return postAxiosForRedux(url, params);
};

export const update_icd_clinic_procedure_type = (id, params) => {
  const url = `${BASE_API}clinic_procedure_type/${id}/update_icd_rule`;
  return postAxiosForRedux(url, params);
};
// clinic_procedure_type_variable
export const list_clinic_procedure_type_variable = (id) => {
  const url = `${BASE_API}clinic_procedure_type_variable?clinic_procedure_type_id=${id}`;
  return getAxiosForRedux(url);
};

export const save_clinic_procedure_type_variable = (params) => {
  const url = `${BASE_API}clinic_procedure_type_variable`;
  return postAxiosForRedux(url, params);
};

export const detail_clinic_procedure_type_variable = (id) => {
  const url = `${BASE_API}clinic_procedure_type_variable/${id}`;
  return getAxiosForRedux(url);
};

export const edit_clinic_procedure_type_variable = (id, params) => {
  const url = `${BASE_API}clinic_procedure_type_variable/${id}`;
  return putAxiosForRedux(url, params);
};

export const delete_clinic_procedure_type_variable = (id) => {
  const url = `${BASE_API}clinic_procedure_type_variable/${id}`;
  return deleteAxios(url);
};

// patient_insurances
export const patient_insurances_id_insurances = (patient_id) => {
  const url = `${BASE_API}patient_insurances?patient_id=${patient_id}`;
  return getAxiosForRedux(url);
};

export const save_patient_insurances = (params) => {
  const url = `${BASE_API}patient_insurances`;
  return postAxiosForRedux(url, params);
};

export const detail_patient_insurances = (id) => {
  const url = `${BASE_API}patient_insurances/${id}`;
  return getAxiosForRedux(url);
};

export const edit_patient_insurances = (id, params) => {
  const url = `${BASE_API}patient_insurances/${id}`;
  return putAxiosForRedux(url, params);
};

export const delete_patient_insurances = (id) => {
  const url = `${BASE_API}patient_insurances/${id}`;
  return deleteAxios(url);
};

export const check_patient_insurances = (id) => {
  const url = `${BASE_API}patient_insurances/${id}/check_insurance`;
  return getAxiosForRedux(url);
};

export const text_template_procedure = (id, params) => {
  const url = `${BASE_API}procedure_type/${id}/update_text_template`;
  return postAxiosForRedux(url, params);
};

export const text_template_condition = (id, params) => {
  const url = `${BASE_API}referral_conditions/${id}/update_text_template`;
  return postAxiosForRedux(url, params);
};

export const text_template_ultrasound = (id, params) => {
  const url = `${BASE_API}ultrasounds/${id}/update_text_template`;
  return postAxiosForRedux(url, params);
};

export const update_plan_condition = (id, params) => {
  const url = `${BASE_API}referral_conditions/${id}/update_plan_rule`;
  return postAxiosForRedux(url, params);
};
// angio_type
export const get_angio_type = () => {
  const url = `${BASE_API}angio_procedure_type`;
  return getAxiosForRedux(url);
};

export const save_angio_type = (params) => {
  const url = `${BASE_API}angio_procedure_type`;
  return postAxiosForRedux(url, params);
};

export const detail_angio_type = (id) => {
  const url = `${BASE_API}angio_procedure_type/${id}`;
  return getAxiosForRedux(url);
};

export const edit_angio_type = (id, params) => {
  const url = `${BASE_API}angio_procedure_type/${id}`;
  return putAxiosForRedux(url, params);
};

export const delete_angio_type = (id) => {
  const url = `${BASE_API}angio_procedure_type/${id}`;
  return deleteAxios(url);
};

export const update_angio_type_template = (id, param) => {
  const url = `${BASE_API}angio_procedure_type/${id}/update_text_template`;
  return postAxiosForRedux(url, param);
};
// angio_condition_type
export const angio_condition_type = () => {
  const url = `${BASE_API}angio_condition_type`;
  return getAxiosForRedux(url);
};

export const angio_condition_type_icd = (id, params) => {
  const url = `${BASE_API}angio_procedure_type/${id}/update_icd_rule`;
  return postAxiosForRedux(url, params);
};

export const angio_condition_type_cpt = (id, params) => {
  const url = `${BASE_API}angio_procedure_type/${id}/update_cpt_rule`;
  return postAxiosForRedux(url, params);
};

// angio load intra
export const load_intra_operative = (id) => {
  const url = `${BASE_API}procedures/angio/load_intra_operative/${id}`;
  return getAxiosForRedux(url);
};

export const save_intra_operative = (id, params) => {
  const url = `${BASE_API}procedures/angio/save_intra_operative/${id}`;
  return postAxiosForRedux(url, params);
};

// angio_condition_type_choice
export const angio_condition_type_choice = (id) => {
  const url = `${BASE_API}angio_condition_type_choice?angio_condition_type_id=${id}`;
  return getAxiosForRedux(url);
};

// angio_type_variable
export const get_angio_type_variable = (id) => {
  const url = `${BASE_API}angio_procedure_type_variable?angio_procedure_type_id=${id}`;
  return getAxiosForRedux(url);
};

// procedurals
export const save_procedurals = (id, params) => {
  const url = `${BASE_API}procedures/angio/save_procedurals/${id}`;
  return postAxiosForRedux(url, params);
};

export const load_procedurals = (id) => {
  const url = `${BASE_API}procedures/angio/load_procedurals/${id}`;
  return getAxiosForRedux(url);
};

//
export const save_info_side = (id, params) => {
  const url = `${BASE_API}procedures/angio/save_info/${id}`;
  return postAxiosForRedux(url, params);
};
//

export const save_angio_type_variable = (params) => {
  const url = `${BASE_API}angio_procedure_type_variable`;
  return postAxiosForRedux(url, params);
};

export const detail_angio_type_variable = (id) => {
  const url = `${BASE_API}angio_procedure_type_variable/${id}`;
  return getAxiosForRedux(url);
};

export const edit_angio_type_variable = (id, params) => {
  const url = `${BASE_API}angio_procedure_type_variable/${id}`;
  return putAxiosForRedux(url, params);
};

export const delete_angio_type_variable = (id) => {
  const url = `${BASE_API}angio_procedure_type_variable/${id}`;
  return deleteAxios(url);
};

//

export const getProcedureData = () => {
  const url = `${BASE_API}procedure_type`;
  return getAxiosForRedux(url);
};

export const addProcedures_new = (params) => {
  const url = `${BASE_API}procedures`;
  return postAxiosForRedux(url, params);
};

// procedure_type_variable
export const updateProcedureTypeCPT = (id, params) => {
  const url = `${BASE_API}procedure_type_variable/update_cpt/${id}`;
  return putAxiosForRedux(url, params);
};

export const getProcedureType = (id) => {
  const url = `${BASE_API}procedure_type_variable?procedure_type_id=${id}`;
  return getAxiosForRedux(url);
};

export const saveProcedureType = (params) => {
  const url = `${BASE_API}procedure_type_variable`;
  return postAxiosForRedux(url, params);
};

export const editProcedureType = (id, params) => {
  const url = `${BASE_API}procedure_type_variable/${id}`;
  return putAxiosForRedux(url, params);
};

export const deleteProcedureType = (id) => {
  const url = `${BASE_API}procedure_type_variable/${id}`;
  return deleteAxios(url);
};
// condition variable
export const updateConditionVariableICD = (id, params) => {
  const url = `${BASE_API}condition_variable/update_icd/${id}`;
  return putAxiosForRedux(url, params);
};

export const getConditionVariable = (id) => {
  const url = `${BASE_API}condition_variable?condition_id=${id}`;
  return getAxiosForRedux(url);
};

export const delete_referral_conditions = (id) => {
  const url = `${BASE_API}referral_conditions/${id}`;
  return deleteAxios(url);
};

export const saveConditionVariable = (params) => {
  const url = `${BASE_API}condition_variable`;
  return postAxiosForRedux(url, params);
};

export const editConditionVariable = (id, params) => {
  const url = `${BASE_API}condition_variable/${id}`;
  return putAxiosForRedux(url, params);
};

export const deleteConditionVariable = (id) => {
  const url = `${BASE_API}condition_variable/${id}`;
  return deleteAxios(url);
};
//
export const getDetailProcedureData = (id) => {
  const url = `${BASE_API}procedure_type/${id}`;
  return getAxiosForRedux(url);
};

export const addProcedure = (params) => {
  const url = `${BASE_API}procedure_type`;
  return postAxios(url, params);
};

export const updateDetailProcedureData = (id, params) => {
  const url = `${BASE_API}procedure_type/${id}`;
  return putAxiosForRedux(url, params);
};

export const deleteProcedureData = (id) => {
  const url = `${BASE_API}procedure_type/${id}`;
  return deleteAxios(url);
};

export const getProcedureLocations = () => {
  const url = `${BASE_API}locations?type=procedure`;
  return getAxiosForRedux(url);
};

export const getProviders = (id, sort_by, only_providers) => {
  let url = `${BASE_API}providers`;
  url += id ? `?patient_id=${id}` : "";
  url += sort_by ? `?sort_by=${sort_by}` : "";
  url += only_providers ? `?only_providers=1` : "";
  return getAxiosForRedux(url);
};

export const editProviders = (id, params) => {
  let url = `${BASE_API}providers/${id}`;
  return putAxiosForRedux(url, params);
};

export const getProvidersLocation = (id) => {
  let url = `${BASE_API}providers?location_id=${id}`;
  return getAxiosForRedux(url);
};

export const getUltrasounds = () => {
  const url = `${BASE_API}ultrasounds`;
  return getAxiosForRedux(url);
};

export const get_ultrasound_type = (id) => {
  const url = `${BASE_API}ultrasound_finding?ultrasound_type_id=${id}`;
  return getAxiosForRedux(url);
};

export const save_ultrasound_type = (params) => {
  const url = `${BASE_API}ultrasound_finding`;
  return postAxiosForRedux(url, params);
};

export const detail_ultrasound_type = (id) => {
  const url = `${BASE_API}ultrasound_finding/${id}`;
  return getAxiosForRedux(url);
};

export const update_ultrasound_type = (id, params) => {
  const url = `${BASE_API}ultrasound_finding/${id}`;
  return postAxiosForRedux(url, params);
};

export const delete_ultrasound_type = (id) => {
  const url = `${BASE_API}ultrasound_finding/${id}`;
  return deleteAxios(url);
};

export const getDetailUltrasounds = (id) => {
  const url = `${BASE_API}ultrasounds/${id}`;
  return getAxiosForRedux(url);
};

export const deleteUltrasoundType = (id) => {
  const url = `${BASE_API}ultrasounds/${id}`;
  return deleteAxios(url);
};

export const ultrasound_update_conclusion_rule = (id, params) => {
  const url = `${BASE_API}ultrasounds/${id}/update_conclusion_rule`;
  return postAxiosForRedux(url, params);
};

export const ultrasound_update_result_rule = (id, params) => {
  const url = `${BASE_API}ultrasounds/${id}/update_result_rule`;
  return postAxiosForRedux(url, params);
};

export const ultrasound_update_cpt_rule = (id, params) => {
  const url = `${BASE_API}ultrasounds/${id}/update_cpt_rule`;
  return postAxiosForRedux(url, params);
};

export const getPatientCallback = ({ referral_date }) => {
  let url = BASE_API + "patient_callback";
  url += referral_date ? "?referral_date=" + referral_date : "";
  return getAxiosForRedux(url);
};

export const get_ultrasound_rule_result = (id) => {
  const url = `${BASE_API}procedures/${id}/get_ultrasound_rule_result`;
  return getAxiosForRedux(url);
};

export const save_ultrasound_rule_result = (id, params) => {
  const url = `${BASE_API}procedures/${id}/save_ultrasound_rule_result`;
  return postAxiosForRedux(url, params);
};

// ultrasound_finding_value
export const get_ultrasound_finding_value = (id) => {
  const url = `${BASE_API}ultrasound_finding_value?appointment_id=${id}`;
  return getAxiosForRedux(url);
};
export const save_ultrasound_finding_value = (params) => {
  const url = `${BASE_API}ultrasound_finding_value`;
  return postAxiosForRedux(url, params);
};
export const detail_ultrasound_finding_value = (id) => {
  const url = `${BASE_API}ultrasound_finding_value/${id}`;
  return getAxiosForRedux(url);
};
//

export const getPatientPending = ({
  referral_date,
  skip,
  take,
  appointment_type,
}) => {
  let url = BASE_API + "patient_pending";
  url += referral_date ? "?referral_date=" + referral_date : "";
  // + '&appointment_type=' + (appointment_type || '')
  // + '&skip=' + (skip || '')
  // + '&take=' + (take || '');
  return getAxiosForRedux(url);
};

export const getInitialPatientData = () => {
  let url = BASE_API + "patient_pending";
  return getAxios(url).then((result) => {
    if (result.data && result.data[0]) {
      const patient_id = result.data[0].patient_id || result.data[0].id;
      url = `${BASE_API}patients/${patient_id}`;
      return getAxiosForRedux(url);
    }
  });
};

export const sortProvider = (id, params) => {
  const url = `${BASE_API}users/${id}/get`;
  return postAxiosForRedux(url, params);
};

export const getEvaluationsTagert = (id) => {
  const url = `${BASE_API}incident_reports?targeted_user_id=${id}`;
  return getAxiosForRedux(url);
};

export const add_plan_me = (id, params) => {
  const url = `${BASE_API}procedures/${id}/add_plan_me`;
  return postAxiosForRedux(url, params);
};

export const getEvaluations = (date, employee) => {
  let url = `${BASE_API}evaluations?date_from=${date ? date : ""}&user_id=${
    employee ? employee : ""
  }`;
  return getAxiosForRedux(url);
};

export const getEvaluationsUser = (id) => {
  const url = `${BASE_API}evaluations?user_id=${id}`;
  return getAxiosForRedux(url);
};

export const getEvaluationsDetail = (id) => {
  const url = `${BASE_API}evaluations/${id}`;
  return getAxiosForRedux(url);
};

export const incident_reports_all = () => {
  const url = `${BASE_API}incident_reports`;
  return getAxiosForRedux(url);
};

export const incident_reports = (id) => {
  const url = `${BASE_API}incident_reports?user_id=${id}`;
  return getAxiosForRedux(url);
};

export const incident_reports_post = (params) => {
  const url = `${BASE_API}incident_reports`;
  return postAxiosForRedux(url, params);
};

export const incident_reports_update = (id, prams) => {
  const url = `${BASE_API}incident_reports/${id}`;
  return putAxiosForRedux(url, prams);
};

export const getFilterListOfType = () => {
  const url = `${BASE_API}procedures/filter_list`;
  return getAxiosForRedux(url);
};

export const getReferralCondition = () => {
  const url = `${BASE_API}referral_conditions`;
  return getAxiosForRedux(url);
};

// export const getPCM = (page, type, sortColumn = {}) => {
//   let url = "";
//   const param = { ...sortColumn };
//   url = `${BASE_API}pcm/get_list`;
//   param["page"] = page;
//   param["per_page"] = 100;
//   param["type"] = type;

//   return postAxiosForRedux(url, param);
// };

export const getPCMFilter = (data) => {
  let url = "";
  const param = {};
  if (data) {
    param["patient_name"] = data.search;
    param["type"] = data.type;
    param["user_id"] = data.user_id;
    param["start_dos"] = data.startDate;
    param["end_dos"] = data.endDate;
    param["page"] = data.page;
    param["per_page"] = 100;
    param["column"] = data.column;

    url = `${BASE_API}pcm/get_list`;
  }
  return postAxiosForRedux(url, param);
};

export const getPCMOverview = (page, data) => {
  let url = "";
  const param = {};
  url = `${BASE_API}pcm/get_overview`;
  param["patient_name"] = data.search;
  param["start_dos"] = data.startDate;
  param["icon_overview_status"] = data.overviewStatus;
  param["custom_report"] = data.customReport;
  param["page"] = page;
  param["per_page"] = 100;
  param["column"] = data.column;
  return postAxiosForRedux(url, param);
};

export const getPCMSummary = (type, startDate, endDate) => {
  let url = "";
  const param = {};
  url = `${BASE_API}pcm/get_summary`;
  param["type"] = type;
  param["start_dos"] = startDate;
  param["end_dos"] = endDate;
  return postAxiosForRedux(url, param);
};

export const updatePCM = (id, params) => {
  const url = `${BASE_API}pcm/${id}`;
  return putAxiosForRedux(url, params);
};

export const getPCMDetail = (id) => {
  const url = `${BASE_API}pcm/${id}`;
  return getAxiosForRedux(url);
};

export const getPCMForUser = (user_id, start_dos, end_dos) => {
  let url = "";
  if (!start_dos && end_dos) {
    url = `${BASE_API}pcm/get_for_user?user_id=${user_id}&end_dos=${end_dos}`;
  } else if (!end_dos && start_dos) {
    url = `${BASE_API}pcm/get_for_user?user_id=${user_id}&start_dos=${start_dos}`;
  } else if (!start_dos && !end_dos) {
    url = `${BASE_API}pcm/get_for_user?user_id=${user_id}`;
  } else if (start_dos && end_dos) {
    url = `${BASE_API}pcm/get_for_user?user_id=${user_id}&start_dos=${start_dos}&end_dos=${end_dos}`;
  }
  return getAxiosForRedux(url);
};

export const getPCMProvider = () => {
  const url = `${BASE_API}pcm/get_provider_list`;
  return getAxiosForRedux(url);
};

export const getPolicies = () => {
  const url = `${BASE_API}policies`;
  return getAxiosForRedux(url);
};
export const putPolicies = (id, params) => {
  const url = `${BASE_API}policies/${id}`;
  return putAxiosForRedux(url, params);
};
export const postPolicies = (params) => {
  const url = `${BASE_API}policies`;
  return postAxiosForRedux(url, params);
};
export const getDetailPolicies = (id) => {
  const url = `${BASE_API}policies/${id}`;
  return getAxiosForRedux(url);
};
export const getConditionDetailData = (id) => {
  const url = `${BASE_API}referral_conditions/${id}`;
  return getAxiosForRedux(url);
};

export const getProviderUser = (id) => {
  const url = `${BASE_API}providers/${id || 1}`;
  return getAxiosForRedux(url);
};

export const getRefferredProviders = () => {
  const url = `${BASE_API}referring_providers`;
  return getAxiosForRedux(url);
};

export const filterAppointmentSchedule = (
  id,
  location,
  provider,
  period,
  prev,
  payload,
  custom,
  load_next
) => {
  const url = custom
    ? `${BASE_API}get_us_newpatient?patient_id=${id}&required_location_id=${
        location || ""
      }&provider_id=${provider || ""}&date_period=${period || ""}&custom_date=${
        prev || ""
      }&load_next_row=${load_next || ""}`
    : `${BASE_API}get_us_newpatient?patient_id=${id}&required_location_id=${
        location || ""
      }&provider_id=${provider || ""}&date_period=${period || ""}&prev_date=${
        prev || ""
      }&load_next_row=${load_next || ""}`;
  return getAxiosForRedux(url, payload);
};

export const getAppointmentSchedule = (id) => {
  const url = `${BASE_API}get_us_newpatient?patient_id=${id}&required_location_id=&provider_id=&date_period=&prev_date=`;
  return getAxiosForRedux(url);
};

export const getProviderSchedule = (id, location, provider) => {
  const url = `${BASE_API}provider_schedule?patient_id=${id}&location_id=${
    location || ""
  }&provider_id=${provider || ""}`;
  return getAxiosForRedux(url);
};

export const getProcedureSchedule = (id) => {
  const url = `${BASE_API}get_available_procedures?patient_id=${id}`;
  return getAxiosForRedux(url);
};

export const getProcedureCallback = ({
  procedure_type,
  procedure_date,
  skip,
  take,
}) => {
  const url = `${BASE_API}procedure_callback?procedure_type=${
    procedure_type || ""
  }&procedure_date=${procedure_date || ""}&skip=${skip || ""}&take=${
    take || ""
  }`;
  return getAxiosForRedux(url);
};

export const getProcedurePending = ({
  procedure_type,
  procedure_date,
  skip,
  take,
}) => {
  const url = `${BASE_API}procedure_pending?procedure_type=${
    procedure_type || ","
  }&procedure_date=${procedure_date || ","}&skip=${skip || ","}&take=${
    take || ","
  }`;
  return getAxiosForRedux(url);
};

export const getPatient = ({ id, ...rest }) => {
  const url = `${BASE_API}patients/${id}`;
  return getAxiosForRedux(url, rest);
};

export const save_new_ultrasound = (param) => {
  const url = `${BASE_API}ultrasounds`;
  return postAxiosForRedux(url, param);
};

export const getPatients = (query) => {
  const url = `${BASE_API}patients/search?q=${query || ""}`;
  return getAxiosForRedux(url);
};

export const getCallAttempResult = () => {
  const url = `${BASE_API}call_attempt/call_attempt_result`;
  return getAxiosForRedux(url);
};

export const deleteCallAttemp = (id, params) => {
  let url = `${BASE_API}call_attempt/${id}`;
  if (params && params?.procedure_id) {
    url += `?procedure_id=${params?.procedure_id}`;
  }
  return deleteAxios(url);
};

export const saveClinicSchedule = (id, params) => {
  const url = `${BASE_API}patients/${id}/save_clinic_appointment`;
  return postAxiosForRedux(url, params, {});
};

export const addCallAttempt = (params) => {
  const user = cookies.get("cvai-current-user");
  if (user !== undefined) {
    params["user_id"] = parseInt(user);
  }
  const url = `${BASE_API}call_attempt`;
  return postAxiosForRedux(url, params, {});
};

export const addNewPatient = (params) => {
  const url = `${BASE_API}patients`;
  return postAxiosForRedux(url, params, {});
};

export const verifyInsurance = (id, policy) => {
  const url = `${BASE_API}patients/check_insurance?patient_id=${id}&policy_number=${policy}`;
  return getAxios(url);
};

export const verifyInsuranceDetail = (id, params) => {
  const { medical_id, policy_number } = params;
  const url = `${BASE_API}patients/${id}/check_insurance?policy_number=${policy_number}`;
  return getAxiosForRedux(url);
};

export const editDemographicsOfPatient = (params) => {
  const url = `${BASE_API}patients/${params.id}/demographics`;
  return putAxiosForRedux(url, params);
};

export const get_employee_documents = (params) => {
  let url = `${BASE_API}employee_documents?category=${
    params.category ? params.category : ""
  }`;
  url += params.provider_id ? `&provider_id=${params.provider_id}` : "";
  url += params.user_id ? `&user_id=${params.user_id}` : "";
  url += params.date ? `&created_at=${params.date}` : "";
  return getAxiosForRedux(url);
};

export const get_detail_employee_documents = (id) => {
  const url = `${BASE_API}employee_documents/${id}`;
  return getAxiosForRedux(url);
};

export const delete_employee_documents = (id) => {
  const url = `${BASE_API}employee_documents/${id}`;
  return deleteAxios(url);
};

export const send_fax_employee_documents = (id, params) => {
  let url = `${BASE_API}employee_documents/${id}/send_fax`;
  return postAxiosForRedux(url, params);
};

export const email_fax_employee_documents = (id, params) => {
  let url = `${BASE_API}employee_documents/${id}/email_fax`;
  return postAxiosForRedux(url, params);
};

export const print_fax_employee_documents = (id) => {
  let url = `${BASE_API}employee_documents/${id}/print_fax`;
  return postAxiosForRedux(url);
};

export const update_cpt_rule = (id, params) => {
  let url = `${BASE_API}procedure_type/${id}/update_cpt_rule`;
  return postAxiosForRedux(url, params);
};

export const update_icd_rule = (id, params) => {
  let url = `${BASE_API}referral_conditions/${id}/update_icd_rule`;
  return postAxiosForRedux(url, params);
};

export const updateMedicalHistory = (params, id) => {
  const url = `${BASE_API}patients/${id}/medical_history`;
  return putAxiosForRedux(url, params);
};

export const updateQuestionnaire = (params, id) => {
  const url = `${BASE_API}patients/${id}/questionnaire`;
  return putAxios(url, params);
};

export const updateDetailLocations = (id, params) => {
  const url = `${BASE_API}locations/${id}`;
  return putAxios(url, params);
};

export const getDiseases = (query) => {
  let url = `${BASE_API}diseases`;
  url += query ? `?q=${query}` : "";
  return getAxios(url);
};

export const getSurgicalHistories = (query) => {
  let url = `${BASE_API}surgical_history`;
  url += query ? `?q=${query}` : "";
  return getAxios(url);
};

export const addNewLocation = (params) => {
  let url = BASE_API + "locations";
  return postAxiosForRedux(url, params);
};

export const deleteLocation = (id, params) => {
  let url = `${BASE_API}locations/${id}`;
  return deleteAxios(url);
};

export const autoZip = (zipcode) => {
  let url = `${BASE_API}utility/zip_lookup?zipcode=${zipcode}`;
  return getAxiosForRedux(url);
};

export const updateDetailUltrasounds = (id, params) => {
  const url = `${BASE_API}ultrasounds/${id}`;
  return putAxios(url, params);
};

export const timeoff_requests_all = () => {
  const url = `${BASE_API}timeoff_requests`;
  return getAxiosForRedux(url);
};
export const timeoff_requests = (id) => {
  const url = `${BASE_API}timeoff_requests?user_id=${id}`;
  return getAxiosForRedux(url);
};
export const timeoff_detail_requests = (id) => {
  const url = `${BASE_API}timeoff_requests/${id}`;
  return getAxiosForRedux(url);
};
export const approve_timeoff_requests = (id, formData) => {
  const url = `${BASE_API}timeoff_requests/${id}`;
  return postAxiosForRedux(url, formData);
};
export const add_timeoff_requests = (params) => {
  const url = `${BASE_API}timeoff_requests`;
  return postAxiosForRedux(url, params);
};
export const getClinicReminders = ({
  recent_contact_date,
  appointment_date,
}) => {
  let url = BASE_API + "clinic_reminders";
  url += `?recent_contact_date=${recent_contact_date || ""}&appointment_date=${
    appointment_date || ""
  }`;
  return getAxiosForRedux(url);
};

export const getAuthorizationNumbers = () => {
  let url = BASE_API + "authorization_number_list";
  return getAxiosForRedux(url);
};

export const confirmProcedure = (id) => {
  const url = `${BASE_API}procedures/${id}`;
  return putAxiosForRedux(url, { status: "confirmed" });
};

export const deleteProcedure = (id) => {
  const url = `${BASE_API}procedures/${id}`;
  return deleteAxios(url);
};

export const set_cpt_code_review = (id, param) => {
  const url = `${BASE_API}procedures/${id}`;
  return putAxiosForRedux(url, param);
};

export const get_cpt_code_review = (id) => {
  const url = `${BASE_API}procedures/${id}`;
  return getAxiosForRedux(url);
};

export const getSingleProcedure = (id) => {
  const url = `${BASE_API}procedures/${id}`;
  return getAxiosForRedux(url);
};

export const save_temp = (id, param) => {
  const url = `${BASE_API}procedures/${id}/save_temp`;
  return putAxiosForRedux(url, param);
};

export const patient_document_delete_multiple = (param) => {
  const url = `${BASE_API}patient_documents/bulk_delete`;
  return postAxiosForRedux(url, param);
};

// unlock finalize
export const unlock_finalize = (id) => {
  const url = `${BASE_API}procedures/${id}/unlock`;
  return postAxiosForRedux(url);
};

export const search_cpt_code = (cpt_code, page) => {
  let url = "";
  if (cpt_code) {
    url = `${BASE_API}cpt_codes?code=${cpt_code}&page=${page}&per_page=20`;
  } else {
    url = `${BASE_API}cpt_codes?page=${page}&per_page=20`;
  }
  return getAxiosForRedux(url);
};

// prior_authorization_settings
export const get_prior_authorization_settings = () => {
  const url = `${BASE_API}prior_authorization_settings`;
  return getAxiosForRedux(url);
};
export const save_prior_authorization_settings = (params) => {
  const url = `${BASE_API}prior_authorization_settings`;
  return postAxiosForRedux(url, params);
};
//

export const saveProcedureSchedule = (id, params) => {
  const url = `${BASE_API}procedures/${id}/save_procedure_schedule`;
  return postAxiosForRedux(url, params);
};

export const saveUltrasoundSchedule = (id, params) => {
  const url = `${BASE_API}procedures/${id}/save_ultrasound_schedule`;
  return postAxiosForRedux(url, params);
};

export const savePlanClinicSchedule = (id, params) => {
  const url = `${BASE_API}procedures/${id}/save_clinic_schedule`;
  return postAxiosForRedux(url, params);
};

export const procedure_cancel = (id, params) => {
  const url = `${BASE_API}procedures/${id}/cancel`;
  return postAxiosForRedux(url, params);
};

export const patient_cancel = (id, params) => {
  const url = `${BASE_API}patients/${id}/cancel`;
  return postAxiosForRedux(url, params);
};

export const saveProcedureQuestion = (id, params) => {
  const url = `${BASE_API}procedures/${id}/save_procedure_question`;
  return postAxiosForRedux(url, params);
};

export const rescheduleProcedure = (id, procedure_id, params, custom) => {
  const { location, provider, prev_date, period } = params;
  let url;
  if (custom) {
    url =
      BASE_API +
      `patients/${id}/reschedule_procedure?procedure_id=${procedure_id}`;
    url += location ? `&location_id=${location}` : "";
    url += provider ? `&provider_id=${provider}` : "";
    url += prev_date ? `&custom_date=${prev_date}` : "";
    url += period ? `&date_period=${period}` : "";
  } else {
    url =
      BASE_API +
      `patients/${id}/reschedule_procedure?procedure_id=${procedure_id}`;
    url += location ? `&location_id=${location}` : "";
    url += provider ? `&provider_id=${provider}` : "";
    url += prev_date ? `&prev_date=${prev_date}` : "";
    url += period ? `&date_period=${period}` : "";
  }

  return getAxiosForRedux(url);
};

export const getProcedureFollowUp = (
  id,
  procedure_id,
  params,
  custom,
  load_next
) => {
  const { location, provider, prev_date, period, is_follow } = params;
  let url;
  if (custom) {
    url =
      BASE_API +
      `patients/${id}/get_procedure_followup?procedure_id=${procedure_id}`;
    url += location ? `&location_id=${location}` : "";
    url += provider ? `&provider_id=${provider}` : "";
    url += prev_date ? `&custom_date=${prev_date}` : "";
    url += period ? `&date_period=${period}` : "";
    url += is_follow ? `&is_follow=1` : "";
  } else {
    url =
      BASE_API +
      `patients/${id}/get_procedure_followup?procedure_id=${procedure_id}`;
    url += location ? `&location_id=${location}` : "";
    url += provider ? `&provider_id=${provider}` : "";
    url += prev_date ? `&prev_date=${prev_date}` : "";
    url += period ? `&date_period=${period}` : "";
    url += is_follow ? `&is_follow=1` : "";
  }

  return getAxiosForRedux(url);
};

export const getAvailableProcedure = (id) => {
  const url = BASE_API + "get_available_procedures?patient_id=" + (id || "");
  return getAxiosForRedux(url);
};

export const getClinicWorkflowAppointments = ({
  patient_id,
  start_date,
  end_date,
  type,
  paging,
}) => {
  let url = BASE_API + "procedures";
  url += "?" + (patient_id ? `patient_id=${patient_id}&` : "");
  url += start_date ? `start_date=${start_date}&` : "";
  url += end_date ? `end_date=${end_date}&` : "";
  url += type ? `type=${type}&` : "";
  url += paging ? `page_number=${paging}` : "";
  url += `&page_size=100`;
  return getAxiosForRedux(url);
};

export const getCommunicationRecords = ({
  patient_id,
  result,
  method,
  paging,
}) => {
  let url = BASE_API + "communication_records";
  url += "?";
  url += patient_id ? `filter[patient_id]=${patient_id}&` : "";
  url += result ? `filter[result]=${result}&` : "";
  url += method ? `filter[method]=${method}&` : "";
  url += paging ? `page[number]=${paging}` : "";
  url += `&page[size]=100`;
  return getAxiosForRedux(url);
};

export const getFaxLogs = ({
  patient_id,
  user_id,
  pcp_id,
  direction,
  dateSort,
  patientSort,
  typeSort,
}) => {
  let url = BASE_API + "fax_logs";
  url += "?" + (patient_id ? `patient_id=${patient_id}&` : "");
  url += pcp_id ? `pcp_id=${pcp_id}` : "";
  url += direction ? `direction=${direction}` : "";
  url += dateSort ? `&sort_by=date&sort_direction=${dateSort}` : "";
  url += patientSort
    ? `&sort_by=patient_name&sort_direction=${patientSort}`
    : "";
  url += typeSort ? `&sort_by=type&sort_direction=${typeSort}` : "";
  return getAxiosForRedux(url);
};
export const detailFaxLog = (id) => {
  let url = `${BASE_API}fax_logs/${id}`;
  return getAxiosForRedux(url);
};
export const sendFax = (id, params) => {
  let url = `${BASE_API}fax_logs/${id}/send_fax`;
  return postAxiosForRedux(url, params);
};
export const emailFax = (id, params) => {
  let url = `${BASE_API}fax_logs/${id}/email_fax`;
  return postAxiosForRedux(url, params);
};
export const printFax = (id) => {
  let url = `${BASE_API}fax_logs/${id}/print_fax`;
  return postAxiosForRedux(url);
};
export const sendFaxPatientDocument = (id, params) => {
  let url = `${BASE_API}patient_documents/${id}/send_fax`;
  return postAxiosForRedux(url, params);
};
export const faxPatientDocumentBulk = (params) => {
  let url = `${BASE_API}patient_documents/fax_bulk`;
  return postAxiosForRedux(url, params);
};
export const emailPatientDocument = (id, params) => {
  let url = `${BASE_API}patient_documents/${id}/email_fax`;
  return postAxiosForRedux(url, params);
};
export const emailPatientDocumentBulk = (params) => {
  let url = `${BASE_API}patient_documents/email_bulk`;
  return postAxiosForRedux(url, params);
};
export const printPatientDocumentBulk = (params) => {
  let url = `${BASE_API}patient_documents/print_bulk`;
  return postAxiosForRedux(url, params);
};
export const printPatientDocument = (id) => {
  let url = `${BASE_API}patient_documents/${id}/print_fax`;
  return postAxiosForRedux(url);
};
export const upload_document_setting = (params) => {
  let url = `${BASE_API}settings/upload_document`;
  return postAxiosForRedux(url, params);
};
export const getScanLogs = ({
  patient_id,
  user_id,
  pcp_id,
  direction,
  dateSort,
  patientSort,
  typeSort,
}) => {
  let url = BASE_API + "scan_logs";
  url += "?" + (patient_id ? `patient_id=${patient_id}&` : "");
  url += pcp_id ? `pcp_id=${pcp_id}` : "";
  url += direction ? `direction=${direction}` : "";
  url += dateSort ? `&sort_by=date&sort_direction=${dateSort}` : "";
  url += patientSort
    ? `&sort_by=patient_name&sort_direction=${patientSort}`
    : "";
  url += typeSort ? `&sort_by=type&sort_direction=${typeSort}` : "";
  return getAxiosForRedux(url);
};
export const detailScanLog = (id) => {
  let url = `${BASE_API}scan_logs/${id}`;
  return getAxiosForRedux(url);
};
export const sendScan = (id, params) => {
  let url = `${BASE_API}scan_logs/${id}/send_fax`;
  return postAxiosForRedux(url, params);
};
export const emailScan = (id, params) => {
  let url = `${BASE_API}scan_logs/${id}/email_fax`;
  return postAxiosForRedux(url, params);
};
export const printScan = (id) => {
  let url = `${BASE_API}scan_logs/${id}/print_fax`;
  return postAxiosForRedux(url);
};
export const saveFaxToDocument = (id, params) => {
  let url = `${BASE_API}fax_logs/${id}/save_fax_to_document`;
  return postAxiosForRedux(url, params);
};

export const deleteFaxLog = (id) => {
  let url = `${BASE_API}fax_logs/${id}`;
  return deleteAxios(url);
};

export const deleteScanLog = (id) => {
  let url = `${BASE_API}scan_logs/${id}`;
  return deleteAxios(url);
};

export const deletePatientDocument = (id) => {
  let url = `${BASE_API}patient_documents/${id}`;
  return deleteAxios(url);
};

export const filterFaxLogs = ({ patient_id, user_id, pcp_id, direction }) => {
  let url = BASE_API + "fax_logs";
  url += "?" + (patient_id ? `filter[patient_id]=${patient_id}&` : "");
  url += user_id ? `filter[user_id]=${user_id}&` : "";
  url += pcp_id ? `filter[pcp_id]=${pcp_id}&` : "";
  url += direction ? `filter[direction]=${direction}` : "";
  return getAxiosForRedux(url);
};
export const savePriorAuthorization = (id, params) => {
  let url = `${BASE_API}procedures/${id}/save_authorization_number`;
  return postAxiosForRedux(url, params);
};

export const getPriorAuthorization = (params) => {
  let url = "";
  if (!params) {
    url =
      BASE_API +
      "prior_authorization?type=," +
      "&authorization_number=," +
      "&date_of_service=,";
    return getAxiosForRedux(url);
  }
  const { type, authorization_number, date_of_service } = params;
  url =
    BASE_API +
    "prior_authorization?type=" +
    (type || "") +
    "&authorization_number" +
    (authorization_number || "") +
    "&date_of_service=" +
    (date_of_service || "");
  return getAxiosForRedux(url);
};

export const getPCPList = (page, search) => {
  let url = BASE_API + `pcp`;
  url += page ? `?page=${page}&per_page=100` : "";
  url += search ? `&q=${search}` : "";
  return getAxiosForRedux(url);
};

export const get_list_patients = (page, status, search) => {
  let url = BASE_API + `patients`;
  url += page ? `?page=${page}&per_page=100&status=${status}` : "";
  url += search ? `&q=${search}` : "";
  return getAxiosForRedux(url);
};

// communication
export const sendLetter = (patient_id) => {
  const url = `${BASE_API}patients/${patient_id}/send_letter`;
  return postAxios(url, null, {});
};

export const sendFaxPCP = (patient_id) => {
  const url = `${BASE_API}patients/${patient_id}/send_fax_pcp`;
  return postAxios(url, null, {});
};

export const sendFaxRP = (patient_id) => {
  const url = `${BASE_API}patients/${patient_id}/send_fax_rp`;
  return postAxios(url, null, {});
};

export const printPacket = (patient_id) => {
  const url = `${BASE_API}patients/${patient_id}/print_packet`;
  return postAxios(url, null, {});
};

export const sendFaxPCPProcedure = (procedure_id) => {
  const url = `${BASE_API}procedures/${procedure_id}/send_fax_pcp`;
  return postAxios(url, null, {});
};

export const sendFaxHospital = (procedure_id) => {
  const url = `${BASE_API}procedures/${procedure_id}/send_fax_hospital`;
  return postAxios(url, null, {});
};

export const sendFaxRPProcedure = (procedure_id) => {
  const url = `${BASE_API}procedures/${procedure_id}/send_fax_rp`;
  return postAxios(url, null, {});
};

export const printPacketProcedure = (procedure_id) => {
  const url = `${BASE_API}procedures/${procedure_id}/print_packet`;
  return postAxios(url, null, {});
};

export const sendLetterProcedure = (procedure_id) => {
  const url = `${BASE_API}procedures/${procedure_id}/send_letter`;
  return postAxios(url, null, {});
};

export const sendEmailProcedure = (procedure_id) => {
  const url = `${BASE_API}procedures/${procedure_id}/send_email`;
  return postAxiosForRedux(url, null, {});
};

export const printPolicy = (policy_id) => {
  const url = `${BASE_API}policies/${policy_id}/pdf`;
  return getAxios(url, null, {});
};

// Put Requests
export const putRequest = (id, body, route) => {
  const url = `${BASE_API}patients/${id}/${route}`;

  // const data = {
  //   leg_swelling: 0,
  //   smoked_cigarettes: 0,
  //   history_aneurysm: 1,
  //   suffered_dvt: 0,
  //   leg_pain: 0,
  //   stroke_Symptom: 0
  // }

  // console.log(url, id, body, route)
  // I am assuming body is already in this format - should be easy enough to do onSubmit
  // Not sure if we want to separate these put requests - depending on format of body, may make sense have some error checking in here
  return putAxios(url, body);
};

//get patient schedules
export const getPatientSchedules = ({
  date,
  location,
  provider_id,
  patient_id,
  only_clinics,
  only_procedures,
  only_us,
  only_angio,
}) => {
  const url = `${BASE_API}get_patient_schedules?date=${date}&location=${location}&provider_id=${provider_id}&patient_id=${patient_id}&only_clinics=${only_clinics}&only_procedures=${only_procedures}&only_us=${only_us}&only_angio=${only_angio}`;
  return getAxiosForRedux(url);
};

//get scanning token
export const getScanningToken = (scanType = "id", patient_id) => {
  const url = `${BASE_API}gettokenformobilescan?scan_type=${scanType}&patient_id=${patient_id}`;
  return getAxiosForRedux(url);
};

// Get ID Card Info => panamacheng updated
export const getIDCardInfo = (patient_id, card_type) => {
  const url = `${BASE_API}patient-idcard/patients/${patient_id}/${card_type}`;
  return getAxiosForRedux(url);
};

// patient notes
export const getPatientNotes = ({ patientId }) => {
  const url = `${BASE_API}patient_notes/patient/${patientId}`;
  return getAxiosForRedux(url);
};

// patient_notes new
export const save_patient_notes = (params) => {
  const url = `${BASE_API}patient_notes`;
  return postAxiosForRedux(url, params);
};

// patient_notes get
export const get_patient_notes = (patient_id) => {
  const url = `${BASE_API}patient_notes?patient_id=${patient_id}`;
  return getAxiosForRedux(url);
};

// patient_notes delete
export const delete_patient_notes = (patient_id) => {
  const url = `${BASE_API}patient_notes/${patient_id}`;
  return deleteAxios(url);
};

// patient_notes billing new
export const save_patient_notes_billing = (params) => {
  const url = `${BASE_API}billing_note`;
  return postAxiosForRedux(url, params);
};

// patient_notes billing get
export const get_patient_notes_billing = (patient_id) => {
  const url = `${BASE_API}billing_note?appointment_id=${patient_id}`;
  return getAxiosForRedux(url);
};

// patient_notes billing delete
export const delete_patient_notes_billing = (patient_id) => {
  const url = `${BASE_API}billing_note/${patient_id}`;
  return deleteAxios(url);
};

// save patient notes
export const savePatientNotes = ({ patientId, allNotes, allNotesDeleted }) => {
  const url = `${BASE_API}patient_notes/patient/${patientId}`;
  for (let notes of allNotesDeleted) {
    allNotes.push(notes);
  }
  return postAxiosForRedux(url, { allNotes });
};

// scan insurance card
export const getScanTokenInsuranceCard = (patient_id) => {
  const url = `${BASE_API}scan_insurance_card/get_token?patient_id=${patient_id}`;
  return getAxiosForRedux(url);
};

export const checkScanInsuranceCardResult = (scanToken) => {
  const url = `${BASE_API}scan_insurance_card/check_scanned?scan_token=${scanToken}`;
  return getAxiosForRedux(url);
};

export const patientScheduleConfirmCheckin = (appointment_id) => {
  const url = `${BASE_API}patient_schedule/confirm_checkin?appointment_id=${appointment_id}`;
  return getAxiosForRedux(url);
};

export const saveUploadedPatientDocument = ({
  patient_id,
  category,
  provider_id,
  filename,
}) => {
  const url = `${BASE_API}clinic-provider/save-uploaded-document/${patient_id}`;
  return postAxiosForRedux(url, { category, provider_id, filename });
};

export const searchPatientDocuments = ({
  patient_id,
  date,
  provider_id,
  category,
}) => {
  const url = `${BASE_API}clinic-provider/search-documents/${patient_id}?date=${date}&provider_id=${provider_id}&category=${category}`;
  return getAxiosForRedux(url);
};

export const searchDrug = (query) => {
  const url = `${BASE_API}drug?query=${query}`;
  return getAxiosForRedux(url);
};

export const get_pre_operative_procedure = (id) => {
  const url = `${BASE_API}pre_operative_procedure?procedure_id=${id}`;
  return getAxiosForRedux(url);
};

export const update_in_patient_charting = (id, param) => {
  const url = `${BASE_API}procedures/${id}/update_in_patient_charting`;
  return putAxiosForRedux(url, param);
};

export const charting_generate_pdf = (id) => {
  const url = `${BASE_API}procedures/${id}/generate_pdf`;
  return getAxiosForRedux(url);
};

export const apply_cpt_rules = (param) => {
  const url = `${BASE_API}procedures/apply_cpt_rules`;
  return postAxiosForRedux(url, param);
};

export const save_in_patient_charting = (param) => {
  const url = `${BASE_API}procedures/save_in_patient_charting`;
  return postAxiosForRedux(url, param);
};

export const search_plan_suggestion = () => {
  const url = `${BASE_API}plan_suggestion`;
  return getAxiosForRedux(url);
};

export const get_plan_patient = (patient_id, procedure_id) => {
  let url = `${BASE_API}plan?patient_id=${patient_id}&procedure_id=${procedure_id}`;
  return getAxiosForRedux(url);
};

export const delete_plan = (plan_id) => {
  let url = `${BASE_API}plan/${plan_id}`;
  return deleteAxios(url);
};

export const get_image_list = (id, params) => {
  let url = `${BASE_API}patients/${id}/get-image-list`;
  if (params) {
    url += `?date=${params.date ? params.date : ""}`;
    url += `&provider_id=${
      params.provider_id === "all"
        ? ""
        : !params.provider_id
        ? ""
        : params.provider_id
    }`;
    url += `&exam_name=${
      params.exam_name === "all"
        ? ""
        : !params.exam_name
        ? ""
        : params.exam_name
    }`;
  }

  return getAxiosForRedux(url);
};

export const save_plan_patient = (param) => {
  const url = `${BASE_API}plan`;
  return postAxiosForRedux(url, param);
};

export const add_pre_operative_procedure = (param) => {
  const url = `${BASE_API}pre_operative_procedure`;
  return postAxiosForRedux(url, param);
};

export const get_post_operative_procedure = (id) => {
  const url = `${BASE_API}post_operative_procedure?procedure_id=${id}`;
  return getAxiosForRedux(url);
};

export const add_post_operative_procedure = (param) => {
  const url = `${BASE_API}post_operative_procedure`;
  return postAxiosForRedux(url, param);
};

export const postReferralCondition = (data) => {
  const url = `${BASE_API}referral_conditions`;
  return postAxiosForRedux(url, data);
};

export const updateReferralCondition = (id, data) => {
  const url = `${BASE_API}referral_conditions/${id}`;
  return putAxiosForRedux(url, data);
};

export const HPIGetConditions = (procedure_id) => {
  const url = `${BASE_API}clinic-provider/hpi/${procedure_id}`;
  return getAxiosForRedux(url);
};

export const HPISaveCondition = (patient_id, procedure_id, conditions) => {
  const url = `${BASE_API}clinic-provider/hpi/${procedure_id}`;
  return postAxiosForRedux(url, { patient_id: patient_id, conditions });
};

/** Inventory Section API */
export const getInventories = (query = "") => {
  const url = `${INVENTORY_API}inventory/search?query=${query}`;
  return getAxiosForRedux(url);
};

export const getPatientHospital = (id, page) => {
  const url = `${BASE_API}procedures?patient_id=${id}&page=${page}&per_page=100000&is_hospital_appointment=1`;
  return getAxiosForRedux(url);
};

export const getPatientAppointment = (params) => {
  const url = `${BASE_API}procedures/get_appointments`;
  return postAxiosForRedux(url, params);
};

// office
export const OfficeGetConditions = (procedure_id) => {
  const url = `${BASE_API}office-procedure/hpi/${procedure_id}`;
  return getAxiosForRedux(url);
};

export const OfficeSaveCondition = (patient_id, procedure_id, conditions) => {
  const url = `${BASE_API}office-procedure/hpi/${procedure_id}`;
  return postAxiosForRedux(url, { patient_id: patient_id, conditions });
};

export const getInventoryProducts = () => {
  const url = `${INVENTORY_API}inventory/product_numbers`;
  return getAxiosForRedux(url);
};

export const getInventoryLocations = () => {
  const url = `${INVENTORY_API}inventory/locations`;
  return getAxiosForRedux(url);
};

export const getInventorySpecificLocations = () => {
  const url = `${INVENTORY_API}inventory/specific_locations`;
  return getAxiosForRedux(url);
};

export const getAgniographicSupplies = (query) => {
  const url = `${INVENTORY_API}inventory/angiographic?query=${query}`;
  return getAxiosForRedux(url);
};

export const getNarcoticInventories = (query) => {
  const url = `${INVENTORY_API}inventory/narcotic?query=${query}`;
  return getAxiosForRedux(url);
};

export const addNewInventory = (params) => {
  const url = `${INVENTORY_API}inventory/new`;
  return postAxiosForRedux(url, params);
};

export const updateInventoryItem = (id, params) => {
  const url = `${INVENTORY_API}inventory/others/${id}`;
  return putAxiosForRedux(url, params);
};

export const updateAngiographicSupply = (id, params) => {
  const url = `${INVENTORY_API}inventory/angiographic/${id}`;
  return putAxiosForRedux(url, params);
};

export const updateNarcoticInventory = (id, params) => {
  const url = `${INVENTORY_API}inventory/narcotic/${id}`;
  return putAxiosForRedux(url, params);
};

export const deleteInventoryItem = (id, params) => {
  const url = `${INVENTORY_API}inventory/${id}/destroy`;
  return putAxiosForRedux(url, params);
};

export const getInventoryCostHistory = (id, type = "other") => {
  const url = `${INVENTORY_API}inventory/${id}/cost_history?type=${type}`;
  return getAxiosForRedux(url);
};

export const addInventoryCostHistory = (id, params) => {
  const url = `${INVENTORY_API}inventory/${id}/cost_history`;
  return postAxiosForRedux(url, params);
};

export const addToPurchaseList = (id, params) => {
  const url = `${INVENTORY_API}inventory/${id}/addToOrder`;
  return postAxiosForRedux(url, params);
};

export const getPurchaseList = () => {
  const url = `${INVENTORY_API}inventory/purchases`;
  return getAxiosForRedux(url);
};

export const orderPurchaseItem = (id, params) => {
  const url = `${INVENTORY_API}inventory/purchases/${id}/order`;
  return postAxiosForRedux(url, params);
};

export const getReceivingOrders = () => {
  const url = `${INVENTORY_API}inventory/receiving`;
  return getAxiosForRedux(url);
};

export const getReturnCreditOrders = () => {
  const url = `${INVENTORY_API}inventory/return_credit`;
  return getAxiosForRedux(url);
};

export const getAllReceivingOrders = () => {
  const url = `${INVENTORY_API}inventory/receiving/all`;
  return getAxiosForRedux(url);
};

export const updateReceivingOrder = (id, params) => {
  const url = `${INVENTORY_API}inventory/receiving/${id}`;
  return putAxiosForRedux(url, params);
};

export const deleteReceivingOrder = (id) => {
  const url = `${INVENTORY_API}inventory/receiving/${id}/destroy`;
  return putAxiosForRedux(url, {});
};

export const itemReceivedOrder = (id, params) => {
  const url = `${INVENTORY_API}inventory/receiving/${id}/item_received`;
  return putAxiosForRedux(url, params);
};

export const creditReceivedOrder = (id, params) => {
  const url = `${INVENTORY_API}inventory/receiving/${id}/credit_received`;
  return putAxiosForRedux(url, params);
};

export const shippedReceivingOrder = (id, params) => {
  const url = `${INVENTORY_API}inventory/receiving/${id}/shipped`;
  return putAxiosForRedux(url, params);
};

// Delete purchase
export const deletePurchase = (id) => {
  const url = `${INVENTORY_API}inventory/purchase/${id}/destroy`;
  return putAxiosForRedux(url, {});
};

// Update Inventory Notes
export const updateInventoryNotes = (id, params) => {
  const url = `${INVENTORY_API}inventory/${id}/notes`;
  return putAxiosForRedux(url, params);
};

// Get Inventory Users
export const getInventoryUsers = () => {
  const url = `${INVENTORY_API}inventory/users`;
  return getAxiosForRedux(url);
};

// Patient Examination

export const patientExaminationLoadInit = (procedure_id) => {
  const url = `${BASE_API}patient_examination/load-init/${procedure_id}`;
  return getAxiosForRedux(url);
};

// Get Patient Document
export const get_filter_patient_document = (params) => {
  let url = `${BASE_API}patient_documents?category=${
    params.category ? params.category : ""
  }`;
  url += params.provider_id ? `&provider_id=${params.provider_id}` : "";
  url += params.patient_id ? `&patient_id=${params.patient_id}` : "";
  url += params.date ? `&created_at=${params.date}` : "";
  url += params.sort_directionDate
    ? `&sort_by=date&sort_direction=${params.sort_directionDate}`
    : "";
  url += params.sort_directionCate
    ? `&sort_by=date&sort_direction=${params.sort_directionCate}`
    : "";
  return getAxiosForRedux(url);
};

// Patient Document
export const put_patient_document = (id, prams) => {
  const url = `${BASE_API}patient_documents/${id}`;
  return putAxiosForRedux(url, prams);
};

// send email
export const send_email = (patient_id) => {
  const url = `${BASE_API}patients/${patient_id}/send_email`;
  return postAxios(url, null, {});
};

export const save_quick_register = (prams) => {
  const url = `${BASE_API}patients/save_quick_register`;
  return postAxiosForRedux(url, prams);
};

// get patient Document
export const get_patient_document = (id) => {
  const url = `${BASE_API}patient_documents/${id}`;
  return getAxiosForRedux(url);
};

export const patientExaminationCopyLast = (patient_id, scope) => {
  const url = `${BASE_API}patient_examination/copy-last/${patient_id}?scope=${scope}`;
  return getAxiosForRedux(url);
};

export const patientExaminationSave = (procedure_id, data) => {
  const url = `${BASE_API}patient_examination/save/${procedure_id}`;
  return postAxiosForRedux(url, data);
};

export const get_meaningful_statistics = (params) => {
  let url = `${BASE_API}meaningful_statistics/get_list`;
  return postAxiosForRedux(url, params);
};

// Clinic Provider->Review
export const clinicProviderReviewLoadInit = (procedure_id) => {
  const url = `${BASE_API}clinic-provider/review/${procedure_id}`;
  return getAxiosForRedux(url);
};

// Clinic->Provider->PatientSnapshot
export const clinicProviderPatientSnapshotLoadInit = (patient_id) => {
  const url = `${BASE_API}clinic-provider/patient-snapshot/load-init/${patient_id}`;
  return getAxiosForRedux(url);
};

export const loadUltrasoundHistoryForCondition = (
  patient_id,
  condition_value
) => {
  const url = `${BASE_API}clinic-provider/patient-snapshot/load-history/${patient_id}?condition=${condition_value}`;
  return getAxiosForRedux(url);
};

// angio
export const angioPatientSnapshotLoadInit = (patient_id) => {
  const url = `${BASE_API}patient_examination/load-init-angio/${patient_id}`;
  return getAxiosForRedux(url);
};

export const clinicProviderPatientSnapshotSaveMedicalHistory = (
  patient_id,
  data
) => {
  const url = `${BASE_API}clinic-provider/patient-snapshot/save-medical-history/${patient_id}`;
  return postAxiosForRedux(url, data);
};

// Clinic->Provider->Plan
export const clinicProviderPlanLoadInit = (patient_id) => {
  const url = `${BASE_API}clinic-provider/plan/load-init/${patient_id}`;
  return getAxiosForRedux(url);
};

// Procedure Workflow->Procedure Module->Consent
export const procedureConsentLoadInit = (patient_id) => {
  const url = `${BASE_API}procedure-consent/load-init/${patient_id}`;
  return getAxiosForRedux(url);
};

// save chief complaint
export const save_chief_complaint = (id, param) => {
  const url = `${BASE_API}procedures/${id}/save_chief_complaint`;
  return postAxiosForRedux(url, param);
};

export const searchAllergy = (query) => {
  const url = `${BASE_API}allergies?q=${query}`;
  return getAxiosForRedux(url);
};

export const procedureConsentSaveDocument = (data) => {
  const url = `${BASE_API}procedure-consent/save-document`;
  return Axios.post(url, data, {
    header: {
      "Content-Type": "multipart/form-data",
    },
  }).then((result) => {
    if (data) {
      return { data: result.data, ...data };
    }
    return result.data;
  });
};

export const procedureConsentSearch = (patient_id, searchData) => {
  const url = `${BASE_API}procedure-consent/search/${patient_id}`;
  return postAxiosForRedux(url, { searchData });
};

export const procedureConsentGetSignatureImage = (provider_id) => {
  const url = `${BASE_API}procedure-consent/get-signature-image/${provider_id}`;
  return getAxiosForRedux(url);
};

export const procedureConsentSetSignatureImage = (
  provider_id,
  signature_image
) => {
  const url = `${BASE_API}procedure-consent/set-signature-image/${provider_id}`;
  return postAxiosForRedux(url, { signature_image });
};

export const procedureConsentDelete = (consent_id) => {
  const url = `${BASE_API}procedure-consent/delete/${consent_id}`;
  return postAxiosForRedux(url);
};

export const procedureConsentSendFax = (consent_id, data) => {
  const url = `${BASE_API}procedure-consent/send-fax/${consent_id}`;
  return postAxiosForRedux(url, data);
};

export const procedureConsentSendEmail = (consent_id, data) => {
  const url = `${BASE_API}procedure-consent/send-email/${consent_id}`;
  return postAxiosForRedux(url, data);
};

export const procedureConsentGetDocument = (consent_id, data) => {
  const url = `${BASE_API}procedure-consent/get-document/${consent_id}`;
  return getAxiosForRedux(url);
};

export const getSearchHistory = () => {
  const user_id = cookies.get("cvai-current-user");
  const url = `${BASE_API}search_history?user_id=${user_id}`;
  return getAxiosForRedux(url);
};

export const addSearchHistory = (patient_id) => {
  const user_id = cookies.get("cvai-current-user");
  cookies.set("current_patient_id", patient_id);
  const params = { user_id, patient_id };
  const url = `${BASE_API}search_history`;
  return postAxiosForRedux(url, params);
};

export const getGlobalMessages = () => {
  const url = `${BASE_API}global_messages`;
  return getAxiosForRedux(url);
};

export const addGlobalMessage = (message) => {
  const user_id = cookies.get("cvai-current-user");
  const params = { user_id: user_id, message: message };
  const url = `${BASE_API}global_messages`;
  return postAxiosForRedux(url, params);
};

export const deleteGlobalMessage = (id) => {
  const url = `${BASE_API}global_messages/${id}`;
  return deleteAxios(url).then((result) => {
    if (result.status === "success") {
      dispatch(getGlobalMessagesAction());
    }
  });
};

export const adminUserGetSignatureImage = (provider_id) => {
  const url = `${BASE_API}procedure-consent/get-signature-image/${provider_id}`;
  return getAxiosForRedux(url);
};

export const hipaaGetList = (patient_id) => {
  const url = `${BASE_API}hipaa/get-list/${patient_id}`;
  return getAxiosForRedux(url);
};

export const getAppointmentBoardAppointments = (slug) => {
  const url = `${BASE_API}appointment_monitor/${slug}`;
  return getAxiosForRedux(url);
};

export const createAppointmentBoard = (location_id, type) => {
  const url = `${BASE_API}appointment_monitor/`;
  return postAxiosForRedux(url, {
    location_id: location_id,
    type: type
  });
};

export const exportPCM = (user_id, startDate, endDate) => {
  const url = `${BASE_API}pcm/download?user_id=${user_id}&start_dos=${startDate}&end_dos=${endDate}`;
  return getAxiosForRedux(url);
};

export const exportPCMforUser = (user_id, startDate, endDate) => {
  const url = `${BASE_API}pcm/download_for_user?user_id=${user_id}&start_dos=${startDate}&end_dos=${endDate}`;
  return getAxiosForRedux(url);
};

export const exportPCMOverview = (icon_review_status) => {
  let url = `${BASE_API}pcm/download_overview`;
  url += icon_review_status ? `?icon_review_status=${icon_review_status}` : "";
  return getAxiosForRedux(url);
};

// Admin setting.
export const adminSettingLogoUpload = (params) => {
  const url = `${BASE_API}settings`;
  return postAxiosForRedux(url, params);
};

export const adminSettingGetConfig = () => {
  const url = `${BASE_API}settings`;
  return getAxiosForRedux(url);
};

export const adminSettingSaveOCRInfo = (params) => {
  const url = `${BASE_API}settings`;
  return postAxiosForRedux(url, params);
};

export const getPrimaryProviderDetails = (query) => {
  const url = `${BASE_API}primary-care-providers/search?q=${query || ""}`;
  return getAxiosForRedux(url);
};

export const updatePrimaryProviderDetail = (id, params) => {
  const url = `${BASE_API}pcp/${id}`;
  return putAxiosForRedux(url, params);
};

export const createNewPrivateProvider = (params) => {
  const url = `${BASE_API}pcp`;
  return postAxiosForRedux(url, params);
};

export const adminSettingSaveOrgDetail = (params) => {
  const url = `${BASE_API}settings`;
  return postAxiosForRedux(url, params);
};

// User media.
export const uploadUserMedia = (params) => {
  const url = `${BASE_API}user_medias`;
  return postAxiosForRedux(url, params);
};

export const getAllUserMedia = (id) => {
  const url = `${BASE_API}user_medias?user_id=${id}`;
  return getAxiosForRedux(url);
};

export const removeUserMedia = (id) => {
  const url = `${BASE_API}user_medias/${id}}`;
  return deleteAxios(url);
};

export const updateUserMedia = (id, params) => {
  const url = `${BASE_API}user_medias/${id}}`;
  return putAxiosForRedux(url, params);
};

// Support module.
export const getAllSupportModules = () => {
  const url = `${BASE_API}supports`;
  return getAxiosForRedux(url);
};

export const getSupportModules = (user_id) => {
  const url = `${BASE_API}user-supports?user_id=${user_id}`;
  return getAxiosForRedux(url);
};

export const createNewSupportModule = (params) => {
  const url = `${BASE_API}supports`;
  return postAxiosForRedux(url, params);
};

export const updateSupportModule = (id, params) => {
  const url = `${BASE_API}supports/${id}`;
  return putAxiosForRedux(url, params);
};

export const updateAuthorReadStatus = (id) => {
  const url = `${BASE_API}supports/read/author/${id}`;
  return postAxiosForRedux(url);
};

export const updateSupportReadStatus = (id) => {
  const url = `${BASE_API}supports/read/support/${id}`;
  return postAxiosForRedux(url);
};


export const deleteSupportModule = (id) => {
  const url = `${BASE_API}supports/${id}`;
  return deleteAxios(url);
};

export const closeSupportModule = (id, params) => {
  const url = `${BASE_API}supports/${id}`;
  return putAxiosForRedux(url, params);
};

export const updateSupportModuleHistory = (id, params) => {
  const url = `${BASE_API}support_history/${id}`;
  return putAxiosForRedux(url, params);
};

// me_variable
export const get_me_variable_select = () => {
  const url = `${BASE_API}me_variable`;
  return getAxiosForRedux(url);
};

// me_variable_rule
export const get_me_variable_rule = (type) => {
  const url = `${BASE_API}me_variable_rule?type=${type}`;
  return getAxiosForRedux(url);
};
export const save_me_variable_rule = (params) => {
  const url = `${BASE_API}me_variable_rule`;
  return postAxiosForRedux(url, params);
};
export const edit_me_variable_rule = (id, params) => {
  const url = `${BASE_API}me_variable_rule/${id}`;
  return putAxiosForRedux(url, params);
};
export const detail_me_variable_rule = (id) => {
  const url = `${BASE_API}me_variable_rule/${id}`;
  return getAxiosForRedux(url);
};

export const delete_me_variable_rule = (id) => {
  const url = `${BASE_API}me_variable_rule/${id}`;
  return deleteAxios(url);
};
export const update_patient_ops = (id) => {
  const url = `${BASE_API}patients/${id}/update_ops`;
  return postAxiosForRedux(url);
};

export const saveHospitalCompleted = (id, params) => {
  const url = `${BASE_API}procedures/${id}/save_hospital_info`;
  return postAxios(url, params);
};

export const finalizeProcedurePending = (id) => {
  const url = `${BASE_API}procedures/${id}/finalize`;
  return postAxios(url);
};

export const finalizePatientPending = (id) => {
  const url = `${BASE_API}patients/${id}/finalize`;
  return postAxios(url);
};

export const getExistingFollowUp = (id) => {
  const url = `${BASE_API}procedures/${id}/get_existing_followup`;
  return getAxiosForRedux(url);
};

export const getQuestionsForProcedure = (id) => {
  const url = `${BASE_API}procedures/${id}/load_questions`;
  return getAxiosForRedux(url);
};

export const saveProcedureChoice = (id, params) => {
  const url = `${BASE_API}procedures/${id}/save_choices`;
  return postAxiosForRedux(url, params);
};

export const getDeficiencyList = (params) => {
  const url = `${BASE_API}procedures/get_deficiency`;
  return postAxiosForRedux(url, params);
};

export const bulkFinalize = () => {
  const url = `${BASE_API}procedures/bulk_finalize`;
  return postAxios(url);
};

export const getProcedureCallAttempt = (procedure_id) => {
  const url = `${BASE_API}get_procedure_call_attempt?procedure_id=${procedure_id}`;
  return getAxiosForRedux(url);
}

export const addProcedureCallAttempt = (params) => {
  const url = `${BASE_API}clinic_reminders`;
  return postAxiosForRedux(url, params, {});
};

export const deleteProcedureCallAttempt = (id) => {
  let url = `${BASE_API}clinic_reminders/${id}`;
  return deleteAxios(url);
};