import React from 'react';

export const BloodVesselPlaque0 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
  </svg>
);

export const BloodVesselPlaque20 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel{fill:#fcd602;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel" d="M271.51,477.28a341.92,341.92,0,0,1,103.9-245.9,39.64,39.64,0,0,0,.36-56.45h0a39.66,39.66,0,0,0-45-7.83c-116.13,55-196.46,173.18-196.46,310.18s80.33,255.24,196.46,310.19a39.66,39.66,0,0,0,45-7.83h0a39.64,39.64,0,0,0-.36-56.45A341.92,341.92,0,0,1,271.51,477.28Z"/>
  </svg>
);

export const BloodVesselPlaque40 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel-1{fill:#fbd603;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel-1" d="M408.69,477.28A342,342,0,0,1,517.23,227c35.23-33,12.19-92.09-36.08-92.63l-3.86,0c-189,0-342.93,153.88-343,342.89,0,189.45,153.53,343,343,343l3.7,0c48.35-.51,71.54-59.58,36.25-92.63A342,342,0,0,1,408.69,477.28Z"/>
  </svg>
);

export const BloodVesselPlaque60 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel-1{fill:#fbd603;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel-1" d="M545.88,477.28a341.44,341.44,0,0,1,40.51-161.83c43.72-81.54-14.27-180.51-106.79-181.12h-2.32c-189.41,0-343,153.55-343,343s153.55,343,343,343h2.32c92.52-.61,150.51-99.59,106.79-181.12A341.45,341.45,0,0,1,545.88,477.28Z"/>
  </svg>
);

export const BloodVesselPlaque80 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel{fill:#fcd602;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel" d="M484.15,134.39c-1.91,0-3.83-.06-5.76-.07-189.38-.6-344.07,153.59-344.07,343s153.55,343,343,343q3,0,6,0C621.58,817.85,722.41,688.05,692,553.08a346,346,0,0,1,0-151.6C722.33,266.87,622.12,137.1,484.15,134.39Z"/>
  </svg>
);

export const BloodVesselPlaque100 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel{fill:#fcd602;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <circle className="b-vessel" cx="477.28" cy="477.28" r="342.96"/>
  </svg>
);

export const BloodVesselPlaqueStent0 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel-2{fill:#7cc7ff;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel-2" d="M552.54,855.29a20,20,0,0,1-4.1-39.58l.49-.1a345.39,345.39,0,0,0,266.66-410,349.88,349.88,0,0,0-9.28-34.86,20,20,0,1,1,38.07-12.27A385.53,385.53,0,0,1,557.29,854.72l-.61.13A19.63,19.63,0,0,1,552.54,855.29Zm-152.06-.24a20.35,20.35,0,0,1-4.23-.45A385.86,385.86,0,0,1,110.48,357.44a20,20,0,1,1,38,12.45,343.2,343.2,0,0,0-17.11,107A348.87,348.87,0,0,0,139,549a345.9,345.9,0,0,0,265.7,266.49,20,20,0,0,1-4.19,39.56Zm349-602.28a20,20,0,0,1-14.88-6.62,345.94,345.94,0,0,0-514-.69,20,20,0,1,1-29.7-26.79A383,383,0,0,1,397.28,99.84,385.22,385.22,0,0,1,764.34,219.39a20,20,0,0,1-14.86,33.38Z"/>
  </svg>
);

export const BloodVesselPlaqueStent20 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel{fill:#fcd602;}.c-vessel{fill:#7cc7ff;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel" d="M312.66,477.28a273.55,273.55,0,0,1,83.12-196.72,31.72,31.72,0,0,0,.3-45.16h0a31.74,31.74,0,0,0-36-6.26c-92.9,44-157.17,138.54-157.17,248.14s64.27,204.19,157.17,248.15a31.74,31.74,0,0,0,36-6.26h0a31.72,31.72,0,0,0-.3-45.16A273.55,273.55,0,0,1,312.66,477.28Z"/>
    <path className="c-vessel" d="M552.54,855.29a20,20,0,0,1-4.1-39.58l.57-.12a345.38,345.38,0,0,0,266.58-410,349.88,349.88,0,0,0-9.28-34.86,20,20,0,1,1,38.07-12.27A385.53,385.53,0,0,1,557.29,854.72l-.61.13A19.56,19.56,0,0,1,552.54,855.29Zm-152.06-.24a20.42,20.42,0,0,1-4.23-.45A385.86,385.86,0,0,1,110.48,357.44a20,20,0,1,1,38,12.45,343.2,343.2,0,0,0-17.11,107A348.87,348.87,0,0,0,139,549a345.9,345.9,0,0,0,265.7,266.49,20,20,0,0,1-4.19,39.56Zm349-602.28a20,20,0,0,1-14.88-6.62,345.94,345.94,0,0,0-514-.69,20,20,0,1,1-29.7-26.79A383,383,0,0,1,397.28,99.84,385.22,385.22,0,0,1,764.34,219.39a20,20,0,0,1-14.86,33.38Z"/>
  </svg>
);

export const BloodVesselPlaqueStent40 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel-1{fill:#fbd603;}.c-vessel{fill:#7cc7ff;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel-1" d="M422.41,477.28A273.54,273.54,0,0,1,509.24,277c28.18-26.4,9.75-73.68-28.86-74.11l-3.09,0c-151.22,0-274.35,123.1-274.38,274.32,0,151.55,122.83,274.42,274.37,274.42h3c38.68-.41,57.23-47.66,29-74.11A273.55,273.55,0,0,1,422.41,477.28Z"/>
    <path className="c-vessel" d="M552.54,855.29a20,20,0,0,1-4.11-39.58l.62-.13a345.38,345.38,0,0,0,266.54-410,349.88,349.88,0,0,0-9.28-34.86,20,20,0,0,1,38.07-12.28A385.37,385.37,0,0,1,778.3,718.65a384.22,384.22,0,0,1-221,136.07l-.6.13A20.33,20.33,0,0,1,552.54,855.29Zm-152.06-.24a20.42,20.42,0,0,1-4.23-.45A385.86,385.86,0,0,1,110.48,357.44a20,20,0,1,1,38,12.45,343.2,343.2,0,0,0-17.11,107A348.87,348.87,0,0,0,139,549a345.9,345.9,0,0,0,265.7,266.49,20,20,0,0,1-4.19,39.56Zm349-602.28a20,20,0,0,1-14.87-6.62,345.95,345.95,0,0,0-514-.69,20,20,0,1,1-29.7-26.79A383,383,0,0,1,397.28,99.84,385.22,385.22,0,0,1,764.34,219.39a20,20,0,0,1-14.86,33.38Z"/>
  </svg>
);

export const BloodVesselPlaqueStent60 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel-1{fill:#fbd603;}.c-vessel{fill:#7cc7ff;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel-1" d="M532.16,477.28a273.1,273.1,0,0,1,32.41-129.46c35-65.23-11.42-144.41-85.43-144.9h-1.86c-151.53,0-274.37,122.84-274.37,274.37S325.75,751.65,477.28,751.65h1.86c74-.49,120.41-79.67,85.43-144.9A273.11,273.11,0,0,1,532.16,477.28Z"/>
    <path className="c-vessel" d="M552.54,855.29a20,20,0,0,1-4.11-39.58l.62-.13a345.38,345.38,0,0,0,266.54-410,349.88,349.88,0,0,0-9.28-34.86,20,20,0,0,1,38.07-12.28A385.37,385.37,0,0,1,778.3,718.65a384.22,384.22,0,0,1-221,136.07l-.6.13A20.33,20.33,0,0,1,552.54,855.29Zm-152.06-.24a20.42,20.42,0,0,1-4.23-.45A385.86,385.86,0,0,1,110.48,357.44a20,20,0,1,1,38,12.45,343.2,343.2,0,0,0-17.11,107A348.87,348.87,0,0,0,139,549a345.9,345.9,0,0,0,265.7,266.49,20,20,0,0,1-4.19,39.56Zm349-602.28a20,20,0,0,1-14.87-6.62,345.95,345.95,0,0,0-514-.69,20,20,0,1,1-29.7-26.79A383,383,0,0,1,397.28,99.84,385.22,385.22,0,0,1,764.34,219.39a20,20,0,0,1-14.86,33.38Z"/>
  </svg>
);

export const BloodVesselPlaqueStent80 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel{fill:#fcd602;}.c-vessel{fill:#7cc7ff;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel" d="M482.78,203c-1.53,0-3.07-.05-4.61-.05-151.5-.48-275.26,122.86-275.26,274.37S325.75,751.65,477.28,751.65c1.59,0,3.18,0,4.76,0,110.68-1.88,191.34-105.71,167-213.69a277,277,0,0,1,0-121.28C673.32,309,593.15,205.14,482.78,203Z"/>
    <path className="c-vessel" d="M552.54,855.29a20,20,0,0,1-4.11-39.58l.62-.13a345.38,345.38,0,0,0,266.54-410,349.88,349.88,0,0,0-9.28-34.86,20,20,0,0,1,38.07-12.28A385.37,385.37,0,0,1,778.3,718.65a384.22,384.22,0,0,1-221,136.07l-.6.13A20.33,20.33,0,0,1,552.54,855.29Zm-152.06-.24a20.42,20.42,0,0,1-4.23-.45A385.86,385.86,0,0,1,110.48,357.44a20,20,0,1,1,38,12.45,343.2,343.2,0,0,0-17.11,107A348.87,348.87,0,0,0,139,549a345.9,345.9,0,0,0,265.7,266.49,20,20,0,0,1-4.19,39.56Zm349-602.28a20,20,0,0,1-14.87-6.62,345.95,345.95,0,0,0-514-.69,20,20,0,1,1-29.7-26.79A383,383,0,0,1,397.28,99.84,385.22,385.22,0,0,1,764.34,219.39a20,20,0,0,1-14.86,33.38Z"/>
  </svg>
);

export const BloodVesselPlaqueStent100 = () => (
  <svg viewBox="0 0 954.57 954.57">
    <defs><style>{`.a-vessel{fill:red;}.b-vessel-2{fill:#7cc7ff;}.c-vessel-1{fill:#fcd602;}`}</style></defs>
    <path className="a-vessel" d="M477.28,954.57a477.4,477.4,0,0,1-185.78-917A477.4,477.4,0,0,1,663.07,917.05,474.18,474.18,0,0,1,477.28,954.57Zm0-914.57a437.4,437.4,0,0,0-170.2,840.21A437.4,437.4,0,0,0,647.49,74.36,434.48,434.48,0,0,0,477.28,40Z"/>
    <path className="b-vessel-2" d="M552.54,855.29a20,20,0,0,1-4.09-39.58l.49-.1a345.39,345.39,0,0,0,266.65-410,349.88,349.88,0,0,0-9.28-34.86,20,20,0,1,1,38.07-12.27A385.53,385.53,0,0,1,557.29,854.72l-.62.14A20.19,20.19,0,0,1,552.54,855.29Zm-152.07-.24a20.32,20.32,0,0,1-4.22-.45A385.86,385.86,0,0,1,110.48,357.44a20,20,0,1,1,38,12.45,343.2,343.2,0,0,0-17.11,107A348.87,348.87,0,0,0,139,549a345.87,345.87,0,0,0,265.7,266.49,20,20,0,0,1-4.2,39.56Zm349-602.28a20,20,0,0,1-14.87-6.62,345.95,345.95,0,0,0-514-.69,20,20,0,1,1-29.7-26.79A383,383,0,0,1,397.28,99.84,385.22,385.22,0,0,1,764.34,219.39a20,20,0,0,1-14.86,33.38Z"/>
    <circle className="c-vessel-1" cx="477.28" cy="477.28" r="274.37"/>
  </svg>
);

export const IVUSIcon = () => (
  <svg viewBox="0 0 745.89 828.15">
    <defs><style>{`.a-ivus{fill:#7cc7ff;}`}</style></defs>
    <path className="a-ivus" d="M373,787A373,373,0,0,1,227.77,70.45,373,373,0,0,1,518.12,757.71,370.68,370.68,0,0,1,373,787Zm0-705.89A332.95,332.95,0,0,0,137.52,649.51,332.94,332.94,0,1,0,608.37,178.65,330.73,330.73,0,0,0,373,81.13Z"/>
    <path className="a-ivus" d="M373,645.84A231.76,231.76,0,0,1,141.18,414.08a20,20,0,0,1,40,0c0,105.74,86,191.76,191.77,191.76a20,20,0,0,1,0,40Z"/>
    <circle className="a-ivus" cx="372.95" cy="414.08" r="70.59"/>
    <path className="a-ivus" d="M373,504.67a90.59,90.59,0,1,1,90.58-90.59A90.69,90.69,0,0,1,373,504.67Zm0-141.18a50.59,50.59,0,1,0,50.58,50.59A50.64,50.64,0,0,0,373,363.49Z"/>
    <path className="a-ivus" d="M584.71,434.08a20,20,0,0,1-20-20c0-105.74-86-191.77-191.76-191.77a20,20,0,0,1,0-40A231.76,231.76,0,0,1,604.71,414.08,20,20,0,0,1,584.71,434.08Z"/>
    <polygon className="a-ivus" points="372.94 705.89 302.36 828.15 443.53 828.15 372.94 705.89"/>
    <polygon className="a-ivus" points="372.94 122.26 443.53 0 302.36 0 372.94 122.26"/>
  </svg>
);

export const AtherectomyDirectionalIcon = () => (
  <svg viewBox="0 0 246.22 828">
    <defs><style>{`.a-directional{fill:#5aaafa;}`}</style></defs>
    <path className="a-directional" d="M147.45,40A58.77,58.77,0,0,1,189,140.32l-49,49a57.68,57.68,0,0,0-16.91,40.81V538.67h83.11V746.44a41.56,41.56,0,1,1-83.11,0V676.28A230.87,230.87,0,0,0,98.74,573L64.37,504.3A230.83,230.83,0,0,1,40,401.06V147.45A58.77,58.77,0,0,1,57.21,105.9L105.9,57.21A58.77,58.77,0,0,1,147.45,40m0-40A98.81,98.81,0,0,0,77.61,28.93L28.93,77.61A98.81,98.81,0,0,0,0,147.45V401.06A272.22,272.22,0,0,0,28.6,522.19L63,590.92a191.89,191.89,0,0,1,20.15,85.36v70.16a81.56,81.56,0,1,0,163.11,0V498.67H163.11V230.13a17.6,17.6,0,0,1,5.19-12.53l49-49A98.77,98.77,0,0,0,147.45,0Z"/>
  </svg>
);

export const AtherectomyLaserIcon = () => (
  <svg viewBox="0 0 196.8 824" style={{ transform: 'rotate(180deg)' }}>
    <defs><style>{`.a-laser{fill:#00b4a0;}`}</style></defs>
    <path className="a-laser" d="M176.8,667.2a20,20,0,0,1-20-20V20a20,20,0,0,1,40,0V647.2A20,20,0,0,1,176.8,667.2Z"/>
    <path className="a-laser" d="M20,667.2a20,20,0,0,1-20-20V20a20,20,0,0,1,40,0V647.2A20,20,0,0,1,20,667.2Z"/>
    <path className="a-laser" d="M98.4,824A98.51,98.51,0,0,1,0,725.6V667.8a70.08,70.08,0,0,1,70-70h56.8a70.08,70.08,0,0,1,70,70v57.8A98.51,98.51,0,0,1,98.4,824ZM70,637.8a30,30,0,0,0-30,30v57.8a58.4,58.4,0,0,0,116.8,0V667.8a30,30,0,0,0-30-30Z"/>
  </svg>
);

export const AtherectomyRotationalIcon = () => (
  <svg viewBox="0 0 486.57 824" style={{ transform: 'rotate(180deg)' }}>
    <defs><style>{`.a-rotational{fill:#9855d4;}`}</style></defs>
    <path className="a-rotational" d="M243.28,824c-.57,0-1.15,0-1.72-.07a58.34,58.34,0,0,1-19.15-5.43c-39.35-18.12-97.29-86.11-147.59-173.24-33.2-57.5-58.72-116.18-70-161-1.94-7.69-3.48-15-4.56-21.8a20,20,0,0,1,1.9-12.18c8.46-16.74,30.25-29.89,66.6-40.23,44.47-12.64,108.08-19.89,174.53-19.89s130.07,7.25,174.54,19.89h0c36.35,10.34,58.13,23.49,66.59,40.23a20,20,0,0,1,1.9,12.18c-1.08,6.77-2.61,14.11-4.55,21.8-11.3,44.82-36.82,103.5-70,161-50.3,87.13-108.23,155.12-147.59,173.24A58.29,58.29,0,0,1,245,823.93C244.43,824,243.86,824,243.28,824ZM41.37,464.85c.64,3.1,1.39,6.32,2.22,9.63C54,515.87,78,570.82,109.47,625.26c52.52,91,104,145.1,129.66,156.9a27.79,27.79,0,0,0,4.15,1.57,27.39,27.39,0,0,0,4.15-1.57c25.64-11.8,77.15-65.93,129.67-156.9,31.43-54.44,55.44-109.39,65.87-150.78.84-3.31,1.58-6.53,2.23-9.63-5.21-4-16.6-10.14-38.32-16.32h0c-41.06-11.67-100.69-18.37-163.6-18.37s-122.53,6.7-163.59,18.37C58,454.7,46.57,460.89,41.37,464.85Z"/>
    <path className="a-rotational" d="M243.28,430.16a20,20,0,0,1-20-20V20a20,20,0,1,1,40,0V410.16A20,20,0,0,1,243.28,430.16Z"/>
  </svg>
);

export const AtherectomyTransluminalIcon = () => (
  <svg viewBox="0 0 411.28 824">
    <defs><style>{`.a-transluminal{fill:#b4e051;}`}</style></defs>
    <path className="a-transluminal" d="M298.46,824a20,20,0,0,1-20-20V692.46a159.7,159.7,0,0,1,8.21-50.6l65.79-197.37a366,366,0,0,0,18.82-116V90a50,50,0,0,0-50-50h-.08l-231.29.41A50.06,50.06,0,0,0,40,90.41V329.16a366.09,366.09,0,0,0,18.82,116l65.79,197.37a159.59,159.59,0,0,1,8.21,50.59V804a20,20,0,0,1-40,0V693.1a119.6,119.6,0,0,0-6.16-37.94L20.87,457.79A406,406,0,0,1,0,329.16V90.41A90.13,90.13,0,0,1,89.84.41L321.12,0h.17a90,90,0,0,1,90,90V328.52a406,406,0,0,1-20.87,128.62L324.62,654.51a119.71,119.71,0,0,0-6.16,38V804A20,20,0,0,1,298.46,824Z"/>
    <path className="a-transluminal" d="M205.64,596.92A64.93,64.93,0,0,1,142,546.17L109.84,405.09a70,70,0,0,1,68.25-85.57h55.1a70,70,0,0,1,68.25,85.57L269.26,546.17A64.92,64.92,0,0,1,205.64,596.92Zm-27.55-237.4a30,30,0,0,0-29.25,36.68L181,537.28a25.26,25.26,0,0,0,49.25,0L262.44,396.2a30,30,0,0,0-29.25-36.68Z"/>
  </svg>
);

export const BalloonIcon = () => (
  <svg viewBox="0 0 233.12 888.96">
    <defs><style>{`.a-balloon{fill:#db2780;}`}</style></defs>
    <path className="a-balloon" d="M116.56,889a20,20,0,0,1-20-20V20a20,20,0,0,1,40,0V869A20,20,0,0,1,116.56,889Z"/>
    <path className="a-balloon" d="M116.56,770.71A116.69,116.69,0,0,1,0,654.15V234.81a116.56,116.56,0,0,1,233.12,0V654.15A116.69,116.69,0,0,1,116.56,770.71Zm0-612.46A76.65,76.65,0,0,0,40,234.81V654.15a76.56,76.56,0,1,0,153.12,0V234.81A76.65,76.65,0,0,0,116.56,158.25Z"/>
  </svg>
);

export const StentBalloonCoveredIcon = () => (
  <svg viewBox="0 0 461.36 812.11">
    <defs><style>{`.a-stent-balloon-c{fill:#41d6c3;}`}</style></defs>
    <path className="a-stent-balloon-c" d="M116.42,348.54a21.38,21.38,0,0,1-17.21-8.73L43.54,263.59a21.21,21.21,0,0,1,0-25.13l55.67-76.21a21.38,21.38,0,0,1,17.2-8.73h0a21.39,21.39,0,0,1,17.2,8.74l55.67,76.21a21.2,21.2,0,0,1,0,25.12h0l-55.68,76.22A21.34,21.34,0,0,1,116.42,348.54ZM83.89,251l32.52,44.51L148.93,251l-32.52-44.51Zm73.1,11v0Z"/>
    <path className="a-stent-balloon-c" d="M231,348.54a21.33,21.33,0,0,1-17.2-8.74l-55.68-76.21a21.21,21.21,0,0,1,0-25.13l55.67-76.21a21.3,21.3,0,0,1,34.4,0l55.68,76.21a21.22,21.22,0,0,1,0,25.12h0l-55.68,76.22A21.35,21.35,0,0,1,231,348.54ZM198.47,251,231,295.54,263.51,251,231,206.52Zm73.09,11,0,0Z"/>
    <path className="a-stent-balloon-c" d="M116.42,503.57a21.39,21.39,0,0,1-17.21-8.74L43.54,418.62a21.23,21.23,0,0,1,0-25.12l55.68-76.22a21.39,21.39,0,0,1,17.2-8.74h0a21.39,21.39,0,0,1,17.2,8.74l55.67,76.21a21.23,21.23,0,0,1,0,25.13h0l-55.68,76.21A21.35,21.35,0,0,1,116.42,503.57ZM83.89,406.06l32.52,44.51,32.52-44.51-32.52-44.52Z"/>
    <path className="a-stent-balloon-c" d="M231,503.57a21.36,21.36,0,0,1-17.2-8.74l-55.68-76.21a21.25,21.25,0,0,1,0-25.12l55.68-76.22a21.29,21.29,0,0,1,34.4,0l55.68,76.21a21.23,21.23,0,0,1,0,25.13h0l-55.68,76.21A21.36,21.36,0,0,1,231,503.57Zm-32.52-97.51L231,450.57l32.52-44.51L231,361.54Z"/>
    <path className="a-stent-balloon-c" d="M345,348.54h0a21.33,21.33,0,0,1-17.2-8.74l-55.68-76.21a21.23,21.23,0,0,1,0-25.14l55.67-76.2a21.31,21.31,0,0,1,34.4,0l55.68,76.22a21.23,21.23,0,0,1,0,25.12h0l-55.68,76.22A21.38,21.38,0,0,1,345,348.54ZM312.43,251,345,295.54,377.47,251,345,206.52Zm-8,11,0,0Zm81.13-22,0,0Z"/>
    <path className="a-stent-balloon-c" d="M345,503.57h0a21.36,21.36,0,0,1-17.2-8.74l-55.68-76.21a21.23,21.23,0,0,1,0-25.13l55.68-76.21a21.29,21.29,0,0,1,34.4,0l55.68,76.21a21.25,21.25,0,0,1,0,25.12h0l-55.68,76.21A21.39,21.39,0,0,1,345,503.57Zm-32.52-97.51L345,450.57l32.52-44.51L345,361.54Zm73.09-11,0,0Z"/>
    <path className="a-stent-balloon-c" d="M116.42,658.59a21.38,21.38,0,0,1-17.21-8.73L43.54,573.65a21.22,21.22,0,0,1,0-25.12L99.21,472.3a21.38,21.38,0,0,1,17.2-8.73h0a21.39,21.39,0,0,1,17.2,8.74l55.67,76.21a21.21,21.21,0,0,1,0,25.14l-55.67,76.2A21.34,21.34,0,0,1,116.42,658.59ZM83.89,561.08l32.52,44.51,32.52-44.51-32.52-44.51Z"/>
    <path className="a-stent-balloon-c" d="M231,658.59a21.33,21.33,0,0,1-17.2-8.74l-55.68-76.2a21.22,21.22,0,0,1,0-25.12l55.68-76.23a21.3,21.3,0,0,1,34.4,0l55.68,76.21a21.23,21.23,0,0,1,0,25.14l-55.67,76.2A21.35,21.35,0,0,1,231,658.59Zm-32.52-97.51L231,605.59l32.52-44.51L231,516.57Z"/>
    <path className="a-stent-balloon-c" d="M345,658.59h0a21.33,21.33,0,0,1-17.2-8.74l-55.68-76.2a21.23,21.23,0,0,1,0-25.13l55.68-76.22a21.31,21.31,0,0,1,34.4,0l55.68,76.22a21.25,21.25,0,0,1,0,25.13l-55.68,76.21A21.38,21.38,0,0,1,345,658.59Zm-32.52-97.51L345,605.59l32.52-44.51L345,516.57Zm-8.07-11,0,0Z"/>
    <path className="a-stent-balloon-c" d="M461.36,697.84H0V114.27H461.36ZM40,657.84H421.36V154.27H40Z"/>
    <path className="a-stent-balloon-c" d="M230.68,812.11c-74,0-134.27-60.23-134.27-134.27h40a94.27,94.27,0,0,0,188.54,0h40C365,751.88,304.72,812.11,230.68,812.11Z"/>
    <path className="a-stent-balloon-c" d="M365,134.27H325a94.27,94.27,0,1,0-188.54,0h-40C96.41,60.23,156.65,0,230.68,0S365,60.23,365,134.27Z"/>
  </svg>
);

export const StentBalloonIcon = () => (
  <svg viewBox="0 0 382.53 733.61">
    <defs><style>{`.a-stent-balloon{fill:#7cc7ff;}`}</style></defs>
    <path className="a-stent-balloon" d="M191.27,733.61c-74,0-134.27-60.23-134.27-134.27H97a94.27,94.27,0,1,0,188.53,0h40C325.53,673.38,265.3,733.61,191.27,733.61Z"/>
    <path className="a-stent-balloon" d="M325.53,134.27h-40a94.27,94.27,0,1,0-188.53,0H57C57,60.23,117.23,0,191.27,0S325.53,60.23,325.53,134.27Z"/>
    <path className="a-stent-balloon" d="M77,309.29a21.39,21.39,0,0,1-17.2-8.72L4.12,224.35a21.2,21.2,0,0,1,0-25.14L59.8,123A21.38,21.38,0,0,1,77,114.27h0A21.35,21.35,0,0,1,94.2,123l55.68,76.21a21.23,21.23,0,0,1,0,25.13L94.2,300.56A21.37,21.37,0,0,1,77,309.29ZM44.48,211.78,77,256.29l32.52-44.51L77,167.27Zm73.09,11v0Zm-81.14,0v0Zm0-22,0,0Z"/>
    <path className="a-stent-balloon" d="M191.57,309.29h0a21.36,21.36,0,0,1-17.2-8.74l-55.67-76.2a21.21,21.21,0,0,1,0-25.14L174.38,123a21.34,21.34,0,0,1,17.19-8.73h0a21.38,21.38,0,0,1,17.2,8.72l55.68,76.23a21.22,21.22,0,0,1,0,25.11v0l-55.68,76.21A21.36,21.36,0,0,1,191.57,309.29Zm-32.51-97.51,32.52,44.51,32.52-44.51-32.52-44.51Zm-8.05,11v0Zm81.14-22h0Zm-81.16,0v0Z"/>
    <path className="a-stent-balloon" d="M77,464.32a21.38,21.38,0,0,1-17.2-8.73L4.12,379.37a21.2,21.2,0,0,1,0-25.14L59.8,278A21.39,21.39,0,0,1,77,269.29h0A21.38,21.38,0,0,1,94.2,278l55.68,76.21a21.23,21.23,0,0,1,0,25.13L94.2,455.58A21.35,21.35,0,0,1,77,464.32ZM44.48,366.81,77,411.32l32.52-44.51L77,322.29Zm73.09,11v0Zm-81.14,0v0Zm0-22.06,0,0Z"/>
    <path className="a-stent-balloon" d="M191.57,464.32h0a21.36,21.36,0,0,1-17.2-8.74L118.7,379.37a21.21,21.21,0,0,1,0-25.14L174.38,278a21.35,21.35,0,0,1,17.19-8.74h0a21.36,21.36,0,0,1,17.2,8.73l55.68,76.22a21.23,21.23,0,0,1,0,25.12h0l-55.68,76.21A21.34,21.34,0,0,1,191.57,464.32Zm-32.51-97.51,32.52,44.51,32.52-44.51-32.52-44.52Zm-8.05,11v0Zm81.14-22h0Zm-81.16,0v0Z"/>
    <path className="a-stent-balloon" d="M305.53,309.29h0a21.35,21.35,0,0,1-17.19-8.74l-55.67-76.2a21.21,21.21,0,0,1,0-25.14L288.33,123a21.37,21.37,0,0,1,17.19-8.73h0a21.39,21.39,0,0,1,17.2,8.72l55.68,76.23a21.21,21.21,0,0,1,0,25.13l-55.68,76.21A21.38,21.38,0,0,1,305.53,309.29ZM273,211.78l32.52,44.51,32.52-44.51-32.52-44.51Zm73.1,11v0Zm-81.14,0,0,0Zm0-22v0Z"/>
    <path className="a-stent-balloon" d="M305.53,464.32h0a21.35,21.35,0,0,1-17.19-8.74l-55.67-76.21a21.21,21.21,0,0,1,0-25.14L288.33,278a21.38,21.38,0,0,1,17.19-8.74h0a21.38,21.38,0,0,1,17.2,8.73l55.68,76.22a21.21,21.21,0,0,1,0,25.13l-55.68,76.21A21.36,21.36,0,0,1,305.53,464.32ZM273,366.81l32.52,44.51,32.52-44.51-32.52-44.52Zm73.1,11v0Zm-81.14,0,0,0Zm0-22.06v0Z"/>
    <path className="a-stent-balloon" d="M77,619.34a21.39,21.39,0,0,1-17.2-8.72L4.12,534.4a21.2,21.2,0,0,1,0-25.14L59.8,433.05A21.38,21.38,0,0,1,77,424.32h0a21.35,21.35,0,0,1,17.19,8.74l55.68,76.21a21.23,21.23,0,0,1,0,25.13L94.2,610.61A21.37,21.37,0,0,1,77,619.34ZM44.48,521.83,77,566.34l32.52-44.51L77,477.32Zm73.09,11v0Zm-81.14,0v0Zm0-22.05,0,0Z"/>
    <path className="a-stent-balloon" d="M191.57,619.34h0a21.36,21.36,0,0,1-17.2-8.74L118.7,534.4a21.21,21.21,0,0,1,0-25.14l55.68-76.21a21.34,21.34,0,0,1,17.19-8.73h0a21.36,21.36,0,0,1,17.2,8.73l55.68,76.22a21.22,21.22,0,0,1,0,25.11v0l-55.68,76.21A21.36,21.36,0,0,1,191.57,619.34Zm-32.51-97.51,32.52,44.51,32.52-44.51-32.52-44.51Zm-8.05,11v0Zm81.14-22h0Zm-81.16,0v0Z"/>
    <path className="a-stent-balloon" d="M305.53,619.34h0a21.35,21.35,0,0,1-17.19-8.74l-55.67-76.2a21.21,21.21,0,0,1,0-25.14l55.67-76.21a21.37,21.37,0,0,1,17.19-8.73h0a21.38,21.38,0,0,1,17.2,8.73l55.68,76.22a21.21,21.21,0,0,1,0,25.13l-55.68,76.21A21.38,21.38,0,0,1,305.53,619.34ZM273,521.83l32.52,44.51,32.52-44.51-32.52-44.51Zm73.1,11v0Zm-81.14,0,0,0Zm0-22.05v0Z"/>
  </svg>
);

export const StentCoveredIcon = () => (
  <svg viewBox="0 0 461.36 583.58">
    <defs><style>{`.a-stent-covered{fill:#ba8ff7;}`}</style></defs>
    <path className="a-stent-covered" d="M116.41,234.28a21.39,21.39,0,0,1-17.19-8.73L43.54,149.33a21.21,21.21,0,0,1,0-25.14L99.21,48a21.39,21.39,0,0,1,17.2-8.73h0A21.37,21.37,0,0,1,133.62,48l55.67,76.21a21.21,21.21,0,0,1,0,25.13l-55.68,76.21a21.39,21.39,0,0,1-17.19,8.74ZM83.89,136.76l32.52,44.52,32.52-44.52L116.41,92.25Zm73.1,11v0Zm-81.14,0,0,0Zm0-22.05v0Z"/>
    <path className="a-stent-covered" d="M231,234.28h0a21.39,21.39,0,0,1-17.19-8.75l-55.68-76.2a21.23,21.23,0,0,1,0-25.14L213.79,48A21.37,21.37,0,0,1,231,39.25h0A21.34,21.34,0,0,1,248.18,48l55.69,76.22a21.26,21.26,0,0,1,0,25.12h0l-55.68,76.21A21.39,21.39,0,0,1,231,234.28Zm-32.52-97.52L231,181.28l32.52-44.52L231,92.25Zm-8,11h0Zm81.13-22v0Zm-81.16,0,0,0Z"/>
    <path className="a-stent-covered" d="M116.41,389.3a21.36,21.36,0,0,1-17.19-8.73L43.54,304.35a21.21,21.21,0,0,1,0-25.14L99.21,203a21.39,21.39,0,0,1,17.2-8.73h0a21.37,21.37,0,0,1,17.2,8.74l55.67,76.2a21.21,21.21,0,0,1,0,25.13l-55.68,76.22a21.36,21.36,0,0,1-17.19,8.73ZM83.89,291.79l32.52,44.51,32.52-44.51-32.52-44.51Zm73.1,11v0Zm-81.14,0,0,0Zm0-22.05v0Z"/>
    <path className="a-stent-covered" d="M231,389.3h0a21.35,21.35,0,0,1-17.19-8.74l-55.68-76.21a21.23,21.23,0,0,1,0-25.14L213.79,203A21.37,21.37,0,0,1,231,194.28h0A21.36,21.36,0,0,1,248.18,203l55.69,76.22a21.26,21.26,0,0,1,0,25.12h0l-55.68,76.22A21.38,21.38,0,0,1,231,389.3Zm-32.52-97.51L231,336.3l32.52-44.51L231,247.28Zm-8,11,0,0Zm81.13-22h0Zm-81.16,0,0,0Z"/>
    <path className="a-stent-covered" d="M345,234.28h0a21.39,21.39,0,0,1-17.19-8.75l-55.68-76.2a21.23,21.23,0,0,1,0-25.14L327.75,48a21.37,21.37,0,0,1,17.19-8.73h0A21.34,21.34,0,0,1,362.14,48l55.69,76.22a21.23,21.23,0,0,1,0,25.13l-55.68,76.21A21.39,21.39,0,0,1,345,234.28Zm-32.52-97.52L345,181.28l32.52-44.52L345,92.25Zm73.09,11,0,0Zm-81.14,0h0Zm0-22.06,0,0Z"/>
    <path className="a-stent-covered" d="M345,389.3h0a21.35,21.35,0,0,1-17.19-8.74l-55.68-76.21a21.23,21.23,0,0,1,0-25.14L327.75,203a21.37,21.37,0,0,1,17.19-8.73h0A21.36,21.36,0,0,1,362.14,203l55.69,76.22a21.23,21.23,0,0,1,0,25.13l-55.68,76.22A21.38,21.38,0,0,1,345,389.3Zm-32.52-97.51L345,336.3l32.52-44.51L345,247.28Zm73.09,11,0,0Zm-81.14,0v0Zm0-22.05,0,0Z"/>
    <path className="a-stent-covered" d="M116.41,544.33a21.39,21.39,0,0,1-17.19-8.73L43.54,459.38a21.21,21.21,0,0,1,0-25.14L99.21,358a21.37,21.37,0,0,1,17.2-8.74h0a21.37,21.37,0,0,1,17.2,8.74l55.67,76.21a21.21,21.21,0,0,1,0,25.13l-55.68,76.21a21.37,21.37,0,0,1-17.19,8.74ZM83.89,446.81l32.52,44.52,32.52-44.52L116.41,402.3Zm73.1,11v0Zm-81.14,0,0,0Zm0-22.06v0Z"/>
    <path className="a-stent-covered" d="M231,544.33h0a21.36,21.36,0,0,1-17.19-8.75l-55.68-76.2a21.23,21.23,0,0,1,0-25.14L213.79,358A21.35,21.35,0,0,1,231,349.3h0A21.34,21.34,0,0,1,248.18,358l55.69,76.22a21.26,21.26,0,0,1,0,25.12h0l-55.68,76.21A21.39,21.39,0,0,1,231,544.33Zm-32.52-97.52L231,491.33l32.52-44.52L231,402.3Zm-8,11,0,0Zm81.13-22v0Zm-81.16,0,0,0Z"/>
    <path className="a-stent-covered" d="M345,544.33h0a21.36,21.36,0,0,1-17.19-8.75l-55.68-76.2a21.23,21.23,0,0,1,0-25.14L327.75,358a21.35,21.35,0,0,1,17.19-8.74h0A21.34,21.34,0,0,1,362.14,358l55.69,76.22a21.23,21.23,0,0,1,0,25.13l-55.68,76.21A21.39,21.39,0,0,1,345,544.33Zm-32.52-97.52L345,491.33l32.52-44.52L345,402.3Zm73.09,11,0,0Zm-81.14,0v0Zm0-22.06,0,0Z"/>
    <path className="a-stent-covered" d="M461.36,583.58H0V0H461.36ZM40,543.58H421.36V40H40Z"/>
  </svg>
);

export const StentIcon = () => (
  <svg viewBox="0 0 382.53 505.08">
    <defs><style>{`.a-stent{fill:#ff71d4;}`}</style></defs>
    <path className="a-stent" d="M77,195a21.4,21.4,0,0,1-17.2-8.73L4.12,110.08a21.23,21.23,0,0,1,0-25.14L59.8,8.73A21.38,21.38,0,0,1,77,0h0A21.35,21.35,0,0,1,94.2,8.74L149.88,85a21.23,21.23,0,0,1,0,25.13L94.2,186.29A21.41,21.41,0,0,1,77,195ZM44.48,97.51,77,142l32.52-44.52L77,53Zm73.09,11h0Zm-81.14,0v0Zm0-22,0,0Z"/>
    <path className="a-stent" d="M191.58,195h0a21.41,21.41,0,0,1-17.2-8.75l-55.67-76.2a21.2,21.2,0,0,1,0-25.14L174.38,8.73A21.36,21.36,0,0,1,191.57,0h0a21.36,21.36,0,0,1,17.19,8.73L264.45,85a21.23,21.23,0,0,1,0,25.12h0l-55.68,76.21A21.37,21.37,0,0,1,191.58,195ZM159.06,97.51,191.58,142,224.1,97.51,191.58,53Zm-8.05,11v0Zm81.14-22h0Zm-81.16,0v0Z"/>
    <path className="a-stent" d="M77,350.05a21.38,21.38,0,0,1-17.2-8.73L4.12,265.1a21.23,21.23,0,0,1,0-25.14l55.67-76.2A21.38,21.38,0,0,1,77,155h0a21.35,21.35,0,0,1,17.19,8.74L149.88,240a21.23,21.23,0,0,1,0,25.13L94.2,341.32A21.37,21.37,0,0,1,77,350.05ZM44.48,252.54,77,297.05l32.52-44.51L77,208Zm73.09,11v0Zm-81.14,0v0Zm0-22.05,0,0Z"/>
    <path className="a-stent" d="M191.58,350.05h0a21.37,21.37,0,0,1-17.2-8.74L118.7,265.1a21.2,21.2,0,0,1,0-25.14l55.67-76.2A21.36,21.36,0,0,1,191.57,155h0a21.38,21.38,0,0,1,17.19,8.72L264.45,240a21.23,21.23,0,0,1,0,25.12h0l-55.68,76.22A21.36,21.36,0,0,1,191.58,350.05Zm-32.52-97.51,32.52,44.51,32.52-44.51L191.58,208Zm-8.05,11v0Zm81.14-22h0Zm-81.16,0v0Z"/>
    <path className="a-stent" d="M305.53,195h0a21.39,21.39,0,0,1-17.19-8.75l-55.67-76.2a21.21,21.21,0,0,1,0-25.14L288.33,8.73A21.37,21.37,0,0,1,305.52,0h0a21.38,21.38,0,0,1,17.2,8.73L378.41,85a21.21,21.21,0,0,1,0,25.13l-55.68,76.21A21.39,21.39,0,0,1,305.53,195ZM273,97.51,305.53,142l32.52-44.52L305.53,53Zm73.1,11h0Zm-81.14,0,0,0Zm0-22v0Z"/>
    <path className="a-stent" d="M305.53,350.05h0a21.35,21.35,0,0,1-17.19-8.74L232.66,265.1a21.21,21.21,0,0,1,0-25.14l55.67-76.2A21.37,21.37,0,0,1,305.52,155h0a21.39,21.39,0,0,1,17.2,8.72L378.41,240a21.21,21.21,0,0,1,0,25.13l-55.68,76.22A21.38,21.38,0,0,1,305.53,350.05ZM273,252.54l32.52,44.51,32.52-44.51L305.53,208Zm73.1,11v0Zm-81.14,0,0,0Zm0-22.05v0Z"/>
    <path className="a-stent" d="M77,505.08a21.4,21.4,0,0,1-17.2-8.73L4.12,420.13a21.23,21.23,0,0,1,0-25.14l55.67-76.2A21.36,21.36,0,0,1,77,310.05h0a21.35,21.35,0,0,1,17.19,8.74L149.88,395a21.23,21.23,0,0,1,0,25.13L94.2,496.34A21.38,21.38,0,0,1,77,505.08ZM44.48,407.56,77,452.08l32.52-44.52L77,363.05Zm73.09,11v0Zm-81.14,0v0Zm0-22.06,0,0Z"/>
    <path className="a-stent" d="M191.58,505.08h0a21.38,21.38,0,0,1-17.2-8.75l-55.67-76.2a21.2,21.2,0,0,1,0-25.14l55.67-76.2a21.34,21.34,0,0,1,17.19-8.74h0a21.36,21.36,0,0,1,17.19,8.73L264.45,395a21.23,21.23,0,0,1,0,25.12h0l-55.68,76.21A21.37,21.37,0,0,1,191.58,505.08Zm-32.52-97.52,32.52,44.52,32.52-44.52-32.52-44.51Zm-8.05,11v0Zm81.14-22v0Zm-81.16,0v0Z"/>
    <path className="a-stent" d="M305.53,505.08h0a21.36,21.36,0,0,1-17.19-8.75l-55.67-76.2a21.21,21.21,0,0,1,0-25.14l55.67-76.2a21.35,21.35,0,0,1,17.19-8.74h0a21.38,21.38,0,0,1,17.2,8.73L378.41,395a21.21,21.21,0,0,1,0,25.13l-55.68,76.21A21.39,21.39,0,0,1,305.53,505.08ZM273,407.56l32.52,44.52,32.52-44.52-32.52-44.51Zm73.1,11v0Zm-81.14,0,0,0Zm0-22.06v0Z"/>
  </svg>
);

export const UltrasoundProbeIcon = () => (
  <svg viewBox="0 0 831.38 555.66" style={{ transform: 'rotate(90deg)' }}>
    <defs><style>{`.a-prob{fill:#5aaafa;}`}</style></defs>
    <path className="a-prob" d="M714.21,169.39a19.94,19.94,0,0,1,5.48,10.29,517.31,517.31,0,0,1,.23,195.14,20,20,0,0,1-17.13,16.05L584.68,405.73a90.37,90.37,0,0,0-78.93,89.49,20,20,0,0,1-40,0A130.42,130.42,0,0,1,579.69,366l103.47-13A479.44,479.44,0,0,0,683,201.61L579.85,189.15a129.74,129.74,0,0,1-114.1-128.7,20,20,0,0,1,40,0,89.71,89.71,0,0,0,78.89,89l117.82,14.23A20,20,0,0,1,714.21,169.39Z"/>
    <path className="a-prob" d="M820.06,182.17a20,20,0,0,1,5.68,11.45A632.19,632.19,0,0,1,826,360.33a20,20,0,0,1-17.33,17.21L702.79,390.87a20,20,0,0,1-22.14-23.65,471.67,471.67,0,0,0,8.44-89.38h0a471.27,471.27,0,0,0-8.65-90.45,20,20,0,0,1,22-23.71l105.86,12.79A20,20,0,0,1,820.06,182.17ZM788.14,339.81A594.06,594.06,0,0,0,788,214.29l-63.77-7.7a517.75,517.75,0,0,1,4.89,71.25h0a517.28,517.28,0,0,1-4.72,70Z"/>
    <path className="a-prob" d="M499.89,46.31a20,20,0,0,1,2.53,25.19c-2.29,3.46-4.55,7.09-6.72,10.81-29.38,50.34-46.23,121.61-46.23,195.53S466.32,423,495.7,473.37c2.16,3.7,4.42,7.33,6.72,10.8a20,20,0,0,1-8.86,29.46l-95.28,40.43a20,20,0,0,1-25.07-8.31l-.38-.63c-42.34-72.56-65.65-167.47-65.65-267.28S330.49,83.11,372.81,10.59l.44-.73a20.06,20.06,0,0,1,25-8.25L493.56,42A19.86,19.86,0,0,1,499.89,46.31ZM456.8,485.78c-30.15-55.63-47.33-130.8-47.33-207.95S426.65,125.52,456.8,69.89L399.23,45.46c-33.64,64.44-52.05,146.22-52.05,232.38s18.4,167.9,52.05,232.37Z"/>
    <path className="a-prob" d="M253.22,132.31A20,20,0,0,1,258.59,151a567.23,567.23,0,0,0-14,126.89,567.21,567.21,0,0,0,14,126.88,20,20,0,0,1-39,9,607.55,607.55,0,0,1-15-135.84A607.64,607.64,0,0,1,219.6,142a20,20,0,0,1,33.62-9.68Z"/>
    <path className="a-prob" d="M636.47,263.69a20,20,0,0,1-14.14,34.15H516.23a20,20,0,0,1,0-40h106.1A19.9,19.9,0,0,1,636.47,263.69Z"/>
    <path className="a-prob" d="M171.14,65.69A20,20,0,0,1,175.67,87c-21.84,56.85-33.38,122.83-33.38,190.83s11.54,134,33.38,190.82A20,20,0,1,1,138.33,483c-23.58-61.37-36-132.32-36-205.16s12.46-143.8,36-205.17a20,20,0,0,1,32.81-7Z"/>
    <path className="a-prob" d="M97.45,5.87a20,20,0,0,1,3.1,24.24l-.4.68C61.37,97.25,40,185,40,277.84s21.37,180.59,60.17,247.08l.37.6a20,20,0,1,1-34.49,20.26L66,545.7l-.36-.62C23.31,472.56,0,377.65,0,277.84S23.31,83.11,65.63,10.59L66,9.9a20,20,0,0,1,31.41-4Z"/>
  </svg>
);
