import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ScrollWrapper from "../../../../../common/components/ScrollBar";
import EditableFormInput from "../../../../../common/components/EditableText/EditableFormInput";
import { adminSettingSaveOCRInfo } from "../../../../../store/actions";

const DocumentOCRSettings = ({resource}) => {
  const [ocrEmail, setOCREmail] = useState('');
  const [fax, setFax] = useState('');
  const [phone, setPhone] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!resource.loading) {
      setOCREmail(resource.ocr_email);
      setFax(resource.ocr_fax_number);
      setPhone(resource.ocr_phone_number);
    }
  }, [resource]);

  const handlInputChange = (key, val) => {
    const payload = {
      type: "ocr",
      key: key,
      value: val
    };
    dispatch(adminSettingSaveOCRInfo(payload));
  }
  
  return (
    <div
      className="documentOcrSetting"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <ScrollWrapper css="no-padding x-hidden">
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="OCR Email"
          value={ocrEmail}
          onCustomBlur={()=>handlInputChange('ocr_email', ocrEmail)}
          handleChange={(val) => setOCREmail(val)}
        />
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="Fax Number"
          value={fax}
          mask={'phone'}
          onCustomBlur={()=>handlInputChange('ocr_fax_number', fax)}
          handleChange={(val) => setFax(val)}
        />
        <div style={{ marginTop: '0.5rem' }} />
        <EditableFormInput
          label="Phone Number"
          value={phone}
          mask={'phone'}
          onCustomBlur={()=>handlInputChange('ocr_phone_number', phone)}
          handleChange={(val) => setPhone(val)}
        />
      </ScrollWrapper>
    </div>
  );
}

export default DocumentOCRSettings;
