import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { GearIconSVG } from "../../../common/components/icons/SVGIcon";
import { useWindowSize } from "../../../common/utils";
import {
  getProcedureCallback,
  selectProcedure,
  setProcedureData,
  getProcedureCallAttempt,
} from "../../../store/actions";
import { Icon } from "antd";
import DataTable from "../../../common/components/DataTable/DataTable";

const filterSuggestion = (suggestions, userInput) => {
  if (!userInput) {
    return suggestions;
  }
  let filteredSuggestions = [];

  filteredSuggestions = suggestions.filter(
    (suggestion) =>
      suggestion.patient_name &&
      suggestion.patient_name
        .split(" ")
        .join("")
        .toLowerCase()
        .indexOf(userInput.split(" ").join("").toLowerCase()) > -1
  );

  return filteredSuggestions;
};

const CallbackTable = ({
  title,
  search,
  selectedType,
  handleSidebar,
  handleSelect,
}) => {
  const ref = useRef(null);
  const loading = useSelector((state) => state.procedure.loadingCallback);
  const data = useSelector((state) => state.procedure.callback);
  const isConfirmed = useSelector((state) => state.procedure.isConfirmed);

  const dispatch = useDispatch();

  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (isConfirmed && !loading) {
      // dispatch(setProcedureData("loadingCallback", true));
      // dispatch(getProcedureCallback({}));
      // setRow(-1);
    }
  }, [isConfirmed]);

  useEffect(() => {
    // checkb
    if (!data && !loading) {
      dispatch(setProcedureData("loadingCallback", true));
      dispatch(getProcedureCallback({}));
    }
  }, [data]);

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  const tableRows = useMemo(() => {
    if (data) {
      setOffset(0);
      return filterSuggestion(data, search);
    }
    return [];
  }, [data, search]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(30, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index) => {
    setRow(index);
    dispatch(setProcedureData("loadingResource", true));
    dispatch(selectProcedure(rowData[index]));
    dispatch(getProcedureCallAttempt(rowData[index].id));
    handleSelect("call-back");
    handleSidebar();
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array((rowCount + 1) - tableRows.length).fill({});
  }

  const onScroll = (values) => {
    if (values.scrollTop > rowData.length * 30 && offset < tableRows.length) {
      const count = Math.min(30, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  return (
    <div ref={ref} className="tableContainer">
      <ScrollWrapper css="no-padding no-b-padding x-hidden" onScrollFrame={onScroll}>
        <div className="tableList">
          <DataTable
            isXHidden={true}
            tableWidth={'100%'}
            title="new_patient_pending"
            isNormal={true}
            labels={[
              "Patient Name",
              "Procedure",
              "Appointment Date"
            ]}
            widths={[
              '30%',
              '40%',
              '30%'
            ]}
            defaultActive={-1}
            disableDelete={true}
            columns={[
              {sortable: false, key: 'name'},
              {sortable: false, key: 'referral_condition'},
              {sortable: false, key: 'referral_date'},
            ]}
            handleClickRow={(row, index) => {handleRow(index)} }
            rowData={(rowData || []).map((row, index) => {
              return { 
                name: (
                  <div
                    className="iconField td with-icon"
                    style={{
                      display: "flex",
                      paddingLeft: "0.25rem",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Icon type="phone" theme="twoTone" twoToneColor="#F1C21B" />
                    <p style={{ paddingLeft: "0.75rem" }}>{row.patient && row.patient.name}</p>
                  </div>
                ),
                referral_condition: row.procedure_type && row.procedure_type.value,
                referral_date: row.appointment_date && moment(row.appointment_date).format("MM/DD/YYYY HH:mm")
              }
            })}
          />
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default CallbackTable;
