import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import { useDebounce } from "use-lodash-debounce";
import moment from "moment";
import { SearchSVG } from "../../../common/components/icons/SVGIcon";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import Loading from "../../../common/components/Loading";
import ScrollWrapper from "../../../common/components/ScrollBar";
import {
  useWindowSize,
  useOutsideDetect,
  getValueFromObjectArray,
} from "../../../common/utils";
import {
  setDashboardDemographics,
  setGlobalLoading,
} from "../../../store/actions";
import { getPatients } from "../../../services/api.services";

const { Search } = Input;

const PatientDemographics = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const patientDemographic = useSelector(
    (state) => state.dashboard.patientDemographics
  );
  const pcpList = useSelector((state) => state.common.pcpList);
  const providers = useSelector((state) => state.common.providers);
  const loadingPatients = useSelector(
    (state) => state.dashboard.loadingPatients
  );
  const loadingWideMess = useSelector(
    (state) => state.dashboard.loadingWideMess
  );
  const loadingAdd = useSelector((state) => state.dashboard.addingPatient);
  const loadingHistory = useSelector((state) => state.dashboard.loadingHistory);
  const dispatch = useDispatch();

  const [openPanel, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeSearch, setActiveSearch] = useState(-1);
  const [activeSearchRow, setActiveSearchRecords] = useState(null);
  const [patients, setPatients] = useState([]);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    dispatch(setDashboardDemographics(null));
  }, []);

  useEffect(() => {
    if (debouncedValue && openPanel) {
      fetchPatients(search);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (ref2 && ref2.current) {
      const initialSize = {
        width: ref2.current.clientWidth,
        height: ref2.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref2]);

  useEffect(() => {
    if (
      loading ||
      loadingPatients ||
      loadingWideMess ||
      loadingAdd ||
      loadingHistory
    ) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [loading, loadingPatients, loadingWideMess, loadingAdd, loadingHistory]);

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const size = useWindowSize(ref2);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = 5;
  }
  if (size.height) {
    rowCount = Math.floor(size.height / (16 * 2.5));
  } else {
    rowCount = Math.floor(initialSize.height / (16 * 2.5));
  }

  const fetchPatients = async (query = "") => {
    setLoading(true);
    const result = await getPatients(query);
    if (result && result.length > 0) {
      setPatients(result);
      setLoading(false);
    } else {
      setPatients([]);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOpen(true);
  };

  const handleSearchSelect = (row, index) => {
    setActiveSearch(index);
    setActiveSearchRecords(row);
    setSearch(
      `${row.first_name || ""} ${row.last_name || ""} - ${
        row.date_of_birth && moment(row.date_of_birth).format("MM/DD/YYYY")
      }`
    );
    setOpen(false);

    handleAddHistory(row);
  };

  const handleAddHistory = (searchRow = null) => {
    if (activeSearchRow) {
      setActiveRow(0);
      dispatch(setDashboardDemographics(activeSearchRow));
      setActiveSearchRecords(null);
    } else if (searchRow) {
      setActiveRow(0);
      dispatch(setDashboardDemographics(searchRow));
      setActiveSearchRecords(null);
    }
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const handleKeyPress = async () => {
    if (activeSearchRow) {
      setActiveRow(0);
      dispatch(setDashboardDemographics(activeSearchRow));
      setActiveSearchRecords(null);
    }
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (rowData && rowData[activeSearch + 1]) {
        setActiveSearchRecords(rowData[activeSearch + 1]);
        setActiveSearch(activeSearch + 1);
      }
    } else {
      setActiveSearch(activeSearch - 1);
      setActiveSearchRecords(rowData[activeSearch - 1]);
    }
  };

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div ref={ref} className="patientSearch__filter-search">
          <Search
            value={search}
            placeholder="Search Patient"
            onChange={handleSearch}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 40) {
                onKeyDown("down");
              } else if (e.keyCode == 38) {
                onKeyDown("up");
              }
            }}
            onPressEnter={handleKeyPress}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
            onFocus={() => setOpen(true)}
          />
        </div>
        {/* <div className="patientSearch__filter-action">
          <button
            className="patientSearch__filter-add"
            onClick={handleAddHistory}
          >
            <span>Search</span>
            <span>+</span>
          </button>
        </div> */}
      </div>
      {openPanel && search && rowCount !== NaN && rowData.length > 0 && (
        <div
          ref={ref1}
          className="fullContainer patientSearch__result"
          style={rowData.length <= rowCount - 1 ? { height: "auto" } : {}}
        >
          <div
            className="tableSection"
            style={{
              height: rowData.length <= rowCount - 1 ? "auto" : "100%",
              position: "relative",
            }}
          >
            {loading && <Loading />}
            <div className="tableContainer">
              <ScrollWrapper
                css="no-padding x-hidden"
                onScrollFrame={onScroll}
                disableScroll={rowData.length <= rowCount - 1}
              >
                <div className="tableList">
                  {rowData.map((row, index) => (
                    <div
                      className={`tableItem ${index === 0 ? "no-border" : ""} ${
                        index === activeSearch ? "active" : ""
                      }`}
                      key={`resultTable-${index}`}
                      onClick={() => handleSearchSelect(row, index)}
                    >
                      <div className="td with-icon" style={{ width: "100%" }}>
                        <p>{`${row.first_name || ""} ${row.last_name || ""} - ${
                          row.date_of_birth &&
                          moment(row.date_of_birth).format("MM/DD/YYYY")
                        }`}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      )}
      <div
        className="fullContainer"
        style={{ padding: "1rem 0 0 0", position: "relative" }}
      >
        <div
          ref={ref2}
          className="demographicSection"
          style={{ width: "100%", height: "100%" }}
        >
          <ScrollWrapper css="no-padding x-hidden">
            {patientDemographic && Object.keys(patientDemographic).length > 0 && (
              <div style={{ width: "100%", paddingTop: "1rem" }}>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="Patient Name"
                    value={`${patientDemographic.first_name} ${patientDemographic.last_name}`}
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="DOB"
                    value={
                      patientDemographic &&
                      patientDemographic.date_of_birth &&
                      moment(patientDemographic.date_of_birth).format(
                        "MM/DD/YYYY"
                      )
                    }
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="Address"
                    value={patientDemographic.address}
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="State"
                    value={patientDemographic.state}
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="City"
                    value={patientDemographic.city}
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="Zip Code"
                    value={patientDemographic.zipcode}
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="Phone No"
                    value={patientDemographic.phone_number}
                    mask={"phone"}
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="Referring"
                    value={
                      patientDemographic &&
                      getValueFromObjectArray(
                        providers,
                        patientDemographic.referral_provider,
                        "id",
                        "value"
                      )
                    }
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="PCP"
                    value={
                      patientDemographic &&
                      getValueFromObjectArray(
                        pcpList && pcpList.active_pcp
                          ? pcpList.active_pcp
                          : pcpList,
                        patientDemographic.primary_care_provider_id,
                        "id",
                        "full_name"
                      )
                    }
                    fullWidth
                  />
                </div>
              </div>
            )}
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default PatientDemographics;
