import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, DatePicker, Input } from "antd";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import { SortingArrowIcon } from "../../../../common/components/icons/SortingIcon";
import {
  procedureConsentSelectDocument,
  procedureConsentSearch,
  procedureConsentSetSearchData,
} from "../../../../store/actions";
import ScrollWrapper from "../../../../common/components/ScrollBar";

const ConsentDocumentList = ({}) => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const consentDocuments = useSelector(
    (state) => state.procedureConsent.consentDocuments
  );
  const allProcedures = useSelector(
    (state) => state.procedureConsent.allProcedures
  );
  const allProviders = useSelector(
    (state) => state.procedureConsent.allProviders
  );
  const selectDocument = (selectedDocumentData) => {
    dispatch(procedureConsentSelectDocument(selectedDocumentData));
  };
  const patientResource = useSelector(
    (state) => state.patientSchedule.selectedPatientResource
  );
  const p_searchData = useSelector(
    (state) => state.procedureConsent.searchData
  );
  const [searchData, setSearchData] = useState(p_searchData);
  const [sortOption, setSortOption] = useState({
    date: "",
    procedure: "",
    provider: "",
  });
  const [emptyRows, setEmptyRows] = useState([]);

  useEffect(() => {
    let totalRowsCount = Math.floor(tableRef.current.offsetHeight / 40);
    let emptyRowsCount = totalRowsCount - consentDocuments.length;
    let emptyRows_ = [];
    for (let i = 0; i < emptyRowsCount; i++) {
      emptyRows_.push({ document: null });
    }
    setEmptyRows(emptyRows_);
  }, [consentDocuments]);

  useEffect(() => {
    setSearchData({ ...p_searchData });
  }, [p_searchData]);

  const onChangeSearchDate = (dateValue, date) => {
    const searchData_ = { ...searchData, dateValue, date };
    setSearchData(searchData_);
    dispatch(procedureConsentSetSearchData(searchData_));
    const patient_id = patientResource.id;
    dispatch(procedureConsentSearch(patient_id, searchData_));
  };
  // const onChangeSearchDate = (date) => {
  //   console.log(date)
  // }
  const onChangeSearchProcedure = (procedure) => {
    const searchData_ = { ...searchData, procedure };
    setSearchData(searchData_);
    dispatch(procedureConsentSetSearchData(searchData_));
    const patient_id = patientResource.id;
    dispatch(procedureConsentSearch(patient_id, searchData_));
  };

  const onChangeSearchProviderId = (provider_id) => {
    const searchData_ = { ...searchData, provider_id };
    setSearchData(searchData_);
    dispatch(procedureConsentSetSearchData(searchData_));
    const patient_id = patientResource.id;
    dispatch(procedureConsentSearch(patient_id, searchData_));
  };

  const handleSortOption = (key, value) => {
    setSortOption({ ...sortOption, [key]: value });
  };

  return (
    <>
      <div
        className="tableHeader d-flex sortableHeader resourceInfo"
        style={{ alignItems: "flex-end" }}
      >
        <div
          className={`th sortable ${!sortOption.date ? "hide-icon" : ""}`}
          style={{
            width: "calc(30%)",
            minWidth: "calc(30%)",
            display: "flex",
            alignItems: "center",
            height: "40px",
          }}
        >
          <b
            style={{
              width: "100%",
              minWidth: "20%",
              borderBottom: "none",
              paddingLeft: "0.75rem",
            }}
          >
            Date
          </b>

          <SortingArrowIcon
            sort={sortOption.date}
            handleSortASC={() => handleSortOption("date", "asc")}
            handleSortDESC={() => handleSortOption("date", "desc")}
          />
        </div>
        {/*
        <EditableFormInput
          value={searchData['dateValue']}
          handleChange={onChangeSearchDate}
          label={'Filter by Date...'}
          type={'date'}
        />
        */}
        <div
          className={`th sortable ${!sortOption.procedure ? "hide-icon" : ""}`}
          style={{
            width: "35%",
            maxWidth: "35%",
            display: "flex",
            alignItems: "center",
            height: "40px",
          }}
        >
          <b
            style={{
              width: "100%",
              minWidth: "20%",
              borderBottom: "none",
              paddingLeft: "0.75rem",
            }}
          >
            Procedure
          </b>

          <SortingArrowIcon
            sort={sortOption.procedure}
            handleSortASC={() => handleSortOption("procedure", "asc")}
            handleSortDESC={() => handleSortOption("procedure", "desc")}
          />
        </div>

        <div
          className={`th sortable ${!sortOption.provider ? "hide-icon" : ""}`}
          style={{
            width: "35%",
            maxWidth: "35%",
            display: "flex",
            alignItems: "center",
            height: "40px",
          }}
        >
          <b
            style={{
              width: "100%",
              minWidth: "20%",
              borderBottom: "none",
              paddingLeft: "0.75rem",
            }}
          >
            Provider
          </b>

          <SortingArrowIcon
            sort={sortOption.provider}
            handleSortASC={() => handleSortOption("provider", "asc")}
            handleSortDESC={() => handleSortOption("provider", "desc")}
          />
        </div>
      </div>
      <div
        className="tableList"
        style={{ height: "calc(100vh - 610px)", minHeight: "25vh" }}
        ref={tableRef}
      >
        <ScrollWrapper css="x-hidden no-padding">
          {consentDocuments.map((item, index) => (
            <div className="tableItem" key={`chart-table-${index}`}>
              <div className="dateField" style={{ width: "30%" }}>
                <p>{item.date}</p>
              </div>
              <div
                className="linkField"
                style={{ width: "35%", paddingLeft: "1.25rem" }}
              >
                <p onClick={() => selectDocument(item)}>{item.filename}</p>
              </div>
              <div
                className="authorField"
                style={{ minWidth: "35%", paddingLeft: "1.25rem" }}
              >
                <Input
                  style={{ paddingLeft: 8, width: "100%" }}
                  defaultValue={item.provider}
                  placeholder={"Provider..."}
                  allowClear={true}
                />
              </div>
            </div>
          ))}
          {emptyRows.map((item, index) => (
            <div className="tableItem" key={`chart-table-empty-${index}`}>
              <div className="dateField" style={{ width: "20%" }}>
                <p>&nbsp;</p>
              </div>
              <div className="linkField">
                <p>&nbsp;</p>
              </div>
              <div className="authorField">
                <p>&nbsp;</p>
              </div>
            </div>
          ))}
        </ScrollWrapper>
      </div>
    </>
  );
};

export default ConsentDocumentList;
