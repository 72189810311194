import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const OfficeGetConditions = createActionThunk(
  AT.OFFICE_GET_CONDITIONS,
  (procedure_id) => API.OfficeGetConditions(procedure_id)
);

export const OfficeSetConditions = (conditions) => (dispatch) =>
  dispatch({
    type: AT.OFFICE_SET_CONDITIONS,
    payload: {
      conditions,
    },
  });

export const OfficeSaveCondition = createActionThunk(
  AT.OFFICE_SAVE_CONDITION,
  (patient_id, procedure_id, data) =>
    API.OfficeSaveCondition(patient_id, procedure_id, data)
);
