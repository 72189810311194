import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Empty, Select } from "antd";

import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import {
  setProcedureDetails,
  getDiseases,
  update_icd_clinic_procedure_type,
  angio_condition_type,
} from "../../../store/actions";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import Loading from "../../../common/components/Loading";
import Cookies from "universal-cookie";
import ScrollWrapper from "../../../common/components/ScrollBar";

const cookies = new Cookies();
let timer = null;
const IndicationCode = ({ resource }) => {
  const icdCodesClinic = useSelector(
    (state) => state.procedureDetail.icdCodesClinic
  );
  const diseases = useSelector((state) => state.common.diseases);
  const loadingDiseases = useSelector((state) => state.common.loadingDiseases);
  const [searchResult, setSearchResult] = useState([]);
  const [searchAnswer, setSearchAnswer] = useState([]);
  const [page, setPage] = useState(1);
  const [selectResult, setSelectResult] = useState([]);
  const [selectSearch, setSelectSearch] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [focusState, setFocusState] = useState(false);
  const [IDRow, setIDRow] = useState(null);
  const [activeDelete, setActiveDelete] = useState([]);
  const [removableParent, setRemovableParent] = useState(-1);
  const detail_clinic_type = useSelector(
    (state) => state.procedureDetail.detail_clinic_type
  );
  const clinicVariable = useSelector(
    (state) => state.procedureDetail.clinicVariable
  );

  const dispatch = useDispatch();
  const handleChange = async (type, value, index, indexICD) => {
    if (icdCodesClinic && icdCodesClinic[indexICD]) {
      if (type === "disease_id") {
        icdCodesClinic[indexICD]["disease_id"] = value[0];
        await dispatch(
          setProcedureDetails("icdCodesClinic", [...icdCodesClinic])
        );
      } else {
        if (type == "icd_code") {
          icdCodesClinic[indexICD]["condition_serialize"][index]["icd_code"][
            "icd_code"
          ] = value[1];
          icdCodesClinic[indexICD]["condition_serialize"][index]["icd_code"][
            "icd_name"
          ] = value[2];
        } else {
          icdCodesClinic[indexICD]["condition_serialize"][index][type] =
            value === "THAN" && type === "operator" ? "THAN" : value;
        }
        await dispatch(
          setProcedureDetails("icdCodesClinic", [...icdCodesClinic])
        );
      }
    }
  };

  const handleChangeOperator = async (value, index, indexICD) => {
    const nextIndex = index + 1;
    if (
      icdCodesClinic[indexICD] &&
      icdCodesClinic[indexICD]["condition_serialize"] &&
      icdCodesClinic[indexICD]["condition_serialize"].length > 0
    ) {
      const plan = icdCodesClinic[indexICD]["condition_serialize"][nextIndex];
      let paramNU = {
        variable_id:
          plan && typeof plan["variable_id"] != "undefined"
            ? plan["variable_id"]
            : "",
        variable_name:
          plan && typeof plan["variable_name"] != "undefined"
            ? plan["variable_name"]
            : "",
        answer:
          plan && typeof plan["answer"] != "undefined" ? plan["answer"] : "",
        comparison:
          plan && typeof plan["comparison"] != "undefined"
            ? plan["comparison"]
            : ">=",
        operator:
          plan && typeof plan["operator"] != "undefined"
            ? plan["operator"]
            : "",
      };
      let paramUN = {
        icd_code: { icd_code: "", icd_name: "" },
        variable_id: undefined,
        variable_name: undefined,
        answer: undefined,
        comparison: undefined,
        operator: undefined,
      };
      if (icdCodesClinic && icdCodesClinic[indexICD]) {
        if (value !== "THAN") {
          icdCodesClinic[indexICD]["condition_serialize"][index + 1] = {
            ...paramNU,
          };
          await dispatch(
            setProcedureDetails("icdCodesClinic", [...icdCodesClinic])
          );
        } else {
          const abc = icdCodesClinic[indexICD];
          abc["condition_serialize"].map((item, indexMap) => {
            if (indexMap > index) {
              abc["condition_serialize"][indexMap] = null;
            }
          });
          const check = abc["condition_serialize"].filter((item) => {
            if (item != null) {
              return item;
            }
          });
          icdCodesClinic[indexICD]["condition_serialize"] = [...check, paramUN];
          await dispatch(
            setProcedureDetails("icdCodesClinic", [...icdCodesClinic])
          );
        }
      }
    }
  };

  const procedure_type = useSelector(
    (state) => state.procedureDetail.procedure_type
  );

  useEffect(() => {
    if (detail_clinic_type && Object.keys(detail_clinic_type).length > 0) {
      dispatch(
        setProcedureDetails(
          "icdCodesClinic",
          detail_clinic_type?.icd_rules ? detail_clinic_type?.icd_rules : []
        )
      );
    }
  }, [detail_clinic_type]);

  // useEffect(() => {
  //   const selectMap = (angio_type_select_search || []).map((r) => {
  //     return { id: r.id, name: r.name };
  //   });
  //   const checkArrayChoice = (angio_choice || []).map((r) => {
  //     return { id: r.id, name: r.name };
  //   });
  //   setSearchAnswer([...checkArrayChoice]);
  //   setSearchResult([...selectMap]);
  // }, [angio_type_select_search, angio_choice]);

  useEffect(() => {
    const variableMap = (clinicVariable || []).map((r) => {
      return { id: r.id, value: r.variable_name };
    });
    let answerCheck = [];
    const variableAswer = (clinicVariable || []).map((r) => {
      if (r.answer) {
        (r.answer || []).map((item) => {
          answerCheck = [...answerCheck, item];
        });
      }
    });
    setSearchAnswer([...answerCheck]);
    setSearchResult([...variableMap]);
  }, [clinicVariable]);

  useEffect(() => {
    if (!localLoading) {
      setSelectResult([...diseases]);
    }
  }, [diseases]);

  useEffect(() => {
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(getDiseases(selectSearch));
      setLocalLoading(false);
    }, 500);
  }, [selectSearch]);

  const handleClickRow = (value) => {
    // setIDRow(value.variable);
  };

  // const handleScroll = (e) => {
  //   const isEndOfList = e.target.scrollTop > (3 / 5) * e.target.scrollHeight;
  //   if (
  //     isEndOfList &&
  //     !loadingDiseases &&
  //     diseases &&
  //     diseases.length > 0
  //   ) {
  //     setPage(page + 1);
  //     dispatch(getDiseases(selectSearch, page + 1));
  //   }
  // };

  const removeProvider = async (index, indexICD) => {
    const checkCPTRow = { ...icdCodesClinic[indexICD] };
    if (
      checkCPTRow &&
      checkCPTRow["condition_serialize"] &&
      checkCPTRow["condition_serialize"].length > 1
    ) {

      if (checkCPTRow["condition_serialize"][index].operator === 'THAN'
      || checkCPTRow["condition_serialize"][index].operator === '') {
        checkCPTRow["condition_serialize"].splice(index, 2);
      } else {
        checkCPTRow["condition_serialize"].splice(index, 1);
      }

      const check = checkCPTRow["condition_serialize"].filter((item) => {
        if (item != null) {
          return item;
        }
      });
      if (check && check.length > 0) {
        icdCodesClinic[indexICD]["condition_serialize"] = [...check];
      } else {
        icdCodesClinic.splice(indexICD, 1);
      }

      await dispatch(
        setProcedureDetails("icdCodesClinic", [...icdCodesClinic])
      );
    } else {
      icdCodesClinic[indexICD] = null;
      const checkArray = icdCodesClinic.filter((r) => {
        if (r != null) {
          return r;
        }
      });
      await dispatch(setProcedureDetails("icdCodesClinic", [...checkArray]));
      await dispatch(
        update_icd_clinic_procedure_type(detail_clinic_type.id, {
          rules: [...checkArray],
        })
      );
    }
  };

  const update = async () => {
    if (
      icdCodesClinic &&
      icdCodesClinic[IDRow] &&
      icdCodesClinic[IDRow]["condition_serialize"]
    ) {
      const arrayADD = icdCodesClinic.map((r) => {
        return {
          disease_id: r?.disease_id,
          clinic_procedure_type_id: detail_clinic_type.id,
          condition_serialize: r?.condition_serialize
            ? [...r?.condition_serialize]
            : [{}],
        };
      });
      await dispatch(
        update_icd_clinic_procedure_type(detail_clinic_type.id, {
          rules: arrayADD,
        })
      );
    }
  };

  useEffect(() => {
    update();
  }, [icdCodesClinic]);

  // useEffect(() => {
  //   dispatch(angio_condition_type());
  // }, []);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <ScrollWrapper css="no-padding x-hidden">
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {icdCodesClinic.map((itemICD, indexICD) => {
          return (
            <div
              key={indexICD}
              onClick={() => {
                setIDRow(indexICD);
              }}
              style={{
                width: "100%",
                marginBottom:
                  itemICD?.condition_serialize &&
                  itemICD?.condition_serialize.length > 2
                    ? 20
                    : 10,
                height:
                  itemICD?.condition_serialize &&
                  itemICD?.condition_serialize.length > 1
                    ? itemICD?.condition_serialize.length * 42
                    : 50,
              }}
            >
              <DataTable
                title="icd_code"
                handleClickRow={handleClickRow}
                hideHeader={true}
                sizeHeightFix={
                  itemICD?.condition_serialize &&
                  itemICD?.condition_serialize.length > 1
                    ? itemICD?.condition_serialize.length * 42
                    : 50
                }
                isFixHeight={true}
                hideHeaderHeight="0px"
                allActive={true}
                handleDelete={(value, index) => removeProvider(index, indexICD)}
                parentIndex={indexICD}
                handleActiveDelete={(val) => {
                  const activeDeletes = [...activeDelete];
                  activeDeletes[indexICD] = val;
                  if (removableParent !== indexICD) {
                    activeDeletes[removableParent] = -1;
                    setRemovableParent(indexICD);
                  } else {
                    if (val === -1) setRemovableParent(-1);
                  }
                  setActiveDelete(activeDeletes);
                }}
                activeParent={activeDelete[indexICD]}
                labels={["Variable", "Option", "Answer", "Operator"]}
                widths={["30%", "5%", "35%", "30%"]}
                columns={[
                  { sortable: false, key: "variable" },
                  { sortable: false, key: "option" },
                  { sortable: false, key: "answer" },
                  { sortable: false, key: "operator" },
                ]}
                rowData={(itemICD?.condition_serialize || []).map(
                  (item, index) => {
                    let arrayAnswer = [];
                    const answerCheck = (clinicVariable || []).find((r) => {
                      return r.variable_name == item?.variable_name?.value;
                    });
                    if (answerCheck) {
                      const allAnswerCheck = (answerCheck.answer || []).map(
                        (item, index) => {
                          return { id: item.id, answer: item.answer };
                        }
                      );
                      arrayAnswer = [...allAnswerCheck];
                    }
                    return {
                      key: index,
                      disableDelete:
                        typeof item?.operator === "undefined" && true,
                      fullsize: typeof item?.operator === "undefined" && true,
                      variable:
                        typeof item?.operator === "undefined" ? (
                          <Select
                            key={index}
                            showSearch
                            placeholder="Search ICD Code"
                            value={`${item?.icd_code?.icd_code} ${item?.icd_code?.icd_name}`}
                            onSearch={(value) => {
                              setSelectSearch(value);
                            }}
                            onDropdownVisibleChange={(open) => {
                              setFocusState(open);
                            }}
                            onBlur={() => {
                              setSelectSearch(null);
                            }}
                            autoClearSearchValue={false}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            notFoundContent={
                              localLoading || loadingDiseases ? (
                                <div style={{ height: 100 }}>
                                  <Loading />
                                </div>
                              ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              )
                            }
                            onChange={(value) => {
                              handleChange(
                                "disease_id",
                                value,
                                index,
                                indexICD
                              );
                              handleChange("icd_code", value, index, indexICD);
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {(selectResult || []).map((r, index) => (
                              <Select.Option
                                key={r.id}
                                value={
                                  focusState
                                    ? [r.id, r.icd_code, r.icd_name]
                                    : `${r.icd_code} ${r.icd_name}`
                                }
                              >
                                {r.icd_code} {r.icd_name}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            placeholder={"Variable"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.variable_id}
                            onChange={(value) => {
                              handleChange(
                                "variable_id",
                                value,
                                index,
                                indexICD
                              );
                              handleChange(
                                "variable_name",
                                searchResult.find((r) => r.id == value),
                                index,
                                indexICD
                              );
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {(searchResult || []).map((opt, index) => (
                              <Select.Option
                                key={`clinicVariable-${index}-${opt.id}`}
                                value={opt.id}
                              >
                                {opt.value}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                      option:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <span>=</span>
                        ),
                      answer:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <Select
                            placeholder={"Answer"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.answer}
                            // notFoundContent={
                            //   loading_angio_type_variable ? null : (
                            //     <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            //   )
                            // }
                            // onFocus={async () => {
                            //   setSearchAnswer([]);
                            //   if (item?.variable_id) {
                            //     await dispatch(
                            //       angio_condition_type_choice(item?.variable_id)
                            //     );
                            //   }
                            // }}
                            onChange={(value) => {
                              handleChange("answer", value, index, indexICD);
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {(arrayAnswer || []).map(
                              (opt) =>
                                opt.answer && (
                                  <Select.Option
                                    key={`answer-${opt.answer}`}
                                    value={opt.answer}
                                  >
                                    {opt.answer}
                                  </Select.Option>
                                )
                            )}
                          </Select>
                        ),
                      operator:
                        typeof item?.operator === "undefined" ? (
                          ""
                        ) : (
                          <Select
                            placeholder={"Operator"}
                            suffixIcon={<ExpandSVG />}
                            allowClear={false}
                            value={item?.operator || undefined}
                            onChange={(value) => {
                              handleChange("operator", value, index, indexICD);
                              handleChangeOperator(value, index, indexICD);
                            }}
                            style={{ width: "100%", borderBottom: 0 }}
                          >
                            {["OR", "AND", "THAN"].map((opt) => (
                              <Select.Option
                                key={`operator-${opt}`}
                                value={opt}
                              >
                                {opt}
                              </Select.Option>
                            ))}
                          </Select>
                        ),
                    };
                  }
                )}
              />
            </div>
          );
        })}
      </div>
    </ScrollWrapper>
  );
};

export default IndicationCode;
