import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";

import Loading from "../../components/Loading";
import DataTable from "../../components/DataTable/DataTable";
import { getClinicWorkflowAppointments } from "../../../store/actions";

const cookies = new Cookies();

const Appointment = ({ data, title, display, sidebarIcon }) => {
  const [activeIndex, setRow] = useState(0);

  const dispatch = useDispatch();
  const appointments = useSelector(
    (state) => state.procedure.patientProcedures
  );

  const patient_id = cookies.get("current_patient_id");



  const fetchAPI = () => {
    dispatch(
      getClinicWorkflowAppointments({ patient_id: patient_id, paging: 1 })
    );
  };

  const [tableLoad, setTableLoads] = useState([]);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTableLoads([])
  }, [patient_id])

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad) {
        setLoading(true);
        const listUpdate = await dispatch(
          getClinicWorkflowAppointments({
            patient_id: patient_id,
            paging: page + 1,
          })
        );
        if (listUpdate) {
          if (appointments && appointments.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (appointments.length > 0) {
      if (page > 1) {
        setTableLoads([...tableLoad, ...appointments]);
        setLoading(false);
      } else {
        setTableLoads([...appointments]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [appointments]);

  const handleRow = (row, index) => () => {
    setRow(index);
  };

  const getAppointmentType = (row) => {
    if (row && row.type == 'or') {
      return row.procedure_type && row.procedure_type.value`${row?.procedure_type.value} ${row?.codes ? `(${row?.codes})` : ""}`;
    } else {
      return row.ultrasound_type && row.ultrasound_type.name`${row?.procedure_type.name} ${row?.codes ? `(${row?.codes})` : ""}`;
    }
  };

  return (
    <div
      style={{
        // display: !display ? "flex" : display === "flex" ? "flex" : "none",
        // padding: "1rem 2rem",
        // height: "calc(100vh - 165px)",
      }}
      className="clinicWorkflow__main-section"
    >
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer no-padding-right">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="appointment_table"
                tableWidth={1060}
                labels={[
                  "Date and Time",
                  "Appointment Type (CPT Code)",
                  "ICD Code",
                  "Location",
                  "Bill Date",
                ]}
                widths={["20%", "22%", "18%", "20%", "20%"]}
                columns={[
                  { sortable: false, key: "date_time" },
                  { sortable: false, key: "cpt_code" },
                  { sortable: false, key: "icd_code" },
                  { sortable: false, key: "location" },
                  { sortable: false, key: "bill_date" },
                ]}
                sorts={["", "", "", "", ""]}
                rowData={(tableLoad || []).map((row, index) => ({
                  id: row.id,
                  date_time:
                    row.appointment_date &&
                    moment(row.appointment_date).format("MM/DD/YYYY hh:mm A"),
                  cpt_code:
                    row?.procedure_name &&
                    `${row?.procedure_name} ${row?.codes ? `(${row?.codes})` : ""
                    }`,
                  icd_code: row.icd_codes,
                  location: row.location && row.location.value,
                  bill_date:
                    row.charge_date &&
                    moment(row.charge_date).format("MM/DD/YYYY hh:mm A"),
                }))}
                disableIcon={true}
                fetchList={fetchAPI}
                loadMore={loadMore}
                handleClickRow={handleRow}
                defaultActive={0}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
