import { createReducer } from "redux-create-reducer";
import moment from "moment";

import AT from "../actionTypes";

const initialState = {
  resource: null,
  loadingResource: false,
  // isAdded: false,
  isUpdated: false,
  isVerifyInsurance: false,
  isVerified: false,
  hasInsurance: null,
  errorMessage: "",
  // Sidebar
  step: 0,
  demographics: {},
  insurance: {},
  medical: {},
  questions: {},
  schedule: {},
  communication: {},
  attempt: {},
  schedules: [],
  scheduleOptions: [],
  dropdownOption: [],
  scheduleLoading: [],
  activeRows: [],
  initialLoading: false,
  loadingNotes: false,
  patientsNote: [],
  patientsNoteBilling: [],
  // insurance_companies
  loading_search_insurance_companies: false,
  search_insurance_companies: [],
  detail_insurance_companies: {},
  insurance_types: [],
  loading_insurance_types: false,
  // patient_insurances
  loading_patient_insurances: false,
  patient_all_insurances_id: [],
  detail_patient_insurances: {},
  check_patient_insurances: {},
  payer_id_types: [],
  save_check_question: false,
  enterInsuranceCheck: false,
};

const SET_STEP_DATA_SUCCEEDED = `${AT.SET_STEP_DATA}_SUCCEEDED`;
const SELECT_PATIENT_SUCCEEDED = `${AT.SELECT_PATIENT}_SUCCEEDED`;
const UPDATE_PATIENT_STARTED = `${AT.UPDATE_PATIENT}_STARTED`;
const UPDATE_PATIENT_SUCCEEDED = `${AT.UPDATE_PATIENT}_SUCCEEDED`;
const SAVE_CHECK_QUESTION_SUCCEEDED = `${AT.SAVE_CHECK_QUESTION}_SUCCEEDED`;

const ADD_PATIENT_STARTED = `${AT.ADD_PATIENT}_STARTED`;
const ADD_PATIENT_SUCCEEDED = `${AT.ADD_PATIENT}_SUCCEEDED`;
const VERIFY_INSURANCE_STARTED = `${AT.VERIFY_INSURANCE}_STARTED`;
const VERIFY_INSURANCE_SUCCEEDED = `${AT.VERIFY_INSURANCE}_SUCCEEDED`;
const UPDATE_MEDICAL_HISTORY_SUCCEEDED = `${AT.UPDATE_MEDICAL_HISTORY}_SUCCEEDED`;
const UPDATE_QUESTIONNAIRE_SUCCEEDED = `${AT.UPDATE_QUESTIONNAIRE}_SUCCEEDED`;
const GET_APPOINTMENTS_SUCCEEDED = `${AT.GET_APPOINTMENTS}_SUCCEEDED`;
const FILTER_APPOINTMENTS_SUCCEEDED = `${AT.FILTER_APPOINTMENTS}_SUCCEEDED`;
const ADD_CALL_ATTEMPTS_SUCCEEDED = `${AT.ADD_CALL_ATTEMPTS}_SUCCEEDED`;
// Save Schedule
const SAVE_CLINIC_SCHEDULE_SUCCEEDED = `${AT.SAVE_CLINIC_SCHEDULE}_SUCCEEDED`;
// remove state hasInsurance
const REMOVE_HAS_INSURANCE_SUCCEEDED = `${AT.REMOVE_HAS_INSURANCE}_SUCCEEDED`;
// patient note
const SAVE_PATIENT_NOTES_NEW_STARTED = `${AT.SAVE_PATIENT_NOTES_NEW}_STARTED`;
const SAVE_PATIENT_NOTES_NEW_SUCCEEDED = `${AT.SAVE_PATIENT_NOTES_NEW}_SUCCEEDED`;
const GET_ALL_PATIENT_NOTES_NEW_STARTED = `${AT.GET_ALL_PATIENT_NOTES_NEW}_STARTED`;
const GET_ALL_PATIENT_NOTES_NEW_SUCCEEDED = `${AT.GET_ALL_PATIENT_NOTES_NEW}_SUCCEEDED`;
const DELETE_PATIENT_NOTES_NEW_STARTED = `${AT.DELETE_PATIENT_NOTES_NEW}_STARTED`;
const DELETE_PATIENT_NOTES_NEW_SUCCEEDED = `${AT.DELETE_PATIENT_NOTES_NEW}_SUCCEEDED`;
// billing note
const SAVE_PATIENT_NOTES_NEW_BILLING_STARTED = `${AT.SAVE_PATIENT_NOTES_NEW_BILLING}_STARTED`;
const SAVE_PATIENT_NOTES_NEW_BILLING_SUCCEEDED = `${AT.SAVE_PATIENT_NOTES_NEW_BILLING}_SUCCEEDED`;
const GET_ALL_PATIENT_NOTES_NEW_BILLING_STARTED = `${AT.GET_ALL_PATIENT_NOTES_NEW_BILLING}_STARTED`;
const GET_ALL_PATIENT_NOTES_NEW_BILLING_SUCCEEDED = `${AT.GET_ALL_PATIENT_NOTES_NEW_BILLING}_SUCCEEDED`;
const DELETE_PATIENT_NOTES_NEW_BILLING_STARTED = `${AT.DELETE_PATIENT_NOTES_NEW_BILLING}_STARTED`;
const DELETE_PATIENT_NOTES_NEW_BILLING_SUCCEEDED = `${AT.DELETE_PATIENT_NOTES_NEW_BILLING}_SUCCEEDED`;
// insurance_companies
const SEARCH_INSURANCE_COMPANIES_STARTED = `${AT.SEARCH_INSURANCE_COMPANIES}_STARTED`;
const SEARCH_INSURANCE_COMPANIES_SUCCEEDED = `${AT.SEARCH_INSURANCE_COMPANIES}_SUCCEEDED`;
const GET_PAYER_ID_STARTED = `${AT.GET_PAYER_ID}_STARTED`;
const GET_PAYER_ID_SUCCEEDED = `${AT.GET_PAYER_ID}_SUCCEEDED`;
const DETAIL_INSURANCE_COMPANIES_STARTED = `${AT.DETAIL_INSURANCE_COMPANIES}_STARTED`;
const DETAIL_INSURANCE_COMPANIES_SUCCEEDED = `${AT.DETAIL_INSURANCE_COMPANIES}_SUCCEEDED`;
const SAVE_INSURANCE_COMPANIES_STARTED = `${AT.SAVE_INSURANCE_COMPANIES}_STARTED`;
const SAVE_INSURANCE_COMPANIES_SUCCEEDED = `${AT.SAVE_INSURANCE_COMPANIES}_SUCCEEDED`;
const EDIT_INSURANCE_COMPANIES_STARTED = `${AT.EDIT_INSURANCE_COMPANIES}_STARTED`;
const EDIT_INSURANCE_COMPANIES_SUCCEEDED = `${AT.EDIT_INSURANCE_COMPANIES}_SUCCEEDED`;
const DELETE_INSURANCE_COMPANIES_STARTED = `${AT.DELETE_INSURANCE_COMPANIES}_STARTED`;
const DELETE_INSURANCE_COMPANIES_SUCCEEDED = `${AT.DELETE_INSURANCE_COMPANIES}_SUCCEEDED`;
const INSURANCE_TYPES_STARTED = `${AT.INSURANCE_TYPES}_STARTED`;
const INSURANCE_TYPES_SUCCEEDED = `${AT.INSURANCE_TYPES}_SUCCEEDED`;
// patient_insurances
const PATIENT_NODES_ID_INSURANCES_STARTED = `${AT.PATIENT_NODES_ID_INSURANCES}_STARTED`;
const PATIENT_NODES_ID_INSURANCES_SUCCEEDED = `${AT.PATIENT_NODES_ID_INSURANCES}_SUCCEEDED`;
const SAVE_PATIENT_NODES_INSURANCES_STARTED = `${AT.SAVE_PATIENT_NODES_INSURANCES}_STARTED`;
const SAVE_PATIENT_NODES_INSURANCES_SUCCEEDED = `${AT.SAVE_PATIENT_NODES_INSURANCES}_SUCCEEDED`;
const DETAIL_PATIENT_NODES_INSURANCES_STARTED = `${AT.DETAIL_PATIENT_NODES_INSURANCES}_STARTED`;
const DETAIL_PATIENT_NODES_INSURANCES_SUCCEEDED = `${AT.DETAIL_PATIENT_NODES_INSURANCES}_SUCCEEDED`;
const EDIT_PATIENT_NODES_INSURANCES_STARTED = `${AT.EDIT_PATIENT_NODES_INSURANCES}_STARTED`;
const EDIT_PATIENT_NODES_INSURANCES_SUCCEEDED = `${AT.EDIT_PATIENT_NODES_INSURANCES}_SUCCEEDED`;
const DELETE_PATIENT_NODES_INSURANCES_STARTED = `${AT.DELETE_PATIENT_NODES_INSURANCES}_STARTED`;
const DELETE_PATIENT_NODES_INSURANCES_SUCCEEDED = `${AT.DELETE_PATIENT_NODES_INSURANCES}_SUCCEEDED`;
const CHECK_PATIENT_NODES_INSURANCES_STARTED = `${AT.CHECK_PATIENT_NODES_INSURANCES}_STARTED`;
const CHECK_PATIENT_NODES_INSURANCES_SUCCEEDED = `${AT.CHECK_PATIENT_NODES_INSURANCES}_SUCCEEDED`;
const RESET_CHECK_PATIENT_SUCCEEDED = `${AT.RESET_CHECK_PATIENT}_SUCCEEDED`;
const UPDATE_PATIENT_OPS_STARTED = `${AT.UPDATE_PATIENT_OPS}_STARTED`;
const UPDATE_PATIENT_OPS_SUCCEEDED = `${AT.UPDATE_PATIENT_OPS}_SUCCEEDED`;
const SET_ENTER_INSURANCE_CHECK_SUCCEEDED = `SET_ENTER_INSURANCE_CHECK_SUCCEEDED`;

export default createReducer(initialState, {
  [DELETE_PATIENT_NOTES_NEW_BILLING_STARTED](state, action) {
    return Object.assign({}, state, { loadingNotes: true });
  },
  [DELETE_PATIENT_NOTES_NEW_BILLING_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingNotes: false,
    });
  },
  [GET_ALL_PATIENT_NOTES_NEW_BILLING_STARTED](state, action) {
    return Object.assign({}, state, { loadingNotes: true });
  },
  [GET_ALL_PATIENT_NOTES_NEW_BILLING_SUCCEEDED](state, action) {
    const patientsNoteBilling = action.payload;
    return Object.assign({}, state, {
      loadingNotes: false,
      patientsNoteBilling,
    });
  },
  [SAVE_PATIENT_NOTES_NEW_BILLING_STARTED](state, action) {
    return Object.assign({}, state, { loadingNotes: true });
  },
  [SAVE_PATIENT_NOTES_NEW_BILLING_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingNotes: false,
    });
  },
  //
  [SAVE_CHECK_QUESTION_SUCCEEDED](state, action) {
    const save_check_question = action.payload;
    return Object.assign({}, state, {
      save_check_question,
    });
  },
  // patient_insurances
  [RESET_CHECK_PATIENT_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loading_patient_insurances: false,
      check_patient_insurances: {},
    });
  },
  [CHECK_PATIENT_NODES_INSURANCES_STARTED](state) {
    return Object.assign({}, state, { loading_patient_insurances: true });
  },
  [CHECK_PATIENT_NODES_INSURANCES_SUCCEEDED](state, action) {
    const check_patient_insurances = action.payload;
    return Object.assign({}, state, {
      loading_patient_insurances: false,
      check_patient_insurances,
    });
  },
  [DELETE_PATIENT_NODES_INSURANCES_STARTED](state) {
    return Object.assign({}, state, { loading_patient_insurances: true });
  },
  [DELETE_PATIENT_NODES_INSURANCES_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loading_patient_insurances: false,
    });
  },
  [EDIT_PATIENT_NODES_INSURANCES_STARTED](state) {
    return Object.assign({}, state, { loading_patient_insurances: true });
  },
  [EDIT_PATIENT_NODES_INSURANCES_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loading_patient_insurances: false,
    });
  },
  [SAVE_PATIENT_NODES_INSURANCES_STARTED](state) {
    return Object.assign({}, state, { loading_patient_insurances: true });
  },
  [SAVE_PATIENT_NODES_INSURANCES_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loading_patient_insurances: false,
    });
  },
  [DETAIL_PATIENT_NODES_INSURANCES_STARTED](state, action) {
    return Object.assign({}, state, { loading_patient_insurances: true });
  },
  [DETAIL_PATIENT_NODES_INSURANCES_SUCCEEDED](state, action) {
    const detail_patient_insurances = action.payload;
    return Object.assign({}, state, {
      detail_patient_insurances,
      loading_patient_insurances: false,
    });
  },
  [PATIENT_NODES_ID_INSURANCES_STARTED](state, action) {
    return Object.assign({}, state, { loading_patient_insurances: true });
  },
  [PATIENT_NODES_ID_INSURANCES_SUCCEEDED](state, action) {
    const patient_all_insurances_id = action.payload;
    return Object.assign({}, state, {
      patient_all_insurances_id,
      loading_patient_insurances: false,
      enterInsuranceCheck: false,
    });
  },
  // insurance_companies
  [INSURANCE_TYPES_STARTED](state, action) {
    return Object.assign({}, state, { loading_insurance_types: true });
  },
  [INSURANCE_TYPES_SUCCEEDED](state, action) {
    const insurance_types = action.payload;
    return Object.assign({}, state, {
      insurance_types,
      loading_insurance_types: false,
    });
  },
  [DELETE_INSURANCE_COMPANIES_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_search_insurance_companies: true,
    });
  },
  [DELETE_INSURANCE_COMPANIES_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_search_insurance_companies: false,
    });
  },
  [EDIT_INSURANCE_COMPANIES_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_search_insurance_companies: true,
    });
  },
  [EDIT_INSURANCE_COMPANIES_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_search_insurance_companies: false,
    });
  },
  [SAVE_INSURANCE_COMPANIES_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_search_insurance_companies: true,
    });
  },
  [SAVE_INSURANCE_COMPANIES_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_search_insurance_companies: false,
    });
  },
  [DETAIL_INSURANCE_COMPANIES_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_search_insurance_companies: true,
    });
  },
  [DETAIL_INSURANCE_COMPANIES_SUCCEEDED](state, action) {
    const detail_insurance_companies = action.payload;
    return Object.assign({}, state, {
      detail_insurance_companies,
      loading_search_insurance_companies: false,
    });
  },
  [SEARCH_INSURANCE_COMPANIES_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_search_insurance_companies: true,
    });
  },
  [SEARCH_INSURANCE_COMPANIES_SUCCEEDED](state, action) {
    const search_insurance_companies = action.payload;
    return Object.assign({}, state, {
      search_insurance_companies,
      loading_search_insurance_companies: false,
    });
  },
  //
  [GET_PAYER_ID_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_get_payer_id: true,
    });
  },
  [GET_PAYER_ID_SUCCEEDED](state, action) {
    const payer_id_types = action.payload;
    return Object.assign({}, state, {
      payer_id_types,
      loading_get_payer_id: false,
    });
  },
  [DELETE_PATIENT_NOTES_NEW_STARTED](state, action) {
    return Object.assign({}, state, { loadingNotes: true });
  },
  [DELETE_PATIENT_NOTES_NEW_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingNotes: false,
    });
  },
  [GET_ALL_PATIENT_NOTES_NEW_STARTED](state, action) {
    return Object.assign({}, state, { loadingNotes: true });
  },
  [GET_ALL_PATIENT_NOTES_NEW_SUCCEEDED](state, action) {
    const patientsNote = action.payload;
    return Object.assign({}, state, {
      loadingNotes: false,
      patientsNote,
    });
  },
  [SAVE_PATIENT_NOTES_NEW_STARTED](state, action) {
    return Object.assign({}, state, { loadingNotes: true });
  },
  [SAVE_PATIENT_NOTES_NEW_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingNotes: false,
    });
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [SET_STEP_DATA_SUCCEEDED](state, action) {
    return Object.assign({}, state, { ...action.payload });
  },
  [VERIFY_INSURANCE_STARTED](state, action) {
    return Object.assign({}, state, {
      isVerifyInsurance: true,
      isVerified: false,
      hasInsurance: null,
    });
  },
  [REMOVE_HAS_INSURANCE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      hasInsurance: null,
    });
  },
  [UPDATE_MEDICAL_HISTORY_SUCCEEDED](state, action) {
    const result = action.payload;

    if (result) {
      const resource = { ...state.resource, ...result };
      if (result.coverages && result.coverages.length > 0) {
        return Object.assign({}, state, {
          resource,
          hasInsurance: 1,
        });
      } else {
        return Object.assign({}, state, {
          resource,
          hasInsurance: 0,
        });
      }
    }
  },
  [VERIFY_INSURANCE_SUCCEEDED](state, action) {
    const result = action.payload;

    if (result) {
      const resource = { ...state.resource, ...result };
      if (result.coverages && result.coverages.length > 0) {
        return Object.assign({}, state, {
          resource,
          isVerifyInsurance: false,
          isVerified: true,
          hasInsurance: 1,
        });
      } else {
        return Object.assign({}, state, {
          resource,
          isVerifyInsurance: false,
          isVerified: true,
          hasInsurance: 0,
        });
      }
    }

    return Object.assign({}, state, { isVerifyInsurance: false });
  },
  [ADD_CALL_ATTEMPTS_SUCCEEDED](state, action) {
    let attempt = [];
    const result = action.payload;
    if (typeof state.attempt.call_attempts == "undefined") {
      attempt = {
        call_attempts: [result],
      };
    } else {
      attempt = {
        call_attempts: [...state.attempt.call_attempts, result],
      };
    }
    return Object.assign({}, state, { attempt });
  },
  // Select patient process
  [SELECT_PATIENT_SUCCEEDED](state, action) {
    const resource = action.payload;
    const newStepData = {
      demographics: { ...resource },
      insurance: {
        policy_number: resource["policy_number"] || "",
        medical_id: resource["medical_id"] || "",
      },
      medical: {
        previous_medical_history: resource["previous_medical_history"],
        previous_surgical_history: resource["previous_surgical_history"],
        medications: resource["medications"],
        allergies: resource["allergies"],
        flu_vaccination: resource["flu_vaccination"],
        pneumococcal: resource["pneumococcal"],
        aortic_disease: resource["aortic_disease"],
        amputation: resource["amputation"],
        heart_disease: resource["heart_disease"],
        smoking_ppd: resource["smoking_ppd"],
        alcoholic_drink_pw: resource["alcoholic_drink_pw"],
        previous_smoking_ppd: resource["previous_smoking_ppd"],
        years_of_smoking: resource["years_of_smoking"],
        no_drug_allergies: resource["no_drug_allergies"],
        no_medication: resource["no_medication"],
      },
      questions: {
        leg_swelling: resource.leg_swelling,
        smoked_cigarettes: resource.smoked_cigarettes,
        history_aneurysm: resource.history_aneurysm,
        suffered_dvt: resource.suffered_dvt,
        leg_pain: resource.leg_pain,
        stroke_symptoms: resource.stroke_symptoms,
      },
      schedule: { ...resource },
      communication: {
        fax_rp: 0,
        fax_pcp: 0,
        mail: 0,
        print_packet: 0,
      },
      attempt: {
        call_attempts: resource["call_attempts"],
      },
    };
    return Object.assign({}, state, {
      resource,
      step: 0,
      schedules: [],
      scheduleOptions: [],
      dropdownOption: [],
      scheduleLoading: [],
      activeRows: [],
      loadingResource: false,
      ...newStepData,
    });
  },
  // Update patient process
  [UPDATE_PATIENT_STARTED](state, action) {
    return Object.assign({}, state, { isUpdated: false });
  },
  [UPDATE_PATIENT_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingResource: false,
      isUpdated: true,
    });
  },
  [UPDATE_PATIENT_OPS_STARTED](state, action) {
    return Object.assign({}, state, { isUpdated: false });
  },
  [UPDATE_PATIENT_OPS_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingResource: false,
      isUpdated: true,
    });
  },
  // [ADD_PATIENT_STARTED](state, action) {
  //   return Object.assign({}, state, {isAdded: false});
  // },
  // [ADD_PATIENT_SUCCEEDED](state, action) {
  //   const resource = action.payload.data;
  //   return Object.assign({}, state, {loadingResource: false, isAdded: true, resource});
  // },
  [GET_APPOINTMENTS_SUCCEEDED](state, action) {
    const data = action.payload;
    console.log("check sort", data);
    let scheduleA = [];
    let scheduleB = [];
    let schedules = [];
    if (data && data["a"] && data["a"][0]) {
      scheduleA = (data["a"][0] || []).map((schedule, index) => {
        if (index > 0) {
          return [];
        }

        const keysOfSlot = Object.keys(schedule);
        const slots = (
          schedule[keysOfSlot[0].toString()]["timeslot"] || []
        ).map((slot) => {
          const location = slot.location;
          const provider = slot.provider;
          const date = moment(slot.start).format("MM/DD/YYYY");
          const time = moment(slot.start).format("hh:mm A");
          const technician = slot.technician;
          const start_slot = slot.start_slot;
          const doc_slot = slot.slot;
          return {
            location,
            provider,
            date,
            time,
            technician,
            start_slot,
            doc_slot,
          };
        });

        return slots;
      });
    }
    if (data && data["b"] && data["b"][0]) {
      scheduleB = (data["b"][0] || []).map((schedule, index) => {
        if (scheduleA.length > 0 || index > 0) {
          return [];
        }

        const keysOfSlot = Object.keys(schedule);
        const slots = (
          schedule[keysOfSlot[0].toString()]["timeslot"] || []
        ).map((slot) => {
          const location = slot.location;
          const provider = slot.provider;
          const date = moment(slot.start).format("MM/DD/YYYY");
          const time = moment(slot.start).format("hh:mm A");
          const technician = slot.technician;
          const start_slot = slot.start_slot;
          const doc_slot = slot.slot;
          return {
            location,
            provider,
            date,
            time,
            technician,
            start_slot,
            doc_slot,
          };
        });

        return slots;
      });
    }
    schedules = [...scheduleA, ...scheduleB];
    const scheduleOptions = new Array(schedules.length).fill({
      location_id: "",
      provider_id: "",
      period: "",
    });
    const dropdownOption = new Array(schedules.length).fill({
      type: "",
      list: [],
    });
    const scheduleLoading = new Array(schedules.length).fill(false);
    const activeRows = new Array(schedules.length).fill(-1);

    return Object.assign({}, state, {
      schedules,
      scheduleOptions,
      dropdownOption,
      scheduleLoading,
      activeRows,
      initialLoading: false,
    });
  },
  // Filter appointment process
  [FILTER_APPOINTMENTS_SUCCEEDED](state, action) {
    const { data, idxOfSchedule, action: actionType } = action.payload;
    let scheduleA = [];
    let scheduleB = [];
    let schedules = [];
    if (data && data["a"] && data["a"][0]) {
      scheduleA = (data["a"][0] || []).map((schedule) => {
        const keysOfSlot = Object.keys(schedule);
        const slots = (
          schedule[keysOfSlot[0].toString()]["timeslot"] || []
        ).map((slot) => {
          const location = slot.location;
          const provider = slot.provider;
          const date = moment(slot.start).format("MM/DD/YYYY");
          const time = moment(slot.start).format("hh:mm A");
          const technician = slot.technician;
          const start_slot = slot.start_slot;
          const doc_slot = slot.slot;
          return {
            location,
            provider,
            date,
            time,
            technician,
            start_slot,
            doc_slot,
          };
        });

        return slots;
      });
    }
    if (data && data["b"] && data["b"][0]) {
      scheduleB = (data["b"][0] || []).map((schedule) => {
        const keysOfSlot = Object.keys(schedule);
        const slots = (
          schedule[keysOfSlot[0].toString()]["timeslot"] || []
        ).map((slot) => {
          const location = slot.location;
          const provider = slot.provider;
          const date = moment(slot.start).format("MM/DD/YYYY");
          const time = moment(slot.start).format("hh:mm A");
          const technician = slot.technician;
          const start_slot = slot.start_slot;
          const doc_slot = slot.slot;
          return {
            location,
            provider,
            date,
            time,
            technician,
            start_slot,
            doc_slot,
          };
        });

        return slots;
      });
    }
    schedules = [...scheduleA, ...scheduleB];
    const newSchedules = [...state.schedules];
    const loadingOfSchedules = [...state.scheduleLoading];
    if (actionType === "row") {
      newSchedules[idxOfSchedule + 1] = schedules[idxOfSchedule + 1];
      loadingOfSchedules[idxOfSchedule + 1] = false;
    } else {
      newSchedules[idxOfSchedule] = schedules[idxOfSchedule];
      loadingOfSchedules[idxOfSchedule] = false;
    }
    return Object.assign({}, state, {
      schedules: newSchedules,
      scheduleLoading: loadingOfSchedules,
    });
  },
  [SAVE_CLINIC_SCHEDULE_SUCCEEDED](state, action) {
    return Object.assign({}, state);
  },
  [SET_ENTER_INSURANCE_CHECK_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      enterInsuranceCheck: true,
    });
  },
});
