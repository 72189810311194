import React, { useState } from "react";
import { validateField } from "../../../../services/validations";
import ToastMessage from "../../../../common/components/Toast";
import YesNo from "../../../../common/components/YesNo/YesNoOne";
import LoginFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { getUsers, postUser } from "../../../../store/actions";
import { useDispatch } from "react-redux";

const AddUserDetails = ({ closeAddForm }) => {
  const [isProvider, setIsProvider] = useState(null);
  const [isTechnician, setIsTechnician] = useState(null);
  const [isPCM, setIsPCM] = useState(null);
  const [salary, setSalary] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [points, setPoints] = useState("");
  const [email, setEmail] = useState("");
  const [exemptions, setExemptions] = useState("");
  const [hours, setHours] = useState("");
  const [hire, setHire] = useState("");
  const [end, setEnd] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [enableError, setEnableError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [position, setPosition] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);
  const [opsProviderId, setOpsProviderId] = useState("");
  const dispatch = useDispatch();

  const handleChangeOption = (key, checked) => {
    if (key === "isProvider") {
      setIsProvider(checked);
    } else if (key === "isPCM") {
      setIsPCM(checked);
    } else if (key === "isTechnician")
    {
      setIsTechnician(checked);
    } else {
      setIsAdmin(checked);
    }
  };

  const saveUserPost = async () => {
    const checkPostUser = await dispatch(
      postUser({
        name: firstName + " " + lastName,
        username: login,
        password: password,
        email: email,
        salary: salary,
        exemption: exemptions,
        points: points,
        vacation_hours: hours,
        hire_date: hire,
        end_date: end,
        is_provider: isProvider,
        is_pcm: isPCM,
        is_technician: isTechnician,
        is_admin: isAdmin,
        position: position,
        ops_provider_id: opsProviderId,
      })
    );
    const loadList = await dispatch(getUsers());
    if (checkPostUser && loadList) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const addNewUser = () => {
    if (
      validateField("text", firstName) ||
      validateField("text", lastName) ||
      validateField("email", email) ||
      validateField("text", login) ||
      validateField("text", password)
    ) {
      setEnableError(true);
      return;
    }

    // Todo: Integrate API
    setEnableError(false);
    saveUserPost();
    if (closeAddForm) {
      closeAddForm();
    }
  };

  const cancelForm = () => {
    setEnableError(false);
    if (closeAddForm) {
      closeAddForm();
    }
  };

  return (
    <React.Fragment>
      <div
        className="resourceContent"
        style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
      >
        {isToastr && (
          <ToastMessage
            type="Ultrasounds"
            status="success"
            msg="Saved Successfully!"
          />
        )}
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"First Name"}
            value={firstName}
            handleChange={(value) => {
              setFirstName(value);
            }}
            required={true}
            enableErrorText={enableError}
            isError={validateField("text", firstName)}
            helperText={"First Name is Required!"}
          />
        </div>

        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Last Name"}
            value={lastName}
            handleChange={(value) => {
              setLastName(value);
            }}
            required={true}
            enableErrorText={enableError}
            isError={validateField("text", lastName)}
            helperText={"Last Name is Required!"}
          />
        </div>
      </div>
      <div
        className="resourceContent"
        style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
      >
        <div className="resourceInfo d-flex">
          <b style={{ minWidth: "37%" }}>Provider: </b>
          <YesNo
            option={isProvider}
            setOption={(checked) => {
              handleChangeOption("isProvider", checked);
            }}
          />
        </div>
        <div className="resourceInfo d-flex">
          <b style={{ minWidth: "37%" }}>Technician: </b>
          <YesNo
              option={isTechnician}
              setOption={(checked) => {
                handleChangeOption("isTechnician", checked);
              }}
          />
        </div>
        <div className="resourceInfo d-flex">
          <b style={{ minWidth: "37%" }}>PCM: </b>
          <YesNo
            option={isPCM}
            setOption={(checked) => {
              handleChangeOption("isPCM", checked);
            }}
          />
        </div>
        <div className="resourceInfo d-flex">
          <b style={{ minWidth: "37%" }}>Admin: </b>
          <YesNo
            option={isAdmin}
            setOption={(checked) => {
              handleChangeOption("isAdmin", checked);
            }}
          />
        </div>

        <div
          className="resourceInfo d-flex align-items-end required-field"
          style={{ marginTop: "0.5rem" }}
        >
          <LoginFormInput
            label={"Salary/Wage"}
            value={salary}
            handleChange={(value) => {
              setSalary(value);
            }}
            prefix={"$"}
            unit={"/hour"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Points"}
            value={points}
            handleChange={(value) => {
              setPoints(value);
            }}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Position"}
            value={position}
            handleChange={(value) => {
              setPosition(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", position)}
            helperText={"Position is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Email"}
            value={email}
            handleChange={(value) => {
              setEmail(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("email", email)}
            helperText={"Email is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Exemptions"}
            value={exemptions}
            handleChange={(value) => {
              setExemptions(value);
            }}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Vacation Hours/yr"}
            value={hours}
            handleChange={(value) => {
              setHours(value);
            }}
            unit={"hours"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Hire Date"}
            value={hire}
            handleChange={(value) => {
              setHire(value);
            }}
            type={"date"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"End Date"}
            value={end}
            handleChange={(value) => {
              setEnd(value);
            }}
            type={"date"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Login"}
            value={login}
            handleChange={(value) => {
              setLogin(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", login)}
            helperText={"Login is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Password"}
            value={password}
            handleChange={(value) => {
              setPassword(value);
            }}
            type={"password"}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", password)}
            helperText={"Password is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end">
          <LoginFormInput
              label={"OPS Provider ID"}
              value={opsProviderId}
              handleChange={(value) => {
                setOpsProviderId(value);
              }}
              type={"text"}
          />
        </div>
        <hr />
      </div>
      <div
        className="actionContent"
        style={{ marginTop: "2rem", paddingRight: "1rem", flexWrap: "wrap" }}
      >
        <button
          className="outlined-btn"
          style={{ marginTop: "0.5rem" }}
          onClick={cancelForm}
        >
          Back
        </button>
        <button
          onClick={addNewUser}
          style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}
        >
          Save
        </button>
      </div>
    </React.Fragment>
  );
};

export default AddUserDetails;
