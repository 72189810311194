import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";

export const get_ocr_category = createActionThunk(AT.GET_OCR_CATEGORY, () =>
  API.get_ocr_category()
);

export const get_ocr_words = createActionThunk(AT.GET_OCR_WORDS, () =>
  API.get_ocr_words()
);

export const post_ocr_words = createActionThunk(AT.POST_OCR_WORDS, (params) =>
  API.post_ocr_words(params)
);

export const put_ocr_words = createActionThunk(AT.PUT_OCR_WORDS, (id, params) =>
  API.put_ocr_words(id, params)
);

export const delete_ocr_category = createActionThunk(
  AT.DELETE_OCR_WORDS,
  (id, params) => API.delete_ocr_words(id, params)
);
