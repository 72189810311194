import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { InputNumber, Checkbox, Upload, message } from "antd";
import {
  CloseIcon,
  DevicesSVG,
  SummaryEmptySVG,
} from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import {
  BarcodeSVG,
  MaleSVG,
} from "../../../../common/components/icons/FaxSVG";
import Loading from "../../../../common/components/Loading";
import EditFormInput from "../../../../common/components/EditableText/LoginFormInput";
import {
  orderPurchaseItem,
  setInventoryData,
  getPurchaseList,
  deletePurchaseItem,
} from "../../../../store/actions";

const { Dragger } = Upload;

const draggerProps = {
  name: "file",
  multiple: false,
  action: "",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const InvoicesSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar },ref) => {
  const purchase = useSelector((state) => state.inventory.receivingOrder);
  const loading = useSelector((state) => state.inventory.loadingAPI);

  const [paidAmount, setPaidAmount] = useState();
  const [netBalance, setNetBalance] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [contactPerson, setContactPerson] = useState();

  const totalAmount = useMemo(() => {
    if (purchase) {
      return (purchase.cost * purchase.purchase_number).toFixed(2);
    }
    return 0;
  }, [purchase]);

  if (!isShow || !purchase) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper>
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={<DevicesSVG />}
          title={purchase.name || ""}
          subTitle=""
        />

        <div
          className="resourceContent"
          style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
        >
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Contact Person"}
              value={contactPerson}
              onChange={setContactPerson}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Contact Phone"}
              value={contactPhone}
              mask={"phone"}
              onChange={setContactPhone}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Items"}
              value={purchase.purchase_number}
              unit={purchase.unit}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput label={"Total"} value={totalAmount} prefix={"$"} />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Amount Paid"}
              value={paidAmount && paidAmount.toFixed(2)}
              prefix={"$"}
              onChange={setPaidAmount}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditFormInput
              label={"Net Balance"}
              value={netBalance && netBalance.toFixed(2)}
              prefix={"$"}
              onChange={setNetBalance}
            />
          </div>
          <hr />
          <div className="resourceInfo d-flex" style={{ alignItems: "center" }}>
            <b>Image of Invoice</b>
            <Dragger
              {...draggerProps}
              className="barcode-uploader"
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <BarcodeSVG />
              </p>
            </Dragger>
          </div>
          <hr />
        </div>
        <div
          className="actionContent"
          style={{ marginTop: "2rem", paddingRight: "1rem" }}
        >
          <button>Confirm Amount Paid</button>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default InvoicesSidebar;
