import React, { useState, useEffect, useRef } from "react";
import { Icon, Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import PDFView from "../../../common/components/PDFView";
import PDFView from "../../../common/components/PDFView";
import PatientChartSideBar from "./sidebar";
import LaboratoryTable from "./LaboratoryTable";
import { StaticResource } from "./constant";
import { BASE_API } from "../../constants/constants";
import { LeftCharvenSVG, SummaryEmptySVG } from "../../components/icons/SVGIcon";
import Cookies from "universal-cookie";
import {
  get_filter_patient_document,
  reset_select_document,
  setSidebarState,
} from "../../../store/actions";
import VideoImageViewer from "./VideoImageViewer";
import SidebarExtension from "../../components/SidebarExtension";

const cookies = new Cookies();
const { TabPane } = Tabs;
let imageTags = [];

const PatientChart = (props) => {
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  // const documents = useSelector((state) => state.common.patientDocuments);
  const selectedDocument = useSelector(
    (state) => state.clinicProvider.selectedDocument
  );

  // const loadingGetDataDocument = useSelector(
  //   (state) => state.common.loadingGetPatientDocument
  // );

  const dispatch = useDispatch();
  const current_patient_id = cookies.get("current_patient_id");
  const [isSidebar, setIsSidebar] = useState(true);
  const [activeTab, setActiveTab] = useState("documentation");
  const [image, setImage] = useState("");
  const opener = useRef();
  useEffect(() => {
    dispatch(reset_select_document());
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    } else if (patientResource) {
      setIsSidebar(true);
    }
  }, []);

  useEffect(() => {
    let params = { patient_id: current_patient_id };
    if (activeTab === "documentation") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    } else if (activeTab === "image-viewer-tab") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    } else if (activeTab === "laboratory") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    }
    dispatch(get_filter_patient_document(params));
  }, [activeTab]);

  /**
   * getImage
   * @desc Retrieves the Image and displays it in the image tab
   * @param imageId
   */
  const getImage = (imageId) => {
    fetch(BASE_API + "imagetags/" + imageId, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        imageTags = response;
        setImage(
          "http://dashboard.vascularhealthclinics.org/get-image/1/" + imageId
        );
        setActiveTab("image-viewer-tab");
      })
      .catch((error) => console.log(error));
  };

  const refreshImageList = () => {
    // this.refs.parent_charts_sidebar_ref.refreshImageList();
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleTab = (activeTab) => {
    setActiveTab(activeTab);
  };
  const sidebarRef = useRef();
  return (
    <div
      style={{
        display: !props.display
          ? "flex"
          : props.display === "flex"
          ? "flex"
          : "none",
      }}
      className="clinicWorkflow__main-section"
    >
      <div className="eventsContainer has-sub-header">
        <Tabs
          className="patientChart"
          type="card"
          onChange={handleTab}
          activeTab={activeTab}
        >
          <TabPane tab="Documentation" key="documentation">
              <PDFView url={selectedDocument && selectedDocument.file_path} />
          </TabPane>

          <TabPane tab="Images" key="image-viewer-tab">
            <VideoImageViewer />
          </TabPane>

          <TabPane tab="Laboratory" key="laboratory">
            <LaboratoryTable />
          </TabPane>
        </Tabs>
      </div>
      <PatientChartSideBar
        ref={sidebarRef}
        sidebarIcon={props.sidebarIcon}
        opener={opener}
        resource={patientResource}
        activeTab={activeTab}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
      <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
    </div>
  );
};

export default PatientChart;
