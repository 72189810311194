import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";
import pdf2base64 from "pdf-to-base64";
import printJS from "print-js";
import {
  CloseIcon,
  CheckSVG,
} from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import CptReview from "../../../../common/components/CptReview/index";
import {
  set_cpt_code_review,
  search_cpt_code,
  get_plan_patient,
  reset_appointment_pdf,
  get_cpt_code_review,
  unlock_finalize,
  setGlobalLoading,
  setGlobalToastr,
  unlockClinicProcedure,
  lockClinicProcedure,
} from "../../../../store/actions";
import ReviewIcdCode from "./ReviewIcdCode";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";

const cookies = new Cookies();
let timer = null;

const ReviewSideBar = React.forwardRef(
  (
    {
      isShow,
      sidebarIcon,
      handleCloseSidebar,
      valueText,
      onChangeCPT,
      resource,
    },
    ref
  ) => {
    const patientResource = useSelector(
      (state) => state.clinicProvider?.patientResource
    );
    const usersCheck = useSelector((state) => state.common.currentUserInfo);
    const fullName = (
      patientResource?.first_name +
      " " +
      patientResource?.last_name
    ).toUpperCase();
    const age = patientResource?.date_of_birth
      ? "(" + moment().diff(patientResource.date_of_birth, "years") + " yo)"
      : "";
    const detailUltrasounds = useSelector(
      (state) => state.common.detailUltrasounds
    );
    const appointment_pdf = useSelector(
      (state) => state.common.appointment_pdf
    );
    const providers = useSelector((state) => state.common.providers);
    const [row, setRow] = useState(-1);
    const [dataSearch, setDataSearch] = useState([]);
    const [cptData, setCptData] = useState([]);
    const [isResult, setIsResult] = useState(false);
    const [searchInput, setInput] = useState("");
    const [searching, setSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const current_patient_id = cookies.get("current_patient_id");
    const current_appointment_id = cookies.get("current_appointment_id");
    const dispatch = useDispatch();
    const apointment_ultrasound_id = cookies.get("apointment_ultrasound_id");
    const CPT = useSelector((state) => state.common.CPT);
    const searchData = useSelector((state) => state.common.searchCPT);
    const patient_plan = useSelector(
      (state) => state.procedure.get_plan_patient
    );
    const [dataPlan, setDataPlan] = useState([]);
    const [printLink, setPrintLink] = useState("");
    const [loadingButton, setLoadingButton] = useState(true);
    const [medicalDecision, setMeicalDecision] = useState();
    const [isLock, setIsLock] = useState(0);
    const [readingPhysicianId, setReadingPhysicianId] = useState(0);

    const onChange = (type) => (value) => {
      console.log(value);
      setReadingPhysicianId(value);
    };

    const is_locked = useSelector(
      (state) => state.clinicProviderReview.is_locked
    );

    const reading_physician_id = useSelector(
      (state) => state.clinicProviderReview.reading_physician_id
    );

    const checkIslock = () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        setIsLock(is_locked);
        setLoadingButton(false);
      }, 1000);
    };

    useEffect(() => {
      checkIslock();
    }, [is_locked]);

    useEffect(() => {
      setReadingPhysicianId(reading_physician_id);
    }, [reading_physician_id]);

    useEffect(() => {
      dispatch(get_plan_patient(current_patient_id, current_appointment_id));
    }, []);
    const removeRow = (index) => {
      let newRows = [];
      newRows = [...cptData];
      newRows.splice(index, 1);
      onChangeCPT([...newRows]);
      setCptData([...newRows]);
    };

    const selectItem = (itemcheck, item, type) => {
      const newRows = cptData;
      let checkExist = false;
      cptData.some((r) => {
        if (r.cpt_code === item) {
          checkExist = true;
        }
      });
      if (!checkExist) {
        newRows.push({ cpt_code: item, short_desc: type });
        setCptData(newRows);
        onChangeCPT([...newRows]);
        setIsResult(false);
        setInput("");
      } else {
        setIsResult(false);
        setInput("");
      }
    };

    /*const checkPrint = async () => {
    const base64 = await pdf2base64(appointment_pdf.url);
    setPrintLink(base64);
    await dispatch(reset_appointment_pdf());
  };

  useEffect(() => {
    if (appointment_pdf && Object.keys(appointment_pdf).length > 0) {
      checkPrint();
    }
  }, [appointment_pdf]);

  const openFile = async () => {
    if (printLink) {
      await printJS({ printable: printLink, type: "pdf", base64: true });
      setTimeout(async () => {
        setPrintLink("");
      }, 1000);
    }
  };

  useEffect(() => {
    openFile();
  }, [printLink]);*/

    useEffect(() => {
      if (patient_plan && patient_plan.length > 0) {
        let checkArray = [];
        patient_plan.map((item) => {
          (item?.plan || []).map((r) => {
            if (r.processed === 1) {
              if (r.bundle === "ultrasound_test") {
                checkArray = [
                  ...checkArray,
                  { value: r?.ultrasound?.ultrasound_type?.name },
                ];
              } else if (r.bundle === "procedure") {
                checkArray = [
                  ...checkArray,
                  { value: r?.procedure?.procedure_type?.value },
                ];
              } else if (r.bundle === "") {
                checkArray = [
                  ...checkArray,
                  { value: r?.procedure?.procedure_type?.value },
                ];
              }
            }
          });
        });
        setDataPlan(checkArray);
      }
    }, [patient_plan]);

    const handleSearch = async (search) => {
      await setInput(search);
      clearTimeout(timer);
      setLoading(true);
      timer = setTimeout(async () => {
        await dispatch(search_cpt_code(search));
        setLoading(false);
      }, 1000);
    };

    const saveAll = async () => {
      await dispatch(setGlobalLoading(true));
      setLoading(true);
      const param = {
        text_template: valueText,
        cpt_codes: cptData.map((r) => {
          return r.cpt_code;
        }),
        medical_decision_making: medicalDecision,
        reading_physician_id: readingPhysicianId,
      };

      if (isLock == 1) {
        await dispatch(unlock_finalize(apointment_ultrasound_id));
        setIsLock(0);
        await dispatch(unlockClinicProcedure());
      } else {
        await dispatch(set_cpt_code_review(apointment_ultrasound_id, param));
        await dispatch(lockClinicProcedure());
        setIsLock(1);
        await dispatch(
          setGlobalToastr({
            message: "The procedure is finalized",
          })
        );
      }
      await dispatch(get_cpt_code_review(apointment_ultrasound_id));

      setLoading(false);
      await dispatch(setGlobalLoading(false));
    };

    useEffect(() => {
      if (searchData.length > 0) {
        const checkedArray = searchData.map((item) => {
          return {
            id: item.id,
            cpt_code: item.code,
            short_desc: item.short_desc,
            created_at: item.created_at,
            updated_at: item.updated_at,
            fee: item.fee,
            cpt_code_name: item.cpt_code_name,
          };
        });
        setDataSearch(checkedArray);
      }
    }, [searchData]);

    useEffect(() => {
      if (CPT && CPT.cpt_codes && CPT.cpt_codes.length > 0) {
        const checkedArray = CPT.cpt_codes.map((r) => {
          return {
            cpt_code: r.code,
            short_desc: r.short_desc,
          };
        });
        onChangeCPT([...checkedArray]);
        setCptData(checkedArray);
        setIsLock(CPT.is_locked);
      }
    }, [CPT]);

    useEffect(() => {
      if (isShow) {
        // dispatch(setGlobalLoading(loading))
      }
    }, [loading, isShow]);

    if (!isShow) {
      return null;
    }

    return (
      <>
        <div
          ref={ref}
          className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
        >
          <ScrollWrapper>
            <div className="headerContainer">
              <button onClick={handleCloseSidebar}>
                <CloseIcon />
              </button>
            </div>
            <SidebarTitle
              icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
              title={
                detailUltrasounds && detailUltrasounds.name ? (
                  detailUltrasounds.name.toUpperCase()
                ) : (
                  <span className="text-uppercase">Summary Dashboard</span>
                )
              }
              subTitle=""
            />

            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "8px",
                paddingBottom: "1rem",
                minHeight: "45vh",
              }}
            >
              <div className="resourceInfo" style={{ marginTop: "1rem" }}>
                <ReviewIcdCode />
              </div>

              <div
                className="resourceInfo between"
                style={{ marginTop: "1rem" }}
              >
                <CptReview
                  DataOpSearch={dataSearch}
                  keySearch="cpt_code_name"
                  keyType="short_desc"
                  title="cpt_review"
                  keyValue="cpt_code"
                  rows={cptData}
                  removeItem={removeRow}
                  loading={searching}
                  isResult={isResult}
                  query={searchInput}
                  selectItem={selectItem}
                  handleSearch={handleSearch}
                  isLocked={isLock}
                />
              </div>

              <div className="resourceInfo d-flex required-field">
                <EditableFormInput
                  label={"Reading Physician"}
                  value={readingPhysicianId || undefined}
                  handleChange={onChange("reading_physician_id")}
                  type={"check"}
                  optionKey={"id"}
                  valueKey={"value"}
                  options={providers || []}
                />
              </div>
            </div>
            {usersCheck && usersCheck?.is_provider == 1 ? (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: isLock == 1 ? "#C6C6C6" : "#1164F2",
                      }}
                      onClick={saveAll}
                    >
                      {isLock == 1 ? "Unfinalize" : "Finalize +"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#1164F2",
                      }}
                    >
                      {"Save for Review"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            )}
          </ScrollWrapper>
        </div>
      </>
    );
  }
);

export default ReviewSideBar;
