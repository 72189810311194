import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "antd";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ChartingSidebar from "./ChartingSidebar";
import { LeftCharvenSVG } from "../../../../common/components/icons/SVGIcon";
import {
  getLocations,
  setParamsChart,
  getProcedureData,
} from "../../../../store/actions";
import { StaticResource, TempReview } from "../../constant";
import dataHeader from "./formarterData";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const cookies = new Cookies();
let timer = null;
const Charting = ({ setAcitive }) => {
  const patientResource = useSelector(
    (state) => state.clinicProvider.patientResource
  );
  const authName = cookies.get("cvai-current-user-name");
  const [text, setText] = useState(EditorState.createEmpty());
  const [textRender, setTextRender] = useState({});
  const [text_template_detail, set_text_template_detail] = useState([]);
  const [text_template_hospital, set_text_template_hospital] = useState([]);
  const [check_render_ag, setCheckRenderAG] = useState("");
  const [isSidebar, setIsSidebar] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const detailProcedureData = useSelector(
    (state) => state.common.detailProcedureData
  );
  const [dataIDDiff, setDataIDDiff] = useState([]);
  const [detailHospital, setDetailHospital] = useState({});
  const [content, setContent] = useState(EditorState.createEmpty());
  const conditionData = useSelector((state) => state.common.referralConditions);
  const procedureData = useSelector((state) => state.common.procedureData);
  const procedure_type = useSelector(
    (state) => state.procedureDetail.procedure_type
  );
  const providers = useSelector((state) => state.common.providers);
  const paramsChart = useSelector((state) => state.patientRecords.paramsChart);
  const indicationsValue = useSelector(
    (state) => state.patientRecords.indicationsValue
  );
  const [textHeader, setTextHeader] = useState(undefined);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const clickSetRow = (value, item) => {
    setDetailHospital(item);
  };

  useEffect(() => {
    dispatch(getProcedureData());
    dispatch(getLocations());
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
    const contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
    const editorState = EditorState.createWithContent(contentState);
    setText(editorState);
  }, []);

  useEffect(() => {
    if (detailProcedureData && detailProcedureData.text_template_serialized) {
      set_text_template_detail({
        text_template: [detailProcedureData.text_template_serialized],
        id: detailProcedureData.id,
      });
    }
    if (
      detailHospital &&
      detailHospital.medical_reading &&
      detailHospital.medical_reading.text_template
    ) {
      set_text_template_hospital([
        detailHospital.medical_reading.text_template,
      ]);
    }
  }, [detailProcedureData, detailHospital]);

  const onChange = (editorState) => {
    setText(editorState);
    let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setParamsChart("text_param", data));
    }, 500);
  };

  useEffect(() => {
    //
    if (
      (paramsChart && Object.keys(paramsChart).length > 0) ||
      (indicationsValue && Object.keys(indicationsValue).length > 0)
    ) {
      let data = JSON.stringify(convertToRaw(text.getCurrentContent()));
      if (
        data.includes("DATE OF PROCEDURE") ||
        data.includes("PREOPERATIVE DIAGNOSIS") ||
        data.includes("POSTOPERATIVE DIAGNOSIS") ||
        data.includes("PROCEDURE PERFORMED") ||
        data.includes("DISPOSITION") ||
        data.includes("COMPLICATIONS") ||
        data.includes("ESTIMATED BLOOD LOSS") ||
        data.includes("INDICATIONS") ||
        data.includes("SURGEON")
      ) {
        if (procedureData && procedureData.length > 0) {
          const dataIDDiff = (procedureData || []).map((r) => r.id);
          const DataID = (paramsChart.appointment_type_id || []).map(
            (r) => r.value
          );
          var a = dataIDDiff;
          var b = DataID;
          var b1 = new Set(b);
          var difference = [...new Set([...a].filter((x) => !b1.has(x)))];
          let check = "";
          difference.map((r) => {
            const [headDiff, ...itemHeadDiff] =
              data &&
              data.split(
                `,{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
              );
            const checkHeadDiff = (itemHeadDiff || []).map((item) => {
              return item.split(
                `{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
              )[0];
            });
            if (checkHeadDiff && checkHeadDiff[0]) {
              if (
                data.includes(
                  `,{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadDiff[0]}{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                )
              ) {
                data = data.replace(
                  `{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadDiff[0]}{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                  ``
                );
              } else if (
                data.includes(
                  `,{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadDiff[0]}{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                )
              ) {
                data = data.replace(
                  `,{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadDiff[0]}{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
                  ``
                );
              }
            }
          });
        }
        // head 1
        const [head1, ...itemHead1] =
          data && data.split(`},{"key":"text1","text":"`);
        const checkHead1 = (itemHead1 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead1) {
          data = data.replace(
            `{"key":"text1","text":"${checkHead1[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text1","text":"${
              paramsChart?.appointment_date
                ? moment(paramsChart?.appointment_date).format("MM/DD/YYYY")
                : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              paramsChart?.appointment_date
                ? `,{"key":"5td5s","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 2
        const [head2, ...itemHead2] =
          data && data.split(`},{"key":"text2","text":"`);
        const checkHead2 = (itemHead2 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead2) {
          data = data.replace(
            `{"key":"text2","text":"${checkHead2[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text2","text":"${
              paramsChart?.side ? `${paramsChart?.side} ` : ""
            }${
              paramsChart?.preoperative_dx_id
                ? (
                    (conditionData || []).find(
                      (r) => r.id == paramsChart?.preoperative_dx_id
                    ) || {}
                  ).value
                : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              paramsChart?.preoperative_dx_id || paramsChart?.side
                ? `,{"key":"e01nq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 3
        const [head3, ...itemHead3] =
          data && data.split(`},{"key":"text3","text":"`);
        const checkHead3 = (itemHead3 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead3) {
          data = data.replace(
            `{"key":"text3","text":"${checkHead3[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text3","text":"${
              paramsChart?.side ? `${paramsChart?.side} ` : ""
            }${
              paramsChart?.preoperative_dx_id
                ? (
                    (conditionData || []).find(
                      (r) => r.id == paramsChart?.preoperative_dx_id
                    ) || {}
                  ).value
                : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              paramsChart?.preoperative_dx_id || paramsChart?.side
                ? `,{"key":"37ke","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 4
        if (
          data.includes(
            `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )
        ) {
          const [head4, ...itemHead4] =
            data &&
            data.split(
              `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
            );
          const checkHead4 = (itemHead4 || []).map((item) => {
            return item.split(
              `{"key":"b16nu","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            )[0];
          });
          if (checkHead4) {
            data = data.replace(
              `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHead4[0]}`,
              `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${(
                paramsChart.appointment_type_id || []
              ).map((r, index) => {
                return `${
                  index > 0
                    ? `{"key":"txtkeyempty${r.value}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                    : ""
                }{"key":"txtdesc${r.value}","text":"${
                  r.label
                }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`;
              })}${
                paramsChart.appointment_type_id &&
                paramsChart.appointment_type_id.length > 0
                  ? ","
                  : ""
              }`
            );
          }
        }
        // head 5
        const [head5, ...itemHead5] =
          data && data.split(`},{"key":"text5","text":"`);
        const checkHead5 = (itemHead5 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead5) {
          data = data.replace(
            `{"key":"text5","text":"${checkHead5[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text5","text":"${
              paramsChart?.disposition ? paramsChart?.disposition : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              paramsChart?.disposition
                ? `,{"key":"a9p8d","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 6
        const [head6, ...itemHead6] =
          data && data.split(`},{"key":"text6","text":"`);
        const checkHead6 = (itemHead6 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead6) {
          data = data.replace(
            `{"key":"text6","text":"${checkHead6[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text6","text":"${
              paramsChart?.complications ? paramsChart?.complications : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              paramsChart?.complications
                ? `,{"key":"48v4j","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 7
        const [head7, ...itemHead7] =
          data && data.split(`},{"key":"text7","text":"`);
        const checkHead7 = (itemHead7 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead7) {
          data = data.replace(
            `{"key":"text7","text":"${checkHead7[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text7","text":"${
              paramsChart?.ebl ? `${paramsChart?.ebl} cc` : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              paramsChart?.ebl
                ? `,{"key":"8mesm","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 8
        const [head8, ...itemHead8] =
          data &&
          data.split(
            `{"key":"6rdo2","text":"INDICATIONS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"},{"offset":0,"length":11,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead8 = (itemHead8 || []).map((item) => {
          return item.split(
            `{"key":"4i8qs","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          )[0];
        });
        if (checkHead8 && checkHead8[0] && checkHead8[0] !== "") {
          let valueImport =
            indicationsValue?.indications &&
            indicationsValue?.indications.length > 0
              ? indicationsValue?.indications.replace(`{"blocks":[`, "")
              : null;
          const renderBlock = valueImport
            ? valueImport.replace(`],"entityMap":{}}`, "")
            : null;
          if (renderBlock) {
            data = data.replace(
              `${checkHead8[0]}`,
              `{"key":"txtbrl8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${renderBlock},{"key":"textend","text":"The patient was made aware of the risks and benefits of the procedure including indication, cardiac events, stroke, limb loss and death. The patient wishes to proceed. All questions have been answered in full.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        } else {
          if (
            indicationsValue &&
            indicationsValue?.indications &&
            indicationsValue?.indications.length > 0
          ) {
            let valueImport = indicationsValue?.indications.replace(
              `{"blocks":[`,
              ""
            );
            const renderBlock = valueImport.replace(`],"entityMap":{}}`, "");
            let clear = data.replace(
              `{"key":"6rdo2","text":"INDICATIONS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"},{"offset":0,"length":11,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`,
              `{"key":"6rdo2","text":"INDICATIONS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"},{"offset":0,"length":11,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},${renderBlock},{"key":"textend","text":"The patient was made aware of the risks and benefits of the procedure including indication, cardiac events, stroke, limb loss and death. The patient wishes to proceed. All questions have been answered in full.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
            data = clear;
          }
        }
        // head 9
        const [head9, ...itemHead9] =
          data && data.split(`},{"key":"text9","text":"`);
        const checkHead9 = (itemHead9 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead9) {
          data = data.replace(
            `{"key":"text9","text":"${checkHead9[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text9","text":"${
              paramsChart?.provider_id
                ? (
                    (providers || []).find(
                      (r) => r.id == paramsChart?.provider_id
                    ) || {}
                  ).value
                : `${authName}`
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        // head 11
        const [head11, ...itemHead11] =
          data && data.split(`},{"key":"text11","text":"`);
        const checkHead11 = (itemHead11 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead11) {
          data = data.replace(
            `{"key":"text11","text":"${checkHead11[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text11","text":"${
              paramsChart?.anesthesia ? paramsChart?.anesthesia : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}
            ${
              paramsChart?.anesthesia
                ? `,{"key":"8ca3sm","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        var checkArray = [];
        if (paramsChart.ros && paramsChart.ros.length > 0) {
          (paramsChart.ros || []).map((r, index) => {
            return (r.values || []).map((vl, ivl) => {
              if (vl.checked && vl.checked == true) {
                checkArray = [
                  ...checkArray,
                  {
                    type: r.typeFull,
                    variable_name: r.variable_name,
                    checked: true,
                    text_template: vl.text_template,
                    option: vl.option,
                  },
                ];
              }
            });
          });
        }
        let fixText = data;
        checkArray.map((r, indexr) => {
          if (r.checked) {
            setTextRender({
              ...textRender,
              [`${r.variable_name}`]: r.text_template,
            });
            if (fixText.includes(`[${r.variable_name}]`)) {
              const a = fixText.split(`[${r.variable_name}]`);
              (a || []).map((i, indexi) => {
                fixText = fixText.replace(
                  `[${r.variable_name}]`,
                  `${r.text_template}`
                );
              });
            } else {
              const a = fixText.split(`${textRender[`${r.variable_name}`]}`);
              (a || []).map((i, indexi) => {
                fixText = fixText.replace(
                  `${textRender[`${r.variable_name}`]}`,
                  `${r.text_template}`
                );
              });
            }
          }
        });

        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(
            setParamsChart("text_param", fixText.length > 0 ? fixText : data)
          );
        }, 500);
        const contentState = convertFromRaw(
          JSON.parse(fixText.length > 0 ? fixText : data)
        );
        const editorState = EditorState.createWithContent(contentState);
        setText(editorState);
      }
    }
  }, [paramsChart, indicationsValue, procedureData]);

  useEffect(() => {
    let data = JSON.stringify(convertToRaw(text.getCurrentContent()));
    const checkBlock = JSON.parse(data);
    if (
      data &&
      checkBlock &&
      checkBlock.blocks &&
      checkBlock.blocks.length > 10
    ) {
      let mapText_Check = [];
      let array_text_detail = [];
      const ckm = `${text_template_detail.text_template.map((r, index) => {
        if (r && r.length > 1 && r.includes("blocks")) {
          const mapText_ = JSON.parse(r);
          if (mapText_) {
            (mapText_.blocks || []).map((r, i) => {
              if (mapText_.blocks.length <= 1) {
                mapText_Check = [
                  {
                    key: "txtdxhead" + text_template_detail.id,
                    text: "",
                    type: "unstyled",
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  ...mapText_Check,
                  r,
                  {
                    key: "txtdxend" + text_template_detail.id,
                    text: "",
                    type: "unstyled",
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                ];
              } else {
                if (i == 0) {
                  mapText_Check = [
                    {
                      key: "txtdxhead" + text_template_detail.id,
                      text: "",
                      type: "unstyled",
                      depth: 0,
                      inlineStyleRanges: [],
                      entityRanges: [],
                      data: {},
                    },
                    ...mapText_Check,
                    r,
                  ];
                }
                if (i == mapText_.blocks.length - 1) {
                  mapText_Check = [
                    ...mapText_Check,
                    r,
                    {
                      key: "txtdxend" + text_template_detail.id,
                      text: "",
                      type: "unstyled",
                      depth: 0,
                      inlineStyleRanges: [],
                      entityRanges: [],
                      data: {},
                    },
                  ];
                }
                if (i != 0 && i !== mapText_.blocks.length - 1) {
                  mapText_Check = [...mapText_Check, r];
                }
              }
            });
          }
          array_text_detail = mapText_Check;
        }
      })}`;
      // Head end
      const [headend, ...itemHeadend] =
        data &&
        data.split(
          `{"key":"textendtxt","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      const checkHeadend = (itemHeadend || []).map((item) => {
        return item.split(`],"entityMap"`)[0];
      });
      let mixon = "";
      if (checkHeadend && array_text_detail) {
        let check = data.replace(
          `{"key":"textendtxt","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadend[0]}`,
          `{"key":"textendtxt","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
            check_render_ag && check_render_ag.length > 2
              ? `${check_render_ag},${array_text_detail
                  .map((r) => JSON.stringify(r))
                  .join()}`
              : `,${array_text_detail.map((r) => JSON.stringify(r)).join()}`
          }`
        );
        data = check;
        const contentState = convertFromRaw(JSON.parse(data));
        const editorState = EditorState.createWithContent(contentState);
        setText(editorState);
      }
    }
  }, [text_template_detail]);

  useEffect(() => {
    if (text) {
      let data = JSON.stringify(convertToRaw(text.getCurrentContent()));
      const [headend, ...itemHeadend] =
        data &&
        data.split(
          `{"key":"textendtxt","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      const checkHeadend = (itemHeadend || []).map((item) => {
        return item.split(`],"entityMap"`)[0];
      });
      if (checkHeadend && checkHeadend[0]) {
        setCheckRenderAG(`${checkHeadend[0]}`);
      }
    }
  }, [text]);
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div
        className="eventsContainer has-sub-header"
        style={{ paddingTop: "1rem" }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <Editor
            toolbar={{
              options: ["inline", "list"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            editorStyle={{
              border: "1px solid #f2f2f2",
              padding: "10px",
              height: "100%",
              backgroundColor: "#fff",
            }}
            placeholder="Patient Review"
            editorState={text}
            onEditorStateChange={onChange}
          />
        </div>
      </div>
      <ChartingSidebar
        ref={sidebarRef}
        setAcitive={setAcitive ? setAcitive : {}}
        onClickSetRow={(value, item) => {
          clickSetRow(value, item);
        }}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
      <SidebarExtension startingWidth='calc(28.3% - 20px)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
    </div>
  );
};

export default Charting;
