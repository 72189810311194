import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';
import { Input } from 'antd';
import { SearchSVG } from '../icons/SVGIcon';
import moment from "moment";
import { useOutsideDetect } from "../../../common/utils";
import Loading from "../../../common/components/Loading";
import ScrollWrapper from "../../../common/components/ScrollBar";

import {
  getPatientsByQuery,
  setPatientForRecords,
  setPatientHistory,
  setStepData,
  setPatientRecordData,
  addPatientSearchHistory,
} from '../../../store/actions';

import './style.scss';

const { Search } = Input;

const SearchPatient = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const history = useHistory();
  const patients = useSelector((state) => state.dashboard.patients);
  const loading = useSelector((state) => state.dashboard.loadingPatients);
  const globalLoading = useSelector((state) => state.common.globalLoading);

  const dispatch = useDispatch();

  const [openPanel, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [activeSearch, setActiveSearch] = useState(-1);

  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    if (debouncedValue && openPanel) {
      dispatch(getPatientsByQuery(search));
    }
  }, [debouncedValue]);

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOpen(true);
  };

  const handleSearchSelect = async (row, index) => {
    setActiveSearch(index)
    setSearch(
      `${row.first_name || ""} ${row.last_name || ""} - ${
        row.date_of_birth && moment(row.date_of_birth).format("MM/DD/YYYY")
      }`
    );
    setOpen(false);
    await dispatch(setStepData("resource", null));
    await dispatch(setPatientRecordData("resource", null));
    await dispatch(setPatientForRecords(row.id));
    await dispatch(addPatientSearchHistory(row.id));
    await dispatch(setPatientHistory(row));
    history.push("/patient-records/clinical");
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  return (
    <li ref={ref} className='searchListItem headerListItem'>
      <Search
        value={search}
        placeholder="Search..."
        onChange={handleSearch}
        style={{ width: 'calc(100% - 60px)', height: '100%', backgroundColor: '#2c3f48', paddingRight: 16 }}
        suffix={<SearchSVG />}
        onFocus={() => setOpen(true)}
      />
      {openPanel && search && rowData.length > 0 && (
        <div
          ref={ref1}
          className="fullContainer patientSearch__result"
          style={{ height: `${rowData.length > 0 ? rowData.length * 40 : 80}px` }}
        >
          <div
            className="tableSection"
            style={{ height: "100%", position: "relative" }}
          >
            <div className="tableContainer">
              <ScrollWrapper css="no-padding" onScrollFrame={onScroll}>
                <div className="tableList">
                  {search &&
                    (rowData || []).map((row, index) => (
                      <div
                        className={`tableItem ${
                          index === 0 ? "no-border" : ""
                        } ${index === activeSearch ? "active" : ""}`}
                        key={`resultTable-${index}`}
                        onClick={() => handleSearchSelect(row, index)}
                      >
                        <div
                          className="td with-icon"
                          style={{ width: "100%" }}
                        >
                          <p>{`${row.first_name || ""} ${
                            row.last_name || ""
                          } - ${
                            row.date_of_birth &&
                            moment(row.date_of_birth).format("MM/DD/YYYY")
                          }`}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      )}

      {(globalLoading || loading) && (
        <Loading
          extra={{
            position: "absolute",
            right: "8px",
            width: "40px",
            height: "40px",
            left: "unset",
            transform: "translate(0%, -50%)"
          }}
          color="#ffffff"
        />
      )}
    </li>
  );
};

export default SearchPatient;
