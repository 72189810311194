import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Upload, message, Select, Checkbox } from "antd";
import { useDebounce } from "use-lodash-debounce";
import moment from "moment";

import YesNo from "../../../common/components/YesNo";
import { getDiseases, getSurgicalHistories } from "../../../services/api.services";
import {
  updateMedicalHistory,
  setStepData,
  searchAllergy,
  setGlobalLoading,
} from "../../../store/actions";
import ICDAutoCompleteComponent from "../../../common/components/ICDAutoComplete";
import MedicationAutoComplete from "../../../common/components/MedicationAutoComplete";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";

const { Panel } = Collapse;
const { Dragger } = Upload;
const { Option } = Select;

const initialState = {
  previous_medical_history: [],
  previous_surgical_history: [],
  medications: [],
  allergies: [],
  flu_vaccination: -1,
  no_medication: false,
  no_drug_allergies: false,
  pneumococcal: -1,
  pneumococcal_reason: "",
  flu_vaccination_reason: null,
  aortic_disease: -1,
  amputation: -1,
  heart_disease: -1,
  smoking_ppd: 0,
  alcoholic_drink_pw: "",
};

const draggerProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
let timer = null;
const MedicalHistoryComponent = ({ ...restProps }) => {
  const { resource, medical } = useSelector((state) => ({
    resource: state.newPatient.resource,
    medical: state.newPatient.medical,
  }));

  const dispatch = useDispatch();
  const [isMounted, setMount] = useState(false);
  const [option, setOption] = useState(initialState);
  const [row, setRow] = useState(initialState);
  const [searchInput, setInput] = useState("");
  const [searching, setSearching] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [hideScope, setHideScope] = useState(false);
  const [type, setType] = useState("");
  const [ICDCodeName, setICDCodeName] = useState([]);
  const [is65, setIs65] = useState(false);
  const [loadingAllergyLocal, setLoadingAllergyLocal] = useState(false);
  const loadingAllergy = useSelector((state) => state.common.loadingAllergy);
  const allergiesRedux = useSelector((state) => state.common.allergies);
  const debouncedValue = useDebounce(searchInput, 400);

  const searchResult = async () => {
    setSearching(true);
    setIsResult(true);
    try {
      const result = await getDiseases(searchInput);
      if (isMounted) {
        const newOptions = result.data || [];
        const newOption = { ...option, [type]: newOptions };
        setOption(newOption);
        setSearching(false);
      }
    } catch (error) {
      const newOption = { ...option, [type]: [] };
      setOption(newOption);
      setSearching(false);
    }
  };

  const searchResultSurgicalHistory = async () => {
    setSearching(true);
    setIsResult(true);
    try {
      const result = await getSurgicalHistories(searchInput);
      if (isMounted) {
        const newOptions = result.data || [];
        const newOption = { ...option, [type]: newOptions };
        setOption(newOption);
        setSearching(false);
      }
    } catch (error) {
      const newOption = { ...option, [type]: [] };
      setOption(newOption);
      setSearching(false);
    }
  };

  useEffect(() => {
    if (resource) {
      if (moment().diff(resource?.date_of_birth, "years") >= 65) {
        setIs65(true);
      } else {
        setIs65(false);
      }
    }
  }, [resource]);

  useEffect(() => {
    dispatch(
      setGlobalLoading(searching || loadingAllergyLocal || loadingAllergy)
    );
  }, [searching, loadingAllergy, loadingAllergyLocal]);

  useEffect(() => {
    setMount(true);
    return () => {
      setMount(false);
    };
  });

  useEffect(() => {
    if (debouncedValue) {
      searchResult();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (medical) {
      const previous_surgical_history = (
        medical["previous_surgical_history"] || []
      ).map((sh) => sh.procedure_name || sh);

      const medications = (medical.medications || []).map(
        (mh) => mh.name || mh
      );
      const allergies = (medical["allergies"] || []).map(
        (ah) => ah.allergy_name || ah
      );
      const newRow = {
        ...row,
        previous_surgical_history: previous_surgical_history,
        medications,
        allergies,
        flu_vaccination: medical.flu_vaccination,
        pneumococcal: medical.pneumococcal,
        pneumococcal_reason: medical?.pneumococcal_reason,
        flu_vaccination_reason: medical?.flu_vaccination_reason,
        no_medication: medical?.no_medication,
        no_drug_allergies: medical?.no_drug_allergies,
        aortic_disease: medical.aortic_disease,
        amputation: medical.amputation,
        heart_disease: medical.heart_disease,
        smoking_ppd: medical.smoking_ppd,
        previous_smoking_ppd: medical.previous_smoking_ppd,
        years_of_smoking: medical?.years_of_smoking,
        alcoholic_drink_pw: medical.alcoholic_drink_pw,
      };
      setRow(newRow);
    }
  }, [medical]);

  useEffect(() => {
    if (resource && resource.icd_codes) {
      const arrayICDCodeName = (resource.icd_codes || []).map((r) => {
        return `${r?.disease?.icd_code_name}`;
      });
      let name = [];
      (arrayICDCodeName || []).map((item, index) => {
        if (!name.includes(item)) {
          name.push(item);
        }
      });
      setICDCodeName(name);
      const previous_surgical_history = (
        medical["previous_surgical_history"] || []
      ).map((sh) => sh.procedure_name || sh);

      const medications = (medical.medications || []).map(
        (mh) => mh.name || mh
      );
      const allergies = (medical["allergies"] || []).map(
        (ah) => ah.allergy_name || ah
      );
      const newRow = {
        ...row,
        previous_medical_history: name,
        previous_surgical_history: previous_surgical_history,
        medications,
        allergies,
        flu_vaccination: medical.flu_vaccination,
        pneumococcal: medical.pneumococcal,
        pneumococcal_reason: medical?.pneumococcal_reason,
        flu_vaccination_reason: medical?.flu_vaccination_reason,
        no_medication: medical?.no_medication,
        no_drug_allergies: medical?.no_drug_allergies,
        aortic_disease: medical.aortic_disease,
        amputation: medical.amputation,
        heart_disease: medical.heart_disease,
        years_of_smoking: medical.years_of_smoking,
        smoking_ppd: medical.smoking_ppd,
        previous_smoking_ppd: medical.previous_smoking_ppd,
        alcoholic_drink_pw: medical.alcoholic_drink_pw,
      };
      setRow(newRow);
    }
  }, [resource]);

  const changePanel = (selectedType) => {
    setType(selectedType);
    setInput("");
  };

  const handleUpdateOption = (selectedType) => (value) => {
    let newRow = {};
    if (selectedType === "flu_vaccination_reason") {
      newRow = {
        ...row,
        ["flu_vaccination_reason"]: value
          ? moment(value).format("MM/DD/YYYY")
          : "",
      };
    } else if (selectedType === "no_medication") {
      newRow = { ...row, ["no_medication"]: value.target.checked };
    } else if (selectedType === "no_drug_allergies") {
      newRow = { ...row, ["no_drug_allergies"]: value.target.checked };
    } else {
      newRow = { ...row, [selectedType]: value };
    }
    dispatch(updateMedicalHistory(newRow, resource.id));
    dispatch(setStepData("medical", newRow));
  };

  const handleUpdateBox = (selectedType) => (e) => {
    const newRow = { ...row, [selectedType]: e };
    setRow(newRow);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(updateMedicalHistory(newRow, resource.id));
      dispatch(setStepData("medical", newRow));
    }, 500);
  };

  const selectItem = (item, typeCheck) => {
    const checkExist = (row[typeCheck] || []).some(
      (r) => r.replace(/\s/g, "") === item.replace(/\s/g, "")
    );

    if (row && row[typeCheck] && !checkExist) {
      var ICDCheck = [...ICDCodeName];
      if (!ICDCodeName.some((r) => r === item)) {
        ICDCheck = [...ICDCheck, item];
        setICDCodeName([...ICDCheck]);
      }
      const newRows = [...row[typeCheck], item];

      let newRow = {};
      if (typeCheck == "previous_medical_history") {
        newRow = {
          ...row,
          ["previous_medical_history"]: [...ICDCheck],
        };
      } else {
        newRow = {
          ...row,
          [typeCheck]: newRows,
        };
      }
      setIsResult(false);
      setInput("");
      dispatch(updateMedicalHistory(newRow, resource.id));
      dispatch(setStepData("medical", newRow));
    }
  };

  const handleSearch = (search) => {
    setInput(search);
  };

  const searchingAllergy = async (query) => {
    clearTimeout(timer);
    setLoadingAllergyLocal(true);
    timer = setTimeout(() => {
      setLoadingAllergyLocal(false);
      dispatch(searchAllergy(query));
    }, 500);
  };

  const removeRow = (item) => {
    const index = ICDCodeName.findIndex((r) => r === item);
    const newRowsICD = [...ICDCodeName];
    newRowsICD.splice(index, 1);
    setICDCodeName(newRowsICD);
    const rowIndex = row[type].findIndex((r) => r === item);
    const newRows = [...row[type]];
    newRows.splice(rowIndex, 1);
    const newRow = {
      ...row,
      [type]: newRows,
      ["previous_medical_history"]: newRowsICD,
    };
    dispatch(updateMedicalHistory(newRow, resource.id));
    dispatch(setStepData("medical", newRow));
  };

  useEffect(() => {
    let a = moment();
    let b = moment(resource.date_of_birth);
    if (a.diff(b, "years") >= 62) {
      setHideScope(false);
    } else {
      setHideScope(true);
    }
  }, [resource]);

  return (
    <div className="resourceContent pr-7px">
      <Collapse accordion onChange={changePanel}>
        <Panel header="Previous Medical History"
          className={(resource?.icd_codes && resource?.icd_codes.length > 0)  ? '' : 'invalid'}
          key="previous_medical_history">
          <ICDAutoCompleteComponent
            title="previous-medical"
            rows={ICDCodeName} //array of string to send to server
            keyLabel={"icd_code_name"} //search key
            removeItem={removeRow}
            addItem={searchResult}
            loading={searching}
            options={option["previous_medical_history"]}
            isResult={isResult}
            query={searchInput}
            selectItem={(value) => {
              selectItem(value, "previous_medical_history");
            }}
            handleSearch={handleSearch}
          />
        </Panel>
        <Panel header="Immunization" className={(resource?.pneumococcal != null ||
            resource?.pneumococcal == -1 || resource?.pneumococcal == null)  ? '' : 'invalid'} key="history-2">
          {hideScope &&
          Number(moment().format("MM")) < 10 &&
          Number(moment().format("MM")) > 2 ? (
            <div>There is no immunization data needed at this time</div>
          ) : null}
          {Number(moment().format("MM")) >= 10 ||
          Number(moment().format("MM")) <= 2 ? (
            <div className="resourceInfo between half-pannel">
              <div>Have you received flu vaccination this season?</div>
              <YesNo
                option={row["flu_vaccination"]}
                setOption={handleUpdateOption("flu_vaccination")}
              />
            </div>
          ) : null}
          {row["flu_vaccination"] == 1 ? (
            <div className="resourceInfo between half-pannel">
              <div>Date vaccination received?</div>
              <div
                style={{
                  width: "40%",
                  minWidth: "40%",
                }}
              >
                <EditableFormInput
                  label={"Date"}
                  fullWidth={true}
                  value={
                    row["flu_vaccination_reason"]
                      ? moment(row["flu_vaccination_reason"])
                      : null
                  }
                  type="date"
                  handleChange={handleUpdateOption("flu_vaccination_reason")}
                />
              </div>
            </div>
          ) : null}
          {!hideScope ? (
            <React.Fragment>
              <div className="resourceInfo between half-pannel">
                <div>Have you received the pneumococcal vaccination?</div>
                <YesNo
                  option={row["pneumococcal"]}
                  setOption={handleUpdateOption("pneumococcal")}
                />
              </div>
              {row["pneumococcal"] == 0 ? (
                <div className="resourceInfo between half-pannel">
                  <div>Reasons why not?</div>
                  <div
                    style={{
                      width: "40%",
                      minWidth: "40%",
                    }}
                  >
                    <EditableFormInput
                      label={"Reasons"}
                      fullWidth={true}
                      value={row["pneumococcal_reason"]}
                      type="check"
                      options={[
                        { value: "Medical Reason" },
                        { value: "Refused" },
                      ]}
                      optionKey={"value"}
                      valueKey={"value"}
                      handleChange={handleUpdateOption("pneumococcal_reason")}
                    />
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
        </Panel>
        <Panel header="Previous Surgical History 2"
          className={(resource?.previous_surgical_history && resource?.previous_surgical_history.length > 0)  ? '' : 'invalid'}
          key="previous_surgical_history">
          <ICDAutoCompleteComponent
            title="previous-surgical"
            rows={row["previous_surgical_history"]}
            keyLabel={"icd_code_name"}
            removeItem={removeRow}
            addItem={searchResult}
            loading={searching}
            options={option["previous_surgical_history"]}
            isResult={isResult}
            query={searchInput}
            selectItem={(value) => {
              selectItem(value, "previous_surgical_history");
            }}
            handleSearch={handleSearch}
          />
        </Panel>
        <Panel header="Family History"
          className={((resource?.amputation == 1 || resource?.amputation == 0) && (resource?.aortic_disease == 1 || resource?.aortic_disease == 0))  ? '' : 'invalid'}
        
        key="history-4">
          <div className="resourceInfo between half-pannel">
            <div style={{ width: "calc(100% - 180px)" }}>
              Anyone in family suffer from aortic aneurysm disease?
            </div>
            <YesNo
              option={row["aortic_disease"]}
              setOption={handleUpdateOption("aortic_disease")}
            />
          </div>
          <div className="resourceInfo between half-pannel">
            <div style={{ width: "calc(100% - 180px)" }}>
              Anyone in family suffer from amputation?
            </div>
            <YesNo
              option={row["amputation"]}
              setOption={handleUpdateOption("amputation")}
            />
          </div>
          <div className="resourceInfo between half-pannel">
            <div style={{ width: "calc(100% - 180px)" }}>
              Anyone in family suffer from heart disease?
            </div>
            <YesNo
              option={row["heart_disease"]}
              setOption={handleUpdateOption("heart_disease")}
            />
          </div>
        </Panel>
        <Panel header="Social History" 
        className={(
          resource?.previous_smoking_ppd != null &&
          resource?.smoking_ppd != null &&
          resource?.years_of_smoking != null && resource?.years_of_smoking != '' &&
          resource?.alcoholic_drink_pw != null
        )  ? '' : 'invalid'}
        key="history-5">
          <div
            className="resourceInfo between half-pannel"
            style={{ alignItems: "center" }}
          >
            <div style={{ width: "calc(100% - 199px)" }}>
              Previous smoking history
            </div>
            <div
              style={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <EditableFormInput
                label={"Packs (ppd)"}
                fullWidth={true}
                value={
                  row["previous_smoking_ppd"] &&
                  Number(row["previous_smoking_ppd"])
                }
                type="check"
                options={[
                  { value: 0 },
                  { value: 1 },
                  { value: 1.5 },
                  { value: 2 },
                  { value: 2.5 },
                  { value: 3 },
                ]}
                optionKey={"value"}
                valueKey={"value"}
                handleChange={handleUpdateBox("previous_smoking_ppd")}
              />
            </div>
          </div>
          <div
            className="resourceInfo between half-pannel"
            style={{ alignItems: "center" }}
          >
            <div style={{ width: "calc(100% - 199px)" }}>
              Current smoking history
            </div>
            <div
              style={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <EditableFormInput
                label={"Packs (ppd)"}
                fullWidth={true}
                value={row["smoking_ppd"] && Number(row["smoking_ppd"])}
                type="check"
                options={[
                  { value: 0.5 },
                  { value: 1 },
                  { value: 1.5 },
                  { value: 2 },
                  { value: 2.5 },
                  { value: 3 },
                ]}
                optionKey={"value"}
                valueKey={"value"}
                handleChange={handleUpdateBox("smoking_ppd")}
              />
            </div>
          </div>
          <div
            className="resourceInfo between half-pannel"
            style={{ alignItems: "center" }}
          >
            <div style={{ width: "calc(100% - 199px)" }}>
              Total years smoking
            </div>
            <div
              style={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <EditableFormInput
                label={"Enter years"}
                fullWidth={true}
                value={row["years_of_smoking"]}
                handleChange={handleUpdateBox("years_of_smoking")}
              />
            </div>
          </div>
          <div
            className="resourceInfo between half-pannel"
            style={{ alignItems: "center" }}
          >
            {is65 || resource?.gender?.toLowerCase() === "female" ? (
              <div style={{ width: "calc(100% - 199px)" }}>
                How many times in the past year have you had more than 4
                drinks/day
              </div>
            ) : (
              <div style={{ width: "calc(100% - 199px)" }}>
                How many times in the past year have you had more than 5
                drinks/day
              </div>
            )}
            <div
              style={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <EditableFormInput
                label={"Days/year"}
                fullWidth={true}
                value={
                  row["alcoholic_drink_pw"] && `${row["alcoholic_drink_pw"]}`
                }
                type="check"
                options={[{ value: "< 2 days" }, { value: "≥ 2 days" }]}
                optionKey={"value"}
                valueKey={"value"}
                handleChange={handleUpdateBox("alcoholic_drink_pw")}
              />
            </div>
          </div>
        </Panel>
        <Panel header="Allergies"
          className={((resource?.allergies && resource?.allergies.length > 0) || resource?.no_drug_allergies == true)  ? '' : 'invalid'}
          key="allergies">
          <ICDAutoCompleteComponent
            title="allergies"
            rows={row["allergies"]}
            keyLabel={"name"}
            removeItem={removeRow}
            addItem={searchResult}
            exception={true}
            loading={loadingAllergyLocal || loadingAllergy}
            options={
              loadingAllergyLocal || loadingAllergy ? [] : allergiesRedux
            }
            isResult={isResult}
            selectItem={(value) => {
              selectItem(value, "allergies");
            }}
            handleSearch={searchingAllergy}
          />
          <div
            style={{ marginTop: 10 }}
            className="resourceInfo between half-pannel"
          >
            <Checkbox
              checked={row["no_drug_allergies"]}
              onChange={handleUpdateOption("no_drug_allergies")}
              style={{ width: "100%" }}
              disabled={row["allergies"] && row["allergies"].length > 0}
            >
              No Known Drug Allergies
            </Checkbox>
          </div>
        </Panel>
        <Panel header="Medications"
          className={((resource?.medications && resource?.medications.length > 0) || resource?.no_medication == true)  ? '' : 'invalid'}
          key="medications">
          <MedicationAutoComplete
            title="medications"
            rows={row[type] || []}
            keyLabel={"icd_code_name"}
            removeItem={removeRow}
            addItem={(val) => console.log("searchResult")}
            loading={searching}
            options={[]}
            isResult={isResult}
            query={searchInput}
            selectItem={(value) => {
              selectItem(value, "medications");
            }}
            handleSearch={(val) => console.log(val, "handleSearch")}
          />
          {row[type] ? (
            <div
              style={{ marginTop: 10 }}
              className="resourceInfo between half-pannel"
            >
              <Checkbox
                checked={row["no_medication"]}
                onChange={handleUpdateOption("no_medication")}
                style={{ width: "100%" }}
                disabled={row[type].length > 0}
              >
                No Known Medications
              </Checkbox>
            </div>
          ) : null}
        </Panel>
      </Collapse>
    </div>
  );
};

export default MedicalHistoryComponent;
