import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _, { set } from "lodash";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import AutoCompleteComponentPlanOder from "../AutoCompletePlanOder";
import ScrollWrapper from "../ScrollBar";
import { LeftCharvenSVG } from "../icons/SVGIcon";
import ToastMessage from "../Toast";
import Loading from "../Loading";
import DiseaseBody from "../DiseasBody";

import { getProviderSchedule } from "../../../services/api.services";
import {
  search_plan_suggestion,
  get_plan_patient,
  save_plan_patient,
  setStepData,
  getAppointments,
  filterAppointments,
  filterLocations,
  filterProviders,
  delete_plan,
  setGlobalLoading,
  rescheduleProcedure,
  setSidebarState,
} from "../../../store/actions";
import { validateSteps, validateField } from "../../../services/validations";
import Sidebar from "./Sidebar";
import SidebarExtension from "../SidebarExtension";
import EmptySidebar from "./EmptySidebar";
import { Icon } from "antd";

const cookies = new Cookies();
const initialCondition = [
  { step: "demographics", status: true },
  { step: "insurance", status: true },
  { step: "medical", status: true },
  { step: "questions", status: true },
  { step: "schedule", status: true },
  { step: "communication", status: true },
  { step: "attempt", status: true },
];

let timer = null;
const PlanOrders = ({ sidebarIcon, navigateToReview }) => {
  const history = useHistory();
  const [isSidebar, setIsSlidebar] = useState(true);
  const [loading, setLoading] = useState(false);
  const [planNames, setPlanNames] = useState({});
  const [planNamesNoSide, setPlanNamesNoSide] = useState({});
  const [plans, setPlans] = useState([]);
  const [plansType, setPlansType] = useState([]);
  const scheduleLoading = useSelector(
    (state) => state.newPatient.scheduleLoading
  );
  const deleteLoading = useSelector(
    (state) => state.procedure.loadingDeletePlan
  );
  const loadingSchedule = useSelector(
    (state) => state.newPatient.initialLoading
  );
  const loadingFirst = useSelector((state) => state.procedure.loadingTimeSlots);
  const loadingSecond = useSelector((state) => state.procedure.loadingFollowUp);
  const [enableError, setEnableError] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [firstCheck, setFirstCheck] = useState(false);
  const referredProviders = useSelector(
    (state) => state.common.referredProviders
  );
  const referralConditions = useSelector(
    (state) => state.common.referralConditions
  );
  const filteredLocations = useSelector(
    (state) => state.common.filteredLocations
  );
  const scheduleOptions = useSelector(
    (state) => state.newPatient.scheduleOptions
  );
  const filteredProviders = useSelector(
    (state) => state.common.filteredProviders
  );
  const [conditions, setConditions] = useState(initialCondition);
  const [idPlan, setIDPlan] = useState([]);
  const [activeRow, setActiveRow] = useState({});
  const [keyCheck, setKeyCheck] = useState(null);
  const [procedure_id_row, setProcedure_id_row] = useState(0);
  const [currentPlanId, setCurrentPlanId] = useState(0);
  const [typePlanId, setTypePlanId] = useState(null);
  const [typeAllPlanId, setTypeAllPlanId] = useState([]);
  const [selectedCheck, setSelectedCheck] = useState({});
  const [processHandle, setProcessHandle] = useState({});
  const [patientPlan, setPatientPlan] = useState([]);
  const [sideHandle, setSideHandle] = useState({});
  const [hasSideHandle, setHasSideHandle] = useState({});
  const [clickedCondition, setClickedCondition] = useState("");
  const current_patient_id = cookies.get("current_patient_id");
  const current_appointment_id = cookies.get("current_appointment_id");
  const [isToastr, setIsToastr] = useState(false);
  const [activeSide, setActiveSide] = useState(false);
  const [offSide, setOffSide] = useState(true);
  const [onButton, setOnButton] = useState(false);
  const [isDeleteToastr, setIsDeleteToastr] = useState(false);
  const all_plans = useSelector((state) => state.procedure.search_plan_all);
  const patient_plan = useSelector((state) => state.procedure.get_plan_patient);
  const is_locked = useSelector(
    (state) => state.clinicProviderReview.is_locked
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentConditionIndex, setCurrentConditionIndex] = useState(0);
  const [continueDisabled, setContinueDisabled] = useState(false);
  useEffect(() => {
    setPatientPlan(patient_plan);
  }, [patient_plan]);

  const loadingPatientStart = useSelector(
    (state) => state.procedure.loadingPatientStart
  );
  const dropdownOption = useSelector(
    (state) => state.newPatient.dropdownOption
  );
  const [planMap, setPlanMap] = useState([]);

  const data = useSelector((state) => state.patientSchedule);

  const pcpList = useSelector((state) => state.common.pcpList);
  const dispatch = useDispatch();

  const loaderTabel = async () => {
    await dispatch(setGlobalLoading(true));
    await dispatch(
      get_plan_patient(current_patient_id, current_appointment_id)
    );
    await dispatch(search_plan_suggestion());
    await dispatch(filterLocations("", "all"));
    await dispatch(filterProviders(""));
    await dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    loaderTabel();
    if (window.innerWidth <= 768) {
      setIsSlidebar(false);
    }
  }, []);

  const changeSetProcessHandle = (index, value, date, typeCheck) => {
    if (typeCheck == "ClinicDisease") {
      const indexCheck = patientPlan.findIndex((r) => r.condition_id == value);
      if (indexCheck != -1) {
        //
        let listItems = [...processHandle[value]];
        listItems[index] = true;
        setProcessHandle({ ...processHandle, [value]: listItems });
        //
        let checkItem = [...patientPlan];
        checkItem[indexCheck]["plan"][index]["processed"] = 1;
        checkItem[indexCheck]["plan"][index]["followup_appointment"] = {
          ...checkItem[indexCheck]["plan"][index]["followup_appointment"],
          appointment_date: date?.followup_date,
        };
        checkItem[indexCheck]["plan"][index]["procedure"] = {
          ...checkItem[indexCheck]["plan"][index]["procedure"],
          appointment_date: date?.procedure_date,
        };
        setPatientPlan([...checkItem]);
      }
    }
  };

  useEffect(() => {
    if (patientPlan && all_plans) {
      let idArray = {};
      let nameArray = {};
      let urgencyArray = {};
      let typeArray = {};
      let mapIDPlan = {};
      let processCheck = {};
      let sideCheck = {};
      let hasSideCheck = {};
      let nameNoSideArray = {};

      const condition_id = (patientPlan || []).map((r, index) => {
        return r?.condition_id;
      });
      (patientPlan || []).map((patient, indexPatient) => {
        const checkIdArray = (patient.plan || []).map((r) => {
          if (r.bundle === "ultrasound_test") {
            return (
              r.ultrasound && r.ultrasound.us_test && r.ultrasound.us_test.id
            );
          } else if (r.bundle === "procedure") {
            return (
              (all_plans || []).find(
                (item) =>
                  r.procedure &&
                  r.procedure.procedure_type &&
                  item.name === r.procedure.procedure_type.value
              ) || {}
            ).id;
          } else if (r.bundle === "clinic" || r.bundle === "") {
            return (
              (all_plans || []).find(
                (item) =>
                  r.procedure &&
                  r.procedure.procedure_type &&
                  item.name === r.procedure.procedure_type.value
              ) || {}
            ).id;
          } else if (r.bundle === "general") {
            return (
              (all_plans || []).find(
                (item) =>
                  r.procedure_extra &&
                  r.procedure_extra.procedure_type &&
                  item.name === r.procedure_extra.procedure_type.value
              ) || {}
            ).id;
          } else if (r.bundle === "angio") {
            return (
              (all_plans || []).find(
                (item) =>
                  r.angio &&
                  r.angio.angio_type &&
                  item.name === r.angio.angio_type.name
              ) || {}
            ).id;
          }
        });
        const checkNameArray = (patient.plan || []).map((r) => {
          if (r.bundle === "ultrasound_test") {
            if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
              return (
                r.ultrasound &&
                r.ultrasound.ultrasound_type &&
                r.side + " " + r.ultrasound.ultrasound_type.name
              );
            } else {
              return (
                r.ultrasound &&
                r.ultrasound.ultrasound_type &&
                r.ultrasound.ultrasound_type.name
              );
            }
          } else if (r.bundle === "procedure") {
            if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
              return (
                r.procedure &&
                r.procedure.procedure_type &&
                r.side + " " + r.procedure.procedure_type.value
              );
            } else {
              return (
                r.procedure &&
                r.procedure.procedure_type &&
                r.procedure.procedure_type.value
              );
            }
          } else if (r.bundle === "clinic" || r.bundle === "") {
            return (
              r.procedure &&
              r.procedure.procedure_type &&
              r.procedure.procedure_type.value
            );
          } else if (r.bundle === "general") {
            return (
              r.procedure_extra &&
              r.procedure_extra.procedure_type &&
              r.procedure_extra.procedure_type.value
            );
          } else if (r.bundle === "angio") {
            return r.angio && r.angio.angio_type && r.angio.angio_type.name;
          }
        });
        const checkNameNoSideArray = (patient.plan || []).map((r) => {
          if (r.bundle === "ultrasound_test") {
            return (
              r.ultrasound &&
              r.ultrasound.ultrasound_type &&
              r.ultrasound.ultrasound_type.name
            );
          } else if (r.bundle === "procedure") {
            return (
              r.procedure &&
              r.procedure.procedure_type &&
              r.procedure.procedure_type.value
            );
          } else if (r.bundle === "clinic" || r.bundle === "") {
            return (
              r.procedure &&
              r.procedure.procedure_type &&
              r.procedure.procedure_type.value
            );
          } else if (r.bundle === "general") {
            return (
              r.procedure_extra &&
              r.procedure_extra.procedure_type &&
              r.procedure_extra.procedure_type.value
            );
          } else if (r.bundle === "angio") {
            return r.angio && r.angio.angio_type && r.angio.angio_type.name;
          }
        });
        const checkUrgencyArray = (patient.plan || []).map((r, index) => {
          return r.urgency ? r.urgency : undefined;
        });
        const checkTypeArray = (patient.plan || []).map((r) => r.bundle);
        const checkMapIDPlan = (patient.plan || []).map((r) => r.id);
        const checkProcessCheck = (patient.plan || []).map((r, index) => {
          return !!r.processed;
        });

        const checksideCheck = (patient.plan || []).map((r, index) => {
          return r.side;
        });
        const checkHasSideCheck = (patient.plan || []).map((r, index) => {
          return r.has_side;
        });
        processCheck = {
          ...processCheck,
          [condition_id[indexPatient]]: checkProcessCheck,
        };
        sideCheck = {
          ...sideCheck,
          [condition_id[indexPatient]]: checksideCheck,
        };
        hasSideCheck = {
          ...hasSideCheck,
          [condition_id[indexPatient]]: checkHasSideCheck,
        };
        mapIDPlan = {
          ...mapIDPlan,
          [condition_id[indexPatient]]: checkMapIDPlan,
        };
        typeArray = {
          ...typeArray,
          [condition_id[indexPatient]]: checkTypeArray,
        };
        urgencyArray = {
          ...urgencyArray,
          [condition_id[indexPatient]]: checkUrgencyArray,
        };
        nameArray = {
          ...nameArray,
          [condition_id[indexPatient]]: checkNameArray,
        };
        nameNoSideArray = {
          ...nameNoSideArray,
          [condition_id[indexPatient]]: checkNameNoSideArray,
        };
        idArray = { ...idArray, [condition_id[indexPatient]]: checkIdArray };
      });
      setSelectedCheck(urgencyArray);
      setIDPlan(mapIDPlan);
      setPlansType(typeArray);
      setPlans(idArray);
      setPlanNames(nameArray);
      setPlanNamesNoSide(nameNoSideArray);
      setProcessHandle(processCheck);
      setSideHandle(sideCheck);
      setHasSideHandle(hasSideCheck);
      setTypeAllPlanId(condition_id);
      if (activeRow && Object.keys(activeRow).length <= 0) {
        let check = false;
        (patientPlan || []).map((patient, index) => {
          (patient.plan || []).map((item, key) => {
            /*
            We build a map of plan that will be used for next button
             */
            if (item.processed === 0) {
              let temp_map = [...planMap];
              temp_map.push({ [patient?.condition_id]: key });
              setPlanMap(temp_map);
            }
            if (item.processed === 0 && !check) {
              setActiveRow({ ...activeRow, [patient?.condition_id]: key });
              setProcedure_id_row(key);
              setTypePlanId(patient?.condition_id);
              check = true;
              setCurrentPlanId(item.id);
            }
          });
        });
      }
      if (patientPlan) {
        let block = false;
        patientPlan.map((r, index) => {
          (r.plan || []).map((item, itemIndex) => {
            if (!block && r.plan.some((check) => check.processed == 0)) {
              setOnButton(false);
              block = true;
            } else if (!block && r.plan.some((check) => check.processed == 1)) {
              setOnButton(true);
              block = true;
            }
          });
        });
      }
    }
  }, [patientPlan]);

  useEffect(() => {
    if (patientPlan) {
      const check = (patientPlan || []).filter(
        (r) => r?.condition_id === typePlanId
      );
      if (check && check[0]) {
        setFirstCheck(true);
        const arrayPlan = check[0].plan && check[0].plan[activeRow[typePlanId]];
        if (arrayPlan) {
          setProcedure_id_row(arrayPlan.entity_id);
          if (firstCheck) {
            setIsSlidebar(true);
          }
        } else if (
          !arrayPlan &&
          activeRow &&
          Object.keys(activeRow).length > 0
        ) {
          setProcedure_id_row(0);
          setActiveRow({});
          setIsSlidebar(false);
        }
      }
    }
  }, [activeRow]);

  // useEffect(() => {
  //   if (
  //     loading ||
  //     loadingSchedule ||
  //     scheduleLoading[0] ||
  //     loadingFirst ||
  //     loadingSecond ||
  //     loadingPatientStart ||
  //     deleteLoading
  //   ) {
  //     dispatch(setGlobalLoading(true));
  //   } else {
  //     dispatch(setGlobalLoading(false));
  //   }
  //   return () => {
  //     dispatch(setGlobalLoading(false));
  //   };
  // }, [
  //   loading,
  //   loadingSchedule,
  //   scheduleLoading[0],
  //   loadingFirst,
  //   loadingSecond,
  //   loadingPatientStart,
  //   deleteLoading,
  // ]);

  const handleCloseSidebar = () => {
    setIsSlidebar(false);
  };

  const toggleSidebar = () => {
    setIsSlidebar(!isSidebar);
    setOffSide(!offSide);
  };

  const onChangeSelect = (value, index, type) => {
    const arrayMap = (selectedCheck[type] || []).map((r, i) => {
      if (index === i) {
        return value;
      }
      return r;
    });
    setSelectedCheck({ ...selectedCheck, [type]: arrayMap });
    savePlanCheckStorage({ ...selectedCheck, [type]: arrayMap }, null);
  };

  const savePlanCheckStorage = async (select, selectSide) => {
    let planAllArrayCheck = [];
    const condition_id = (patientPlan || []).map((r, index) => {
      return r?.condition_id;
    });
    (condition_id || []).map((r) => {
      (plans[r] || []).map((item, index) => {
        planAllArrayCheck.push({
          id: idPlan[r][index],
          entity_id: item,
          type: plansType[r][index],
          side: selectSide
            ? selectSide && selectSide[r] && selectSide[r][index]
            : sideHandle && sideHandle[r] && sideHandle[r][index],
          hpi_condition_id: r,
          processed: processHandle[r][index] ? processHandle[r][index] : 0,
          urgency: select
            ? select && select[r] && select[r][index]
            : selectedCheck && selectedCheck[r] && selectedCheck[r][index],
        });
      });
    });
    const param = {
      patient_id: current_patient_id,
      procedure_id: current_appointment_id,
      plans: planAllArrayCheck,
    };
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(save_plan_patient(param));
    }, 500);
  };

  const onChangeSelectSide = (value, index, type) => {
    const arrayMap = (sideHandle[type] || []).map((r, i) => {
      if (index === i) {
        return value;
      }
      return r;
    });
    setSideHandle({ ...sideHandle, [type]: arrayMap });
    savePlanCheckStorage(null, { ...sideHandle, [type]: arrayMap });
  };

  const handleSearchPlan = (search) => {
    if (search) {
      setLoading(true);
      const filteredPlans_ =
        all_plans &&
        all_plans.filter(
          (plan) =>
            plan.name.toLowerCase().includes(search.toLowerCase()) &&
            search.length > 0
        );
      setFilteredPlans(filteredPlans_);
      setLoading(false);
    }
  };

  const selectItemPlan = async (planName, planId, planType, type, hasSide) => {
    if (planName === "") return;
    for (let planId_ of plans[type]) {
      if (planId_ === planId) return;
    }
    const lengthArray = plansType[type].length + 1;
    let listItems = [...selectedCheck[type]];
    listItems.splice(lengthArray, 0, undefined);
    let selectedCheckSave = { ...selectedCheck, [type]: listItems };
    await setSelectedCheck({ ...selectedCheck, [type]: listItems });
    listItems = [...processHandle[type]];
    listItems.splice(lengthArray, 0, undefined);
    let processHandleCheck = { ...processHandle, [type]: listItems };
    await setProcessHandle({ ...processHandle, [type]: listItems });
    listItems = [...sideHandle[type]];
    listItems.splice(lengthArray, 0, undefined);
    let sideHandleCheck = { ...sideHandle, [type]: listItems };
    await setSideHandle({ ...sideHandle, [type]: listItems });
    listItems = [...hasSideHandle[type]];
    listItems.splice(lengthArray, 0, hasSide);
    await setHasSideHandle({ ...hasSideHandle, [type]: listItems });
    let typeItems = [...plansType[type]];
    typeItems.splice(lengthArray, 1, planType);
    let planTypeCheck = { ...plansType, [type]: typeItems } || {};
    await setPlansType({ ...plansType, [type]: typeItems });
    listItems = [...plans[type]];
    listItems.splice(lengthArray, 0, planId);
    let planCheck = { ...plans, [type]: listItems } || {};
    await setPlans({ ...plans, [type]: listItems });
    listItems = [...planNames[type]];
    listItems.splice(lengthArray, 0, planName);
    await setPlanNames({ ...planNames, [type]: listItems });
    listItems = [...planNamesNoSide[type]];
    listItems.splice(lengthArray, 0, planName);
    await setPlanNamesNoSide({ ...planNamesNoSide, [type]: listItems });

    //
    let planAllArrayCheck = [];
    const condition_id = (patientPlan || []).map((r, index) => {
      return r?.condition_id;
    });
    (condition_id || []).map((r) => {
      (planCheck[r] || []).map((item, index) => {
        planAllArrayCheck.push({
          id: idPlan[r][index],
          entity_id: item,
          type: planTypeCheck[r][index],
          side:
            sideHandleCheck && sideHandleCheck[r] && sideHandleCheck[r][index],
          hpi_condition_id: r,
          processed: processHandleCheck[r][index]
            ? processHandleCheck[r][index]
            : 0,
          urgency:
            selectedCheckSave &&
            selectedCheckSave[r] &&
            selectedCheckSave[r][index],
        });
      });
    });
    const param = {
      patient_id: current_patient_id,
      procedure_id: current_appointment_id,
      plans: planAllArrayCheck,
    };
    await dispatch(save_plan_patient(param));
    //
  };

  const removeRowPlan = async (plan, index, type) => {
    setLoading(true);
    if (selectedCheck && Object.keys(selectedCheck[type]).length > 0) {
      const checkFilterCheck = selectedCheck[type].splice(index, 1);
    }
    if (Object.keys(processHandle[type]).length > 0) {
      const checkProcessCheck = processHandle[type].splice(index, 1);
    }
    let listItems = [...idPlan[type]];
    let plan_id = listItems[index];
    listItems.splice(index, 1);
    setIDPlan({ ...idPlan, [type]: listItems });
    listItems = [...plans[type]];
    listItems.splice(index, 1);
    setPlans({ ...plans, [type]: listItems });
    listItems = [...planNames[type]];
    listItems.splice(index, 1);
    setPlanNames({ ...planNames, [type]: listItems });
    listItems = [...planNamesNoSide[type]];
    listItems.splice(index, 1);
    setPlanNamesNoSide({ ...planNamesNoSide, [type]: listItems });
    listItems = [...plansType[type]];
    listItems.splice(index, 1);
    setPlansType({ ...plansType, [type]: listItems });
    if (plan_id) {
      const deleteSuccess = await dispatch(delete_plan(plan_id));
      /*const loadSuccess = await dispatch(
        get_plan_patient(current_patient_id, current_appointment_id)
      );*/
      if (deleteSuccess) {
        setIsDeleteToastr(true);
        setTimeout(() => {
          setIsDeleteToastr(false);
        }, 2000);
      }
    }
    setLoading(false);
  };

  const savePlan = async () => {
    setLoading(true);
    let planAllArrayCheck = [];
    typeAllPlanId.map((r) => {
      (plans[r] || []).map((item, index) => {
        planAllArrayCheck.push({
          id: idPlan[r][index],
          entity_id: item,
          type: plansType[r][index],
          side: sideHandle && sideHandle[r] && sideHandle[r][index],
          hpi_condition_id: r,
          processed: processHandle[r][index] ? processHandle[r][index] : 0,
          urgency: selectedCheck && selectedCheck[r] && selectedCheck[r][index],
          showSide:
            hasSideHandle && hasSideHandle[r] ? hasSideHandle[r][index] : null,
        });
      });
    });
    console.log(typeAllPlanId, "typeAllPlanId");
    console.log(planAllArrayCheck, "planAllArrayCheck");
    const checkPlanTrue = [
      { value: "side", type: "default" },
      { value: "urgency", type: "default" },
    ];

    const checkPlanFalse = [{ value: "urgency", type: "default" }];

    if (planAllArrayCheck && planAllArrayCheck.length > 0) {
      // fix check process
      const check = planAllArrayCheck.map((item, index) => {
        if (
          item.showSide === false &&
          item.type !== "general" &&
          item.type !== "other"
        ) {
          if (
            !validateSteps(
              {
                urgency: item.urgency,
              },
              checkPlanFalse
            )
          ) {
            setEnableError(true);
            return false;
          } else {
            return true;
          }
        } else if (item.type !== "general" && item.type !== "other") {
          if (
            !validateSteps(
              {
                urgency: item.urgency,
                side: item.side,
              },
              checkPlanTrue
            )
          ) {
            setEnableError(true);
            return false;
          } else {
            return true;
          }
        } else {
          console.log("object");
          return true;
        }
      });
      if (check.some((r) => r == true)) {
        const param = {
          patient_id: current_patient_id,
          procedure_id: current_appointment_id,
          plans: planAllArrayCheck,
        };
        const saveSuccess = await dispatch(save_plan_patient(param));
        const getList = await dispatch(
          get_plan_patient(current_patient_id, current_appointment_id)
        );
        let block = false;
        (getList || []).map((r, index) => {
          (r.plan || []).map((item, itemIndex) => {
            if (item.processed == 0 && !block) {
              setActiveRow({ ...activeRow, [r.condition_id]: itemIndex });
              setKeyCheck(r?.condition_id);
              setTypePlanId(r?.condition_id);
              setCurrentPlanId(item.id);
              setProcedure_id_row(item.entity_id);
              block = true;
            }
          });
        });
        if (saveSuccess && getList) {
          setActiveSide(true);
          setLoading(false);
          // await dispatch(rescheduleProcedure(current_patient_id, procedure_id_row));
          setIsToastr(true);
          setIsSlidebar(true);
          setTimeout(() => {
            setIsToastr(false);
          }, 2000);
        }
      } else {
        // console.log(check);
      }
    }
  };

  const onclickRow = (row, index, type, rowAll) => {
    if (rowAll.processCheck || rowAll.processCheck == 1) {
      setActiveSide(true);
    } else {
      setActiveSide(false);
    }
    setKeyCheck(type);
    setIsSlidebar(true);
    setActiveRow({ ...activeRow, [type]: index });
    setTypePlanId(type);
  };

  const nextSave = () => {
    let block = false;
    patientPlan.map((r, index) => {
      (r.plan || []).map((item, itemIndex) => {
        let temp_index = currentIndex;
        if (
          itemIndex === temp_index + 1 &&
          itemIndex < r.plan.length &&
          item.processed === 0 &&
          index === currentConditionIndex
        ) {
          setActiveRow({ ...activeRow, [r.condition_id]: itemIndex });
          setKeyCheck(r.condition_id);
          setTypePlanId(r?.condition_id);
          setCurrentPlanId(item.id);
          block = true;
          setCurrentIndex(itemIndex);
          if (
            currentConditionIndex >= patientPlan.length &&
            itemIndex === r.plan.length - 1
          ) {
            console.log("should be disabled");
            setContinueDisabled(true);
          }
        }
      });
    });
    /*
    If block is still false that means the user has skipped all of the current plan in current condition
    then we increase the condition index and reset the current index
     */

    if (block === false) {
      let temp = currentConditionIndex;
      setCurrentConditionIndex(temp + 1);
      patientPlan.map((r, index) => {
        (r.plan || []).map((item, itemIndex) => {
          if (item.processed === 0 && !block && temp + 1 === index) {
            setActiveRow({ ...activeRow, [r.condition_id]: itemIndex });
            setKeyCheck(r.condition_id);
            setTypePlanId(r?.condition_id);
            setCurrentPlanId(item.id);
            block = true;
            setCurrentIndex(itemIndex);
          }
        });
      });
    }

    if (block === false) {
      if (navigateToReview) {
        navigateToReview();
      }
    }
  };

  // utral
  const handleSubmit = () => {};
  const initialSchedule = () => {
    dispatch(setStepData("initialLoading", true));
    dispatch(getAppointments(current_patient_id));
  };

  const handleSchedule = (
    location_id,
    provider_id,
    period,
    prev,
    idxOfSchedule,
    action = "",
    custom,
    load_next
  ) => {
    const loadingOfSchedules = [...scheduleLoading];
    if (action === "row" && idxOfSchedule >= loadingOfSchedules.length - 1) {
      return;
    }
    if (action === "row") {
      loadingOfSchedules[idxOfSchedule + 1] = true;
    } else {
      loadingOfSchedules[idxOfSchedule] = true;
    }
    dispatch(setStepData("scheduleLoading", loadingOfSchedules));
    dispatch(
      filterAppointments(
        current_patient_id,
        location_id,
        provider_id,
        period,
        prev,
        idxOfSchedule,
        action,
        custom,
        load_next
      )
    );
  };

  const handleScheduleOption = (idxOfSchedule, newOption) => {
    const newScheduleOption = [...scheduleOptions];
    newScheduleOption[idxOfSchedule] = newOption;
    dispatch(setStepData("scheduleOptions", newScheduleOption));
  };

  const handleProviderSchedule = async (
    location_id,
    provider_id,
    idxOfSchedule
  ) => {
    if (location_id && provider_id) {
      return;
    }
    const newOption = [...dropdownOption];
    if (!location_id && !provider_id) {
      newOption[idxOfSchedule] = { type: "", list: [] };
      dispatch(setStepData("dropdownOption", newOption));
      return;
    }
    try {
      const result = await getProviderSchedule(
        current_patient_id,
        location_id,
        provider_id
      );
      let type = "";
      if (!location_id) {
        type = "location";
      } else if (!provider_id) {
        type = "provider";
      }
      newOption[idxOfSchedule] = { type, list: result.data };
      dispatch(setStepData("dropdownOption", newOption));
    } catch (error) {
      dispatch(setStepData("dropdownOption", dropdownOption));
    }
  };

  const selectedPlan = (patientPlan || []).find(
    (item) => item?.condition_id === typePlanId
  );

  const titleProps =
    selectedPlan && selectedPlan?.condition_name
      ? selectedPlan?.condition_name
      : "";

  let conditionClassName = "";
  for (let condition_ of patientPlan) {
    conditionClassName += _.snakeCase(condition_["condition_name"]) + " ";
  }
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      {isToastr && (
        <ToastMessage
          type="Plan/Orders"
          status="success"
          msg="Process Orders Successfully!"
        />
      )}
      {isDeleteToastr && (
        <ToastMessage
          type="Plan/Orders"
          status="success"
          msg="Plan is deleted successfully!"
        />
      )}

      <div className="eventsContainer has-sub-header">
        <div className="patientContainer">
          <div
            className={`imageSection disease-icon ${_.snakeCase(
              clickedCondition
            )}-clicked ${conditionClassName}`}
          >
            <DiseaseBody />
          </div>
          <div className="detailSection">
            <ScrollWrapper css="no-padding">
              {(patientPlan || []).map((item, index) => {
                return (
                  <React.Fragment key={`${item.name}-${index}`}>
                    <div
                      className="detailTitle"
                      style={{ marginTop: index > 0 ? 30 : 0 }}
                    >
                      {`${item?.condition_name}`}
                    </div>
                    <AutoCompleteComponentPlanOder
                      onChangeSelect={(value, index, type) => {
                        onChangeSelect(value, index, type);
                      }}
                      isGeneral={
                        item?.condition_name == "General" ? true : false
                      }
                      onChangeSelectSide={(value, index, type) => {
                        onChangeSelectSide(value, index, type);
                      }}
                      enableError={enableError}
                      processArray={processHandle}
                      valueSelected={selectedCheck}
                      handleValueSelect={plansType}
                      sideHandle={sideHandle}
                      isSelectRow={true}
                      title="condition-plans"
                      placeholder="Search Plans"
                      keyLabel={"name"}
                      keyValue={"id"}
                      onclickRow={(row, index, type, rowAll) => {
                        onclickRow(row, index, type, rowAll);
                      }}
                      keyCheck={keyCheck}
                      keyType={"type"}
                      keyIdPlan={item?.condition_id}
                      activeIndex={activeRow[`${item?.condition_id}`]}
                      isResult={false}
                      loading={loading}
                      disableSearchIcon={false}
                      handleSearch={handleSearchPlan}
                      selectItem={selectItemPlan}
                      removeItem={removeRowPlan}
                      rows={planNamesNoSide}
                      options={filteredPlans}
                      isWhite={false}
                      hasSideHandle={hasSideHandle}
                      isLocked={is_locked}
                    />
                  </React.Fragment>
                );
              })}
            </ScrollWrapper>
          </div>
        </div>
      </div>
      {offSide && (
        <React.Fragment>
          {plansType &&
          plansType[typePlanId] &&
          plansType[typePlanId].length > 0 &&
          plansType[typePlanId][activeRow[typePlanId]] &&
          activeSide ? (
            <Sidebar
              ref={sidebarRef}
              planTypes={plansType}
              plans={patientPlan}
              planNames={planNames}
              sidebarIcon={sidebarIcon}
              typePlanId={typePlanId}
              activeRow={(activeRow[typePlanId] && activeRow[typePlanId]) || 0}
              titlePlan={
                planNames &&
                planNames[typePlanId] &&
                planNames[typePlanId][activeRow[typePlanId]]
              }
              changeSetProcessHandle={changeSetProcessHandle}
              onButton={onButton}
              handleLoading={setLoading}
              handleCloseSidebar={handleCloseSidebar}
              isShow={isSidebar}
              nextSave={nextSave}
              procedure_id_row={procedure_id_row}
              handleContinue={handleSubmit}
              filteredLocations={filteredLocations}
              filteredProviders={filteredProviders}
              conditions={conditions}
              referredProviders={referredProviders}
              referralConditions={referralConditions}
              pcpList={(pcpList && pcpList["active_pcp"]) || []}
              handleAppointmentSchedule={handleSchedule}
              initialAppointmentSchedule={initialSchedule}
              handleProviderSchedule={handleProviderSchedule}
              handleScheduleOption={handleScheduleOption}
              currentPlanId={currentPlanId}
            />
          ) : (
            <EmptySidebar
              processOrders={savePlan}
              ref={sidebarRef}
              isShow={isSidebar}
              handleCloseSidebar={handleCloseSidebar}
            />
          )}
        </React.Fragment>
      )}

      <SidebarExtension
        startingWidth="calc(28.3% - 20px + 0.3rem)"
        sidebarToggle={toggleSidebar}
        sidebarReference={sidebarRef}
        sidebarState={offSide}
      />
    </div>
  );
};

export default PlanOrders;
