import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Collapse } from "antd";
import SnapList from "./SnapList";
import SnapChart from "./SnapChart";
import {
  clinicProviderPatientSnapshotLoadInit,
  patient_insurances_id_insurances,
  setPatientSnapshotData
} from "../../../store/actions";

const PatientOverviewList = ({ onClickConditionPanel }) => {
  const list = useSelector((state) => state.clinicProviderPatientSnapshot.list);
  const calList = useSelector((state) => state.clinicProviderPatientSnapshot.calList);
  const descList = useSelector((state) => state.clinicProviderPatientSnapshot.descList);

  const [selectedDate, setSelectedDate] = useState("");
  const onSelect = (selectedDate_) => {
    setSelectedDate(selectedDate_);
  };

  return (
    <Collapse accordion onChange={(key) => onClickConditionPanel(key)}>
      {list.map((data, index) => (
        <Collapse.Panel
          header={data.condition}
          key={`collapse-${data["condition"]}`}
        >
          {
            calList && descList &&
            <div className="content">
              <SnapChart
                items={calList}
                selectedDate={selectedDate}
                onSelect={onSelect}
              />
              <div className="snapDetails">
                <div className="snapDetails__title">Clinical Summary</div>
                <SnapList
                  items={descList}
                  selectedDate={selectedDate}
                  onChangeSelectedDate={onSelect}
                />
              </div>
            </div>
          }
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default PatientOverviewList;
