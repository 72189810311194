import { createReducer } from "redux-create-reducer";
import moment from "moment";
import AT from "../actionTypes";

const initialState = {
  hpi_conditions: [],
  ROSs: [],
  physical_examinations: [],
  vitals: [],
  vessel_states: [],
  icd_codes: [],
  medical_history: [],
  previous_surgical_histories: [],
  allergies: [],
  family_history: [],
  social_history: [],
  descList: [],
  chiefComplaint: "",
  is_locked: null,
  medical_decision_making: "",
  meaningful_uses: [],
  loading_meaningful_uses: false,
  result_meaningful_uses: false,
  tabActive: "",
  accordion: "",
  step: "",
  loadingClinicReview: false,
  reading_physician_id: 0,
};

const SAVE_TEMP_SUCCEEDED = `${AT.SAVE_TEMP}_SUCCEEDED`;
const GET_CPT_CODE_REVIEW_SUCCESS = `${AT.GET_CPT_CODE_REVIEW}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.ADD_PLAN_ME}_STARTED`](state, action) {
    return {
      ...state,
      loading_meaningful_uses: true,
    };
  },
  [`${AT.ADD_PLAN_ME}_SUCCEEDED`](state, action) {
    const result_meaningful_uses = action.payload;
    return {
      ...state,
      loading_meaningful_uses: false,
      result_meaningful_uses,
    };
  },
  [`${AT.CHANGE_TAB}_SUCCEEDED`](state, action) {
    const { tabActive, accordion, step } = action.payload;
    return {
      ...state,
      tabActive,
      accordion,
      step,
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      hpi_conditions: [],
      ROSs: [],
      physical_examinations: [],
      vitals: [],
      vessel_states: [],
      icd_codes: [],
      medical_history: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      family_history: [],
      social_history: [],
      descList: [],
      chiefComplaint: "",
      is_locked: null,
      medical_decision_making: "",
      meaningful_uses: [],
    };
  },
  [`${AT.CLINIC_REVIEW_LOAD_INIT}_STARTED`](state) {
    return {
      ...state,
      hpi_conditions: [],
      ROSs: [],
      physical_examinations: [],
      vitals: [],
      vessel_states: [],
      icd_codes: [],
      medical_history: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      family_history: [],
      social_history: [],
      descList: [],
      chiefComplaint: "",
      // is_locked: null,
      // medical_decision_making: "",
      meaningful_uses: [],
      loadingClinicReview: true,
      reading_physician_id: 0,
    };
  },
  [`${AT.CLINIC_REVIEW_LOAD_INIT}_SUCCEEDED`](state, action) {
    if (!action.payload) {
      return { ...state };
    }
    let {
      hpi_conditions,
      ROSs,
      physical_examinations,
      vitals,
      vessel_states,
      icd_codes,
      medical_history,
      previous_surgical_histories,
      allergies,
      medications,
      family_history,
      social_history,
      descList,
      chiefComplaint,
      is_locked,
      medical_decision_making,
      reading_physician_id,
      meaningful_uses,
    } = action.payload;
    return {
      ...state,
      hpi_conditions,
      ROSs,
      physical_examinations,
      vitals: (vitals || []).map((item) => {
        if (item.type === "Temperature") {
          return { type: item.type, value: `${item.value} ℉` };
        } else if (item.type === "BP (Right)" || item.type === "BP (Left)") {
          return { type: item.type, value: `${item.value} mnHg` };
        } else if (item.type === "HR") {
          return { type: item.type, value: `${item.value} BPM` };
        } else if (item.type === "Resp") {
          return { type: item.type, value: `${item.value} RR` };
        } else if (item.type === "SpO2") {
          return { type: item.type, value: `${item.value} %` };
        } else if (item.type === "Weight") {
          return { type: item.type, value: `${item.value} Kg` };
        } else if (item.type === "Height") {
          return { type: item.type, value: `${item.value} Inches` };
        }
        return item;
      }),
      vessel_states,
      icd_codes,
      medical_history,
      previous_surgical_histories,
      allergies,
      medications,
      family_history,
      social_history,
      descList,
      chiefComplaint,
      is_locked,
      medical_decision_making,
      reading_physician_id,
      meaningful_uses,
      loadingClinicReview: false,
    };
  },
  [`${AT.UNLOCK_CLINIC_PROCEDURE}_SUCCEEDED`](state) {
    return Object.assign({}, state, {
      is_locked: 0,
    });
  },
  [`${AT.LOCK_CLINIC_PROCEDURE}_SUCCEEDED`](state) {
    return Object.assign({}, state, {
      is_locked: 1,
    });
  },
  // check
  [GET_CPT_CODE_REVIEW_SUCCESS](state, action) {
    const CPT = action.payload;
    return Object.assign({}, state, {
      is_locked: CPT.is_locked,
    });
  },
  [SAVE_TEMP_SUCCEEDED](state, action) {
    const CPT = action.payload;
    return Object.assign({}, state, {
      is_locked: CPT.is_locked,
    });
  },
});
