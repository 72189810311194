import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import pdf2base64 from "pdf-to-base64";

import moment from "moment";
import { Checkbox, Collapse } from "antd";

import {
  CloseIcon,
  PalMessageSVG,
  ArrowDownSVG,
} from "../../components/icons/SVGIcon";
import { MaleSVG } from "../../components/icons/FaxSVG";
import ScrollWrapper from "../../components/ScrollBar";
import SidebarTitle from "../../components/SidebarTitle";
import ImageList from "./ImageList";
import EditableMaterialText from "../../components/EditableText/MaterialInput";
import EditableFormInput from "../../components/EditableText/EditableFormInput";
import DataTable from "../../components/DataTable/WhiteHeaderTable";
import SidebarExtension from "../../components/SidebarExtension";

import {
  getProviders,
  get_filter_patient_document,
  get_patient_document,
  sendFaxPatientDocument,
  emailPatientDocument,
  printPatientDocument,
  set_default_toast,
  deletePatientDocument,
  reset_fax_scan_document_log,
  emailPatientDocumentBulk,
  faxPatientDocumentBulk,
  patient_document_delete_multiple,
  setGlobalLoading,
  printPatientDocumentBulk,
  get_list_patients,
} from "../../../store/actions";
import ToastMessage from "../../../common/components/Toast";
import { validateField, validateSteps } from "../../../services/validations";

const { Panel } = Collapse;
const cookies = new Cookies();

const PatientChartSideBar = React.forwardRef(
  (
    { activeTab, resource, sidebarIcon, isShow, handleCloseSidebar, opener },
    ref
  ) => {
    const tableRef = useRef(null);
    const patientResource = useSelector(
      (state) =>
        state.clinicProvider.patientResource || state.patientRecords.resource
    );
    const documents = useSelector((state) => state.common.patientDocuments);
    const patients = useSelector((state) => state.common.list_patients);
    const pcpList = useSelector((state) => state.common.pcpList);
    const providers = useSelector((state) => state.common.referredProviders);
    const loadingGetPatientDocument = useSelector(
      (state) => state.common.loadingGetPatientDocument
    );
    const [dataDoc, setDataDoc] = useState([]);

    const selectedDocument = useSelector(
      (state) => state.clinicProvider.selectedDocument
    );
    const print_patient_document = useSelector(
      (state) => state.patientRecords.print_patient_document
    );
    const [isToastr, setIsToastr] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const loading_print = useSelector(
      (state) => state.patientRecords.loading_print_patient_document
    );
    const errorRest = useSelector((state) => state.common.globalErrorMessage);
    const isToast = useSelector((state) => state.patientRecords.isToast);
    const user_id = cookies.get("cvai-current-user");
    const id_patient = cookies.get("current_patient_id");

    const [reset, setReset] = useState(true);
    const dispatch = useDispatch();
    const [faxValue, setFaxValue] = useState(undefined);
    const [emailValue, setEmailValue] = useState(undefined);
    const [pcp, setPCP] = useState(undefined);
    const [referring, setReferring] = useState(undefined);
    const refs = useRef(null);
    const [printLink, setPrintLink] = useState("");
    const sidebarRef = useRef();
    const [documentFilters, setFilters] = useState({
      date: undefined,
      category: undefined,
      provider_id: undefined,
    });
    const [message, setMessage] = useState(null);
    const [status, setStatus] = useState(true);
    const patient_document_id = cookies.get("patient_document_id");
    const [enableError, setEnableError] = useState(false);
    const [enableErrorEmail, setEnableErrorEmail] = useState(false);
    const [checkedRows, setCheckedRows] = useState([]);
    const [checkedIDRows, setCheckedIDRows] = useState([]);
    const [sortOption, setSortOption] = useState({
      date: "",
      document_category: "",
    });
    const [patientId, setPatientId] = useState("");
    const [isOpenFax, setOpenFax] = useState(false);
    const [isOpenEmail, setOpenEmail] = useState(false);

    const toggleOpenFax = (e) => {
      e.stopPropagation();
      setOpenFax(!isOpenFax);
    };
    const toggleOpenEmail = (e) => {
      e.stopPropagation();
      setOpenEmail(!isOpenEmail);
    };

    useEffect(() => {
      if (!loadingGetPatientDocument) {
        setDataDoc(documents);
      }
    }, [documents]);

    useEffect(() => {
      dispatch(getProviders());
      dispatch(get_list_patients(1, 1, ""));
    }, []);

    useEffect(() => {
      if (isShow) {
        dispatch(setGlobalLoading(loading_print || loadingGetPatientDocument));
      }
    }, [loading_print, loadingGetPatientDocument, isShow]);

    useEffect(() => {
      if (selectedDocument) {
        setReset(true);
      }
    }, [selectedDocument]);

    useEffect(() => {
      if (reset) {
        setReset(false);
      }
    }, [reset]);

    useEffect(() => {
      if (isToast) {
        setIsToastr(true);
        setTimeout(() => {
          dispatch(set_default_toast());
          setIsToastr(false);
        }, 2000);
      }
    }, [isToast]);

    useEffect(() => {
      if (
        print_patient_document &&
        Object.keys(print_patient_document).length > 0
      ) {
        checkPrint();
      }
    }, [print_patient_document]);

    useEffect(() => {
      if (printLink) {
        printPdf(printLink);
        setTimeout(() => {
          setPrintLink("");
        }, 1000);
      }
    }, [printLink]);

    let printPdf = function (b64) {
      const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize),
            byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };

      const contentType = "application/pdf",
        blob = b64toBlob(b64, contentType),
        blobUrl = URL.createObjectURL(blob);

      console.dir("new print");
      var iframe = null;

      iframe = document.createElement("iframe");
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          // setTimeout(iframe.contentWindow.print, 500);
          iframe.contentWindow.print();
        }, 500);
      };

      iframe.src = blobUrl;
    };

    const selectedPatient = useMemo(() => {
      if (!patientId) {
        return "";
      }
      return (patients || []).find((p) => p.id === patientId);
    }, [patientId, patients]);

    const handleCheckRows = (idx, item) => {
      const checksID = [...checkedIDRows];
      const currentCheckID = checksID.findIndex((check) => check === item.id);

      const checks = [...checkedRows];
      const currentCheck = checks.findIndex((check) => check === idx);

      if (currentCheck === -1) {
        checks.push(idx);
        checksID.push(item.id);
      } else {
        checks.splice(currentCheck, 1);
        checksID.splice(currentCheckID, 1);
      }
      setCheckedRows(checks);
      setCheckedIDRows(checksID);
    };

    const handleSortOption = (value) => {
      let { category, provider_id, date } = documentFilters;
      if (!category) category = "";
      if (!provider_id) provider_id = "";
      if (!date) date = "";
      setSortOption({ ["date"]: value[0], ["document_category"]: value[1] });
      const params = {
        patient_id: id_patient,
        date,
        category,
        provider_id,
        sort_directionDate: value[0],
        sort_directionCate: value[1],
      };
      dispatch(get_filter_patient_document(params));
    };

    useEffect(() => {
      if (
        patient_document_id &&
        patient_document_id != null &&
        patient_document_id != "null"
      ) {
        onOpenDocument(null, Number(patient_document_id));
      }
    }, [patient_document_id]);

    const onOpenDocument = (document, idCookie) => {
      if (document && document?.id) {
        cookies.set("patient_document_id", null);
      }
      setActiveId((document && document?.id) || idCookie);
      if (
        (document && document?.id && document?.id !== activeId) ||
        (idCookie && idCookie !== activeId)
      ) {
        setEmailValue(undefined);
        setFaxValue(undefined);
        dispatch(get_patient_document(document?.id || idCookie));
      }
    };

    const checkPrint = async () => {
      const base64 = await pdf2base64(print_patient_document.url);
      setMessage("Print Successfully!");
      setIsToastr(true);
      setPrintLink(base64);
      await dispatch(reset_fax_scan_document_log());
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
      }, 2000);
    };

    const deleteApiDocument = async (row, index) => {
      if (row.data && row.data.id) {
        await dispatch(deletePatientDocument(row.data.id));
        let { category, provider_id, date } = documentFilters;
        if (!category) category = "";
        if (!provider_id) provider_id = "";
        if (!date) date = "";
        const params = {
          patient_id: id_patient,
          date,
          category,
          provider_id,
          sort_directionDate: sortOption.date,
          sort_directionCate: sortOption.document_category,
        };
        await setMessage("Delete Document Successfully!");
        await dispatch(get_filter_patient_document(params));
        await setConfirmDelete(false);
        await setActiveId(null);
      } else {
        setMessage("Please Click Document You Need!");
        setStatus(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          setMessage(null);
          setStatus(true);
        }, 2000);
      }
    };

    const emptyRows = useMemo(() => {
      if (!tableRef || !tableRef.current) {
        return [];
      }
      const totalRowsCount = Math.floor(
        (tableRef.current.offsetHeight - 50) / 40
      );
      const emptyRowsCount = totalRowsCount - documents.length;
      if (emptyRowsCount < 1) {
        return [];
      }
      return new Array(emptyRowsCount).fill({ document: null });
    }, [documents]);

    const sendFax = async () => {
      if (checkedIDRows.length > 1) {
        const data = {
          fax_to: faxValue,
        };
        if (!validateSteps(data, [{ value: "fax_to", type: "default" }])) {
          setEnableError(true);
          return;
        } else {
          setEnableError(false);
          await dispatch(
            faxPatientDocumentBulk({
              fax_to: faxValue,
              user_id: user_id,
              document_ids: checkedIDRows,
            })
          );
          setMessage("Send Fax Successfully!");
          setFaxValue("");
        }
      } else {
        if (selectedDocument && selectedDocument.id) {
          const data = {
            fax_to: faxValue,
          };
          if (!validateSteps(data, [{ value: "fax_to", type: "default" }])) {
            setEnableError(true);
            return;
          } else {
            setEnableError(false);
            const formData = new FormData();
            formData.append("fax_to", faxValue);
            formData.append("user_id", user_id);
            await dispatch(
              sendFaxPatientDocument(selectedDocument.id, formData)
            );
            setMessage("Send Fax Successfully!");
            setFaxValue("");
          }
        } else {
          setMessage("Please Click Document You Need!");
          setStatus(false);
          setIsToastr(true);
          setTimeout(() => {
            setIsToastr(false);
            setMessage(null);
            setStatus(true);
          }, 2000);
        }
      }
    };
    const sendEmail = async () => {
      if (checkedIDRows.length > 1) {
        const data = {
          email: emailValue,
        };
        if (!validateSteps(data, [{ value: "email", type: "email" }])) {
          setEnableErrorEmail(true);
          return;
        } else {
          setEnableErrorEmail(false);
          await dispatch(
            emailPatientDocumentBulk({
              document_ids: checkedIDRows,
              email: emailValue,
            })
          );
          setMessage("Send Email Successfully!");
          setEmailValue("");
        }
      } else {
        if (selectedDocument && selectedDocument.id) {
          const data = {
            email: emailValue,
          };
          if (!validateSteps(data, [{ value: "email", type: "email" }])) {
            setEnableErrorEmail(true);
            return;
          } else {
            setEnableErrorEmail(false);
            const formData = new FormData();
            formData.append("email_address", emailValue);
            await dispatch(emailPatientDocument(selectedDocument.id, formData));
            setMessage("Send Email Successfully!");
            setEmailValue("");
          }
        } else {
          setMessage("Please Click Document You Need!");
          setStatus(false);
          setIsToastr(true);
          setTimeout(() => {
            setIsToastr(false);
            setMessage(null);
            setStatus(true);
          }, 2000);
        }
      }
    };

    const sendPrint = async () => {
      if (checkedIDRows.length > 1) {
        await dispatch(
          printPatientDocumentBulk({
            document_ids: checkedIDRows,
          })
        );
      } else {
        if (selectedDocument && selectedDocument.id) {
          await dispatch(printPatientDocument(selectedDocument.id));
        } else {
          setMessage("Please Click Document You Need!");
          setStatus(false);
          setIsToastr(true);
          setTimeout(() => {
            setIsToastr(false);
            setMessage(null);
            setStatus(true);
          }, 2000);
        }
      }
    };

    useEffect(() => {
      if (dataDoc?.length > 0) {
        onOpenDocument(dataDoc[0]);
      }
    }, [dataDoc]);

    const showToast = () => {
      return (
        <ToastMessage
          type="Document"
          status={status ? "success" : "failed"}
          msg={message ? message : "Update Successfully!"}
        />
      );
    };

    const existingProvider = (
      pcpList && pcpList?.active_pcp ? pcpList?.active_pcp : pcpList || []
    ).find((rp) => {
      if (rp && rp.id) {
        return patientResource && rp.id === patientResource.referral_provider;
      }
    });

    const existingPCP = (
      pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList || []
    ).find((cp) => {
      if (cp && cp.id) {
        return (
          patientResource && cp.id === patientResource.primary_care_provider_id
        );
      }
    });

    if (!isShow) {
      return null;
    }
    return (
      <>
        <div
          ref={ref}
          className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
        >
          {isToastr && !errorRest && showToast()}

          <ScrollWrapper css="x-hidden">
            <div className="headerContainer">
              <button onClick={handleCloseSidebar}>
                <CloseIcon />
              </button>
            </div>

            <SidebarTitle
              icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
              title={
                <span className="text-uppercase">
                  {activeTab === "documentation"
                    ? "Document Database"
                    : activeTab === "image-viewer-tab"
                    ? "Images Database"
                    : "Laboratory Database"}
                </span>
              }
              subTitle=""
            />

            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
                paddingBottom: "2rem",
                height:
                  activeTab !== "image-viewer-tab"
                    ? "calc(100% - 70px)"
                    : "auto",
                display: activeTab !== "image-viewer-tab" ? "flex" : "block",
                flexDirection: "column",
              }}
            >
              {activeTab === "documentation" && (
                <div
                  className="resourceInfo d-flex"
                  style={{ flex: 1, width: "100%", flexDirection: "column" }}
                >
                  <div
                    ref={tableRef}
                    style={{
                      height: "calc(100% - 10px)",
                      minHeight: "25vh",
                      width: "100%",
                    }}
                  >
                    <DataTable
                      title="patient_chart_imagelist"
                      isNormal={true}
                      labels={["Date", "Document Category"]}
                      widths={["35%", "65%"]}
                      activeIndex={0}
                      showIcon={false}
                      columns={[
                        { sortable: true, key: "date" },
                        { sortable: true, key: "filename" },
                      ]}
                      handleSort={(sortOpt) => handleSortOption(sortOpt)}
                      handleClickRow={(row, index) => onOpenDocument(row.data)}
                      rowData={(dataDoc || []).map((item, index) => ({
                        key: `image-list-${item.id}-${index}`,
                        data: item,
                        date: (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Checkbox
                              checked={checkedRows.includes(index)}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleCheckRows(index, item);
                              }}
                            />
                            {item.created_at && (
                              <p style={{ paddingLeft: "1rem" }}>
                                {moment(
                                  item.created_at,
                                  "YYYY/MM/DD hh:mm:ss"
                                ).format("MM/DD/YYYY")}
                              </p>
                            )}
                          </div>
                        ),
                        filename: (
                          <div style={{ textTransform: "capitalize" }}>
                            {item.category === "hipaa_form"
                              ? "HIPAA and Privacy Policy Acknowledgement"
                              : item.category.toLowerCase().replace(/-/g, " - ")
                              ? item.category.toLowerCase().replace(/-/g, " - ")
                              : item.filename
                                  .toLowerCase()
                                  .replace(/-/g, " - ")}
                          </div>
                        ),
                      }))}
                      emptyRows={emptyRows}
                      handleDelete={deleteApiDocument}
                      sorts={[sortOption.date, sortOption.document_category]}
                    />
                  </div>
                  <div
                    className="resourceInfo d-flex"
                    style={{ justifyContent: "flex-end", marginTop: "0.5rem" }}
                  ></div>
                </div>
              )}

              {activeTab === "image-viewer-tab" && <ImageList />}

              {activeTab === "laboratory" && (
                <React.Fragment>
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PalMessageSVG />
                  </div>
                </React.Fragment>
              )}
              {activeTab !== "image-viewer-tab" && (
                <Collapse accordion style={{ marginTop: "2.5rem" }}>
                  <Panel header="Fax" key="fax">
                    <div
                      className="resourceInfo d-flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          position: "relative",
                          maxWidth: "400px",
                        }}
                        onClick={() => setOpenFax(false)}
                      >
                        <EditableFormInput
                          handleChange={(value) => {
                            setFaxValue(value);
                          }}
                          value={faxValue}
                          label={"Fax No"}
                          mask={"phone"}
                          required={true}
                          enableErrorText={enableError}
                          isError={validateField("fax_to", faxValue)}
                          helperText={"Fax No is Required!"}
                        />

                        {((existingPCP && existingPCP.fax_number) ||
                          (existingProvider &&
                            existingProvider.fax_number)) && (
                          <div
                            style={{
                              position: "absolute",
                              display: "inline-flex",
                              width: "40px",
                              height: "40px",
                              zIndex: 20,
                              top: "2px",
                              right: "2px",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              transform: isOpenFax
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                            className="dropdown-button"
                            onClick={toggleOpenFax}
                          >
                            <ArrowDownSVG />
                          </div>
                        )}

                        {isOpenFax &&
                          ((existingPCP && existingPCP.fax_number) ||
                            (existingProvider &&
                              existingProvider.fax_number)) && (
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 20,
                                left: 0,
                                right: 0,
                                top: "42px",
                                background: "#fff",
                                border: "1px solid #ddd",
                              }}
                            >
                              {existingPCP && existingPCP.fax_number && (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    borderBottom: "1px solid #ddd",
                                    padding: "0 14px",
                                    fontSize: "14px",
                                    color: "#000",
                                    lineHeight: "40px",
                                    cursor: "pointer",
                                    background:
                                      pcp === "pcp" ? "#dbe3ee" : "#fff",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFaxValue(existingPCP.fax_number);
                                    if (existingPCP.email) {
                                      setEmailValue(existingPCP.email);
                                    }
                                    setPCP("pcp");
                                    setOpenFax(false);
                                  }}
                                >
                                  PCP
                                </div>
                              )}
                              {existingProvider && existingProvider.fax_number && (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    padding: "0 14px",
                                    fontSize: "14px",
                                    color: "#000",
                                    lineHeight: "40px",
                                    cursor: "pointer",
                                    background:
                                      pcp === "referring_provider"
                                        ? "#dbe3ee"
                                        : "#fff",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFaxValue(existingProvider.fax_number);
                                    if (existingProvider.email) {
                                      setEmailValue(existingProvider.email);
                                    }
                                    setPCP("referring_provider");
                                    setOpenFax(false);
                                  }}
                                >
                                  Referring Provider
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                      <button
                        className="common-btn blue-btn"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "8px",
                        }}
                        onClick={sendFax}
                      >
                        <span>Send</span>
                        <span>+</span>
                      </button>
                    </div>
                  </Panel>
                  <Panel header="E-Mail" key="e-mail">
                    <div
                      className="resourceInfo d-flex"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          position: "relative",
                          maxWidth: "400px",
                        }}
                        onClick={() => setOpenEmail(false)}
                      >
                        <EditableMaterialText
                          reset={reset}
                          handleChange={(value) => {
                            setEmailValue(value);
                          }}
                          value={emailValue}
                          label={"E-Mail"}
                          required={true}
                          enableErrorText={enableErrorEmail}
                          isError={validateField("email", emailValue)}
                          helperText={"E-Mail is Required!"}
                        />

                        {((existingPCP && existingPCP.email) ||
                          (existingProvider && existingProvider.email)) && (
                          <div
                            style={{
                              position: "absolute",
                              display: "inline-flex",
                              width: "40px",
                              height: "40px",
                              zIndex: 20,
                              top: "2px",
                              right: "2px",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              transform: isOpenEmail
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                            className="dropdown-button"
                            onClick={toggleOpenEmail}
                          >
                            <ArrowDownSVG />
                          </div>
                        )}

                        {isOpenEmail &&
                          ((existingPCP && existingPCP.email) ||
                            (existingProvider && existingProvider.email)) && (
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 20,
                                left: 0,
                                right: 0,
                                top: "42px",
                                background: "#fff",
                                border: "1px solid #ddd",
                              }}
                            >
                              {existingPCP && existingPCP.email && (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    borderBottom: "1px solid #ddd",
                                    padding: "0 14px",
                                    fontSize: "14px",
                                    color: "#000",
                                    lineHeight: "40px",
                                    cursor: "pointer",
                                    background:
                                      pcp === "pcp" ? "#dbe3ee" : "#fff",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEmailValue(existingPCP.email);
                                    if (existingPCP.fax_number) {
                                      setFaxValue(existingPCP.fax_number);
                                    }
                                    setPCP("pcp");
                                    setOpenEmail(false);
                                  }}
                                >
                                  PCP
                                </div>
                              )}
                              {existingProvider && existingProvider.email && (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    padding: "0 14px",
                                    fontSize: "14px",
                                    color: "#000",
                                    lineHeight: "40px",
                                    cursor: "pointer",
                                    background:
                                      pcp === "referring_provider"
                                        ? "#dbe3ee"
                                        : "#fff",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEmailValue(existingProvider.email);
                                    if (existingProvider.fax_number) {
                                      setFaxValue(existingProvider.fax_number);
                                    }
                                    setPCP("referring_provider");
                                    setOpenEmail(false);
                                  }}
                                >
                                  Referring Provider
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                      <button
                        className="common-btn blue-btn"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "8px",
                        }}
                        onClick={sendEmail}
                      >
                        <span>Send</span>
                        <span>+</span>
                      </button>
                    </div>
                  </Panel>
                  <Panel header="Print" key="print">
                    <div
                      className="resourceInfo d-flex"
                      style={{
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      onClick={sendPrint}
                    >
                      <button
                        className="common-btn blue-btn"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "8px",
                        }}
                        onClick={sendFax}
                      >
                        <span>Print</span>
                        <span>+</span>
                      </button>
                    </div>
                  </Panel>
                </Collapse>
              )}
            </div>
          </ScrollWrapper>
        </div>
      </>
    );
  }
);

export default PatientChartSideBar;
