import React, { useState, useEffect } from 'react';
import {
  Input,
  Collapse,
  Icon
} from 'antd';
import {SearchSVG, CloseIconSVG, TrashSVG} from '../../../../common/components/icons/SVGIcon';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import { useDebounce } from 'use-lodash-debounce';
import _ from 'lodash';

const OfficeProcedureList = ({
  rows,
  options,
  onChangeData,
  onClickConditionPanel
}) => {
  const [data, setData] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [searchInput, setSearch] = useState('');

  useEffect(() => {
    setData(rows);
    let filteredOptions_ = [];
    for (let option of options) {
      let isExist = false;
      for (let row of rows) {
        if (option['condition']===row['condition']) 
          isExist = true;
      }
      if (!isExist) filteredOptions_.push(option);
    }
    setFilteredOptions(filteredOptions_);
  }, [rows]);

  // const data = rows?rows:[];

  
  const handleSearch = (search) => {
    setSearch(search);

    let filteredOptions_ = [];
    for (let option of options) {
      let isExist = false;
      for (let row of rows) {
        if (option['condition']===row['condition']) 
          isExist = true;
      }
      if (!isExist) {
        if (option['condition'].toLowerCase().includes(search.toLowerCase())) {
          filteredOptions_.push(option);
        }
      }
    }
    setFilteredOptions(filteredOptions_);
  }
  const onSelectOption = (option) => {
    setSelectedOption(option);
    setSearch(option['condition']);
    setShowOptions(false);
  }
  const onAddCondition = () => {
    if (!selectedOption) return;

    let data_ = [...data];
    data_.push(selectedOption);
    setSearch('');
    setSelectedOption(undefined);
    onChangeData(data_);  
  }

  const onRemoveCondition = (conditionIndex) => {
    let data_ = [...data];
    data_.splice(conditionIndex, 1);
    onChangeData(data_);
  }
  const onChangeComments = (conditionIndex, questionIndex, comments) => {
    let data_ = [...data];
    data_[conditionIndex]['questions'][questionIndex]['comments'] = comments;
    onChangeData(data_);
  }
  const onSelectChoice = (conditionIndex, questionIndex, checked_choice) => {
    let data_ = [...data];
    data_[conditionIndex]['questions'][questionIndex]['checked_choice'] = checked_choice;
    onChangeData(data_);
  }


  const PanelToolbar = ({onRemoveCondition, row}) => (
    <div className='atuoAccordion__result-item-close_btn' onClick={(e) => {e.stopPropagation(); onRemoveCondition(row)}}>
      <Icon type="delete"/>
    </div>
  )

  return (
    <div className="atuoAccordion">
      <div className="atuoAccordion__filter">
        <div className="atuoAccordion__filter-search white">
          <SearchInput 
            searchInput={searchInput}
            setSearch={handleSearch}
            setShowOptions={setShowOptions}
          />
        </div>
        <div className="atuoAccordion__filter-action" style={{maxHeight: '40px'}} >
          <button className="atuoAccordion__filter-add" onClick={onAddCondition}>
            <span>Add</span>
          </button>
        </div>
        {showOptions && searchInput && (
          <div className="atuoAccordion__filter-result">
            <div className="atuoAccordion__filter-result-list">
              <ScrollWrapper css="no-padding">
                {!!filteredOptions.length &&
                  filteredOptions.map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`filter-option-${index}`}
                      onClick={() => onSelectOption(row)}
                    >
                      <p>{row['condition']}</p>
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>
      <div className="atuoAccordion__result">
        <div className="atuoAccordion__result-list">
          <Collapse accordion onChange={(key) => onClickConditionPanel(key)}>
            {data.map((row, index) => (
              <Collapse.Panel
                className="atuoAccordion__result-item"
                key={`collapse-${_.snakeCase(row['condition'])}`}
                header={row.condition}
                extra={<PanelToolbar onRemoveCondition={() => onRemoveCondition(index)} row={row} />} 
              >
                <div className="content"
                >
                  <div className="hpiList">
                    {row.questions.map((question, index2) => (
                      <HPIConditionItem
                        key={`questionList-${index}-${index2}`}
                        data={question}
                        onChangeComments={(comments) => onChangeComments(index, index2, comments)}
                        onSelectChoice = {(checked_choice) => onSelectChoice(index, index2, checked_choice)}
                      />
                    ))}
                  </div>
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};  

const SearchInput = (props) => {
  const [searchInput, setSearch] = useState('');
  const debouncedSearch = useDebounce(searchInput, 400);
  useEffect(() => {
     setSearch(props.searchInput);
  }, [props.searchInput]);

  useEffect(() => {
    props.setSearch(searchInput);
  }, [debouncedSearch]);

  const handleSearch = (search) => {
    setSearch(search);    
  }

  return (
    <Input.Search
      value={searchInput}
      placeholder="Search Procedure"
      onChange={e => handleSearch(e.target.value)}
      style={{ width: '100%' }}
      suffix={<SearchSVG />}
      onFocus={() => props.setShowOptions(true)}
      onBlur={() => setTimeout(() => {props.setShowOptions(false)}, 250)}
    />
  )
}

const HPIConditionItem = ({
  data,
  onChangeComments,
  onSelectChoice
}) => {
  const [isOpenedBox, setOpenBox] = useState(false);
  const [question, setQuestion] = useState('');
  const [checkedChoice, setCheckedChoice] = useState('');
  const [comments, setComments] = useState('');
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    setQuestion(data.question);
    setChoices(data.choices);
    setComments(data.comments);
    setCheckedChoice(data.checked_choice);
  }, [])

  return (
    <div className={`HPIItem`}>
      <div className="HPIItem__label">{question}</div>
      <div className={`HPIItem__icon ${isOpenedBox ? 'open' : ''}`} onClick={() => setOpenBox(!isOpenedBox)}>
        <Icon type="edit" />
      </div>
      <div className={`HPIItem__options`}>
        {choices.map((choice, index) => (
          <button
            key={`question-choice-${index}`}
            className={`HPIItem__option ${choice === checkedChoice? 'active' : ''}`}
            onClick={() => {
              setCheckedChoice(choice);
              onSelectChoice(choice);
            }}
          >
            {choice}
          </button>
        ))}
      </div>

      <div
        className="HPIItem__box"
        style={{
          display: isOpenedBox ? 'inline-block' : 'none'
        }}  
        onBlur={() => {
          onChangeComments(comments);
        }}      
      >
        <div 
          style={{
            position: 'absolute', right: '5px', top: '5px', zIndex: 10,
            width: '15px', height: '15px',
            cursor: 'pointer'
          }}
          onClick={() => setOpenBox(!isOpenedBox)}
        >
          <CloseIconSVG />
        </div>
        <Input.TextArea rows={4}
          value={comments}
          onChange={e => {
            setComments(e.target.value);
          }}
        />
      </div>
    </div>
  )
}

export default OfficeProcedureList;
  