import React, { useState, useEffect, useMemo } from "react";
import { CloseIcon, SummaryEmptySVG } from "../../components/icons/SVGIcon";
import ScrollWrapper from "../../components/ScrollBar";
import { MaleSVG } from "../../components/icons/FaxSVG";
import SidebarTitle from "../../components/SidebarTitle";
import './style.scss';

const EmptySidebar = React.forwardRef(({
  isShow,
  handleCloseSidebar,
  processOrders,
  ...restProps
}, ref) => {
  return (
    <div
      ref={ref}
      className={`resourceContainer ${restProps.noSubHeader ? "" : "has-sub-header"
        } ${isShow ? "show" : ""}`}
    >
      <ScrollWrapper css="x-hidden">
        <div
          className="emptyContentIcon"
          style={{
            width: "100%",
            height: "calc(100% - 160px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SummaryEmptySVG />
        </div>
        <div
            className="actionContent"
            style={{ marginTop: "2rem", paddingRight: "1rem" }}
          >
            <button className="common-btn blue-btn" style={{
              display: 'flex',
              justifyContent: 'space-between'
            }} onClick={processOrders}>
              <span>Process Orders</span>
              <span>+</span>
            </button>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default EmptySidebar;
