import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Cookies from "universal-cookie";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import { getLocations, setParamsChart } from "../../../../store/actions";
import dataHeader from "./formarterData";
import "../../../../common/components/TableDrafJS/Table.css";

const cookies = new Cookies();

const ReviewText = ({ changeValueText, dataCPT }) => {
  const contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
  const editorState = EditorState.createWithContent(contentState);
  const [text, setText] = useState(editorState);
  const [first, setFirst] = useState(false);
  const [data_template, set_data_template] = useState([]);
  const authName = cookies.get("cvai-current-user-provider-name");
  const intra = useSelector((state) => state.patientExamination.intra);
  const list_procedurals = useSelector(
    (state) => state.procedure.list_procedurals
  );
  const detail_procedure = useSelector((state) => state.common.CPT);
  const data_template_review = useSelector(
    (state) => state.patientSchedule.data_template_review
  );
  //
  const complication_value = useSelector(
    (state) => state.patientExamination.complication
  );
  const total_contrast_load = useSelector(
    (state) => state.patientExamination.total_contrast_load
  );
  const total_radiation_time = useSelector(
    (state) => state.patientExamination.total_radiation_time
  );
  //

  const dispatch = useDispatch();
  const dataState = JSON.stringify(convertToRaw(text.getCurrentContent()));
  if (!first && dataState && dataState.includes("blocks")) {
    setFirst(true);
  }

  const onChange = (editorState) => {
    setText(editorState);
    let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    changeValueText(data);
  };

  useEffect(() => {
    if (first) {
      let data = JSON.stringify(convertToRaw(text.getCurrentContent()));
      // header 1 DATE OF PROCEDURE
      if (
        !data.includes(
          `{"key":"txt1empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtd2","text":"${moment().format(
            "MM/DD/YYYY"
          )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )
      ) {
        data = data.replace(
          `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `{"key":"txt1empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtd2","text":"${moment().format(
            "MM/DD/YYYY"
          )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }

      // header 9 SURGEON
      if (
        !data.includes(
          `{"key":"txt9empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"nmsi2","text":"${authName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )
      ) {
        data = data.replace(
          `{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `{"key":"txt9empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"nmsi2","text":"${authName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }

      // header 8 INDICATION
      if (intra && intra.length > 0) {
        for (let i = 0; i < intra.length; i++) {
          let intratxt = "";

          if (intra[i] && intra[i]["questions"]) {
            // intratxt += `{"key":"txtheaderintra${i}","text":"${intra[i]["angio_procedure_type_name"]}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${intra[i]["angio_procedure_type_name"].length},"style":"BOLD"},{"offset":0,"length":${intra[i]["angio_procedure_type_name"].length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`;
            if (data_template && data_template[i]) {
              if (data_template[i]["text_template"] && data_template[i]["text_template"].includes("blocks")) {
                let valueImport = data_template[i]["text_template"].replace(
                  `{"blocks":[`,
                  ""
                );
                if (
                  valueImport.includes(`],"entityMap":{`) &&
                  valueImport.includes(`}}}}}`)
                ) {
                  const [enti, ...entis] =
                    valueImport && valueImport.split(`],"entityMap":{`);
                  const checkEnti = (entis || []).map((item) => {
                    return item.split(`}}}}}`)[0];
                  });
                  if (checkEnti && checkEnti[0].length > 0) {
                    valueImport = valueImport.replace(
                      `],"entityMap":{${checkEnti[0]}}}}}}`,
                      ``
                    );
                  }
                }
                if (valueImport.includes(`"entityMap":[{`)) {
                  const [enti, ...entis] =
                    valueImport && valueImport.split(`],"entityMap":[{"`);
                  const checkEnti = (entis || []).map((item) => {
                    return item.split(`]}`)[0];
                  });
                  if (checkEnti && checkEnti[0].length > 0) {
                    valueImport = valueImport.replace(
                      `],"entityMap":[{"${checkEnti[0]}]}`,
                      ``
                    );
                  }
                }
                if (valueImport.includes(`],"entityMap":[]}`)) {
                  valueImport = valueImport.replace(`],"entityMap":[]}`, "");
                }
                if (valueImport.includes(`],"entityMap":{}}`)) {
                  valueImport = valueImport.replace(`],"entityMap":{}}`, "");
                }
                if (valueImport.length > 104) {
                  if (
                    !data.includes(
                      `{"key":"txttemplateempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                    )
                  ) {
                    intratxt += `${`{"key":"txttemplateempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`}${valueImport},`;
                  }
                }
              }
            }
            for (let a = 0; a < intra[i]["questions"].length; a++) {
              if (intra[i]["questions"][a]["checked_choice"] != "" && intra[i]["questions"][a]["choices"]) {
                for (
                  let b = 0;
                  b < intra[i]["questions"][a]["choices"].length;
                  b++
                ) {
                  if (
                    `${intra[i]["questions"][a]["choices"][b]["option"]}` ===
                    `${intra[i]["questions"][a]["checked_choice"]}`
                  ) {
                    for (let c = 0; c < 100; c++) {
                      if (
                        intratxt.includes(
                          `[${intra[i]["questions"][a]["variable_name"]}]`
                        )
                      ) {
                        intratxt = intratxt.replace(
                          `[${intra[i]["questions"][a]["variable_name"]}]`,
                          `${intra[i]["questions"][a]["choices"][b]["text_template"]}`
                        );
                      }
                    }
                  }
                }
              }
            }
          }
          if (
            intratxt.length > 0 &&
            !data.includes(
              `${intratxt}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            )
          ) {
            data = data.replace(
              `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${intratxt}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
      }

      let closureCheck = "";
      let footerCheck = "";

      // header 12, 13, 10 ,11  ACCESSS, DIAGNOSTIC INTERVENTION Closure Device
      if (list_procedurals && list_procedurals.length > 0) {
        let allUltrasound = "";
        // let allneedle = "";
        let headerCheck = "";
        for (let i = 0; i < list_procedurals.length; i++) {
          let rowBody = "";
          let ultrasoundCheck = "";
          if (list_procedurals[i]["body"]["part"] == "m-body") {
            rowBody = "Left Internal Iliac Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-neck-1") {
            rowBody = "Left Carotid Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-body-2") {
            rowBody = "Right Renal Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-body-0") {
            rowBody = "Left Renal Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-neck-1") {
            rowBody = "Right Carotid Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-arm-4") {
            rowBody = "Right Axillary Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-arm-3") {
            rowBody = "Right Brachial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-arm-1l") {
            rowBody = "Right Radial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-arm-0") {
            rowBody = "Right Radial-Ulnar Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-arm-1r") {
            rowBody = "Right Ulnar Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-arm-2") {
            rowBody = "Right Ulnar-Radial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-arm-4") {
            rowBody = "Left Axillary Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-arm-3") {
            rowBody = "Left Brachial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-arm-1r") {
            rowBody = "Left Radial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-arm-0") {
            rowBody = "Left Radial-Ulnar Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-arm-1l") {
            rowBody = "Left Ulnar Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-arm-2") {
            rowBody = "Left Ulnar-Radial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-0") {
            rowBody = "Right External Iliac Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-1") {
            rowBody = "Right Common Femoral Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-2") {
            rowBody = "Right Proximal Superficial Femoral Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-3") {
            rowBody = "Right Mid Superficial Femoral Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-5") {
            rowBody = "Right Tibioperoneal Trunk";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-4") {
            rowBody = "Right Distal Superficial Femoral Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-7l") {
            rowBody = "Right Mid Anterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-8l") {
            rowBody = "Right Distal Anterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-9") {
            rowBody = "Right Plantar Arch";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-8r") {
            rowBody = "Right Distal Posterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-7r") {
            rowBody = "Right Mid Posterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-6r") {
            rowBody = "Right Proximal Posterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-o-3") {
            rowBody = "Right Distal Peroneal Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-o-1") {
            rowBody = "Right Profunda Femoris";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-o-0") {
            rowBody = "Right Internal Iliac Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-o-2") {
            rowBody = "Right Proximal Peroneal Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg-6l") {
            rowBody = "Right Proximal Anterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-neck-0") {
            rowBody = "Right Common Carotid Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-lung") {
            rowBody = "Right Subclavian Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-lung") {
            rowBody = "Left Subciavian Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-body-1") {
            rowBody = "Superior Mesenteric Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-body-0") {
            rowBody = "Celiac Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-0") {
            rowBody = "Left External Iliac Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-1") {
            rowBody = "Left Common Femoral Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-2") {
            rowBody = "Left Proximal Superficial Femoral Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-3") {
            rowBody = "Left Mid Superficial Femoral Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-5") {
            rowBody = "Left Tibioperoneal Trunk";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-4") {
            rowBody = "Left Distal Superficial Femoral Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-7r") {
            rowBody = "Left Mid Posterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-8r") {
            rowBody = "Left Distal Posterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-9") {
            rowBody = "Left Plantar Arch";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-8l") {
            rowBody = "Left Distal Anterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-7l") {
            rowBody = "Left Mid Anterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-6l") {
            rowBody = "Left Proximal Anterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-o-3") {
            rowBody = "Left Distal Peroneal Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-o-1") {
            rowBody = "Left Profunda Femoris";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-o-0") {
            rowBody = "Left Internal Iliac Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-o-2") {
            rowBody = "Left Proximal Peroneal Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg-6r") {
            rowBody = "Left Proximal Posterior Tibial Artery";
          } else if (list_procedurals[i]["body"]["part"] == "r-leg") {
            rowBody = "Right Common Iliac Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-leg") {
            rowBody = "Left Common Iliac Artery";
          } else if (list_procedurals[i]["body"]["part"] == "l-neck-0") {
            rowBody = "Left Common Carotid Artery";
          } else {
            rowBody = list_procedurals[i]["body"]["part"];
          }
          // headerCheck += `{"key":"hedlkmempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"hedlkm${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`;
          // footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"dnkajn2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          // map check
          const checkArray = list_procedurals[i]["medicals"].map((m, index) => {
            if (
              (m == "stenosis-60" ||
                m == "stenosis-0" ||
                m == "stenosis-20" ||
                m == "stenosis-40" ||
                m == "stenosis-80" ||
                m == "stenosis-100") &&
              list_procedurals[i]["medicals"] &&
              list_procedurals[i]["medicals"][index + 1] &&
              list_procedurals[i]["medicals"][index + 1] == "ivus"
            ) {
              return true;
            } else if (
              m == "stenosis-60" ||
              m == "stenosis-0" ||
              m == "stenosis-20" ||
              m == "stenosis-40" ||
              m == "stenosis-80" ||
              m == "stenosis-100"
            ) {
              return false;
            } else {
              return null;
            }
          });
          // if (
          //   checkArray.some((checkcondition) => checkcondition == true) ||
          //   checkArray.some((checkcondition) => checkcondition == false)
          // ) {
          // }
          if (checkArray.some((checkcondition) => checkcondition == true)) {
            let trueCheck = "";
            for (
              let fc = 0;
              fc < list_procedurals[i]["medicals"].length;
              fc++
            ) {
              if (
                (list_procedurals[i]["medicals"][fc] == "stenosis-60" ||
                  list_procedurals[i]["medicals"][fc] == "stenosis-0" ||
                  list_procedurals[i]["medicals"][fc] == "stenosis-20" ||
                  list_procedurals[i]["medicals"][fc] == "stenosis-40" ||
                  list_procedurals[i]["medicals"][fc] == "stenosis-80" ||
                  list_procedurals[i]["medicals"][fc] == "stenosis-100") &&
                list_procedurals[i]["medicals"] &&
                list_procedurals[i]["medicals"][fc + 1] &&
                list_procedurals[i]["medicals"][fc + 1] == "ivus"
              ) {
                if (list_procedurals[i]["medicals"][fc].includes("-")) {
                  const replaceSplit = list_procedurals[i]["medicals"][
                    fc
                  ].split("-");
                  if (replaceSplit.length > 0) {
                    trueCheck += `${replaceSplit[1]}% ${replaceSplit[0]},`;
                  }
                }
              }
            }

            if (
              !data.includes(
                `{"key":"checked${i}","text":"The ${rowBody} demonstrates  ${trueCheck.includes(",") && trueCheck.split(",")[0]
                } on IVUS (Intravascaular Ultrasound) intraluminal imaging.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              )
            ) {
              headerCheck += `{"key":"hedlkmempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"hedlkm${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"endheaderempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              headerCheck += `{"key":"checked${i}","text":"The ${rowBody} demonstrates  ${trueCheck.includes(",") && trueCheck.split(",")[0]
                } on IVUS (Intravascaular Ultrasound) intraluminal imaging.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            }
          } else if (
            checkArray.some((checkcondition) => checkcondition == false)
          ) {
            let falseCheck = "";
            for (
              let fc = 0;
              fc < list_procedurals[i]["medicals"].length;
              fc++
            ) {
              if (
                list_procedurals[i]["medicals"][fc] == "stenosis-60" ||
                list_procedurals[i]["medicals"][fc] == "stenosis-0" ||
                list_procedurals[i]["medicals"][fc] == "stenosis-20" ||
                list_procedurals[i]["medicals"][fc] == "stenosis-40" ||
                list_procedurals[i]["medicals"][fc] == "stenosis-80" ||
                list_procedurals[i]["medicals"][fc] == "stenosis-100"
              ) {
                if (list_procedurals[i]["medicals"][fc].includes("-")) {
                  const replaceSplit = list_procedurals[i]["medicals"][
                    fc
                  ].split("-");
                  if (replaceSplit.length > 0) {
                    falseCheck += `${replaceSplit[1]}% ${replaceSplit[0]},`;
                  }
                }
              }
            }
            if (
              !data.includes(
                `{"key":"checkout${i}","text":"The ${rowBody} demonstrates ${falseCheck.includes(",") && falseCheck.split(",")[0]
                } on diagnostic angiography.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              ) &&
              !headerCheck.includes(
                `{"key":"checkout${i}","text":"The ${rowBody} demonstrates ${falseCheck.includes(",") && falseCheck.split(",")[0]
                } on diagnostic angiography.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              )
            ) {
              headerCheck += `{"key":"hedlkmempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"hedlkm${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"endheaderempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              headerCheck += `{"key":"checkout${i}","text":"The ${rowBody} demonstrates ${falseCheck.includes(",") && falseCheck.split(",")[0]
                } on diagnostic angiography.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            }
          }
          //
          if (
            list_procedurals[i]["medicals"].some(
              (r) => r == "closure-device"
            ) ||
            list_procedurals[i]["medicals"].some(
              (r) => r == "local-anesthesia"
            ) ||
            list_procedurals[i]["medicals"].some((r) => r == "access") ||
            list_procedurals[i]["medicals"].some((r) => r == "ultrasound")
          ) {
            let check_find_id = null;
            let checkSuccess = [];
            checkSuccess = (list_procedurals[i]["medicals"] || []).map(
              (r, index) => {
                if (r == "closure-device") {
                  check_find_id = index;
                  return true;
                } else if (
                  r == "local-anesthesia" ||
                  r == "access" ||
                  r == "ultrasound"
                ) {
                  check_find_id = null;
                  return false;
                } else {
                  return null;
                }
              }
            );
            if (checkSuccess.some((r) => r == true)) {
              if (check_find_id !== null) {
                closureCheck += `{"key":"colosureheader${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"headercolouser${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"kmgjan6${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                closureCheck += `{"key":"checkedcolosure${i}","text":"The procedure was terminated. The sheath was removed. ${`Utilizing standard techniques the ${list_procedurals[i]["details"] &&
                    list_procedurals[i]["details"][check_find_id] &&
                    list_procedurals[i]["details"][check_find_id][
                    "product_description"
                    ]
                    ? list_procedurals[i]["details"][check_find_id][
                    "product_description"
                    ]
                    : ""
                  } Closure Device was utilized for arterial closure.`} The patient tolerated the procedure well, there were no acute complications. All counts were correct and I was present through the entire case.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              }
            } else if (checkSuccess.some((r) => r == false)) {
              closureCheck += `{"key":"colosureheader${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"headercolouser${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"kmgjan6${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              closureCheck += `{"key":"falsecolosure${i}","text":"The procedure was terminated. The sheath was removed. Manual Pressure was applied until hemostasis was achieved. The patient tolerated the procedure well, there were no acute complications. All counts were correct and I was present through the entire case.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            }
          }

          if (list_procedurals[i]["medicals"].some((r) => r == "ultrasound")) {
            ultrasoundCheck += `Duplex ultrasonography was utilized to visualize the ${rowBody}. An electronic image of the ultrasound findings is contained within the patients record. Lidocaine 1% was infiltrated within the overlying skin to anesthesize the surface skin overlying the ${rowBody}. `;
            // allUltrasound += `{"key":"unslkmempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"unslkm${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"txmultraempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txmultra${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
          if (list_procedurals[i]["medicals"].some((r) => r == "access")) {
            ultrasoundCheck += `A micropuncture needle was guided sonographically into the ${rowBody} and this was used to pass a micropuncture wire and subsequently exchanged with a micropuncture sheath. A larger sheath was exchanged with the micropuncture sheath to maintain access within the ${rowBody} for diagnostic and intervention purposes. `;
          }
          if (
            list_procedurals[i]["medicals"].some((r) => r == "ultrasound") ||
            list_procedurals[i]["medicals"].some((r) => r == "access")
          ) {
            allUltrasound += `{"key":"unslkmempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"anes${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"txtneedleempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            allUltrasound += `{"key":"kmsacvn${i}","text":"${ultrasoundCheck}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
          // if (list_procedurals[i]["medicals"].some((r) => r == "access")) {
          //   if (
          //     !allneedle.includes(
          //       `{"key":"anesempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"anes${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"txtneedleempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtneedle${i}","text":"A micropuncture needle was guided sonographically into the ${rowBody} and this was used to pass a micropuncture wire and subsequently exchanged with a micropuncture sheath. A larger sheath was exchanged with the micropuncture sheath to maintain access within the ${rowBody} for diagnostic and intervention purposes.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          //     )
          //   ) {
          //     allneedle += `{"key":"anesempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"anes${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"txtneedleempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtneedle${i}","text":"A micropuncture needle was guided sonographically into the ${rowBody} and this was used to pass a micropuncture wire and subsequently exchanged with a micropuncture sheath. A larger sheath was exchanged with the micropuncture sheath to maintain access within the ${rowBody} for diagnostic and intervention purposes.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          //   }
          // }
          // footerCheck += `{"key":"atemicxempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"atemicx${i}","text":"ATHRECTOMY","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"},{"offset":0,"length":11,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`;
          let footerBody = "";
          let checkFooter = "";
          for (let ft = 0; ft < list_procedurals[i]["medicals"].length; ft++) {
            if (
              list_procedurals[i]["medicals"][ft] ==
              "directional-atherectomy" ||
              list_procedurals[i]["medicals"][ft] == "laser-atherectomy" ||
              list_procedurals[i]["medicals"][ft] == "rotational-atherectomy" ||
              list_procedurals[i]["medicals"][ft] == "obital-atherectomy" ||
              list_procedurals[i]["medicals"][ft] == "balloon-angioplasty" ||
              list_procedurals[i]["medicals"][ft] ==
              "balloon-expandable-covered-stent" ||
              list_procedurals[i]["medicals"][ft] == "covered-stent" ||
              list_procedurals[i]["medicals"][ft] ==
              "balloon-expandable-stent" ||
              list_procedurals[i]["medicals"][ft] == "self-expanding-stent" ||
              list_procedurals[i]["medicals"][ft] == "closure-device"
            ) {
              if (
                list_procedurals[i]["medicals"][ft] == "directional-atherectomy"
              ) {
                footerBody = "Directional Atherectomy";
              } else if (
                list_procedurals[i]["medicals"][ft] == "laser-atherectomy"
              ) {
                footerBody = "Laser Atherectomy";
              } else if (
                list_procedurals[i]["medicals"][ft] == "rotational-atherectomy"
              ) {
                footerBody = "Rotational Atherectomy";
              } else if (
                list_procedurals[i]["medicals"][ft] == "obital-atherectomy"
              ) {
                footerBody = "Obital Atherectomy";
              } else if (
                list_procedurals[i]["medicals"][ft] ==
                "balloon-expandable-covered-stent"
              ) {
                footerBody = "Balloon Expandable Covered Stent";
              } else if (
                list_procedurals[i]["medicals"][ft] == "covered-stent"
              ) {
                footerBody = "Covered Stent";
              } else if (
                list_procedurals[i]["medicals"][ft] ==
                "balloon-expandable-stent"
              ) {
                footerBody = "Balloon Expandable Stent";
              } else if (
                list_procedurals[i]["medicals"][ft] == "self-expanding-stent"
              ) {
                footerBody = "Self Expanding Stent";
              } else if (
                list_procedurals[i]["medicals"][ft] == "balloon-angioplasty"
              ) {
                footerBody = "Balloon Angioplasty";
              } else if (
                list_procedurals[i]["medicals"][ft] == "closure-device"
              ) {
                footerBody = "Closure Device";
              }
              if (
                footerBody == "Directional Atherectomy" ||
                footerBody == "Laser Atherectomy" ||
                footerBody == "Rotational Atherectomy" ||
                footerBody == "Obital Atherectomy" ||
                footerBody == "Covered Stent" ||
                footerBody == "Balloon Expandable Stent" ||
                footerBody == "Self Expanding Stent" ||
                footerBody == "Balloon Expandable Covered Stent" ||
                footerBody == "Balloon Angioplasty"
              ) {
                if (
                  footerBody == "Directional Atherectomy" ||
                  footerBody == "Laser Atherectomy" ||
                  footerBody == "Rotational Atherectomy" ||
                  footerBody == "Obital Atherectomy"
                ) {
                  if (
                    !data.includes(
                      `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                    )
                  ) {
                    footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                  }
                  checkFooter += `A ${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["product_description"]
                      ? `${list_procedurals[i]["details"][ft]["product_description"]}`
                      : ""
                    } ${footerBody} was utilized within the ${rowBody} ${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["number_of_passes"]
                      ? `for a total of ${list_procedurals[i]["details"][ft]["number_of_passes"]} passes`
                      : ""
                    }${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["pulses_delivered"]
                      ? ` and ${list_procedurals[i]["details"][ft]["pulses_delivered"]} pulses`
                      : ""
                    }${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["frequency"]
                      ? ` and frequency of ${list_procedurals[i]["details"][ft]["frequency"]}`
                      : ""
                    }${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["post_stenosis"]
                      ? `. The resultant post-stenosis was ${list_procedurals[i]["details"][ft]["post_stenosis"]}.`
                      : ""
                    }`;
                } else if (
                  footerBody == "Covered Stent" ||
                  footerBody == "Balloon Expandable Stent" ||
                  footerBody == "Self Expanding Stent" ||
                  footerBody == "Balloon Expandable Covered Stent"
                ) {
                  if (
                    !data.includes(
                      `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                    )
                  ) {
                    footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                  }
                  checkFooter += ` A ${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["product_description"]
                      ? `${list_procedurals[i]["details"][ft]["product_description"]}`
                      : ""
                    } ${footerBody} covered stent was utilized within ${rowBody ? `the ${rowBody}` : ""
                    }${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["post_stenosis"]
                      ? `. The resultant post-stenosis was ${list_procedurals[i]["details"][ft]["post_stenosis"]}.`
                      : ""
                    }`;
                } else if (footerBody == "Balloon Angioplasty") {
                  if (
                    !data.includes(
                      `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                    )
                  ) {
                    footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                  }

                  checkFooter += ` A ${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["product_description"]
                      ? `${list_procedurals[i]["details"][ft]["product_description"]}`
                      : ""
                    } ${footerBody} balloon was utilized within the ${rowBody ? rowBody : ""
                    } ${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["atm"]
                      ? `and insufflated at ${list_procedurals[i]["details"][ft]["atm"]} ATM`
                      : ""
                    }${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["inflation_time"]
                      ? ` for a total of ${list_procedurals[i]["details"][ft]["inflation_time"]} minutes`
                      : ""
                    }${list_procedurals[i]["details"][ft] &&
                      list_procedurals[i]["details"][ft]["post_stenosis"]
                      ? `. The resultant post-stenosis was ${list_procedurals[i]["details"][ft]["post_stenosis"]}.`
                      : ""
                    }`;
                }
              }
              // if (
              //   footerBody == "Directional Atherectomy" ||
              //   footerBody == "Laser Atherectomy" ||
              //   footerBody == "Rotational Atherectomy" ||
              //   footerBody == "Obital Atherectomy"
              // ) {
              //   if (
              //     !data.includes(
              //       `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"dnkajn2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              //     )
              //   ) {
              //     footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"dnkajn2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              //     footerCheck += `{"key":"fttxkma${ft}${i}","text":"A ${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["product_description"]
              //         ? `${list_procedurals[i]["details"][ft]["product_description"]}`
              //         : ""
              //     } ${footerBody} was utilized within the ${rowBody}. ${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["number_of_passes"]
              //         ? `for a total of ${list_procedurals[i]["details"][ft]["number_of_passes"]} passes`
              //         : ""
              //     }${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["pulses_delivered"]
              //         ? ` and ${list_procedurals[i]["details"][ft]["pulses_delivered"]} pulses`
              //         : ""
              //     }${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["frequency"]
              //         ? ` and frequency of ${list_procedurals[i]["details"][ft]["frequency"]}`
              //         : ""
              //     }${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["post_stenosis"]
              //         ? `. The resultant post-stenosis was ${list_procedurals[i]["details"][ft]["post_stenosis"]}.`
              //         : ""
              //     }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              //   }
              // } else if (footerBody == "Balloon Angioplasty") {
              //   if (
              //     !data.includes(
              //       `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"dnkajn2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              //     )
              //   ) {
              //     footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"dnkajn2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              //     footerCheck += `{"key":"lnamcx${ft}${i}","text":"A ${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["product_description"]
              //         ? `${list_procedurals[i]["details"][ft]["product_description"]}`
              //         : ""
              //     } ${footerBody} balloon was utilized within the ${
              //       rowBody ? rowBody : ""
              //     }. ${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["atm"]
              //         ? `and insufflated at ${list_procedurals[i]["details"][ft]["atm"]} ATM`
              //         : ""
              //     }${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["inflation_time"]
              //         ? ` for a total of ${list_procedurals[i]["details"][ft]["inflation_time"]} minutes`
              //         : ""
              //     }${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["post_stenosis"]
              //         ? `. The resultant post-stenosis was ${list_procedurals[i]["details"][ft]["post_stenosis"]}.`
              //         : ""
              //     }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              //   }
              // } else if (
              //   footerBody == "Covered Stent" ||
              //   footerBody == "Balloon Expandable Stent" ||
              //   footerBody == "Self Expanding Stent" ||
              //   footerBody == "Balloon Expandable Covered Stent"
              // ) {
              //   if (
              //     !data.includes(
              //       `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"dnkajn2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              //     )
              //   ) {
              //     footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${rowBody.length},"style":"BOLD"},{"offset":0,"length":${rowBody.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"dnkajn2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              //     footerCheck += `{"key":"lnlajdn${ft}${i}","text":"A ${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["product_description"]
              //         ? `${list_procedurals[i]["details"][ft]["product_description"]}`
              //         : ""
              //     } ${footerBody} covered stent was utilized within ${
              //       rowBody ? `the ${rowBody}. ` : ""
              //     }${
              //       list_procedurals[i]["details"][ft] &&
              //       list_procedurals[i]["details"][ft]["post_stenosis"]
              //         ? `. The resultant post-stenosis was ${list_procedurals[i]["details"][ft]["post_stenosis"]}.`
              //         : ""
              //     }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              //   }
              // }
            }
          }
          if (
            footerBody == "Directional Atherectomy" ||
            footerBody == "Laser Atherectomy" ||
            footerBody == "Rotational Atherectomy" ||
            footerBody == "Obital Atherectomy" ||
            footerBody == "Covered Stent" ||
            footerBody == "Balloon Expandable Stent" ||
            footerBody == "Self Expanding Stent" ||
            footerBody == "Balloon Expandable Covered Stent" ||
            footerBody == "Balloon Angioplasty"
          ) {
            footerCheck += `{"key":"lmasjdv${i}","text":"${checkFooter}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        }

        //
        if (closureCheck.length > 0) {
          if (!data.includes(`${closureCheck}`)) {
            data = data.replace(
              `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${closureCheck}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        if (allUltrasound.length > 0) {
          if (!data.includes(allUltrasound)) {
            data = data.replace(
              `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${allUltrasound}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        //
        // if (allneedle.length > 0) {
        //   if (
        //     !data.includes(
        //       `${allneedle}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        //     )
        //   ) {
        //     data = data.replace(
        //       `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
        //       `${allneedle}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        //     );
        //   }
        // }
        if (
          headerCheck.length > 0 &&
          !data.includes(
            `${headerCheck}{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )
        ) {
          data = data.replace(
            `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${headerCheck}{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
        // header 10 INTERVENTION
        if (footerCheck.length > 0 && !data.includes(footerCheck)) {
          data = data.replace(
            `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${footerCheck}{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }
      //
      // for loot cpt header 4 PROCEDURE PERFORMED
      let cpt_text = "";
      if (dataCPT.length > 0) {
        cpt_text += `{"key":"txtcptspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        for (let i = 0; i < dataCPT.length; i += 1) {
          cpt_text += `{"key":"cpttxt${i}","text":"${dataCPT && dataCPT[i] && dataCPT[i]["cpt_code"]
            } ${dataCPT && dataCPT[i] && dataCPT[i]["short_desc"]
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }
      // header 4 PROCEDURE PERFORMED:
      const [header4, ...item4] =
        data &&
        data.split(
          `{"key":"pr98x","text":"PROCEDURE PERFORMED","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":19,"style":"BOLD"},{"offset":0,"length":19,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader4 = (item4 || []).map((item) => {
        return item.split(
          `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )[0];
      });
      if (checkHeader4 && checkHeader4[0] && checkHeader4[0] != "") {
        data = data.replace(
          `${checkHeader4[0]}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${cpt_text}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      } else {
        data = data.replace(
          `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${cpt_text}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      if (dataCPT.length == 0) {
        data = data.replace(
          `${checkHeader4[0]}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // header 6 COMPLICATIONS
      let complication = "";
      const [header6, ...item6] =
        data &&
        data.split(
          `{"key":"cop2i","text":"COMPLICATIONS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":13,"style":"BOLD"},{"offset":0,"length":13,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader6 = (item6 || []).map((item) => {
        return item.split(
          `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )[0];
      });
      if (complication_value && complication_value.length > 0) {
        complication = `{"key":"txt6empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"comlak3","text":"${complication_value}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        if (!data.includes(complication)) {
          data = data.replace(
            `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${complication}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
      }

      if (complication_value && complication_value.length == 0) {
        data = data.replace(
          `${checkHeader6[0]}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // for loot icd header 2 PREOPERATIVE DIAGNOSIS
      let icd_text = "";
      if (
        detail_procedure &&
        detail_procedure.icd_codes &&
        detail_procedure.icd_codes.length > 0
      ) {
        icd_text += `{"key":"txticd2space","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        for (let i = 0; i < detail_procedure.icd_codes.length; i += 1) {
          icd_text += `{"key":"icd2txt${i}","text":"${detail_procedure.icd_codes &&
            detail_procedure.icd_codes[i] &&
            detail_procedure.icd_codes[i]["icd_code"]
            } ${detail_procedure.icd_codes &&
            detail_procedure.icd_codes[i] &&
            detail_procedure.icd_codes[i]["icd_name"]
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }
      //
      // header 2 PREOPERATIVE DIAGNOSIS
      const [header2, ...item2] =
        data &&
        data.split(
          `{"key":"pd985","text":"PREOPERATIVE DIAGNOSIS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":22,"style":"BOLD"},{"offset":0,"length":22,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader2 = (item2 || []).map((item) => {
        return item.split(
          `{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )[0];
      });
      if (checkHeader2 && checkHeader2[0] && checkHeader2[0] != "") {
        data = data.replace(
          `${checkHeader2[0]}{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${icd_text}{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      } else {
        data = data.replace(
          `{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${icd_text}{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      if (
        detail_procedure &&
        detail_procedure.icd_codes &&
        detail_procedure.icd_codes.length == 0
      ) {
        data = data.replace(
          `${checkHeader2[0]}{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // for loot icd header 3 POSTOPERATIVE DIAGNOSIS
      let icd_text_h3 = "";
      if (
        detail_procedure &&
        detail_procedure.icd_codes &&
        detail_procedure.icd_codes.length > 0
      ) {
        icd_text_h3 += `{"key":"txticd3space","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        for (let i = 0; i < detail_procedure.icd_codes.length; i += 1) {
          icd_text_h3 += `{"key":"icd3txt${i}","text":"${detail_procedure.icd_codes &&
            detail_procedure.icd_codes[i] &&
            detail_procedure.icd_codes[i]["icd_code"]
            } ${detail_procedure.icd_codes &&
            detail_procedure.icd_codes[i] &&
            detail_procedure.icd_codes[i]["icd_name"]
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }
      // header 3 POSTOPERATIVE DIAGNOSIS
      const [header3, ...item3] =
        data &&
        data.split(
          `{"key":"pdx95","text":"POSTOPERATIVE DIAGNOSIS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":23,"style":"BOLD"},{"offset":0,"length":23,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader3 = (item3 || []).map((item) => {
        return item.split(
          `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )[0];
      });
      if (checkHeader3 && checkHeader3[0] && checkHeader3[0] != "") {
        data = data.replace(
          `${checkHeader3[0]}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${icd_text_h3}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      } else {
        data = data.replace(
          `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${icd_text_h3}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      if (
        detail_procedure &&
        detail_procedure.icd_codes &&
        detail_procedure.icd_codes.length == 0
      ) {
        data = data.replace(
          `${checkHeader3[0]}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      //
      const contentState = convertFromRaw(JSON.parse(data));
      const editorState = EditorState.createWithContent(contentState);
      changeValueText(data);
      setText(editorState);
    }
  }, [
    first,
    intra,
    list_procedurals,
    data_template,
    dataCPT,
    complication_value,
    detail_procedure,
  ]);

  useEffect(() => {
    set_data_template([...data_template_review]);
  }, [data_template_review]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="RichEditor-editor">
        <Editor
          toolbar={{
            options: ["inline", "list"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          editorStyle={{
            border: "1px solid #f2f2f2",
            padding: "10px",
            height: "100%",
            backgroundColor: "#fff",
          }}
          placeholder="Patient Review"
          editorState={text}
          onEditorStateChange={onChange}
        />
      </div>
    </div>
  );
};

export default ReviewText;
