import React from 'react';
import { StepSVG } from '../icons/SVGIcon';
import './style.scss'

const Stepper = ({ steps, currentStep, handleStep }) => {
  const onStepClick = stepIndex => () => {
    handleStep(stepIndex);
  };

  return (
    <div className='stepContainer'>
      {steps.length && steps.map((step, index) => (
        <div
          key={`step-${step}-${index}`}
          className={`stepper ${index <= currentStep ? 'active' : ''}`}
          onClick={onStepClick(index)}
        >
          <span className='stepLabel'>{step}</span>
          <StepSVG />
        </div>
      ))}
    </div>
  )
};

export default Stepper;
