import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import pdf2base64 from "pdf-to-base64";
import { CloseIcon } from "../../components/icons/SVGIcon";
import { MaleSVG } from "../../components/icons/FaxSVG";
import ScrollWrapper from "../../components/ScrollBar";
import SidebarTitle from "../../components/SidebarTitle";
import ImageList from "../PatientChart/ImageList";
import {
  getProviders,
  printPatientDocument,
  set_default_toast,
  reset_fax_scan_document_log,
  setGlobalLoading
} from "../../../store/actions";
import ToastMessage from "../../../common/components/Toast";

const EducationSideBar = React.forwardRef(({ resource, sidebarIcon, isShow, handleCloseSidebar }, ref) => {
  const documents = useSelector((state) => state.common.patientDocuments);
  const selectedDocument = useSelector(
    (state) => state.clinicProvider.selectedDocument
  );
  const print_patient_document = useSelector(
    (state) => state.patientRecords.print_patient_document
  );
  const [isToastr, setIsToastr] = useState(false);

  const loading_print = useSelector(
    (state) => state.patientRecords.loading_print_patient_document
  );
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const isToast = useSelector((state) => state.patientRecords.isToast);

  const [reset, setReset] = useState(true);
  const dispatch = useDispatch();
  const refs = useRef(null);
  const [printLink, setPrintLink] = useState("");

  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    dispatch(getProviders());
  }, []);

  useEffect(() => {
    if (isShow) {
      dispatch(setGlobalLoading(loading_print));
    }
  }, [loading_print, isShow]);

  useEffect(() => {
    if (selectedDocument) {
      setReset(true);
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    // console.log("loading documents");
    // console.log(documents);
  }, [documents]);

  useEffect(() => {
    if (isToast) {
      setIsToastr(true);
      setTimeout(() => {
        dispatch(set_default_toast());
        setIsToastr(false);
      }, 2000);
    }
  }, [isToast]);

  useEffect(() => {
    if (
      print_patient_document &&
      Object.keys(print_patient_document).length > 0
    ) {
      checkPrint();
    }
  }, [print_patient_document]);

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
      setTimeout(() => {
        setPrintLink("");
      }, 1000);
    }
  }, [printLink]);

  let printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize),
              byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
  
          byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    const contentType = "application/pdf",
    blob = b64toBlob(b64, contentType),
    blobUrl = URL.createObjectURL(blob);

    console.dir('new print');
    var iframe = null;

    iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        // setTimeout(iframe.contentWindow.print, 500);
        iframe.contentWindow.print();
      }, 500);
    };
  
    iframe.src = blobUrl;
  }

  const refreshImageList = () => {
    if (refs) {
      refs.refreshList();
    }
  };

  const checkPrint = async () => {
    const base64 = await pdf2base64(print_patient_document.url);
    setMessage("Print Successfully!");
    setIsToastr(true);
    setPrintLink(base64);
    await dispatch(reset_fax_scan_document_log());
    setTimeout(() => {
      setIsToastr(false);
      setMessage(null);
    }, 2000);
  };

  const sendPrint = async () => {
    if (selectedDocument && selectedDocument.id) {
      await dispatch(printPatientDocument(selectedDocument.id));
    } else {
      setMessage("Please Click Document You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const showToast = () => {
    return (
      <ToastMessage
        type="Document"
        status={status ? "success" : "failed"}
        msg={message ? message : "Update Successfully!"}
      />
    );
  };

  if (!isShow) {
    return null;
  }

  return (
    <>
      <div
        ref={ref}
        className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
      >
        {isToastr && !errorRest && showToast()}

        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>

          <SidebarTitle
            icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
            title={
              <span className="text-uppercase">Education Correspondence</span>
            }
            subTitle=""
          />

          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "2rem",
              height: "auto",
              display: "block",
            }}
          >
            <ImageList />

            <div
              className="resourceInfo d-flex"
              style={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginTop: "1.5rem",
              }}
            >
              <button className="common-btn outlined-btn">Email</button>
              <button className="common-btn" style={{ marginLeft: "0.5rem" }}>
                Print
              </button>
            </div>
          </div>
        </ScrollWrapper>
      </div>
    </>
  );
});

export default EducationSideBar;
