/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { CloseIcon, SummaryEmptySVG } from "../../components/icons/SVGIcon";
import { MaleSVG } from "../../components/icons/FaxSVG";
import SidebarTitle from "../../components/SidebarTitle";
import FormStepper from "../../components/FormStepper";
import { newPatientOptions } from "../../constants/initialData";
import ScrollWrapper from "../../components/ScrollBar";
import Demographics from "../PatientSnapshot/Demographics";
import AddNewPatient from "../PatientSnapshot/AddNewPatient";
import InsuranceDetail from "../PatientSnapshot/InsuranceDetail";
import MedicalHistory from "../PatientSnapshot/MedicalHistory";
import HIPDocumentation from "./HIPDocumentation";
import BillingAndCharges from "./BillingAndCharges";
import Notes from "../../../common/components/Notes";
import { validateSteps } from "../../../services/validations";
import {
  savePatientNotes,
  patientScheduleConfirmCheckin,
  setPatientSnapshotData,
  getOnlyProviders,
} from "../../../store/actions";
import Cookies from "universal-cookie";
import { Icon } from "antd";

const initialConditionsOne = [
  { step: "demographics", status: true },
  { step: "insurance", status: true },
  { step: "medical", status: true },
  { step: "HIPAA", status: true },
  { step: "notes", status: false },
];

const initialConditionsTwo = [
  { step: "demographics", status: true },
  { step: "insurance", status: true },
  { step: "HIPAA", status: true },
  { step: "billing", status: true },
  { step: "notes", status: false },
];

const formStepsOne = [
  {
    title: "Demographics",
    content: Demographics,
  },
  {
    title: "Insurance Detail",
    content: InsuranceDetail,
  },
  {
    title: "Medical History",
    content: MedicalHistory,
  },
  {
    title: "HIPAA Documentation",
    content: HIPDocumentation,
  },
  {
    title: "Notes",
    content: Notes,
  },
];

const formStepsTwo = [
  {
    title: "Demographics",
    content: Demographics,
  },
  {
    title: "Insurance Detail",
    content: InsuranceDetail,
  },
  {
    title: "HIPAA Documentation",
    content: HIPDocumentation,
  },
  {
    title: "Billing and Charges",
    content: BillingAndCharges,
  },
  {
    title: "Notes",
    content: Notes,
  },
];

const ResourceContainer = React.forwardRef(
  (
    {
      resource,
      isShow,
      handleCloseSidebar,
      paymentForm,
      refreshCalendar,
      showConfirm,
      hideMedication,
      isNormal,
      sidebarIcon,
      formSteps,
      initialConditions,
      sidebarTitle,
      hasSubHeader,
      detail_ultrasound_result,
      isFinding,
      ...restProps
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const [step, setStep] = useState(0);
    const [isAddForm, setIsAddForm] = useState(false);
    const [conditions, setConditions] = useState(
      hideMedication ? initialConditionsTwo : initialConditionsOne
    );
    const patient_all_insurances_id = useSelector(
      (state) => state.newPatient.patient_all_insurances_id
    );
    const stepData = useSelector((state) => ({
      demographics: resource,
      insurance: state.newPatient.insurance,
      medical: {
        ...state.newPatient.medical,
        previous_medical_history: resource?.icd_codes || [],
      },
    }));
    const detail_ultrasound_props = useSelector(
      (state) => state.common.detail_ultrasound_props
    );
    const patientsNote = useSelector((state) => state.newPatient.patientsNote);
    // const isAdded = useSelector((state) => state.patientPending.isAdded);
    const locations = useSelector((state) => state.common.locations);
    const providers = useSelector((state) => state.common.only_providers);
    const enterInsuranceCheck = useSelector(
      (state) => state.newPatient.enterInsuranceCheck
    );
    useEffect(() => {
      if (!providers) {
        dispatch(getOnlyProviders());
      }
    }, []);
    const [hideScope, setHideScope] = useState(false);
    const filteredLocations = useSelector(
      (state) => state.common.filteredLocations
    );
    const filteredProviders = useSelector(
      (state) => state.common.filteredProviders
    );
    const referralConditions = useSelector(
      (state) => state.common.referralConditions
    );
    const referredProviders = useSelector(
      (state) => state.common.referredProviders
    );
    const pcpList = useSelector((state) => state.common.pcpList);

    const scanCompareResult = useSelector(
      (state) => state.scanDriverLicense.scanCompareResult
    );
    const allNotes = useSelector((state) => state.patientSchedule.allNotes);
    const allNotesDeleted = useSelector(
      (state) => state.patientSchedule.allNotesDeleted
    );
    const isInsuranceVerified = useSelector(
      (state) => state.patientSchedule.isInsuranceVerified
    );

    const resourceCheck = useSelector(
      (state) => state.patientSnapshot.resource
    );

    const documents = useSelector((state) => state.hipaa.documents);

    const defaultFormSteps = hideMedication ? formStepsTwo : formStepsOne;

    const handleContinue = () => {};

    const procedure_id = cookies.get("current_appointment_id");

    useEffect(() => {
      if (resource) {
        dispatch(setPatientSnapshotData("resource", { ...resource }));
        let a = moment();
        let b = moment(resource?.date_of_birth);
        if (a.diff(b, "years") >= 62) {
          setHideScope(false);
        } else {
          if (
            Number(moment().format("MM")) < 10 &&
            Number(moment().format("MM")) > 2
          ) {
            setHideScope(true);
          } else {
            setHideScope(false);
          }
        }
      }
    }, [resource]);

    const onSubmit = () => {
      dispatch(savePatientNotes(resource.id, allNotes, allNotesDeleted));
      dispatch(patientScheduleConfirmCheckin(procedure_id));
      setTimeout(() => {
        refreshCalendar();
      }, 800);
    };

    if (scanCompareResult === "matched") {
      let newConditions = [...conditions];
      if (!newConditions[0].status) {
        newConditions[0].status = true;
        setConditions(newConditions);
      }
    }
    if (isInsuranceVerified) {
      let newConditions = [...conditions];
      if (!newConditions[1].status) {
        newConditions[1].status = true;
        setConditions(newConditions);
      }
    }

    useEffect(() => {
      if (resource && !initialConditions && !isFinding) {
        const newConditions = [...conditions];
        for (let i = 0; i < 4; i++) {
          const validate = validateSteps(
            stepData[conditions[i].step],
            newPatientOptions[conditions[i].step]
          );
          newConditions[i].status = validate;
        }
        if (resourceCheck) {
          if (
            resourceCheck["alcoholic_drink_pw"] != "" &&
            ((resourceCheck["allergies"] &&
              resourceCheck["allergies"].length > 0) ||
              resourceCheck["no_drug_allergies"] == true) &&
            resourceCheck["amputation"] != -1 &&
            (hideScope
              ? resourceCheck["pneumococcal"] != null ||
                resourceCheck["pneumococcal"] == null
              : resourceCheck["pneumococcal"] != -1) &&
            resourceCheck["aortic_disease"] != -1 &&
            ((resourceCheck["medications"] &&
              resourceCheck["medications"].length > 0) ||
              resourceCheck["no_medication"] == true) &&
            resourceCheck["icd_codes"] &&
            resourceCheck["icd_codes"].length > 0 &&
            resourceCheck["previous_smoking_ppd"] != "" &&
            resourceCheck["previous_surgical_history"] &&
            resourceCheck["previous_surgical_history"].length > 0 &&
            resourceCheck["smoking_ppd"] != "" &&
            resourceCheck["years_of_smoking"] != ""
          ) {
            newConditions[2].status = true;
          } else {
            newConditions[2].status = false;
          }
        }
        console.log(patient_all_insurances_id, "patient_all_insurances_id2");
        if (
          patient_all_insurances_id &&
          patient_all_insurances_id.some(
            (variable) =>
              variable.is_verified &&
              variable.is_verified === 1 &&
              variable.is_eligible &&
              variable.is_eligible === 1
          )
        ) {
          newConditions[1].status = true;
        } else {
          newConditions[1].status = false;
        }
        if (enterInsuranceCheck) {
          newConditions[1].status = true;
        }
        if (documents && documents.length > 0) {
          newConditions[3].status = true;
        } else {
          newConditions[3].status = false;
        }
        if (newConditions[4]) {
          if (patientsNote && patientsNote.length > 0) {
            newConditions[4].status = true;
          } else {
            newConditions[4].status = false;
          }
        }
        setConditions([...newConditions]);
      } else if (resource && initialConditions && !isFinding) {
        if (
          initialConditions &&
          initialConditions[0] &&
          initialConditions[0]["step"] != "clinical"
        ) {
          const newConditions = [...initialConditions];
          const validate = validateSteps(
            stepData["demographics"],
            newPatientOptions["demographics"]
          );
          newConditions[0].status = validate;
          if (resourceCheck) {
            if (
              resourceCheck["alcoholic_drink_pw"] != "" &&
              ((resourceCheck["allergies"] &&
                resourceCheck["allergies"].length > 0) ||
                resourceCheck["no_drug_allergies"] == true) &&
              resourceCheck["amputation"] != -1 &&
              (hideScope
                ? resourceCheck["pneumococcal"] != null ||
                  resourceCheck["pneumococcal"] == null
                : resourceCheck["pneumococcal"] != -1) &&
              resourceCheck["aortic_disease"] != -1 &&
              ((resourceCheck["medications"] &&
                resourceCheck["medications"].length > 0) ||
                resourceCheck["no_medication"] == true) &&
              resourceCheck["icd_codes"] &&
              resourceCheck["icd_codes"].length > 0 &&
              resourceCheck["previous_smoking_ppd"] != "" &&
              resourceCheck["previous_surgical_history"] &&
              resourceCheck["previous_surgical_history"].length > 0 &&
              resourceCheck["smoking_ppd"] != "" &&
              resourceCheck["years_of_smoking"] != ""
            ) {
              newConditions[1].status = true;
            } else {
              newConditions[1].status = false;
            }
          }
          if (newConditions[2]) {
            if (patientsNote && patientsNote.length > 0) {
              newConditions[2].status = true;
            } else {
              newConditions[2].status = false;
            }
          }
          setConditions([...newConditions]);
        }
      }
      if (isFinding) {
        const newConditions = [...initialConditions];
        if (detail_ultrasound_props?.disease_id) {
          newConditions[0].status = true;
        } else {
          newConditions[0].status = false;
        }
        if (
          detail_ultrasound_props?.result_r &&
          detail_ultrasound_props?.result_r.length &&
          detail_ultrasound_props?.result_r.length > 0 &&
          detail_ultrasound_props?.result_l &&
          detail_ultrasound_props?.result_l.length &&
          detail_ultrasound_props?.result_l.length > 0
        ) {
          newConditions[1].status = true;
        } else {
          newConditions[1].status = false;
        }
        if (
          detail_ultrasound_props?.conclusion_l &&
          detail_ultrasound_props?.conclusion_l.length &&
          detail_ultrasound_props?.conclusion_l.length > 0 &&
          detail_ultrasound_props?.conclusion_r &&
          detail_ultrasound_props?.conclusion_r.length &&
          detail_ultrasound_props?.conclusion_r.length > 0
        ) {
          newConditions[2].status = true;
        } else {
          newConditions[2].status = false;
        }
        if (newConditions[4]) {
          if (patientsNote && patientsNote.length > 0) {
            newConditions[4].status = true;
          } else {
            newConditions[4].status = false;
          }
        }
        setConditions([...newConditions]);
      }
    }, [
      isFinding,
      detail_ultrasound_props,
      resource,
      resourceCheck,
      step,
      detail_ultrasound_result,
      initialConditions,
      hideScope,
      enterInsuranceCheck,
      patient_all_insurances_id,
      patientsNote,
    ]);

    const patientAge = useMemo(() => {
      if (!resource || !Object.keys(resource).length) {
        return "";
      }
      return moment().diff(resource.date_of_birth, "years") + " yo";
    }, [resource]);

    const patientGender = useMemo(() => {
      if (!resource || !Object.keys(resource).length) {
        return "";
      }
      if (!resource.gender) {
        return "";
      }
      return " " + resource.gender.toUpperCase();
    }, [resource]);

    const isSubmitable = useMemo(() => {
      if (!conditions || !conditions.length) {
        return false;
      }
      return conditions.every((condition) => condition.status);
    }, [conditions]);

    if (!isShow) {
      return null;
    }

    if (!resource || !Object.keys(resource).length) {
      return (
        <div
          className={`resourceContainer ${isShow ? "show" : ""} ${
            hasSubHeader ? "has-sub-header" : ""
          }`}
        >
          <ScrollWrapper css="x-hidden">
            <div className="headerContainer">
              <button onClick={handleCloseSidebar}>
                <CloseIcon />
              </button>
            </div>
            {!isAddForm && (
              <div
                className="emptyContentIcon"
                style={{
                  width: "100%",
                  height: "calc(100% - 160px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SummaryEmptySVG />
              </div>
            )}
            {!isAddForm && (
              <div
                className="actionContent"
                style={{ marginTop: "2rem", paddingRight: "1rem" }}
              >
                <button onClick={() => setIsAddForm(true)}>
                  Add New Patient
                </button>
              </div>
            )}
            {isAddForm && (
              <SidebarTitle
                icon={sidebarIcon ? sidebarIcon : <MaleSVG />}
                title={
                  <span className="text-uppercase">
                    {isNormal
                      ? sidebarTitle
                        ? sidebarTitle.toUpperCase()
                        : "PATIENT DASHBOARD"
                      : "ADD NEW PATIENT"}
                  </span>
                }
                subTitle=""
              />
            )}
            {isAddForm && (
              <AddNewPatient
                handleClose={() => setIsAddForm(false)}
                referralConditions={referralConditions}
                pcpList={
                  pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList
                }
                providers={providers}
              />
            )}
          </ScrollWrapper>
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={`resourceContainer ${isShow ? "show" : ""} ${
          hasSubHeader ? "has-sub-header" : ""
        }`}
      >
        <ScrollWrapper css="x-hidden">
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          {resource ? (
            <>
              {resource.first_name !== "" && (
                <SidebarTitle
                  icon={
                    sidebarIcon ? (
                      sidebarIcon
                    ) : (
                      <Icon
                        type="snippets"
                        theme="twoTone"
                        twoToneColor="#740937"
                      />
                    )
                  }
                  title={
                    isNormal ? (
                      sidebarTitle ? (
                        sidebarTitle.toUpperCase()
                      ) : (
                        "PATIENT DASHBOARD"
                      )
                    ) : isAddForm ? (
                      "NEW PATIENT"
                    ) : (
                      <span className="text-uppercase">
                        {`${resource.first_name} ${resource.last_name} (`}
                        <span className="text-lowercase">{patientAge}</span>
                        {patientGender}
                        {`) (OPS ID: ${
                          resource.ops_patient_id
                            ? resource.ops_patient_id
                            : resource.id
                            ? resource.id
                            : ""
                        })`}
                      </span>
                    )
                  }
                  subTitle=""
                />
              )}

              <FormStepper
                {...restProps}
                steps={formSteps || defaultFormSteps}
                conditions={initialConditions || conditions}
                currentStep={step}
                handleStep={setStep}
                handleContinue={handleContinue}
                resource={resource}
                paymentForm={paymentForm}
                isScan={true}
                setOpenAddForm={setIsAddForm}
                isAddForm={isAddForm}
                locations={locations}
                providers={providers}
                filteredLocations={filteredLocations}
                filteredProviders={filteredProviders}
                referredProviders={referredProviders}
                referralConditions={referralConditions}
                pcpList={
                  pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList
                }
                sidebarTitle={sidebarTitle}
              />

              {resource.first_name !== "" && showConfirm && (
                <div
                  style={{
                    marginTop: "0.5rem",
                    paddingRight: "16px",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <button
                    className={"common-btn submit-btn"}
                    style={{ border: 0 }}
                    disabled={!isSubmitable}
                    onClick={onSubmit}
                  >
                    Confirm Check-In
                  </button>
                </div>
              )}

              <div style={{ width: "100%", paddingBottom: "2rem" }} />
            </>
          ) : null}
        </ScrollWrapper>
      </div>
    );
  }
);

export default ResourceContainer;
