import React, { useState, useEffect, useRef } from "react";
import { useDebounce } from "use-lodash-debounce";

import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import { getInventories } from "../../../services/api.services";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";

const OutgoingReferrals = () => {
  const tableRef = useRef(null)
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [priors, setPriors] = useState([]);

  const debounce = useDebounce(search, 200);

  useEffect(() => {
    if (debounce) {
      fetchData(debounce);
    } else {
      setSearchResult([]);
    }
  }, [debounce]);

  const addSearchResult = (value) => {
    setPriors([...priors, value]);
  };

  const fetchData = (debounce) => {
    getInventories(debounce)
      .then(result => {
        if (result) {
          setSearchResult([...result.others, ...result.narcotic, ...result.supplies]);
        }
      })
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          title="outgoing-referrals-Search"
          options={searchResult}
          handlePopulate={(value) =>
            addSearchResult(value)
          }
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Referrals"
          keyLabel="name"
        />
      </div>

      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        {priors && priors.length > 0 && (
          <DataTable
            title="outgoing-referrals"
            labels={["Name"]}
            widths={["100%"]}
            parentHeight={tableRef && tableRef.current && tableRef.current.clientHeight}
            columns={[{ sortable: true, key: "name" }]}
            handleDelete={(value, index) => console.log(index)}
            rowData={(priors || []).map((item, index) => {
              return {
                name: item.name,
              };
            })}
            emptyRows={new Array(4).fill(1)}
            sorts={[""]}
          />
        )}
      </div>
    </div>
  );
};

export default OutgoingReferrals;
