import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Cookies from "universal-cookie";

import ScrollWrapper from "../../components/ScrollBar";
import SnapshotSideBar from "./SnapSidebar";
import { LeftCharvenSVG } from "../../components/icons/SVGIcon";
import DiseaseBody from "../../components/DiseasBody";
import {
  clinicProviderPatientSnapshotLoadInit,
  setPatientSnapshotData,
  setGlobalLoading,
  selectPatient,
  patient_insurances_id_insurances,
  loadUltrasoundHistoryForCondition,
  patientExaminationLoadInit,
  getClinicWorkflowAppointments,
  setFirstLoadedSnapshot,
  setSidebarState,
} from "../../../store/actions";
import PatientOverviewList from "./PatientOverviewList";
import "./DiseaseBody.scss";
import SidebarExtension from "../../components/SidebarExtension";
import { Icon } from "antd";

const cookies = new Cookies();
const PatientOverview = ({ isClinic, sidebarIcon, patientResource, display, ...restProps }) => {
  const list = useSelector((state) => state.clinicProviderPatientSnapshot.list);
  const resource = useSelector((state) => state.newPatient.resource);
  const loadedSnapshot = useSelector((state) => state.patientSnapshot.loadedSnapshot);
  const dispatch = useDispatch();
  const [isSidebar, setIsSidebar] = useState(false);
  const [clickedCondition, setClickedCondition] = useState("");

  const current_patient_id = cookies.get("current_patient_id");
  const appointment_id = cookies.get("current_appointment_id");

  useEffect(() => {
    if (isClinic) {
      dispatch(patientExaminationLoadInit(appointment_id))
      dispatch(getClinicWorkflowAppointments({
        patient_id: current_patient_id,
        paging: 1,
      }));
    }
  }, [isClinic])


  useEffect(() => {
    if (patientResource) {
      if (!loadedSnapshot)
      {
        dispatch(setPatientSnapshotData("resource", { ...patientResource }));
        dispatch(setFirstLoadedSnapshot());
      }
      dispatch(clinicProviderPatientSnapshotLoadInit(patientResource.id));
      dispatch(patient_insurances_id_insurances(patientResource.id));
      setIsSidebar(true);
    }
  }, [patientResource]);

  useEffect(() => {
    if (restProps && restProps.loadingResource !== undefined) {
      dispatch(setGlobalLoading(restProps.loadingResource));
    }
  }, [restProps]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const onClickConditionPanel = async (condition) => {
    if (condition) {
      condition = condition.replace("collapse-", "");
      await dispatch(setGlobalLoading(true));
      await dispatch(loadUltrasoundHistoryForCondition(patientResource.id, condition));
      await dispatch(setGlobalLoading(false));
      setClickedCondition(_.snakeCase(condition));
    } else {
      setClickedCondition("");
    }
  };

  const opener = useRef();

  let conditionClassName = "";
  for (let condition_ of list) {
    conditionClassName += _.snakeCase(condition_["condition"]) + " ";
  }
  const isResource = !patientResource || !Object.keys(patientResource).length;
  const sidebarRef = useRef();
  return (
    <div
      style={{
        display: !display ? "flex" : display === "flex" ? "flex" : "none",
      }}
      className="clinicWorkflow__main-section"
    >
      <div className="eventsContainer has-sub-header">
        <div className="patientContainer">
          <div
            className={`imageSection disease-icon ${_.snakeCase(
              clickedCondition
            )}-clicked ${conditionClassName}`}
          >
            <DiseaseBody />
          </div>
          <div className="detailSection">
            <ScrollWrapper css="no-padding x-hidden">
              <PatientOverviewList
                onClickConditionPanel={onClickConditionPanel}
              />
            </ScrollWrapper>
          </div>
        </div>
      </div>
      <SnapshotSideBar
        patientResource={patientResource}
        sidebarIcon={sidebarIcon}
        isShow={isSidebar}
        isScan={restProps.isScan}
        handleCloseSidebar={handleCloseSidebar}
        ref={sidebarRef}
      />
      {!isResource && (
        <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
      )}
    </div>
  );
};

export default PatientOverview;
