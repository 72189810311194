import React, { useState, useEffect, useRef, useMemo } from "react";
import { Select } from "antd";

import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { useSelector, useDispatch } from "react-redux";
import {
  setProcedureDetails,
  getLocations,
  addProcedureDetails,
  updateDetailProcedureData,
} from "../../../store/actions";

const LocationsAndSupport = ({ resource }) => {
  const tableRef = useRef(null);
  const supportLocations = useSelector(
    (state) => state.procedureDetail.supportLocations
  );
  const providersSearch = useSelector((state) => state.common.locations);
  const [searchResult, setSearchResult] = useState([]);

  const detailProcedureData = useSelector(
    (state) => state.common.detailProcedureData
  );
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const updateProviders = async (type, index, value) => {
    if (type === "location") {
      if (!supportLocations.some((r) => r.location === value?.id)) {
        await dispatch(
          addProcedureDetails("supportLocations", {
            name_location: value.value,
            location: value.id,
            firstCase: "",
          })
        );
      }
    } else {
      supportLocations[index][type] = value;
      await dispatch(
        setProcedureDetails("supportLocations", [...supportLocations])
      );
    }
    callApiUpdate();
  };

  const removeProvider = async (index) => {
    supportLocations.splice(index, 1);
    await dispatch(
      setProcedureDetails("supportLocations", [...supportLocations])
    );
    callApiUpdate();
  };

  useEffect(() => {
    if (detailProcedureData) {
      const providerDetail = (detailProcedureData.locations || []).map((r) => {
        return {
          name_location: r.value,
          location: r.id,
          firstCase: `${r?.pivot?.is_first_case === 1 ? "yes" : "no"}`,
        };
      });
      dispatch(setProcedureDetails("supportLocations", [...providerDetail]));
    }
  }, [detailProcedureData]);

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  const callApiUpdate = () => {
    if (
      supportLocations &&
      detailProcedureData &&
      Object.keys(detailProcedureData).length > 0
    ) {
      const renderArray = supportLocations.map((r) => {
        return `"${r.location}":{"is_first_case":${r.firstCase === "yes" ? 1 : 0}}`;
      });
      dispatch(
        updateDetailProcedureData(detailProcedureData.id, {
          value: detailProcedureData?.value,
          sort: detailProcedureData?.sort,
          side: detailProcedureData?.side,
          is_first_case: detailProcedureData?.is_first_case,
          number_of_week_followup: detailProcedureData?.number_of_week_followup,
          range_between_procedure: detailProcedureData?.range_between_procedure,
          locations: `{${renderArray.join()}}`,
        })
      );
    }
  };

  useEffect(() => {
    const arrayFilter = [...providersSearch];
    const arraySucces = arrayFilter.filter((item) => {
      return item?.value.toLowerCase().includes(search.toLowerCase());
    });
    setSearchResult([...arraySucces]);
  }, [search]);

  const emptyRows = useMemo(() => {
    if (tableRef && tableRef.current) {
      const total = Math.floor(tableRef.current.clientHeight / 40);
      const count = supportLocations && supportLocations.length > 0 ? total - supportLocations.length : total;

      return new Array(count).fill(1);
    }
    return [];
  }, [tableRef, supportLocations]);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          title="Procedure-Location-Support"
          options={searchResult}
          handlePopulate={(value) =>
            updateProviders("location", supportLocations.length, value)
          }
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Locations"
          keyLabel="value"
        />
      </div>

      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="location_support"
          labels={["Location"]}
          widths={["100%"]}
          fullHeight={true}
          columns={[
            { sortable: true, key: "location" },
          ]}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(supportLocations || []).map((item, index) => {
            return {
              location: item.name_location,
            };
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>
    </div>
  );
};

export default LocationsAndSupport;
