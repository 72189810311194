import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import { Icon, Tooltip } from "antd";
import TopBar from "../../../common/components/SubTopBar";
import { CustomEvents } from "../../../common/components/CustomWeek";
import ClinicWorkflowSidebar from "../../../common/views/QuickRegisterSidebar";
import DayCalendarCheck from "./DayCalendarCheck";
import { StaticResource } from "../constant";
import { LeftCharvenSVG } from "../../../common/components/icons/SVGIcon";

import {
  getVhcLocations,
  getProviders,
  getPatientSchedules,
  getReferralConditions,
  setSelectedPatientResource,
  chooseSelectedPatientResourceByRandom,
  chooseSelectedPatientResource,
  searchPatientSchedules,
  selectPatient,
  get_patient_notes,
} from "../../../store/actions";
import SidebarExtension from "../../../common/components/SidebarExtension";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";

const resources = [
  {
    id: "angio",
    title: "OBL Procedure",
  },
];

const cookies = new Cookies();

class CheckIn extends Component {
  state = {
    isSidebar: false,
    events: [],
    resources: [],
    selectedCategories: [],
    searchValue: "",
    schedulesState: [],
    isSelectedPatient: false,
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = () => {
    this.props.getVhcLocations();
    this.props.getProviders();
    this.props.getReferralConditions();
    this.loadInitialPaymentJs();
    this.handleEvents(this.state.filters);
    const { patient_id } = this.props.location.state || "";
    if (patient_id) {
      this.setState({ isSidebar: window.innerWidth <= 768 ? false : true });
      this.props.chooseSelectedPatientResource(patient_id);
    }
  };

  loadInitialPaymentJs = () => {
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 0,
      only_procedures: 1,
      only_us: 0,
      only_angio: 1,
    });
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: searchPatientName });
    } else {
      this.setState({ schedulesState: this.props.schedules });
    }
  };

  onSelectResource = (patient_id) => {
    cookies.set("current_patient_id", patient_id);
    this.props.get_patient_notes(patient_id);
    this.props.selectPatient(patient_id);
    this.props.chooseSelectedPatientResource(patient_id);
    this.handleSidebar();
  };

  refreshCalendar = () => {
    const { date, location, provider_id } = this.state;
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 0,
      only_procedures: 1,
      only_us: 0,
      only_angio: 1,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.schedules) {
      this.setState({ schedulesState: nextProps.schedules });
    }
    const isSelectedPatientProps = nextProps.selectedPatientResource || {};
    if (Object.keys(isSelectedPatientProps).length > 0) {
      this.setState({
        isSelectedPatient: true,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  renderSideComponent = () => {
    const { selectedPatientResource, resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (
      resourceCheck.first_name +
      " " +
      resourceCheck.last_name
    ).toUpperCase();
    const age = resourceCheck.date_of_birth
      ? "(" +
        moment().diff(resourceCheck.date_of_birth, "years") +
        // " yo " +
        // (!!resourceCheck.gender
        //   ? resourceCheck.gender.toUpperCase()
        //   : "MALE") +
        ")"
      : "";

    const allerrgies = [];
    if (resourceCheck.allergies) {
      resourceCheck.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" ||
          allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >
        
        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "26px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && <br />}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip
              placement="bottomRight"
              title={resourceCheck.allergies
                .map((allergy) => allergy.allergy_name)
                .join(", ")}
            >
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>
      
        {resourceCheck.gender &&
        resourceCheck.gender.toUpperCase() == "MALE" ? (
          <MaleSVG
            style={{ marginLeft: "8px", height: "unset", width: "26px" }}
          />
        ) : (
          <FemaleSVG
            style={{ marginLeft: "8px", height: "unset", width: "26px" }}
          />
        )}
      </div>
    );
  };

  selectAppoinment = (id) => {
    cookies.set("current_appointment_id", id);
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar, isSelectedPatient } = this.state;
    const { selectedPatientResource } = this.props;

    return (
      <div className={`clinicWorkflow__main-page`}>
        <TopBar
          title="Check-In Module"
          isBarcode={isSelectedPatient}
          sideComponent={this.renderSideComponent()}
        />
        <div
          className={`clinicWorkflow__main-container ${
            isSidebar ? "openSidebar" : ""
          }`}
        >
          <div className="clinicWorkflow__main-section">
            <div className="eventsContainer">
              <div className="calendarContainer">
                <DayCalendarCheck
                  locations={this.props.locations || []}
                  providers={this.props.providers || []}
                  events={this.state.schedulesState || []}
                  slotProviders={this.props.scheduleProviders || []}
                  resources={resources}
                  loading={
                    this.props.loadingSchedules || this.props.loadingResource
                  }
                  onSelectAppointment={this.selectAppoinment}
                  fetchEvents={this.handleEvents}
                  onSearch={this.onSearch}
                  valueSearch={this.state.searchValue}
                  onSelectResource={this.onSelectResource}
                  calendarType="check-in"
                />
              </div>
            </div>
            <ClinicWorkflowSidebar
              ref={this.sidebarRef}
              sidebarIcon={<Icon type="copy" theme="twoTone" twoToneColor="#198038"/>}
              canCheckIn={true}
              resource={selectedPatientResource}
              isShow={isSidebar}
              handleCloseSidebar={this.handleCloseSidebar}
              paymentForm={window.SqPaymentForm}
              refreshCalendar={this.refreshCalendar}
              referralConditions={this.props.referralConditions || []}
              isNormal={true}
            />
            {selectedPatientResource &&
              !!Object.keys(selectedPatientResource).length && (
                <SidebarExtension
                  startingWidth="calc(28.3% - 20px + 0.3rem)"
                  sidebarReference={this.sidebarRef}
                  sidebarToggle={this.toggleSidebar}
                  sidebarState={isSidebar}
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  locations: state.common.vhcLocations,
  providers: state.common.providers,
  schedules: state.patientSchedule.schedules,
  scheduleProviders: state.patientSchedule.providers,
  referralConditions: state.common.referralConditions,
  loadingSchedules: state.patientSchedule.loadingSchedules,
  loadingResource: state.patientSchedule.loadingResource,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  resourceCheck: state.patientSnapshot.resource,
});

const mapDispatchToProps = {
  getVhcLocations,
  selectPatient,
  get_patient_notes,
  getProviders,
  getPatientSchedules,
  getReferralConditions,
  chooseSelectedPatientResourceByRandom,
  chooseSelectedPatientResource,
  searchPatientSchedules,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckIn));
