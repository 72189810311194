import React, { useState } from "react";
import { Input, Select, DatePicker, Slider, Dropdown, Menu } from "antd";
import {
  ExpandSVG,
  SearchSVG,
} from "../../../../common/components/icons/SVGIcon";

const { Search } = Input;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const MeaningfulUseFilter = ({
  valueSearch,
  providers,
  onChangeDate,
  onChangeProvider,
}) => {
  const [search, setSearch] = useState("");

  const changeSearch = (value) => {
    setSearch(value);
    valueSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
            placeholder="Search Patient"
            onChange={(e) => changeSearch(e.target.value)}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <DatePicker
            format={dateFormat}
            placeholder="Filter by DOS"
            suffixIcon={<ExpandSVG />}
            onChange={onChangeDate}
          />
        </div>
      </div>
    </div>
  );
};

export default MeaningfulUseFilter;
