import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../../../common/components/icons/SVGIcon";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import EditableText from "../../../../common/components/EditableText";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import pdf2base64 from "pdf-to-base64";
import { printPolicy } from "../../../../services/api.services";
import { Icon } from "antd";

const ManualPoliciesSidebar = React.forwardRef(({
  resource,
  isShow,
  handleCloseSidebar,
  policiesDetail,
}, ref) => {
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [openPrint, setPrintDialog] = useState(false);
  const [printLink, setPrintLink] = useState("");
  const [print_packet_status, setPrintStatus] = useState(0);

  useEffect(() => {
    if (policiesDetail) {
      setReset(true);
    }
  }, [policiesDetail]);

  const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
  const isSafari = (navigator.userAgent.indexOf('Safari') > -1) && !isChrome;

  const makeLi = (value) => {
    if (value && value.length > 0) {
      var result = value;
      const checkTagsUL = result.split("<ul>").length - 1;
      if (checkTagsUL && checkTagsUL > 0) {
        for (let index = 0; index < checkTagsUL; index++) {
          result = result.replace(
            "<ul>",
            `<ul style="list-style:disc;padding-left:15px;margin-left:35px;" >`
          );
        }
      }
      const checkTagsOL = result.split("<ol>").length - 1;
      if (checkTagsOL && checkTagsOL > 0) {
        for (let index = 0; index < checkTagsOL; index++) {
          result = result.replace("<ol>", `<ol style="padding-left:15px" >`);
        }
      }
      return result;
    }
    return null;
  };

  const handlePrint = async () => {
    const print = await printPolicy(policiesDetail.id);
    if (print.data && print.data.url) {
      const base64 = await pdf2base64(print.data.url);
      setPrintLink(base64);
      setPrintDialog(true);
      setPrintStatus(1);
    } else {
      setPrintStatus(2);
    }
  };

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
    }
  }, [printLink]);
  let printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize),
              byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
  
          byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    const contentType = "application/pdf",
    blob = b64toBlob(b64, contentType),
    blobUrl = URL.createObjectURL(blob);

    console.dir('new print');
    var iframe = null;

    iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        // setTimeout(iframe.contentWindow.print, 500);
        iframe.contentWindow.print();
      }, 500);
    };
  
    iframe.src = blobUrl;
  }

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  if (!isShow || !policiesDetail) {
    return null;
  }

  return (
    <div ref={ref} className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}>
      <ScrollWrapper css="x-hidden">
        <div className="headerContainer">
          <button onClick={handleCloseSidebar}>
            <CloseIcon />
          </button>
        </div>
        <SidebarTitle
          icon={<Icon type="bank" theme="twoTone" twoToneColor="#9F1853"/>}
          title={`Policy ${policiesDetail.policy_number} - ${policiesDetail.policy_name}`}
          subTitle=""
        />

        <div
          className={`resourceContent ${isSafari ? 'safari-browser' : 'no-safari'}`}
          style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
        >
          <div className="resourceInfo ol-ul">
            <b>Purpose: </b>
            <div
              dangerouslySetInnerHTML={{
                __html: makeLi(policiesDetail.purpose),
              }}
            />
          </div>
          <div className="resourceInfo ol-ul" style={{ marginTop: "0.5rem" }}>
            <b>Applies to: </b>
            <div
              dangerouslySetInnerHTML={{
                __html: makeLi(policiesDetail.apply_to),
              }}
            />
          </div>
          <div
            className="resourceInfo ol-ul d-flex"
            style={{ marginBottom: "1rem", marginTop: "0.5rem" }}
          >
            <div>
              <b>Policy: </b>
              <div
                dangerouslySetInnerHTML={{
                  __html: makeLi(policiesDetail.policy),
                }}
              />
            </div>
          </div>
          <div
            className="resourceInfo ol-ul d-flex"
            style={{ marginBottom: "1rem", marginTop: "0.5rem" }}
          >
            <div>
              <b>Procedures: </b>
              <div
                dangerouslySetInnerHTML={{
                  __html: makeLi(policiesDetail.procedures),
                }}
              />
            </div>
          </div>

          <div className="resourceInfo d-flex">
            <b style={{ minWidth: "35%" }}>Approved by: </b>
            <div style={{ marginLeft: "0.5rem" }}>
              {policiesDetail.approved_by}
            </div>
          </div>
          <div className="resourceInfo d-flex">
            <b style={{ minWidth: "35%" }}>Effective date: </b>
            <div style={{ marginLeft: "0.5rem" }}>{"11/1/2015"}</div>
          </div>
        </div>
        <div
          className="actionContent"
          style={{ marginTop: "2rem", paddingRight: "1rem", paddingBottom: '2rem' }}
        >
          <button onClick={handlePrint}>Print</button>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default ManualPoliciesSidebar;
