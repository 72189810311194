import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import {
  CloseIcon,
} from "../../components/icons/SVGIcon";
import SidebarTitle from "../../components/SidebarTitle";
import ScrollWrapper from "../../components/ScrollBar";
import { MaleSVG } from "../../components/icons/FaxSVG";
import EditableMaterialText from "../../components/EditableText/MaterialInput";

const MedicationSidebar = React.forwardRef(({
  resource,
  isShow,
  sidebarIcon,
  handleCloseSidebar,
}, ref) => {
  const [reset, setReset] = useState(true);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const authId = cookies.get("cvai-current-user");

  if (!isShow) {
    return null;
  }

  return (
    <>
      <div
        ref={ref}
        className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
      >
        <ScrollWrapper>
          <div className="headerContainer">
            <button onClick={handleCloseSidebar}>
              <CloseIcon />
            </button>
          </div>
          <SidebarTitle icon={sidebarIcon ? sidebarIcon : <MaleSVG />} title={<span className="text-uppercase">MEDICATIONS</span>} subTitle="" />

          <div
            className="resourceContent"
            style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}
          >
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Position"}
                noEditable={true}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableMaterialText
                reset={reset}
                label={"Start Date / Time"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableMaterialText
                reset={reset}
                label={"End Date / Time"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableMaterialText
                reset={reset}
                label={"Hours"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableMaterialText
                reset={reset}
                label={"Reason"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Number of Hours"}
                noEditable={true}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Vacation Hours Remaining"}
                noEditable={true}
              />
            </div>
          </div>
          <div
            className="actionContent"
            style={{ minHeight: "0px", marginTop: 0 }}
          />
        </ScrollWrapper>
      </div>
    </>
  );
});

export default MedicationSidebar;
