import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Input, Select, Checkbox, DatePicker, TimePicker } from "antd";
import {
  ExpandSVG,
  LeftCharvenSVG,
} from "../../../common/components/icons/SVGIcon";
import YesNo from "../../../common/components/YesNo";
import {
  getFollowUp,
  getTimeSlots,
  saveProcedureSchedule,
  setProcedureData,
  setGlobalLoading,
  getProcedurePending,
  setStepData,
  getProcedureEvents,
} from "../../../store/actions";

const { Option } = Select;

const getValue = (options, target, key) => {
  const opt = (options || []).find(
      (option) => option[key].toString() === target.toString()
  );
  if (opt) {
    return opt.value;
  }
  return "";
};

const FollowupAppointmentStepComponent = ({ handleContinue }) => {
  const locations = useSelector((state) => state.procedure.locations);
  const providers = useSelector((state) => state.procedure.providers);
  const clincLocations = useSelector((state) => state.procedure.clincLocations);
  const followupProviders = useSelector(
      (state) => state.procedure.followupProviders
  );
  const resource = useSelector((state) => state.procedure.resource);
  const loading = useSelector((state) => state.procedure.loadingSchedule);
  const planDetails = useSelector((state) => state.procedure.planDetails);
  const timeSlots = useSelector((state) => state.procedure.timeSlots);
  const followupSlots = useSelector((state) => state.procedure.followupSlots);
  const loadingFirst = useSelector((state) => state.procedure.loadingTimeSlots);
  const loadingSecond = useSelector((state) => state.procedure.loadingFollowUp);
  const firstClinic = useSelector((state) => state.procedure.timeSlot);
  const secondClinic = useSelector((state) => state.procedure.followSlot);

  const [endarterectomy, setEndarterectomy] = useState([]);
  const [follow_Up, setFollow_Up] = useState([]);

  const [activeCustom, setActiveCustom] = useState({
    first: false,
    second: false,
  });

  const [customAppointment, setCustomAppointment] = useState({
    first: {},
    second: {},
  });

  const dispatch = useDispatch();

  const [timeOption, setTimeOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });
  const [followOption, setFollowOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });
  const [questions, setQuestions] = useState({
    taking_metformin: -1,
    taking_eliquis: -1,
    taking_coumadin: -1,
    taking_plavix: -1,
    receive_coronary_stent: -1,
  });

  const saveAllDate = (type) => {
    if (type === "time") {
      if (timeSlots && timeSlots[0]) {
        const checkTimeSlots = timeSlots[0];
        if (checkTimeSlots) {
          if (
              endarterectomy &&
              !endarterectomy.some((r) => r.start === checkTimeSlots.start)
          ) {
            setEndarterectomy([...endarterectomy, checkTimeSlots]);
          }
        }
      }
    }

    if (type === "follow") {
      if (followupSlots && followupSlots[0]) {
        const checkFollowupSlots = followupSlots[0];
        if (checkFollowupSlots) {
          if (
              follow_Up &&
              !follow_Up.some((r) => r.start === checkFollowupSlots.start)
          ) {
            setFollow_Up([...follow_Up, checkFollowupSlots]);
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingFirst || loadingSecond));
  }, [loading, loadingFirst, loadingSecond]);

  const title = useMemo(() => {
    if (planDetails && planDetails.procedure) {
      return planDetails.procedure;
    }
    return "Procedure";
  }, [planDetails]);

  const setFiirst = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };
  const setSecond = (index) => {
    dispatch(setProcedureData("followSlot", index));
  };

  const onSaveSchedule = async (
      questionsCheck,
      firstClinicCheck,
      timeSlotsCheck,
      secondClinicCheck,
      followupSlotsCheck
  ) => {
    const date = moment(new Date()).format("MM/DD/YYYY");
    const timeSlot =
        firstClinicCheck > -1
            ? timeSlotsCheck[firstClinicCheck]
            : timeSlotsCheck && timeSlotsCheck[0]
            ? timeSlotsCheck[0]
            : "";
    const followup =
        secondClinicCheck > -1
            ? followupSlotsCheck[secondClinicCheck]
            : followupSlotsCheck && followupSlotsCheck[0]
            ? followupSlotsCheck[0]
            : "";
    const params = {
      procedure_date: timeSlot && moment(timeSlot.start).format("MM/DD/YYYY"),
      procedure_time: timeSlot && moment(timeSlot.start).format("HH:mm A"),
      procedure_location: timeSlot && timeSlot.location,
      procedure_provider: timeSlot && timeSlot.provider,
      followup_date: followup && moment(followup.start).format("MM/DD/YYYY"),
      followup_time: followup && moment(followup.start).format("HH:mm A"),
      followup_location: followup && followup.location,
      followup_provider: followup && followup.provider,
      ...questionsCheck,
    };

    if (params.procedure_date && params.followup_date) {
      await dispatch(setProcedureData("loadingSchedule", true));
      await dispatch(saveProcedureSchedule(resource.id, params));
      await dispatch(getProcedurePending({}));
      await dispatch(setStepData("loadingEvents", true));
      await dispatch(getProcedureEvents({ date }));
    }
  };

  useEffect(() => {
    if (!loading && !loadingFirst && !loadingSecond) {
      setTimeout(() => {
        onSaveSchedule(
            questions,
            firstClinic,
            timeSlots,
            secondClinic,
            followupSlots
        );
      }, 400);
    }
  }, [questions, firstClinic, secondClinic, timeSlots, followupSlots]);

  const handleChangeSlots = (type) => (value) => {
    const prev_date =
        type == "location" || type == "provider"
            ? ""
            : firstClinic !== -1
            ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
            : timeSlots[0]
                ? moment(timeSlots[0].start).format("MM/DD/YYYY")
                : "";
    const newOption = { ...timeOption, prev_date, [type]: value };
    setTimeOption(newOption);
    dispatch(setProcedureData("loadingTimeSlots", true));
    dispatch(getTimeSlots(resource.patient_id, resource.id, newOption));
    setFiirst(-1);
  };

  const handleChangeFollow = (type) => (value) => {
    const prev_date =
        type == "location" || type == "provider"
            ? ""
            : secondClinic !== -1
            ? moment(followupSlots[secondClinic].start).format("MM/DD/YYYY")
            : followupSlots[0]
                ? moment(followupSlots[0].start).format("MM/DD/YYYY")
                : timeSlots[firstClinic]
                    ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
                    : "";
    const newOption = { ...followOption, prev_date, [type]: value };
    setFollowOption(newOption);
    dispatch(setProcedureData("loadingFollowUp", true));
    dispatch(getFollowUp(resource.patient_id, resource.id, newOption));
    setSecond(-1);
  };

  const selectTimeSlot = (row, index) => {
    setFollow_Up([]);
    const newOption = {
      location: row.location,
      provider: row.provider,
      prev_date: moment(row.start).format("MM/DD/YYYY"),
      period: moment(row.start).format("A"),
    };
    setFiirst(index);
    dispatch(setProcedureData("loadingFollowUp", true));
    dispatch(
        getFollowUp(
            resource.patient_id,
            resource.id,
            {
              ...followOption,
              prev_date: moment(row.start).format("MM/DD/YYYY"),
            },
            false,
            "true"
        )
    );
  };

  const handleMoreDates = (type) => {
    saveAllDate(type);
    if (timeSlots && timeSlots.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(timeSlots[timeSlots.length - 1].start).format(
            "MM/DD/YYYY"
        ),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(
          getTimeSlots(resource.patient_id, resource.id, option, false, false)
      );
      setFiirst(-1);
    }
    if (followupSlots && followupSlots.length > 0 && type === "follow") {
      const newOption = {
        ...followOption,
        prev_date: moment(followupSlots[followupSlots.length - 1].start).format(
            "MM/DD/YYYY"
        ),
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(
          getFollowUp(resource.patient_id, resource.id, newOption, false, false)
      );
      setSecond(-1);
    }
  };

  const previous = (type) => {
    if (endarterectomy.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(
            endarterectomy[endarterectomy.length - 1].start
        ).format("MM/DD/YYYY"),
      };
      //
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource.id, option));
      setFiirst(-1);
      const aa = endarterectomy;
      aa.splice(endarterectomy.length - 1, 1);
      setEndarterectomy(aa);
    }
    if (follow_Up.length > 0 && type === "follow") {
      const newOption = {
        ...followOption,
        prev_date: moment(follow_Up[follow_Up.length - 1].start).format(
            "MM/DD/YYYY"
        ),
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(getFollowUp(resource.patient_id, resource.id, newOption));
      setSecond(-1);
      const aa = follow_Up;
      aa.splice(follow_Up.length - 1, 1);
      setFollow_Up(aa);
    }
  };

  const handleCustomChange = (idx, type, value) => {
    const customAppointments = { ...customAppointment };
    customAppointments[idx][type] = value;
    setCustomAppointment(customAppointments);
  };

  const toggleCustom = (type) => {
    const isCustomArray = { ...activeCustom };
    const customAppointments = { ...customAppointment };
    isCustomArray[type] = !isCustomArray[type];
    customAppointments[type] = isCustomArray[type]
        ? {
          location: "",
          date: "",
          time: "",
          provider: "",
        }
        : {};

    setActiveCustom(isCustomArray);
    setCustomAppointment(customAppointments);
  };

  const emptySlots =
      timeSlots && timeSlots.length && timeSlots.length < 3
          ? new Array(3 - timeSlots.length).fill("empty")
          : !timeSlots || !timeSlots.length
          ? new Array(3).fill("empty")
          : [];
  const emptyFollows =
      followupSlots && followupSlots.length && followupSlots.length < 3
          ? new Array(3 - followupSlots.length).fill("empty")
          : !followupSlots || !followupSlots.length
          ? new Array(3).fill("empty")
          : [];

  return (
      <>
        <div className="resourceContent pr-7px" style={{ position: "relative" }}>

          {activeCustom["first"] && (
              <div
                  className="resourceInfo between mt-2 sortableHeader"
                  style={{ background: "#f4f7fb" }}
              >
                <div
                    className={`th sortable w-25 hide-icon`}
                    style={{
                      paddingLeft: "2px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                >
                  <Select
                      placeholder="Location"
                      value={customAppointment["first"].location || undefined}
                      suffixIcon={<span />}
                      allowClear={false}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        background: "transparent",
                        borderBottom: 0,
                        boxShadow: "none",
                      }}
                      onChange={(value) =>
                          handleCustomChange("first", "location", value)
                      }
                  >
                    {(locations || []).map((loc, index) => (
                        <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                          {loc.value}
                        </Option>
                    ))}
                  </Select>
                </div>

                <div
                    className={`th sortable w-25 hide-icon`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      paddingLeft: 0,
                      borderLeft: "1px solid #dfe3e6",
                    }}
                >
                  <DatePicker
                      onChange={(value) => {
                        handleCustomChange(
                            "first",
                            "date",
                            moment(value).format("MM/DD/YYYY")
                        );
                      }}
                      style={{
                        width: "100%",
                        background: "transparent",
                        boxShadow: "none",
                        borderBottom: 0,
                      }}
                      format={"MM/DD/YYYY"}
                      placeholder="Date"
                      suffixIcon={<ExpandSVG />}
                  />
                </div>

                <div
                    className={`th sortable w-25 hide-icon`}
                    style={{
                      paddingLeft: "2px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                >
                  <TimePicker
                      format={"h:mm A"}
                      use12Hours
                      placeholder="Time"
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        background: "transparent",
                        boxShadow: "none",
                        borderBottom: 0,
                      }}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                      minuteStep={15}
                      onChange={(value) =>
                          handleCustomChange(
                              "first",
                              "time",
                              moment(value).format("h:mm A")
                          )
                      }
                  />
                </div>

                <div
                    className={`th sortable w-25 hide-icon`}
                    style={{
                      paddingLeft: "2px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                >
                  <Select
                      placeholder="Provider"
                      value={customAppointment["first"].provider || undefined}
                      suffixIcon={<span />}
                      allowClear={false}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        background: "transparent",
                        borderBottom: 0,
                        boxShadow: "none",
                      }}
                      onChange={(value) =>
                          handleCustomChange("first", "provider", value)
                      }
                  >
                    {(providers || []).map((provider, index) => (
                        <Option
                            key={`provider-${provider.id}-${index}`}
                            value={provider.id}
                        >
                          {provider.value}
                        </Option>
                    ))}
                  </Select>
                </div>
              </div>
          )}

          <div
              className="resourceInfo d-flex"
              style={{ justifyContent: "space-between", marginTop: "24px" }}
          >
            <b>{`${title} Follow-Up`}</b>
            <button
                className="text-btn"
                style={{ marginLeft: "1rem" }}
                onClick={() => toggleCustom("second")}
            >
              {`Custom ${activeCustom["second"] ? "-" : "+"}`}
            </button>
          </div>

          <div style={{ marginBottom: "4px" }}>
            <div
                className="resourceInfo between mt-2 sortableHeader"
                style={{ background: "#f4f7fb" }}
            >
              <div
                  className={`th sortable w-25 hide-icon`}
                  style={{
                    paddingLeft: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderLeft: "1px solid #dfe3e6",
                  }}
              >
                <Select
                    placeholder="Location"
                    value={followOption.location || undefined}
                    suffixIcon={<span />}
                    allowClear={false}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      background: "transparent",
                      borderBottom: 0,
                      boxShadow: "none",
                    }}
                    onChange={handleChangeFollow("location")}
                >
                  {(clincLocations || []).map((loc, index) => (
                      <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                        {loc.value}
                      </Option>
                  ))}
                </Select>
              </div>

              <div
                  className={`th sortable w-25 hide-icon`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderLeft: "1px solid #dfe3e6",
                  }}
              >
                Date
                <div>
                  <button
                      className="more-dates backward"
                      style={{
                        marginRight: "0.5rem",
                        padding: "2px",
                        minWidth: "unset",
                        width: "auto",
                        height: "auto",
                        border: "none",
                        background: "transparent",
                        color: "#3d70be",
                        marginTop: 0,
                      }}
                      onClick={() => {
                        previous("follow");
                      }}
                  >
                    <LeftCharvenSVG />
                  </button>
                  <button
                      className="more-dates forward"
                      style={{
                        padding: "2px",
                        minWidth: "unset",
                        width: "auto",
                        height: "auto",
                        border: "none",
                        background: "transparent",
                        color: "#3d70be",
                        marginTop: 0,
                      }}
                      onClick={() => {
                        handleMoreDates("follow");
                      }}
                  >
                    <LeftCharvenSVG />
                  </button>
                </div>
              </div>

              <div
                  className={`th sortable w-25 hide-icon`}
                  style={{
                    paddingLeft: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderLeft: "1px solid #dfe3e6",
                  }}
              >
                <Select
                    placeholder="Time"
                    value={followOption.period || undefined}
                    suffixIcon={<span />}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      background: "transparent",
                      borderBottom: 0,
                      boxShadow: "none",
                    }}
                    onChange={handleChangeFollow("period")}
                >
                  <Option value="am">AM</Option>
                  <Option value="pm">PM</Option>
                </Select>
              </div>

              <div
                  className={`th sortable w-25 hide-icon`}
                  style={{
                    paddingLeft: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderLeft: "1px solid #dfe3e6",
                  }}
              >
                <Select
                    placeholder="Provider"
                    value={followOption.provider || undefined}
                    suffixIcon={<span />}
                    allowClear={false}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      background: "transparent",
                      borderBottom: 0,
                      boxShadow: "none",
                    }}
                    onChange={handleChangeFollow("provider")}
                >
                  {(followupProviders || []).map((provider, index) => (
                      <Option
                          key={`provider-${provider.id}-${index}`}
                          value={provider.id}
                      >
                        {provider.value}
                      </Option>
                  ))}
                </Select>
              </div>
            </div>
            {(followupSlots || []).map((slot, index) => (
                <React.Fragment key={`time-slots-${index}`}>
                  <div
                      className={`resourceInfo between t-row ${
                          secondClinic === index ? "active" : ""
                      }`}
                      style={{ alignItems: "center" }}
                      onClick={() => setSecond(index)}
                  >
                    <div className="w-25">
                      {getValue(clincLocations, slot.location, "id")}
                    </div>
                    <div className="w-25">
                      {slot.start && moment(slot.start).format("MM/DD/YYYY")}
                    </div>
                    <div className="w-25">
                      {slot.start && moment(slot.start).format("HH:mm A")}
                    </div>
                    <div className="w-25">
                      {getValue(followupProviders, slot.provider, "id")}
                    </div>
                  </div>
                  <hr className="small-block" />
                </React.Fragment>
            ))}
            {emptyFollows.map((schedule, index) => (
                <React.Fragment key={`following-schedule-${schedule}-${index}`}>
                  <div className={`resourceInfo between t-row`} />
                  <hr className="small-block" />
                </React.Fragment>
            ))}
          </div>

          {activeCustom["second"] && (
              <div
                  className="resourceInfo between mt-2 sortableHeader"
                  style={{ background: "#f4f7fb" }}
              >
                <div
                    className={`th sortable w-25 hide-icon`}
                    style={{
                      paddingLeft: "2px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                >
                  <Select
                      placeholder="Location"
                      value={customAppointment["second"].location || undefined}
                      suffixIcon={<span />}
                      allowClear={false}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        background: "transparent",
                        borderBottom: 0,
                        boxShadow: "none",
                      }}
                      onChange={(value) =>
                          handleCustomChange("second", "location", value)
                      }
                  >
                    {(clincLocations || []).map((loc, index) => (
                        <Option key={`location-${loc.id}-${index}`} value={loc.id}>
                          {loc.value}
                        </Option>
                    ))}
                  </Select>
                </div>

                <div
                    className={`th sortable w-25 hide-icon`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      paddingLeft: 0,
                      borderLeft: "1px solid #dfe3e6",
                    }}
                >
                  <DatePicker
                      onChange={(value) => {
                        handleCustomChange(
                            "second",
                            "date",
                            moment(value).format("MM/DD/YYYY")
                        );
                      }}
                      style={{
                        width: "100%",
                        background: "transparent",
                        boxShadow: "none",
                        borderBottom: 0,
                      }}
                      format={"MM/DD/YYYY"}
                      placeholder="Date"
                      suffixIcon={<ExpandSVG />}
                  />
                </div>

                <div
                    className={`th sortable w-25 hide-icon`}
                    style={{
                      paddingLeft: "2px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                >
                  <TimePicker
                      format={"h:mm A"}
                      use12Hours
                      placeholder="Time"
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        background: "transparent",
                        boxShadow: "none",
                        borderBottom: 0,
                      }}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                      minuteStep={15}
                      onChange={(value) =>
                          handleCustomChange(
                              "second",
                              "time",
                              moment(value).format("h:mm A")
                          )
                      }
                  />
                </div>

                <div
                    className={`th sortable w-25 hide-icon`}
                    style={{
                      paddingLeft: "2px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                >
                  <Select
                      placeholder="Provider"
                      value={customAppointment["second"].provider || undefined}
                      suffixIcon={<span />}
                      allowClear={false}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        background: "transparent",
                        borderBottom: 0,
                        boxShadow: "none",
                      }}
                      onChange={(value) =>
                          handleCustomChange("second", "provider", value)
                      }
                  >
                    {(followupProviders || []).map((provider, index) => (
                        <Option
                            key={`provider-${provider.id}-${index}`}
                            value={provider.id}
                        >
                          {provider.value}
                        </Option>
                    ))}
                  </Select>
                </div>
              </div>
          )}

          <div
              className="actionContent"
              style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}
          />
        </div>
      </>
  );
};

export default FollowupAppointmentStepComponent;
