import React from 'react';
import './style.scss';

const CardBlock = ({
  varient,
  title,
  other,
  children,
  style,
  contentPadding,
  subAction,
  hasTitle = true,
  onClick = () => {}
}) => {
  return (
    <div onClick={onClick} className={`cardBlock ${varient || 'default'} ${other || ''}`} style={style}>
      {hasTitle && (<div className="cardBlock__title">{title}</div>)}
      {subAction && <div className="cardBlock__subAction">{subAction}</div>}
      <div className="cardBlock__content" style={{ paddingTop: contentPadding || '1.5rem' }}>{children}</div>
    </div>
  );
};

export default CardBlock;
