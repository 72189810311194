import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import { useDebounce } from "use-lodash-debounce";

import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import {
  setProcedureDetails,
  getPrimaryProviderDetails,
  addProcedureDetails,
  updateDetailProcedureData,
  getProviders,
} from "../../../store/actions";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";

const ProcedureTimes = [
  { value: 30, label: "30 mins" },
  { value: 60, label: "60 mins" },
  { value: 90, label: "90 mins" },
  { value: 120, label: "120 mins" },
  { value: 150, label: "150 mins" },
  { value: 180, label: "180 mins" },
  { value: 210, label: "210 mins" },
  { value: 240, label: "240 mins" },
  { value: 270, label: "270 mins" },
  { value: 300, label: "300 mins" },
];

const ProcedureProviders = ({ resource }) => {
  const tableRef = useRef(null);
  const providersState = useSelector(
    (state) => state.procedureDetail.providers
  );
  const detailProcedureData = useSelector(
    (state) => state.common.detailProcedureData
  );
  const dispatch = useDispatch();

  const updateProviders = async (type, index, value) => {
    if (type === "physician") {
      await dispatch(
        addProcedureDetails("providers", {
          physician_name: value?.value,
          physician: value?.id,
          duration: "",
        })
      );
    } else {
      providersState[index][type] = value;
      await dispatch(setProcedureDetails("providers", [...providersState]));
    }
    callApiUpdate();
  };
  const removeProvider = async (index) => {
    providersState.splice(index, 1);
    await dispatch(setProcedureDetails("providers", [...providersState]));
    callApiUpdate();
  };

  useEffect(() => {
    if (detailProcedureData) {
      const providerDetail = (detailProcedureData.providers || []).map((r) => {
        return {
          physician: r.id,
          physician_name: r.value,
          duration: r?.pivot?.interval === 0 ? undefined : r?.pivot?.interval,
        };
      });
      dispatch(setProcedureDetails("providers", [...providerDetail]));
    }
  }, [detailProcedureData]);

  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const providers = useSelector((state) => state.common.providers);

  useEffect(() => {
    if (search && search.length > 0) {
      const checkfilter = providers.filter((r) =>
        r.value.toLowerCase().includes(search.toLowerCase())
      );
      setSearchResult(checkfilter);
    } else {
      setSearchResult(providers);
    }
  }, [search]);

  useEffect(() => {
    if (providers) {
      setSearchResult(providers);
    }
  }, [providers]);


  const handlePopulate = (value) => {
    const name =
      !value.first_name && !value.last_name
        ? ""
        : `${value.first_name} ${value.last_name}`;
    // TODO: integrate with datatable
  };

  useEffect(() => {
    dispatch(getProviders());
  }, []);

  const callApiUpdate = () => {
    if (
      providersState &&
      detailProcedureData &&
      Object.keys(detailProcedureData).length > 0
    ) {
      const renderArray = providersState.map((r) => {
        return `"${r.physician}":{"interval":${r.duration}}`;
      });
      dispatch(
        updateDetailProcedureData(detailProcedureData.id, {
          value: detailProcedureData?.value,
          sort: detailProcedureData?.sort,
          side: detailProcedureData?.side,
          is_first_case: detailProcedureData?.is_first_case,
          number_of_week_followup: detailProcedureData?.number_of_week_followup,
          range_between_procedure: detailProcedureData?.range_between_procedure,
          providers: `{${renderArray.join()}}`,
        })
      );
    }
  };

  const emptyRows = useMemo(() => {
    if (tableRef && tableRef.current) {
      const total = Math.floor(tableRef.current.clientHeight / 40);
      const count =
        providersState && providersState.length > 0
          ? total - providersState.length
          : total;

      return new Array(count).fill(1);
    }
    return [];
  }, [tableRef, providersState]);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          title="Procedure-Provider-Search"
          options={searchResult}
          handlePopulate={(value) =>
            updateProviders("physician", providersState.length, value)
          }
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Providers"
          keyLabel="value"
        />
      </div>

      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="procedure_provider"
          labels={["Provider", "Duration"]}
          widths={["50%", "50%"]}
          fullHeight={true}
          columns={[
            { sortable: true, key: "provider" },
            { sortable: true, key: "duration" },
          ]}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(providersState || []).map((item, index) => {
            return {
              provider: item.physician_name,
              duration: (
                <Select
                  placeholder="Duration"
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  value={item.duration}
                  onChange={(value) => {
                    updateProviders("duration", index, value);
                  }}
                  style={{ width: "100%", borderBottom: 0 }}
                >
                  {ProcedureTimes.map((r) => (
                    <Select.Option key={r.value} value={r.value}>
                      {r.label}
                    </Select.Option>
                  ))}
                </Select>
              ),
            };
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>
    </div>
  );
};

export default ProcedureProviders;
