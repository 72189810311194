import React, { useEffect, useState, useRef } from "react";
import { Collapse, Icon, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  AtherectomyDirectionalIcon,
  AtherectomyLaserIcon,
  AtherectomyRotationalIcon,
  AtherectomyTransluminalIcon,
  BalloonIcon,
  IVUSIcon,
  UltrasoundProbeIcon,
  BloodVesselPlaque0,
  BloodVesselPlaque100,
  BloodVesselPlaque20,
  BloodVesselPlaque40,
  BloodVesselPlaque60,
  BloodVesselPlaque80,
  BloodVesselPlaqueStent0,
  BloodVesselPlaqueStent100,
  BloodVesselPlaqueStent20,
  BloodVesselPlaqueStent40,
  BloodVesselPlaqueStent60,
  BloodVesselPlaqueStent80,
  StentBalloonCoveredIcon,
  StentBalloonIcon,
  StentCoveredIcon,
  StentIcon,
} from "../../../../common/components/icons/OperationIcon";
import IntaOperativeSideBar from "./IntaOperativeSidebar";
import {
  StaticResource,
  SampleAccessData,
  filterMedicationsByQuery as filterDataByQuery,
} from "../../constant";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import CardWhiteBlock from "../../../../common/components/CardWhite";
import ProcedureSearch from "./procedureSearch";
import {
  ExpandSVG,
  LeftCharvenSVG,
  DevicesSVG,
  DugsSVG,
} from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import { IntaOperationBody } from "../../../../common/components/icons/IntaOperation";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import LabelIcon from "../../../../common/components/LabelIcon";
import { getArteryLabel } from "../../../../common/constants/Artery";
import { getMedical } from "../../../../common/constants/Operations";
import {
  getAngiographicSupplies,
  setProcedureReview,
  save_procedurals,
  save_info_side,
} from "../../../../store/actions";
import AccessDetails from "../operativeDetailsComponents/AccessDetails";
import ArteryDetail from "../operativeDetailsComponents/ArteryDetail";
import Cookies from "universal-cookie";
import SidebarExtension from "../../../../common/components/SidebarExtension";

const cookies = new Cookies();
const { Panel } = Collapse;
const { Option } = Select;
let timer;

const ProcedureOptions = [
  "Right Lower Extremity Intervention",
  "Right Iliac Intervention",
  "Right Renal Artery Intervention",
  "Right Upper Extremity Intervention",
  "Right Innominate Artery Intervention",
  "Right PICC Line Placement",
  "Right Dialysis Catheter Placement",
  "Right Carotid Angiogram",
  "Right Dialysis Intervention",
  "Left Lower Extremity Intervention",
  "Left Iliac Intervention",
  "Left Renal Artery Intervention",
  "Left Upper Extremity Intervention",
  "Left Innominate Artery Intervention",
  "Left PICC Line Placement",
  "Left Dialysis Catheter Placement",
  "Left Carotid Angiogram",
  "Left Dialysis Intervention",
];

// Inta Operation Data Structure
const SampleOperation = {
  body: "",
  medicals: [],
  details: [],
};

const IntaOperative = ({ data, title }) => {
  const imageRef = useRef(null);
  const sidebarRef = useRef();
  const supplies = useSelector((state) => state.inventory.supplies);
  const procedureReview = useSelector((state) => state.procedureReview);
  const [allInventory, setAllInventory] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [inputInventory, setInputInventory] = useState("");
  const [inventoryItems, setInventoryItems] = useState([]);
  const [dataSidebar, setDataSidebar] = useState({});
  const [isSidebar, setIsSidebar] = useState(true);
  const [disableDetail, setDisableDetail] = useState(true);
  const [loading, setLoading] = useState("");
  const [tab, setTab] = useState("documentation");
  const dispatch = useDispatch();
  const list_procedurals = useSelector(
    (state) => state.procedure.list_procedurals
  );
  const [intaOperations, setIntaOperations] = useState([]);
  const [currentOperation, setCurrentOperation] = useState({
    ...SampleOperation,
  });
  const [selectedOperation, setSelectedOperation] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const id_appoinment = cookies.get("current_appointment_id");
  const debouncedSearch = useDebounce(inputInventory, 400);
  const is_locked = useSelector((state) => state.common.is_locked);

  useEffect(() => {
    if (list_procedurals) {
      setIntaOperations(list_procedurals);
      if (list_procedurals[0]) {
        setCurrentOperation(list_procedurals[0]);
      }
    }
  }, [list_procedurals]);

  useEffect(() => {
    dispatch(getAngiographicSupplies(""));
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      const allInventories = filterDataByQuery(supplies, debouncedSearch);
      setAllInventory(allInventories);
      setLoading(false);
    }
  }, [debouncedSearch]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleInventorySearch = (search) => {
    setInputInventory(search);
    if (search) {
      setLoading(true);
    }
  };

  const selectItemInventory = (itemName, itemId) => {
    if (itemName === "") return;
    for (let itemId_ of inventories) {
      if (itemId_ === itemId) return;
    }
    const inventory = supplies.find((su) => su.id === itemId);

    let listInventory = [...inventories];
    listInventory.push(itemId);
    setInventories(listInventory);
    listInventory = [...itemNames];
    listInventory.push(itemName);
    setItemNames(listInventory);
    listInventory = [...inventoryItems];
    listInventory.push(inventory);
    setInventoryItems(listInventory);
  };

  const removeRowIventory = (item, index) => {
    let listItems = [...inventories];
    listItems.splice(index, 1);
    setInventories(listItems);
    listItems = [...itemNames];
    listItems.splice(index, 1);
    setItemNames(listItems);
    listItems = [...inventoryItems];
    listItems.splice(index, 1);
    setInventoryItems(listItems);
  };

  const handleIntaOperation = (type) => (value) => {
    if (
      !currentOperation.body.part &&
      value.part &&
      currentOperation.medicals.length > 0
    ) {
      setCurrentOperation({ ...currentOperation, [type]: value });
      updateOperations({ ...currentOperation, [type]: value });
    } else if (
      currentOperation.body.part &&
      value.part === currentOperation.body.part
    ) {
      setCurrentOperation({ details: [], body: "", medicals: [] });
    } else if (
      currentOperation.body.part &&
      value.part !== currentOperation.body.part
    ) {
      const existingOperation = intaOperations.find(
        (inta) => inta.body.part === value.part
      );
      if (existingOperation) {
        setCurrentOperation(existingOperation);
      } else {
        setCurrentOperation({
          details: [],
          medicals: intaOperations.length > 0 ? [] : currentOperation.medicals,
          [type]: value,
        });
        if (!intaOperations.length && currentOperation.medicals[0]) {
          updateOperations({
            details: [],
            medicals: currentOperation.medicals,
            [type]: value,
          });
        }
      }
    } else {
      setCurrentOperation({
        body: "",
        medicals: [],
        details: [],
        [type]: value,
      });
    }
    setSelectedIndex(-1);
    setSelectedOperation("");
    setDisableDetail(true);
    setTab("tools");
  };

  const updateOperations = (data) => {
    const existing = intaOperations.findIndex(
      (inta) => inta.body.part === data.body.part
    );
    const operations = [...intaOperations];
    if (existing === -1) {
      operations.push({ ...data });
    } else {
      operations[existing] = { ...data };
    }
    setIntaOperations(operations);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(
        save_procedurals(id_appoinment, {
          procedurals: operations,
        })
      );
      dispatch(setProcedureReview("intra_operations", operations));
    }, 500);
  };

  const handleClickLabel = (value) => {
    if (is_locked === false || is_locked === 0)
    {
      const operation = getMedical(value);
      const current = { ...currentOperation };
      if (current.body && current.body.part) {
        current.medicals.push(value);
        updateOperations({ ...current });
      } else {
        current.medicals = [value];
      }
      setCurrentOperation(current);
    }
  };

  const handleSelectIcon = (value, index) => {
    setDisableDetail(false);
    setSelectedOperation(value);
    setSelectedIndex(index);
    setTab("details");
  };

  const dataOnchange = (value) => {
    const operations = [...intaOperations];
    setDataSidebar(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(
        save_info_side(id_appoinment, {
          ...value,
        })
      );
      dispatch(setProcedureReview("intra_operations", operations));
    }, 500);
  };

  const handleRemoveMedical = (medical) => {
    const current = { ...currentOperation };
    if (selectedIndex === -1) {
      setSelectedOperation("");
      setDisableDetail(true);
      setTab("tools");
      return;
    } else {
      current.medicals.splice(selectedIndex, 1);
      setCurrentOperation(current);
      updateOperations(current);
      setSelectedOperation("");
      setDisableDetail(true);
      setSelectedIndex(-1);
      setTab("tools");
    }
  };

  const handleMedicals = (medicals, from, to) => {
    const details = [...currentOperation.details];
    if (from !== -1 && details[from]) {
      const fromDetail = { ...details[from] };
      details.splice(from, 1);
      details.splice(to, 0, fromDetail);
    } else if (details[to]) {
      details.splice(to, 0, null);
    }
    setCurrentOperation({ ...currentOperation, medicals, details: details });
    updateOperations({ ...currentOperation, medicals, details: details });
    setSelectedIndex(-1);
    setSelectedOperation("");
    setDisableDetail(true);
    setTab("tools");
  };

  const saveCurrentOperation = (detail) => {
    const current = { ...currentOperation };
    if (detail.post_stenosis) {
      const curOperation = getMedical(current.medicals[selectedIndex]);
      const operation = current.medicals[selectedIndex + 1]
        ? getMedical(current.medicals[selectedIndex + 1])
        : undefined;
      if (curOperation.type !== "status" && !operation) {
        current.medicals[selectedIndex + 1] =
          "stenosis-" + detail.post_stenosis.replace("%", "");
      } else if (
        curOperation.type !== "status" &&
        operation.state &&
        operation.state === "stenosis"
      ) {
        current.medicals[selectedIndex + 1] =
          "stenosis-" + detail.post_stenosis.replace("%", "");
      } else if (curOperation.type !== "status" && !operation.state) {
        current.medicals.splice(
          selectedIndex + 1,
          0,
          "stenosis-" + detail.post_stenosis.replace("%", "")
        );
      }
      if (curOperation.type === "status") {
        current.medicals[selectedIndex] =
          curOperation.state + "-" + detail.post_stenosis.replace("%", "");
      }
      current.details[selectedIndex] = detail;
      setCurrentOperation(current);
      updateOperations(current);
      // Todo implement
    } else {
      current.details[selectedIndex] = detail;
      setCurrentOperation(current);
      updateOperations(current);
    }
    // Todo implement
  };
  
  
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="patientContainer">
          <div
            className="imageSection"
            ref={imageRef}
            style={{ position: "relative" }}
          >
            {/*<BodySVG />*/}
            <IntaOperationBody
              current={currentOperation}
              operations={intaOperations}
              handleClick={handleIntaOperation("body")}
            />
            {/* {currentOperation && (
              <ArteryDetail
                parent={imageRef && imageRef.current && imageRef.current.clientWidth}
                data={currentOperation.body}
                medicals={currentOperation.medicals}
              />
            )} */}
          </div>

          <DndProvider backend={HTML5Backend}>
            <div className="detailSection" style={{ paddingRight: "10px" }}>
              <CardWhiteBlock
                varient={"default"}
                title={""}
                other={tab === "documentation" ? "full-height" : "half-height"}
                extraStyle={{
                  padding: tab === "documentation" ? "0px" : "0 1.5rem 1rem",
                  backgroundColor:
                    tab === "documentation" ? "#f4f7fb" : "transparent",
                }}
              >
                <ScrollWrapper css="no-padding x-hidden">
                  <div
                    className="tabHeaders"
                    style={{
                      height: "37px",
                      paddingLeft: tab === "documentation" ? "2.5rem" : "1rem",
                      paddingTop: "0.5rem",
                      background: "#fff",
                    }}
                  >
                    <div
                      className={`tabHeaders-item ${
                        tab === "documentation" ? "active" : ""
                      }`}
                      style={{ width: "100px", textAlign: 'left' }}
                      onClick={() => setTab("documentation")}
                    >
                      Documentation
                    </div>
                    <div
                      className={`tabHeaders-item ${
                        tab === "tools" ? "active" : ""
                      }`}
                      style={{ width: "34.2px" }}
                      onClick={() => setTab("tools")}
                    >
                      Tools
                    </div>
                    <div
                      className={`tabHeaders-item ${
                        tab === "details" ? "active" : ""
                      } ${disableDetail && "disabled"}`}
                      style={{ width: "45.5px" }}
                      onClick={() => !disableDetail && setTab("details")}
                    >
                      Details
                    </div>
                    <div
                      className={`tabHeaders-item ${
                        tab === "inventory-utilization" ? "active" : ""
                      }`}
                      style={{ width: "auto" }}
                      onClick={() => setTab("inventory-utilization")}
                    >
                      Inventory & Utilization
                    </div>
                  </div>
                  {tab === "documentation" && (
                    <div
                      className="content"
                      style={{
                        padding: "28px 0 12px",
                        height: "calc(100% - 39px)",
                      }}
                    >
                      <ProcedureSearch />
                    </div>
                  )}
                  {tab === "indication" && (
                    <div className="content" style={{ padding: "28px 0 12px" }}>
                      <div className="info d-flex">
                        <Select
                          placeholder="Procedure to be Performed..."
                          suffixIcon={<ExpandSVG />}
                          allowClear={true}
                          style={{
                            width: "25%",
                            minWidth: "260px",
                          }}
                        >
                          {ProcedureOptions.map((opt, index) => (
                            <Option
                              key={`procedure-to-be-performed-${index}`}
                              value={opt}
                            >
                              {opt}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div className="info d-flex">
                        <Select
                          placeholder="Clinical Indication..."
                          suffixIcon={<ExpandSVG />}
                          allowClear={true}
                          style={{
                            width: "25%",
                            minWidth: "260px",
                          }}
                        >
                          <Option value="Critical Limb Ischemia">
                            Critical Limb Ischemia
                          </Option>
                          <Option value="Critical Limb Ischemia1">
                            Critical Limb Ischemia
                          </Option>
                        </Select>
                      </div>
                    </div>
                  )}
                  {tab === "tools" && (
                    <Collapse
                      className="card-collapse"
                      accordion
                      style={{ paddingTop: "12px" }}
                    >
                      <Panel header="Access" key="Access">
                        <div
                          className="content"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            paddingTop: "0.5rem",
                          }}
                        >
                          <LabelIcon
                            label={"Ultrasound"}
                            isDraggable={true}
                            icon={<UltrasoundProbeIcon />}
                            item="ultrasound"
                            handleClick={() => handleClickLabel("ultrasound")}
                          />
                          <LabelIcon
                            label={"Local Anesthesia"}
                            isDraggable={true}
                            icon={<DugsSVG />}
                            item="local-anesthesia"
                            handleClick={() =>
                              handleClickLabel("local-anesthesia")
                            }
                          />
                          <LabelIcon
                            label={"Access"}
                            isDraggable={true}
                            icon={<DevicesSVG />}
                            item="access"
                            handleClick={() => handleClickLabel("access")}
                          />
                          <LabelIcon
                            label={"Closure Device"}
                            isDraggable={true}
                            icon={
                              <img
                                src="/images/closure-featured.png"
                                alt="closure device"
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  marginLeft: 0,
                                  marginBottom: "5px",
                                }}
                              />
                            }
                            item="closure-device"
                            handleClick={() =>
                              handleClickLabel("closure-device")
                            }
                          />
                        </div>
                      </Panel>
                      <Panel header="Diagnostic" key="Diagnostic">
                        <div
                          className="content"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                            paddingTop: "0.5rem",
                          }}
                        >
                          <LabelIcon
                            label={"Stenosis 0%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaque0 />}
                            isHover={true}
                            item="stenosis-0"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "stenosis-0"
                            }
                            handleClick={() => handleClickLabel("stenosis-0")}
                          />
                          <LabelIcon
                            label={"Stenosis 20%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaque20 />}
                            isHover={true}
                            item="stenosis-20"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "stenosis-20"
                            }
                            handleClick={() => handleClickLabel("stenosis-20")}
                          />
                          <LabelIcon
                            label={"Stenosis 40%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaque40 />}
                            isHover={true}
                            item="stenosis-40"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "stenosis-40"
                            }
                            handleClick={() => handleClickLabel("stenosis-40")}
                          />
                          <LabelIcon
                            label={"Stenosis 60%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaque60 />}
                            isHover={true}
                            item="stenosis-60"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "stenosis-60"
                            }
                            handleClick={() => handleClickLabel("stenosis-60")}
                          />
                          <LabelIcon
                            label={"Stenosis 80%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaque80 />}
                            isHover={true}
                            item="stenosis-80"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "stenosis-80"
                            }
                            handleClick={() => handleClickLabel("stenosis-80")}
                          />
                          <LabelIcon
                            label={"Stenosis 100%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaque100 />}
                            isHover={true}
                            item="stenosis-100"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "stenosis-100"
                            }
                            handleClick={() => handleClickLabel("stenosis-100")}
                          />
                          <LabelIcon
                            label={"In-Stent 0%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaqueStent0 />}
                            isHover={true}
                            item="in-stent-0"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "in-stent-0"
                            }
                            handleClick={() => handleClickLabel("in-stent-0")}
                          />
                          <LabelIcon
                            label={"In-Stent 20%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaqueStent20 />}
                            isHover={true}
                            item="in-stent-20"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "in-stent-20"
                            }
                            handleClick={() => handleClickLabel("in-stent-20")}
                          />
                          <LabelIcon
                            label={"In-Stent 40%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaqueStent40 />}
                            isHover={true}
                            item="in-stent-40"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "in-stent-40"
                            }
                            handleClick={() => handleClickLabel("in-stent-40")}
                          />
                          <LabelIcon
                            label={"In-Stent 60%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaqueStent60 />}
                            isHover={true}
                            item="in-stent-60"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "in-stent-60"
                            }
                            handleClick={() => handleClickLabel("in-stent-60")}
                          />
                          <LabelIcon
                            label={"In-Stent 80%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaqueStent80 />}
                            isHover={true}
                            item="in-stent-80"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "in-stent-80"
                            }
                            handleClick={() => handleClickLabel("in-stent-80")}
                          />
                          <LabelIcon
                            label={"In-Stent 100%"}
                            isDraggable={true}
                            icon={<BloodVesselPlaqueStent100 />}
                            isHover={true}
                            item="in-stent-100"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "in-stent-100"
                            }
                            handleClick={() => handleClickLabel("in-stent-100")}
                          />
                          <LabelIcon
                            label={"IVUS"}
                            isDraggable={true}
                            icon={<IVUSIcon />}
                            isHover={true}
                            item="ivus"
                            active={
                              !!currentOperation &&
                              currentOperation.diagnostic === "ivus"
                            }
                            handleClick={() => handleClickLabel("ivus")}
                          />
                        </div>
                      </Panel>
                      <Panel header="Atherectomy" key="Atherectomy">
                        <div
                          className="content"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                            paddingTop: "0.5rem",
                          }}
                        >
                          <LabelIcon
                            label={"Directional Atherectomy"}
                            isDraggable={true}
                            icon={<AtherectomyDirectionalIcon />}
                            item="directional-atherectomy"
                            handleClick={() =>
                              handleClickLabel("directional-atherectomy")
                            }
                          />
                          <LabelIcon
                            label={"Laser Atherectomy"}
                            isDraggable={true}
                            icon={<AtherectomyLaserIcon />}
                            item="laser-atherectomy"
                            handleClick={() =>
                              handleClickLabel("laser-atherectomy")
                            }
                          />
                          <LabelIcon
                            label={"Rotational Atherectomy"}
                            isDraggable={true}
                            icon={<AtherectomyRotationalIcon />}
                            item="rotational-atherectomy"
                            handleClick={() =>
                              handleClickLabel("rotational-atherectomy")
                            }
                          />
                          <LabelIcon
                            label={"Obital Atherectomy"}
                            isDraggable={true}
                            icon={<AtherectomyTransluminalIcon />}
                            item="obital-atherectomy"
                            handleClick={() =>
                              handleClickLabel("obital-atherectomy")
                            }
                          />
                        </div>
                      </Panel>
                      <Panel header="Angioplasty" key="Angioplasty">
                        <div
                          className="content"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            paddingTop: "0.5rem",
                          }}
                        >
                          <LabelIcon
                            label={"Balloon Angioplasty"}
                            isDraggable={true}
                            icon={<BalloonIcon />}
                            item="balloon-angioplasty"
                            handleClick={() =>
                              handleClickLabel("balloon-angioplasty")
                            }
                          />
                        </div>
                      </Panel>
                      <Panel header="Stenting" key="Stenting">
                        <div
                          className="content"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                            paddingTop: "0.5rem",
                          }}
                        >
                          <LabelIcon
                            label={"Balloon Expandable Covered Stent"}
                            isDraggable={true}
                            icon={<StentBalloonCoveredIcon />}
                            item="balloon-expandable-covered-stent"
                            handleClick={() =>
                              handleClickLabel(
                                "balloon-expandable-covered-stent"
                              )
                            }
                          />
                          <LabelIcon
                            label={"Covered Stent"}
                            isDraggable={true}
                            icon={<StentCoveredIcon />}
                            item="covered-stent"
                            handleClick={() =>
                              handleClickLabel("covered-stent")
                            }
                          />
                          <LabelIcon
                            label={"Balloon Expandable Stent"}
                            isDraggable={true}
                            icon={<StentBalloonIcon />}
                            item="balloon-expandable-stent"
                            handleClick={() =>
                              handleClickLabel("balloon-expandable-stent")
                            }
                          />
                          <LabelIcon
                            label={"Self Expanding Stent"}
                            isDraggable={true}
                            icon={<StentIcon />}
                            item="self-expanding-stent"
                            handleClick={() =>
                              handleClickLabel("self-expanding-stent")
                            }
                          />
                        </div>
                      </Panel>
                      <Panel header="Other" key="Other">
                        <div
                          className="content"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            paddingTop: "0.5rem",
                          }}
                        ></div>
                      </Panel>
                    </Collapse>
                  )}
                  {tab === "details" && (
                    <AccessDetails
                      title={"19G Access"}
                      inventories={supplies}
                      items={inventoryItems}
                      data={SampleAccessData}
                      current={currentOperation}
                      activeIndex={selectedIndex}
                      activeItem={selectedOperation}
                      handleSubmit={saveCurrentOperation}
                      removeItem={handleRemoveMedical}
                    />
                  )}
                  {tab === "inventory-utilization" && (
                    <div
                      className="content"
                      style={{ padding: "28px 1rem 12px" }}
                    >
                      <AutoCompleteComponent
                        title="inta-operative"
                        placeholder="Search Inventory"
                        rows={itemNames}
                        keyLabel={"name"}
                        keyValue={"id"}
                        disableSearchIcon={false}
                        removeItem={removeRowIventory}
                        loading={loading}
                        options={allInventory}
                        isResult={false}
                        selectItem={selectItemInventory}
                        handleSearch={handleInventorySearch}
                        minRows={8}
                        isLocked={is_locked}
                      />
                    </div>
                  )}
                </ScrollWrapper>
              </CardWhiteBlock>
              <CardWhiteBlock
                varient={"default"}
                title={
                  <div
                    className="pannel-title"
                    style={{ marginTop: "1.5rem", marginLeft: "-12px", display: 'flex' }}
                  >
                    <Icon type="appstore" theme="twoTone" twoToneColor="#EE5396"/>
                    <span
                      style={{
                        marginLeft: "8px",
                        fontSize: "18px",
                        fontWeight: "normal",
                      }}
                    >
                      PROCEDURAL DOCUMENTATION
                    </span>
                  </div>
                }
                other={
                  tab === "documentation" ? "d-none" : "half-height bottom"
                }
              >
                <ScrollWrapper css="no-padding x-hidden">
                  <Collapse
                    className="card-collapse"
                    accordion
                    style={{ paddingTop: "24px" }}
                    activeKey={currentOperation.body.part}
                    onChange={(pannelKey) => {
                      const operation = intaOperations.find(
                        (inta) => inta.body.part === pannelKey
                      );
                      if (
                        currentOperation &&
                        operation &&
                        pannelKey !== currentOperation.body.part
                      ) {
                        setCurrentOperation(operation);
                        setDisableDetail(true);
                        setSelectedOperation("");
                        setSelectedIndex(-1);
                        setTab("tools");
                      } else {
                        setCurrentOperation({
                          ...SampleOperation,
                          body: "",
                          medicals: [],
                        });
                        setDisableDetail(true);
                        setSelectedOperation("");
                        setSelectedIndex(-1);
                        setTab("tools");
                      }
                    }}
                  >
                    {(intaOperations || []).map((operation, index) => (
                      <Panel
                        header={getArteryLabel(operation.body.part)}
                        key={operation.body.part}
                      >
                        <ArteryDetail
                          data={operation.body}
                          medicals={operation.medicals}
                          diagnostic={operation.diagnostic}
                          medical={operation.medical}
                          handleOperation={handleSelectIcon}
                          handleMedicals={handleMedicals}
                          isDraggable={true}
                        />
                      </Panel>
                    ))}
                  </Collapse>
                </ScrollWrapper>
              </CardWhiteBlock>
            </div>
          </DndProvider>
        </div>
      </div>
      {isSidebar === true && (
        <IntaOperativeSideBar
          ref={sidebarRef}
          dataOnchange={dataOnchange}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
      <SidebarExtension startingWidth='calc(28.3% - 20px + 0.3rem)' sidebarReference={sidebarRef} sidebarToggle={toggleSidebar} sidebarState={isSidebar}/>
    </div>
  );
};

export default IntaOperative;
