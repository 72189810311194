import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Select, DatePicker } from 'antd';
import { useDebounce } from 'use-lodash-debounce';
import moment from 'moment';
import { ExpandSVG, SearchSVG } from '../../../../common/components/icons/SVGIcon';
import {
  getInventoryLocations,
  getInventorySpecificLocations,
  getInventorySearch,
  setInventoryData,
  getInventoryUsers
} from '../../../../store/actions';

const { Search } = Input;
const { Option } = Select;
const dateFormat = 'MM/DD/YYYY';

const InventoryFilter = ({ locations }) => {
  const specificLocations = useSelector(state => state.inventory.specificLocations);
  const filterOption = useSelector(state => state.inventory.inventoryFilter);
  const deleted = useSelector(state => state.inventory.isDeleted);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const debounce = useDebounce(search, 400);

  useEffect(() => {
    dispatch(getInventorySpecificLocations());
    dispatch(getInventoryUsers());
    dispatch(setInventoryData(
      'inventoryFilter',
      {
        specific_location: undefined,
        location: undefined,
        type: undefined,
        inventory: undefined
      }
    ))
    dispatch(setInventoryData('inventory', null));
  }, []);

  useEffect(() => {
    dispatch(getInventorySearch(debounce));
  }, [debounce]);

  useEffect(() => {
    if (deleted) {
      dispatch(getInventorySearch(debounce));
    }
  }, [deleted]);

  const changeSearch = (value) => {
    setSearch(value);
  }

  const changeFilter = type => value => {
    const newFilter = { ...filterOption, [type]: value }
    dispatch(setInventoryData('inventoryFilter', newFilter));
  }

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer" style={{ width: '50%' }}>
        <div className="searchField">
          <Search
            placeholder="Search"
            onChange={e => changeSearch(e.target.value)}
            style={{ width: '100%' }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer" style={{ width: '50%' }}>
        <div className="statusFilter" style={{
          width: '120px',
          maxWidth: '120px',
          minWidth: '120px'
        }}>
          <Select
            placeholder="Inventory..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.inventory}
            onChange={changeFilter('inventory')}
          >
            <Option value="1">+1</Option>
            <Option value="10">+10</Option>
            <Option value="20">+20</Option>
            <Option value="50">+50</Option>
            <Option value="100">+100</Option>
          </Select>
        </div>
        <div className="statusFilter" style={{
          width: '120px',
          maxWidth: '120px',
          minWidth: '120px'
        }}>
          <Select
            placeholder="Location..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.location}
            onChange={changeFilter('location')}
          >
            {(locations || []).map((loc, index) => (
              <Option
                key={`location-${index}`}
                value={loc.value}
              >
                {loc.value}
              </Option>
            ))}
          </Select>
        </div>
        <div className="statusFilter" style={{ flex: 1 }}>
          <Select
            placeholder="Specific Location..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.specific_location}
            onChange={changeFilter('specific_location')}
          >
            {(specificLocations || []).map((loc, index) => (
              <Option
                key={`location-${index}`}
                value={loc.specific_location}
              >
                {loc.specific_location}
              </Option>
            ))}
          </Select>
        </div>
      </div>

    </div>
  );
};

export default InventoryFilter;
