import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ScrollWrapper from "../../../common/components/ScrollBar";
import {
  CheckSVG,
  RedRequiredCircleSVG,
} from "../../../common/components/icons/SVGIcon";
import { useWindowSize } from "../../../common/utils";
import {
  getPriorAuthorization,
  selectPrior,
  setPriorData,
  setDefaultSaveResource,
  getLocations,
  selectPatient,
  get_patient_notes,
  setGlobalLoading,
} from "../../../store/actions";
import ToastMessage from "../../../common/components/Toast";
import { Icon } from "antd";
import DataTable from "../../../common/components/DataTable/DataTable";

const filterSuggestion = (suggestions, userInput, option) => {
  if (!userInput && !option) {
    return suggestions;
  }

  console.dir(option);
  console.dir(suggestions);

  let suggestionsByCode = suggestions;

  // Filter off of authorization_number
  if (option && option.authorization_number) {
    suggestionsByCode = suggestionsByCode.filter(
      (suggestion) =>
        suggestion.authorization_number !== undefined &&
        suggestion.authorization_number !== null &&
        suggestion.authorization_number
          .split(" ")
          .join("")
          .toLowerCase()
          .indexOf(
            option.authorization_number.split(" ").join("").toLowerCase()
          ) > -1
    );
  }

    // Filter off of expiration_date
    if (option && option.expiration_date) {
      suggestionsByCode = suggestionsByCode.filter(
        (suggestion) => {
          console.dir(suggestion.expiration_date);
          console.dir(moment(option.expiration_date).format('YYYY/MM/DD HH:ii:ss'));
          return suggestion.expiration_date !== undefined &&
          suggestion.expiration_date !== null &&
          suggestion.expiration_date === moment(option.expiration_date).format('YYYY-MM-DD HH:mm:ss')

        }
            
      );
    }

  // Filter off of response status
  if (option && option.response_status) {
    suggestionsByCode = suggestionsByCode.filter(
      (suggestion) =>
        suggestion.status !== undefined &&
        suggestion.status !== null &&
        suggestion.status === option.response_status
    );
  }



  // Return final filter with searched patient name.
  return suggestionsByCode.filter(
    (suggestion) =>
      suggestion.patient_name &&
      suggestion.patient_name
        .split(" ")
        .join("")
        .toLowerCase()
        .indexOf(userInput.split(" ").join("").toLowerCase()) > -1
  );
};

const PriorResultTable = ({ title, search, filter, handleSidebar }) => {
  const loading = useSelector((state) => state.prior.loadingPriors);
  const data = useSelector((state) => state.prior.priors);
  const resource = useSelector((state) => state.prior.resource);
  const savedResource = useSelector((state) => state.prior.savedResoruces);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);

  const dispatch = useDispatch();
  const [messageToast, setMessageToast] = useState(null);
  const [isToastr, setIsToastr] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [takeCount, setTakeCount] = useState(30);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      const count = Math.round(ref.current.clientHeight / 40) + 3;
      setTakeCount(count);
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    dispatch(setPriorData("loadingPriors", true));
    dispatch(setPriorData("resource", null));
    dispatch(getLocations());
    dispatch(getPriorAuthorization());
  }, []);

  const saveResourceApi = async () => {
    setIsToastr(true);
    await dispatch(setPriorData("loadingPriors", true));
    await dispatch(setPriorData("loadingResource", true));
    await dispatch(setPriorData("resource", null));
    await dispatch(setDefaultSaveResource());
    await dispatch(getPriorAuthorization());
    setRow(-1);
    setTimeout(() => {
      setIsToastr(false);
    }, 2000);
  };

  useEffect(() => {
    if (savedResource) {
      saveResourceApi();
    }
  }, [savedResource]);

  const tableRows = useMemo(() => {
    if (data) {
      setOffset(0);
      return filterSuggestion(data, search, filter);
    }
    return [];
  }, [data, search, filter]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      return tableRows.slice(0, offset + takeCount);
    }
  }, [offset, tableRows]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = async (index) => {
    setRow(index);
    if (rowData[index] && rowData[index]["patient_id"]) {
      dispatch(selectPatient(rowData[index]["patient_id"]));
      dispatch(get_patient_notes(rowData[index]["patient_id"]));
      await dispatch(setPriorData("loadingResource", true));
      await dispatch(selectPrior(rowData[index]));
      handleSidebar();
    }
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const onScroll = (values) => {
    if (values.scrollTop > offset * 40 + 80) {
      setOffset(offset + takeCount);
    }
  };

  const showToast = () => {
    return (
      <ToastMessage
        type="Prior Authorizations"
        status={"success"}
        msg={messageToast ? messageToast : "Prior Authorization Saved!"}
      />
    );
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading])
  return (
    <div ref={ref} className="tableContainer">
      {isToastr && !errorRest && showToast()}
      <DataTable
        title="prior_result"
        hideHeader={true}
        labels={["Name", 'Type', 'Date', 'Authorization Number', 'Expiration', 'Status']}
        widths={['23%', '15.4%', '15.4%', '15.4%', '15.4%', '15.4%' ]}
        columns={[
          { sortable: false, key: 'name' },
          { sortable: false, key: 'type' },
          { sortable: false, key: 'date' },
          { sortable: false, key: 'authorization' },
          { sortable: false, key: 'expiration' },
          { sortable: false, key: 'status' },
        ]}
        defaultActive={-1}
        disableDelete={true}
        loadMore={onScroll}
        handleClickRow={(row, index) => {handleRow(index)}}
        rowData={(rowData || []).map((row, index) => {
          return {
            name: (
              <div
                className="iconField td with-icon"
                style={{
                  display: "flex",
                  paddingLeft: "0.25rem",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {row.icon && (
                  <div className="">{row.icon}</div>
                )}
                {!row.icon && (
                  <div className="">
                    {row.status !== "Complete" ? (
                      <Icon type="warning" theme="twoTone" twoToneColor="#FF832B"/>
                    ) : (
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
                    )}
                  </div>
                )}
                <p style={{ paddingLeft: "0.75rem" }} >{row.patient_name}</p>
              </div>
            ),
            type: row.type,
            date: moment(row.date_of_procedure).isValid() && moment(row.date_of_procedure).format("MM/DD/YYYY HH:mm"),
            authorization: row.authorization_number,
            expiration: moment(row.expiration_date).isValid() && moment(row.expiration_date).format("MM/DD/YYYY"),
            status: row.status
          }
        })}
      />
    </div>
  );
};

export default PriorResultTable;
