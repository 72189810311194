import React, { useState, useEffect, useRef } from "react";

const useWindowSize = (ref) => {
  const getSize = () => {
    return {
      width: ref.current ? ref.current.clientWidth : undefined,
      height: ref.current ? ref.current.clientHeight : undefined,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!ref) {
      return false;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const CallbackTable = ({ data, title, handleSidebar }) => {
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(0);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const tableRows = data ? data : [];
  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index) => () => {
    setRow(index);
    handleSidebar();
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  return (
    <div
      ref={ref}
      className="tableContainer"
      style={{
        height: rowCount > tableRows.length ? "auto" : "100%",
        maxHeight: rowCount > tableRows.length ? "auto" : "100%",
        overflowY: rowCount > tableRows.length ? "hidden" : "scroll",
      }}
    >
      <div className="tableList">
        {tableRows.map((row, index) => (
          <div
            className={`tableItem ${activeIndex === index ? "active" : ""}`}
            key={`resultTable-${title}-${index}`}
            onClick={handleRow(index)}
          >
            <div className="nameField">
              {row.icon && <div className="iconField">{row.icon}</div>}
              <p>{row.name}</p>
            </div>
            <div className="typeField">
              <p>{row.type}</p>
            </div>
            <div className="dateField">
              <p>{row.date}</p>
            </div>
          </div>
        ))}
        {emptyRows.map((row, index) => (
          <div
            className={`tableItem empty`}
            key={`resultTable-empty-${title}-${index}`}
          >
            <div className="nameField" />
            <div className="typeField" />
            <div className="dateField" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CallbackTable;
