import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { CheckSVG } from "../../../../common/components/icons/SVGIcon";
import { getPCPList, getDetailUsers, setGlobalLoading } from "../../../../store/actions";
import { Icon } from "antd";

let timer = null;
const ActiveReferring = ({
  activeIndex,
  title,
  handleRow,
  propsListTable,
  deleteListPCP,
  addProps,
  search,
}) => {
  const ref = useRef(null);

  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const pcpList = useSelector((state) => state.common.pcpList);
  const loading_get_pcp = useSelector((state) => state.common.loading_get_pcp);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    if (deleteListPCP && Object.keys(deleteListPCP).length > 0) {
      if (deleteListPCP.id) {
        const index = tableRows.findIndex((r) => r.id == deleteListPCP.id);
        tableRows.splice(index, 1);
        setTableRows([...tableRows]);
      }
    }
  }, [deleteListPCP]);

  useEffect(() => {
    if (addProps && Object.keys(addProps).length > 0) {
      if (addProps.id) {
        tableRows.unshift(addProps);
        setTableRows([...tableRows]);
      }
    }
  }, [addProps]);

  useEffect(() => {
    setPage(1);
    setTableRows([]);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(getPCPList(1, search));
    }, 500);
  }, [search]);

  useEffect(() => {
    if (propsListTable && Object.keys(propsListTable).length > 0) {
      if (propsListTable.id) {
        const index = tableRows.findIndex((r) => r.id == propsListTable.id);
        tableRows.splice(index, 1, {
          ...propsListTable,
          first_name: propsListTable.first_name,
          last_name: propsListTable.last_name,
          name: `${propsListTable.first_name} ${propsListTable.last_name}`,
          full_name: `${propsListTable.first_name} ${propsListTable.last_name}`,
        });
        setTableRows([...tableRows]);
      }
    }
  }, [propsListTable]);

  useEffect(() => {
    if (!loading_get_pcp && pcpList && pcpList["active_pcp"]) {
      setTableRows([...tableRows, ...pcpList["active_pcp"]]);
    }
  }, [pcpList]);

  const dispatch = useDispatch();
  const size = useWindowSize(ref);

  useEffect(() => {
    fetchApi();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading_get_pcp));
  }, [loading_get_pcp])

  const onScroll = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading_get_pcp) {
      if (offLoad) {
        setLoading(true);
        const list = await dispatch(getPCPList(page + 1, search));
        if (list) {
          if (
            pcpList &&
            pcpList["active_pcp"] &&
            pcpList["active_pcp"].length > 0
          ) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  let emptyRows = [];

  const fetchApi = async () => {
    await dispatch(getPCPList(page));
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const getLocaleString = (str) => {
    if (!str) {
      return "$0.00";
    }
    const num = parseFloat(str).toFixed(2);
    const decimals = num.split(".")[1];
    if (!decimals || decimals === "00") {
      return "$" + Math.round(parseFloat(str)).toLocaleString() + ".00";
    }
    if (decimals.charAt(1) === "0") {
      return (
        "$" + (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + "0"
      );
    }
    return "$" + (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
  };

  return (
    <div className="fullContainer pt-2">
      <div className="tableHeader">
        <div className="tableItem">
          <div
            className="th"
            style={{ width: "calc(31% - 2rem)", marginLeft: "2rem" }}
          >
            <b>Provider Name</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Login</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Password</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Last Access Date and Time</b>
          </div>
        </div>
      </div>
      <div className="tableSection">
        <div ref={ref} className="tableContainer">
          <ScrollWrapper css="no-padding" onScrollFrame={onScroll}>
            <div className="tableList">
              {tableRows.map((row, index) => (
                <div
                  className={`tableItem yellow ${
                    activeIndex === index ? "active" : ""
                  }`}
                  key={`resultTable-${title}-${index}`}
                  onClick={handleRow(index, row)}
                >
                  <div className="td with-icon" style={{ width: "31%" }}>
                    <div className="iconField">
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148"/>
                    </div>
                    <p>{row.full_name}</p>
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    {/* <p>{row.position}</p> */}
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    {/* <p>{row.meta && row.meta.points}</p> */}
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    {/* <p>{row.meta && getLocaleString(row.meta.salary)}</p> */}
                  </div>
                </div>
              ))}
              {emptyRows.map((row, index) => (
                <div
                  className={`tableItem empty`}
                  key={`resultTable-empty-${title}-${index}`}
                >
                  <div className="td" style={{ width: "31%" }} />
                  <div className="td" style={{ width: "23%" }} />
                  <div className="td" style={{ width: "23%" }} />
                  <div className="td" style={{ width: "23%" }} />
                </div>
              ))}
            </div>
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default ActiveReferring;
