import { Icon } from 'antd';
import React from 'react';
import { CloseIconSVG, TrashSVG } from './SVGIcon';

const DeleteIconButton = ({ handleClick, selected, extraStyle, icon }) => {
  const style = extraStyle || {
    alignSelf: "center",
    marginBottom: "2px",
    lineHeight: "16px",
  };

  return (
    <div
      style={{
        width: icon === 'can' ? "16px" : "12px",
        height: icon === 'can' ? "16px" : "12px",
        fontSize: icon === 'can' ? "16px" : "12px",
        lineHeight: icon === 'can' ? "16px" : "12px",
        display: "inline-block",
        zIndex: 5,
        cursor: "pointer",
        ...style
      }}
      className={`closeMessage ${
        selected ? "selected" : ""
      }`}
      onClick={handleClick}
    >
      {icon === 'can' ? (selected ? <Icon type="delete" theme="twoTone" twoToneColor="#DA1E28"/> : <Icon type="delete"/> ) : <CloseIconSVG />}
    </div>
  );
};

export default DeleteIconButton;
