import React from 'react';
import { Checkbox } from 'antd';

const CustomItem = ({ checked, option, onClick, disabled }) => {
  return (
    <div className={`item-renderer ${disabled && "disabled"}`}>
      <Checkbox tabIndex={-1} checked={checked} onChange={onClick}>
        {option.label} 
      </Checkbox>
      <span style={{textAlign: 'left', float: 'right', marginLeft: 'auto', marginRight: '1'}}>{option.suffix}</span>
    </div>
  )
}

export default CustomItem;
