import React, { useState, useEffect, useMemo } from "react";
import { SortingArrowIcon } from "../icons/SortingIcon";
import DeleteIconButton from "../icons/DeleteIconButton";
import ScrollWrapper from "../ScrollBar";

import "./style.scss";

const WhiteHeaderTable = ({
  labels,
  widths,
  rowData,
  columns,
  sorts,
  title,
  hideHeader,
  hideHeaderHeight,
  allActive,
  handleClickRow,
  noneAllActive,
  handleSort,
  emptyRows,
  fullHeight,
  disableDelete,
  handleDelete,
  isNormal,
  disableFirstBorder,
  showIcon,
  fullsize,
  isSide,
  componentSide,
  parentHeight,
  isFixHeight,
  sizeHeightFix,
  isNotFirstactive,
  activeParent,
  handleActiveDelete,
  parentIndex,
  activeIndex,
  keyCheck,
  isShowPaddingHeight
}) => {
  const [sortOptions, setSortOptions] = useState([]);
  const [activeRow, setActiveRow] = useState(!isNotFirstactive ? 0 : -1);
  const [activeDelete, setActiveDelete] = useState(-1);
  useEffect(() => {
    if (sorts) {
      setSortOptions(sorts);
    }
  }, [sorts]);

  useEffect(() => {
    setActiveRow(activeIndex);
  }, [activeIndex]);

  const emptyData = useMemo(() => {
    if (parentHeight && parentHeight > 0) {
      const total = Math.floor(parentHeight / 40);
      const count =
        rowData && rowData.length > 0 ? total - rowData.length : total;

      return new Array(count).fill(1);
    }
    return [];
  }, [parentHeight, rowData]);

  const handleSortOption = (index, sort) => {
    const newSorts = [...sortOptions];
    newSorts[index] = sort;
    setSortOptions(newSorts);

    if (handleSort) {
      handleSort(newSorts);
    }
  };

  const handleRow = (index, row) => {
    setActiveRow(index);

    if (handleClickRow) {
      handleClickRow(row, index);
    }
  };

  const handleDeleteRow = (index, row) => {
    if (handleActiveDelete && activeParent !== index) {
      handleActiveDelete(index);
      return;
    }
    if (!handleActiveDelete && activeDelete !== index) {
      setActiveDelete(index);
      return;
    }
    if (handleDelete) {
      handleDelete(row, index);
      setActiveDelete(-1);
      if (handleActiveDelete) handleActiveDelete(-1);
    }
  };

  return (
    <div
      style={
        isFixHeight
          ? { height: sizeHeightFix }
          : fullHeight
          ? { height: "100%" }
          : {}
      }
      className="whiteHeaderTable"
    >
      <div
        className={`whiteHeaderTable-header sortableHeader ${
          isNormal ? "normal" : ""
        }`}
        style={{
          minHeight: hideHeaderHeight === undefined ? "50px" : hideHeaderHeight,
        }}
      >
        {labels &&
          labels.length > 0 &&
          labels.map((label, index) => (
            <div
              className={`th sortable ${
                index === 0 && disableFirstBorder ? "disable-first-border" : ""
              } ${!sortOptions[index] ? "hide-icon" : ""}`}
              style={{
                width:
                  showIcon && index === 0
                    ? `calc(${widths[index]} - 2rem`
                    : widths[index] || `calc(100% / ${labels.length})`,
                minWidth:
                  showIcon && index === 0
                    ? `calc(${widths[index]} - 2rem`
                    : widths[index] || `calc(100% / ${labels.length})`,
                display: hideHeader ? "none" : "flex",
                alignItems: "center",
                height:
                  hideHeaderHeight === undefined ? "40px" : hideHeaderHeight,
                marginLeft: showIcon && index === 0 ? "2rem" : 0,
              }}
              key={`${title}-head-${index}`}
            >
              <div
                className="whiteHeaderTable-header__label"
                style={{
                  width: "100%",
                  minWidth: "20%",
                  borderBottom: "none",
                  paddingLeft: "0.75rem",
                  color: "rgba(0, 0, 0, 0.65)",
                }}
              >
                {label}
              </div>

              {columns && columns[index] && columns[index].sortable && (
                <SortingArrowIcon
                  sort={sortOptions[index]}
                  handleSortASC={() => handleSortOption(index, "asc")}
                  handleSortDESC={() => handleSortOption(index, "desc")}
                />
              )}
            </div>
          ))}
      </div>

      <div
        style={
          isFixHeight
            ? {
                height: sizeHeightFix,
              }
            : {
                maxHeight: `calc(100% - ${
                  hideHeaderHeight === undefined ? "50px" : hideHeaderHeight
                })`,
              }
        }
        className="whiteHeaderTable-body"
      >
        <div
          className="tableList"
          style={
            isFixHeight
              ? { height: sizeHeightFix }
              : { height: "100%", minHeight: "15vh" }
          }
        >
          <ScrollWrapper
            style={isFixHeight ? { height: sizeHeightFix } : {}}
            css="no-padding x-hidden"
          >
            {rowData &&
              rowData.length > 0 &&
              rowData.map((row, index) => {
                return (
                  <React.Fragment key={`${title}-body-${index}-${row.key}`}>
                    <div
                      className={`tableItem yellow 
                      ${
                        noneAllActive &&
                        row?.keyIdPlan == keyCheck &&
                        activeRow === index
                          ? "active"
                          : ""
                      }
                      ${
                        !noneAllActive && activeRow === index ? "active" : ""
                      } ${!noneAllActive && allActive ? "active" : ""}`}
                      onClick={() => handleRow(index, row)}
                    >
                      {columns &&
                        columns.length > 0 &&
                        columns.map((col, idx) => (
                          <div
                            className="tableItemField"
                            style={{
                              width: row.fullsize
                                ? "calc(100% - 40px)"
                                : widths[idx] ||
                                  `calc(100% / ${labels.length})`,
                              minWidth: row.fullsize
                                ? "calc(100% - 40px)"
                                : widths[idx] ||
                                  `calc(100% / ${labels.length})`,
                            }}
                            key={`${title}-col-${index}-${idx}`}
                          >
                            <div
                              className={
                                idx === labels.length - 1
                                  ? !disableDelete
                                    ? "last-value with-delete p"
                                    : "last-value p"
                                  : !labels || !labels.length
                                  ? "pr-0 p"
                                  : "p"
                              }
                            >
                              {row[col.key]}
                            </div>
                            {!disableDelete &&
                              idx === labels.length - 1 &&
                              !row.disableDelete && (
                                <DeleteIconButton
                                  handleClick={() =>
                                    handleDeleteRow(index, row)
                                  }
                                  selected={
                                    handleActiveDelete
                                      ? activeParent === index
                                      : activeDelete === index
                                  }
                                  icon={"can"}
                                  extraStyle={{
                                    position: "absolute",
                                    display: "inline-block",
                                    right: "0.75rem",
                                    top: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                          </div>
                        ))}
                    </div>
                    {row.isSide && (
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#dbe3ee",
                          paddingLeft: 15,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <div
                          style={{
                            width: "calc(100% - 40px)",
                            minWidth: "calc(100% - 40px)",
                          }}
                        >
                          {row["componentSide"] && row["componentSide"]}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            {parentHeight &&
              parentHeight > 0 &&
              (emptyData || []).map((item, index) => (
                <div className="tableItem" key={`chart-empty-table-${index}`}>
                  <div
                    className="tableItemField"
                    style={{ width: "100%", minWidth: "100%" }}
                  />
                </div>
              ))}
            {!parentHeight &&
              (emptyRows || []).map((item, index) => (
                <div className="tableItem" key={`chart-empty-table-${index}`}>
                  <div
                    className="tableItemField"
                    style={{ width: "100%", minWidth: "100%" }}
                  />
                </div>
              ))}
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default WhiteHeaderTable;
