import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, DatePicker, Collapse } from "antd";
import moment from "moment";
import Cookies from "universal-cookie";
import pdf2base64 from "pdf-to-base64";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import {
  CloseIcon,
  ExpandSVG,
} from "../../../../common/components/icons/SVGIcon";
import { MaleSVG } from "../../../../common/components/icons/FaxSVG";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ExaminationROS from "./ExaminationROS";
import ToastMessage from "../../../../common/components/Toast";
import {
  getProcedureData,
  getProcedureType,
  getDetailProcedureData,
  search_cpt_code,
  getReferralConditions,
  getPatientHospital,
  save_in_patient_charting,
  update_in_patient_charting,
  setParamsChart,
  charting_generate_pdf,
  reset_charting_pdf,
  apply_cpt_rules,
  reset_cpt_rules,
  removeParamsChart,
  setGlobalLoading,
} from "../../../../store/actions";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const cookies = new Cookies();
const { Option } = Select;
const { Panel } = Collapse;
const dateFormat = "MM/DD/YYYY";

let timer = null;
const ChartingSideBar = React.forwardRef(
  ({ isShow, handleCloseSidebar, onClickSetRow, setAcitive }, ref) => {
    const resource = useSelector((state) => state.patientRecords.resource);
    const loadingSearchCPT = useSelector(
      (state) => state.common.loadingSearchCPT
    );
    const resourceHospital = useSelector(
      (state) => state.patientRecords.resourceHospital
    );
    const charting_pdf = useSelector(
      (state) => state.patientRecords.charting_pdf
    );
    const loading = useSelector(
      (state) => state.patientRecords.loadingResource
    );
    const cpt_rules = useSelector((state) => state.patientRecords.cpt_rules);
    const procedureData = useSelector((state) => state.common.procedureData);
    const conditionData = useSelector(
      (state) => state.common.referralConditions
    );
    const indicationsValue = useSelector(
      (state) => state.patientRecords.indicationsValue
    );
    const [printLink, setPrintLink] = useState("");
    const [arrayDiff, setArrayDiff] = useState([]);
    const [arrayIDA, setArrayIDA] = useState([]);
    const [loadingLocal, setLoadingLocal] = useState(false);
    const [isFail, setToast] = useState(false);
    const [isSave, setSave] = useState(false);
    const [keyPanel, setKeyPanel] = useState(undefined);
    const loadingProcedure = useSelector(
      (state) => state.common.loadingDetailProcedureData
    );
    const [page, setPage] = useState(1);
    const [activeRow, setActiveRow] = useState(-1);
    const [rowIDClick, setRowIDClick] = useState(null);
    const [selectResult, setSelectResult] = useState([]);
    const [hospitalSelect, setHospitalSelect] = useState({});
    const [selectSearch, setSelectSearch] = useState(null);
    const patient_id_cookie = cookies.get("current_patient_id");
    const user_id = cookies.get("cvai-current-user");
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.common.locations);
    const [editorState, setEditor] = useState(EditorState.createEmpty());
    const selectData = useSelector((state) => state.common.searchCPT);
    const providers = useSelector((state) => state.common.providers);
    const [dataHospital, setDataHospital] = useState([]);
    const [params, setParams] = useState({});
    const text_param = useSelector((state) => state.patientRecords.text_param);
    const [rowSearchName, setRowSearchName] = useState([]);
    const [rowSearchID, setRowSearchID] = useState([]);
    const fetchAPI = async () => {
      setLoadingLocal(true);
      cookies.set("patient_document_id", null);
      let loadSuccess = await dispatch(getProcedureData());
      loadSuccess = await dispatch(getReferralConditions());
      if (loadSuccess) {
        setLoadingLocal(false);
      }
    };

    useEffect(() => {
      if (cpt_rules && cpt_rules.length > 0) {
        let nameRow = [];
        let idRow = [];
        const checkCPT = (cpt_rules || []).map((r) => {
          nameRow = [...nameRow, `${r.code} ${r.short_desc}`];
          idRow = [...idRow, `${r.id}`];
        });
        setRowSearchName([...rowSearchName, ...nameRow]);
        setRowSearchID([...rowSearchID, ...idRow]);
      }
    }, [cpt_rules]);

    useEffect(() => {
      if (hospitalSelect && Object.keys(hospitalSelect).length > 0) {
        dispatch(reset_cpt_rules());
        let nameRow = [];
        let idRow = [];

        if (hospitalSelect.cpt_codes) {
          setRowSearchName([...nameRow]);
          setRowSearchID([...idRow]);
        }
        const paramsCheck = {
          ...hospitalSelect,
          appointment_type_id: hospitalSelect?.appointment_type_id,
          anesthesia: hospitalSelect?.medical_reading?.anesthesia,
          complications: hospitalSelect?.medical_reading?.complications,
          ebl: hospitalSelect?.medical_reading?.ebl,
          indications: hospitalSelect?.medical_reading?.indications,
          preoperative_dx_id:
            hospitalSelect?.medical_reading?.preoperative_dx_id,
          side: hospitalSelect?.medical_reading?.side,
          disposition: hospitalSelect?.medical_reading?.disposition,
        };
        setParams(paramsCheck);
        dispatch(setParamsChart("paramsChart", paramsCheck));
        dispatch(
          setParamsChart("indicationsValue", {
            indications: hospitalSelect?.medical_reading?.indications,
          })
        );
        if (hospitalSelect?.medical_reading?.indications) {
          const contentState = convertFromRaw(
            JSON.parse(hospitalSelect?.medical_reading?.indications)
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        }
      } else {
        setParams({});
        dispatch(setParamsChart("paramsChart", {}));
        dispatch(setParamsChart("indicationsValue", {}));
      }
    }, [hospitalSelect]);

    useEffect(() => {
      fetchAPI();
    }, []);

    useEffect(() => {
      dispatch(getPatientHospital(patient_id_cookie, 1));
    }, [patient_id_cookie]);

    const arr_diff = (a1, a2) => {
      var a = [],
        diff = [];

      for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
      }

      for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
          delete a[a2[i]];
        } else {
          a[a2[i]] = true;
        }
      }

      for (var k in a) {
        diff.push(k);
      }

      return diff;
    };

    const handleSelect = async (type, value) => {
      if (type == "appointment_type_id") {
        setArrayDiff(value);
        let idValue = value.map((r) => {
          return r.value;
        });
        setArrayIDA(idValue);
        let idIDA = arrayDiff.map((r) => {
          return r.value;
        });
        let intersection = arr_diff(idIDA, idValue);
        if (intersection && intersection.length > 0) {
          setLoadingLocal(true);
          const resetParams = await dispatch(removeParamsChart());
          const resetCpt = await dispatch(reset_cpt_rules());
          await dispatch(getProcedureType(Number(intersection)));
          await dispatch(getDetailProcedureData(Number(intersection)));
          setLoadingLocal(false);
        }
      }
      setParams({
        ...params,
        [type]: value,
      });
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          setParamsChart("paramsChart", {
            ...params,
            [type]: value,
          })
        );
      }, 500);
    };
    useEffect(() => {
      if (params && Object.keys(params).length > 0) {
        const dataCheck = [...resourceHospital];
        if (
          (params?.providers_filter &&
            !params?.dateFilter &&
            !params?.typeFilter) ||
          (params?.dateFilter &&
            !params?.providers_filter &&
            !params?.typeFilter) ||
          (params?.typeFilter &&
            !params?.providers_filter &&
            !params?.dateFilter)
        ) {
          const dataFilter = dataCheck.filter((r) => {
            if (r.provider_id === params?.providers_filter) {
              return r;
            } else if (
              moment(r.appointment_date).format("MM/DD/YYYY") ===
              moment(params?.dateFilter).format("MM/DD/YYYY")
            ) {
              return r;
            } else if (r?.procedure_type?.id === params?.typeFilter) {
              return r;
            }
          });
          setDataHospital([...dataFilter]);
        } else if (
          (params?.providers_filter &&
            params?.dateFilter &&
            !params?.typeFilter) ||
          (params?.providers_filter &&
            !params?.dateFilter &&
            params?.typeFilter) ||
          (params?.dateFilter &&
            !params?.providers_filter &&
            params?.typeFilter)
        ) {
          let dataSet = [];
          if (params?.providers_filter && params?.dateFilter) {
            dataSet = dataCheck.filter((r) => {
              if (
                r.provider_id === params?.providers_filter &&
                moment(r.appointment_date).format("MM/DD/YYYY") ===
                  moment(params?.dateFilter).format("MM/DD/YYYY")
              ) {
                return r;
              }
            });
          } else if (params?.providers_filter && params?.typeFilter) {
            dataSet = dataCheck.filter((r) => {
              if (
                r.provider_id === params?.providers_filter &&
                r?.procedure_type?.id === params?.typeFilter
              ) {
                return r;
              }
            });
          } else if (params?.dateFilter && params?.typeFilter) {
            dataSet = dataCheck.filter((r) => {
              if (
                moment(r.appointment_date).format("MM/DD/YYYY") ===
                  moment(params?.dateFilter).format("MM/DD/YYYY") &&
                r?.procedure_type?.id === params?.typeFilter
              ) {
                return r;
              }
            });
          }
          setDataHospital([...dataSet]);
        } else {
          const dataFilter = dataCheck.filter((r) => {
            if (
              r.provider_id === params?.providers_filter &&
              moment(r.appointment_date).format("MM/DD/YYYY") ===
                moment(params?.dateFilter).format("MM/DD/YYYY") &&
              r?.procedure_type?.id === params?.typeFilter
            ) {
              return r;
            }
          });
          setDataHospital([...dataFilter]);
        }
        if (
          !params?.providers_filter &&
          !params?.dateFilter &&
          !params?.typeFilter
        ) {
          setDataHospital([...resourceHospital]);
        }
      }
    }, [params]);

    useEffect(() => {
      if (!loadingLocal && selectData && selectData.length > 0) {
        setSelectResult([...selectResult, ...selectData]);
      }
    }, [selectData]);

    const checkPrint = async () => {
      const base64 = await pdf2base64(charting_pdf.url);
      setPrintLink(base64);
      await dispatch(reset_charting_pdf());
    };

    useEffect(() => {
      if (charting_pdf && Object.keys(charting_pdf).length > 0) {
        checkPrint();
      }
    }, [charting_pdf]);

    const openFile = async () => {
      if (printLink) {
        await printPdf(printLink);
        await dispatch(getPatientHospital(Number(patient_id_cookie), 1));
        setTimeout(() => {
          setPrintLink("");
        }, 1000);
      }
    };

    let printPdf = function (b64) {
      const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize),
            byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };

      const contentType = "application/pdf",
        blob = b64toBlob(b64, contentType),
        blobUrl = URL.createObjectURL(blob);

      console.dir("new print");
      var iframe = null;

      iframe = document.createElement("iframe");
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          // setTimeout(iframe.contentWindow.print, 500);
          iframe.contentWindow.print();
        }, 500);
      };

      iframe.src = blobUrl;
    };

    useEffect(() => {
      openFile();
    }, [printLink]);

    useEffect(() => {
      setSelectResult([]);
      setPage(1);
      clearTimeout(timer);
      setLoadingLocal(true);
      timer = setTimeout(() => {
        dispatch(search_cpt_code(selectSearch, page));
        setLoadingLocal(false);
      }, 500);
    }, [selectSearch]);

    const save_patient_charting = async () => {
      if (
        params?.location_id &&
        params?.appointment_type_id &&
        params.appointment_date
      ) {
        let answerArray = [];
        if (params.ros) {
          (params.ros || []).forEach((r) => {
            (r.values || []).forEach((i) => {
              if (i.checked) {
                answerArray = [
                  ...answerArray,
                  {
                    procedure_type_variable_id: i.procedure_type_variable_id,
                    answer: i.option,
                  },
                ];
              }
            });
          });
        } else {
          if (params.specifications) {
            (params?.specifications).forEach((r) => {
              answerArray = [
                ...answerArray,
                {
                  procedure_type_variable_id: r.procedure_type_variable_id,
                  answer: r.answer,
                },
              ];
            });
          }
        }
        const getParam = {
          procedure_type_id: params?.appointment_type_id,
          patient_id: Number(patient_id_cookie),
          provider_id: params?.provider_id,
          cpt_codes: rowSearchID,
          answers: answerArray,
          user_id: user_id,
          appointment_date: params?.appointment_date,
          location_id: params?.location_id,
          preoperative_dx_id: params?.preoperative_dx_id,
          side: params?.side,
          anesthesia: params?.anesthesia,
          indications: indicationsValue?.indications,
          ebl: params?.ebl,
          complications: params?.complications,
          disposition: params?.disposition,
          text_template: text_param,
        };
        if (rowIDClick) {
          await dispatch(update_in_patient_charting(rowIDClick, getParam));
          await dispatch(getPatientHospital(Number(patient_id_cookie), 1));
          setSave(true);
          setTimeout(() => {
            setSave(false);
          }, 2000);
        } else {
          await dispatch(save_in_patient_charting(getParam));
          await dispatch(getPatientHospital(Number(patient_id_cookie), 1));
          setSave(true);
          setTimeout(() => {
            setSave(false);
          }, 2000);
        }
      } else {
        setToast(true);
        setKeyPanel("work-completes");
        setTimeout(() => {
          setToast(false);
        }, 2000);
      }
    };

    useEffect(() => {
      setDataHospital(resourceHospital);
    }, [resourceHospital]);

    useEffect(() => {
      if (isShow && resource && Object.keys(resource).length) {
        dispatch(
          setGlobalLoading(
            loadingProcedure || loadingLocal || loading || loadingSearchCPT
          )
        );
      }
    }, [
      isShow,
      resource,
      loadingProcedure,
      loadingLocal,
      loading,
      loadingSearchCPT,
    ]);

    const acctionClick = async (index, item) => {
      if (
        resourceHospital &&
        resourceHospital.length > 0 &&
        resourceHospital[index]
      ) {
        if (
          resourceHospital[index] &&
          resourceHospital[index]["medical_reading"] &&
          resourceHospital[index]["medical_reading"]["patient_document_id"]
        ) {
          setLoadingLocal(true);
          await cookies.set(
            "patient_document_id",
            resourceHospital[index]["medical_reading"]["patient_document_id"]
          );
          await setAcitive(1);
          await dispatch(removeParamsChart());
        } else {
          setLoadingLocal(true);
          await dispatch(removeParamsChart());
          await setParams(resourceHospital[index]);
          await dispatch(
            setParamsChart("paramsChart", resourceHospital[index])
          );
          await dispatch(
            setParamsChart("indicationsValue", resourceHospital[index])
          );
          setRowIDClick(item.id);
          await dispatch(
            getProcedureType(resourceHospital[index]["appointment_type_id"])
          );
          await dispatch(
            getDetailProcedureData(
              resourceHospital[index]["appointment_type_id"]
            )
          );
          setActiveRow(index);
          setTimeout(() => {
            setLoadingLocal(false);
          }, 500);
          setHospitalSelect(resourceHospital[index]);
        }
      }
    };

    const removeCPT = (row, index) => {
      const nameRow = [...rowSearchName];
      nameRow.splice(index, 1);
      setRowSearchName([...nameRow]);
      const idRow = [...rowSearchID];
      idRow.splice(index, 1);
      setRowSearchID([...idRow]);
    };

    const addCPT = (value, key, type) => {
      const nameRow = [...rowSearchName, `${value} ${type}`];
      setRowSearchName([...nameRow]);
      const idRow = [...rowSearchID, key];
      setRowSearchID([...idRow]);
    };

    if (!isShow || !resource || !Object.keys(resource).length) {
      return null;
    }

    const onScroll = (values) => {
      const isEndOfList = values.scrollTop > (3 / 5) * values.scrollHeight;
      if (
        isEndOfList &&
        !loadingSearchCPT &&
        selectData &&
        selectData.length > 0
      ) {
        setPage(page + 1);
        dispatch(search_cpt_code(selectSearch, page + 1));
      }
    };

    const onFinalize = async () => {
      if (hospitalSelect && Object.keys(hospitalSelect).length > 0) {
        await dispatch(charting_generate_pdf(hospitalSelect.id));
      }
    };

    const cptRules = () => {
      let answerArray = [];
      if (params?.ros) {
        (params.ros || []).map((r) => {
          (r.values || []).map((i) => {
            if (i.checked) {
              answerArray = [
                ...answerArray,
                {
                  procedure_type_variable_id: i.procedure_type_variable_id,
                  answer: i.option,
                },
              ];
            }
          });
        });
      } else {
        if (params?.specifications) {
          (params?.specifications).map((r) => {
            answerArray = [
              ...answerArray,
              {
                procedure_type_variable_id: r.procedure_type_variable_id,
                answer: r.answer,
              },
            ];
          });
        }
      }
      if (answerArray) {
        dispatch(
          apply_cpt_rules({
            procedure_type_id: params.appointment_type_id,
            answers: answerArray,
          })
        );
      }
    };

    const onChange = async (editorState) => {
      setEditor(editorState);
      let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      setParams({
        ...params,
        indications: data,
      });
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          setParamsChart("indicationsValue", {
            ["indications"]: data,
          })
        );
      }, 500);
    };

    return (
      <>
        <div
          ref={ref}
          className={`resourceContainer has-sub-header ${isShow ? "show" : ""}`}
        >
          {isFail && !isSave && (
            <ToastMessage
              type="In Patient Charting"
              status="failed"
              msg="You need make all infomation at Work Completed!"
            />
          )}
          {!isFail && isSave && (
            <ToastMessage
              type="In Patient Charting"
              status="success"
              msg="Insurance information is saved successfully!"
            />
          )}
          <ScrollWrapper css="x-hidden">
            <div className="headerContainer">
              <button onClick={handleCloseSidebar}>
                <CloseIcon />
              </button>
            </div>
            <SidebarTitle
              icon={<MaleSVG />}
              title={"IN-HOSPITAL DATABASE"}
              subTitle={""}
            />

            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "1rem",
              }}
            >
              <Collapse
                onChange={(value) => {
                  setKeyPanel(value);
                }}
                activeKey={keyPanel}
                accordion
              >
                <Panel header="Work Completed" key="work-completes">
                  <div className="resourceInfo d-flex">
                    <EditableFormInput
                      label={"Completed"}
                      value={
                        params?.appointment_type_id ||
                        hospitalSelect?.appointment_type_id
                      }
                      type={"check"}
                      multiple={true}
                      disableMultipleCheckAll={true}
                      optionKey={"id"}
                      handleChange={(value) => {
                        handleSelect("appointment_type_id", value);
                      }}
                      valueKey={"value"}
                      options={procedureData || []}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableFormInput
                      label={"On"}
                      value={
                        params?.appointment_date ||
                        hospitalSelect?.appointment_date
                      }
                      handleChange={(value) => {
                        handleSelect("appointment_date", value);
                      }}
                      type={"date"}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      label={"Location"}
                      value={params?.location_id || hospitalSelect?.location_id}
                      handleChange={(value) => {
                        handleSelect("location_id", value);
                      }}
                      type={"check"}
                      optionKey={"id"}
                      valueKey={"label"}
                      options={locations || []}
                    />
                  </div>
                </Panel>
                <Panel header="Report Generation" key="report-generation">
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      label={"Preoperative Dx"}
                      value={
                        params?.preoperative_dx_id ||
                        hospitalSelect?.medical_reading?.preoperative_dx_id
                      }
                      type={"check"}
                      handleChange={(value) => {
                        handleSelect("preoperative_dx_id", value);
                      }}
                      optionKey={"id"}
                      valueKey={"label"}
                      options={conditionData || []}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      label={"Side"}
                      value={
                        params?.side || hospitalSelect?.medical_reading?.side
                      }
                      handleChange={(value) => {
                        handleSelect("side", value);
                      }}
                      type={"check"}
                      optionKey={"value"}
                      valueKey={"label"}
                      options={[
                        { value: "Left", label: "Left" },
                        { value: "Right", label: "Right" },
                        { value: "Bilateral", label: "Bilateral" },
                      ]}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      label={"Anesthesia"}
                      value={
                        params?.anesthesia ||
                        hospitalSelect?.medical_reading?.anesthesia
                      }
                      handleChange={(value) => {
                        handleSelect("anesthesia", value);
                      }}
                      type={"check"}
                      optionKey={"value"}
                      valueKey={"label"}
                      options={[
                        { value: "General", label: "General" },
                        { value: "Local", label: "Local" },
                        { value: "Sedation", label: "Sedation" },
                        { value: "Epidural", label: "Epidural" },
                      ]}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      label={"EBL"}
                      value={
                        params?.ebl || hospitalSelect?.medical_reading?.ebl
                      }
                      handleChange={(value) => {
                        handleSelect("ebl", value);
                      }}
                      type={"text"}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      label={"Complications"}
                      value={
                        params?.complications ||
                        hospitalSelect?.medical_reading?.complications
                      }
                      handleChange={(value) => {
                        handleSelect("complications", value);
                      }}
                      type={"check"}
                      optionKey={"value"}
                      valueKey={"label"}
                      options={[
                        { value: "None", label: "None" },
                        { value: "Guarded", label: "Guarded" },
                        { value: "Death", label: "Death" },
                      ]}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      label={"Disposition"}
                      value={
                        params?.disposition ||
                        hospitalSelect?.medical_reading?.disposition
                      }
                      handleChange={(value) => {
                        handleSelect("disposition", value);
                      }}
                      type={"check"}
                      optionKey={"value"}
                      valueKey={"label"}
                      options={[
                        { value: "ICU", label: "ICU" },
                        { value: "Surgery", label: "Surgery" },
                        { value: "Floor", label: "Floor" },
                        { value: "Home", label: "Home" },
                      ]}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <div style={{ width: "100%", height: "100%" }}>
                      <Editor
                        toolbarHidden={true}
                        toolbar={{
                          options: [],
                          inline: {
                            options: [],
                          },
                          list: {
                            options: [],
                          },
                        }}
                        editorStyle={{
                          border: "1px solid #CDCDCD",
                          padding: "10px",
                          height: 130,
                          backgroundColor: "#fff",
                        }}
                        placeholder="Patient Review"
                        editorState={editorState}
                        onEditorStateChange={onChange}
                      />
                    </div>
                  </div>
                </Panel>
                <Panel
                  header="Procedure Specifications"
                  key="procedure-specifications"
                >
                  <div
                    className="resourceInfo"
                    style={{ paddingLeft: "0px", display: "block" }}
                  >
                    <ExaminationROS
                      type="procedure"
                      keyPanel={keyPanel}
                      dataAll={params}
                      dataCheck={
                        (params && params["ros"] && params["ros"][activeRow]) ||
                        (dataHospital &&
                          dataHospital.length > 0 &&
                          dataHospital[activeRow])
                      }
                      onChangeData={(value) => {
                        handleSelect("ros", value);
                      }}
                    />
                  </div>
                </Panel>
                <Panel header="Billing Codes" key="billing-codes">
                  {params?.appointment_type_id ? (
                    <div
                      style={{ paddingLeft: "1rem" }}
                      className="resourceInfo d-flex"
                    >
                      <button onClick={cptRules} className="text-btn">
                        Apply cpt rules +
                      </button>
                    </div>
                  ) : null}
                  <div
                    className="resourceInfo d-flex"
                    style={{ paddingLeft: "1rem" }}
                  >
                    <AutoCompleteComponent
                      onScroll={(value) => {
                        onScroll(value);
                      }}
                      title="previous-medical-cpt"
                      options={selectResult || []}
                      keyLabel={"code"}
                      keyType={"short_desc"}
                      keyValue={"id"}
                      rows={rowSearchName || []}
                      removeItem={(row, index) => removeCPT(row, index)}
                      selectItem={(value, key, type) =>
                        addCPT(value, key, type)
                      }
                      handleSearch={(value) => {
                        setSelectSearch(value);
                      }}
                    />
                  </div>
                </Panel>
                <Panel
                  header="Documentation History"
                  key="documentation-history"
                >
                  <div
                    className="resourceInfo d-flex"
                    style={{ alignItems: "flex-end" }}
                  >
                    <DatePicker
                      format={dateFormat}
                      placeholder="Filter by Date..."
                      suffixIcon={<ExpandSVG />}
                      value={params?.dateFilter}
                      onChange={(value) => {
                        handleSelect("dateFilter", value);
                      }}
                      style={{
                        width: "35%",
                        minWidth: "35%",
                        marginRight: "4px",
                      }}
                    />
                    <Select
                      placeholder="Type..."
                      suffixIcon={<ExpandSVG />}
                      allowClear={true}
                      value={params?.typeFilter}
                      onChange={(value) => {
                        handleSelect("typeFilter", value);
                      }}
                      style={{ width: "40%", maxWidth: "40%" }}
                    >
                      {(procedureData || []).map((r) => (
                        <Option key={r.id} value={r.id}>
                          {r.value}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      placeholder="Provider..."
                      suffixIcon={<ExpandSVG />}
                      value={params?.providers_filter}
                      onChange={(value) => {
                        handleSelect("providers_filter", value);
                      }}
                      allowClear={true}
                      style={{ width: "25%", maxWidth: "25%" }}
                    >
                      {(providers || []).map((r) => (
                        <Option key={r.id} value={r.id}>
                          {r.value}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div className="resourceInfo d-flex">
                    <div className="tableList">
                      {(dataHospital || []).map((item, index) => (
                        <div
                          onClick={() => {
                            acctionClick(index, item);
                            onClickSetRow(index, item);
                          }}
                          className={`tableItem yellow ${
                            activeRow === index ? "active" : ""
                          }`}
                          key={`chart-table-${index}`}
                        >
                          <div className="dateField">
                            <p>
                              {item.appointment_date
                                ? moment(item.appointment_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : null}
                            </p>
                          </div>
                          <div className="linkField">
                            <p>{item?.procedure_type?.value}</p>
                          </div>
                          <div className="athorFiled">
                            <p>
                              {providers &&
                              providers.length > 0 &&
                              item.provider_id &&
                              providers.find((r) => r.id === item.provider_id)
                                ?.value.length > 0
                                ? `${providers
                                    .find((r) => r.id === item.provider_id)
                                    ?.value.substring(0, 6)} ...`
                                : ""}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div className="actionContent" style={{ paddingBottom: "2rem" }}>
              <button
                className="common-btn"
                onClick={save_patient_charting}
                style={{ marginTop: "2rem" }}
              >
                Save
              </button>
              <button
                className="common-btn"
                onClick={onFinalize}
                style={{ marginTop: "2rem", marginLeft: "0.5rem" }}
              >
                Finalize +
              </button>
            </div>
          </ScrollWrapper>
        </div>
      </>
    );
  }
);

export default ChartingSideBar;
